import { type Component } from "vue";

import {
  IWidgetSettingsTabs,
  IWidgetMetaOptions,
  IWidgetOptions,
  IWidgetFieldItem,
} from "~~/models/widgets/widget.core/widget.model";

export enum FormElementName {
  TITLE = "TextInputField",
  DESCRIPTION = "TextEditorField",
  TEXT_INPUT = "FormTextField",
  PASSWORD = "FormPasswordField",
  EMAIL = "FormEmailField",
  NUMBER = "FormNumberField",
  CHECKBOX = "FormCheckboxField",
  DROPDOWN = "FormDropdownField",
  BUTTON = "ButtonLinkField",
  IMAGE = "FileUploadField",
  RADIO_GROUP = "FormRadioField",
  DATE = "FormDateField",
  PHONE = "FormTelField",
  FORM_TEXT_INPUT_FIELD = "FormTextInputField",
  UPLOAD = "FormDocumentUploadField",
  HIDDEN = "FormHiddenField",
}

export const FORM_ELEMENTS_LIST = [
  FormElementName.TITLE,
  FormElementName.DESCRIPTION,
  FormElementName.TEXT_INPUT,
  FormElementName.PASSWORD,
  FormElementName.EMAIL,
  FormElementName.NUMBER,
  FormElementName.CHECKBOX,
  FormElementName.DROPDOWN,
  FormElementName.BUTTON,
  FormElementName.IMAGE,
  FormElementName.RADIO_GROUP,
  FormElementName.DATE,
  FormElementName.FORM_TEXT_INPUT_FIELD,
  FormElementName.UPLOAD,
];

export interface IDynamicFieldProperties {
  fieldsTabs?: IWidgetSettingsTabs;
  options: IWidgetMetaOptions;
}

export type DynamicFieldsProperties = Record<string, IWidgetOptions>;
export type FormDropdownItem = {
  label: Record<string, string>;
  value: string;
};

export type FormFieldInitial = Record<
  string,
  (
    fieldDetails?: {
      placeholder: string;
      label: string;
      options?: IWidgetOptions;
      items?: FormDropdownItem[] | null;
    },
    formName?: string
  ) => {
    options: IWidgetOptions;
    items?: FormDropdownItem[] | null;
  }
>;

export type FormStylingFieldInitial = Record<
  string,
  () => { options: IWidgetOptions; title: string }
>;

export enum FormStyleFieldName {
  TITLE = "title_styling",
  FIELD = "fields_styling",
  LABEL = "labels_styling",
  TOOLTIP = "tooltips_styling",
  ERROR = "errors_styling",
  CHECKBOX = "checkboxes_styling",
  DROPDOWNS = "dropdowns_styling",
  DROPDOWN_MENU = "dropdown_menu_styling",
  DROPDOWN_MENU_ITEMS = "dropdown_menu_items_styling",
  BUTTON = "buttons_styling",
  RADIO_GROUP = "radio_group_styling",
  CALENDAR = "calendar_styling",
  DATEPICKER = "datepicker_styling",
  UPLOAD_LIST = "upload_list_styling",
  UPLOAD_ITEMS = "upload_items_styling",
  UPLOAD_CAPTION = "upload_caption_styling",
  UPLOAD_BUTTON = "upload_button_styling",
  SUCCESS = "field_success_styling",
}

export enum FormScreen {
  FORM = "form",
  SUCCESS = "success",
  EXPIRED = "expired",
}

export enum DepositListScreen {
  DATA_FILLED = "dataFilledPage",
  PAYMENT_METHOD = "paymentMethodPage",
  SUCCESS = "success",
  FAIL = "fail",
}

export const FORM_TRIGGERS_ELEMENTS = [
  FormStyleFieldName.TOOLTIP,
  FormStyleFieldName.ERROR,
  FormStyleFieldName.DROPDOWN_MENU,
  FormStyleFieldName.DROPDOWN_MENU_ITEMS,
];

export type IFormFieldLocalizedOptions = {
  items?: Record<string, string>;
  label?: {
    value: Record<string, string>;
    _active: boolean;
  };
  tooltip?: {
    value: Record<string, string>;
    _active: boolean;
  };
  placeholder?: {
    value: Record<string, string>;
    _active: boolean;
  };
};

export type FormFieldDetails = {
  value: {
    fields: {
      [fieldName: string]: {
        hint?: string;
        items: IWidgetFieldItem[];
        label: string;
        name: FormElementName;
        placeholder: string;
        options?: IFormFieldLocalizedOptions;
        type: string;
        validation: {
          [key: string]: any;
        };
        value: any;
        readonly?: boolean;
      };
    };
  };
};

export type FormDescriptionOptionUpdate = {
  optionName: string;
  value: any;
  fieldId: string | number;
};

export type FormDescriptionOptionsUpdate = {
  options: Record<string, string>;
  fieldId: string | number;
};

export const SUCCESS_SCREEN_FIELDS = [
  "success_title",
  "success_description_1",
  "success_image",
  "success_button",
];

export const EXPIRED_SCREEN_FIELDS = [
  "expired_title",
  "expired_description_1",
  "expired_image",
  "expired_button",
];

export type TriggerComponent = Component & {
  triggerFieldExample: (fieldStylingName: string) => void;
  hideFieldExample: (fieldStylingName: string) => void;
};

export const FAIL_SCREEN_FIELDS = [
  "fail_title",
  "fail_description_1",
  "fail_image",
  "fail_button",
];

export const PROVIDERS_FIELDS = [
  "providers_item_button",
  "provider_description_1",
  "provider_image",
  "provider_title",
  "provider_image",
];

export const FORM_CARD_FIELDS = [
  "form_back_button",
  "update_profile_text",
  "title",
  "provider_card",
];

export const HIDE_DEPOSIT_LIST_FORM_SETTINGS_DICT = [
  "form_back_button",
  "provider_card",
  "provider_description_1",
  "provider_image",
  "provider_title",
  "providers_item",
  "providers_item_button",
  "providers_item_image",
  "providers_item_limits",
  "providers_item_title",
  "providers_item_tooltip",
  "providers_list",
  "title",
  "title_styling",
  "update_profile_text",
];

export enum ProviderFormFenixScreen {
  PROVIDER_PAGE = "providerPage",
  SUCCESS_STATUS_PAGE = "successStatusPage",
  FAIL_STATUS_PAGE = "failStatusPage",
  PENDING_STATUS_PAGE = "pendingStatusPage",
}

export const HIDE_PROVIDER_FORM_FENIX_LIST = [
  "form_back_button",
  "provider_card",
  "provider_description_1",
  "provider_image",
  "provider_title",
  "success_message",
  "fail_message",
  "pending_message",
  "additional_info",
  "deposit_form_description",
];
