<script lang="ts" setup>
import { usePagesStore } from "~~/store/pages";
import { useQueryStore } from "~~/helpers/query-store";
import { useFormErrors } from "~~/composables/useFormErrors";
import { useLanguagesStore } from "~~/store/languages";
import { PageType } from "~~/models/stores/pages-store.model";

import PageConfigDrawerPermissionsEditor from "./accessScenarioEditor/PageConfigDrawerPermissionsEditor.vue";
import PageConfigDrawerLanguageRoute from "./PageConfigDrawerLanguageRoute.vue";

const { errors, parseError, resetErrors } = useFormErrors();
const { selectedPage } = storeToRefs(usePagesStore());

type SettingType = "page-info" | "dynamic-params" | "visit-rules";

const selectedPageSetting = ref<string>("page-info");
const pageSettings = ref<Record<SettingType, string>>({
  "page-info": "Page info",
  "dynamic-params": "Dynamic parameters",
  "visit-rules": "Visit rules",
});

const languagesStore = useLanguagesStore();
const { availableLanguages, defaultLanguage } = storeToRefs(languagesStore);

const loading = ref<boolean>(false);

const selectedLanguage = ref<string>(defaultLanguage.value?.codeWithRegion!);

const open = useQueryStore("showPageConfig", {
  transform: {
    get: value => Boolean(value),
    set: value => (value ? "true" : undefined),
  },
});

const isDefaultLanguage = computed(() => {
  return selectedLanguage.value === defaultLanguage.value?.codeWithRegion;
});

const onSubmit = async (values: any) => {
  loading.value = true;
  resetErrors();

  try {
    await usePagesStore().editPage({
      id: selectedPage.value!.id,
      name: values.name,
      routes: values.routes,
      routeBinding: values.routeBinding,
      options: selectedPage.value?.options,
      pageType: selectedPage.value?.pageType,
    });

    open.value = false;
  } catch (err: any) {
    parseError(err);
  } finally {
    loading.value = false;
  }
};

const closeDrawer = () => {
  open.value = false;
};

const resetValidation = (field: string) => {
  delete errors.value[field];
};

onBeforeMount(() => {
  if (!selectedPage.value) {
    return;
  }

  if (
    !selectedPage.value?.options ||
    Array.isArray(selectedPage.value.options)
  ) {
    selectedPage.value.options = {};
  }

  if (!selectedPage.value.options?.accessScenarios) {
    selectedPage.value.options.accessScenarios = [];
  }
});

onMounted(() => {
  open.value = false;
  if (!selectedPage.value) {
    return;
  }

  const firstRoute = Object.values(selectedPage.value.routes)[0];

  for (const lang in availableLanguages.value) {
    const code = availableLanguages.value[lang].codeWithRegion;

    if (!selectedPage.value?.routes[code]) {
      usePagesStore().updatePageOptions({
        routes: {
          ...selectedPage.value.routes,
          [code]: firstRoute,
        },
      });
    }
  }
});

const handlePageAlignmentUpdate = (alignment: string) => {
  if (selectedPage.value && selectedPage.value.options) {
    selectedPage.value.options.alignment = alignment;
  }
};
</script>

<template>
  <a-drawer
    v-if="selectedPage"
    v-model:visible="open"
    class="page-config-drawer"
    :width="500"
    title="Edit page"
    placement="right"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      name="dynamic_form_nest_item"
      :model="selectedPage"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-tabs
        v-model:active-key="selectedPageSetting"
        type="card"
        size="large"
      >
        <a-tab-pane
          key="page-info"
          :tab="pageSettings['page-info']"
        >
          <a-typography-title :level="5">
            {{ pageSettings["page-info"] }}
          </a-typography-title>

          <a-form-item
            class="margin-bottom-none"
            label="Name"
            name="name"
            :rules="[{ required: true, message: 'Field is required!' }]"
            layout="vertical"
            :validate-status="errors.name ? 'error' : undefined"
            :help="errors.name"
          >
            <a-input
              v-model:value="selectedPage.name"
              @input="resetValidation('name')"
            />
          </a-form-item>

          <a-form-item
            class="page-config-drawer__page-type-radio"
            label="Page Type"
            :rules="[{ required: true }]"
            layout="vertical"
          >
            <a-radio-group v-model:value="selectedPage.pageType">
              <a-radio-button :value="PageType.WEBPAGE">Webpage</a-radio-button>
              <a-radio-button :value="PageType.MODAL_BOX"
                >Modal box</a-radio-button
              >
            </a-radio-group>
          </a-form-item>

          <PageConfigAlignment
            v-if="selectedPage.pageType === PageType.MODAL_BOX"
            :model-value="selectedPage?.options?.alignment"
            @update:model-value="handlePageAlignmentUpdate"
          />

          <a-divider />

          <a-typography-title :level="5">
            {{ pageSettings["dynamic-params"] }}
          </a-typography-title>

          <a-collapse ghost>
            <template #expandIcon="{ isActive }">
              <CommonIcon
                :name="
                  isActive
                    ? 'ant-design:caret-down-filled'
                    : 'ant-design:caret-right-filled'
                "
              />
            </template>

            <a-collapse-panel
              class="page-config-drawer__collapse-item"
              header="More about dynamic parameters"
            >
              <p>
                The values specified with «:» in the page URL field will be
                automatically split into dynamic parameters. You can link
                entities and values to them by creating a site map in this way.
              </p>
            </a-collapse-panel>
          </a-collapse>

          <a-tabs v-model:active-key="selectedLanguage">
            <a-tab-pane
              v-for="(language, key) in availableLanguages"
              :key="key"
              :tab="language.name"
              force-render
            >
              <PageConfigDrawerLanguageRoute
                v-model:route="selectedPage.routes[language.codeWithRegion]"
                v-model:errors="errors"
                :language="language"
                @update:route="resetValidation('route')"
              />
            </a-tab-pane>
          </a-tabs>

          <a-space
            style="width: 100%; margin-top: 24px"
            :size="16"
            direction="vertical"
          >
            <DynamicParamField
              v-for="(_, name) in selectedPage.routeBinding"
              :key="name"
              :label="name"
            >
              <DynamicEntityInput
                v-model="selectedPage.routeBinding[name]"
                :validation-path="['routeBinding', name]"
                :rules="[{ required: true, message: 'Field is required!' }]"
                :disabled="!isDefaultLanguage"
                label-type="Entity"
                label-variable="Entity variable"
              />
            </DynamicParamField>
          </a-space>
        </a-tab-pane>
        <a-tab-pane
          key="visit-rules"
          :tab="pageSettings['visit-rules']"
        >
          <template v-if="selectedPage.options?.accessScenarios">
            <PageConfigDrawerPermissionsEditor
              v-model:scenarios="selectedPage.options.accessScenarios"
            />
          </template>
        </a-tab-pane>
      </a-tabs>

      <div class="page-config-drawer__footer">
        <a-button @click="closeDrawer"> Cancel </a-button>

        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          Save
        </a-button>
      </div>
    </a-form>
  </a-drawer>
</template>

<style lang="scss">
.page-config-drawer {
  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    color: $c-grey-85;
    font-size: 14px;
    margin: 0;
  }

  .ant-drawer-title {
    font-size: 20px;
    font-weight: 600;
  }
  .ant-drawer-body {
    padding-bottom: 0;
  }

  .page-config-drawer__collapse-item {
    margin: 0 -12px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 0;
    color: #1677ff;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 4px;

    .common-icon {
      opacity: 0.88;
    }
  }

  &__alert-message {
    max-width: 300px;
  }

  &__footer {
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #e9e9e9;
    padding: 16px 0;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &__page-type-radio {
    margin: 16px 0 0 0;

    .ant-radio-group {
      width: 100% !important;
      display: flex !important;
    }

    .ant-radio-button-wrapper {
      width: 100% !important;
      text-align: center;
    }

    .ant-radio-button-wrapper:first-child {
      border-start-start-radius: 6px;
      border-end-start-radius: 6px;
    }

    .ant-radio-button-wrapper:last-child {
      border-start-end-radius: 6px;
      border-end-end-radius: 6px;
    }
  }
}
</style>
