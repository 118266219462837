import {
  MIN_SEARCH_CATEGORY_LENGTH,
  SEARCH_INPUT_DELAY,
} from "~~/constants/widget-config";
import {
  OptionValue,
  ICommonMenuItem,
  DropdownSearchItem,
} from "~~/models/common/dropdown.model";
import { debounce } from "~~/assets/utils";
import { useWidgetsStore } from "~~/store/widgets";

export const useCategoriesSearch = () => {
  const widgetsStore = useWidgetsStore();

  const loading = ref<boolean>(false);
  const categoryInputValue = ref<string>("");

  const groupsList = computed<ICommonMenuItem[]>(() => {
    return widgetsStore.groups;
  });

  const selectedCategoryOrGroup = computed<DropdownSearchItem>(() => {
    return widgetsStore.selectedCategoryOrGroup;
  });

  const fetchAvailableGroups = (): void => {
    loading.value = true;

    widgetsStore.fetchAvailableGroups().finally(() => {
      loading.value = false;
    });
  };

  const fetchCategories = (): void => {
    loading.value = true;

    widgetsStore.fetchCategories(categoryInputValue.value).finally(() => {
      loading.value = false;
    });
  };

  const handleUpdateCategoryInput = debounce((v: OptionValue): void => {
    if (!v || v.toString().length < MIN_SEARCH_CATEGORY_LENGTH) {
      widgetsStore.setSelectedCategoryOrGroup(null);
      return;
    }

    /* 
      We don't need to call api on dropdown select
    */
    if (selectedCategoryOrGroup.value?.id === v) {
      return;
    }

    fetchCategories();
  }, SEARCH_INPUT_DELAY);

  const handleSearchFocus = (): void => {
    if (groupsList.value.length || loading.value) {
      return;
    }

    fetchAvailableGroups();
  };

  const handleUpdateCategory = (value: DropdownSearchItem): void => {
    widgetsStore.setSelectedCategoryOrGroup(value);
  };

  return {
    groupsList,
    loading,
    selectedCategoryOrGroup,
    categoryInputValue,

    handleUpdateCategoryInput,
    handleSearchFocus,
    handleUpdateCategory,
  };
};
