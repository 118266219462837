import { GlobalEvents } from "~~/models/common/events.enum";

import { Events } from "./events";

const events = new Events();

events.createEvent(
  GlobalEvents.WINDOW_SCROLL,
  (eventHandler: (nativeEvent: Event) => void) => {
    window.addEventListener("scroll", eventHandler);
  }
);

events.createEvent(
  GlobalEvents.WINDOW_DROP,
  (eventHandler: (nativeEvent: Event) => void) => {
    window.addEventListener("drop", eventHandler);
  }
);

events.createEvent(
  GlobalEvents.BODY_CLICK,
  (eventHandler: (nativeEvent: Event) => void) => {
    document.body.addEventListener("click", eventHandler);
  }
);

export { events, GlobalEvents };
