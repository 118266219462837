import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getCornerRadiusStyle,
  getDecorationValue,
  getFlexPosition,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import {
  ResizingType,
  SpacingKeyName,
  State,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { getPxValueFromNumber } from "~~/assets/utils";
import { CellSizeType } from "~~/models/grid.interface";

export const getWidthStyles = (width: WidgetDimension): ElementStyle => {
  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.type === CellSizeType.FIXED) {
    return {
      width: `${width.value?.value}${width.value?.type}`,
    };
  }

  return {
    width: "fit-content !important",
  };
};

export const getHeightStyles = (height: WidgetDimension): ElementStyle => {
  if (height.type === CellSizeType.FILL) {
    return {
      alignSelf: "stretch",
    };
  }

  if (height.type === CellSizeType.FIXED) {
    return {
      height: `${height.value?.value}${height.value?.type}`,
    };
  }

  return {
    height: "fit-content !important",
  };
};

export const useBetslipHeaderInlineStyles = (
  headerOptions: Ref<IWidgetOptions>,
  headerCountersOptions: Ref<IWidgetOptions>,
  state: Ref<State>,
  cashOutOptions?: Ref<IWidgetOptions>
) => {
  const isHeaderDefaultState = computed<boolean>(() => {
    return state.value === State.DEFAULT;
  });

  const activeHeaderContainerStateOptions = computed<IWidgetOptions>(() => {
    if (isHeaderDefaultState.value) {
      return headerOptions.value;
    }

    return headerOptions.value.states[state.value];
  });

  const horizontontalAlignmentStyles = computed<ElementStyle>(() => {
    if (headerOptions.value.layout === 1) {
      return {
        justifyContent: generateFlexAlignment(
          headerOptions.value.buttonDisplaySettings.alignment
        ),
      };
    }

    return {
      alignItems: generateFlexAlignment(
        headerOptions.value.buttonDisplaySettings.alignment
      ),
    };
  });

  const headerCashOutItemStyles = computed<ElementStyle>(() => {
    if (headerOptions.value.layout === 1) {
      return {
        alignItems: generateFlexAlignment(
          headerOptions.value.buttonDisplaySettings.alignment
        ),
        justifyContent: getFlexPosition(headerOptions.value.alignment),
      };
    }

    return {
      alignItems: generateFlexAlignment(
        headerOptions.value.buttonDisplaySettings.alignment
      ),
      justifyContent: getFlexPosition(headerOptions.value.alignment),
    };
  });

  const headerItemStyle = computed<ElementStyle>(() => {
    if (headerOptions.value.layout === 1) {
      return {
        justifyContent: generateFlexAlignment(
          headerOptions.value.buttonDisplaySettings.alignment
        ),
        alignItems: getFlexPosition(headerOptions.value.alignment),
      };
    }

    return {
      alignItems: generateFlexAlignment(
        headerOptions.value.buttonDisplaySettings.alignment
      ),
      justifyContent: getFlexPosition(headerOptions.value.alignment),
    };
  });

  const headerContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(headerOptions.value),
      ...getSpacing(headerOptions.value.margins, SpacingKeyName.MARGIN),
      ...getSpacing(headerOptions.value.padding, SpacingKeyName.PADDING),
      gap: `${headerOptions.value.spaceBetween}px`,
    };
  });

  const gapStyles = computed<ElementStyle>(() => {
    return {
      gap: `${activeHeaderContainerStateOptions.value.gapBetweenTextAndCounter}px`,
    };
  });

  const headerContentGapInlineStyles = computed<ElementStyle>(() => {
    const layout = activeHeaderContainerStateOptions.value.layout;
    return {
      flexDirection: layout === 2 || layout === 3 ? "column" : "row",
      ...gapStyles.value,
    };
  });

  const headerContentInlineStyles = computed<ElementStyle>(() => {
    const isFill =
      headerOptions.value.buttonDisplaySettings.resizing === ResizingType.FILL;
    return {
      color: getColorFromHex(activeHeaderContainerStateOptions.value.color)!,
      fontSize: headerOptions.value.theme
        ? `${headerOptions.value.theme.value}px`
        : undefined,
      fontFamily: headerOptions.value.fontFamily,
      ...getBackgroundColorStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.fill
      ),
      ...getBorderStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.border
      ),
      ...getBoxShadowStyle(
        activeHeaderContainerStateOptions.value.headerElementsSettings.shadow
      ),
      ...getSpacing(headerOptions.value.padding, SpacingKeyName.PADDING),
      ...getSpacing(
        headerOptions.value.headerElementsSettings.padding,
        SpacingKeyName.PADDING
      ),
      ...getCornerRadiusStyle(
        headerOptions.value.headerElementsSettings.cornerRadius
      ),
      width: isFill ? "100%" : "fit-content",
      // justifyContent: isFill
      //   ? generateFlexAlignment(
      //       headerOptions.value.buttonDisplaySettings.alignment
      //     )
      //   : "center",
    };
  });

  const headerLabelInlineDecorationStyles = computed<ElementStyle>(() => {
    return {
      ...getDecorationValue(headerOptions.value.decoration),
    };
  });

  const headerCashOutStyles = computed(() => {
    return {
      color: getColorFromHex(cashOutOptions?.value.color)!,
      fontSize: getPxValueFromNumber(cashOutOptions?.value.theme.value),
      fontFamily: cashOutOptions?.value.fontFamily,
      textAlign: cashOutOptions?.value.alignment,
      ...getSpacing(cashOutOptions?.value.padding, SpacingKeyName.PADDING),
      ...getDecorationValue(cashOutOptions?.value.decoration),
    };
  });

  const headerCounterInlineStyles = computed<ElementStyle>(() => {
    let headerCountersStatesOptions;

    if (!isHeaderDefaultState.value) {
      headerCountersStatesOptions =
        headerCountersOptions.value.states[state.value];
    }

    return {
      color: isHeaderDefaultState.value
        ? getColorFromHex(headerCountersOptions.value.color)!
        : getColorFromHex(headerCountersStatesOptions.color)!,
      fontSize: `${headerCountersOptions.value.theme.value}px`,
      fontFamily: headerCountersOptions.value.fontFamily,
      ...getBorderStyle(headerCountersOptions.value.border),
      backgroundColor: isHeaderDefaultState.value
        ? getColorFromHex(headerCountersOptions.value.fill)!
        : getColorFromHex(headerCountersStatesOptions.fill)!,
      ...getSpacing(
        headerCountersOptions.value.padding,
        SpacingKeyName.PADDING
      ),
      ...getDecorationValue(headerCountersOptions.value.decoration),
      ...getCornerRadiusStyle(headerCountersOptions.value.cornerRadius),
    };
  });

  const sizeStyles = computed<ElementStyle>(() => {
    const size = headerOptions.value.size;

    if (!size) {
      return {};
    }

    const widthStyles = getWidthStyles(size.width);
    const heightStyles = getHeightStyles(size.height);

    return {
      ...widthStyles,
      ...heightStyles,
    };
  });

  return {
    headerContainerInlineStyles,
    headerContentGapInlineStyles,
    gapStyles,
    headerContentInlineStyles,
    headerCounterInlineStyles,
    headerLabelInlineDecorationStyles,
    state,
    headerCashOutStyles,
    horizontontalAlignmentStyles,
    headerCashOutItemStyles,
    headerItemStyle,
    sizeStyles,
  };
};
