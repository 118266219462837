<template>
  <div
    v-if="selectedCell"
    class="settings-columns"
  >
    <div
      v-translate:grid="'Columns'"
      class="settings-columns__header"
    ></div>
    <a-tooltip placement="bottom">
      <template
        v-if="!areColumnsConfigurable"
        #title
      >
        <span>{{ CHANGING_COLUMNS_NOT_POSSIBLE }}</span>
      </template>
      <div
        :class="{
          'settings-columns__layouts': true,
          'settings-columns__layouts--disabled': hasWidthSettingsErrors,
        }"
      >
        <GridSettingsLayout
          v-for="option in LAYOUTS"
          :key="option"
          :columns="option"
          :is-disabled="!areColumnsConfigurable"
          @select="onSelectLayout"
        />
      </div>
    </a-tooltip>
    <div
      v-show="selectedCell.children.length > 1 && areColumnsConfigurable"
      class="settings-columns__controls"
    >
      <a-form
        ref="form"
        :model="formModel"
        class="settings-columns__form"
      >
        <div
          v-for="(child, index) in formModel.columns"
          :key="index"
          class="settings-columns__layout-element"
        >
          <div class="f-base">
            {{ getLayoutNumberingText(index) }}
          </div>
          <div class="settings-columns__fixed-switch">
            <a-tooltip placement="bottom">
              <template
                v-if="shouldDisableSizingToggle(child.sizing)"
                #title
              >
                <span>{{ tooltipMessage }}</span>
              </template>
              <CommonToggle
                :key="child.sizing"
                :is-on="child.sizing === Sizing.FIXED"
                toggle-size="small"
                :is-disabled="shouldDisableSizingToggle(child.sizing)"
                @toggle="handleToggle($event, index)"
              />
              Fixed
            </a-tooltip>
          </div>
          <a-form-item
            :name="['columns', index]"
            :rules="{
              validator: validateCol,
              trigger: 'change',
            }"
          >
            <div class="settings-columns__input">
              <WidthInput
                v-model:input="formModel.columns[index]"
                @change="updateAndValidate"
              ></WidthInput>
            </div>
          </a-form-item>
        </div>
      </a-form>
    </div>
    <p
      v-show="errorMsg"
      class="settings-columns__error"
    >
      {{ errorMsg }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, watch } from "vue";

import { Sizing } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { LAYOUTS } from "~~/constants/layouts";
import { widthValidations } from "~~/constants/width-validations";
import { CHANGING_COLUMNS_NOT_POSSIBLE } from "~~/constants/tooltip-messages";
import { useColumnWidthValidation } from "~~/components/composables/grid/useColumnsWidthValidation";
import { recalculateCells } from "~~/assets/utils/grid/grid-cell-width";

const gridStore = useGridConfig();

const { selectedCell, hasWidthSettingsErrors, areColumnsConfigurable } =
  storeToRefs(gridStore);
const { changeColumnsNumber } = gridStore;

const form = ref();

const errorMsg = ref("");

const {
  formModel,
  getColumns,
  fixedCellsCount,
  getAdaptiveCellWidth,
  getFixedCellWidth,
  validateTotalWidth,
  validateCol,
} = useColumnWidthValidation();

const maxFixedCount = computed(() => {
  const parentCell = selectedCell.value;
  const children = parentCell!.children.length;
  return parentCell!.settings.sizing === Sizing.FIXED ? children : children - 1;
});

const shouldDisableAdaptiveSizingToggle = computed((): boolean => {
  return fixedCellsCount.value.current >= maxFixedCount.value;
});

const shouldDisableFixedSizingToggle = computed((): boolean => {
  return fixedCellsCount.value.current === 1;
});

const tooltipMessage = computed(() => {
  if (shouldDisableAdaptiveSizingToggle.value)
    return widthValidations.REQUIRED_ADAPTIVE_CHILD;
  else if (shouldDisableFixedSizingToggle.value)
    return widthValidations.REQUIRED_FIXED_CHILD;
  return "";
});

const shouldDisableSizingToggle = (sizing: Sizing): boolean => {
  if (
    selectedCell.value?.settings.sizing === Sizing.ADAPTIVE &&
    sizing === Sizing.ADAPTIVE
  ) {
    return shouldDisableAdaptiveSizingToggle.value;
  } else if (
    selectedCell.value?.settings.sizing === Sizing.FIXED &&
    sizing === Sizing.FIXED
  ) {
    return shouldDisableFixedSizingToggle.value;
  }
  return false;
};

const selectedCellColumns = computed<number>(
  () => selectedCell.value?.children.length ?? 0
);

const selectedCellSizing = computed<Sizing | undefined>(() => {
  return selectedCell.value?.settings.sizing;
});

/* NOTE: temporary; will be taken from translations */
function getLayoutNumberingText(index: number): string {
  let numberingText = `${index + 1}${
    index === 0 ? "st" : index === 1 ? "nd" : "rd"
  }`;
  return numberingText;
}

watch(
  () => selectedCell.value,
  () => {
    if (selectedCell.value) {
      formModel.columns = getColumns(false);
      updateAndValidate();
    }
  }
);

watch(
  () => selectedCellColumns.value,
  () => {
    formModel.columns = getColumns();
    updateAndValidate();
  }
);

watch(
  () => selectedCellSizing.value,
  () => {
    formModel.columns = getColumns();
    updateAndValidate();
  }
);

const updateAndValidate = async (): Promise<void> => {
  gridStore.setValidationErrors(false);
  if (!selectedCell.value) return;
  await nextTick();
  try {
    await form.value.validate();
    /* This would empty message if no errors */
    errorMsg.value = await validateTotalWidth();
    gridStore.setSelectedCellChildren(formModel.columns);
  } catch (e: any) {
    /* Individual errors for input fields are also caught here, but we don't need to display them,
    only custom form validation error handled by `validateTotalWidth` */
    if (typeof e === "string") {
      errorMsg.value = e as string;
    } else if (e.errorFields.length === 0) return;
    gridStore.setValidationErrors(true);
  }
};

function handleToggle(toggleValue: boolean, targetIndex: number): void {
  if (!selectedCell.value) return;
  const cellToChange = formModel.columns[targetIndex];
  if (cellToChange) {
    cellToChange.width = toggleValue
      ? getFixedCellWidth()
      : getAdaptiveCellWidth();
    cellToChange.sizing = toggleValue ? Sizing.FIXED : Sizing.ADAPTIVE;
  }
  recalculateCells(
    formModel.columns,
    selectedCell.value.settings.sizing,
    selectedCell.value.settings.width
  );
  updateAndValidate();
}

function onSelectLayout($event: number): void {
  changeColumnsNumber($event);
}

onMounted(() => {
  updateAndValidate();
});
</script>

<style lang="scss">
.settings-columns {
  border-bottom: 1px solid $c-grey-15;
  width: 100%;
  padding: $space-m $space-m 28px;

  &__header {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $c-black;
    margin-bottom: $space-m;
  }

  &__layouts {
    @include flex(center, flex-start, 12px);

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__controls {
    @include flex(center, space-between, $space-s);

    .ant-form {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__layout-element {
    @include flex-column(flex-start, space-between, 0);
    margin: $space-m 0 0;
    width: 100%;
    flex: 1 0 72px;
  }

  &__fixed-switch {
    @include flex(center, space-between, $space-s);
    margin-top: 4px;
  }

  &__input {
    margin-top: 12px;
  }

  &__error {
    margin-top: 12px;
    color: $c-red;
  }

  .ant-input-affix-wrapper {
    border-radius: 4px;
    padding: 0px 3px 0 4px;
  }

  .ant-form {
    @include flex(center, center, 8px);
  }

  .ant-form-item-explain-error {
    margin-top: 12px;
  }
}
</style>
