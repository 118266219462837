import { type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { useEventCardsConfig } from "~~/composables/widgets/sportsbook/useEventCardsConfig";

export const useBet9EventCardsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const {
    eventCardsDesignControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  } = useEventCardsConfig(widget, states, emit);

  const eventCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.columns",
            valueSource: fields.value.list_container.field,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distanceBetweenCards",
            valueSource: fields.value.list_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: fields.value.list_container.field,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: fields.value.list_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League name",
              value: "league_title",
              isActiveValueSource: fields.value.league_title.field.options,
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Event info",
              value: "list_event_info",
            },
            {
              label: "Bet items",
              value: "list_item",
            },
            {
              label: "Event button link",
              value: "list_item_more",
            },
          ],
        },
      },
    ];
  });

  return {
    eventCardsDesignControls,
    eventCardsContentControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  };
};
