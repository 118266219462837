import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateStageButtonCssString,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateTextChooseIconCssString,
  generateCheckboxCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateScreensCssString,
  generateIconRightCssString,
  generateIconCssString,
  generateIconSizeCssString,
  generateFlexContainerCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateMaxHeight, generateWidth } from "../helpers";

const generateUserProfileDropdownMenuCssString =
  (dropdownMenu: IWidgetOptions, menuItem: IWidgetOptions) =>
  (cssString: string): string => {
    const dropdownCssContent = [
      generateDefaultStyles(dropdownMenu.options),
      generateWidth(100, "%"),
      generateMaxHeight("250px"),
    ].join("");

    const menuItemCssContent = generateFlex({
      flex: "flex",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: menuItem.options.spaceBetween,
    });

    cssString += generateCssClassWithContent({
      className: dropdownMenu.options._cssClass,
      content: [dropdownCssContent, menuItemCssContent].join(""),
    });
    return cssString;
  };

export const generateCustomSuccessMessageCss =
  (successField: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: successField.options._cssClass,
      pseudoClassName: " > div",
      content: "flex: 1;",
    });
    return cssString;
  };

export const generateUserProfileFormWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const form = widget.content["form"];
  const submitButton = widget.content["submit"];
  const title = widget.content["title"];
  const success_message = widget.content["success_message"];

  const customFields = widget.options._customFields;
  const submitButtonClassName = generateClassName("submitButton");

  const titleClassName = generateClassName("title");

  // Widget class name
  widget.options._cssClass = generateClassName("UserProfileFormWidget");

  // Form, Label, Field, Error, Tooltip class names
  form.options._cssClass = generateClassName("form");
  success_message.options._cssClass = generateClassName("success_message");
  success_message.options.iconSettings._cssClass = generateClassName(
    "success_message_icon"
  );

  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");

  // Submit button class name
  submitButton.options._cssClass = submitButtonClassName;
  submitButton.options.iconSettings._cssClass = generateClassName("buttonIcon");

  // Title class name
  title.options._cssClass = titleClassName;
  customFields.title_styling.options._cssClass = titleClassName;

  if (customFields.dropdowns_styling) {
    // Dropdown class names
    customFields.dropdowns_styling.options._cssClass =
      generateClassName("dropdown");
    customFields.dropdown_menu_styling.options._cssClass =
      generateClassName("dropdownMenu");
    customFields.dropdown_menu_items_styling.options._cssClass =
      generateClassName("dropdownItems");
  }

  if (customFields.calendar_styling) {
    // Calendar class names
    customFields.calendar_styling.options._wrapperCssClass =
      generateClassName("calendarWrapper");
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    customFields.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    customFields.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");

    customFields.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  if (customFields.checkboxes_styling) {
    customFields.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    customFields.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");

    customFields.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (customFields.checkboxes_styling.options.check.icon) {
      customFields.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  const checkboxes =
    customFields.checkboxes_styling &&
    customFields.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          customFields.checkboxes_styling,
          customFields.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(customFields.checkboxes_styling);

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateUserProfileDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  return pipeSync<string>(
    generateStringDefault(widget),
    generateFormCssString(form, widget),
    generateScreensCssString(widget, "UserProfileFormWidget", []),
    generateStringDefault(customFields.title_styling),
    generateStageButtonCssString(submitButton),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    checkboxes,
    ...dropdowns,
    ...calendar,
    generateStringDefault(success_message),
    generateIconCssString(success_message.options.iconSettings, "textColor"),
    generateIconSizeCssString(success_message.options.iconSettings, "iconSize"),
    generateFlexContainerCssString(success_message),
    generateCustomSuccessMessageCss(success_message)
  )("");
};
