<template>
  <div
    v-click-outside="handleClickOutside"
    class="create-new-page pointer"
    tabindex="0"
  >
    <input
      ref="input"
      v-model="newPageInputValue"
      placeholder="Page Name"
      class="create-new-page__name-input"
      @blur="handleInputBlur"
      @keyup.enter="handlePageCreateOnEnter($event)"
    />
    <CommonTooltip
      v-if="newPageInputValue.length === 0"
      class="create-new-page__input-tooltip"
      max-width="192px"
      :message="PAGE_NAME_VALIDATION"
    />

    <span
      class="create-new-page__close-button"
      @click="stopCreatingNewPage"
    >
    </span>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";

import { usePagesStore } from "~~/store/pages";
import { PAGE_NAME_VALIDATION } from "~~/constants/name-validations";
import {
  CREATING_PAGE_SUCCESS,
  CREATING_PAGE_ERROR,
} from "~~/constants/notification-messages";
import { handleActionError } from "~~/helpers/handle-action-error";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";

const pagesStore = usePagesStore();

const emit = defineEmits<{
  (e: "update:is-create-new-page", event: boolean): void;
  (e: "close"): void;
}>();

const { isCreatingNewPage, selectedPageId } = storeToRefs(pagesStore);

const input = ref<HTMLInputElement | null>(null);

const newPageInputValue = ref<string>("New Page");

const stopCreatingNewPage = (): void => {
  pagesStore.setIsCreatingNewPage(false);
  emit("close");
};

const handleInputBlur = (event: FocusEvent): void => {
  const inputElement = event.target as HTMLInputElement;
  const relatedTarget = event.relatedTarget as HTMLElement;

  if (relatedTarget?.classList.contains("create-new-page")) {
    (inputElement as HTMLElement).focus();
  }
};

const handleInputFocusAndSelect = (): void => {
  input.value?.focus();
  input.value?.select();
};

const handlePageCreateOnEnter = (event: KeyboardEvent): void => {
  createPage().then(() => {
    const targetElement = event.target as HTMLInputElement;
    targetElement.blur();
  });
};

const handleClickOutside = (): void => {
  if (isCreatingNewPage.value) createPage();
};

const createPage = async (): Promise<void> => {
  const name = newPageInputValue.value;

  pagesStore
    .createPage(name)
    .then(() => {
      handleActionSuccess(CREATING_PAGE_SUCCESS, () => {
        pagesStore.fetchPageContent();
        stopCreatingNewPage();
      });
      useIframePostMessage().sendFullRedirect(
        `/pages/${selectedPageId.value}/setup`
      );
    })
    .catch(error => {
      handleActionError(CREATING_PAGE_ERROR, error, handleInputFocusAndSelect);
    })
    .finally(() => {
      newPageInputValue.value = "New Page";
    });
};

onMounted(() => {
  handleInputFocusAndSelect();
});
</script>

<style lang="scss">
.create-new-page {
  @include flex(center, space-between, 8px);
  padding: $space-s;
  margin: 4px 0;
  border-radius: 8px;
  background-color: $c-light-blue;
  color: $c-primary-base;
  position: relative;

  &__name-input {
    width: 100%;
    padding: 0 8px;
    border: 1px solid transparent;
    background-color: $c-light-blue;

    &:focus {
      outline: 1px solid $c-primary-base;
      border-radius: 4px;
      color: $c-black;
      background-color: $c-white;
    }
  }

  &__close-button {
    pointer-events: all !important;
    position: relative;
    width: 24px;
    height: 24px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 8px;
      width: 1px;
      height: 10px;
      background-color: $c-primary-base;
    }
  }

  &__close-button:before {
    transform: rotate(45deg);
  }

  &__close-button:after {
    transform: rotate(-45deg);
  }

  &__input-tooltip {
    top: 45px;
  }
}
</style>
