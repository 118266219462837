import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 21;

const v21WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["SportsBookPreMatchFenixMobileWidget"].includes(widget.name)) {
    Object.keys(widget.options.bindingParams).forEach(key => {
      if (key !== "pageType" && widget.options.bindingParams[key].required) {
        widget.options.bindingParams[key].required = false;
      }
    });
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v21WidgetMigration;
