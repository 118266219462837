import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { CellSizeType, Sizing } from "~~/models/grid.interface";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGrid,
  generateGap,
  generateFlexDirection,
  generateWrap,
  generateAlignItems,
  generateWidth,
  generateMaxWidth,
  generateTextColor,
  generateFontSize,
  generateMarginStyle,
  generateJustifyContent,
  generatePaddingStyle,
  generateWidgetWidth,
} from "../helpers";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import { getPxValueFromNumber } from "../..";

export const generateHeaderCssString =
  (header: IWidgetField) =>
  (cssString: string): string => {
    const { options } = header;

    const alignment = options.alignment;

    const widgetPositionCssContent = generateFlex({
      flex: "flex",
      align: alignment,
      justify: "space-between",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent + generateDefaultStyles(header.options),
    });

    return cssString;
  };

export const generateLeagueWinnerCardCssString =
  (leagueCard: IWidgetField) =>
  (cssString: string): string => {
    const { options } = leagueCard;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.displaySettings}, minmax(0, 1fr))`,
      rowGap: options.verticalSpacing,
      columnGap: options.verticalSpacing,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        widgetPositionCssContent + generateDefaultStyles(leagueCard.options),
    });

    return cssString;
  };

/* 
  To prevent case when bet bet name flows coef
*/
export const betDefaultSpacingCssString = (): string => {
  return "padding-left:8px;";
};

export const generateLeagueBetItemCssString =
  (leagueBetItem: IWidgetField, allowItemWidth?: boolean) =>
  (cssString: string): string => {
    const { options } = leagueBetItem;

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content: nameCssContent + "overflow: hidden;",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const flexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    const itemWidth = allowItemWidth ? "min-width: 0" : "";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(leagueBetItem.options) +
        flexString +
        "flex: 1; cursor: pointer;" +
        itemWidth,
    });

    cssString += generateDefaultStylesWithStates(leagueBetItem, false);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: "--selected",
      content: generateDefaultStyles(leagueBetItem.options.states.active),
    });

    for (const state in leagueBetItem.options.states) {
      const pseudoClassName = `:${state}`;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: leagueBetItem.options.name._cssClass,
        content: generateDefaultStyles(
          leagueBetItem.options.states[state].name
        ),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: leagueBetItem.options.coef._cssClass,
        content: generateDefaultStyles(
          leagueBetItem.options.states[state].coef
        ),
      });
    }

    if (options._itemSelectedCssClass) {
      cssString += generateCssClassWithContent({
        className: options._itemSelectedCssClass,
        content: generateDefaultStyles(leagueBetItem.options.states.active),
      });
    }

    return cssString;
  };

export const generateListContainerCssString =
  (eventsCard: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventsCard;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.columns}, minmax(0,1fr))`,
      rowGap: options.distanceBetweenCards,
      columnGap: options.distanceBetweenCards,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent + "overflow: hidden;",
    });

    const isHorizontal =
      options.items.displayMode === DisplayOrientation.HORIZONTAL;

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      content:
        generateDefaultStyles(options.card) +
        "display:flex;flex-direction: column;",
    });

    cssString += generateCssClassWithContent({
      className: options.card._headerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "space-between",
        gap: "4",
      }),
    });

    const itemsCssContent = generateFlex({
      flex: "flex",
      direction: isHorizontal ? "row" : "column",
      align: isHorizontal ? "center" : "flex-start",
      justify: isHorizontal ? "flex-start" : "flex-end",
      gap: isHorizontal ? "0" : options.items.distanceBetweenItems,
    });

    const { distanceBetweenItems } = options.items;
    let betItemsCssContent;

    if (options.items.displayMode === DisplayOrientation.HORIZONTAL) {
      betItemsCssContent = generateGrid({
        rowGap: distanceBetweenItems,
        columnGap: distanceBetweenItems,
        templateColumns: `repeat(auto-fit, minmax(calc(33.33334% - ${getPxValueFromNumber(
          distanceBetweenItems
        )}), 1fr))`,
      });
    } else {
      betItemsCssContent = generateFlex({
        flex: "flex",
        direction: "column",
        align: "stretch",
        justify: "flex-start",
        gap: distanceBetweenItems,
      });
    }

    const itemsContainerWidth = generateWidth(100, "%");
    const grow = isHorizontal ? "" : "flex-grow: 1;";
    const marginTop = isHorizontal ? "margin-top: auto;" : "";

    cssString += generateCssClassWithContent({
      className: options.items._cssClass,
      content: itemsCssContent + itemsContainerWidth + grow + marginTop,
    });

    cssString += generateCssClassWithContent({
      className: options.bet_items._cssClass,
      content: betItemsCssContent + itemsContainerWidth,
    });

    cssString += generateCssClassWithContent({
      className: options._itemHeaderCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "space-between",
        gap: "0",
      }),
    });
    return cssString;
  };

export const generateListStartTimeCssString =
  (startTime: IWidgetField, gap = "4", additionalStyles?: string) =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(startTime.options, ["spaceBetween", "padding"]) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          gap: gap,
        }) +
        generateMarginStyle({ right: options.spaceBetween || 0 }) +
        additionalStyles,
    });

    const flexShrink = `flex-shrink: ${
      options.width?.type === CellSizeType.FIXED ? "0" : "initial"
    };`;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content:
        generateWidgetWidth(options.width) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: options.alignment,
          gap,
        }) +
        flexShrink +
        generatePaddingStyle(options.padding),
    });

    return cssString;
  };

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    let widgetStyles = "";

    if (options.contentWidth.type === Sizing.FIXED) {
      widgetStyles = generateWidth(options.contentWidth.width, "px");
    } else {
      widgetStyles += generateWidth(100, "%");
    }

    widgetStyles += generateMaxWidth(`100%`);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateItemMoreStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(
      {
        options: options,
      },
      false,
      [],
      ["size"]
    );

    const paddingStylesString = generateDefaultStyles({
      spacing: options.spacing,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        paddingStylesString +
        generateWidgetWidth(options.width) +
        "text-align: center;",
    });

    return cssString;
  };

export const generateFilterStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const wrap =
      filterOptions.displayMode === DisplayOrientation.HORIZONTAL
        ? "nowrap"
        : "wrap";

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      content:
        generateGap(filterOptions.distance) +
        generateWrap(wrap) +
        generateAlignItems("flex-end") +
        generateFlexDirection("row") +
        generateJustifyContent("flex-start"),
    });

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      pseudoClassName: " li",
      content: "cursor: pointer",
    });

    return cssString;
  };

export const generateFilterItemsStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:not(:nth-child(3)) {flex:1; width:initial;}`;

    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:nth-child(3) {flex:2; width:initial;}`;

    if (filterOptions.displayMode === DisplayOrientation.VERTICAL) {
      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:not(:nth-child(3)) {flex:1; width:calc(50% - (${filterOptions.distance}px / 2));}`;

      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:nth-child(3) {flex:2 1 100%; width:initial;}`;
    }

    return cssString;
  };

export const generateTitleAdditionalStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "flex: 1;",
    });

    return cssString;
  };

export const generateSportsbookWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const header = widget.content["header"];
  const title = widget.content["title"];
  const live_button_title = widget.content["live_button_title"];
  const winner_league_title = widget.content["winner_league_title"];
  const winner_league_card = widget.content["winner_league_card"];
  const winner_league_bet_items = widget.content["winner_league_bet_items"];
  const list_title = widget.content["list_title"];
  const filter = widget.content["filter"];
  const list_container = widget.content["list_container"];
  const list_start_time = widget.content["list_start_time"];
  const league_title = widget.content["league_title"];
  const list_event_name = widget.content["list_event_name"];
  const list_item = widget.content["list_item"];
  const list_item_more = widget.content["list_item_more"];
  const no_events_title = widget.content["no_events_title"];
  const betbuilder_button = widget.content["betbuilder_button"];
  const customFields = widget.options._customFields;

  widget.options._cssClass = generateClassName("Sportsbook");
  header.options._cssClass = generateClassName("header");
  title.options._cssClass = generateClassName("title");
  live_button_title.options._cssClass = generateClassName("live_button_title");
  winner_league_title.options._cssClass = generateClassName(
    "winner_league_title"
  );
  winner_league_card.options._cssClass =
    generateClassName("winner_league_card");

  betbuilder_button.options._cssClass = generateClassName("betbuilder_button");

  /*
    Bet item
  */
  winner_league_bet_items.options._cssClass = generateClassName(
    "winner_league_bet_items"
  );
  winner_league_bet_items.options.name._cssClass = generateClassName(
    "winner_league_bet_items_name"
  );
  winner_league_bet_items.options.coef._cssClass = generateClassName(
    "winner_league_bet_items_coef"
  );

  list_title.options._cssClass = generateClassName("list_title");

  no_events_title.options._cssClass = generateClassName("no_events_title");

  /*
    Form
  */
  filter.options._cssClass = generateClassName("filter");
  filter.options._sectionClass = generateClassName("filter_section");
  filter.options._itemClass = generateClassName("filter_item");
  filter.options._itemBottomClass = generateClassName("filter_item_bottom");

  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");

  // Dropdown class names
  customFields.dropdowns_styling.options._cssClass =
    generateClassName("dropdown");
  customFields.dropdown_menu_styling.options._cssClass =
    generateClassName("dropdownMenu");
  customFields.dropdown_menu_items_styling.options._cssClass =
    generateClassName("dropdownItems");

  // Calendar class names
  customFields.calendar_styling.options._wrapperCssClass =
    generateClassName("calendarWrapper");
  customFields.calendar_styling.options._dayCellCssClass =
    generateClassName("dayCell");

  customFields.calendar_styling.options._navCssClass =
    generateClassName("calendarNav");

  customFields.datepicker_styling.options._datepickerCssClass =
    generateClassName("datepicker");
  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  if (customFields.calendar_styling) {
    // Calendar class names
    customFields.calendar_styling.options._wrapperCssClass =
      generateClassName("calendarWrapper");
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    customFields.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    customFields.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");

    customFields.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  list_container.options._cssClass = generateClassName("list_container");
  list_container.options.card._cssClass = generateClassName("list_card");
  list_container.options.card._headerCssClass =
    generateClassName("list_card_header");
  list_container.options.items._cssClass = generateClassName("list_items");
  list_container.options.bet_items = {};
  list_container.options.bet_items._cssClass =
    generateClassName("list_bet_items");

  list_container.options._itemHeaderCssClass = generateClassName(
    "list_container_item_header"
  );

  list_start_time.options._cssClass = generateClassName("list_start_time");
  list_start_time.options._containerCssClass = generateClassName(
    "list_start_time_container"
  );
  list_start_time.options.icon._cssClass = generateClassName(
    "list_start_time_icon"
  );

  league_title.options._cssClass = generateClassName("league_title");
  list_event_name.options._cssClass = generateClassName("list_event_name");

  list_item.options._cssClass = generateClassName("list_item");
  list_item.options.name._cssClass = generateClassName("list_item_name");
  list_item.options.coef._cssClass = generateClassName("list_item_coef");

  list_item_more.options._cssClass = generateClassName("list_item_more");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateHeaderCssString(header as IWidgetField),
    generateStringWithStates(live_button_title, false),
    generateStringWithStates(betbuilder_button, false),
    generateStringDefault(title),
    generateTitleAdditionalStyles(title.options),
    generateStringDefault(no_events_title),
    generateStringDefault(winner_league_title),
    generateLeagueWinnerCardCssString(winner_league_card as IWidgetField),
    generateLeagueBetItemCssString(winner_league_bet_items as IWidgetField),
    generateStringDefault(list_title),
    generateStringDefault(filter),
    generateListContainerCssString(list_container as IWidgetField),
    generateListStartTimeCssString(list_start_time as IWidgetField),
    generateStringDefault(league_title),
    generateStringDefault(list_event_name),
    generateLeagueBetItemCssString(list_item as IWidgetField, true),
    generateItemMoreStyles(list_item_more.options),

    /*
      Form
    */
    generateFormCssString(filter, widget),
    generateFilterStyles(filter.options),
    generateFilterItemsStyles(filter.options),
    generateTooltipStyling(customFields.tooltips_styling),
    generateIconCssString(customFields.tooltips_styling.options.textChooseIcon),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
