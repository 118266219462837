import { type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidthConfig } from "../common/useWidthConfig";

import { useFavoritesEventsLiveItems } from "./useFavoritesEventsLiveItems";

export const useFavoritesEventsLiveWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  widgetContentItems?: Record<string, any>[]
) => {
  const { isWidthConfigDisabled } = useWidthConfig();
  const { WIDGET_CONTENT_ITEMS } = useFavoritesEventsLiveItems(widget);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetContentItems ?? WIDGET_CONTENT_ITEMS,
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
