<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 config-alignment"
  >
    <p class="m-t-16 m-b-4">{{ label || "Alignment" }}</p>
    <div class="config-alignment__menu">
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            modelValue === VerticalAlignment.TOP,
        }"
        @click="handleAlignmentClick(VerticalAlignment.TOP)"
      >
        <CommonIcon name="custom:vertical-align-top" />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            modelValue === VerticalAlignment.MIDDLE,
        }"
        @click="handleAlignmentClick(VerticalAlignment.MIDDLE)"
      >
        <CommonIcon name="custom:vertical-align-middle" />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            modelValue === VerticalAlignment.BOTTOM,
        }"
        @click="handleAlignmentClick(VerticalAlignment.BOTTOM)"
      >
        <CommonIcon name="custom:vertical-align-bottom" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { VerticalAlignment } from "~~/models/widgets/widget-controls.model";

defineProps<{
  modelValue?: string;
  label?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleAlignmentClick = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-alignment {
  @include controls-menu(33%);
}
</style>
