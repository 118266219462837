<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <ModalWindowHeaderWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="modal-header-widget">
      <div :style="widgetContentStyles">
        <WidgetField
          v-if="fields.title.active"
          :widget="widget"
          :field="fields.title.field"
          class="text-widget__field w-100"
        >
          <BasicTitleField
            :field="fields.title.field"
            :value="fields.title.field.value"
            :state="states.title"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.close_button.field"
          class="button-widget__field"
        >
          <BasicButtonField
            :field="fields.close_button.field"
            :current-state="states.close_button"
          />
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useModalWindowHeaderInitData } from "~~/composables/widgets/modal-window/header/useModalWindowHeaderInitData";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getFlexPosition } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useModalWindowHeaderInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const widgetContentStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    width: "100%",
    alignItems: getFlexPosition(
      props.widget.options.baseDisplaySettings.alignment
    ),
    justifyContent: "flex-start",
    gap: getPxValueFromNumber(props.widget.options.baseDisplaySettings.gap),
  };
});

initFields();
</script>

<style lang="scss">
.modal-header {
  position: relative;
  .button-widget__field {
    margin-left: auto;
  }
}
</style>
