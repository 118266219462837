import { Ref } from "vue";

import { typography } from "~~/constants/configs/text-common/typography-config";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLinkStatesControls } from "~~/composables/widgets/common/useLinkStatesControls";

type Args = {
  field: Ref<IWidgetField | null>;
  state?: Ref<string>;
  label?: string;
  except?: string[];
};

export const useTitleControls = (
  { field, state, label, except }: Args,
  emit?: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const titleControls = computed(() => {
    let els = [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: field.value,
        options: {
          placeholder: "Enter text",
          label: label !== undefined ? label : "Active",
          active: true,
        },
      },
      typography({
        source: field.value,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: field.value,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: field.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];

    if (except) {
      els = els.filter(
        config => !except.includes(config.componentPath as string)
      );
    }
    return els;
  });

  const { titleLinkStatesControls } = useLinkStatesControls(
    { state, field },
    emit
  );

  return { titleControls, titleLinkStatesControls };
};
