import { defineStore } from "pinia";

import { PageIdType } from "~~/models/widgets/widget.core/widget.model";
import { IVersionStore, IVersionResponse } from "~~/models/version-store.model";
import http from "~~/services/http";

export const useVersionStore = defineStore("version", {
  state: (): IVersionStore => ({
    version: null,
    latestVersion: null,
    currPageId: null,
  }),
  getters: {
    isOutdated({ version, latestVersion }): boolean {
      if (typeof version !== "number" || typeof latestVersion !== "number") {
        return false;
      }

      return version < latestVersion;
    },
  },
  actions: {
    updateCurrentVersion(version: number | null): void {
      this.version = version;
    },
    async fetchPageVersion(
      pageId: PageIdType,
      resetVersion?: boolean
    ): Promise<void> {
      if (this.currPageId !== pageId) {
        this.updateCurrentVersion(null);
        this.latestVersion = null;
        this.currPageId = pageId;
      }

      const response: IVersionResponse = await http.get(
        `/pages/get-version/${pageId}`
      );
      const { data } = response;

      /* 
        Save latest fetched version
      */
      this.latestVersion = data.contentVersion;

      /* 
        First fetched version
        will be current version of page.
        This value will be changed in few cases:
        1. When user opens page in Grid App by link(from dashboard)
        2. When user selects different page on sidebar
        3. When method is triggered with resetVersion flag
      */
      if (!this.version || resetVersion) {
        this.updateCurrentVersion(data.contentVersion);
      }
    },
  },
});
