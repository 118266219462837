type AsyncFunction<T> = (ctx: T) => any | Promise<any>;
/*
!!! NOTE! This function can mutate initial state if context will array or object. !!! It is done for performance reasons.

Usage example:
function patchFn1(state) {
  if (state.foo.includes('bar')) {
    state.foo = 'foo';
  }

  return state;
}

function patchFn2(state) {
  if (state.foo.includes('foo')) {
    state.foo = 'foo bar';
  }

  return state;
}

const patchedState = pipe<{ foo: string }>(
  patchFn1,
  patchFn2,
  patchFn3,
  patchFn4,
)(initialState);

NOTE! Each patch function must return a state from its arguments or new state.
 */

export const pipe =
  <T = any>(...valueModificators: AsyncFunction<T>[]) =>
  (value: any): Promise<T> =>
    valueModificators.reduce(
      (currentValue, currentFunction) => currentValue.then(currentFunction),
      Promise.resolve(value)
    );

type SyncFunction<T> = (ctx: T) => T;
export const pipeSync =
  <T = any>(...valueModificators: SyncFunction<T>[]) =>
  (value: any): T =>
    valueModificators.reduce(
      (currentValue, currentFunction) => currentFunction(currentValue),
      value
    );

export const pipeThroughArraySync =
  <T = any>(valueModificators: SyncFunction<T>[]) =>
  (value: any): T =>
    valueModificators.reduce(
      (currentValue, currentFunction) => currentFunction(currentValue),
      value
    );
