<template>
  <div class="common-toggle">
    <a-switch
      v-model:checked="isToggled"
      :size="toggleSize"
      :disabled="isDisabled"
      @change="emitToggle"
    />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["toggle"]);

const props = withDefaults(
  defineProps<{
    isOn: boolean;
    isDisabled?: boolean;
    toggleSize: string;
  }>(),
  {
    isDisabled: false,
  }
);

const isToggled = ref<boolean>(props.isOn);

const emitToggle = () => {
  emit("toggle", isToggled.value);
};
</script>
