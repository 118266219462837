<template>
  <div
    v-if="hasContentPermissions"
    class="widget-control-container config-form-content"
  >
    <ConfigTabs
      v-if="isDefaultStateActive"
      :meta="{ items: [{ label: 'Form Settings', value: 'form' }] }"
    />

    <template v-else-if="activeStateKey === State.FORM_SUCCESS">
      <ConfigImageUpload
        v-model:active="fields.success_image.field.options._active"
        v-model="fields.success_image.field.value"
        label="Image"
        field-name="success_image"
        :meta="{
          dependentFields: [
            fields.success_title.field,
            fields.success_description_1.field,
            fields.success_button.field,
          ],
          emitOptionsClick: true,
          attribute: 'success_image',
        }"
        @options-click="handleFieldOptionsClick(fields.success_image.field!)"
      />

      <ConfigTextInput
        v-model:active="fields.success_title.field.options._active"
        v-model="fields.success_title.field.value"
        label="Title"
        :meta="{
          dependentFields: [
            fields.success_image.field,
            fields.success_description_1.field,
            fields.success_button.field,
          ],
          emitOptionsClick: true,
        }"
        @options-click="handleFieldOptionsClick(fields.success_title.field!)"
      />

      <ConfigTextInput
        v-model:active="fields.success_description_1.field.options._active"
        :model-value="fields.success_description_1.field.value"
        label="Description"
        :meta="{
          customMaxLength: MAX_TEXTAREA,
          dependentFields: [
            fields.success_image.field,
            fields.success_title.field,
            fields.success_button.field,
          ],
          emitOptionsClick: true,
        }"
        @update:model-value="
          handleDescriptionInput($event, fields.success_description_1.field)
        "
        @options-click="
          handleFieldOptionsClick(fields.success_description_1.field!)
        "
      />

      <ConfigTextInput
        v-model:active="fields.success_button.field.options._active"
        v-model="fields.success_button.field.value"
        label="Button"
        :meta="{
          dependentFields: [
            fields.success_image.field,
            fields.success_title.field,
            fields.success_description_1.field,
          ],
          emitOptionsClick: true,
        }"
        @options-click="handleFieldOptionsClick(fields.success_button.field!)"
      />
    </template>
    <template v-else-if="activeStateKey === State.FORM_EXPIRED">
      <ConfigImageUpload
        v-model:active="fields.expired_image.field.options._active"
        v-model="fields.expired_image.field.value"
        label="Image"
        field-name="expired_image"
        :meta="{
          dependentFields: [
            fields.expired_title.field,
            fields.expired_description_1.field,
            fields.expired_button.field,
          ],
          emitOptionsClick: true,
          attribute: 'expired_image',
        }"
        @options-click="handleFieldOptionsClick(fields.expired_image.field!)"
      />

      <ConfigTextInput
        v-model:active="fields.expired_title.field.options._active"
        v-model="fields.expired_title.field.value"
        label="Title"
        :meta="{
          dependentFields: [
            fields.expired_image.field,
            fields.expired_description_1.field,
            fields.expired_button.field,
          ],
          emitOptionsClick: true,
        }"
        @options-click="handleFieldOptionsClick(fields.expired_title.field!)"
      />

      <ConfigTextInput
        v-model:active="fields.expired_description_1.field.options._active"
        :model-value="fields.expired_description_1.field.value"
        label="Description"
        :meta="{
          customMaxLength: MAX_TEXTAREA,
          dependentFields: [
            fields.expired_image.field,
            fields.expired_title.field,
            fields.expired_button.field,
          ],
          emitOptionsClick: true,
        }"
        @update:model-value="
          handleDescriptionInput($event, fields.expired_description_1.field)
        "
        @options-click="
          handleFieldOptionsClick(fields.expired_description_1.field!)
        "
      />

      <ConfigTextInput
        v-model:active="fields.expired_button.field.options._active"
        v-model="fields.expired_button.field.value"
        label="Button"
        :meta="{
          dependentFields: [
            fields.expired_image.field,
            fields.expired_title.field,
            fields.expired_description_1.field,
          ],
          emitOptionsClick: true,
        }"
        @options-click="handleFieldOptionsClick(fields.expired_button.field!)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useStatesConfig } from "~~/components/composables/widgets/useStatesConfig";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { MAX_TEXTAREA } from "~~/constants/input-validation";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const store = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { activeStateKey, isDefaultStateActive } = useStatesConfig(
  selectedWidget.value?.options.states,
  State.FORM_DEFAULT
);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const fields = useWidgetFields(selectedWidget.value!);

const handleDescriptionInput = (event: string, field: IWidgetField): void => {
  store.updateFieldValue(field.id, event);
};

const handleFieldOptionsClick = (field: IWidgetField): void => {
  widgetSettingsStore.setActiveElement(selectedWidget.value, field);
};
</script>
