import { useRoute, useRouter } from "vue-router";

import { useMetaStore } from "~~/store/meta";

export const useProject = () => {
  const route = useRoute();
  const router = useRouter();
  const metaStore = useMetaStore();

  const saveProjectId = () => {
    /* 
      Wait for moment when router has completed the initial
      navigation.
    */
    router.isReady().then(() => {
      const projectId = route.query.project_id;

      if (!projectId) {
        console.error("Project id is not specified in route query params");
        return;
      }

      metaStore.setProjectId(projectId as string);
    });
  };

  const saveInterfaceLang = () => {
    /* 
      Wait for moment when router has completed the initial
      navigation.
    */
    router.isReady().then(() => {
      const interfaceLang = route.query.lang;

      if (!interfaceLang) {
        console.error(
          "Project language is not specified in route query params"
        );
        return;
      }

      metaStore.setInterfaceLang(interfaceLang as string);
    });
  };

  const useHotkeys = (hotkeys: Record<string, Function>) => {
    const keydownHandler = (event: KeyboardEvent) => {
      const keyCombination = `${event.metaKey ? "cmd+" : ""}${
        event.ctrlKey ? "ctrl+" : ""
      }${event.shiftKey ? "shift+" : ""}${
        event.altKey ? "alt+" : ""
      }${event.key.toLowerCase()}`;
      if (hotkeys[keyCombination]) {
        hotkeys[keyCombination]();
      }
    };

    onMounted(() => {
      window.addEventListener("keydown", keydownHandler);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", keydownHandler);
    });
  };

  return {
    saveProjectId,
    saveInterfaceLang,
    useHotkeys,
  };
};
