<template>
  <div
    class="widget-field"
    :class="{
      'widget-field--hovered': isFieldHovered,
      'widget-field--selected': isFieldSelected,
    }"
    @click="handleFieldClick"
    @mouseenter="handleHover"
    @mouseleave="handleLeave"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const widgetSettingsStore = useWidgetSettingsStore();

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  customDropdownValue?: string;
  isCustomHovered?: boolean;
  isCustomSelected?: boolean;
}>();

const emit = defineEmits<{
  (e: "select", value: number | string | null): void;
  (e: "hover", value: number | string | null): void;
  (e: "leave", value: number | string | null): void;
}>();

const { selectedField, hoveredField, selectedDropdownItem } =
  storeToRefs(widgetSettingsStore);

const isFieldHovered = computed<boolean>(() => {
  if (props.isCustomHovered) {
    return true;
  }

  if (!hoveredField.value) {
    return false;
  }

  return hoveredField.value?.id === props.field?.id;
});

const isFieldSelected = computed<boolean>(() => {
  if (props.isCustomSelected) {
    return true;
  }

  if (!selectedField.value) {
    return false;
  }

  return (
    selectedField.value?.id === props.field?.id &&
    (props.customDropdownValue
      ? props.customDropdownValue === selectedDropdownItem.value
      : true)
  );
});

const handleFieldClick = (event: Event): void => {
  event.stopPropagation();

  if (!props.field) {
    return;
  }

  widgetSettingsStore.setActiveElement(
    props.widget,
    props.field,
    undefined,
    props.customDropdownValue
  );
  emit("select", props.field?.id);
};

const handleHover = () => {
  widgetSettingsStore.setHoveredField(props.field);
  emit("hover", props.field?.id);
};

const handleLeave = () => {
  widgetSettingsStore.setHoveredField(null);
  emit("leave", props.field?.id);
};
</script>

<style lang="scss">
.widget-field {
  border: 1px solid transparent;

  // &:not(.widget-field--no-overflow) > * {
  //   overflow: auto;
  // }

  &--hovered,
  &--selected {
    border-color: rgba($c-primary-base, 1);
  }

  &--selected {
    border-width: 2px;
  }
}
</style>
