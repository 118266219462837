import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { INITIAL_STATES } from "~~/constants/widget-details/form/dynamic-fields";

import { useProviderFormFenixInitFieldsOptions } from "./useProviderFormFenixInitFieldsOptions";

export const useProviderFormFenixInitData = (widget: IWidgetWithFields) => {
  const DEFAULT_STATES = {
    ...INITIAL_STATES,
    providers_item: State.DEFAULT,
    title: State.DEFAULT,
    provider_title: State.DEFAULT,
    providers_title: State.DEFAULT,
    provider_card: State.DEFAULT,
    form_back_button: State.DEFAULT,
    providers_item_button: State.DEFAULT,
    providers_item_title: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const { init } = useProviderFormFenixInitFieldsOptions(widget);

  return {
    states,
    DEFAULT_STATES,
    init,
  };
};
