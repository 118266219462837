import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import { useSearchFieldItems } from "~~/composables/widgets/search/field/useSearchFieldItems";
import { useSearchResultBoxControls } from "~~/composables/widgets/search/field/useSearchResultBoxControls";
import { useSearchResultItemsControls } from "~~/composables/widgets/search/field/useSearchResultItemsControls";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useFieldContainerControls } from "./useFieldContainerControls";

export const useSearchFieldConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useSearchFieldItems(widget);
  const {
    fieldContainerDesignControls,
    fieldContainerContentControls,
    fieldContainerStatesControls,
  } = useFieldContainerControls(widget, states, emit);

  const { searchResultBoxControls } = useSearchResultBoxControls(widget);

  const {
    searchResultItemsDesignControls,
    searchResultItemsContentControls,
    searchResultItemsStatesControls,
  } = useSearchResultItemsControls(widget, states, emit);

  /* 
    Widget config
  */

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigCornerRadius"],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.content.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.content.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible:
          widget.value.options.content.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    fieldContainerDesignControls,
    fieldContainerContentControls,
    fieldContainerStatesControls,

    searchResultBoxControls,

    searchResultItemsDesignControls,
    searchResultItemsContentControls,
    searchResultItemsStatesControls,
  };
};
