<template>
  <ConfigHeader>
    <template #buttons>
      <!-- <a-button
        type="primary"
        :disabled="true"
        @click="() => {}"
      >
        Save
      </a-button> -->
      <div
        :class="['config__button-status', { 'non-visible': !allowSavePage }]"
        :title="publishedStatus.text"
      >
        <a-badge
          :status="publishedStatus.color"
          :text="publishedStatus.text"
        />
      </div>

      <a-button
        :class="{
          'config__action-button config__save-button--is-outdated': isOutdated,
        }"
        type="primary"
        class="config__action-button config__save-button"
        :loading="loading"
        :disabled="loading || isWidgetsTemplateLoading || !allowSavePage"
        @click="handleSaveClick"
        >{{ saveText }}
      </a-button>
      <!-- <a-button @click="reset"> Reset </a-button> -->
      <!--
      <a-button
        v-translate:grid="'Preview'"
        type="primary"
        ghost
      /> -->
    </template>

    <template #input>
      <div class="page-name">
        {{ selectedPageName }}

        <CommonIcon
          v-if="hasDesignPermissions"
          class="edit-icon pointer"
          name="ant-design:edit-outlined"
          @click="editPage"
        />
      </div>
    </template>
  </ConfigHeader>
  <LanguageTabs />
  <PageLoader :handler="loadData">
    <div class="widgets-config">
      <ConfigSideMenu>
        <template #title>
          <CommonIcon
            v-if="
              !selectedPageName && activeSideMenuTab === SideMenuTab.CONTENT
            "
            name="ant-design:loading-outlined"
            spin
            class="widgets-config__page-name-loader"
          />
          <h3
            v-if="activeSideMenuTab === SideMenuTab.PAGES"
            class="sub-heading"
          >
            Pages
          </h3>
          <h3
            v-if="activeSideMenuTab === SideMenuTab.TAGS"
            class="sub-heading"
          >
            Tags
          </h3>
          <h3
            v-if="activeSideMenuTab === SideMenuTab.WIDGETS_TREE"
            class="sub-heading"
          >
            Widgets tree
          </h3>
          <a-tooltip
            v-else
            placement="bottom"
          >
            <template #title>
              <span v-if="selectedPageName.length > 20">
                {{ selectedPageName }}
              </span>
              <h3
                v-else
                class="sub-heading overflow-ellipsis widgets-config__selected-page-name-heading"
              >
                {{ selectedPageName }}
              </h3>
            </template>
          </a-tooltip>
        </template>

        <template #content>
          <PagesTab
            v-show="activeSideMenuTab === SideMenuTab.PAGES"
            @select-page="handleSelectPage"
          />

          <AddWidget
            v-show="
              hasDesignPermissions && activeSideMenuTab === SideMenuTab.CONTENT
            "
          />

          <TagsList
            v-show="activeSideMenuTab === SideMenuTab.TAGS"
            :visible="activeSideMenuTab === SideMenuTab.TAGS"
          />

          <WidgetsTreeTab
            v-show="activeSideMenuTab === SideMenuTab.WIDGETS_TREE"
          />

          <div
            v-show="!hasDesignPermissions"
            :style="{
              padding: '16px',
            }"
          >
            You are not allowed to add widgets to workspace
          </div>
        </template>
      </ConfigSideMenu>

      <div
        :class="{
          'widgets-config__content': true,
          'widgets-config__content--readonly':
            activeSideMenuTab === SideMenuTab.PAGES,
        }"
      >
        <CommonSpinner v-if="isWidgetsTemplateLoading" />

        <div
          v-else
          id="grid-container"
          ref="gridContainer"
          class="widgets-config__grid-container"
          @click="handleWidgetsRootClick"
        >
          <WidgetsGrid />
        </div>

        <WidgetSettingsSidebar
          :class="{
            'widgets-config__setting-sidebar': true,
            'widgets-config__setting-sidebar--visible':
              activeSideMenuTab !== SideMenuTab.PAGES,
          }"
        />
      </div>
    </div>

    <ConfigContentToolsModal
      v-model="showContentToolsModal"
      @close="handleContentModalCloseClick"
    />

    <ConfigOutdatedModal
      v-model="showOutdatedModal"
      @save="handleOutdatedModalSaveClick"
      @update="handleOutdatedModalUpdateClick"
      @close="handleOutdatedModalCloseClick"
    />
    <PageConfigDrawer
      v-if="
        pagesStore.selectedPage &&
        Object.keys(languagesStore.availableLanguages).length
      "
    />

    <WidgetTagDrawer />
  </PageLoader>
</template>

<script lang="ts" setup>
import { type LocationQueryValue, useRoute } from "vue-router";

import { SideMenuTab } from "~~/models/side-menu-tab.enum";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useUiStore } from "~~/store/ui";
import { usePagesStore } from "~~/store/pages";
import { useLanguagesStore } from "~~/store/languages";
import { useWidgetsStore } from "~~/store/widgets";
import { Step } from "~~/models/step.enum";
import {
  PAGE_SAVE_ERROR,
  PAGE_SAVE_SUCCESS,
} from "~~/constants/notification-messages";
import { TemplateQueryType } from "~~/models/grid.interface";
import { useProject } from "~~/composables/useProject";
import { useQueueHandler } from "~~/composables/useQueueHandler";
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { handleActionError } from "~~/helpers/handle-action-error";
import { useI18nStaticTranslations } from "~~/composables/translations/useI18nStaticTranslations";
import { TranslationCategory } from "~~/models/stores/static-translation-store.model";
import { useAuthStore } from "~~/store/auth";
import { useVersionStore } from "~~/store/version";
import { PageIdType } from "~~/models/widgets/widget.core/widget.model";
// FIXME: temp change
// const CHECK_PAGE_VERSION_FREQUENCY = 5_000;
const CHECK_PAGE_VERSION_FREQUENCY = 60_000;

const { useHotkeys } = useProject();
const store = useUiStore();
const widgetSettingsStore = useWidgetSettingsStore();
const widgetsStore = useWidgetsStore();
const authStore = useAuthStore();
const gridStore = useGridConfig();
const pagesStore = usePagesStore();
const versionStore = useVersionStore();
const languagesStore = useLanguagesStore();
const loading = ref(false);
const showOutdatedModal = ref(false);
const showContentToolsModal = ref(false);

type Interval = ReturnType<typeof setInterval>;
const checkPageVersionIntervalId = ref<null | Interval>(null);

const route = useRoute();

const saveQueueHandler = useQueueHandler();
const resetQueueHandler = useQueueHandler();

provide("saveQueueHandler", saveQueueHandler);
provide("resetQueueHandler", resetQueueHandler);

const hasDesignPermissions = computed<boolean>(() => {
  return usePermissions().has(usePermissions().Permissions.DESIGN);
});

const allowSavePage = computed<boolean>(() => {
  return pagesStore.allowSavePage;
});

const { activeSideMenuTab } = storeToRefs(store);
const { selectedPageName, isWidgetsTemplateLoading } = storeToRefs(pagesStore);
const gridContainer = ref<HTMLElement | null>(null);

const runVersionCheck = (pageId: PageIdType): void => {
  if (!pageId) {
    return;
  }

  clearInterval(checkPageVersionIntervalId.value as Interval);

  checkPageVersionIntervalId.value = setInterval(() => {
    if (loading.value) {
      return;
    }

    versionStore.fetchPageVersion(pageId);
  }, CHECK_PAGE_VERSION_FREQUENCY);
};

const loadData = async () => {
  pagesStore.setIsWidgetsTemplateLoading(true);

  store.switchTo(Step.WIDGETS);
  gridStore.setTemplateId(route.query.template_id as TemplateQueryType);

  if (route.query.id) {
    versionStore.fetchPageVersion(route.query.id as string);
    await pagesStore.fetchPage(route.query.id, route.query.template_id);
  }

  const pages = await pagesStore.fetchPagesByTemplate(
    route.query.template_id as TemplateQueryType
  );

  pagesStore.setPages(pages);

  await Promise.all([
    gridStore.fetchWidgetsGrid(),
    widgetsStore.fetchSportList(),
  ]);

  await pagesStore.fetchPageContent();
  const activeWidget = gridStore.getWidgetById(
    route.query.active_widget as LocationQueryValue
  );

  if (activeWidget) {
    const screen = gridStore.findScreenByCellId(activeWidget.cell_id);
    store.setScreen(screen);
    widgetSettingsStore.setActiveElement(activeWidget, null);
  }
};

const handleWidgetsRootClick = (e: Event): void => {
  const target = e.target as HTMLElement;
  /*
    Check if click was inside our grid container.
    if yes - we do not need to deselect widget
  */
  const container = target.closest(gridContainer.value?.className || "");
  if (container) {
    return;
  }
  // widgetsStore.setDefaultActiveState();
  /*
    Make sure all events inside sidebar have been
    completed.
    nextTick does not work here, since vue dom updates happen
    earlier than html events(click, for example). So, firstly occurs nextTick,
    then events. That's why we're using setTimeout
  */
  setTimeout(() => {
    widgetSettingsStore.setActiveElement(null, null);
  });
};

const saveDraft = async (): Promise<void> => {
  loading.value = true;
  clearInterval(checkPageVersionIntervalId.value as Interval);

  try {
    await saveQueueHandler.submitQueue();
  } catch (e) {
    console.trace(e);
    handleActionError(PAGE_SAVE_ERROR);
    loading.value = false;
  }

  try {
    await gridStore.saveWidgetsGrid();
    /*
      Looks version is not updated directly
      after page save, so adding setTimeout
    */

    setTimeout(async () => {
      await versionStore.fetchPageVersion(pagesStore.selectedPageId, true);
      runVersionCheck(pagesStore.selectedPageId);
    }, 1000);

    handleActionSuccess(PAGE_SAVE_SUCCESS);
  } catch (e) {
    console.trace(e);
    handleActionError(PAGE_SAVE_ERROR);
    runVersionCheck(pagesStore.selectedPageId);
  } finally {
    loading.value = false;
  }
};

const handleSaveClick = (): void => {
  if (!isOutdated.value) {
    saveDraft();
    return;
  }

  showOutdatedModal.value = true;
};

const handleOutdatedModalSaveClick = (): void => {
  versionStore.updateCurrentVersion(null);
  showOutdatedModal.value = false;
  saveDraft().then(() => {
    versionStore.fetchPageVersion(pagesStore.selectedPageId, true);
  });
};

const handleOutdatedModalUpdateClick = (): void => {
  versionStore.updateCurrentVersion(null);
  showOutdatedModal.value = false;
  pagesStore.fetchPageContent().then(() => {
    versionStore.fetchPageVersion(pagesStore.selectedPageId, true);
  });
};

const handleOutdatedModalCloseClick = (): void => {
  showOutdatedModal.value = false;
};

const handleContentModalOpenClick = (): void => {
  showContentToolsModal.value = true;
};

const handleContentModalCloseClick = (): void => {
  showContentToolsModal.value = false;
};

useHotkeys({
  "ctrl+b": handleContentModalOpenClick,
  "cmd+b": handleContentModalOpenClick,
});

// const reset = async () => {
//   await resetQueueHandler.submitQueue();
// };

const router = useRouter();
const editPage = async (): Promise<void> => {
  router.replace({
    query: {
      ...route.query,
      showPageConfig: "true",
    },
  });
};

const isOutdated = computed<boolean>(() => {
  return versionStore.isOutdated;
});

const saveText = computed<string>(() => {
  if (isOutdated.value) {
    return "Save (Outdated version)";
  }

  return "Save";
});

const publishedStatus = computed<{ text: string; color: string }>(() => {
  switch (pagesStore.selectedPage?.published) {
    case true:
      return {
        text: "Published",
        color: "success",
      };
    case false:
      return {
        text: "Not published",
        color: "error",
      };
    default:
      return {
        text: "Unknown",
        color: "warning",
      };
  }
});

const handleSelectPage = (): void => {
  versionStore.fetchPageVersion(pagesStore.selectedPageId);
};

onMounted(() => {
  authStore.fetchPermissions();
  useI18nStaticTranslations([TranslationCategory.WIDGETS]);
});

onBeforeUnmount(() => {
  clearInterval(checkPageVersionIntervalId.value as Interval);
});

watchEffect(() => {
  runVersionCheck(pagesStore.selectedPageId);
});
</script>

<style lang="scss">
.widgets-config {
  padding: $space-l 0;
  overflow: auto;

  &__grid-container {
    position: relative;
  }

  &__placeholder {
    margin-top: 50px;
  }

  &__element {
    margin-top: 30px;
  }

  &__content {
    &--readonly {
      pointer-events: none;

      .widget-cell {
        &__empty {
          color: $c-grey-02;
        }

        &__item--active {
          border: unset;
        }

        &__item-label,
        &__controls-container,
        &--error,
        &--target {
          display: none;
        }
      }

      .widget-field--selected,
      .base-widget--active,
      .base-widget--selected {
        border: none;
      }

      .base-widget__label {
        display: none;
      }

      .widget-cell--container:not(:last-child),
      .widget-cell__list
        .widget-cell--root
        > .widget-cell:not(:first-child).widget-cell--is-child:first-child,
      .widget-cell--root:not(.widget-cell--has-space-between):not(
          .widget-cell--is-empty
        ).widget-cell--root
        > .widget-cell:not(:last-child),
      .widget-cell--has-margin-top,
      .widget-cell--is-child,
      .widgets-grid__row,
      .widgets-grid {
        border: unset;
      }
    }
  }

  &__page-name-loader {
    color: $c-black;
    margin: 0 auto;
  }

  &__selected-page-name-heading {
    max-width: 150px;
  }

  &__setting-sidebar {
    width: 0;
    transition: width $base-transition;

    &--visible {
      width: $settings-sidebar-width;
    }
  }
}

.config__button-status {
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  .ant-badge-status {
    position: relative;
    left: calc(100% - 1ch);
    transition: 0.3s;
  }
  &:hover {
    .ant-badge-status {
      left: 0;
    }
  }
}

.page-name {
  display: flex;
  align-items: center;
  max-width: 625px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: $c-black;
  white-space: pre; // include whitespaces
}

.edit-icon {
  color: $c-primary-base;
}
</style>
