import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  generateFlexibleImageCssString,
  generateStageButtonCssString,
} from "~~/assets/utils/widget-css/utils/form-helper-functions";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateWidgetWidth } from "../helpers";
import { getFlexPosition } from "../../widget-settings";
import {
  generateStringDefault,
  generateStringWithStates,
  generateCustomStyles,
} from "../utils/pipe-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getPxValueFromNumber } from "../..";

import {
  generateAmountsCssString,
  generateCancelBtnCssString,
  generateCardsCssString,
  generateModalCssString,
  generateProgressCssString,
  generateTabsStyles,
  generateTextCssString,
} from "./bonuses-widget-css";

export const generateStatusAlignCssString =
  (status: IWidgetOptions, cards: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = status;
    const alignment = cards.options.alignment;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: `justify-content: ${getFlexPosition(alignment)}`,
    });

    return cssString;
  };

export const generateBonusGroupCssString =
  (group: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = group;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    const flex = generateFlex({
      flex: "flex",
      align: "normal",
      justify: "flex-start",
      direction: "column",
      gap: options.display.distanceBetweenGroups,
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generateWidgetWidth(options.width) + flex,
    });

    return cssString;
  };

export const generateDescriptionAdditionalCss =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "word-break:break-word;",
    });

    return cssString;
  };

export const generateBonusesFenixCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.tabs.options._containerCssClass =
    generateClassName("tabs_container");

  prefilled.bonus_group.options._containerCssClass = generateClassName(
    "bonus_group_container"
  );

  prefilled.cards.options._containerCssClass =
    generateClassName("cards_container");
  prefilled.cards.options._infoCssClass = generateClassName("info_container");

  prefilled.status.options._containerCssClass = generateClassName(
    "status_primary_container"
  );
  prefilled.status.options._primaryCssClass =
    generateClassName("status_primary");
  prefilled.status.options._secondaryCssClass =
    generateClassName("status_secondary");

  prefilled.dates.options._containerCssClass = generateClassName(
    "dates_primary_container"
  );
  prefilled.dates.options._primaryCssClass = generateClassName("dates_primary");
  prefilled.dates.options._secondaryCssClass =
    generateClassName("dates_secondary");

  prefilled.progress_bar.options._progressCssClass =
    generateClassName("progress_progress");
  prefilled.progress_bar.options._bgCssClass = generateClassName("progress_bg");
  prefilled.progress_bar.options._textCssClass =
    generateClassName("progress_text");

  prefilled.amounts.options._itemCssClass = generateClassName("amounts_item");
  prefilled.amounts.options._primaryCssClass = generateClassName(
    "amounts_item_primary"
  );
  prefilled.amounts.options._secondaryCssClass = generateClassName(
    "amounts_item_secondary"
  );

  prefilled.modal.options._buttonsContainerCssClass = generateClassName(
    "modal_buttons_container"
  );
  prefilled.modal_close_icon.options.iconSettings._cssClass =
    generateClassName("modal_close_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(prefilled.title),
    generateTabsStyles(prefilled.tabs),
    generateCardsCssString(prefilled.cards, prefilled.image),
    generateCustomStyles(prefilled.cards.options._containerCssClass, {
      gap: getPxValueFromNumber(
        prefilled.bonus_group.options.display.distanceBetweenCards
      ),
    }),
    generateBonusGroupCssString(prefilled.bonus_group),
    generateStringDefault(prefilled.bonus_group_title),
    generateStringDefault(prefilled.bonus_group_description),
    generateStringDefault(prefilled.bonus_title),
    generateStringDefault(prefilled.no_active_bonuses),
    generateTextCssString(prefilled.status),
    generateTextCssString(prefilled.dates, true),
    generateStringDefault(prefilled.description),
    generateProgressCssString(prefilled.progress_bar),
    generateAmountsCssString(prefilled.amounts, prefilled.cards),
    generateStringWithStates(prefilled.terms_title, true),
    generateCustomStyles(prefilled.terms_title.options._cssClass, {
      cursor: "pointer",
    }),
    generateStringWithStates(prefilled.action_button, false),
    generateCancelBtnCssString(prefilled.action_button, prefilled.cards),
    generateFlexibleImageCssString(prefilled.image as IWidgetField),
    generateStatusAlignCssString(prefilled.status, prefilled.cards),

    generateStringDefault(prefilled.modal),
    generateStringDefault(prefilled.modal_title),
    generateStringDefault(prefilled.modal_description),
    generateStringWithStates(prefilled.modal_primary_button, false),
    generateStringWithStates(prefilled.modal_secondary_button, false),
    generateModalCssString(prefilled.modal),
    generateStageButtonCssString(prefilled.modal_close_icon as IWidgetField),
    generateDescriptionAdditionalCss(prefilled.bonus_group_description),
    generateDescriptionAdditionalCss(prefilled.description),
    generateDescriptionAdditionalCss(prefilled.bonus_title),
    generateDescriptionAdditionalCss(prefilled.title)
  )("");
};
