<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
  >
    <div
      :style="containerStyles"
      class="sport-event-fenix__header"
    >
      <div
        :style="containerStyles"
        class="sport-event-fenix__left"
      >
        <slot name="left" />
      </div>

      <div>
        <slot name="right" />
      </div>
    </div>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
.sport-event-fenix {
  &__header {
    @include flex(center, space-between);
  }

  &__left {
    @include flex(center, flex-start);
  }
}
</style>
