<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    class="form-dropdown-field"
  >
    <div
      :style="radioElementContainerStyles"
      class="form-radio-button-field__container"
    >
      <div
        v-for="(item, index) in radioOptions"
        :key="index"
        :style="radioElementStyles"
        class="form-radio-button-field"
      >
        <span
          v-if="styleField.options.radio._active"
          :style="radioStyles"
          class="form-radio-button-field__radio"
          :class="{
            'form-radio-button-field__radio--icon-visible':
              iconVisible && index === 0,
          }"
        ></span>
        <span class="form-radio-button-field__text"> {{ item.label }}</span>
      </div>
    </div>
  </FormInputBase>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

type Item = {
  label: string;
  value: string | number;
};

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  states: Record<string, string>;
  items?: Item[];
}>();

const { currentLanguageCode } = useLocalizedValue();

// const defaultValue = computed<string | number>(
//   () => radioOptions.value?.[0]?.value || ""
// );

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.RADIO_GROUP];
});

type RadioEl = {
  value: number | string;
  label: Record<string, string>;
};

const radioOptions = computed<Item[]>(() => {
  if (props.items) {
    return props.items;
  }

  const items = props.field.options?.items || [];

  if (!Array.isArray(items)) {
    return [];
  }

  return (items as RadioEl[]).map(el => {
    return {
      ...el,
      label: el.label[currentLanguageCode.value],
    };
  });
});

const styleField = computed<IWidgetField>(() => {
  return props.stylingFields.find(
    field => field.name === FormStyleFieldName.RADIO_GROUP
  )!;
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (state.value === State.SELECTED_DEFAULT) {
    return styleField.value?.options;
  }

  return styleField.value?.options.states[state.value];
});

const iconVisible = computed<boolean>(() => {
  if (
    state.value === State.SELECTED_DEFAULT ||
    state.value === State.SELECTED_DISABLED ||
    state.value === State.SELECTED_HOVER
  ) {
    return true;
  }

  return false;
});

const radioElementStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...styleField.value.options,
      ...activeStateOptions.value,
    }),
    ...getTextCommonStyles({
      ...styleField.value.options,
      ...activeStateOptions.value,
    }),
    gap: getPxValueFromNumber(styleField.value.options.radio.distance),
  };
});

const radioElementContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(styleField.value.options.display.distance),
  };
});

const radioIconStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...getCommonStyles({
        ...activeStateOptions.value.radio,
        fill: activeStateOptions.value.radio.icon,
      }),
    },
    "icon"
  );
});

const radioStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(activeStateOptions.value.radio),
    width: getPxValueFromNumber(styleField.value.options.size),
    height: getPxValueFromNumber(styleField.value.options.size),
    ...radioIconStyles.value,
  };
});

const {
  isTooltipVisible,
  triggerFieldExample,
  hideFieldExample,
  isErrorVisible,
} = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});
</script>

<style lang="scss">
.form-radio-button-field {
  &__container {
    @include flex(center, flex-start, 0);
    min-width: 0;
    // overflow: hidden;
  }

  &__radio {
    @include flex(center, center, 0);
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &::after {
      display: none;
      content: "";
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: var(--icon-backgroundColor);
    }

    &--icon-visible {
      &::after {
        display: block;
      }
    }
  }

  @include flex(center, flex-start, 0);
}
</style>
