import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useEventBetBuilderConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const betBuilderDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.betbuilder_container.field,
    });
  });

  const betBuilderContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header",
              value: "betbuilder_header",
            },
            {
              label: "Content",
              value: "betbuilder_content_container",
            },
          ],
        },
      },
    ];
  });

  const betBuilderHeaderDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.betbuilder_header.field,
    });
  });

  const betBuilderHeaderContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.betbuilder_header.field,
        options: {
          placeholder: "None",
          showOptions: false,
        },
      },
      typography({
        source: fields.value.betbuilder_header.field,
      }),
      {
        section: generateDesignSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrowSettings",
            valueSource: fields.value.betbuilder_header.field,
            className: "p-t-0",
            options: {
              label: "Fill",
              isBold: true,
              allowDisable: false,
              showOptions: false,
              meta: {
                hideToggle: true,
              },
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.arrowSettings.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: fields.value.betbuilder_header.field,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const statesValuePath = computed<string>(() => {
    if (states.value.betbuilder_header === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.betbuilder_header}`;
  });

  const betBuilderHeaderStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.betbuilder_header,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "betbuilder_header", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: fields.value.betbuilder_header.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: fields.value.betbuilder_header.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: fields.value.betbuilder_header.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: fields.value.betbuilder_header.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.decoration`,
            valueSource: fields.value.betbuilder_header.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.arrowSettings.textColor`,
            valueSource: fields.value.betbuilder_header.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const betBuilderContentContainerDesignControls = computed<ControlProp[]>(
    () => {
      return getInitialDesignList({
        source: fields.value.betbuilder_content_container.field,
      });
    }
  );

  const betBuilderContentContainerContentControls = computed<ControlProp[]>(
    () => {
      return [
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              {
                label: "Description",
                value: "betbuilder_content_description",
              },
              {
                label: "Iframe",
                value: "betbuilder_content_iframe",
              },
            ],
          },
        },
      ];
    }
  );
  return {
    betBuilderDesignControls,
    betBuilderContentControls,

    betBuilderHeaderDesignControls,
    betBuilderHeaderContentControls,
    betBuilderHeaderStatesControls,

    betBuilderContentContainerDesignControls,
    betBuilderContentContainerContentControls,
  };
};
