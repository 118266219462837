<template>
  <div
    class="basic-slider-field"
    :style="containerStyles"
  >
    <template v-if="showArrows">
      <CommonIcon
        class="basic-slider-field__arrow"
        :class="{
          'basic-slider-field__arrow--inside':
            widget.options.sliderArrows.position === 'inside',
        }"
        :style="arrowStyles"
        :name="arrowIcons"
      />

      <CommonIcon
        class="basic-slider-field__arrow basic-slider-field__arrow--right"
        :class="{
          'basic-slider-field__arrow--right-inside':
            widget.options.sliderArrows.position === 'inside',
        }"
        :style="arrowStyles"
        :name="arrowIcons"
      />
    </template>

    <div
      class="basic-slider-field__slides-container"
      :style="slidesContainerStyles"
    >
      <WidgetField
        v-for="(slide, index) in slides"
        :key="slide.id"
        :field="slide"
        :widget="widget"
        class="w-100 basic-slider-field__slide-item"
        :class="{
          'display-none':
            index < visibleSlidesStartIndex || index >= visibleSlidesEndIndex,
        }"
      >
        <slot
          name="slide"
          :slide-field="slide"
          :show-arrows="showArrows"
          :show-pagination="showPagination"
        >
        </slot>
      </WidgetField>
    </div>

    <BasicSliderPagination
      v-if="showPagination"
      class="basic-slider-field__pagination"
      :class="{
        'basic-slider-field__pagination--inside':
          widget.options.sliderPagination.position === 'inside',
      }"
      :index="slidesPagesIndex"
      :size="slides.length"
      :is-stroke="widget.options.sliderPagination.isStroke"
      :color="widget.options.sliderPagination.color"
      :alignment="widget.options.sliderPagination.alignment"
    />
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { generateWidgetField } from "~~/assets/utils/widget";
import { CellSizeType, type ICell } from "~~/models/grid.interface";
import {
  ResizingType,
  SliderItemsPosition,
} from "~~/models/widgets/widget-controls.model";
import type {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import type {
  IPageFieldDetails,
  IPageWidgetDetails,
} from "~~/models/page.model";
import { TEXT_SLIDER_ARROWS_WIDTH } from "~~/constants/configs/text-slider/settings";
import { getColorFromHex } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { useMetaStore } from "~~/store/meta";

import BasicSliderPagination from "./BasicSliderPagination.vue";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const metaStore = useMetaStore();

const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);
const { widgetsDetailsCache } = storeToRefs(widgetsStore);

const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
  () => {
    return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
      props.widget.static_id
    ];
  }
);

const arrayField = computed<IWidgetField | null>(() => {
  return (
    props.widget.fields.find(field => field.name === FieldName.ITEMS) || null
  );
});

const slides = computed<ICustomField[]>(
  () => (arrayField.value?.value as ICustomField[]) || []
);

if (!slides.value.length) {
  arrayField.value!.value = (
    widgetCacheData.value!.fields![arrayField.value!.name].items as {
      fields: IPageFieldDetails[];
    }[]
  ).map(item => ({
    ...generateWidgetField(
      FieldName.SLIDE,
      props.widget.id,
      "",
      "Slide",
      "Slide"
    ),
    fields: item.fields.map(field =>
      generateWidgetField(
        field.name,
        props.widget.id,
        field.type,
        "",
        field.title
      )
    ),
    options: {},
  }));
}

const slidesPagesIndex = computed<number>(() => {
  if (
    slides.value.length === 1 ||
    selectedWidget.value?.id !== props.widget.id ||
    ![
      FieldName.SLIDE,
      FieldName.TITLE,
      FieldName.DESCRIPTION_1,
      FieldName.BUTTON,
      FieldName.BUTTON_1,
      FieldName.BUTTON_2,
      FieldName.BUTTONS_GROUP,
    ].includes(selectedField.value?.name as FieldName)
  )
    return 0;

  return slides.value.findIndex(slide =>
    selectedField.value?.name === FieldName.SLIDE
      ? slide.id === selectedField.value?.id
      : slide.fields?.some(field => field.id === selectedField.value?.id)
  );
});

const visibleSlidesStartIndex = computed<number>(() =>
  slidesPagesIndex.value === slides.value.length - 1
    ? slides.value.length - props.widget.options.columns
    : slidesPagesIndex.value
);
const visibleSlidesEndIndex = computed<number>(() => {
  const endIndex = visibleSlidesStartIndex.value + props.widget.options.columns;
  return endIndex > slides.value.length ? slides.value.length : endIndex;
});

const isFillMode = computed<boolean>(() => {
  return (
    props.widget.options.slideOptions?.size?.height?.type === ResizingType.FILL
  );
});

const containerStyles = computed<CSSProperties>(() => {
  return {
    gridTemplateColumns:
      props.widget.options.sliderArrows.position === SliderItemsPosition.INSIDE
        ? "0 1fr 0"
        : "min-content 1fr min-content",
    justifyContent:
      props.widget.options.size.width.type === CellSizeType.FIXED
        ? "center"
        : "initial",

    gridTemplateRows: isFillMode.value ? "minmax(0, 1fr)" : "min-content",
  };
});

const slidesContainerStyles = computed<CSSProperties>(() => {
  return {
    gap: getPxValueFromNumber(props.widget.options.spaceBetween),
    width: "100%",
  };
});

const showPagination = computed<boolean>(
  () =>
    props.widget.options.sliderPagination.isActive && slides.value.length > 1
);

const showArrows = computed<boolean>(
  () => props.widget.options.sliderArrows.isActive && slides.value.length > 1
);

const arrowIcons = computed<string>(
  () => props.widget.options.sliderArrows.icon
);

const arrowStyles = computed<CSSProperties>(() => ({
  ...getStylesAsVars({
    iconColor: getColorFromHex(props.widget.options.sliderArrows.color) || "",
    width: getPxValueFromNumber(TEXT_SLIDER_ARROWS_WIDTH),
  }),
}));
</script>

<style lang="scss">
.basic-slider-field {
  position: relative;
  display: grid;

  &__slides-container {
    grid-row: 1;
    grid-column: 2;
    // overflow: hidden;
    display: flex;
    justify-self: center;
  }

  &__slide-item {
    flex-grow: 1;
    // overflow: hidden;
    height: 100%;
  }

  &__pagination {
    grid-row: 2;
    grid-column: 1 / 4;

    &--inside {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__arrow {
    align-self: center;
    display: flex;
    justify-content: center;
    grid-row: 1 / 3;
    grid-column: 1;
    color: var(--iconColor);
    width: var(--width);

    &--inside {
      transform: translateX(0);
    }

    &--right {
      grid-column: 3;
      transform: rotate(180deg);
    }

    &--right-inside {
      transform: rotate(180deg) translateX(48px);
    }
  }
}
</style>
