<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #banner_container>
          <div class="p-l-16 p-r-16 p-t-16 p-b-16 config-theme">
            {{ bannerContainerText }}
          </div>
        </template>

        <template #market_cards-design>
          <ControlsList
            :widget="widget"
            :controls="marketCardsDesignControls"
          />
        </template>

        <template #market_cards-content>
          <ControlsList
            :widget="widget"
            :controls="marketCardsContentControls"
          />
        </template>

        <template #market_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="marketSuspendedStatusDesignControls"
          />
        </template>

        <template #market_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="marketSuspendedStatusContentControls"
          />
        </template>

        <template #market_name>
          <ControlsList
            :widget="widget"
            :controls="marketNameControls"
          />
        </template>

        <template #favourite_event_market>
          <ControlsList
            :widget="widget"
            :controls="favouriteEventMarketControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #betbuilder_container-design>
          <ControlsList
            :widget="widget"
            :controls="betBuilderDesignControls"
          />
        </template>

        <template #betbuilder_container-content>
          <ControlsList
            :widget="widget"
            :controls="betBuilderContentControls"
          />
        </template>

        <template #betbuilder_header-design>
          <ControlsList
            :widget="widget"
            :controls="betBuilderHeaderDesignControls"
          />
        </template>

        <template #betbuilder_header-content>
          <ControlsList
            :widget="widget"
            :controls="betBuilderHeaderContentControls"
          />
        </template>

        <template #betbuilder_header-states>
          <ControlsList
            :widget="widget"
            :controls="betBuilderHeaderStatesControls"
          />
        </template>

        <template #betbuilder_content_container-design>
          <ControlsList
            :widget="widget"
            :controls="betBuilderContentContainerDesignControls"
          />
        </template>

        <template #betbuilder_content_container-content>
          <ControlsList
            :widget="widget"
            :controls="betBuilderContentContainerContentControls"
          />
        </template>

        <template #betbuilder_content_iframe>
          <p class="p-l-16 p-t-16 p-r-16 p-b-16">
            The functionality for creating a bet will be added here
          </p>
        </template>

        <template #tabs_container-design>
          <ControlsList
            :widget="widget"
            :controls="tabsContainerDesignControls"
          />
        </template>

        <template #tabs_container-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContainerContentControls"
          />
        </template>

        <template #tabs-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #betbuilder_content_description>
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useEventConfig } from "~~/composables/widgets/1event/useEventConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);
const store = useWidgetsStore();

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "eventId": {
      label: "Event ID",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "eventPageUrl": {
      label: "Event Page URL",
      sources: ["custom"],
      required: true,
    },
    "sportId": {
      label: "Sport ID",
      sources: ["pageRoute"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  titleControls,

  bannerContainerText,

  marketCardsContentControls,
  marketCardsDesignControls,
  marketNameControls,
  favouriteEventMarketControls,

  marketSuspendedStatusDesignControls,
  marketSuspendedStatusContentControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  betBuilderDesignControls,
  betBuilderContentControls,

  betBuilderHeaderDesignControls,
  betBuilderHeaderContentControls,
  betBuilderHeaderStatesControls,

  betBuilderContentContainerDesignControls,
  betBuilderContentContainerContentControls,

  tabsContainerDesignControls,
  tabsContainerContentControls,
  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,
} = useEventConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
