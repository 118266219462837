<template>
  <div class="config-slider-transition wconfig-item m-t-16">
    <ConfigToggle
      v-if="hasContentPermissions"
      size="small"
      label="Auto slide"
      :allow-disable="true"
      :model-value="modelValue.isAuto"
      @update:model-value="handleUpdate('isAuto', $event)"
    />

    <template v-if="modelValue.isAuto && hasDesignPermissions">
      <ConfigHorizontalSpace
        label="Delay"
        class="border-bottom-none p-l-0 p-r-0"
        suffix="sec"
        :model-value="modelValue.delay"
        @update:model-value="handleUpdate('delay', $event)"
      />

      <a-checkbox
        :checked="modelValue.isInfinite"
        @update:checked="handleUpdate('isInfinite', $event)"
      >
        Infinite loop
      </a-checkbox>
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";

interface SliderTransition {
  isAuto: boolean;
  delay: number;
  isInfinite: boolean;
}

const props = defineProps<{
  modelValue: SliderTransition;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: SliderTransition): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleUpdate = (key: keyof SliderTransition, value: number | boolean) => {
  emit("update:modelValue", { ...props.modelValue, [key]: value });
};
</script>
