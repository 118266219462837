import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultIconSettings,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 77;

const generateButton = (name: string) => {
  const initial = BUTTON_INITIAL().options;

  return {
    name: name,
    type: "ButtonLinkField",
    value: "Login",
    options: {
      ...initial,
      padding: getDefaultSpacing({ top: 4, bottom: 4, right: 16, left: 16 }),
      iconSettings: getDefaultIconSettings({ _active: false }),
    },
  };
};

const v77WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "SportEventFenixWidget" &&
    !widget.content.racing_banner_login_button &&
    !widget.content.live_banner_login_button
  ) {
    widget.content.racing_banner_login_button = generateButton(
      "racing_banner_login_button"
    );
    widget.content.live_banner_login_button = generateButton(
      "live_banner_login_button"
    );
  }

  return widget;
};
export default v77WidgetMigration;
