import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 72;

const textField = () => {
  return {
    "name": "bets_result_system_name",
    "type": "UnknownField",
    "value": null,
    "options": {},
  };
};

const v72WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (["BetslipFenixWidget"].includes(widget.name)) {
    if (!("bets_result_system_name" in widget.content)) {
      widget.content.bets_result_system_name = textField();
    }
  }

  return widget;
};
export default v72WidgetMigration;
