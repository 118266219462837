<template>
  <div class="common-search">
    <p
      v-if="label"
      class="f-base common-search__label m-b-4"
    >
      {{ label }}
    </p>

    <div
      ref="container"
      v-click-outside="handleClickOutside"
    >
      <a-input-group>
        <a-dropdown
          :trigger="[]"
          :visible="menuIsVisible"
          :get-popup-container="getPopupContainer"
        >
          <a-tooltip placement="top">
            <template
              v-if="inputValue && inputValue.length > 20"
              #title
            >
              {{ inputValue }}
            </template>
            <a-input
              :placeholder="inputPlaceholder"
              :value="inputValue || currentOption"
              @update:value="$emit('update:inputValue', $event)"
              @focus="handleInputFocus"
            >
              <template #suffix>
                <span class="common-search__icon">
                  <a-spin
                    v-if="loading"
                    :indicator="indicator"
                  />
                  <template v-else>
                    <CommonIcon
                      v-if="menuIsVisible"
                      name="ant-design:search-outlined"
                    />
                    <CommonIcon
                      v-else
                      name="ant-design:down-outlined"
                      @click.stop="handleDropDownClick($event)"
                    />
                    <span
                      v-if="inputValue || currentOption"
                      class="pointer"
                      @click="handleUpdateDropdown(null)"
                    >
                      <CommonIcon name="custom:close-field-icon" />
                    </span>
                  </template>
                </span>
              </template>
            </a-input>
          </a-tooltip>
          <template #overlay>
            <CommonDropdownMenu
              v-if="menuIsVisible && optionsList.length"
              :options="optionsList"
              :model-value="modelValue"
              @update:model-value="handleUpdateDropdown"
            />
          </template>
        </a-dropdown>
      </a-input-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs, h } from "vue";

import {
  DropdownSearchItem,
  ICommonMenuItem,
  OptionValue,
} from "~~/models/common/dropdown.model";
import { isPartOf } from "~~/assets/utils/common/dropdown-search";
import CommonDropdownMenu from "~~/components/common/CommonDropdownMenu.vue";

const props = defineProps<{
  options: ICommonMenuItem[];
  modelValue: DropdownSearchItem | string;
  inputValue: string | null;
  inputPlaceholder: string;
  label?: string;
  allowCustomValue?: boolean;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: DropdownSearchItem): void;
  (e: "update:inputValue", event: OptionValue): void;
  (e: "focus", event: Event): void;
}>();

const { options, inputValue } = toRefs(props);

const container = ref<HTMLElement | null>(null);
const menuIsVisible = ref<boolean>(false);

const indicator = computed(() => {
  return h("CommonIcon", {
    style: {
      fontSize: "12px",
    },
    name: "ant-design:loading-outlined",
    spin: true,
  });
});

const getMatchingOptions = (
  options: ICommonMenuItem[] | undefined = []
): ICommonMenuItem[] => {
  return options.filter(child => {
    return isPartOf(child.label, inputValue.value);
  });
};

/*
  Please note. This computed was created in development
  purposes and most likely will be replaced with API calls.
*/
const optionsList = computed<ICommonMenuItem[]>(() => {
  const result = [];

  for (let i = 0; i < options.value.length; i++) {
    const option = options.value[i];
    const labelMatches = isPartOf(option.label, inputValue.value);

    if (labelMatches) {
      result.push(option);

      continue;
    }

    const filteredChildren = getMatchingOptions(option.children);

    if (!filteredChildren.length) {
      continue;
    }

    result.push({
      ...option,
      children: filteredChildren,
    });
  }

  return result;
});

const currentOption = computed<OptionValue>(() => {
  const activeOption = options.value.find(
    option => option.value === props.modelValue
  );
  return activeOption?.label || null;
});

const handleUpdateDropdown = (
  e: {
    value: DropdownSearchItem;
    label: string;
  } | null
): void => {
  menuIsVisible.value = false;
  emit("update:modelValue", e?.value || null);
  emit("update:inputValue", e?.label || null);
};

const handleInputFocus = (e: Event): void => {
  menuIsVisible.value = true;
  emit("focus", e);
};

/*
  We need to specify dropdown popup container as
  component root element
*/
const getPopupContainer = (trigger: HTMLElement): HTMLElement | null => {
  return trigger.parentElement;
};

/*
  We can not close dropdown on input
  blur, so we need to catch clicks
  outside the whole component
*/
const handleClickOutside = (): void => {
  menuIsVisible.value = false;
};

const handleDropDownClick = (e: Event): void => {
  menuIsVisible.value = true;
  handleInputFocus(e);
};
</script>

<style lang="scss">
.common-search {
  .ant-input-affix-wrapper {
    border-radius: 6px !important;
    height: $space-xl !important;
  }

  &__icon {
    color: $c-grey-25;
    @include flex(center, center, $space-s);
  }

  &__sub-element {
    padding: $space-s 10px;
  }

  .ant-input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
