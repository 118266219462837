<template>
  <BasicSlideContainer
    :style="sizeStyles"
    :slide-field="slideField"
    :slide-state="states.card"
    :widget="widget"
    :show-arrows="showArrows"
    :show-pagination="showPagination"
    :states="states"
    :class="`card-slide_${slideField.options.display.alignment}`"
    class="card-slide"
  >
    <BasicCardFenixWidget
      :widget="widget"
      :field="slideField"
      :states="states"
    />
  </BasicSlideContainer>
</template>

<script setup lang="ts">
import type {
  IWidgetWithFields,
  ICustomField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import BasicCardFenixWidget from "~~/components/fields/basic/BasicCardFenixWidget.vue";
import { getHeightStyles } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  slideField: ICustomField;
  states: Record<string, State>;
  widget: IWidgetWithFields;
  showPagination?: boolean;
  showArrows?: boolean;
}>();

const cardField = ref<InstanceType<typeof BasicCardFenixWidget>>();

defineExpose({
  cardField,
});

const sizeStyles = computed<ElementStyle>(() => {
  const height = props.widget.options.display.height;

  if (!height) {
    return {};
  }

  return {
    ...getHeightStyles(height),
  };
});
</script>

<style lang="scss">
.cards-list-widget {
  .card-slide {
    height: 100%;

    &__card {
      & > div {
        flex: 1;
      }
      .basic-card-field__description {
        flex-grow: initial;
      }
    }

    &_left {
      .basic-card-field {
        align-items: flex-start;
      }

      .basic-card-field__text {
        align-items: flex-start;
      }

      .basic-card-field__buttons-group {
        justify-content: flex-start !important;
      }
    }

    &_center {
      .basic-card-field {
        align-items: center;
      }
      .basic-card-field__text {
        align-items: center;
      }

      .basic-card-field__buttons-group {
        justify-content: center !important;
      }
    }

    &_right {
      .basic-card-field {
        align-items: flex-end;
      }
      .basic-card-field__text {
        align-items: flex-end;
      }

      .basic-card-field__buttons-group {
        justify-content: flex-end !important;
      }
    }
  }
}
</style>
