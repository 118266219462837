<template>
  <div
    :style="textStyles"
    class="sportsbook-live__time"
  >
    <CommonIcon
      v-if="showIcon"
      :style="iconStyles"
      :name="icon || field.options.icon.value"
      class="sportsbook-events__icon"
    />
    <slot>
      <span> 00:00 Period </span>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    field: IWidgetField;
    icon?: string;
    showIcon?: boolean;
    state?: State;
  }>(),
  { showIcon: true }
);

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(
      props.state && props.state !== State.DEFAULT
        ? props.field.options.states[props.state].icon.color
        : props.field.options.icon.color
    ),
    ...getFontSizeStyle(props.field.options.icon.size),
  };
});

const textStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(props.field.options, props.state),
    ...getCommonStyles(props.field.options),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__time {
    @include flex(center, flex-start, 4px);
  }
}
</style>
