<template>
  <WidgetField
    :widget="widget"
    :field="betItemsFeild"
    class="bets-list-fenix-mobile-bet-items"
    :style="containerStyles"
  >
    <div
      v-for="item in items"
      :key="item.name"
      :style="itemStyles"
      class="bets-list-fenix-mobile-bet-items__item"
    >
      <span :style="itemNameStyles">{{ item.name }} </span>

      <span :style="itemCoefStyles">{{ item.coef }}</span>
    </div>
  </WidgetField>
</template>

<script setup lang="ts">
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getDecorationValue,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  isLayoutFirstMode: boolean;
}>();

const fields = useWidgetFields(props.widget);

const items = [
  { name: "1", coef: "0.00" },
  { name: "x", coef: "0.00" },
  { name: "2", coef: "0.00" },
];

const itemStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    flexGrow: 1,
    flexDirection: props.isLayoutFirstMode ? "column" : "row",

    fontFamily: betItemsFeild.value.options.fontFamily,
    ...getCommonStyles(betItemsFeild.value.options, props.states.bet_items),
    alignItems: "center",
    justifyContent: "space-between",
  };
});

const betItemsFeild = computed(() => fields.value.bet_items.field);

const itemNameStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(
      (props.states.bet_items === State.DEFAULT
        ? betItemsFeild.value.options
        : betItemsFeild.value.options.states[props.states.bet_items]
      ).name.color
    ),
    ...getFontSizeStyle(betItemsFeild.value.options.name.theme),
    ...getDecorationValue(betItemsFeild.value.options.name.decoration),
  };
});

const itemCoefStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(
      (props.states.bet_items === State.DEFAULT
        ? betItemsFeild.value.options
        : betItemsFeild.value.options.states[props.states.bet_items]
      ).coef.color
    ),
    ...getFontSizeStyle(betItemsFeild.value.options.coef.theme),
    ...getDecorationValue(betItemsFeild.value.options.coef.decoration),
  };
});

const containerStyles = computed(() => ({
  display: "flex",
  flexDirection:
    betItemsFeild.value.options.layout === "horizontal" ? "row" : "column",
  gap: getPxValueFromNumber(betItemsFeild.value.options.spaceBetween),
}));
</script>

<style lang="scss">
.bets-list-fenix-mobile-bet-items {
}
</style>
