import { Ref } from "vue";

import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

import { IFields } from "../useWidgetFields";

export function useTitleLinkStateControls(
  states: Ref<Record<string, State>>,
  fields?: Ref<IFields>,
  emit?: any,
  customFieldValue?: Ref<IWidgetField>,
  customStateHandler?: (state: State) => void,
  customStatePath?: string
) {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const titleLinkSettingsValuePath = computed(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${states.value[selectedField.value!.name]}`;
  });

  const currentField = computed<IWidgetField>(() => {
    if (fields?.value.title.field) {
      return fields?.value.title.field;
    }

    return customFieldValue!.value;
  });

  const titleLinkStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        if (customStateHandler) {
          customStateHandler(value);
          return;
        }

        if (customStatePath) {
          emit("update-state", { state: customStatePath, value });
          return;
        }

        emit("update-title-link-state", value);
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${titleLinkSettingsValuePath.value}.color`,
      valueSource: currentField.value,
      options: {
        label: "Text color",
        type: ColorPickerType.TEXT,
      },
    },
    {
      componentPath: "ConfigDecoration",
      valuePath: `${titleLinkSettingsValuePath.value}.decoration`,
      valueSource: currentField.value,
      options: {
        label: "Decoration",
      },
    },
  ]);

  return {
    titleLinkStatesControls,
  };
}
