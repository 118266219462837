import { ICellHeight, Dimension } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";

const getFixedHeight = (heightOptions: ICellHeight): string => {
  if (!heightOptions.height) {
    return "";
  }

  if (heightOptions.height?.type === Dimension.CALC) {
    return `calc(${heightOptions.height.value})`;
  }

  return `${heightOptions.height.value}${heightOptions.height?.type}`;
};

export const getCellFixedHeightStyle = (height?: ICellHeight): ElementStyle => {
  if (!height || !height.height) {
    return {
      height: "100%",
      alignSelf: "stretch",
    };
  }

  if (height.isMax) {
    return {
      height: "initial",
      maxHeight: getFixedHeight(height),
      overflow: "auto",
    };
  }

  if (height.isMin) {
    return {
      height: "initial",
      minHeight: getFixedHeight(height),
    };
  }

  return {
    height: getFixedHeight(height),
    overflow: "auto",
  };
};
