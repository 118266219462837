export type OptionValue = string | number | null;

export interface ICommonMenuItem {
  label: string;
  value: OptionValue;
  id: string | number;
  type: SearchDropdownType;
  children?: ICommonMenuItem[];
}

export enum DndContainerId {
  GRID = "grid",
  WIDGETS = "widgets",
  ARRAY_ITEMS = "array-items",
  FORM_ITEMS = "form-items",
  SPORT_ITEMS = "sport-items",
}

export enum SearchDropdownType {
  GROUP = "group",
  CATEGORY = "category",
}

export type DropdownSearchItem = Pick<ICommonMenuItem, "id" | "type"> | null;
