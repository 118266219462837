import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import { prefillWithClasses } from "../utils/prefill-with-classes";

import {
  generateDropdownContainerCssString,
  generateMenuCssString,
  generateMenuItemsCssString,
  generateWidgetCssString,
} from "./languages-widget-css";

export const generateCasinoPromotedCategoriesCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.menu_items.options.icon._cssClass =
    generateClassName("menu_items_icon");
  prefilled.menu_items.options.text._cssClass =
    generateClassName("menu_items_text");

  prefilled.dropdown_container.options.icon._cssClass = generateClassName(
    "dropdown_container_icon"
  );

  prefilled.dropdown_container.options.text._cssClass = generateClassName(
    "dropdown_container_text"
  );

  prefilled.dropdown_container.options.arrow._cssClass = generateClassName(
    "dropdown_container_arrow"
  );

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateDropdownContainerCssString(prefilled.dropdown_container, widget),
    generateMenuCssString(
      prefilled.dropdown_menu,
      prefilled.menu_items,
      widget
    ),
    generateMenuItemsCssString(prefilled.menu_items, widget)
  )("");
};
