import { Ref } from "vue";

import {
  AlignContent,
  AlignItems,
  Alignment,
  DisplayOrientation,
  Grid,
  JustifyContent,
  JustifyItems,
  ResizingType,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultSizeValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { useCarouselInit } from "~~/entities/carousel/composables/useCarouselInit";
import { useArrowsInit } from "~~/entities/carousel/composables/useArrowsInit";
import { usePaginationInit } from "~~/entities/carousel/composables/usePaginationInit";

import {
  CardsDisplayMode,
  EventInfoDirection,
  EventInfoVariant,
} from "../PromoBetsFenixWidgetTypes";

export const usePromoBetsFenixWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(
    [],
    {
      displayMode: CardsDisplayMode.Grid,
      gridSettings: {
        grid: Grid.FIT,
        minCardWidth: 408,
        gridFixedType: Grid.FIT,
        gap: 16,
        rowGap: 16,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      },
      ...useCarouselInit(),
    },
    {
      fill: getDefaultFillValue("#ffffff"),
    }
  );

  const init = (): void => {
    initFields();

    addFieldInitialOptions("arrows", useArrowsInit());
    addFieldInitialOptions("pagination", usePaginationInit());

    addFieldInitialOptions("cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          size: {
            height: getDefaultSizeValue(ResizingType.FILL),
          },
          layoutPosition: {
            position: TextPosition.BOTTOM,
          },
          layoutAlignment: {
            alignItems: AlignItems.STRETCH,
            justifyContent: JustifyContent.STRETCH,
          },
          gap: "8",

          states: {
            [State.HOVER]: getInitialOptions({
              data: DESIGN_INITIAL(),
              exclude: ["cornerRadius", "spacing"],
            }).options,
            [State.ACTIVE]: getInitialOptions({
              data: DESIGN_INITIAL(),
              exclude: ["cornerRadius", "spacing"],
            }).options,
          },
        },
      },
    });

    addFieldInitialOptions("image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
      values: {
        containerSize: {
          width: {
            type: ResizingType.FILL,
            value: 100,
          },
          height: {
            type: ResizingType.FIXED,
            value: 167,
          },
        },
        resizing: {
          type: ResizingType.FILL,
          position: "center center",
          x: 50,
          y: 50,
        },
      },
    });

    addFieldInitialOptions("content", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          layoutAlignment: {
            alignItems: AlignItems.STRETCH,
            justifyContent: JustifyContent.STRETCH,
          },
          alignContent: AlignContent.START,
          gap: "8",
        },
      },
    });

    addFieldInitialOptions("event_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          size: {
            width: getDefaultSizeValue(),
            height: getDefaultSizeValue(),
          },
          variant: EventInfoVariant.Variant1,
          direction: EventInfoDirection.Default,
          layoutAlignment: {
            justifyContent: AlignItems.CENTER,
            alignContent: AlignContent.SPACE_BETWEEN,
            justifyItems: JustifyItems.CENTER,
            alignItems: JustifyContent.START,
          },
          gap: "8",
        },
      },
    });

    addFieldInitialOptions("team_player", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins", "padding"],
      values: {
        theme: "14",
        decoration: ["bold"],
        alignment: Alignment.CENTER,
      },
      additionalData: {
        options: {
          size: {
            height: getDefaultSizeValue(ResizingType.HUG),
          },
          layout: getDefaultSizeValue(),
          horizontalAlignment: Alignment.CENTER,
          icon: {
            _active: true,
            size: "20",
            gap: "8",
            position: TextPosition.BOTTOM,
          },
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        },
      },
    });

    addFieldInitialOptions("date_time", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["alignment", "link", "margins", "padding"],
            values: {
              theme: getDefaultTheme(14),
            },
          }).options,
          displayOrientation: DisplayOrientation.HORIZONTAL,
          gap: "8",
          icon: {
            _active: true,
            valueDate: "ant-design:calendar-filled",
            valueTime: "ant-design:clock-circle-filled",
            color: {
              color: "#69B1FF",
              opacity: 100,
            },
            size: "20",
            gap: "4",
          },
        },
      },
    });

    addFieldInitialOptions("markets", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          displayOrientation: DisplayOrientation.HORIZONTAL,
          gap: "4",
        },
      },
    });

    addFieldInitialOptions("outcomes", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        cornerRadius: getDefaultCornerRadiusValue(4),
        spacing: {
          padding: getDefaultSpacing({
            top: 4,
            right: 8,
            bottom: 4,
            left: 8,
          }),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          displayOrientation: DisplayOrientation.HORIZONTAL,
          gap: "4",
          fontFamily: getDefaultFontFamily(),
          textName: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["fontFamily", "alignment", "link", "margins", "padding"],
            values: {
              theme: getDefaultTheme(12),
              color: getDefaultFillValue("#1677FF"),
            },
          }).options,
          textCoefficient: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["fontFamily", "alignment", "link", "margins", "padding"],
            values: {
              theme: getDefaultTheme(14),
              decoration: ["bold"],
              color: getDefaultFillValue("#1677FF"),
            },
          }).options,

          states: {
            [State.HOVER]: {
              ...getInitialOptions({
                data: DESIGN_INITIAL(),
                exclude: ["cornerRadius", "spacing"],
              }).options,
              textName: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
              textCoefficient: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
            },
            [State.ACTIVE]: {
              ...getInitialOptions({
                data: DESIGN_INITIAL(),
                exclude: ["cornerRadius", "spacing"],
              }).options,
              textName: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
              textCoefficient: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
            },
            [State.DISABLED]: {
              ...getInitialOptions({
                data: DESIGN_INITIAL(),
                exclude: ["cornerRadius", "spacing"],
              }).options,
              textName: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
              textCoefficient: {
                color: {
                  color: "",
                  opacity: 100,
                },
              },
            },
          },
        },
      },
    });

    addFieldInitialOptions("button", {
      data: {
        options: {
          _active: true,
          fill: {
            color: "",
            opacity: 100,
          },
          border: getDefaultBorderValue(),
          shadow: {
            fill: {
              color: "",
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
          cornerRadius: getDefaultCornerRadiusValue(0),
          spacing: {
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
          iconSettings: {
            default_value: "ant-design:arrow-right-outlined",
            textColor: {
              color: "#1677FF",
              opacity: 100,
            },
            iconSize: 16,
            position: TextPosition.RIGHT,
            gap: 5,
            _active: false,
          },
          buttonDisplaySettings: {
            resizing: getDefaultSizeValue(),
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            textColor: getDefaultFillValue("#1677FF"),
            theme: 14,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            _active: true,
          },
          states: {
            [State.HOVER]: {
              fill: {
                color: "",
                opacity: 100,
              },
              border: getDefaultBorderValue(),
              shadow: {
                fill: {
                  color: "",
                  opacity: 100,
                },
                x: 0,
                y: 0,
                blur: 0,
                spread: 0,
              },
              buttonTextSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
              iconSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
            },
            [State.ACTIVE]: {
              fill: {
                color: "",
                opacity: 100,
              },
              border: getDefaultBorderValue(),
              shadow: {
                fill: {
                  color: "",
                  opacity: 100,
                },
                x: 0,
                y: 0,
                blur: 0,
                spread: 0,
              },
              buttonTextSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
              iconSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
            },
            [State.DISABLED]: {
              fill: {
                color: "",
                opacity: 100,
              },
              border: getDefaultBorderValue(),
              shadow: {
                fill: {
                  color: "",
                  opacity: 100,
                },
                x: 0,
                y: 0,
                blur: 0,
                spread: 0,
              },
              buttonTextSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
              iconSettings: {
                textColor: {
                  color: "",
                  opacity: 100,
                },
              },
            },
          },
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    init,
  };
};
