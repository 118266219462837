<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <RacingSportsEventInfoWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="racing-sports-event-live-widget">
      <WidgetField
        :widget="widget"
        :field="fields.iframe_container.field"
      >
        <div class="racing-sports-event-live-widget__iframe">Iframe</div>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.tabs_container.field"
      >
        <RacingSportsButtonTabs
          :container-field="fields.tabs_container.field"
          :tabs-field="fields.tabs.field"
          :widget="widget"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.event_info_container.field"
      >
        <RacingSportsEventInfo :field="fields.event_info_container.field">
          <template #title>
            <WidgetField
              :widget="widget"
              :field="fields.event_info_title.field"
            >
              <BasicTitleField
                :field="fields.event_info_title.field"
                value="00:00 Venue — Race distance"
              />
            </WidgetField>
          </template>

          <template #description>
            <WidgetField
              :widget="widget"
              :field="fields.event_info_description.field"
            >
              <BasicTitleField
                :field="fields.event_info_description.field"
                value="Group stage: conditions for passage of participants to the next stage."
              />
            </WidgetField>
          </template>
        </RacingSportsEventInfo>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.players_info_container.field"
      >
        <RacingSportsPlayersInfo
          :widget="widget"
          :field="fields.players_info_container.field"
          :states="states"
          :card-field="fields.players_info_card.field"
          :header-field="fields.players_info_header.field"
          :number-field="fields.players_info_player_number.field"
          :name-field="fields.players_info_player_name.field"
          :last-races-field="fields.players_info_player_last_score.field"
          :prev-odds-field="fields.players_info_player_prev_odds.field"
          :odds-field="fields.players_info_player_current_odds.field"
          :card-state="states.players_info_card"
        >
        </RacingSportsPlayersInfo>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/racing-sports-live/useRacingSportsLiveInitData";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

initFields();
</script>

<style lang="scss">
.racing-sports-event-live-widget {
  &__iframe {
    color: $c-black;
    background-color: #fff;
    padding: 36px 44px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
}
</style>
