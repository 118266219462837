import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import {
  BorderStrokeType,
  State,
} from "~~/models/widgets/widget-controls.model";

export const CALENDAR_STYLE_INITIAL = () => ({
  title: "Calendar panel style",
  options: {
    _active: true,
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 6),
      stroke: {
        width: 1,
        type: BorderStrokeType.DEPENDENT,
      },
    }),
    contentFill: {
      color: null,
      opacity: 100,
    },
    contentBorder: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 15),
      stroke: {
        width: 2,
        type: BorderStrokeType.DEPENDENT,
      },
    }),
    shadow: {
      x: 0,
      y: 10,
      blur: 20,
      spread: 0,
      fill: {
        color: "#000000",
        opacity: 10,
      },
    },
    cornerRadius: getDefaultCornerRadiusValue(8),
    padding: {
      top: 12,
      bottom: 12,
      left: 20,
      right: 20,
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    weekDaysColor: {
      color: "#000000",
      opacity: 100,
    },
    fontFamily: getDefaultFontFamily(),
    contentCornerRadius: getDefaultCornerRadiusValue(6),
    states: {
      [State.HOVER]: {
        contentFill: {
          color: null,
          opacity: 100,
        },
        contentBorder: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
          stroke: {
            width: 2,
            type: BorderStrokeType.DEPENDENT,
          },
        }),
        color: {
          color: "#000000",
          opacity: 100,
        },
      },
      [State.SELECTED_DEFAULT]: {
        contentFill: {
          color: "#1677FF",
          opacity: 100,
        },
        contentBorder: getDefaultBorderValue(),
        color: {
          color: "#ffffff",
          opacity: 100,
        },
      },
      [State.SELECTED_HOVER]: {
        contentFill: {
          color: "#1677FF",
          opacity: 100,
        },
        contentBorder: getDefaultBorderValue(),
        color: {
          color: "#ffffff",
          opacity: 100,
        },
      },
      [State.INACTIVE]: {
        contentFill: {
          color: null,
          opacity: 100,
        },
        contentBorder: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 25,
        },
      },
      [State.DISABLED]: {
        contentFill: {
          color: null,
          opacity: 100,
        },
        contentBorder: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 25,
        },
      },
    },
  },
});
