<template>
  <div class="sportsbook-live__bet-items">
    <div
      :style="betItemsContainerStyles"
      class="sportsbook-live__items-list"
    >
      <template v-if="state !== ('suspended' as State)">
        <div
          v-for="(inner, index2) in items"
          :key="index2"
          class="sportsbook-live__item"
          :style="betItemContainerStyles"
        >
          <SportsbookBetItem
            :item-value="inner"
            :state-options="currentStateOptions"
            :bet-item-field="field"
            :is-up="state === State.UP"
            :is-down="state === State.DOWN"
            :show-both-arrows-states="[State.ACTIVE, State.HOVER]"
            :state="state"
          />
        </div>
      </template>

      <BasicDesignWrapperField
        v-else
        :field="field"
        :widget="widget"
        state="suspended"
        class="sportbook-live-mobile-fenix__bet-item--disabled"
      >
        <BasicTitleField
          :field="field"
          :options="{
            ...field.options.states.suspended.name,
            alignment: Alignment.CENTER,
          }"
          value="There are no quotas available"
        />
      </BasicDesignWrapperField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  Alignment,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  field: IWidgetField;
  widget: IWidgetWithFields;
  items: {
    name: string;
    coef: string;
  }[];
  state: State;
}>();

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const direction =
    props.field.options.displayMode === DisplayOrientation.HORIZONTAL
      ? "column"
      : "row";

  return {
    gap: getPxValueFromNumber(props.field.options.distance),
    gridAutoFlow: direction,
    ...getCommonStyles({
      margins: props.field.options.containerMargins ?? {},
    }),
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__items-list {
    display: grid;
  }

  &__item {
    min-width: 0;
  }
}
</style>
