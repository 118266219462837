<template>
  <div v-if="hasDesignPermissions">
    <ConfigCheckbox
      :model-value="modelValue?.fixed"
      label="Fix position when scrolling"
      :class="{ 'group-control-element': modelValue?.fixed }"
      @update:model-value="
        $emit('update:model-value', {
          fixed: $event,
          position: modelValue?.position || CellPosition.TOP,
        })
      "
    />

    <ConfigRadioTextElements
      v-if="modelValue?.fixed"
      :model-value="modelValue.position"
      :items="[
        { label: 'Top', value: 'top' },
        { label: 'Bottom', value: 'bottom' },
      ]"
      @update:model-value="
        $emit('update:model-value', {
          fixed: true,
          position: $event,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { CellPosition, ICellPosition } from "~~/models/grid.interface";

defineProps<{
  modelValue?: ICellPosition;
}>();

defineEmits<{
  (e: "update:model-value", event: ICellPosition): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);
</script>
