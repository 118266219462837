<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-spacing widget-control-container p-l-16 p-r-16 p-t-16"
  >
    <h3
      v-if="label"
      class="sub-heading"
    >
      {{ label }}
    </h3>

    <div>
      <ConfigPaddingInputs
        :model-value="modelValue?.padding"
        :is-horizontal="isHorizontalPadding"
        show-tooltip
        :meta="{ isBold }"
        label="Padding"
        class="wconfig-spacing__padding"
        @update:model-value="handleUpdatePadding"
      />

      <ConfigMarginInputs
        :model-value="modelValue?.margin"
        :is-horizontal="isHorizontalMargin"
        is-all-side-setting
        show-tooltip
        :meta="{ isBold }"
        label="Margin"
        class="wconfig-spacing__margin"
        @update:model-value="handleUpdateMargin"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  IVerticalSpacingInput,
  ISpacingInput,
} from "~~/models/widgets/widget-controls.model";

const {
  label = "Spacing",
  isBold = false,
  ...props
} = defineProps<{
  modelValue: {
    padding: ISpacingInput;
    margin: IVerticalSpacingInput;
  };
  label?: string | boolean;
  isBold?: boolean;
  isHorizontalPadding?: boolean;
  isHorizontalMargin?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      padding: ISpacingInput;
      margin: IVerticalSpacingInput;
    }
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdatePadding = (padding: ISpacingInput) => {
  emit("update:modelValue", {
    ...(props.modelValue || {}),
    padding,
  });
};

const handleUpdateMargin = (margin: IVerticalSpacingInput) => {
  emit("update:modelValue", {
    ...(props.modelValue || {}),
    margin,
  });
};
</script>

<style lang="scss">
.wconfig-spacing {
  &__padding,
  &__margin {
    padding: 0 0 $space-m 0;
    margin: 0;
    border: none;
  }

  .sub-heading {
    margin-bottom: $space-m;
  }
}
</style>
