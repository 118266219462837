import {
  DESIGN_INITIAL,
  getDefaultFillValue,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";

export const usePaginationInit = () => {
  return {
    data: DESIGN_INITIAL(),
    values: {
      spacing: {
        padding: getDefaultSpacing(16),
        margin: getDefaultSpacing(0),
      },
    },
    additionalData: {
      options: {
        isStroke: false,
        size: 8,
        color: getDefaultFillValue("#1677FF", 50),
        alignment: Alignment.CENTER,

        states: {
          [State.ACTIVE]: {
            color: getDefaultFillValue("#1677FF"),
            size: 12,
          },
        },
      },
    },
  };
};
