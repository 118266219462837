import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../../compiler/default-css-compiler";
import { prefillWithClasses } from "../../utils/prefill-with-classes";
import { generateStringWithStates } from "../../utils/pipe-helper-functions";

export const generateWidgetStyles = (options: IWidgetOptions) => {
  return (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content: generateDefaultStyles(options),
    });
    return cssString;
  };
};

export const generateSimpleTextWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}_content`);

  const prefilled = prefillWithClasses(widget.content);

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStringWithStates(prefilled.title, true)
  )("");
};
