import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";

export const DROPDOWN_MENU_STYLE_INITIAL = () => ({
  title: "Dropdown menu style",
  options: {
    _active: true,
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000"),
    }),
    shadow: {
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
      fill: {
        color: "",
        opacity: 100,
      },
    },
    cornerRadius: getDefaultCornerRadiusValue(2),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});
