import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Align, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getDefaultAlignValue } from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

import { useWidgetCacheData } from "../common/useWidgetCacheData";

const separatorInitial = () => ({
  options: {
    _active: true,
    color: {
      color: "#000000",
      opacity: 45,
    },
    iconSize: 16,
    value: "ant-design:arrow-right-outlined",
  },
});

export const useBreadcrumbsInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    links: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions([], {
    gap: 8,
    display: {
      alignment: getDefaultAlignValue([Align.START, Align.START]),
    },
    breadcrumbs: [
      {
        title: {
          sources: ["custom"],
          source: "custom",
          label: "Title",
          value:
            useLocalizedValue().transformToLocalizedValue("Breadcrumb Link")
              .localizedValue,
        },
        link: {
          sources: ["custom"],
          source: "custom",
          label: "Url",
          required: true,
          value:
            useLocalizedValue().transformToLocalizedValue("/home")
              .localizedValue,
        },
      },
      {
        title: {
          sources: ["custom"],
          source: "custom",
          label: "Title",
          value:
            useLocalizedValue().transformToLocalizedValue("Breadcrumb Link")
              .localizedValue,
        },
        link: {
          sources: ["custom"],
          source: "custom",
          label: "Url",
          required: true,
          value:
            useLocalizedValue().transformToLocalizedValue("/home")
              .localizedValue,
        },
      },
    ],
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialSeparatorsOptions = prefillEmptyOptions(
      fields.value.separators.field.options,
      getInitialOptions({
        data: separatorInitial(),
      }).options
    );

    const initialLinksOptions = prefillEmptyOptions(
      fields.value.links.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["theme", "alignment", "link"],
        values: {
          theme: 16,
          link: {
            type: null,
            value: null,
            authorizeValue: null,
            isNewTabOpen: null,
            states: {
              [State.HOVER]: {
                color: {
                  color: "#000000",
                  opacity: 100,
                },
                decoration: [],
              },
              [State.ACTIVE]: {
                color: {
                  color: "#000000",
                  opacity: 100,
                },
                decoration: [],
              },
              [State.DISABLED]: {
                color: {
                  color: "#000000",
                  opacity: 100,
                },
                decoration: [],
              },
            },
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.separators.field,
      initialSeparatorsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.links.field,
      initialLinksOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,
    initFields,
    initWidgetFieldsOptions,
  };
};
