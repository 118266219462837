import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import {
  MIN_IMAGE_CONTAINER_SIZE,
  MAX_IMAGE_CONTAINER_SIZE,
} from "~~/constants/widget-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

export const useBonusesPhotoConfig = (
  widget: Ref<IWidgetWithFields>,
  fieldName: string = "image"
) => {
  const fields = useWidgetFields(widget.value);

  const baseImageControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.resizing",
        valueSource: fields.value[fieldName].field,
        className: "group-control-element",
        options: {
          label: "Resizing",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Fit container",
              value: ResizingType.FIT,
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        className: "p-l-16 p-t-16 p-r-16 p-b-16 widget-control-container",
        valuePath: "options.containerSize",
        valueSource: fields.value[fieldName].field,
        options: {
          label: "Photo container size",
          minValue: MIN_IMAGE_CONTAINER_SIZE,
          maxValue: MAX_IMAGE_CONTAINER_SIZE,
        },
      },
    ];
  });

  const imageControls = computed(() => {
    return [
      ...baseImageControls.value,
      ...getInitialDesignList({
        source: fields.value[fieldName].field,
        except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
        elements: [
          [
            4,
            {
              componentPath: "ConfigSpacingInputs",
              valuePath: `options.spacing`,
              valueSource: fields.value[fieldName].field,
              options: {
                placeholder: "None",
                label: "Spacing",
              },
            },
          ],
        ],
      }),
    ];
  });

  return {
    imageControls,
    baseImageControls,
  };
};
