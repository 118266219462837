import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetControls } from "~~/composables/widgets/coming-events/useComingEventsWidgetControls";
import { useItems } from "~~/composables/widgets/coming-events/useComingEventsItems";
import { useBetsListTabsControls } from "~~/composables/widgets/bets-list/useBetsListTabsControls";
import { useComingEventsCardsControls } from "~~/composables/widgets/coming-events/useComingEventsCardsControls";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const { DROPDOWN_ITEMS, TABS } = useItems();

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const { widgetDesignControls, widgetContentControls, widgetDataControls } =
    useWidgetControls(widget, fields);

  const {
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
    arrowDesignControls,
    arrowContentControls,
    arrowStatesControls,
  } = useBetsListTabsControls(statesValuePath, states, emit);

  const {
    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,

    startTimeDateControls,
  } = useComingEventsCardsControls(statesValuePath, states, fields, emit);

  const defaultTextControls = computed<ControlProp[]>(() => {
    return [
      typography({ source: selectedField.value }, "Text"),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const titleControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          isBold: true,
          showOptions: true,
        },
      },
      ...defaultTextControls.value,
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,

    titleControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,

    startTimeDateControls,
    defaultTextControls,

    arrowDesignControls,
    arrowContentControls,
    arrowStatesControls,
  };
};
