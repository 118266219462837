<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
      :options="stateOptions"
    >
      <div
        :style="containerStyles"
        class="sportbook-live-mobile-fenix__title"
      >
        <CommonIcon
          v-if="field.options.icon._active"
          :name="icon"
          :style="iconStyles"
        />

        <BasicTitleField
          :field="field"
          :options="titleOptions"
        >
          <slot />
        </BasicTitleField>

        <WidgetField
          v-if="buttonField.options._active"
          :widget="widget"
          :field="buttonField"
          :style="buttonContainerStyles"
        >
          <BasicButtonField
            :field="buttonField"
            :current-state="buttonState"
          />
        </WidgetField>
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  buttonField: IWidgetField;
  icon: string;
  buttonState: string;
  state: string;
}>();

const titleOptions = computed<IWidgetOptions>(() => {
  return {
    ...props.field.options,
    color: stateOptions.value.color,
    padding: null,
  };
});

const stateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return {
    ...props.field.options,
    ...props.field.options.states[props.state],
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getFontSizeStyle(props.field.options.icon.size),
    ...getColorStyle(stateOptions.value.icon.color),
  };
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});

const buttonContainerStyles = computed<ElementStyle>(() => {
  const options = props.buttonField.options;

  if (options.buttonDisplaySettings.resizing === ResizingType.FILL) {
    return { flex: 1 };
  }

  return {};
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__title {
    @include flex(center, flex-start, 0);
  }
}
</style>
