<template>
  <div>
    <ConfigPaddingInputs
      :model-value="selectedField!.options.padding"
      :meta="{ isBold }"
      @update:model-value="
        $emit('update-field-option', {
          key: 'padding',
          value: $event,
          field: selectedField!,
        })
      "
    />

    <ConfigToggle
      size="small"
      :model-value="selectedField!.options.withTranslations"
      :label="'With Translations'"
      class="p-l-16 p-t-16"
      :allow-disable="true"
      :show-options="false"
      is-field-item-toggle
      @update:model-value="
        $emit('update-field-option', {
          key: 'withTranslations',
          value: $event,
          field: selectedField!,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

defineProps<{
  isBold?: boolean;
}>();

defineEmits<{
  (
    e: "update-field-option",
    event: {
      key: string;
      value: any;
      field: IWidgetField;
    }
  ): void;
}>();

const widgetsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetsStore);
</script>
