import type { Ref } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { Sizing } from "~~/models/grid.interface";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useGridConfig } from "~~/store/grid";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useFormElementConfig } from "~~/composables/widgets/form/useFormElementConfig";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useFormUploadConfig } from "~~/composables/widgets/form/useFormUploadConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import { findCellRootElement } from "~~/assets/utils/grid/grid-tree";
import { ICell } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { FormElementName } from "~~/models/widgets/form.model";

export const useNewFormWidgetConfig = (data: {
  widget: Ref<IWidgetWithFields>;
  activeView: Ref<string>;
  states: Ref<Record<string, string>>;
  emit: any;
  formElementField?: Ref<IWidgetField | null>;
  formsElements: Record<string, string[]>;
  viewsList: { label: string; value: string }[];
}) => {
  const {
    widget,
    activeView,
    states,
    emit,
    formElementField,
    formsElements,
    viewsList,
  } = data;

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetsSettingStore = useWidgetSettingsStore();
  const { selectedCell } = storeToRefs(widgetsSettingStore);
  const gridStore = useGridConfig();

  const rootCell = computed<ICell | null>(() => {
    return findCellRootElement(
      gridStore.currentWidgetsLayout,
      selectedCell.value
    );
  });

  const isWidthConfigDisabled = computed<boolean>(() => {
    if (!rootCell.value) {
      return false;
    }

    return rootCell.value.settings.sizing === Sizing.FIXED;
  });

  const {
    formElementDesignControls,
    formElementContentControls,
    formElementStylesControls,

    formTitleStyles,
    formLabelsStyles,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    formErrorStyles,

    checkboxButtonDesignControls: checkboxButtonDesignControlsToEdit,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,

    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,

    formDatePickerStyles,

    formDropdownStyles,

    formDropdownMenuStyles,

    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,

    successFieldControls,
  } = useFormElementConfig(
    widget,
    states as Ref<Record<string, State>>,
    emit,
    [],
    formElementField,
    formsElements
  );

  const checkboxButtonDesignControls =
    checkboxButtonDesignControlsToEdit.value.filter(
      control => control.valuePath !== "options.display.distance"
    );

  /* 
    Upload
  */

  const {
    formUploadCaptionStyles,
    formUploadListStyles,
    formUploadItemDesignStyles,
    formUploadItemContentStyles,
    formUploadItemStatesStyles,
    uploadButtonDesignControls,
    uploadButtonContentControls,
    uploadButtonStatesControls,
  } = useFormUploadConfig(widget, states as Ref<Record<string, State>>, emit);

  const {
    textFieldControls,
    textInputFieldControls,
    checkboxFieldControls,
    radioGroupFieldControls,
    dropdownFieldControls,
    uploadFieldControls,
  } = useFormContentConfig();

  const buttonState = computed<string>(() => {
    return states.value.ButtonLinkField || "Default";
  });

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: buttonState,
        exclude: {
          content: ["ConfigLinkType"],
        },
        stateHandler: (value: State) => {
          emit("update-state", { state: FormElementName.BUTTON, value });
        },
      },
      emit
    );

  const widgetDesignSettingsControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const formSettingsDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const formContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        options: {
          items: viewsList,
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: `options.states.${activeView.value}.contentWidth`,
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Form Settings",
              value: activeView.value,
            },
          ],
        },
      },
    ];
  });

  const formSettingsContentControls = computed(() => {
    return formContentControls.value;
  });

  return {
    fields,

    /*
      Controls
    */
    widgetDesignSettingsControls,

    formSettingsDesignControls,
    formSettingsContentControls,

    formElementDesignControls,
    formElementContentControls,
    formElementStylesControls,

    /*
      Form elements style
    */
    formTitleStyles,
    formLabelsStyles,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    formErrorStyles,

    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,

    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,

    formDatePickerStyles,

    formDropdownStyles,

    formDropdownMenuStyles,

    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,

    /*
      Form content
    */
    textFieldControls,
    textInputFieldControls,
    checkboxFieldControls,
    radioGroupFieldControls,
    dropdownFieldControls,
    uploadFieldControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,

    /* 
      Upload
    */

    formUploadCaptionStyles,

    formUploadListStyles,

    formUploadItemDesignStyles,
    formUploadItemContentStyles,
    formUploadItemStatesStyles,

    uploadButtonDesignControls,
    uploadButtonContentControls,
    uploadButtonStatesControls,

    successFieldControls,
  };
};
