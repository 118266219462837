import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const useStatesConfig = (
  states: { [key in State]: IWidgetOptions },
  defaultState = State.DEFAULT
) => {
  const activeState = computed<IWidgetOptions>(() => {
    return Object.values(states).find(state => state._active) ?? {};
  });

  const activeStateKey = computed<State | null>(() => {
    /*
    We need to perform this check when we're using this computed value, for example, for the 'states settings' link.
    This is because when the component is mounted, we don't have any 'states',
    we only have them once the user starts styling it.
    */
    if (!states) return null;

    return (
      (Object.keys(states).find(
        key => states[key as State]._active
      ) as State) ?? defaultState
    );
  });

  /*
    This computed should be used to detect whether the state inside the component is 'default'.
    If it is, default values for config settings should be used.
    Otherwise, values for the current state can be found with the help of 'activeStateKey'.
  */
  const isDefaultStateActive = computed<boolean>(() => {
    return activeStateKey.value ? activeStateKey.value === defaultState : true;
  });

  return {
    activeState,
    activeStateKey,
    isDefaultStateActive,
  };
};
