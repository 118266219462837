import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { betDefaultSpacingCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";
import { actionButtonCustomCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-live-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateGrid,
  generateTextColor,
} from "../helpers";
import { getFlexPosition } from "../../widget-settings";

export const generateCardsContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const cardsGrid = generateGrid({
      templateColumns: `repeat(${field.options.displayMode.layout}, minmax(0, 1fr))`,
      columnGap: field.options.displayMode.cardsDistance,
      rowGap: field.options.displayMode.cardsDistance,
    });

    cssString += generateCssClassWithContent({
      className: field.options._wrapperCssClass,
      content:
        generateDefaultStyles({
          spacing: {
            margin: field.options.spacing.margin,
          },
        }) + cardsGrid,
    });

    return cssString;
  };

export const generateCardsCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateDefaultStyles({
        ...field.options,
        spacing: {
          padding: field.options.spacing.padding,
        },
      }),
    });

    return cssString;
  };

export const generateCardHeaderCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { eventInfoLayout } = field.options.displayMode;

    cssString += generateCssClassWithContent({
      className: field.options._headerCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: eventInfoLayout === "row" ? "center" : "flex-start",
          direction: eventInfoLayout === "row" ? "row" : "column",
          justify: "flex-start",
          gap: "8",
        }) +
        generateDefaultStyles({
          spacing: {
            margin: { bottom: 16 },
          },
        }),
    });

    cssString += generateCssClassWithContent({
      className: field.options._detailsCssClass,
      content: generateFlex({
        flex: "flex",
        align: "flex-start",
        direction: "row",
        justify: "flex-start",
        gap: "0",
      }),
    });

    return cssString;
  };

export const generateTimeCssString =
  (startTime: IWidgetField) =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(startTime.options) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          gap: "4",
        }),
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: getFlexPosition(options.alignment),
        gap: "8",
      }),
    });

    return cssString;
  };

export const generateBetItemsContainerCssString =
  (
    betItemsContainerCssClass: string,
    rowContainerCssClass: string,
    field: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = field;

    const isHorizontal =
      options.displayMode.betItemsLayout === DisplayOrientation.HORIZONTAL;

    const betItemsContainerCss = generateGrid({
      gridAutoFlow: isHorizontal ? "column" : "row",
      rowGap: options.displayMode.betItemsDistance,
      columnGap: options.displayMode.betItemsDistance,
      templateColumns: isHorizontal
        ? "repeat(auto-fit, minmax(50px, 1fr));"
        : "",
    });

    const flex = isHorizontal ? "flex: 1;" : "";

    cssString += generateCssClassWithContent({
      className: betItemsContainerCssClass,
      content: betItemsContainerCss + flex,
    });

    cssString += generateCssClassWithContent({
      className: betItemsContainerCssClass,
      pseudoClassName: " span",
      content: "overflow: hidden;text-overflow: ellipsis;white-space: nowrap;",
    });

    const itemFlexString = generateFlex({
      flex: "flex",
      align: isHorizontal ? "center" : "stretch",
      justify: "flex-start",
      gap: isHorizontal ? "0" : options.displayMode.betItemsDistance,
      direction: isHorizontal ? "row" : "column",
    });

    cssString += generateCssClassWithContent({
      className: rowContainerCssClass,
      content: itemFlexString,
    });

    return cssString;
  };

export const generateBetItemsCssString =
  (betItems: IWidgetField) =>
  (cssString: string): string => {
    const { options } = betItems;

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content: nameCssContent + "overflow: hidden;",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const itemFlexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content:
        generateDefaultStyles(options) +
        itemFlexString +
        "min-width: 0px;" +
        "cursor: pointer;",
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      content: generateDefaultStyles(betItems.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      childClassName: betItems.options.coef._cssClass,
      content: generateDefaultStyles(betItems.options.states.active.coef),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      childClassName: betItems.options.name._cssClass,
      content: generateDefaultStyles(betItems.options.states.active.name),
    });

    cssString += generateDefaultStylesWithStates(
      {
        options: {
          ...options,
          _cssClass: options._itemCssClass,
        },
      },
      false,
      ["up", "down"]
    );

    for (const state in betItems.options.states) {
      if (state == "up" || state === "down") {
        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:${state}`,
        childClassName: betItems.options.name._cssClass,
        content: generateDefaultStyles(betItems.options.states[state].name, [
          "theme",
        ]),
      });

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:${state}`,
        childClassName: betItems.options.coef._cssClass,
        content: generateDefaultStyles(betItems.options.states[state].coef, [
          "theme",
        ]),
      });
    }

    return cssString;
  };

export const generateSearchResultsWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("SearchResults");
  widget.options._contentCssClass = generateClassName("SearchResults_content");

  const title = widget.content["title"];
  const no_event_title = widget.content["no_event_title"];
  const event_card = widget.content["event_card"];
  const event_live_status = widget.content["event_live_status"];
  const event_time = widget.content["event_time"];
  const event_title = widget.content["event_title"];
  const bet_items = widget.content["bet_items"];
  const event_button = widget.content["event_button"];

  title.options._cssClass = generateClassName("title");

  no_event_title.options._cssClass = generateClassName("no_event_title");

  event_card.options._cssClass = generateClassName("event_card");
  event_card.options._wrapperCssClass = generateClassName("event_card_wrapper");
  event_card.options._headerCssClass = generateClassName("event_card_header");
  event_card.options._detailsCssClass = generateClassName("event_card_details");

  event_live_status.options._cssClass = generateClassName("event_live_status");

  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options._cssClass = generateClassName("event_time");
  event_time.options.icon._cssClass = generateClassName("event_time_icon");

  event_title.options._cssClass = generateClassName("event_title");

  bet_items.options._containerCssClass = generateClassName(
    "bet_items_container"
  );
  bet_items.options._cssClass = generateClassName("bet_items");
  bet_items.options._itemCssClass = generateClassName("bet_items_item");
  bet_items.options._itemSelectedCssClass = bet_items.options._itemCssClass;

  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");

  event_button.options._cssClass = generateClassName("event_button");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(title),
    generateStringDefault(no_event_title),
    generateStringDefault(event_live_status),
    generateStringDefault(event_title),
    generateCardsContainerCssString(event_card),
    generateCardsCssString(event_card),
    generateCardHeaderCssString(event_card),
    generateTimeCssString(event_time as IWidgetField),
    generateBetItemsContainerCssString(
      bet_items.options._cssClass,
      bet_items.options._containerCssClass,
      event_card
    ),
    generateBetItemsCssString(bet_items as IWidgetField),
    generateStringWithStates(event_button, false),
    actionButtonCustomCssString(event_button as IWidgetField)
  )("");
};
