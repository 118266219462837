<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <TransactionHistoryWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="transaction-history">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="transaction-history__field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :state="states.title"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.tabs_container.field"
      >
        <TransactionHistoryTabs
          :field="fields.tabs_container.field"
          :state="states.tab"
          :items="TAB_ITEMS"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.pending_description.active"
        :widget="widget"
        :field="fields.pending_description.field"
      >
        <BasicTitleField :field="fields.pending_description.field" />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.form.field"
        no-overflow
      >
        <TransactionHistoryFilter
          :widget="widget"
          :field="fields.form.field"
          :fields="filterFields"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.table.field"
        :is-custom-selected="isTableFieldSelected"
      >
        <WidgetTable
          :table-header="TABLE_HEADER"
          :rows="TABLE_CONTENT"
          :field="fields.table.field"
          :header-field="fields.table_header_settings.field"
          :rows-field="fields.table_row_settings.field"
          :hidden="hiddenTableFields"
          :is-empty="states.items === State.NO_ACTIVE"
        >
          <template #reference="data">
            <span class="transaction-history__col-text">
              <span> {{ data.value }} </span>
              <span>
                <CommonIcon name="ant-design:copy-outlined" />
              </span>
            </span>
          </template>

          <template #status="data">
            <span class="transaction-history__col-text">
              <span
                :class="`transaction-history__col-status--${data.value}`"
                class="transaction-history__col-status"
              >
              </span>

              <span> {{ data.value ? "Won Status" : "Lost Status" }} </span>
            </span>
          </template>

          <template #checkbox>
            <div
              :style="checkboxStyles"
              class="transaction-history__checkbox-container"
            >
              <a-checkbox :checked="isCheckboxChecked" />
            </div>
          </template>

          <template #header-checkbox>
            <div
              :style="checkboxStyles"
              class="transaction-history__checkbox-container"
            >
              <a-checkbox :checked="isCheckboxChecked" />
            </div>
          </template>

          <template #empty>
            <BasicTitleField :field="fields.no_events_title.field" />
          </template>
        </WidgetTable>
      </WidgetField>

      <WidgetField
        v-if="fields.action_button.active"
        :widget="widget"
        :field="fields.action_button.field"
      >
        <BasicTextButtonField
          :field="fields.action_button.field"
          :current-state="states.action_button"
          class="transaction-history__cancel-button"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/transaction-history/useTransactionHistoryInitData";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  getBorderStyle,
  getBackgroundColorStyle,
  getColorStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField } = storeToRefs(widgetsStore);

const { t } = useI18n();

const TAB_ITEMS = computed(() => [
  t("widgets.TransactionHistoryWidget.transactions_list"),
  t("widgets.TransactionHistoryWidget.pending_transactions"),
]);

const TABLE_HEADER = ref([
  { label: "", name: "checkbox" },
  { label: t("widgets.TransactionHistoryWidget.date"), name: "date" },
  { label: t("widgets.TransactionHistoryWidget.reference"), name: "reference" },
  {
    label: t("widgets.TransactionHistoryWidget.description"),
    name: "description",
  },
  {
    label: t("widgets.TransactionHistoryWidget.transaction_type"),
    name: "transaction_type",
  },
  { label: t("widgets.TransactionHistoryWidget.status"), name: "status" },
  {
    label: t("widgets.TransactionHistoryWidget.operation_amount"),
    name: "operation_amount",
  },
]);

const TABLE_CONTENT = ref([
  {
    date: "14-07-2023 15:01",
    reference: "Unique transaction id",
    description: "Description of what transaction has happened",
    transaction_type: "Describes the transaction type",
    status: false,
    operation_amount: "+ 1,07",
  },
  {
    date: "14-07-2023 15:01",
    reference: "Unique transaction id",
    description: "Description of what transaction has happened",
    transaction_type: "Describes the transaction type",
    status: true,
    operation_amount: "+ 1,07",
  },
  {
    date: "14-07-2023 15:01",
    reference: "Unique transaction id",
    description: "Description of what transaction has happened",
    transaction_type: "Describes the transaction type",
    status: false,
    operation_amount: "+ 1,07",
  },
]);

const hiddenTableFields = computed<string[]>(() => {
  if (fields.value.table.field.options.mode === "table") {
    return [];
  }

  return ["checkbox"];
});

const {
  states,
  filterFields,
  INITIAL_STATES,

  initFields,
  initWidgetFieldsOptions,
} = useInit(props.widget);

const currentCheckboxStateOptions = computed<IWidgetOptions>(() => {
  if (states.value.table_checkboxes_settings === State.SELECTED_DEFAULT) {
    return fields.value.table_checkboxes_settings.field.options;
  }

  return fields.value.table_checkboxes_settings.field.options.states[
    states.value.table_checkboxes_settings
  ];
});

const checkboxStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...(getBorderStyle(
        currentCheckboxStateOptions.value.border
      ) as ElementStyle),
      ...getBackgroundColorStyle(currentCheckboxStateOptions.value.fill),
      ...getColorStyle(currentCheckboxStateOptions.value.checkIcon.color),
    },
    "checkbox"
  );
});

const handleStateUpdate = (stateDetails: {
  state: string;
  value: State;
}): void => {
  states.value[stateDetails.state] = stateDetails.value;
};

const isCheckboxChecked = computed<boolean>(() => {
  const currState = states.value.table_checkboxes_settings;

  if (
    currState === State.SELECTED_DEFAULT ||
    currState === State.SELECTED_HOVER
  ) {
    return true;
  }

  return false;
});

const isTableFieldSelected = computed<boolean>(() => {
  const tableFields = [
    fields.value.table.field,
    fields.value.table_header_settings.field,
    fields.value.table_row_settings.field,
    fields.value.table_checkboxes_settings.field,
  ];

  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  return (
    tableFields.filter(field => selectedField.value?.id === field.id).length > 0
  );
});

const resetStates = (): void => {
  for (const key in states.value) {
    states.value[key] = INITIAL_STATES[key];
  }
};

initFields();
initWidgetFieldsOptions();
</script>

<style lang="scss">
.transaction-history {
  &__col-text {
    & > span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;

      &:first-child {
        margin-right: 8px;
      }
    }

    .common-icon {
      display: inline;
    }
  }

  &__col-status {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    &--true {
      background-color: #52c41a;
    }

    &--false {
      background-color: #ff4d4f;
    }
  }

  &__checkbox-container {
    .ant-checkbox-inner {
      border-radius: 5px;
      background-color: var(--checkbox-backgroundColor) !important;
      border: none;
      border: var(--checkbox-borderWidth, 0) var(--checkbox-borderStyle)
        var(--checkbox-borderColor, transparent);

      &::after {
        border-color: var(--checkbox-color);
      }
    }

    .ant-checkbox {
      &::after {
        display: none !important;
      }
    }
  }

  &__cancel-button {
    justify-content: flex-start;
  }
}
</style>
