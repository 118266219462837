<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-ff widget-control-container"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="flex items-center gap-4 m-b-4"
    >
      Font Family
      <a-tooltip>
        <template #title>
          Instead of Font Family, you need to choose 1 of the
          {{ FONT_FAMILY_OPTIONS.length }}
          variables that correspond to font families in frontend app.
        </template>

        <CommonIcon name="ant-design:question-circle-outlined" />
      </a-tooltip>
    </p>
    <CommonSelect
      :model-value="currentFontFamily"
      :options="fontFamilyOptions"
      placeholder="Select font family"
      class="m-t-4"
      @update:model-value="handleChange"
    />
  </div>
</template>

<script setup lang="ts">
import type { SelectProps } from "ant-design-vue";

import { usePermissions } from "~~/composables/permissions/usePermissions";
import { FONT_FAMILY_OPTIONS } from "~~/constants/widget-config";
import { SimpleStringControlValue } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: SimpleStringControlValue;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: SimpleStringControlValue): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentFontFamily = computed<SimpleStringControlValue>(() => {
  return props.modelValue;
});

const fontFamilyOptions = ref<SelectProps["options"]>(FONT_FAMILY_OPTIONS);

const handleChange = (value: SimpleStringControlValue): void => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.config-ff {
  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }
}
</style>
