import type { Ref } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useFormDropdownConfig } from "~~/composables/widgets/form/useFormDropdownConfig";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useFormDatePickerConfig } from "~~/composables/widgets/form/useFormDatepickerConfig";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { FormElementName } from "~~/models/widgets/form.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

const shouldBeListed = (
  field: IWidgetField,
  currentFormName?: string,
  formsElements?: Record<string, string[]>
): boolean => {
  if (field.options._hidden) {
    return false;
  }

  if (field.type === FormElementName.HIDDEN) {
    return false;
  }

  if (field.type === "CheckboxField") {
    return false;
  }

  if (formsElements && currentFormName && formsElements[currentFormName]) {
    const customEls = formsElements[currentFormName];
    if (customEls.includes(field.name)) {
      return true;
    }
  }

  if (currentFormName && currentFormName === field.options._parent) {
    return true;
  } else if (currentFormName && field.options._parent) {
    return false;
  }

  if (formsElements && currentFormName) {
    const customEls = formsElements[currentFormName];

    if (!customEls.includes(field.name)) {
      return false;
    }
  }

  if (field.name === "success_message") {
    return true;
  }

  if (field.options._custom) {
    return false;
  }

  if (field.name === "form") {
    return false;
  }

  if (field.name.includes("success")) {
    return false;
  }

  if (field.name.includes("expired")) {
    return false;
  }

  if (field.name.includes("fail")) {
    return false;
  }

  /* 
    Redundant form field
  */
  if (field.type === "CheckboxField") {
    return false;
  }

  return true;
};

export const useFormElementConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any,
  customFields?: IWidgetField[],
  formElementField?: Ref<IWidgetField | null>,
  formsElements?: Record<string, string[]>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);
  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
    successFieldControls,
  } = useFormFieldConfig(widget, states);

  const {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  } = useFormDatePickerConfig(widget, states);

  const currentFormElementField = computed<IWidgetField>(() => {
    if (formElementField?.value) {
      return formElementField.value;
    }

    return fields.value.form?.field;
  });

  const formElementDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigVerticalSpace",
        valuePath: "options.verticalSpace",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Distance between form elements",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentFormElementField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const dividedFields = computed<{
    visible: IWidgetField[];
    hidden: IWidgetField[];
  }>(() => {
    const visible: IWidgetField[] = [];
    const hidden: IWidgetField[] = [];

    widget.value.fields.forEach(field => {
      const listed = shouldBeListed(
        field,
        currentFormElementField.value?.name,
        formsElements
      );

      if (listed) {
        visible.push(field);
        return;
      }

      hidden.push(field);
    });

    return {
      visible,
      hidden,
    };
  });
  const formElementContentControls = computed(() => {
    if (!currentFormElementField.value) {
      return [];
    }

    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentFormElementField.value,
            options: {
              placeholder: "None",
              label: "Distance between form elements",
            },
          },
        ],
      },
      {
        // TODO-imrovement: update if need multiple choice
        section: generateContentSectionName("Captcha"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options._form.captcha.isGoogleRecaptchaEnabled",
            className: "group-control-element--child p-t-16 p-l-16 p-r-16",
            valueSource: currentFormElementField.value,
            options: {
              label: "Enable Google ReCaptcha",
              isFieldItemToggle: false,
              allowDisable: true,
              size: "small",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigFormItems",
        valuePath: "",
        valueSource: null,
        options: {
          fields: dividedFields.value,
          customEditHandler(field: IWidgetField) {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              field,
              undefined,
              field.type
            );
          },
        },
      },
    ];
  });

  const formStylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields);
  });

  const formElementStylesControls = computed(() => {
    const tabItems = formStylesTabs.value;

    const fields = customFields || widget.value.fields;

    const buttons = fields.filter(field => field.type === "ButtonLinkField");

    if (buttons.length === 1) {
      const [submitBtn] = buttons;
      tabItems.push({
        label: "Submit button style",
        value: submitBtn.name,
        customDropdownValue: submitBtn.type,
      });
    }
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: tabItems,
        },
      },
    ];
  });

  const formTitleStyles = computed(() => {
    return [typography({ source: fields.value.title_styling.field })];
  });

  /* 
    Checkbox
  */

  const checkboxButtonDesignControls = computed<ControlProp[]>(() => {
    if (!fields.value.checkboxes_styling?.field) {
      return [];
    }

    return getInitialDesignList({
      // TODO: tmp solution, need to check what is the problem
      source: fields.value.checkboxes_styling?.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.checkboxes_styling?.field,
            options: {
              label: "Padding",
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.checkboxes_styling?.field,
            options: {
              label: "Space between",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const checkboxButtonContentControls = computed<ControlProp[]>(() => {
    if (!fields.value.checkboxes_styling?.field) {
      return [];
    }

    return [
      {
        section: generateContentSectionName("Check"),
        toggleable: true,
        valueSource: fields.value.checkboxes_styling.field.options.check,
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.check.distance",
            valueSource: fields.value.checkboxes_styling.field,
            visible:
              fields.value.checkboxes_styling.field.options.check._active,
            options: {
              label: "Space between text and checkbox",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.size",
            valueSource: fields.value.checkboxes_styling.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.check.fill`,
            valueSource: fields.value.checkboxes_styling.field,
            visible:
              fields.value.checkboxes_styling.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `options.check.border`,
            valueSource: fields.value.checkboxes_styling.field,
            visible:
              fields.value.checkboxes_styling.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.check.icon`,
            valueSource: fields.value.checkboxes_styling.field,
            visible:
              fields.value.checkboxes_styling.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Check icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.checkboxes_styling.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];
  });

  const currentCheckboxButtonStatePath = computed<string>(() => {
    if (states.value.checkboxes_styling === State.SELECTED_DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.checkboxes_styling}`;
  });

  const isCheckboxSelectedState = computed<boolean>(() => {
    return (
      states.value.checkboxes_styling === State.SELECTED_DEFAULT ||
      states.value.checkboxes_styling === State.SELECTED_DISABLED ||
      states.value.checkboxes_styling === State.SELECTED_HOVER
    );
  });

  const checkboxButtonStatesControls = computed<ControlProp[]>(() => {
    if (!fields.value.checkboxes_styling?.field) {
      return [];
    }

    return [
      stateConfig({
        source: states.value.checkboxes_styling,
        statesList: [
          {
            label: STATE_KEY_LABELS.selected_default,
            value: State.SELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.selected_hover,
            value: State.SELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.selected_disabled,
            value: State.SELECTED_DISABLED,
          },
          {
            label: STATE_KEY_LABELS.unselected_default,
            value: State.UNSELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.unselected_hover,
            value: State.UNSELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.unselected_disabled,
            value: State.UNSELECTED_DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "checkboxes_styling", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.checkboxes_styling.field,
        basePath: currentCheckboxButtonStatePath.value,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: "Check",
        visible: fields.value.checkboxes_styling.field.options.check._active,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.fill`,
            valueSource: fields.value.checkboxes_styling.field,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.border`,
            valueSource: fields.value.checkboxes_styling.field,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.icon`,
            valueSource: fields.value.checkboxes_styling.field,
            visible: isCheckboxSelectedState.value,
            options: {
              placeholder: "None",
              label: "Check icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      {
        section: "Text",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.color`,
            valueSource: fields.value.checkboxes_styling.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentCheckboxButtonStatePath.value}.decoration`,
            valueSource: fields.value.checkboxes_styling.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  /* 
    Radiogroup
  */

  const radioButtonDesignControls = computed<ControlProp[]>(() => {
    if (!fields.value.radio_group_styling?.field) {
      return [];
    }

    return getInitialDesignList({
      source: fields.value.radio_group_styling.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.radio_group_styling.field,
            options: {
              label: "Padding",
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.radio_group_styling.field,
            options: {
              label: "Space between",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const isRadioSelectedState = computed<boolean>(() => {
    return (
      states.value.radio_group_styling === State.SELECTED_DEFAULT ||
      states.value.radio_group_styling === State.SELECTED_DISABLED ||
      states.value.radio_group_styling === State.SELECTED_HOVER
    );
  });

  const radioButtonContentControls = computed<ControlProp[]>(() => {
    if (!fields.value.radio_group_styling?.field) {
      return [];
    }

    return [
      {
        section: generateContentSectionName("Radio"),
        toggleable: true,
        valueSource: fields.value.radio_group_styling.field.options.radio,
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.radio.distance",
            valueSource: fields.value.radio_group_styling.field,
            visible:
              fields.value.radio_group_styling.field.options.radio._active,
            options: {
              label: "Space between text and radio",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.size",
            valueSource: fields.value.radio_group_styling.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.radio.fill`,
            valueSource: fields.value.radio_group_styling.field,
            visible:
              fields.value.radio_group_styling.field.options.radio._active,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `options.radio.border`,
            valueSource: fields.value.radio_group_styling.field,
            visible:
              fields.value.radio_group_styling.field.options.radio._active,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.radio.icon`,
            valueSource: fields.value.radio_group_styling.field,
            visible:
              fields.value.radio_group_styling.field.options.radio._active,
            options: {
              placeholder: "None",
              label: "Radio icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.radio_group_styling.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];
  });

  const currentRadioButtonStatePath = computed<string>(() => {
    if (states.value.radio_group_styling === State.SELECTED_DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.radio_group_styling}`;
  });

  const radioState = computed<State>(() => {
    return states.value.radio_group_styling;
  });

  const radioButtonStatesControls = computed<ControlProp[]>(() => {
    if (!fields.value.radio_group_styling?.field) {
      return [];
    }

    return [
      stateConfig({
        source: radioState.value,
        statesList: [
          {
            label: STATE_KEY_LABELS.selected_default,
            value: State.SELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.selected_hover,
            value: State.SELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.selected_disabled,
            value: State.SELECTED_DISABLED,
          },
          {
            label: STATE_KEY_LABELS.unselected_default,
            value: State.UNSELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.unselected_hover,
            value: State.UNSELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.unselected_disabled,
            value: State.UNSELECTED_DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "radio_group_styling", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.radio_group_styling.field,
        basePath: currentRadioButtonStatePath.value,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: "Radio",
        visible: fields.value.radio_group_styling.field.options.radio._active,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentRadioButtonStatePath.value}.radio.fill`,
            valueSource: fields.value.radio_group_styling.field,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${currentRadioButtonStatePath.value}.radio.border`,
            valueSource: fields.value.radio_group_styling.field,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentRadioButtonStatePath.value}.radio.icon`,
            valueSource: fields.value.radio_group_styling.field,
            visible: isRadioSelectedState.value,
            options: {
              placeholder: "None",
              label: "Radio icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      {
        section: "Text",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentRadioButtonStatePath.value}.color`,
            valueSource: fields.value.radio_group_styling.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentRadioButtonStatePath.value}.decoration`,
            valueSource: fields.value.radio_group_styling.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  return {
    FORM_ELEMENTS_STYLE_TABS,

    formElementDesignControls,
    formElementContentControls,
    formElementStylesControls,

    dividedFields,

    /* 
      Form elements styles
    */
    formTitleStyles,
    formLabelsStyles,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    formErrorStyles,

    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,

    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,

    formDatePickerStyles,

    formDropdownStyles,

    formDropdownMenuStyles,

    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,

    successFieldControls,
  };
};
