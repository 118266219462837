import { Ref } from "vue";

import {
  PROFILE_DROPDOWN_WIDGET_AUTHORIZED_FIELDS,
  PROFILE_DROPDOWN_WIDGET_HIDDEN_FIELDS,
} from "~~/constants/widget-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

const shouldBeListed = (field: IWidgetField, widgetState: State): boolean => {
  if (PROFILE_DROPDOWN_WIDGET_HIDDEN_FIELDS.includes(field.name as FieldName)) {
    return false;
  }

  // Need this complicated logic to show items for current state
  const isAuthorizedInclude =
    PROFILE_DROPDOWN_WIDGET_AUTHORIZED_FIELDS.includes(field.name as FieldName);
  if (
    (widgetState === State.AUTHORIZED && !isAuthorizedInclude) ||
    (widgetState === State.UNAUTHORIZED && isAuthorizedInclude)
  ) {
    return false;
  }

  return true;
};

export const useProfileDropdownWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  widgetState: Ref<State>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigCornerRadius"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const dividedFields = computed<{
    visible: IWidgetField[];
    hidden: IWidgetField[];
  }>(() => {
    const visible: IWidgetField[] = [];
    const hidden: IWidgetField[] = [];

    widget.value.fields.forEach(field => {
      const listed = shouldBeListed(field, widgetState.value);

      if (listed) {
        visible.push(field);
        return;
      }

      hidden.push(field);
    });

    return {
      visible,
      hidden,
    };
  });

  const widgetSettingsValuePath = computed<string>(() => {
    if (widgetState.value === State.AUTHORIZED) {
      return "options";
    }

    return `options.authStates.${widgetState.value}`;
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valueSource: widgetState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.authorized,
              value: State.AUTHORIZED,
            },
            {
              label: STATE_KEY_LABELS.unauthorized,
              value: State.UNAUTHORIZED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-widget-state", value);
        },
      },
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayOrientation",
            valuePath: `${widgetSettingsValuePath.value}.profileDropdownDisplay.layout`,
            valueSource: widget.value,
            options: {
              isBold: false,
            },
          },
          {
            componentPath: "ConfigButtonDisplaySettings",
            valuePath: `${widgetSettingsValuePath.value}.profileDropdownDisplay`,
            valueSource: widget.value,
            options: {
              showTitle: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: `${widgetSettingsValuePath.value}.profileDropdownDisplay.distanceBetweenItems`,
            valueSource: widget.value,
            className: "group-control-element",
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigFormItems",
        options: {
          fields: dividedFields.value,
          readonlyFieldNames: [FieldName.BALANCE],
          label: "Elements",
          // Need this logic to trigger dropdown and balance fields
          customEditHandler(field: IWidgetField) {
            const fieldToCustomFieldName: Record<string, string> = {
              [FieldName.BALANCE]: "balanceContainer",
              [FieldName.DROPDOWN]: "dropdownContainer",
            };

            const customFieldName =
              fieldToCustomFieldName[field.name] || field.name;

            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              field,
              undefined,
              customFieldName
            );
          },
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
