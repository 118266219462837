import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

const SETTINGS_ITEMS = [
  {
    label: "Game cards",
    value: "game_cards",
  },
];

export const useCasinoListItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Game cards", "game_cards"),
    generateDropdownItem("Hover content", "hover_content"),
    generateDropdownItem("Title", "game_title", "hoverContent"),
    generateDropdownItem(
      "Link States",
      "gameTitleLinkStates",
      "gameTitleLinkStates"
    ),
    generateDropdownItem(
      "Play for money button",
      "play_money_button",
      "hoverContent"
    ),
    generateDropdownItem(
      "Play for free button",
      "play_free_button",
      "hoverContent"
    ),
    generateDropdownItem("Photo", "photo", "cardsElements"),
    generateDropdownItem("Cards elements", "cards_elements"),
    generateDropdownItem("Promo tag", "promo_tag", "cardsElements"),
    generateDropdownItem("Favorite icon", "favorite_icon", "cardsElements"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    game_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_tag: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_money_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_free_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
