import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import {
  TITLE_INITIAL,
  ITEMS_GROUP_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useSearchFieldInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    field_container: State.DEFAULT,
    search_result_items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(
    [],
    {
      bindingParams: {},
      content: {
        contentWidth: {
          type: Sizing.ADAPTIVE,
          width: 160,
          alignment: Alignment.CENTER,
        },
      },
    },
    {
      fill: getDefaultFillValue("#000000"),
      spacing: {
        padding: getDefaultSpacing(12),
      },
    }
  );

  /*
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.content.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.content.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.content.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }
    }
    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialFieldContainerState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: getDefaultFillValue("#000000"),
        },
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
        placeholder: {
          color: getDefaultFillValue("#C7C7C7"),
        },
      };
    };

    const initialFieldContainerOptions = prefillEmptyOptions(
      fields.value.field_container.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["arrow"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          text: {
            _active: true,
            color: getDefaultFillValue("#494949"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: null,
            value: "Request example",
          },
          icon: {
            value: "ant-design:search-outlined",
            textColor: {
              color: "#000000",
              opacity: 100,
            },
            _active: true,
          },
          states: {
            [State.HOVER]: initialFieldContainerState(),
            [State.ACTIVE]: initialFieldContainerState(),
            [State.DISABLED]: initialFieldContainerState(),
          },
        },
        additionalData: {
          options: {
            placeholder: {
              _active: true,
              value: "Search",
              color: getDefaultFillValue("#C7C7C7"),
            },
          },
        },
      }).options
    );

    const initialSearchResultBoxOptions = prefillEmptyOptions(
      fields.value.search_result_box.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["display", "size"],
        values: {
          fill: getDefaultFillValue("#FFFFFF"),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 6),
          }),
          shadow: {
            fill: getDefaultFillValue("#000000", 10),
            x: 0,
            y: 10,
            blur: 20,
            spread: 0,
          },
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            padding: getDefaultSpacing(4),
            margin: getDefaultSpacing({
              top: 4,
            }),
          },
        },
      }).options
    );

    const initialSearchResultItemsState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: getDefaultFillValue("#000000"),
        },
      };
    };

    // Default value for Search item
    fields.value.search_result_items.field.value =
      useLocalizedValue().transformToLocalizedValue(
        "Search item"
      ).localizedValue;

    const initialSearchResultItemsOptions = prefillEmptyOptions(
      fields.value.search_result_items.field.options,
      getInitialOptions({
        data: ITEMS_GROUP_INITIAL(),
        exclude: ["itemsIcon", "margins"],
        values: {
          fill: getDefaultFillValue(),
          spaceBetween: 4,
          itemsText: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: null,
          },
          states: {
            [State.HOVER]: initialSearchResultItemsState(),
            [State.ACTIVE]: initialSearchResultItemsState(),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.field_container.field,
      initialFieldContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_result_box.field,
      initialSearchResultBoxOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_result_items.field,
      initialSearchResultItemsOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
