<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
    >
      <div
        class="sportbook-live-mobile-fenix__event-info"
        :style="containerStyles"
        :class="{
          'sportbook-live-mobile-fenix__event-info--horizontal':
            props.field.options.display.layout ===
            DisplayOrientation.HORIZONTAL,
        }"
      >
        <div class="sportbook-live-mobile-fenix__event-info-element">
          <WidgetField
            :widget="widget"
            :field="eventNameField"
            class="sportbook-live-mobile-fenix__event-name"
          >
            <BasicTitleField
              :field="eventNameField"
              value="Team / Player #1"
            />
          </WidgetField>

          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sportbook-live-mobile-fenix__event-info-score"
              :style="scoreStyles"
              >0</span
            >
          </WidgetField>
        </div>

        <div class="sportbook-live-mobile-fenix__event-info-element">
          <WidgetField
            :widget="widget"
            :field="eventNameField"
            class="sportbook-live-mobile-fenix__event-name"
          >
            <BasicTitleField
              :field="eventNameField"
              :options="reversedNameOptions"
              value="Team / Player #2"
            />
          </WidgetField>

          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sportbook-live-mobile-fenix__event-info-score"
              :style="scoreStyles"
              >0</span
            >
          </WidgetField>
        </div>
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  Alignment,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  eventNameField: IWidgetField;
  eventScoreField: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  const layout = props.field.options.display.layout;
  const gap = props.field.options.display.distance;

  if (layout === DisplayOrientation.VERTICAL) {
    return {
      flexDirection: "column",
      gap: getPxValueFromNumber(gap),
    };
  }

  return {
    flexDirection: "row",
    gap: getPxValueFromNumber(gap),
    justifyContent: "center",
  };
});

const scoreStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(props.eventScoreField.options),
    ...getCommonStyles(props.eventScoreField.options),
  };
});

const reversedNameOptions = computed<IWidgetOptions>(() => {
  if (props.field.options.display.layout === DisplayOrientation.VERTICAL) {
    return props.eventNameField.options;
  }

  const leftAlign = props.eventNameField.options.alignment;
  let alignment: string;

  if (leftAlign === Alignment.LEFT) {
    alignment = Alignment.RIGHT;
  } else if (leftAlign === Alignment.RIGHT) {
    alignment = Alignment.LEFT;
  } else {
    alignment = Alignment.CENTER;
  }

  return {
    ...props.eventNameField.options,
    alignment,
  };
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__event-info {
    display: flex;

    &-element {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-element {
      flex: 1;
    }

    &--horizontal {
      .sportbook-live-mobile-fenix__event-info-element {
        &:last-child {
          flex-direction: row-reverse;
        }
      }
    }
  }

  &__event-name {
    flex: 1;
  }

  &__event-info-score {
    display: inline-block;
  }
}
</style>
