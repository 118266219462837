<template>
  <BaseWidgetNew
    ignore-style-states
    :widget="widget"
    :cell="cell"
    :current-state="activeView"
    :is-selected="isWholeWidgetSelected"
    :non-state-options="['fillImageColor']"
  >
    <CommonFormWidget
      :widget="widget"
      :states="currentStates"
      :screens="SCREENS"
      :active-view="activeView"
      @update-active-view="handleUpdateActiveView"
      @update-button-state="handleUpdateButtonState"
      @reset-states="handleResetStates"
    >
      <template #success_message>
        <WidgetField
          v-if="fields.success_message.active"
          :widget="widget"
          :field="fields.success_message.field"
        >
          <FormSuccessMessage :field="fields.success_message.field" />
        </WidgetField>
      </template>
    </CommonFormWidget>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FormScreen } from "~~/models/widgets/form.model";
import { useForm } from "~~/composables/widgets/form/useForm";

const SCREENS = [] as FormScreen[];

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const {
  currentStates,
  isWholeWidgetSelected,
  handleUpdateButtonState,
  handleResetStates,
  activeView,
  handleUpdateActiveView,
  fields,
} = useForm(ref(props.widget), SCREENS, "UserProfileForm");

provide("getCustomDescriptionFieldNameFn", () => "description");
</script>
