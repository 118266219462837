<!-- eslint-disable vue/no-v-html -->
<template>
  <p
    v-show="searchValue && !page.isEditing"
    class="page-item__search-page-element overflow-ellipsis"
    v-bind="$attrs"
    @click="handleSearchPageClick"
    v-html="page.nameHighlighted"
  ></p>

  <input
    v-show="!searchValue || page.isEditing"
    ref="inputEditingPageElement"
    :value="page.name"
    v-bind="$attrs"
    placeholder="Page Name"
    class="page-item__page-name-input overflow-ellipsis"
    :class="{
      'pointer': selectedPageId !== page.id,
      'page-item__page-name-input--focus': !isInputDisabled,
      'page-item__page-name-input--active': isActive,
    }"
    :readonly="isInputDisabled"
    @click="handleInputClick(page)"
    @input="handleInput($event)"
    @focusout="changePageName"
    @keyup.enter="handlePageNameChangeOnEnter($event)"
  />

  <CommonTooltip
    v-if="page.name.length === 0"
    class="page-item__input-tooltip"
    max-width="192px"
    :message="PAGE_NAME_VALIDATION"
  />
  <CommonIcon
    v-if="isInputDisabled"
    name="ant-design:more-outlined"
    class="page-item__more-button"
    :class="{
      'page-item__more-button--active':
        isContextMenuOpened && selectedPageId === page.id,
    }"
    @click="handleMoreClick($event, page.id)"
  />

  <div
    v-if="isContextMenuOpened && selectedPageId === page.id"
    ref="moreContextMenu"
    v-click-outside.prevent="handleClickOutside"
    class="page-item__more-context-menu"
    :class="{ 'page-item__more-context-menu--top': isContextMenuTop }"
  >
    <ul>
      <li
        class="page-item__context-menu-option"
        @click="editPage"
      >
        <CommonIcon
          name="ant-design:edit-outlined"
          class="page-item__context-menu-icon"
        />
        Edit page
      </li>
      <li
        class="page-item__context-menu-option"
        @click.stop="handleCopyPage"
      >
        <CommonIcon
          name="ant-design:copy-outlined"
          class="page-item__context-menu-icon"
        />
        Duplicate
      </li>
      <li
        class="page-item__context-menu-option--red"
        @click="showDeleteConfirmModal"
      >
        <CommonIcon
          name="ant-design:delete-outlined"
          class="page-item__context-menu-icon"
        />
        Delete
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";
import { nextTick } from "vue";

import { usePagesStore } from "~~/store/pages";
import { IPage } from "~~/models/stores/pages-store.model";
import { PAGE_NAME_VALIDATION } from "~~/constants/name-validations";
import { PageIdType } from "~~/models/widgets/widget.core/widget.model";
import {
  EDITING_PAGE_NAME_ERROR,
  EDITING_PAGE_NAME_SUCCESS,
} from "~~/constants/notification-messages";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";

const route = useRoute();
const router = useRouter();

const notification = useNotification();
const pagesStore = usePagesStore();

const { pages, selectedPageId, selectedPageName, isCreatingNewPage } =
  storeToRefs(pagesStore);

const emit = defineEmits<{
  (
    e: "click-copy",
    event: {
      id: PageIdType;
      name: string;
    }
  ): void;
  (e: "show-delete"): void;
  (e: "select-page", id?: PageIdType): void;
}>();

const props = defineProps<{
  page: IPage;
  searchValue: string;
  isActive: boolean;
}>();

const isContextMenuOpened = ref<boolean>(false);

const inputEditingPageElement = ref<HTMLInputElement | null>(null);

const moreContextMenu = ref<HTMLInputElement | null>(null);

const isContextMenuTop = ref<boolean>(false);

const isInputDisabled = ref<boolean>(true);

const handleInputClick = async (page: IPage): Promise<void> => {
  if (selectedPageId.value === page.id) {
    isInputDisabled.value = false;

    await nextTick();

    inputEditingPageElement.value?.focus();
  } else {
    selectPage(page);
  }
};

const findPageStoreValue = (id: PageIdType): IPage => {
  return pages.value.find(element => element.id === id)!;
};

const selectPage = async (page: IPage | null): Promise<void> => {
  const prevPage = findPageStoreValue(selectedPageId.value);

  if (prevPage && page?.id !== selectedPageId.value) {
    prevPage.isEditing = false;
  }

  pagesStore.setSelectedPage(page);
  emit("select-page", page?.id);
  await pagesStore.fetchPageContent();

  useIframePostMessage().sendPathChange(`/pages/${page?.id}/setup`);
};

const handleSearchPageClick = (): void => {
  const currentPage = findPageStoreValue(props.page.id);
  if (!currentPage) return;

  currentPage.isEditing = true;

  inputEditingPageElement.value?.focus();
};

const handleInput = (e: Event): void => {
  const target = e.target as HTMLInputElement;
  const value: string = target.value;

  const currentPage = findPageStoreValue(selectedPageId.value);
  if (currentPage) {
    currentPage.name = value;
  }
};

const handlePageNameChangeOnEnter = (e: Event): void => {
  const targetElement = e.target as HTMLInputElement;
  targetElement.blur();
};

const editPage = async (): Promise<void> => {
  router.replace({
    query: {
      ...route.query,
      showPageConfig: "true",
    },
  });
};

const handleClickOutside = (): void => {
  isContextMenuOpened.value = false;
};

const handleMoreClick = async (
  event: Event,
  pageId: PageIdType
): Promise<void> => {
  if (isCreatingNewPage.value) return;

  event.stopPropagation();

  isContextMenuTop.value = false;

  isContextMenuOpened.value = !isContextMenuOpened.value;

  if (selectedPageId.value !== pageId) {
    selectPage(props.page);
  }

  await nextTick();

  const tooltipElement = moreContextMenu.value;

  if (tooltipElement) {
    const tooltipRect = tooltipElement.getBoundingClientRect();
    isContextMenuTop.value = tooltipRect.bottom > window.innerHeight;
  }
};

const handleCopyPage = (): void => {
  isContextMenuOpened.value = false;
  emit("click-copy", { id: props.page.id, name: props.page.name });
};

const showDeleteConfirmModal = (): void => {
  isContextMenuOpened.value = false;
  emit("show-delete");
};

const changePageName = (): void => {
  isInputDisabled.value = true;
  const id = selectedPageId.value;
  const currentPage = findPageStoreValue(selectedPageId.value);

  if (!currentPage) return;

  currentPage.isEditing = false;

  if (selectedPageName.value === currentPage.name) return;

  if (!currentPage.name || !id) {
    currentPage.name = selectedPageName.value;
    return;
  }

  pagesStore
    .editPageName(currentPage.name, id)
    .then(() => {
      pagesStore.setPageName(currentPage.name);
      notification.notify({
        text: EDITING_PAGE_NAME_SUCCESS,
        type: "success",
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 422) {
        notification.notify({
          text: error.response.data.name,
          type: "error",
        });
      } else {
        notification.notify({
          text: EDITING_PAGE_NAME_ERROR,
          type: "error",
        });
      }
      currentPage.name = selectedPageName.value;
    });
};
</script>

<style lang="scss">
.page-item {
  &__page-name-input {
    width: 100%;
    border: 1px solid transparent;
    background-color: $c-white;

    &--active {
      background-color: $c-light-blue;
      color: $c-primary-base;
    }

    &:read-only {
      outline: unset;
    }

    &--focus {
      outline: 1px solid $c-primary-base;
      border-radius: 4px;
      color: $c-black;
      background-color: $c-white;
    }
  }

  &__more-button {
    display: none;
    padding: 5px;
    border-radius: 4px;

    &--active {
      color: $c-white;
      background-color: $c-primary-base;
    }
  }

  &__input-tooltip {
    top: 45px;
  }

  &__search-page-element {
    margin: 0;
    width: 100%;
    padding-left: 3px;
  }

  &__more-context-menu {
    max-height: 104px;
    position: absolute;
    right: 0px;
    top: 45px;
    padding: $space-xs;
    border-radius: 8px;
    background-color: $c-white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    &--top {
      bottom: 45px;
      top: unset;
    }
  }

  &__context-menu-option {
    @include flex(center, flex-start, 8px);
    padding: 4px 15px;
    color: $c-black;

    &--red {
      @include flex(center, flex-start, 8px);
      padding: 4px 15px;
      color: $c-icon-font-red;
    }
  }
}
</style>
