import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const generateButtonField = (name: string) => ({
  "name": name,
  "type": "ButtonLinkField",
  "value": null,
  "default_value": "Default",
  "validation": [{ string: { max: 50 } }],
  "title": "Title",
  "options": {
    "_active": true,
  },
});

const WIDGET_VERSION = 11;

const v11WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["SportsBookWidget"].includes(widget.name)) {
    if (!("live_button_title" in widget.content)) {
      widget.content.live_button_title =
        generateButtonField("live_button_title");
    }

    if (!("betbuilder_button" in widget.content)) {
      widget.content.betbuilder_button =
        generateButtonField("betbuilder_button");
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v11WidgetMigration;
