import { SUCCESS_MESSAGE_INITIAL } from "~~/constants/widget-details/form-initial-options";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 68;

const createSuccessField = () => {
  return {
    "type": "TextInputField",
    "value": "Default",
    "default_value": "Default",
    "options": SUCCESS_MESSAGE_INITIAL().options,
    "name": "success_message",
    "validation": [
      {
        "string": {
          "max": 255,
        },
      },
    ],
  };
};

const v66WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["ChangePasswordWidget", "UserProfileFormWidget"].includes(widget.name)) {
    if (!("success_message" in widget.content)) {
      widget.content.success_message = createSuccessField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};
export default v66WidgetMigration;
