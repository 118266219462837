<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
    :style="containerStyle"
    class="betslip-each-way__total-stake"
  >
    <BasicTitleField
      :field="field"
      :options="field.options.label"
    >
      Total stake
    </BasicTitleField>

    <BasicTitleField
      :field="field"
      :options="field.options.value"
    >
      $ 0,00
    </BasicTitleField>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
.betslip-each-way {
  &__total-stake {
    @include flex(center, flex-start, 0);

    & > div {
      flex: 1;
    }
  }
}
</style>
