import type { Ref } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { Sizing } from "~~/models/grid.interface";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useGridConfig } from "~~/store/grid";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useSuccessScreenConfig } from "~~/composables/widgets/form/useSuccessScreenConfig";
import {
  generateDropdownItem,
  DropdownItem,
} from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useFormElementConfig } from "~~/composables/widgets/form/useFormElementConfig";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useFormUploadConfig } from "~~/composables/widgets/form/useFormUploadConfig";
import {
  DepositListScreen,
  FormElementName,
  FormScreen,
} from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useFormScreenConfig } from "~~/composables/widgets/form/useFormScreenConfig";
import { findCellRootElement } from "~~/assets/utils/grid/grid-tree";
import {
  generateStyleDropdownItems,
  isInputField,
  getFormElements,
} from "~~/assets/utils/widget/form";
import { removeHTMLFromString } from "~~/assets/utils/widget/form";
import { ICell } from "~~/models/grid.interface";
import { FORM_FIELD_COMPONENTS } from "~~/constants/widget-details/form/form-dynamic-components";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import {
  IParams,
  useWidgetDynamicParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useFormSuccessMessageConfig } from "~~/composables/widgets/form/useFormSuccessMessage";

const configFormItems: ConfigTabItem[] = [
  {
    label: "Form",
    value: FormScreen.FORM,
  },
  {
    label: "Success Page",
    value: FormScreen.SUCCESS,
  },
  {
    label: "Expired Page",
    value: FormScreen.EXPIRED,
  },
  {
    label: "Fail Page",
    value: DepositListScreen.FAIL,
  },
];

const { getLocalizedValue } = useLocalizedValue();

const generateContentDropdownItems = (
  fields: IWidgetField[]
): DropdownItem[] => {
  return getFormElements(fields)
    .filter(field => FORM_FIELD_COMPONENTS[field.type])
    .map(field => {
      let label;
      const isInput = isInputField(field);

      if (isInput) {
        label = getLocalizedValue.value(field.options?.label?.value);
      } else if (field.type === FormElementName.DESCRIPTION) {
        label = removeHTMLFromString(getLocalizedValue.value(field.value));
      } else {
        label = getLocalizedValue.value(field.value);
      }

      return generateDropdownItem(label, field.type, "fields", {
        fieldId: field.id,
      });
    });
};

export const useFormWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  activeView: Ref<string>,
  states: Ref<Record<string, string>>,
  emit: any,
  screens: Ref<string[]>,
  customFields?: IWidgetField[],
  formElementField?: Ref<IWidgetField | null>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetsSettingStore = useWidgetSettingsStore();
  const { selectedCell } = storeToRefs(widgetsSettingStore);
  const gridStore = useGridConfig();

  const currentItems = computed<ConfigTabItem[]>(() => {
    const items = configFormItems.filter(
      item =>
        screens.value.includes(item.value) || item.value === FormScreen.FORM
    );

    return items;
  });

  const rootCell = computed<ICell | null>(() => {
    return findCellRootElement(
      gridStore.currentWidgetsLayout,
      selectedCell.value
    );
  });

  const isWidthConfigDisabled = computed<boolean>(() => {
    if (!rootCell.value) {
      return false;
    }

    return rootCell.value.settings.sizing === Sizing.FIXED;
  });

  const {
    screenTitleControls,
    screenImageControls,
    screenButtonStatesControls,
    screenButtonDesignControls,
    screenButtonContentControls,
    titleLinkStatesControls,
    valueSources,
    imageNameKey,
  } = useFormScreenConfig(widget, activeView as Ref<FormScreen>, states);

  const { screenContentControls } = useSuccessScreenConfig(
    widget,
    currentItems,
    activeView,
    emit,
    isWidthConfigDisabled,
    valueSources,
    imageNameKey
  );

  const {
    FORM_ELEMENTS_STYLE_TABS,

    formElementDesignControls,
    formElementContentControls,
    formElementStylesControls,

    formTitleStyles,
    formLabelsStyles,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    formErrorStyles,

    checkboxButtonDesignControls: checkboxButtonDesignControlsToEdit,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,

    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,

    formDatePickerStyles,

    formDropdownStyles,

    formDropdownMenuStyles,

    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,

    successFieldControls,
  } = useFormElementConfig(
    widget,
    states as Ref<Record<string, State>>,
    emit,
    customFields,
    formElementField
  );

  const checkboxButtonDesignControls =
    checkboxButtonDesignControlsToEdit.value.filter(
      control => control.valuePath !== "options.display.distance"
    );

  /* 
    Upload
  */

  const {
    formUploadCaptionStyles,
    formUploadListStyles,
    formUploadItemDesignStyles,
    formUploadItemContentStyles,
    formUploadItemStatesStyles,
    uploadButtonDesignControls,
    uploadButtonContentControls,
    uploadButtonStatesControls,
  } = useFormUploadConfig(widget, states as Ref<Record<string, State>>, emit);

  const {
    textFieldControls,
    textInputFieldControls,
    checkboxFieldControls,
    radioGroupFieldControls,
    dropdownFieldControls,
    uploadFieldControls,
  } = useFormContentConfig();

  const { successMessageDesignControls, successMessageContentControls } =
    useFormSuccessMessageConfig(widget);

  const buttonState = computed<string>(() => {
    return states.value.ButtonLinkField || "Default";
  });

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: buttonState,
        exclude: {
          content: ["ConfigLinkType"],
        },
        stateHandler: (value: State) => {
          emit("update-button-state", value);
        },
        showAlignmentOnFillOnly: false,
      },
      emit
    );

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Form Settings", "form", "form"),

    generateDropdownItem("Title", "success_title", "success"),
    generateDropdownItem("Image", "success_image", "success"),
    generateDropdownItem("Description", "success_description_1", "success"),
    generateDropdownItem("Button", "success_button", "success"),

    generateDropdownItem("Title", "expired_title", "expired"),
    generateDropdownItem("Image", "expired_image", "expired"),
    generateDropdownItem("Description", "expired_description_1", "expired"),
    generateDropdownItem("Button", "expired_button", "expired"),

    generateDropdownItem("Title", "fail_title", "fail"),
    generateDropdownItem("Image", "fail_image", "fail"),
    generateDropdownItem("Description", "fail_description_1", "fail"),
    generateDropdownItem("Button", "fail_button", "fail"),

    generateDropdownItem(
      "Link settings",
      "customScreenTitleLink",
      "customScreenTitleLink"
    ),

    generateDropdownItem("Success message", "success_message"),
  ];

  const currentDynamicParamsArgs = computed<IParams>(() => {
    switch (widget.value.name) {
      case "RegisterFormWidget":
      case "LoginFormWidget":
        return {
          "redirectUrl": {
            label: "Redirect URL",
            sources: ["custom"],
            required: false,
          },
        };
      case "ChangePasswordWidget":
        return {
          "successMessage": {
            label: "Success message type",
            sources: ["custom"],
            required: false,
            description:
              "Possible values: success_message, success_state. Empty value means success_state",
          },
        };
      case "UserProfileFormWidget":
        return {
          "visibleFields": {
            label: "Visible fields",
            description:
              "Possible values: all | required and not filled. Empty value means all",
            sources: ["custom"],
            required: false,
          },
        };

      default:
        return {} as IParams;
    }
  });

  const { bindingParams } = useWidgetDynamicParams(
    currentDynamicParamsArgs.value,
    {
      values: widget.value.options?.bindingParams || {},
    }
  );

  const widgetSettings = [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
  ];

  if (Object.keys(currentDynamicParamsArgs.value).length) {
    widgetSettings.push(generateTabItem("Data", "data"));
  }

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings,
    form: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
    ],
    [FormElementName.BUTTON]: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    success_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    expired_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    fail_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    success_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const formDropdownItems = computed<DropdownItem[]>(() => {
    return [
      ...DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
      ...generateContentDropdownItems(
        customFields ? customFields : widget.value.fields
      ),
    ];
  });

  const widgetDesignSettingsControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const formSettingsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const formContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        visible: currentItems.value.length > 1,
        options: {
          items: currentItems.value,
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: `options.states.${activeView.value}.contentWidth`,
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Form Settings",
              value: "form",
            },
          ],
        },
      },
    ];
  });

  const formSettingsContentControls = computed(() => {
    if (
      activeView.value === "success" ||
      activeView.value === "expired" ||
      activeView.value === "fail"
    ) {
      return screenContentControls.value;
    }

    return formContentControls.value;
  });

  return {
    formDropdownItems,
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignSettingsControls,

    formSettingsDesignControls,
    formSettingsContentControls,

    formElementDesignControls,
    formElementContentControls,
    formElementStylesControls,

    /*
      Success
    */

    screenTitleControls,
    titleLinkStatesControls,
    screenImageControls,

    screenButtonStatesControls,
    screenButtonDesignControls,
    screenButtonContentControls,

    /*
      Form elements style
    */
    formTitleStyles,
    formLabelsStyles,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    formErrorStyles,

    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,

    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,

    formDatePickerStyles,

    formDropdownStyles,

    formDropdownMenuStyles,

    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,

    /*
      Form content
    */
    textFieldControls,
    textInputFieldControls,
    checkboxFieldControls,
    radioGroupFieldControls,
    dropdownFieldControls,
    uploadFieldControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,

    /* 
      Upload
    */

    formUploadCaptionStyles,

    formUploadListStyles,

    formUploadItemDesignStyles,
    formUploadItemContentStyles,
    formUploadItemStatesStyles,

    uploadButtonDesignControls,
    uploadButtonContentControls,
    uploadButtonStatesControls,

    /* 
      Success message
    */

    successMessageDesignControls,
    successMessageContentControls,

    bindingParams,

    successFieldControls,
  };
};
