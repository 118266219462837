<template>
  <div
    class="wconfig-common-radio p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container"
  >
    <div class="w-100">
      <p
        v-if="showTitle"
        class="sub-heading wconfig-common-radio__title"
      >
        {{ title }}
      </p>

      <div>
        <p
          v-if="label"
          class="widget-control__label"
          :class="{
            'bold': isBold,
          }"
        >
          {{ label }}
        </p>

        <div class="wconfig-common-radio__radio-group-container">
          <a-radio-group
            :value="modelValue"
            class="wconfig-common-radio__radio-group w-100 text-center"
            @update:value="handleUpdate($event)"
          >
            <a-radio-button :value="firstValue">
              {{ firstVariant }}
              <span
                v-if="firstVariantIcon"
                class="wconfig-common-radio__icon"
                :class="firstVariantIcon.className"
              >
                <CommonIcon :name="firstVariantIcon?.icon" />
              </span>
            </a-radio-button>
            <a-radio-button :value="secondaryValue">
              {{ secondaryVariant }}
              <span
                v-if="secondaryVariantIcon"
                class="wconfig-common-radio__icon"
                :class="secondaryVariantIcon.className"
              >
                <CommonIcon :name="secondaryVariantIcon?.icon" />
              </span>
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string;
    label: string;
    title?: string;
    firstVariant: string;
    firstVariantIcon?: {
      className: string;
      icon: string;
    };
    firstValue: string;
    secondaryVariant: string;
    secondaryVariantIcon?: {
      className: string;
      icon: string;
    };
    secondaryValue: string;
    isBold?: boolean;
    showTitle?: boolean;
  }>(),
  {
    label: "",
    firstVariantIcon: undefined,
    secondaryVariantIcon: undefined,
    isBold: false,
    showTitle: false,
    title: "",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const handleUpdate = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.wconfig-common-radio {
  &__container {
    @include flex(center, space-around, $space-s);
  }

  &__icon {
    margin: 8px 0;
    width: 100%;
    @include flex(center, center, 0);
  }

  &__title {
    margin-bottom: $space-m;
  }

  .ant-radio-button-wrapper {
    width: 50%;
    padding: 0 12px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      word-break: break-all;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }

  .bold {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 8px;
  }
}
</style>
