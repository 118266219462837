import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Sports", "sports_container"),
    generateDropdownItem("Sports title", "title"),
    generateDropdownItem("Sports events button", "title_button"),
    generateDropdownItem("Leagues", "leagues_titles"),
    generateDropdownItem("Sport events", "sport_events_container"),
    generateDropdownItem("Sport event card", "event_cards"),
    generateDropdownItem("Header", "event_header"),
    generateDropdownItem("League name", "event_league"),
    generateDropdownItem("Favorite icon", "favourites_button"),
    generateDropdownItem("Stream icon", "stream_icon"),
    generateDropdownItem("Time", "event_time"),
    generateDropdownItem("Period", "event_period"),
    generateDropdownItem("Event info", "event_info"),
    generateDropdownItem("Event name", "event_name"),
    generateDropdownItem("Event score", "event_score"),
    generateDropdownItem("Bet items", "bet_items"),
    generateDropdownItem("Sports Events Button", "event_button"),
    generateDropdownItem("Racing events", "racing_events_container"),
    generateDropdownItem("Racing event cards", "racing_event_cards"),
    generateDropdownItem("Stream icon", "racing_event_stream_icon"),
    generateDropdownItem("Event name", "racing_event_name"),
    generateDropdownItem("Race status", "racing_event_status"),
    generateDropdownItem("Favorite icon", "racing_event_favourites_button"),
    generateDropdownItem("Sports events button", "racing_event_button"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    sports_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    leagues_titles: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    sport_events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_event_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
