import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateErrorCssString,
  generateFieldsWithStatesCssString,
  generateFormCssString,
  generateStageButtonCssString,
  generateTextChooseIconCssString,
  generateTooltipStyling,
  generateScreensCssString,
  generateIconRightCssString,
  generateImageCssString,
} from "../utils/form-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { generateFlex } from "../helpers";
import { generateCssClassWithContent } from "../compiler/default-css-compiler";

export const generateWidgetContentWrapperCssString =
  (items: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    cssString += generateCssClassWithContent({
      className: options._contentWrapperCssClass,
      content: generateFlex({
        flex: "flex",
        direction: "column",
        align: "stretch",
        gap: "0",
        justify: "flex-start",
      }),
    });

    return cssString;
  };

export const generateLoginFormFenixWidgetCssString = (
  widget: IWidgetOptions
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentWrapperCssClass = generateClassName(
    `${widget.name}_contentWrapper`
  );
  const prefilled = prefillWithClasses(widget.content);
  const customFields = widget.options._customFields;

  // Widget class name
  // widget.options._cssClass = generateClassName("LoginFormFenixWidget");

  const screens = ["success", "login_form", "forgot_password_form"];

  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.title_styling.options._cssClass =
    generateClassName("title_styling");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");
  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  prefilled.login_title.options._cssClass =
    customFields.title_styling.options._cssClass;

  prefilled.forgot_password_title.options._cssClass =
    customFields.title_styling.options._cssClass;

  prefilled.login_submit.options.iconSettings._cssClass =
    generateClassName("login_submit_icon");
  prefilled.login_button.options.iconSettings._cssClass =
    generateClassName("login_button_icon");
  prefilled.login_forgot_password_button.options.iconSettings._cssClass =
    generateClassName("login_forgot_password_button_icon");

  prefilled.forgot_password_submit.options.iconSettings._cssClass =
    generateClassName("forgot_password_submit_icon");
  prefilled.forgot_password_back_button.options.iconSettings._cssClass =
    generateClassName("forgot_password_forgot_password_back_button_icon");

  prefilled.result_back_button.options.iconSettings._cssClass =
    generateClassName("result_back_button_icon");

  return pipeSync<string>(
    generateTooltipStyling(customFields.tooltips_styling),
    generateIconRightCssString(customFields.fields_styling.options),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateStringDefault(customFields.title_styling),

    generateStringDefault({
      ...widget,
      options: {
        ...widget.options,
        size: null,
      },
    }),
    generateFormCssString(prefilled.login_form, widget),
    generateFormCssString(prefilled.forgot_password_form, widget),
    generateScreensCssString(widget, "LoginFormFenixWidget", screens),

    generateStageButtonCssString(prefilled.login_submit as IWidgetField),
    generateStageButtonCssString(
      prefilled.login_forgot_password_button as IWidgetField
    ),
    generateStageButtonCssString(prefilled.login_button as IWidgetField, true),
    // generateStringDefault(prefilled.login_title as IWidgetField),
    generateStringDefault(prefilled.login_description_1 as IWidgetField),
    generateStringDefault(prefilled.login_description_2 as IWidgetField),
    generateStringDefault(prefilled.login_text),

    generateStageButtonCssString(
      prefilled.forgot_password_submit as IWidgetField
    ),
    generateStageButtonCssString(
      prefilled.forgot_password_back_button as IWidgetField
    ),
    // generateStringDefault(prefilled.forgot_password_title),
    generateStringDefault(prefilled.forgot_password_description_1),
    generateStringDefault(prefilled.forgot_password_description_2),

    generateStringDefault(prefilled.result_title),
    generateStageButtonCssString(prefilled.result_back_button as IWidgetField),
    generateImageCssString(prefilled.result_image as IWidgetField),
    generateStringDefault(prefilled.result_message),
    generateStringDefault(prefilled.result_timer),
    generateWidgetContentWrapperCssString(widget)
  )("");
};
