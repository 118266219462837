<template>
  <div
    :style="containerStyles"
    class="betslip-widget__event-container"
  >
    <WidgetField
      v-if="fields.event_name_title.active"
      :widget="widget"
      :field="fields.event_name_title.field"
      :style="eventNameInlineStyles"
    >
      <span>Event name</span>
    </WidgetField>
    <WidgetField
      v-if="fields.market_title.active"
      :widget="widget"
      :field="fields.market_title.field"
    >
      <div :style="betMarketInlineStyles">Bet market</div>
    </WidgetField>
    <div class="betslip-widget__cards-coef-container">
      <WidgetField
        v-if="fields.bet_name_title.active"
        :widget="widget"
        :field="fields.bet_name_title.field"
      >
        <div :style="betNameInlineStyles">Bet name</div>
      </WidgetField>

      <WidgetField
        v-if="fields.coefficient_title.active"
        :widget="widget"
        :field="fields.coefficient_title.field"
        class="betslip-widget__coefficient-field"
      >
        <div
          class="betslip-widget__coefficient"
          :class="{
            'betslip-widget__coefficient--show-triangle':
              states.coefficient_title === State.DOWN ||
              states.coefficient_title === State.UP,
            'betslip-widget__coefficient--triangle-down':
              states.coefficient_title === State.DOWN,
          }"
          :style="[triangleBorderColorStyles, coefficientContainerInlineStyles]"
        >
          <span>0,00</span>
        </div>
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getPxValueFromNumber } from "~~/assets/utils";
import { useBetslipCardsInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipCardsInlineStyles";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const containerStyles = computed<ElementStyle>(() => {
  if (!props.fields.event_container.field.options.display) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: getPxValueFromNumber(
      props.fields.event_container.field.options.display?.distance
    ),
  };
});

const {
  betMarketInlineStyles,
  betNameInlineStyles,
  coefficientContainerInlineStyles,
  triangleBorderColorStyles,
  eventNameInlineStyles,
} = useBetslipCardsInlineStyles(ref(props.fields), ref(props.states));
</script>
