<template>
  <div
    v-if="hasDesignPermissions"
    class="config-choose-icon widget-control-container p-t-16 p-b-16 p-l-16 p-r-16"
  >
    <p
      :class="{ bold: isBold }"
      class="m-b-0 config-choose-icon__label"
    >
      {{ label }}
    </p>

    <div class="config-choose-icon__select-container p-t-16">
      <CommonIcon
        v-if="icons[modelValue.icon]"
        :name="icons[modelValue.icon]"
        class="config-choose-icon__selected-icon"
      />
      <a-select
        :get-popup-container="getPopupContainer"
        :value="modelValue.icon"
        placeholder="Select icon"
        :options="selectOptions"
        size="small"
        class="config-choose-icon__select"
        @update:value="handleUpdateIcon"
      ></a-select>
    </div>
    <ConfigColorPickerInput
      :model-value="modelValue.color"
      :type="ColorPickerType.TEXT"
      label="Icon color"
      class="config-choose-icon__color-picker"
      @update:model-value="handleUpdateColor"
    />

    <div
      v-if="modelValue.gap !== undefined"
      class="p-t-16"
    >
      <p>Spacing between text and icon</p>

      <ConfigRangeSlider
        :model-value="modelValue.gap"
        :min-value="MIN_GAP_INPUT_VALUE"
        :max-value="MAX_GAP_INPUT_VALUE"
        class="config-icon-settings__input-element"
        @update:model-value="handleUpdateGap($event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FunctionalComponent } from "vue";
import type { SelectProps } from "ant-design-vue";

import {
  MIN_GAP_INPUT_VALUE,
  MAX_GAP_INPUT_VALUE,
} from "~~/constants/input-validation";
import type { IFillControl } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

type SelectedIconProps = {
  color: IFillControl;
  icon: string;
  gap?: number;
};

const props = defineProps<{
  modelValue: SelectedIconProps;
  label: string;
  options: { label: string; value: string }[];
  icons: Record<string, FunctionalComponent>;
  isBold?: boolean;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const selectOptions = computed<SelectProps["options"]>(() => {
  return props.options;
});

const emit = defineEmits<{
  (e: "update:model-value", event: SelectedIconProps): void;
}>();

const handleUpdateIcon = (icon: string) => {
  emit("update:model-value", {
    ...props.modelValue,
    icon,
  });
};

const handleUpdateColor = (color: IFillControl) => {
  emit("update:model-value", {
    ...props.modelValue,
    color,
  });
};

const handleUpdateGap = (gap: number) => {
  emit("update:model-value", {
    ...props.modelValue,
    gap,
  });
};

const getPopupContainer = (trigger: HTMLElement): HTMLElement | null => {
  return trigger.parentElement;
};
</script>

<style lang="scss">
.config-choose-icon {
  &__label {
    margin-bottom: 0;
  }

  &__select-container {
    @include flex(center, space-between, 0);
    width: 100%;
  }

  &__selected-icon {
    border: 1px solid $c-grey-15;
    height: 24px;
    width: 24px;
    @include flex(center, center, 0);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: $c-grey-45;
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &__color-picker {
    padding: $space-m 0 0 0;
    border-bottom: unset;
  }
}
</style>
