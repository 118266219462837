<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PromotionsPageSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <section class="promotions-page">
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.page_content.field"
      >
        <BasicTitleField :field="fields.page_content.field" />
      </BasicDesignWrapperField>
    </section>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/promotions-page/usePromotionsPageInitData";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  init,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

init();
</script>

<style lang="scss"></style>
