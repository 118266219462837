import { State } from "~~/models/widgets/widget-controls.model";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { getDefaultTheme } from "~~/constants/configs/common/widget-initial";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useWidgetsStore } from "~~/store/widgets";

export const useSimpleTextWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value);

  const DEFAULT_STATES = {
    title: State.DEFAULT,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const { handleStateUpdate, resetStates } = useStates({
    defaultStates: DEFAULT_STATES,
    states,
  });

  const {
    initWidgetOptions,
    initFields,

    addFieldInitialOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([]);

  const init = (): void => {
    initFields();

    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(32),
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    states,
    fields,
    init,
    handleStateUpdate,
    resetStates,
  };
};
