<template>
  <div
    :class="{
      'config-input--stretch': stretchInput,
      'config-input--has-inputs': slots.inputs,
      'config-input--has-suffix': slots.suffix,
    }"
    class="config-input"
  >
    <a-input-group
      class="config-input__group"
      size="small"
    >
      <slot name="inputs">
        <component
          :is="inputComponent"
          v-bind="inputProps"
          :value="modelValue"
          size="small"
          placeholder="0"
          @update:value="$emit('update:modelValue', $event)"
          @change="$emit('change', $event)"
          @blur="$emit('blur')"
          @mouseenter="$emit('mouseenter', $event)"
          @mouseleave="$emit('mouseleave', $event)"
        >
          <template
            v-if="slots.prefix"
            #prefix
          >
            <slot name="prefix"> </slot>
          </template>

          <template
            v-if="!props.isNumber && slots.suffix"
            #suffix
          >
            <span class="f-base config-input__px-text">
              <slot name="suffix"> </slot>
            </span>
          </template>

          <template
            v-else-if="slots.suffix"
            #addonAfter
          >
            <span class="f-base config-input__px-text">
              <slot name="suffix"> </slot>
            </span>
          </template>
        </component>
      </slot>

      <span
        v-if="slots.after"
        class="config-input__after m-l-8"
      >
        <slot name="after"></slot>
      </span>
    </a-input-group>
  </div>
</template>

<script lang="ts" setup>
import { type Component, useSlots } from "vue";
import { Input, InputNumber } from "ant-design-vue";

const slots = useSlots();

const props = defineProps<{
  modelValue?: string | number | null;
  stretchInput?: Boolean;
  isNumber?: Boolean;
  inputProps?: Partial<{
    min: Number;
    max: Number;
    controls: boolean;
  }>;
}>();

defineEmits<{
  (e: "update:modelValue", event: string | number | null): void;
  (e: "change", event: string | number | null): void;
  (e: "blur"): void;
  (e: "mouseenter", event: Event): void;
  (e: "mouseleave", event: Event): void;
}>();

const inputComponent = computed<Component>(() => {
  if (props.isNumber) {
    return InputNumber;
  }

  return Input;
});
</script>

<style lang="scss">
.config-input {
  &__group {
    @include flex(center, flex-start, 0);
  }
  &:not(&--stretch) {
    .ant-input {
      width: 84px;
    }
    .ant-input-number {
      width: calc(84px - 25px);
    }
  }

  :not(.ant-input-number-affix-wrapper) {
    padding-left: 0;
  }

  .ant-input-number {
    width: 100%;
    padding-left: 7px;
  }

  .ant-input-affix-wrapper,
  .ant-input-number-group-wrapper,
  .ant-input-number-affix-wrapper {
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;
    width: 100%;
  }

  .ant-input-affix-wrapper {
    .ant-input,
    .ant-input-number {
      border: 1px solid $c-grey-15;
      border-radius: 4px;
      padding: 0 $space-s;
    }

    .ant-input-suffix {
      position: absolute;
      right: $space-s;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      background-color: $c-white;
    }
  }

  .ant-input-number-affix-wrapper,
  .ant-input-number-wrapper {
    .ant-input-number {
      border: 1px solid $c-grey-15;
      border-radius: 4px;
    }

    .ant-input-number-suffix {
      position: absolute;
      right: $space-s;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &--has-suffix {
    .ant-input-number {
      border-right: unset !important;
      padding: 0 0 0 $space-s !important;
    }
  }

  .ant-input-number-group-addon {
    background: none !important;
    padding: 0 5px !important;
    border-radius: 4px;
  }

  &__px-text {
    color: $c-grey-45;
  }
}
</style>
