import { Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

export const useItemsGroupControls = (
  itemsGroup: Ref<IWidgetField | null>,
  itemsGroupState: Ref<State>,
  emit: any
) => {
  const itemsGroupDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: itemsGroup.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: itemsGroup.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: itemsGroup.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: itemsGroup.value,
        options: {
          label: "Space between",
          isBold: true,
        },
      },
    ];
  });

  const itemsGroupContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItemsText",
        valuePath: `options.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.itemsIcon`,
        valueSource: itemsGroup.value,
        options: {
          label: "Icon",
        },
      },
    ];
  });

  const itemsGroupSettingsValuePath = computed<string>(() => {
    if (itemsGroupState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${itemsGroupState.value}`;
  });

  const itemsGroupStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: itemsGroupState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-items-group-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${itemsGroupSettingsValuePath.value}.fill`,
        valueSource: itemsGroup.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${itemsGroupSettingsValuePath.value}.border`,
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${itemsGroupSettingsValuePath.value}.shadow`,
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsIcon`,
        valueSource: itemsGroup.value,
        options: {
          label: "Icon",
          isStatesTab: true,
        },
      },
    ];
  });

  return {
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  };
};
