import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";

const MODAL_CONFIG_TABS = [
  {
    label: "Modal title",
    value: "modal_title",
  },
  {
    label: "Modal description",
    value: "modal_description",
  },
  {
    label: "Modal primary button",
    value: "modal_primary_button",
  },
  {
    label: "Modal secondary button",
    value: "modal_secondary_button",
  },
  {
    label: "Modal icon",
    value: "modal_close_icon",
  },
];

type Args = {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  primaryBtnStateName: string;
  secondaryBtnStateName: string;
  closeBtnStateName: string;
};

export const useBonusesModalConfig = (
  {
    widget,
    states,
    primaryBtnStateName,
    secondaryBtnStateName,
    closeBtnStateName,
  }: Args,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const primaryButtonState = computed<State>(() => {
    return states.value[primaryBtnStateName] as State;
  });

  const secondaryButtonState = computed<State>(() => {
    return states.value[secondaryBtnStateName] as State;
  });

  const closeButtonState = computed<State>(() => {
    return states.value[closeBtnStateName] as State;
  });

  const {
    buttonStatesControls: primaryButtonStatesControls,
    buttonDesignControls: primaryButtonDesignControls,
    buttonContentControls: primaryButtonContentControls,
  } = useSimpleButtonControls(primaryButtonState, primaryBtnStateName, emit);

  const {
    buttonStatesControls: secondaryButtonStatesControls,
    buttonDesignControls: secondaryButtonDesignControls,
    buttonContentControls: secondaryButtonContentControls,
  } = useSimpleButtonControls(
    secondaryButtonState,
    secondaryBtnStateName,
    emit
  );

  const {
    buttonStatesControls: closeButtonStatesControls,
    buttonDesignControls: closeButtonDesignControls,
    buttonContentControls: closeButtonContentControls,
  } = useButtonControls(
    {
      state: closeButtonState,
      stateHandler: (state: State) => {
        emit("update-state", { state: closeBtnStateName, value: state });
      },
      exclude: {
        content: ["ConfigLinkType", "ConfigButtonDisplaySettings"],
      },
    },
    emit
  );

  const modalControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.modal.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.modal.field,
            options: {
              label: "Padding",
            },
          },
        ],
      ],
    });
  });

  const modalContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: MODAL_CONFIG_TABS,
        },
      },
    ];
  });

  return {
    modalControls,
    modalContentControls,

    primaryButtonStatesControls,
    primaryButtonDesignControls,
    primaryButtonContentControls,

    secondaryButtonStatesControls,
    secondaryButtonDesignControls,
    secondaryButtonContentControls,

    closeButtonStatesControls,
    closeButtonDesignControls,
    closeButtonContentControls,
  };
};
