<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-array-items-text wconfig-item p-t-16 p-r-16 p-b-16 p-l-16"
  >
    <p class="sub-heading">
      {{ label }}
    </p>

    <ConfigColorPickerInput
      :model-value="modelValue.color"
      :type="ColorPickerType.TEXT"
      label="Text color"
      class="wconfig-array-items-text__control m-t-16"
      @update:model-value="handleUpdate('color', $event)"
    />

    <ConfigThemeSelect
      :model-value="modelValue.theme"
      class="wconfig-array-items-text__control"
      @update:model-value="handleUpdate('theme', $event)"
    />

    <ConfigFontFamilySelect
      :model-value="modelValue.fontFamily"
      class="wconfig-array-items-text__control"
      @update:model-value="handleUpdate('fontFamily', $event)"
    />

    <ConfigDecoration
      :model-value="modelValue.decoration"
      class="wconfig-array-items-text__control"
      @update:model-value="handleUpdate('decoration', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { TextStyle } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  modelValue: {
    _active: boolean;
    color: { [key: string]: string };
    theme: string;
    fontFamily: string;
    decoration: TextStyle[];
  };
  label?: string;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      [key: string]: { [key: string]: string } | string | boolean | TextStyle[];
    }
  ): void;
}>();

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-array-items-text {
  @include widgets-content-input-container;

  &__control {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
