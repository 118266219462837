<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #title-design>
          <ControlsList
            :widget="widget"
            :controls="titleDesignControls"
          />
        </template>

        <template #title-content>
          <ControlsList
            :widget="widget"
            :controls="titleContentControls"
          />
        </template>

        <template #title-states>
          <ControlsList
            :widget="widget"
            :controls="titleStatesControls"
          />
        </template>

        <template #items>
          <ControlsList
            :widget="widget"
            :controls="itemsControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupStatesControls"
          />
        </template>

        <template #item>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useDropdownWidgetConfig } from "~~/composables/widgets/dropdown/useDropdownWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  titleState: State;
  itemsGroupState: State;
  widget: IWidgetWithFields;
}>();

const emit = defineEmits<{
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const titleState = toRef(() => props.titleState);
const itemsGroupState = toRef(() => props.itemsGroupState);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  titleDesignControls,
  titleContentControls,
  titleStatesControls,
  itemsControls,
  itemsGroupDesignControls,
  itemsGroupContentControls,
  itemsGroupStatesControls,
  itemControls,
} = useDropdownWidgetConfig(
  ref(props.widget),
  titleState,
  itemsGroupState,
  emit
);

const resetStates = () => {
  emit("reset-states");
};
</script>
