import { type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";

import { useWidgetFields } from "../useWidgetFields";

export const useBetslipFenixMultibetTooltipConfig = (
  widget: Ref<IWidgetWithFields>
) => {
  const fields = useWidgetFields(widget.value);

  const multibetTooltipDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const multibetTipContentStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.multibet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.multibet_tooltip.field,
        },
        "Tooltips text"
      ),
    ];
  });

  return {
    multibetTooltipDesignStyles,
    multibetTipContentStyles,
  };
};
