<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupStatesControls"
          />
        </template>

        <template #live>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #comingEvents>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/coming-events-switcher/useComingEventsSwitcherConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  itemsGroupState: State;
}>();

const emit = defineEmits<{
  (e: "update-items-group-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const itemsGroupState = toRef(() => props.itemsGroupState);

const { bindingParams } = useWidgetDynamicParams(
  {
    "alwaysUseFallbackUrl": {
      label: "Always use fallback url.",
      description: "Possible values: yes | no",
      sources: ["custom"],
      required: true,
    },
    "fallbackLiveEventURL": {
      label: "Fallback Live event URL",
      sources: ["custom"],
      required: true,
    },
    "comingEventsURL": {
      label: "Coming events URL",
      sources: ["custom"],
      required: true,
    },
    "isComingEventsPage": {
      label: "Is coming events page?",
      description: "Possible value: Yes | No",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  /* 
      Controls
    */
  widgetDesignControls,
  widgetContentControls,

  itemControls,
  itemsGroupDesignControls,
  itemsGroupContentControls,
  itemsGroupStatesControls,
} = useConfig(ref(props.widget), itemsGroupState, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
