import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getCornerRadiusStyle,
  getDecorationValue,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import {
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import { IFields } from "../../useWidgetFields";

export const useBetslipTabsInlineStyles = (
  fields: ComputedRef<IFields>,
  states: Ref<Record<string, State>>
) => {
  const tabsOptions = fields.value.betslip_tabs_container.field.options;

  const activeTabsContainerStateOptions = computed<IWidgetOptions>(() => {
    if (states.value.betslip_tabs_container === State.DEFAULT) {
      return tabsOptions;
    }

    return tabsOptions.states[states.value.betslip_tabs_container];
  });

  const tabsContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(tabsOptions),
      ...getSpacing(tabsOptions.margins, SpacingKeyName.MARGIN),
      ...getSpacing(tabsOptions.padding, SpacingKeyName.PADDING),
      gap: `${tabsOptions.spaceBetween}px`,
    };
  });

  const tabsAlignmentStyles = computed<ElementStyle>(() => {
    return {
      justifyContent:
        tabsOptions.buttonDisplaySettings.resizing === ResizingType.FILL
          ? "center"
          : tabsOptions.buttonDisplaySettings.alignment,
    };
  });

  const tabsContentInlineStyles = computed<ElementStyle>(() => {
    const isFill =
      tabsOptions.buttonDisplaySettings.resizing === ResizingType.FILL;
    return {
      color: getColorFromHex(activeTabsContainerStateOptions.value.color)!,
      fontSize: `${tabsOptions.theme.value}px`,
      fontFamily: tabsOptions.fontFamily,
      ...getBackgroundColorStyle(
        activeTabsContainerStateOptions.value.headerElementsSettings.fill
      ),
      ...getBorderStyle(
        activeTabsContainerStateOptions.value.headerElementsSettings.border
      ),
      ...getBoxShadowStyle(
        activeTabsContainerStateOptions.value.headerElementsSettings.shadow
      ),
      ...getSpacing(
        tabsOptions.headerElementsSettings.padding,
        SpacingKeyName.PADDING
      ),
      ...getDecorationValue(tabsOptions.decoration),
      ...getCornerRadiusStyle(tabsOptions.headerElementsSettings.cornerRadius),
      width: isFill ? "100%" : "fit-content",
      textAlign: isFill
        ? tabsOptions.buttonDisplaySettings.alignment
        : "center",
    };
  });

  return {
    tabsContainerInlineStyles,
    tabsAlignmentStyles,
    tabsContentInlineStyles,
  };
};
