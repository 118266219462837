import { Ref } from "vue";

import { BlockType, ICell } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";

const store = useGridConfig();

export const useSelection = (
  inputCell: ICell,
  currentCell: Ref<HTMLElement | null>
): {
  selectedCell: Ref<ICell | null>;
  addListeners: () => void;
  removeListeners: () => void;
  onSelect: () => void;
} => {
  const { cellId, parentId, settings } = inputCell;

  /* NOTE: User cannot interact with cells if there's invalid width settings for a current cell.
    Example:
    Container is changed from ADAPTIVE to FIXED, which immediately affects UI,
    but then user must set at least one column as FIXED and the system cannot save current grid state
    or any further changes until the user does that.
  */
  const { selectedCell, hasWidthSettingsErrors } = storeToRefs(store);

  function onHover(): void {
    if (hasWidthSettingsErrors.value) return;
    /* NOTE: this method is called only on CONTAINER blocks */
    store.addHighlightedCell(cellId);
    store.setHoveredColumn(parentId);
  }

  function onHoverOut(): void {
    if (hasWidthSettingsErrors.value) return;
    store.removeHighlightedCell(cellId);
    store.setHoveredColumn(null);
  }

  function addListeners(): void {
    if (
      currentCell.value !== null &&
      settings.blockType === BlockType.CONTAINER
    ) {
      currentCell.value.addEventListener("mouseenter", onHover);
      currentCell.value.addEventListener("mouseleave", onHoverOut);
    }
  }

  function removeListeners(): void {
    if (currentCell.value !== null) {
      currentCell.value.removeEventListener("mouseenter", onHover);
      currentCell.value.removeEventListener("mouseleave", onHoverOut);
    }
  }

  function onSelect(): void {
    if (hasWidthSettingsErrors.value) return;
    /* NOTE: select only CONTAINER blocks */
    if (parentId === null) {
      /* if no parent, this is a 1st-level container */
      store.setSelectedCell(inputCell);
    } else if (settings.blockType === BlockType.COLUMN) {
      /* column's parent (not null) is always a container block */
      const parentCell = store.getCell(parentId);
      store.setSelectedCell(parentCell);
    }
  }

  return {
    selectedCell,
    addListeners,
    removeListeners,
    onSelect,
  };
};
