import { CSSProperties } from "vue";

import {
  AlignItems,
  Display,
  JustifyContent,
} from "~~/models/widgets/widget-controls.model";
import {
  getColorStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetHelpers";
import { useDesignInitialStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

export const useArrowsStyles = (arrowsOptions: any = {}) => {
  const arrowsButtonOptions = arrowsOptions.button || {};

  const arrowsStyles = (): CSSProperties => {
    return {
      position: "absolute",
      zIndex: "9",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      display: Display.FLEX,
      justifyContent: JustifyContent.SPACE_BETWEEN,
      pointerEvents: "none",
    };
  };

  const arrowsContainerStyles = (): CSSProperties => {
    return {
      ...useDesignInitialStyles(arrowsOptions),

      display: Display.FLEX,
      justifyContent: JustifyContent.CENTER,
      alignItems: getFlexPosition(arrowsButtonOptions.alignment),

      ...arrowsOptions.layoutAlignment,
      gap: getPxValueFromNumber(arrowsOptions.gap),
    };
  };

  const arrowsButtonStyles = (): CSSProperties => {
    const styles = {
      ...useDesignInitialStyles(arrowsButtonOptions),

      pointerEvents: "auto",
      display: Display.FLEX,
      justifyContent: JustifyContent.CENTER,
      alignItems: AlignItems.CENTER,
      ...(arrowsButtonOptions.icon?.color
        ? getColorStyle(arrowsButtonOptions.icon?.color)
        : {}),
      width: arrowsButtonOptions.icon?.size
        ? getPxValueFromNumber(arrowsButtonOptions.icon?.size)
        : undefined,
      height: arrowsButtonOptions.icon?.size
        ? getPxValueFromNumber(arrowsButtonOptions.icon?.size)
        : undefined,
      fontSize: arrowsButtonOptions.icon?.size
        ? getPxValueFromNumber(
            Math.max(Math.floor(arrowsButtonOptions.icon?.size / 2), 1)
          )
        : undefined,
      cursor: "pointer",
    };
    return clearObject(styles);
  };

  return {
    arrowsStyles,
    arrowsContainerStyles,
    arrowsButtonStyles,
  };
};
