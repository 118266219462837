import {
  getDefaultFillValue,
  getDefaultTheme,
  getDefaultFontFamily,
  getDefaultDecoration,
} from "~~/constants/configs/common/widget-initial";
import { Alignment } from "~~/models/widgets/widget-controls.model";

export const CAPTION_STYLE_INITIAL = () => ({
  title: "Caption text style",
  options: {
    _active: true,
    color: getDefaultFillValue("#000000", 45),
    theme: getDefaultTheme(13),
    fontFamily: getDefaultFontFamily(),
    decoration: getDefaultDecoration(),
    alignment: Alignment.LEFT,
  },
});
