<template>
  <WidgetField
    :widget="widget"
    :field="fields.sports.field"
    class="bets-list-fenix-mobile-sports"
    :style="containerStyles"
  >
    <WidgetField
      :widget="widget"
      :field="fields.title.field"
      class="bets-list-fenix-mobile-sports__title"
      :style="titleStyles"
    >
      <CommonIcon
        v-if="fields.title.field.options.iconSettings._active"
        :name="'ion:football-outline'"
        :style="iconInlineStyle"
      />

      <BasicTitleField
        :field="fields.title.field"
        :state="states.title"
      >
        {{ item.name }}
      </BasicTitleField>

      <BetsListFenixMobileEventButton
        :widget="widget"
        :field="fields.sports_event_button.field"
        :state="states.sports_event_button"
      />

      <BetsListFenixMobileEventButton
        :widget="widget"
        :field="fields.hover_button.field"
        :state="states.hover_button"
        value="Other events of the sport"
      />
    </WidgetField>

    <WidgetField
      v-if="fields.leagues.field.options._active"
      :widget="widget"
      :field="fields.leagues.field"
      class="bets-list-fenix-mobile-sports__title"
      :style="leaguesStyles"
    >
      <BasicTitleField :field="fields.leagues.field">
        League Name
      </BasicTitleField>
    </WidgetField>

    <div :style="eventsContainerStyles">
      <BetsListFenixMobileEventCard
        v-for="index in cardsCount"
        :key="index"
        :widget="widget"
        :states="states"
      />
    </div>

    <div v-if="fields.leagues.field.options._active">
      <WidgetField
        :widget="widget"
        :field="fields.leagues.field"
        class="bets-list-fenix-mobile-sports__title"
        :style="leaguesWithMarginStyles"
      >
        <BasicTitleField :field="fields.leagues.field">
          League Name
        </BasicTitleField>
      </WidgetField>

      <div :style="eventsContainerStyles">
        <BetsListFenixMobileEventCard
          :widget="widget"
          :states="states"
        />
      </div>
    </div>
  </WidgetField>
</template>

<script setup lang="ts">
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getColorFromHex } from "~~/assets/utils/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { ISportItem } from "~~/models/stores/widgets-store.model";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

import BetsListFenixMobileEventCard from "./BetsListFenixMobileEventCard.vue";
import BetsListFenixMobileEventButton from "./BetsListFenixMobileEventButton.vue";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  item: ISportItem;
}>();

const fields = useWidgetFields(props.widget);

const containerStyles = computed<ElementStyle>(() => {
  return getCommonStyles(
    fields.value.sports.field.options,
    props.states.sports
  );
});

const titleStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.title.field.options, props.states.title),
    gap: getPxValueFromNumber(fields.value.title.field.options.spaceBetween),
  };
});

const iconInlineStyle = computed<ElementStyle>(() => {
  return {
    color: getColorFromHex(
      activeTitleStateOptions.value.iconSettings.textColor
    )!,
    fontSize: getPxValueFromNumber(
      fields.value.title.field.options.iconSettings.iconSize
    ),
  };
});

const activeTitleStateOptions = computed<IWidgetOptions>(() => {
  if (props.states.title === State.DEFAULT) {
    return fields.value.title.field.options;
  }

  return fields.value.title.field.options.states[props.states.title];
});

const leaguesStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.leagues.field.options, props.states.title),
  };
});

const leaguesWithMarginStyles = computed<ElementStyle>(() => {
  return {
    ...leaguesStyles.value,
    marginTop: getPxValueFromNumber(
      fields.value.leagues.field.options.spaceBetween
    ),
  };
});

const eventsContainerStyles = computed<ElementStyle>(() => ({
  display: "grid",
  "grid-template-columns": `repeat(${fields.value.event_card.field.options.columns}, minmax(0, 1fr))`,
  gap: getPxValueFromNumber(
    fields.value.event_card.field.options.containerSpaceBetween
  ),
}));

const cardsCount = computed(() => {
  return Math.max(2, fields.value.event_card.field.options.columns);
});
</script>

<style lang="scss">
.bets-list-fenix-mobile-sports {
  &__title {
    display: flex;
    align-items: center;
  }

  &__event-button {
    display: flex;
    align-items: center;
  }
}
</style>
