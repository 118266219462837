import { type Ref } from "vue";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

export function useFlexibleImageControls(field: Ref<IWidgetField>) {
  const flexibleImageControls = computed<ControlProp[]>(() => [
    {
      section: generateDesignSectionName("Photo container size"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.containerSize.width.type",
          valueSource: field.value,
          options: {
            label: "Width",
            items: [
              {
                label: "Fixed",
                value: ResizingType.FIXED,
              },
              {
                label: "Fill",
                value: ResizingType.FILL,
              },
            ],
          },
        },
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "options.containerSize.width.value",
          className: "p-l-16 p-r-16 p-t-16",
          valueSource: field.value,
          visible:
            field.value.options.containerSize.width.type === ResizingType.FIXED,
          options: {
            minValue: 1,
            maxValue: 1024,
          },
        },
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.containerSize.height.type",
          valueSource: field.value,
          options: {
            label: "Height",
            items: [
              {
                label: "Fixed",
                value: ResizingType.FIXED,
              },
              {
                label: "Fill",
                value: ResizingType.FILL,
              },
            ],
          },
        },
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "options.containerSize.height.value",
          className: "p-l-16 p-r-16 p-t-16",
          valueSource: field.value,
          visible:
            field.value.options.containerSize.height.type ===
            ResizingType.FIXED,
          options: {
            minValue: 1,
            maxValue: 1024,
          },
        },
      ],
    },
    {
      componentPath: "ConfigFlexibleImageResizing",
      valuePath: "options.resizing",
      valueSource: field.value,
      options: {
        label: "Image resizing",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: field.value,
      options: {
        label: "Border",
      },
    },
    // {
    //   componentPath: "ConfigBoxShadow",
    //   valuePath: "options.shadow",
    //   valueSource: field.value,
    //   options: {
    //     label: "Shadow",
    //   },
    // },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: field.value,
      options: {
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: field.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  return {
    flexibleImageControls,
  };
}
