import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  ConfigTabItem,
  DisplayOrientation,
  ResizingType,
  State,
  TextPosition,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { ProviderFormFenixScreen } from "~~/models/widgets/form.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useWidgetsStore } from "~~/store/widgets";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";
import { useWidgetFields } from "../useWidgetFields";

export const useProviderFormFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  activeView: Ref<ProviderFormFenixScreen>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = computed(() => [
    generateDropdownItem("Back Button", "form_back_button", activeView.value),
    generateDropdownItem(
      "Provider Card",
      "provider_card",
      ProviderFormFenixScreen.PROVIDER_PAGE
    ),
    generateDropdownItem(
      "Provider Image",
      "provider_image",
      ProviderFormFenixScreen.PROVIDER_PAGE
    ),
    generateDropdownItem(
      "Provider Name",
      "provider_title",
      ProviderFormFenixScreen.PROVIDER_PAGE
    ),
    generateDropdownItem(
      "Provider Description",
      "provider_description_1",
      ProviderFormFenixScreen.PROVIDER_PAGE
    ),
    generateDropdownItem(
      "Description",
      "deposit_form_description",
      ProviderFormFenixScreen.PROVIDER_PAGE
    ),

    generateDropdownItem(
      "Success Message",
      "success_message",
      ProviderFormFenixScreen.SUCCESS_STATUS_PAGE
    ),
    generateDropdownItem(
      "Fail Message",
      "fail_message",
      ProviderFormFenixScreen.FAIL_STATUS_PAGE
    ),
    generateDropdownItem(
      "Pending Message",
      "pending_message",
      ProviderFormFenixScreen.PENDING_STATUS_PAGE
    ),

    generateDropdownItem(
      "Additional info",
      "additional_info",
      ProviderFormFenixScreen.SUCCESS_STATUS_PAGE
    ),
  ]);

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    form_back_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    provider_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    success_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    fail_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    pending_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    additional_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const statusValueMap = {
    [ProviderFormFenixScreen.SUCCESS_STATUS_PAGE]: "Success",
    [ProviderFormFenixScreen.FAIL_STATUS_PAGE]: "Fail",
    [ProviderFormFenixScreen.PENDING_STATUS_PAGE]: "Pending",
  };

  const widgetContentConfigTabItems = computed(() => {
    return [
      {
        label: "Back Button",
        value: "form_back_button",
      },

      ...(activeView.value === ProviderFormFenixScreen.PROVIDER_PAGE
        ? [
            {
              label: "Provider Card",
              value: "provider_card",
            },
            {
              label: "Form",
              value: "form",
            },
          ]
        : [
            {
              label: `${statusValueMap[activeView.value]} Message`,
              value: `${statusValueMap[
                activeView.value
              ].toLowerCase()}_message`,
            },
          ]),
    ];
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        options: {
          items: [
            {
              label: "Provider Page",
              value: ProviderFormFenixScreen.PROVIDER_PAGE,
            },
            {
              label: "Success Status Page",
              value: ProviderFormFenixScreen.SUCCESS_STATUS_PAGE,
            },
            {
              label: "Fail Status Page",
              value: ProviderFormFenixScreen.FAIL_STATUS_PAGE,
            },
            {
              label: "Pending Status Page",
              value: ProviderFormFenixScreen.PENDING_STATUS_PAGE,
            },
          ],
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetContentConfigTabItems.value,
        },
      },
    ];
  });

  const formBackButtonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const formBackButtonContentControls = computed(() => {
    const { allowDisableFieldsItem } =
      useAllowDisableDependentFieldContentItems(selectedField, [
        FieldContentItem.BUTTON_TEXT_SETTINGS,
        FieldContentItem.ICON_SETTINGS,
      ]);

    const items = [
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: fields.value.form_back_button.field,
        options: {
          showAlignmentOnFillOnly: false,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        valueSource: selectedField.value?.options.buttonTextSettings,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "p-t-16",
            valueSource: fields.value.form_back_button.field,
            options: {
              label: "Text",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];

    return items;
  });

  const formBackButtonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const formBackButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: "form_back_button", value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${formBackButtonSettingsValuePath.value}.fill`,
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${formBackButtonSettingsValuePath.value}.border`,
      valueSource: fields.value.form_back_button.field,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${formBackButtonSettingsValuePath.value}.shadow`,
      valueSource: fields.value.form_back_button.field,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${formBackButtonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: fields.value.form_back_button.field,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${formBackButtonSettingsValuePath.value}.iconSettings.textColor`,
          valueSource: fields.value.form_back_button.field,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const providerCardDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_card.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const providerCardContentItems: ConfigTabItem[] = [
    {
      label: "Provider image",
      value: "provider_image",
    },
    {
      label: "Provider name",
      value: "provider_title",
      isActiveValueSource: fields.value.provider_title.field.options,
    },
    {
      label: "Provider description",
      value: "provider_description_1",
    },
  ];

  const providerCardContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigLayoutPosition",
        valuePath: "options.layoutPosition",
        valueSource: fields.value.provider_card.field,
        options: {
          showHorizontalAlignment: true,
          "onUpdate:model-value"(value: any) {
            widgetsStore.updateFieldOptionValue(
              value,
              "layoutPosition",
              fields.value.provider_card.field.id
            );

            if (
              value.position === TextPosition.TOP ||
              value.position === TextPosition.BOTTOM
            ) {
              widgetsStore.updateFieldOptionValue(
                value.horizontalAlignment,
                "alignment",
                fields.value.provider_title.field.id
              );

              widgetsStore.updateFieldOptionValue(
                value.horizontalAlignment,
                "alignment",
                fields.value.provider_description_1.field.id
              );

              if (
                fields.value.provider_image.field.options.resizing.type !==
                ResizingType.FIT
              ) {
                return;
              }

              widgetsStore.updateFieldOptionValue(
                `${VerticalPosition.CENTER} ${value.horizontalAlignment}`,
                "resizing.position",
                fields.value.provider_image.field.id
              );
            }
          },
        },
        visible: true,
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: fields.value.provider_card.field,
        options: {
          label: "Distance between elements",
          showIconPrefix: false,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: providerCardContentItems,
        },
      },
    ];
  });

  const { flexibleImageControls: formCardImageControls } =
    useFlexibleImageControls(toRef(fields.value.provider_image.field));

  const formCardTitleControls = computed(() => {
    return [
      typography({ source: fields.value.provider_title.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const formCardDescriptionControls = computed(() => {
    return [
      typography({ source: fields.value.provider_description_1.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_description_1.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const statusMessageDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const statusMessageContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          selectedField.value?.options.layout === DisplayOrientation.VERTICAL
            ? {
                componentPath: "ConfigAlignment",
                valuePath: "options.horizontalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                  isTextContentIcons: false,
                },
              }
            : {
                componentPath: "ConfigVerticalAlignment",
                valuePath: "options.verticalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                },
              },

          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        {
          source: selectedField.value,
        },
        "Text"
      ),
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: true,
          isStatesTab: false,
        },
      },
    ];
  });

  const additionalInfoDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const additionalInfoContentControls = computed(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  const formDescriptionTitleField = computed(() => {
    return [
      typography({ source: fields.value.deposit_form_description.field }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.deposit_form_description.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,

    widgetDesignControls,
    widgetContentControls,

    formBackButtonDesignControls,
    formBackButtonContentControls,
    formBackButtonStatesControls,

    providerCardDesignControls,
    providerCardContentControls,

    formCardImageControls,

    formCardTitleControls,
    formCardDescriptionControls,

    statusMessageDesignControls,
    statusMessageContentControls,

    additionalInfoDesignControls,
    additionalInfoContentControls,

    formDescriptionTitleField,
  };
};
