<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-control-container config-layout-position p-t-16 p-r-16 p-l-16 p-b-16"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label || "Layout" }}
    </p>
    <div class="config-layout-position__container">
      <span
        class="config-layout-position__icon pointer"
        :class="{
          'config-layout-position__icon--active':
            modelValue?.position === TextPosition.BOTTOM,
        }"
        @click="updateValue(TextPosition.BOTTOM, 'position')"
      >
        <CommonIcon name="custom:layout-bottom" />
        <span class="config-layout-position__icon-label f-base"
          >Text bottom</span
        >
      </span>
      <span
        class="config-layout-position__icon pointer"
        :class="{
          'config-layout-position__icon--active':
            modelValue?.position === TextPosition.TOP,
        }"
        @click="updateValue(TextPosition.TOP, 'position')"
      >
        <CommonIcon
          name="custom:layout-bottom"
          class="r-180"
        />
        <span class="config-layout-position__icon-label f-base">Text top</span>
      </span>
      <span
        class="config-layout-position__icon pointer"
        :class="{
          'config-layout-position__icon--active':
            modelValue?.position === TextPosition.RIGHT,
        }"
        @click="updateValue(TextPosition.RIGHT, 'position')"
      >
        <CommonIcon name="custom:layout-right" />
        <span class="config-layout-position__icon-label f-base"
          >Text right</span
        >
      </span>
      <span
        class="config-layout-position__icon pointer"
        :class="{
          'config-layout-position__icon--active':
            modelValue?.position === TextPosition.LEFT,
        }"
        @click="updateValue(TextPosition.LEFT, 'position')"
      >
        <CommonIcon
          name="custom:layout-right"
          class="r-180"
        />
        <span class="config-layout-position__icon-label f-base">Text left</span>
      </span>
    </div>

    <template v-if="showAlignment">
      <ConfigVerticalAlignment
        v-if="
          modelValue?.position === TextPosition.RIGHT ||
          modelValue?.position === TextPosition.LEFT
        "
        :model-value="modelValue.alignment"
        label="Content alignment"
        class="config-layout-position__alignment"
        @update:model-value="updateValue($event, 'alignment')"
      />

      <ConfigAlignment
        v-if="
          showHorizontalAlignment &&
          (modelValue?.position === TextPosition.TOP ||
            modelValue?.position === TextPosition.BOTTOM)
        "
        :model-value="modelValue.horizontalAlignment"
        label="Content alignment"
        class="config-layout-position__alignment"
        :is-text-content-icons="false"
        @update:model-value="updateValue($event, 'horizontalAlignment')"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  TextPosition,
  LayoutPositionComplex,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    modelValue: LayoutPositionComplex;
    isBold?: boolean;
    label?: string;
    showHorizontalAlignment?: boolean;
    showAlignment?: boolean;
  }>(),
  {
    label: "",
    showAlignment: true,
  }
);

const emit = defineEmits<{
  (e: "update:model-value", event: LayoutPositionComplex): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const updateValue = (
  value: TextPosition | VerticalAlignment,
  key: string
): void => {
  emit("update:model-value", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-layout-position {
  &__container {
    @include flex(center, flex-start, 5px, wrap);
  }

  &__icon {
    @include flex-column(center, center, 8px);
    border: 1px solid $c-grey-15;
    padding: 8px;
    border-radius: 6px;
    flex-grow: 1;

    &--active {
      border: 2px solid $c-primary-base !important;
    }

    svg {
      width: 88px;
      height: 48px;
    }
  }

  &__alignment {
    padding: 0;
  }

  &__icon-label {
    padding: 5px 0;
  }
}
</style>
