import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateImageCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";

export const generateFormSuccessCssString =
  (widget: IPageContentWidget) => (cssString: string) => {
    const successImage = widget.content["success_image"];
    const successButton = widget.content["success_button"];
    const successDescription = widget.content["success_description_1"];
    const successTitle = widget.content["success_title"];

    successButton.options.iconSettings._cssClass =
      generateClassName("successButtonIcon");
    successImage.options._cssClass = generateClassName("successImage");
    successImage.options._cssClass = generateClassName("successImage");
    successButton.options._cssClass = generateClassName("successButton");
    successDescription.options._cssClass =
      generateClassName("successDescription");
    successTitle.options._cssClass = generateClassName("successTitle");
    successButton.options.iconSettings._cssClass =
      generateClassName("buttonIcon");

    return pipeSync<string>(
      generateImageCssString(successImage as IWidgetField),
      generateStringWithStates(successTitle, true),
      generateStringDefault(successDescription),
      generateStageButtonCssString(successButton as IWidgetField)
    )(cssString);
  };
