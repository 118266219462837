<template>
  <div
    class="dropdown-field"
    :style="wrapperStyle"
  >
    <WidgetField
      :widget="widget"
      :field="titleField"
      :style="dropdownTriggerStyle"
    >
      <DropdownTriggerField
        :value="titleField?.value"
        :field="titleField"
        :widget="widget"
        :state="titleState"
      />
    </WidgetField>
    <!-- TODO: need to remake this to position absolute -->
    <WidgetField
      v-if="isWidgetSelected"
      :widget="widget"
      :field="arrayField"
      class="dropdown-field__items-field"
      :style="dropdownTriggerStyle"
    >
      <DropdownMenuField :field="arrayField">
        <div
          class="dropdown-field__dropdown-items-container"
          :style="dropdownItemsInlineStyle"
        >
          <WidgetField
            v-for="itemField in itemsFields"
            :key="itemField.id"
            :field="itemField"
            :widget="widget"
            :class="{
              'dropdown-field__field--selected': isItemsGroupSelected,
            }"
          >
            <ArrayItemField
              :field="itemField"
              :wrapper-options="menuItemsOptions"
              :state="itemsGroupState"
              :is-items-group-selected="isItemsGroupSelected"
            />
          </WidgetField>
        </div>
      </DropdownMenuField>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { useWidgetWithArrayField } from "~~/composables/widgets/useWidgetWithArrayField";
import {
  Alignment,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    titleFieldName?: FieldName;
    titleState: State;
    itemsGroupState: State;
  }>(),
  {
    titleFieldName: FieldName.TITLE,
  }
);

const {
  titleField,
  arrayField,
  itemsFields,
  isItemsGroupSelected,
  isWidgetSelected,
  wrapperStyle,
  menuItemsOptions,
  initItemsGroup,
} = useWidgetWithArrayField(props.widget, props.titleFieldName);

initItemsGroup();

const dropdownTriggerStyle = computed<CSSProperties>(() => {
  if (titleField.value?.options.display?.resizing === ResizingType.FILL) {
    return { alignSelf: "stretch" };
  }
  return {};
});

const dropdownItemsInlineStyle = computed<CSSProperties>(() => {
  return {
    gap: `${menuItemsOptions.value.spaceBetween}px`,
    alignItems: generateFlexAlignment(
      titleField.value?.options.display?.alignment ||
        props.widget.options.display?.alignment ||
        Alignment.LEFT
    ),
  };
});
</script>

<style lang="scss">
.dropdown-field {
  display: flex;
  flex-direction: column;
  position: relative;

  &__field--selected {
    border: 2px solid rgba($c-primary-base, 1);
  }

  &__dropdown-items-container {
    @include flex-column(center, center, 0px);
  }
}
</style>
