import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 1;

const generateNoEventTitleField = () => ({
  "type": "TextInputField",
  "value": "Default",
  "options": {
    "_active": true,
    "theme": 13,
    "fontFamily": "Roboto",
    "decoration": [],
    "alignment": "center",
    "color": {
      "color": "#000000",
      "opacity": 100,
    },
    "padding": {
      "top": 0,
      "right": 0,
      "bottom": 0,
      "left": 0,
    },
  },
  "name": "no_events_title",
  "validation": [
    {
      "string": {
        "max": 255,
      },
    },
  ],
});

const v1WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["SportsBookWidget", "TransactionHistoryWidget"].includes(widget.name)) {
    if (!("no_events_title" in widget.content)) {
      widget.content.no_events_title = generateNoEventTitleField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v1WidgetMigration;
