import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";
import { TEXT_BUTTON_INITIAL } from "~~/constants/configs/common/text-button-initial";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const LIST_FILTER_FIELDS = [
  "form",
  "date_from",
  "date_to",
  "transaction_types",
  "transaction_subtypes",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "calendar_styling",
  "datepicker_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const INITIAL_STATES: Record<string, State> = {
    tab: State.DEFAULT,
    table_checkboxes_settings: State.SELECTED_DEFAULT,
    action_button: State.DEFAULT,
    title: State.DEFAULT,
    items: State.ACTIVE_EVENTS,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.CALENDAR]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...INITIAL_STATES,
  });

  /*
  Initialize widget with initial options
*/

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, ["title"])
      );
    }
    widgetsStore.updateWidgetFields(widget, [...widget.fields, ...formFields]);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }
  };

  const initialTabStateOptions = () => {
    return {
      fill: getDefaultFillValue("#ffffff"),
      border: getDefaultBorderValue({
        fill: getDefaultFillValue("", 100),
      }),
      shadow: getDefaultShadow(),
      color: getDefaultFillValue("#000000"),
    };
  };

  const initWidgetFieldsOptions = (): void => {
    const initialWidgetOptions = initWidgetOptions([], {
      contentWidth: {
        type: Sizing.ADAPTIVE,
        alignment: Alignment.CENTER,
        width: 320,
      },
    });

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
      }).options
    );

    const initialDescriptionOptions = prefillEmptyOptions(
      fields.value.pending_description.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(),
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialTabsOptions = prefillEmptyOptions(
      fields.value.tabs_container.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "margins", "padding", "size"],
        additionalData: {
          options: {
            buttonDisplaySettings: {
              resizing: ResizingType.HUG,
              alignment: "left",
            },
            distance: 16,
            states: {
              [State.HOVER]: initialTabStateOptions(),
              [State.ACTIVE]: initialTabStateOptions(),
            },
          },
        },
        values: {
          theme: 13,
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(8),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialFilterOptions = prefillEmptyOptions(
      fields.value.form.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            distance: 24,
            displayMode: DisplayOrientation.HORIZONTAL,
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialTableOptions = prefillEmptyOptions(
      fields.value.table.field.options,
      getInitialOptions({
        data: {
          options: {
            verticalSize: 12,
            horizontalSize: 12,
            fill: getDefaultFillValue("#ffffff"),
            borderType: "between_rows",
            border: getDefaultBorderValue({
              fill: getDefaultFillValue("#000000", 6),
            }),
            shadow: getDefaultShadow(),
            cornerRadius: getDefaultCornerRadiusValue(),
            spacing: {
              margin: getDefaultSpacing({
                left: 0,
                right: 0,
                top: 16,
                bottom: 16,
              }),
              padding: getDefaultSpacing({
                left: 0,
                right: 0,
                top: 16,
                bottom: 16,
              }),
            },
            mode: "table",
          },
        },
      }).options
    );

    const initialTabsHeaderOptions = prefillEmptyOptions(
      fields.value.table_header_settings.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "margins", "padding"],
        additionalData: {
          options: {
            fill: getDefaultFillValue("#000000", 2),
            separator: getDefaultBorderValue({
              fill: getDefaultFillValue("#000000", 6),
            }),
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialRowsOptions = prefillEmptyOptions(
      fields.value.table_row_settings.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "margins", "padding"],
        additionalData: {
          options: {
            icon: {
              color: getDefaultFillValue("#1F1F1F"),
              size: 14,
            },
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialCheckboxState = (fill = "#1677FF") => {
      return {
        fill: getDefaultFillValue(fill),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue(fill, 100),
        }),
        checkIcon: {
          color: getDefaultFillValue("#ffffff"),
        },
      };
    };

    const initialCheckboxesOptions = prefillEmptyOptions(
      fields.value.table_checkboxes_settings.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["shadow", "cornerRadius", "spacing", "size"],
        additionalData: {
          options: {
            checkIcon: {
              color: getDefaultFillValue("#ffffff"),
            },
            states: {
              [State.SELECTED_HOVER]: initialCheckboxState(),
              [State.UNSELECTED_DEFAULT]: initialCheckboxState("#F3F3F3"),
              [State.UNSELECTED_HOVER]: initialCheckboxState("#F3F3F3"),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#1677FF"),
        },
      }).options
    );

    const initialNoEventsTextOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.action_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
      }).options
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTextOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.pending_description.field,
      initialDescriptionOptions
    );

    widgetsStore.updateValue(
      useLocalizedValue().transformToLocalizedValue(
        "Pending withdrawal cancel info"
      ).localizedValue,
      "value",
      fields.value.pending_description.field,
      "value"
    );
    widgetsStore.updateFieldOptions(
      fields.value.form.field,
      initialFilterOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs_container.field,
      initialTabsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.table.field,
      initialTableOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.table_header_settings.field,
      initialTabsHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.table_row_settings.field,
      initialRowsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.table_checkboxes_settings.field,
      initialCheckboxesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.action_button.field,
      initialButtonOptions
    );
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    filterFields,
    INITIAL_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
