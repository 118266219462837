import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useVirtualsConfig = (widget: Ref<IWidgetWithFields>) => {
  const DROPDOWN_ITEMS = [generateDropdownItem("Iframe", "iframe_container")];

  const widgetControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  return {
    widgetControls,
    DROPDOWN_ITEMS,
  };
};
