<template>
  <div
    v-if="showSpacingHighlight"
    class="spacing-highlight position-absolute"
    :style="spacingHighlightStyle"
  ></div>
</template>

<script lang="ts" setup>
import {
  ISpacingInput,
  IVerticalSpacingInput,
} from "~~/models/widgets/widget-controls.model";
import { useSpacingHighlightStyles } from "~~/composables/widgets/useSpacingHighlightStyles";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const widgetSettingsStore = useWidgetSettingsStore();
const { calculateSpacingHighlightStyles } = useSpacingHighlightStyles();

const { hoveredControl } = storeToRefs(widgetSettingsStore);

const props = defineProps<{
  isTargetSelected: boolean;
  margin: IVerticalSpacingInput;
  padding: ISpacingInput;
  isTargetDisabled?: boolean;
}>();
const showSpacingHighlight = computed<boolean>(() => {
  if (props.isTargetDisabled) {
    return false;
  }

  if (!props.isTargetSelected) {
    return false;
  }

  if (!hoveredControl.value) {
    return false;
  }

  return true;
});

const spacingHighlightStyle = computed<{ [key: string]: string | number }>(
  () => {
    if (!showSpacingHighlight.value) {
      return {};
    }

    return calculateSpacingHighlightStyles(hoveredControl.value as string, {
      padding: props.padding,
      margin: props.margin,
    });
  }
);
</script>

<style lang="scss">
.spacing-highlight {
  background: repeating-linear-gradient(
    50deg,
    transparent,
    transparent 3px,
    transparent 4px,
    $c-primary-base 5px
  );
}
</style>
