import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { State } from "~~/models/widgets/widget-controls.model";

export const CHECKBOX_STYLE_INITIAL = () => ({
  title: "Checkbox style",
  options: {
    _active: true,
    fill: {
      color: null,
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: getDefaultShadow(),
    size: 16,
    color: {
      color: "#1677FF",
      opacity: 100,
    },
    theme: 13,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    display: {
      distance: 8,
    },
    padding: getDefaultSpacing(0),
    cornerRadius: getDefaultCornerRadiusValue(),
    check: {
      _active: true,
      fill: getDefaultFillValue("#ffffff"),
      border: getDefaultBorderValue({
        fill: getDefaultFillValue("#000000", 6),
      }),
      icon: getDefaultFillValue("#000000"),
      distance: 8,
    },
    states: {
      [State.SELECTED_HOVER]: {
        fill: {
          color: null,
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
        check: {
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          icon: getDefaultFillValue("#000000"),
        },
      },
      [State.SELECTED_DISABLED]: {
        fill: {
          color: null,
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
        check: {
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          icon: getDefaultFillValue("#000000"),
        },
      },
      [State.UNSELECTED_HOVER]: {
        fill: {
          color: null,
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 100,
        },
        shadow: getDefaultShadow(),
        decoration: [],
        check: {
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          icon: getDefaultFillValue("#000000"),
        },
      },
      [State.UNSELECTED_DEFAULT]: {
        fill: {
          color: null,
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        color: {
          color: "#000000",
          opacity: 100,
        },
        shadow: getDefaultShadow(),
        decoration: [],
        check: {
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          icon: getDefaultFillValue("#000000"),
        },
      },
      [State.UNSELECTED_DISABLED]: {
        fill: {
          color: null,
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
        check: {
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          icon: getDefaultFillValue("#000000"),
        },
      },
    },
  },
});
