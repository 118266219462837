import type { Ref } from "vue";

import { ISportItem } from "~~/models/stores/widgets-store.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useBetsListSortedSelectedSports = (
  widget: IWidgetWithFields,
  sportsItems: Ref<Array<ISportItem>>
): Array<ISportItem> | null => {
  const bindingParams = widget.options.bindingParams;

  if (Array.isArray(bindingParams?.sportIds.value)) {
    if (!bindingParams?.sportIds.value) {
      return [];
    }

    const idToPosition: Record<string, number> = {};
    bindingParams.sportIds.value.forEach((id: string, index: number) => {
      idToPosition[id] = index;
    });

    const sortedArray = sportsItems.value
      .filter(obj => idToPosition[obj.id] !== undefined)
      .sort((a, b) => idToPosition[a.id] - idToPosition[b.id]);

    return sortedArray;
  }

  return null;
};
