import type { Rule } from "ant-design-vue/es/form";

import { Sizing, WidthParams } from "~~/models/grid.interface";
import {
  widthValidations,
  MIN_FIXED_COL_WIDTH,
  DEFAULT_ADAPTIVE_COL_WIDTH,
  REPLACE_STR,
} from "~~/constants/width-validations";
import { useGridConfig } from "~~/store/grid";
import { recalculateCells } from "~~/assets/utils/grid/grid-cell-width";

export const useColumnWidthValidation = () => {
  const { selectedCell } = storeToRefs(useGridConfig());
  const formModel = reactive<{ columns: WidthParams[] }>({
    columns: getColumns(false),
  });

  const fixedCellsCount = computed<{ prev: number; current: number }>(() => {
    /* NOTE: `prev` is in sync with selected cell, `current` is for new value currently stored in formModel */
    const prev = !selectedCell.value
      ? 0
      : selectedCell.value?.children.filter(
          child => child.settings.sizing === Sizing.FIXED
        ).length;

    const current = formModel.columns.filter(
      child => child.sizing === Sizing.FIXED
    ).length;

    return {
      prev,
      current,
    };
  });

  function getFixedCellWidth(): number {
    if (selectedCell.value && selectedCell.value.settings.width) {
      if (
        selectedCell.value.children.length - fixedCellsCount.value.prev ===
        1
      ) {
        const cellsTotalWidth = getCellsTotalWidth();
        return selectedCell.value.settings.width - cellsTotalWidth.fixed;
      }
    }
    return MIN_FIXED_COL_WIDTH;
  }

  function getAdaptiveCellWidth(): number {
    const cellsTotalWidth = getCellsTotalWidth();
    const remaining = DEFAULT_ADAPTIVE_COL_WIDTH - cellsTotalWidth.adaptive;
    return remaining < 0 ? 0 : remaining;
  }

  /*
    Envoked when columns are changed: either their number or width parameters.
    Auto sets width values
  */
  function getColumns(shouldRecalculate = true): WidthParams[] {
    if (!selectedCell.value) return [];
    const cellToParams = selectedCell.value.children.map(child => {
      return {
        sizing: child.settings.sizing,
        width: child.settings.width ?? DEFAULT_ADAPTIVE_COL_WIDTH,
      };
    });
    if (shouldRecalculate) {
      return recalculateCells(
        cellToParams,
        selectedCell.value.settings.sizing,
        selectedCell.value.settings.width
      );
    } else return cellToParams;
  }

  const getCellsTotalWidth = (): { adaptive: number; fixed: number } => {
    return formModel.columns.reduce(
      (acc, current) => {
        if (current.sizing === Sizing.FIXED) {
          acc.fixed = acc.fixed + Number(current.width);
        } else acc.adaptive = acc.adaptive + Number(current.width);
        return acc;
      },
      { adaptive: 0, fixed: 0 }
    );
  };

  const validateCol = async (
    _rule: Rule,
    value: { sizing: Sizing; width: string | number }
  ): Promise<string> => {
    if (value.sizing === Sizing.ADAPTIVE) {
      if (Number(value.width) > DEFAULT_ADAPTIVE_COL_WIDTH) {
        return Promise.reject(widthValidations.ADAPTIVE_CELL_MAX_WIDTH);
      } else if (Number(value.width === 0)) {
        return Promise.reject(widthValidations.ADAPTIVE_CELL_MIN_WIDTH);
      }
    }
    if (
      value.sizing === Sizing.FIXED &&
      Number(value.width) < MIN_FIXED_COL_WIDTH
    ) {
      return Promise.reject(widthValidations.FIXED_CELL_WIDTH);
    }
    return Promise.resolve("");
  };

  const validateTotalWidth = async (): Promise<string> => {
    const cell = selectedCell.value;
    if (!cell) return Promise.resolve("");

    const parentWidth: number = cell.settings.width ?? 0;
    const cellsTotalWidth = getCellsTotalWidth();
    if (cell.settings.sizing === Sizing.FIXED) {
      /* NOTE: always should have at least one fixed child in fixed parent */
      if (fixedCellsCount.value.current === cell.children.length) {
        /* All children are FIXED */
        if (
          // TODO: review rounding logic
          parentWidth !== Math.floor(cellsTotalWidth.fixed) &&
          parentWidth !== Math.ceil(cellsTotalWidth.fixed)
        ) {
          const errorMsg =
            widthValidations.TOTAL_EXACT_FIXED_CELLS_WIDTH.replace(
              REPLACE_STR,
              parentWidth.toString()
            );
          return Promise.reject(errorMsg);
        }
      } else {
        /* Have at least one ADAPTIVE block */
        if (parentWidth < cellsTotalWidth.fixed) {
          const errorMsg = widthValidations.TOTAL_FIXED_CELLS_WIDTH.replace(
            REPLACE_STR,
            parentWidth.toString()
          );
          return Promise.reject(errorMsg);
        }
      }
    }
    /* NOTE: need rounding since adaptive cell width can have fractions, like 33.33% */
    if (
      cellsTotalWidth.adaptive > 0 &&
      Math.ceil(cellsTotalWidth.adaptive) !== 100
    ) {
      return Promise.reject(widthValidations.TOTAL_ADAPTIVE_CELLS_WIDTH);
    }
    if (
      cell.settings.sizing === Sizing.FIXED &&
      fixedCellsCount.value.current === 0
    ) {
      return Promise.reject(widthValidations.REQUIRED_FIXED_CHILD);
    }
    if (
      cell.settings.sizing === Sizing.ADAPTIVE &&
      formModel.columns.length - fixedCellsCount.value.current === 0
    ) {
      return Promise.reject(widthValidations.REQUIRED_ADAPTIVE_CHILD);
    }
    return Promise.resolve("");
  };

  return {
    formModel,
    getAdaptiveCellWidth,
    getFixedCellWidth,
    getColumns,
    validateTotalWidth,
    validateCol,
    getCellsTotalWidth,
    fixedCellsCount,
  };
};
