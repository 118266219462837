<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="emit('reset-states')"
        @blur-tab="emit('reset-states')"
        @change="emit('reset-states')"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { State } from "~~/models/widgets/widget-controls.model";
import { useSimpleTextWidgetConfig } from "~~/composables/widgets/simple-text/useSimpleTextWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  titleLinkState: State;
}>();

const emit = defineEmits<{
  (e: "update-title-link-state", event: State): void;
  (e: "reset-states"): void;
}>();

const { selectedWidget } = storeToRefs(useWidgetSettingsStore());
const titleLinkState = toRef(() => props.titleLinkState);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  titleControls,
  titleLinkStatesControls,
} = useSimpleTextWidgetConfig(ref(props.widget), titleLinkState, emit);
</script>
