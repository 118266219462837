import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

import { useWidgetFields } from "../../useWidgetFields";

export const useSearchResultBoxControls = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const searchResultBoxControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.search_result_box.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.search_result_box.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.search_result_box.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.search_result_box.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.spacing.padding",
      valueSource: fields.value.search_result_box.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.spacing.margin",
      valueSource: fields.value.search_result_box.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  return {
    searchResultBoxControls,
  };
};
