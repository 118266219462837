import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";

type Args = {
  source: any;
  onUpdate: (value: State) => void;
  statesList?: { label: string; value: string }[];
};

const DEFAULT_STATES = [
  {
    label: STATE_KEY_LABELS.default,
    value: State.DEFAULT,
  },
  {
    label: STATE_KEY_LABELS.hover,
    value: State.HOVER,
  },
  {
    label: STATE_KEY_LABELS.active,
    value: State.ACTIVE,
  },
];

export const stateConfig = ({
  source,
  onUpdate,
  statesList,
}: Args): ControlProp => {
  const stateControl = {
    componentPath: "common/ConfigStatesList",
    valuePath: "",
    valueSource: source,
    options: {
      items: statesList || DEFAULT_STATES,
    },
    onUpdate(value: State) {
      onUpdate(value);
    },
  };

  return stateControl;
};
