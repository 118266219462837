import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 22;

const v22WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["SportsBookPreMatchFenixMobileWidget"].includes(widget.name)) {
    Object.keys(widget.options.bindingParams).forEach(key => {
      if (key === "sportsBookPageUrl") {
        delete widget.options.bindingParams[key];
      }

      if (!widget.options.bindingParams["liveEventPageURL"]) {
        widget.options.bindingParams["liveEventPageURL"] = {
          label: "Live event page URL",
          sources: ["custom"],
          required: false,
        };
      }

      if (!widget.options.bindingParams["eventsPageUrl"]) {
        widget.options.bindingParams["eventsPageUrl"] = {
          label: "Events page URL",
          sources: ["custom"],
          required: false,
        };
      }

      if (!widget.options.bindingParams["topLeaguesPageUrl"]) {
        widget.options.bindingParams["topLeaguesPageUrl"] = {
          label: "Top leagues page URL",
          sources: ["custom"],
          required: false,
        };
      }

      if (!widget.options.bindingParams["allLeaguesPageUrl"]) {
        widget.options.bindingParams["allLeaguesPageUrl"] = {
          label: "All leagues page URL",
          sources: ["custom"],
          required: false,
        };
      }
    });
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v22WidgetMigration;
