<template>
  <div
    :style="containerStyles"
    :class="{
      'sportsbook-live__event-info--row': displayHorizontal,
    }"
    class="sportsbook-live__event-info"
  >
    <div class="sportsbook-live__event-info-item">
      <WidgetField
        :widget="widget"
        :field="teamPlayerField"
        class="sportsbook-live__event-info-text-container"
      >
        <span
          class="text-truncate"
          :style="textStyles"
        >
          Team / Player #1
        </span>
      </WidgetField>

      <WidgetField
        v-if="scoreField.options._active"
        :widget="widget"
        :field="scoreField"
      >
        <span
          class="sportsbook-live__event-info-score"
          :style="scoreStyles"
          >0</span
        >
      </WidgetField>
    </div>
    <div class="sportsbook-live__event-info-item">
      <WidgetField
        :widget="widget"
        :field="teamPlayerField"
        class="sportsbook-live__event-info-text-container"
      >
        <span
          class="text-truncate"
          :style="secondPlayerTextStyles"
        >
          Team / Player #2
        </span>
      </WidgetField>

      <WidgetField
        v-if="scoreField.options._active"
        :widget="widget"
        :field="scoreField"
      >
        <span
          class="sportsbook-live__event-info-score"
          :style="scoreStyles"
          >0</span
        >
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getTextAlignStyle,
  getTextAlignStyleReversed,
} from "~~/assets/utils/widget-settings";
import { getSpacing } from "~~/assets/utils/widget-settings";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  teamPlayerField: IWidgetField;
  scoreField: IWidgetField;
  eventCardField?: IWidgetField;
  eventCardState?: State;
}>();

const textStyles = computed<ElementStyle>(() => {
  let textColor: Record<string, string> = {};
  if (props.eventCardField && props.eventCardState) {
    textColor = getColorStyle(
      props.eventCardField.options.states[props.eventCardState]?.color ??
        props.teamPlayerField.options.color
    );
  }
  return {
    ...getTextCommonStyles(props.teamPlayerField.options),
    ...getTextAlignStyle(props.teamPlayerField.options.alignment),
    ...getSpacing(
      props.teamPlayerField.options.padding,
      SpacingKeyName.PADDING
    ),
    ...textColor,
  };
});

const displayHorizontal = computed<boolean>(
  () =>
    props.field.options.displayMode === "center" ||
    props.field.options.displayMode === "horizontal"
);

const secondPlayerTextStyles = computed<ElementStyle>(() => {
  if (!displayHorizontal.value) {
    return textStyles.value;
  }

  let textColor: Record<string, string> = {};
  if (props.eventCardField && props.eventCardState) {
    textColor = getColorStyle(
      props.eventCardField.options.states[props.eventCardState]?.color ??
        props.teamPlayerField.options.color
    );
  }

  const paddingOptions = props.teamPlayerField.options.padding || {};

  const padding = {
    ...paddingOptions,
    left: paddingOptions.right,
    right: paddingOptions.left,
  };

  return {
    ...getTextCommonStyles(props.teamPlayerField.options),
    ...getTextAlignStyleReversed(props.teamPlayerField.options.alignment),
    ...getSpacing(padding, SpacingKeyName.PADDING),
    ...textColor,
  };
});

const scoreStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(props.scoreField.options),
    ...getCommonStyles(props.scoreField.options),
  };
});

const containerDisplayStyles = computed<ElementStyle>(() => {
  if (displayHorizontal.value) {
    return {
      flexDirection: "row",
    };
  }

  return {
    flexDirection: "column",
    alignItems: "stretch",
  };
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...containerDisplayStyles.value,
    gap: getPxValueFromNumber(props.field.options.distance),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__event-info {
    @include flex(center, flex-start, 0);
    &-item {
      @include flex(center, space-between, 0);
      flex: 1;
      min-width: 0;
    }

    &-score {
      display: inline-block;
      padding: 4px 15px;
      background-color: #4096ff;
      color: #fff;
    }

    &-text-container {
      flex: 1;
      min-width: 0;
      span {
        display: inline-block;
        width: 100%;
      }
    }

    &--row {
      .sportsbook-live__event-info-item:nth-child(2) {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
