import { getDefaultFillValue } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 25;

const generateArrowOptions = () => {
  return {
    color: getDefaultFillValue("#1677ff"),
  };
};

const v25WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["EventWidget"].includes(widget.name)) {
    const options = widget.content.bet_items.options;

    if (!options.arrow) {
      options.arrow = generateArrowOptions();

      Object.keys(options.states).forEach(state => {
        options.states[state].arrow = generateArrowOptions();
      });
    }
  }

  if (["BetslipWidget"].includes(widget.name)) {
    const options = widget.content.coefficient_title.options;

    if (!options.arrow) {
      options.arrow = generateArrowOptions();

      Object.keys(options.states).forEach(state => {
        options.states[state].arrow = generateArrowOptions();
      });
    }
  }

  if (["RacingSportsEventLiveWidget"].includes(widget.name)) {
    const options = widget.content.players_info_player_current_odds.options;

    if (!options.arrow) {
      options.arrow = generateArrowOptions();

      Object.keys(options.states).forEach(state => {
        options.states[state].arrow = generateArrowOptions();
      });
    }
  }

  if (["SportsBookLiveFenixWidget"].includes(widget.name)) {
    const options = widget.content.bet_items.options;

    if (!options.arrow) {
      options.arrow = generateArrowOptions();

      Object.keys(options.states).forEach(state => {
        options.states[state].arrow = generateArrowOptions();
      });
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v25WidgetMigration;
