export const DEFAULT_ADAPTIVE_COL_WIDTH = 100;
export const MIN_FIXED_COL_WIDTH = 200;
export const REPLACE_STR = "%%";

export const widthValidations = {
  MAX_CONTAINER_WIDTH: `Width cannot exceed max screen size of ${REPLACE_STR}px`,
  MIN_CONTAINER_WIDTH: `Width cannot be less than min screen size of ${REPLACE_STR}px`,
  ADAPTIVE_CELL_MAX_WIDTH: `Width for adaptive cell cannot be more than ${DEFAULT_ADAPTIVE_COL_WIDTH}%`,
  ADAPTIVE_CELL_MIN_WIDTH: "Value cannot be 0",
  FIXED_CELL_WIDTH: `Width for fixed cell cannot be less than ${MIN_FIXED_COL_WIDTH}px`,
  TOTAL_EXACT_FIXED_CELLS_WIDTH: `The sum of fixed cells must match the parent width of ${REPLACE_STR}px`,
  TOTAL_FIXED_CELLS_WIDTH: `The sum of fixed cells cannot exceed the parent width of ${REPLACE_STR}px`,
  TOTAL_ADAPTIVE_CELLS_WIDTH: `The sum of adaptive cells should be ${DEFAULT_ADAPTIVE_COL_WIDTH}%`,
  REQUIRED_FIXED_CHILD:
    "There should be at least one fixed column in the fixed parent",
  REQUIRED_ADAPTIVE_CHILD:
    "There should be at least one adaptive column in the adaptive parent",
};
