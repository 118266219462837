import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

export const useSportsbookItems = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const SETTINGS_ITEMS = [
    {
      label: "Header",
      value: "header",
    },
    {
      label: "Leagues",
      value: "leagues_container",
      isActiveValueSource: fields.value.leagues_container.field.options,
    },
    {
      label: "League winner",
      value: "winner_container",
    },
    {
      label: "Events filter",
      value: "filter_container",
    },
    {
      label: "Events",
      value: "events_container",
    },
  ];

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Header", "header"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Button", "live_button_title"),
    generateDropdownItem("Title", "winner_league_title"),
    generateDropdownItem("League winner card", "winner_league_card"),
    generateDropdownItem("Bet items", "winner_league_bet_items"),
    generateDropdownItem("Events title", "list_title"),
    generateDropdownItem("Events filter settings", "filter"),
    generateDropdownItem("Event cards", "list_container"),
    generateDropdownItem("Start time / date", "list_start_time"),
    generateDropdownItem("League", "league_title"),
    generateDropdownItem("Team / Player", "list_event_name"),
    generateDropdownItem("Bet items", "list_item"),
    generateDropdownItem("Event info", "list_event_info"),
    generateDropdownItem("Favorite icon", "list_favourite_icon"),
    generateDropdownItem("Date", "list_date"),

    generateDropdownItem("Build button", "betbuilder_button"),
    generateDropdownItem("Event button link", "list_item_more"),
    generateDropdownItem("From Date", "date_from"),
    generateDropdownItem("To Date", "date_to"),
    generateDropdownItem("Markets", "markets"),
    generateDropdownItem("Leagues", "leagues_container"),
    generateDropdownItem("Items", "league_card"),
    generateDropdownItem("League winner", "winner_container"),
    generateDropdownItem("Events filter", "filter_container"),
    generateDropdownItem("Events", "events_container"),
    generateDropdownItem("League", "league_name"),
    generateDropdownItem("Date", "day_label"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_button_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    winner_league_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    winner_league_bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    filter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
    ],
    list_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    list_item: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    list_item_more: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betbuilder_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    leagues_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    league_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    winner_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    filter_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    list_event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    day_label: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
