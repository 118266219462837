import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { CellSizeType } from "~~/models/grid.interface";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

type KeyNames = {
  width?: string;
  height?: string;
  alignment?: string;
};

type Args = {
  source: IWidgetField | IWidgetWithFields | null;
  except?: string[];
  addElements?: ControlProp[];
  visible?: boolean;
  keyNames?: KeyNames;
  verticalAlignmentVisible?: boolean;
};

export const size = (
  {
    source,
    except,
    addElements,
    visible,
    keyNames,
    verticalAlignmentVisible,
  }: Args,
  title?: string
): ControlProp => {
  const verticalAlignmentIsVisible = computed<boolean>(() => {
    if (typeof verticalAlignmentVisible === "boolean") {
      return verticalAlignmentVisible;
    }

    return source?.options?.size?.height?.type === CellSizeType.FIXED;
  });

  const controls = [
    {
      componentPath: "common/ConfigDimension",
      valuePath: keyNames?.width || "options.size.width",
      valueSource: source,
      options: {
        placeholder: "Enter text",
        type: ColorPickerType.TEXT,
        label: "Width",
        icon: "ant-design:column-width-outlined",
        isWidth: true,
      },
    },
    {
      componentPath: "common/ConfigDimension",
      valuePath: keyNames?.height || "options.size.height",
      valueSource: source,
      options: {
        placeholder: "Enter text",
        type: ColorPickerType.TEXT,
        label: "Height",
        icon: "ant-design:column-height-outlined",
      },
    },
    {
      componentPath: "ConfigVerticalAlignment",
      valuePath: keyNames?.alignment || "options.baseDisplaySettings.alignment",
      visible: verticalAlignmentIsVisible.value,
      valueSource: source,
      options: {
        label: "Alignment",
      },
    },
  ].filter(control => !except?.includes(control.componentPath));

  return {
    section: generateDesignSectionName(title || "Size"),
    controls: [...controls, ...(addElements || [])],
    visible: typeof visible === "boolean" ? visible : true,
  };
};
