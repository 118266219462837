import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const SETTINGS_ITEMS = [
  {
    label: "Dropdown container",
    value: "dropdown_container",
  },
  {
    label: "Dropdown menu",
    value: "dropdown_menu",
  },
  {
    label: "Menu items",
    value: "menu_items",
  },
];

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "description"),
    generateDropdownItem("Buttons container", "buttons_container"),
    generateDropdownItem("Checkboxes container", "form"),
    generateDropdownItem("Checkboxes", "checkboxes_styling"),
    generateDropdownItem("Allow all", "button_allow_all"),
    generateDropdownItem("Allow selection", "button_allow_selection"),
    generateDropdownItem("Deny", "button_deny"),
    generateDropdownItem("Link settings", "linkStates", "linkStates"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    form: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    buttons_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_allow_all: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_deny: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_allow_selection: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    checkboxes_styling: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
