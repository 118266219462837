<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-parent-settings"
  >
    <ConfigColorPickerInput
      :model-value="selectedParentContainerStyles?.backgroundColor"
      :type="ColorPickerType.BACKGROUND"
      label="Background color"
      @update:model-value="
        handleUpdateParentStyling({ backgroundColor: { ...$event } })
      "
    />

    <ConfigVerticalAlignment
      :model-value="selectedParentContainerStyles?.alignment || null"
      class="widget-parent-settings__vertical-alignment wconfig-item"
      @update:model-value="handleUpdateParentStyling({ alignment: $event })"
    />

    <ConfigColumnWidth
      v-if="showSpaceBetween"
      label="Spacing"
      :model-value="selectedParentContainerStyles?.spaceBetween || null"
      class="widget-parent-settings__spacing"
      @update:model-value="handleUpdateParentStyling({ spaceBetween: $event })"
    />

    <ConfigMarginInputs
      :model-value="selectedParentContainerStyles?.margin"
      label="Margin"
      :is-all-side-setting="true"
      @update:model-value="handleUpdateParentStyling({ margin: { ...$event } })"
    />

    <ConfigCellPosition
      :model-value="selectedParentContainerStyles?.position"
      @update:model-value="
        handleUpdateParentStyling({
          position: $event,
        })
      "
    />

    <ConfigCellHeight
      :model-value="selectedParentContainerStyles?.height"
      :is-parent="true"
      @update:model-value="
        handleUpdateParentStyling({
          height: $event,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { ICellOptions } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const gridStore = useGridConfig();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedContainer } = storeToRefs(widgetSettingsStore);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const selectedParentContainerStyles = computed<ICellOptions>(() => {
  if (!selectedContainer.value?.cellId) return {};
  return gridStore.cellsOptions[selectedContainer.value?.cellId];
});

const handleUpdateParentStyling = (style: Partial<ICellOptions>): void => {
  if (!selectedContainer.value?.cellId) {
    return;
  }
  gridStore.updateCellsOptions(selectedContainer.value.cellId, style);
};

const showSpaceBetween = computed<boolean>(() => {
  return (selectedContainer.value?.children || []).length > 1;
});
</script>

<style lang="scss">
.widget-parent-settings {
  &__vertical-alignment {
    padding-top: 0;
  }

  &__spacing {
    border-bottom: unset;
    padding-bottom: 0;
  }
}
</style>
