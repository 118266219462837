<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SearchFieldWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      :style="wrapperStyle"
      class="search-widget"
    >
      <WidgetField
        :widget="widget"
        :field="fields.field_container.field"
      >
        <DropdownTriggerField
          :widget="widget"
          :field="fields.field_container.field"
          :value="fields.field_container.field.options.text.value"
          :placeholder-value="
            fields.field_container.field.options.placeholder.value
          "
          :state="states.field_container"
        />
      </WidgetField>

      <WidgetField
        v-if="
          fields.search_result_box.field.options._active &&
          isSearchResultBoxSelected
        "
        :widget="widget"
        :field="fields.search_result_box.field"
        class="search-widget-results__box"
      >
        <DropdownMenuField :field="fields.search_result_box.field">
          <div :style="searchResultsBoxStyle">
            <WidgetField
              v-for="id in 3"
              :key="id"
              :widget="widget"
              :field="fields.search_result_items.field"
              class="search-widget-results__items"
            >
              <ArrayItemField
                :field="fields.search_result_items.field"
                :wrapper-options="fields.search_result_items.field.options"
                :state="states.search_result_items"
                :is-items-group-selected="false"
              />
            </WidgetField>
          </div>
        </DropdownMenuField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell, Sizing } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useSearchFieldInitData } from "~~/composables/widgets/search/field/useSearchFieldInitData";
import { Alignment } from "~~/models/widgets/widget-controls.model";
import { getFormWidth } from "~~/assets/utils/widget/form";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useSearchFieldInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const wrapperStyle = computed<Record<string, string | number>>(() => {
  if (
    !props.widget.options.content.contentWidth ||
    props.widget.options.content.contentWidth.type === Sizing.ADAPTIVE
  ) {
    return {};
  }

  const styles = {
    ...getFormWidth(props.widget.options.content.contentWidth),
  };

  if (props.widget.options.content.contentWidth.alignment === Alignment.LEFT) {
    return {
      ...styles,
      "margin-right": "auto",
    };
  }

  if (props.widget.options.content.contentWidth.alignment === Alignment.RIGHT) {
    return {
      ...styles,
      "margin-left": "auto",
    };
  }

  return {
    ...styles,
    "margin-left": "auto",
    "margin-right": "auto",
  };
});

const isSearchResultBoxSelected = computed<boolean>(() => {
  return (
    selectedField.value?.name === fields.value.field_container.field.name ||
    selectedField.value?.name === fields.value.search_result_box.field.name ||
    selectedField.value?.name === fields.value.search_result_items.field.name
  );
});

const searchResultsBoxStyle = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "flex-direction": "column",
    "gap": `${fields.value.search_result_items.field.options.spaceBetween}px`,
  };
});

initFields();
</script>

<style lang="scss">
.search-widget {
  position: relative;
  .dropdown-trigger-field {
    .common-icon {
      font-size: 1.5em;
    }
  }
  &-results {
    &__box {
      position: absolute;
      left: 0;
      right: 0;
    }
    &__items {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
