import { Grid } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 41;

const v40WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;
  if (["PaymentProvidersListFenixWidget"].includes(widget.name)) {
    if (!widget.content.providers_list.options.gridSettings) {
      widget.content.providers_list.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      };
    }
  }
  return widget;
};
export default v40WidgetMigration;
