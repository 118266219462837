import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useFormDropdownConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Dropdown
  */

  const formDropdownStyles = computed(() => {
    return [
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.icon",
        valueSource: fields.value.dropdowns_styling.field,
        options: {
          hideColor: true,
          meta: {
            hideToggle: true,
          },
        },
      },
    ];
  });

  /* 
      Dropdown menu
    */

  const formDropdownMenuStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.dropdown_menu_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.dropdown_menu_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.dropdown_menu_styling.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.dropdown_menu_styling.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.dropdown_menu_styling.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  /* 
      Dropdown menu items
    */

  const formDropdownMenuItemsDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          label: "Space between",
          isBold: true,
        },
      },
    ];
  });

  const formDropdownMenuItemsContentStyles = computed(() => {
    return [
      typography({
        source: fields.value.dropdown_menu_items_styling.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const formDropdownMenuItemsStatePath = computed(() => {
    if (states.value.dropdown_menu_items_styling === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.dropdown_menu_items_styling}`;
  });

  const formDropdownMenuItemsStates = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.dropdown_menu_items_styling,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.dropdown_menu_items_styling = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${formDropdownMenuItemsStatePath.value}.fill`,
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${formDropdownMenuItemsStatePath.value}.border`,
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${formDropdownMenuItemsStatePath.value}.shadow`,
        valueSource: fields.value.dropdown_menu_items_styling.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${formDropdownMenuItemsStatePath.value}.color`,
            valueSource: fields.value.dropdown_menu_items_styling.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${formDropdownMenuItemsStatePath.value}.decoration`,
            valueSource: fields.value.dropdown_menu_items_styling.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  return {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  };
};
