<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    class="betslip-status-fenix-widget"
  >
    <BetslipStatusFenixWidgetSettings
      :widget="widget"
      :state="state"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      class="betslip-status-fenix-widget__header-items-container"
      :style="headerContainerInlineStyles"
    >
      <div
        class="betslip-status-fenix-widget__header-item"
        :style="[headerContentGapInlineStyles, headerContentInlineStyles]"
      >
        <div :style="headerLabelInlineDecorationStyles">
          <span>{{ t("widgets.BetslipWidget.betslip") }}</span>
        </div>
        <WidgetField
          v-if="fields.betslip_status.active"
          :widget="widget"
          :field="fields.betslip_status.field"
        >
          <div :style="headerCounterInlineStyles">1</div>
        </WidgetField>
      </div>

      <div
        class="betslip-status-fenix-widget__header-item"
        :style="[headerContentGapInlineStyles, headerContentInlineStyles]"
      >
        <div :style="headerLabelInlineDecorationStyles">
          <span>{{ t("widgets.BetslipWidget.mybets") }}</span>
        </div>
        <WidgetField
          v-if="fields.betslip_status.active"
          :widget="widget"
          :field="fields.betslip_status.field"
        >
          <div :style="headerCounterInlineStyles">1</div>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useBetslipHeaderInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipHeaderInlineStyles";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipStatusInitData } from "~~/composables/widgets/betslip/useBetslipStatusInitData";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { t } = useI18n();

const state = ref<State>(State.DEFAULT);

const handleStateUpdate = (value: State): void => {
  state.value = value;
};

const resetStates = (): void => {
  state.value = State.DEFAULT;
};

const { init } = useBetslipStatusInitData(props.widget);

init();

const fields = useWidgetFields(props.widget);

const {
  headerContainerInlineStyles,
  headerContentGapInlineStyles,
  headerContentInlineStyles,
  headerCounterInlineStyles,
  headerLabelInlineDecorationStyles,
} = useBetslipHeaderInlineStyles(
  ref(props.widget.options),
  ref(fields.value.betslip_status.field.options),
  state
);
</script>

<style lang="scss">
.betslip-status-fenix-widget {
  &__header-items-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item {
    @include flex(center, center, 0px);
  }
}
</style>
