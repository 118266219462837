<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #pending_description>
          <ControlsList
            :widget="widget"
            :controls="descriptionControls"
          />
        </template>

        <template #tabs_container-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs_container-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs_container-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #form-design>
          <ControlsList
            :widget="widget"
            :controls="filterDesignControls"
          />
        </template>

        <template #form-content>
          <ControlsList
            :widget="widget"
            :controls="filterContentControls"
          />
        </template>

        <template #form-styles>
          <ControlsList
            :widget="widget"
            :controls="filterStyleControls"
          />
        </template>

        <template #table-design>
          <ControlsList
            :widget="widget"
            :controls="tableDesignControls"
          />
        </template>

        <template #table-content>
          <ControlsList
            :widget="widget"
            :controls="tableContentControls"
          />
        </template>

        <template #table_header_settings>
          <ControlsList
            :widget="widget"
            :controls="tableHeaderControls"
          />
        </template>

        <template #table_row_settings>
          <ControlsList
            :widget="widget"
            :controls="tableRowsControls"
          />
        </template>

        <template #table_checkboxes_settings>
          <ControlsList
            :widget="widget"
            :controls="tableCheckboxesControls"
          />
        </template>

        <template #date_from>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #date_to>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #transaction_types>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #transaction_subtypes>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #action_button-design>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonDesignControls"
          />
        </template>

        <template #action_button-content>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonContentControls"
          />
        </template>

        <template #action_button-states>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonStatesControls"
          />
        </template>

        <template
          v-for="(controlValues, controlOptionName) in formStylesControls"
          #[controlOptionName]
          :key="controlOptionName"
        >
          <ControlsList
            :widget="widget"
            :controls="controlValues"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/transaction-history/useTransactionHistoryConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,

  titleControls,
  titleLinkStatesControls,

  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,

  filterDesignControls,
  filterContentControls,
  filterStyleControls,

  tableDesignControls,
  tableContentControls,

  tableHeaderControls,
  tableRowsControls,
  tableCheckboxesControls,

  formStylesControls,
  textInputFieldControls,
  dropdownFieldControls,

  cancelButtonStatesControls,
  cancelButtonDesignControls,
  cancelButtonContentControls,

  descriptionControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  resetStates();
});
</script>
