import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  SliderArrowIcon,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

type Args = {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  arrowsFieldName?: string;
  paginationFieldName?: string;
};

export const useCarouselConfig = (
  {
    widget,
    states,
    arrowsFieldName = "arrows",
    paginationFieldName = "pagination",
  }: Args,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const arrowsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value[arrowsFieldName].field,
    });
  });

  const arrowsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.content.icon.size",
        valueSource: fields.value[arrowsFieldName].field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      ...getInitialDesignList({
        source: fields.value[arrowsFieldName].field,
        basePath: "options.content",
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.content.alignment",
        valueSource: fields.value[arrowsFieldName].field,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.content.icon.value",
        valueSource: fields.value[arrowsFieldName].field,
        className: "group-control-element container-widget__arrows-settings",
        options: {
          label: "Icon",
          items: [
            { icon: SliderArrowIcon.OUTLINED, value: SliderArrowIcon.OUTLINED },
            { icon: SliderArrowIcon.ARROW, value: SliderArrowIcon.ARROW },
            { icon: SliderArrowIcon.CARET, value: SliderArrowIcon.CARET },
          ],
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.content.icon.color",
        valueSource: fields.value[arrowsFieldName].field,
        options: {
          placeholder: "None",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
    ];
  });

  const stateValue = computed<string | null>(() => {
    return states.value[arrowsFieldName];
  });

  const currentStatePath = computed<string>(() => {
    if (states.value[arrowsFieldName] === State.DEFAULT) {
      return "options.content";
    }

    return `options.states.${states.value[arrowsFieldName]}.content`;
  });

  const arrowsStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateValue.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "arrows", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: fields.value[arrowsFieldName].field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: fields.value[arrowsFieldName].field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: fields.value[arrowsFieldName].field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.icon.color`,
            valueSource: fields.value[arrowsFieldName].field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const paginationDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value[paginationFieldName].field,
    });
  });

  const paginationContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.isStroke",
        valueSource: fields.value[paginationFieldName].field,
        className: "group-control-element",
        options: {
          shrink: true,
          customIconWidth: "calc(100% - 24px)",
          items: [
            {
              label: "Bullets",
              value: false,
              icon: "custom:bullets",
            },
            {
              label: "Strokes",
              value: true,
              icon: "custom:strokes",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        valueSource: fields.value[paginationFieldName].field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 8,
          maxValue: 48,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: fields.value[paginationFieldName].field,
        className: "group-control-element",
        options: {
          placeholder: "None",
          label: "Color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: fields.value[paginationFieldName].field,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
    ];
  });

  return {
    arrowsDesignControls,
    arrowsContentControls,
    arrowsStateControls,
    paginationDesignControls,
    paginationContentControls,
  };
};
