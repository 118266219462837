type IQueueItem = () => Promise<void> | void;

export type IUseQueueHandler = ReturnType<typeof useQueueHandler>;

export const useQueueHandler = () => {
  const queue = ref<IQueueItem[]>([]);

  const addToQueue = (item: IQueueItem) => {
    queue.value.push(item);
  };

  const reset = () => {
    queue.value = [];
  };

  const submitQueue = async () => {
    //TODO: refactor to promise all
    for (const item of queue.value) {
      await item();
    }
  };

  return {
    queue,

    reset,
    addToQueue,
    submitQueue,
  };
};
