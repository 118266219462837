<template>
  <ConfigRadioTextElements
    v-if="hasDesignPermissions"
    class="p-l-0 p-b-0 p-r-0 border-bottom-none"
    label="Position"
    :items="[
      { value: SliderItemsPosition.INSIDE, label: 'Inside' },
      { value: SliderItemsPosition.OUTSIDE, label: 'Outside' },
    ]"
    :model-value="modelValue"
    @update:model-value="handleUpdate"
  />
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { SliderItemsPosition } from "~~/models/widgets/widget-controls.model";

defineProps<{
  modelValue: SliderItemsPosition;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: SliderItemsPosition): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: SliderItemsPosition) => {
  emit("update:modelValue", value);
};
</script>
