<template>
  <div
    v-click-outside.prevent="handleClickOutside"
    class="widgets-tree-context-menu"
  >
    <ul>
      <li
        class="widgets-tree-context-menu__option"
        @click.stop="handleDuplicateNode(node)"
      >
        <CommonIcon
          name="ant-design:copy-outlined"
          class="widgets-tree-context-menu__context-menu-icon"
        />
        Duplicate
      </li>
      <li
        class="widgets-tree-context-menu__option"
        @click.stop="handleCopyNodeToClipboard(node)"
      >
        <CommonIcon
          name="ant-design:export-outlined"
          class="widgets-tree-context-menu__context-menu-icon"
        />
        Copy to clipboard
      </li>
      <li
        class="widgets-tree-context-menu__option"
        @click.stop="handlePasteNodeFromClipboard(node)"
      >
        <CommonIcon
          name="ant-design:import-outlined"
          class="widgets-tree-context-menu__context-menu-icon"
        />
        Paste from clipboard
      </li>
      <li
        v-if="node.widgetData?.options._tag"
        class="widgets-tree-context-menu__option"
        @click.stop="handleChangeNodeOnAllPages"
      >
        <CommonIcon
          name="ant-design:cluster-outlined"
          class="widgets-tree-context-menu__context-menu-icon"
        />
        Change on all pages
      </li>
      <li
        class="widgets-tree-context-menu__option--red"
        @click="handleDeleteNode(node)"
      >
        <CommonIcon
          name="ant-design:delete-outlined"
          class="widgets-tree-context-menu__context-menu-icon"
        />
        Delete
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";

import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useWidgetClipboardCopy } from "~~/composables/widgets/useWidgetClipboardCopy";
import { CellId, ICell } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";

import { ITreeNode } from "./WidgetsTreeTab.vue";

defineProps<{
  node: ITreeNode;
}>();

const isContextMenuOpened = defineModel<boolean>("isContextMenuOpened", {
  required: true,
});

const widgetStore = useWidgetsStore();
const notification = useNotification();
const widgetClipboardCopyHandler = useWidgetClipboardCopy();
const widgetSettingsStore = useWidgetSettingsStore();

const handleClickOutside = () => {
  isContextMenuOpened.value = false;
};

const findCellLocal = (cellId: CellId | null): ICell | null => {
  return findCell(useGridConfig().currentWidgetsLayout, cellId);
};

const handleDuplicateNode = (node: ITreeNode) => {
  const cell = findCellLocal(node.widgetData?.cell_id || null);

  if (cell && node.widgetData) {
    widgetStore.duplicateWidget(node.widgetData, node.parentWidgetData, cell);

    notification.notify({
      text: "Duplicate widget",
      type: "success",
    });
  } else {
    notification.notify({
      text: "Fail to duplicate widget",
      type: "error",
    });
  }
};

const handleWidgetClipboardAction = (
  node: ITreeNode,
  action: "copy" | "paste"
) => {
  const cell = findCellLocal(node.widgetData?.cell_id || null);

  if (!cell) {
    return notification.notify({
      text: `Fail to ${action} widget`,
      type: "error",
    });
  }

  const widgetIndex = useGridConfig().findWidgetIndexInCell(
    cell.cellId,
    node.key
  );

  if ((!widgetIndex && widgetIndex !== 0) || widgetIndex < 0) {
    return notification.notify({
      text: `Fail to ${action} widget`,
      type: "error",
    });
  }

  if (action === "copy") {
    widgetClipboardCopyHandler.copyWidget(
      cell,
      node.parentWidgetData,
      widgetIndex
    );
  } else if (action === "paste") {
    widgetClipboardCopyHandler.pasteWidget(
      cell,
      node.parentWidgetData,
      widgetIndex
    );
  }
};

const handleCopyNodeToClipboard = (node: ITreeNode) => {
  handleWidgetClipboardAction(node, "copy");
};

const handlePasteNodeFromClipboard = (node: ITreeNode) => {
  handleWidgetClipboardAction(node, "paste");
};

const handleChangeNodeOnAllPages = () => {
  widgetSettingsStore.updateTagDrawerValue(true);
};

const handleDeleteNode = (node: ITreeNode) => {
  const widget = node.widgetData;

  if (!widget) {
    return notification.notify({
      text: "Fail to delete widget",
      type: "error",
    });
  }

  const cell = findCellLocal(widget.cell_id || null);

  if (cell && widget.cell_id) {
    const parentWidget =
      node.parentWidgetData !== null && node.parentWidgetData !== undefined
        ? node.parentWidgetData
        : useGridConfig().findWidget(widget.cell_id, widget.parentId || 0) ||
          undefined;

    widgetStore.deleteWidget(widget, parentWidget, cell);

    notification.notify({
      text: "Delete widget",
      type: "success",
    });
  } else {
    notification.notify({
      text: "Fail to delete widget",
      type: "error",
    });
  }
};
</script>

<style scoped lang="scss">
.widgets-tree-context-menu {
  position: absolute;
  z-index: 1;
  right: 0px;
  padding: $space-xs;
  border-radius: 8px;
  background-color: $c-white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  &__option {
    @include flex(center, flex-start, 8px);
    padding: 4px 15px;
    color: $c-black;

    &--red {
      @include flex(center, flex-start, 8px);
      padding: 4px 15px;
      color: $c-icon-font-red;
    }
  }
}
</style>
