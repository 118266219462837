import { FillType } from "~~/models/widgets/widget-controls.model";

import { getDefaultBorderValue } from "../common/widget-initial";

export const cardStatesInitial = () => {
  return {
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
  };
};
