import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  DisplayOrientation,
  Grid,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetBindingParamsObject,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  WIDGET_INITIAL,
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultSizeValue,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import {
  ARROW_INITIAL,
  TABS_INITIAL,
} from "~~/constants/configs/bets-list-common/initial-contants";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useBetsListWidgetInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions, addFieldInitialOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    event_cards: State.WITH_ACTIVE_EVENTS,
    tabs: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    arrow: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {
      maxEvents: {
        source: "custom",
        value: "5",
      },
      sorting: {
        source: "custom",
        value: "by_popular",
      },
      eventsInterval: {
        source: "custom",
        value: "24",
      },
      sportIds: {
        source: "custom",
        value: [],
      },
    } as unknown as IWidgetBindingParamsObject,
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /*
    This needed for dnd
  */
  // if (
  //   parentCell.value?.settings.sizing === Sizing.FIXED &&
  //   initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  // ) {
  //   initialWidgetOptions.contentWidth = {
  //     type: Sizing.FIXED,
  //     width: parentCell.value?.settings.width,
  //     alignment: initialWidgetOptions.contentWidth.alignment,
  //   };
  // }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialTabsContainerOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: TABS_INITIAL(),
        additionalData: {
          options: {
            container: {},
            text: {
              _active: true,
            },
          },
        },
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_cards.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distanceBetweenItems: 16,
              displayMode: DisplayOrientation.HORIZONTAL,
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            gridSettings: {
              grid: Grid.FIXED,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 1,
              minCardsInRow: 1,
            },
          },
        },
      }).options
    );

    const initialTimeDateOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            width: getDefaultSizeValue(ResizingType.HUG),
            icon: {
              _active: true,
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
              valueDate: "ant-design:calendar-filled",
              valueTime: "ant-design:clock-circle-filled",
            },
            spaceBetween: 12,
            displayMode: "date",
            margins: getDefaultSpacing({
              right: 8,
            }),
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            spaceBetween: 8,
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing({
              top: 5,
              right: 8,
              bottom: 5,
              left: 8,
            }),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        },
      }).options
    );

    const initialListItemMoreState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue(),
      };
    };

    const initialEventButtonOptions = prefillEmptyOptions(
      fields.value.event_button.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            width: getDefaultSizeValue(ResizingType.HUG),
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [TextStyle.BOLD],
            states: {
              [State.HOVER]: initialListItemMoreState(),
              [State.ACTIVE]: initialListItemMoreState(),
              [State.DISABLED]: initialListItemMoreState(),
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing({
              top: 5,
              bottom: 5,
              left: 8,
              right: 8,
            }),
            margin: getDefaultSpacing({
              left: 16,
            }),
          },
          fill: getDefaultFillValue("#000000", 6),
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        },
      }).options
    );

    const initialArrowOptions = prefillEmptyOptions(
      fields.value.arrow.field.options,
      getInitialOptions({
        data: ARROW_INITIAL(),
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_cards.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialEventButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialEventButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.arrow.field,
      initialArrowOptions
    );

    addFieldInitialOptions("header", {
      data: WIDGET_INITIAL(),
      exclude: ["link", "padding", "alignment", "baseDisplaySettings", "size"],
      values: {
        displayOrientation: DisplayOrientation.VERTICAL,
      },
    });
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
