<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
    class="container-widget__arrow-content"
    :style="containerStyle"
  >
    <div
      :style="arrowStyle"
      class="container-widget__arrow"
    >
      <CommonIcon
        :name="arrowIcon"
        class="container-widget__arrow-icon"
      />
    </div>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import { ElementStyle } from "~~/models/common";
import {
  IWidgetWithFields,
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import {
  getColorStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  state: string;
}>();

const arrowIcon = computed<string>(
  () => props.field.options.content.icon.value
);

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options.content;
  }

  return props.field.options.states[props.state].content;
});

const containerStyle = computed<ElementStyle>(() => {
  return {
    ...getStylesAsVars(
      {
        ...getColorStyle(activeStateOptions.value.icon.color),
        alignment: getFlexPosition(props.field.options.content.alignment),
        size: getPxValueFromNumber(props.field.options.content.icon.size),
        fontSize: getPxValueFromNumber(
          Math.max(Math.floor(props.field.options.content.icon.size / 2), 1)
        ),
      },
      "icon"
    ),
  };
});

const arrowStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.field.options.content,
      ...activeStateOptions.value,
    }),
  };
});
</script>

<style lang="scss" scoped>
.container-widget {
  &__arrow-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: var(--icon-alignment);
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--icon-size);
    height: var(--icon-size);
    color: var(--icon-color);
    font-size: var(--icon-fontSize);
  }
}
</style>
