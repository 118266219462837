import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

import { useFieldContainerControls } from "../../search/field/useFieldContainerControls";

import { useCasinoGamesSearchWidgetControls } from "./useCasinoGamesSearchWidgetControls";

export const useCasinoGamesSearchWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Field container", "field_container"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    field_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const { widgetDesignControls, widgetContentControls } =
    useCasinoGamesSearchWidgetControls(widget);

  const {
    fieldContainerDesignControls,
    fieldContainerContentControls,
    fieldContainerStatesControls,
  } = useFieldContainerControls(widget, states, emit);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    fieldContainerDesignControls,
    fieldContainerContentControls,
    fieldContainerStatesControls,
  };
};
