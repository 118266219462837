import {
  IVisibleFor,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";

type Args = {
  source: IWidgetField | IWidgetWithFields | null;
  except?: string[];
  only?: string[];
  elements?: [number, ControlProp][];
  basePath?: string;
  isBold?: boolean;
};

export const getInitialDesignList = ({
  source,
  except,
  elements,
  only,
  basePath,
  isBold = true,
}: Args): ControlProp[] => {
  const currPath = basePath || "options";

  const showWidgetSpecificControls = computed<boolean>(() => {
    /*
      visible property should be boolean to apply
    */
    return source?.options?._isWidget || false;
  });

  let designControls: ControlProp[] = [
    {
      componentPath: "common/ConfigCommonSelect",
      valuePath: `${currPath}._visibleFor`,
      valueSource: source,
      visible: showWidgetSpecificControls.value,
      options: {
        label: "Visible",
        placeholder: "For all users",
        isBold,
        selectOptions: [
          {
            label: "For all users",
            value: null,
          },
          {
            label: "For authorized",
            value: IVisibleFor.AUTHORIZED,
          },
          {
            label: "For unauthorized",
            value: IVisibleFor.UN_AUTHORIZED,
          },
          {
            label: "When toggled",
            value: IVisibleFor.WHEN_TOGGLED,
          },
        ],
      },
    },
    {
      componentPath: "ConfigLanguageVisibility",
      valuePath: `${currPath}._isVisible`,
      valueSource: source,
      visible: showWidgetSpecificControls.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "content/ConfigNonLocalizedInput",
      valuePath: `${currPath}._tag`,
      valueSource: source,
      visible: showWidgetSpecificControls.value,
      options: {
        placeholder: "None",
        showOptions: false,
        label: "Tag",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${currPath}.fill`,
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${currPath}.border`,
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Border",
        isBold,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${currPath}.shadow`,
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: `${currPath}.cornerRadius`,
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: `${currPath}.spacing`,
      valueSource: source,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ];

  if (except) {
    designControls = designControls.filter(
      control => !except?.includes(control.componentPath as string)
    );
  }

  if (only) {
    designControls = designControls.filter(control =>
      only?.includes(control.componentPath as string)
    );
  }

  if (elements && elements.length) {
    elements.forEach(el => {
      designControls.splice(el[0], 0, el[1]);
    });
  }

  return designControls;
};
