import {
  getDefaultBorderValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const buttonStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
  };
};
