import { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";

import { useWidgetWithArrayField } from "../useWidgetWithArrayField";
import { useWidgetFields } from "../useWidgetFields";
import { useItemControls } from "../dropdown/useItemControls";
import { useItemsControls } from "../dropdown/useItemsControls";

import { useProfileDropdownWidgetControls } from "./useProfileDropdownWidgetControls";
import { useButtonControls } from "./useButtonControls";
import { useDropdownContainerControls } from "./useDropdownContainerControls";
import { useTitleControls } from "./useTitleControls";
import { useItemsGroupControls } from "./useItemsGroupControls";
import { useBalanceControls } from "./useBalanceControls";

export const useProfileDropdownWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  widgetState: ComputedRef<State> | Ref<State>,
  depositState: ComputedRef<State>,
  logoutState: ComputedRef<State>,
  registrationState: ComputedRef<State>,
  loginState: ComputedRef<State>,
  titleState: ComputedRef<State>,
  itemsGroupState: ComputedRef<State>,
  balanceItemsState: ComputedRef<State>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const { titleField, arrayField, itemsGroup } = useWidgetWithArrayField(
    widget.value,
    FieldName.DROPDOWN
  );

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Button", "deposit", "deposit"),
    generateDropdownItem("Button", "logout", "logout"),
    generateDropdownItem("Button", "login", "login"),
    generateDropdownItem("Button", "registration", "registration"),
    generateDropdownItem("Dropdown", "dropdownContainer", "dropdownContainer"),
    generateDropdownItem("Dropdown container", "dropdown"),
    generateDropdownItem("Dropdown menu", "items"),
    generateDropdownItem("Menu items", "itemsGroup"),
    generateDropdownItem("Item", "item", "item"),
    generateDropdownItem("Balance", "balanceContainer", "balanceContainer"),
    generateDropdownItem("Items", "balanceItems", "balanceItems"),
    generateDropdownItem("Item", "balance", "balance"),
    generateDropdownItem("Item", "bonus", "bonus"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    deposit: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    logout: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    login: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    registration: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    dropdownContainer: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    dropdown: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    balanceContainer: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    balanceItems: [
      generateTabItem("Design", "design"),
      generateTabItem("States", "states"),
    ],
  };

  const { widgetDesignControls, widgetContentControls } =
    useProfileDropdownWidgetControls(widget, widgetState, emit);

  const {
    buttonDesignControls: depositDesignControls,
    buttonContentControls: depositContentControls,
    buttonStatesControls: depositStatesControls,
  } = useButtonControls(ref(fields.value.deposit.field), depositState, emit);

  const {
    buttonDesignControls: logoutDesignControls,
    buttonContentControls: logoutContentControls,
    buttonStatesControls: logoutStatesControls,
  } = useButtonControls(
    ref(fields.value.logout.field),
    logoutState,
    emit,
    true
  );

  const {
    buttonDesignControls: registrationDesignControls,
    buttonContentControls: registrationContentControls,
    buttonStatesControls: registrationStatesControls,
  } = useButtonControls(
    ref(fields.value.registration.field),
    registrationState,
    emit
  );

  const {
    buttonDesignControls: loginDesignControls,
    buttonContentControls: loginContentControls,
    buttonStatesControls: loginStatesControls,
  } = useButtonControls(ref(fields.value.login.field), loginState, emit);

  const { dropdownContainerDesignControls, dropdownContainerContentControls } =
    useDropdownContainerControls(
      ref(fields.value.dropdownContainer.field),
      titleField
    );

  const { titleDesignControls, titleContentControls, titleStatesControls } =
    useTitleControls(titleState, titleField, emit);

  const { itemsControls } = useItemsControls(arrayField);

  const {
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  } = useItemsGroupControls(itemsGroup, itemsGroupState, emit);

  const { itemControls } = useItemControls();

  const {
    balanceDesignControls,
    balanceContentControls,
    balanceItemsDesignControls,
    balanceItemsStatesControls,
  } = useBalanceControls(fields, balanceItemsState, emit);

  const { itemControls: balanceItemControls } = useItemControls();

  return {
    DROPDOWN_ITEMS,
    TABS,

    widgetDesignControls,
    widgetContentControls,
    depositDesignControls,
    depositContentControls,
    depositStatesControls,
    logoutDesignControls,
    logoutContentControls,
    logoutStatesControls,
    registrationDesignControls,
    registrationContentControls,
    registrationStatesControls,
    loginDesignControls,
    loginContentControls,
    loginStatesControls,
    dropdownContainerDesignControls,
    dropdownContainerContentControls,
    titleDesignControls,
    titleContentControls,
    titleStatesControls,
    itemsControls,
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
    itemControls,
    balanceDesignControls,
    balanceContentControls,
    balanceItemsDesignControls,
    balanceItemsStatesControls,
    balanceItemControls,
  };
};
