import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IContentField } from "~~/models/page.model";
import {
  generateBetItemsCssString as generateRacingBetItems,
  generateRowDisabledCssString,
} from "~~/assets/utils/widget-css/widgets/racing-sports-event-live-widget-css";
import { generateBetItemsCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-live-widget-css";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateIconCssString,
  generateIconSizeCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateBorderStyle,
  generateFillColorStyle,
  generateFlex,
  generateFontSize,
  generateHeight,
  generateMaxWidth,
  generateMinWidth,
  generatePaddingStyle,
  generateShadowStyle,
  generateTextAlignStyle,
  generateTextColor,
  generateWidth,
} from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getPxValueFromNumber } from "../..";

import {
  generateBetBuilderSpacingCssString,
  generateIframeCssString,
  generateStateIconCssString,
} from "./1event-widget-css";
import {
  generateCardsContainerCssString,
  generateCardsGridContainerCssString,
  generateContainerCssString,
} from "./racing-sports-event-live-widget-css";

export const generateBannerCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: `padding: 36px;`,
    });

    return cssString;
  };

export const generateMarketsContainerCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content:
        `column-count: ${element.options.display.layout};` +
        `gap: ${getPxValueFromNumber(element.options.display.distance)};`,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " > div",
      content: `margin-bottom: ${getPxValueFromNumber(
        element.options.display.distance
      )};`,
    });

    return cssString;
  };

export const generateMarketCardCssString =
  (
    element: IContentField,
    iconElement: IContentField,
    titleElement: IContentField,
    statusElement: IContentField
  ) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content:
        generateFlex({
          flex: "inline-flex",
          direction: "column",
          justify: "flex-start",
          align: "stretch",
          gap: element.options.display.distance,
        }) + "width: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: element.options._headerCssClass,
      content: generateFlex({
        flex: "flex",
        justify: "flex-start",
        align: "center",
      }),
    });

    cssString += generateCssClassWithContent({
      className: iconElement.options._cssClass,
      content: generatePaddingStyle(iconElement.options.padding),
    });

    cssString += generateCssClassWithContent({
      className: titleElement.options._cssClass,
      content: "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;",
    });

    if (element.options.display.layout === 1) {
      cssString += generateCssClassWithContent({
        className: titleElement.options._cssClass,
        content: "flex:1;",
      });
    }

    if (element.options.display.layout === 2) {
      cssString += generateCssClassWithContent({
        className: titleElement.options._cssClass,
        content: "order:1;",
      });

      cssString += generateCssClassWithContent({
        className: statusElement.options._cssClass,
        content: "order:2;",
      });

      cssString += generateCssClassWithContent({
        className: iconElement.options._cssClass,
        content: "order:3;margin-left:auto;",
      });
    }

    if (element.options.display.layout === 3) {
      cssString += generateCssClassWithContent({
        className: titleElement.options._cssClass,
        content: "order:-1;flex:1;",
      });
    }

    return cssString;
  };

export const generatePaddingCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generatePaddingStyle(element.options.padding),
    });

    return cssString;
  };

export const generateBetItemsCustomCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    if (element.options.displayMode === DisplayOrientation.VERTICAL) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: `grid-auto-flow:column;grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
        `,
      });

      cssString += generateCssClassWithContent({
        className: element.options._multipleCssClass,
        content: `grid-template-columns: repeat(2, 1fr);grid-auto-flow:row;`,
      });
    }

    return cssString;
  };

export const generateLiveHeaderCssString =
  (element: IContentField, titleElement: IContentField) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: element.options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options) + flex,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      childClassName: "sport-event-live__league-name",
      content: generateWidth(100, "%"),
    });

    cssString += generateCssClassWithContent({
      className: titleElement.options._cssClass,
      content:
        "flex:1;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;",
    });

    return cssString;
  };

export const generateLiveHeaderSwitchCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      content: flex,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " svg",
      content:
        generateTextColor(element.options.icon.color) +
        generateFontSize(element.options.icon.size),
    });

    /* 
      active
    */

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      pseudoClassName: ` button[data-state="active"] .${element.options._cssClass}`,
      content: generateDefaultStyles(element.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      pseudoClassName: ` button[data-state="active"] .${element.options._cssClass} svg`,
      content: generateTextColor(element.options.states.active.icon.color),
    });

    /* 
      hover
    */
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ":hover",
      content: generateDefaultStyles(element.options.states.hover),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ":hover svg",
      content: generateTextColor(element.options.states.hover.icon.color),
    });

    return cssString;
  };

export const generateIconSpacingCssString =
  (icon: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: icon._cssClass,
      content: `margin-right: ${getPxValueFromNumber(icon.distance)}`,
    });

    return cssString;
  };

const generateScoreContainerCss =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
      gap: element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      content: generateDefaultStyles(element.options.typography || {}) + flex,
    });

    return cssString;
  };

const generateEventInfoCssString =
  (element: IContentField, justify = "space-around") =>
  (cssString: string): string => {
    if (!element) return cssString;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify,
      gap: element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options) + flex,
    });

    return cssString;
  };

const generateStatisticsCss =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const defaultStyles =
      generateDefaultStyles(element.options) +
      generateDefaultStyles(element.options.typography) +
      "word-break:break-all;";

    let displayStyles = `${generateTextAlignStyle(
      element.options.display.alignment
    )}`;

    if (element.options.display.resizing === ResizingType.FILL) {
      displayStyles += `${generateWidth(50, "%")}`;
    } else {
      displayStyles += `${generateWidth(element.options.display.width, "%")}`;
    }

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: defaultStyles + displayStyles,
    });

    return cssString;
  };

const generateFooterCss =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options) + flex,
    });

    return cssString;
  };

const generateBannerUnAuthorizedCss =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const currentFill = element.options.states.unauthorized.fill;

    cssString += generateCssClassWithContent({
      className: element.options._unAuthorizedCssClass,
      content: generateFillColorStyle(currentFill),
    });

    return cssString;
  };

const generatePrematchBannerAdditionalCss =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const flex = generateFlex({
      flex: "flex",
      direction: "column",
      align: "center",
      justify: "unset",
      gap: element.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      content: generateDefaultStyles(element.options) + flex,
    });

    return cssString;
  };

const generateHeaderTitlesCss =
  (element: IContentField, secondElement: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const secondElementDisplay = secondElement.options.display;
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify:
        element.options.display.alignmentType === "alignment"
          ? element.options.display.alignment
          : element.options.display.justifyContent,
      gap: element.options.display.distance,
    });

    let displayStyles = "";

    if (secondElementDisplay.resizing === ResizingType.FILL) {
      displayStyles = `${generateWidth(50, "%")}`;
    } else {
      displayStyles = `${generateWidth(100 - secondElementDisplay.width, "%")}`;
    }

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options) + flex + displayStyles,
    });

    return cssString;
  };

const generateValuesCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: generateDefaultStyles(element.options),
    });

    const winnerConfig = element.options.winner;

    if (winnerConfig) {
      cssString += generateCssClassWithContent({
        className: element.options._winnerCssClass,
        content:
          generateFillColorStyle(winnerConfig.fill, true) +
          generateShadowStyle(winnerConfig.shadow, true) +
          generateBorderStyle(winnerConfig.border, true) +
          generateTextColor(winnerConfig.color, true),
      });
    }

    const defaultFlex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
    });

    /* Small css */
    const smallOptions = element.options.states.small_size;

    cssString += generateCssClassWithContent({
      className: element.options._blockSmallCssClass,
      content:
        generateDefaultStyles(smallOptions.typography) +
        generateWidth(smallOptions.block.width, "px") +
        generateHeight(smallOptions.block.height) +
        "text-align: center;" +
        defaultFlex,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cellSmallCssClass,
      content:
        generateWidth(100, "%") +
        generateMinWidth(`${smallOptions.cell.minCellWidth}px`) +
        generateMaxWidth(`${smallOptions.cell.maxCellWidth}px`) +
        defaultFlex,
    });
    /* Big css */
    const bigOptions = element.options.states.big_size;

    cssString += generateCssClassWithContent({
      className: element.options._blockBigCssClass,
      content:
        generateDefaultStyles(bigOptions.typography) +
        generateWidth(bigOptions.block.width, "px") +
        generateHeight(bigOptions.block.height) +
        "text-align: center;" +
        defaultFlex,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cellBigCssClass,
      content:
        generateWidth(100, "%") +
        generateMinWidth(`${bigOptions.cell.minCellWidth}px`) +
        generateMaxWidth(`${bigOptions.cell.maxCellWidth}px`) +
        defaultFlex,
    });
    return cssString;
  };

const generateStageButtonAdditionalCssString =
  (element: IContentField) =>
  (cssString: string): string => {
    if (!element) return cssString;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: element.options.buttonDisplaySettings.alignment,
    });

    cssString += generateCssClassWithContent({
      className: element.options._containerCssClass,
      content: flex + generateWidth("100", "%"),
    });

    return cssString;
  };

export const generateSportEventFenixCss = (widget: IWidgetOptions): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.markets_cards.options._headerCssClass = generateClassName(
    "markets_cards_header"
  );

  prefilled.bet_items.options._cssClass = generateClassName("bet_items");
  prefilled.bet_items.options._multipleCssClass =
    generateClassName("bet_items_multiple");

  prefilled.bet_items.options._itemCssClass =
    generateClassName("bet_items_item");
  prefilled.bet_items.options._itemSelectedCssClass =
    prefilled.bet_items.options._itemCssClass;
  prefilled.bet_items.options._itemUpCssClass =
    prefilled.bet_items.options._itemCssClass;
  prefilled.bet_items.options._itemDownCssClass =
    prefilled.bet_items.options._itemCssClass;

  prefilled.bet_items.options.name._cssClass =
    generateClassName("bet_items_name");
  prefilled.bet_items.options.coef._cssClass =
    generateClassName("bet_items_coef");

  prefilled.live_header_sport_league_name.options.icon._cssClass =
    generateClassName("live_header_sport_league_name_icon");

  prefilled.live_header_view_mode_switch.options._cssClass = generateClassName(
    "live_header_view_mode_switch_item"
  );
  prefilled.live_header_view_mode_switch.options._containerCssClass =
    generateClassName("live_header_view_mode_switch_container");

  prefilled.live_header_view_mode_switch.options.icon._cssClass =
    generateClassName("live_header_view_mode_switch_container");

  /* 
    table
  */

  prefilled.racing_player_cards.options._containerCssClass = generateClassName(
    "racing_player_cards_container"
  );

  /* 
    Table bet items
  */
  prefilled.racing_odds.options._notRunnerCssClass = generateClassName(
    "racing_odds_not_runner"
  );
  prefilled.racing_odds.options._itemCssClass =
    generateClassName("bet_items_item");
  prefilled.racing_odds.options._itemSelectedCssClass = generateClassName(
    "bet_items_item_selected"
  );
  prefilled.racing_odds.options._itemUpCssClass =
    generateClassName("bet_items_item_up");
  prefilled.racing_odds.options._itemDownCssClass = generateClassName(
    "bet_items_item_down"
  );

  if (prefilled.live_banner) {
    prefilled.live_banner.options._unAuthorizedCssClass = generateClassName(
      "live_banner_unauthorized"
    );
    prefilled.live_banner_login_button.options.iconSettings._cssClass =
      generateClassName("racing_banner_login_button_icon");
    prefilled.live_banner_login_button.options._containerCssClass =
      generateClassName("live_banner_login_button_container");

    prefilled.live_banner_event_info_score.options._containerCssClass =
      generateClassName("score_container");

    prefilled.racing_banner.options._unAuthorizedCssClass = generateClassName(
      "racing_banner_unauthorized"
    );
    prefilled.racing_banner_login_button.options.iconSettings._cssClass =
      generateClassName("racing_banner_login_button_icon");
    prefilled.racing_banner_login_button.options._containerCssClass =
      generateClassName("racing_banner_login_button_container");

    prefilled.live_banner_header_value_titles.options._blockSmallCssClass =
      generateClassName("live_banner_value_titles_block_small");
    prefilled.live_banner_header_value_titles.options._blockBigCssClass =
      generateClassName("live_banner_value_titles_block_big");
    prefilled.live_banner_header_value_titles.options._cellSmallCssClass =
      generateClassName("live_banner_value_titles_cell_small");
    prefilled.live_banner_header_value_titles.options._cellBigCssClass =
      generateClassName("live_banner_value_titles_cell_big");

    prefilled.live_banner_header_point_title.options._blockSmallCssClass =
      generateClassName("live_banner_point_block_small");
    prefilled.live_banner_header_point_title.options._blockBigCssClass =
      generateClassName("live_banner_point_block_big");
    prefilled.live_banner_header_point_title.options._cellSmallCssClass =
      generateClassName("live_banner_point_cell_small");
    prefilled.live_banner_header_point_title.options._cellBigCssClass =
      generateClassName("live_banner_point_cell_big");

    prefilled.live_banner_rows_team_player_info_values.options._winnerCssClass =
      generateClassName("live_banner_rows_player_info_values_block_winner");
    prefilled.live_banner_rows_team_player_info_values.options._blockSmallCssClass =
      generateClassName("live_banner_rows_player_info_values_block_small");
    prefilled.live_banner_rows_team_player_info_values.options._blockBigCssClass =
      generateClassName("live_banner_rows_player_info_values_block_big");
    prefilled.live_banner_rows_team_player_info_values.options._cellSmallCssClass =
      generateClassName("live_banner_rows_player_info_values_cell_small");
    prefilled.live_banner_rows_team_player_info_values.options._cellBigCssClass =
      generateClassName("live_banner_rows_player_info_values_cell_big");

    prefilled.live_banner_rows_team_player_info_point.options._winnerCssClass =
      generateClassName("live_banner_rows_team_player_info_point_winner");
    prefilled.live_banner_rows_team_player_info_point.options._blockSmallCssClass =
      generateClassName("live_banner_rows_player_info_point_block_small");
    prefilled.live_banner_rows_team_player_info_point.options._blockBigCssClass =
      generateClassName("live_banner_rows_player_info_point_block_big");
    prefilled.live_banner_rows_team_player_info_point.options._cellSmallCssClass =
      generateClassName("live_banner_rows_player_info_point_cell_small");
    prefilled.live_banner_rows_team_player_info_point.options._cellBigCssClass =
      generateClassName("live_banner_rows_player_info_point_cell_big");
  }

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(prefilled.no_events_title),
    generateBannerCssString(prefilled.banner),
    generateMarketsContainerCssString(prefilled.markets_container),
    generateMarketCardCssString(
      prefilled.markets_cards,
      prefilled.markets_pin_icon,
      prefilled.markets_market_name,
      prefilled.markets_status
    ),
    generateStringDefault(prefilled.markets_market_name),
    generateStringDefault(prefilled.markets_status),
    generateIconCssString(prefilled.markets_pin_icon.options),
    generateIconSizeCssString(prefilled.markets_pin_icon.options),
    generateBetItemsCssString(prefilled.bet_items as IWidgetField),
    generateBetItemsCustomCssString(prefilled.bet_items as IWidgetField),
    generateStringDefault(prefilled.betting_constructor),
    generateStringDefault(prefilled.betting_constructor_content),
    generateStringWithStates(prefilled.betting_constructor_header, false),
    generateIconCssString(
      prefilled.betting_constructor_header.options.arrowSettings
    ),
    generateStateIconCssString(prefilled.betting_constructor_header.options),
    generateBetBuilderSpacingCssString(prefilled.betting_constructor_header),
    generateStringDefault(prefilled.betting_constructor_description),
    generateIframeCssString(prefilled.betting_constructor),

    generateLiveHeaderCssString(
      prefilled.live_header,
      prefilled.live_header_sport_league_name
    ),
    generateIconCssString(prefilled.live_header_back_button.options),
    generateIconSizeCssString(prefilled.live_header_back_button.options),
    generatePaddingCssString(prefilled.live_header_back_button),

    generateStringDefault(prefilled.live_header_sport_league_name),
    generateIconCssString(prefilled.live_header_sport_league_name.options.icon),
    generateIconSizeCssString(
      prefilled.live_header_sport_league_name.options.icon
    ),
    generateIconSpacingCssString(
      prefilled.live_header_sport_league_name.options.icon
    ),

    generateLiveHeaderSwitchCssString(prefilled.live_header_view_mode_switch),

    generateStringDefault(prefilled.racing_event_info),
    generateStringDefault(prefilled.racing_title),
    generateStringDefault(prefilled.racing_description),

    /* 
      table
    */

    generateStringDefault(prefilled.racing_players_info),
    generateStringDefault(prefilled.racing_header_card),
    generateStringDefault(prefilled.racing_player_cards),
    generateCardsContainerCssString(
      prefilled.racing_player_cards.options._containerCssClass,
      prefilled.racing_players_info
    ),
    generateContainerCssString(prefilled.racing_players_info),
    generateCardsGridContainerCssString(
      prefilled.racing_players_info,
      prefilled.racing_header_card,
      prefilled.racing_player_cards
    ),
    generateRacingBetItems(prefilled.racing_odds as IWidgetField),

    generateStringDefault(prefilled.racing_number),
    generateStringDefault(prefilled.racing_player_name),
    generateStringDefault(prefilled.racing_last_5_races),
    generateStringDefault(prefilled.racing_prev_odds),

    generateRowDisabledCssString(prefilled.racing_player_cards, {
      number: prefilled.racing_number,
      name: prefilled.racing_player_name,
      lastRaces: prefilled.racing_last_5_races,
      prevOdds: prefilled.racing_prev_odds,
      currOdds: prefilled.racing_odds,
    }),

    generateStageButtonCssString(
      prefilled.live_banner_login_button as IWidgetField
    ),
    generateStageButtonAdditionalCssString(prefilled.live_banner_login_button),

    generateStringDefault(prefilled.live_banner_login_text),

    generateStringDefault(prefilled.live_banner),
    generateBannerUnAuthorizedCss(prefilled.live_banner),

    generateEventInfoCssString(prefilled.live_banner_event_info),
    generateStringDefault(
      prefilled.live_banner_event_info_name,
      [],
      "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;max-width:50%;"
    ),

    generateStringDefault(prefilled.live_banner_event_info_score),
    generateScoreContainerCss(prefilled.live_banner_event_info_score),

    generateEventInfoCssString(prefilled.live_banner_header, "space-between"),

    generateStatisticsCss(prefilled.live_banner_header_statistics),

    generateHeaderTitlesCss(
      prefilled.live_banner_header_titles,
      prefilled.live_banner_header_statistics
    ),

    generateValuesCssString(prefilled.live_banner_header_value_titles),
    generateValuesCssString(prefilled.live_banner_header_point_title),

    generateEventInfoCssString(prefilled.live_banner_rows, "space-between"),
    generateStatisticsCss(prefilled.live_banner_rows_team_player),

    generateHeaderTitlesCss(
      prefilled.live_banner_rows_team_player_info,
      prefilled.live_banner_rows_team_player
    ),
    generateValuesCssString(prefilled.live_banner_rows_team_player_info_values),
    generateValuesCssString(prefilled.live_banner_rows_team_player_info_point),

    generateFooterCss(prefilled.live_banner_footer),
    generateStringDefault(prefilled.live_banner_footer_time),
    generateStringDefault(prefilled.live_banner_footer_period),

    generateStringDefault(prefilled.racing_banner),
    generateBannerUnAuthorizedCss(prefilled.racing_banner),

    generateStringDefault(prefilled.racing_banner_event_description),
    generateStringDefault(prefilled.racing_banner_login_text),
    generateStageButtonCssString(
      prefilled.racing_banner_login_button as IWidgetField
    ),
    generateStageButtonAdditionalCssString(
      prefilled.racing_banner_login_button
    ),

    generateStringDefault(prefilled.prematch_banner),
    generatePrematchBannerAdditionalCss(prefilled.prematch_banner),
    generateStringDefault(
      prefilled.prematch_banner_event_title,
      [],
      "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;"
    ),
    generateStringDefault(prefilled.prematch_banner_event_time)
  )("");
};
