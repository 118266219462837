<script lang="ts" setup>
import { usePagesStore } from "~~/store/pages";

const pagesStore = usePagesStore();
const props = defineProps<{
  handler: () => any;
}>();

const loading = ref<boolean>(false);
const loadingError = ref<boolean>(false);

onMounted(async () => {
  loading.value = true;

  try {
    await props.handler();
    pagesStore.setPageSaveStatus(true);
  } catch (error) {
    pagesStore.setPageSaveStatus(false);
    console.error(error);
    loadingError.value = true;
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <div>
    <div v-if="loading">
      <CommonSpinner />
    </div>

    <div v-else-if="loadingError">
      <h1 class="error">Loading error! 😵</h1>
    </div>

    <div v-else>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
