import type { Ref } from "vue";

import { useItems } from "~~/composables/widgets/transaction-history/useTransactionHistoryItems";
import { useWidgetInitialDesignOptions } from "~~/composables/widgets/common/useWidgetInitialDesignControls";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useTitleControls } from "~~/composables/widgets/items/useTitleControls";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useFormDropdownConfig } from "~~/composables/widgets/form/useFormDropdownConfig";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useFormDatePickerConfig } from "~~/composables/widgets/form/useFormDatepickerConfig";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { useTitleLinkStateControls } from "~~/composables/widgets/card/useTitleLinkStatesControls";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { Sizing } from "~~/models/grid.interface";
import { useNoItemsTextConfig } from "~~/composables/widgets/common/useNoItemsTextConfig";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

const SETTINGS_ITEMS = [
  {
    label: "Title settings",
    value: "title",
  },
  {
    label: "Description settings",
    value: "pending_description",
  },
  {
    label: "Tabs settings",
    value: "tabs_container",
  },
  {
    label: "Filter settings",
    value: "form",
  },
  {
    label: "Table settings",
    value: "table",
  },
  {
    label: "Button settings",
    value: "action_button",
  },
];

const TABLE_SETTINGS_ITEMS = [
  {
    label: "Header settings",
    value: "table_header_settings",
  },
  {
    label: "Rows settings",
    value: "table_row_settings",
  },
  {
    label: "Checkbox settings",
    value: "table_checkboxes_settings",
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);
  const widgetSettingsStore = useWidgetSettingsStore();

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);
  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  /* 
    Widget
  */
  const { widgetDesignControls } = useWidgetInitialDesignOptions(widget);
  const { isWidthConfigDisabled } = useWidthConfig();

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        valueSource: widget.value,
        className:
          widget.value.options.contentWidth.type === Sizing.FIXED
            ? "group-control-element"
            : "",
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
    Title
  */

  const titleState = computed<State>(() => {
    return states.value.title;
  });

  const titleField = computed<IWidgetField>(() => {
    return fields.value.title.field;
  });

  const { titleControls } = useTitleControls(
    {
      field: titleField,
      state: titleState,
    },
    emit
  );

  const { titleLinkStatesControls } = useTitleLinkStateControls(
    states,
    undefined,
    emit,
    titleField,
    undefined,
    "title"
  );

  /* 
    description
  */

  const descriptionControls = computed(() => {
    return [
      typography({
        source: fields.value.pending_description.field,
        except: ["link"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.pending_description.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Tabs
  */

  const tabsDesignControls = computed(() => {
    return getInitialDesignList({ source: fields.value.tabs_container.field });
  });

  const isDisplaySettingsAlignmentVisible = computed<boolean>(() => {
    return (
      fields.value.tabs_container.field.options.buttonDisplaySettings
        .resizing === ResizingType.HUG
    );
  });

  const tabsContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: fields.value.tabs_container.field,
        className: "group-control-element",
        options: {
          showAlignmentOnHugOnly: true,
        },
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.distance",
        className: isDisplaySettingsAlignmentVisible.value ? "p-t-0" : "",
        valueSource: fields.value.tabs_container.field,
        options: {
          label: "Distance between tabs",
        },
      },
      typography({
        source: fields.value.tabs_container.field,
        except: ["link"],
      }),
    ];
  });

  const currentTabsStatePath = computed<string>(() => {
    if (states.value.tab === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.tab}`;
  });

  const tabsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value.tab,
        onUpdate: value => {
          emit("update-state", { state: "tab", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.tabs_container.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: currentTabsStatePath.value,
      }),
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentTabsStatePath.value}.color`,
            valueSource: fields.value.tabs_container.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
    ];
  });

  /* 
    Filter
  */

  const filterDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.form.field,
      elements: [
        [
          4,
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.distance",
            valueSource: fields.value.form.field,
            options: {
              label: "Distance between fields",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const formContentFields = computed<IWidgetField[]>(() => {
    return [
      fields.value.date_from.field,
      fields.value.date_to.field,
      fields.value.transaction_types.field,
      fields.value.transaction_subtypes.field,
    ];
  });

  const filterContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.displayMode",
        valueSource: fields.value.form.field,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            {
              label: "Horizontal",
              value: "horizontal",
            },
            {
              label: "Vertical",
              value: "vertical",
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigFormItems",
        valuePath: "",
        valueSource: null,
        options: {
          fields: {
            visible: formContentFields.value,
            hidden: [],
          },
          isOrderAllowed: false,
          customEditHandler(field: IWidgetField) {
            widgetSettingsStore.setActiveElement(selectedWidget.value, field);
          },
        },
      },
    ];
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields);
  });

  const filterStyleControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  /* 
    Table
  */

  const sizeItems = [
    { value: 20, label: "Large" },
    { value: 12, label: "Middle" },
    { value: 8, label: "Small" },
  ];

  const tableDesignControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.horizontalSize",
            valueSource: fields.value.table.field,
            options: {
              label: "Horizontal space",
              items: sizeItems,
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.verticalSize",
            valueSource: fields.value.table.field,
            options: {
              label: "Vertical space",
              items: sizeItems,
            },
          },
        ],
      },

      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.table.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        section: generateDesignSectionName("Border"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.borderType",
            valueSource: fields.value.table.field,
            options: {
              label: "Type",
              isBold: true,
              items: [
                { value: "between_rows", label: "Between rows" },
                { value: "bordered", label: "Bordered" },
              ],
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: "options.border",
            valueSource: fields.value.table.field,
            options: {
              placeholder: "None",
              label: "Color",
            },
          },
        ],
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.table.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.table.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.table.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: "items",
      noTextFieldName: "no_events_title",
      customLabels: {
        [State.ACTIVE_EVENTS]: "There are transactions",
        [State.NO_ACTIVE]: "No transactions",
      },
    }),
    emit
  );

  const tableContentControls = computed(() => {
    if (states.value.items === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.mode",
        valueSource: fields.value.table.field,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            { value: "table", label: "Table" },
            { value: "cards", label: "Cards" },
          ],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: TABLE_SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
    Table header
  */

  const tableHeaderControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.table_header_settings.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      typography({
        source: fields.value.table_header_settings.field,
        except: ["link"],
      }),
      {
        section: generateDesignSectionName("Separator"),
        visible: fields.value.table.field.options.mode === "table",
        controls: [
          {
            componentPath: "ConfigBorder",
            valuePath: "options.separator",
            valueSource: fields.value.table_header_settings.field,
            options: {
              placeholder: "None",
              label: "Color",
            },
          },
        ],
      },
    ];
  });

  /* 
    Table rows
  */

  const tableRowsControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.table_row_settings.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      typography({
        source: fields.value.table_row_settings.field,
        except: ["link"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.table_row_settings.field,
            options: {
              placeholder: "Enter text",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.table_row_settings.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  /* 
    Table checkboxes
  */

  const currentCheckboxStatePath = computed<string>(() => {
    if (states.value.table_checkboxes_settings === State.SELECTED_DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.table_checkboxes_settings}`;
  });

  const tableCheckboxesControls = computed(() => {
    return [
      stateConfig({
        source: states.value.table_checkboxes_settings,
        statesList: [
          {
            label: STATE_KEY_LABELS.selected_default,
            value: State.SELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.selected_hover,
            value: State.SELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.unselected_default,
            value: State.UNSELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.unselected_hover,
            value: State.UNSELECTED_HOVER,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "table_checkboxes_settings", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.table_checkboxes_settings.field,
        basePath: currentCheckboxStatePath.value,
        except: [
          "ConfigCornerRadius",
          "ConfigSpacingInputs",
          "ConfigBoxShadow",
        ],
      }),
      {
        section: generateDesignSectionName("Check icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxStatePath.value}.checkIcon.color`,
            valueSource: fields.value.table_checkboxes_settings.field,
            options: {
              placeholder: "None",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
    Cancel button
  */

  const cancelButtonState = computed(() => {
    return states.value.action_button;
  });

  const {
    buttonStatesControls: cancelButtonStatesControls,
    buttonDesignControls: cancelButtonDesignControls,
    buttonContentControls: cancelButtonContentControls,
  } = useSimpleButtonControls(cancelButtonState, "action_button", emit);

  /* 
    Filter elements controls
  */
  const { textInputFieldControls, dropdownFieldControls } =
    useFormContentConfig();

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);
  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  } = useFormDatePickerConfig(widget, states);

  const formStylesControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "tooltips_styling-design": formTooltipDesignStyles.value,
      "tooltips_styling-content": formTooltipContentStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
      "calendar_styling-design": formCalendarDesignStyles.value,
      "calendar_styling-content": formCalendarContentStyles.value,
      "calendar_styling-states": formCalendarStates.value,
      "datepicker_styling": formDatePickerStyles.value,
      "dropdowns_styling": formDropdownStyles.value,
      "dropdown_menu_styling": formDropdownMenuStyles.value,
      "dropdown_menu_items_styling-design":
        formDropdownMenuItemsDesignStyles.value,
      "dropdown_menu_items_styling-content":
        formDropdownMenuItemsContentStyles.value,
      "dropdown_menu_items_styling-states": formDropdownMenuItemsStates.value,
    };
  });

  return {
    DROPDOWN_ITEMS,
    TABS,

    widgetDesignControls,
    widgetContentControls,

    titleControls,
    titleLinkStatesControls,

    descriptionControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    filterDesignControls,
    filterContentControls,
    filterStyleControls,

    tableDesignControls,
    tableContentControls,

    tableHeaderControls,
    tableRowsControls,
    tableCheckboxesControls,

    formStylesControls,
    textInputFieldControls,
    dropdownFieldControls,

    cancelButtonStatesControls,
    cancelButtonDesignControls,
    cancelButtonContentControls,
  };
};
