<template>
  <div
    v-if="hasDesignPermissions"
    class="p-t-16 p-b-16 page-config-alignment widget-control-container"
  >
    <div class="wconfig-common-radio__radio-group-container">
      <a-radio-group
        :value="modelValue"
        class="wconfig-common-radio__radio-group w-100"
        @update:value="handleAlignmentClick($event)"
      >
        <a-radio-button
          v-for="(item, idx) in ALIGN_VALUES"
          :key="idx"
          :value="item.value"
          :title="item.title"
          :checked="item.value === modelValue"
        >
          <CommonIcon
            class="page-config-alignment__dot"
            name="custom:dot-small"
          />

          <slot name="icon">
            <CommonIcon name="ant-design:picture-filled" />
          </slot>
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";

withDefaults(
  defineProps<{
    modelValue?: string;
  }>(),
  {
    modelValue() {
      return "";
    },
  }
);

const ALIGN_VALUES = [
  {
    title: "Top Left",
    icon: "lt",
    value: "top_left",
  },
  {
    title: "Top Center",
    icon: "ct",
    value: "top_center",
  },
  {
    title: "Top Right",
    icon: "rt",
    value: "top_right",
  },
  {
    title: "Center Left",
    icon: "lc",
    value: "center_left",
  },
  {
    title: "Center Center",
    icon: "cc",
    value: "center_center",
  },
  {
    title: "Center Right",
    icon: "rc",
    value: "center_right",
  },
  {
    title: "Bottom Left",
    icon: "lb",
    value: "bottom_left",
  },
  {
    title: "Bottom Center",
    icon: "cb",
    value: "bottom_center",
  },
  {
    title: "Bottom Right",
    icon: "rb",
    value: "bottom_right",
  },
];

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleAlignmentClick = (value: string): void => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
$config-prefix: "page-config-alignment";

.#{$config-prefix} {
  .wconfig-common-radio__radio {
    &-group {
      &-container {
        padding: 0.5rem 0;
        border: 1px solid $c-grey-15;
        border-radius: 6px;
      }
    }
  }

  .#{$config-prefix}__dot {
    position: relative;
    &::before {
      content: "";
      display: block;
      transition: all 0.2s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 5px;
      height: 5px;
    }

    &:hover {
      svg {
        opacity: 0;
      }

      &::before {
        background-color: $c-grey-06;
        width: 20px;
        height: 20px;
      }
    }
  }

  .common-icon {
    font-size: 1.3em;
    &:not(.#{$config-prefix}__dot) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
    }
  }
  .ant-radio {
    &-group {
      @include flex($gap: 0, $wrap: true);
    }
    &-button-wrapper {
      @include inline-flex(center, center, 0);
      flex: 1 0 33.33333%;
      border: none;
      outline: none;
      &:active,
      &:focus,
      &:focus-within,
      &::before {
        content: none;
        border: none;
        box-shadow: none;
      }
      &-checked {
        .common-icon {
          visibility: visible;
        }
      }
    }
  }
}
</style>
