import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/promoted-bets-list/usePromotedBetsItems";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useBonusesPhotoConfig } from "~~/composables/widgets/bonuses/useBonusesPhotoConfig";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { usePromotedCardsConfig } from "~~/composables/widgets/promoted-bets-list/usePromotedCardsConfig";
import { usePromotedInfoConfig } from "~~/composables/widgets/promoted-bets-list/usePromotedInfoConfig";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS } = useItems();

  /* 
    Widget config
  */
  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Cards",
              value: "event_card",
            },
          ],
        },
      },
    ];
  });

  const { cardsDesignControls, cardsContentControls, cardsStatesControls } =
    usePromotedCardsConfig(widget, states, emit);

  const {
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventTimeControls,
  } = usePromotedInfoConfig(widget);

  /* 
    Photo
  */

  const { baseImageControls: basePhotoControls } = useBonusesPhotoConfig(
    widget,
    "event_image"
  );

  const photoControls = computed<ControlProp[]>(() => {
    return [
      ...basePhotoControls.value,
      ...getInitialDesignList({
        source: fields.value.event_image.field,
        except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
        elements: [
          [
            3,
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: "options.padding",
              valueSource: fields.value.event_image.field,
              options: {
                label: "Padding",
              },
            },
          ],
        ],
      }),
    ];
  });

  /* 
    Bet items
  */

  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit);

  /* 
    Button
  */

  const buttonState = computed<string>(() => {
    return states.value.event_button;
  });

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls({
      state: buttonState,
      exclude: {
        content: ["ConfigLinkType"],
      },
      stateHandler: (state: State) => {
        emit("update-state", { state: "event_button", value: state });
      },
    });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,

    photoControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,

    teamPlayerTitleControls,

    eventTimeControls,
  };
};
