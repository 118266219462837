import { DirectiveBinding, watch } from "vue";

import { useStaticTranslations } from "~~/store/staticTranslations";
import { TranslationCategory } from "~~/models/stores/static-translation-store.model";

function getTranslation(
  key: string,
  translations: { [key: string]: string }
): string {
  return translations?.[key] ?? key;
}

const vTranslate = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const key = binding.value as string;
    const arg = binding.arg as TranslationCategory;

    const staticTranslations = useStaticTranslations();
    const translations = staticTranslations.staticTranslations;
    // TODO: remove this code in the future
    const translation = getTranslation(key, translations[arg] as never);
    el.textContent = translation;

    watch(
      () => staticTranslations.staticTranslations?.[arg],
      newTranslations => {
        const newTranslation = getTranslation(key, newTranslations as never);
        el.textContent = newTranslation;
      }
    );
  },
};

export default vTranslate;
