<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <ControlsList
            :widget="widget"
            :controls="widgetDataControls"
          />
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title-design>
          <ControlsList
            :widget="widget"
            :controls="titleDesignControls"
          />
        </template>

        <template #title-content>
          <ControlsList
            :widget="widget"
            :controls="titleContentControls"
          />
        </template>

        <template #title-states>
          <ControlsList
            :widget="widget"
            :controls="titleStatesControls"
          />
        </template>

        <template #sports-design>
          <ControlsList
            :widget="widget"
            :controls="sportsDesignControls"
          />
        </template>

        <template #sports-content>
          <ControlsList
            :widget="widget"
            :controls="sportsContentControls"
          />
        </template>

        <template #sports-states>
          <ControlsList
            :widget="widget"
            :controls="sportsStatesControls"
          />
        </template>

        <template #event_card-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardDesignControls"
          />
        </template>

        <template #event_card-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardContentControls"
          />
        </template>

        <template #event_card-states>
          <ControlsList
            :widget="widget"
            :controls="eventCardStatesControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="startTimeDateControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>

        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStatesControls"
          />
        </template>

        <template #sports_event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #sports_event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>
        <template #sports_event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStatesControls"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="eventLeagueControls"
          />
        </template>

        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #event_name>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #leagues-design>
          <ControlsList
            :widget="widget"
            :controls="leaguesDesignControls"
          />
        </template>

        <template #leagues-content>
          <ControlsList
            :widget="widget"
            :controls="leaguesContentControls"
          />
        </template>

        <template #hover_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #hover_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>

        <template #hover_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetsListFenixMobileConfig } from "~~/composables/widgets/bets-list-fenix-mobile/useBetsListFenixMobileConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "betDisplayMode": {
      label: "Bet display mode",
      sources: ["custom"],
      description: "Possible values: Name | Code",
      required: true,
    },
    "fullNameActive": {
      label: "Full name active",
      sources: ["custom"],
      description: "Possible values: Yes | No",
      required: true,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "sportPageURL": {
      label: "Sport page URL",
      sources: ["custom"],
      required: true,
    },
    "leaguePageURL": {
      label: "League page URL",
      sources: ["custom"],
      required: true,
    },
    "sportIds": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "maxEvents": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "sorting": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "eventsInterval": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  /* 
      Controls
    */
  widgetDesignControls,
  widgetContentControls,
  widgetDataControls,

  titleDesignControls,
  titleContentControls,
  titleStatesControls,

  sportsDesignControls,
  sportsContentControls,
  sportsStatesControls,

  eventCardDesignControls,
  eventCardContentControls,
  eventCardStatesControls,

  startTimeDateControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventButtonLinkDesignControls,
  eventButtonLinkContentControls,
  eventButtonLinkStatesControls,

  headerDesignControls,
  headerContentControls,

  eventLeagueControls,

  favIconControls,

  eventNameControls,

  leaguesDesignControls,
  leaguesContentControls,
} = useBetsListFenixMobileConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
