<template>
  <div
    v-if="
      currentField?.options.buttonTextSettings._active && hasDesignPermissions
    "
    class="config-button-text-settings widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <ConfigColorPickerInput
      :model-value="modelValue.textColor"
      :type="ColorPickerType.TEXT"
      label="Text color"
      class="config-button-text-settings__color-picker"
      @update:model-value="handleUpdate('textColor', $event)"
    />

    <ConfigThemeSelect
      :model-value="modelValue.theme"
      class="config-button-text-settings__theme-select"
      @update:model-value="handleUpdate('theme', $event)"
    />

    <ConfigFontFamilySelect
      :model-value="modelValue.fontFamily"
      class="config-button-text-settings__font-family-select"
      @update:model-value="handleUpdate('fontFamily', $event)"
    />

    <ConfigDecoration
      :model-value="modelValue.decoration"
      class="config-button-text-settings__decoration"
      @update:model-value="handleUpdate('decoration', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { TextStyle } from "~~/models/widgets/widget-controls.model";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue: {
    textColor: { [key: string]: string };
    theme: string;
    fontFamily: string;
    decoration: TextStyle[];
  };
  fieldName?: string;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: { [key: string]: { [key: string]: string } | string | TextStyle[] }
  ): void;
  (e: "update:active", event: boolean): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentField: IWidgetField | undefined = getCurrentField(
  props.fieldName
).value;

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-button-text-settings {
  &__color-picker {
    padding: 0;
    border-bottom: unset;
  }

  &__theme-select,
  &__font-family-select,
  &__decoration {
    padding: $space-m 0 0;
    border-bottom: unset;
  }
}
</style>
