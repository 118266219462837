import {
  generateWidgetField,
  isSimpleValue,
  prefillEmptyOptions,
} from "~~/assets/utils/widget";
import type { Data } from "~~/assets/utils/common/init-data";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { InnerFieldValue } from "~~/models/page.model";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { Sizing } from "~~/models/grid.interface";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { WIDGET_INITIAL } from "~~/constants/configs/common/widget-initial";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { useLanguagesStore } from "~~/store/languages";
import { generateId } from "~~/assets/utils";
import { findAnyStartsWith } from "~~/helpers/findAnyStartsWith";
import { DEFAULT_CONTENT_VALUES } from "~~/constants";

type CreateFieldParams = {
  skipFormFields?: boolean;
};

export const useCreateFields = (
  widget: IWidgetWithFields,
  formFilterFields?: Array<string>,
  omitFillCustomFields?: boolean,
  excludeFormFields?: string[]
) => {
  const { widgetCacheData } = useWidgetCacheData(widget);
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const {
    isLocalized,
    transformToLocalizedValue,
    getLocalizedValueFromDefaultLanguage,
  } = useLocalizedValue();

  const { availableLanguagesCodes } = storeToRefs(useLanguagesStore());

  const createFields = (params: CreateFieldParams = {}): void => {
    const { skipFormFields } = params;

    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      if (skipFormFields) {
        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, excludeFormFields)
      );

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }
    widgetsStore.updateWidgetFields(widget, [
      ...widgetFields,
      ...formFields.filter(
        field =>
          !formFilterFields?.length || formFilterFields.includes(field.name)
      ),
    ]);
  };

  const initFields = (params: CreateFieldParams = {}): void => {
    if (!Object.keys(widget.options).length) {
      createFields(params);
    } else if (!omitFillCustomFields) {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }
  };

  const widgetInitialVisibilityOptions = Object.keys(
    availableLanguagesCodes.value
  ).reduce((res, curr) => {
    return {
      ...res,
      [curr]: true,
    };
  }, {});

  const initWidgetOptions = (
    exclude?: string[],
    customOptions?: IWidgetOptions,
    values?: IWidgetOptions
  ) => {
    const initialWidgetOptions = prefillEmptyOptions(
      widget.options,
      getInitialOptions({
        data: WIDGET_INITIAL(),
        exclude: exclude,
        additionalData: {
          options: {
            bindingParams: {},
            contentWidth: {
              type: Sizing.ADAPTIVE,
              width: 320,
              alignment: "center",
            },
            _isVisible: widgetInitialVisibilityOptions,
            _visibleFor: null,
            _tag: "",
            ...(customOptions || {}),
          },
        },
        values,
      }).options
    );

    /*
    This needed for dnd
  */
    // if (
    //   parentCell.value?.settings.sizing === Sizing.FIXED &&
    //   initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
    // ) {
    //   initialWidgetOptions.contentWidth = {
    //     type: Sizing.FIXED,
    //     width: parentCell.value?.settings.width,
    //     alignment: initialWidgetOptions.contentWidth.alignment,
    //   };
    // }

    return initialWidgetOptions;
  };

  const _tryToApplyValue = (field: IWidgetField, assignedValue: any): void => {
    const fieldValue = getLocalizedValueFromDefaultLanguage.value(field.value);
    const hasExistingData = Boolean(field.value);
    const isDefaultValue = findAnyStartsWith(
      DEFAULT_CONTENT_VALUES,
      fieldValue
    );
    const shouldApplyAssignedValue =
      assignedValue && (!hasExistingData || isDefaultValue);

    if (shouldApplyAssignedValue) {
      if (assignedValue !== undefined) {
        if (typeof assignedValue === "string") {
          field.value = transformToLocalizedValue(assignedValue).localizedValue;
        } else {
          field.value = assignedValue;
        }
      }
    }
  };

  const generateDummyField = ({
    name,
    value,
    type,
    ...data
  }: Partial<IWidgetField>) => ({
    id: generateId(),
    type: type ?? WIDGET_FIELD_TYPES.UNKNOWN,
    value: value ?? null,
    name: name,
    options: {
      _active: true,
      _isWidget: false,
    },
    ...data,
  });

  const addFieldInitialOptions = (
    fieldName: string,
    initialData: {
      data: Data;
      exclude?: string[];
      additionalData?: Data;
      values?: Record<string, any>;
    },
    assignedData?: Partial<IWidgetField>
  ): void => {
    if (!fields.value[fieldName]) {
      console.error(`Field ${fieldName} does not exist. Generating field...`);
      // Generating fallback
      const dummyField = generateDummyField({
        ...assignedData,
        name: fieldName,
      });
      widgetsStore.updateWidgetFields(widget, [
        ...widget.fields,
        dummyField as IWidgetField,
      ]);
    }
    const field = fields.value[fieldName].field;

    _tryToApplyValue(field, assignedData?.value);

    const initialOptions = field.options;

    const prefilledData = prefillEmptyOptions(
      initialOptions,
      getInitialOptions(initialData).options
    );

    widgetsStore.updateFieldOptions(field, prefilledData);
  };

  return {
    addFieldInitialOptions,

    createFields,
    initWidgetOptions,
    initFields,
    generateDummyField,
  };
};
