import { useWidgetsStore } from "~~/store/widgets";
import { isSimpleValue, prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  Align,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultAlignValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { InnerFieldValue } from "~~/models/page.model";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

import { useWidgetCacheData } from "../../common/useWidgetCacheData";
import { useWidgetFields } from "../../useWidgetFields";

const LIST_FIELDS = [
  "form",
  "categories",
  "providers",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

export const useCasinoGamesFiltersWidgetInitData = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const fields = useWidgetFields(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const INITIAL_STATES: Record<string, State> = {
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...INITIAL_STATES,
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, ["title"])
      );
    }
    widgetsStore.updateWidgetFields(widget, [...widget.fields, ...formFields]);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }
  };

  const initWidgetFieldsOptions = () => {
    const initialWidgetOptions = initWidgetOptions(
      [],
      {
        bindingParams: {},
      },
      {
        fill: getDefaultFillValue("#ffffff"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing({
            top: 0,
            bottom: 24,
          }),
        },
      }
    );

    const initialFormOptions = prefillEmptyOptions(
      fields.value.form.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            distance: 24,
            displayMode: DisplayOrientation.HORIZONTAL,
            alignment: getDefaultAlignValue([Align.START, Align.START]),
          },
        },
      }).options
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
    widgetsStore.updateFieldOptions(
      fields.value.form.field,
      initialFormOptions
    );
  };

  const fieldsList = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field => LIST_FIELDS.includes(field.name));
  });

  return {
    states,
    fieldsList,
    INITIAL_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
