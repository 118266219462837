import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { TextStyle } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(
    [],
    {
      display: {
        distance: 8,
      },
    },
    {
      fill: getDefaultFillValue("#ffffff"),
      spacing: {
        margin: getDefaultSpacing(0),
        padding: getDefaultSpacing(16),
      },
    }
  );

  const init = (): void => {
    initFields();

    addFieldInitialOptions("account_number", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "alignment"],
      additionalData: {
        options: {
          icon: {
            size: 16,
            color: getDefaultFillValue("#69B1FF"),
            distance: 12,
            value: "ant-design:copy-outlined",
          },
        },
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("username", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "alignment"],
      values: {
        theme: getDefaultTheme(13),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,

    init,
  };
};
