import { type Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../useWidgetFields";

export const useBetItemsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Bet items.
  */

  const betItemsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  const showDisplayMode = computed<boolean>(() => {
    /* 
      Show display mode control only for 1 and 2 columns layout.
    */
    return fields.value.market_cards.field.options.columns < 3;
  });

  const betItemsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: fields.value.market_cards.field,
            visible: showDisplayMode.value,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: fields.value.market_cards.field,
            className: "group-control-element",
            options: {
              label: "Distance between bets items",
            },
          },
        ],
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: selectedField.value,
        options: {
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.name.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.name.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.name.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.coef.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.coef.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.coef.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  const currentBetItemPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
            {
              label: STATE_KEY_LABELS.up,
              value: State.UP,
            },
            {
              label: STATE_KEY_LABELS.down,
              value: State.DOWN,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow color",
            },
          },
        ],
      },
    ];
  });

  return {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  };
};
