export const toSorted = <T>(
  array: T[],
  callback: (a: T, b: T) => number
): T[] => {
  // @ts-ignore
  if (typeof Array.prototype.toSorted === "function") {
    // @ts-ignore
    return array.toSorted(callback);
  }

  return [...array].sort(callback);
};
