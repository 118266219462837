<template>
  <div
    class="basic-slider-pagination"
    :style="styles"
  >
    <CommonIcon
      v-for="(_, i) in size"
      :key="i"
      :name="icon"
      :class="[
        'basic-slider-pagination__item',
        isStroke && 'basic-slider-pagination__item--stroke',
        i === index && 'basic-slider-pagination__item--active',
      ]"
    />
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { getPxValueFromNumber } from "~~/assets/utils";
import { getColorFromHex } from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { TEXT_SLIDER_PAGINATION_HEIGHT } from "~~/constants/configs/text-slider/settings";
import {
  Alignment,
  type IFillControl,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  index: number;
  size: number;
  isStroke: boolean;
  color: IFillControl;
  alignment: Alignment;
}>();

const icon = computed<string>(
  () => `custom:${props.isStroke ? "stroke" : "dot"}`
);

const styles = computed<CSSProperties>(() => ({
  justifyContent: props.alignment,
  ...getStylesAsVars({
    iconColor: getColorFromHex(props.color) || "",
    height: getPxValueFromNumber(TEXT_SLIDER_PAGINATION_HEIGHT),
  }),
}));
</script>

<style lang="scss">
.basic-slider-pagination {
  display: flex;
  gap: 12px;
  width: 100%;
  height: var(--height);
  align-items: center;
  justify-content: center;

  &__item {
    color: var(--iconColor);
    opacity: 50%;

    svg {
      width: 8px;
      height: 8px;
    }

    &--active {
      opacity: 100%;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &--stroke {
      svg {
        width: 24px;
        height: 2px;
      }
    }
  }
}
</style>
