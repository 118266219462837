import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("View mode switch", "tabs_container"),
    generateDropdownItem("Buttons", "tabs"),
    generateDropdownItem("Iframe", "iframe_container"),
    generateDropdownItem("Event info", "event_info_container"),
    generateDropdownItem("Race distance and venue", "event_info_title"),
    generateDropdownItem(
      "Group stage and conditions",
      "event_info_description"
    ),
    generateDropdownItem("Players info", "players_info_container"),
    generateDropdownItem("Header card", "players_info_header"),
    generateDropdownItem("Player cards", "players_info_card"),
    generateDropdownItem("Horse or greyhound", "players_info_player_name"),
    generateDropdownItem("№", "players_info_player_number"),
    generateDropdownItem("Last 5 races", "players_info_player_last_score"),
    generateDropdownItem("Previous odds", "players_info_player_prev_odds"),
    generateDropdownItem("Odds", "players_info_player_current_odds"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    event_info_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    players_info_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    players_info_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    players_info_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    players_info_player_current_odds: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    tabs_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
