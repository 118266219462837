import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  SliderArrowIcon,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { StateController } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/composables/usePromoBetsFenixWidgetStateController";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useArrowsConfig = (
  widget: Ref<IWidgetWithFields>,
  stateController: StateController
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const arrowsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.arrows.field,
    });
  });
  const arrowsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.button.icon.size",
        valueSource: fields.value.arrows.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      ...getInitialDesignList({
        source: fields.value.arrows.field,
        basePath: "options.button",
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.button.alignment",
        valueSource: fields.value.arrows.field,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.button.icon.value",
        valueSource: fields.value.arrows.field,
        className: "group-control-element",
        options: {
          label: "Icon",
          items: [
            { icon: SliderArrowIcon.OUTLINED, value: SliderArrowIcon.OUTLINED },
            { icon: SliderArrowIcon.ARROW, value: SliderArrowIcon.ARROW },
            { icon: SliderArrowIcon.CARET, value: SliderArrowIcon.CARET },
          ],
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.button.icon.color",
        valueSource: fields.value.arrows.field,
        options: {
          placeholder: "None",
          label: "Icon Color",
          type: ColorPickerType.BACKGROUND,
        },
      },
    ];
  });
  const arrowsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.arrows,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "arrows",
            value,
          });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${stateController.getOptionsValuePath(
          "arrows"
        )}.button.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${stateController.getOptionsValuePath(
          "arrows"
        )}.button.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${stateController.getOptionsValuePath(
          "arrows"
        )}.button.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${stateController.getOptionsValuePath(
              "arrows"
            )}.button.icon.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Icon Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    arrowsDesignControls,
    arrowsContentControls,
    arrowsStatesControls,
  };
};
