import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

const SETTINGS_ITEMS = [
  {
    label: "Dropdown container",
    value: "dropdown_container",
  },
  // {
  //   label: "Dropdown menu",
  //   value: "dropdown_menu",
  // },
  // {
  //   label: "Menu items",
  //   value: "menu_items",
  // },
];

export const useCasinoProvidersFilterWidgetItems = (
  widget: Ref<IWidgetWithFields>
) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Dropdown container", "dropdown_container"),
    // generateDropdownItem("Dropdown menu", "dropdown_menu"),
    // generateDropdownItem("Items", "menu_items"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    dropdown_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    // menu_items: [
    //   generateTabItem("Design", "design"),
    //   generateTabItem("Content", "content"),
    //   generateTabItem("States", "states"),
    // ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
