import { CSSProperties } from "vue";

import {
  getResizingHeightStyles,
  getResizingWidthStyles,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

import { EventInfoDirection } from "../../PromoBetsFenixWidgetTypes";
import { getEventInfoVariantStyles } from "../../PromoBetsFenixWidgetHelpers";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useEventInfoStyles = (
  eventInfoOptions: any = {}
): CSSProperties => {
  if (!eventInfoOptions._active) {
    return {};
  }

  const variant = getEventInfoVariantStyles(eventInfoOptions.variant)[
    eventInfoOptions.direction as EventInfoDirection
  ];

  return {
    ...useDesignInitialStyles(eventInfoOptions),

    ...getResizingWidthStyles(eventInfoOptions.size.width),
    ...getResizingHeightStyles(eventInfoOptions.size.height),
    ...variant.eventInfoStyles,

    ...eventInfoOptions.layoutAlignment,

    gap: getPxValueFromNumber(eventInfoOptions.gap),
  };
};
