import { type Ref, type ComputedRef } from "vue";

import { State } from "~~/models/widgets/widget-controls.model";
import {
  getSpacing,
  getDecorationValue,
  getDecorationColor,
  getCornerRadiusStyle,
  getBackgroundColorStyle,
  getColorStyle,
  getFontSizeStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getFontFamilyStyle,
  getColorFromHex,
} from "~~/assets/utils/widget-settings";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { findField, getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useFormInputStyling = (
  stylingFields: Ref<IWidgetField[]>,
  widget?: IWidgetWithFields,
  state?: ComputedRef<string>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const isErrorVisible = ref<boolean>(false);
  const isTooltipVisible = ref<boolean>(false);
  const isDropdownVisible = ref<boolean>(false);
  const isCalendarVisible = ref<boolean>(false);

  const inputField = computed<IWidgetField | undefined>(() => {
    return findField(FormStyleFieldName.FIELD, stylingFields.value);
  });

  const successField = computed<IWidgetField | undefined>(() => {
    return findField(FormStyleFieldName.SUCCESS, stylingFields.value);
  });

  const activeStateOptions = computed<IWidgetOptions>(() => {
    if (selectedField.value?.name === FormStyleFieldName.SUCCESS) {
      return {
        ...(inputField.value?.options || {}),
        ...successField.value?.options,
      };
    }

    if (state?.value === State.DEFAULT || !state?.value) {
      return inputField.value?.options || {};
    }

    return inputField.value?.options.states[state.value];
  });

  const errorField = computed<IWidgetField | undefined>(() => {
    return findField(FormStyleFieldName.ERROR, stylingFields.value);
  });

  const inputStyles = computed<ElementStyle>(() => {
    if (!inputField.value) {
      return {};
    }

    const placeholderStyle = {
      placeholderColor: getColorFromHex(
        activeStateOptions.value.placeholderColor
      ),
      ...getDecorationValue(activeStateOptions.value.decoration),
      ...getDecorationColor(activeStateOptions.value.placeholderColor),
    } as ElementStyle;

    let styles = {
      ...getSpacing(inputField.value.options.padding, SpacingKeyName.PADDING),
      ...getCornerRadiusStyle(inputField.value.options.cornerRadius),
      ...getFontSizeStyle(inputField.value.options.theme),
      ...getFontFamilyStyle(inputField.value.options.fontFamily),
      ...getBackgroundColorStyle(activeStateOptions.value.fill),
      ...getBoxShadowStyle(activeStateOptions.value.shadow),
      ...getDecorationValue(activeStateOptions.value.decoration),
      ...getColorStyle(activeStateOptions.value.color),
      ...getStylesAsVars(placeholderStyle),
    };

    if (isErrorVisible.value && errorField.value) {
      styles = {
        ...styles,
        ...getBorderStyle(errorField.value.options.border),
      };
    } else {
      styles = {
        ...styles,
        ...getBorderStyle(activeStateOptions.value.border),
      };
    }

    return styles;
  });

  const inputIconStyle = computed<ElementStyle>(() => {
    if (!inputField.value) {
      return {};
    }

    const styles = {
      ...getColorStyle(activeStateOptions.value.iconRight.color),
    };

    return styles;
  });

  const setTriggerValue = (
    fieldStylingName: FormStyleFieldName,
    value: boolean
  ): void => {
    switch (fieldStylingName) {
      case FormStyleFieldName.ERROR:
        isErrorVisible.value = value;
        return;

      case FormStyleFieldName.TOOLTIP:
        isTooltipVisible.value = value;
        return;

      case FormStyleFieldName.DROPDOWN_MENU:
        isDropdownVisible.value = value;
        return;

      case FormStyleFieldName.DROPDOWN_MENU_ITEMS:
        isDropdownVisible.value = value;
        return;
      case FormStyleFieldName.CALENDAR:
        isCalendarVisible.value = value;
        return;
    }
  };

  const triggerFieldExample = (fieldStylingName: FormStyleFieldName): void => {
    if (selectedWidget.value && widget && widget.id !== selectedWidget.value.id)
      return;
    setTriggerValue(fieldStylingName, true);
  };

  const hideFieldExample = (fieldStylingName: FormStyleFieldName): void => {
    setTriggerValue(fieldStylingName, false);
  };

  return {
    inputStyles,
    inputIconStyle,
    isErrorVisible,
    isTooltipVisible,
    isDropdownVisible,
    isCalendarVisible,
    errorField,

    triggerFieldExample,
    hideFieldExample,
  };
};
