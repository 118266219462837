import type { Ref } from "vue";

import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useDropdownMenuControls = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const dropdownMenuControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.dropdown_menu.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  return {
    dropdownMenuControls,
  };
};
