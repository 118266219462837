<template>
  <ConfigToggle
    v-if="hasContentPermissions"
    size="small"
    :model-value="active"
    :label="label"
    :placeholder="placeholder"
    :allow-disable="allowDisable"
    :show-options="showOptions"
    class="widget-control-container p-l-16 p-r-16 p-b-16 p-t-16"
    @update:model-value="handleActive"
    @options-click="$emit('show-options')"
  >
    <ConfigEditor
      v-if="active"
      :is-multiple="isMultiple"
      :placeholder="placeholder"
      :model-value="modelValue"
      :fields="fields"
      :primary-field="primaryField"
      class="config-toggle-textarea__input p-t-16"
    />
  </ConfigToggle>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

withDefaults(
  defineProps<{
    isMultiple?: boolean;
    modelValue?: Record<string, any> | undefined | null;
    placeholder: string | undefined;
    label: string;
    active: boolean;
    fields: IWidgetField[];
    primaryField: IWidgetField;
    allowDisable: boolean;
    showOptions: boolean;
  }>(),
  {
    placeholder: "Type value...",
    active: false,
    modelValue: null,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
  (e: "update:active", event: boolean): void;
  (e: "show-options"): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleActive = (value: boolean): void => {
  emit("update:active", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-text {
  @include widgets-content-input-container;
}

.config-toggle-textarea {
  &__element {
    @include flex(center, flex-start, 0);
  }

  &__label {
    margin-left: $space-s;
  }

  &__input {
    border-bottom: unset;
    padding-bottom: 0;
  }
}
</style>
