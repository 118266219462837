import { IDividerPosition } from "~~/models/dnd.model";

import {
  Grid,
  ICell,
  ICellNullable,
  CellId,
  GridRootDimension,
  TemplateQueryType,
  ICellOptions,
} from "../grid.interface";

export enum TemplateStatus {
  DRAFT = "draft",
  SAVED = "saved",
}

export interface IGridStore {
  template: {
    name: string;
    id: TemplateQueryType;
    status: TemplateStatus | null;
  };
  gridLayout: Grid;
  widgetsGridLayout: Grid;
  gridRootRowsDimensions: GridRootDimension;
  highlightedCells: CellId[]; // array of cellId to manage parent <-> child hovering logic; if several blocks are under hover (parent & child), the last one in the array will be considered active
  colWithHover: CellId | null; // a column which contains a cell currently highlighted on hover; needed for styling
  selectedCell: ICell | null; // this one is to work with settings,
  dividerData: IDividerPosition; // holds position details of divider
  hasWidthSettingsErrors: boolean;
  isLayoutSaved: boolean;
  isTemplateEditable: boolean;
  cellsOptions: Record<CellId, ICellOptions>;
}

export interface GridWithMeta {
  grid: Grid;
  name: string;
  id?: string;
}

export interface ITemplateResponse {
  data: GridWithMeta;
}

export interface ISaveLayoutData {
  name: string;
  cells: ICellNullable[];
}

export interface ITemplateErrorResponse {
  response: {
    data: {
      [key: string]: string[];
    };
  };
}
