import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

export const useLeagueWinnerCardConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    League winner card 
  */
  const leagueWinnerCardDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.winner_league_card.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.winner_league_card.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.winner_league_card.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.winner_league_card.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.winner_league_card.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const leagueWinnerCardContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigDisplaySettings",
        valuePath: "options.displaySettings",
        valueSource: fields.value.winner_league_card.field,
        className: "group-control-element",
      },
      {
        componentPath: "common/ConfigSpacing",
        valuePath: "options.horizontalSpacing",
        valueSource: fields.value.winner_league_card.field,
        className: "group-control-element",
        options: {
          label: "Horizontal spacing",
        },
      },
      {
        componentPath: "common/ConfigSpacing",
        valuePath: "options.verticalSpacing",
        valueSource: fields.value.winner_league_card.field,
        options: {
          label: "Vertical spacing",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Bet items",
              value: "winner_league_bet_items",
            },
          ],
        },
      },
    ];
  });

  return {
    leagueWinnerCardDesignControls,
    leagueWinnerCardContentControls,
  };
};
