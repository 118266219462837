<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <GdprWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @update-title-link-state="
        handleStateUpdate({ state: 'title', value: $event })
      "
      @reset-states="resetStates"
    />

    <div
      :style="widgetStyle"
      class="gdpr-widget"
    >
      <div class="gdpr-widget__left">
        <WidgetField
          :widget="widget"
          :field="fields.title.field"
        >
          <BasicTitleField
            :field="fields.title.field"
            :state="states.title"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.description.field"
        >
          <BasicDescriptionField
            ref="descriptionField"
            :fields="[fields.description.field]"
            :widget="widget"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.form.field"
        >
          <div
            :style="checkboxesContainerStyle"
            class="gdpr-widget__checkboxes"
          >
            <WidgetField
              :widget="widget"
              :field="fields.checkboxes_styling.field"
            >
              <div
                :style="checkboxesAlignmentStyle"
                class="gdpr-widget__checkboxes-content"
              >
                <div :style="checkboxesContentStyle">
                  <FormCheckboxField
                    :styling-fields="[fields.checkboxes_styling.field]"
                    :states="states"
                    :value="t('widgets.GdprWidget.necessary')"
                  />

                  <FormCheckboxField
                    :styling-fields="[fields.checkboxes_styling.field]"
                    :states="states"
                    :value="t('widgets.GdprWidget.functionality')"
                  />

                  <FormCheckboxField
                    :styling-fields="[fields.checkboxes_styling.field]"
                    :states="states"
                    :value="t('widgets.GdprWidget.statistics')"
                  />

                  <FormCheckboxField
                    :styling-fields="[fields.checkboxes_styling.field]"
                    :states="states"
                    :value="t('widgets.GdprWidget.performance')"
                  />
                </div>
              </div>
            </WidgetField>
          </div>
        </WidgetField>
      </div>

      <WidgetField
        :widget="widget"
        :field="fields.buttons_container.field"
        :style="buttonsContainerStyle"
        class="gdpr-widget__right"
      >
        <div
          :style="buttonsContentStyle"
          class="gdpr-widget__right-content"
        >
          <WidgetField
            :widget="widget"
            :field="fields.button_allow_all.field"
            :style="buttonStyle"
            class="basic-card-field__field"
          >
            <BasicButtonField
              :field="fields.button_allow_all.field"
              :current-state="states.button_allow_all"
              :custom-value="t('widgets.GdprWidget.allow_all')"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.button_allow_selection.field"
            :style="buttonStyle"
            class="basic-card-field__field"
          >
            <BasicButtonField
              :field="fields.button_allow_selection.field"
              :current-state="states.button_allow_selection"
              :custom-value="t('widgets.GdprWidget.allow_selection')"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.button_deny.field"
            :style="buttonStyle"
            class="basic-card-field__field"
          >
            <BasicButtonField
              :field="fields.button_deny.field"
              :current-state="states.button_deny"
              :custom-value="t('widgets.GdprWidget.deny')"
            />
          </WidgetField>
        </div>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/gdpr/useGdprInitData";
import { getCommonStyles } from "~~/assets/utils/styles";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getFlexPosition,
  getWidthStyles,
} from "~~/assets/utils/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { t } = useI18n();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const checkboxesContainerStyle = computed<ElementStyle>(() => {
  return getCommonStyles(fields.value.form.field.options);
});

const checkboxesContentStyle = computed<ElementStyle>(() => {
  const settings = fields.value.form.field.options.displaySettings;

  return {
    display: "flex",
    flexDirection:
      settings.layout === DisplayOrientation.HORIZONTAL ? "row" : "column",
    gap: getPxValueFromNumber(settings.gap),
    justifyContent: "flex-start",
    alignItems: "flex-start",
  };
});

const checkboxesAlignmentStyle = computed<ElementStyle>(() => {
  const settings = fields.value.form.field.options.displaySettings;

  return {
    justifyContent: getFlexPosition(settings.alignment),
  };
});

const buttonsContentStyle = computed<ElementStyle>(() => {
  const settings = fields.value.buttons_container.field.options.displaySettings;

  return {
    ...getCommonStyles(fields.value.buttons_container.field.options),
    flexDirection:
      settings.layout === DisplayOrientation.HORIZONTAL ? "row" : "column",
    gap: getPxValueFromNumber(settings.gap),
    justifyContent:
      settings.layout === DisplayOrientation.HORIZONTAL
        ? getFlexPosition(settings.buttonsAlignment)
        : "flex-start",
    alignItems:
      settings.layout === DisplayOrientation.VERTICAL
        ? getFlexPosition(settings.buttonsAlignment)
        : "flex-start",
  };
});

const buttonsContainerStyle = computed<ElementStyle>(() => {
  const settings = fields.value.buttons_container.field.options.displaySettings;
  const style = getWidthStyles(settings.width);

  return {
    ...style,
    "max-width": style.width,
    overflow: "hidden",
  };
});

const buttonStyle = computed<ElementStyle>(() => {
  const settings = fields.value.buttons_container.field.options.displaySettings;

  return getWidthStyles(settings.buttonsWidth);
});

const widgetStyle = computed<ElementStyle>(() => {
  const settings = props.widget.options.displaySettings;

  return {
    flexDirection:
      settings.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    gap: getPxValueFromNumber(settings.gap),
    justifyContent: getFlexPosition(settings.alignment),
    alignItems: settings.alignment,
  };
});

initFields();
</script>

<style lang="scss">
.gdpr-widget {
  @include flex(flex-start, flex-start, 0);

  &__left {
    @include flex-column;
    flex: 1;
    align-items: stretch;
  }

  &__right {
    flex: 1;

    &-content {
      @include flex-column;
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-content {
      @include flex(flex-start, flex-start, 0);
    }
  }
}
</style>
