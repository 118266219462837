import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  ConfigTabItem,
  SportMenuLiveBet9CardLayoutMode,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useSportsbookItems } from "~~/composables/widgets/sportsbook-fenix-mobile/useSportsbookItems";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";
import { useSportsbookHeaderConfig } from "~~/composables/widgets/sportsbook/useSportsbookHeaderConfig";
import { useLeagueWinnerCardConfig } from "~~/composables/widgets/sportsbook/useLeagueWinnerCardConfig";
import { useFormDropdownConfig } from "~~/composables/widgets/form/useFormDropdownConfig";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useFormDatePickerConfig } from "~~/composables/widgets/form/useFormDatepickerConfig";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useNoItemsTextConfig } from "../common/useNoItemsTextConfig";
import { useSportsBookTabsConfig } from "../sportsbook/useSportsBookTabsConfig";
import { useSportsBookSearchControls } from "../sportsbook/useSportsBookSearchConfig";
import { useSportsbookDayLabelConfig } from "../sportsbook-bet9/useSportsbookDayLabelConfig";

import { useEventInfoConfig } from "./useSportsbookEventInfoConfig";
import { useFenixEventCardsConfig } from "./useEventCardsConfig";

export const useSportsbookConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useSportsbookItems(
    widget,
    states
  );
  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit, false, {
    design: [
      [
        4,
        {
          componentPath: "ConfigMarginInputs",
          valuePath: "options.container.margin",
          valueSource: fields.value.list_bet_item.field,
          options: {
            label: "Margin",
            isAllSideSetting: true,
          },
        },
      ],
    ],
  });

  const {
    betItemsDesignControls: topLeaguesBetItemsDesignControls,
    betItemsContentControls: topLeaguesBetItemsContentControls,
    betItemsStateControls: topLeaguesBetItemsStatesControls,
  } = useBetItemsConfig(states, emit, false, {
    design: [
      [
        4,
        {
          componentPath: "ConfigMarginInputs",
          valuePath: "options.container.margin",
          valueSource: fields.value.top_leagues_list_bet_item.field,
          options: {
            label: "Margin",
            isAllSideSetting: true,
          },
        },
      ],
    ],
  });

  const {
    eventInfoDesignControls,
    eventInfoContentControls,

    timeDateControls,
    favoriteIconControls,
  } = useEventInfoConfig(widget);

  const {
    eventInfoDesignControls: topLeaguesEventInfoDesignControls,
    eventInfoContentControls: topLeaguesEventInfoContentControls,
  } = useEventInfoConfig(widget, "top_leagues_");

  const {
    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,

    topLeaguesEventCardsDesignControls,
    topLeaguesEventCardsContentControls,
    topLeaguesEventCardsStatesControls,
    topLeaguesStartTimeDateControls,
    topLeaguesEventButtonLinkDesignControls,
    topLeaguesEventButtonLinkContentControls,
    topLeaguesEventButtonLinkStateControls,
  } = useFenixEventCardsConfig(widget, states, emit, selectedField);

  const {
    headerDesignControls,
    headerContentControls,
    headerButtonStatesControls,
    headerButtonDesignControls,
    headerButtonContentControls,
  } = useSportsbookHeaderConfig(widget, states, emit);

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);
  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  } = useFormDatePickerConfig(widget, states);

  const formStylesControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "tooltips_styling-design": formTooltipDesignStyles.value,
      "tooltips_styling-content": formTooltipContentStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
      "calendar_styling-design": formCalendarDesignStyles.value,
      "calendar_styling-content": formCalendarContentStyles.value,
      "calendar_styling-states": formCalendarStates.value,
      "datepicker_styling": formDatePickerStyles.value,
      "dropdowns_styling": formDropdownStyles.value,
      "dropdown_menu_styling": formDropdownMenuStyles.value,
      "dropdown_menu_items_styling-design":
        formDropdownMenuItemsDesignStyles.value,
      "dropdown_menu_items_styling-content":
        formDropdownMenuItemsContentStyles.value,
      "dropdown_menu_items_styling-states": formDropdownMenuItemsStates.value,
    };
  });

  const { leagueWinnerCardDesignControls, leagueWinnerCardContentControls } =
    useLeagueWinnerCardConfig(widget);

  const {
    tabsDesignControls,
    tabsContentControls,
    tabItemsDesignControls,
    tabItemsContentControls,
    tabItemsStatesControls,
    itemControls,
  } = useSportsBookTabsConfig(widget, states, emit);

  const {
    searchFieldDesignControls,
    searchFieldContentControls,
    searchFieldStatesControls,

    searchContainerDesignControls,
    searchContainerContentControls,

    searchBoxContentControls,
    searchBoxDesignControls,

    searchResultItemsDesignControls,
    searchResultItemsContentControls,
    searchResultItemsStatesControls,
  } = useSportsBookSearchControls(widget, states, emit);

  /* 
    Filter elements controls
  */
  const { calendarFieldControls } = useFormContentConfig();

  const filterLabelControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "options.label.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter label text",
          label: "Label",
          allowDisable: true,
          active: selectedField.value?.options.label._active,
          showOptions: false,
          "onUpdate:active": (value: boolean) => {
            if (!selectedField.value) {
              return;
            }

            selectedField.value.options.label._active = value;
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "options.tooltip.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter tooltip text",
          label: "Tooltip",
          allowDisable: true,
          active: selectedField.value?.options.tooltip._active,
          showOptions: false,
          "onUpdate:active": (value: boolean) => {
            if (!selectedField.value) {
              return;
            }

            selectedField.value.options.tooltip._active = value;
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "options.placeholder.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter placeholder text",
          label: "Placeholder",
          allowDisable: true,
          active: selectedField.value?.options.placeholder._active,
          showOptions: false,
          "onUpdate:active": (value: boolean) => {
            if (!selectedField.value) {
              return;
            }

            selectedField.value.options.placeholder._active = value;
          },
        },
      },
    ];
  });

  /* 
    Widget config
  */

  const widgeDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.sport,
              value: State.SPORTSBOOK_SPORT,
            },
            {
              label: STATE_KEY_LABELS.country,
              value: State.SPORTSBOOK_COUNTRY,
            },
            {
              label: STATE_KEY_LABELS.league,
              value: State.SPORTSBOOK_LEAGUE,
            },
            {
              label: STATE_KEY_LABELS.top_leagues,
              value: State.SPORTSBOOK_TOP_LEAGUES,
            },
            {
              label: STATE_KEY_LABELS.all_leagues,
              value: State.SPORTSBOOK_ALL_LEAGUES,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "widget", value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS.value,
        },
        onUpdate(value: State) {
          emit("update-state", { state: "items", value });
        },
      },
    ];
  });

  /* 
   Common title config
  */

  const titleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
   Common title league config
  */

  const titleLeagueControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const searchTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              selectedField.value
            );
          },
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
   Common title list config
  */

  const titleListControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.list_title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          active: fields.value.list_title.active,
          showOptions: fields.value.list_title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.list_title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.list_title.field
            );
          },
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Events filter settings
  */

  const eventsFilterSettingsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.distance",
        valueSource: fields.value.filter.field,
        options: {
          label: "Distance between fields",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.filter.field,
        options: {},
      },
    ];
  });

  const formContentFields = computed<IWidgetField[]>(() => {
    return [
      fields.value.date_from.field,
      fields.value.date_to.field,
      fields.value.markets.field,
    ];
  });

  const eventsFilterSettingsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: fields.value.filter.field,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.visibility",
            valueSource: fields.value.filter.field,
            options: {
              label: "Fields visibility",
              items: [
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Period",
                  value: "period",
                },
                {
                  label: "Market",
                  value: "market",
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: fields.value.filter.field,
            className: "group-control-element--child",
            visible:
              fields.value.filter.field.options.visibility !== "all" &&
              fields.value.filter.field.options.displayMode === "horizontal",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.filter.field,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },

      {
        componentPath: "form/ConfigFormItems",
        valuePath: "",
        valueSource: null,
        options: {
          fields: {
            visible: formContentFields.value,
            hidden: [],
          },
          isOrderAllowed: false,
          customEditHandler(field: IWidgetField) {
            widgetSettingsStore.setActiveElement(selectedWidget.value, field);
          },
        },
      },
    ];
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields, [
      "title_styling",
      "field_success_styling",
    ]);
  });

  const eventsFilterSettingsStyleControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  /* 
    Build button config
  */

  const buildBtnState = computed<State>(() => {
    return states.value.betbuilder_button;
  });

  const {
    buttonStatesControls: buildButtonStateControls,
    buttonDesignControls: buildButtonDesignControls,
    buttonContentControls,
  } = useSimpleButtonControls(buildBtnState, "betbuilder_button", emit);

  /* 
    Top Leagues Build button config
  */

  const topLeaguesBuildBtnState = computed<State>(() => {
    return states.value.top_leagues_betbuilder_button;
  });

  const {
    buttonStatesControls: topLeaguesBuildButtonStateControls,
    buttonDesignControls: topLeaguesBuildButtonDesignControls,
    buttonContentControls: simpleTopLeaguesBuildButtonContentControls,
  } = useSimpleButtonControls(
    topLeaguesBuildBtnState,
    "top_leagues_betbuilder_button",
    emit
  );

  const buildButtonContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
        },
      },
      ...buttonContentControls.value,
    ];
  });

  const topLeaguesBuildButtonContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
        },
      },
      ...simpleTopLeaguesBuildButtonContentControls.value,
    ];
  });

  const leaguesContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.leagues_container.field,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: fields.value.leagues_container.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const leaguesContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Items layout",
              items: [
                {
                  icon: "custom:sportbook-grid-1",
                  value: 1,
                },
                {
                  icon: "custom:sportbook-grid-2",
                  value: 2,
                },
                {
                  icon: "custom:sportbook-grid-3",
                  value: 3,
                },
                {
                  icon: "custom:sportbook-grid-4",
                  value: 4,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "league_card", label: "Items" }],
        },
      },
    ];
  });

  const leagueBtnState = computed<State>(() => {
    return states.value.league_card;
  });

  const {
    buttonStatesControls: leagueButtonStateControls,
    buttonDesignControls: leagueButtonDesignControls,
    buttonContentControls: simpleLeagueButtonContentControls,
  } = useSimpleButtonControls(leagueBtnState, "league_card", emit);

  const leagueButtonContentControls = computed<ControlProp[]>(() => {
    return [
      ...simpleLeagueButtonContentControls.value,
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: selectedField.value,
        className: "group-control-element--child",
        options: {
          label: "Alignment",
        },
      },
    ];
  });

  /* 
    Filter
  */

  const filterContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.filter_container.field,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: fields.value.filter_container.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const filterContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.width",
            valueSource: fields.value.filter_container.field,
            options: {
              label: "Width",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.filter_container.field,
            options: {
              label: "Position",
              items: [
                {
                  label: "First",
                  value: SportMenuLiveBet9CardLayoutMode.FIRST,
                },
                {
                  label: "Second",
                  value: SportMenuLiveBet9CardLayoutMode.SECOND,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.list_title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Filter Title",
          active: fields.value.list_title.active,
          showOptions: fields.value.list_title.active,
          allowDisable: true,
          "onUpdate:active": (value: boolean) => {
            fields.value.list_title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.list_title.field
            );
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "filter", label: "Filter" }],
        },
      },
    ];
  });

  /* 
    Winner
  */

  const winnerContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.winner_container.field,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: fields.value.winner_container.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const winnerContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { value: "winner_league_title", label: "Title" },
            { value: "winner_league_card", label: "League winner card" },
          ],
        },
      },
    ];
  });

  /* 
    Events
  */

  const eventsContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.events_container.field,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: fields.value.events_container.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: "events",
      noTextFieldName: "no_events_title",
      customLabels: {
        [State.NO_ACTIVE]: STATE_KEY_LABELS.no_active_events,
        [State.ACTIVE_EVENTS]: STATE_KEY_LABELS.with_active_events,
      },
    }),
    emit
  );

  const eventsContainerContentControls = computed<ControlProp[]>(() => {
    if (states.value.events === State.ACTIVE_EVENTS) {
      return [
        ...stateControls.value,
        {
          section: generateDesignSectionName("Date"),
          showConfig: true,
          onConfigClick() {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              fields.value.day_label.field
            );
          },
          toggleable: true,
          valueSource: fields.value.day_label.field.options,
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.distanceBetweenDateEvents",
              valueSource: fields.value.events_container.field,
              className: "group-control-element",
              options: {
                label: "Distance between date events",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          section: generateDesignSectionName("League"),
          showConfig: true,
          onConfigClick() {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              fields.value.league_name.field
            );
          },
          toggleable: true,
          valueSource: fields.value.league_name.field.options,
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.distanceBetweenLeagueEvents",
              valueSource: fields.value.events_container.field,
              className: "group-control-element",
              options: {
                label: "Distance between league events",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          section: generateDesignSectionName("Event cards"),
          showConfig: true,
          onConfigClick() {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              fields.value.list_container.field
            );
          },
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.distanceBetweenCards",
              valueSource: fields.value.list_container.field,
              className: "group-control-element",
              options: {
                label: "Distance between event cards",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
      ];
    }

    return [...stateControls.value, ...eventCardsNoContentControls.value];
  });

  /* 
    Top leagues Events
  */

  const topLeaguesEventsContainerDesignControls = computed<ControlProp[]>(
    () => {
      return getInitialDesignList({
        source: fields.value.top_leagues_events_container.field,
        except: ["ConfigColorPickerInput"],
        elements: [
          [
            0,
            {
              componentPath: "ConfigFillImageColor",
              valuePath: "options.fillImageColor",
              valueSource: fields.value.top_leagues_events_container.field,
              options: {
                placeholder: "None",
                label: "Fill",
                isBold: true,
              },
            },
          ],
        ],
      });
    }
  );

  const {
    eventCardsNoContentControls: topLeaguesEventCardsNoContentControls,
    stateControls: topLeaguesStateControls,
  } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: "top_leagues_events",
      noTextFieldName: "top_leagues_no_events_title",
      customLabels: {
        [State.NO_ACTIVE]: STATE_KEY_LABELS.no_active_events,
        [State.ACTIVE_EVENTS]: STATE_KEY_LABELS.with_active_events,
      },
    }),
    emit
  );

  const topLeaguesEventsContainerContentControls = computed<ControlProp[]>(
    () => {
      if (states.value.top_leagues_events === State.ACTIVE_EVENTS) {
        return [
          ...topLeaguesStateControls.value,
          {
            section: generateDesignSectionName("Date"),
            showConfig: true,
            onConfigClick() {
              widgetSettingsStore.setActiveElement(
                selectedWidget.value,
                fields.value.top_leagues_day_label.field
              );
            },
            toggleable: true,
            valueSource: fields.value.top_leagues_day_label.field.options,
            controls: [
              {
                componentPath: "ConfigColumnWidth",
                valuePath: "options.distanceBetweenDateEvents",
                valueSource: fields.value.top_leagues_events_container.field,
                className: "group-control-element",
                options: {
                  label: "Distance between date events",
                  showIconPrefix: false,
                  showSuffix: true,
                  suffix: "px",
                },
              },
            ],
          },
          {
            section: generateDesignSectionName("League"),
            showConfig: true,
            onConfigClick() {
              widgetSettingsStore.setActiveElement(
                selectedWidget.value,
                fields.value.top_leagues_league_name.field
              );
            },
            toggleable: true,
            valueSource: fields.value.top_leagues_league_name.field.options,
            controls: [
              {
                componentPath: "ConfigColumnWidth",
                valuePath: "options.distanceBetweenLeagueEvents",
                valueSource: fields.value.top_leagues_events_container.field,
                className: "group-control-element",
                options: {
                  label: "Distance between league events",
                  showIconPrefix: false,
                  showSuffix: true,
                  suffix: "px",
                },
              },
            ],
          },
          {
            section: generateDesignSectionName("Event cards"),
            showConfig: true,
            onConfigClick() {
              widgetSettingsStore.setActiveElement(
                selectedWidget.value,
                fields.value.top_leagues_list_container.field
              );
            },
            controls: [
              {
                componentPath: "ConfigColumnWidth",
                valuePath: "options.distanceBetweenCards",
                valueSource: fields.value.top_leagues_list_container.field,
                className: "group-control-element",
                options: {
                  label: "Distance between event cards",
                  showIconPrefix: false,
                  showSuffix: true,
                  suffix: "px",
                },
              },
            ],
          },
        ];
      }

      return [
        ...topLeaguesStateControls.value,
        ...topLeaguesEventCardsNoContentControls.value,
      ];
    }
  );

  const { dayLabelDesignControls, dayLabelContentControls } =
    useSportsbookDayLabelConfig();

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgeDesignControls,
    widgetContentControls,

    headerDesignControls,
    headerContentControls,

    titleControls,
    titleLeagueControls,
    searchTitleControls,
    titleListControls,

    headerButtonStatesControls,
    headerButtonDesignControls,
    headerButtonContentControls,

    leagueWinnerCardDesignControls,
    leagueWinnerCardContentControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    eventsFilterSettingsDesignControls,
    eventsFilterSettingsContentControls,
    eventsFilterSettingsStyleControls,

    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,

    topLeaguesEventCardsDesignControls,
    topLeaguesEventCardsContentControls,
    topLeaguesEventCardsStatesControls,

    topLeaguesStartTimeDateControls,
    topLeaguesEventButtonLinkDesignControls,
    topLeaguesEventButtonLinkContentControls,
    topLeaguesEventButtonLinkStateControls,

    buildButtonDesignControls,
    buildButtonContentControls,
    buildButtonStateControls,

    topLeaguesBuildButtonStateControls,
    topLeaguesBuildButtonDesignControls,
    topLeaguesBuildButtonContentControls,

    /* 
      Filter content
    */
    filterLabelControls,
    calendarFieldControls,

    formStylesControls,

    /* 
      New
    */

    leaguesContainerDesignControls,
    leaguesContainerContentControls,

    leagueButtonStateControls,
    leagueButtonDesignControls,
    leagueButtonContentControls,

    filterContainerDesignControls,
    filterContainerContentControls,

    winnerContainerDesignControls,
    winnerContainerContentControls,

    eventsContainerDesignControls,
    eventsContainerContentControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    timeDateControls,
    favoriteIconControls,

    tabsDesignControls,
    tabsContentControls,
    tabItemsDesignControls,
    tabItemsContentControls,
    tabItemsStatesControls,
    itemControls,

    searchFieldDesignControls,
    searchFieldContentControls,
    searchFieldStatesControls,

    searchContainerDesignControls,
    searchContainerContentControls,

    searchResultItemsDesignControls,
    searchResultItemsContentControls,
    searchResultItemsStatesControls,

    searchBoxContentControls,
    searchBoxDesignControls,

    topLeaguesEventCardsNoContentControls,
    topLeaguesStateControls,
    topLeaguesEventsContainerDesignControls,
    topLeaguesEventsContainerContentControls,

    topLeaguesBetItemsDesignControls,
    topLeaguesBetItemsContentControls,
    topLeaguesBetItemsStatesControls,

    topLeaguesEventInfoDesignControls,
    topLeaguesEventInfoContentControls,

    dayLabelDesignControls,
    dayLabelContentControls,
  };
};
