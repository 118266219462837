<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-control-container config-dimension-input m-t-16 p-b-16 p-l-16 p-r-16"
  >
    <ConfigRadioTextElements
      class="p-t-0 p-l-0 p-b-0 p-r-0 border-bottom-none"
      label="Height"
      :items="heighTypes"
      :model-value="modelValue?.type"
      :default-selected="CellSizeType.HUG"
      @update:model-value="handleUpdate($event, 'type')"
    />

    <template v-if="modelValue?.type === CellSizeType.FIXED">
      <ConfigDimensionValue
        :model-value="modelValue?.height"
        class="m-t-16"
        @update:model-value="handleUpdate($event, 'height')"
      />

      <div class="config-dimension-input__checkboxes">
        <ConfigCheckbox
          :model-value="modelValue?.isMax"
          :indeterminate="modelValue?.isMax"
          label="Max height"
          class="group-control-element p-l-0"
          @update:model-value="handleCheckboxUpdate($event, MAX_KEY_NAME)"
        />

        <ConfigCheckbox
          :model-value="modelValue?.isMin"
          :indeterminate="modelValue?.isMin"
          label="Min height"
          class="group-control-element p-l-0"
          @update:model-value="handleCheckboxUpdate($event, MIN_KEY_NAME)"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { ICellHeight, CellSizeType } from "~~/models/grid.interface";

const HEIGHT_TYPES = [
  {
    label: "Fixed",
    value: CellSizeType.FIXED,
  },
  {
    label: "Fill",
    value: CellSizeType.FILL,
  },
  {
    label: "Hug",
    value: CellSizeType.HUG,
  },
];

const MAX_KEY_NAME = "isMax";
const MIN_KEY_NAME = "isMin";

const props = defineProps<{
  modelValue?: ICellHeight;
  isParent?: boolean;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const emit = defineEmits<{
  (e: "update:model-value", event: ICellHeight): void;
}>();

const handleUpdate = (
  value: Partial<ICellHeight>,
  name: keyof ICellHeight
): void => {
  emit("update:model-value", {
    ...(props.modelValue || {}),
    [name]: value,
  });
};

const handleCheckboxUpdate = (value: boolean, keyName: string): void => {
  let result = {
    [keyName]: value,
  };

  if (keyName === MAX_KEY_NAME) {
    result[MIN_KEY_NAME] = false;
  } else {
    result[MAX_KEY_NAME] = false;
  }

  emit("update:model-value", {
    ...(props.modelValue || {}),
    ...result,
  });
};

const heighTypes = computed<{ label: string; value: string }[]>(() => {
  const items = [...HEIGHT_TYPES];

  if (props.isParent) {
    items.splice(1, 1);
  }

  return items;
});
</script>

<style lang="scss">
.config-dimension-input {
  &__checkboxes {
    @include flex("center", "space-between");

    span {
      white-space: nowrap;
    }
  }
}
</style>
