<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-color-image-fill p-t-16"
  >
    <p
      class="wconfig-color-image-fill__label p-l-16 p-r-16"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label || "Fill" }}
    </p>
    <div
      v-if="modelValue.fillType && hasDesignPermissions"
      class="p-l-16 p-r-16"
    >
      <a-radio-group
        :value="modelValue.fillType"
        class="wconfig-item__radio-group w-100"
        @update:value="handleFillTypeUpdate($event)"
      >
        <a-radio-button :value="FillType.COLOR"> Color </a-radio-button>
        <a-radio-button :value="FillType.IMAGE"> Image </a-radio-button>
      </a-radio-group>
    </div>

    <div v-if="modelValue.fillType === FillType.IMAGE">
      <ConfigImageUploader
        :value="modelValue.value"
        :upload-function="handleImageUpload"
        :field-name="fieldName"
        class="border-bottom-none"
        @update:value="handleValueUpdate"
      />

      <ConfigImagePosition
        v-if="modelValue.value"
        :model-value="modelValue.position"
        :min-value="0"
        :max-value="MAX_NUMBER_INPUT_VALUE"
        class="p-t-0"
        @update:model-value="handlePositionUpdate"
      />
    </div>
    <div v-else>
      <ConfigColorPickerInput
        :model-value="currentColor"
        label=""
        :type="ColorPickerType.BACKGROUND"
        class="wconfig-color-image-fill__color-picker"
        @update:model-value="handleColorUpdate"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch } from "vue";

import {
  FILE_UPLOADING_ERROR,
  FILE_UPLOADING_SUCCESS,
} from "~~/constants/notification-messages";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import { handleActionError } from "~~/helpers/handle-action-error";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  FillType,
  IFillControl,
  IImagePosition,
} from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue: {
    fillType: FillType;
    value: string | IFillControl;
    position?: IImagePosition;
  };
  fieldName?: string;
  label?: string;
  isBold?: boolean;
  valuePath: string;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      fillType: FillType;
      value: string | IFillControl | undefined;
      position?: IImagePosition;
    }
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const getInitialCachedColor = (): IFillControl => {
  if (!props.modelValue.value) {
    return {
      color: "",
      opacity: 100,
    };
  }

  if (typeof props.modelValue.value === "string") {
    return {
      color: "",
      opacity: 100,
    };
  }

  return props.modelValue.value;
};

const cachedColor = ref<IFillControl>(getInitialCachedColor());
const cachedImagePath = ref<string>("");
const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const currentColor = computed<IFillControl>(() => {
  if (typeof props.modelValue.value === "string") {
    return cachedColor.value;
  }

  if (!props.modelValue.value?.opacity) {
    return cachedColor.value;
  }

  return props.modelValue.value;
});

const handleValueUpdate = (data: any) => {
  handleUpdate("value", data);
};

const handleImageUpload = (file: Blob): Promise<string> => {
  const widgetId = widgetSettingsStore.selectedWidget?.static_id;

  if (!widgetId) {
    handleActionError(FILE_UPLOADING_ERROR);
    throw new Error();
  }

  return widgetsStore
    .mediaUpload({
      file,
    })
    .then(resp => {
      handleActionSuccess(FILE_UPLOADING_SUCCESS);
      return resp.data.path;
    })
    .catch(error => {
      handleActionError(FILE_UPLOADING_ERROR, error);
      throw new Error();
    });
};

const handleUpdate = (key: string, value: any): void => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};

const handleColorUpdate = (color: IFillControl): void => {
  cachedColor.value = color;

  emit("update:modelValue", {
    fillType: FillType.COLOR,
    value: color,
  });
};

const handleFillTypeUpdate = (type: FillType) => {
  if (type === FillType.COLOR) {
    cachedImagePath.value = props.modelValue.value as string;
  } else {
    /* 
      Save prev color value. There are cases when value is empty,
      so we're taking cached color instead(which is generated on control creation)
    */
    cachedColor.value = (props.modelValue.value ||
      cachedColor.value) as IFillControl;
  }

  emit("update:modelValue", {
    fillType: type,
    value: type === FillType.COLOR ? cachedColor.value : cachedImagePath.value,
  });
};

const handlePositionUpdate = (position: IImagePosition): void => {
  handleUpdate("position", position);
};

watch(
  () => props.valuePath,
  () => {
    cachedColor.value = getInitialCachedColor();
  }
);
</script>

<style lang="scss">
.wconfig-color-image-fill {
  &__label {
    margin-bottom: $space-s;
  }
}
</style>
