<template>
  <div v-if="hasDesignPermissions">
    <ConfigColorPickerInput
      :model-value="currentCellStyle?.backgroundColor"
      :type="ColorPickerType.BACKGROUND"
      label="Background color"
      @update:model-value="
        handleUpdateCellStyling(currentCell, { backgroundColor: { ...$event } })
      "
    />

    <ConfigCellPosition
      v-if="isRootCell"
      :model-value="cellContainerStyle?.position"
      @update:model-value="
        handleUpdateCellStyling(cellContainer, {
          position: $event,
        })
      "
    />

    <ConfigCellHeight
      :is-parent="isRootCell"
      :model-value="currentCellStyle?.height"
      @update:model-value="
        handleUpdateCellStyling(currentCell, {
          height: $event,
        })
      "
    />

    <ConfigCellContent
      :model-value="currentCellStyle?.cellContentSettings"
      @update:model-value="
        handleUpdateCellStyling(currentCell, {
          cellContentSettings: $event,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { findCell, findCellRootElement } from "~~/assets/utils/grid/grid-tree";
import type { ICell, ICellOptions } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const gridStore = useGridConfig();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget, selectedColumn, selectedContainer } =
  storeToRefs(widgetSettingsStore);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentCell = computed<ICell | null>(() => {
  return findCell(
    gridStore.currentWidgetsLayout,
    selectedWidget.value?.cell_id || null
  );
});

const cellContainer = computed<ICell | null>(() => {
  return findCellRootElement(gridStore.currentWidgetsLayout, currentCell.value);
});

const cellContainerStyle = computed<ICellOptions | null>(() => {
  if (!cellContainer.value) return null;

  return gridStore.cellsOptions[cellContainer.value?.cellId];
});

const currentCellStyle = computed<ICellOptions>(() => {
  if (!selectedWidget.value?.cell_id) return {};
  return gridStore.cellsOptions[selectedWidget.value?.cell_id];
});

const handleUpdateCellStyling = (
  cell: ICell | null,
  style: Partial<ICellOptions>
): void => {
  if (!cell?.cellId) {
    return;
  }

  gridStore.updateCellsOptions(cell.cellId, style);
};

const isRootCell = computed<boolean>(() => {
  if (!selectedColumn.value || selectedColumn.value?.children.length > 1) {
    return false;
  }

  return selectedColumn.value?.cellId === selectedContainer.value?.cellId;
});
</script>
