<template>
  <div v-if="hasContentPermissions">
    <div class="wconfig-toggle">
      <div class="wconfig-toggle__switch">
        <a-tooltip placement="bottom">
          <template
            v-if="modelValue && !allowDisable"
            #title
          >
            <span>{{ cannotDisableText }}</span>
          </template>
          <a-switch
            v-if="!hideSwitch"
            :size="size || 'default'"
            :checked="modelValue"
            :disabled="modelValue && !allowDisable"
            class="wconfig-toggle__switch-element"
            @update:checked="$emit('update:model-value', $event)"
          />
        </a-tooltip>

        <p class="wconfig-toggle__label m-b-0 sub-heading">
          {{ label }}
        </p>
      </div>

      <CommonIcon
        v-if="showOptions"
        name="ant-design:setting-outlined"
        class="wconfig-toggle__icon pointer"
        @click="$emit('options-click')"
      />
    </div>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";

type SizeType = "small" | "default";

const props = defineProps<{
  modelValue?: boolean;
  label?: string;
  allowDisable: boolean;
  showOptions?: boolean;
  isFieldItemToggle?: boolean;
  hideSwitch?: boolean;
  size?: SizeType;
}>();

defineEmits<{
  (e: "update:model-value", event: string): void;
  (e: "options-click"): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const cannotDisableText = computed<string>(() =>
  props.isFieldItemToggle
    ? "At least one item should be active in field"
    : "At least one field should be active in widget"
);
</script>

<style lang="scss">
.wconfig-toggle {
  @include flex(center, space-between, 0);

  &__switch {
    @include flex(center, flex-start, 0);
  }

  &__switch-element {
    margin-right: $space-s;
  }

  &__label {
    margin-bottom: 0;
  }

  &__icon {
    color: $c-grey-45;
    font-size: 14px;
  }
}
</style>
