import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { State } from "~~/models/widgets/widget-controls.model";

export const FIELDS_STYLE_INITIAL = () => ({
  title: "Fields container style",
  options: {
    _active: true,
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 6),
    }),
    shadow: {
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
      fill: {
        color: "",
        opacity: 100,
      },
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    placeholderColor: {
      color: "#000000",
      opacity: 25,
    },
    cornerRadius: getDefaultCornerRadiusValue(8),
    padding: {
      top: 4,
      bottom: 4,
      left: 4,
      right: 4,
    },
    theme: 13,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    textIcon: {
      color: {
        color: "#A7A7A7",
        opacity: 100,
      },
      distance: 5,
    },
    iconRight: {
      color: {
        color: "#000000",
        opacity: 100,
      },
      gap: 5,
      value: "ant-design:copy-outlined",
      iconSize: 16,
    },
    states: {
      [State.HOVER]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        placeholderColor: {
          color: "#000000",
          opacity: 25,
        },
        textIcon: {
          color: {
            color: "#A7A7A7",
            opacity: 100,
          },
        },
        iconRight: {
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
        decoration: [],
      },
      [State.ACTIVE]: {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#ffffff"),
        }),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        placeholderColor: {
          color: "#000000",
          opacity: 25,
        },
        textIcon: {
          color: {
            color: "#A7A7A7",
            opacity: 100,
          },
        },
        iconRight: {
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
        decoration: [],
      },
      [State.DISABLED]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        placeholderColor: {
          color: "#000000",
          opacity: 25,
        },
        textIcon: {
          color: {
            color: "#A7A7A7",
            opacity: 100,
          },
        },
        iconRight: {
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
        decoration: [],
      },
    },
  },
});
