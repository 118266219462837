import { CSSProperties } from "vue";

import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getColorFromHex,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { getTextCommonStyles } from "~~/assets/utils/styles";

import { IFields } from "../../useWidgetFields";

export const useBetslipCardsInlineStyles = (
  fields: ComputedRef<IFields>,
  states: Ref<Record<string, State>>,
  notAvailableField?: ComputedRef<IWidgetField>
) => {
  const currentNotAvailableField = computed<IWidgetField>(() => {
    if (notAvailableField?.value) {
      return notAvailableField.value;
    }

    return fields.value.not_available_status_title.field;
  });

  const activeCoefficientStateOptions = computed<IWidgetOptions>(() => {
    if (states.value.coefficient_title === State.DEFAULT) {
      return fields.value.coefficient_title.field.options;
    }

    return fields.value.coefficient_title.field.options.states[
      states.value.coefficient_title
    ];
  });

  const betslipCardsContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(fields.value.cards_container.field.options),
      ...getSpacing(
        fields.value.cards_container.field.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const notAvailableMarginStyles = computed<ElementStyle>(() => {
    return {
      ...getSpacing(
        currentNotAvailableField.value.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const betsTypeMarginStyles = computed<ElementStyle>(() => {
    return {
      ...getSpacing(
        fields.value.bets_type_title.field.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const liveMarginStyles = computed<ElementStyle>(() => {
    return {
      ...getSpacing(
        fields.value.live_status_title.field.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const notAvailableInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(currentNotAvailableField.value.options),
      ...getSpacing(
        currentNotAvailableField.value.options.margins,
        SpacingKeyName.MARGIN
      ),
      color: getColorFromHex(currentNotAvailableField.value.options.color)!,
      fontSize: `${currentNotAvailableField.value.options.theme.value}px`,
      fontFamily: currentNotAvailableField.value.options.fontFamily,
      ...getDecorationValue(currentNotAvailableField.value.options.decoration),
    };
  });

  const secondaryButtonInlineMargin = computed<ElementStyle>(() => {
    return {
      ...getSpacing(
        fields.value.my_bets_secondary_button.field.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const primaryButtonInlineMargin = computed<ElementStyle>(() => {
    return {
      ...getSpacing(
        fields.value.my_bets_primary_button.field.options.margins,
        SpacingKeyName.MARGIN
      ),
    };
  });

  const activeBetsTypeStateOptions = computed<IWidgetOptions>(() => {
    const betsTypeOptions = fields.value.bets_type_title.field.options;

    if (states.value.bets_type_title === State.SINGLE_BETS) {
      return betsTypeOptions || {};
    }

    return betsTypeOptions.states[states.value.bets_type_title];
  });

  const betsTypeInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getBackgroundColorStyle(activeBetsTypeStateOptions.value.fill),
      ...getBorderStyle(activeBetsTypeStateOptions.value.border),
      ...getCornerRadiusStyle(
        fields.value.bets_type_title.field.options.cornerRadius
      ),
      ...getSpacing(
        fields.value.bets_type_title.field.options.padding,
        SpacingKeyName.PADDING
      ),
      ...getSpacing(
        fields.value.bets_type_title.field.options.margins,
        SpacingKeyName.MARGIN
      ),
      color: getColorFromHex(activeBetsTypeStateOptions.value.color)!,
      fontSize: `${fields.value.bets_type_title.field.options.theme.value}px`,
      fontFamily: fields.value.bets_type_title.field.options.fontFamily,
      ...getDecorationValue(
        fields.value.bets_type_title.field.options.decoration
      ),
    };
  });

  const liveInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(fields.value.live_status_title.field.options),
      ...getSpacing(
        fields.value.live_status_title.field.options.margins,
        SpacingKeyName.MARGIN
      ),
      color: getColorFromHex(
        fields.value.live_status_title.field.options.color
      )!,
      ...getDecorationValue(
        fields.value.live_status_title.field.options.decoration
      ),
      fontSize: `${fields.value.live_status_title.field.options.theme.value}px`,
      fontFamily: fields.value.live_status_title.field.options.fontFamily,
    };
  });

  const eventNameField = computed<IWidgetField>(() => {
    return fields.value.event_name_title.field;
  });

  const eventNameInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getTextCommonStyles(eventNameField.value.options),
      ...getSpacing(
        eventNameField.value.options.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const betMarketInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getTextCommonStyles(fields.value.market_title.field.options),
      ...getSpacing(
        fields.value.market_title.field.options.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const betNameInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getTextCommonStyles(fields.value.bet_name_title.field.options),
      ...getSpacing(
        fields.value.bet_name_title.field.options.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const coefficientContainerInlineStyles = computed<ElementStyle>(() => {
    const options = fields.value.coefficient_title.field.options;

    return {
      ...getTextCommonStyles({
        ...options,
        ...activeCoefficientStateOptions.value,
      }),
      ...getCommonStyles({
        ...options,
        ...activeCoefficientStateOptions.value,
      }),
    };
  });

  const triangleBorderColorStyles = computed<CSSProperties>(() => ({
    ...getStylesAsVars({
      ...getColorStyle({
        ...activeCoefficientStateOptions.value.arrow?.color,
      }),
    }),
  }));

  const closeIconInlineStyles = computed<ElementStyle>(() => {
    return {
      color: getColorFromHex(fields.value.close_icon.field.options.color)!,
      fontSize: `${fields.value.close_icon.field.options.iconSize}px`,
    };
  });

  const timeFieldInlineStyles = computed<ElementStyle>(() => {
    return {
      color: getColorFromHex(fields.value.time_title.field.options.color)!,
      fontSize: `${fields.value.time_title.field.options.theme.value}px`,
      fontFamily: fields.value.time_title.field.options.fontFamily,
      ...getDecorationValue(fields.value.time_title.field.options.decoration),
      ...getSpacing(
        fields.value.time_title.field.options.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const betAmountContainerInlineStyles = computed<ElementStyle>(() => {
    const betAmountContainerOptions =
      fields.value.bet_amounts_container.field.options;
    return {
      gap: `${betAmountContainerOptions.gap}px`,
      ...getSpacing(betAmountContainerOptions.padding, SpacingKeyName.PADDING),
    };
  });

  const betAmountLabelInlineStyles = computed<ElementStyle>(() => {
    const betAmountContainerOptions =
      fields.value.bet_amounts_container.field.options;
    return {
      color: getColorFromHex(betAmountContainerOptions.labelSettings.color)!,
      fontSize: `${betAmountContainerOptions.labelSettings.theme.value}px`,
      ...getDecorationValue(betAmountContainerOptions.labelSettings.decoration),
    };
  });

  const betAmountValueInlineStyles = computed<ElementStyle>(() => {
    const betAmountContainerOptions =
      fields.value.bet_amounts_container.field.options;
    return {
      color: getColorFromHex(betAmountContainerOptions.valueSettings.color)!,
      fontSize: `${betAmountContainerOptions.valueSettings.theme.value}px`,
      ...getDecorationValue(betAmountContainerOptions.valueSettings.decoration),
    };
  });

  const myBetsWarningMessageContainerInlineStyles = computed<ElementStyle>(
    () => {
      const warningContainerOptions =
        fields.value.my_bets_warning_message.field.options;
      return {
        ...getCommonStyles(warningContainerOptions),
        color: getColorFromHex(warningContainerOptions.color)!,
        fontSize: `${warningContainerOptions.theme.value}px`,
        fontFamily: warningContainerOptions.fontFamily,
        ...getSpacing(warningContainerOptions.margins, SpacingKeyName.MARGIN),
        ...getDecorationValue(warningContainerOptions.decoration),
        gap: `${warningContainerOptions.iconSettings.gap}px`,
      };
    }
  );

  const myBetsWarningIconInlineStyles = computed<ElementStyle>(() => {
    const warningIconOptions =
      fields.value.my_bets_warning_message.field.options.iconSettings;
    return {
      color: getColorFromHex(warningIconOptions.textColor)!,
      fontSize: `${warningIconOptions.iconSize}px`,
    };
  });

  const eventContainerInlineStyles = computed<ElementStyle>(() => {
    const eventContainerOptions = fields.value.event_container.field.options;
    return {
      ...getCommonStyles(eventContainerOptions),
      ...getSpacing(eventContainerOptions.margins, SpacingKeyName.MARGIN),
    };
  });

  return {
    myBetsWarningIconInlineStyles,
    betslipCardsContainerInlineStyles,
    notAvailableMarginStyles,
    betsTypeMarginStyles,
    notAvailableInlineStyles,
    secondaryButtonInlineMargin,
    primaryButtonInlineMargin,
    betsTypeInlineStyles,
    myBetsWarningMessageContainerInlineStyles,
    betAmountValueInlineStyles,
    liveInlineStyles,
    liveMarginStyles,
    eventNameInlineStyles,
    betMarketInlineStyles,
    betNameInlineStyles,
    coefficientContainerInlineStyles,
    closeIconInlineStyles,
    timeFieldInlineStyles,
    betAmountContainerInlineStyles,
    betAmountLabelInlineStyles,
    triangleBorderColorStyles,
    eventContainerInlineStyles,
  };
};
