import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useFormUploadConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Upload caption
  */

  const formUploadCaptionStyles = computed(() => {
    return [
      typography({
        source: fields.value.upload_caption_styling.field,
      }),
    ];
  });

  /* 
    Upload list
  */

  const formUploadListStyles = computed(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.upload_list_styling.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.upload_list_styling.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Upload items
  */

  const formUploadItemDesignStyles = computed(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.upload_items_styling.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.upload_items_styling.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigVerticalSpace",
        valuePath: "options.distance",
        valueSource: fields.value.upload_items_styling.field,
        options: {
          placeholder: "None",
          label: "Distance between items",
          isBold: true,
        },
      },
    ];
  });

  const formUploadItemContentStyles = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        valueSource: fields.value.upload_items_styling.field,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.text.color",
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.text.theme",
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.text.fontFamily",
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.text.decoration",
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.fileIcon",
        valueSource: fields.value.upload_items_styling.field,
        options: {
          isStatesTab: false,
          hideToggle: true,
          label: "File icon",
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.deleteIcon",
        valueSource: fields.value.upload_items_styling.field,
        options: {
          isStatesTab: false,
          hideToggle: true,
          label: "Delete icon",
        },
      },
    ];
  });

  const currentPath = computed<string>(() => {
    if (states.value[FormStyleFieldName.UPLOAD_ITEMS] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[FormStyleFieldName.UPLOAD_ITEMS]}`;
  });

  const formUploadItemStatesStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[FormStyleFieldName.UPLOAD_ITEMS],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value[FormStyleFieldName.UPLOAD_ITEMS] = value;
        },
      },
      ...getInitialDesignList({
        source: fields.value.upload_items_styling.field,
        except: ["ConfigSpacingInputs", "ConfigCornerRadius"],
        basePath: currentPath.value,
      }),
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPath.value}.text.color`,
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentPath.value}.text.decoration`,
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("File icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPath.value}.fileIcon.textColor`,
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "None",
              label: "Icon color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Delete icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPath.value}.deleteIcon.textColor`,
            valueSource: fields.value.upload_items_styling.field,
            options: {
              placeholder: "None",
              label: "Icon color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
    Upload button
  */

  const buttonState = computed<State>(() => {
    return states.value[FormStyleFieldName.UPLOAD_BUTTON];
  });

  const {
    buttonDesignControls: uploadButtonDesignControls,
    buttonContentControls: uploadButtonContentControls,
    buttonStatesControls: uploadButtonStatesControls,
  } = useButtonControls({
    state: buttonState,
    stateHandler: (state: State) => {
      emit("update-state", {
        state: FormStyleFieldName.UPLOAD_BUTTON,
        value: state,
      });
      states.value[FormStyleFieldName.UPLOAD_BUTTON] = state;
    },
    exclude: {
      content: ["ConfigLinkType", "content/ConfigTextInputWithToggle"],
    },
  });
  return {
    formUploadCaptionStyles,

    formUploadListStyles,

    formUploadItemDesignStyles,
    formUploadItemContentStyles,
    formUploadItemStatesStyles,

    uploadButtonDesignControls,
    uploadButtonContentControls,
    uploadButtonStatesControls,
  };
};
