<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-cards-alignment p-l-16 p-r-16 p-t-16"
  >
    <p class="m-b-4">{{ label }}</p>
    <div class="wconfig-cards-alignment__cards-wrapper">
      <div
        v-for="(item, index) in iconDict"
        :key="index"
        class="wconfig-cards-alignment__item pointer"
        :class="{
          'wconfig-cards-alignment__item--active': modelValue === item.value,
        }"
      >
        <CommonIcon
          :name="item.icon"
          @click="handleValueUpdate(item.value)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { IGridSettingsControl } from "~~/models/widgets/widget-controls.model";

defineProps<{
  modelValue: string;
  label: string;
}>();

type IGridAlignmentIconDict = {
  icon: string;
  value: IGridSettingsControl["alignment"];
};

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const iconDict = computed<IGridAlignmentIconDict[]>(() => {
  return [
    {
      icon: "custom:grid-alignment-icon-start",
      value: "flex-start",
    },
    {
      icon: "custom:grid-alignment-icon-end",
      value: "flex-end",
    },
    {
      icon: "custom:grid-alignment-icon-center",
      value: "center",
    },
    {
      icon: "custom:grid-alignment-icon-space-around",
      value: "space-around",
    },
    {
      icon: "custom:grid-alignment-icon-space-evenly",
      value: "space-evenly",
    },
  ];
});

const emit = defineEmits<{
  (e: "update:model-value", event: string): void;
}>();

const handleValueUpdate = (value: string) => {
  emit("update:model-value", value);
};
</script>

<style lang="scss">
.wconfig-cards-alignment {
  &__cards-wrapper {
    @include flex(flex-start, flex-start, 0px, true);
    min-width: 231px;
  }

  &__item {
    width: 110px;
    height: 52px;
    padding: 8px;
    border: 1px solid $c-grey-15;
    border-radius: 8px;

    &--active {
      border: 2px solid $c-primary-base;
    }
  }

  &__item:nth-child(odd) {
    margin-right: 11px;
    margin-bottom: 8px;
  }

  &__item:nth-child(even) {
    margin: 0;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
