import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGrid,
  generateMarginStyle,
  generateMaxWidth,
  generateWidth,
} from "../helpers";

import {
  generateBetItemsCssString,
  generateEventInfoCssString,
  generateListTimeCssString,
  generatePlayerNameCssString,
  noItemsTextBlockStylesCssString,
} from "./sportsbook-live-widget-css";
import {
  generateHeaderCssString,
  generateTabsCssString,
} from "./betslist-widget-css";
import { generateSuspendedBetItemCssString } from "./sportsbook-mobile-live-fenix-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";

export const generateEventCardsCssString =
  (eventCards: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventCards;

    const withoutNoItems = {
      ...options,
    };

    delete withoutNoItems.decoration;
    delete withoutNoItems.theme;
    delete withoutNoItems.alignment;
    delete withoutNoItems.color;
    delete withoutNoItems.fontFamily;

    const cardsGridCss = generateGrid({
      templateColumns: `repeat(${withoutNoItems.columns}, minmax(0, 1fr))`,
      rowGap: withoutNoItems.distance,
      columnGap: withoutNoItems.distance,
    });

    cssString += generateCssClassWithContent({
      className: withoutNoItems._cssClass,
      content:
        cardsGridCss + generateMarginStyle(withoutNoItems.spacing.margin),
    });

    cssString += generateCssClassWithContent({
      className: withoutNoItems._itemCssClass,
      content:
        generateDefaultStyles(withoutNoItems) +
        generateFlex({
          flex: "flex",
          align: "unset",
          justify: "flex-end",
          direction: "column",
        }),
    });

    return cssString;
  };

export const generateBetItemAdditionalCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    if (options.displayMode === DisplayOrientation.HORIZONTAL) {
      const flex =
        "display:flex !important; flex-direction:row !important; align-items:center !important;justify-content:space-between !important;";

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: flex,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: "bets-list-live-widget-market-outcome-2-columns",
        content: `width: 100%;max-width: 50%;`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName:
          "bets-list-live-widget-market-outcome-3-columns:not(:last-child)",
        content: `width: 100%;max-width: calc(33% - ${options.distance}px);`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName:
          "bets-list-live-widget-market-outcome-3-columns:last-child",
        content: `width: 100%;max-width:33%;`,
      });

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        content: generateWidth(100, "%"),
      });

      cssString += generateCssClassWithContent({
        className: options._itemWrapperCssClass,
        content: `${generateMaxWidth("50%")}${generateWidth(100, "%")}`,
      });
    }

    return cssString;
  };

export const generateLiveBetsListWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const header = widget.content["header"];
  const title = widget.content["title"];
  const event_cards = widget.content["event_cards"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const event_time = widget.content["event_time"];
  const event_league = widget.content["event_league"];
  const event_info = widget.content["event_info"];
  const event_team_player = widget.content["event_team_player"];
  const event_score = widget.content["event_score"];
  const bet_items = widget.content["bet_items"];
  const tabs = widget.content["tabs"];
  const arrow = widget.content["arrow"];
  const no_events_title = widget.content["no_events_title"];
  const show_more_title = widget.content["show_more_title"];

  widget.options._cssClass = generateClassName("LiveBetsListWidget");
  header.options._cssClass = generateClassName("header");
  title.options._cssClass = generateClassName("title");

  event_cards.options._cssClass = generateClassName("event_cards");
  event_cards.options._itemCssClass = generateClassName("event_cards");
  event_suspended_status.options._cssClass = generateClassName(
    "event_suspended_status"
  );
  no_events_title.options._cssClass = generateClassName("no_events_title");
  show_more_title.options._cssClass = generateClassName("show_more_title");

  arrow.options._cssClass = generateClassName("arrow");
  arrow.options._wrapperCssClass = generateClassName("arrow-wrapper");
  arrow.options._containerCssClass = generateClassName("arrow-container");

  tabs.options.container._cssClass = generateClassName("tabs-container");
  tabs.options._wrapperCssClass = generateClassName("tabs-wrapper");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options.iconSettings._cssClass = generateClassName("tabs_icon");

  tabs.options._itemSelectedCssClass =
    tabs.options._cssClass + '[data-selected="true"]';

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_league.options._cssClass = generateClassName("event_league");
  event_info.options._cssClass = generateClassName("event_info");
  event_info.options._itemCssClass = generateClassName("event_info_item");
  event_team_player.options._cssClass = generateClassName("event_team_player");
  event_score.options._cssClass = generateClassName("event_score");
  bet_items.options._cssClass = generateClassName("bet_items");

  bet_items.options._itemWrapperCssClass = generateClassName(
    "bet_items_item-wrapper"
  );
  bet_items.options._itemCssClass = generateClassName("bet_items_item");
  bet_items.options._itemSelectedCssClass =
    bet_items.options._itemCssClass + '[data-selected="true"]';
  bet_items.options._itemUpCssClass =
    bet_items.options._itemCssClass + '[data-up="true"]';
  bet_items.options._itemDownCssClass =
    bet_items.options._itemCssClass + '[data-down="true"]';

  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateHeaderCssString(header as IWidgetField),
    generateStringWithStates(title, false),
    generateTabsCssString(tabs as IWidgetField, arrow as IWidgetField),
    generateEventCardsCssString(event_cards as IWidgetField),
    generateStringDefault(event_suspended_status, ["size"]),
    generateListTimeCssString(event_time as IWidgetField),
    generateStringDefault(event_league),
    generateStringDefault(event_info),
    generatePlayerNameCssString(
      event_team_player as IWidgetField,
      event_info as IWidgetField
    ),
    generateStringDefault(event_score, ["size"]),
    generateEventInfoCssString(event_info as IWidgetField),
    generateBetItemsCssString(bet_items as IWidgetField),
    generateSuspendedBetItemCssString(bet_items),
    generateBetItemAdditionalCssString(bet_items),
    generateStringDefault(no_events_title),
    noItemsTextBlockStylesCssString(
      no_events_title.options._cssClass,
      event_cards as IWidgetField
    ),
    generateStageButtonCssString(show_more_title as IWidgetField),
    generateGridLayoutCssString(event_cards)
  )("");
};
