import {
  DisplayOrientation,
  FlexDirection,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { CellLayout } from "~~/models/grid.interface";

export const displayOrientationToFlexDirection: Record<
  DisplayOrientation,
  FlexDirection
> = {
  [DisplayOrientation.WRAP]: FlexDirection.ROW,
  [DisplayOrientation.VERTICAL]: FlexDirection.COLUMN,
  [DisplayOrientation.HORIZONTAL]: FlexDirection.ROW,
};

export const flexDirectionToDisplayOrientation: Record<
  FlexDirection,
  DisplayOrientation
> = {
  [FlexDirection.ROW]: DisplayOrientation.HORIZONTAL,
  [FlexDirection.ROW_REVERSE]: DisplayOrientation.HORIZONTAL,
  [FlexDirection.COLUMN]: DisplayOrientation.VERTICAL,
  [FlexDirection.COLUMN_REVERSE]: DisplayOrientation.VERTICAL,
};

export const textPositionToFlexDirection: Record<TextPosition, FlexDirection> =
  {
    [TextPosition.BOTTOM]: FlexDirection.COLUMN,
    [TextPosition.TOP]: FlexDirection.COLUMN_REVERSE,
    [TextPosition.RIGHT]: FlexDirection.ROW,
    [TextPosition.LEFT]: FlexDirection.ROW_REVERSE,
  };

export const textPositionToCellLayout: Record<TextPosition, CellLayout> = {
  [TextPosition.BOTTOM]: CellLayout.VERTICAL,
  [TextPosition.TOP]: CellLayout.VERTICAL,
  [TextPosition.RIGHT]: CellLayout.HORIZONTAL,
  [TextPosition.LEFT]: CellLayout.HORIZONTAL,
};
