import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  DisplayOrientation,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/dropdown/initial-data";
import { TITLE_INITIAL as CARD_TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useFavoritesEventsLiveWidgetInitData = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { parentCell } = useWidthConfig();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    event_card: State.ACTIVE_EVENTS,
    tabs: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    heading: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage({
      color: getDefaultFillValue("#ffffff"),
    }),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const getInitialTitleOptions = () => {
      const result = TITLE_INITIAL();

      result.options.text.color.color = "#ffffff";
      result.options.fill.color = "#001D66";
      result.options.icon._active = true;
      result.options.icon.value = "ant-design:star-filled";
      result.options.icon.textColor.color = "#ffffff";
      result.options.arrow.textColor.color = "#ffffff";
      result.options.arrow.value = "CaretDown";
      result.options.arrow.default_value = "CaretDown";

      return result;
    };

    const initialHeadingOptions = prefillEmptyOptions(
      fields.value.heading.field.options,
      getInitialOptions({
        data: getInitialTitleOptions(),
        values: {
          cornerRadius: getDefaultCornerRadiusValue(0),
          margins: getDefaultSpacing(0),
          padding: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 8,
            bottom: 8,
          }),
        },
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_card.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "padding", "size"],
        additionalData: {
          options: {
            columns: 1,
            distance: 16,
            betItemsLayout: DisplayOrientation.HORIZONTAL,
            betItemsDistance: 16,
          },
        },
        values: {
          theme: 13,
          cornerRadius: getDefaultCornerRadiusValue(0),
          border: getDefaultBorderValue(),
          alignment: Alignment.CENTER,
          fill: getDefaultFillValue("#fafafa"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              left: 16,
              right: 16,
              top: 16,
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialStreamIconOptions = prefillEmptyOptions(
      fields.value.stream_icon.field.options,
      {
        textColor: getDefaultFillValue("#52C41A"),
        iconSize: 20,
        value: "ant-design:play-circle-filled",
      }
    );

    const initialSuspendedOptions = prefillEmptyOptions(
      fields.value.event_suspended_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...CARD_TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F62F39"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#F62F39"),
          }),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing({
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: CARD_TITLE_INITIAL(),
        exclude: ["alignment"],
        additionalData: {
          options: {
            icon: {
              size: 20,
              color: getDefaultFillValue("#69B1FF"),
              value: "mdi:clock",
            },
            padding: getDefaultSpacing(),
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favourites_button.field.options,
      {
        iconSize: 24,
        value: "ant-design:star-filled",
        color: {
          color: "#FFC53D",
          opacity: 100,
        },
      }
    );

    const initialLeagueOptions = prefillEmptyOptions(
      fields.value.event_league.field.options,
      getInitialOptions({
        data: CARD_TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            padding: getDefaultSpacing({
              top: 16,
              bottom: 8,
            }),
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialInfoOptions = prefillEmptyOptions(
      fields.value.event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            displayMode: "right",
            distance: 12,
          },
        },
      }).options
    );

    const initialEventTeamPlayerOptions = prefillEmptyOptions(
      fields.value.event_team_player.field.options,
      getInitialOptions({
        data: CARD_TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialEventScoreOptions = prefillEmptyOptions(
      fields.value.event_score.field.options,
      getInitialOptions({
        data: {
          options: {
            ...CARD_TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "alignment", "size"],
        additionalData: {
          options: {},
        },
        values: {
          theme: 13,
          spacing: {
            padding: getDefaultSpacing({
              top: 1,
              bottom: 1,
              left: 8,
              right: 8,
            }),
            margin: getDefaultSpacing({ left: 8 }),
          },
          fill: getDefaultFillValue("#4096FF"),
          color: getDefaultFillValue("#ffffff"),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_event_title.field.options,
      getInitialOptions({
        data: CARD_TITLE_INITIAL(),
        values: {
          theme: 13,
        },
        exclude: ["link"],
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.heading.field,
      initialHeadingOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_card.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.stream_icon.field,
      initialStreamIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_suspended_status.field,
      initialSuspendedOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourites_button.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_league.field,
      initialLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info.field,
      initialInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_team_player.field,
      initialEventTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_score.field,
      initialEventScoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_event_title.field,
      initialNoEventsTitleOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
