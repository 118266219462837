import { type IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { calculateDisableFieldsAllowed } from "~~/helpers/configs/disable-fields-allowed";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useWidgetFields } from "../useWidgetFields";

export const useSlideControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const slideDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
    ];
  });

  const slideContentControls = computed(() => {
    const fields = useWidgetFields(
      selectedField.value as unknown as IWidgetWithFields
    );
    const PRIMARY_WIDGET_FIELDS = ["title", "description_1", "button"];

    const disableFieldsAllowed = calculateDisableFieldsAllowed(
      PRIMARY_WIDGET_FIELDS,
      fields
    );

    if (!Object.keys(fields.value).length) return [];

    return [
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "ConfigElementPosition",
        valuePath: "options.textPosition",
        valueSource: selectedField.value,
        options: {},
        visible: fields.value.title.active,
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "group-control-element",
        options: {
          isMultiple: true,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: disableFieldsAllowed.value,
          fields: [fields.value.description_1.field],
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.displaySettings",
        valueSource: fields.value.description_1.field,
        options: {
          label: "Display settings",
          items: [
            {
              icon: "custom:one-column-setting",
              value: 1,
            },
            {
              icon: "custom:two-columns-setting",
              value: 2,
            },
            {
              icon: "custom:three-columns-setting",
              value: 3,
            },
          ],
        },
        visible: fields.value.description_1.active,
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.button.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter button text",
          label: "Button",
          max: 50,
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.button.active,
          showOptions: fields.value.button.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.button.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.button.field
            );
          },
        },
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
        },
      },
    ];
  });

  const widgetSettingsValuePath = computed<string>(() => {
    if (states.value.slide === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.slide}`;
  });

  const slideStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.slide,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "slide", value });
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${widgetSettingsValuePath.value}.fillImageColor`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${widgetSettingsValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${widgetSettingsValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  return {
    slideDesignControls,
    slideContentControls,
    slideStatesControls,
  };
};
