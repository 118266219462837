import { FillType } from "~~/models/widgets/widget-controls.model";
import { IPageFieldDetails } from "~~/models/page.model";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  DESIGN_INITIAL,
  getDefaultFillValue,
  getDefaultTheme,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL as initial } from "~~/constants/configs/card-common/initial-constants";

export const FORM_INITIAL = (formDetails?: Partial<IPageFieldDetails>) => {
  let formData = {};

  if (formDetails) {
    formData = {
      _form: {
        action: formDetails.action,
        method: formDetails.method,
        target: formDetails.target,
        captcha: {
          isGoogleRecaptchaEnabled: false,
        },
      },
    };
  }

  return {
    options: {
      ...formData,
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(2),
      verticalSpace: 15,
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
        },
      },
    },
  };
};

export const SUCCESS_MESSAGE_INITIAL = () => {
  return {
    options: {
      ...initial().options,
      ...DESIGN_INITIAL().options,
      theme: getDefaultTheme(13),
      fill: getDefaultFillValue("#E4FFE6"),
      color: getDefaultFillValue("#98D4A5"),
      spacing: {
        padding: getDefaultSpacing(5),
        margin: getDefaultSpacing({ top: 10 }),
      },
      cornerRadius: getDefaultCornerRadiusValue(4),
      iconSettings: {
        value: "ant-design:check",
        _active: true,
        iconSize: 20,
        gap: 8,
        textColor: getDefaultFillValue("#98D4A5"),
      },
    },
    title: "Success message style",
  };
};
