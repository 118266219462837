<template>
  <div
    :style="cardsContainerStyles"
    class="transaction-history-tabs"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :style="cardStyles"
    >
      {{ item }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getFlexPosition,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  field: IWidgetField;
  state: State;
  items: string[];
}>();

const cardFlexStyles = computed<ElementStyle>(() => {
  if (props.field.options.buttonDisplaySettings.resizing === ResizingType.HUG) {
    return {};
  }

  return {
    flex: 1,
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const cardContentStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(currentStateOptions.value.color),
    ...getFontSizeStyle(props.field.options.theme),
    ...getDecorationValue(props.field.options.decoration),
    ...getTextAlignStyle(props.field.options.alignment),
  };
});

const cardStyles = computed<ElementStyle>(() => {
  return {
    ...cardContentStyles.value,
    ...cardFlexStyles.value,
    ...getCommonStyles({
      ...props.field.options,
      ...currentStateOptions.value,
    }),
  };
});

const cardsContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.distance),
    justifyContent: getFlexPosition(
      props.field.options.buttonDisplaySettings.alignment
    ),
  };
});
</script>

<style lang="scss">
.transaction-history-tabs {
  @include flex(stretch, flex-start, 0);
}
</style>
