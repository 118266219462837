import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useControlHover = () => {
  const widgetsSettingsStore = useWidgetSettingsStore();

  const handleMouseIn = (controlName: string) => {
    widgetsSettingsStore.setHoveredControl(controlName);
  };

  const handleMouseOut = () => {
    widgetsSettingsStore.setHoveredControl(null);
  };

  return {
    handleMouseIn,
    handleMouseOut,
  };
};
