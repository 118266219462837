import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

const SETTINGS_ITEMS = [
  {
    label: "Title",
    value: "title",
  },
  {
    label: "View mode switch",
    value: "tabs_container",
  },
  {
    label: "Banner",
    value: "banner_container",
  },
  {
    label: "Market cards",
    value: "market_cards",
  },
];

export const useEventItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("View mode switch", "tabs_container"),
    generateDropdownItem("Buttons", "tabs"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Banner", "banner_container"),
    generateDropdownItem("Market cards", "market_cards"),
    generateDropdownItem("Suspended status", "market_suspended_status"),
    generateDropdownItem("Market name", "market_name"),
    generateDropdownItem("Pin icon", "favourite_event_market"),
    generateDropdownItem("Bet items", "bet_items"),

    generateDropdownItem("Betting constructor", "betbuilder_container"),
    generateDropdownItem("Header", "betbuilder_header", "constructor"),
    generateDropdownItem(
      "Content",
      "betbuilder_content_container",
      "constructor"
    ),
    generateDropdownItem(
      "Description",
      "betbuilder_content_description",
      "constructor"
    ),
    generateDropdownItem("Iframe", "betbuilder_content_iframe", "constructor"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    market_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    market_suspended_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betbuilder_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    betbuilder_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betbuilder_content_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
