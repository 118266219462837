import { getDefaultFontFamily } from "~~/constants/configs/common/widget-initial";

export const LABELS_STYLE_INITIAL = () => ({
  title: "Label style",
  options: {
    _active: true,
    color: {
      color: "#000000",
      opacity: 100,
    },
    theme: 13,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    position: "vertical",
    states: {
      error: {
        color: {
          color: "#000000",
          opacity: 100,
        },
      },
      success: {
        color: {
          color: "#000000",
          opacity: 100,
        },
      },
    },
  },
});
