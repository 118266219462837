<template>
  <div class="bets-list-tabs">
    <WidgetField
      v-if="fields.tabs.active"
      :widget="widget"
      :field="fields.tabs.field"
      :style="headerTabsInlineStyles"
      class="bets-list-tabs__header-tabs"
      :class="{
        'bets-list-tabs__header-tabs--hidden-scroll':
          tabsOptions.overflow === Overflow.HIDDEN ||
          tabsOptions.overflow === Overflow.ARROWS,
      }"
    >
      <template v-if="sortedSelectedSports.length">
        <div
          v-for="sport in sortedSelectedSports"
          :key="sport.name"
          class="bets-list-tabs__tabs-element"
          :style="tabsStyles"
        >
          <CommonIcon
            v-if="fields.tabs.field.options.iconSettings._active"
            :name="icon || 'ion:football-outline'"
            :style="headerTabIconInlineStyle"
          />
          <span
            v-if="fields.tabs.field.options.text?._active"
            :style="tabsContentStyles"
            >{{ sport.name }}</span
          >
        </div>
      </template>

      <template v-else>
        <div :style="tabsStyles">
          <span :style="tabsContentStyles"> There are tabs </span>
        </div>
      </template>
    </WidgetField>

    <WidgetField
      v-if="fields.tabs.field.options.overflow === Overflow.ARROWS"
      :widget="widget"
      :field="fields.arrow.field"
    >
      <div
        :style="arrowContainerStyles"
        class="bets-list-tabs__arrow-container"
      >
        <CommonIcon
          class="bets-list-tabs__arrow bets-list-tabs__arrow--right"
          :class="{
            'bets-list-tabs__arrow--right-inside':
              fields.arrow.field.options.arrowSettings.position === 'inside',
          }"
          :style="arrowStyles"
          :name="arrowIcons"
        />
      </div>
    </WidgetField>
  </div>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue";

import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import {
  getBackgroundColorStyle,
  getBackgroundFillStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontSizeStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { useBetsListSortedSelectedSports } from "~~/composables/widgets/bets-list/useBetsListSortedSelectedSports";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import type { ISportItem } from "~~/models/stores/widgets-store.model";
import {
  State,
  ResizingType,
  Alignment,
  SpacingKeyName,
  Overflow,
  SliderItemsPosition,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  isMockTabs?: boolean;
  icon?: string;
}>();

const widgetsStore = useWidgetsStore();

const fields = useWidgetFields(props.widget);

const { sportsItems } = storeToRefs(widgetsStore);

const sortedSelectedSports = computed<Array<ISportItem>>(() => {
  return props.isMockTabs
    ? [
        { id: "1", name: "Tab 1", position: 0, published: true, slug: "" },
        { id: "2", name: "Tab 2", position: 1, published: true, slug: "" },
        { id: "3", name: "Tab 3", position: 2, published: true, slug: "" },
      ]
    : useBetsListSortedSelectedSports(props.widget, sportsItems) || [];
});

const tabsOptions = computed<IWidgetOptions>(() => {
  return fields.value.tabs.field.options;
});

const activeTabsContainerStateOptions = computed<IWidgetOptions>(() => {
  if (props.states.tabs === State.DEFAULT) {
    return tabsOptions.value;
  }

  return tabsOptions.value.states[props.states.tabs];
});

const tabsStyles = computed<ElementStyle>(() => {
  const isFill =
    tabsOptions.value.displaySettings.resizing === ResizingType.FILL;

  return {
    ...getBorderStyle(activeTabsContainerStateOptions.value.border),
    ...getBoxShadowStyle(activeTabsContainerStateOptions.value.shadow),
    ...getBackgroundColorStyle(activeTabsContainerStateOptions.value.fill),
    ...getCornerRadiusStyle(tabsOptions.value.cornerRadius),
    ...getSpacing(tabsOptions.value.padding, SpacingKeyName.PADDING),
    ...getSpacing(tabsOptions.value.margins, SpacingKeyName.MARGIN),
    gap: getPxValueFromNumber(tabsOptions.value.iconSettings.gap),
    width: isFill ? "100%" : "fit-content",
    flexShrink: isFill ? "1" : "0",
    whiteSpace: "nowrap",
    justifyContent: isFill
      ? Alignment.CENTER
      : generateFlexAlignment(tabsOptions.value.displaySettings.alignment),
  };
});

const tabsContentStyles = computed<ElementStyle>(() => {
  return {
    color: getColorFromHex(activeTabsContainerStateOptions.value.color)!,
    ...getFontSizeStyle(tabsOptions.value.theme),
    fontFamily: tabsOptions.value.fontFamily,
    ...getDecorationValue(activeTabsContainerStateOptions.value.decoration),
  };
});

const headerTabIconInlineStyle = computed<ElementStyle>(() => {
  return {
    color: getColorFromHex(
      activeTabsContainerStateOptions.value.iconSettings.textColor
    )!,
    fontSize: getPxValueFromNumber(tabsOptions.value.iconSettings.iconSize),
  };
});

const headerTabsInlineStyles = computed<ElementStyle>(() => {
  const isFill =
    tabsOptions.value.displaySettings.resizing === ResizingType.FILL;
  const arrowSettings = fields.value.arrow.field.options.arrowSettings;
  let width = {
    width: "100%",
  };

  if (tabsOptions.value.overflow === Overflow.ARROWS) {
    width = {
      width:
        arrowSettings.position === SliderItemsPosition.INSIDE
          ? `calc(100% + ${getPxValueFromNumber(arrowSettings.size + 4)})`
          : "calc(100% - 8px)",
    };
  }

  return {
    gap: getPxValueFromNumber(tabsOptions.value.spaceBetween),
    justifyContent: isFill
      ? ""
      : generateFlexAlignment(tabsOptions.value.displaySettings.alignment),
    overflow: "auto",
    ...width,
  };
});

const activeArrowStateOptions = computed(() => {
  if (props.states.arrow === State.DEFAULT) {
    return fields.value.arrow.field.options;
  }

  return fields.value.arrow.field.options.states[props.states.arrow];
});

const arrowContainerStyles = computed<CSSProperties>(() => {
  return {
    ...getBackgroundFillStyle(activeArrowStateOptions.value.fill),
    ...getBorderStyle(activeArrowStateOptions.value.border),
    ...getBoxShadowStyle(activeArrowStateOptions.value.shadow),
    ...getCornerRadiusStyle(fields.value.arrow.field.options.cornerRadius),
  };
});

const arrowStyles = computed<CSSProperties>(() => {
  return {
    color:
      getColorFromHex(fields.value.arrow.field.options.arrowSettings.color) ||
      "",
    fontSize: getPxValueFromNumber(
      fields.value.arrow.field.options.arrowSettings.size
    ),
  };
});

const arrowIcons = computed<string>(
  () => fields.value.arrow.field.options.arrowSettings.icon
);
</script>

<style lang="scss">
.bets-list-tabs {
  display: grid;
  grid-template-columns: 1fr auto;

  &__header-tabs {
    @include flex(center, flex-start, 0px);

    &--hidden-scroll {
      scrollbar-width: none;
      -ms-overflow-style: none;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
    }
  }

  &__tabs-element {
    @include flex(center, center, 8px);
  }

  &__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    transform: rotate(180deg);
    padding: 2px;
  }
}

.bets-list-tabs__header-tabs--hidden-scroll::-webkit-scrollbar {
  display: none;
}
</style>
