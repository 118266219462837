export interface ISettingsDropdownItem {
  value: string | number | undefined;
  group?: string | null;
  /*
    Dropdown item is visible in list,
    but not clickable
  */
  disabled?: boolean;
  parent?: string;
}

export type Tab = {
  value: "design" | "content" | "states" | "data" | string;
  label: string;
  parent: string;
};

export type ItemTabs = Record<string, Tab[]>;

export const DEFAULT_SETTINGS_TAB = "design";

export const DROPDOWN_ROOT_ITEMS_GROUP = "_root";

export type ControlProp = {
  componentPath?: string;
  valuePath?: string | null;
  options?: Record<string, any>;
  value?: string;
  controls?: ControlProp[];
  section?: string;
  valueSource?: any;
  className?: string;
  onUpdate?: (value: any) => void;
  visible?: boolean;
  toggleable?: boolean;
  allowDisable?: boolean;
  withoutSectionLabel?: boolean;
  showConfig?: boolean;
  onConfigClick?: (value?: any) => void;
};
