<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <BreadcrumbsWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />
    <div
      class="breadcrumbs-widget"
      :style="widgetContainerStyles"
    >
      <div
        class="breadcrumbs-widget__inner"
        :style="innerAlignmentStyles"
      >
        <div
          v-for="(item, index) in widget.options.breadcrumbs"
          :key="`${item}_${index}`"
          class="breadcrumbs-widget__container"
        >
          <WidgetField
            v-if="fields.separators.active && index > 0"
            :widget="widget"
            :style="widgetGapStyles"
            :field="fields.separators.field"
          >
            <CommonIcon
              :name="fields.separators.field.options.value"
              :size="fields.separators.field.options.iconSize"
              class="breadcrumbs-widget__icon"
            />
          </WidgetField>
          <WidgetField
            v-if="fields.links.active"
            :widget="widget"
            :style="widgetGapStyles"
            :field="fields.links.field"
          >
            <BasicTitleField
              :field="fields.links.field"
              :value="item.title.value"
              :state="states.links"
            />
          </WidgetField>
        </div>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useBreadcrumbsInitData } from "~~/composables/widgets/breadcrumbs/useBreadcrumbsInitData";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getColorFromHex, getFlexAlign } from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useBreadcrumbsInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const iconsColor = computed<string>(() => {
  return getColorFromHex(fields.value.separators.field.options.color) || "#000";
});

const widgetContainerStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    flexWrap: "nowrap",
    height: "100%",
    ...getFlexAlign(props.widget.options.display.alignment),
  };
});

const widgetGapStyles = computed<ElementStyle>(() => {
  return {
    marginRight: getPxValueFromNumber(props.widget.options.gap),
  };
});

const innerAlignmentStyles = computed<ElementStyle>(() => {
  return {
    ...getFlexAlign(props.widget.options.display.alignment),
  };
});

initFields();
</script>

<style lang="scss">
.breadcrumbs-widget {
  &__inner {
    display: flex;
    flex-wrap: nowrap;

    scrollbar-width: none;
    -ms-overflow-style: none;

    overflow: auto;
    // scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    // & > * {
    //   scroll-snap-align: start;
    // }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  &__container {
    @include flex(center, unset, 0);
  }

  &__icon {
    svg {
      color: v-bind(iconsColor);
    }
  }
}
</style>
