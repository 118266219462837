<script setup lang="ts">
import RedirectToEditor from "~~/components/PageConfigDrawer/accessScenarioEditor/RedirectToEditor.vue";
import { useFormErrors } from "~~/composables/useFormErrors";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

import type { IAccessScenario } from "./type";

const { errors } = useFormErrors();

const scenarioFormData = defineModel<IAccessScenario>("scenario", {
  required: true,
});

const { availableScenarios, step } = defineProps<{
  availableScenarios: any[];
  step: number;
}>();

const emit = defineEmits<{
  (e: "removeScenario"): void;
}>();

const actionsSelectList = [
  {
    label: "Redirect",
    value: "REDIRECT",
  },
  {
    label: "Page 404",
    value: "PAGE_404",
  },
  {
    label: "Page 403",
    value: "PAGE_403",
  },
  {
    label: "Page 401",
    value: "PAGE_401",
  },
];

function handleScenarioChange() {
  scenarioFormData.value.meta = {};
  scenarioFormData.value.action = "";
}

function changeActionToRedirect(isEnabled: boolean) {
  if (!scenarioFormData.value.meta) {
    scenarioFormData.value.meta = {};
  }

  if (isEnabled) {
    scenarioFormData.value.meta.redirectScenario = {
      url: useLocalizedValue().transformToLocalizedValue("").localizedValue,
    };
  } else {
    delete scenarioFormData.value.meta.redirectScenario;
  }
}

function handleActionChange(newAction: string) {
  scenarioFormData.value.action = newAction;

  const actionsList: Record<string, (isEnabled: boolean) => void> = {
    REDIRECT: changeActionToRedirect,
  };

  Object.values(actionsList).forEach(action => action(false));

  const handleAction = actionsList[newAction];

  if (handleAction) {
    handleAction(true);
  }
}

function removeScenario() {
  emit("removeScenario");
}
</script>

<template>
  <a-card>
    <div class="header">
      <div class="drag-btn">
        <holder-outlined style="font-size: 1.15em" />
        <a-typography-title
          class="margin-bottom-none"
          :level="5"
        >
          Rule #{{ step }}
        </a-typography-title>
      </div>

      <a-button @click="removeScenario">
        <template #icon><delete-outlined /></template>
      </a-button>
    </div>
    <a-space
      style="width: 100%"
      :size="8"
      direction="vertical"
    >
      <a-form-item
        label="Can be visited by"
        required
      >
        <a-select
          v-model:value="scenarioFormData.type"
          :options="availableScenarios"
          class="page-config-permissions-editor__scenario-select"
          @update:value="handleScenarioChange"
        />
      </a-form-item>

      <a-form-item label="Action if the visitor does not fit the criteria">
        <a-radio-group
          v-model:value="scenarioFormData.action"
          @update:value="handleActionChange"
        >
          <a-radio-button
            v-for="action in actionsSelectList"
            :key="action.value"
            :value="action.value"
          >
            {{ action.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-item>

      <RedirectToEditor
        v-if="'REDIRECT' === scenarioFormData.action"
        v-model:scenario="scenarioFormData"
        v-model:errors="errors"
      />
    </a-space>
  </a-card>
</template>

<style scoped lang="scss">
.drag-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: move;
  user-select: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
