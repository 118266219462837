import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
  TextStyle,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL as DROPDOWN_TITLE_INITIAL } from "~~/constants/configs/dropdown/initial-data";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { TITLE_INITIAL as CARD_TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useFavoritesEventsLiveFenixWidgetInitData = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { parentCell } = useWidthConfig();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    event_card: State.DEFAULT,
    racing_event_card: State.DEFAULT,
    heading: State.DEFAULT,
    header: State.DEFAULT,
    footer: State.DEFAULT,
    event_league: State.DEFAULT,
    event_container: State.ACTIVE_EVENTS,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage({
      color: getDefaultFillValue("#ffffff"),
    }),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const getInitialTitleOptions = () => {
      const result = DROPDOWN_TITLE_INITIAL();

      result.options.text.color.color = "#ffffff";
      result.options.fill.color = "#001D66";
      result.options.icon._active = true;
      result.options.icon.value = "ant-design:star-filled";
      result.options.icon.textColor.color = "#ffffff";
      result.options.arrow.textColor.color = "#ffffff";
      result.options.arrow.value = "CaretDown";
      result.options.arrow.default_value = "CaretDown";

      return result;
    };

    const initialHeadingOptions = prefillEmptyOptions(
      fields.value.heading.field.options,
      getInitialOptions({
        data: getInitialTitleOptions(),
        values: {
          cornerRadius: getDefaultCornerRadiusValue(0),
          margins: getDefaultSpacing(0),
          padding: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 8,
            bottom: 8,
          }),
        },
      }).options
    );

    const initialEventContainerOptions = prefillEmptyOptions(
      fields.value.event_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#ffffff", 100),
          spacing: {
            padding: {
              top: 12,
              bottom: 12,
              left: 12,
              right: 12,
            },
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
        additionalData: {
          options: {
            columns: 1,
            distance: 16,
          },
        },
      }).options
    );

    const initialSportEventCardOptions = prefillEmptyOptions(
      fields.value.event_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#f5f5f5", 100),
        },
        additionalData: {
          options: {
            layoutMode: SportMenuLiveBet9CardLayoutMode.FIRST,
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
            },
          },
        },
      }).options
    );

    const initialHeaderOptions = prefillEmptyOptions(
      fields.value.header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            layout: "vertical",
            leagueNameVerticalPosition: "top",
            leagueNameHorizontalPosition: "left",
            favoriteIconAlignment: VerticalAlignment.TOP,
            verticalSpace: 4,
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
              },
            },
          },
        },
        values: {
          spacing: {
            padding: {
              top: 12,
              bottom: 8,
              left: 12,
              right: 12,
            },
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
      }).options
    );

    const initialFooterOptions = prefillEmptyOptions(
      fields.value.footer.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            layout: DisplayOrientation.HORIZONTAL,
            verticalSpace: 8,
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
              },
            },
          },
        },
        values: {
          spacing: {
            padding: {
              top: 0,
              bottom: 12,
              left: 12,
              right: 12,
            },
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
      }).options
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favourites_button.field.options,
      {
        iconSize: 24,
        value: "ant-design:star-outlined",
        color: {
          color: "#FFC53D",
          opacity: 100,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_event_title.field.options,
      getInitialOptions({
        data: CARD_TITLE_INITIAL(),
        values: {
          theme: 13,
        },
        exclude: ["link"],
      }).options
    );

    const initialEventLeagueOptions = prefillEmptyOptions(
      fields.value.event_league.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        displayMode: "fill",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialSuspendedOptions = prefillEmptyOptions(
      fields.value.event_suspended_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...CARD_TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F62F39"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#F62F39"),
          }),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
                color: getDefaultFillValue(),
                border: getDefaultBorderValue(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
                color: getDefaultFillValue(),
                border: getDefaultBorderValue(),
              },
            },
          },
        },
      }).options
    );

    const initialStreamIconOptions = prefillEmptyOptions(
      fields.value.stream_icon.field.options,
      {
        color: getDefaultFillValue("#52C41A"),
        iconSize: 20,
        value: "ant-design:play-circle-filled",
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialEventNameOptions = prefillEmptyOptions(
      fields.value.event_team_player.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        displayMode: "fill",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["alignment"],
        values: {
          theme: 13,
          icon: {
            _active: true,
            size: 20,
            color: getDefaultFillValue("#69B1FF"),
            value: "mdi:clock",
          },
          padding: getDefaultSpacing(),
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
              icon: {
                color: getDefaultFillValue(),
              },
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
              icon: {
                color: getDefaultFillValue(),
              },
            },
          },
        },
      }).options
    );

    const initialEventPeriodOptions = prefillEmptyOptions(
      fields.value.event_period.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        displayMode: "fill",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialEventScoreOptions = prefillEmptyOptions(
      fields.value.event_score.field.options,
      getInitialOptions({
        data: {
          options: {
            ...CARD_TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "alignment", "size"],
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                color: getDefaultFillValue(),
                fill: getDefaultFillValue(),
              },
              [State.ACTIVE]: {
                color: getDefaultFillValue(),
                fill: getDefaultFillValue(),
              },
            },
          },
        },
        values: {
          theme: 13,
          spacing: {
            padding: getDefaultSpacing({
              top: 8,
              bottom: 8,
              left: 12,
              right: 12,
            }),
            margin: getDefaultSpacing(0),
          },
          fill: getDefaultFillValue("#4096FF"),
          color: getDefaultFillValue("#ffffff"),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialRacingEventCardOptions = prefillEmptyOptions(
      fields.value.racing_event_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#f5f5f5", 100),
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
            },
          },
        },
      }).options
    );

    const initialRacingEventNameOptions = prefillEmptyOptions(
      fields.value.racing_event_name.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        displayMode: "fill",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialRacingStreamIconOptions = prefillEmptyOptions(
      fields.value.racing_stream_icon.field.options,
      {
        color: getDefaultFillValue("#52C41A"),
        iconSize: 20,
        value: "ant-design:play-circle-filled",
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialRacingStatusOptions = prefillEmptyOptions(
      fields.value.racing_event_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...CARD_TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#e6f2fe"),
          color: getDefaultFillValue("#1677FF"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          border: getDefaultBorderValue(),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing(),
          },
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
                color: getDefaultFillValue(),
                border: getDefaultBorderValue(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue(),
                color: getDefaultFillValue(),
                border: getDefaultBorderValue(),
              },
            },
          },
        },
      }).options
    );

    const initialRacingFavIconOptions = prefillEmptyOptions(
      fields.value.racing_favourites_icon.field.options,
      {
        iconSize: 24,
        value: "ant-design:star-outlined",
        color: getDefaultFillValue("#FFC53D"),
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    widgetsStore.updateFieldOptions(
      fields.value.heading.field,
      initialHeadingOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_container.field,
      initialEventContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_card.field,
      initialSportEventCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.header.field,
      initialHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.footer.field,
      initialFooterOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourites_button.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_league.field,
      initialEventLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_suspended_status.field,
      initialSuspendedOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.stream_icon.field,
      initialStreamIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_team_player.field,
      initialEventNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_period.field,
      initialEventPeriodOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_score.field,
      initialEventScoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_card.field,
      initialRacingEventCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_name.field,
      initialRacingEventNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_stream_icon.field,
      initialRacingStreamIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_event_status.field,
      initialRacingStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.racing_favourites_icon.field,
      initialRacingFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_event_title.field,
      initialNoEventsTitleOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
  };
};
