import { useWidgetsStore } from "~~/store/widgets";
import { isSimpleValue, prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  Alignment,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultTheme,
  getDefaultShadow,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  BUTTON_TABS_CONTAINER_INITIAL,
  BUTTON_TABS_ELEMENT_INITIAL,
} from "~~/constants/configs/common/button-tabs-initial";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { Sizing } from "~~/models/grid.interface";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, string> = {
    players_info_player_current_odds: State.DEFAULT,
    tabs: State.DEFAULT,
    players_info_card: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({ ...DEFAULT_STATES });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, [], {
          [FormStyleFieldName.CHECKBOX]: {
            color: getDefaultFillValue("#ffffff"),
          },
        })
      );

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    /*
    Initialize widget with initial options
  */
    const initialWidgetOptions = initWidgetOptions([], {
      contentWidth: {
        type: Sizing.ADAPTIVE,
        width: 320,
        alignment: "center",
      },
    });

    const initialIframeContainerOptions = prefillEmptyOptions(
      fields.value.iframe_container.field.options,
      getInitialOptions({
        data: { options: {} },
      }).options
    );

    const initialTabsContainerOptions = prefillEmptyOptions(
      fields.value.tabs_container.field.options,
      getInitialOptions({
        data: BUTTON_TABS_CONTAINER_INITIAL(),
      }).options
    );

    const initialTabsOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: BUTTON_TABS_ELEMENT_INITIAL(),
      }).options
    );

    const initialEventInfoOptions = prefillEmptyOptions(
      fields.value.event_info_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            distance: 16,
          },
        },
      }).options
    );

    const initialEventInfoTitleOptions = prefillEmptyOptions(
      fields.value.event_info_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
      }).options
    );

    const initialEventInfoDescriptionOptions = prefillEmptyOptions(
      fields.value.event_info_description.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(13),
        },
      }).options
    );

    const initialPlayersInfoOptions = prefillEmptyOptions(
      fields.value.players_info_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            mode: "table",
            displayMode: {
              distanceBetweenItems: 16,
              distanceBetweenCards: 1,
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#000000", 5),
        },
      }).options
    );

    const initialPlayerInfoHeaderOptions = prefillEmptyOptions(
      fields.value.players_info_header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: TITLE_INITIAL().options,
        },
        exclude: ["padding"],
        values: {
          fill: getDefaultFillValue("#ffffff"),
          theme: getDefaultTheme(14),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing(0),
          },
        },
      }).options
    );

    const playerInfoCardStateOptions = () => {
      return {
        players_info_player_number: {
          color: getDefaultFillValue("#000000"),
        },
        players_info_player_name: {
          color: getDefaultFillValue("#000000"),
        },
        players_info_player_last_score: {
          color: getDefaultFillValue("#000000"),
        },
        players_info_player_prev_odds: {
          color: getDefaultFillValue("#000000"),
        },
        players_info_player_current_odds: {
          fill: getDefaultFillValue("#E6F4FF"),
          border: getDefaultBorderValue(),
          color: getDefaultFillValue("#1677FF"),
        },
      };
    };

    const initialPlayerInfoCardOptions = prefillEmptyOptions(
      fields.value.players_info_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            ...playerInfoCardStateOptions(),
            states: {
              [State.DISABLED]: {
                fill: getDefaultFillValue("#ffffff"),
                ...playerInfoCardStateOptions(),
              },
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing(0),
          },
        },
      }).options
    );

    const initialPlayerNumberOptions = prefillEmptyOptions(
      fields.value.players_info_player_number.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        additionalData: {
          options: {
            iconSize: 20,
          },
        },
        values: {
          theme: getDefaultTheme(14),
        },
      }).options
    );

    const initialPlayerNameOptions = prefillEmptyOptions(
      fields.value.players_info_player_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          decoration: [TextStyle.BOLD],
          theme: getDefaultTheme(14),
        },
      }).options
    );

    const initialLastScoreOptions = prefillEmptyOptions(
      fields.value.players_info_player_last_score.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(14),
        },
      }).options
    );

    const initialPrevOddsOptions = prefillEmptyOptions(
      fields.value.players_info_player_prev_odds.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(14),
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        color: getDefaultFillValue("#1677FF"),
        arrow: {
          color: getDefaultFillValue("#1677ff"),
        },
      };
    };

    const initialOddsOptions = prefillEmptyOptions(
      fields.value.players_info_player_current_odds.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            fill: getDefaultFillValue("#E6F4FF"),
            border: getDefaultBorderValue(),
            shadow: getDefaultShadow(),
            cornerRadius: getDefaultCornerRadiusValue(4),
            padding: getDefaultSpacing({
              left: 0,
              right: 0,
              top: 4,
              bottom: 4,
            }),
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
              [State.UP]: initialBetItemState(),
              [State.DOWN]: initialBetItemState(),
            },
          },
        },
        values: {
          color: getDefaultFillValue("#1677FF"),
          theme: getDefaultTheme(13),
          decoration: [],
          alignment: Alignment.CENTER,
        },
      }).options
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

    widgetsStore.updateFieldOptions(
      fields.value.iframe_container.field,
      initialIframeContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs_container.field,
      initialTabsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info_container.field,
      initialEventInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info_title.field,
      initialEventInfoTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info_description.field,
      initialEventInfoDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_container.field,
      initialPlayersInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_header.field,
      initialPlayerInfoHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_card.field,
      initialPlayerInfoCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_player_name.field,
      initialPlayerNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_player_number.field,
      initialPlayerNumberOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_player_last_score.field,
      initialLastScoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_player_prev_odds.field,
      initialPrevOddsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.players_info_player_current_odds.field,
      initialOddsOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  const checkboxesStylingFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      ["checkboxes_styling"].includes(field.name)
    );
  });

  return {
    states,
    DEFAULT_STATES,
    checkboxesStylingFields,

    initFields,
    initWidgetFieldsOptions,
  };
};
