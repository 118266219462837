import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

import { useWidgetFields } from "../useWidgetFields";

export const useTitleInitialControls = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "config-group-last-element",
        options: {
          placeholder: "Enter text",
          label: "Active",
          active: true,
        },
      },
      typography({ source: fields.value.title.field! }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: fields.value.title.field,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    titleControls,
  };
};
