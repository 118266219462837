import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { FormScreen } from "~~/models/widgets/form.model";
import { generateCustomSuccessMessageCss } from "~~/assets/utils/widget-css/widgets/user-profile-form-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateFieldsWithStatesCssString,
  generateStageButtonCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateTextChooseIconCssString,
  generateScreensCssString,
  generateIconRightCssString,
  generateIconCssString,
  generateIconSizeCssString,
  generateFlexContainerCssString,
} from "../utils/form-helper-functions";
import { generateFormSuccessCssString } from "../utils/generate-form-success";

export const generateChangePasswordFormWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const form = widget.content["form"];
  const submitButton = widget.content["submit"];
  const title = widget.content["title"];
  const customFields = widget.options._customFields;
  const submitButtonClassName = generateClassName("submitButton");
  const titleClassName = generateClassName("title");
  const description1 = widget.content["description_1"];
  const success_message = widget.content["success_message"];

  // Widget class name
  widget.options._cssClass = generateClassName("ChangePasswordWidget");
  success_message.options._cssClass = generateClassName("success_message");
  success_message.options.iconSettings._cssClass = generateClassName(
    "success_message_icon"
  );

  const screens = [FormScreen.SUCCESS];

  // Form, Label, Field, Error, Tooltip class names
  form.options._cssClass = generateClassName("form");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  description1.options._cssClass = generateClassName("description_1_");

  // Submit button class name
  submitButton.options._cssClass = submitButtonClassName;
  submitButton.options.iconSettings._cssClass = generateClassName("buttonIcon");
  // customFields.buttons_styling.options._cssClass = submitButtonClassName;

  // Title class name
  title.options._cssClass = titleClassName;
  customFields.title_styling.options._cssClass = titleClassName;

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");
  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  return pipeSync<string>(
    generateStringDefault({
      ...widget,
      options: {
        ...widget.options,
        size: null,
      },
    }),
    generateFormCssString(form, widget),
    generateScreensCssString(widget, "ChangePasswordWidget", screens),
    generateStringDefault(customFields.title_styling),
    generateStageButtonCssString(submitButton),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    generateFormSuccessCssString(widget as IPageContentWidget),
    generateStringDefault(description1),
    generateStringDefault(success_message),
    generateIconCssString(success_message.options.iconSettings, "textColor"),
    generateIconSizeCssString(success_message.options.iconSettings, "iconSize"),
    generateFlexContainerCssString(success_message),
    generateCustomSuccessMessageCss(success_message)
  )("");
};
