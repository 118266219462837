import { type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useNoItemsTextConfig } from "~~/composables/widgets/common/useNoItemsTextConfig";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { CellSizeType } from "~~/models/grid.interface";

export const useEventCardsFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any,
  fieldPrefix = ""
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);
  /* 
    Event cards
  */

  const eventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.card.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.card.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.card.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.card.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.card.spacing",
        valueSource: selectedField.value,
        options: {},
      },
    ];
  });

  const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: `${fieldPrefix}event_cards`,
      noTextFieldName: `${fieldPrefix}no_events_title`,
    }),
    emit
  );

  const eventCardsContentControls = computed(() => {
    if (states.value[`${fieldPrefix}event_cards`] === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.columns",
            valueSource: selectedField.value,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distanceBetweenCards",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: selectedField.value,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Start time / date",
              value: `${fieldPrefix}list_start_time`,
            },
            {
              label: "League",
              value: `${fieldPrefix}league_title`,
            },
            {
              label: "Team / Player",
              value: `${fieldPrefix}list_event_name`,
            },
            {
              label: "Bet items",
              value: `${fieldPrefix}list_item`,
            },
            {
              label: "Event button link",
              value: `${fieldPrefix}list_item_more`,
            },
          ],
        },
      },
    ];
  });

  /* 
      Start time date
    */

  const startTimeDateControls = computed(() => {
    return [
      {
        ...typography({
          source: selectedField.value,
          except: ["ConfigAlignment"],
          addElements: [
            {
              componentPath: "ConfigAlignment",
              valuePath: "options.alignment",
              valueSource: selectedField.value,
              className: "group-control-element--child",
              options: {
                label: "Alignment",
                isTextContentIcons: false,
              },
            },
          ],
        }),
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.spaceBetween",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          label: "Space between items",
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          isBold: true,
        },
      },
    ];
  });

  /* 
    Event button link
  */

  const eventButtonLinkDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          isHorizontalMargin: true,
        },
      },
    ];
  });

  const eventButtonLinkContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              type: ColorPickerType.TEXT,
              icon: "ant-design:column-width-outlined",
              isWidth: true,
              exclude: [CellSizeType.FILL],
            },
          },
        ],
      },
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const eventButtonLinkValuePath = computed<string>(() => {
    if (states.value[`${fieldPrefix}list_item_more`] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[`${fieldPrefix}list_item_more`]}`;
  });

  const eventButtonLinkStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value[`${fieldPrefix}list_item_more`],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: `${fieldPrefix}list_item_more`,
            value,
          });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${eventButtonLinkValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${eventButtonLinkValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${eventButtonLinkValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${eventButtonLinkValuePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    eventCardsDesignControls,
    eventCardsContentControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  };
};
