import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 66;

const v66WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  const labelsStyling = widget.options._customFields?.labels_styling?.options;
  if (labelsStyling && !labelsStyling.containerPadding) {
    labelsStyling.containerPadding = getDefaultSpacing();
  }

  widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};
export default v66WidgetMigration;
