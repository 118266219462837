<!-- TODO: move to another location -->
<template>
  <BasicSlideContainer
    :style="sizeStyles"
    :slide-field="slideField"
    :widget="widget"
    :show-arrows="showArrows"
    :show-pagination="showPagination"
    :states="states"
    :class="`banner-slide--${slideField.options.alignment}`"
    class="banner-slide"
  >
    <BasicCardField
      ref="cardField"
      :widget="widget"
      :field="slideField"
      :button-state-1="states['button_1']"
      :button-state-2="states['button_2']"
      :title-options="{
        ...fields.title.field.options,
        alignment: slideField.options.alignment,
      }"
      class="banner-slide__card"
    >
      <template #image>
        <FlexibleImageField
          :widget="widget"
          :field="fields.image.field"
      /></template>
    </BasicCardField>
  </BasicSlideContainer>
</template>

<script setup lang="ts">
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import type {
  IWidgetWithFields,
  ICustomField,
} from "~~/models/widgets/widget.core/widget.model";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { useWidgetsStore } from "~~/store/widgets";
import {
  Alignment,
  FillType,
  State,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";
import {
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
  BUTTON_INITIAL,
  BUTTONS_GROUP_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultFillImage,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { ElementStyle } from "~~/models/common";
import { CellSizeType } from "~~/models/grid.interface";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import BasicCardField from "~~/components/fields/basic/BasicCardField.vue";

const props = defineProps<{
  slideField: ICustomField;
  states: Record<string, State>;
  widget: IWidgetWithFields;
  showPagination: boolean;
  showArrows: boolean;
}>();

const widgetsStore = useWidgetsStore();

const fields = useWidgetFields(
  props.slideField as unknown as IWidgetWithFields
);

const cardField = ref<InstanceType<typeof BasicCardField>>();

defineExpose({
  cardField,
});

/*
  Initialize widget with initial options
*/

const initialWidgetOptions = prefillEmptyOptions(
  props.slideField.options,
  getInitialOptions({
    data: DESIGN_INITIAL(),
    exclude: ["fill"],
    additionalData: {
      options: {
        layoutPosition: {
          position: "bottom",
          alignment: null,
        },
        alignment: Alignment.LEFT,
        fillImageColor: getDefaultFillImage(),
        link: {
          type: null,
          value: null,
          authorizeValue: null,
          hasAuthorizeValue: false,
          isNewTabOpen: null,
        },
        states: {
          [State.HOVER]: {
            fillImageColor: {
              fillType: FillType.COLOR,
              value: {
                color: "",
                opacity: 100,
              },
            },
            border: getDefaultBorderValue(),
            shadow: {
              fill: {
                color: null,
                opacity: 100,
              },
              x: 0,
              y: 0,
              blur: 0,
              spread: 0,
            },
          },
          [State.ACTIVE]: {
            fillImageColor: {
              fillType: FillType.COLOR,
              value: {
                color: "",
                opacity: 100,
              },
            },
            border: getDefaultBorderValue(),
            shadow: {
              fill: {
                color: null,
                opacity: 100,
              },
              x: 0,
              y: 0,
              blur: 0,
              spread: 0,
            },
          },
        },
      },
    },
  }).options
);

const initialTitleOptions = prefillEmptyOptions(
  fields.value.title.field.options,
  TITLE_INITIAL().options
);

const initialDescriptionOptions = prefillEmptyOptions(
  fields.value.description_1.field.options,
  DESCRIPTION_INITIAL().options
);

const initialButtonsGroupOptions = prefillEmptyOptions(
  fields.value.buttons_group.field.options,
  BUTTONS_GROUP_INITIAL().options
);

const initialButton1Options = prefillEmptyOptions(
  fields.value.button_1.field.options,
  BUTTON_INITIAL().options
);

const initialButton2Options = prefillEmptyOptions(
  fields.value.button_2.field.options,
  BUTTON_INITIAL().options
);

const initialImageOptions = prefillEmptyOptions(
  fields.value.image.field.options,
  {
    ...FLEXIBLE_IMAGE_INITIAL().options,
    _isImagePreload: false,
    _seo: {
      title: "",
      alt: "",
    },
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
  }
);

widgetsStore.updateFieldOptions(props.slideField, initialWidgetOptions);
widgetsStore.updateFieldOptions(fields.value.title.field, initialTitleOptions);
widgetsStore.updateFieldOptions(
  fields.value.description_1.field,
  initialDescriptionOptions
);
widgetsStore.updateFieldOptions(
  fields.value.buttons_group.field,
  initialButtonsGroupOptions
);
widgetsStore.updateFieldOptions(
  fields.value.button_1.field,
  initialButton1Options
);
widgetsStore.updateFieldOptions(
  fields.value.button_2.field,
  initialButton2Options
);
widgetsStore.updateFieldOptions(fields.value.image.field, initialImageOptions);

const getWidthStyles = (width: WidgetDimension): ElementStyle => {
  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.type === CellSizeType.HUG) {
    return {
      width: "initial",
      // display: "inline-block",
    };
  }

  return {
    width: `${width.value?.value}${width.value?.type}`,
  };
};

const getHeightStyles = (height: WidgetDimension): ElementStyle => {
  if (!height || height.type === CellSizeType.HUG) {
    return {
      height: "initial",
      // display: "inline-block",
    };
  }

  if (height.type === CellSizeType.FILL) {
    return {
      alignSelf: "stretch",
    };
  }

  return {
    height: `${height.value?.value}${height.value?.type}`,
  };
};

const sizeStyles = computed<ElementStyle>(() => {
  const size = props.widget.options.slideOptions.size;

  if (!size) {
    return {};
  }

  const widthStyles = getWidthStyles(size.width);
  const heightStyles = getHeightStyles(size.height);

  return {
    ...widthStyles,
    ...heightStyles,
  };
});
</script>

<style lang="scss">
.banner-slider-widget {
  .banner-slide {
    height: 100%;

    &__card {
      & > div {
        flex: 1;
      }
      .basic-card-field__description {
        flex-grow: initial;
      }
    }

    &--left {
      .basic-card-field {
        align-items: flex-start;
      }

      .basic-card-field__text {
        align-items: flex-start;
      }

      .basic-card-field__buttons-group {
        justify-content: flex-start !important;
      }
    }

    &--center {
      .basic-card-field {
        align-items: center;
      }
      .basic-card-field__text {
        align-items: center;
      }

      .basic-card-field__buttons-group {
        justify-content: center !important;
      }
    }

    &--right {
      .basic-card-field {
        align-items: flex-end;
      }
      .basic-card-field__text {
        align-items: flex-end;
      }

      .basic-card-field__buttons-group {
        justify-content: flex-end !important;
      }
    }
  }
}
</style>
