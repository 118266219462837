import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useSportsbookLiveFenixItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Sports", "sports_container"),
    generateDropdownItem("Sport events", "sport_events_container"),
    generateDropdownItem("Racing events", "racing_events_container"),
    generateDropdownItem("Sport event card", "event_cards"),
    generateDropdownItem("Racing event card", "racing_event_cards"),

    generateDropdownItem("Sports title", "title", "sportsTitle"),
    generateDropdownItem("Sports title button", "title_button", "sportsTitle"),
    generateDropdownItem("Hover button", "title_hover_button", "sportsTitle"),
    generateDropdownItem("Leagues", "leagues_titles", "sportsTitle"),

    generateDropdownItem("Header", "event_header", "eventCard"),
    generateDropdownItem("Event Info", "event_info", "eventCard"),
    generateDropdownItem("Bet Items", "bet_items", "eventCard"),
    generateDropdownItem("Sports Events Button", "event_button", "eventCard"),

    generateDropdownItem("League name", "event_league", "eventHeader"),
    generateDropdownItem("Favorite Icon", "favourites_button", "eventHeader"),
    generateDropdownItem("Stream Icon", "stream_icon", "eventHeader"),
    generateDropdownItem("Time", "event_time", "eventHeader"),
    generateDropdownItem("Period", "event_period", "eventHeader"),
    generateDropdownItem("Status", "event_suspended_status", "eventHeader"),

    generateDropdownItem("Event Name", "event_name", "eventInfo"),
    generateDropdownItem("Score", "event_score", "eventInfo"),

    generateDropdownItem("Stream Icon", "racing_event_stream_icon", "racing"),
    generateDropdownItem("Event Name", "racing_event_name", "racing"),
    generateDropdownItem("Race status", "racing_event_status", "racing"),
    generateDropdownItem(
      "Favorite Icon",
      "racing_event_favourites_button",
      "racing"
    ),
    generateDropdownItem(
      "Sports Events Button",
      "racing_event_button",
      "racing"
    ),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    sports_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title_hover_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    leagues_titles: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    sport_events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_suspended_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_event_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
