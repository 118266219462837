<template>
  <div class="width-container">
    <h3 class="width-container__header">Width</h3>

    <ConfigSizing
      :sizing="widthParams.sizing"
      :is-disabled="shouldDisableControls"
      :tooltip-message="CHANGING_SIZING_NOT_POSSIBLE"
      @update:sizing="updateActiveSizing"
    ></ConfigSizing>

    <a-form
      ref="form"
      :model="widthParams"
      :rules="validationRules"
      @finish="updateCell"
    >
      <a-form-item
        hidden
        name="sizing"
      >
        <a-radio-group v-model:value="widthParams.sizing">
          <a-radio :value="Sizing.FIXED"></a-radio>
          <a-radio :value="Sizing.ADAPTIVE"></a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        v-show="widthParams.sizing === Sizing.FIXED"
        name="width"
      >
        <div class="width-container__input-container">
          <CommonIcon
            name="ant-design:column-width-outlined"
            class="width-container__icon"
          />
          <a-input
            v-model:value="widthParams.width"
            class="width-container__input"
            size="small"
            :disabled="shouldDisableControls"
            @blur="updateCell"
          >
            <template #suffix>
              <div class="input-suffix">px</div>
            </template>
          </a-input>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { Form } from "ant-design-vue";

import { NestingLevel, Sizing } from "~~/models/grid.interface";
import { breakpoints } from "~~/models/breakpoints.model";
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { useContainerWidthValidation } from "~~/components/composables/grid/useContainerWidthValidation";
import { CHANGING_SIZING_NOT_POSSIBLE } from "~~/constants/tooltip-messages";
import { DEFAULT_ADAPTIVE_COL_WIDTH } from "~~/constants/width-validations";

const gridStore = useGridConfig();
const uiStore = useUiStore();
const { selectedCell, hasWidthSettingsErrors, areColumnsConfigurable } =
  storeToRefs(gridStore);
const { activeScreen } = storeToRefs(uiStore);

const { widthParams, validationRules } = useContainerWidthValidation();

const shouldDisableControls = computed<boolean>(() => {
  /* NOTE: prevent from changing grid in the following cases:
  - if current width settings are invalid
  - if it's a second-level container (width changes on a child could affect parent and we don't want that because logic can become too complicated)
  - if it's a first-level container with children (change from adaptive to fixed would require setting at least one fixed child but we don't allow to modify nested blocks)
  */
  return (
    hasWidthSettingsErrors.value ||
    selectedCell.value?.settings.level === NestingLevel.CHILD ||
    !areColumnsConfigurable.value
  );
});

const defaultWidth = computed<number>(() => {
  return breakpoints[activeScreen.value].screenSize;
});

const currentWidth = computed((): number | undefined => {
  if (!selectedCell.value) return;
  const { settings } = selectedCell.value;
  if (settings.sizing === Sizing.FIXED) return settings.width;
});

const form = ref();
const useForm = Form.useForm;

watch(
  () => selectedCell.value,
  () => {
    if (selectedCell.value) {
      form.value.clearValidate("width");
      useForm(widthParams).resetFields({
        sizing: selectedCell.value!.settings.sizing,
        width: currentWidth.value,
      });
    }
  }
);

const updateActiveSizing = (newActiveSizing: Sizing): void => {
  /* NOTE: for ADAPTIVE fields width should be reset to 100% */
  let width = DEFAULT_ADAPTIVE_COL_WIDTH;
  if (newActiveSizing === Sizing.FIXED) {
    width = currentWidth.value ?? defaultWidth.value;
  }
  useForm(widthParams).resetFields({
    sizing: newActiveSizing,
    width,
  });
  updateCell();
};

const updateCell = async (): Promise<void> => {
  if (form.value) {
    try {
      await form.value.validate();
      gridStore.setSelectedCellWidth(widthParams);
    } catch (e) {
      console.error(e);
    }
  }
};
</script>

<style lang="scss">
.width-container {
  @include flex-column(flex-start, flex-start, $space-m);
  border-bottom: 1px solid $c-grey-15;
  width: 100%;
  padding: $space-m $space-m 28px;

  &__header {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $c-black;
  }

  &__input-container {
    @include flex(center, space-between, 10px);
    width: 100%;
  }

  &__icon {
    color: $c-grey-45;
    font-size: 18px;
  }

  &__input {
    width: 204px;
    border: 1px solid $c-grey-15;
    border-radius: 4px;
  }
}
</style>
