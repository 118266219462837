<script lang="ts" setup>
import { getGridAlignValue, getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

const { options } = defineProps<{
  options: IWidgetOptions;
}>();

const gridWrapper = ref<HTMLElement | null>(null);
const fieldsCount = ref(0);
const alignSelf = computed(() => getGridAlignValue(options.display.alignSelf));
let observer: MutationObserver | null = null;

const gridStyles = computed<ElementStyle>(() => {
  let gridTemplateValue = "";

  for (let i = 1; i <= fieldsCount.value; i++) {
    if (i == fieldsCount.value) gridTemplateValue += "1fr";
    else gridTemplateValue += "min-content ";
  }

  return {
    display: "grid",
    width: "100%",
    alignSelf: "normal",
    gridTemplateRows: gridTemplateValue,
    gap: getPxValueFromNumber(options.display.gap),
  };
});

onMounted(() => {
  nextTick(() => {
    if (gridWrapper.value) {
      fieldsCount.value = gridWrapper.value.children.length;

      observer = new MutationObserver(() => {
        fieldsCount.value = gridWrapper.value?.children.length ?? 0;
      });

      observer.observe(gridWrapper.value, { childList: true });
    }
  });
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div
    ref="gridWrapper"
    class="basic-grid-wrapper"
    :style="gridStyles"
  >
    <slot />
  </div>
</template>

<!-- TODO: make a possibility to choose concrete element -->
<style lang="scss">
.basic-grid-wrapper {
  & > *:last-child {
    align-self: v-bind(alignSelf);
  }
}
</style>
