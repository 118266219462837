import {
  ICustomField,
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { Sizing } from "~~/models/grid.interface";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateWidth,
  generateMaxWidth,
  generateFontSize,
} from "../helpers";
import { generateItemsGroupCssString } from "../compiler/items-css-compiler";

export const generateFieldContainerCssString =
  (fieldContainer: IWidgetField) =>
  (cssString: string): string => {
    const { options } = fieldContainer;

    const textCssContent = generateDefaultStyles(options.text);
    const placeholderCssContent = generateDefaultStyles(options.placeholder);
    const iconCssContent = generateDefaultStyles(options.icon);

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      content: textCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      pseudoClassName: "::placeholder",
      content: placeholderCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconCssContent + generateFontSize(options.icon.iconSize),
    });

    for (const state in options.states) {
      let classNameString =
        state === State.ACTIVE ? `:has(input:focus)` : `:has(input:${state})`;

      classNameString =
        state === State.DISABLED ? `:has([disabled])` : classNameString;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].text,
        childClassName: options.text._cssClass,
        customFunction: generateDefaultStyles,
        pseudoClassName: classNameString,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: classNameString,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].icon,
        customFunction: generateDefaultStyles,
        pseudoClassName: `${classNameString} svg`,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].placeholder,
        customFunction: generateDefaultStyles,
        pseudoClassName: `${classNameString} input::placeholder`,
      });
    }

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
      gap: "8",
      direction:
        options.icon.position === Alignment.RIGHT ? "row-reverse" : "row",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateResultBoxCssString =
  (resultBox: IWidgetField, spaceBetween: string) =>
  (cssString: string): string => {
    const { options } = resultBox;

    const searchResultsBoxCssContent = generateDefaultStyles(options);

    const boxCssContent = generateFlex({
      flex: "flex",
      direction: "column",
      align: "initial",
      justify: "flex-start",
      gap: spaceBetween,
    });

    const position = "position:absolute;left:0;width:100%;z-index:3;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: position + boxCssContent + searchResultsBoxCssContent,
    });

    return cssString;
  };

export const generateResultItemsCssString =
  (items: IWidgetField) =>
  (cssString: string): string => {
    const { options } = items;

    const searchResultsBoxCssContent = generateDefaultStyles(options);

    const boxCssContent = generateFlex({
      flex: "flex",
      direction: "column",
      align: "initial",
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: boxCssContent + searchResultsBoxCssContent,
    });

    return cssString;
  };

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    let widgetStyles = "";

    if (options.content.contentWidth.type === Sizing.FIXED) {
      widgetStyles = generateWidth(options.content.contentWidth.width, "px");
    } else {
      widgetStyles += generateWidth(100, "%");
    }

    widgetStyles += generateMaxWidth(`100%`);

    // For search input aligment
    cssString += generateCssClassWithContent({
      className: options.content._cssClass,
      content: widgetStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateSearchFieldWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const field_container = widget.content["field_container"];
  const search_result_box = widget.content["search_result_box"];
  const search_result_items = widget.content["search_result_items"];

  widget.options._cssClass = generateClassName("SearchField");

  // Search input / Dropdown field
  field_container.options._cssClass = generateClassName("field_container");
  field_container.options.text._cssClass = generateClassName(
    "field_container--text"
  );
  field_container.options.icon._cssClass = generateClassName(
    "field_container--icon"
  );

  // Result box
  const gapValue = search_result_items.options.spaceBetween;
  search_result_box.options._cssClass = generateClassName("search_result_box");

  // Result Items
  search_result_items.options._cssClass = generateClassName(
    "search_result_items"
  );

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateFieldContainerCssString(field_container as IWidgetField),
    generateResultBoxCssString(search_result_box as IWidgetField, gapValue),
    generateItemsGroupCssString(search_result_items as ICustomField)
  )("");
};
