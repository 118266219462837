<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-decoration widget-control-container"
  >
    <p
      :class="{
        'sub-heading': meta?.isBold,
      }"
      class="m-b-4"
    >
      {{ label || "Decoration" }}
    </p>
    <div class="config-decoration__menu">
      <button
        class="config-decoration__button pointer"
        :class="{
          'config-decoration__button--active': currentDecorations.includes(
            TextStyle.BOLD
          ),
        }"
        @click="handleDecorationClick(TextStyle.BOLD)"
      >
        <CommonIcon name="ant-design:bold-outlined" />
      </button>
      <button
        class="config-decoration__button pointer"
        :class="{
          'config-decoration__button--active': currentDecorations.includes(
            TextStyle.ITALIC
          ),
        }"
        @click="handleDecorationClick(TextStyle.ITALIC)"
      >
        <CommonIcon name="ant-design:italic-outlined" />
      </button>
      <button
        class="config-decoration__button pointer"
        :class="{
          'config-decoration__button--active': currentDecorations.includes(
            TextStyle.UNDERLINE
          ),
        }"
        @click="handleDecorationClick(TextStyle.UNDERLINE)"
      >
        <CommonIcon name="ant-design:underline-outlined" />
      </button>
      <button
        class="config-decoration__button pointer"
        :class="{
          'config-decoration__button--active': currentDecorations.includes(
            TextStyle.STRIKE
          ),
        }"
        @click="handleDecorationClick(TextStyle.STRIKE)"
      >
        <CommonIcon name="ant-design:strikethrough-outlined" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { TextStyle } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue?: string[];
  label?: string;
  meta?: {
    isBold?: boolean;
  };
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: string[]): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentDecorations = computed<string[]>(() => {
  return props.modelValue || [];
});

const handleDecorationClick = (value: string): void => {
  let decorations = [...currentDecorations.value];

  if (decorations.includes(value)) {
    decorations = decorations.filter(item => item !== value);
  } else {
    decorations.push(value);
  }

  /* 
    Update control value only if there is no custom 
    decorations inside meta
  */
  emit("update:modelValue", decorations);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-decoration {
  @include controls-menu(25%);
}
</style>
