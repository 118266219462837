<!-- 
  TODO emit update after input blur only  
 -->
<template>
  <div
    v-if="hasDesignPermissions"
    class="m-t-16 p-b-16 p-l-16 p-r-16 config-padding-inputs widget-control-container"
  >
    <p
      class="m-b-4 config-padding-inputs__title"
      :class="{
        'sub-heading': meta.isBold,
      }"
    >
      {{ label }}
    </p>

    <div class="config-padding-inputs__container">
      <a-tooltip
        v-for="paddingProperty in currentPaddingList"
        :key="paddingProperty.value"
        placement="bottomRight"
      >
        <template #title>
          <span>{{ paddingProperty.label }}</span>
        </template>

        <ConfigInput
          v-number
          :model-value="modelValue[paddingProperty.value as keyof ISpacingInput]"
          class="config-padding-inputs__option"
          :is-number="true"
          :input-props="{
            min: 0,
            max: MAX_NUMBER_INPUT_VALUE,
            controls: false,
          }"
          @update:model-value="handleUpdate(paddingProperty.value, $event)"
          @mouseenter="
            handleMouseIn(`${SpacingKeyName.PADDING}-${paddingProperty.value}`)
          "
          @mouseleave="handleMouseOut"
          @blur="$emit('blur')"
        >
          <template #prefix
            ><CommonIcon
              :name="paddingProperty.icon"
              :class="paddingProperty.iconClassName"
          /></template>
          <template #suffix>
            <span> px </span>
          </template>
        </ConfigInput>
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import {
  SpacingInput,
  ISpacingInput,
  SpacingKeyName,
  ISpacingControl,
} from "~~/models/widgets/widget-controls.model";
import { useControlHover } from "~~/composables/widgets-config/useControlHover";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: ISpacingInput;
    showTooltip?: boolean;
    label?: string;
    meta?: {
      isBold: boolean;
    };
    isHorizontal?: boolean;
  }>(),
  {
    meta: () => ({
      isBold: false,
    }),
    label: "Padding",
    showTooltip: false,
    modelValue: (): ISpacingInput => ({
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }),
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: ISpacingInput): void;
  (e: "blur"): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const { handleMouseIn, handleMouseOut } = useControlHover();

const HORIZONTAL_PADDING_PROPERTIES: ISpacingControl[] = [
  {
    label: "Left padding",
    value: SpacingInput.LEFT,
    icon: "custom:space-left",
    iconClassName: "",
  },
  {
    label: "Right padding",
    value: SpacingInput.RIGHT,
    icon: "custom:space-left",
    iconClassName: "r-180",
  },
];
const PADDING_PROPERTIES: ISpacingControl[] = [
  {
    label: "Left padding",
    value: SpacingInput.LEFT,
    icon: "custom:space-left",
    iconClassName: "",
  },
  {
    label: "Top padding",
    value: SpacingInput.TOP,
    icon: "custom:margin-bottom",
    iconClassName: "r-180",
  },
  {
    label: "Right padding",
    value: SpacingInput.RIGHT,
    icon: "custom:space-left",
    iconClassName: "r-180",
  },
  {
    label: "Bottom padding",
    value: SpacingInput.BOTTOM,
    icon: "custom:margin-bottom",
    iconClassName: "",
  },
];

const currentPaddingList = computed<ISpacingControl[]>(() => {
  if (props.isHorizontal) {
    return HORIZONTAL_PADDING_PROPERTIES;
  }

  return PADDING_PROPERTIES;
});

const handleUpdate = (key: string, value: number | string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-padding-inputs {
  &__container {
    @include flex(flex-start, space-between, 8px, true);
  }

  &__option {
    margin-bottom: 10px;
    width: calc(50% - 4px);

    svg {
      height: 24px;
      width: 24px;
    }

    .ant-input {
      padding-right: 28px !important;
    }
  }

  &__title {
    &.sub-heading {
      margin-bottom: $space-m;
    }
  }
}
</style>
