import { State } from "~~/models/widgets/widget-controls.model";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";

const initialStateValue = () => {
  return {
    fill: getDefaultFillValue(),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    color: getDefaultFillValue("#000000"),
  };
};

export const TEXT_BUTTON_INITIAL = () => ({
  value: "Button",
  options: {
    _active: true,
    fill: getDefaultFillValue("#1677FF"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: 6,
    padding: getDefaultSpacing({
      top: 4,
      bottom: 4,
      left: 16,
      right: 16,
    }),
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    color: getDefaultFillValue("#ffffff"),
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    states: {
      [State.HOVER]: initialStateValue(),
      [State.ACTIVE]: initialStateValue(),
      [State.DISABLED]: initialStateValue(),
    },
  },
});
