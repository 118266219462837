import type { Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useButtonControls = (
  field: Ref<IWidgetField>,
  buttonState: Ref<State>,
  emit: any,
  withoutLink = false
) => {
  const buttonSettingsValuePath = computed<string>(() => {
    if (buttonState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${buttonState.value}`;
  });

  const buttonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: buttonState.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit(`update-button-state`, { state: field.value.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.fill`,
      valueSource: field.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${buttonSettingsValuePath.value}.border`,
      valueSource: field.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${buttonSettingsValuePath.value}.shadow`,
      valueSource: field.value,
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.buttonTextSettings.textColor`,
      valueSource: field.value,
      options: {
        label: "Text color",
        type: ColorPickerType.TEXT,
        isBold: true,
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.iconSettings.textColor`,
      valueSource: field.value,
      options: {
        label: "Icon color",
        type: ColorPickerType.TEXT,
        isBold: true,
      },
    },
  ]);

  const buttonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: field.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: field.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: field.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: field.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: field.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: field.value,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    field,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const buttonContentControls = computed(() => [
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: field.value,
      className: field.value.options.buttonTextSettings._active
        ? "group-control-element"
        : "",
      options: {
        placeholder: "Enter button text",
        label: "Text",
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        active: field.value.options.buttonTextSettings._active,
        showOptions: false,
        max: 50,
        "onUpdate:active": (value: boolean) => {
          field.value.options.buttonTextSettings._active = value;
        },
      },
    },
    {
      componentPath: "ConfigButtonTextSettings",
      valuePath: "options.buttonTextSettings",
      valueSource: field.value,
      options: {
        placeholder: "Enter text",
        label: "Text color",
      },
    },
    {
      componentPath: "ConfigIconSettings",
      valuePath: "options.iconSettings",
      valueSource: field.value,
      options: {
        isStatesTab: false,
        allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
          .value,
      },
    },
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: field.value,
      options: {
        showAlignmentOnFillOnly: true,
      },
    },
    ...(withoutLink
      ? []
      : [
          {
            componentPath: "ConfigLinkType",
            valuePath: "options.link",
            valueSource: field.value,
            options: {
              label: "Link",
              shouldShowStyleSettings: false,
            },
          },
        ]),
  ]);

  return {
    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
  };
};
