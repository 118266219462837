<template>
  <div
    class="widgets-tree-element__element"
    @mouseenter="handleHover"
    @mouseleave="handleLeave"
  >
    <span
      v-if="node.children && node.children.length"
      class="widgets-tree-element__expand-icon"
      @click="toggleNodeExpansion(node)"
    >
      <CommonIcon
        :name="
          expandedState.get(node.key)
            ? 'ant-design:caret-down-outlined'
            : 'ant-design:caret-right-outlined'
        "
      />
    </span>

    <div
      class="widgets-tree-element__element-content"
      :class="{
        'widgets-tree-element__element-content--selected':
          selectedNodeKey === node.key,
        'widgets-tree-element__element-content--hovered':
          hoveredNodeId === node.key,
      }"
      @click="handleNodeSelect(node)"
    >
      <div class="widgets-tree-element__element-title">
        <CommonIcon
          class="widgets-tree-element__element-title-icon"
          :name="node.iconName"
        />
        <span
          class="widgets-tree-element__element-title-text overflow-ellipsis"
          :class="[
            {
              'widgets-tree-element__element-title-text--not-last-child':
                node.showSuffixControls,
            },
          ]"
          :style="`--level:${node.level}`"
        >
          {{ node.title }}
        </span>
      </div>

      <div
        v-if="node.showSuffixControls"
        class="widgets-tree-element__element-suffix"
      >
        <CommonIcon
          :name="
            node.visible
              ? 'ant-design:eye-outlined'
              : 'ant-design:eye-invisible-outlined'
          "
          @click.stop="handleNodeVisibleChange(node)"
        />
        <CommonIcon
          name="ant-design:more-outlined"
          class="widgets-tree-element__more-icon"
          @click.stop="handleMoreClick(node)"
        />
      </div>
    </div>
  </div>

  <WidgetTreeContextMenu
    v-if="isContextMenuOpened && selectedNodeKey === node.key"
    v-model:is-context-menu-opened="isContextMenuOpened"
    :node="node"
    :selected-node-key="selectedNodeKey"
  />
</template>

<script lang="ts" setup>
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLanguagesStore } from "~~/store/languages";

import { ITreeNode } from "./WidgetsTreeTab.vue";

const { node, expandedState, selectedNodeId } = defineProps<{
  node: ITreeNode;
  expandedState: Map<string, boolean>;
  selectedNodeId: string;
}>();

const { currentLanguageCode } = storeToRefs(useLanguagesStore());

const { hoveredField, hoveredWidget } = storeToRefs(useWidgetSettingsStore());

const isContextMenuOpened = ref(false);
const selectedNodeKey = ref<string>("");

const handleHover = () => {
  if (node.isLastChild && node.parentWidgetData) {
    const field = node.parentWidgetData.fields.find(
      field => String(field.id) === node.key
    );
    useWidgetSettingsStore().setHoveredField(field || null);
  } else if (node.widgetData) {
    useWidgetSettingsStore().setHoveredWidget(node.widgetData);
  }
};

const handleLeave = () => {
  if (node.isLastChild) {
    useWidgetSettingsStore().setHoveredField(null);
  } else if (node.widgetData) {
    useWidgetSettingsStore().setHoveredWidget(null);
  }
};

const hoveredNodeId = computed(() =>
  String(hoveredField.value?.id || hoveredWidget.value?.id || "")
);

const toggleNodeExpansion = (node: ITreeNode) => {
  const currentExpanded = expandedState?.get(node.key) || false;
  expandedState.set(node.key, !currentExpanded);

  if (node.level === 0 && currentExpanded) {
    expandedState.clear();
  }
};

const handleNodeSelect = (node: ITreeNode) => {
  const { widgetData, key, parentWidgetData } = node;

  if (widgetData) {
    return useWidgetSettingsStore().selectWidget(widgetData);
  }

  if (!parentWidgetData || !key) {
    return;
  }

  const selectedField = parentWidgetData.fields?.find(
    field => String(field.id) === key
  );

  useWidgetSettingsStore().setActiveElement(parentWidgetData, selectedField);
};

const handleNodeVisibleChange = (node: ITreeNode) => {
  node.visible = !node.visible;

  if (node.widgetData) {
    node.widgetData.options._isVisible[currentLanguageCode.value] =
      !node.widgetData.options._isVisible[currentLanguageCode.value];
  }
};

const handleMoreClick = (node: ITreeNode) => {
  handleNodeSelect(node);

  isContextMenuOpened.value = !isContextMenuOpened.value;
};

watch(
  () => selectedNodeId,
  newValue => {
    selectedNodeKey.value = newValue;
  }
);

onMounted(() => {
  if (selectedNodeId) {
    selectedNodeKey.value = selectedNodeId;
  }
});
</script>

<style scoped lang="scss">
.widgets-tree-element {
  &__element-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2px 6px;
    margin-right: 6px;
    border-radius: 4px;
    gap: 4px;

    &--hovered,
    &:hover {
      background-color: #0000000a;
    }

    &--selected {
      background-color: #e6f4ff !important;
      color: #1677ff;
    }
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
  }

  &__element-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__element-title-text {
    width: 100%;
    user-select: none;
    // TODO: remake this to normal css, this is hotfix
    max-width: calc(130px - (var(--level) * 10px));
  }

  &__element-suffix {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__more-icon {
    transform: rotate(90deg);
  }
}
</style>
