<template>
  <div
    v-if="hasDesignPermissions"
    class=""
    :class="[
      'widget-control-container',
      'p-t-16 p-r-16 p-b-16 p-l-16',
      'config-alignment-advanced',
      { 'config-alignment-advanced_flex': isFlex },
      { 'config-alignment-advanced_grid': !isFlex },
      { 'config-alignment-advanced_horizontal': isDirectionHorizontal },
      { 'config-alignment-advanced_vertical': !isDirectionHorizontal },
    ]"
  >
    <p
      v-if="label"
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label }}
    </p>
    <div class="wconfig-common-radio__radio-group-container">
      <div class="config-alignment-advanced__list">
        <div
          v-if="modelValue.justifyContent"
          class="config-alignment-advanced__list-item"
        >
          <span class="config-alignment-advanced__list-item-label">{{
            !isFlex ? "Justify content" : isDirectionHorizontal ? "X" : "Y"
          }}</span>
          <CommonSelect
            v-model="modelValue.justifyContent"
            :options="items.justifyContent"
            placeholder="Select"
            class="config-alignment-advanced__list-item-select"
          />
        </div>
        <div
          v-if="modelValue.alignContent"
          class="config-alignment-advanced__list-item"
        >
          <span class="config-alignment-advanced__list-item-label">{{
            "Align content"
          }}</span>
          <CommonSelect
            v-model="modelValue.alignContent"
            :options="items.alignContent"
            placeholder="Select"
            class="config-alignment-advanced__list-item-select"
          />
        </div>
        <div
          v-if="modelValue.justifyItems"
          class="config-alignment-advanced__list-item"
        >
          <span class="config-alignment-advanced__list-item-label">{{
            "Justify items"
          }}</span>
          <CommonSelect
            v-model="modelValue.justifyItems"
            :options="items.justifyItems"
            placeholder="Select"
            class="config-alignment-advanced__list-item-select"
          />
        </div>
        <div
          v-if="modelValue.alignItems"
          class="config-alignment-advanced__list-item"
        >
          <span class="config-alignment-advanced__list-item-label">{{
            !isFlex ? "Align items" : isDirectionHorizontal ? "Y" : "X"
          }}</span>
          <CommonSelect
            v-model="modelValue.alignItems"
            :options="items.alignItems"
            placeholder="Select"
            class="config-alignment-advanced__list-item-select"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  AlignContent,
  AlignItems,
  Display,
  DisplayOrientation,
  FlexDirection,
  JustifyContent,
  JustifyItems,
  LayoutAlignment,
} from "~~/models/widgets/widget-controls.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { flexDirectionToDisplayOrientation } from "~~/constants/dictionaries";

const props = withDefaults(
  defineProps<{
    display?: Display.FLEX | Display.GRID;
    direction: FlexDirection;
    // eslint-disable-next-line vue/require-default-prop
    label?: string;
    isBold?: boolean;
  }>(),
  {
    display: Display.FLEX,
    direction: FlexDirection.ROW,
  }
);

const modelValue = defineModel<LayoutAlignment>({
  default: {
    justifyContent: null,
    alignContent: null,
    justifyItems: null,
    alignItems: null,
  },
});

const isFlex = computed(() => {
  return props.display === Display.FLEX;
});

const isDirectionHorizontal = computed(() => {
  return (
    flexDirectionToDisplayOrientation[props.direction] ===
    DisplayOrientation.HORIZONTAL
  );
});

const items = computed(() => {
  return {
    justifyContent: [
      {
        label: "Normal",
        value: JustifyContent.NORMAL,
      },
      {
        label: "Start",
        value: isFlex.value ? JustifyContent.FLEX_START : JustifyContent.START,
      },
      {
        label: "End",
        value: isFlex.value ? JustifyContent.FLEX_END : JustifyContent.END,
      },
      {
        label: "Center",
        value: JustifyContent.CENTER,
      },
      {
        label: "Stretch",
        value: JustifyContent.STRETCH,
      },
      {
        label: "Space between",
        value: JustifyContent.SPACE_BETWEEN,
      },
      {
        label: "Space around",
        value: JustifyContent.SPACE_AROUND,
      },
    ],
    alignItems: [
      {
        label: "Normal",
        value: AlignItems.NORMAL,
      },
      {
        label: "Start",
        value: isFlex.value ? AlignItems.FLEX_START : AlignItems.START,
      },
      {
        label: "End",
        value: isFlex.value ? AlignItems.FLEX_END : AlignItems.END,
      },
      {
        label: "Center",
        value: AlignItems.CENTER,
      },
      {
        label: "Stretch",
        value: AlignItems.STRETCH,
      },
      {
        label: "Baseline",
        value: AlignItems.BASELINE,
      },
    ],
    alignContent: [
      {
        label: "Normal",
        value: AlignContent.NORMAL,
      },
      {
        label: "Start",
        value: isFlex.value ? AlignContent.FLEX_START : AlignContent.START,
      },
      {
        label: "End",
        value: isFlex.value ? AlignContent.FLEX_END : AlignContent.END,
      },
      {
        label: "Center",
        value: AlignContent.CENTER,
      },
      {
        label: "Stretch",
        value: AlignContent.STRETCH,
      },
      {
        label: "Space between",
        value: AlignContent.SPACE_BETWEEN,
      },
      {
        label: "Space around",
        value: AlignContent.SPACE_AROUND,
      },
    ],
    justifyItems: [
      {
        label: "Normal",
        value: JustifyItems.NORMAL,
      },
      {
        label: "Start",
        value: isFlex.value ? JustifyItems.FLEX_START : JustifyItems.START,
      },
      {
        label: "End",
        value: isFlex.value ? JustifyItems.FLEX_END : JustifyItems.END,
      },
      {
        label: "Center",
        value: JustifyItems.CENTER,
      },
      {
        label: "Stretch",
        value: JustifyItems.STRETCH,
      },
      {
        label: "Baseline",
        value: JustifyItems.BASELINE,
      },
    ],
  };
});

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);
</script>

<style lang="scss">
.config-alignment-advanced {
  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: -8px;

    &-item {
      margin-bottom: 8px;

      &-label {
        display: block;
      }

      &-select {
        flex: 1;
      }
    }
  }

  &_flex {
    .config-alignment-advanced {
      &__list {
        &-item {
          display: flex;
          align-items: center;

          &-label {
            margin-right: 8px;
          }
        }
      }
    }
  }

  &_vertical {
    .config-alignment-advanced {
      &__list {
        flex-direction: column-reverse;
      }
    }
  }

  &_grid {
    .config-alignment-advanced {
      &__list {
        &-item {
          &-label {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
</style>
