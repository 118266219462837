<template>
  <WidgetField
    :widget="widget"
    class="sport-event-fenix-widget-prematch-banner"
    :style="prematchBannerStyles"
    :field="fields.prematch_banner.field"
  >
    <WidgetField
      :widget="widget"
      :field="fields.prematch_banner_event_title.field"
      :style="eventTitleStyles"
    >
      Event title
    </WidgetField>

    <WidgetField
      :widget="widget"
      :style="eventTimeStyles"
      :field="fields.prematch_banner_event_time.field"
    >
      Event time
    </WidgetField>
  </WidgetField>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getSpacing,
  getTextShadowStyle,
} from "~~/assets/utils/widget-settings";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  fields: IFields;
}>();

const prematchBannerStyles = computed<CSSProperties>(() => {
  const options = props.fields.prematch_banner.field.options;

  return {
    ...getCommonStyles(options),
    gap: getPxValueFromNumber(options.distance),
  };
});

const eventTitleStyles = computed<CSSProperties>(() => {
  const options = props.fields.prematch_banner_event_title.field.options;

  return {
    width: "100%",
    textAlign: options.alignment,
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});

const eventTimeStyles = computed<CSSProperties>(() => {
  const options = props.fields.prematch_banner_event_time.field.options;

  return {
    width: "100%",
    textAlign: options.alignment,
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});
</script>

<style lang="scss">
.sport-event-fenix-widget-prematch-banner {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
