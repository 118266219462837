import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { IInsertPosition } from "~~/models/grid.interface";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import {
  MAX_GAP_INPUT_VALUE,
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_GAP_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";

import { useButtonControls } from "../../card/useButtonControls";
import {
  useAllowDisableDependentFieldContentItems,
  useAllowDisableDependentTabs,
} from "../../useAllowDisableDependentFieldContentItems";
import { useItemControls } from "../../dropdown/useItemControls";
import { useConfigArrayItemsControl } from "../../items/useConfigArrayItemsControl";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Login button", "login", "auth"),
    generateDropdownItem("Sign up button", "registration", "auth"),

    generateDropdownItem("Deposit", "deposit"),
    generateDropdownItem("Account", "account"),
    generateDropdownItem("Dropdown container", "dropdown_container"),
    generateDropdownItem("Dropdown menu", "dropdown_menu"),
    generateDropdownItem("Account info", "account_info"),

    generateDropdownItem("User ID", "user_id"),
    generateDropdownItem("Username ", "username", "userId"),
    generateDropdownItem("Account number", "account_number", "userId"),

    generateDropdownItem("Balance", "balance"),
    generateDropdownItem("Items", "balanceItems", "balanceItems"),
    generateDropdownItem("Item", "real", "balanceItems"),
    generateDropdownItem("Item", "bonus", "balanceItems"),
    generateDropdownItem("Account menu", "account_menu"),
    generateDropdownItem("Menu items", "itemsGroup"),
    generateDropdownItem("Item", "item", "item"),
    generateDropdownItem("Logout", "logout"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    login: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    registration: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    deposit: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    logout: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    account: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    dropdown_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    account_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    user_id: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    balance: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    account_menu: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    let sharedTabs = null;

    if (states.value.status === State.AUTHORIZED) {
      sharedTabs = [
        {
          label: "Deposit button",
          value: "deposit",
          isActiveValueSource: fields.value.deposit.field.options,
        },
        { label: "Account", value: "account" },
      ];
    } else {
      sharedTabs = [
        { label: "Login button", value: "login" },
        { label: "Sign up button", value: "registration" },
      ];
    }

    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.status,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.authorized,
              value: State.AUTHORIZED,
            },
            {
              label: STATE_KEY_LABELS.unauthorized,
              value: State.UNAUTHORIZED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "status", value });
        },
      },
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: widget.value,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "border-bottom-none p-b-0",
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: widget.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: sharedTabs,
        },
      },
    ];
  });

  /**
   * Login button
   */
  const {
    buttonDesignControls: loginButtonDesignControls,
    buttonContentControls: loginButtonContentControls,
    buttonStatesControls: loginButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.login),
      stateHandler: (state: State) => {
        emit("update-state", { state: "login", value: state });
      },
      valueSource: toRef(fields.value.login.field),
    },
    emit
  );

  /**
   * Sign up button
   */
  const {
    buttonDesignControls: signupButtonDesignControls,
    buttonContentControls: signupButtonContentControls,
    buttonStatesControls: signupButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.registration),
      stateHandler: (state: State) => {
        emit("update-state", { state: "registration", value: state });
      },
      valueSource: toRef(fields.value.registration.field),
    },
    emit
  );

  /**
   * Deposit button
   */
  const {
    buttonDesignControls: depositButtonDesignControls,
    buttonContentControls: depositButtonContentControls,
    buttonStatesControls: depositButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.deposit),
      stateHandler: (state: State) => {
        emit("update-state", { state: "deposit", value: state });
      },
      valueSource: toRef(fields.value.deposit.field),
      exclude: {
        content: ["ConfigLinkType"],
      },
    },
    emit
  );

  const {
    buttonDesignControls: logoutButtonDesignControls,
    buttonContentControls: logoutButtonContentControls,
    buttonStatesControls: logoutButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.logout),
      stateHandler: (state: State) => {
        emit("update-state", { state: "logout", value: state });
      },
      valueSource: toRef(fields.value.logout.field),
      exclude: {
        content: ["ConfigLinkType"],
      },
    },
    emit
  );

  /**
   * Account
   */
  const accountDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const accountContentControls = computed<ControlProp[]>(() => {
    let sharedTabs = null;

    if (selectedField.value?.options.display?.mode === "dropdown") {
      sharedTabs = [
        { label: "Dropdown container", value: "dropdown_container" },
        { label: "Dropdown menu", value: "dropdown_menu" },
        { label: "Account info", value: "account_info" },
      ];
    } else {
      sharedTabs = [{ label: "Account info", value: "account_info" }];
    }

    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.display.mode",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            {
              label: "Dropdown",
              value: "dropdown",
            },
            {
              label: "List",
              value: "list",
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: sharedTabs,
        },
      },
    ];
  });

  /**
   * Dropdown container
   */
  const dropdownContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.ARROW, FieldContentItem.ICON, FieldContentItem.TEXT]
  );

  const dropdownContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.display.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Resizing",
              type: ColorPickerType.TEXT,
              exclude: [ResizingType.FILL],
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            visible:
              selectedField.value?.options.display?.resizing?.type ===
              ResizingType.FIXED,
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.text,
        allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        controls: typography({
          source: selectedField.value?.options.text,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        }).controls,
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrow",
        valueSource: selectedField.value,
        options: {
          allowDisable: allowDisableFieldsItem(FieldContentItem.ARROW).value,
        },
      },
    ];
  });

  const dropdownContainerStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.text.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.icon.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.arrow.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /**
   * Dropdown menu
   */
  const dropdownMenuControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  /**
   * Account info
   */
  const accountInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const { allowDisableTabItem } = useAllowDisableDependentTabs([
    fields.value.user_id.field,
    fields.value.balance.field,
    fields.value.account_menu.field,
    fields.value.logout.field,
  ]);

  const accountInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.display.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Resizing",
              type: ColorPickerType.TEXT,
              exclude: [ResizingType.FILL],
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              allowDisable: allowDisableTabItem(fields.value.user_id.field)
                .value,
              label: "User ID",
              value: "user_id",
              isActiveValueSource: fields.value.user_id.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.balance.field)
                .value,
              label: "Balance",
              value: "balance",
              isActiveValueSource: fields.value.balance.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.account_menu.field)
                .value,
              label: "Account menu",
              value: "account_menu",
              isActiveValueSource: fields.value.account_menu.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.logout.field)
                .value,
              label: "Logout button",
              value: "logout",
              isActiveValueSource: fields.value.logout.field.options,
            },
          ],
        },
      },
    ];
  });

  /**
   * Account menu
   */
  const accountMenuDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const accountMenuContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      useConfigArrayItemsControl("Menu items", false, FieldName.ACCOUNT_MENU),
    ];
  });

  /**
   * User id
   */
  const userIdDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const userIdContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Username", value: "username" },
            { label: "Account number", value: "account_number" },
          ],
        },
      },
    ];
  });

  /**
   * Username
   */
  const usernameControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.username.field!,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Account number
   */
  const accountNumberControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.account_number.field!,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.distance",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Spacing between text and icon",
              minValue: MIN_GAP_INPUT_VALUE,
              maxValue: MAX_GAP_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  /**
   * Balance
   */
  const balanceDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const balanceFields = computed<IWidgetField[]>(() =>
    [fields.value.real.field, fields.value.bonus.field].sort(
      (a, b) => a.options._position - b.options._position
    )
  );

  const balanceContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.displayItems.alignment",
            valueSource: fields.value.balance.field,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigArrayItems",
        options: {
          items: balanceFields.value,
          hideCRUD: true,
          showVisibility: true,
          label: "Items",
          optionsClickHandler: () => {
            widgetSettingsStore.setActiveElement(
              widgetSettingsStore.selectedWidget,
              fields.value.balance.field,
              widgetSettingsStore.selectedCell || undefined,
              "balanceItems"
            );
          },
          reorderHandler: (
            targetId: number | string,
            insertPosition: IInsertPosition
          ): void => {
            const [currFields] = reorderListElements(
              "id",
              targetId,
              insertPosition,
              balanceFields.value
            );

            currFields.forEach((field, index) => {
              field.options._position = index;
            });
          },
        },
      },
    ];
  });

  const { itemControls } = useItemControls();

  const { allowDisableFieldsItem: allowDisableFieldsBalanceItem } =
    useAllowDisableDependentFieldContentItems(selectedField, [
      FieldContentItem.TEXT,
      FieldContentItem.ICON,
    ]);

  const balanceItemControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItemText",
        valuePath: `options.text`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
          allowDisable: allowDisableFieldsBalanceItem(FieldContentItem.TEXT)
            .value,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.icon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          hidePicker: false,
          allowDisable: allowDisableFieldsBalanceItem(FieldContentItem.ICON)
            .value,
        },
      },
    ];
  });

  /**
   * Balance items
   */
  const balanceItemsControls = computed(() => [
    {
      section: generateContentSectionName("Display settings"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.displayItems.layout",
          valueSource: fields.value.balance.field,
          className: "group-control-element",
          options: {
            label: "Layout",
            noTextWrap: true,
            items: [
              {
                label: "Horizontal",
                value: DisplayOrientation.HORIZONTAL,
              },
              {
                label: "Vertical",
                value: DisplayOrientation.VERTICAL,
              },
            ],
          },
        },
        {
          componentPath: "ConfigAlignment",
          valuePath: "options.displayItems.alignment",
          valueSource: fields.value.balance.field,
          className: "border-bottom-none p-b-0",
          options: {
            isTextContentIcons: false,
          },
        },
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.displayItems.distance",
          valueSource: fields.value.balance.field,
          options: {
            label: "Distance between items",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "ConfigFontFamilySelect",
      valuePath: "options.fontFamily",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "Select",
        label: "Font family",
        isBold: true,
      },
    },
    {
      section: generateDesignSectionName("Label text style"),
      controls: [
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.text.theme",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },

        {
          componentPath: "ConfigDecoration",
          valuePath: "options.text.decoration",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.text.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Label text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Value text style"),
      controls: [
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.primaryText.theme",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },

        {
          componentPath: "ConfigDecoration",
          valuePath: "options.primaryText.decoration",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.primaryText.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Value text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "options.icon.size",
          className: "p-l-16 p-r-16 p-t-16",
          valueSource: fields.value.balance.field,
          options: {
            label: "Size",
            minValue: MIN_ICON_SIZE_INPUT_VALUE,
            maxValue: MAX_ICON_SIZE_INPUT_VALUE,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.icon.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /**
   * Account items (itemsGroup)
   */

  const itemsGroupDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const itemsGroupContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.elementsLayout",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Item elements layout",
              noTextWrap: true,
              items: [
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between item elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigArrayItemsText",
        valuePath: `options.itemsText`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.itemsIcon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
        },
      },
    ];
  });

  const itemsGroupStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value.itemsGroup,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "itemsGroup", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: `${selectedElementPath.value}.itemsText`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `${selectedElementPath.value}.itemsIcon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          isStatesTab: true,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    loginButtonDesignControls,
    loginButtonContentControls,
    loginButtonStatesControls,

    signupButtonDesignControls,
    signupButtonContentControls,
    signupButtonStatesControls,

    depositButtonDesignControls,
    depositButtonContentControls,
    depositButtonStatesControls,

    logoutButtonDesignControls,
    logoutButtonContentControls,
    logoutButtonStatesControls,

    accountDesignControls,
    accountContentControls,

    dropdownContainerDesignControls,
    dropdownContainerContentControls,
    dropdownContainerStatesControls,

    dropdownMenuControls,

    accountInfoDesignControls,
    accountInfoContentControls,

    userIdDesignControls,
    userIdContentControls,

    usernameControls,
    accountNumberControls,

    balanceDesignControls,
    balanceContentControls,

    itemControls,
    balanceItemControls,

    balanceItemsControls,

    accountMenuDesignControls,
    accountMenuContentControls,

    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  };
};
