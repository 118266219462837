import {
  Alignment,
  FillType,
  SliderArrowIcon,
  SliderItemsPosition,
  SliderTransition,
  State,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import {
  WIDGET_INITIAL as defaultWidgetInitial,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";

import { getDefaultSize } from "../common/widget-initial";

export const WIDGET_INITIAL = () => {
  return {
    options: {
      ...defaultWidgetInitial().options,
      baseDisplaySettings: {
        alignment: VerticalAlignment.TOP,
      },
      size: getDefaultSize(),
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      margins: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      columns: 1,
      transition: SliderTransition.ALL,
      spaceBetween: 24,
      slidePadding: {
        top: 8,
        bottom: 8,
        left: 8,
        right: 8,
      },
      sliderTransition: {
        isAuto: true,
        delay: 7,
        isInfinite: true,
      },
      sliderPagination: {
        isActive: true,
        isStroke: false,
        color: {
          color: "",
          opacity: 100,
        },
        alignment: Alignment.CENTER,
        position: SliderItemsPosition.INSIDE,
      },
      sliderArrows: {
        isActive: true,
        icon: SliderArrowIcon.OUTLINED,
        color: {
          color: "",
          opacity: 100,
        },
        position: "inside",
      },
    },
  };
};

export const SLIDE_INITIAL = () => ({
  options: {
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    textPosition: {
      position: "top",
      scale: 50,
      alignment: VerticalAlignment.TOP,
    },
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    states: {
      [State.HOVER]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: {
            color: "",
            opacity: 100,
          },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: null,
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
      },
      [State.ACTIVE]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: {
            color: "",
            opacity: 100,
          },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: null,
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
      },
    },
  },
});

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    theme: 24,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: "left",
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
      states: {
        [State.HOVER]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
        [State.ACTIVE]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
      },
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    margins: {
      top: 0,
      bottom: 0,
    },
  },
});
