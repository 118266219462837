import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateGap,
  generateFlexDirection,
  generateWrap,
  generateAlignItems,
  generateFlex,
  generateJustifyContent,
  generateBorderStyle,
  generateCornerRadiusStyle,
  generateShadowStyle,
  generateFillColorStyle,
  generateMarginStyle,
  generatePaddingStyle,
  generateFontSize,
  generateTextColor,
  generateSpacingStyle,
} from "../helpers";
import { getPxValueFromNumber } from "../..";
import { getColorFromHex } from "../../widget-settings";

export const generateTabsStyles =
  (fieldDetails: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldDetails;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "flex-start",
          justify: options.buttonDisplaySettings.alignment,
          gap: options.distance,
        }) + generateMarginStyle(options.spacing.margin),
    });

    const flex =
      options.buttonDisplaySettings.resizing === ResizingType.HUG
        ? ""
        : "flex: 1;";

    cssString += generateDefaultStylesWithStates(
      {
        options: {
          ...options,
          _cssClass: `${options._cssClass} button`,
          spacing: {
            ...options.spacing,
            margin: null,
          },
        },
      },
      false
    );

    cssString += generateCssClassWithContent({
      className: `${options._cssClass} button[data-active='true']`,
      content: {
        ...options.states.active,
        spacing: {
          ...options.spacing,
          margin: null,
        },
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: flex + "width: 100%;",
    });

    if (options.buttonDisplaySettings.resizing === ResizingType.HUG) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: "base-tabs__trigger",
        content: "width: initial;",
      });
    }

    return cssString;
  };

export const generateFilterStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const wrap =
      filterOptions.displayMode === DisplayOrientation.HORIZONTAL
        ? "nowrap"
        : "wrap";

    const direction =
      filterOptions.displayMode === 1 || filterOptions.displayMode === 3
        ? "column"
        : "row";

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      content:
        generateGap(filterOptions.distance) +
        generateWrap(wrap) +
        generateAlignItems("flex-start") +
        generateFlexDirection(direction) +
        generateJustifyContent("flex-start"),
    });

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      pseudoClassName: " li",
      content: "cursor: pointer",
    });

    return cssString;
  };

export const generateFilterItemsStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:not(:nth-child(n+3)) {flex:1;width:initial;}`;

    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:nth-child(n+3) {flex:2;width:initial;}`;

    if (filterOptions.displayMode === DisplayOrientation.VERTICAL) {
      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:not(:nth-child(n+3)) {flex:1;width:calc(50% - (${filterOptions.distance}px / 2));}`;

      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:nth-child(n+3) {flex:2 1 100%;width:initial;}`;
    }

    return cssString;
  };

export const generateTableStyles =
  (fieldOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldOptions;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generateSpacingStyle(options.spacing),
    });

    const isTableMode = options.mode === "table";

    let tableStyles = "";

    if (isTableMode) {
      tableStyles +=
        generateBorderStyle(options.border) +
        generateFillColorStyle(options.fill) +
        generateShadowStyle(options.shadow) +
        generateCornerRadiusStyle(options.cornerRadius) +
        "border-collapse:separate;border-spacing: 0;";
    } else {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: " tr",
        content:
          generateBorderStyle(options.border) +
          generateShadowStyle(options.shadow) +
          generateCornerRadiusStyle(options.cornerRadius) +
          generateFillColorStyle(options.fill),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: tableStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: ` tr:last-child:not(.${options._noContentRowCssClass})`,
      content: "border-bottom: none;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " th:first-child:before",
      content: "display: none;",
    });

    return cssString;
  };

const getBorderDefaultStyle = (tableOptions: IWidgetOptions): string => {
  const border = tableOptions.options.border;

  const borderStyle = `${getPxValueFromNumber(border.stroke?.width)} ${
    border.style
  } ${getColorFromHex(border.fill)};`;

  return borderStyle;
};

const getBorderStyle = (
  tableOptions: IWidgetOptions,
  isTd?: boolean
): string => {
  const borderStyle = getBorderDefaultStyle(tableOptions);

  const baseStyle = `border-bottom: ${borderStyle};`;

  if (tableOptions.options.borderType === "between_rows") {
    return baseStyle;
  }

  return baseStyle + (isTd ? `border-inline-end: ${borderStyle}` : "");
};

export const generateTableHeaderStyles =
  (fieldOptions: IWidgetOptions, tableOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldOptions;

    const horizontalSpace = tableOptions.options.horizontalSize;
    const verticalSpace = tableOptions.options.verticalSize;

    const borderStyle = getBorderStyle(tableOptions);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " th",
      content:
        borderStyle +
        generatePaddingStyle({
          left: horizontalSpace,
          right: horizontalSpace,
          top: verticalSpace,
          bottom: verticalSpace,
        }) +
        generateDefaultStyles(options) +
        "position: relative;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " th:before",
      content:
        "content: '';" +
        "display: block;" +
        "position: absolute;" +
        "left: 0;" +
        "top: 50%;" +
        "transform: translateY(-50%);" +
        "width: 1px;" +
        "height: 22px;" +
        `border-left: ${getPxValueFromNumber(options.separator.width)} ${
          options.separator.style
        } ${getColorFromHex(options.separator.fill)};`,
    });

    if (tableOptions.options.mode === "cards") {
      const isBorderedMode = tableOptions.options.borderType === "bordered";

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content:
          (isBorderedMode ? borderStyle : "") +
          generatePaddingStyle({
            left: horizontalSpace,
            right: horizontalSpace,
            top: verticalSpace,
            bottom: verticalSpace,
          }) +
          generateDefaultStyles(options) +
          "display: block;" +
          "position: relative;",
      });
    }
    return cssString;
  };

export const generateTableRowStyles =
  (fieldOptions: IWidgetOptions, tableOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldOptions;

    const horizontalSpace = tableOptions.options.horizontalSize;
    const verticalSpace = tableOptions.options.verticalSize;

    const borderStyle = getBorderStyle(tableOptions, true);

    let rowStyles =
      borderStyle + generateDefaultStyles(options) + "position: relative;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName:
        ":last-child:not(:first-child) .base-table__cell:last-child .base-table__value",
      content: "border-bottom: none !important;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: ":last-child:not(:first-child) .base-table__cell",
      content: "border-bottom: none !important;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: "base-table__cell:last-child",
      content: "border-right: none !important;",
    });

    const isTableMode = tableOptions.options.mode === "table";

    if (isTableMode) {
      rowStyles +=
        generatePaddingStyle({
          left: horizontalSpace,
          right: horizontalSpace,
          top: verticalSpace,
          bottom: verticalSpace,
        }) + getBorderStyle(tableOptions, true);
    } else {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: " td:last-child .base-table__value",
        content: `border-bottom: none;`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: " .base-table__value",
        content:
          generatePaddingStyle({
            left: horizontalSpace,
            right: horizontalSpace,
            top: verticalSpace,
            bottom: verticalSpace,
          }) + "border-right: none !important;",
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: ":not(:last-child)",
        content: generateMarginStyle({
          top: 0,
          bottom: tableOptions.options.spacing.margin.bottom,
        }),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: isTableMode ? " td" : " .base-table__value",
      content: rowStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: isTableMode ? " td svg" : " .base-table__value svg",
      content: `${generateTextColor(options.icon.color)}; ${generateFontSize(
        options.icon.size
      )}`,
    });

    return cssString;
  };

export const generateCheckboxCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;
    const checkboxCheckedDefaultCssContent = [
      generateBorderStyle(options.border),
      generateFillColorStyle(options.fill),
    ].join("");

    const checkboxCheckedHoverCssContent = [
      generateBorderStyle(options.states["selected_hover"].border),
      generateFillColorStyle(options.states["selected_hover"].fill),
    ].join("");

    const checkboxUncheckedDefaultCssContent = [
      generateBorderStyle(options.states["unselected_default"].border),
      generateFillColorStyle(options.states["unselected_default"].fill),
    ].join("");

    const checkboxUncheckedHoverCssContent = [
      generateBorderStyle(options.states["unselected_hover"].border),
      generateFillColorStyle(options.states["unselected_hover"].fill),
    ].join("");

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      pseudoClassName: " button.base-checkbox",
      content: "width: 16px; height: 16px;border-radius:5px;",
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      childClassName: "base-checkbox:hover",
      content: checkboxUncheckedHoverCssContent,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}`,
      childClassName: "base-checkbox",
      content: checkboxUncheckedDefaultCssContent,
    });

    cssString += `.${
      options._cssClass
    } button.base-checkbox[data-state='checked'] {${checkboxCheckedDefaultCssContent}}.${
      options._cssClass
    }  button.base-checkbox[data-state='checked'] .base-checkbox__icon{border-color: ${getColorFromHex(
      options.checkIcon?.color
    )};}`;

    cssString += `.${
      options._cssClass
    } button.base-checkbox[data-state='checked']:hover{${checkboxCheckedHoverCssContent}}.${
      options._cssClass
    }  button.base-checkbox[data-state='checked']:hover .base-checkbox__icon{border-color: ${getColorFromHex(
      options.states["selected_hover"].checkIcon?.color
    )};}`;

    return cssString;
  };

export const generateEmptyTextStyles =
  (fieldOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldOptions;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateTransactionHistoryCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const tabs = widget.content["tabs_container"];
  const description = widget.content["pending_description"];
  const form = widget.content["form"];
  const table = widget.content["table"];
  const table_header_settings = widget.content["table_header_settings"];
  const table_row_settings = widget.content["table_row_settings"];
  const table_checkboxes_settings = widget.content["table_checkboxes_settings"];
  const no_events_title = widget.content["no_events_title"];

  const button = widget.content["action_button"];

  const customFields = widget.options._customFields;

  widget.options._cssClass = generateClassName("TransactionHistory");
  title.options._cssClass = generateClassName("title");

  tabs.options._cssClass = generateClassName("tabs_container");
  tabs.options._itemCssClass = generateClassName("tabs_item");

  table.options._cssClass = generateClassName("table");
  table.options._noContentRowCssClass = generateClassName("no_content_row");
  table.options._containerCssClass = generateClassName("table_container");
  table_header_settings.options._cssClass = generateClassName(
    "table_header_settings"
  );
  table_row_settings.options._cssClass =
    generateClassName("table_row_settings");
  table_checkboxes_settings.options._cssClass = generateClassName(
    "table_checkboxes_settings"
  );
  no_events_title.options._cssClass = generateClassName("no_events_title");

  /* 
    Form
  */
  form.options._cssClass = generateClassName("filter");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  // Dropdown class names
  customFields.dropdowns_styling.options._cssClass =
    generateClassName("dropdown");
  customFields.dropdown_menu_styling.options._cssClass =
    generateClassName("dropdownMenu");
  customFields.dropdown_menu_items_styling.options._cssClass =
    generateClassName("dropdownItems");

  // Calendar class names
  customFields.calendar_styling.options._wrapperCssClass =
    generateClassName("calendarWrapper");
  customFields.calendar_styling.options._dayCellCssClass =
    generateClassName("dayCell");

  customFields.calendar_styling.options._navCssClass =
    generateClassName("calendarNav");

  customFields.datepicker_styling.options._datepickerCssClass =
    generateClassName("datepicker");
  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  if (customFields.calendar_styling) {
    // Calendar class names
    customFields.calendar_styling.options._wrapperCssClass =
      generateClassName("calendarWrapper");
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    customFields.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    customFields.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");

    customFields.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  description.options._cssClass = generateClassName("description");
  button.options._cssClass = generateClassName("button");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateTabsStyles(tabs),
    generateStringWithStates(title, true),
    generateStringWithStates(button, false),
    generateTableStyles(table),
    generateTableHeaderStyles(table_header_settings, table),
    generateTableRowStyles(table_row_settings, table),
    generateStringDefault(description),
    generateEmptyTextStyles(no_events_title),
    generateCheckboxCssString(table_checkboxes_settings),

    /* 
      Form
    */
    generateFormCssString(form, widget),
    generateFilterStyles(form.options),
    generateFilterItemsStyles(form.options),
    generateTooltipStyling(customFields.tooltips_styling),
    generateIconCssString(customFields.tooltips_styling.options.textChooseIcon),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
