import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";

export const generateVirtualsWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("VirtualsWidget");

  return pipeSync<string>(generateStringDefault(widget))("");
};
