<template>
  <div
    :style="containerStyles"
    class="sportsbook-live__cards-container"
  >
    <div
      v-if="eventCardsState === State.NO_ACTIVE"
      :style="cardStyles"
    >
      <slot name="empty"></slot>
    </div>

    <template v-else>
      <WidgetField
        v-for="(card, index) in cards"
        :key="index"
        :widget="widget"
        :field="containerField ? field : null"
        :style="cardStyles"
        class="sportsbook-live__card"
      >
        <div class="sportsbook-live__header">
          <slot name="stream"></slot>
          <slot name="event_name"></slot>
          <slot name="suspended"></slot>

          <slot name="time"></slot>

          <slot name="header_append"></slot>
        </div>

        <slot name="league"></slot>
        <slot name="event_info"></slot>

        <slot name="market_name"></slot>
        <slot
          name="bet_items"
          :items="card.items"
        ></slot>

        <slot name="action_button"></slot>
      </WidgetField>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    field: IWidgetField;
    containerField?: IWidgetField;
    cards?: { items: { name: string; coef: string }[] }[];
    eventCardsState: State;
  }>(),
  {
    containerField: undefined,
    cards: () => [
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
    ],
  }
);

const cardStyles = computed<ElementStyle>(() => {
  const settings = props.field?.options;

  if (!settings) {
    return {};
  }

  const activeStateOptions =
    props.eventCardsState === State.DEFAULT
      ? settings
      : settings.states?.[props.eventCardsState] ?? {};

  const styles = {
    ...settings,
    ...activeStateOptions,
  };

  const isHorizontal = settings.contentLayout === DisplayOrientation.HORIZONTAL;

  return {
    display: "flex",
    flexDirection: isHorizontal ? "row" : "column",
    gap: getPxValueFromNumber(styles.contentItemsDistance),
    ...getCommonStyles({ ...styles }),
  };
});

const containerStyles = computed<ElementStyle>(() => {
  const settings = props.containerField
    ? props.containerField.options
    : props.field.options;
  const spacing = {
    margin: props.field.options.spacing.margin,
  };

  return {
    gridTemplateColumns: `repeat(${settings.columns}, minmax(0, 1fr))`,
    gap: getPxValueFromNumber(settings.distance),
    ...getCommonStyles({ spacing }),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__card {
    overflow: hidden;
  }

  &__header {
    @include flex(center, flex-start, 0);
    width: 100%;
  }

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 16px;
    column-gap: 16px;
  }
}
</style>
