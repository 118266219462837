import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { State, Align } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  TITLE_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useModalWindowHeaderInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    title: State.DEFAULT,
    close_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
    Initialize widget with initial options
  */
  const initialWidgetOptions = initWidgetOptions(
    ["fill"],
    {
      fillImageColor: getDefaultFillImage({
        value: getDefaultFillValue("#ffffff"),
      }),
    },
    {
      baseDisplaySettings: {
        alignment: Align.START,
        gap: 8,
      },
    }
  );

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
      }).options
    );

    const initialCloseButtonState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        buttonTextSettings: {
          _active: false,
          textColor: getDefaultFillValue("#000000", 45),
          theme: getDefaultTheme(16),
          fontFamily: getDefaultFontFamily(),
          decoration: getDefaultDecoration(),
        },
        iconSettings: {
          default_value: "ant-design:close-outlined",
          textColor: getDefaultFillValue("#000000", 45),
          iconSize: 20,
          position: "right",
          gap: 0,
          _active: true,
        },
      };
    };

    const initialCloseButtonOptions = prefillEmptyOptions(
      fields.value.close_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(0),
          padding: getDefaultSpacing(),
          iconSettings: {
            default_value: "ant-design:close-outlined",
            textColor: getDefaultFillValue("#000000", 45),
            iconSize: 20,
            position: "right",
            gap: 0,
            _active: true,
          },
          buttonTextSettings: {
            _active: false,
            textColor: getDefaultFillValue("#000000", 45),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          states: {
            [State.HOVER]: initialCloseButtonState(),
            [State.ACTIVE]: initialCloseButtonState(),
            [State.DISABLED]: initialCloseButtonState(),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.close_button.field,
      initialCloseButtonOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
