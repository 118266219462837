<template>
  <div
    v-if="hasDesignPermissions"
    class="config-max-height widget-control-container m-t-16 p-b-16 p-l-16 p-r-16"
  >
    <p class="m-b-4">Max height</p>
    <ConfigInput
      :model-value="modelValue"
      class="config-max-height__input"
      @update:model-value="handleUpdate"
      @blur="handleBlur"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { CSS_CALC_HEIGHT_REGEX } from "~~/constants/css-validations";

type MaxHeight = {
  value: string;
  type: string;
};

const props = defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const DEFAULT_TYPE = "px";
const ALLOWED_TYPES = ["px", "%", "vh", "svh"];

const getDetailedValue = (value: string): MaxHeight => {
  for (let i = 0; i < value.length; i++) {
    if (!isNaN(+value[i])) {
      continue;
    }

    return {
      value: value.slice(0, i),
      type: value.slice(i, value.length).toLowerCase(),
    };
  }

  return {
    value: value.trim(),
    type: "",
  };
};

const validate = (valueDetails: MaxHeight, fullValue: string): boolean => {
  if (CSS_CALC_HEIGHT_REGEX.test(fullValue)) {
    return true;
  }

  if (!ALLOWED_TYPES.includes(valueDetails.type)) {
    return false;
  }

  if (!valueDetails.value) {
    return false;
  }

  return true;
};

const handleUpdate = (value: string): void => {
  emit("update:model-value", value);
};

const handleBlur = (): void => {
  const heightDetails = getDetailedValue(props.modelValue || "");
  const isValid = validate(heightDetails, props.modelValue || "");

  if (isValid) {
    return;
  }

  if (heightDetails.value) {
    emit("update:model-value", heightDetails.value + DEFAULT_TYPE);
    return;
  }

  emit("update:model-value", "");
};
</script>

<style lang="scss">
.config-max-height {
  .ant-input-group,
  .ant-input {
    width: 100% !important;
  }

  .ant-input {
    border-radius: 4px !important;
  }
}
</style>
