import { getDefaultFillValue } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 29;

const v29WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    [
      "LiveBetsListWidget",
      "SportsBookMobileLiveFenixWidget",
      "SportsBookLiveFenixWidget",
    ].includes(widget.name)
  ) {
    const betItems = widget.content.bet_items.options;

    if (!betItems.arrow?.up) {
      widget.content.bet_items.options.arrow = {
        up: {
          color: getDefaultFillValue("#1677ff"),
        },
        down: {
          color: getDefaultFillValue("#1677ff"),
        },
      };
      Object.keys(betItems.states).forEach(state => {
        widget.content.bet_items.options.states[state].arrow = {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        };
      });
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v29WidgetMigration;
