import { defineAsyncComponent, type Component } from "vue";

import { FormElementName } from "~~/models/widgets/form.model";

export const FORM_FIELD_COMPONENTS: Record<string, Component> = {
  [FormElementName.TITLE]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormTextTitle.vue")
  ),
  [FormElementName.DESCRIPTION]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormDescriptionField.vue")
  ),
  [FormElementName.TEXT_INPUT]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormTextInputField.vue")
  ),
  [FormElementName.EMAIL]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormTextInputField.vue")
  ),
  [FormElementName.NUMBER]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormTextInputField.vue")
  ),
  [FormElementName.PASSWORD]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormPasswordField.vue")
  ),
  [FormElementName.CHECKBOX]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormCheckboxField.vue")
  ),
  [FormElementName.DROPDOWN]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormDropdownField.vue")
  ),
  [FormElementName.BUTTON]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormButtonField.vue")
  ),
  [FormElementName.RADIO_GROUP]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormRadioGroupField.vue")
  ),
  [FormElementName.DATE]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormDateField.vue")
  ),
  [FormElementName.PHONE]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormPhoneField.vue")
  ),
  [FormElementName.FORM_TEXT_INPUT_FIELD]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormTextInputField.vue")
  ),
  [FormElementName.UPLOAD]: defineAsyncComponent(
    () => import("~~/components/fields/form/FormUploadField.vue")
  ),
};
