import { IWidgetMigration } from "../runWidgetMigrations";

const generateNoOutcomesField = () => ({
  "name": "no_outcomes_title",
  "type": "TextInputField",
  "value": "Default",
  "options": {
    "_active": true,
  },
});

const WIDGET_VERSION = 31;

const v31WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    ["SportsBookMobileLiveFenixWidget", "SportsBookLiveFenixWidget"].includes(
      widget.name
    )
  ) {
    if (!("no_outcomes_title" in widget.content)) {
      widget.content.no_outcomes_title = generateNoOutcomesField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v31WidgetMigration;
