import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export type Data = {
  [key: string]: any;
  options: IWidgetOptions;
};

type SourceObject = {
  [key: string]: any;
};

const getSourceObjectByKey = (
  rootObject: SourceObject,
  keyName: string
): { source: SourceObject; key: string } => {
  if (!keyName.includes(".")) {
    return { source: rootObject, key: keyName };
  }

  const names = keyName.split(".");

  let resultObject: { [key: string]: any } = rootObject;
  for (let i = 0; i < names.length; i++) {
    if (i === names.length - 1) {
      continue;
    }

    const key = names[i];

    resultObject = resultObject[key];
  }

  return {
    key: names[names.length - 1],
    source: resultObject,
  };
};

export const getInitialOptions = ({
  data,
  exclude,
  additionalData,
  values,
}: {
  data: Data;
  exclude?: string[];
  additionalData?: Data;
  values?: Record<string, any>;
}): Data => {
  if (additionalData) {
    for (const key in additionalData.options) {
      if (Object.hasOwn(data.options, key)) {
        const err = new Error(
          `Property ${key} already exists, you are rewriting it`
        );
        console.error(err);
      }
    }

    for (const key in additionalData) {
      if (key === "options") {
        data.options = {
          ...data.options,
          ...additionalData.options,
        };

        continue;
      }

      data[key] = additionalData[key];
    }
  }

  if (exclude) {
    exclude.forEach(keyName => {
      const { source, key } = getSourceObjectByKey(data.options, keyName);

      if (!source || !key) {
        return;
      }

      delete source[key];
    });
  }

  if (values) {
    for (const key in values) {
      data.options[key] = values[key];
    }
  }

  return data;
};
