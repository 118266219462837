import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 26;

const v26WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["BetslipFenixWidget"].includes(widget.name)) {
    const iconRight =
      widget.options._customFields.fields_styling.options.iconRight;

    if (!iconRight.value) {
      iconRight.value = "ant-design:copy-outlined";
      iconRight.iconSize = 16;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v26WidgetMigration;
