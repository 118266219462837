<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #titleLinkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #close_button-design>
          <ControlsList
            :widget="widget"
            :controls="closeButtonDesignControls"
          />
        </template>

        <template #close_button-content>
          <ControlsList
            :widget="widget"
            :controls="closeButtonContentControls"
          />
        </template>

        <template #close_button-states>
          <ControlsList
            :widget="widget"
            :controls="closeButtonStateControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useModalWindowHeaderConfig } from "~~/composables/widgets/modal-window/header/useModalWindowHeaderConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  titleControls,
  titleLinkStatesControls,

  closeButtonDesignControls,
  closeButtonContentControls,
  closeButtonStateControls,
} = useModalWindowHeaderConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
