<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
      class="sportbook-live-mobile-fenix__header"
    >
      <div
        :style="gapStyles"
        class="sportbook-live-mobile-fenix__header-container"
      >
        <div
          :style="containerStyles"
          class="sportbook-live-mobile-fenix__header-general"
        >
          <div
            v-if="leagueNameField.options._active"
            :style="titleContainerStyles"
          >
            <WidgetField
              :widget="widget"
              :field="leagueNameField"
            >
              <BasicTitleField
                :field="leagueNameField"
                value="League name"
              />
            </WidgetField>
          </div>

          <div
            :style="detailsStyles"
            class="sportbook-live-mobile-fenix__header-details"
          >
            <WidgetField
              v-if="streamIconField.options._active"
              :widget="widget"
              :field="streamIconField"
            >
              <CommonIcon
                :name="streamIconField.options.value"
                :style="streamIconStyles"
              />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="timeIconField"
            >
              <SportsBookLiveTime
                :field="timeIconField"
                :show-icon="timeIconField.options.icon._active"
              >
                <span> 00:00 </span>
              </SportsBookLiveTime>
            </WidgetField>

            <WidgetField
              v-if="periodField.options._active"
              :widget="widget"
              :field="periodField"
              :style="periodStyles"
            >
              <BasicTitleField
                :field="periodField"
                value="Period"
              />
            </WidgetField>
          </div>
        </div>

        <WidgetField
          v-if="favoriteIconField.options._active"
          :widget="widget"
          :field="favoriteIconField"
          :style="favIconContainerStyles"
        >
          <CommonIcon
            :style="favIconStyles"
            name="ant-design:star-outlined"
          />
        </WidgetField>
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  getColorStyle,
  getFlexPosition,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  leagueNameField: IWidgetField;
  favoriteIconField: IWidgetField;
  streamIconField: IWidgetField;
  timeIconField: IWidgetField;
  periodField: IWidgetField;
}>();

const gapStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});

const detailsStyles = computed<ElementStyle>(() => {
  const styles: ElementStyle = {
    ...gapStyles.value,
  };

  if (props.periodField.options.display === ResizingType.FILL) {
    styles.flex = "1";
  }

  return styles;
});

const containerStyles = computed<ElementStyle>(() => {
  const layout = props.field.options.display.layout;
  const position = props.field.options.display.position;

  const baseStyles: ElementStyle = {
    ...gapStyles.value,
  };

  if (props.leagueNameField.options.display === ResizingType.FILL) {
    baseStyles.flex = "1";
  }

  if (layout === DisplayOrientation.VERTICAL) {
    const direction = position === "top" ? "column" : "column-reverse";

    return {
      ...baseStyles,
      flexDirection: direction,
      justifyContent: "space-between",
    };
  }

  const direction = position === "right" ? "row-reverse" : "row";

  return {
    ...baseStyles,
    flexDirection: direction,
  };
});

const titleContainerStyles = computed<ElementStyle>(() => {
  if (props.leagueNameField.options.display === ResizingType.FILL) {
    return {
      flex: "1",
    };
  }

  return {};
});

const favIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.favoriteIconField.options.color),
    ...getFontSizeStyle(props.favoriteIconField.options.size),
  };
});

const streamIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.streamIconField.options.textColor),
    ...getFontSizeStyle(props.streamIconField.options.iconSize),
  };
});

const favIconContainerStyles = computed<ElementStyle>(() => {
  const alignment = props.field.options.display.iconAlignment;
  const position = getFlexPosition(alignment);

  return {
    alignSelf: position,
  };
});

const periodStyles = computed<ElementStyle>(() => {
  if (props.periodField.options.display === ResizingType.FILL) {
    return {
      flex: "1",
    };
  }

  return {};
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__header {
    &-details {
      @include flex(center, flex-start, 0);
    }

    &-container {
      display: flex;
      justify-content: space-between;
    }

    &-general {
      display: flex;
    }
  }
}
</style>
