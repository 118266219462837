<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookBet9WidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sportsbook-widget-bet9">
      <WidgetField
        v-if="fields.header.active"
        :widget="widget"
        :field="fields.header.field"
      >
        <div
          :style="headerStyles"
          class="sportsbook-widget-bet9__header"
        >
          <WidgetField
            v-if="fields.title.active"
            :widget="widget"
            :field="fields.title.field"
            class="sportsbook-widget-bet9__header-title"
          >
            <BasicTitleField
              :field="fields.title.field"
              value="Default"
            />
          </WidgetField>

          <WidgetField
            v-if="fields.live_button_title.active"
            :widget="widget"
            :field="fields.live_button_title.field"
            class="basic-card-widget__field"
          >
            <BasicTextButtonField
              :field="fields.live_button_title.field"
              :current-state="states.live_button_title"
            />
          </WidgetField>
        </div>
      </WidgetField>

      <WidgetField
        v-if="fields.leagues_container.active"
        :widget="widget"
        :field="fields.leagues_container.field"
      >
        <SportsbookBet9Leagues
          :widget="widget"
          :field="fields.leagues_container.field"
          :league-field="fields.league_card.field"
          :state="states.league_card"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.winner_container.active"
        :widget="widget"
        :field="fields.winner_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.winner_container.field"
        >
          <WidgetField
            v-if="fields.winner_league_title.active"
            :widget="widget"
            :field="fields.winner_league_title.field"
          >
            <BasicTitleField :field="fields.winner_league_title.field" />
          </WidgetField>

          <WidgetField
            v-if="fields.winner_league_card.active"
            :widget="widget"
            :field="fields.winner_league_card.field"
          >
            <SportsbookLeagueWinnerCard
              :widget="props.widget"
              :field="fields.winner_league_card.field"
              :bet-item-field="fields.winner_league_bet_items.field"
              :bet-item-state="states.winner_league_bet_items"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="fields.filter_container.active"
        :widget="widget"
        :field="fields.filter_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.filter_container.field"
        >
          <WidgetField
            v-if="fields.list_title.active"
            :widget="widget"
            :field="fields.list_title.field"
          >
            <BasicTitleField :field="fields.list_title.field" />
          </WidgetField>

          <WidgetField
            v-if="fields.filter.active"
            :widget="widget"
            :field="fields.filter.field"
            :is-custom-selected="isFilterFieldSelected"
            no-overflow
          >
            <SportsbookFilter
              :widget="widget"
              :field="fields.filter.field"
              :fields="filterFields"
              :states="states"
              :visibility="fields.filter.field.options.visibility"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="fields.events_container.active"
        :widget="widget"
        :field="fields.events_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.events_container.field"
          :style="dateSpacingStyle"
          class="sportsbook-widget-bet9__dates"
        >
          <template v-if="states.event_cards === State.ACTIVE_EVENTS">
            <div
              v-for="(dateItem, index) in items"
              :key="index"
            >
              <div>
                <WidgetField
                  v-if="fields.day_label.active"
                  :widget="widget"
                  :field="fields.day_label.field"
                  :style="dayLabelStyles"
                >
                  <BasicTitleField
                    :field="fields.day_label.field"
                    :value="dateItem.date"
                  />
                </WidgetField>

                <div
                  :style="cardsSpacingStyle"
                  class="sportsbook-widget-bet9__leagues"
                >
                  <div
                    v-for="(leagueItem, index2) in dateItem.items"
                    :key="`${index}-${index2}`"
                  >
                    <WidgetField
                      v-if="fields.league_name.active"
                      :widget="widget"
                      :field="fields.league_name.field"
                    >
                      <BasicTitleField
                        :field="fields.league_name.field"
                        :value="leagueItem.league"
                      />
                    </WidgetField>

                    <WidgetField
                      v-if="fields.list_container.active"
                      :widget="widget"
                      :field="fields.list_container.field"
                    >
                      <SportsbookBet9EventCards
                        :widget="widget"
                        :field="fields.list_container.field"
                        :event-info-field="fields.list_event_info.field"
                        :start-time-field="fields.list_start_time.field"
                        :date-field="fields.list_date.field"
                        :favorite-icon-field="fields.list_favourite_icon.field"
                        :league-field="fields.league_title.field"
                        :team-player-field="fields.list_event_name.field"
                        :bet-item-field="fields.list_item.field"
                        :bet-builder-button-field="
                          fields.betbuilder_button.field
                        "
                        :bet-item-state="states.list_item"
                        :list-item-more-state="states.list_item_more"
                        :event-button-field="fields.list_item_more.field"
                        :bet-builder-state="states.betbuilder_button"
                        :items="leagueItem.items"
                      />
                    </WidgetField>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <BasicTitleField :field="fields.no_events_title.field" />
          </template>
        </BasicDesignWrapperField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useSportsbookInitData } from "~~/composables/widgets/sportsbook-bet9/useSportsbookInitData";
import { getCommonStyles } from "~~/assets/utils/styles";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedDropdownItem, selectedWidget } =
  storeToRefs(widgetSettingsStore);

const {
  states,
  filterFields,
  DEFAULT_STATES,

  initFields,
} = useSportsbookInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.header.field.options),
    alignItems: fields.value.header.field.options.alignment,
  };
});

const isFilterFieldSelected = computed<boolean>(() => {
  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  if (!selectedDropdownItem.value) {
    return false;
  }

  return !!STYLING_FIELDS[selectedDropdownItem.value];
});

initFields();

const items = [
  {
    date: "Today",
    items: [
      {
        league: "League name",
        items: [
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
      {
        league: "League name",
        items: [
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
    ],
  },
  {
    date: "Tomorrow",
    items: [
      {
        league: "League name",
        items: [
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
      {
        league: "League name",
        items: [
          {
            start_time: "Start time",
            date: "Date",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
    ],
  },
];

const cardsSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.league_name.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.list_container.field.options.distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.events_container.field.options.distanceBetweenLeagueEvents
    ),
  };
});

const dateSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.day_label.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.list_container.field.options.distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.events_container.field.options.distanceBetweenDateEvents
    ),
  };
});

const dayLabelStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.day_label.field.options),
    width:
      fields.value.day_label.field.options.resizing === ResizingType.FILL
        ? "100%"
        : "fit-content",
  };
});
</script>

<style lang="scss">
.sportsbook-widget-bet9 {
  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    flex: 1;
  }

  &__dates {
    @include flex-column(stretch, flex-start, 0px);
  }

  &__leagues {
    @include flex-column(stretch, flex-start, 0px);
  }
}
</style>
