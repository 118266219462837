<template>
  <div
    v-if="label"
    class="form-text-input-field"
    :class="`form-text-input-field--${labelPosition}`"
  >
    <div class="form-text-input-field__content">
      <div class="form-text-input-field__label-container">
        <div
          v-if="label._active && label.value"
          class="m-b-4 form-text-input-field__label"
          :style="labelContentStyles"
        >
          <span class="form-text-input-field__label-text-container">
            <span
              v-if="field.validation?.required"
              :style="requiredStyles"
              class="form-text-input-field__required"
            >
              *
            </span>
            <span
              :style="labelStyles"
              class="form-text-input-field__label-text"
            >
              {{ getLocalizedValue(label.value) }}
            </span>
          </span>
          <div
            v-if="
              tooltipField?.options.mode === 'icon' ||
              tooltipField?.options.mode === undefined
            "
            class="form-text-input-field__tooltip-icon"
            :style="tooltipIconStyles"
          >
            <a-tooltip
              :key="tooltipIconOptions?.gap"
              :overlay-style="tooltipStyles"
              :visible="isTooltipVisible && tooltip?._active"
              :get-popup-container="getMenuContainer"
              placement="top"
              overlay-class-name="form-text-input-field__tooltip"
            >
              <template
                v-if="tooltip?._active"
                #title
              >
                {{ getLocalizedValue(tooltip.value) }}
              </template>

              <p v-if="tooltip?._active && iconValue">
                <CommonIcon
                  :name="iconValue"
                  :style="tooltipIconColorStyle"
                />
              </p>
            </a-tooltip>
          </div>
        </div>
      </div>
      <a-tooltip
        v-if="tooltipField?.options.mode === 'field'"
        :key="tooltipIconOptions?.gap"
        :overlay-style="tooltipStyles"
        :visible="isTooltipVisible && tooltip?._active"
        :get-popup-container="getMenuContainer"
        placement="bottomLeft"
        overlay-class-name="form-text-input-field__tooltip remove-arrow"
      >
        <template
          v-if="tooltip?._active"
          #title
        >
          <div class="flex items-center">
            {{ getLocalizedValue(tooltip.value) }}
            <span style="padding: 4px">&times;</span>
          </div>
        </template>

        <div class="form-text-input-field__input-container">
          <slot />
        </div>
      </a-tooltip>
      <div
        v-else
        class="form-text-input-field__input-container"
      >
        <slot />
      </div>
    </div>

    <p
      v-if="isErrorVisible"
      :style="errorStyles"
      class="form-text-input-field__error"
    >
      Error text goes here
    </p>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  getSpacing,
  getDecorationValue,
  getCornerRadiusStyle,
  getBackgroundColorStyle,
  getColorStyle,
  getColorFromHex,
  getFontSizeStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getFontFamilyStyle,
} from "~~/assets/utils/widget-settings";
import {
  IFillControl,
  SpacingKeyName,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import {
  getStylesAsVars,
  findField,
  IFormTextInputProp,
} from "~~/assets/utils/widget/form";
import { ElementStyle, StyleValue } from "~~/models/common";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

enum LabelPosition {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  labelField?: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  isErrorVisible?: boolean;
  isTooltipVisible: boolean;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);
const { getLocalizedValue } = useLocalizedValue();

const label = computed<IFormTextInputProp | undefined>(() => {
  if (props.labelField) {
    return props.labelField;
  }

  return props.field.options?.label;
});

const tooltip = computed<IFormTextInputProp | undefined>(() => {
  return props.field.options?.tooltip;
});

const tooltipIconStyles = computed<ElementStyle>(() => {
  const field = props.stylingFields.find(
    field => field.name === FormStyleFieldName.FIELD
  );

  if (labelPosition.value === LabelPosition.HORIZONTAL) {
    return {
      alignSelf: "flex-end",
      marginBottom: `${
        field?.options.padding.bottom + field?.options.border.width
      }px`,
    };
  }

  return {};
});

const tooltipStyles = computed<ElementStyle>(() => {
  const tooltipField = props.stylingFields.find(
    field => field.name === FormStyleFieldName.TOOLTIP
  );

  if (!tooltipField) {
    return {};
  }

  const textColor = getColorFromHex(tooltipField.options.color) as StyleValue;

  const styles = {
    ...getSpacing(tooltipField.options.padding, SpacingKeyName.PADDING),
    ...getCornerRadiusStyle(tooltipField.options.cornerRadius),
    ...getBackgroundColorStyle(tooltipField.options.fill),
    ...getBorderStyle(tooltipField.options.border),
    ...getBoxShadowStyle(tooltipField.options.shadow),
    ...getFontSizeStyle(tooltipField.options.theme),
    ...getDecorationValue(tooltipField.options.decoration),
    textColor,
  };

  return getStylesAsVars(styles);
});

const labelField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.LABEL, props.stylingFields);
});

const tooltipField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.TOOLTIP, props.stylingFields);
});

const inputField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.FIELD, props.stylingFields);
});

const errorField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.ERROR, props.stylingFields);
});

const tooltipIconOptions = computed<{
  icon: string;
  color: IFillControl;
  gap: number;
} | null>(() => {
  if (!tooltipField.value) {
    return null;
  }

  return tooltipField.value.options.textChooseIcon;
});

const iconValue = computed<string | null>(() => {
  if (!tooltipIconOptions.value) {
    return null;
  }

  return tooltipIconOptions.value.icon;
});

const tooltipIconColorStyle = computed<ElementStyle>(() => {
  if (!tooltipIconOptions.value || !tooltipField.value) {
    return {};
  }

  return {
    ...getColorStyle(tooltipIconOptions.value.color),
  };
});

const labelContentStyles = computed<ElementStyle>(() => {
  if (!inputField.value) {
    return {};
  }

  if (labelField.value?.options?.position !== LabelPosition.VERTICAL) {
    return {};
  }

  return {
    gap: getPxValueFromNumber(tooltipIconOptions.value?.gap),
    ...getSpacing(
      labelField.value.options.containerPadding,
      SpacingKeyName.PADDING
    ),
  };
});

const labelActiveOptions = computed<IWidgetOptions>(() => {
  if (selectedField.value?.name === FormStyleFieldName.SUCCESS) {
    return {
      ...(labelField.value?.options || {}),
      ...labelField.value?.options.states.success,
    };
  }

  if (selectedField.value?.name === FormStyleFieldName.ERROR) {
    return {
      ...(labelField.value?.options || {}),
      ...labelField.value?.options.states.error,
    };
  }

  return labelField.value?.options || {};
});

const labelStyles = computed<ElementStyle>(() => {
  if (!labelField.value) {
    return {};
  }

  return {
    ...getFontSizeStyle(labelField.value.options.theme),
    ...getDecorationValue(labelField.value.options.decoration),
    ...getColorStyle(labelActiveOptions.value.color),
    fontFamily: labelField.value.options.fontFamily,
  };
});

const requiredStyles = computed<ElementStyle>(() => {
  if (!labelField.value) {
    return {};
  }

  return {
    ...getStylesAsVars(getFontSizeStyle(labelField.value.options.theme)),
  };
});

const labelPosition = computed(() => {
  if (!labelField.value) {
    return LabelPosition.VERTICAL;
  }

  return labelField.value.options.position;
});

const errorStyles = computed<ElementStyle>(() => {
  if (!errorField.value) {
    return {};
  }

  return {
    ...getColorStyle(errorField.value.options.color),
    ...getFontSizeStyle(errorField.value.options.theme),
    ...getFontFamilyStyle(errorField.value.options.fontFamily),
    ...getDecorationValue(errorField.value.options.decoration),
    textAlign: errorField.value.options.alignment,
  };
});

const getMenuContainer = (): HTMLElement | null => {
  return document.querySelector(`#${WIDGETS_GRID_ID}`);
};
</script>

<style lang="scss">
.form-text-input-field {
  &__input {
    outline: none;
    width: 100%;
    border-radius: 0px;
  }

  &__input-container {
    width: 100%;
  }

  &__required {
    color: #ff7875;
    line-height: var(--fontSize);
  }

  input {
    &::placeholder {
      text-decoration-color: var(--textDecorationColor) !important;
      overflow: hidden !important;
      max-width: 99%;
      text-overflow: ellipsis;
    }
  }

  &__label-text-container {
    @include flex(flex-start, flex-start, 4px);
    line-height: 1.308;
  }

  &__label-text {
    max-width: max-content;
    word-break: break-all;
  }

  &__error {
    margin-bottom: 0;
  }

  &__tooltip {
    font-size: 12px;
    flex-grow: 1;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
    &.remove-arrow {
      &[class*="ant-tooltip-placement"] {
        padding: 0 !important;
      }
      .ant-tooltip-arrow-content {
        display: none;
      }
    }
  }

  &__content {
    @include flex-column(flex-start, flex-start, 0);
  }

  &__label {
    @include flex(center, flex-start, 0);

    margin: 0;
  }

  &__label-container {
    @include flex(center, flex-start, 0);
  }

  &__tooltip-icon {
    line-height: 14px;
    height: 14px;
  }

  &--vertical {
    .form-text-input-field__label-container {
      flex-grow: 1;
      width: 100%;
    }

    .form-text-input-field__label {
      flex-grow: 1;
    }
  }

  &--horizontal {
    .form-text-input-field__content {
      flex-direction: row;
      gap: $space-s;
      align-items: flex-end;
    }

    .form-text-input-field__tooltip-icon {
      line-height: initial;
      height: initial;
    }

    .form-text-input-field__input-container {
      flex: 1;
      flex-basis: 50%;
      max-width: 50%;
    }

    .form-text-input-field__label-container {
      flex: 1;
      max-width: 50%;
    }

    .form-text-input-field__label {
      justify-content: space-between;
      width: 100%;

      p {
        margin: 0;
      }
    }

    .form-text-input-field__label-text-container {
      word-break: break-word;
    }

    .form-text-input-field__error {
      margin-left: 50%;
    }
  }
}
</style>
