<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
    >
      <div
        :style="containerStyles"
        class="sportbook-live-mobile-fenix__sport-events-container"
      >
        <template
          v-for="value in count"
          :key="value"
        >
          <slot />
        </template>
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  count: number;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
    "grid-template-columns": `repeat(${props.field.options.display.layout}, 1fr)`,
  };
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__sport-events-container {
    display: grid;
  }
}
</style>
