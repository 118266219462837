import {
  ICustomField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import {
  generateFlexAlignment,
  generateFontSize,
  generateInvisibleScrollCssString,
  generateTextColor,
} from "~~/assets/utils/widget-css/helpers";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { itemsGroupClassNameGenerator } from "../compiler/items-css-compiler";
import { generateFlex, generateWidth } from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";

const generateItemsGroupCssString =
  (
    itemsGroup: ICustomField,
    itemsCssClass: string,
    displayOptions?: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = itemsGroup;
    const iconCssStringContent =
      generateTextColor(options.itemsIcon?.textColor) +
      generateFontSize(options.itemsIcon?.iconSize);

    const isDisplayFill = displayOptions?.resizing === ResizingType.FILL;
    const itemsGroupAlignment = isDisplayFill
      ? displayOptions?.alignment
      : "flex-start";

    const itemsGroupWidth = isDisplayFill ? `${generateWidth(100, "%")}` : "";

    cssString += generateDefaultStylesWithStates(itemsGroup, false);
    cssString += `.${itemsGroup.options._cssClass}{${generateFlex({
      flex: "flex",
      align: "center",
      justify: itemsGroupAlignment,
      direction: options.itemsIcon?.position === "left" ? "row-reverse" : "row",
      gap: options.itemsIcon?.gap,
    })}${itemsGroupWidth}}`;

    cssString += generateCssClassWithContent({
      className: itemsGroup.fields?.[0].options._cssClass || options._cssClass,
      content: options.itemsText,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.itemsIcon?._cssClass,
      content: iconCssStringContent,
    });

    for (const state in itemsGroup.options.states) {
      if (state === State.ACTIVE) {
        /* 
      Styles for active route
    */
        cssString += generateCssClassWithContent({
          className: `${itemsCssClass} li[data-active="true"] .${options._cssClass}`,
          content: options.states.active,
          customFunction: generateDefaultStyles,
        });

        cssString += generateCssClassWithContent({
          className: `${itemsCssClass} li[data-active="true"] .${options._cssClass}`,
          childClassName: options.itemsIcon?._cssClass,
          content: options.states.active.itemsIcon?.textColor,
          customFunction: generateTextColor,
        });

        cssString += generateCssClassWithContent({
          className: `${itemsCssClass} li[data-active="true"] .${options._cssClass}`,
          content: options.states.active.itemsText,
          customFunction: generateDefaultStyles,
        });
      } else {
        cssString += generateCssClassWithContent({
          className: options._cssClass,
          childClassName: options.itemsIcon?._cssClass,
          content: options.states[state].itemsIcon?.textColor,
          customFunction: generateTextColor,
          pseudoClassName: `:${state}`,
        });

        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: options.states[state].itemsText,
          customFunction: generateDefaultStyles,
          pseudoClassName: `:${state}`,
        });
      }
    }

    return cssString;
  };

const generateWidgetCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const itemsGroupOptions = element.content.items.value[0].options;

    const isDisplayFill = itemsGroupOptions.resizing === ResizingType.FILL;

    const widgetAlignItems = isDisplayFill
      ? "stretch"
      : generateFlexAlignment(itemsGroupOptions.alignment);

    const widgetCssContent = generateDefaultStyles(element.options);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: widgetCssContent + `align-items:${widgetAlignItems};`,
    });

    cssString += generateCssClassWithContent({
      className: element.options._contentCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "unset",
          justify: "center",
          direction: "column",
        }) + "height: 100%;width:100%;",
    });

    return cssString;
  };

const generateItemsCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const itemsGroupOptions = element.value[0].options;
    const isItemsGroupFill = itemsGroupOptions.resizing === ResizingType.FILL;

    const elementContent = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: isItemsGroupFill ? "flex-start" : itemsGroupOptions.alignment,
      direction: "row",
      gap: itemsGroupOptions.spaceBetween,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementContent,
    });

    cssString += generateCssClassWithContent({
      className: element.options._rootCssClass,
      content: `overflow: auto;${generateWidth(100, "%")}`,
    });

    const liFlex = isItemsGroupFill ? "flex:auto;" : "flex:unset;";

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} li`,
      content: liFlex,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} li[data-active="true"] .${itemsGroupOptions._cssClass}`,
      content: itemsGroupOptions,
      customFunction: generateDefaultStyles,
    });

    cssString += `.${element.options._cssClass} li {${liFlex}}`;

    return cssString;
  };

export const generateComingEventsSwitcherWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const items = widget.content["items"];
  widget.options._cssClass = generateClassName("ComingEventsSwitcherWidget");
  widget.options._contentCssClass = generateClassName(
    "ComingEventsSwitcherWidget_content"
  );

  items.options = {};
  items.options._cssClass = generateClassName("coming-events-items");
  items.options._rootCssClass = generateClassName("coming-events-items-root");

  items.value[0].options.itemsIcon._cssClass =
    generateClassName("comingItemsIcon");
  items.value[0].options.itemsText._cssClass =
    generateClassName("comingItemsText");
  itemsGroupClassNameGenerator(items);

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateItemsCssString(items),
    generateItemsGroupCssString(
      items.value[0],
      items.options._cssClass,
      widget.options.display
    ),
    generateInvisibleScrollCssString({
      _cssClass: items.options._rootCssClass,
    })
  )("");
};
