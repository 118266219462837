import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

import { useWidgetWithArrayField } from "../useWidgetWithArrayField";

import { useItemsWidgetControls } from "./useItemsWidgetControls";
import { useTitleControls } from "./useTitleControls";
import { useItemsGroupControls } from "./useItemsGroupControls";
import { useItemControls } from "./useItemControls";

export const useItemsWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  titleLinkState: Ref<State>,
  itemsGroupState: Ref<State>,
  emit: any
) => {
  const { titleField, itemsGroup } = useWidgetWithArrayField(widget.value);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
    generateDropdownItem("Items", "itemsGroup"),
    generateDropdownItem("Item", "item", "item"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const { widgetDesignControls, widgetContentControls } =
    useItemsWidgetControls(widget, titleField);

  const { titleControls, titleLinkStatesControls } = useTitleControls(
    { field: titleField, state: titleLinkState },
    emit
  );

  const {
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  } = useItemsGroupControls(itemsGroup, itemsGroupState, emit);

  const { itemControls } = useItemControls();

  return {
    DROPDOWN_ITEMS,
    TABS,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    titleControls,
    titleLinkStatesControls,
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
    itemControls,
  };
};
