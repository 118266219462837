<template>
  <div
    :style="betItemsContainerStyles"
    class="sport-event-fenix-widget__bet-items"
  >
    <div
      v-for="(inner, index2) in items"
      :key="index2"
      class="sport-event-fenix-widget__item"
      :style="betItemContainerStyles"
    >
      <SportsbookBetItem
        :item-value="inner"
        :state-options="currentStateOptions"
        :bet-item-field="field"
        :is-up="state === State.UP"
        :is-down="state === State.DOWN"
        :show-both-arrows-states="[State.ACTIVE, State.HOVER]"
        :state="state"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  field: IWidgetField;
  items: {
    name: string;
    coef: string;
  }[];
  state: State;
  columns: number;
}>();

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const direction =
    props.field.options.displayMode === DisplayOrientation.HORIZONTAL
      ? "column"
      : "row";

  return {
    gap: getPxValueFromNumber(props.field.options.distance),
    gridAutoFlow: direction,
    gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
    ...getCommonStyles({
      margins: props.field.options.containerMargins ?? {},
    }),
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});
</script>

<style lang="scss">
.sport-event-fenix-widget {
  &__bet-items {
    display: grid;
  }

  &__items-list {
    display: grid;
  }

  &__item {
    min-width: 0;
  }
}
</style>
