<template>
  <div
    :style="containerStyles"
    class="search-results-widget-cards"
  >
    <template v-if="state === State.ACTIVE_EVENTS">
      <div
        v-for="(card, index) in cards"
        :key="index"
        :style="cardStyles"
      >
        <div
          :class="{
            'search-results-widget-cards__top--vertical':
              field.options.displayMode.eventInfoLayout === 'column',
          }"
          class="search-results-widget-cards__top"
        >
          <div class="search-results-widget-cards__event-details">
            <slot name="tag"></slot>
            <slot name="event_time"></slot>
          </div>
          <slot name="event_title"></slot>
        </div>

        <div>
          <div
            :class="{
              'search-results-widget-cards__items-container--vertical':
                field.options.displayMode.betItemsLayout ===
                DisplayOrientation.VERTICAL,
            }"
            :style="itemsContainerStyles"
            class="search-results-widget-cards__items-container"
          >
            <WidgetField
              :widget="widget"
              :field="betItemsField"
              class="search-results-widget-cards__bet-items-list"
            >
              <div
                :style="betItemsContainerStyles"
                :class="{
                  'search-results-widget-cards__items-list-vertical':
                    field.options.displayMode.betItemsLayout ===
                    DisplayOrientation.VERTICAL,
                }"
                class="search-results-widget-cards__items-list"
              >
                <div
                  v-for="(inner, index2) in card.items"
                  :key="index2"
                  class="search-results-widget-cards__item"
                >
                  <SportsbookBetItem
                    :item-value="inner"
                    :state-options="currentStateOptions"
                    :bet-item-field="betItemsField"
                  />
                </div>
              </div>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="eventButtonField"
            >
              <SportsBookLiveEventsButton
                :field="eventButtonField"
                :state="eventButtonState"
              />
            </WidgetField>
          </div>
        </div>
      </div>
    </template>

    <div v-else>
      <slot name="empty"> </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    field: IWidgetField;
    betItemsField: IWidgetField;
    eventButtonField: IWidgetField;
    state: string;
    betItemsState: string;
    eventButtonState: string;
    cards?: { items: { name: string; coef: string }[] }[];
  }>(),
  {
    cards: () => [
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
          { name: "Bet name", coef: "0.00" },
        ],
      },
    ],
  }
);

const cardStyles = computed<ElementStyle>(() => {
  const styles = {
    ...props.field.options,
  };

  const spacing = {
    padding: styles.spacing.padding,
  };

  return getCommonStyles({
    ...styles,
    spacing,
  });
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemsState === State.DEFAULT) {
    return props.betItemsField.options;
  }

  return props.betItemsField.options.states[props.betItemsState];
});

const containerStyles = computed<ElementStyle>(() => {
  const spacing = {
    margin: props.field.options.spacing.margin,
  };

  if (props.state === State.NO_ACTIVE) {
    return getCommonStyles({ spacing });
  }

  return {
    gridTemplateColumns: `repeat(${props.field.options.displayMode.layout}, minmax(0, 1fr))`,
    gap: getPxValueFromNumber(props.field.options.displayMode.cardsDistance),
    ...getCommonStyles({ spacing }),
  };
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const direction =
    props.field.options.displayMode.betItemsLayout ===
    DisplayOrientation.VERTICAL
      ? "row"
      : "column";

  return {
    gap: getPxValueFromNumber(props.field.options.displayMode.betItemsDistance),
    "grid-template-columns":
      direction === "row" ? "1fr" : "repeat(auto-fit, minmax(0px, 1fr))",
    "grid-auto-flow": direction,
  };
});

const itemsContainerStyles = computed<ElementStyle>(() => {
  if (
    props.field.options.displayMode.betItemsLayout ===
    DisplayOrientation.HORIZONTAL
  ) {
    return {};
  }

  return {
    gap: getPxValueFromNumber(props.field.options.displayMode.betItemsDistance),
  };
});
</script>

<style lang="scss">
.search-results-widget-cards {
  display: grid;

  &__top {
    @include flex(center, flex-start, 8px);
    margin-bottom: 16px;

    &--vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__event-details {
    @include flex(center, flex-start, 0);
  }

  &__items-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
    flex: 1;

    &-vertical {
      align-items: stretch;
    }
  }

  &__items-container {
    @include flex(center, flex-start, 0);

    &--vertical {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__bet-items-list {
    @include flex(center, flex-start, 0);
    flex: 1;
  }

  &__item {
    flex: 1;
  }
}
</style>
