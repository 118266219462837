import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorFromHex,
  getDecorationValue,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";

import { IFields } from "../../useWidgetFields";

export const useBetslipBetsResultInlineStyles = (
  fields: ComputedRef<IFields>
) => {
  const totalAmountValueInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      color: getColorFromHex(totalAmountContainerOptions.valueSettings.color)!,
      fontSize: `${totalAmountContainerOptions.valueSettings.theme.value}px`,
      ...getDecorationValue(
        totalAmountContainerOptions.valueSettings.decoration
      ),
    };
  });

  const resultContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(fields.value.result_container.field.options),
    };
  });

  const totalAmountContainerInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      gap: `${totalAmountContainerOptions.gap}px`,
      ...getSpacing(
        totalAmountContainerOptions.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const totalAmountLabelInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      color: getColorFromHex(totalAmountContainerOptions.labelSettings.color)!,
      fontSize: `${totalAmountContainerOptions.labelSettings.theme.value}px`,
      ...getDecorationValue(
        totalAmountContainerOptions.labelSettings.decoration
      ),
    };
  });

  return {
    totalAmountValueInlineStyles,
    resultContainerInlineStyles,
    totalAmountContainerInlineStyles,
    totalAmountLabelInlineStyles,
  };
};
