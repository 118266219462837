import { OptionValue } from "~~/models/common/dropdown.model";

export const getTextValue = (v: OptionValue): string => {
  if (v) {
    return v.toString().trim().toLocaleLowerCase();
  }

  return "";
};

export const isPartOf = (a: OptionValue, b: OptionValue): boolean => {
  const aString = getTextValue(a);
  const bString = getTextValue(b);

  return aString.includes(bString);
};
