import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useEventItems } from "~~/composables/widgets/1event/useEventItems";
import { useBetItemsConfig } from "~~/composables/widgets/1event/useBetItemsConfig";
import { useMarketCardsConfig } from "~~/composables/widgets/1event/useMarketCardsConfig";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useEventBetBuilderConfig } from "~~/composables/widgets/1event/useEventBetBuilderConfig";
import { useRacingSportsButtonTabsConfig } from "~~/composables/widgets/racing-sports-live/useRacingSportsButtonTabsConfig";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useEventConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useEventItems(widget);
  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(widget, states, emit);

  const {
    marketCardsDesignControls,
    marketCardsContentControls,
    marketNameControls,
    favouriteEventMarketControls,
    marketSuspendedStatusDesignControls,
    marketSuspendedStatusContentControls,
  } = useMarketCardsConfig(widget);

  const {
    betBuilderDesignControls,
    betBuilderContentControls,

    betBuilderHeaderDesignControls,
    betBuilderHeaderContentControls,
    betBuilderHeaderStatesControls,

    betBuilderContentContainerDesignControls,
    betBuilderContentContainerContentControls,
  } = useEventBetBuilderConfig(widget, states, emit);

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
   Common title config
  */

  const titleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
   Commom banner container 
  */

  const bannerContainerText = computed(() => {
    return "The system photo will be used as the background. You can view images for each section in the admin panel";
  });

  const {
    tabsContainerDesignControls,
    tabsContainerContentControls,
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
  } = useRacingSportsButtonTabsConfig(widget, states, emit);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    bannerContainerText,

    marketCardsDesignControls,
    marketCardsContentControls,
    marketNameControls,
    favouriteEventMarketControls,
    marketSuspendedStatusDesignControls,
    marketSuspendedStatusContentControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    betBuilderDesignControls,
    betBuilderContentControls,

    betBuilderHeaderDesignControls,
    betBuilderHeaderContentControls,
    betBuilderHeaderStatesControls,

    betBuilderContentContainerDesignControls,
    betBuilderContentContainerContentControls,

    tabsContainerDesignControls,
    tabsContainerContentControls,
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
    /* 
      Filter content
    */
  };
};
