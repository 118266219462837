<template>
  <div
    class="basic-card-slider-field"
    :class="`basic-card-slider-field_${widget.options.display.mode}`"
    :style="containerStyles"
  >
    <template v-if="widget.options.display.mode === 'carousel'">
      <slot name="pagination">
        <WidgetField
          v-if="fields.pagination.field.options._active"
          :widget="widget"
          :field="fields.pagination.field"
          class="basic-card-slider-field__pagination-container"
        >
          <ContainerPagination
            :widget="widget"
            :field="fields.pagination.field"
          />
        </WidgetField>
      </slot>

      <slot name="arrows">
        <WidgetField
          v-if="fields.arrows.field.options._active"
          :widget="widget"
          :field="fields.arrows.field"
          class="basic-card-slider-field__arrow-container"
        >
          <ContainerArrow
            :widget="widget"
            :field="fields.arrows.field"
            :state="states.arrows"
          />
        </WidgetField>

        <WidgetField
          v-if="fields.arrows.field.options._active"
          :widget="widget"
          :field="fields.arrows.field"
          class="basic-card-slider-field__arrow-container basic-card-slider-field__arrow-container--right"
        >
          <ContainerArrow
            :widget="widget"
            :field="fields.arrows.field"
            :state="states.arrows"
          />
        </WidgetField>
      </slot>
    </template>

    <div
      class="basic-card-slider-field__slides-container"
      :style="slidesContainerStyles"
    >
      <WidgetField
        v-for="slide in slides"
        :key="slide.id"
        :field="slide"
        :widget="widget"
        class="basic-card-slider-field__slide-item"
      >
        <slot
          name="slide"
          :slide-field="slide"
        >
        </slot>
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ContainerMode,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import type {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { CellSizeType } from "~~/models/grid.interface";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getDisplayGridStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: IFields;
  states: Record<string, State>;
}>();

const arrayField = computed<IWidgetField | null>(() => {
  return (
    props.widget.fields.find(field => field.name === FieldName.ITEMS) || null
  );
});

const slides = computed<ICustomField[]>(
  () => (arrayField.value?.value as ICustomField[]) || []
);

const containerStyles = computed<ElementStyle>(() => {
  return {
    justifyContent:
      props.widget.options.size.width.type === CellSizeType.FIXED
        ? "center"
        : "initial",
  };
});

const isFixed = computed<boolean>(() => {
  return props.widget.options.display.layout === ResizingType.FIXED;
});

const slidesContainerStyles = computed<ElementStyle>(() => {
  const display = props.widget.options.display;

  const adaptedGridObject = {
    grid: display.layout,
    minCardWidth: display.width,
    gap: display.gap.column,
    rowGap: display.gap.row,
    gridFixedType: display.grid.gridFixedType,
    minCardsInRow: display.grid.minCardsInRow,
    maxCardsInRow: display.grid.maxCardsInRow,
  };

  switch (display.mode) {
    case ContainerMode.GRID:
      return getDisplayGridStyles(adaptedGridObject);
    case ContainerMode.CAROUSEL:
      adaptedGridObject.minCardsInRow = display.slider.visible;
      adaptedGridObject.maxCardsInRow = display.slider.visible;
      adaptedGridObject.grid = isFixed.value
        ? ResizingType.FILL
        : ResizingType.FIXED;
      return {
        ...getDisplayGridStyles(adaptedGridObject),
        gridTemplateColumns: isFixed.value
          ? `repeat(auto-fill, ${getPxValueFromNumber(display.width)})`
          : getDisplayGridStyles(adaptedGridObject).gridTemplateColumns,
        gridAutoFlow: "column",
      };
    default:
      return {};
  }
});
</script>

<style lang="scss">
.basic-card-slider-field {
  position: relative;
  display: grid;

  &__slides-container {
    display: flex;
  }

  &__slide-item {
    height: 100%;
  }

  &__arrow-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    align-self: flex-start;
    z-index: 6;

    &--right {
      right: 0;
      left: initial;

      .container-widget__arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__pagination-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 6;
  }
}
</style>
