import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { FormElementName } from "~~/models/widgets/form.model";

export const FORM_STYLING_FIELDS_OPTIONS: Record<
  FormElementName,
  FormStyleFieldName[]
> = {
  [FormElementName.TITLE]: [FormStyleFieldName.TITLE],
  [FormElementName.HIDDEN]: [],
  [FormElementName.DESCRIPTION]: [],
  [FormElementName.BUTTON]: [],
  [FormElementName.TEXT_INPUT]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.EMAIL]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.NUMBER]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.PASSWORD]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.CHECKBOX]: [
    FormStyleFieldName.CHECKBOX,
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.DROPDOWN]: [
    FormStyleFieldName.DROPDOWNS,
    FormStyleFieldName.DROPDOWN_MENU,
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.DROPDOWN_MENU_ITEMS,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.IMAGE]: [],
  [FormElementName.RADIO_GROUP]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.RADIO_GROUP,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.DATE]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.CALENDAR,
    FormStyleFieldName.DATEPICKER,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.PHONE]: [
    FormStyleFieldName.DROPDOWNS,
    FormStyleFieldName.DROPDOWN_MENU,
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.DROPDOWN_MENU_ITEMS,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.FORM_TEXT_INPUT_FIELD]: [
    FormStyleFieldName.FIELD,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.SUCCESS,
  ],
  [FormElementName.UPLOAD]: [
    FormStyleFieldName.UPLOAD_LIST,
    FormStyleFieldName.UPLOAD_ITEMS,
    FormStyleFieldName.UPLOAD_CAPTION,
    FormStyleFieldName.UPLOAD_BUTTON,
    FormStyleFieldName.LABEL,
    FormStyleFieldName.ERROR,
    FormStyleFieldName.TOOLTIP,
    FormStyleFieldName.FIELD,
    FormStyleFieldName.SUCCESS,
  ],
};
