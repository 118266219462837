<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sportsbook-widget">
      <WidgetField
        v-if="fields.header.active"
        :widget="widget"
        :field="fields.header.field"
      >
        <div
          :style="headerStyles"
          class="sportsbook-widget__header"
        >
          <WidgetField
            v-if="fields.title.active"
            :widget="widget"
            :field="fields.title.field"
            class="sportsbook-widget__header-title"
          >
            <BasicTitleField
              :field="fields.title.field"
              value="Default"
            />
          </WidgetField>

          <WidgetField
            v-if="fields.live_button_title.active"
            :widget="widget"
            :field="fields.live_button_title.field"
            class="basic-card-widget__field"
          >
            <BasicTextButtonField
              :field="fields.live_button_title.field"
              :current-state="states.live_button_title"
            />
          </WidgetField>
        </div>
      </WidgetField>

      <WidgetField
        v-if="fields.winner_league_title.active"
        :widget="widget"
        :field="fields.winner_league_title.field"
      >
        <BasicTitleField :field="fields.winner_league_title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.winner_league_card.active"
        :widget="widget"
        :field="fields.winner_league_card.field"
      >
        <SportsbookLeagueWinnerCard
          :widget="props.widget"
          :field="fields.winner_league_card.field"
          :bet-item-field="fields.winner_league_bet_items.field"
          :bet-item-state="states.winner_league_bet_items"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.list_title.active"
        :widget="widget"
        :field="fields.list_title.field"
      >
        <BasicTitleField :field="fields.list_title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.filter.active"
        :widget="widget"
        :field="fields.filter.field"
        :is-custom-selected="isFilterFieldSelected"
        no-overflow
      >
        <SportsbookFilter
          :widget="widget"
          :field="fields.filter.field"
          :fields="filterFields"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.list_container.active"
        :widget="widget"
        :field="fields.list_container.field"
      >
        <SportsbookEventsCard
          :widget="widget"
          :field="fields.list_container.field"
          :start-time-field="fields.list_start_time.field"
          :league-field="fields.league_title.field"
          :team-player-field="fields.list_event_name.field"
          :bet-item-field="fields.list_item.field"
          :bet-builder-button-field="fields.betbuilder_button.field"
          :bet-item-state="states.list_item"
          :list-item-more-state="states.list_item_more"
          :event-button-field="fields.list_item_more.field"
          :bet-builder-state="states.betbuilder_button"
          :no-events-field="fields.no_events_title.field"
          :event-card-state="states.event_cards"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useSportsbookInitData } from "~~/composables/widgets/sportsbook/useSportsbookInitData";
import { getCommonStyles } from "~~/assets/utils/styles";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedDropdownItem, selectedWidget } =
  storeToRefs(widgetSettingsStore);

const {
  states,
  filterFields,
  DEFAULT_STATES,

  initFields,
} = useSportsbookInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.header.field.options),
    alignItems: fields.value.header.field.options.alignment,
  };
});

const isFilterFieldSelected = computed<boolean>(() => {
  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  if (!selectedDropdownItem.value) {
    return false;
  }

  return !!STYLING_FIELDS[selectedDropdownItem.value];
});

initFields();
</script>

<style lang="scss">
.sportsbook-widget {
  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    flex: 1;
  }
}
</style>
