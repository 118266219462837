import { pipeSync } from "~~/helpers/pipe";
import { IContentField, IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateGrid,
  generateMarginStyle,
  generateTextColor,
} from "../helpers";

import {
  generateEventInfoCssString,
  generateListTimeCssString,
} from "./sportsbook-live-widget-css";
import {
  generateEventNameCssString,
  generateFavouritesButtonCssString,
  generateFooterCssString,
  generateHeaderCssString,
  generateLeagueNameCssString,
  generatePeriodCssString,
  generateTimeStatesCssString,
} from "./sports-menu-live-fenix-css";

export const generateTitleCssString =
  (title: IContentField) =>
  (cssString: string): string => {
    const { options } = title;
    const iconFontCssContent =
      generateTextColor(options.icon.textColor) +
      generateFontSize(options.icon.iconSize) +
      generateMarginStyle({ right: options.icon.gap });

    cssString += generateDefaultStylesWithStates(title, false);

    const titleContent = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: titleContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options.text,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.arrow._cssClass,
      content: `${generateTextColor(
        options.arrow.textColor
      )}margin-left: auto;`,
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    for (const state in title.options.states) {
      const currentState = options.states[state];

      if (state === State.ACTIVE) {
        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: currentState,
          customFunction: generateDefaultStyles,
          pseudoClassName: '[data-state="open"]',
        });

        cssString += generateCssClassWithContent({
          className: options._cssClass,
          childClassName: options.arrow?._cssClass,
          content: currentState.arrow?.textColor,
          customFunction: generateTextColor,
          pseudoClassName: '[data-state="open"]',
        });

        cssString += generateCssClassWithContent({
          className: options._cssClass,
          childClassName: options.icon?._cssClass,
          content: currentState.icon?.textColor,
          customFunction: generateTextColor,
          pseudoClassName: '[data-state="open"]',
        });

        cssString += generateCssClassWithContent({
          className: options._cssClass,
          content: currentState.text,
          customFunction: generateDefaultStyles,
          pseudoClassName: '[data-state="open"]',
        });
      }

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.arrow?._cssClass,
        content: currentState.arrow?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.icon?._cssClass,
        content: currentState.icon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: currentState.text,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

export const generateNoEventsSpacingCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

const generateRacingCardCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      gap: "8",
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `${flex}
      overflow:hidden;
      max-width: 100%;
      `,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateEventContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const cardsGrid = generateGrid({
      templateColumns: `repeat(${field.options.columns}, minmax(0, 1fr))`,
      gap: field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateDefaultStyles(field.options) + cardsGrid,
    });

    return cssString;
  };

export const generateFavoritesEventsLiveFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const heading = widget.content["heading"];
  const event_container = widget.content["event_container"];
  const event_card = widget.content["event_card"];
  const racing_event_card = widget.content["racing_event_card"];
  const header = widget.content["header"];
  const footer = widget.content["footer"];
  const event_league = widget.content["event_league"];
  const favourites_button = widget.content["favourites_button"];
  const racing_favourites_icon = widget.content["racing_favourites_icon"];
  const event_period = widget.content["event_period"];
  const racing_event_name = widget.content["racing_event_name"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const racing_event_status = widget.content["racing_event_status"];
  const stream_icon = widget.content["stream_icon"];
  const racing_stream_icon = widget.content["racing_stream_icon"];
  const event_time = widget.content["event_time"];
  const event_info = widget.content["event_info"];
  const event_team_player = widget.content["event_team_player"];
  const event_score = widget.content["event_score"];
  const no_event_title = widget.content["no_event_title"];

  const isLayoutFirstMode =
    event_card.options.layoutMode === SportMenuLiveBet9CardLayoutMode.FIRST;

  widget.options._cssClass = generateClassName("FavoritesEventsLiveWidget");

  heading.options._cssClass = generateClassName("heading");
  heading.options.arrow._cssClass = generateClassName("titleArrow");
  heading.options.icon._cssClass = generateClassName("titleIcon");

  event_container.options._cssClass = generateClassName("eventContainer");
  event_card.options._cssClass = generateClassName("eventCard");
  racing_event_card.options._cssClass = generateClassName("racingEventCard");

  header.options._cssClass = generateClassName("header");
  header.options._leftCssClass = generateClassName("header_left");
  header.options._bottomCssClass = generateClassName("header_bottom");

  footer.options._cssClass = generateClassName("footer");
  footer.options._itemCssClass = generateClassName("footer_item");

  event_suspended_status.options._cssClass = generateClassName(
    "event_suspended_status"
  );
  racing_event_status.options._cssClass = generateClassName(
    "racing_event_status"
  );

  event_league.options._cssClass = generateClassName("event_league");

  favourites_button.options._cssClass = generateClassName("favourites_button");
  racing_favourites_icon.options._cssClass = generateClassName(
    "racing_favourites_icon"
  );

  no_event_title.options._cssClass = generateClassName("no_event_title");

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");

  event_info.options._cssClass = generateClassName("event_info");
  event_info.options._itemCssClass = generateClassName("event_info_item");
  event_team_player.options._cssClass = generateClassName("event_team_player");
  event_score.options._cssClass = generateClassName("event_score");

  racing_event_name.options._cssClass = generateClassName("racing-event-name");

  stream_icon.options._cssClass = generateClassName("stream_icon");
  racing_stream_icon.options._cssClass =
    generateClassName("racing_stream_icon");

  event_period.options._cssClass = generateClassName("event_period");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateTitleCssString(heading),
    generateStringWithStates(event_card, false),
    generateRacingCardCssString(racing_event_card),
    generateEventContainerCssString(event_container),
    generateHeaderCssString(header, event_card.options._cssClass),
    generateFooterCssString(
      footer,
      isLayoutFirstMode,
      event_card.options._cssClass
    ),
    generateLeagueNameCssString(event_league, event_card.options._cssClass),
    generateFavouritesButtonCssString(
      favourites_button,
      header,
      event_card.options._cssClass
    ),
    generateFavouritesButtonCssString(
      racing_favourites_icon,
      undefined,
      racing_event_card.options._cssClass
    ),
    generateStringWithStates(
      event_suspended_status,
      false,
      event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      racing_event_status,
      false,
      racing_event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      stream_icon,
      false,
      event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      racing_stream_icon,
      false,
      racing_event_card.options._cssClass,
      true
    ),
    generateEventNameCssString(
      event_team_player,
      isLayoutFirstMode,
      event_card.options._cssClass
    ),
    generateEventNameCssString(
      racing_event_name,
      true,
      racing_event_card.options._cssClass
    ),
    generateStringWithStates(
      event_score,
      false,
      event_card.options._cssClass,
      true
    ),
    generateListTimeCssString(event_time as IWidgetField),
    generateTimeStatesCssString(
      event_time as IWidgetField,
      event_card.options._cssClass
    ),
    generatePeriodCssString(event_period, event_card.options._cssClass),

    generateStringDefault(event_info),
    generateEventInfoCssString(event_info as IWidgetField),
    generateNoEventsSpacingCssString({
      _cssClass: no_event_title.options._cssClass,
      padding: event_card.options.spacing.padding,
    }),
    generateStringDefault(no_event_title)
  )("");
};
