<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <ControlsList
            :widget="widget"
            :controls="widgetDataControls"
          />

          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #tabs-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardsDesignControls"
          />
        </template>

        <template #event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardsContentControls"
          />
        </template>

        <template #event_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedDesignControls"
          />
        </template>

        <template #event_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedContentControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_team_player>
          <ControlsList
            :widget="widget"
            :controls="teamPlayerTitleControls"
          />
        </template>

        <template #event_score-design>
          <ControlsList
            :widget="widget"
            :controls="eventScoreDesignControls"
          />
        </template>

        <template #event_score-content>
          <ControlsList
            :widget="widget"
            :controls="eventScoreContentControls"
          />
        </template>

        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="eventLeagueControls"
          />
        </template>

        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>

        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #show_more_title-design>
          <ControlsList
            :widget="widget"
            :controls="showMoreDesignControls"
          />
        </template>

        <template #show_more_title-content>
          <ControlsList
            :widget="widget"
            :controls="showMoreContentControls"
          />
        </template>

        <template #show_more_title-states>
          <ControlsList
            :widget="widget"
            :controls="showMoreStateControls"
          />
        </template>

        <template #arrow-design>
          <ControlsList
            :widget="widget"
            :controls="arrowDesignControls"
          />
        </template>

        <template #arrow-content>
          <ControlsList
            :widget="widget"
            :controls="arrowContentControls"
          />
        </template>

        <template #arrow-states>
          <ControlsList
            :widget="widget"
            :controls="arrowStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useLiveBetsListConfig } from "~~/composables/widgets/live-bets-list/useLiveBetsListConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "fullNameActive": {
      label: "Full name active",
      sources: ["custom"],
      description: "Possible values: Yes | No",
      required: true,
    },
    "sportIds": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
    "maxEvents": {
      label: "",
      sources: ["custom"],
      required: true,
      isHidden: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  /*
      Controls
    */
  widgetDesignControls,
  widgetContentControls,
  widgetDataControls,

  headerDesignControls,
  headerContentControls,

  titleControls,

  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,

  eventCardsDesignControls,
  eventCardsContentControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventSuspendedDesignControls,
  eventSuspendedContentControls,
  eventTimeControls,
  eventInfoDesignControls,
  eventInfoContentControls,
  eventLeagueControls,
  teamPlayerTitleControls,
  eventScoreContentControls,
  eventScoreDesignControls,

  showMoreStateControls,
  showMoreDesignControls,
  showMoreContentControls,

  arrowDesignControls,
  arrowContentControls,
  arrowStatesControls,
} = useLiveBetsListConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
