import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { calculateDisableFieldsAllowed } from "~~/helpers/configs/disable-fields-allowed";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { IFields } from "../useWidgetFields";

export const useButtonsGroupControls = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields | null>,
  except?: string[]
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const buttonsGroupDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: selectedField.value,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const buttonsGroupContentControls = computed(() => {
    if (!fields.value) return [];

    const disableFieldsAllowed = calculateDisableFieldsAllowed(
      ["button_1", "button_2"],
      fields as Ref<IFields>
    );

    const items = [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distanceBetweenItems",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between buttons",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.button_1.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Button",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.button_1.active,
          showOptions: fields.value.button_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value!.button_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value!.button_1.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.button_2.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Button",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.button_2.active,
          showOptions: fields.value.button_2.active,
          "onUpdate:active": (value: boolean) => {
            fields.value!.button_2.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value!.button_2.field
            );
          },
        },
      },
    ];

    if (!except) {
      return items;
    }

    items[0].controls = items[0].controls!.filter(
      item => !except.includes(item.componentPath!)
    );

    return items.filter(item => !except.includes(item.componentPath!));
  });

  return {
    buttonsGroupDesignControls,
    buttonsGroupContentControls,
  };
};
