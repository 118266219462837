import {
  ICell,
  BlockType,
  NestingLevel,
  Sizing,
  ICellOptions,
} from "~~/models/grid.interface";
import { VerticalAlignment } from "~~/models/widgets/widget-controls.model";
import { preDefinedWidthValues } from "~~/constants/cell-params";

export const useWidgetCellStyling = () => {
  const getWidgetCellClasses = (
    cell: ICell,
    cellOptions: ICellOptions,
    isCellAsParentSelected: boolean,
    isCellAsChildSelected: boolean,
    isCellAsCellSelected: boolean
  ): { [key: string]: string | boolean } => {
    const cellSettings = cell.settings;

    const width = cellSettings.width;
    const sizing = cellSettings.sizing;
    const alignment = cellOptions?.alignment;
    const margin = cellOptions?.margin;

    const classesObj: Record<string, string | boolean> = {
      "widget-cell--root":
        cellSettings.blockType === BlockType.CONTAINER &&
        cellSettings.level === NestingLevel.PARENT,
      "widget-cell--column": cellSettings.blockType === BlockType.COLUMN,
      "widget-cell--column--active":
        cellSettings.blockType === BlockType.COLUMN && isCellAsCellSelected,
      "widget-cell--container": cellSettings.blockType === BlockType.CONTAINER,
      "widget-cell--has-space-between": Number(cellOptions?.spaceBetween) > 0,
      "widget-cell--has-margin-top": Number(margin?.top) > 0,
      "widget-cell--has-margin-bottom": Number(margin?.bottom) > 0,
      "widget-cell--active": isCellAsParentSelected || isCellAsChildSelected,
      "widget-cell--align-flex-start": alignment === VerticalAlignment.TOP,
      "widget-cell--align-flex-end": alignment === VerticalAlignment.BOTTOM,
      "widget-cell--align-center": alignment === VerticalAlignment.MIDDLE,
      "widget-cell--adaptive": sizing === Sizing.ADAPTIVE,
      "widget-cell--fixed": sizing === Sizing.FIXED,
    };

    if (width && preDefinedWidthValues.includes(width)) {
      if (sizing === Sizing.ADAPTIVE) {
        classesObj[`w-pct-${width}`] = true;
      } else {
        classesObj[`w-px-${width}`] = true;
      }
    }

    return classesObj;
  };

  const getWidgetCellStyle = (
    cell: ICell
  ): Record<string, string | undefined> => {
    const cellSettings = cell.settings;

    if (
      cellSettings.width &&
      preDefinedWidthValues.includes(cellSettings.width)
    ) {
      return {};
    }

    const styles =
      cellSettings.sizing === Sizing.ADAPTIVE
        ? { flex: `0 1 ${cellSettings.width}%` }
        : { width: `${cellSettings.width}px` };

    return styles;
  };

  return {
    getWidgetCellClasses,
    getWidgetCellStyle,
  };
};
