<template>
  <div
    class="grid-settings-sidebar"
    :class="{
      'grid-settings-sidebar--layout-view': activeStep === Step.LAYOUTS,
    }"
  >
    <div v-if="selectedCell">
      <header class="grid-settings-sidebar__header heading">
        {{
          selectedCell.settings.level === NestingLevel.PARENT
            ? "Parent container"
            : "Child container"
        }}
      </header>
      <section>
        <SidebarSettingsWidth />
      </section>
      <section>
        <SidebarSettingsColumns />
      </section>
    </div>
    <div
      v-else
      class="grid-settings-sidebar__empty"
    >
      <template v-if="isMobileTemplateEmpty">
        <p>The template with empty grid can not be saved</p>
      </template>

      <template v-else>
        <p class="grid-settings-sidebar__cursor">
          <CommonIcon name="fa:hand-pointer-o" />
        </p>
        <p
          v-translate:grid="
            'Select an element on the canvas to activate this panel'
          "
        ></p>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { NestingLevel } from "~~/models/grid.interface";
import { Step } from "~~/models/step.enum";

const gridStore = useGridConfig();
const uiStore = useUiStore();

const { activeStep } = storeToRefs(uiStore);

const { selectedCell, isMobileTemplateEmpty } = storeToRefs(gridStore);
</script>

<style lang="scss">
.grid-settings-sidebar {
  @include flex-column(flex-start, flex-start, 0);
  position: fixed;
  top: ($header-height + $space-lg);
  right: 0;
  height: calc(100vh - $header-height);
  width: $settings-sidebar-width;
  background-color: $c-white;
  border-left: 1px solid $c-grey-15;
  overflow-y: auto;
  z-index: 1;

  &--layout-view {
    top: $header-height;
  }

  &__header {
    color: $c-black;
    padding: $space-m $space-m 0;
  }

  &__empty {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $c-black;
    padding: 72px 16px;
  }

  &__cursor {
    margin-bottom: calc($space-xs + $space-s);
  }
}
</style>
