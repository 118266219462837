import type { Ref } from "vue";

import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  isLive: boolean,
  isRacing: boolean | undefined,
  isFenix: boolean | undefined
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [];

  const showMenuItemsArrowSettings = computed<boolean>(() => {
    return !(
      (isLive && currentField.value?.name === "menu_level_2") ||
      currentField.value?.name === "menu_level_3"
    );
  });

  const showItemsArrowSettings = computed<boolean>(() => {
    if (isRacing && currentField.value?.name === "menu_level_2") {
      return false;
    }

    if (isFenix && currentField.value?.name === "menu_level_3") {
      return false;
    }

    return true;
  });

  const showItemsIconSettings = computed<boolean>(() => {
    if (isFenix && currentField.value?.name !== "menu_level_1") {
      return false;
    }

    return true;
  });

  if (isLive) {
    DROPDOWN_ITEMS.push(
      generateDropdownItem("Title", "title"),
      generateDropdownItem("Sport items", "menu_level_1"),
      generateDropdownItem("League items", "menu_level_2")
    );

    if (isFenix) {
      DROPDOWN_ITEMS.push(
        generateDropdownItem("Events", "event_container"),
        generateDropdownItem("Sport event cards", "event_card"),
        generateDropdownItem("Header", "header"),
        generateDropdownItem("Footer", "footer"),
        generateDropdownItem("Favorite Icon", "favourites_button"),
        generateDropdownItem("League Name", "event_league"),
        generateDropdownItem("Status", "event_suspended_status"),
        generateDropdownItem("Stream Icon", "stream_icon"),
        generateDropdownItem("Event Name", "event_team_player"),
        generateDropdownItem("Time", "event_time"),
        generateDropdownItem("Period", "event_period"),
        generateDropdownItem("Score", "event_score"),
        generateDropdownItem("Racing event cards ", "racing_event_card"),
        generateDropdownItem("Stream Icon", "racing_stream_icon"),
        generateDropdownItem("Event Name", "racing_event_name"),
        generateDropdownItem("Race Status", "racing_event_status"),
        generateDropdownItem("Favorite Icon", "racing_favourites_icon"),
        generateDropdownItem("Timer", "racing_event_timer")
      );
    }
  } else {
    DROPDOWN_ITEMS.push(
      generateDropdownItem("Title", "title"),
      generateDropdownItem("Sport items", "menu_level_1"),
      generateDropdownItem("Country items", "menu_level_2"),
      generateDropdownItem("League items", "menu_level_3")
    );
  }

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    menu_level_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    menu_level_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  } as Record<string, TabItem[]>;

  if (isLive) {
    if (isFenix) {
      TABS.event_container = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];

      TABS.event_card = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
        generateTabItem("States", "states"),
      ];

      TABS.header = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];

      TABS.footer = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];

      TABS.event_suspended_status = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];

      TABS.event_score = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];

      TABS.racing_event_card = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
        generateTabItem("States", "states"),
      ];

      TABS.racing_event_status = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];
    }
  } else {
    TABS.menu_level_3 = [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ];
  }

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigCornerRadius"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const configItems = computed<ConfigTabItem[]>(() => {
    if (isLive && isFenix) {
      return [
        {
          label: "Sport items",
          value: "menu_level_1",
        },
        {
          label: "League items",
          value: "menu_level_2",
          isActiveValueSource: fields.value.menu_level_2.field.options,
        },
        {
          label: "Events",
          value: "event_container",
          isActiveValueSource: fields.value.event_container.field.options,
        },
      ];
    }

    if (isLive) {
      return [
        {
          label: "Sport items",
          value: "menu_level_1",
        },
        {
          label: "League items",
          value: "menu_level_2",
        },
      ];
    }

    if (isFenix) {
      return [
        {
          label: "Sport items",
          value: "menu_level_1",
        },
        {
          label: "Contry items",
          value: "menu_level_2",
          isActiveValueSource: fields.value.menu_level_2.field.options,
        },
        {
          label: "League items",
          value: "menu_level_3",
          isActiveValueSource: fields.value.menu_level_3.field.options,
        },
      ];
    }

    return [
      {
        label: "Sport items",
        value: "menu_level_1",
      },
      {
        label: "Contry items",
        value: "menu_level_2",
      },
      {
        label: "League items",
        value: "menu_level_3",
      },
    ];
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title?.field,
        visible: !!fields.value.title,
        options: {
          placeholder: "Enter button text",
          label: isLive && isFenix ? "Title" : "Text",
          allowDisable: true,
          active: fields.value.title?.field.options._active,
          showOptions: fields.value.title?.field.options._active,
          "onUpdate:active": (value: boolean) => {
            widgetsStore.updateFieldOptionValue(
              value,
              "_active",
              fields.value.title?.field.id
            );
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title?.field
            );
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: configItems.value,
        },
      },
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.title.field,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: fields.value.title.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
  menu_level_1
  menu_level_2
  menu_level_3
  */

  const currentField = computed<IWidgetField | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return fields.value?.[selectedField.value.name].field;
  });

  const fenixMenuItemContentDisplayModeControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              label: "Items layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between items",
            },
          },
        ],
      },
    ];

    if (currentField.value?.name === "menu_level_1") {
      result[0].controls.push(
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.elementsDisplayMode",
          valueSource: currentField.value,
          className: "group-control-element",
          options: {
            label: "Items elements layout",
            items: [
              {
                label: "Vertical",
                value: "vertical",
              },
              {
                label: "Horizontal",
                value: "horizontal",
              },
            ],
          },
        },
        {
          componentPath: "ConfigVerticalSpace",
          valuePath: "options.itemIcon.spaceBetween",
          valueSource: currentField.value,
          className: "group-control-element",
          options: {
            placeholder: "None",
            label: "Distance between item elements",
          },
        }
      );
    }

    if (currentField.value?.name === "menu_level_2") {
      result[0].controls.push({
        componentPath: "ConfigVerticalSpace",
        valuePath: "options.itemIcon.spaceBetween",
        className: "group-control-element",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Distance between item elements",
        },
      });
    }

    return result;
  });

  const menuItemDesignControls = computed(() => {
    const result = [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      isFenix && isLive && currentField.value?.name === "event_container"
        ? {
            componentPath: "ConfigSpacingInputs",
            valuePath: "options.spacing",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Spacing",
            },
          }
        : {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: currentField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
    ];

    if (!isFenix) {
      result.push({
        componentPath: "ConfigVerticalSpace",
        valuePath: "options.verticalSpace",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Distance between items",
          isBold: true,
        },
      });
    }

    return result;
  });

  const menuItemContentControls = computed(() => {
    if (isFenix && isLive && currentField.value?.name === "event_container") {
      return [
        {
          section: generateDesignSectionName("Display mode"),
          controls: [
            {
              componentPath: "common/ConfigRadioIcon",
              valuePath: "options.columns",
              valueSource: currentField.value,
              className: "event-cards-radio",
              options: {
                label: "Event cards layout",
                items: [
                  {
                    icon: "custom:layout-column-1",
                    value: 1,
                  },
                  {
                    icon: "custom:layout-column-2",
                    value: 2,
                  },
                  {
                    icon: "custom:layout-column-3",
                    value: 3,
                  },
                ],
              },
            },
            {
              componentPath: "common/ConfigSpacing",
              valuePath: "options.distance",
              valueSource: currentField.value,
              options: {
                label: "Distance between cards",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              {
                label: "Sport event cards",
                value: "event_card",
              },
              {
                label: "Racing event cards",
                value: "racing_event_card",
              },
            ],
          },
        },
      ];
    }

    const result = [
      typography(
        {
          source: currentField.value,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];

    if (isFenix) {
      result.unshift(
        ...getInitialContentControlsList({
          source: currentField.value,
        }),
        ...fenixMenuItemContentDisplayModeControls.value
      );
    }

    if (!showItemsIconSettings.value) {
      result.push({
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrowSettings",
        valueSource: currentField.value,
        visible: showMenuItemsArrowSettings.value,
        options: {
          label: "Color",
          isBold: true,
          allowDisable: true,
          showOptions: false,
        },
      });

      return result;
    }

    if (isFenix && currentField.value?.name === "menu_level_1") {
      result.push({
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.itemIcon._active",
            valueSource: currentField.value,
            className: "p-l-16 p-t-16 p-r-16",
            options: {
              placeholder: "None",
              label: "Icon",
              isBold: true,
              size: "small",
              type: ColorPickerType.BACKGROUND,
              showOptions: false,
              allowDisable: true,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.itemIcon.color",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              placeholder: "None",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.itemIcon.size",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 64,
            },
          },
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrowSettings",
            valueSource: currentField.value,
            visible: showMenuItemsArrowSettings.value,
            options: {
              label: "Fill",
              isBold: true,
              allowDisable: true,
              showOptions: false,
            },
          },
        ],
      });

      return result;
    }

    if (showItemsArrowSettings.value) {
      result.push({
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.itemIcon._active",
            valueSource: currentField.value,
            className: "p-l-16 p-t-16 p-r-16",
            options: {
              placeholder: "None",
              label: "Icon",
              isBold: true,
              size: "small",
              type: ColorPickerType.BACKGROUND,
              showOptions: false,
              allowDisable: true,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.itemIcon.color",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              placeholder: "None",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.itemIcon.size",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 64,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            className: "p-l-16 p-r-16",
            valuePath: "options.itemIcon.spaceBetween",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              label: "Spacing between text and icon",
              minValue: 4,
              maxValue: 20,
            },
          },
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrowSettings",
            valueSource: currentField.value,
            visible: showMenuItemsArrowSettings.value,
            options: {
              label: "Fill",
              isBold: true,
              allowDisable: true,
              showOptions: false,
            },
          },
        ],
      });
    }

    return result;
  });

  const currentStatePath = computed(() => {
    if (!selectedField.value) {
      return "";
    }

    const currState = states.value?.[selectedField.value.name];

    if (currState === State.DEFAULT) {
      return `options`;
    }

    return `options.states.${currState}`;
  });

  const stateValue = computed<State | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return states.value[selectedField.value.name];
  });

  const arrowStateControls = computed(() => {
    if (isLive) {
      if (currentField.value?.name !== "menu_level_2") {
        return [
          {
            section: generateDesignSectionName("Arrow"),
            controls: [
              {
                componentPath: "ConfigColorPickerInput",
                valuePath: `${currentStatePath.value}.arrowSettings.textColor`,
                valueSource: currentField.value,
                visible: currentField.value?.name !== "menu_level_3",
                options: {
                  label: "Arrow color",
                  type: ColorPickerType.TEXT,
                },
              },
            ],
          },
        ];
      }

      return [];
    }

    if (currentField.value?.name !== "menu_level_3") {
      return [
        {
          section: generateDesignSectionName("Arrow"),
          controls: [
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${currentStatePath.value}.arrowSettings.textColor`,
              valueSource: currentField.value,
              visible: currentField.value?.name !== "menu_level_3",
              options: {
                label: "Arrow color",
                type: ColorPickerType.TEXT,
              },
            },
          ],
        },
      ];
    }

    return [];
  });

  const menuItemStatesControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateValue.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value[selectedField.value!.name] = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: currentField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: currentField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: currentField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.color`,
            valueSource: currentField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentStatePath.value}.decoration`,
            valueSource: currentField.value,
            options: {
              label: "Decoration",
            },
          },
        ],
      },
    ];

    if (showItemsIconSettings.value) {
      result.push(
        {
          section: generateDesignSectionName("Icon"),
          controls: [
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${currentStatePath.value}.itemIcon.color`,
              valueSource: currentField.value,
              options: {
                label: "Icon color",
                type: ColorPickerType.TEXT,
              },
            },
          ],
        },
        ...arrowStateControls.value
      );
    }
    return result;
  });

  const sportEventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const sportEventCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layoutMode",
            valueSource: currentField.value,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: SportMenuLiveBet9CardLayoutMode.FIRST,
                },
                {
                  label: "Second",
                  value: SportMenuLiveBet9CardLayoutMode.SECOND,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header",
              value: "header",
            },
            {
              label: "Footer",
              value: "footer",
            },
          ],
        },
      },
    ];
  });

  const sportEventCardsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: stateValue.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      section: generateDesignSectionName("Card"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: currentField.value,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: currentField.value,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentStatePath.value}.shadow`,
          valueSource: currentField.value,
          options: {
            label: "Shadow",
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Header"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.header.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Footer"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.footer.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("League"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_league.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Status"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Stream icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.stream_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Event Name"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_team_player.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Time"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_time.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.icon.color`,
          valueSource: fields.value.event_time.field,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Period"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_period.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Favorite icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.favourites_button.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Score"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.event_score.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_score.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const headerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const isEventCardLayoutFirstMode = computed(
    () =>
      fields.value.event_card.field.options.layoutMode ===
      SportMenuLiveBet9CardLayoutMode.FIRST
  );

  const isEventCardHeaderVertical = computed(
    () =>
      fields.value.header.field.options.layout === DisplayOrientation.VERTICAL
  );

  const headerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: currentField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: `options.leagueName${
              isEventCardHeaderVertical.value ? "Vertical" : "Horizontal"
            }Position`,
            valueSource: currentField.value,
            options: {
              label: "League name position",
              items: isEventCardHeaderVertical.value
                ? [
                    {
                      label: "Top",
                      value: "top",
                    },
                    {
                      label: "Bottom",
                      value: "bottom",
                    },
                  ]
                : [
                    {
                      label: "Left",
                      value: "left",
                    },
                    {
                      label: "Right",
                      value: "right",
                    },
                  ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.favoriteIconAlignment",
            valueSource: currentField.value,
            options: {
              label: "Favorite icon alignment",
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League Name",
              value: "event_league",
              isActiveValueSource: fields.value.event_league.field.options,
            },
            {
              label: "Favorite icon",
              value: "favourites_button",
              isActiveValueSource: fields.value.favourites_button.field.options,
            },
            {
              label: "Stream Icon",
              value: "stream_icon",
              isActiveValueSource: fields.value.stream_icon.field.options,
            },
            ...(isEventCardLayoutFirstMode.value
              ? [
                  {
                    label: "Status",
                    value: "event_suspended_status",
                  },
                  {
                    label: "Event name",
                    value: "event_team_player",
                  },
                ]
              : [
                  {
                    label: "Period",
                    value: "event_period",
                    isActiveValueSource:
                      fields.value.event_period.field.options,
                  },
                  {
                    label: "Time",
                    value: "event_time",
                  },
                  {
                    label: "Status",
                    value: "event_suspended_status",
                  },
                ]),
          ],
        },
      },
    ];
  });

  const footerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const footerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: currentField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            ...(isEventCardLayoutFirstMode.value
              ? [
                  {
                    label: "Start Time",
                    value: "event_time",
                  },
                  {
                    label: "Period",
                    value: "event_period",
                    isActiveValueSource:
                      fields.value.event_period.field.options,
                  },
                ]
              : [
                  {
                    label: "Event Name",
                    value: "event_team_player",
                  },
                ]),

            {
              label: "Score",
              value: "event_score",
              isActiveValueSource: fields.value.event_score.field.options,
            },
          ],
        },
      },
    ];
  });

  const favIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.favourites_button.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: fields.value.favourites_button.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const leagueNameControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: "fill",
                },
                {
                  label: "Hug content",
                  value: "hug",
                },
              ],
            },
          },
        ],
      },
      typography({
        source: currentField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventSuspendedStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: currentField.value,
      except: ["ConfigBoxShadow"],
    });
  });

  const eventSuspendedStatusContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_suspended_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const streamIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const eventNameControls = computed(() => [
    typography({ source: currentField.value }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: currentField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const eventTimeControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.icon._active",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Icon",
              allowDisable: true,
            },
          },
          ...(currentField.value?.options.icon._active
            ? [
                {
                  componentPath: "ConfigIconPicker",
                  valuePath: "options.icon.value",
                  className: "p-l-16 p-r-16 p-t-16",
                  valueSource: currentField.value,
                },

                {
                  componentPath: "ConfigColorPickerInput",
                  valuePath: "options.icon.color",
                  valueSource: currentField.value,
                  options: {
                    placeholder: "Enter text",
                    label: "Icon color",
                    type: ColorPickerType.TEXT,
                  },
                },

                {
                  componentPath: "common/ConfigRangeSlider",
                  valuePath: "options.icon.size",
                  className: "p-l-16 p-r-16 p-t-16",
                  valueSource: currentField.value,
                  options: {
                    label: "Size",
                    minValue: MIN_ICON_SIZE_INPUT_VALUE,
                    maxValue: MAX_ICON_SIZE_INPUT_VALUE,
                  },
                },
              ]
            : []),
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventPeriodControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: "fill",
                },
                {
                  label: "Hug content",
                  value: "hug",
                },
              ],
            },
          },
        ],
      },
      typography({
        source: currentField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventScoreDesignControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: currentField.value,
        except: ["ConfigBoxShadow"],
      }),
    ];
  });

  const eventScoreContentControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const racingEventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const racingEventCardsContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Stream Icon",
              value: "racing_stream_icon",
              isActiveValueSource:
                fields.value.racing_stream_icon.field.options,
            },
            {
              label: "Event Name",
              value: "racing_event_name",
            },
            {
              label: "Race Status",
              value: "racing_event_status",
            },
            {
              label: "Timer",
              value: "racing_event_timer",
            },
            {
              label: "Favorite icon",
              value: "racing_favourites_icon",
              isActiveValueSource:
                fields.value.racing_favourites_icon.field.options,
            },
          ],
        },
      },
    ];
  });

  const racingEventCardsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: stateValue.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      section: generateDesignSectionName("Card"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: currentField.value,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: currentField.value,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentStatePath.value}.shadow`,
          valueSource: currentField.value,
          options: {
            label: "Shadow",
            isBold: false,
          },
        },
      ],
    },

    {
      section: generateDesignSectionName("Status"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Stream icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_stream_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Event Name"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_event_name.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Favorite icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_favourites_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const racingStreamIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const racingEventNameControls = computed(() => [
    typography({ source: currentField.value }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: currentField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const racingEventStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: currentField.value,
      except: ["ConfigBoxShadow"],
    });
  });

  const racingEventStatusContentControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const racingFavIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const racingEventTimerControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    menuItemDesignControls,
    menuItemContentControls,
    menuItemStatesControls,

    sportEventCardsDesignControls,
    sportEventCardsContentControls,
    sportEventCardsStatesControls,

    headerDesignControls,
    headerContentControls,

    footerDesignControls,
    footerContentControls,

    favIconControls,

    leagueNameControls,

    eventSuspendedStatusDesignControls,
    eventSuspendedStatusContentControls,

    streamIconControls,

    eventNameControls,

    eventTimeControls,

    eventPeriodControls,

    eventScoreDesignControls,
    eventScoreContentControls,

    racingEventCardsDesignControls,
    racingEventCardsContentControls,
    racingEventCardsStatesControls,

    racingStreamIconControls,

    racingEventNameControls,

    racingEventStatusDesignControls,
    racingEventStatusContentControls,

    racingFavIconControls,

    racingEventTimerControls,
  };
};
