<template>
  <BaseWidgetNew
    ignore-style-states
    :widget="widget"
    :cell="cell"
    :current-state="activeView"
    :non-state-options="['fillImageColor']"
    :is-selected="isWholeWidgetSelected"
  >
    <div class="basic-withdrawal-list-widget">
      <DepositListWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
        :active-view="activeView"
        :screens="SCREENS"
        :show="!shouldShowFormSettings"
        :states="states"
        @update-active-view="handleUpdateActiveView"
        @update-state="handleStateUpdate"
        @reset-states="resetStates"
      />
      <div v-if="activeView === DepositListScreen.DATA_FILLED">
        <div class="basic-withdrawal-list-widget__fields-wrapper">
          <div class="basic-withdrawal-list-widget__field-element">
            <WidgetField
              v-if="fields.title.active"
              :widget="widget"
              :field="fields.title.field"
              class="basic-withdrawal-list-widget__field"
            >
              <BasicTitleField
                :field="fields.title.field"
                :state="states.title"
              />
            </WidgetField>

            <WidgetField
              v-if="fields.update_profile_text.active"
              :widget="widget"
              :field="fields.update_profile_text.field"
              class="basic-withdrawal-list-widget__field"
            >
              <BasicDescriptionField
                ref="descriptionField"
                :widget="widget"
                :fields="descriptionFields"
              />
            </WidgetField>
          </div>

          <WidgetField
            :widget="widget"
            :field="fields.providers_item.field"
            class="basic-withdrawal-list-widget__provider-cards-field"
            :style="cardsListContainerStyles"
          >
            <ProvidersList
              :widget="widget"
              :layout-position="layoutPosition"
              :card-item-style="cardItemStyle"
              :fields="fields"
              :cards-title-inline-styles="cardsTitleInlineStyles"
              :tooltip-styles="tooltipStyles"
              :tooltip-icon-component="tooltipIconComponent"
              :tooltip-icon-color-style="tooltipIconColorStyle"
              :cards-button-state="states.providers_item_button"
              :selected-dropdown-item="selectedDropdownItem"
              :cards-title-link-selected-state="states.providers_item_title"
              :provider-button-text="
                t('widgets.WithdrawalListWidget.providers_item_button')
              "
            />
          </WidgetField>
        </div>
      </div>
      <div
        v-else
        class="basic-withdrawal-list-widget__payment-method-page-view"
        :style="paymnetMethodPageInlineStyles"
      >
        <WidgetField
          v-if="
            fields.form_back_button.active &&
            activeView === DepositListScreen.PAYMENT_METHOD
          "
          :widget="widget"
          :field="fields.form_back_button.field"
          class="basic-withdrawal-list-widget__payment-method-page-view--element"
        >
          <BasicButtonField
            :field="fields.form_back_button.field"
            :current-state="states.form_back_button"
            :custom-value="t('widgets.WithdrawalListWidget.form_back_button')"
          />
        </WidgetField>

        <WidgetField
          v-if="
            fields.provider_card.active &&
            activeView === DepositListScreen.PAYMENT_METHOD
          "
          :widget="widget"
          :field="fields.provider_card.field"
          class="basic-withdrawal-list-widget__payment-method-page-view--element"
        >
          <FormCardField
            v-if="activeView === DepositListScreen.PAYMENT_METHOD"
            :widget="widget"
            :field="fields.provider_card.field"
            :card-state="states.provider_card"
            :cell="cell"
            class="basic-withdrawal-list-widget__payment-method-page-view--element"
          />
        </WidgetField>

        <CommonFormWidget
          ref="commonFormWidget"
          :widget="widget"
          :states="currentStates"
          :screens="SCREENS"
          :custom-get-form-elements="customGetFormElements"
          :active-view="activeView"
          :show-form-settings="shouldShowFormSettings"
          :custom-form-name="'paymentMethodPage'"
          :custom-filtered-fields="depositListFilteredFields"
          class="basic-withdrawal-list-widget__payment-method-page-view--element"
          @update-active-view="handleUpdateActiveView"
          @update-button-state="handleUpdateButtonState"
          @reset-states="handleResetStates"
        />
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DepositListScreen } from "~~/models/widgets/form.model";
import { useDepositListForm } from "~~/composables/widgets/deposit-list/useDepositListForm";
import { useDepositListSharedLogic } from "~~/composables/widgets/deposit-list/useDepositListWidgetSharedLogic";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { t } = useI18n();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget, selectedDropdownItem } =
  storeToRefs(widgetSettingsStore);

const activeView = ref("dataFilledPage");
const SCREENS = [
  DepositListScreen.DATA_FILLED,
  DepositListScreen.PAYMENT_METHOD,
  DepositListScreen.SUCCESS,
  DepositListScreen.FAIL,
];
const commonFormWidget = ref(null);

const {
  currentStates,
  isWholeWidgetSelected,
  handleUpdateButtonState,
  handleResetStates,
} = useDepositListForm(ref(props.widget), SCREENS);

const {
  shouldShowFormSettings,
  handleUpdateActiveView,
  fields,
  cardsListContainerStyles,
  cardItemStyle,
  layoutPosition,
  cardsTitleInlineStyles,
  tooltipStyles,
  tooltipIconComponent,
  tooltipIconColorStyle,
  customGetFormElements,
  depositListFilteredFields,
  paymnetMethodPageInlineStyles,
  handleStateUpdate,
  resetStates,
  states,
} = useDepositListSharedLogic(ref(props.widget), activeView);

const descriptionFields = computed(() => {
  return props.widget.fields.filter(field =>
    field.name.includes("update_profile_text")
  );
});
</script>

<style lang="scss">
.basic-withdrawal-list-widget {
  &__fields-wrapper {
    @include flex-column(flex-start, flex-start);
  }

  &__field-element {
    width: 100%;
  }

  &__provider-cards-field {
    width: 100%;
    @include flex(center, center, 0px, true);
  }

  &__payment-method-page-view {
    @include flex-column(unset, unset, 24px);
  }
}
</style>
