<script lang="ts" setup>
//@ts-ignore
import Papa from "papaparse";
import { useNotification } from "@kyvg/vue3-notification";
import isObject from "lodash/isObject";

import { OSG_KEY_START, VITE_DASHBOARD_BASE_URL } from "~~/constants";
import { useMetaStore } from "~~/store/meta";
import { useGridConfig } from "~~/store/grid";
import { usePagesStore } from "~~/store/pages";
import { useLanguagesStore } from "~~/store/languages";
import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { downloadCsv } from "~~/helpers/downloadCsv";
import { configAppApi } from "~~/services/http";
import { useGlobalPages } from "~~/composables/widgets-config/useGlobalPages";
import { useFormErrors } from "~~/composables/useFormErrors";

import { useWidgetContentChanges } from "../composables/useWidgetContentChanges";

interface IOsgTranslationsData {
  keyName: string;
  translation: string;
  foundIn: string[];
}

interface FormUploadState {
  filename: string;
  data: IOsgTranslationsData[];
}
const strategy = defineModel<boolean>("strategy", {
  required: true,
});

const loading = defineModel<boolean>("loading");

const { isActive } = defineProps<{
  isActive: boolean;
}>();

const globalChangesActive = computed(() => isActive);
const {
  pagesList,
  pagesContentData,

  findPageIdByWidget,
  fetchAllPages,
  fetchPagesContent,
} = useGlobalPages(globalChangesActive);

const pagesStore = usePagesStore();
const metaStore = useMetaStore();
const gridStore = useGridConfig();
const widgetChanges = useWidgetContentChanges();
const notification = useNotification();
const { errors, parseError, resetErrors } = useFormErrors();

const notTranslatedReports = ref<{ value: string; text: string }[]>([]);
const formUploadState = reactive<FormUploadState>({
  filename: "",
  data: [],
});

async function getNotTranslatedReports() {
  const { data } = await configAppApi.get<string[]>("/reports/not-translated");

  notTranslatedReports.value = data.map(item => ({
    value: item,
    text: item,
  }));
}

const formUploadSubmit = async () => {
  loading.value = true;
  resetErrors();

  formUploadState.filename = formUploadState.filename
    .trim()
    .replaceAll(" ", "_");

  try {
    await configAppApi.post("/reports/not-translated/file", formUploadState);

    formUploadState.data = [];

    notification.notify({
      text: "Not translated report file uploaded successfully.",
      type: "success",
    });
  } catch (err: any) {
    parseError(err);
  } finally {
    await getNotTranslatedReports();
    loading.value = false;
  }
};

function getNotTranslatedOnDefaultLang(value: string | Record<string, string>) {
  const defaultLanguageCode =
    useLanguagesStore().defaultLanguage?.codeWithRegion;

  if (!defaultLanguageCode) {
    throw new Error("Default language is not defined.");
  }

  return isObject(value) ? value[defaultLanguageCode] : value;
}

async function getNoTranslationStringsByStrategy() {
  try {
    if (strategy.value) {
      // await processNotTranslatedStringsGlobal();
      await processNotTranslatedStringsGlobalPages();
    } else {
      await processNotTranslatedStrings(gridStore.pageWidgets);
    }
  } catch (error) {
    resetFormData("Something went wrong... Reason:" + error);
  }
}

// Get all data from all pages and make uniq list with no-translations
const processNotTranslatedStringsGlobalPages = async () => {
  loading.value = true;

  const globalStatus = await metaStore.fetchGlobalStatus();
  if (globalStatus) {
    resetFormData(
      "There is already active global process in project. Please try again later."
    );
    return;
  }

  metaStore.updateGlobalStatus(true);
  metaStore.runUpdateWorkingStatusInterval();

  try {
    await fetchAllPages(true);
    await fetchPagesContent();
  } catch (error: any) {
    resetFormData("Global fetching fails. Reason: " + error.message);
    return;
  }

  const allWidgets = Object.values(pagesContentData.value).reduce(
    (acc: IPageContentWidget[], pageData) => {
      for (const cellId in pageData.pageContent) {
        acc.push(...pageData.pageContent[cellId].widgets);
      }
      return acc;
    },
    []
  );

  await processNotTranslatedStrings(allWidgets);

  metaStore.updateGlobalStatus(false);
  metaStore.stopWorkingStatusInterval();
};

// Get all data from all files from config-app and make uniq list with no-translations
// const processNotTranslatedStringsGlobalCfg = async () => {
//   loading.value = true;
//   const result = [] as IOsgTranslationsParseResult[];
//   for (const filenameObj of notTranslatedReports.value) {
//     const { data: report } = await configAppApi.get<
//       IOsgTranslationsParseResult[]
//     >("/not-translated-report-file", {
//       params: {
//         filename: filenameObj.value,
//         projectId: formUploadState.projectId,
//       },
//     });
//     result.push(...report);
//   }
//   loading.value = false;
//   const preparingForSet = result.map(item => JSON.stringify(item));
//   formUploadState.data = [...new Set(preparingForSet)].map(item =>
//     JSON.parse(item)
//   );
// };

const processNotTranslatedStrings = async (
  widgets: IWidgetWithFields[] | IPageContentWidget[]
) => {
  type NotTranslatedType = {
    [
      translation: IOsgTranslationsData["translation"]
    ]: IOsgTranslationsData["foundIn"];
  };

  const result: NotTranslatedType = {};

  for (const widget of widgets) {
    const pageId = findPageIdByWidget(widget) ?? pagesStore.selectedPageId;

    widgetChanges.processWidgetTextContent(
      widget,
      localizedValue => {
        const translation = String(
          getNotTranslatedOnDefaultLang(localizedValue)
        ).trim();
        // TODO - maybe Object.prototype.toString.call(translation).slice(8, -1)
        const skipped = [
          "undefined",
          "null",
          "[object Object]",
          "[]",
          "",
        ].includes(translation);
        // If not a osg key and to skipped
        if (!skipped && !translation.startsWith(OSG_KEY_START)) {
          result[translation] = (result[translation] || []).concat(
            `${pageId}:${widget.id}`
          );
        }
      },
      {
        skipDisabled: true,
        skipDefault: true,
        skipHtml: true,
      }
    );
  }

  formUploadState.data = Object.keys(result).reduce((acc, translation) => {
    acc.push({
      keyName: "",
      translation,
      foundIn: result[translation],
    });

    return acc;
  }, [] as IOsgTranslationsData[]);

  resetFormData("Operation completed successfully!", "success");
};

async function downloadSelectedReport() {
  loading.value = true;

  try {
    const { data: selectedReportContent } = await configAppApi.get<
      IOsgTranslationsData[]
    >("/reports/not-translated/file", {
      params: {
        filename: formUploadState.filename.trim().replaceAll(" ", "_"),
      },
    });

    const csvContent = Papa.unparse(selectedReportContent, {
      columns: ["keyName", "translation"],
    });

    downloadCsv(
      csvContent,
      `${formUploadState.filename}__${new Date().toLocaleDateString()}.csv`
    );

    notification.notify({
      text: "Report file successfully downloaded.",
      type: "success",
    });
  } catch (error: any) {
    console.error(error);
    notification.notify({
      text: "Fail to download report file. Reason: " + error.message,
      type: "error",
    });
  } finally {
    loading.value = false;
  }
}

function prepareWidgetLink(data: string): string {
  const dataArr = data.split(":");

  if (dataArr.length === 2) {
    return `${VITE_DASHBOARD_BASE_URL}/pages/${dataArr[0]}/setup?active_widget=${dataArr[1]}`;
  }
  return "";
}

const resetValidation = (field: string) => {
  delete errors.value[field];
};

function resetFormData(message?: string, messageType = "error") {
  if (message) {
    console.debug(message);
    notification.notify({
      text: message,
      type: messageType,
    });
  }
  loading.value = false;
}

watch(strategy, () => {
  formUploadState.data = [];
});

watch(
  () => isActive,
  async newValue => {
    if (newValue) {
      await getNotTranslatedReports();
    }
  },
  { immediate: true }
);

watch(
  () => isActive,
  newValue => {
    if (!newValue) {
      pagesContentData.value = {};
      pagesList.value = [];
      metaStore.updateGlobalStatus(false);
      metaStore.stopWorkingStatusInterval();
    }
  }
);
</script>

<template>
  <a-form-item>
    <a-space nowrap>
      <a-switch
        v-model:checked="strategy"
        checked-children="Global"
        un-checked-children="Current"
        :disabled="loading"
      />
      <a-button
        class="config__action-button"
        type="primary"
        html-type="button"
        :disabled="loading"
        @click="getNoTranslationStringsByStrategy"
      >
        Get not translated strings
      </a-button>
    </a-space>
  </a-form-item>
  <a-form-item>
    <a-table
      :data-source="formUploadState.data"
      size="small"
      :scroll="{ x: 1000, y: 900 }"
    >
      <a-table-column
        key="keyName"
        title="Key name"
        data-index="keyName"
      >
        <template #default>
          <a-typography-text type="secondary">Empty</a-typography-text>
        </template>
      </a-table-column>

      <a-table-column
        key="translation"
        title="Translation"
        data-index="translation"
      >
        <template #default="{ text: translation }">
          <a-typography-text ellipsis>{{ translation }}</a-typography-text>
        </template>
      </a-table-column>

      <a-table-column
        v-if="strategy"
        key="foundIn"
        title="Found in"
        data-index="foundIn"
      >
        <template #default="{ text: foundIn }">
          <a
            v-for="(ids, index) in foundIn"
            :key="ids"
            :href="prepareWidgetLink(ids)"
            target="_blank"
          >
            {{ "Widget " }}{{ index + 1 }};
          </a>
        </template>
      </a-table-column>
    </a-table>
  </a-form-item>

  <a-form
    name="osg_form_upload"
    :model="formUploadState"
    layout="vertical"
    @finish="formUploadSubmit"
  >
    <a-form-item
      label="File name"
      name="filename"
      :rules="[{ required: true, message: 'Field is required!' }]"
      :validate-status="errors.filename ? 'error' : undefined"
      :help="errors.filename"
    >
      <a-auto-complete
        v-model:value="formUploadState.filename"
        :options="notTranslatedReports"
        @input="resetValidation('filename')"
      />
      <a-typography-text type="secondary"
        ><small
          >NOTE! File must be uploaded to the server before downloading</small
        ></a-typography-text
      >
    </a-form-item>

    <a-form-item>
      <a-space wrap>
        <a-button
          class="config__action-button"
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          Upload report to server
        </a-button>

        <a-button
          class="config__action-button"
          html-type="button"
          :loading="loading"
          :disabled="!formUploadState.filename"
          @click="downloadSelectedReport"
        >
          <template #icon>
            <DownloadOutlined />
          </template>
          Download report
        </a-button>
      </a-space>
    </a-form-item>
  </a-form>
</template>
