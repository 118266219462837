<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="currentDropdownItems"
        :tabs="currentTabs"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #header_tabs_container-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header_tabs_container-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #header_tabs_container-states>
          <ControlsList
            :widget="widget"
            :controls="headerStatesControls"
          />
        </template>

        <template #header_tabs_container_counters-design>
          <ControlsList
            :widget="widget"
            :controls="headerCountersDesignControls"
          />
        </template>

        <template #header_tabs_container_counters-content>
          <ControlsList
            :widget="widget"
            :controls="headerCountersContentControls"
          />
        </template>

        <template #cashout>
          <ControlsList
            :widget="widget"
            :controls="betsResultTextControls"
          />
        </template>

        <template #betslip_tabs_container-design>
          <ControlsList
            :widget="widget"
            :controls="betslipTabsDesignControls"
          />
        </template>

        <template #betslip_tabs_container-content>
          <ControlsList
            :widget="widget"
            :controls="betslipTabsContentControls"
          />
        </template>

        <template #betslip_tabs_container-states>
          <ControlsList
            :widget="widget"
            :controls="betslipTabsStatesControls"
          />
        </template>

        <template #bets_container-design>
          <ControlsList
            :widget="widget"
            :controls="betsDesignControls"
          />
        </template>

        <template #bets_container-content>
          <ControlsList
            :widget="widget"
            :controls="betsContentControls"
          />
        </template>

        <template #bets_container-preview>
          <ControlsList
            :widget="widget"
            :controls="betsPreviewControls"
          />
        </template>

        <template #cards_container-design>
          <ControlsList
            :widget="widget"
            :controls="betsCardsDesignControls"
          />
        </template>

        <template #cards_container-content>
          <ControlsList
            :widget="widget"
            :controls="betsCardsContentControls"
          />
        </template>

        <template #event_container-design>
          <ControlsList
            :widget="widget"
            :controls="eventContainerDesignControls"
          />
        </template>

        <template #event_container-content>
          <ControlsList
            :widget="widget"
            :controls="eventContainerContentControls"
          />
        </template>

        <template #live_status_title-design>
          <ControlsList
            :widget="widget"
            :controls="liveStatusDesignControls"
          />
        </template>

        <template #live_status_title-content>
          <ControlsList
            :widget="widget"
            :controls="liveStatusContentControls"
          />
        </template>

        <template #not_available_status_title-design>
          <ControlsList
            :widget="widget"
            :controls="notAviableStatusDesignControls"
          />
        </template>

        <template #not_available_status_title-content>
          <ControlsList
            :widget="widget"
            :controls="notAviableStatusContentControls"
          />
        </template>

        <template #close_icon>
          <ControlsList
            :widget="widget"
            :controls="closeIconControls"
          />
        </template>

        <template #event_name_title>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #market_title>
          <ControlsList
            :widget="widget"
            :controls="betMarketControls"
          />
        </template>

        <template #bet_name_title>
          <ControlsList
            :widget="widget"
            :controls="betNameControls"
          />
        </template>

        <template #coefficient_title-design>
          <ControlsList
            :widget="widget"
            :controls="coefficientDesignControls"
          />
        </template>

        <template #coefficient_title-content>
          <ControlsList
            :widget="widget"
            :controls="coefficientContentControls"
          />
        </template>

        <template #coefficient_title-states>
          <ControlsList
            :widget="widget"
            :controls="coefficientStatesControls"
          />
        </template>

        <template #result_container-design>
          <ControlsList
            :widget="widget"
            :controls="resultDesignControls"
          />
        </template>

        <template #result_container-content>
          <ControlsList
            :widget="widget"
            :controls="resultContentControls"
          />
        </template>

        <template #result_container_total_amounts>
          <ControlsList
            :widget="widget"
            :controls="totalAmountsControls"
          />
        </template>

        <template #result_container_warning_message_title-design>
          <ControlsList
            :widget="widget"
            :controls="warningMessageDesignControls"
          />
        </template>

        <template #result_container_warning_message_title-content>
          <ControlsList
            :widget="widget"
            :controls="warningMessageContentControls"
          />
        </template>

        <template #result_container_bet_button-design>
          <ControlsList
            :widget="widget"
            :controls="actionButtonDesignControls"
          />
        </template>

        <template #result_container_bet_button-content>
          <ControlsList
            :widget="widget"
            :controls="actionButtonContentControls"
          />
        </template>

        <template #result_container_bet_button-states>
          <ControlsList
            :widget="widget"
            :controls="actionButtonStatesControls"
          />
        </template>

        <template #result_container_remove_button-design>
          <ControlsList
            :widget="widget"
            :controls="removeButtonDesignControls"
          />
        </template>

        <template #result_container_remove_button-content>
          <ControlsList
            :widget="widget"
            :controls="removeButtonContentControls"
          />
        </template>

        <template #result_container_remove_button-states>
          <ControlsList
            :widget="widget"
            :controls="removeButtonStatesControls"
          />
        </template>

        <template #result_container_copy_button-design>
          <ControlsList
            :widget="widget"
            :controls="copyButtonDesignControls"
          />
        </template>

        <template #result_container_copy_button-content>
          <ControlsList
            :widget="widget"
            :controls="copyButtonContentControls"
          />
        </template>

        <template #result_container_copy_button-states>
          <ControlsList
            :widget="widget"
            :controls="copyButtonStatesControls"
          />
        </template>

        <template #no_bets_description>
          <FormDescriptionSettings
            :is-bold="true"
            @update-field-option="updateFieldOption"
          />
        </template>

        <template #no_bets_description_mybets>
          <FormDescriptionSettings
            :is-bold="true"
            @update-field-option="updateFieldOption"
          />
        </template>

        <template #bets_type_title-design>
          <ControlsList
            :widget="widget"
            :controls="betsTypeDesignControls"
          />
        </template>

        <template #bets_type_title-content>
          <ControlsList
            :widget="widget"
            :controls="betsTypeContentControls"
          />
        </template>

        <template #bets_type_title-states>
          <ControlsList
            :widget="widget"
            :controls="betsTypeStatesControls"
          />
        </template>

        <template #time_title>
          <ControlsList
            :widget="widget"
            :controls="timeControls"
          />
        </template>

        <template #bet_amounts_container>
          <ControlsList
            :widget="widget"
            :controls="betAmountsControls"
          />
        </template>

        <template #bets_result_save_choice_button-design>
          <ControlsList
            :widget="widget"
            :controls="saveChoiseButtonDesignControls"
          />
        </template>

        <template #bets_result_save_choice_button-content>
          <ControlsList
            :widget="widget"
            :controls="saveChoiseButtonContentControls"
          />
        </template>

        <template #bets_result_save_choice_button-states>
          <ControlsList
            :widget="widget"
            :controls="saveChoiseButtonStatesControls"
          />
        </template>

        <template #bets_result_continue_button-design>
          <ControlsList
            :widget="widget"
            :controls="continueButtonDesignControls"
          />
        </template>

        <template #bets_result_continue_button-content>
          <ControlsList
            :widget="widget"
            :controls="continueButtonContentControls"
          />
        </template>

        <template #bets_result_continue_button-states>
          <ControlsList
            :widget="widget"
            :controls="continueButtonStatesControls"
          />
        </template>

        <template #my_bets_primary_button-design>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonDesignControls"
          />
        </template>

        <template #my_bets_primary_button-content>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonContentControls"
          />
        </template>

        <template #my_bets_primary_button-states>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonStatesControls"
          />
        </template>

        <template #my_bets_secondary_button-design>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonDesignControls"
          />
        </template>

        <template #my_bets_secondary_button-content>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonContentControls"
          />
        </template>

        <template #my_bets_secondary_button-states>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonStatesControls"
          />
        </template>

        <template #my_bets_warning_message-design>
          <ControlsList
            :widget="widget"
            :controls="myBetsWarningMessageDesignControls"
          />
        </template>

        <template #my_bets_warning_message-content>
          <ControlsList
            :widget="widget"
            :controls="myBetsWarningMessageContentControls"
          />
        </template>

        <template #success_message_title-design>
          <ControlsList
            :widget="widget"
            :controls="betsResultSuccessMessageDesignControls"
          />
        </template>

        <template #success_message_title-content>
          <ControlsList
            :widget="widget"
            :controls="betsResultSuccessMessageContentControls"
          />
        </template>

        <template #form-design>
          <ControlsList
            :widget="widget"
            :controls="betslipFormDesignControls"
          />
        </template>

        <template #form-content>
          <ControlsList
            :widget="widget"
            :controls="betslipFormContentControls"
          />
        </template>

        <template #collapse-design>
          <ControlsList
            :widget="widget"
            :controls="collapseDesignControls"
          />
        </template>

        <template #collapse-content>
          <ControlsList
            :widget="widget"
            :controls="collapseContentControls"
          />
        </template>

        <template #collapse-states>
          <ControlsList
            :widget="widget"
            :controls="collapseStateControls"
          />
        </template>

        <template #bets_result_system_name>
          <ControlsList
            :widget="widget"
            :controls="betsResultTextControls"
          />
        </template>

        <template #multibet_tooltip-design>
          <ControlsList
            :widget="widget"
            :controls="multibetTooltipDesignStyles"
          />
        </template>

        <template #multibet_tooltip-content>
          <ControlsList
            :widget="widget"
            :controls="multibetTipContentStyles"
          />
        </template>

        <template #each_way_bet-design>
          <ControlsList
            :widget="widget"
            :controls="eachWayBetDesignControls"
          />
        </template>

        <template #each_way_bet-content>
          <ControlsList
            :widget="widget"
            :controls="eachWayBetContentControls"
          />
        </template>

        <template #each_way_bet_checkbox-design>
          <ControlsList
            :widget="widget"
            :controls="eachWayCheckboxDesignControls"
          />
        </template>

        <template #each_way_bet_checkbox-content>
          <ControlsList
            :widget="widget"
            :controls="eachWayCheckboxContentControls"
          />
        </template>

        <template #each_way_bet_checkbox-states>
          <ControlsList
            :widget="widget"
            :controls="eachWayCheckboxStatesControls"
          />
        </template>

        <template #each_way_bet_tooltip-design>
          <ControlsList
            :widget="widget"
            :controls="formTooltipDesignStyles"
          />
        </template>

        <template #each_way_bet_tooltip-content>
          <ControlsList
            :widget="widget"
            :controls="formTooltipContentStyles"
          />
        </template>

        <template #stake_info-design>
          <ControlsList
            :widget="widget"
            :controls="stakeInfoDesignControls"
          />
        </template>

        <template #stake_info-content>
          <ControlsList
            :widget="widget"
            :controls="stakeInfoContentControls"
          />
        </template>

        <template #total_stake-design>
          <ControlsList
            :widget="widget"
            :controls="totalStakeDesignControls"
          />
        </template>

        <template #total_stake-content>
          <ControlsList
            :widget="widget"
            :controls="totalStakeContentControls"
          />
        </template>

        <template #loader>
          <ControlsList
            :widget="widget"
            :controls="loaderControls"
          />
        </template>

        <template #cashout_tooltip-design>
          <ControlsList
            :widget="widget"
            :controls="cashoutTooltipDesignStyles"
          />
        </template>

        <template #cashout_tooltip-content>
          <ControlsList
            :widget="widget"
            :controls="cashoutTooltipContentStyles"
          />
        </template>

        <template #header_label>
          <ControlsList
            :widget="widget"
            :controls="headerLabelControls"
          />
        </template>

        <template #booking_label>
          <ControlsList
            :widget="widget"
            :controls="bookingLabelControls"
          />
        </template>

        <template #booking_button-design>
          <ControlsList
            :widget="widget"
            :controls="bookingButtonDesignControls"
          />
        </template>

        <template #booking_button-content>
          <ControlsList
            :widget="widget"
            :controls="bookingButtonContentControls"
          />
        </template>

        <template #booking_button-states>
          <ControlsList
            :widget="widget"
            :controls="bookingButtonStatesControls"
          />
        </template>

        <template #booking_input-design>
          <ControlsList
            :widget="widget"
            :controls="bookingInputDesignControls"
          />
        </template>

        <template #booking_input-content>
          <ControlsList
            :widget="widget"
            :controls="bookingInputContentControls"
          />
        </template>

        <template #booking_input-states>
          <ControlsList
            :widget="widget"
            :controls="bookingInputStatesControls"
          />
        </template>

        <template #booking_error-design>
          <ControlsList
            :widget="widget"
            :controls="bookingErrorDesignControls"
          />
        </template>

        <template #booking_error-content>
          <ControlsList
            :widget="widget"
            :controls="bookingErrorContentControls"
          />
        </template>

        <!-- 
          Form element styles
         -->
        <template
          v-for="(controlValues, controlOptionName) in formContentControls"
          #[controlOptionName]
          :key="controlOptionName"
        >
          <ControlsList
            :widget="widget"
            :controls="controlValues"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipFenixConfig } from "~~/composables/widgets/betslip-fenix/useBetslipFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingsStore = useWidgetSettingsStore();
const store = useWidgetsStore();
const { selectedWidget } = storeToRefs(widgetsSettingsStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "bookingCode": {
      label: "Booking code",
      description: "Possible values: Yes | No",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  BETSLIP_DROPDOWN_ITEMS,
  MY_BETS_DROPDOWN_ITEMS,
  BETS_RESULT_DROPDOWN_ITEMS,
  BOOKING_CODE_DROPDOWN_ITEMS,
  currentTabs,
  widgetDesignControls,
  widgetContentControls,

  headerDesignControls,
  headerContentControls,
  headerStatesControls,

  headerCountersDesignControls,
  headerCountersContentControls,

  cashoutTooltipDesignStyles,
  cashoutTooltipContentStyles,

  betslipTabsDesignControls,
  betslipTabsContentControls,
  betslipTabsStatesControls,

  betsDesignControls,
  betsContentControls,
  betsPreviewControls,

  betsCardsDesignControls,
  betsCardsContentControls,

  liveStatusDesignControls,
  liveStatusContentControls,

  notAviableStatusDesignControls,
  notAviableStatusContentControls,

  closeIconControls,

  eventNameControls,

  betMarketControls,

  betNameControls,

  coefficientDesignControls,
  coefficientContentControls,
  coefficientStatesControls,

  resultDesignControls,
  resultContentControls,

  totalAmountsControls,

  warningMessageDesignControls,
  warningMessageContentControls,

  myBetsWarningMessageDesignControls,
  myBetsWarningMessageContentControls,

  betsResultSuccessMessageDesignControls,
  betsResultSuccessMessageContentControls,

  actionButtonDesignControls,
  actionButtonContentControls,
  actionButtonStatesControls,

  removeButtonDesignControls,
  removeButtonContentControls,
  removeButtonStatesControls,

  copyButtonDesignControls,
  copyButtonContentControls,
  copyButtonStatesControls,

  betsTypeDesignControls,
  betsTypeContentControls,
  betsTypeStatesControls,

  timeControls,

  eventContainerDesignControls,
  eventContainerContentControls,

  betAmountsControls,

  saveChoiseButtonDesignControls,
  saveChoiseButtonContentControls,
  saveChoiseButtonStatesControls,

  continueButtonDesignControls,
  continueButtonContentControls,
  continueButtonStatesControls,

  primaryButtonDesignControls,
  primaryButtonContentControls,
  primaryButtonStatesControls,

  secondaryButtonDesignControls,
  secondaryButtonContentControls,
  secondaryButtonStatesControls,

  formContentControls,
  betslipFormContentControls,
  betslipFormDesignControls,

  collapseDesignControls,
  collapseContentControls,
  collapseStateControls,

  betsResultTextControls,

  multibetTooltipDesignStyles,
  multibetTipContentStyles,

  eachWayBetDesignControls,
  eachWayBetContentControls,

  eachWayCheckboxDesignControls,
  eachWayCheckboxContentControls,
  eachWayCheckboxStatesControls,

  formTooltipDesignStyles,
  formTooltipContentStyles,

  stakeInfoDesignControls,
  stakeInfoContentControls,

  totalStakeDesignControls,
  totalStakeContentControls,

  loaderControls,

  headerLabelControls,

  bookingLabelControls,

  bookingButtonStatesControls,
  bookingButtonDesignControls,
  bookingButtonContentControls,

  bookingInputDesignControls,
  bookingInputContentControls,
  bookingInputStatesControls,

  bookingErrorDesignControls,
  bookingErrorContentControls,
} = useBetslipFenixConfig(ref(props.widget), states, emit);

const currentDropdownItems = computed<DropdownItem[]>(() => {
  if (states.value.BetslipFenixWidget === State.BETSLIP) {
    return BETSLIP_DROPDOWN_ITEMS.value;
  } else if (states.value.BetslipFenixWidget === State.MY_BETS) {
    return MY_BETS_DROPDOWN_ITEMS.value;
  } else if (states.value.BetslipFenixWidget === State.BOOKING_CODE) {
    return BOOKING_CODE_DROPDOWN_ITEMS.value;
  } else {
    return BETS_RESULT_DROPDOWN_ITEMS.value;
  }
});

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
