import { PageIdType } from "~~/models/widgets/widget.core/widget.model";
import { IAccessScenario } from "~~/components/PageConfigDrawer/accessScenarioEditor/type";

export type ListItemValue = string | number | null;

export interface IPagesStore {
  selectedPage: IPage | null;
  pages: IPage[];
  templateId: PageQueryType;
  isCreatingNewPage: boolean;
  isWidgetsTemplateLoading: boolean;
  allowSavePage: boolean;
}

export interface ISelectedPage {
  id: PageIdType;
  name: string;
  project: string;
  route: string;
  template: string;
  update_at: string;
  created_at: string;
}

export interface IPageRouteBindingObject {
  entity_type: string;
  entity_variable: string;
}

export enum PageType {
  WEBPAGE = "webpage",
  MODAL_BOX = "modal_box",
}

export interface IPage {
  id: PageIdType;
  project: PageIdType;
  template: PageQueryType;
  name: string;
  routeBinding: Record<string, IPageRouteBindingObject>;
  created_at: string;
  updated_at: string;

  routes: Record<string, string>;
  nameHighlighted?: string;
  isEditing?: boolean;
  options?: Record<string, any> & {
    accessScenarios?: IAccessScenario[];
    alignment?: string;
  };
  published: boolean;
  pageType: PageType;
}

export type PageQueryType = string | undefined | null;
