import { DynamicFieldsProperties } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  FormElementName,
  FormFieldInitial,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";

import { TITLE_INITIAL } from "./title";
import { IMAGE_INITIAL } from "./image";
import { BUTTON_INITIAL } from "./button";
import { TEXT_INPUT_INITIAL } from "./input";
import { CHECKBOX_INITIAL } from "./checkbox";
import { RADIO_GROUP_INITIAL } from "./radio_group";
import { DATE_INITIAL } from "./date";
import { PHONE_INITIAL } from "./phone";
import { DROPDOWN_INITIAL } from "./dropdown";
import { DESCRIPTION_INITIAL } from "./description";
import { LOGIN_FORM_STYLING_FIELDS } from "./styling";
import { UPLOAD_INITIAL } from "./upload";

export const DYNAMIC_FIELD_OPTIONS: FormFieldInitial = {
  [FormElementName.TITLE]: TITLE_INITIAL,
  [FormElementName.HIDDEN]: TITLE_INITIAL,
  [FormElementName.TEXT_INPUT]: TEXT_INPUT_INITIAL,
  [FormElementName.EMAIL]: TEXT_INPUT_INITIAL,
  [FormElementName.NUMBER]: TEXT_INPUT_INITIAL,
  [FormElementName.CHECKBOX]: CHECKBOX_INITIAL,
  [FormElementName.DROPDOWN]: DROPDOWN_INITIAL,
  [FormElementName.DESCRIPTION]: DESCRIPTION_INITIAL,
  [FormElementName.BUTTON]: BUTTON_INITIAL,
  [FormElementName.IMAGE]: IMAGE_INITIAL,
  [FormElementName.PASSWORD]: TEXT_INPUT_INITIAL,
  [FormElementName.RADIO_GROUP]: RADIO_GROUP_INITIAL,
  [FormElementName.DATE]: DATE_INITIAL,
  [FormElementName.PHONE]: PHONE_INITIAL,
  [FormElementName.FORM_TEXT_INPUT_FIELD]: TEXT_INPUT_INITIAL,
  [FormElementName.UPLOAD]: UPLOAD_INITIAL,
  // ...STYLING_FIELDS,
};

export const INITIAL_STATES: Record<string, string> = {
  [FormStyleFieldName.FIELD]: State.DEFAULT,
  [FormStyleFieldName.CHECKBOX]: State.SELECTED_DEFAULT,
  [FormStyleFieldName.RADIO_GROUP]: State.SELECTED_DEFAULT,
  [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
  [FormElementName.BUTTON]: State.DEFAULT,
  [FormStyleFieldName.CALENDAR]: State.DEFAULT,
  [FormStyleFieldName.UPLOAD_ITEMS]: State.DEFAULT,
  [FormStyleFieldName.UPLOAD_BUTTON]: State.DEFAULT,
  success_title: State.DEFAULT,
  expired_title: State.DEFAULT,
  fail_title: State.DEFAULT,
  success_button: State.DEFAULT,
  expired_button: State.DEFAULT,
  fail_button: State.DEFAULT,
};

export const LOGIN_FORM_DYNAMIC_FIELD_OPTIONS: DynamicFieldsProperties = {
  [FormElementName.TITLE]: TITLE_INITIAL,
  [FormElementName.TEXT_INPUT]: {
    options: {
      // ...text_input.options,
      tooltip: {},
    },
  },
  [FormElementName.DESCRIPTION]: DROPDOWN_INITIAL,
  [FormElementName.BUTTON]: BUTTON_INITIAL,
  [FormElementName.PASSWORD]: {
    options: {
      // ...text_input.options,
      tooltip: {},
    },
  },
  ...LOGIN_FORM_STYLING_FIELDS,
};

export const SET_PASSWORD_FORM_DYNAMIC_FIELD_OPTIONS: DynamicFieldsProperties =
  {
    [FormElementName.TITLE]: TITLE_INITIAL,
    [FormElementName.DESCRIPTION]: DESCRIPTION_INITIAL,
    [FormElementName.BUTTON]: BUTTON_INITIAL,
    [FormElementName.IMAGE]: IMAGE_INITIAL,
    [FormElementName.PASSWORD]: {
      options: {
        // ...text_input.options,
        tooltip: {},
      },
    },
    ...LOGIN_FORM_STYLING_FIELDS,
  };

export const FORGOT_PASSWORD_FORM_DYNAMIC_FIELD_OPTIONS: DynamicFieldsProperties =
  {
    [FormElementName.TITLE]: TITLE_INITIAL,
    [FormElementName.DESCRIPTION]: DESCRIPTION_INITIAL,
    [FormElementName.BUTTON]: BUTTON_INITIAL,
    [FormElementName.IMAGE]: IMAGE_INITIAL,
    [FormElementName.TEXT_INPUT]: {
      options: {
        // ...text_input.options,
        tooltip: {},
      },
    },
    ...LOGIN_FORM_STYLING_FIELDS,
  };
