import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useBonusesProgressConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const progressControls = computed(() => {
    return [
      typography({
        source: fields.value.progress_bar.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Progress bar"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.bar.size",
            valueSource: fields.value.progress_bar.field,
            options: {
              label: "Size",
              items: [
                {
                  label: "Large",
                  value: 14,
                },
                {
                  label: "Middle",
                  value: 12,
                },
                {
                  label: "Small",
                  value: 8,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.bar.color",
            valueSource: fields.value.progress_bar.field,
            options: {
              label: "Progress color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.bar.fill",
            valueSource: fields.value.progress_bar.field,
            options: {
              label: "Background color",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.progress_bar.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    progressControls,
  };
};
