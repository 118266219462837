import { type IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { calculateDisableFieldsAllowed } from "~~/helpers/configs/disable-fields-allowed";
import { State } from "~~/models/widgets/widget-controls.model";

import { useWidgetFields } from "../useWidgetFields";
import { useSlideControls as useTextSlideControls } from "../text-slider/useSlideControls";

export const useSlideControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const slideContentControls = computed(() => {
    const fields = useWidgetFields(
      selectedField.value as unknown as IWidgetWithFields
    );
    const PRIMARY_WIDGET_FIELDS = ["title", "description_1", "buttons_group"];

    const disableFieldsAllowed = calculateDisableFieldsAllowed(
      PRIMARY_WIDGET_FIELDS,
      fields
    );

    if (!Object.keys(fields.value).length) return [];

    return [
      {
        componentPath: "ConfigLayoutPosition",
        valuePath: "options.layoutPosition",
        valueSource: selectedField.value,
        className: "border-bottom-none p-b-0",
        options: {},
        visible: true,
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: selectedField.value,
        className: "group-control-element--child",
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options._active",
        valueSource: fields.value.image.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Image",
          size: "small",
          allowDisable: disableFieldsAllowed.value,
          showOptions: fields.value.image.active,
          "onOptions-click": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.image.field
            );
          },
        },
      },
      {
        componentPath: "ConfigImageUpload",
        valuePath: "value",
        valueSource: fields.value.image.field,
        options: {
          active: fields.value.image.field.options._active,
          attribute: "items.image",
          isValueOption: false,
          isContentImage: false,
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "group-control-element",
        options: {
          isMultiple: false,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: disableFieldsAllowed.value,
          fields: [fields.value.description_1.field],
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description_1.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options._active",
        valueSource: fields.value.buttons_group.field,
        className: "p-l-16 p-t-16 p-r-16 p-b-16 widget-control-container",
        options: {
          label: "Buttons group",
          size: "small",
          allowDisable: disableFieldsAllowed.value,
          showOptions: fields.value.buttons_group.active,
          "onOptions-click": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.buttons_group.field
            );
          },
        },
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
    ];
  });

  const { slideDesignControls, slideStatesControls } = useTextSlideControls(
    widget,
    states,
    emit
  );

  return {
    slideDesignControls,
    slideContentControls,
    slideStatesControls,
  };
};
