import { type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useEventInfoConfig = (
  widget: Ref<IWidgetWithFields>,
  fieldPrefix = ""
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const eventInfoDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value[`${fieldPrefix}list_event_info`].field,
    });
  });

  const eventInfoContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value[`${fieldPrefix}list_event_info`].field,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: fields.value[`${fieldPrefix}list_event_info`].field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Start time",
              value: `${fieldPrefix}list_start_time`,
              isActiveValueSource:
                fields.value[`${fieldPrefix}list_start_time`].field.options,
            },
            {
              label: "Date",
              value: `${fieldPrefix}list_date`,
              isActiveValueSource:
                fields.value[`${fieldPrefix}list_date`].field.options,
            },
            {
              label: "Team / Player",
              value: `${fieldPrefix}list_event_name`,
            },
            {
              label: "Build button",
              value: `${fieldPrefix}betbuilder_button`,
            },
            {
              label: "Favourite icon",
              value: `${fieldPrefix}list_favourite_icon`,
              isActiveValueSource:
                fields.value[`${fieldPrefix}list_favourite_icon`].field.options,
            },
          ],
        },
      },
    ];
  });

  /* 
      Start time / Date
    */

  const timeDateControls = computed(() => {
    return [
      {
        ...typography({
          source: selectedField.value,
          except: ["ConfigAlignment"],
        }),
      },
      {
        componentPath: "ConfigIconPicker",
        valuePath: "options.icon.value",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: selectedField.value,
        options: {
          allowDisable: true,
          label: "Icon",
          isActive: selectedField.value?.options.icon._active,
          "onUpdate:active": (value: boolean) => {
            selectedField.value!.options.icon._active = value;
          },
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.icon.size",
        valueSource: selectedField.value,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.icon.color",
        valueSource: selectedField.value,
        options: {
          label: "Color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          isBold: true,
        },
      },
    ];
  });

  /* 
      Favourite icon
    */

  const favoriteIconControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    eventInfoDesignControls,
    eventInfoContentControls,

    timeDateControls,
    favoriteIconControls,
  };
};
