<template>
  <div
    v-if="hasDesignPermissions"
    class="config-slider-columns wconfig-item m-t-16"
  >
    <a-tooltip
      v-for="column in sliderColumnsList"
      :key="column"
      placement="left"
    >
      <template
        v-if="slidesCount < MIN_SLIDES_COUNT[column]"
        #title
      >
        <span>Add at least {{ MIN_SLIDES_COUNT[column] }} slides</span>
      </template>

      <div
        class="config-slider-columns__state-element f-base pointer"
        :class="{
          'config-slider-columns__state-element--active': modelValue === column,
          'config-slider-columns__state-element--disabled':
            slidesCount < MIN_SLIDES_COUNT[column],
        }"
        @click="handleUpdate(column)"
      >
        <CommonIcon :name="`custom:layout-column-${column}`" />
      </div>
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { SliderColumns } from "~~/models/widgets/widget-controls.model";

const MIN_SLIDES_COUNT: Record<SliderColumns, number> = {
  [SliderColumns.ONE]: 1,
  [SliderColumns.TWO]: 4,
  [SliderColumns.THREE]: 6,
};

const props = withDefaults(
  defineProps<{
    modelValue: SliderColumns;
    slidesCount: number;
  }>(),
  {
    modelValue: 1,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: SliderColumns): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: SliderColumns) => {
  if (props.slidesCount < MIN_SLIDES_COUNT[value]) return;

  emit("update:modelValue", value);
};

const sliderColumnsList = [
  SliderColumns.ONE,
  SliderColumns.TWO,
  SliderColumns.THREE,
];

watch(
  () => props.slidesCount,
  newSlidesCount => {
    if (newSlidesCount < MIN_SLIDES_COUNT[props.modelValue]) {
      emit("update:modelValue", props.modelValue - 1);
    }
  }
);
</script>

<style lang="scss">
.config-slider-columns {
  @include flex(center, space-between, $space-s);

  &__label {
    padding-top: 4px;
  }

  &__state-element {
    position: relative;
    padding: 8px;
    color: $c-black;
    border: 1px solid $c-grey-15;
    border-radius: 6px;
    @include flex-column(center, space-between, 0);
    flex-grow: 1;

    svg {
      width: 100%;
      height: 52px;
    }

    &--active {
      padding: 7px;
      border: 2px solid $c-primary-base;
    }

    &--disabled {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}
</style>
