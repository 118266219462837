<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
  >
    <div
      :style="containerStyles"
      class="sport-event-fenix__markets-container"
    >
      <slot></slot>
    </div>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    "column-count": props.field.options.display.layout,
    "--gap": getPxValueFromNumber(props.field.options.display.distance),
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
.sport-event-fenix {
  &__markets-container {
    display: block;

    & > div {
      break-inside: avoid;
      margin-bottom: var(--gap);
    }
  }
}
</style>
