<template>
  <div class="form-button-field">
    <BasicButtonField
      :field="field"
      :current-state="state"
    />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { FormElementName } from "~~/models/widgets/form.model";

const props = defineProps<{
  field: IWidgetField;
  states: Record<string, string>;
}>();

const state = computed<string>(() => {
  return props.states[FormElementName.BUTTON];
});
</script>
