<template>
  <WidgetField
    :widget="widget"
    :field="field"
    class="promo-group-card"
    :class="[`promo-group-card--${layoutPosition.position}`]"
    :style="[containerStyles, containerCommonStyles]"
  >
    <FlexibleImageField
      v-if="imageField.options._active"
      class="promo-group-card__field w-100"
      :field="imageField"
      :widget="widget"
      :value="imageValue || imageField.value"
    />

    <div
      class="w-100 promo-group-card__text"
      :style="textContainerStyles"
    >
      <WidgetField
        v-if="titleField.options._active"
        :widget="widget"
        :field="titleField"
        class="promo-group-card__field"
      >
        <BasicTitleField
          :field="titleField"
          :state="titleLinkSelectedState"
          :value="titleValue || titleField.value"
        />
      </WidgetField>

      <WidgetField
        v-if="descriptionField.options._active"
        :widget="widget"
        :field="descriptionField"
        class="promo-group-card__field promo-group-card__description"
      >
        <BasicTitleField
          :fields="descriptionField"
          :options="descriptionField.options"
          :value="descriptionValue || descriptionField.value"
          :widget="widget"
        />
      </WidgetField>

      <WidgetField
        v-if="
          secondaryDescriptionField && secondaryDescriptionField.options._active
        "
        :widget="widget"
        :field="secondaryDescriptionField"
        class="promo-group-card__field promo-group-card__description"
      >
        <BasicTitleField
          :fields="secondaryDescriptionField"
          :options="secondaryDescriptionField?.options"
          :value="secondaryDescriptionValue || secondaryDescriptionField?.value"
          :widget="widget"
        />
      </WidgetField>

      <WidgetField
        v-if="buttonGroupField.options._active"
        :widget="widget"
        :field="buttonGroupField"
        :style="buttonsGroupStyle"
        class="promo-group-card__field promo-group-card__buttons-group"
      >
        <WidgetField
          v-if="button1Field.options._active"
          :widget="widget"
          :field="button1Field"
          class="promo-group-card__field"
          :style="button1Style"
        >
          <BasicButtonField
            :field="button1Field"
            :custom-value="button1Value || button1Field.value"
            :current-state="buttonState1"
          />
        </WidgetField>

        <WidgetField
          v-if="button2Field.options._active"
          :widget="widget"
          :field="button2Field"
          class="promo-group-card__field"
          :style="button2Style"
        >
          <BasicButtonField
            :field="button2Field"
            :custom-value="button2Value || button2Field.value"
            :current-state="buttonState2"
          />
        </WidgetField>
      </WidgetField>
    </div>
  </WidgetField>
</template>

<script lang="ts" setup>
import type {
  ICustomField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  TextPositionComplex,
  TextPosition,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundFillStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    field: ICustomField;
    fieldState: State;
    imageField: ICustomField;
    titleField: ICustomField;
    descriptionField: ICustomField;
    // eslint-disable-next-line vue/require-default-prop
    secondaryDescriptionField?: ICustomField;
    buttonGroupField: ICustomField;
    button1Field: ICustomField;
    button2Field: ICustomField;
    imageValue?: string;
    titleValue?: string;
    descriptionValue?: string;
    secondaryDescriptionValue?: string;
    button1Value?: string;
    button2Value?: string;
    buttonState1?: State;
    buttonState2?: State;
  }>(),
  {
    imageValue: "img.png",
    titleValue: "Title",
    descriptionValue: "Description",
    secondaryDescriptionValue: "Secondary Description",
    button1Value: "Button",
    button2Value: "Button",
    buttonState1: State.DEFAULT,
    buttonState2: State.DEFAULT,
  }
);

const element = computed<IWidgetWithFields | ICustomField>(
  () => props.field || props.widget
);

const titleLinkSelectedState = ref(State.DEFAULT);

const layoutPosition = computed<TextPositionComplex>(() => {
  return element.value.options.layoutPosition;
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.fieldState === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.fieldState];
});

const textContainerStyles = computed<ElementStyle>(() => {
  const alignment = props.field.options.layoutPosition.alignment.split(" ");

  const justify = getFlexPosition(alignment[0]);
  const align = getFlexPosition(alignment[1]);

  return {
    gap: getPxValueFromNumber(props.field.options.gap),
    alignItems: align,
    justifyContent: justify,
  };
});

const containerHeightStyle = computed<ElementStyle>(() => {
  const heightOptions = props.field.options.containerSize.height;

  if (heightOptions.type === ResizingType.FIXED) {
    return {
      height: getPxValueFromNumber(heightOptions.value),
    };
  }

  return {
    height: "100%",
  };
});

const containerCommonStyles = computed<ElementStyle>(() => {
  const alignment = props.field.options.layoutPosition.alignment.split(" ");

  const justify = getFlexPosition(alignment[0]);
  const align = getFlexPosition(alignment[1]);

  return {
    ...containerHeightStyle.value,
    ...getCommonStyles({
      ...props.field.options,
      ...activeStateOptions.value,
    }),
    ...getBackgroundFillStyle(activeStateOptions.value.fillImageColor),
    gap: getPxValueFromNumber(props.field.options.gap),
    alignItems: align,
    justifyContent: justify,
  };
});

const containerStyles = computed<
  { [key: string]: string | undefined } | undefined
>(() => {
  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return undefined;
  }

  return {
    alignItems: layoutPosition.value.alignment || undefined,
  };
});

const buttonsGroupStyle = computed(() => {
  const style = useWidgetStyle(props.buttonGroupField);

  const { layout, gap, alignment } = props.buttonGroupField.options;

  if (layout === DisplayOrientation.VERTICAL) {
    style.value.flexDirection = "column";
  }

  style.value.gap = getPxValueFromNumber(gap);
  style.value.justifyContent = alignment;
  style.value.alignItems =
    layout === DisplayOrientation.HORIZONTAL
      ? "start"
      : generateFlexAlignment(alignment);
  return style.value;
});

const getButtonStyle = (name: "button_1" | "button_2") => {
  const buttonDisplaySettings =
    name === "button_1"
      ? props.button1Field.options.buttonDisplaySettings
      : props.button2Field.options.buttonDisplaySettings;

  if (buttonDisplaySettings.resizing === ResizingType.HUG) return {};

  if (
    buttonDisplaySettings.resizing === ResizingType.FILL &&
    props.buttonGroupField.options.layout === DisplayOrientation.VERTICAL
  )
    return { width: "100%" };

  return { flexGrow: 1 };
};

const button1Style = computed(() => getButtonStyle("button_1"));
const button2Style = computed(() => getButtonStyle("button_2"));
</script>

<style lang="scss">
.promo-group-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--top {
    flex-direction: column-reverse;
  }

  &--right {
    flex-direction: row;
  }

  &--left {
    flex-direction: row-reverse;
  }

  &__buttons-group {
    display: flex;
    width: 100%;
  }

  &__text {
    @include flex-column(flex-start, flex-start, 0);
  }
}
</style>
