import { ElementStyle } from "~~/models/common";
import {
  TextPositionComplex,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";

export const useTextPositionStyles = (
  textPositionInWidget: Ref<TextPositionComplex>
) => {
  const DEFAULT_SCALE_VALUE = 50;
  const titleStyles = computed<Record<string, string> | undefined>(() => {
    if (
      !textPositionInWidget.value ||
      textPositionInWidget.value?.position === TextPosition.TOP
    ) {
      return undefined;
    }

    return {
      flexBasis: `${textPositionInWidget.value.scale || DEFAULT_SCALE_VALUE}%`,
      width: `${textPositionInWidget.value.scale || DEFAULT_SCALE_VALUE}%`,
    };
  });

  const descriptionStyles = computed<Record<string, string> | undefined>(() => {
    const titleScale: number = Number(
      textPositionInWidget.value?.scale || DEFAULT_SCALE_VALUE
    );
    const maxScale = 100;

    if (
      !titleScale ||
      textPositionInWidget.value?.position === TextPosition.TOP
    ) {
      return undefined;
    }

    return {
      flexBasis: `${maxScale - titleScale}%`,
      width: `${maxScale - titleScale}%`,
    };
  });

  const containerStyles = computed<ElementStyle | undefined>(() => {
    if (
      !textPositionInWidget.value ||
      textPositionInWidget.value?.position === TextPosition.TOP
    ) {
      return undefined;
    }

    return {
      alignItems: textPositionInWidget.value.alignment || undefined,
    };
  });

  return {
    titleStyles,
    descriptionStyles,
    containerStyles,
  };
};
