import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { size } from "~~/constants/configs/common/size-config";

type KeyNames = {
  width?: string;
  height?: string;
  alignment?: string;
};

type Args = {
  source: IWidgetField | IWidgetWithFields | null;
  keyNames?: KeyNames;
  except?: string[];
};

export const getInitialContentControlsList = ({
  source,
  keyNames,
  except,
}: Args): ControlProp[] => {
  const controls: ControlProp[] = [
    size({
      source: source,
      keyNames,
      except,
    }),
  ];

  return controls;
};
