import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateImageCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";

export const generateFormExpiredCssString =
  (widget: IPageContentWidget) => (cssString: string) => {
    const expiredImage = widget.content["expired_image"];
    const expiredButton = widget.content["expired_button"];
    const expiredDescription = widget.content["expired_description_1"];
    const expiredTitle = widget.content["expired_title"];

    expiredButton.options.iconSettings._cssClass =
      generateClassName("expiredButtonIcon");
    expiredImage.options._cssClass = generateClassName("expiredImage");
    expiredImage.options._cssClass = generateClassName("expiredImage");
    expiredButton.options._cssClass = generateClassName("expiredButton");
    expiredDescription.options._cssClass =
      generateClassName("expiredDescription");
    expiredTitle.options._cssClass = generateClassName("expiredTitle");

    return pipeSync<string>(
      generateImageCssString(expiredImage as IWidgetField),
      generateStringWithStates(expiredTitle, true),
      generateStringDefault(expiredDescription),
      generateStageButtonCssString(expiredButton as IWidgetField)
    )(cssString);
  };
