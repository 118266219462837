import {
  Alignment,
  FillType,
  Grid,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

const tabsStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    itemBorder: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    text: {
      color: {
        color: "#000000",
        opacity: 100,
      },
    },
  };
};

export const TABS_INITIAL = () => {
  return {
    options: {
      fill: {
        color: "",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      itemBorder: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      display: {
        resizing: ResizingType.HUG,
        alignment: "left",
      },
      gap: 4,
      text: {
        color: {
          color: "#000000",
          opacity: 100,
        },
        theme: 12,
        fontFamily: getDefaultFontFamily(),
        decoration: ["bold"],
        alignment: "left",
      },
      states: {
        [State.HOVER]: tabsStatesInitial(),
        [State.ACTIVE]: tabsStatesInitial(),
      },
    },
  };
};

export const PROMO_LIST_INITIAL = () => {
  return {
    options: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      gap: 12,
    },
  };
};

const cardStatesInitial = () => {
  return {
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
  };
};

export const PROMO_CARD_INITIAL = () => {
  return {
    options: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "#ffffff",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      gap: 4,
      layoutPosition: {
        position: "bottom",
        alignment: "center center",
      },
      containerSize: {
        height: {
          type: ResizingType.FILL,
          value: 272,
        },
      },
      states: {
        [State.HOVER]: cardStatesInitial(),
      },
    },
  };
};

const buttonStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    buttonTextSettings: {
      textColor: getDefaultFillValue("#000000"),
    },
    iconSettings: {
      textColor: getDefaultFillValue("#000000"),
    },
  };
};

export const PROMO_BUTTON_INITIAL = () => {
  return {
    options: {
      _active: true,
      fill: {
        color: "#1677FF",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(6),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      buttonDisplaySettings: {
        resizing: "fill",
        alignment: "center",
      },
      buttonTextSettings: {
        _active: true,
        textColor: {
          color: "#ffffff",
          opacity: 100,
        },
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
      },
      iconSettings: {
        _active: false,
        default_value: "ant-design:left-outlined",
        textColor: getDefaultFillValue("#000000"),
        iconSize: 20,
        position: Alignment.LEFT,
        gap: 8,
      },
      states: {
        [State.HOVER]: buttonStatesInitial(),
        [State.ACTIVE]: buttonStatesInitial(),
        [State.DISABLED]: buttonStatesInitial(),
      },
    },
  };
};

export const PROMO_GROUP_LIST_INITIAL = () => {
  return {
    options: {
      fill: {
        color: "",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      gridSettings: {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      },
    },
  };
};
