<template>
  <div class="controls-list">
    <div
      v-for="(control, index) in controls"
      :key="index"
      class="controls-list__control"
    >
      <template
        v-if="
          control.controls &&
          (control.visible === true || control.visible === undefined)
        "
      >
        <p
          v-if="control.section"
          class="settings-section-title controls-list__title p-l-16 p-t-16"
        >
          <ConfigToggle
            v-if="control.toggleable && control.valueSource"
            size="small"
            :model-value="control.valueSource?._active"
            :label="control.section"
            :allow-disable="
              control.allowDisable === undefined || control.allowDisable
            "
            :show-options="false"
            is-field-item-toggle
            @update:model-value="
              handleUpdateActiveValue('_active', $event, control.valueSource)
            "
          />

          <template v-else>
            {{ control.section }}
          </template>

          <span
            v-if="control.showConfig"
            class="p-r-16"
          >
            <CommonIcon
              name="ant-design:setting-outlined"
              class="controls-list__config-icon pointer"
              @click="handleConfigClick(control)"
          /></span>
        </p>

        <div
          v-if="
            (control.toggleable === undefined ||
              control.valueSource?._active) &&
            (control.section || control.withoutSectionLabel)
          "
          class="controls-list__section-controls"
        >
          <ControlElement
            v-for="(childControl, innerIndex) in control.controls"
            :key="`${index}-${innerIndex}-${childControl.componentPath}`"
            :control="childControl"
          />
        </div>
      </template>

      <ControlElement
        v-else
        :key="control.componentPath"
        :control="control"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";

defineProps<{
  controls: ControlProp[];
  widget: IWidgetWithFields;
}>();

const handleUpdateActiveValue = (
  prop: string,
  value: boolean,
  path: Record<string, any>
) => {
  if (path[prop] !== null && path[prop] !== undefined) {
    path[prop] = value;
  }
};

const handleConfigClick = (control: ControlProp): void => {
  if (!control.onConfigClick) {
    return;
  }

  control.onConfigClick();
};
</script>

<style lang="scss">
.controls-list {
  overflow: auto;
  max-height: calc(100vh - 214px);

  &__section-control {
    .widget-control-container {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }

  &__section-controls {
    border-bottom: 1px solid $c-grey-15;
    padding-bottom: $space-m;

    .widget-control-container {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }

  &__title {
    padding-top: $space-m;
  }

  &__config-icon {
    color: $c-grey-45;
    font-size: 14px;
  }

  &__title {
    @include flex(center, space-between, 0);
  }
}
</style>
