import { State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useRacingSportsLiveBetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const betItemDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.players_info_player_current_odds.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              label: "Padding",
            },
          },
        ],
      ],
    });
  });

  const betItemContentControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.players_info_player_current_odds.field,
        },
        "Text"
      ),
    ];
  });

  const currentStatePath = computed<string>(() => {
    if (states.value.players_info_player_current_odds === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.players_info_player_current_odds}`;
  });

  const betItemStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.players_info_player_current_odds,
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
          {
            label: STATE_KEY_LABELS.up,
            value: State.UP,
          },
          {
            label: STATE_KEY_LABELS.down,
            value: State.DOWN,
          },
        ],
        onUpdate: value => {
          emit("update-state", {
            state: "players_info_player_current_odds",
            value,
          });
        },
      }),
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: fields.value.players_info_player_current_odds.field,
        options: {
          placeholder: "Enter text",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: fields.value.players_info_player_current_odds.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.color`,
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.arrow.color`,
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow color",
            },
          },
        ],
      },
    ];
  });

  return {
    betItemDesignControls,
    betItemContentControls,
    betItemStatesControls,
  };
};
