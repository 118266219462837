<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #field_container-design>
          <ControlsList
            :widget="widget"
            :controls="fieldContainerDesignControls"
          />
        </template>

        <template #field_container-content>
          <ControlsList
            :widget="widget"
            :controls="fieldContainerContentControls"
          />
        </template>

        <template #field_container-states>
          <ControlsList
            :widget="widget"
            :controls="fieldContainerStatesControls"
          />
        </template>

        <template #search_result_box>
          <ControlsList
            :widget="widget"
            :controls="searchResultBoxControls"
          />
        </template>

        <template #search_result_items-design>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsDesignControls"
          />
        </template>

        <template #search_result_items-content>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsContentControls"
          />
        </template>

        <template #search_result_items-states>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useSearchFieldConfig } from "~~/composables/widgets/search/field/useSearchFieldConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "searchResultsPageURL": {
      label: "Search Results page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  fieldContainerDesignControls,
  fieldContainerContentControls,
  fieldContainerStatesControls,

  searchResultBoxControls,

  searchResultItemsDesignControls,
  searchResultItemsContentControls,
  searchResultItemsStatesControls,
} = useSearchFieldConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
