import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

export const useSportsbookItems = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const currentWidgetState = computed(() => states.value.widget);

  const SETTINGS_ITEMS = computed<ConfigTabItem[]>(() => {
    let result: ConfigTabItem[] = [
      {
        label: "Header",
        value: "header",
      },
      {
        label: "Tabs",
        value: "items",
        isActiveValueSource: fields.value.items.field.options,
      },
      {
        label: "Events filter",
        value: "filter_container",
        isActiveValueSource: fields.value.filter_container.field.options,
      },
      {
        label: "Events List",
        value: "events_container",
      },
    ];

    if (currentWidgetState.value === State.SPORTSBOOK_COUNTRY) {
      result = [
        {
          label: "Header",
          value: "header",
        },
        {
          label: "Country Leagues",
          value: "leagues_container",
          isActiveValueSource: fields.value.leagues_container.field.options,
        },
        {
          label: "Events filter",
          value: "filter_container",
          isActiveValueSource: fields.value.filter_container.field.options,
        },
        {
          label: "Events List",
          value: "events_container",
        },
      ];
    }

    if (currentWidgetState.value === State.SPORTSBOOK_LEAGUE) {
      result = [
        {
          label: "Header",
          value: "header",
        },
        {
          label: "Tabs",
          value: "items",
          isActiveValueSource: fields.value.items.field.options,
          states: {
            value: states.value.items,
            items: [
              {
                label: STATE_KEY_LABELS.events,
                value: State.SPORTSBOOK_TABS_EVENTS,
              },
              {
                label: STATE_KEY_LABELS.leagueWinner,
                value: State.SPORTSBOOK_TABS_LEAGUE_WINNER,
              },
            ],
          },
        },
      ];

      if (!fields.value.items.active) {
        result.push(
          ...[
            {
              label: "Country Leagues",
              value: "leagues_container",
              isActiveValueSource: fields.value.leagues_container.field.options,
            },
            {
              label: "League winner",
              value: "winner_container",
            },
            {
              label: "Events filter",
              value: "filter_container",
              isActiveValueSource: fields.value.filter_container.field.options,
            },
            {
              label: "Events List",
              value: "events_container",
            },
          ]
        );
        return result;
      }

      if (states.value.items === State.SPORTSBOOK_TABS_EVENTS) {
        result.push(
          ...[
            {
              label: "Country Leagues",
              value: "leagues_container",
              isActiveValueSource: fields.value.leagues_container.field.options,
            },
            {
              label: "Events filter",
              value: "filter_container",
              isActiveValueSource: fields.value.filter_container.field.options,
            },
            {
              label: "Events List",
              value: "events_container",
            },
          ]
        );
      } else if (states.value.items === State.SPORTSBOOK_TABS_LEAGUE_WINNER) {
        result.push({
          label: "League winner",
          value: "winner_container",
        });
      }
    }

    if (currentWidgetState.value === State.SPORTSBOOK_TOP_LEAGUES) {
      result = [
        {
          label: "Header",
          value: "header",
        },
        {
          label: "Tabs",
          value: "items",
          isActiveValueSource: fields.value.items.field.options,
        },
        {
          label: "Events filter",
          value: "filter_container",
          isActiveValueSource: fields.value.filter_container.field.options,
        },
        {
          label: "Top League Events",
          value: "top_leagues_events_container",
        },
      ];
    }

    if (currentWidgetState.value === State.SPORTSBOOK_ALL_LEAGUES) {
      result = [
        {
          label: "Header",
          value: "header",
        },
        {
          label: "Tabs",
          value: "items",
          isActiveValueSource: fields.value.items.field.options,
        },
        {
          label: "Search",
          value: "search_container",
        },
        {
          label: "Leagues List",
          value: "search_result_items",
        },
      ];
    }

    return result;
  });

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Header", "header", "header"),
    generateDropdownItem("Title", "title", "header"),
    generateDropdownItem("Button", "live_button_title", "header"),
    generateDropdownItem("Title", "winner_league_title", "winner_league"),
    generateDropdownItem(
      "League winner card",
      "winner_league_card",
      "winner_league"
    ),
    generateDropdownItem(
      "Bet items",
      "winner_league_bet_items",
      "winner_league"
    ),
    generateDropdownItem("Events title", "list_title", "events_list"),
    generateDropdownItem("Events filter settings", "filter", "events_list"),
    generateDropdownItem("Event cards", "list_container", "events_list"),
    generateDropdownItem(
      "Top Leagues Event cards",
      "top_leagues_list_container",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Start time", "list_start_time", "events_list"),
    generateDropdownItem(
      "Top Leagues Start time / date",
      "top_leagues_list_start_time",
      "top_leagues_events_list"
    ),
    generateDropdownItem("League Title", "league_title", "events_list"),
    generateDropdownItem(
      "Top Leagues Title",
      "top_leagues_league_title",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Team / Player", "list_event_name", "events_list"),
    generateDropdownItem(
      "Top Leagues Team / Player",
      "top_leagues_list_event_name",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Bet items", "list_bet_item", "events_list"),
    generateDropdownItem(
      "Top Leagues Bet items",
      "top_leagues_list_bet_item",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Event info", "list_event_info", "events_list"),
    generateDropdownItem(
      "Top Leagues Event info",
      "top_leagues_list_event_info",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Favorite icon", "list_favourite_icon", "events_list"),
    generateDropdownItem(
      "Top Leagues Favorite icon",
      "top_leagues_list_favourite_icon",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Date", "list_date", "events_list"),
    generateDropdownItem(
      "Top Leagues Date",
      "top_leagues_list_date",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Tabs", "items", "items"),
    generateDropdownItem("Tab items", "itemsGroup"),
    generateDropdownItem("Events", "events", "events"),
    generateDropdownItem("Top Leagues", "topLeagues", "topLeagues"),
    generateDropdownItem("All Leagues", "allLeagues", "allLeagues"),
    generateDropdownItem("League Winner", "leagueWinner", "leagueWinner"),
    generateDropdownItem("Search Title", "search_title", "search"),
    generateDropdownItem("Search Field", "search_field", "search"),
    generateDropdownItem("Leagues List", "search_result_items", "search"),
    generateDropdownItem("Search", "search_container", "search"),
    generateDropdownItem("Search Box", "search_box", "search"),

    generateDropdownItem("Build button", "betbuilder_button", "events_list"),
    generateDropdownItem(
      "Top Leagues Build button",
      "top_leagues_betbuilder_button"
    ),
    generateDropdownItem("Event button link", "list_item_more", "events_list"),
    generateDropdownItem(
      "Top Leagues Event button link",
      "top_leagues_list_item_more"
    ),
    generateDropdownItem("From Date", "date_from", "filter"),
    generateDropdownItem("To Date", "date_to", "filter"),
    generateDropdownItem("Markets", "markets", "filter"),
    generateDropdownItem("Leagues", "leagues_container"),
    generateDropdownItem("Items", "league_card"),
    generateDropdownItem("League winner", "winner_container", "winner_league"),
    generateDropdownItem("Events filter", "filter_container"),
    generateDropdownItem("Events", "events_container", "events_list"),
    generateDropdownItem(
      "Top Leagues Events",
      "top_leagues_events_container",
      "top_leagues_events_list"
    ),
    generateDropdownItem("League Name", "league_name", "events_list"),
    generateDropdownItem(
      "Top Leagues Name",
      "top_leagues_league_name",
      "top_leagues_events_list"
    ),
    generateDropdownItem("Date", "day_label", "events_list"),
    generateDropdownItem(
      "Top Leagues Date",
      "top_leagues_day_label",
      "top_leagues_events_list"
    ),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_button_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    winner_league_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    winner_league_bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    filter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
    ],
    list_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    top_leagues_list_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    list_bet_item: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    top_leagues_list_bet_item: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    list_item_more: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    top_leagues_list_item_more: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betbuilder_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    top_leagues_betbuilder_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    leagues_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    league_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    winner_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    filter_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    top_leagues_events_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    list_event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    top_leagues_list_event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    search_field: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    search_result_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    search_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    search_box: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    day_label: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields).filter(
        el =>
          el.value !== "title_styling" && el.value !== "field_success_styling"
      ),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
