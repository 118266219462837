<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsMenuWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      :menu-fields="menuFields"
      :is-live="true"
      @reset-states="resetStates"
    />

    <div class="sports-menu-live-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <div :style="itemsStyles">
        <WidgetField
          :widget="widget"
          :field="fields.menu_level_1.field"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-widget__container_1">
              <div class="sports-menu-live-widget__element_1">
                <a-sub-menu
                  key="sub1"
                  class="sports-menu-live-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-widget__container_1">
              <div class="sports-menu-live-widget__element_1">
                <a-sub-menu
                  key="sub2"
                  class="sports-menu-live-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-widget__container_1">
              <div class="sports-menu-live-widget__element_1">
                <a-sub-menu
                  key="sub3"
                  class="sports-menu-live-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-live-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.menu_level_2.field"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-live-widget__container_2">
              <div class="sports-menu-live-widget__element_2">
                <a-sub-menu
                  key="sub2-1"
                  class="sports-menu-live-widget__menu_level_2"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_2.field.options.itemIcon._active"
                      name="custom:country-icon"
                    />
                  </template>
                  <template #title>League</template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-widget__container_2">
              <div class="sports-menu-live-widget__element_2">
                <a-sub-menu
                  key="sub2-2"
                  class="sports-menu-live-widget__menu_level_2"
                >
                  <template #icon>
                    <span
                      v-if="fields.menu_level_2.field.options.itemIcon._active"
                      class="sports-menu-live-widget__2__icon"
                    >
                      <CommonIcon name="custom:country-icon" />
                    </span>
                  </template>
                  <template #title>League</template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-live-widget__container_2">
              <div class="sports-menu-live-widget__element_2">
                <a-sub-menu
                  key="sub2-3"
                  class="sports-menu-live-widget__menu_level_2"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_2.field.options.itemIcon._active"
                      name="custom:country-icon"
                    />
                  </template>
                  <template #title>League</template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSportsMenuStyles } from "~~/composables/widgets/sports-menu/useSportsMenuStyles";
import { useSportsMenuSharedLogic } from "~~/composables/widgets/sports-menu/useSportsMenuSharedLogic";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const DEFAULT_STATES = {
  menu_level_1: State.DEFAULT,
  menu_level_2: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const { itemsStyles } = useSportsMenuStyles(props.widget, states, true);

const selectedKeys = ref<string[]>([""]);
const openKeys = ref<string[]>(["sub3", "sub3-3"]);
const menuFields = ["menu_level_1", "menu_level_2"];

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { itemFirstLevelIcon, fields } = useSportsMenuSharedLogic(
  ref(props.widget),
  states,
  true
);
</script>

<style lang="scss">
.sports-menu-live-widget {
  .ant-menu,
  .ant-sub-menu {
    background-color: transparent !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    max-width: 100% !important;
    height: initial !important;
    line-height: 1 !important;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-menu {
    border: none !important;
  }

  .ant-menu-item {
    &::after {
      display: none !important;
    }
  }

  .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled,
  .ant-menu-submenu-title {
    cursor: pointer !important;
    transition: all 0s !important;
    line-height: 1 !important;
  }

  .ant-menu-title-content {
    margin: 0 !important;
  }

  &__menu_level_1 {
    & > .ant-menu-submenu-title {
      background-color: var(--menu_level_1-backgroundColor) !important;
      padding-top: var(--menu_level_1-padding-top) !important;
      padding-left: var(--menu_level_1-padding-left) !important;
      padding-bottom: var(--menu_level_1-padding-bottom) !important;
      padding-right: var(--menu_level_1-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_1-borderRadius) !important;
      border-width: var(--menu_level_1-borderWidth) !important;
      border-color: var(--menu_level_1-borderColor) !important;
      border-style: var(--menu_level_1-borderStyle) !important;
      box-shadow: var(--menu_level_1-boxShadow) !important;

      /* Content */
      color: var(--menu_level_1-color) !important;
      font-size: var(--menu_level_1-fontSize) !important;
      font-family: var(--menu_level_1-fontFamily) !important;
      font-weight: var(--menu_level_1-fontWeight) !important;
      font-style: var(--menu_level_1-fontStyle) !important;
      text-decoration: var(--menu_level_1-textDecoration) !important;

      .ant-menu-item-icon {
        margin-right: var(--menu_level_1-spaceBetweenTextAndIcon) !important;
      }

      svg {
        fill: var(--menu_level_1-iconColor) !important;
        width: var(--menu_level_1-iconSize) !important;
        height: var(--menu_level_1-iconSize) !important;
      }

      & > .sports-menu-live-widget__expand-icon {
        font-size: var(--menu_level_1-fontSize) !important;
        position: absolute !important;
        right: var(--menu_level_1-padding-right) !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        color: var(--menu_level_1-arrowIconColor) !important;
      }
    }
  }

  &__menu_level_2 {
    & > .ant-menu-submenu-title {
      background-color: var(--menu_level_2-backgroundColor) !important;
      padding-top: var(--menu_level_2-padding-top) !important;
      padding-left: var(--menu_level_2-padding-left) !important;
      padding-bottom: var(--menu_level_2-padding-bottom) !important;
      padding-right: var(--menu_level_2-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_2-borderRadius) !important;
      border-width: var(--menu_level_2-borderWidth) !important;
      border-color: var(--menu_level_2-borderColor) !important;
      border-style: var(--menu_level_2-borderStyle) !important;
      box-shadow: var(--menu_level_2-boxShadow) !important;

      /* Content */
      color: var(--menu_level_2-color) !important;
      font-size: var(--menu_level_2-fontSize) !important;
      font-family: var(--menu_level_2-fontFamily) !important;
      font-weight: var(--menu_level_2-fontWeight) !important;
      font-style: var(--menu_level_2-fontStyle) !important;
      text-decoration: var(--menu_level_2-textDecoration) !important;

      .ant-menu-item-icon {
        margin-right: var(--menu_level_2-spaceBetweenTextAndIcon) !important;
      }

      svg {
        fill: var(--menu_level_2-iconColor) !important;
        width: var(--menu_level_2-iconSize) !important;
        height: var(--menu_level_2-iconSize) !important;
      }

      & > .sports-menu-live-widget__expand-icon {
        font-size: var(--menu_level_2-fontSize) !important;
        position: absolute !important;
        right: var(--menu_level_2-padding-right) !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        color: var(--menu_level_2-arrowIconColor) !important;
      }
    }
  }

  &__container_1 {
    padding-bottom: var(--menu_level_1-marginBottom) !important;
  }

  &__container_2 {
    padding-bottom: var(--menu_level_2-marginBottom) !important;
  }
}
</style>
