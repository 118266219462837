<template>
  <WidgetSettingsNew
    :dropdown-items="dropdownItems"
    :tabs="tabs"
    :custom-active-option-selector="findActiveOption"
    @select="handleSelectDropdownItem"
    @blur-tab="handleBlurTab"
    @change="handleChangeField"
    @update-state="$emit('update-state', $event)"
  >
    <template #widgetSettings-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.widgetDesignSettingsControls.value"
      />
    </template>

    <template #widgetSettings-content>
      <ControlsList
        :widget="widget"
        :controls="
          getCustomControls(
            controlsData.formSettingsContentControls.value,
            'widgetSettings-content'
          )
        "
      />
    </template>

    <template
      v-if="bindingParams"
      #widgetSettings-data
    >
      <WidgetDynamicParams
        :widget="widget"
        :initial-value="bindingParams"
      />
    </template>

    <!-- ##### -->

    <template #title_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formTitleStyles.value"
      />
    </template>

    <template #labels_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formLabelsStyles.value"
      />
    </template>

    <template #tooltips_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formTooltipDesignStyles.value"
      />
    </template>

    <template #tooltips_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formTooltipContentStyles.value"
      />
    </template>

    <template #fields_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formFieldDesignStyles.value"
      />
    </template>

    <template #fields_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formFieldContentStyles.value"
      />
    </template>

    <template #fields_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formFieldStates.value"
      />
    </template>

    <template #errors_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formErrorStyles.value"
      />
    </template>

    <template #checkboxes_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.checkboxButtonDesignControls"
      />
    </template>

    <template #checkboxes_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.checkboxButtonContentControls.value"
      />
    </template>

    <template #checkboxes_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.checkboxButtonStatesControls.value"
      />
    </template>

    <template #radio_group_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.radioButtonDesignControls.value"
      />
    </template>

    <template #radio_group_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.radioButtonContentControls.value"
      />
    </template>

    <template #radio_group_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.radioButtonStatesControls.value"
      />
    </template>

    <template #calendar_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formCalendarDesignStyles.value"
      />
    </template>

    <template #calendar_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formCalendarContentStyles.value"
      />
    </template>

    <template #calendar_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formCalendarStates.value"
      />
    </template>

    <template #datepicker_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDatePickerStyles.value"
      />
    </template>

    <template #dropdowns_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDropdownStyles.value"
      />
    </template>

    <template #dropdown_menu_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDropdownMenuStyles.value"
      />
    </template>

    <template #dropdown_menu_items_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDropdownMenuItemsDesignStyles.value"
      />
    </template>

    <template #dropdown_menu_items_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDropdownMenuItemsContentStyles.value"
      />
    </template>

    <template #dropdown_menu_items_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formDropdownMenuItemsStates.value"
      />
    </template>

    <!-- 
      Config content
      -->
    <template #TextInputField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textFieldControls.value"
      />
    </template>

    <template #FormTextField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormTextInputField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormNumberField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormPasswordField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormEmailField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormDropdownField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.dropdownFieldControls.value"
      />
    </template>

    <template #FormCheckboxField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.checkboxFieldControls.value"
      />
    </template>

    <template #FormRadioField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.radioGroupFieldControls.value"
      />
    </template>

    <template #FormDateField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #FormDocumentUploadField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.uploadFieldControls.value"
      />
    </template>

    <template #FormTelField>
      <ControlsList
        :widget="widget"
        :controls="controlsData.textInputFieldControls.value"
      />
    </template>

    <template #ButtonLinkField-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.buttonDesignControls.value"
      />
    </template>

    <template #ButtonLinkField-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.buttonContentControls.value"
      />
    </template>

    <template #ButtonLinkField-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.buttonStatesControls.value"
      />
    </template>

    <template #TextEditorField>
      <FormDescriptionSettings
        :is-bold="false"
        @update-field-option="updateFieldOption"
      />
    </template>

    <!-- 
      Upload 
      -->
    <template #upload_caption_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formUploadCaptionStyles.value"
      />
    </template>

    <template #upload_list_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formUploadListStyles.value"
      />
    </template>

    <template #upload_items_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formUploadItemDesignStyles.value"
      />
    </template>

    <template #upload_items_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formUploadItemContentStyles.value"
      />
    </template>

    <template #upload_items_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.formUploadItemStatesStyles.value"
      />
    </template>

    <template #upload_button_styling-design>
      <ControlsList
        :widget="widget"
        :controls="controlsData.uploadButtonDesignControls.value"
      />
    </template>

    <template #upload_button_styling-content>
      <ControlsList
        :widget="widget"
        :controls="controlsData.uploadButtonContentControls.value"
      />
    </template>

    <template #upload_button_styling-states>
      <ControlsList
        :widget="widget"
        :controls="controlsData.uploadButtonStatesControls.value"
      />
    </template>

    <template
      v-for="formName in formsList"
      #[`${formName}-design`]
      :key="formName"
    >
      <ControlsList
        :widget="widget"
        :controls="controlsData.formElementDesignControls.value"
      />
    </template>

    <template
      v-for="formName in formsList"
      #[`${formName}-content`]
      :key="formName"
    >
      <ControlsList
        :widget="widget"
        :controls="controlsData.formElementContentControls.value"
      />
    </template>

    <template #field_success_styling>
      <ControlsList
        :widget="widget"
        :controls="controlsData.successFieldControls.value"
      />
    </template>

    <template
      v-for="formName in formsList"
      #[`${formName}-styles`]
      :key="formName"
    >
      <ControlsList
        :widget="widget"
        :controls="controlsData.formElementStylesControls.value"
      />
    </template>
  </WidgetSettingsNew>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useNewFormWidgetConfig } from "~~/composables/widgets/form/useNewFormWidgetConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  views: { label: string; value: string }[];
  dropdownItems: DropdownItem[];
  tabs: Record<string, TabItem>;
  formsElements: Record<string, string[]>;
  activeView: string;
  bindingParams?: Record<string, any>;
  customControls?: Record<string, (controls: ControlProp[]) => ControlProp[]>;
}>();

const emit = defineEmits<{
  (e: "update-active-view", event: string): void;
  (e: "update-button-state", event: string): void;
  (e: "select", event: string | null): void;
  (e: "reset-states"): void;
  (e: "update-state", event: { state: string; value: State }): void;
}>();

const states = toRef(() => props.states);
const widget = toRef(() => props.widget);

const store = useWidgetsStore();
const widgetsStore = useWidgetSettingsStore();
const { selectedField, selectedDropdownItem } = storeToRefs(widgetsStore);
const fields = useWidgetFields(widget.value);

const formsList = computed<string[]>(() => {
  return Object.keys(props.formsElements);
});

const currentFormField = computed<IWidgetField | null>(() => {
  if (formsList.value.includes(props.activeView)) {
    return fields.value[props.activeView].field;
  }

  return null;
});

const activeViewValue = computed<string>(() => {
  return props.activeView;
});

const controlsData = useNewFormWidgetConfig({
  formElementField: currentFormField,
  activeView: activeViewValue,
  formsElements: props.formsElements,
  widget,
  states,
  emit,
  viewsList: props.views,
});

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const handleSelectDropdownItem = (item: DropdownItem) => {
  emit("select", item.value as string | null);

  const selectedItem = props.dropdownItems.find(
    curr => curr.custom?.fieldId === item?.custom?.fieldId
  );

  if (!selectedItem || !selectedItem.custom?.fieldId) {
    return;
  }

  const field = props.widget.fields.find(
    f => f.id === selectedItem.custom?.fieldId
  );

  if (!field) {
    return;
  }

  widgetsStore.setActiveElement(props.widget, field, undefined, field.type);
};

const handleBlurTab = (tab: string): void => {
  if (tab === "states") {
    emit("reset-states");
  }
};

const handleChangeField = (): void => {
  emit("reset-states");
};

const findActiveOption = (options: DropdownItem[]): DropdownItem => {
  /*
    If field is not selected - default behaviour
  */
  if (!selectedField.value) {
    return options.find(
      option => option.value === selectedDropdownItem.value
    ) as DropdownItem;
  }

  /*
    Else check if any option has customId same as selectedField
  */
  const optionByCustomId = options.find(
    option => option.custom?.fieldId === selectedField.value?.id
  );

  if (optionByCustomId) {
    return optionByCustomId;
  }

  /*
    If no - again, default behaviour
  */
  return options.find(
    option => option.value === selectedDropdownItem.value
  ) as DropdownItem;
};

const getCustomControls = (
  currentControls: ControlProp[],
  elementName: string
) => {
  if (props.customControls?.[elementName]) {
    return props.customControls[elementName](currentControls);
  }

  return currentControls;
};
</script>
