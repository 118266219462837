import { useI18n } from "vue-i18n";

import { TranslationCategory } from "~~/models/stores/static-translation-store.model";
import { useLanguagesStore } from "~~/store/languages";
import { useStaticTranslations } from "~~/store/staticTranslations";
import { DEFAULT_ACCEPT_LANG } from "~~/constants";

import { useFetchTranslations } from "./useFetchTranslations";

export const useI18nStaticTranslations = async (
  category: TranslationCategory[]
) => {
  const languagesStore = useLanguagesStore();
  const staticTranslationsStore = useStaticTranslations();

  const { staticTranslations } = storeToRefs(staticTranslationsStore);
  const i18n = useI18n();

  const { locale, fallbackLocale } = i18n;

  await languagesStore.fetchAvailableLanguages();

  if (languagesStore.currentLanguage) {
    locale.value = languagesStore.currentLanguage.codeWithRegion;
    fallbackLocale.value =
      languagesStore.defaultLanguage?.codeWithRegion || DEFAULT_ACCEPT_LANG;

    const fetchPromises = Object.keys(languagesStore.availableLanguages).map(
      async lang => {
        try {
          await useFetchTranslations(
            category,
            languagesStore.availableLanguages[lang]
          );
        } catch (error) {
          console.error(
            `Error fetching translations for language ${lang}:`,
            error
          );
        }
      }
    );

    try {
      await Promise.all(fetchPromises);

      i18n.setLocaleMessage(
        languagesStore.currentLanguage.codeWithRegion,
        staticTranslations.value[languagesStore.currentLanguage.codeWithRegion]
      );
    } catch (error) {
      console.error("Error setting locale message:", error);
    }
  } else {
    console.error("Current language not specified");
  }
};
