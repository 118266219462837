import { useMetaStore } from "~~/store/meta";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 61;

const v61WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    window.location.hostname !== "grid.bavtest4.net" &&
    useMetaStore().projectId !== "018d7e2d-c109-7e08-b765-142f028832ef"
  ) {
    return widget;
  }

  // widget.content = JSON.parse(
  //   JSON.stringify(widget.content).replace(/\{osg\.(.*?)\}/gi, "[:osg.$1]")
  // );

  widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};
export default v61WidgetMigration;
