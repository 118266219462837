import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import {
  Alignment,
  DisplayOrientation,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateStageButtonCssString,
  generateCardCssString,
  generateFlexibleImageCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGap,
  generateGrid,
  generateWidth,
} from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";

import { generateDepositListTooltipStyling } from "./deposit-list-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";

const generateNoProvidersTitleCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const titleStyles =
      generateWidth(100, "%") +
      generateFlex({
        flex: "flex",
        align: "center",
        justify: element.options.alignment,
        direction:
          element.options.iconSettings.position === Alignment.LEFT
            ? "row-reverse"
            : "row",
        gap: element.options.iconSettings.gap,
      }) +
      generateDefaultStyles(element.options);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: titleStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options.iconSettings._cssClass,
      content: element.options.iconSettings,
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

const generateInformationButtonCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateStageButtonCssString(element)(cssString);

    const wrapperStyles =
      generateWidth(100, "%") +
      generateFlex({
        flex: "flex",
        align: "center",
        justify: element.options.buttonDisplaySettings.alignment,
      });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      content: wrapperStyles,
    });

    return cssString;
  };

const generateProviderButtonCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateStageButtonCssString(element)(cssString);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: "max-width:-webkit-fill-available;",
    });

    return cssString;
  };

const generateInformationMessageCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const defaultStyles = generateDefaultStyles(element.options);

    const flexStyle = generateFlex({
      flex: "flex",
      direction:
        element.options.layout === DisplayOrientation.VERTICAL
          ? "column"
          : "row",
      align:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? element.options.alignment
          : "flex-start",
      justify: "unset",
      gap: element.options.gap,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: defaultStyles + flexStyle + generateWidth(100, "%"),
    });

    for (const state in element.options.states) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: element.options.states[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

const generateProvidersListCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const defaultStyles = generateDefaultStyles(element.options);

    const gridStyles = generateGrid({
      rowGap: element.options.rowGap,
      columnGap: element.options.gap,
      templateColumns: `repeat(${element.options.maxCardsInRow}, minmax(0, 1fr))`,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: defaultStyles + gridStyles + generateWidth(100, "%"),
    });

    cssString += generateCssClassWithContent({
      className: element.options._notFilledProfileCssClass,
      content: `opacity:${element.options.insufficientOpacity}%;pointer-events:none!important;`,
    });

    return cssString;
  };

export const generateProvidersTitleCssString =
  (element: IWidgetOptions, isLinkStates: boolean) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(element, isLinkStates);

    const flexStyle = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: element.options.alignment,
      gap: "8",
    });

    cssString += `.${element.options._cssClass}{${
      flexStyle + "flex:1;word-break:break-word;"
    }}`;

    return cssString;
  };

export const generateProvidersDescriptionsCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: "flex:1;" + generateDefaultStyles(element.options),
    });

    return cssString;
  };

export const generateCardImageAlignmentCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " .card-element",
      content: "align-items:center;",
    });

    return cssString;
  };

export const generatePaymentProvidersListFenixCss = (
  widget: IWidgetOptions
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  const cardLayout = prefilled.provider_card.options.layoutPosition.position;
  let cardsStretchStyle = `.${widget.options._cssClass} .${prefilled.provider_card.options._cssClass} .card-element:last-child{align-self:stretch;`;

  if (cardLayout === TextPosition.BOTTOM || cardLayout === TextPosition.TOP) {
    cardsStretchStyle += `flex:1;`;
  }

  cardsStretchStyle += "}";

  const cardsGapStyles = `.${widget.options._cssClass} .${
    prefilled.provider_card.options._cssClass
  }{${generateGap(prefilled.provider_card.options.gap)}}`;

  const cardsTextGapStyles = `.${widget.options._cssClass} .${
    prefilled.provider_card.options._cssClass
  } .card-element__text{${generateGap(prefilled.provider_card.options.gap)}}`;

  prefilled.tooltip.options._triggerCssClass =
    generateClassName("tooltip-trigger");

  prefilled.provider_button.options.iconSettings._cssClass = generateClassName(
    "provider-button-icon"
  );
  prefilled.information_button.options.iconSettings._cssClass =
    generateClassName("information-button-icon");

  prefilled.information_button.options._wrapperCssClass = generateClassName(
    "information-button-wrapper"
  );

  prefilled.providers_list.options._notFilledProfileCssClass =
    generateClassName("providers-list-not-filled-profile");

  prefilled.no_providers_title.options.iconSettings._cssClass =
    generateClassName("no-providers-icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(prefilled.page_title),
    generateNoProvidersTitleCssString(
      prefilled.no_providers_title as IWidgetField
    ),

    generateProvidersListCssString(prefilled.providers_list),
    generateFlexibleImageCssString(prefilled.provider_image as IWidgetField),
    generateStringDefault(prefilled.provider_name),
    generateProvidersDescriptionsCssString(prefilled.provider_description),
    generateProviderButtonCssString(prefilled.provider_button as IWidgetField),

    generateStringDefault(prefilled.information_title),
    generateStringDefault(prefilled.information_description),
    generateInformationButtonCssString(
      prefilled.information_button as IWidgetField
    ),

    generateDepositListTooltipStyling(prefilled.tooltip),

    generateInformationMessageCssString(prefilled.information_message),
    generateCardCssString(
      prefilled.provider_card,
      cardsGapStyles + cardsStretchStyle + cardsTextGapStyles
    ),
    generateCardImageAlignmentCssString(prefilled.provider_card),

    generateGridLayoutCssString(prefilled.providers_list)
  )("");
};
