<template>
  <div
    v-if="hasDesignPermissions"
    class="config-corner-radius widget-control-container p-l-16 p-r-16 p-b-16 p-t-16"
  >
    <p
      class="b-n-4 m-b-0"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>

    <ConfigRadioTextElements
      :model-value="modelValue.type"
      :items="[
        { label: 'Dependent', value: BorderStrokeType.DEPENDENT },
        { label: 'Custom', value: BorderStrokeType.CUSTOM },
      ]"
      class="border-bottom-none p-b-0 p-l-0 p-r-0"
      @update:model-value="handleUpdateStrokeType($event)"
    />

    <div
      v-if="modelValue.type === BorderStrokeType.DEPENDENT"
      class="p-t-8 config-corner-radius__input-container"
    >
      <span class="config-corner-radius__square-element">
        <CommonIcon name="custom:radius-icon" />
      </span>
      <a-input-number
        v-number
        :value="modelValue?.value"
        :min="0"
        :max="MAX_CORNER_RADIUS_NUMBER_INPUT_VALUE"
        :controls="false"
        placeholder="None"
        size="small"
        class="widget-control__input config-corner-radius__input"
        @update:value="handleUpdateValue($event)"
      />
    </div>

    <div
      v-if="modelValue.type === BorderStrokeType.CUSTOM"
      class="p-t-8 config-corner-radius__inputs-container"
    >
      <div class="config-corner-radius__input-container">
        <span class="config-corner-radius__square-element">
          <CommonIcon name="custom:radius-top-left-icon" />
        </span>

        <a-input-number
          v-number
          :value="(modelValue.value as ICornerValueComplex).topLeft"
          :min="0"
          :max="MAX_NUMBER_INPUT_VALUE"
          :controls="false"
          placeholder="None"
          size="small"
          class="widget-control__input config-corner-radius__input"
          @update:value="handleUpdateValue({ topLeft: $event })"
        />
      </div>

      <div class="config-corner-radius__input-container">
        <span class="config-corner-radius__square-element">
          <CommonIcon name="custom:radius-top-right-icon" />
        </span>
        <a-input-number
          v-number
          :value="(modelValue.value as ICornerValueComplex).topRight"
          :min="0"
          :max="MAX_NUMBER_INPUT_VALUE"
          :controls="false"
          placeholder="None"
          size="small"
          class="widget-control__input config-corner-radius__input"
          @update:value="handleUpdateValue({ topRight: $event })"
        />
      </div>

      <div class="config-corner-radius__input-container">
        <span class="config-corner-radius__square-element">
          <CommonIcon name="custom:radius-bottom-left-icon" />
        </span>
        <a-input-number
          v-number
          :value="(modelValue.value as ICornerValueComplex).bottomRight"
          :min="0"
          :max="MAX_NUMBER_INPUT_VALUE"
          :controls="false"
          placeholder="None"
          size="small"
          class="widget-control__input config-corner-radius__input"
          @update:value="
            handleUpdateValue({
              bottomRight: $event,
            })
          "
        />
      </div>

      <div class="config-corner-radius__input-container">
        <span class="config-corner-radius__square-element">
          <CommonIcon name="custom:radius-bottom-right-icon" />
        </span>
        <a-input-number
          v-number
          :value="(modelValue.value as ICornerValueComplex).bottomLeft"
          :min="0"
          :max="MAX_NUMBER_INPUT_VALUE"
          :controls="false"
          placeholder="None"
          size="small"
          class="widget-control__input config-corner-radius__input"
          @update:value="
            handleUpdateValue({
              bottomLeft: $event,
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import { MAX_CORNER_RADIUS_NUMBER_INPUT_VALUE } from "~~/constants/widget-config";
import {
  BorderStrokeType,
  ICornerRadiusControl,
  ICornerValueComplex,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: ICornerRadiusControl;
  label?: string;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: ICornerRadiusControl): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: ICornerRadiusControl): void => {
  emit("update:modelValue", value);
};

const handleUpdateStrokeType = (value: BorderStrokeType): void => {
  if (value === BorderStrokeType.DEPENDENT) {
    handleUpdate({
      type: BorderStrokeType.DEPENDENT,
      value: 1,
    });
    return;
  }

  handleUpdate({
    type: BorderStrokeType.CUSTOM,
    value: {
      topLeft: 1,
      topRight: 1,
      bottomRight: 1,
      bottomLeft: 1,
    },
  });
};

const handleUpdateValue = (
  value: Partial<ICornerRadiusControl["value"]>
): void => {
  if (typeof value === "object") {
    const currValue = (props.modelValue?.value || {}) as ICornerValueComplex;

    handleUpdate({
      ...props.modelValue,
      value: {
        ...currValue,
        ...value,
      },
    });

    return;
  }

  handleUpdate({
    ...props.modelValue,
    value: value,
  });

  return;
};
</script>

<style lang="scss">
.config-corner-radius {
  &__input {
    width: 100% !important;
  }

  &__input-container {
    @include flex(center, flex-start, 6px);
  }

  &__inputs-container {
    @include flex(center, flex-start, 4px);
    flex-wrap: wrap;

    & > div {
      flex-basis: calc(50% - 2px);
    }
  }

  &__square-element {
    font-size: 20px;
  }
}
</style>
