import { ComputedRef } from "vue";

import { preDefinedWidthValues } from "~~/constants/cell-params";
import { ICellSettings, Sizing } from "~~/models/grid.interface";

// a helper type
type StyleObj = { flex?: string; width?: string };

export function useStyles(cellSettings: ICellSettings): ComputedRef<StyleObj> {
  return computed(() => {
    let styles: StyleObj = {};
    if (
      cellSettings.width &&
      preDefinedWidthValues.includes(cellSettings.width)
    )
      return styles;

    /* NOTE: use inline styles only if we don't have pre-defined class */
    if (cellSettings.sizing === Sizing.ADAPTIVE) {
      styles = { flex: `0 1 ${cellSettings.width}%` };
    } else {
      styles = { width: `${cellSettings.width}px` };
    }
    return styles;
  });
}
