<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="handleChange"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #event_card-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardDesignControls"
          />
        </template>

        <template #event_card-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardContentControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>

        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStateControls"
          />
        </template>

        <template #event_live_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventLiveStatusDesignControls"
          />
        </template>

        <template #event_live_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventLiveStatusContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/search-results/useSearchResultsConfig";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
  (e: "change", event: string): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const {
  DROPDOWN_ITEMS,
  TABS,

  /* 
    Controls
  */
  widgetDesignControls,
  widgetContentControls,

  titleControls,

  eventCardDesignControls,
  eventCardContentControls,

  eventTimeControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventButtonLinkDesignControls,
  eventButtonLinkContentControls,
  eventButtonLinkStateControls,

  eventLiveStatusDesignControls,
  eventLiveStatusContentControls,
} = useConfig(toRef(props.widget), toRef(props.states), emit);

const { bindingParams } = useWidgetDynamicParams(
  {
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const resetStates = () => {
  emit("reset-states");
};

const handleChange = (item: string): void => {
  emit("change", item);
  resetStates();
};
</script>
