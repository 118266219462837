<template>
  <div class="casino-game-card">
    <div class="casino-game-card__inner">
      <WidgetField
        :widget="widget"
        :field="photoField"
        class="h-100"
        :style="photoWrapperStyles"
      >
        <img
          class="casino-game-card__img"
          :src="`/casino-games/${photoId}.jpg`"
          :style="photoStyles"
          alt="Game image"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="cardsElementsField"
        class="casino-game-card__items"
        :style="cardElementsContentStyles"
      >
        <WidgetField
          :widget="widget"
          :field="promoTagField"
        >
          <BasicButtonField
            :field="promoTagField"
            :current-state="promoTagState"
            :custom-value="tagButtonName"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="favoriteIconField"
          :style="iconStyles"
        >
          <CommonIcon
            :size="favoriteIconField.options.iconSize"
            :name="
              favoriteIconField.options.default_value ??
              'ant-design:star-outlined'
            "
          />
        </WidgetField>
      </WidgetField>
    </div>

    <WidgetField
      v-if="isHoverState || isHoverContent"
      class="casino-game-card_hover"
      :widget="widget"
      :field="hoverContentField"
      :style="cardHoverContentStyles"
    >
      <WidgetField
        :widget="widget"
        :field="gameTitleField"
      >
        <BasicTitleField
          v-if="gameTitleField.options._active"
          :field="gameTitleField"
          :value="t('widgets.CasinoGamesListWidget.game_title')"
        />
      </WidgetField>

      <div
        class="casino-game-card_hover-btn-group"
        :style="cardHoverBtnGroupStyles"
      >
        <WidgetField
          :widget="widget"
          :field="playMoneyButtonField"
        >
          <BasicButtonField
            :field="playMoneyButtonField"
            :current-state="playMoneyButtonState"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="playFreeButtonField"
        >
          <BasicButtonField
            :field="playFreeButtonField"
            :current-state="playFreeButtonState"
          />
        </WidgetField>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import {
  getSpacing,
  getBackgroundColorStyle,
  getFlexPosition,
  getColorStyle,
  getCornerRadiusStyle,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

const props = defineProps<{
  photoId: Number;
  widget: IWidgetWithFields;
  field: IWidgetField;
  photoField: IWidgetField;
  hoverContentField: IWidgetField;
  gameTitleField: IWidgetField;
  playMoneyButtonField: IWidgetField;
  playFreeButtonField: IWidgetField;
  cardsElementsField: IWidgetField;
  promoTagField: IWidgetField;
  favoriteIconField: IWidgetField;
  state: State;
  playMoneyButtonState: State;
  playFreeButtonState: State;
  promoTagState: State;
  isHoverContent: boolean;
}>();

const { t } = useI18n();

const isHoverState = computed<boolean>(() => props.state === State.HOVER);

const tagButtonName = computed<string>(() => {
  if (props.promoTagState === State.DEFAULT) {
    return t("widgets.CasinoGamesListWidget.promo");
  }
  return `${t(
    `widgets.CasinoGamesListWidget.${STATE_KEY_LABELS[
      props.promoTagState
    ].toLowerCase()}`
  )}`;
});

const photoWrapperStyles = computed<ElementStyle | null>(() => {
  if (props.photoField.options.image) {
    return {
      ...getCommonStyles(props.field.options, props.state),
    };
  }
  return null;
});

const photoStyles = computed<ElementStyle>(() => {
  if (
    props.photoField.options.image?.cornerRadius ||
    props.photoField.options.image?.cornerRadius === 0
  ) {
    return {
      ...(getCornerRadiusStyle(
        props.photoField.options.image.cornerRadius
      ) as ElementStyle),
    };
  }

  return {
    ...getCommonStyles(props.photoField.options),
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.favoriteIconField.options.color),
    zIndex: 1,
  };
});

const cardElementsContentStyles = computed<ElementStyle>(() => {
  const { options } = props.cardsElementsField;

  const mergedPadding = [options.image?.padding ?? {}, options.padding].reduce(
    (acc, obj) => {
      Object.keys(obj).forEach(key => {
        acc[key] = (acc[key] || 0) + obj[key];
      });
      return acc;
    },
    {}
  );

  return {
    display: "flex",
    flexDirection:
      options.display.position === Alignment.RIGHT ? "row" : "row-reverse",
    justifyContent: "space-between",
    alignItems: getFlexPosition(options.display.alignment),
    ...getSpacing(mergedPadding, SpacingKeyName.PADDING),
  };
});

const cardHoverBtnGroupStyles = computed<ElementStyle>(() => {
  const { options } = props.hoverContentField;
  return {
    display: "flex",
    justifyContent: "center",
    flexDirection:
      options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    gap: getPxValueFromNumber(options.gap),
  };
});

const cardHoverContentStyles = computed<ElementStyle>(() => {
  const settings = {
    ...props.hoverContentField.options,
    ...props.field.options.states[State.HOVER],
  };

  if (!settings) return {};

  return {
    display: "flex",
    flexDirection:
      settings.position === TextPosition.TOP ? "column" : "column-reverse",
    justifyContent: getFlexPosition(settings.alignment),
    gap: getPxValueFromNumber(settings.gap),
    ...getBackgroundColorStyle(settings.overlay),
  };
});
</script>

<style lang="scss">
.casino-game-card {
  position: relative;
  &__inner {
    position: relative;
  }
  &_hover,
  &__items {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__inner {
    height: 100%;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
