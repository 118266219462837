<template>
  <div
    v-if="hasContentPermissions"
    class="wconfig-dropdown-title wconfig-item"
  >
    <div class="wconfig-dropdown-title__label-container">
      <p class="wconfig-dropdown-title__label m-b-0 sub-heading">
        {{ label }}
      </p>

      <CommonIcon
        name="ant-design:setting-outlined"
        class="wconfig-dropdown-title__icon"
        @click="handleFieldOptionsClick"
      />
    </div>

    <a-textarea
      show-count
      :rows="1"
      :value="getLocalizedValue(modelValue)"
      :maxlength="MAX_BUTTON"
      class="wconfig-dropdown-title__input f-base"
      @update:value="handleInput"
    />
  </div>
</template>

<script setup lang="ts">
import { MAX_BUTTON } from "~~/constants/input-validation";
import { useText } from "~~/components/composables/fields/text/useText";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getFieldByName } from "~~/assets/utils/widget";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue?: Record<string, string> | string | undefined | null;
  label?: string;
  fieldName?: string;
}>();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const emit = defineEmits<{
  (e: "update:modelValue", event: Record<string, string>): void;
  (e: "update:field-option", event: { [key: string]: any }): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { setLocalizedValue, getLocalizedValue } = useLocalizedValue();

const { canUpdateInputValue } = useText(MAX_BUTTON);

const currentField = computed<IWidgetField | undefined>(() =>
  getFieldByName(selectedWidget.value!, props.fieldName!)
);

const handleFieldOptionsClick = (): void => {
  widgetSettingsStore.setActiveElement(
    selectedWidget.value,
    currentField.value
  );
};

const handleInput = (value: string): void => {
  const updateAvailable: boolean = canUpdateInputValue(value);

  if (!updateAvailable) {
    return;
  }

  emit(
    "update:modelValue",
    setLocalizedValue.value(props.modelValue as Record<string, any>, value)
  );
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.wconfig-dropdown-title {
  @include controls-menu(50%);

  display: flex;
  flex-direction: column;
  gap: $space-m;

  padding: $space-m;

  &__label-container {
    @include flex(center, space-between, 0);
  }

  &__icon {
    color: $c-grey-45;
    font-size: 14px;
    cursor: pointer;
  }

  &__menu {
    height: 32px;
  }

  &__input {
    border-radius: 4px;
    border-color: $c-grey-15;
  }
}
</style>
