import {
  BorderStyle,
  IBorderControl,
  IFillControl,
} from "~~/models/widgets/widget-controls.model";
import { getDefaultBorderValue } from "~~/constants/configs/common/widget-initial";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 48;

type OldBorder = {
  fill: IFillControl;
  style: BorderStyle;
  width: string | number | null;
};
const applyNewOptions = (oldBorderOptions: OldBorder | IBorderControl) => {
  if ((oldBorderOptions as IBorderControl).stroke) {
    return oldBorderOptions;
  }

  const newOptions = getDefaultBorderValue();

  if (!oldBorderOptions) {
    return newOptions;
  }

  newOptions.fill = oldBorderOptions.fill;
  newOptions.style = oldBorderOptions.style;
  newOptions.stroke.width = (oldBorderOptions as OldBorder).width;

  return newOptions;
};

const updateBorderValue = (options: IWidgetOptions): void => {
  for (const optionName in options) {
    if (optionName === "border" || optionName === "contentBorder") {
      options[optionName] = applyNewOptions(options[optionName]);
      continue;
    }

    if (typeof options[optionName] === "object" && options[optionName]) {
      updateBorderValue(options[optionName]);
    }
  }
};

const v45WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  updateBorderValue(widget.options);

  for (const fieldName in widget.content) {
    const field = widget.content[fieldName];

    updateBorderValue(field.options);
  }

  if (widget.options._customFields) {
    for (const fieldName in widget.options._customFields) {
      const field = widget.options._customFields[fieldName];

      updateBorderValue(field.options);
    }
  }

  return widget;
};
export default v45WidgetMigration;
