import { type Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { size } from "~~/constants/configs/common/size-config";

import { IFields } from "../useWidgetFields";

export const useBetslipFenixHeaderControls = (
  states: Ref<Record<string, State>>,
  emit: any,
  statesValuePath: ComputedRef<string>,
  fields: ComputedRef<IFields>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const headerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const headerContentControls = computed(() => {
    return [
      size({
        source: selectedField.value,
        except: ["ConfigVerticalAlignment"],
        keyNames: {
          width: "options.size.width",
          height: "options.size.height",
          alignment: "options.slideOptions.alignment",
        },
      }),
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "First",
                  value: 1,
                },
                {
                  label: "Second",
                  value: 2,
                },
                {
                  label: "Third",
                  value: 3,
                },
              ],
            },
          },
          // {
          //   componentPath: "common/ConfigRadioTextElements",
          //   valuePath: "options.buttonDisplaySettings.resizing",
          //   valueSource: selectedField.value,
          //   options: {
          //     items: [
          //       {
          //         label: "Fill container",
          //         value: ResizingType.FILL,
          //       },
          //       {
          //         label: "Hug content",
          //         value: ResizingType.HUG,
          //       },
          //     ],
          //   },
          // },

          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.spaceBetween",
            valueSource: selectedField.value,
            options: {
              label: "Distance between tabs",
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gapBetweenTextAndCounter",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            options: {
              label: "Horizontal alignment",
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            options: {
              label: "Vertical alignment",
            },
          },
        ],
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.headerElementsSettings.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.headerElementsSettings.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.headerElementsSettings.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.headerElementsSettings.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.headerElementsSettings.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Text", value: "header_label" },
            { label: "Counter", value: "header_tabs_container_counters" },
            { label: "Cash out", value: "cashout" },
          ],
        },
      },
    ];
  });

  const headerStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.headerElementsSettings.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.headerElementsSettings.border`,
        valueSource: selectedField.value,
        options: {
          label: "Border",
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.headerElementsSettings.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: fields.value.header_label.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Counter"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.fill`,
            valueSource: fields.value.header_tabs_container_counters.field,
            options: {
              label: "Background color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: fields.value.header_tabs_container_counters.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const headerCountersDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const headerCountersContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  /* 
    Tooltip
  */
  const cashoutTooltipDesignStyles = computed(() => {
    return getInitialDesignList({
      source: fields.value.cashout_tooltip.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.cashout_tooltip.field,
            options: {
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const cashoutTooltipContentStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.cashout_tooltip.field,
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          hideDistanceControl: true,
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
            {
              label: "Question filled",
              value: "ant-design:question-circle-filled",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.cashout_tooltip.field,
          except: ["ConfigAlignment"],
        },
        "Tooltips text"
      ),
    ];
  });

  return {
    headerDesignControls,
    headerContentControls,
    headerStatesControls,
    headerCountersDesignControls,
    headerCountersContentControls,
    cashoutTooltipDesignStyles,
    cashoutTooltipContentStyles,
  };
};
