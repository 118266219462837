<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <ProfileDropdownWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      :widget-state="states.widget"
      :deposit-state="states.deposit"
      :logout-state="states.logout"
      :registration-state="states.registration"
      :login-state="states.login"
      :title-state="states.items"
      :items-group-state="states.items"
      :balance-items-state="states.balanceItems"
      @update-widget-state="
        handleStateUpdate({ state: 'widget', value: $event })
      "
      @update-title-state="handleStateUpdate({ state: 'title', value: $event })"
      @update-button-state="handleStateUpdate"
      @update-items-group-state="
        handleStateUpdate({ state: 'items', value: $event })
      "
      @update-balance-items-state="
        handleStateUpdate({ state: 'balanceItems', value: $event })
      "
      @reset-states="resetStates"
    />

    <div
      class="user-profile-dropdown"
      :style="wrapperStyle"
    >
      <div
        v-for="field in activeFields"
        :key="field.name"
        class="user-profile-dropdown__field-wrapper"
      >
        <template v-if="field.options._active">
          <template v-if="field.name === FieldName.DROPDOWN">
            <WidgetField
              :widget="widget"
              :field="fields.dropdownContainer.field"
              class="background-image"
              :class="{
                'user-profile-dropdown__field--full-width':
                  field.options.display?.resizing === ResizingType.FILL,
              }"
            >
              <BasicDropdownField
                :widget="widget"
                :style="dropdownContainerWrapperStyle"
                :title-state="states.title"
                :items-group-state="states.items"
                :title-field-name="FieldName.DROPDOWN"
              />
            </WidgetField>
          </template>

          <template v-else-if="field.name === FieldName.BALANCE">
            <WidgetField
              :widget="widget"
              :field="fields.balance.field"
              :is-custom-hovered="hoveredBalanceField === 'balanceContainer'"
              custom-dropdown-value="balanceContainer"
              @hover="handleHoverBalanceField('balanceContainer')"
              @leave="handleLeaveBalanceField()"
            >
              <div
                :style="balanceContainerWrapperStyle"
                class="user-profile-dropdown__balance"
                :class="{
                  'user-profile-dropdown__balance--vertical':
                    fields.balance.field.options.layout ===
                    DisplayOrientation.VERTICAL,
                }"
              >
                <WidgetField
                  v-for="balanceField in balanceFields"
                  :key="balanceField.name"
                  :widget="widget"
                  :field="balanceField"
                  :custom-dropdown-value="balanceField.name"
                  :is-custom-hovered="hoveredBalanceField === balanceField.name"
                  :class="{
                    'user-profile-dropdown__balance-item--selected':
                      isBalanceItemSelected,
                  }"
                  @hover="handleHoverBalanceField(balanceField.name)"
                  @leave="handleLeaveBalanceField()"
                >
                  <template v-if="balanceField.options._active">
                    <ProfileDropdownBalanceItem
                      :field="balanceField"
                      :wrapper-field="fields.balance.field"
                      :state="states.balanceItems"
                    />
                  </template>
                </WidgetField>
              </div>
            </WidgetField>
          </template>

          <template v-else>
            <WidgetField
              :widget="widget"
              :field="field"
              :class="{
                'user-profile-dropdown__field--full-width':
                  field.options.buttonDisplaySettings.resizing ===
                  ResizingType.FILL,
              }"
            >
              <BasicButtonField
                :field="field"
                :current-state="getButtonStateByName(field.name as FieldName)"
              />
            </WidgetField>
          </template>
        </template>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue";

import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import {
  PROFILE_DROPDOWN_WIDGET_AUTHORIZED_FIELDS,
  PROFILE_DROPDOWN_WIDGET_HIDDEN_FIELDS,
} from "~~/constants/widget-config";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import { ICell } from "~~/models/grid.interface";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  BUTTON_INITIAL,
  DROPDOWN_CONTAINER_INITIAL,
  TITLE_INITIAL,
  ITEMS_GROUP_INITIAL,
  BALANCE_INITIAL,
  BONUS_INITIAL,
} from "~~/constants/configs/profile-dropdown/initial-data";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ITEMS_INITIAL,
  ITEM_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { useWidgetWithArrayField } from "~~/composables/widgets/useWidgetWithArrayField";
import { useBalanceFields } from "~~/composables/widgets/profile-dropdown/useBalanceFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getDefaultFillImage } from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { titleField, arrayField, itemsFields, itemsGroup, initItemsGroup } =
  useWidgetWithArrayField(props.widget, FieldName.DROPDOWN);

const { initWidgetOptions } = useCreateFields(props.widget);

initItemsGroup();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget, selectedDropdownItem } =
  storeToRefs(widgetSettingsStore);

const fields = useWidgetFields(props.widget);

const { balanceFields } = useBalanceFields(fields);

const DEFAULT_STATES = {
  widget: State.AUTHORIZED,
  deposit: State.DEFAULT,
  logout: State.DEFAULT,
  registration: State.DEFAULT,
  login: State.DEFAULT,
  balanceItems: State.DEFAULT,
  title: State.DEFAULT,
  items: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    if (stateName === "widget") {
      return false;
    }

    return true;
  },
});

const hoveredBalanceField = ref<string | null>(null);

const handleHoverBalanceField = (value: string): void => {
  hoveredBalanceField.value = value;
};

const handleLeaveBalanceField = (): void => {
  hoveredBalanceField.value = null;
};

const getButtonStateByName = (name: FieldName): string => {
  switch (name) {
    case FieldName.DEPOSIT:
      return states.value.deposit;
    case FieldName.LOGOUT:
      return states.value.logout;
    case FieldName.REGISTRATION:
      return states.value.registration;
    case FieldName.LOGIN:
    default:
      return states.value.login;
  }
};

const isBalanceItemSelected = computed<boolean>(
  () => selectedDropdownItem.value === "balanceItems"
);

const isDefaultStateActive = computed<boolean>(
  () => states.value.widget === State.AUTHORIZED
);

const activeStateOptions = computed<IWidgetOptions>(() => {
  return isDefaultStateActive.value
    ? props.widget.options
    : props.widget.options.authStates[states.value.widget!];
});

const wrapperStyle = computed<{ [key: string]: string | undefined }>(() => {
  const displaySettings = activeStateOptions.value?.profileDropdownDisplay;
  return {
    justifyContent: displaySettings?.alignment,
    alignItems:
      displaySettings?.layout === DisplayOrientation.HORIZONTAL
        ? "start"
        : generateFlexAlignment(displaySettings?.alignment),
    gap: getPxValueFromNumber(displaySettings?.distanceBetweenItems),
    flexDirection:
      displaySettings?.layout === DisplayOrientation.HORIZONTAL
        ? "row"
        : "column",
  };
});

const activeFields = computed<IWidgetField[]>(() =>
  [...props.widget.fields]
    .sort(
      (fieldA, fieldB) => fieldA.options.position! - fieldB.options.position!
    )
    .filter(field => {
      const isPartOfAuthorizedView =
        PROFILE_DROPDOWN_WIDGET_AUTHORIZED_FIELDS.includes(
          field.name as FieldName
        );

      const isPartOfViewState = isDefaultStateActive.value
        ? isPartOfAuthorizedView
        : !isPartOfAuthorizedView;

      return (
        field.options._active &&
        isPartOfViewState &&
        !PROFILE_DROPDOWN_WIDGET_HIDDEN_FIELDS.includes(field.name as FieldName)
      );
    })
);

const dropdownContainerOptions = computed<IWidgetOptions>(
  () => fields.value.dropdownContainer.field.options
);

const { fillBackgroundStyling } = useFillBackground(dropdownContainerOptions);

const dropdownContainerWrapperStyle = computed<CSSProperties>(() => {
  const fill = fillBackgroundStyling.value;
  const border = getBorderStyle(dropdownContainerOptions.value?.border);
  const shadow = getBoxShadowStyle(dropdownContainerOptions.value?.shadow);
  const margin = getSpacing(
    dropdownContainerOptions.value.margins,
    SpacingKeyName.MARGIN
  );
  const padding = getSpacing(
    dropdownContainerOptions.value.padding,
    SpacingKeyName.PADDING
  );

  return {
    ...margin,
    ...padding,
    ...border,
    ...shadow,
    ...fill,
  };
});

const balanceContainerWrapperStyle = computed<CSSProperties>(() => {
  const options = fields.value.balance.field.options;

  const fill = getBackgroundColorStyle(options.fill);
  const border = getBorderStyle(options.border);
  const shadow = getBoxShadowStyle(options.shadow);
  const margin = getSpacing(options.margins, SpacingKeyName.MARGIN);
  const padding = getSpacing(options.padding, SpacingKeyName.PADDING);
  const cornerRadius = getCornerRadiusStyle(options.cornerRadius);
  const gap = getPxValueFromNumber(options.gap);

  return {
    ...margin,
    ...padding,
    ...border,
    ...shadow,
    ...fill,
    ...cornerRadius,
    gap,
  };
});

const initialWidgetOptions = initWidgetOptions(["fill"], {
  fillImageColor: getDefaultFillImage(),
  profileDropdownDisplay: {
    alignment: Alignment.LEFT,
    distanceBetweenItems: 8,
    layout: DisplayOrientation.HORIZONTAL,
  },
  authStates: {
    [State.UNAUTHORIZED]: {
      _active: false,
      profileDropdownDisplay: {
        alignment: Alignment.RIGHT,
        distanceBetweenItems: 8,
        layout: DisplayOrientation.HORIZONTAL,
      },
    },
  },
  bindingParams: {},
});

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);

const initButton = (buttonField: IWidgetField, position?: number) => {
  const options = BUTTON_INITIAL().options as IWidgetOptions;

  if (typeof position === "number") {
    options.position = position;
  }

  const initialButtonOptions = prefillEmptyOptions(
    buttonField.options,
    options
  );

  widgetsStore.updateFieldOptions(buttonField, initialButtonOptions);
};

initButton(fields.value.deposit.field, 1);
initButton(fields.value.logout.field, 3);
initButton(fields.value.login.field, 0);
initButton(fields.value.registration.field, 1);

const initialDropdownContainerOptions = prefillEmptyOptions(
  fields.value.dropdownContainer.field.options,
  {
    ...DROPDOWN_CONTAINER_INITIAL().options,
    position: 2,
  }
);
widgetsStore.updateFieldOptions(
  fields.value.dropdownContainer.field,
  initialDropdownContainerOptions
);

const initialTitleOptions = prefillEmptyOptions(
  titleField.value!.options,
  TITLE_INITIAL().options
);

const initialItemsOptions = prefillEmptyOptions(
  arrayField.value!.options,
  ITEMS_INITIAL().options
);

const initialItemsGroupOptions = prefillEmptyOptions(
  itemsGroup.value!.options,
  ITEMS_GROUP_INITIAL().options
);

const initialBalanceOptions = prefillEmptyOptions(
  fields.value.balance.field.options,
  BALANCE_INITIAL().options
);

const initialBonusOptions = prefillEmptyOptions(
  fields.value.bonus.field.options,
  BONUS_INITIAL().options
);

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
widgetsStore.updateFieldOptions(titleField.value!, initialTitleOptions);
widgetsStore.updateFieldOptions(arrayField.value!, initialItemsOptions);
widgetsStore.updateFieldOptions(itemsGroup.value!, initialItemsGroupOptions);
widgetsStore.updateFieldOptions(
  fields.value.balance.field!,
  initialBalanceOptions
);
widgetsStore.updateFieldOptions(fields.value.bonus.field!, initialBonusOptions);

itemsFields.value.forEach(itemField => {
  widgetsStore.updateFieldOptions(
    itemField,
    prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
  );
});
</script>

<style lang="scss">
.user-profile-dropdown {
  display: flex;

  &__field-wrapper {
    display: contents;
  }

  &__field {
    &--full-width {
      flex-grow: 1;
    }
  }

  &__balance {
    display: flex;

    &--vertical {
      flex-direction: column;
    }
  }

  &__balance-item--selected {
    border: 2px solid rgba($c-primary-base, 1);
  }
}
</style>
