import { useWidgetsStore } from "~~/store/widgets";
import { isSimpleValue } from "~~/assets/utils/widget";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getDefaultFillImage } from "~~/constants/configs/common/widget-initial";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { InnerFieldValue } from "~~/models/page.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { Sizing } from "~~/models/grid.interface";

import { initWidgetFieldsOptions } from "./useBetslipInitFieldsOptions";

const LIST_FILTER_FIELDS = [
  "bet_amount",
  "win_amount",
  "labels_styling",
  "fields_styling",
  "errors_styling",
];

export const useBetslipInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const states = ref<Record<string, State>>({
    BetslipWidget: State.BETSLIP,
    header_tabs_container: State.DEFAULT,
    betslip_tabs_container: State.DEFAULT,
    bets_container: State.SINGLE_BETS,
    coefficient_title: State.DEFAULT,
    result_container_bet_button: State.DEFAULT,
    result_container_remove_button: State.DEFAULT,
    result_container_copy_button: State.DEFAULT,
    bets_result_save_choice_button: State.DEFAULT,
    bets_result_continue_button: State.DEFAULT,
    my_bets_primary_button: State.DEFAULT,
    my_bets_secondary_button: State.DEFAULT,
    bets_type_title: State.SINGLE_BETS,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.FIXED,
      width: 320,
      alignment: "center",
    },
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [
      ...widget.fields,
      ...formFields.filter(field => LIST_FILTER_FIELDS.includes(field.name)),
    ]);
  };

  const initFields = (): void => {
    if (!widget.options || !Object.keys(widget.options).length) {
      widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions(fields);
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    filterFields,

    initFields,
  };
};
