import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ICellOptions } from "~~/models/grid.interface";
import { IContentField } from "~~/models/page.model";

import {
  generateFillColorStyle,
  generateFillImageColorStyle,
  generateShadowStyle,
  generateTextShadowStyle,
  generateSpacingStyle,
  generateMarginStyle,
  generatePaddingStyle,
  generateBorderStyle,
  generateCornerRadiusStyle,
  generateTextColor,
  generateDecorationStyle,
  generateTextAlignStyle,
  generateFontSize,
  generateFontFamily,
  generateGap,
  generateContentWidth,
  generateBaseDisplaySettings,
  generateWidgetWrapperWidth,
  generateWidgetWrapperHeight,
  generateBaseWidgetSize,
} from "../helpers";

type ClassNameWithContent = {
  className: string;
  content: IWidgetOptions | string;
  customFunction?: (value: any, secondArg?: any) => string;
  pseudoClassName?: string;
  childClassName?: string;
  parentId?: string;
  secondFunctionArg?: any;
};

export const generateCssClassWithContent = (
  arg: ClassNameWithContent
): string => {
  const cssString = arg.customFunction
    ? arg.customFunction(arg.content, arg.secondFunctionArg)
    : (arg.content as string);

  if (!cssString) {
    return "";
  }

  return `${arg.parentId ? ` #${arg.parentId} ` : ""}.${arg.className}${
    arg.pseudoClassName || ""
  }${arg.childClassName ? ` .${arg.childClassName}` : ""}{${cssString}}`;
};

const propertyMappings: Record<string, (value: any) => string> = {
  fill: generateFillColorStyle,
  backgroundColor: generateFillColorStyle,
  fillImageColor: generateFillImageColorStyle,
  shadow: generateShadowStyle,
  textShadow: generateTextShadowStyle,
  spacing: generateSpacingStyle,
  margins: generateMarginStyle,
  margin: generateMarginStyle,
  padding: generatePaddingStyle,
  border: generateBorderStyle,
  cornerRadius: generateCornerRadiusStyle,
  color: generateTextColor,
  textColor: generateTextColor,
  decoration: generateDecorationStyle,
  alignment: generateTextAlignStyle,
  theme: generateFontSize,
  fontFamily: generateFontFamily,
  spaceBetween: generateGap,
  contentWidth: generateContentWidth,
};

const baseWidgetPropertyMappings: Record<
  string,
  (
    value: any,
    cellOptions: ICellOptions,
    hasChildren?: boolean,
    widgetOptions?: IWidgetOptions
  ) => string
> = {
  size: generateBaseWidgetSize,
  // size: generateSize,
  baseDisplaySettings: generateBaseDisplaySettings,
};

export const generateBasicWidgetStyles = (
  options: IWidgetOptions,
  cellsOptions: ICellOptions,
  hasChildren?: boolean
): string => {
  let cssString = `.${options._cssClass} {`;

  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const value = options[key];
      if (value !== null && value !== undefined) {
        const mappingFunction = baseWidgetPropertyMappings[key];

        if (!mappingFunction) continue;

        cssString += mappingFunction(value, cellsOptions, hasChildren, options);
      }
    }
  }

  cssString += "align-self: flex-start;";

  cssString += "}";

  return cssString;
};

export const generateWidgetWrapperStyles = (
  options: IWidgetOptions,
  cellsOptions: ICellOptions,
  parentWidget?: IWidgetWithFields | null
): string => {
  let cssString = `.${options._widgetWrapperCssClass} {display:flex;`;

  cssString +=
    generateMarginStyle(options.spacing.margin) +
    generateWidgetWrapperWidth(
      options.size.width,
      cellsOptions,
      options,
      parentWidget
    ) +
    // generateWidgetWrapperWidth(options.size.width, cellsOptions) +
    generateWidgetWrapperHeight(
      options.size.height,
      cellsOptions,
      parentWidget
    );

  cssString += "}";

  return cssString;
};

export const generateDefaultStyles = (
  options: IWidgetOptions,
  except?: string[]
): string => {
  let cssString = "";

  for (const key in options) {
    if (
      Object.prototype.hasOwnProperty.call(options, key) &&
      !except?.includes(key)
    ) {
      const value = options[key];
      if (value !== null && value !== undefined) {
        const mappingFunction = propertyMappings[key];

        if (!mappingFunction) continue;

        if (key === "spacing" && options._isWidget) {
          cssString += mappingFunction({
            ...value,
            margin: null,
          });
          continue;
        }

        cssString += mappingFunction(value);
      }
    }
  }

  return cssString;
};

const getStatesList = (
  states: Record<string, any>,
  statesPriorityList: string[]
): string[] => {
  const result = [];
  const currentStatesList = Object.keys(states);

  for (let i = 0; i < statesPriorityList.length; i++) {
    const priorityStateName = statesPriorityList[i];

    if (currentStatesList.includes(priorityStateName)) {
      result.push(priorityStateName);
      currentStatesList.splice(currentStatesList.indexOf(priorityStateName), 1);
    }
  }

  result.unshift(...currentStatesList);

  return result;
};

export const generateDefaultStylesWithStates = (
  element: IWidgetOptions | IContentField,
  isLinkState: boolean,
  excludeStates: string[] = [],
  exceptStyles: string[] = [],
  /* 
    from lowest to highest
  */
  statesPriority = ["hover", "active", "disabled"],
  stateParentElementClass?: string,
  addDataActive = false
): string => {
  const options = element?.options ?? element;
  let cssString = "";

  const states = isLinkState ? options.link?.states : options.states;

  if (states) {
    const stateNamesList = getStatesList(states, statesPriority);

    for (const stateName of stateNamesList) {
      if (excludeStates.includes(stateName)) continue;

      const stateTargetClass = stateParentElementClass || options._cssClass;
      const childSelector = stateParentElementClass
        ? `.${options._cssClass}`
        : "";

      if (stateName === State.HOVER) {
        cssString += `@media (hover: hover) and (pointer: fine) {.${stateTargetClass}:hover ${childSelector} {${generateDefaultStyles(
          states[stateName],
          exceptStyles
        )}}}`;
        cssString += `.${stateTargetClass}:focus-visible ${childSelector} {${generateDefaultStyles(
          states[stateName],
          exceptStyles
        )}}`;
        continue;
      }

      if (stateName === State.ACTIVE && addDataActive) {
        cssString += generateCssClassWithContent({
          className: `${stateTargetClass}[data-active="true"]`,
          content: states[stateName],
          customFunction: generateDefaultStyles,
          pseudoClassName: ` ${childSelector}`,
          secondFunctionArg: exceptStyles,
        });
      }

      cssString += generateCssClassWithContent({
        className: stateTargetClass,
        content: states[stateName],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${stateName} ${childSelector}`,
        secondFunctionArg: exceptStyles,
      });

      if (stateName === State.DISABLED) {
        cssString += generateCssClassWithContent({
          className: stateTargetClass,
          content: "cursor: not-allowed;",
          pseudoClassName: `:disabled ${childSelector}`,
        });
      }
    }
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
      secondFunctionArg: exceptStyles,
    });
  } else {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
      secondFunctionArg: exceptStyles,
    });
  }

  return cssString;
};
