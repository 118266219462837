import { type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { stateConfig } from "~~/constants/configs/common/state-config";

import { useWidgetFields } from "../useWidgetFields";

export const useEachWayBetControls = (data: {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  emit: any;
}) => {
  const { widget, states, emit } = data;

  const fields = useWidgetFields(widget.value);

  /* 
    Each way
  */
  const eachWayBetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.each_way_bet.field,
    });
  });

  const eachWayBetContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.each_way_bet.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Checkbox",
              value: "each_way_bet_checkbox",
            },
            {
              label: "Tooltip",
              value: "each_way_bet_tooltip",
            },
          ],
        },
      },
    ];
  });

  /* 
    Checkbox
  */

  const eachWayCheckboxDesignControls = computed<ControlProp[]>(() => {
    if (!fields.value.each_way_bet_checkbox?.field) {
      return [];
    }

    return getInitialDesignList({
      // TODO: tmp solution, need to check what is the problem
      source: fields.value.each_way_bet_checkbox?.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.each_way_bet_checkbox?.field,
            options: {
              label: "Padding",
            },
          },
        ],
      ],
    });
  });

  const eachWayCheckboxContentControls = computed<ControlProp[]>(() => {
    if (!fields.value.each_way_bet_checkbox?.field) {
      return [];
    }

    return [
      {
        section: generateContentSectionName("Check"),
        toggleable: true,
        valueSource: fields.value.each_way_bet_checkbox.field.options.check,
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.check.distance",
            valueSource: fields.value.each_way_bet_checkbox.field,
            visible:
              fields.value.each_way_bet_checkbox.field.options.check._active,
            options: {
              label: "Space between text and checkbox",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.size",
            valueSource: fields.value.each_way_bet_checkbox.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.check.fill`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            visible:
              fields.value.each_way_bet_checkbox.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `options.check.border`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            visible:
              fields.value.each_way_bet_checkbox.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.check.icon`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            visible:
              fields.value.each_way_bet_checkbox.field.options.check._active,
            options: {
              placeholder: "None",
              label: "Check icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.each_way_bet_checkbox.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];
  });

  const currentCheckboxButtonStatePath = computed<string>(() => {
    if (states.value.each_way_bet_checkbox === State.SELECTED_DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.each_way_bet_checkbox}`;
  });

  const isCheckboxSelectedState = computed<boolean>(() => {
    return (
      states.value.each_way_bet_checkbox === State.SELECTED_DEFAULT ||
      states.value.each_way_bet_checkbox === State.SELECTED_DISABLED ||
      states.value.each_way_bet_checkbox === State.SELECTED_HOVER
    );
  });

  const eachWayCheckboxStatesControls = computed<ControlProp[]>(() => {
    if (!fields.value.each_way_bet_checkbox?.field) {
      return [];
    }

    return [
      stateConfig({
        source: states.value.each_way_bet_checkbox,
        statesList: [
          {
            label: STATE_KEY_LABELS.selected_default,
            value: State.SELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.selected_hover,
            value: State.SELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.unselected_default,
            value: State.UNSELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.unselected_hover,
            value: State.UNSELECTED_HOVER,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "each_way_bet_checkbox", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.each_way_bet_checkbox.field,
        basePath: currentCheckboxButtonStatePath.value,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: "Check",
        visible: fields.value.each_way_bet_checkbox.field.options.check._active,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.fill`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.border`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            options: {
              placeholder: "None",
              label: "Border",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.check.icon`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            visible: isCheckboxSelectedState.value,
            options: {
              placeholder: "None",
              label: "Check icon",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
      {
        section: "Text",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxButtonStatePath.value}.color`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentCheckboxButtonStatePath.value}.decoration`,
            valueSource: fields.value.each_way_bet_checkbox.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  /* 
    Tooltip
  */
  const formTooltipDesignStyles = computed(() => {
    return getInitialDesignList({
      source: fields.value.each_way_bet_tooltip.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.each_way_bet_tooltip.field,
            options: {
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const formTooltipContentStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.each_way_bet_tooltip.field,
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          hideDistanceControl: true,
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
            {
              label: "Question filled",
              value: "ant-design:question-circle-filled",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.each_way_bet_tooltip.field,
          except: ["ConfigAlignment"],
        },
        "Tooltips text"
      ),
    ];
  });

  /* 
    Stake info
  */
  const stakeInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.stake_info.field,
    });
  });

  const stakeInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.stake_info.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Fields",
              value: "form",
            },
            {
              label: "Total stake",
              value: "total_stake",
            },
          ],
        },
      },
    ];
  });

  /* 
    Total stake
  */
  const totalStakeDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.total_stake.field,
    });
  });

  const totalStakeContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.total_stake.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: fields.value.total_stake.field,
        options: {
          placeholder: "Select",
          label: "Font family",
          isBold: true,
        },
      },
      typography(
        {
          source: fields.value.total_stake.field.options.label,
          except: ["ConfigFontFamilySelect"],
          isOptionPath: false,
        },
        "Label text style"
      ),
      typography(
        {
          source: fields.value.total_stake.field.options.value,
          except: ["ConfigFontFamilySelect"],
          isOptionPath: false,
        },
        "Value text style"
      ),
    ];
  });

  return {
    eachWayBetDesignControls,
    eachWayBetContentControls,

    eachWayCheckboxDesignControls,
    eachWayCheckboxContentControls,
    eachWayCheckboxStatesControls,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    stakeInfoDesignControls,
    stakeInfoContentControls,

    totalStakeDesignControls,
    totalStakeContentControls,
  };
};
