import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringWithStates } from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateMarginStyle,
  generateSize,
  generateInvisibleScrollCssString,
} from "../helpers";
import { getFlexAlign } from "../../widget-settings";

const generateWidgetStyles =
  (options: IWidgetOptions, linkOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const flexAlign = getFlexAlign(options.display.alignment);

    const flexStyles = generateFlex({
      flex: "flex",
      align: flexAlign["align-items"],
      justify: flexAlign["justify-content"],
      wrap: "nowrap",
    });

    const sizeStyles = generateSize(options.size);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        flexStyles +
        sizeStyles +
        generateDefaultStyles({
          ...options,
          spacing: {
            margin: options.spacing.margin,
            /*
              Add right margin to last child instead of right padding,
              since it doesn't work in some browsers
            */
            padding: {
              ...options.spacing.padding,
              right: null,
            },
          },
        }),
    });

    const childStyles = generateFlex({
      flex: "flex",
      align: "center",
      justify: "unset",
      gap: "0",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "white-space:nowrap;",
      childClassName: linkOptions._cssClass,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: childStyles,
      childClassName: "breadcrumbs__inner",
    });

    return cssString;
  };

const generateSeparatorCssString =
  (element: IWidgetOptions, widgetOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const marginStyles = generateMarginStyle({
      left: widgetOptions.gap,
      right: widgetOptions.gap,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: marginStyles + generateDefaultStyles(element.options),
    });

    return cssString;
  };

const generateLastLinkCssString =
  (element: IWidgetOptions, widgetOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const marginStyles = generateMarginStyle({
      right: widgetOptions.spacing.padding.right,
    });
    const activeStyles = generateDefaultStyles(
      element.options.link.states.active
    );

    cssString += generateCssClassWithContent({
      className: widgetOptions._cssClass,
      pseudoClassName: ` .breadcrumbs__inner:last-child .${element.options._cssClass}`,
      content: marginStyles + activeStyles,
    });

    return cssString;
  };

const generateBreadcrumbsAdditionalCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: "align-items: center !important;",
    });

    return cssString;
  };

export const generateBreadcrumbsWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const links = widget.content["links"];
  const separators = widget.content["separators"];

  widget.options._cssClass = generateClassName("Breadcrumbs");
  links.options._cssClass = generateClassName("title");
  separators.options._cssClass = generateClassName("separator");

  return pipeSync<string>(
    generateWidgetStyles(widget.options, links.options),
    generateInvisibleScrollCssString(widget.options),
    generateBreadcrumbsAdditionalCssString(widget),
    generateStringWithStates(links, true),
    generateSeparatorCssString(separators, widget.options),
    generateLastLinkCssString(links, widget.options)
  )("");
};
