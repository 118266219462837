export enum FieldContentItem {
  TEXT = "text",
  TEXT_SETTINGS = "textSettings",
  ICON = "icon",
  ARROW = "arrow",
  ARROW_SETTINGS = "arrowSettings",
  BUTTON_TEXT_SETTINGS = "buttonTextSettings",
  PLACEHOLDER = "placeholder",
  ICON_SETTINGS = "iconSettings",
}
