<script setup lang="ts">
import Draggable from "vuedraggable";

import type { IDragEndEvent } from "./types";

interface DraggableParams {
  oldDraggableIndex: number;
  newDraggableIndex: number;
}

const items = defineModel<any[]>({
  required: true,
});

const {
  handleSelector = "",
  itemKey = "id",
  itemClass = undefined,
  disabled = false,
} = defineProps<{
  handleSelector?: string;
  itemKey?: string;
  group: string;
  itemClass?: string | Record<string, any> | any[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: any[]): void;
  (e: "end", params: IDragEndEvent): void;
}>();

function onDragEnd({ newDraggableIndex, oldDraggableIndex }: DraggableParams) {
  if (oldDraggableIndex !== newDraggableIndex) {
    emit("end", {
      item: items.value[newDraggableIndex],
      index: newDraggableIndex,
      oldIndex: oldDraggableIndex,
    });
  }
}
</script>

<template>
  <Draggable
    v-model="items"
    :group="group"
    :item-key="itemKey"
    :handle="handleSelector"
    :disabled="disabled"
    :scroll-sensitivity="200"
    :force-fallback="true"
    @end="onDragEnd"
  >
    <template
      v-if="$slots.prepend"
      #header
    >
      <slot name="prepend" />
    </template>

    <template
      v-if="$slots.append"
      #footer
    >
      <slot name="append" />
    </template>

    <template #item="item">
      <div :class="itemClass">
        <slot
          :value="item.element"
          :index="item.index"
        />
      </div>
    </template>
  </Draggable>
</template>
