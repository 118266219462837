import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  getDefaultBorderValue,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { IBorderControl } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 74;

const generateSuccessField = (initialBorderValue?: IBorderControl) => ({
  name: "field_success_styling",
  type: "UnknownField",
  value: null,
  options: {
    _active: true,
    _custom: true,
    border: initialBorderValue || getDefaultBorderValue(),
  },
});

const applyNewOptions = (widget: IWidgetWithFields): void => {
  const customFields = widget.options._customFields;

  if (customFields.labels_styling) {
    if (!customFields.labels_styling.options.states) {
      customFields.labels_styling.options.states = {
        success: {
          color:
            customFields.labels_styling.options.color || getDefaultFillValue(),
        },
        error: {
          color:
            customFields.labels_styling.options.color || getDefaultFillValue(),
        },
      };
    }
  }

  if (!customFields.field_success_styling) {
    customFields.field_success_styling = generateSuccessField(
      customFields.fields_styling?.options?.border
    );
  }
};

const v74WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  for (const fieldName in widget.content) {
    const currField = widget.content[fieldName];

    if (currField.type !== WIDGET_FIELD_TYPES.FORM_ELEMENT) {
      continue;
    }

    applyNewOptions(widget);
    return widget;
  }

  return widget;
};
export default v74WidgetMigration;
