import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";

export const useSportsbookLiveFenixLeaguesConfig = (
  widget: Ref<IWidgetWithFields>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Sports container config
  */

  const leaguesTitleDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.leagues_titles.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.leagues_titles.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const leaguesTitleContentControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.leagues_titles.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];
  });

  return {
    leaguesTitleDesignControls,
    leaguesTitleContentControls,
  };
};
