import { CSSProperties } from "vue";

import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

import { CardsDisplayMode } from "../../PromoBetsFenixWidgetTypes";

export const useGridCellStyles = (widget: any): CSSProperties => {
  const displayMode = widget.options.displayMode;
  const carouselSettings = widget.options.carouselSettings;

  if (displayMode === CardsDisplayMode.Carousel) {
    const styles = {
      flexShrink: 0,
    };

    if (carouselSettings.layout === ResizingType.FIXED) {
      return {
        ...styles,
        width: getPxValueFromNumber(carouselSettings.width),
      };
    } else {
      return {
        ...styles,
        width: `calc((100% - (${
          carouselSettings.maxVisibleCards
        } - 1) * ${getPxValueFromNumber(carouselSettings.gap)}) / ${
          carouselSettings.maxVisibleCards
        })`,
      };
    }
  } else {
    return {};
  }
};
