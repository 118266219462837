<template>
  <div
    :style="[containerInlineStyles, containerStyles || {}]"
    class="sports-menu-live-fenix-event-cards"
  >
    <WidgetField
      v-for="(card, index) in data"
      :key="index"
      class="sports-menu-live-fenix-event-cards__item"
      :widget="widget"
      :field="cardField"
      :style="cardStyles"
    >
      <slot :card="card"> </slot>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup generic="T">
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    containerField: IWidgetField;
    cardField: IWidgetField;
    cardState: State;
    data?: T[];
    // eslint-disable-next-line vue/require-default-prop
    containerStyles?: ElementStyle;
  }>(),
  {
    data: () => [],
  }
);

const containerInlineStyles = computed<ElementStyle>(() => {
  const filteredOptions = {
    ...props.containerField.options,
    spacing: {
      padding: null,
      margin: null,
    },
  };

  return {
    ...getCommonStyles(filteredOptions),
    gridTemplateColumns: `repeat(${props.containerField.options.columns}, minmax(0, 1fr))`,
    gap: getPxValueFromNumber(props.containerField.options.distance),
  };
});

const cardStyles = computed<ElementStyle>(() => {
  return getCommonStyles(props.cardField.options, props.cardState);
});
</script>

<style lang="scss">
.sports-menu-live-fenix-event-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 16px;
  column-gap: 16px;

  &__item {
    overflow: hidden;
  }

  &__header {
    @include flex(center, flex-start, 0);
  }
}
</style>
