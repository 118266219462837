<template>
  <component
    :is="'div'"
    :id="`cellId_${cell.cellId}`"
    ref="currentCell"
    v-draggable="dndProps"
    :class="[...classes, ...propmtClasses]"
    :style="inlineStyles"
    :data-element-id="elementId"
    :data-is-parent="isParent"
    :data-container-index="containerSequenceIndex"
    @click.stop="onSelect"
  >
    <span
      v-if="isContainer"
      class="grid-cell__label"
    >
      {{ containerGridTranslation }}
      <CommonIcon name="custom:dnd-marker" />
    </span>
    <div class="grid-cell__top-add-icon">
      <GridAddBlockControls :position="InsertionRule.BEFORE" />
    </div>
    <div class="grid-cell__bottom-add-icon">
      <GridAddBlockControls :position="InsertionRule.AFTER" />
    </div>
    <span class="grid-cell__settings-container">
      <GridCellSettingsControls
        v-if="selectedCell?.settings.blockType === BlockType.CONTAINER"
      />
    </span>
    <template v-if="cell.children">
      <GridCell
        v-for="(innerCell, index) in cell.children"
        :key="innerCell.cellId"
        :cell="innerCell"
        :container-sequence-index="
          innerCell.settings.blockType === BlockType.CONTAINER
            ? index
            : containerSequenceIndex
        "
        :columns="
          innerCell.settings.blockType === BlockType.CONTAINER
            ? innerCell.children.length
            : columns
        "
      >
      </GridCell>
    </template>
  </component>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, type CSSProperties } from "vue";

import { IDndProps } from "~~/models/dnd.model";
import { DndContainerId } from "~~/models/common/dropdown.model";
import {
  ICell,
  NestingLevel,
  BlockType,
  IGridDraggable,
  InsertionRule,
} from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useDndStore } from "~~/store/dnd";

import { useSelection } from "../composables/grid/useSelection";
import { useStyles } from "../composables/grid/useStyles";
import { useClasses } from "../composables/grid/useClasses";

const store = useGridConfig();
const dndStore = useDndStore();

const props = defineProps<{
  cell: ICell;
  columns: number;
  containerSequenceIndex: number;
}>();

const currentCell = ref<HTMLElement | null>(null);

const { addListeners, removeListeners, onSelect, selectedCell } = useSelection(
  props.cell,
  currentCell
);

const isParent = computed<boolean>(
  () => props.cell.settings.level === NestingLevel.PARENT
);

const isContainer = computed<boolean>(
  () => props.cell.settings.blockType === BlockType.CONTAINER
);

const isColumn = computed<boolean>(
  () => props.cell.settings.blockType === BlockType.COLUMN
);

const shouldAddPropmtOffset = computed<boolean>(
  () =>
    dndStore.isDragging &&
    dndStore.activeDndContainerId === DndContainerId.GRID &&
    dndStore.draggingLayoutPromptData?.cellId === props.cell.cellId
);

const cellDragPropmtSpacingStyle = computed(() => {
  let result: CSSProperties = {};

  if (isParent.value) {
    result = {
      marginBottom: `${dndStore.draggingLayoutPromptData?.offsetBottom}px`,
      marginTop: `${dndStore.draggingLayoutPromptData?.offsetTop}px`,
    };
  } else {
    if (dndStore.draggingLayoutPromptData?.offsetBottom) {
      result.borderBottom = `${dndStore.draggingLayoutPromptData?.offsetBottom}px solid #fff`;
    }

    if (dndStore.draggingLayoutPromptData?.offsetTop) {
      result.borderTop = `${dndStore.draggingLayoutPromptData?.offsetTop}px solid #fff`;
    }
  }

  return result;
});

const inlineStyles = computed(() => {
  return {
    ...useStyles(props.cell.settings).value,
    ...(shouldAddPropmtOffset.value ? cellDragPropmtSpacingStyle.value : {}),
  };
});
const classes = useClasses(props.cell);
const propmtClasses = computed(() => {
  if (!shouldAddPropmtOffset.value || isParent.value) return [];

  // temp. solution - add borders to divider prompt
  // TODO: find out do we need that
  if (dndStore.draggingLayoutPromptData?.offsetBottom) {
    return ["grid-cell--with-inner-bottom-prompt-offset"];
  } else {
    return ["grid-cell--with-inner-top-prompt-offset"];
  }
});

const containerGridTranslation = computed<string>(() => {
  const label = isParent.value ? "Parent container" : "Child container";
  // TODO: add i18n translation here
  return label;
});

onMounted(() => {
  addListeners();
});

onBeforeUnmount(() => {
  removeListeners();
});

const draggingPayloadData = computed<IGridDraggable>(() => {
  return {
    columns: props.columns,
    draggingCellId: props.cell.cellId,
    isGridElement: true,
  };
});

const dndProps = computed<IDndProps>(() => {
  return {
    payload: draggingPayloadData.value,
    imageSelectorId: `layout-column-${props.columns}`,
    draggingId: props.cell.cellId,
    containerId: DndContainerId.GRID,
    /*
      We can drag only container type cells.
      So, we can disable dnd for column type
    */
    disabled: isColumn.value,
    onDragStart: () => store.setSelectedCell(null),
  };
});

/*
  We need elementId value on "column" type cells.
  As we can not drop on "container" type.
*/
const elementId = computed<string | number>(() => {
  if (isContainer.value) {
    return "";
  }

  return props.cell.cellId;
});
</script>

<style lang="scss">
$cell: "grid-cell";

.#{$cell} {
  display: flex;
  justify-content: center;
  min-height: 100px;
  box-sizing: content-box;

  /* SIZING */

  &--fixed {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &--expanded {
    flex-grow: 1;
  }

  /* BORDERS */

  /* NOTE we need to set
  - in default state: thin dashed borders between columns and thick between blocks
  - in hovered state: thin solid borders for hovered block which should override all existing borders and 'collapse' any sibling borders
  - in selected state: thick borders on containers block which has the highers priority
  General logic is to set "collapsed" borders (in-between-blocks) via left border of the right sibling (not vice versa),
  because then, if we need to reset something on hover, we can use css which is applied from left to right. */

  &--parent {
    border-color: $c-border-blue;

    /* In parent-level block display thin border between empty columns */
    &.#{$cell}--empty + .#{$cell}__column.#{$cell}--empty {
      border-left-width: 1px;
      border-left-style: dashed;

      &:first-child {
        border-left-width: 0;
      }
    }

    /* Manage vertical thick container borders between non-empty columns.
    Note that hovered and selected states give their own borders,
    so check for that to prevent rendering of multiple borders */
    &:not(.#{$cell}--empty) {
      &:not(.#{$cell}--with-hover):not(.#{$cell}--with-selection) {
        + .#{$cell}__column.#{$cell} > .#{$cell}__container {
          border-left-width: 2px;
          border-left-style: dashed;
        }
      }
    }

    /* ATTENTION - WORKAROUND!
    To manage thick border between non-empty and empty columns,
    we should set a border on a column instead of container (which is not available in empty column) */
    &:not(.#{$cell}--empty):not(.#{$cell}--with-hover):not(
        .#{$cell}--with-selection
      )
      + .#{$cell}--empty {
      border-left-width: 2px;
      border-left-style: dashed;
      border-left-color: $c-violet;
    }
  }

  &--child {
    border-color: $c-violet;

    /* Display thick border between vertically stacked child blocks */
    &.#{$cell}__container:not(.#{$cell}--highlighted):not(.#{$cell}--selected)
      + .#{$cell}__container {
      border-top-width: 2px;
      border-top-style: dashed;
    }

    /* and thin for columns inside */
    &.#{$cell}__column {
      border-left-width: 1px;
      border-left-style: dashed;

      &:first-of-type {
        border-left-width: 0;
      }
    }
  }

  /* Child blocks inside the column under the hover or selection need to have thick borders
  as the left border of a sibling block is reset */
  .#{$cell}--with-hover:not(:last-of-type) > .#{$cell}__container,
  .#{$cell}--with-selection:not(:last-of-type) > .#{$cell}__container {
    border-right-width: 2px;
    border-right-style: dashed;
  }

  &--parent.#{$cell}--empty + .#{$cell}__column > .#{$cell}__container {
    border-left-width: 2px;
    border-left-style: dashed;
  }

  &--highlighted {
    /* NOTE on !important: selector specificity for borders is complicated,
    while highlighted border styles should override any defined before */
    border-width: 1px !important;
    border-style: solid !important;
  }

  &--selected {
    /* NOTE: and selected border style has the highest priority */
    border-width: 2px !important;
    border-style: solid !important;
  }

  /* NOTE: need also to manage sibling borders, both horizontal... */
  &--selected + .#{$cell}--highlighted {
    border-top-width: 0 !important;
  }

  /* ...and vertical */
  &--highlighted + .#{$cell}--selected {
    border-top-width: 1px !important;
  }

  /* Child/parent LABEL */

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 $space-s;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    @include flex(center, center, $space-s);
    transition: all 0.1s;
  }

  &__settings-container {
    display: none;
    position: absolute;
  }

  &__bottom-add-icon,
  &__top-add-icon {
    display: none;
  }

  &--selected {
    > .#{$cell}__top-add-icon {
      display: block;
    }

    > .#{$cell}__bottom-add-icon {
      display: block;
    }

    > .#{$cell}__settings-container {
      top: 2px;
      left: 0px;
      display: block;
      z-index: 1;
    }
  }

  &__container {
    position: relative;

    &.#{$cell}--parent {
      > .#{$cell}__label {
        color: $c-border-blue;
        background-color: $c-blue;
        transform: translateY(-100%);
      }

      &.#{$cell}--highlighted,
      &.#{$cell}--selected {
        > .#{$cell}__label {
          color: $c-white;
          background-color: $c-border-blue;
        }
      }
    }

    &.#{$cell}--child {
      > .#{$cell}__label {
        top: 0;
        color: $c-violet;
        background-color: $c-light-violet;
      }

      &.#{$cell}--highlighted,
      &.#{$cell}--selected {
        > .#{$cell}__label {
          color: $c-white;
          background-color: $c-violet;
        }
        > .#{$cell}__settings-container {
          top: 20px;
        }
      }
    }
  }

  &__column {
    display: block;

    /* COLORS */

    &.#{$cell}--parent {
      background-color: $c-light-blue;
    }

    &.#{$cell}--child {
      background-color: $c-pink;
    }
  }

  &--with-inner-bottom-prompt-offset::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px dashed $c-violet;
  }

  &--with-inner-top-prompt-offset::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 2px dashed $c-violet;
  }
}
</style>
