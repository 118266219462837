<script setup lang="ts">
import { useLanguagesStore } from "~~/store/languages";
import { ILanguageProperties } from "~~/models/widgets/languages.interface";

import type { IAccessScenario } from "./type";

const scenarioFormData = defineModel<IAccessScenario>("scenario", {
  required: true,
});

const errors = defineModel<Record<string, string>>("errors", {
  required: true,
});

const languagesStore = useLanguagesStore();

const { availableLanguages, defaultLanguage } = storeToRefs(languagesStore);

const selectedLanguage = ref<string>(defaultLanguage.value?.codeWithRegion!);

const hasError = computed<boolean>(() => {
  if (!errors.value) {
    return false;
  }

  for (const key in errors.value) {
    if (key === "name") {
      continue;
    }

    return true;
  }

  return false;
});

const errorMessage = computed<string | null>(() => {
  if (!errors.value) {
    return null;
  }

  for (const key in errors.value) {
    if (key === "name") {
      continue;
    }

    return errors.value[key];
  }

  return null;
});

const fillWithDefaultSkipped = (editedLang: ILanguageProperties) => {
  if (editedLang.codeWithRegion === defaultLanguage.value?.codeWithRegion) {
    const currentUrl =
      scenarioFormData.value.meta.redirectScenario.url[
        editedLang.codeWithRegion
      ];
    if (currentUrl) {
      Object.keys(scenarioFormData.value.meta.redirectScenario.url).forEach(
        lang => {
          if (lang !== editedLang.codeWithRegion) {
            scenarioFormData.value.meta.redirectScenario.url[lang] = currentUrl;
          }
        }
      );
    }
  }
};
</script>

<template>
  <div class="w-100">
    <a-tabs v-model:active-key="selectedLanguage">
      <a-tab-pane
        v-for="(language, key) in availableLanguages"
        :key="key"
        :tab="language.name"
        force-render
      >
        <a-form-item
          class="margin-bottom-none w-100"
          :name="['routes', language.codeWithRegion]"
          :rules="[{ required: true, message: 'Field is required!' }]"
          layout="vertical"
          :validate-status="hasError ? 'error' : undefined"
          :help="errorMessage"
        >
          <template #label>
            <div class="page-config-drawer__label">
              Redirect to page with URL
              <!-- TODO: disabled because functionality added at front -->
              <!-- <a-tooltip>
                <template #title>
                  Usage example: <br />
                  «/pagename?r=/redirect», where r - is an optional parameter
                  that specifies the page to redirect to (e.g. after login).
                </template>

                <CommonIcon
                  name="ant-design:question-circle-outlined"
                  :size="16"
                />
              </a-tooltip> -->
            </div>
          </template>

          <a-input
            v-model:value="
              scenarioFormData.meta.redirectScenario.url[
                language.codeWithRegion
              ]
            "
            @change="fillWithDefaultSkipped(language)"
          />
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<style scoped lang="scss"></style>
