import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { calculateDisableFieldsAllowed } from "~~/helpers/configs/disable-fields-allowed";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

import { useTitleInitialControls } from "../common/useTitleInitialControls";

import { useButtonControls } from "./useButtonControls";
import { useCardWidgetControls } from "./useCardWidgetControls";
import { useImageControls } from "./useImageControls";
import { useTitleLinkStateControls } from "./useTitleLinkStatesControls";
import { useButtonsGroupControls } from "./useButtonsGroupControls";

export const useCardConfig = (
  widget: Ref<IWidgetWithFields>,
  titleLinkState: Ref<State>,
  widgetState: Ref<State>,
  buttonState1: Ref<State>,
  buttonState2: Ref<State>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const PRIMARY_WIDGET_FIELDS = [
    "image",
    "title",
    "description_1",
    "buttons_group",
  ];

  const disableFieldsAllowed = calculateDisableFieldsAllowed(
    PRIMARY_WIDGET_FIELDS,
    fields
  );

  const { buttonsGroupDesignControls, buttonsGroupContentControls } =
    useButtonsGroupControls(widget, fields);

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: computed(() =>
          selectedField.value?.name === "button_1"
            ? buttonState1.value
            : buttonState2.value
        ),
      },
      emit
    );

  const { widgetDesignControls, widgetContentControls, widgeStatesControls } =
    useCardWidgetControls(
      widget,
      widgetState,
      fields,
      disableFieldsAllowed,
      emit
    );

  const { imageControls } = useImageControls(fields);

  const titleLinkStateValue = computed<Record<string, State>>(() => {
    const fieldName = selectedField.value?.name;

    if (fieldName) {
      return {
        [fieldName]: titleLinkState.value,
      };
    } else {
      return {};
    }
  });

  const { titleLinkStatesControls } = useTitleLinkStateControls(
    titleLinkStateValue,
    fields,
    emit
  );

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Image", "image"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "description_1"),
    generateDropdownItem("Buttons group", "buttons_group"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
    generateDropdownItem("Button", "button_1"),
    generateDropdownItem("Button", "button_2"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
      generateTabItem("Data", "data"),
    ],
    buttons_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const { titleControls } = useTitleInitialControls(widget);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgeStatesControls,
    titleControls,
    titleLinkStatesControls,
    imageControls,
    buttonsGroupDesignControls,
    buttonsGroupContentControls,
    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,
  };
};
