<template>
  <div :style="itemStyles">{{ value }}</div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  field: IWidgetField;
  value: string;
  state?: State;
}>();

const textStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(props.field.options, props.state),
  };
});

const itemStyles = computed<ElementStyle>(() => {
  return {
    ...textStyles.value,
    ...getCommonStyles(
      {
        ...props.field.options,
      },
      props.state
    ),
  };
});
</script>
