<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <LoginFormWidgetCustomSettings
        v-if="showCustomSettings"
        :widget="widget"
        :states="states"
        :dropdown-items="currentDropdownItems"
        :tabs="currentTabs"
        @update-state="$emit('update-state', $event)"
        @reset-states="$emit('reset-states')"
      />

      <NewFormWidgetSettings
        v-else
        :widget="widget"
        :states="states"
        :dropdown-items="currentDropdownItems"
        :tabs="currentTabs"
        :forms-elements="customFormElements"
        :active-view="activeView"
        :views="views"
        :binding-params="bindingParams"
        :custom-controls="customControls"
        show-form-settings
        @update-active-view="$emit('update-active-view', $event)"
        @update-state="$emit('update-state', $event)"
        @reset-states="$emit('reset-states')"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useItems } from "~~/composables/widgets/form/useNewFormItems";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  customFormElements: Record<string, string[]>;
  activeView: string;
  views: { label: string; value: string }[];
}>();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);
const fields = useWidgetFields(props.widget);

defineEmits<{
  (e: "update-state", event: { state: string; value: string }): void;
  (e: "reset-states"): void;
  (e: "update-active-view", value: string): void;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "redirectUrl": {
      label: "Redirect URL",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const customLabels = {
  login_title: "Title",
  login_submit: "Submit",
  login_forgot_password_button: "Forgot password button",
  login_description_1: "Description",
  login_description_2: "Description",

  forgot_password_title: "Title",
  forgot_password_submit: "Submit",
  forgot_password_back_button: "Back button",
  forgot_password_description_1: "Description",
  forgot_password_description_2: "Description",
};

const { TABS, formDropdownItems } = useItems({
  widget: toRef(props.widget),
  customFormElements: props.customFormElements,
  customLabels,
});

const currentDropdownItems = computed(() => {
  return [
    ...formDropdownItems.value,

    generateDropdownItem("Login form", "login_form"),
    generateDropdownItem("Forgot password form", "forgot_password_form"),

    generateDropdownItem("Text", "login_text"),
    generateDropdownItem("Button", "login_button"),

    generateDropdownItem("Title", "result_title"),
    generateDropdownItem("Back button", "result_back_button"),
    generateDropdownItem("Image", "result_image"),
    generateDropdownItem("Message", "result_message"),
    generateDropdownItem("Timer", "result_timer"),
  ];
});

const currentTabs = {
  ...TABS,
  widgetSettings: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("Data", "data"),
  ],
  login_form: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("Styles", "styles"),
  ],
  forgot_password_form: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("Styles", "styles"),
  ],
  result_back_button: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  login_button: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
};

const showCustomSettings = computed<boolean>(() => {
  if (
    [
      "result_title",
      "result_back_button",
      "result_image",
      "result_message",
      "result_timer",
      "login_text",
      "login_button",
    ].includes(selectedField.value?.name || "")
  ) {
    return true;
  }

  return false;
});

const additionalWidgetContentLoginControls = computed<ControlProp[]>(() => {
  return [
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: fields.value.login_text.field,
      options: {
        placeholder: "Enter text",
        label: "Text",
        allowDisable: true,
        active: fields.value.login_text.active,
        showOptions: fields.value.login_text.active,
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.login_text.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.login_text.field
          );
        },
      },
    },
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: fields.value.login_button.field,
      options: {
        placeholder: "Enter text",
        label: "Button",
        allowDisable: true,
        active: fields.value.login_button.active,
        showOptions: fields.value.login_button.active,
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.login_button.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.login_button.field
          );
        },
      },
    },
  ];
});

const allowScreenFieldsDisable = computed<boolean>(() => {
  return (
    [
      fields.value.result_title.field,
      fields.value.result_back_button.field,
      fields.value.result_image.field,
      fields.value.result_message.field,
    ].filter(field => {
      return field.options._active;
    }).length > 1
  );
});

const additionalWidgetSuccessControls = computed<ControlProp[]>(() => {
  return [
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: fields.value.result_title.field,
      options: {
        placeholder: "Enter text",
        label: "Title",
        allowDisable: allowScreenFieldsDisable.value,
        active: fields.value.result_title.field.options._active,
        showOptions: fields.value.result_title.field.options._active,
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.result_title.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.result_title.field
          );
        },
      },
    },
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: fields.value.result_back_button.field,
      options: {
        placeholder: "Enter text",
        label: "Back button",
        allowDisable: allowScreenFieldsDisable.value,
        active: fields.value.result_back_button.field.options._active,
        showOptions: fields.value.result_back_button.field.options._active,
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.result_back_button.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.result_back_button.field
          );
        },
      },
    },
    {
      componentPath: "content/ConfigImageWithToggle",
      valuePath: "value",
      valueSource: fields.value.result_image.field,
      options: {
        label: "Image",
        allowDisable: allowScreenFieldsDisable.value,
        active: fields.value.result_image.field.options._active,
        showOptions: fields.value.result_image.field.options._active,
        attribute: "result_image",
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.result_image.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.result_image.field
          );
        },
      },
    },
    {
      componentPath: "content/ConfigTextInputWithToggle",
      valuePath: "value",
      valueSource: fields.value.result_message.field,
      options: {
        placeholder: "Enter text",
        label: "Message",
        allowDisable: allowScreenFieldsDisable.value,
        active: fields.value.result_message.field.options._active,
        showOptions: fields.value.result_message.field.options._active,
        "onUpdate:active": (value: boolean) => {
          widgetsStore.updateFieldOptionValue(
            value,
            "_active",
            fields.value.result_message.field.id
          );
        },
        "onShow-options": () => {
          widgetSettingsStore.setActiveElement(
            props.widget,
            fields.value.result_message.field
          );
        },
      },
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "Timer Settings",
            value: "result_timer",
          },
        ],
      },
    },
  ];
});
const additionalWidgetContentControls = computed<ControlProp[]>(() => {
  if (props.activeView === "login_form") {
    return additionalWidgetContentLoginControls.value;
  }

  if (props.activeView === "success") {
    return additionalWidgetSuccessControls.value;
  }

  return [];
});
const customControls = {
  "widgetSettings-content": (currentControls: ControlProp[]) => {
    if (props.activeView === "success") {
      return [
        ...currentControls.filter(
          control => control.componentPath !== "form/ConfigTabs"
        ),
        ...additionalWidgetContentControls.value,
      ];
    }

    return [...currentControls, ...additionalWidgetContentControls.value];
  },
};
</script>
