import {
  FormStyleFieldName,
  FormStylingFieldInitial,
} from "~~/models/widgets/form.model";
import { TITLE_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/title-styling";
import { FIELDS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/fields-styling";
import { LABELS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/labels-styling";
import { TOOLTIPS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/tooltips-styling";
import { ERRORS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/errors-styling";
import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";
import { DROPDOWNS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/dropdowns-styling";
import { DROPDOWN_MENU_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/dropdown-menu-styling";
import { DRODOWN_ITEM_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/dropdown-items-styling";
import { BUTTON_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/buttons-styling";
import { UPLOAD_BUTTON_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/upload-button-styling";
import { RADIO_GROUP_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/radio-group-styling";
import { CALENDAR_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/calendar-styling";
import { DATEPICKER_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/datepicker-styling";
import { UPLOAD_LIST_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/upload-list-styling";
import { UPLOAD_ITEMS_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/upload-items-styling";
import { CAPTION_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/upload-caption-styling";
import { FIELDS_SUCCESS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/field-success-styling";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

export const STYLING_FIELDS: FormStylingFieldInitial = {
  [FormStyleFieldName.TITLE]: TITLE_STYLE_INITIAL,
  [FormStyleFieldName.LABEL]: LABELS_STYLE_INITIAL,
  [FormStyleFieldName.TOOLTIP]: TOOLTIPS_STYLE_INITIAL,
  [FormStyleFieldName.FIELD]: FIELDS_STYLE_INITIAL,
  [FormStyleFieldName.ERROR]: ERRORS_STYLE_INITIAL,
  [FormStyleFieldName.CHECKBOX]: CHECKBOX_STYLE_INITIAL,
  [FormStyleFieldName.DROPDOWNS]: DROPDOWNS_STYLE_INITIAL,
  [FormStyleFieldName.DROPDOWN_MENU]: DROPDOWN_MENU_STYLE_INITIAL,
  [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: DRODOWN_ITEM_STYLE_INITIAL,
  [FormStyleFieldName.RADIO_GROUP]: RADIO_GROUP_STYLE_INITIAL,
  [FormStyleFieldName.CALENDAR]: CALENDAR_STYLE_INITIAL,
  [FormStyleFieldName.DATEPICKER]: DATEPICKER_STYLE_INITIAL,
  [FormStyleFieldName.UPLOAD_BUTTON]: UPLOAD_BUTTON_STYLE_INITIAL,
  [FormStyleFieldName.UPLOAD_CAPTION]: CAPTION_STYLE_INITIAL,
  [FormStyleFieldName.UPLOAD_ITEMS]: UPLOAD_ITEMS_INITIAL,
  [FormStyleFieldName.UPLOAD_LIST]: UPLOAD_LIST_INITIAL,
  [FormStyleFieldName.SUCCESS]: FIELDS_SUCCESS_STYLE_INITIAL,
};

export const FORM_ELEMENTS_STYLE_TABS = {
  [FormStyleFieldName.TOOLTIP]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
  ],
  [FormStyleFieldName.FIELD]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.CHECKBOX]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.RADIO_GROUP]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.CALENDAR]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.UPLOAD_ITEMS]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
  [FormStyleFieldName.UPLOAD_BUTTON]: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
    generateTabItem("States", "states"),
  ],
};

export const LOGIN_FORM_STYLING_FIELDS: FormStylingFieldInitial = {
  [FormStyleFieldName.TITLE]: TITLE_STYLE_INITIAL,
  [FormStyleFieldName.FIELD]: FIELDS_STYLE_INITIAL,
  [FormStyleFieldName.LABEL]: LABELS_STYLE_INITIAL,
  [FormStyleFieldName.ERROR]: ERRORS_STYLE_INITIAL,
  [FormStyleFieldName.BUTTON]: BUTTON_STYLE_INITIAL,
};
