<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-control-container config-cell-input m-t-16 p-b-16 p-l-16 p-r-16"
  >
    <p class="settings-section-title p-b-16">Content settings</p>

    <ConfigRadioTextElements
      class="p-t-0 p-l-0 p-r-0 border-bottom-none config-cell-input__layout"
      label="Layout"
      :items="LAYOUT_TYPES"
      :model-value="modelValue?.layout"
      :default-selected="CellLayout.VERTICAL"
      @update:model-value="handleUpdate($event, 'layout')"
    />

    <ConfigOverflow
      label="Horizontal overflow"
      class="p-t-0 p-l-0 p-r-0 border-bottom-none"
      :model-value="modelValue?.overflow"
      @update:model-value="handleUpdate($event, 'overflow')"
    />

    <ConfigHorizontalSpace
      label="Gap"
      class="p-t-0 p-l-0 p-r-0 border-bottom-none"
      :model-value="modelValue?.gap"
      @update:model-value="handleUpdate($event, 'gap')"
    />

    <ConfigAlignmentFull
      class="p-t-0 p-l-0 p-b-0 p-r-0 border-bottom-none"
      label="Alignment"
      :model-value="modelValue?.alignment"
      :layout="modelValue?.layout"
      @update:model-value="handleUpdate($event, 'alignment')"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { ICellContentSettings, CellLayout } from "~~/models/grid.interface";

const LAYOUT_TYPES = [
  {
    label: "Horizontal",
    value: CellLayout.HORIZONTAL,
  },
  {
    label: "Vertical",
    value: CellLayout.VERTICAL,
  },
  {
    label: "Wrap",
    value: CellLayout.WRAP,
  },
];

const props = defineProps<{
  modelValue?: ICellContentSettings;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const emit = defineEmits<{
  (e: "update:model-value", event: ICellContentSettings): void;
}>();

const handleUpdate = (
  value: Partial<ICellContentSettings>,
  name: keyof ICellContentSettings
): void => {
  emit("update:model-value", {
    ...(props.modelValue || {}),
    [name]: value,
  });
};
</script>
