import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { getDefaultTheme } from "~~/constants/configs/common/widget-initial";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 81;

const generateField = (options: IWidgetOptions) => ({
  type: "UnknownField",
  value: null,
  options: options,
  name: "racing_event_timer",
  validation: {},
});

const v78WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "SportsMenuLiveFenixWidget" &&
    !widget.content.racing_event_timer
  ) {
    const options: IWidgetOptions = {
      ...TITLE_INITIAL(),
      theme: getDefaultTheme(13),
    };

    delete options.link;
    delete options.alignment;

    widget.content.racing_event_timer = generateField(options);
  }

  return widget;
};
export default v78WidgetMigration;
