export const EDITING_PAGE_NAME_SUCCESS = "Page name successfully edited";

export const EDITING_PAGE_NAME_ERROR = "Error editing page name. Try again";

export const TEMPLATE_RESET_SUCCES =
  "The template has been reset to the last saved version";

export const SUCCESSFULLY_UPDATED = "Successfully updated";

export const PAGE_NAME_REQUIRED = "Page name is required";

export const CREATING_PAGE_SUCCESS = "Page created";

export const CREATING_PAGE_ERROR = "Page not created, try again";

export const DELETING_PAGE_ERROR = "Page not deleted, try again";

export const COPYING_PAGE_ERROR = "Page not copied, try again";

export const COPYING_PAGE_SUCCESS = "Page successfully copied";

export const TEMPLATE_NAME_CHANGE_SUCCESS =
  "Template name was successfully edited";

export const TEMPLATE_NAME_CHANGE_ERROR =
  "Template name wasn't edited! Try again please";

export const FILE_UPLOADING_SUCCESS = "File was successfully uploaded";

export const FILE_PROCESSING_SUCCESS = "File has been processed successfully";

export const FILE_UPLOADING_ERROR = "File wasn't uploaded, try again";

export const PAGE_SAVE_SUCCESS = "Page was successfully saved";

export const PAGE_SAVE_ERROR = "Page wasn't saved! Try again, please";
