<template>
  <div
    :class="{
      'config-sizing': true,
    }"
  >
    <a-tooltip placement="left">
      <template
        v-if="isDisabled"
        #title
      >
        <span>{{ tooltipMessage }}</span>
      </template>
      <div
        class="config-sizing__state-element f-base pointer"
        :class="{
          'config-sizing__state-element--active':
            sizing === ControlSizing.FIXED,
          'config-sizing__state-element--disabled':
            isDisabled && sizing !== ControlSizing.FIXED,
        }"
        @click="handleUpdate(ControlSizing.FIXED)"
      >
        <CommonIcon name="custom:grid-fixed-layout" />
        <div class="config-sizing__label text-center">Fixed</div>
      </div>
    </a-tooltip>

    <a-tooltip placement="left">
      <template
        v-if="isDisabled"
        #title
      >
        <span>{{ tooltipMessage }}</span>
      </template>

      <div
        class="config-sizing__state-element f-base pointer"
        :class="{
          'config-sizing__state-element--active':
            sizing === ControlSizing.ADAPTIVE,
          'config-sizing__state-element--disabled':
            isDisabled && sizing !== ControlSizing.ADAPTIVE,
        }"
        @click="handleUpdate(ControlSizing.ADAPTIVE)"
      >
        <CommonIcon name="custom:grid-adaptive-layout" />
        <div class="config-sizing__label text-center">Adaptive</div>
      </div>
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import { ControlSizing } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    sizing: ControlSizing;
    isDisabled?: boolean;
    tooltipMessage?: string;
  }>(),
  {
    sizing: ControlSizing.ADAPTIVE,
    isDisabled: false,
    tooltipMessage: "",
  }
);

const emit = defineEmits<{
  (e: "update:sizing", event: ControlSizing): void;
}>();

const handleUpdate = (value: ControlSizing) => {
  if (!props.isDisabled) {
    emit("update:sizing", value);
  }
};
</script>

<style lang="scss">
.config-sizing {
  @include flex(center, space-between, $space-s);

  &__label {
    padding-top: 4px;
  }

  &__state-element {
    position: relative;
    padding: 12px;
    color: $c-black;
    border: 1px solid $c-grey-15;
    border-radius: 6px;
    @include flex-column(center, space-between, 0);

    svg {
      width: 88px;
      height: 64px;
    }

    &--active {
      padding: 11px;
      border: 2px solid $c-primary-base;
    }

    &--disabled {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}
</style>
