<template>
  <div
    :style="containerStyles"
    :class="`sportsbook-filter--${displayOrientation}`"
    class="sportsbook-filter"
  >
    <div
      v-if="showDate"
      :style="distanceStyles"
      class="sportsbook-filter__time"
      :class="{
        'sportsbook-filter__time--stretch': !showMarkets,
      }"
    >
      <WidgetField
        :widget="widget"
        :field="objectFields.date_from"
        class="sportsbook-filter__time-item"
        no-overflow
      >
        <FormDateField
          :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[0] = el;
              }
            "
          :field="objectFields.date_from"
          :styling-fields="dateInputStyleFields"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="objectFields.date_to"
        class="sportsbook-filter__time-item"
        no-overflow
      >
        <FormDateField
          :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[1] = el;
              }
            "
          :field="objectFields.date_to"
          :styling-fields="dateInputStyleFields"
          :states="states"
        />
      </WidgetField>
    </div>

    <WidgetField
      v-if="showMarkets"
      :widget="widget"
      :field="objectFields.markets"
      class="sportsbook-filter__market"
    >
      <FormDropdownField
        :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[2] = el;
              }
            "
        :field="objectFields.markets"
        :styling-fields="marketInputStyleFields"
        :states="states"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TriggerComponent } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { FormElementName } from "~~/models/widgets/form.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexPosition } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  fields: IWidgetField[];
  states: Record<string, State>;
  visibility?: string;
}>();

const objectFields = computed<Record<string, IWidgetField>>(() => {
  return props.fields.reduce((result, curr) => {
    return {
      ...result,
      [curr.name]: curr,
    };
  }, {});
});

const filterFormFields = computed<IWidgetField[]>(() => {
  return [
    objectFields.value.date_from,
    objectFields.value.date_to,
    objectFields.value.markets,
  ];
});

const { fieldsRefsList, getFieldStylings } = useFormInner(
  props.widget,
  filterFormFields
);

const distanceStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.distance),
  };
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...distanceStyles.value,
    justifyContent: getFlexPosition(props.field.options.alignment),
  };
});

const dateInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DATE);
});

const marketInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DROPDOWN);
});

const displayOrientation = computed<string>(() => {
  return props.field.options.displayMode;
});

const showDate = computed<boolean>(() => {
  if (!props.visibility) {
    return true;
  }

  return props.visibility === "all" || props.visibility === "period";
});

const showMarkets = computed<boolean>(() => {
  if (!props.visibility) {
    return true;
  }

  return props.visibility === "all" || props.visibility === "market";
});
</script>

<style lang="scss">
.sportsbook-filter {
  @include flex(flex-end, flex-start, 24px);

  &__time {
    @include flex(flex-end, flex-start, 24px);
    flex-basis: 50%;
  }

  &__market {
    flex-basis: 50%;
  }

  &__time-item {
    flex-grow: 1;
    flex-basis: 50%;
  }

  &--vertical {
    @include flex-column(flex-start, flex-start, 24px);

    .sportsbook-filter__time {
      width: 100%;

      &--stretch {
        flex: 1;
      }
    }

    .sportsbook-filter__market {
      flex-grow: 1;
      width: 100%;
    }
  }
}
</style>
