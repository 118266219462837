export const useTransformEmptyArray = <T extends Record<string, any>>(
  object: T
): { [K in keyof T]: T[K] | "" } => {
  return Object.entries(object || {}).reduce((acc, [lang, value]) => {
    if (Array.isArray(value) && !value.length) {
      acc[lang as keyof T] = "" as T[keyof T];
    } else {
      acc[lang as keyof T] = value;
    }
    return acc;
  }, {} as { [K in keyof T]: T[K] | "" });
};
