import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

export const useItems = () => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Cards", "event_card"),
    generateDropdownItem("Photo", "event_image"),
    generateDropdownItem("Event info", "event_info"),
    generateDropdownItem("Bet items", "bet_items"),
    generateDropdownItem("Button", "event_button"),
    generateDropdownItem("Team / player", "event_name"),
    generateDropdownItem("Date / time", "event_date_time"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    event_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return DEFAULT_DROPDOWN_ITEMS;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
