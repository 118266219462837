import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";
import { State } from "~~/models/widgets/widget-controls.model";

const WIDGET_VERSION = 9;

const v9WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["PromotedBetsListWidget"].includes(widget.name)) {
    const betItems = widget.content.bet_items;

    const themes = ["name", "coef"];
    if (betItems) {
      themes.forEach(theme => {
        if (betItems.options.states[State.HOVER][theme]?.theme) {
          delete betItems.options.states[State.HOVER][theme].theme;
          delete betItems.options.states[State.ACTIVE][theme].theme;
          delete betItems.options.states[State.DISABLED][theme].theme;
        }
      });
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v9WidgetMigration;
