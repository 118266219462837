import type { Ref } from "vue";

import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { FormElementName } from "~~/models/widgets/form.model";
import { generateFormContentDropdownItems } from "~~/assets/utils/widget/form";

export const useItems = (data: {
  widget: Ref<IWidgetWithFields>;
  customFormElements: Record<string, string[]>;
  customLabels?: Record<string, string>;
}) => {
  const { widget, customFormElements, customLabels } = data;

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    [FormElementName.BUTTON]: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const formDropdownItems = computed<DropdownItem[]>(() => {
    return [
      ...generateStyleDropdownItems(widget.value.fields),
      ...generateFormContentDropdownItems(
        widget.value.fields,
        customFormElements,
        customLabels
      ),
    ];
  });

  return {
    TABS,
    formDropdownItems,
  };
};
