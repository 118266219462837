import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  VerticalAlignment,
  State,
  TextStyle,
  DisplayOrientation,
  Alignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { TEXT_BUTTON_INITIAL } from "~~/constants/configs/common/text-button-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const LIST_FILTER_FIELDS = [
  "filter",
  "date_from",
  "date_to",
  "markets",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "calendar_styling",
  "datepicker_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

export const useSportsbookInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, State> = {
    live_button_title: State.DEFAULT,
    winner_league_bet_items: State.DEFAULT,
    list_item: State.DEFAULT,
    list_item_more: State.DEFAULT,
    event_cards: State.ACTIVE_EVENTS,
    betbuilder_button: State.DEFAULT,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.CALENDAR]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({ ...DEFAULT_STATES });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /*
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value?.fields) {
      const fieldDetails = widgetCacheData.value?.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialHeaderOptions = prefillEmptyOptions(
      fields.value.header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            alignment: VerticalAlignment.MIDDLE,
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.live_button_title.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
      }).options
    );

    const initialLeagueWinnerTitleOptions = prefillEmptyOptions(
      fields.value.winner_league_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialWinnerCardOptions = prefillEmptyOptions(
      fields.value.winner_league_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            displaySettings: 1,
            horizontalSpacing: 16,
            verticalSpacing: 16,
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.winner_league_bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
          padding: getDefaultSpacing(8),
        },
      }).options
    );

    const initialListTitleOptions = prefillEmptyOptions(
      fields.value.list_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialEventsFilterOptions = prefillEmptyOptions(
      fields.value.filter.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            distance: 24,
            displayMode: DisplayOrientation.HORIZONTAL,
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.list_container.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distanceBetweenItems: 16,
              displayMode: DisplayOrientation.HORIZONTAL,
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            columns: 1,
            distanceBetweenCards: 16,
          },
        },
      }).options
    );

    const initialStartTimeDateOptions = prefillEmptyOptions(
      fields.value.list_start_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            icon: {
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
            },
            spaceBetween: 12,
          },
        },
        values: {
          padding: getDefaultSpacing({ bottom: 16 }),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialEventsLeagueeOptions = prefillEmptyOptions(
      fields.value.league_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          padding: getDefaultSpacing({ bottom: 4 }),
        },
      }).options
    );

    const initialTeamPlayerOptions = prefillEmptyOptions(
      fields.value.list_event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing({ bottom: 4 }),
        },
      }).options
    );

    const initialBuildButtonOptions = prefillEmptyOptions(
      fields.value.betbuilder_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          color: getDefaultFillValue("#1677FF"),
          cornerRadius: getDefaultCornerRadiusValue(50),
        },
      }).options
    );

    const initialListBetItemsOptions = prefillEmptyOptions(
      fields.value.list_item.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing({
              top: 5,
              right: 8,
              bottom: 5,
              left: 8,
            }),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialNoEventsTextOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialListItemMoreState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue(),
      };
    };

    const initialListItemMoreOptions = prefillEmptyOptions(
      fields.value.list_item_more.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [TextStyle.BOLD],
            states: {
              [State.HOVER]: initialListItemMoreState(),
              [State.ACTIVE]: initialListItemMoreState(),
              [State.DISABLED]: initialListItemMoreState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#000000", 6),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            margin: getDefaultSpacing({
              left: 16,
            }),
            padding: getDefaultSpacing({
              top: 4,
              bottom: 4,
              left: 8,
              right: 8,
            }),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.header.field,
      initialHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.live_button_title.field,
      initialButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_title.field,
      initialLeagueWinnerTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_card.field,
      initialWinnerCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_title.field,
      initialListTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.filter.field,
      initialEventsFilterOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_container.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_start_time.field,
      initialStartTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.league_title.field,
      initialEventsLeagueeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_event_name.field,
      initialTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_item.field,
      initialListBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_item_more.field,
      initialListItemMoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTextOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_button.field,
      initialBuildButtonOptions
    );
    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    filterFields,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
