import { type Ref, onBeforeUpdate, watch } from "vue";

import { FORM_STYLING_FIELDS_OPTIONS } from "~~/constants/widget-details/form/form-fields-styling";
import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  FormElementName,
  FormStyleFieldName,
  TriggerComponent,
} from "~~/models/widgets/form.model";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useUiStore } from "~~/store/ui";

export const useFormInner = (
  widget: IWidgetWithFields,
  formFields: Ref<IWidgetField[]>
) => {
  const fieldsRefsList = ref<TriggerComponent[]>([]);

  const widgetSettingsStore = useWidgetSettingsStore();
  const uiStore = useUiStore();

  const { activeSideMenuTab } = storeToRefs(uiStore);

  const { selectedDropdownItem, selectedWidget } =
    storeToRefs(widgetSettingsStore);

  const stylingFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field => field.options._custom);
  });

  const registerFieldRef = (index: number) => {
    return (fieldRef: TriggerComponent | undefined) => {
      if (fieldRef) {
        fieldsRefsList.value[index] = fieldRef;
      } else {
        delete fieldsRefsList.value[index];
      }
    };
  };

  const findFieldsIndexByType = (types: string[]): Array<number> => {
    const tmpFormFields = formFields.value.map((field, index) => {
      return {
        field: field,
        index: index,
      };
    });

    return tmpFormFields
      .filter(el => types.includes(el.field.type))
      .map(el => el.index);
  };

  const findTriggerFieldElement = (
    stylingFieldName: string
  ): Array<TriggerComponent> | undefined => {
    const fieldTypes = Object.keys(FORM_STYLING_FIELDS_OPTIONS).filter(
      fieldName => {
        return FORM_STYLING_FIELDS_OPTIONS[
          fieldName as FormElementName
        ].includes(stylingFieldName as FormStyleFieldName);
      }
    );

    if (!fieldTypes.length) {
      return;
    }

    const firstAppropriateFieldIndexs = findFieldsIndexByType(fieldTypes);

    if (firstAppropriateFieldIndexs.length < 0) {
      return;
    }

    /*
      Show only 1 element for calendar
    */
    if (stylingFieldName === FormStyleFieldName.CALENDAR) {
      return [fieldsRefsList.value[firstAppropriateFieldIndexs[0]]];
    }

    return firstAppropriateFieldIndexs.map(
      index => fieldsRefsList.value[index]
    );
  };

  const getFieldStylings = (type: string) => {
    const fields = stylingFields.value.filter(field => {
      return FORM_STYLING_FIELDS_OPTIONS[type as FormElementName].includes(
        field.name as FormStyleFieldName
      );
    });

    return fields;
  };

  const showFieldTrigger = (stylingFieldName: string): void => {
    if (widget.id !== selectedWidget.value?.id) return;

    const triggerElement = findTriggerFieldElement(stylingFieldName);

    if (!triggerElement) {
      return;
    }

    triggerElement.forEach(el => {
      el?.triggerFieldExample?.(stylingFieldName);
    });
  };

  const hidePreviousFieldTrigger = (stylingFieldName: string): void => {
    const triggerElement = findTriggerFieldElement(stylingFieldName);

    if (!triggerElement) {
      return;
    }

    triggerElement.forEach(el => {
      el?.hideFieldExample?.(stylingFieldName);
    });
  };

  watch(
    () => selectedDropdownItem.value,
    (newValue, prevValue) => {
      hidePreviousFieldTrigger(prevValue as FormStyleFieldName);

      if (!newValue) {
        return;
      }

      showFieldTrigger(newValue);
    }
  );

  watch(
    () => activeSideMenuTab.value,
    newValue => {
      if (newValue === SideMenuTab.PAGES) {
        hidePreviousFieldTrigger(
          selectedDropdownItem.value as FormStyleFieldName
        );
        return;
      }

      showFieldTrigger(selectedDropdownItem.value as string);
    }
  );

  onBeforeUpdate(() => {
    fieldsRefsList.value = [];
  });

  return {
    getFieldStylings,
    fieldsRefsList,

    showFieldTrigger,
    hidePreviousFieldTrigger,
    registerFieldRef,
  };
};
