import { releaseVersion } from "~~/constants/release-version";

declare global {
  interface Window {
    showReleaseVersion: () => void;
  }
}

export default {
  install: () => {
    const showReleaseVersion = () => {
      console.log(`Current Release Version: ${releaseVersion}`);
      return "";
    };

    if (typeof window !== "undefined") {
      window.showReleaseVersion = showReleaseVersion;

      window.addEventListener(
        "show-release-version",
        window.showReleaseVersion
      );
    }
  },
};
