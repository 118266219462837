import { defineStore } from "pinia";

import http from "~~/services/http";
import {
  ILanguage,
  ILanguageProperties,
  ILanguagesResponse,
} from "~~/models/widgets/languages.interface";
import { ILanguagesStore } from "~~/models/stores/languages-store.model";

export const useLanguagesStore = defineStore("languages", {
  state: (): ILanguagesStore => ({
    availableLanguages: {} as ILanguage,
    currentLanguage: null,
  }),

  getters: {
    currentLanguageCode: state => {
      if (!state.currentLanguage) {
        return "";
      }

      return state.currentLanguage.codeWithRegion;
    },

    availableLanguagesCodes: state => {
      return Object.keys(state.availableLanguages).reduce((acc, key) => {
        const languageCode = state.availableLanguages[key].codeWithRegion;

        acc[languageCode] = "";

        return acc;
      }, {} as Record<string, "">);
    },

    defaultLanguage: state =>
      Object.values(state.availableLanguages).find(
        language => language.isDefault
      ),

    isDefaultLanguage: state => Boolean(state.currentLanguage?.isDefault),
  },

  actions: {
    setAvailableLanguages(items: ILanguage): void {
      this.availableLanguages = items;
    },

    setCurrentLanguage(language: ILanguageProperties): void {
      this.currentLanguage = language;
    },

    async fetchAvailableLanguages(): Promise<void> {
      const response: ILanguagesResponse = await http.get("/languages");
      const { data } = response;

      Object.keys(data.items).forEach(key => {
        const localeItem = data.items[key];

        data.items[key].codeWithRegion = localeItem.region
          ? `${localeItem.code}-${localeItem.region}`
          : localeItem.code;
      });

      const defaultLanguage = Object.values(data.items).find(
        language => language.isDefault === true
      );

      this.setCurrentLanguage(defaultLanguage!);

      this.setAvailableLanguages(data.items);
    },
  },
});
