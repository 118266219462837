import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useItems } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixMobileItems";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useSportsbookLiveFenixSportsConfig } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixSportsConfig";
import { useSportsbookLiveFenixLeaguesConfig } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixLeaguesConfig";
import { useSportsbookLiveFenixSportEventsConfig } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixSportEventsConfig";
import { useSportsbookLiveFenixCardContentConfig } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixCardContentConfig";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useNoItemsTextConfig } from "../common/useNoItemsTextConfig";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const {
    sportsContainerDesignControls,
    sportsContainerContentControls,
    sportsContainerStateControls,

    sportsTitleDesignControls,
    sportsTitleContentControls,
    sportsTitleStatesControls,
  } = useSportsbookLiveFenixSportsConfig(widget, states, emit);

  const { leaguesTitleDesignControls, leaguesTitleContentControls } =
    useSportsbookLiveFenixLeaguesConfig(widget);

  const {
    sportEventsDesignControls,
    sportEventsContentControls,

    sportEventsCardsDesignControls,
    sportEventsCardsContentControls,
    racingEventsCardsContentControls,
    sportEventsCardsStatesControls,

    sportEventHeadersDesignControls,
    sportEventHeadersContentControls,
  } = useSportsbookLiveFenixSportEventsConfig(widget, states, emit);

  const {
    textWithDisplayControls,
    favIconControls,
    streamIconControls,
    eventTimeControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    eventNameControls,

    eventScoreContentControls,
    eventScoreDesignControls,

    eventButtonDesignControls,
    eventButtonContentControls,
    eventButtonStatesControls,

    racingStatusDesignControls,
    racingStatusContentControls,
  } = useSportsbookLiveFenixCardContentConfig(widget, states, emit);

  const { betItemsDesignControls, betItemsContentControls } = useBetItemsConfig(
    states,
    emit,
    true
  );

  /* 
    Widget config
  */
  const widgeDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const {
    eventCardsNoContentControls: widgetNoContentControls,
    stateControls,
  } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: "widget",
      noTextFieldName: "no_events_title",
      customLabels: {
        [State.ACTIVE_EVENTS]: "There are active events",
        [State.NO_ACTIVE]: "No active events",
      },
    }),
    emit
  );

  const widgetContentControls = computed(() => {
    if (states.value.widget === State.NO_ACTIVE) {
      return [
        ...getInitialContentControlsList({
          source: widget.value,
        }),
        ...stateControls.value,
        ...widgetNoContentControls.value,
      ];
    }

    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      ...stateControls.value,
      {
        section: "Sports",
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.sports_container.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distanceBetweenSports",
            valueSource: fields.value.sports_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between sports",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
    ];
  });

  const titleButtonState = computed<State>(() => {
    return states.value.title_button;
  });

  const {
    buttonStatesControls: titleButtonStatesControls,
    buttonDesignControls: titleButtonDesignControls,
    buttonContentControls: titleButtonContentControls,
  } = useButtonControls(
    {
      state: titleButtonState,
      stateHandler: (state: State) => {
        emit("update-state", { state: "title_button", value: state });
      },
      exclude: {
        content: ["ConfigLinkType"],
      },
    },
    emit
  );

  /* 
    Bet items stateControls
  */

  const currentBetItemPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const liveBetStates = computed(() => {
    return [
      {
        label: STATE_KEY_LABELS.up,
        value: State.UP,
      },
      {
        label: STATE_KEY_LABELS.down,
        value: State.DOWN,
      },
    ];
  });

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            ...liveBetStates.value,

            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
            {
              label: "Suspended",
              value: "suspended",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        visible:
          (states.value[selectedField.value!.name] as string) !== "suspended",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        visible: [State.UP, State.DOWN, State.HOVER, State.ACTIVE].includes(
          states.value[selectedField.value!.name]
        ),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.up.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !==
              State.DOWN,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Up color",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.down.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !== State.UP,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Down color",
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgeDesignControls,
    widgetContentControls,

    /* 
      Sports container
    */

    sportsContainerDesignControls,
    sportsContainerContentControls,
    sportsContainerStateControls,

    /* 
      Sports title
    */
    sportsTitleDesignControls,
    sportsTitleContentControls,
    sportsTitleStatesControls,

    /* 
      Leagues title
    */
    leaguesTitleDesignControls,
    leaguesTitleContentControls,

    /* 
      Sport events
    */
    sportEventsDesignControls,
    sportEventsContentControls,

    /* 
      Sport event card
    */

    sportEventsCardsDesignControls,
    sportEventsCardsContentControls,
    racingEventsCardsContentControls,
    sportEventsCardsStatesControls,

    sportEventHeadersDesignControls,
    sportEventHeadersContentControls,

    /* 
      League name
    */
    textWithDisplayControls,
    favIconControls,
    streamIconControls,
    eventTimeControls,

    /* 
      Event info container
    */
    eventInfoDesignControls,
    eventInfoContentControls,

    eventNameControls,

    eventScoreContentControls,
    eventScoreDesignControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    eventButtonDesignControls,
    eventButtonContentControls,
    eventButtonStatesControls,

    racingStatusDesignControls,
    racingStatusContentControls,

    titleButtonStatesControls,
    titleButtonDesignControls,
    titleButtonContentControls,
  };
};
