import {
  Grid,
  ICell,
  ICellNullable,
  CellId,
  NestingLevel,
} from "~~/models/grid.interface";
import { ISaveLayoutData } from "~~/models/stores/grid-store.model";
import { deepCopy, sortByKeyname } from "~~/assets/utils";
import { Breakpoint } from "~~/models/breakpoints.model";
import { addCellToGrid, findCell } from "~~/assets/utils/grid/grid-tree";

export const resetOnlyNumericId = (id: CellId | null): CellId | null => {
  if (typeof id === "number" && id < 0) {
    return null;
  }

  return id;
};

export const resetIdsForAddedCells = (
  cells: ICellNullable[]
): ICellNullable[] => {
  if (!cells) {
    return [] as ICellNullable[];
  }

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    cells[i] = {
      ...cell,
      cellId: resetOnlyNumericId(cell.cellId),
      parentId: resetOnlyNumericId(cell.cellId),
      children: resetIdsForAddedCells(cell.children),
    };
  }

  return cells;
};

export const generateCreateLayoutData = (
  cells: ICell[],
  name: string
): ISaveLayoutData => {
  const copy = deepCopy<ICell[]>(cells) as ICellNullable[];

  const layoutWithNullIds: ICellNullable[] = resetIdsForAddedCells(copy);

  const body: ISaveLayoutData = {
    name: name,
    cells: layoutWithNullIds,
  };

  return body;
};

export const generateInitiaLayout = (): Grid => {
  const mobile = addCellToGrid(
    [],
    { parentId: null },
    {
      columns: 1,
      level: NestingLevel.PARENT,
    }
  );

  const layouts = {
    [Breakpoint.MOBILE]: mobile.grid,
    [Breakpoint.TABLET]: [],
    [Breakpoint.DESKTOP]: [],
  };

  return layouts;
};

export const findCellFromGridLayout = (
  grid: Grid,
  cellId: number | string
): ICell | null => {
  for (const screen in grid) {
    const currentWidgetsLayout = grid[screen as Breakpoint];
    const cell = findCell(currentWidgetsLayout!, cellId);

    if (cell) {
      return cell;
    }
  }

  return null;
};

export const sortGridCells = (cells: ICell[]): ICell[] => {
  cells.forEach(cell => {
    if (!cell.children || !cell.children.length) {
      return;
    }

    cell.children = sortGridCells(cell.children);
  });

  return sortByKeyname(cells!, "position");
};

export const sortGridByPosition = (grid: Grid): Grid => {
  for (const key in grid) {
    const cells = grid[key as Breakpoint];

    grid[key as Breakpoint] = sortGridCells(cells!);
  }

  return grid;
};
