import { IDraggablePayload } from "~~/models/dnd.model";
import { DndContainerId } from "~~/models/common/dropdown.model";
import { PAYLOAD_DND_KEY } from "~~/constants/dnd";
import { events, GlobalEvents } from "~~/services/global-events";
import { useDndStore } from "~~/store/dnd";

const loadImage = (baseElementId: string) => {
  fetch(`/draggingImages/widgets/${baseElementId}.txt`)
    .then(res => res.text())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .then(_res => {
      // FIXME: refactor it, because browser can cache GET fetch requests, local storage is not needed here
      // localStorage.setItem(baseElementId, res);
    });
};

const getImage = (baseElementId: string) => {
  return localStorage.getItem(baseElementId);
};

const createGhostImage = (e: DragEvent, baseElementId: string): void => {
  if (!e.dataTransfer) {
    return;
  }

  const el = document.getElementById(baseElementId) as Element;

  let tmp: HTMLElement;
  if (el) {
    tmp = el.cloneNode(true) as HTMLElement;
  } else {
    // TODO: review logic for consistency
    tmp = document.createElement("div");
    tmp.style.padding = "16px";
    tmp.style.backgroundColor = "#fff";
    tmp.style.borderRadius = "4px";
    tmp.style.opacity = "0.8";

    const image = new Image();
    const imageB64 = getImage(baseElementId);

    if (!imageB64) return;

    image.src = imageB64;
    tmp.appendChild(image);
  }
  /*
    We'll need anchor to remove this element later
  */
  tmp.dataset.isTemporary = "true";
  /*
    We need to take temporary element out of viewport
  */
  tmp.style.position = "absolute";
  tmp.style.top = "-1000px";
  tmp.style.left = "-1000px";
  document.body.appendChild(tmp);

  e.dataTransfer.setDragImage(tmp, tmp.clientWidth / 2, tmp.clientHeight / 2);
};

const onDragStart = (e: DragEvent): void => {
  /*
    Prevent event firing few times
  */
  e.stopImmediatePropagation();
  if (!e.dataTransfer) {
    return;
  }

  const target = e.target as HTMLElement;

  const payload: string | null = target.getAttribute("payload");
  const dndImageId: string | null = target.getAttribute("image");

  if (dndImageId) {
    createGhostImage(e, dndImageId);
  } else {
    console.warn(
      "imageSelectorId property should be provided for v-draggable directive. Unexpected behaviour will occur otherwise in some browsers"
    );
  }

  const containerId: string | null = target.getAttribute("container");
  if (containerId) {
    useDndStore().setActiveDndContainerId(containerId as DndContainerId);
  }

  if (payload) {
    const parsedPayload = JSON.parse(payload);

    const transferPayload: IDraggablePayload = {
      payload: parsedPayload,
    };

    e.dataTransfer.setData(PAYLOAD_DND_KEY, JSON.stringify(transferPayload));
  }
};

const onDragEnd = (): void => {
  useDndStore().setActiveDndContainerId(null);

  /*
    Remove tmp element
  */
  const el = document.querySelector('[data-is-temporary="true"]');

  if (!el) {
    return;
  }

  el.remove();
};

const setElementDraggableAttr = (el: HTMLElement, disabled: boolean): void => {
  if (!disabled) {
    el.setAttribute("draggable", "true");
  } else {
    el.removeAttribute("draggable");
  }
};

const VDraggable = {
  mounted(el: HTMLElement, binding: { value: any }) {
    const disabled: boolean = binding.value.disabled;
    setElementDraggableAttr(el, disabled);

    el.setAttribute("payload", JSON.stringify(binding.value.payload));

    if (binding.value.imageSelectorId) {
      el.setAttribute("image", binding.value.imageSelectorId);

      if (
        !binding.value.payload.columns &&
        !getImage(binding.value.imageSelectorId)
      ) {
        loadImage(binding.value.imageSelectorId);
      }
    }

    if (binding.value.containerId) {
      el.setAttribute("container", binding.value.containerId);
    }

    if (binding.value.onDragStart) {
      el.addEventListener("dragstart", binding.value.onDragStart);
    }

    el.addEventListener("dragstart", onDragStart);

    /*
      Two cases when dragging is ended.
      Canceled or dropped
    */
    el.addEventListener("dragend", onDragEnd);

    /*
      Catch all drop events on window
    */
    events.on(GlobalEvents.WINDOW_DROP, onDragEnd);
  },
  beforeUnmount(el: HTMLElement, binding: { value: any }) {
    el.removeEventListener("dragstart", onDragStart);

    if (binding.value.onDragStart) {
      el.removeEventListener("dragstart", binding.value.onDragStart);
    }

    el.removeEventListener("dragend", onDragEnd);

    events.off(GlobalEvents.WINDOW_DROP, onDragEnd);
  },
  updated(el: HTMLElement, binding: { value: any }) {
    const disabled: boolean = binding.value.disabled;

    setElementDraggableAttr(el, disabled);
  },
};

export default VDraggable;
