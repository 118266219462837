import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const generateEventContainerField = () => ({
  "name": "event_container",
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
    "fill": {
      "color": "#B5B5B5",
      "opacity": 100,
    },
    "border": {
      "fill": {
        "color": "",
        "opacity": 100,
      },
      "width": 0,
      "style": "solid",
    },
    "shadow": {
      "fill": {
        "color": "",
        "opacity": 100,
      },
      "x": 0,
      "y": 0,
      "blur": 0,
      "spread": 0,
    },
    "cornerRadius": 6,
    "spaceBetween": 0,
    "padding": {
      "top": 5,
      "bottom": 5,
      "left": 0,
      "right": 0,
    },
    "margins": {
      "top": 0,
      "bottom": 0,
    },
  },
});

const WIDGET_VERSION = 2;

const v2WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["BetslipWidget"].includes(widget.name)) {
    if (!("event_container" in widget.content)) {
      widget.content.event_container = generateEventContainerField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v2WidgetMigration;
