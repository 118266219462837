export interface IRuntimeForm {
  [key: string]: any;
}

export class RuntimeForm<T extends IRuntimeForm> {
  private readonly form: T;

  constructor(form: T) {
    this.form = form;
  }

  public formData(): FormData {
    const form = new FormData();

    Object.keys(this.form).forEach(key => {
      if (Array.isArray(this.form[key])) {
        (this.form[key] as (string | Blob)[]).forEach(item => {
          form.append(key, item);
        });
      } else if (this.form[key] !== undefined) {
        form.append(key, this.form[key]);
      }
    });

    return form;
  }

  public queryString(): string {
    const queryParams = new URLSearchParams();

    Object.entries(this.form).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(arrayEl => {
          queryParams.append(key, arrayEl);
        });
      } else if (value !== undefined) {
        queryParams.append(key, value);
      }
    });

    return queryParams.toString();
  }
}
