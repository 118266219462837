<template>
  <div class="widget-settings-content">
    <div class="p-l-16 p-r-16 p-t-16 m-b-16 widget-settings-content__header">
      <WidgetSettingsDropdown
        :model-value="selectedDropdownItem"
        :items="currentGroupDropdownItems"
        :custom-active-option-selector="customActiveOptionSelector"
        @update:model-value="handleUpdateDropdownItem"
      />

      <a-button
        v-if="!isWidgetSettingsScreen"
        shape="circle"
        class="widget-settings-content__icon"
        @click="handleCloseSettingsTab"
      >
        <template #icon>
          <CommonIcon name="ant-design:close-outlined" />
        </template>
      </a-button>
    </div>

    <div
      v-if="
        selectedDropdownItem ===
        WidgetDefaultSettingsDropdownItem.PARENT_SETTINGS
      "
    >
      <WidgetParentSettings />
    </div>

    <div
      v-else-if="
        selectedDropdownItem ===
        WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS
      "
    >
      <WidgetChildSettings />
    </div>

    <div
      v-else-if="
        selectedDropdownItem === WidgetDefaultSettingsDropdownItem.CELL_SETTINGS
      "
    >
      <WidgetCellSettings />
    </div>

    <div
      v-else-if="currentTabs && currentTabs.length"
      class="widget-settings-content__tabs"
    >
      <a-tabs
        centered
        animated
        :active-key="activeTab"
        class="widget-settings-content__tabs"
        @update:active-key="handleChangeTab"
      >
        <a-tab-pane
          v-for="tab in currentTabs"
          :key="tab.value"
          :tab="tab.label"
        >
          <slot :name="`${tab.parent}-${tab.value}`"></slot>
        </a-tab-pane>
      </a-tabs>
    </div>

    <div v-else-if="currentUniqueDropdownItems.length">
      <div
        v-for="dropdownItem in currentUniqueDropdownItems"
        :key="dropdownItem.value"
        :name="dropdownItem.value"
      >
        <slot :name="dropdownItem.value"></slot>
      </div>
    </div>

    <div v-else-if="selectedDropdownItem">
      <slot :name="selectedDropdownItem"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { WidgetDefaultSettingsDropdownItem } from "~~/models/widgets/widget.core/widget.model";
import {
  Tab,
  ItemTabs,
  DROPDOWN_ROOT_ITEMS_GROUP,
} from "~~/models/settings/settings-sidebar.model";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";
import { useViewActiveWidgetSettings } from "~~/composables/widgets-config/useViewActiveWidgetSettings";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { NestingLevel } from "~~/models/grid.interface";
import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useUiStore } from "~~/store/ui";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  dropdownItems?: DropdownItem[];
  tabs?: ItemTabs;
  customDefaultTab?: string;
  customActiveOptionSelector?: (options: DropdownItem[]) => DropdownItem;
}>();

const emit = defineEmits<{
  (e: "close", event: string): void;
  (e: "select", event: DropdownItem): void;
  (e: "blur-tab", event: string): void;
  (e: "change", event: string | null): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const defaultTab =
  hasContentPermissions && !hasDesignPermissions ? "content" : "design";

const activeTab = ref<string>(props.customDefaultTab || defaultTab);

const widgetSettingsStore = useWidgetSettingsStore();
const uiStore = useUiStore();
const gridStore = useGridConfig();

const { selectedDropdownItem, selectedCell, selectedWidget } =
  storeToRefs(widgetSettingsStore);
const { isWidgetSettingsScreen, closeSettingsTab } =
  useViewActiveWidgetSettings();
const { activeSideMenuTab } = storeToRefs(uiStore);

const currentUniqueDropdownItems = computed(() => {
  const asObject = (props.dropdownItems || []).reduce<
    Record<string, DropdownItem>
  >((result, curr) => {
    if (curr.value !== selectedDropdownItem.value) return result;

    return {
      ...result,
      [curr.value as string]: curr,
    };
  }, {});

  return Object.values(asObject);
});

const columnSettingsDisabled = computed<boolean>(() => {
  if (selectedCell.value?.settings?.level === NestingLevel.PARENT) {
    return true;
  }

  return false;
});

const defaultDropdownItems = computed<DropdownItem[]>(() => {
  return [
    {
      label: `${selectedWidget.value?.name ?? "Widget"} Settings`,
      value: WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS,
      group: DROPDOWN_ROOT_ITEMS_GROUP,
    },
    {
      label: "Child Container Settings",
      value: WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS,
      group: DROPDOWN_ROOT_ITEMS_GROUP,
      disabled: columnSettingsDisabled.value,
    },
    {
      label: "Cell Settings",
      value: WidgetDefaultSettingsDropdownItem.CELL_SETTINGS,
      group: DROPDOWN_ROOT_ITEMS_GROUP,
    },
    {
      label: "Parent Container Settings",
      value: WidgetDefaultSettingsDropdownItem.PARENT_SETTINGS,
      group: DROPDOWN_ROOT_ITEMS_GROUP,
    },
  ];
});

const filteredWidgetSettingsDropdownItems = computed<DropdownItem[]>(() => {
  const currCell = findCell(
    gridStore.currentLayout,
    selectedWidget.value?.cell_id || null
  );

  if (!currCell) {
    return [];
  }

  const parentCell = currCell.parentId
    ? findCell(gridStore.currentLayout, currCell.parentId)
    : null;

  const isChildLevel = currCell.settings.level === NestingLevel.CHILD;
  const isOneColumnParent = parentCell && parentCell.children.length === 1;

  if (isChildLevel) {
    // Cell has childrens but it's 1-column - remove settings for cell
    if (isOneColumnParent) {
      return defaultDropdownItems.value.filter(
        item => item.value !== WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS
      );
    }
    // Cell has childrens - return original dropdown
    return defaultDropdownItems.value;
  }
  // ParentCell of current cell is a block with one-column cell - remove settings for child and parent
  if (isOneColumnParent) {
    return defaultDropdownItems.value.filter(
      item =>
        item.value !== WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS &&
        item.value !== WidgetDefaultSettingsDropdownItem.PARENT_SETTINGS
    );
  }

  return defaultDropdownItems.value.filter(
    item => item.value !== WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS
  );
});

const globalDropdownItems = computed<DropdownItem[]>(() => {
  return [
    ...filteredWidgetSettingsDropdownItems.value,
    ...(props.dropdownItems || []),
  ];
});

const selectedOption = computed<DropdownItem | undefined>(() =>
  globalDropdownItems.value.find(
    option => option.value === selectedDropdownItem.value
  )
);

const currentGroupDropdownItems = computed<DropdownItem[]>(() => {
  return globalDropdownItems.value.filter(
    item => item.group === selectedOption.value?.group
  );
});

const currentTabs = computed<Tab[]>(() => {
  if (!selectedDropdownItem.value) {
    return [];
  }

  if (!props.tabs || !props.tabs[selectedDropdownItem.value]) {
    return [];
  }

  const tabs = props.tabs[selectedDropdownItem.value] as Tab[];

  return tabs.reduce((acc, tab) => {
    const generatedTab: Tab = {
      ...tab,
      parent: selectedDropdownItem.value as string,
    };

    acc.push(generatedTab);

    return acc;
  }, [] as Tab[]);
});

const handleCloseSettingsTab = (): void => {
  emit("close", selectedDropdownItem.value as string);
  closeSettingsTab(selectedDropdownItem.value, globalDropdownItems.value);
};

const handleUpdateDropdownItem = (item: DropdownItem): void => {
  widgetSettingsStore.setDropdownItem({ name: item.value as string | null });
  emit("select", item);
};

const setDefaultTab = (): void => {
  if (!currentTabs.value || !currentTabs.value.length) {
    return;
  }

  activeTab.value = defaultTab;
};

watch(
  () => selectedDropdownItem.value,
  () => {
    emit("change", selectedDropdownItem.value);

    setDefaultTab();
  }
);

watch(
  () => activeSideMenuTab.value,
  newValue => {
    if (newValue !== SideMenuTab.PAGES) {
      return;
    }

    emit("blur-tab", activeTab.value);
    setDefaultTab();
  }
);

const handleChangeTab = (tab: string): void => {
  emit("blur-tab", activeTab.value);
  activeTab.value = tab;
};

onBeforeUnmount(() => {
  emit("change", null);
});
</script>

<style lang="scss">
.widget-settings-content {
  &__header {
    @include flex(center, space-between, 0);
  }

  &__icon {
    @include flex(center, center, 0);
    cursor: pointer;
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: none;

    .common-icon {
      font-size: 14px;
    }

    svg {
      font-size: 14px;
      vertical-align: middle;
    }
  }

  &__tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-nav-more {
      display: none;
    }

    .ant-tabs-tab {
      padding: 12px 0;
      margin: 0 !important;
      justify-content: center;
      flex-grow: 1;
    }

    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-nav-wrap {
      justify-content: flex-start !important;
    }
  }
}
</style>
