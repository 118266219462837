<template>
  <div
    class="basic-button-field"
    :style="containerStyleValue"
  >
    <button
      :style="buttonStyleValue"
      class="basic-button-field__button pointer"
    >
      <span
        v-if="field.options.buttonTextSettings._active"
        :style="buttonTextStyle"
        >{{ localizedValue }}</span
      >
      <span
        v-if="getButtonIcon && field.options.iconSettings._active"
        :style="iconStyleValue"
      >
        <CommonIcon :name="getButtonIcon" />
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import {
  getColorFromHex,
  getDecorationValue,
  getFontSizeStyle,
  getFontFamilyStyle,
  getBoxShadowStyle,
  getBorderStyle,
} from "~~/assets/utils/widget-settings";
import {
  TextPosition,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  field: IWidgetField;
  currentState: State;
  customValue?: string | Record<string, string | undefined | null>;
}>();

const localizedValue = computed(() => {
  return useLocalizedValue().getLocalizedValue.value(
    props.customValue || props.field.value
  );
});

const activeStateOptions = computed<any>(() => {
  return props.currentState === State.DEFAULT
    ? props.field.options
    : props.field.options.states[props.currentState];
});

const buttonTextStyle = computed<{ [key: string]: string }>(() => {
  const buttonTextSettings = props.field.options.buttonTextSettings;

  return {
    color: getColorFromHex(
      activeStateOptions.value.buttonTextSettings.textColor
    ) as string,
    ...getFontSizeStyle(buttonTextSettings.theme),
    ...getFontFamilyStyle(buttonTextSettings.fontFamily),
    ...getDecorationValue(buttonTextSettings.decoration || []),
  };
});

const containerStyleValue = computed<{ [key: string]: string }>(() => {
  return {
    justifyContent: props.field.options.buttonDisplaySettings?.alignment,
  };
});

const buttonStyleValue = computed<any>(() => {
  const style = useWidgetStyle(props.field);

  const iconSettings = props.field.options.iconSettings;

  if (iconSettings) {
    style.value.flexDirection =
      iconSettings.position === TextPosition.LEFT ? "row-reverse" : "row";

    style.value.gap = getPxValueFromNumber(iconSettings.gap);
  }

  style.value.textAlign = props.field.options.buttonDisplaySettings?.alignment;

  if (
    props.field.options.buttonDisplaySettings?.resizing === ResizingType.FILL
  ) {
    style.value.width = "100%";
    style.value.justifyContent =
      props.field.options.buttonDisplaySettings.alignment;
  }

  style.value.boxShadow = getBoxShadowStyle(activeStateOptions.value?.shadow)
    .boxShadow as string;
  style.value.backgroundColor = (
    activeStateOptions.value?.fill.color
      ? getColorFromHex(activeStateOptions.value?.fill)
      : "transparent"
  ) as string;

  return {
    ...style.value,
    ...getBorderStyle(activeStateOptions.value?.border),
  };
});

const iconStyleValue = computed<{ [key: string]: string }>(() => {
  if (!activeStateOptions.value.iconSettings) return {};
  return {
    color: getColorFromHex(
      activeStateOptions.value.iconSettings.textColor
    ) as string,
    ...getFontSizeStyle(props.field.options.iconSettings.iconSize),
  };
});

const getButtonIcon = computed<string | null>(() => {
  if (!props.field.options.iconSettings) return null;

  const { value, default_value } = props.field.options.iconSettings;

  return value || default_value;
});
</script>

<style lang="scss">
.basic-button-field {
  @include flex(center, center, 0);

  &__button {
    @include flex(center, center, 6px);
  }
}
</style>
