import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";

export const useHtmlEditorConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return getInitialContentControlsList({
      source: widget.value,
    });
  });

  return {
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
  };
};
