import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const TITLE_INITIAL = (fieldDetails?: { options?: IWidgetOptions }) => {
  if (fieldDetails?.options && Object.keys(fieldDetails.options).length) {
    return {
      options: { ...fieldDetails.options },
    };
  }

  return {
    options: {
      _active: true,
    },
  };
};
