import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useSearchEventButtonLinkConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Event button link
  */

  const eventButtonLinkDesignControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.event_button.field,
        options: {
          isHorizontalMargin: true,
        },
      },
    ];
  });

  const eventButtonLinkContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.event_button.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const eventButtonLinkValuePath = computed<string>(() => {
    if (states.value.event_button === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.event_button}`;
  });

  const eventButtonLinkStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value.event_button,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "event_button", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${eventButtonLinkValuePath.value}.fill`,
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${eventButtonLinkValuePath.value}.border`,
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${eventButtonLinkValuePath.value}.shadow`,
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${eventButtonLinkValuePath.value}.color`,
            valueSource: fields.value.event_button.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });
  return {
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  };
};
