<template>
  <BaseGridSidebar class="grid-saved-sidebar">
    <div>
      <p>
        You can continue editing template or add a page to it and start filling
        it with widgets.
      </p>
      <p class="text-bold">Pay attention</p>
      <p>Once you add a page to a template, its grid cannot be changed.</p>
      <p>
        If you haven't created a grid for the rest of the resolutions, they will
        be used from the mobile.
      </p>
    </div>

    <template #buttons>
      <a-button
        class="grid-saved-sidebar__action-button"
        type="primary"
        ghost
        @click="gridStore.changeIsLayoutSavedStatus(false)"
        >Edit</a-button
      >
      <AddPageTrigger />
    </template>
  </BaseGridSidebar>
</template>

<script lang="ts" setup>
import { useGridConfig } from "~~/store/grid";

const gridStore = useGridConfig();
</script>

<style lang="scss">
.grid-saved-sidebar {
  &__new-page-name-input {
    border-radius: 6px;
  }

  p {
    margin-bottom: $space-m;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
