<template>
  <a-drawer
    :width="500"
    :visible="drawerValue"
    class="widget-tag-drawer"
    title="Global widget settings"
    placement="right"
    :footer-style="{ textAlign: 'right' }"
    destroy-on-close
    @update:visible="hideDrawer"
  >
    <WidgetTagSettings />
  </a-drawer>
</template>

<script lang="ts" setup>
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const widgetSettingsStore = useWidgetSettingsStore();

const drawerValue = computed<boolean>(() => {
  return widgetSettingsStore.showTagDrawer;
});

const hideDrawer = (): void => {
  widgetSettingsStore.updateTagDrawerValue(false);
};
</script>

<style lang="scss">
.widget-tag-drawer {
  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    color: $c-grey-85;
    font-size: 14px;
    margin: 0;
  }

  .ant-drawer-title {
    font-size: 20px;
    font-weight: 600;
  }
  .ant-drawer-body {
    padding-bottom: 0;
  }

  &__input {
    display: flex;
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
    }
  }

  &__btn-container {
    @include flex(flex-start, flex-end);
  }

  &__current {
    background-color: $c-green;
    display: inline-block;
    border-radius: 15px;
    padding: 2px 10px;
    color: $c-white;
    font-size: 12px;
    white-space: nowrap;
  }

  &__table {
    td,
    th {
      font-size: 12px;
    }
  }

  &__name-details {
    @include flex(center, flex-start, 4px);
  }

  &__row--disabled {
    td {
      background-color: #ffcdcd !important;
    }
  }
}
</style>
