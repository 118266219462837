<template>
  <div
    :style="containerStyles"
    class="sportsbook-league-items"
  >
    <WidgetField
      v-for="(item, index) in items"
      :key="index"
      :widget="widget"
      :field="betItemField"
    >
      <SportsbookBetItem
        :item-value="item"
        :state-options="currentStateOptions"
        :bet-item-field="betItemField"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { State } from "~~/models/widgets/widget-controls.model";

const items = [
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
  {
    name: "Bet name",
    coef: "0.00",
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  betItemField: IWidgetField;
  betItemState: State;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    "grid-template-columns": `repeat(${props.field.options.displaySettings}, minmax(0, 1fr))`,
    "row-gap": getPxValueFromNumber(props.field.options.verticalSpacing),
    "column-gap": getPxValueFromNumber(props.field.options.horizontalSpacing),
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemState === State.DEFAULT) {
    return props.betItemField.options;
  }

  return props.betItemField.options.states[props.betItemState];
});
</script>

<style lang="scss" scoped>
.sportsbook-league-items {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, minmax(0, 1fr)));
  row-gap: 16px;
  column-gap: 16px;
}
</style>
