<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #description>
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>

        <template #form-design>
          <ControlsList
            :widget="widget"
            :controls="checkboxesContainerDesignControls"
          />
        </template>

        <template #form-content>
          <ControlsList
            :widget="widget"
            :controls="checkboxesContainerContentControls"
          />
        </template>

        <template #buttons_container-design>
          <ControlsList
            :widget="widget"
            :controls="buttonsContainerDesignControls"
          />
        </template>

        <template #buttons_container-content>
          <ControlsList
            :widget="widget"
            :controls="buttonsContainerContentControls"
          />
        </template>

        <!-- 
          Allow all
         -->
        <template #button_allow_all-design>
          <ControlsList
            :widget="widget"
            :controls="allowAllDesignControls"
          />
        </template>

        <template #button_allow_all-content>
          <ControlsList
            :widget="widget"
            :controls="allowAllContentControls"
          />
        </template>

        <template #button_allow_all-states>
          <ControlsList
            :widget="widget"
            :controls="allowAllStateControls"
          />
        </template>

        <!-- 
          Allow selection
         -->
        <template #button_allow_selection-design>
          <ControlsList
            :widget="widget"
            :controls="allowSelectionDesignControls"
          />
        </template>

        <template #button_allow_selection-content>
          <ControlsList
            :widget="widget"
            :controls="allowSelectionContentControls"
          />
        </template>

        <template #button_allow_selection-states>
          <ControlsList
            :widget="widget"
            :controls="allowSelectionStatesControls"
          />
        </template>

        <!-- 
          Deny
         -->
        <template #button_deny-design>
          <ControlsList
            :widget="widget"
            :controls="denyDesignControls"
          />
        </template>

        <template #button_deny-content>
          <ControlsList
            :widget="widget"
            :controls="denyContentControls"
          />
        </template>

        <template #button_deny-states>
          <ControlsList
            :widget="widget"
            :controls="denyStatesControls"
          />
        </template>

        <!-- 
          Checkboxes
         -->

        <template #checkboxes_styling-design>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonDesignControls"
          />
        </template>

        <template #checkboxes_styling-content>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonContentControls"
          />
        </template>

        <template #checkboxes_styling-states>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/gdpr/useGdprConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const store = useWidgetsStore();

const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  titleControls,
  titleLinkStatesControls,

  checkboxesContainerDesignControls,
  checkboxesContainerContentControls,

  buttonsContainerDesignControls,
  buttonsContainerContentControls,

  allowAllStateControls,
  allowAllDesignControls,
  allowAllContentControls,

  allowSelectionDesignControls,
  allowSelectionContentControls,
  allowSelectionStatesControls,

  denyDesignControls,
  denyContentControls,
  denyStatesControls,

  checkboxButtonDesignControls,
  checkboxButtonContentControls,
  checkboxButtonStatesControls,

  // checkboxDesignControls,
  // checkboxStatesControls,
} = useConfig(toRef(props.widget), states, emit);

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
