<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        v-if="showFormSettings"
        :dropdown-items="formDropdownItems"
        :tabs="tabs"
        :custom-active-option-selector="findActiveOption"
        @select="handleSelectDropdownItem"
        @blur-tab="handleBlurTab"
        @change="handleChangeField"
        @update-state="$emit('update-state', $event)"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignSettingsControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="formSettingsContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #form-design>
          <ControlsList
            :widget="widget"
            :controls="formElementDesignControls"
          />
        </template>

        <template #form-content>
          <ControlsList
            :widget="widget"
            :controls="formElementContentControls"
          />
        </template>

        <template #form-styles>
          <ControlsList
            :widget="widget"
            :controls="formElementStylesControls"
          />
        </template>

        <!-- 
          Success screen
         -->
        <template #customScreenTitleLink>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_title
        >
          <ControlsList
            :widget="widget"
            :controls="screenTitleControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_image
        >
          <ControlsList
            :widget="widget"
            :controls="screenImageControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_button-design
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonDesignControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_button-content
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonContentControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_button-states
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonStatesControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.SUCCESS"
          #success_description_1
        >
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>

        <!-- Expired -->

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_title
        >
          <ControlsList
            :widget="widget"
            :controls="screenTitleControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_image
        >
          <ControlsList
            :widget="widget"
            :controls="screenImageControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_button-design
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonDesignControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_button-content
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonContentControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_button-states
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonStatesControls"
          />
        </template>

        <template
          v-if="activeView === FormScreen.EXPIRED"
          #expired_description_1
        >
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>

        <!-- Fail -->

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_title
        >
          <ControlsList
            :widget="widget"
            :controls="screenTitleControls"
          />
        </template>

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_image
        >
          <ControlsList
            :widget="widget"
            :controls="screenImageControls"
          />
        </template>

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_button-design
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonDesignControls"
          />
        </template>

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_button-content
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonContentControls"
          />
        </template>

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_button-states
        >
          <ControlsList
            :widget="widget"
            :controls="screenButtonStatesControls"
          />
        </template>

        <template
          v-if="activeView === DepositListScreen.FAIL"
          #fail_description_1
        >
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>

        <!-- ##### -->

        <template #title_styling>
          <ControlsList
            :widget="widget"
            :controls="formTitleStyles"
          />
        </template>

        <template #labels_styling>
          <ControlsList
            :widget="widget"
            :controls="formLabelsStyles"
          />
        </template>

        <template #tooltips_styling-design>
          <ControlsList
            :widget="widget"
            :controls="formTooltipDesignStyles"
          />
        </template>

        <template #tooltips_styling-content>
          <ControlsList
            :widget="widget"
            :controls="formTooltipContentStyles"
          />
        </template>

        <template #fields_styling-design>
          <ControlsList
            :widget="widget"
            :controls="formFieldDesignStyles"
          />
        </template>

        <template #fields_styling-content>
          <ControlsList
            :widget="widget"
            :controls="formFieldContentStyles"
          />
        </template>

        <template #fields_styling-states>
          <ControlsList
            :widget="widget"
            :controls="formFieldStates"
          />
        </template>

        <template #errors_styling>
          <ControlsList
            :widget="widget"
            :controls="formErrorStyles"
          />
        </template>

        <template #checkboxes_styling-design>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonDesignControls"
          />
        </template>

        <template #checkboxes_styling-content>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonContentControls"
          />
        </template>

        <template #checkboxes_styling-states>
          <ControlsList
            :widget="widget"
            :controls="checkboxButtonStatesControls"
          />
        </template>

        <template #radio_group_styling-design>
          <ControlsList
            :widget="widget"
            :controls="radioButtonDesignControls"
          />
        </template>

        <template #radio_group_styling-content>
          <ControlsList
            :widget="widget"
            :controls="radioButtonContentControls"
          />
        </template>

        <template #radio_group_styling-states>
          <ControlsList
            :widget="widget"
            :controls="radioButtonStatesControls"
          />
        </template>

        <template #calendar_styling-design>
          <ControlsList
            :widget="widget"
            :controls="formCalendarDesignStyles"
          />
        </template>

        <template #calendar_styling-content>
          <ControlsList
            :widget="widget"
            :controls="formCalendarContentStyles"
          />
        </template>

        <template #calendar_styling-states>
          <ControlsList
            :widget="widget"
            :controls="formCalendarStates"
          />
        </template>

        <template #datepicker_styling>
          <ControlsList
            :widget="widget"
            :controls="formDatePickerStyles"
          />
        </template>

        <template #dropdowns_styling>
          <ControlsList
            :widget="widget"
            :controls="formDropdownStyles"
          />
        </template>

        <template #dropdown_menu_styling>
          <ControlsList
            :widget="widget"
            :controls="formDropdownMenuStyles"
          />
        </template>

        <template #dropdown_menu_items_styling-design>
          <ControlsList
            :widget="widget"
            :controls="formDropdownMenuItemsDesignStyles"
          />
        </template>

        <template #dropdown_menu_items_styling-content>
          <ControlsList
            :widget="widget"
            :controls="formDropdownMenuItemsContentStyles"
          />
        </template>

        <template #dropdown_menu_items_styling-states>
          <ControlsList
            :widget="widget"
            :controls="formDropdownMenuItemsStates"
          />
        </template>

        <!-- 
          Config content
         -->
        <template #TextInputField>
          <ControlsList
            :widget="widget"
            :controls="textFieldControls"
          />
        </template>

        <template #FormTextField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormTextInputField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormNumberField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormPasswordField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormEmailField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormDropdownField>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #FormCheckboxField>
          <ControlsList
            :widget="widget"
            :controls="checkboxFieldControls"
          />
        </template>

        <template #FormRadioField>
          <ControlsList
            :widget="widget"
            :controls="radioGroupFieldControls"
          />
        </template>

        <template #FormDateField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #FormDocumentUploadField>
          <ControlsList
            :widget="widget"
            :controls="uploadFieldControls"
          />
        </template>

        <template #FormTelField>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #ButtonLinkField-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #ButtonLinkField-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #ButtonLinkField-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #TextEditorField>
          <FormDescriptionSettings
            :is-bold="false"
            @update-field-option="updateFieldOption"
          />
        </template>

        <!-- 
         Upload 
         -->
        <template #upload_caption_styling>
          <ControlsList
            :widget="widget"
            :controls="formUploadCaptionStyles"
          />
        </template>

        <template #upload_list_styling>
          <ControlsList
            :widget="widget"
            :controls="formUploadListStyles"
          />
        </template>

        <template #upload_items_styling-design>
          <ControlsList
            :widget="widget"
            :controls="formUploadItemDesignStyles"
          />
        </template>

        <template #upload_items_styling-content>
          <ControlsList
            :widget="widget"
            :controls="formUploadItemContentStyles"
          />
        </template>

        <template #upload_items_styling-states>
          <ControlsList
            :widget="widget"
            :controls="formUploadItemStatesStyles"
          />
        </template>

        <template #upload_button_styling-design>
          <ControlsList
            :widget="widget"
            :controls="uploadButtonDesignControls"
          />
        </template>

        <template #upload_button_styling-content>
          <ControlsList
            :widget="widget"
            :controls="uploadButtonContentControls"
          />
        </template>

        <template #upload_button_styling-states>
          <ControlsList
            :widget="widget"
            :controls="uploadButtonStatesControls"
          />
        </template>

        <!-- 
          Success message
         -->

        <template #success_message-design>
          <ControlsList
            :widget="widget"
            :controls="successMessageDesignControls"
          />
        </template>

        <template #success_message-content>
          <ControlsList
            :widget="widget"
            :controls="successMessageContentControls"
          />
        </template>

        <template #field_success_styling>
          <ControlsList
            :widget="widget"
            :controls="successFieldControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { DepositListScreen, FormScreen } from "~~/models/widgets/form.model";
import { useFormWidgetConfig } from "~~/composables/widgets/form/useFormWidgetConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";

const props = defineProps<{
  widget: IWidgetWithFields;
  activeView: string;
  states: Record<string, string>;
  screens: string[];
  showFormSettings: boolean;
  customFilteredFields?: IWidgetField[];
  hideFormContentControls?: boolean;
}>();

const emit = defineEmits<{
  (e: "description-field-option-updated"): void;
  (e: "update-title-link-state", event: State): void;
  (e: "update-active-view", event: string): void;
  (e: "update-button-state", event: string): void;
  (e: "select", event: string | null): void;
  (e: "reset-states"): void;
  (e: "update-state", event: { state: string; value: State }): void;
}>();

const activeView = toRef(() => props.activeView);
const states = toRef(() => props.states);
const widget = toRef(() => props.widget);
const screens = toRef(() => props.screens);

const store = useWidgetsStore();
const widgetsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField, selectedDropdownItem } =
  storeToRefs(widgetsStore);

const {
  formDropdownItems,
  TABS,
  widgetDesignSettingsControls,
  formSettingsContentControls,

  formElementDesignControls,
  formElementContentControls,
  formElementStylesControls,

  /*
    Success
  */

  screenTitleControls,
  titleLinkStatesControls,
  screenImageControls,

  screenButtonStatesControls,
  screenButtonDesignControls,
  screenButtonContentControls,

  /*
    Form elements style
  */
  formTitleStyles,
  formLabelsStyles,

  formTooltipDesignStyles,
  formTooltipContentStyles,

  formFieldDesignStyles,
  formFieldContentStyles,
  formFieldStates,

  formErrorStyles,

  checkboxButtonDesignControls,
  checkboxButtonContentControls,
  checkboxButtonStatesControls,

  radioButtonDesignControls,
  radioButtonContentControls,
  radioButtonStatesControls,

  formCalendarDesignStyles,
  formCalendarContentStyles,
  formCalendarStates,

  formDatePickerStyles,

  formDropdownStyles,

  formDropdownMenuStyles,

  formDropdownMenuItemsDesignStyles,
  formDropdownMenuItemsContentStyles,
  formDropdownMenuItemsStates,

  /*
    Form content
  */
  textFieldControls,
  textInputFieldControls,
  checkboxFieldControls,
  radioGroupFieldControls,
  dropdownFieldControls,
  uploadFieldControls,

  buttonStatesControls,
  buttonDesignControls,
  buttonContentControls,

  /* 
    Upload
  */

  formUploadCaptionStyles,

  formUploadListStyles,

  formUploadItemDesignStyles,
  formUploadItemContentStyles,
  formUploadItemStatesStyles,

  uploadButtonDesignControls,
  uploadButtonContentControls,
  uploadButtonStatesControls,

  successMessageDesignControls,
  successMessageContentControls,

  bindingParams,

  successFieldControls,
} = useFormWidgetConfig(
  widget,
  activeView,
  states,
  emit,
  screens,
  props.customFilteredFields
);

const tabs = computed(() => {
  if (props.hideFormContentControls) {
    return {
      ...TABS,
      form: TABS.form.filter(tab => tab.value !== "content"),
    };
  }

  return TABS;
});

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const handleSelectDropdownItem = (item: DropdownItem) => {
  emit("select", item.value as string | null);

  const selectedItem = formDropdownItems.value.find(
    curr => curr.custom?.fieldId === item?.custom?.fieldId
  );

  if (!selectedItem || !selectedItem.custom?.fieldId) {
    return;
  }

  const field = props.widget.fields.find(
    f => f.id === selectedItem.custom?.fieldId
  );

  if (!field) {
    return;
  }

  widgetsStore.setActiveElement(props.widget, field, undefined, field.type);
};

const handleBlurTab = (tab: string): void => {
  if (tab === "states") {
    emit("reset-states");
  }
};

const handleChangeField = (): void => {
  emit("reset-states");
};

const findActiveOption = (options: DropdownItem[]): DropdownItem => {
  /* 
    If field is not selected - default behaviour
  */
  if (!selectedField.value) {
    return options.find(
      option => option.value === selectedDropdownItem.value
    ) as DropdownItem;
  }

  /* 
    Else check if any option has customId same as selectedField
  */
  const optionByCustomId = options.find(
    option => option.custom?.fieldId === selectedField.value?.id
  );

  if (optionByCustomId) {
    return optionByCustomId;
  }

  /* 
    If no - again, default behaviour
  */
  return options.find(
    option => option.value === selectedDropdownItem.value
  ) as DropdownItem;
};
</script>
