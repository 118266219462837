import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useCasinoListItems } from "~~/composables/widgets/casino/list/useCasinoListItems";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useCasinoListGamesControls } from "./useCasinoListGamesControls";
import { useCasinoListHoverContentControls } from "./useCasinoListHoverContentControls";
import { useCasinoListCardsElementsControls } from "./useCasinoListCardsElements";

export const useCasinoListConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useCasinoListItems(widget);

  const {
    casinoListGamesDesignControls,
    casinoListGamesContentControls,
    casinoListGamesStatesControls,

    casinoListGamesPhotoControls,
    casinoListGamesCardsElementsControls,
  } = useCasinoListGamesControls(widget, states, emit);

  const {
    casinoListGamesHoverContentControls,

    casinoListGameTitleControls,
    casinoListGameTitleLinkControls,

    casinoListGamePlayMoneyButtonDesignControls,
    casinoListGamePlayMoneyButtonContentControls,
    casinoListGamePlayMoneyButtonStateControls,

    casinoListGamePlayFreeButtonDesignControls,
    casinoListGamePlayFreeButtonContentControls,
    casinoListGamePlayFreeButtonStateControls,
  } = useCasinoListHoverContentControls(widget, states, emit);

  const {
    casinoListGamesFavIconControls,

    casinoListGameTagButtonDesignControls,
    casinoListGameTagButtonContentControls,
    casinoListGameTagButtonStateControls,
  } = useCasinoListCardsElementsControls(widget, states, emit);
  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    casinoListGamesDesignControls,
    casinoListGamesContentControls,
    casinoListGamesStatesControls,

    casinoListGamesHoverContentControls,

    casinoListGameTitleControls,
    casinoListGameTitleLinkControls,

    casinoListGamePlayMoneyButtonDesignControls,
    casinoListGamePlayMoneyButtonContentControls,
    casinoListGamePlayMoneyButtonStateControls,

    casinoListGamePlayFreeButtonDesignControls,
    casinoListGamePlayFreeButtonContentControls,
    casinoListGamePlayFreeButtonStateControls,

    casinoListGamesPhotoControls,
    casinoListGamesCardsElementsControls,

    casinoListGamesFavIconControls,

    casinoListGameTagButtonDesignControls,
    casinoListGameTagButtonContentControls,
    casinoListGameTagButtonStateControls,
  };
};
