import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";

export const useRacingSportsbookLiveEventCardConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Event cards 
  */

  const eventCardsDesignControls = computed(() => {
    return [
      ...getInitialDesignList({ source: fields.value.event_cards.field }),
    ];
  });

  const isEventsCardsContentMode = computed<boolean>(() => {
    return states.value.event_cards === State.ACTIVE_EVENTS;
  });

  const eventCardsNoContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        visible: !isEventsCardsContentMode.value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "options.noEventsText",
            valueSource: fields.value.event_cards.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      typography({
        source: fields.value.event_cards.field,
        visible: !isEventsCardsContentMode.value,
      }),
    ];
  });

  const eventCardsContentControls = computed(() => {
    const baseControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.event_cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS[State.ACTIVE_EVENTS],
              value: State.ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS[State.NO_ACTIVE],
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "event_cards",
            value,
          });
        },
      },
    ];

    if (states.value.event_cards === State.NO_ACTIVE) {
      return [...baseControls, ...eventCardsNoContentControls.value];
    }

    return [
      ...baseControls,
      {
        section: generateDesignSectionName("Display mode"),
        visible: isEventsCardsContentMode.value,
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.columns",
            valueSource: fields.value.event_cards.field,
            className: "event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distance",
            valueSource: fields.value.event_cards.field,
            options: {
              label: "Distance between cards",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        visible: isEventsCardsContentMode.value,
        options: {
          items: [
            {
              label: "Stream icon",
              value: "stream_icon",
            },
            {
              label: "Status",
              value: "event_suspended_status",
            },
            {
              label: "Time",
              value: "event_time",
            },
            {
              label: "Event info",
              value: "event_info",
            },
          ],
        },
      },
    ];
  });

  return {
    eventCardsContentControls,
    eventCardsDesignControls,
  };
};
