<template>
  <div
    v-if="hasContentPermissions"
    class="config-text-content widget-control-container"
  >
    <span
      v-if="label"
      class="config-tabs-input__item p-b-16"
    >
      <p
        class="config-tabs-input__el m-b-0"
        :class="{ 'sub-heading': isBold }"
      >
        {{ label }}
      </p>
    </span>
    <div class="p-b-16">
      <a-textarea
        show-count
        :value="getLocalizedValue(modelValue)"
        :maxlength="max || MAX_TEXT"
        class="config-text-content__input f-base"
        :placeholder="placeholder"
        @update:value="handleInput(modelValue, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MAX_TEXT } from "~~/constants/input-validation";
import { useTextInputConfig } from "~~/components/composables/widgets/useTextInputConfig";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue?: Record<string, any> | undefined | null;
  placeholder: string | undefined;
  max?: number;
  label?: string;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: Record<string, string>): void;
}>();

const { getLocalizedValue, transformToLocalizedValue } = useLocalizedValue();

const { handleInput } = useTextInputConfig(props, emit);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

onBeforeMount(() => {
  const { localized, localizedValue } = transformToLocalizedValue(
    props.modelValue
  );

  if (localized) {
    emit("update:modelValue", localizedValue);
  }
});
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.config-text-content {
  @include widgets-content-input-container;
}
</style>
