import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  ITEMS_GROUP_INITIAL,
  ITEMS_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { ArrowType, iconMapping } from "~~/constants/icons-mapping";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useTimezonesInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    dropdown_container: State.DEFAULT,
    search_field: State.DEFAULT,
    menu_items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(["cornerRadius"], {
    display: {
      resizing: "fill",
      alignment: "left",
    },
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialDropdownContainerState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000"),
        }),
        shadow: getDefaultShadow(),
        text: {
          color: getDefaultFillValue("#1677FF"),
        },
        icon: {
          textColor: getDefaultFillValue("#1677FF"),
        },
        arrow: {
          textColor: getDefaultFillValue("#1677FF"),
        },
      };
    };

    const initialDropdownContainerOptions = prefillEmptyOptions(
      fields.value.dropdown_container.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["icon"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          distanceBetweenItems: 8,
          text: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          icon: {
            value: "ant-design:arrow-right-outlined",
            textColor: {
              color: "#000000",
              opacity: 100,
            },
            iconSize: 14,
            gap: 8,
            _active: true,
          },
          arrow: {
            _active: true,
            value: ArrowType.Down,
            default_value: iconMapping[ArrowType.Down],
            arrowSize: 14,
            gap: 8,
            textColor: getDefaultFillValue("#1F1F1F"),
          },
          states: {
            [State.HOVER]: initialDropdownContainerState(),
            [State.ACTIVE]: initialDropdownContainerState(),
          },
        },
      }).options
    );

    const initialDropdownMenuOptions = prefillEmptyOptions(
      fields.value.dropdown_menu.field.options,
      getInitialOptions({
        data: ITEMS_INITIAL(),
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 6),
          }),
          shadow: {
            fill: getDefaultFillValue("#000000", 10),
            x: 0,
            y: 10,
            blur: 20,
            spread: 4,
          },
          margins: getDefaultSpacing({
            top: 4,
          }),
        },
      }).options
    );

    const initialSearchFieldState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        shadow: getDefaultShadow(),
        text: {
          color: getDefaultFillValue("#494949"),
        },
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
        placeholder: {
          color: getDefaultFillValue("#C7C7C7"),
        },
      };
    };

    const initialSearchFieldOptions = prefillEmptyOptions(
      fields.value.search_field.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["arrow"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          text: {
            _active: true,
            color: getDefaultFillValue("#494949"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
            value: "Request example",
          },
          icon: {
            value: "ant-design:search-outlined",
            textColor: {
              color: "#000000",
              opacity: 100,
            },
            _active: true,
          },
          states: {
            [State.HOVER]: initialSearchFieldState(),
            [State.ACTIVE]: initialSearchFieldState(),
            [State.DISABLED]: initialSearchFieldState(),
          },
        },
        additionalData: {
          options: {
            placeholder: {
              _active: true,
              value: "Search",
              color: getDefaultFillValue("#C7C7C7"),
            },
          },
        },
      }).options
    );

    const initialMenuItemsState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000"),
        }),
        shadow: getDefaultShadow(),
        itemsText: {
          color: getDefaultFillValue("#1677FF"),
        },
        arrow: {
          textColor: getDefaultFillValue("#1677FF"),
        },
      };
    };

    // Set default value
    fields.value.menu_items.field.value =
      useLocalizedValue().transformToLocalizedValue("Item").localizedValue;

    const initialMenuItemsOptions = prefillEmptyOptions(
      fields.value.menu_items.field.options,
      getInitialOptions({
        data: ITEMS_GROUP_INITIAL(),
        exclude: ["itemsIcon", "margins"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(6),
          itemsText: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          spaceBetween: 4,
          states: {
            [State.HOVER]: initialMenuItemsState(),
            [State.ACTIVE]: initialMenuItemsState(),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.dropdown_container.field,
      initialDropdownContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.dropdown_menu.field,
      initialDropdownMenuOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_field.field,
      initialSearchFieldOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.menu_items.field,
      initialMenuItemsOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,
    initFields,
    initWidgetFieldsOptions,
  };
};
