import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  DisplayOrientation,
  FillType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateImageCssString,
  generateStageButtonCssString,
  generateCardCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateFlexAlignment } from "../helpers";

export const generateButtonsGroupCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { layout, distanceBetweenItems, alignment } = element.options;
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: `${generateFlex({
        flex: "flex",
        align:
          layout === DisplayOrientation.HORIZONTAL
            ? "start"
            : generateFlexAlignment(alignment),
        justify: alignment,
        direction: layout === DisplayOrientation.VERTICAL ? "column" : "row",
        gap: distanceBetweenItems,
      })}${generateDefaultStyles(element.options)}`,
    });

    return cssString;
  };

export const generateButtonCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateStageButtonCssString(element)("");
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: `align-self: unset !important;
      white-space: nowrap;`,
    });

    return cssString;
  };

export const generateCardWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const description = widget.content["description_1"];
  const image = widget.content["image"];
  const buttonsGroup = widget.content["buttons_group"];
  const button1 = widget.content["button_1"];
  const button2 = widget.content["button_2"];

  widget.options._cssClass = generateClassName("CardWidget");
  title.options._cssClass = generateClassName("title");
  description.options._cssClass = generateClassName("description");
  image.options._cssClass = generateClassName("image");
  buttonsGroup.options._cssClass = generateClassName("buttonsGroup");
  button1.options._cssClass = generateClassName("button");
  button2.options._cssClass = generateClassName("button");
  button1.options.iconSettings._cssClass = generateClassName("buttonIcon");
  button2.options.iconSettings._cssClass = generateClassName("buttonIcon");

  let backgroundOptions: IWidgetOptions = {};

  if (widget.options.fillImageColor?.fillType === FillType.COLOR) {
    backgroundOptions = widget.options.fillImageColor;
  } else {
    backgroundOptions = {
      ...widget.options.fillImageColor,
      value: null,
    };
  }

  return pipeSync<string>(
    generateCardCssString({
      ...widget,
      options: {
        ...widget.options,
        fillImageColor: backgroundOptions,
      },
    }),
    generateImageCssString(image as IWidgetField),
    generateStringWithStates(title, true),
    generateStringDefault(description),
    generateButtonsGroupCssString(buttonsGroup as IWidgetField),
    generateButtonCssString(button1 as IWidgetField),
    generateButtonCssString(button2 as IWidgetField)
  )("");
};
