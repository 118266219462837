import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const UPLOAD_INITIAL = (
  fieldDetails?: {
    label: string;
    options?: IWidgetOptions;
  },
  formName = "form"
) => {
  if (fieldDetails?.options && Object.keys(fieldDetails.options).length) {
    return {
      options: fieldDetails.options,
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      label: {
        _active: true,
        value: fieldDetails?.label || "Label",
      },
      caption: {
        _active: true,
        value: "Caption text (rules)",
      },
      tooltip: {
        _active: true,
        value: "Tooltip text",
      },
      uploadButton: {
        _active: true,
        value: "Upload button",
      },
    },
  };
};
