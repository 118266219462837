<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        class="casino-game-settings"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #iframe>
          <ControlsList
            :widget="widget"
            :controls="iframeControls"
          />
        </template>

        <template #play_toggler-design>
          <ControlsList
            :widget="widget"
            :controls="playTogglerDesignControls"
          />
        </template>

        <template #play_toggler-content>
          <ControlsList
            :widget="widget"
            :controls="playTogglerContentControls"
          />
        </template>

        <template #play_toggler_title>
          <ControlsList
            :widget="widget"
            :controls="stateTitleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #play_toggler_button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #play_toggler_button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #play_toggler_button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/casino-game/useCasinoGameConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
  (e: "update-title-link-state"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "gameId": {
      label: "Game ID",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "isGameForMoney": {
      label: "Is game for money?", // value will be "Yes" or "No"
      description: "Possible value: Yes | No",
      sources: ["custom"],
      required: true,
    },
    "oneGameForMoneyPageUrl": {
      label: "One game for money page URL",
      sources: ["custom"],
      required: false,
    },
    "oneGameForFunPageUrl": {
      label: "One game for fun page URL",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  iframeControls,

  playTogglerDesignControls,
  playTogglerContentControls,

  stateTitleControls,
  titleLinkStatesControls,

  buttonStatesControls,
  buttonDesignControls,
  buttonContentControls,
} = useConfig(toRef(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>

<style lang="scss">
.casino-game-settings {
  .casino-game-config-layout-icons {
    svg {
      width: 88px;
      height: 48px;
    }
  }
}
</style>
