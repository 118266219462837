<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <!-- sports_container -->
        <template #sports_container-design>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerDesignControls"
          />
        </template>

        <template #sports_container-content>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerContentControls"
          />
        </template>

        <template #sports_container-states>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerStateControls"
          />
        </template>

        <!-- title (sports title) -->
        <template #title-design>
          <ControlsList
            :widget="widget"
            :controls="titleDesignControls"
          />
        </template>

        <template #title-content>
          <ControlsList
            :widget="widget"
            :controls="titleContentControls"
          />
        </template>

        <template #title-states>
          <ControlsList
            :widget="widget"
            :controls="titleStateControls"
          />
        </template>

        <!-- title_button -->
        <template #title_button-design>
          <ControlsList
            :widget="widget"
            :controls="titleButtonDesignControls"
          />
        </template>

        <template #title_button-content>
          <ControlsList
            :widget="widget"
            :controls="titleButtonContentControls"
          />
        </template>

        <template #title_button-states>
          <ControlsList
            :widget="widget"
            :controls="titleButtonStateControls"
          />
        </template>

        <!-- title_hover_button -->
        <template #title_hover_button-design>
          <ControlsList
            :widget="widget"
            :controls="titleHoverButtonDesignControls"
          />
        </template>

        <template #title_hover_button-content>
          <ControlsList
            :widget="widget"
            :controls="titleHoverButtonContentControls"
          />
        </template>

        <template #title_hover_button-states>
          <ControlsList
            :widget="widget"
            :controls="titleHoverButtonStateControls"
          />
        </template>

        <!-- event_cards -->
        <template #event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardsDesignControls"
          />
        </template>

        <template #event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardsContentControls"
          />
        </template>

        <template #event_cards-states>
          <ControlsList
            :widget="widget"
            :controls="eventCardsStateControls"
          />
        </template>

        <!-- sport_events_container -->
        <template #sport_events_container-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventsContainerDesignControls"
          />
        </template>

        <template #sport_events_container-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventsContainerContentControls"
          />
        </template>

        <!-- leagues_titles -->
        <template #leagues_titles-design>
          <ControlsList
            :widget="widget"
            :controls="leaguesTitlesDesignControls"
          />
        </template>

        <template #leagues_titles-content>
          <ControlsList
            :widget="widget"
            :controls="leaguesTitlesContentControls"
          />
        </template>

        <!-- event_header -->
        <template #event_header-design>
          <ControlsList
            :widget="widget"
            :controls="eventHeaderDesignControls"
          />
        </template>

        <template #event_header-content>
          <ControlsList
            :widget="widget"
            :controls="eventHeaderContentControls"
          />
        </template>

        <!-- event_league -->
        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="eventLeagueControls"
          />
        </template>

        <!-- favourites_button -->
        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favouritesButtonControls"
          />
        </template>

        <!-- event_suspended_status -->
        <template #event_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventStatusDesignControls"
          />
        </template>
        <template #event_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventStatusContentControls"
          />
        </template>

        <!-- stream_icon -->
        <template #stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>

        <!-- event_time -->
        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <!-- event_period -->
        <template #event_period>
          <ControlsList
            :widget="widget"
            :controls="eventPeriodControls"
          />
        </template>

        <!-- event_info -->
        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>

        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <!-- event_name -->
        <template #event_name>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <!-- event_score -->
        <template #event_score-design>
          <ControlsList
            :widget="widget"
            :controls="eventScoreDesignControls"
          />
        </template>
        <template #event_score-content>
          <ControlsList
            :widget="widget"
            :controls="eventScoreContentControls"
          />
        </template>

        <!-- bet_items -->
        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>
        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>
        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <!-- event_button -->
        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonDesignControls"
          />
        </template>
        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonContentControls"
          />
        </template>
        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonStateControls"
          />
        </template>

        <!-- racing_events_container -->
        <template #racing_events_container-design>
          <ControlsList
            :widget="widget"
            :controls="racingContainerDesignControls"
          />
        </template>
        <template #racing_events_container-content>
          <ControlsList
            :widget="widget"
            :controls="racingContainerContentControls"
          />
        </template>

        <!-- racing_event_cards -->
        <template #racing_event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsDesignControls"
          />
        </template>

        <template #racing_event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsContentControls"
          />
        </template>

        <template #racing_event_cards-states>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsStateControls"
          />
        </template>

        <!-- racing_event_stream_icon -->
        <template #racing_event_stream_icon>
          <ControlsList
            :widget="widget"
            :controls="racingStreamIconControls"
          />
        </template>

        <!-- racing_event_name -->
        <template #racing_event_name>
          <ControlsList
            :widget="widget"
            :controls="racingEventNameControls"
          />
        </template>

        <!-- racing_event_status -->
        <template #racing_event_status-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusDesignControls"
          />
        </template>

        <template #racing_event_status-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusContentControls"
          />
        </template>

        <!-- racing_event_favourites_button -->
        <template #racing_event_favourites_button>
          <ControlsList
            :widget="widget"
            :controls="racingFavouritesButtonControls"
          />
        </template>

        <!-- racing_event_button -->
        <template #racing_event_button-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventButtonDesignControls"
          />
        </template>

        <template #racing_event_button-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventButtonContentControls"
          />
        </template>

        <template #racing_event_button-states>
          <ControlsList
            :widget="widget"
            :controls="racingEventButtonStateControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef, ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { useSportsbookLiveFenixConfig } from "~~/composables/widgets/sportbook-live/fenix/useSportsbookLiveFenixConfig";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "sportId": {
      label: "Sport ID",
      sources: ["pageRoute", "custom"],
    },
    "eventPageUrl": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "sportPageUrl": {
      label: "Sports page URL",
      sources: ["custom"],
      required: true,
    },
    "sportsBookPageUrl": {
      label: "Sports book page URL",
      sources: ["custom"],
      required: true,
    },
    "pageType": {
      label: "Page type",
      sources: ["custom"],
      description: "Possible values: List | Single",
      required: true,
    },
    "maxVisibleEvents": {
      label: "Max visible events",
      sources: ["custom"],
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  sportsContainerDesignControls,
  sportsContainerContentControls,
  sportsContainerStateControls,

  titleDesignControls,
  titleContentControls,
  titleStateControls,

  titleButtonDesignControls,
  titleButtonContentControls,
  titleButtonStateControls,

  titleHoverButtonDesignControls,
  titleHoverButtonContentControls,
  titleHoverButtonStateControls,

  eventCardsDesignControls,
  eventCardsContentControls,
  eventCardsStateControls,

  leaguesTitlesDesignControls,
  leaguesTitlesContentControls,

  sportEventsContainerDesignControls,
  sportEventsContainerContentControls,

  eventHeaderDesignControls,
  eventHeaderContentControls,

  eventLeagueControls,

  favouritesButtonControls,

  eventStatusDesignControls,
  eventStatusContentControls,

  streamIconControls,

  eventTimeControls,

  eventPeriodControls,

  eventInfoDesignControls,
  eventInfoContentControls,

  eventNameControls,

  eventScoreDesignControls,
  eventScoreContentControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventButtonDesignControls,
  eventButtonContentControls,
  eventButtonStateControls,
  // racing
  racingContainerDesignControls,
  racingContainerContentControls,

  racingEventCardsDesignControls,
  racingEventCardsContentControls,
  racingEventCardsStateControls,

  racingEventNameControls,

  racingEventStatusDesignControls,
  racingEventStatusContentControls,

  racingFavouritesButtonControls,

  racingEventButtonDesignControls,
  racingEventButtonContentControls,
  racingEventButtonStateControls,

  racingStreamIconControls,
} = useSportsbookLiveFenixConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
