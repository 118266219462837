import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateStageButtonCssString,
  generateCheckboxCssString,
  generateFormCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFlexAlignment,
  generateWidgetWidth,
} from "../helpers";

export const generateWidgetStyles =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const settings = widget.options.displaySettings;

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: generateDefaultStyles(widget.options),
    });

    cssString += generateCssClassWithContent({
      className: widget.options._contentCssClass,
      content: generateFlex({
        flex: "flex",
        align: "stretch",
        justify: "flex-start",
        direction: settings.layout === "top" ? "column" : "column-reverse",
        gap: "0",
      }),
    });

    return cssString;
  };

export const generateWidgetCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content:
        generateDefaultStyles(widget.options) +
        generateFlex({
          flex: "flex",
          align: "stretch",
          justify: "center",
          direction: "column",
          gap: "0",
        }),
    });

    const settings = widget.options.displaySettings;

    cssString += generateCssClassWithContent({
      className: widget.options._contentCssClass,
      content: generateFlex({
        flex: "flex",
        align: settings.alignment,
        justify: "center",
        direction:
          settings.layout === DisplayOrientation.HORIZONTAL ? "row" : "column",
        gap: settings.gap,
      }),
    });

    return cssString;
  };

export const generateButtonsContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const settings = field.options.displaySettings;

    const flex = generateFlex({
      flex: "flex",
      align:
        settings.layout === DisplayOrientation.VERTICAL
          ? generateFlexAlignment(settings.buttonsAlignment)
          : "flex-start",
      justify:
        settings.layout === DisplayOrientation.HORIZONTAL
          ? generateFlexAlignment(settings.buttonsAlignment)
          : "flex-start",
      direction:
        settings.layout === DisplayOrientation.VERTICAL ? "column" : "row",
      gap: settings.gap,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content:
        generateDefaultStyles(field.options) +
        generateWidgetWidth(settings.width),
    });

    cssString += generateCssClassWithContent({
      className: field.options._contentCssClass,
      content: flex,
    });

    return cssString;
  };

export const generateButtonWidthCssString =
  (field: IWidgetOptions, buttonContainer: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateWidgetWidth(
        buttonContainer.options.displaySettings.buttonsWidth
      ),
    });

    return cssString;
  };

export const generateCheckboxesContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const settings = field.options.displaySettings;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      direction:
        settings.layout === DisplayOrientation.VERTICAL ? "column" : "row",
      gap: settings.gap,
    });

    const containerFlex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: generateFlexAlignment(settings.alignment),
      direction: "row",
      gap: "0",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: flex + "width: initial;margin:0;",
    });

    cssString += generateCssClassWithContent({
      className: field.options._formContainerCssClass,
      content: containerFlex,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      childClassName: "formkit-outer-override",
      content: "width: initial;",
    });

    return cssString;
  };

export const generateGdprWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("GdprWidget");
  widget.options._contentCssClass = generateClassName("GdprWidget_content");
  const customFields = widget.options._customFields;

  const title = widget.content["title"];
  const description = widget.content["description"];
  const buttons_container = widget.content["buttons_container"];
  const form = widget.content["form"];

  const button_allow_all = widget.content["button_allow_all"];
  const button_allow_selection = widget.content["button_allow_selection"];
  const button_deny = widget.content["button_deny"];

  title.options._cssClass = generateClassName("title");
  description.options._cssClass = generateClassName("description");

  buttons_container.options._cssClass = generateClassName("buttons_container");
  buttons_container.options._contentCssClass = generateClassName(
    "buttons_container_content"
  );

  button_allow_all.options.iconSettings._cssClass = generateClassName(
    "button_allow_all_icon"
  );
  button_allow_all.options._cssClass = generateClassName("button_allow_all");

  button_allow_selection.options.iconSettings._cssClass = generateClassName(
    "button_allow_selection_icon"
  );
  button_allow_selection.options._cssClass = generateClassName(
    "button_allow_selection"
  );

  button_deny.options.iconSettings._cssClass =
    generateClassName("button_deny_icon");
  button_deny.options._cssClass = generateClassName("button_deny");

  form.options._cssClass = generateClassName("form");
  form.options._formContainerCssClass = generateClassName("form_container");

  // Icons class names
  if (customFields.checkboxes_styling) {
    customFields.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    customFields.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");

    customFields.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (customFields.checkboxes_styling.options.check.icon) {
      customFields.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  const checkboxes =
    customFields.checkboxes_styling &&
    customFields.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          customFields.checkboxes_styling,
          customFields.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(customFields.checkboxes_styling);

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateStringWithStates(title, true),
    generateStringDefault(description),
    generateButtonsContainerCssString(buttons_container),
    generateStageButtonCssString(button_allow_all as IWidgetField),
    generateStageButtonCssString(button_allow_selection as IWidgetField),
    generateStageButtonCssString(button_deny as IWidgetField),
    generateButtonWidthCssString(button_allow_all, buttons_container),
    generateButtonWidthCssString(button_allow_all, buttons_container),
    generateButtonWidthCssString(button_allow_selection, buttons_container),
    generateButtonWidthCssString(button_deny, buttons_container),
    generateFormCssString(form, widget),
    checkboxes,
    generateCheckboxesContainerCssString(form)
  )("");
};
