<template>
  <div>
    <div
      v-if="hasContentPermissions"
      class="wconfig-breadcrumbs-links p-t-8 p-r-16 p-l-16"
    >
      <DraggableList
        v-model="sortedLinks"
        tag="div"
        item-key="id"
        class="wconfig-breadcrumbs-links__container"
      >
        <template #item="{ element: element, index }">
          <div
            class="wconfig-breadcrumbs-links__container-inner"
            :class="{
              'wconfig-breadcrumbs-links__container-inner--has-children':
                Object.keys(element.link.children || {}).length > 0,
            }"
          >
            <div class="wconfig-breadcrumbs-links__container-header w-100">
              <div class="wconfig-breadcrumbs-links__label-element">
                <span class="pointer">
                  <CommonIcon
                    name="custom:dnd-marker"
                    :size="8"
                  />
                </span>
                <span class="bold">Breadcrumb Links</span>
              </div>
              <span
                class="pointer"
                @click="removeLink(element)"
              >
                <CommonIcon
                  name="custom:close-icon"
                  class="wconfig-breadcrumbs-links__link-icon"
                  :size="8"
                />
              </span>
            </div>
            <div class="wconfig-breadcrumbs-links__container-form">
              <BreadcrumbsDynamicParams
                :widget="widget"
                :initial-value="element"
                :index="index"
              />
            </div>
          </div>
        </template>
      </DraggableList>
    </div>
    <div class="wconfig-breadcrumbs-links__add-new-button-container">
      <a-button
        type="primary"
        class="w-100"
        :disabled="sortedLinks.length === 10"
        @click="handleAddNewLink"
      >
        Add new breadcrumb
      </a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { WritableComputedRef } from "vue";

import { deepCopy } from "~~/assets/utils";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import BreadcrumbsDynamicParams from "~~/components/widget-settings/breadcrumbs/BreadcrumbsDynamicParams.vue";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue: Array<any>;
  widget?: IWidgetWithFields;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: Array<string>): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

let links = ref<Array<any>>(deepCopy(props.modelValue));

const handleAddNewLink = () => {
  const generatedLink = {
    title: {
      sources: ["custom"],
      source: "custom",
      label: "Title",
      value:
        useLocalizedValue().transformToLocalizedValue("Breadcrumb Link")
          .localizedValue,
    },
    link: {
      sources: ["custom"],
      source: "custom",
      label: "Url",
      required: true,
      value: useLocalizedValue().transformToLocalizedValue("/").localizedValue,
    },
  };
  links.value.push(generatedLink);
  emit("update:modelValue", links.value);
};

const sortedLinks: WritableComputedRef<Array<any>> = computed({
  get(): Array<any> {
    return props.modelValue;
  },

  set(val) {
    emit("update:modelValue", val);
  },
});

const removeLink = (linkToDelete: string): void => {
  links.value = links.value.filter(link => link !== linkToDelete);

  emit("update:modelValue", links.value);
};
</script>

<style lang="scss">
.wconfig-breadcrumbs-links {
  max-height: calc(100vh - 112px - 80px - 106px);
  overflow: auto;

  &__container {
    @include flex-column(center, unset, 16px);
    margin: 16px 0 16px;
  }

  &__container-inner {
    @include flex-column(flex-start, unset, 0);
    padding: 16px;
    background-color: #0000000a;

    &--has-children {
      border-left: 2px solid #0080ff;
      padding: 0;
      background-color: transparent;

      .wconfig-breadcrumbs-links__container-header {
        padding: 16px;
        background-color: #0000000a;
      }
    }
  }

  &__container-header {
    @include flex(center, space-between, 0);
  }

  &__label-element {
    @include flex(center, unset, 8px);
  }

  &__add-new-button-container {
    width: 100%;
    max-width: 264px;
    padding: 24px;
    background-color: #fff;

    .ant-btn {
      border-radius: 8px;
    }
  }
}
</style>
