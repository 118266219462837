<template>
  <li
    class="languages-widget-item"
    :style="wrapperStyle"
  >
    <span
      v-if="field.options.icon._active"
      :style="iconStyleValue"
    >
      <CommonIcon name="ant-design:picture-filled" />
    </span>

    <BasicTitleField
      v-if="field.options.text._active"
      :value="value"
      :field="field"
      :options="textOptions"
    />
  </li>
</template>

<script setup lang="ts">
import {
  getFlexPosition,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  field: IWidgetField;
  widget: IWidgetWithFields;
  state: State;
  value: string;
}>();

const activeStateOptions = computed<IWidgetOptions>(() => {
  return props.state === State.DEFAULT
    ? props.field.options
    : props.field.options.states[props.state];
});

const iconSettings = computed<IWidgetOptions>(() => props.field.options.icon);

const wrapperStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.field.options,
      ...activeStateOptions.value,
    }),
    gap: getPxValueFromNumber(iconSettings.value.gap),
    justifyContent: getFlexPosition(props.widget.options.display.alignment),
  };
});

const iconStyleValue = computed<ElementStyle>(() => {
  return {
    ...getFontSizeStyle(iconSettings.value.iconSize),
  };
});

const textOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options.text;
  }

  return {
    ...props.field.options.text,
    color: activeStateOptions.value.color,
  };
});
</script>

<style lang="scss">
.languages-widget-item {
  @include flex(center, flex-start);
  display: flex;
}
</style>
