<template>
  <div class="common-loader">
    <CommonIcon
      name="ant-design:loading-outlined"
      spin
      class="common-loader__spinner"
    />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.common-loader {
  @include flex(center, center, 0);

  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $c-white-30;

  &__spinner {
    font-size: 20px;
    color: $c-primary-base;
  }
}
</style>
