<template>
  <BaseGridSidebar class="grid-with-pages-sidebar">
    You can’t edit the template
    <br />because pages have already <br />been added to it

    <template #buttons>
      <a-button
        type="primary"
        ghost
        @click="duplicateTemplate"
        >Duplicate</a-button
      >
      <AddPageTrigger activator-title="Add new page" />
    </template>
  </BaseGridSidebar>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";

import { useIframePostMessage } from "~~/composables/useIframePostMessage";
import { useGridConfig } from "~~/store/grid";

const gridStore = useGridConfig();
const router = useRouter();
const route = useRoute();

const duplicateTemplate = (): void => {
  gridStore.duplicateTemplate().then(() => {
    router.push({
      path: "layout",
      query: {
        id: gridStore.templateId,
        project_id: route.query.project_id,
        lang: route.query.lang,
        version: route.query.version,
      },
    });

    useIframePostMessage().sendFullRedirect(
      `/template/${gridStore.templateId}`
    );
  });
};
</script>
