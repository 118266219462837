import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "pending_description"),
    generateDropdownItem("Tabs settings", "tabs_container"),
    generateDropdownItem("Filter settings", "form"),
    generateDropdownItem("Table settings", "table"),
    generateDropdownItem("Header settings", "table_header_settings", "table"),
    generateDropdownItem("Rows settings", "table_row_settings", "table"),
    generateDropdownItem(
      "Checkbox settings",
      "table_checkboxes_settings",
      "table"
    ),
    generateDropdownItem("From Date", "date_from", "filter"),
    generateDropdownItem("To Date", "date_to", "filter"),
    generateDropdownItem("Transaction types", "transaction_types", "filter"),
    generateDropdownItem(
      "Transaction subtypes",
      "transaction_subtypes",
      "filter"
    ),
    generateDropdownItem("Cancel button", "action_button"),
    generateDropdownItem("Link states", "linkStates", "linkStates"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    form: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
    ],
    table: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    action_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
