import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { calculateDisableFieldsAllowed } from "~~/helpers/configs/disable-fields-allowed";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getDefaultFontFamily } from "~~/constants/configs/common/widget-initial";

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    theme: 24,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: "left",
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
      states: {
        [State.HOVER]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
        [State.ACTIVE]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
      },
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
});

export const DESCRIPTION_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    displaySettings: 1,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const useTextConfig = (
  widget: Ref<IWidgetWithFields>,
  titleLinkState: Ref<State>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const descriptionFields = computed(() => {
    return widget.value.fields.filter(field =>
      field.name.includes("description")
    );
  });

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "description_1"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const PRIMARY_WIDGET_FIELDS = ["title", "description_1"];

  const disableFieldsAllowed = calculateDisableFieldsAllowed(
    PRIMARY_WIDGET_FIELDS,
    fields
  );

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "ConfigElementPosition",
        valuePath: "options.textPosition",
        valueSource: widget.value,
        options: {},
        visible: fields.value.title.active,
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "group-control-element",
        options: {
          isMultiple: true,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: disableFieldsAllowed.value,
          fields: descriptionFields.value,
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description_1.field
            );
          },
        },
      },
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.title.field,
        options: {
          placeholder: "Enter text",
          label: "Active",
          active: true,
        },
      },
      {
        componentPath: "ConfigElementPosition",
        valuePath: "options.textPosition",
        valueSource: widget.value,
        options: {},
      },
      typography({ source: fields.value.title.field! }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: fields.value.title.field,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const titleLinkSettingsValuePath = computed<string>(() => {
    if (titleLinkState.value === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${titleLinkState.value}`;
  });

  const titleLinkStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: titleLinkState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-title-link-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${titleLinkSettingsValuePath.value}.color`,
        valueSource: fields.value.title.field,
        options: {
          label: "Text color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: `${titleLinkSettingsValuePath.value}.decoration`,
        valueSource: fields.value.title.field,
        options: {
          label: "Decoration",
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    titleControls,
    titleLinkStatesControls,
  };
};
