import { type Ref } from "vue";

import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  JustifyPosition,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useButtonControls } from "../card/useButtonControls";

const AUTHED_LIVE_BANNER_ITEMS = [
  {
    value: "live_banner_event_info",
    label: "Event info",
  },
  {
    value: "live_banner_header",
    label: "Header",
  },
  {
    value: "live_banner_rows",
    label: "Rows",
  },
  {
    value: "live_banner_footer",
    label: "Footer",
  },
];

const NOT_AUTHED_LIVE_BANNER_ITEMS = [
  {
    value: "live_banner_login_text",
    label: "Login text",
  },
  {
    value: "live_banner_login_button",
    label: "Login button",
  },
];

const LIVE_BANNER_EVENT_INFO_ITEMS = [
  {
    value: "live_banner_event_info_name",
    label: "Event name",
  },
  {
    value: "live_banner_event_info_score",
    label: "Score",
  },
];

const AUTHED_RACING_BANNER_ITEMS = [
  {
    value: "racing_banner_event_description",
    label: "Event description",
  },
];

const NOT_AUTHED_RACING_BANNER_ITEMS = [
  {
    value: "racing_banner_login_text",
    label: "Login text",
  },
  {
    value: "racing_banner_login_button",
    label: "Login button",
  },
];

export const useSportEventBannerConfig = (
  states: Ref<Record<string, string>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const bannerDesignControls = computed(() => [
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const bannerContentTabItems = computed<{ label: string; value: string }[]>(
    () => {
      if (selectedField.value!.name === "live_banner") {
        if (states.value.live_banner === State.AUTHORIZED) {
          return AUTHED_LIVE_BANNER_ITEMS;
        } else {
          return NOT_AUTHED_LIVE_BANNER_ITEMS;
        }
      }

      if (selectedField.value!.name === "racing_banner") {
        if (states.value.racing_banner === State.AUTHORIZED) {
          return AUTHED_RACING_BANNER_ITEMS;
        } else {
          return NOT_AUTHED_RACING_BANNER_ITEMS;
        }
      }

      return [];
    }
  );

  const bannerContentControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.authorized,
            value: State.AUTHORIZED,
          },
          {
            label: STATE_KEY_LABELS.unauthorized,
            value: State.UNAUTHORIZED,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.states.unauthorized.fill",
      valueSource: selectedField.value,
      visible: states.value[selectedField.value!.name] === State.UNAUTHORIZED,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: bannerContentTabItems.value,
      },
    },
  ]);

  const prematchBannerContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "prematch_banner_event_title",
            label: "Event Title",
          },
          {
            value: "prematch_banner_event_time",
            label: "Event Time",
          },
        ],
      },
    },
  ]);

  const bannerEventInfoDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const liveBannerEventInfoContentTabItems = computed<
    { label: string; value: string }[]
  >(() => {
    if (selectedField.value?.name === "live_banner_event_info") {
      return LIVE_BANNER_EVENT_INFO_ITEMS;
    }

    return [];
  });

  const liveBannerEventInfoContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: liveBannerEventInfoContentTabItems.value,
      },
    },
  ]);

  const liveBannerEventNameControls = computed(() => [
    typography({
      source: selectedField.value,
    }),
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.textShadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Text shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
      },
    },
  ]);

  const liveBannerFooterElementsControls = computed(() => [
    typography({
      source: selectedField.value,
      except: ["ConfigAlignment"],
    }),
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.textShadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Text shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
      },
    },
  ]);

  const liveBannerEventScoreContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    typography({
      source: selectedField.value!.options.typography,
      except: ["ConfigAlignment"],
      isOptionPath: false,
    }),
  ]);

  const liveBannerHeaderContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "live_banner_header_statistics",
            label: "Statistics",
          },
          {
            value: "live_banner_header_titles",
            label: "Titles",
          },
        ],
      },
    },
  ]);

  const liveBannerStatisticsContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.display.resizing",
          valueSource: selectedField.value,
          options: {
            label: "",
            items: [
              {
                label: "Fill container",
                value: ResizingType.FILL,
              },
              {
                label: "Fixed",
                value: ResizingType.FIXED,
              },
            ],
          },
        },
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.display.width",
          valueSource: selectedField.value,
          visible:
            selectedField.value!.options.display.resizing ===
            ResizingType.FIXED,
          options: {
            label: "Width in %",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "%",
          },
        },
        {
          componentPath: "ConfigAlignment",
          valuePath: "options.display.alignment",
          valueSource: selectedField.value,
          className: "group-control-element--child border-bottom-none",
          options: {
            label: "Alignment",
            isTextContentIcons: false,
          },
        },
      ],
    },
    typography({
      source: selectedField.value!.options.typography,
      except: ["ConfigAlignment"],
      isOptionPath: false,
    }),
  ]);

  const liveBannerTitlesContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.display.alignmentType",
          valueSource: selectedField.value,
          options: {
            label: "Alignment type",
            items: [
              {
                label: "Alignment",
                value: "alignment",
              },
              {
                label: "Justify",
                value: "justify",
              },
            ],
          },
        },
        {
          componentPath: "ConfigAlignment",
          valuePath: "options.display.alignment",
          valueSource: selectedField.value,
          className: "group-control-element--child",
          visible:
            selectedField.value?.options.display.alignmentType === "alignment",
          options: {
            label: "Alignment",
            isTextContentIcons: false,
          },
        },
        {
          componentPath: "common/ConfigCommonSelect",
          valuePath: "options.display.justifyContent",
          valueSource: selectedField.value,
          visible:
            selectedField.value?.options.display.alignmentType === "justify",
          options: {
            label: "Justify content",
            selectOptions: [
              {
                label: "stretch",
                value: JustifyPosition.STRETCH,
              },
              {
                label: "start",
                value: JustifyPosition.START,
              },
              {
                label: "end",
                value: JustifyPosition.END,
              },
              {
                label: "center",
                value: JustifyPosition.CENTER,
              },
              {
                label: "space-between",
                value: JustifyPosition.SPACE_BETWEEN,
              },
              {
                label: "space-around",
                value: JustifyPosition.SPACE_AROUND,
              },
              {
                label: "space-evenly",
                value: JustifyPosition.SPACE_EVENLY,
              },
            ],
          },
        },
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.display.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "live_banner_header_value_titles",
            label: "Values titles",
          },
          {
            value: "live_banner_header_point_title",
            label: "Point title",
          },
        ],
      },
    },
  ]);

  const liveBannerValuesTitlesDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.spacing.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const liveBannerValuesTitlesContentControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.small_size,
            value: State.SMALL_SIZE,
          },
          {
            label: STATE_KEY_LABELS.big_size,
            value: State.BIG_SIZE,
          },
        ],
      },
      onUpdate(value: State) {
        states.value.live_banner_header_value_titles = value;
        states.value.live_banner_header_point_title = value;
        states.value.live_banner_rows_team_player_info_values = value;
        states.value.live_banner_rows_team_player_info_point = value;
      },
    },
    {
      section: generateDesignSectionName("Block"),
      controls: [
        {
          componentPath: "ConfigHorizontalSpace",
          valuePath: "block.width",
          valueSource:
            selectedField.value!.options.states[
              states.value[selectedField.value!.name]
            ],
          className: "group-control-element",
          options: {
            label: "Block width",
          },
        },
        {
          componentPath: "ConfigHorizontalSpace",
          valuePath: "block.height",
          valueSource:
            selectedField.value!.options.states[
              states.value[selectedField.value!.name]
            ],
          className: "group-control-element",
          options: {
            label: "Block height",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Cell"),
      controls: [
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "cell.minCellWidth",
          className: "p-l-16 p-r-16 p-b-16 p-t-16",
          valueSource:
            selectedField.value!.options.states[
              states.value[selectedField.value!.name]
            ],
          options: {
            label: "Minimum cell width",
            minValue:
              selectedField.value!.options.states[
                states.value[selectedField.value!.name]
              ].block.width,
            maxValue:
              selectedField.value!.options.states[
                states.value[selectedField.value!.name]
              ].block.width * 3,
          },
        },
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "cell.maxCellWidth",
          className: "p-l-16 p-r-16 p-b-16 p-t-16",
          valueSource:
            selectedField.value!.options.states[
              states.value[selectedField.value!.name]
            ],
          options: {
            label: "Maximum cell width",
            minValue:
              selectedField.value!.options.states[
                states.value[selectedField.value!.name]
              ].cell.minCellWidth,
            maxValue:
              selectedField.value!.options.states[
                states.value[selectedField.value!.name]
              ].block.width * 3,
          },
        },
      ],
    },
    typography({
      source:
        selectedField.value!.options.states[
          states.value[selectedField.value!.name]
        ].typography,
      except: ["ConfigAlignment"],
      isOptionPath: false,
    }),
  ]);

  const liveBannerValuesTitlesStateValuePath = computed(() => {
    if (
      states.value.live_banner_rows_team_player_info_values_states ===
      State.DEFAULT
    ) {
      return "options";
    }

    if (
      states.value.live_banner_rows_team_player_info_values_states ===
      State.WINNER
    ) {
      return "options.winner";
    }
  });

  const liveBannerValuesTitlesTextStateValuePath = computed(() => {
    if (
      states.value.live_banner_rows_team_player_info_values_states ===
      State.DEFAULT
    ) {
      return `options.states.${
        states.value[selectedField.value!.name]
      }.typography.color`;
    }

    if (
      states.value.live_banner_rows_team_player_info_values_states ===
      State.WINNER
    ) {
      return "options.winner.color";
    }
  });

  const liveBannerValuesTitlesStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value.live_banner_rows_team_player_info_values_states,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.winner,
            value: State.WINNER,
          },
        ],
      },
      onUpdate(value: State) {
        states.value.live_banner_rows_team_player_info_values_states = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${liveBannerValuesTitlesStateValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${liveBannerValuesTitlesStateValuePath.value}.border`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${liveBannerValuesTitlesStateValuePath.value}.shadow`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: liveBannerValuesTitlesTextStateValuePath.value,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Text color",
            isBold: true,
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const liveBannerRowsTeamPlayerInfoPointsStateValuePath = computed(() => {
    if (
      states.value.live_banner_rows_team_player_info_point_states ===
      State.DEFAULT
    ) {
      return "options";
    }

    if (
      states.value.live_banner_rows_team_player_info_point_states ===
      State.WINNER
    ) {
      return "options.winner";
    }
  });

  const liveBannerRowsTeamPlayerInfoPointsTextStateValuePath = computed(() => {
    if (
      states.value.live_banner_rows_team_player_info_point_states ===
      State.DEFAULT
    ) {
      return `options.states.${
        states.value[selectedField.value!.name]
      }.typography.color`;
    }

    if (
      states.value.live_banner_rows_team_player_info_point_states ===
      State.WINNER
    ) {
      return "options.winner.color";
    }
  });

  const liveBannerRowsTeamPlayerInfoPointsStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value.live_banner_rows_team_player_info_point_states,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.winner,
            value: State.WINNER,
          },
        ],
      },
      onUpdate(value: State) {
        states.value.live_banner_rows_team_player_info_point_states = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${liveBannerRowsTeamPlayerInfoPointsStateValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${liveBannerRowsTeamPlayerInfoPointsStateValuePath.value}.border`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${liveBannerRowsTeamPlayerInfoPointsStateValuePath.value}.shadow`,
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: liveBannerRowsTeamPlayerInfoPointsTextStateValuePath.value,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Text color",
            isBold: true,
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const liveBannerRowsContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "live_banner_rows_team_player",
            label: "Team / Player",
          },
          {
            value: "live_banner_rows_team_player_info",
            label: "Team / Player info",
          },
        ],
      },
    },
  ]);

  const liveBannerRowsTeamPlayerInfoContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.display.alignmentType",
          valueSource: selectedField.value,
          options: {
            label: "Alignment type",
            items: [
              {
                label: "Alignment",
                value: "alignment",
              },
              {
                label: "Justify",
                value: "justify",
              },
            ],
          },
        },
        {
          componentPath: "ConfigAlignment",
          valuePath: "options.display.alignment",
          valueSource: selectedField.value,
          className: "group-control-element--child",
          visible:
            selectedField.value?.options.display.alignmentType === "alignment",
          options: {
            label: "Alignment",
            isTextContentIcons: false,
          },
        },
        {
          componentPath: "common/ConfigCommonSelect",
          valuePath: "options.display.justifyContent",
          valueSource: selectedField.value,
          visible:
            selectedField.value?.options.display.alignmentType === "justify",
          options: {
            label: "Justify content",
            selectOptions: [
              {
                label: "stretch",
                value: JustifyPosition.STRETCH,
              },
              {
                label: "start",
                value: JustifyPosition.START,
              },
              {
                label: "end",
                value: JustifyPosition.END,
              },
              {
                label: "center",
                value: JustifyPosition.CENTER,
              },
              {
                label: "space-between",
                value: JustifyPosition.SPACE_BETWEEN,
              },
              {
                label: "space-around",
                value: JustifyPosition.SPACE_AROUND,
              },
              {
                label: "space-evenly",
                value: JustifyPosition.SPACE_EVENLY,
              },
            ],
          },
        },
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.display.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "live_banner_rows_team_player_info_values",
            label: "Values",
          },
          {
            value: "live_banner_rows_team_player_info_point",
            label: "Point",
          },
        ],
      },
    },
  ]);

  const liveBannerFooterContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.distance",
          valueSource: selectedField.value,
          options: {
            label: "Distance between elements",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "live_banner_footer_time",
            label: "Time",
          },
          {
            value: "live_banner_footer_period",
            label: "Period",
          },
        ],
      },
    },
  ]);

  const liveBannerLoginTextControls = computed(() => [
    typography({
      source: selectedField.value,
    }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
      },
    },
  ]);

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls({
      state: toRef(() => states.value[selectedField.value!.name]),
      stateHandler: (state: State) => {
        states.value[selectedField.value!.name] = state;
      },
      valueSource: toRef(selectedField.value!),
      showAlignmentOnFillOnly: false,
    });

  return {
    bannerDesignControls,
    bannerContentControls,

    bannerEventInfoDesignControls /* Use for 'event score', 'header', 'statistics', 'titles' */,

    liveBannerEventInfoContentControls,
    liveBannerEventNameControls,
    liveBannerEventScoreContentControls,
    liveBannerHeaderContentControls,

    liveBannerStatisticsContentControls,
    liveBannerTitlesContentControls,

    liveBannerValuesTitlesDesignControls,
    liveBannerValuesTitlesContentControls,

    liveBannerRowsContentControls,
    liveBannerRowsTeamPlayerInfoContentControls,

    liveBannerFooterContentControls,
    liveBannerLoginTextControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
    liveBannerFooterElementsControls,
    prematchBannerContentControls,

    liveBannerValuesTitlesStateControls,
    liveBannerRowsTeamPlayerInfoPointsStateControls,
  };
};
