<template>
  <div
    :style="itemStyles"
    class="racing-sports-bet-item"
    :class="{
      'racing-sports-bet-item--is-live': isUp || isDown,
      'racing-sports-bet-item--is-down': isDown,
      'racing-sports-bet-item--show-both-arrows': shouldShowBothArrows,
      'racing-sports-bet-item--show-both-arrows-live': isUp || isDown,
      'racing-sports-bet-item--show-both-arrows-down': isDown,
    }"
  >
    <span :style="itemCoefStyles">
      {{ value }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getTextAlignStyle,
  getColorFromHex,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  value: string;
  betItemField: IWidgetField;
  stateOptions: IWidgetOptions;
  isUp?: boolean;
  isDown?: boolean;
  showBothArrowsStates?: Array<State>;
  state?: State;
}>();

const itemStyles = computed<ElementStyle>(() => {
  const commonStyles = {
    fontFamily: props.betItemField.options.fontFamily,
    ...getTextAlignStyle(props.betItemField.options.alignment),
    ...getCommonStyles({
      ...props.betItemField.options,
      ...props.stateOptions,
    }),
  };

  if (props.showBothArrowsStates) {
    return {
      ...commonStyles,
      ...getStylesAsVars({
        upColor: getColorFromHex(props.stateOptions.arrow?.up?.color) || "",
        downColor: getColorFromHex(props.stateOptions.arrow?.down?.color) || "",
      }),
    };
  }

  return {
    ...commonStyles,
    ...getStylesAsVars({
      ...getColorStyle({
        ...props.stateOptions.arrow?.color,
      }),
    }),
  };
});

const itemCoefStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.stateOptions.color),
    ...getFontSizeStyle(props.betItemField.options.theme),
    ...getDecorationValue(props.stateOptions.decoration),
  };
});

const shouldShowBothArrows = computed<boolean>(() => {
  if (!props.showBothArrowsStates || !props.state) return false;

  return !!props.showBothArrowsStates.includes(props.state);
});
</script>

<style lang="scss">
.racing-sports-bet-item {
  &--is-live {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      top: -7px;
      width: 27px;
      height: 15px;
      background-color: var(--color);
      z-index: 2;
      transform: rotate(45deg);
    }
  }

  &--is-down {
    &::after {
      transform: rotate(-45deg);
      top: initial;
      bottom: -7px;
    }
  }

  &--show-both-arrows {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      width: 27px;
      height: 15px;
      z-index: 2;
    }

    &::after {
      background-color: var(--upColor);
      transform: rotate(45deg);
      top: -7px;
    }

    &::before {
      background-color: var(--downColor);
      transform: rotate(-45deg);
      bottom: -7px;
    }
  }

  &--show-both-arrows-live {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      top: -7px;
      width: 27px;
      height: 15px;
      background-color: var(--upColor);
      z-index: 2;
      transform: rotate(45deg);
    }
  }

  &--show-both-arrows-down {
    position: relative;
    overflow: hidden;

    &::after {
      transform: rotate(-45deg);
      top: initial;
      bottom: -7px;
      background-color: var(--downColor);
    }
  }
}
</style>
