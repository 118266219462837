<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :value="value"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    class="form-text-input-field"
  >
    <a-input
      :placeholder="
        placeholder?._active ? getLocalizedValue(placeholder.value) : ''
      "
      :style="{ ...inputStyles, ...cssVars }"
      :value="getLocalizedValue(field.value)"
      class="form-text-input-field__input w-100"
      type="text"
      readonly
    >
      <template #suffix>
        <slot name="suffix" />
      </template>
    </a-input>

    <slot />
  </FormInputBase>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { ElementStyle } from "~~/models/common";
import { getStylesAsVars } from "~~/assets/utils/widget/form";

interface IFormTextInputProp {
  _active: boolean;
  value: string;
}

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.FIELD];
});

const { getLocalizedValue } = useLocalizedValue();

const {
  inputStyles,
  isErrorVisible,
  isTooltipVisible,
  isCalendarVisible,
  triggerFieldExample,
  hideFieldExample,
} = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

defineExpose({
  triggerFieldExample,
  hideFieldExample,
  isCalendarVisible,
});

const placeholder = computed<IFormTextInputProp | undefined>(() => {
  return props.field.options?.placeholder;
});

const cssVars = computed<ElementStyle>(() => {
  return getStylesAsVars({
    borderWidth: inputStyles.value.borderWidth,
  });
});
</script>

<style lang="scss">
.form-text-input-field {
  .ant-input-affix-wrapper {
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-width: var(--borderWidth) !important;
    }
  }

  &__input {
    outline: none;
    width: 100%;
    border-radius: 0px;
    border: 1px solid transparent;

    input {
      background: inherit;
      font-size: inherit;
      color: inherit;
      text-decoration: inherit;
      font-style: inherit;
      font-weight: inherit;

      &::placeholder {
        color: var(--placeholderColor);
        text-decoration: var(--textDecoration) !important;
        text-decoration-color: var(--textDecorationColor) !important;
      }
    }
  }
}
</style>
