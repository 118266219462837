import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";

import { useButtonControls } from "../card/useButtonControls";

export const useSportsbookHeaderConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /*
    Header config
  */

  const headerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.header.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.header.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.header.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.header.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.header.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const headerContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigVerticalAlignment",
        valuePath: "options.alignment",
        valueSource: fields.value.header.field,
        className: "widget-control-container p-b-16",
        options: {
          label: "Alignment",
          isBold: true,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Title",
              value: "title",
            },
            {
              label: "Button",
              value: "live_button_title",
              isActiveValueSource: fields.value.live_button_title.field.options,
            },
          ],
        },
      },
    ];
  });

  /*
    Header button config
  */

  const {
    buttonStatesControls: headerButtonStatesControls,
    buttonDesignControls: headerButtonDesignControls,
    buttonContentControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.live_button_title),
      stateHandler: (state: State) => {
        emit("update-state", { state: "live_button_title", value: state });
      },
      exclude: {
        content: [
          "ConfigButtonDisplaySettings",
          "ConfigLinkType",
          "ConfigIconSettings",
          "content/ConfigTextInputWithToggle",
        ],
      },
    },
    emit
  );

  const headerButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.iconSettings.gap",
            valueSource: fields.value.live_button_title.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Typography"),
        controls: [...buttonContentControls.value],
      },
      {
        section: generateContentSectionName("Marker"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.iconSettings.textColor",
            valueSource: fields.value.live_button_title.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSettings.iconSize",
            valueSource: fields.value.live_button_title.field,
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  return {
    headerDesignControls,
    headerContentControls,

    headerButtonStatesControls,
    headerButtonDesignControls,
    headerButtonContentControls,
  };
};
