export type ArrowIcon = "ArrowRight" | "ArrowLeft" | "Down" | "CaretDown";

export enum ArrowType {
  ArrowRight = "ArrowRight",
  ArrowLeft = "ArrowLeft",
  Down = "Down",
  CaretDown = "CaretDown",
}

export const iconMapping: Record<string, string> = {
  ArrowRight: "ant-design:arrow-right-outlined",
  ArrowLeft: "ant-design:arrow-left-outlined",
  Down: "ant-design:down-outlined",
  CaretDown: "ant-design:caret-down-filled",
  More: "ant-design:more-outlined",
  Ellipsis: "ant-design:ellipsis-outlined",
  Vertical: "custom:orientation-vertical",
  Horizonatal: "custom:orientation-horizontal",
  CalendarOutlined: "ant-design:calendar-outlined",
  CalendarFilled: "ant-design:calendar-filled",
  FluentCalendarOutlined: "fluent:calendar-28-regular",
  FluentCalendarFilled: "fluent:calendar-28-filled",
};

export const rotationIcons: Record<ArrowIcon, boolean> = {
  ArrowRight: true,
  ArrowLeft: true,
  Down: true,
  CaretDown: true,
};
