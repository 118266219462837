import { ComputedRef } from "vue";

import { parseId } from "~~/assets/utils";
import { findCell } from "~~/assets/utils/grid/grid-tree";
import { PROMPT_OFFSET_INNER, PROMPT_OFFSET_OUTER } from "~~/constants/dnd";
import { IClientBoundingRect } from "~~/models/dnd.model";
import {
  InsertionRule,
  IInsertPosition,
  ICell,
} from "~~/models/grid.interface";
import { useDndStore } from "~~/store/dnd";
import { useGridConfig } from "~~/store/grid";

export const useDivider = (
  currentLayout: ComputedRef<ICell[]>,
  shouldUpdatePrompt: boolean
): { [key: string]: any } => {
  const store = useGridConfig();
  const dndStore = useDndStore();

  const { currentDimensions } = storeToRefs(store);

  const getFirstChildColumnCell = (target: HTMLElement) =>
    target.getElementsByClassName("grid-cell__column").item(0) as HTMLElement;
  /*
    We need to know on which index divider is located right now.
    We will use this data to insert cell inside grid
  */
  const insertPosition = ref<IInsertPosition>({} as IInsertPosition);

  const updatePrompt = (target?: HTMLElement) => {
    dndStore.setDraggingLayoutPromptData(null);

    const { index, insertionRule } = insertPosition.value;

    if (target) {
      // dragged to middle of the cell
      if (target.dataset.isParent === "true") return;

      let cellId = parseId(target.dataset.elementId);

      if (!cellId) {
        // dragged over container cell
        // so need to find first available child column cell
        const columnCell = getFirstChildColumnCell(target);
        if (!columnCell) return;

        cellId = parseId(columnCell.dataset.elementId);
      }

      const cell = findCell(currentLayout.value, cellId!);

      if (!cell?.parentId) return;

      dndStore.setDraggingLayoutPromptData({
        cellId: cell.parentId,
        offsetBottom:
          insertionRule === InsertionRule.AFTER ? PROMPT_OFFSET_INNER : 0,
        offsetTop:
          insertionRule === InsertionRule.BEFORE ? PROMPT_OFFSET_INNER : 0,
      });

      return;
    }

    if (!currentLayout.value.length) return;

    const cellIndex = index + insertPosition.value.insertionRule;

    if (cellIndex < currentLayout.value.length) {
      dndStore.setDraggingLayoutPromptData({
        cellId: currentLayout.value[cellIndex].cellId,
        offsetBottom: 0,
        offsetTop: PROMPT_OFFSET_OUTER,
      });
    } else {
      dndStore.setDraggingLayoutPromptData({
        cellId: currentLayout.value[currentLayout.value.length - 1].cellId,
        offsetBottom: PROMPT_OFFSET_OUTER,
        offsetTop: 0,
      });
    }
  };

  const setOutsideCellDividerRenderData = (
    nearestRowIndex: number,
    container: HTMLElement
  ) => {
    const containerRoot = container.children[0] as HTMLElement;
    const width = containerRoot.offsetWidth;
    const left = containerRoot.offsetLeft;
    /*
      Save latest index, to insert cell
      on same position. Cell insertion will be handled
      on drop
    */
    insertPosition.value = {
      index: nearestRowIndex,
      insertionRule: InsertionRule.BEFORE,
    };

    if (shouldUpdatePrompt) updatePrompt();

    calculateRootRowsDimensions();

    const position = currentDimensions.value[nearestRowIndex];

    /*
      It's last row
    */
    if (!position) {
      /*
        Take last row and calculate it's bottom Y position
      */
      const lastRowData =
        currentDimensions.value[currentDimensions.value.length - 1];

      /* 
        If workspace is empty
        TODO: `emptyElement` part needs to be reviewed later
      */
      if (!lastRowData) {
        store.setDividerData({
          left,
          width,
          top: PROMPT_OFFSET_OUTER / 2,
        });
        return;
      }

      store.setDividerData({
        left,
        width,
        top: lastRowData.top + lastRowData.height,
      });

      return;
    }

    store.setDividerData({
      left,
      width,
      top: position.top,
    });
  };

  const setInsideCellDividerRenderData = (
    target: HTMLElement,
    mouseY: number,
    workspaceRootYPosition: number
  ): void => {
    if (!target) {
      return;
    }

    /*
      We need to know if target cell is NestingLevel.Parent
      If yes - set divider in the middle of cell.
      If no - set divider in the top or bottom of cell
    */

    const isParent = target.dataset.isParent === "true";

    const parent = getCellParent(target);

    if (!parent) {
      return;
    }

    /*
      We need dimensions of current cell to
      calculate position of divider
    */
    const targetRenderData: IClientBoundingRect =
      target.getBoundingClientRect();

    /*
      Calculate direction to insert
    */
    const insertionRule: InsertionRule = calculateCellDirectionToInsert(
      targetRenderData,
      mouseY,
      workspaceRootYPosition
    );

    /*
      Save cell details
    */
    const containerIndex = Number(target.dataset.containerIndex || -1);

    insertPosition.value = {
      index: containerIndex,
      insertionRule,
    };

    if (shouldUpdatePrompt) updatePrompt(target);

    const dividerTopPosition = calculateInsideCellDividerPosition(
      isParent,
      target.dataset.elementId
        ? targetRenderData
        : getFirstChildColumnCell(target).getBoundingClientRect(),
      workspaceRootYPosition,
      insertionRule
    );

    const dndContainerLeft =
      document.getElementById("drag-n-drop-container")?.getBoundingClientRect()
        .left || 0;

    // Width of parent
    const width = parent.offsetWidth;

    const left =
      target.offsetWidth < width
        ? parent.getBoundingClientRect().left
        : targetRenderData.left;

    store.setDividerData({
      left: left - dndContainerLeft,
      width,
      top: dividerTopPosition,
    });
  };

  const findNearestRootBlockIndex = (
    mouseY: number,
    positions: Array<IClientBoundingRect>
  ): number => {
    for (let i = 0; i < positions.length; i++) {
      const pos = positions[i];
      const elTopPosition = pos.top;

      if (mouseY < elTopPosition) {
        return i;
      }
    }

    return positions.length;
  };

  /*
    We need to calculate index to insert the child.
    If cursor is less then middle Y position of
    hovering block - make index same as block index.
    If greater - increase index by 1.
  */
  const calculateCellDirectionToInsert = (
    containerRenderData: IClientBoundingRect,
    mouseY: number,
    workspaceRootYPosition: number
  ): InsertionRule => {
    /*
      Get relative to window(default is relative to viewport) container position Y
    */
    const containerPositionY = containerRenderData.top - workspaceRootYPosition;

    const containerMiddlePosition =
      containerPositionY + containerRenderData.height / 2;

    if (mouseY < containerMiddlePosition) {
      return InsertionRule.BEFORE;
    }

    return InsertionRule.AFTER;
  };

  const calculateInsideCellDividerPosition = (
    isParent: boolean,
    targetRenderData: IClientBoundingRect,
    workspaceRootYPosition: number,
    insertionRule: InsertionRule
  ) => {
    let dividerTopPosition = 0;

    /*
      If cell is NestingLevel.Parent
      we need to show divider in the middle Y position
    */
    if (isParent) {
      dividerTopPosition =
        targetRenderData.top +
        targetRenderData.height / 2 -
        workspaceRootYPosition;

      return dividerTopPosition;
    }

    /*
      If rule is "before" - keep divider on top of cell.
      Otherwise move divider to bottom of cell
    */
    if (insertionRule === InsertionRule.BEFORE) {
      /*
        In both cases below the position relative to window(not viewport).
        That's why we need to substruct workspaceRootYPosition.value
      */
      dividerTopPosition = targetRenderData.top - workspaceRootYPosition;

      return dividerTopPosition;
    }

    dividerTopPosition =
      targetRenderData.top + targetRenderData.height - workspaceRootYPosition;

    return dividerTopPosition;
  };

  const calculateRootRowsDimensions = (): void => {
    const currentDimensions = [];

    for (let i = 0; i < currentLayout.value.length; i++) {
      const row = currentLayout.value[i];

      const rowDOMElement: HTMLElement | null = document.querySelector(
        `#cellId_${row.cellId}`
      );

      if (!rowDOMElement) {
        continue;
      }

      currentDimensions.push({
        top: rowDOMElement.offsetTop,
        left: rowDOMElement.offsetLeft,
        height: rowDOMElement.offsetHeight,
        bottom: rowDOMElement.offsetTop + rowDOMElement.offsetHeight,
        width: rowDOMElement.offsetWidth,
        cellId: row.cellId,
      });
    }

    store.updateDimensions(currentDimensions);
  };

  const getCellParent = (el: HTMLElement | null): HTMLElement | null => {
    if (!el) {
      return null;
    }

    if (el.dataset.isParent !== "false") {
      return el;
    }

    return getCellParent(el.parentElement);
  };

  const resetInsertPosition = () => {
    insertPosition.value = {} as IInsertPosition;
  };

  const hideDivider = () => {
    store.setDividerData({ width: 0, left: 0, top: -1000 });
  };

  return {
    insertPosition,
    resetInsertPosition,
    calculateRootRowsDimensions,
    findNearestRootBlockIndex,
    setOutsideCellDividerRenderData,
    setInsideCellDividerRenderData,
    hideDivider,
  };
};
