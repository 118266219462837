import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";

import type { IFields } from "../useWidgetFields";

export const useLiveBetsEventCardsControls = (
  fields: Ref<IFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const eventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {},
      },
    ];
  });

  const isEventsCardsContentMode = computed<boolean>(() => {
    return states.value.event_cards === State.ACTIVE_EVENTS;
  });

  const eventCardsNoContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        visible: !isEventsCardsContentMode.value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.no_events_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      typography({
        source: fields.value.no_events_title.field,
        visible: !isEventsCardsContentMode.value,
      }),
    ];
  });

  const eventCardsContentControls = computed(() => {
    const baseControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.event_cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS[State.WITH_ACTIVE_EVENTS],
              value: State.ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS[State.NO_ACTIVE_EVENTS],
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "event_cards",
            value,
          });
        },
      },
    ];

    if (states.value.event_cards === State.NO_ACTIVE) {
      return [...baseControls, ...eventCardsNoContentControls.value];
    }

    return [
      ...baseControls,
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.displayMode",
            valueSource: fields.value.event_info.field,
            className: "event-cards-radio",
            options: {
              label: "Event info layout",
              items: [
                {
                  icon: "custom:bet-layout-center",
                  value: "center",
                },
                {
                  icon: "custom:bet-layout-right",
                  value: "right",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distance",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Distance between event info items",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: fields.value.bet_items.field,
            options: {
              label: "Bet items layout",
              items: [
                { label: "Horizontal", value: "horizontal" },
                { label: "Vertical", value: "vertical" },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.distance",
            valueSource: fields.value.bet_items.field,
            className: "group-control-element",
            options: { label: "Distance between bets items" },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Suspended status",
              value: "event_suspended_status",
            },
            {
              label: "Time",
              value: "event_time",
            },
            {
              label: "League",
              value: "event_league",
            },
            {
              label: "Event info",
              value: "event_info",
            },
            {
              label: "Bet items",
              value: "bet_items",
            },
          ],
        },
      },
    ];
  });

  return {
    eventCardsDesignControls,
    eventCardsContentControls,
  };
};
