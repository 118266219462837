import { Ref } from "vue";

import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useTitleControls = (
  states: Ref<Record<string, State>>,
  emit: any,
  except?: string[]
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
          label: "Active",
          active: true,
        },
      },
      typography({
        source: selectedField.value,
        except,
      }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const titleLinkSettingsValuePath = computed<string>(() => {
    if (states.value.title_link === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${states.value.title_link}`;
  });

  const titleLinkStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.title_link,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "title_link", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${titleLinkSettingsValuePath.value}.color`,
        valueSource: selectedField.value,
        options: {
          label: "Text color",
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: `${titleLinkSettingsValuePath.value}.decoration`,
        valueSource: selectedField.value,
        options: {
          label: "Decoration",
        },
      },
    ];
  });

  return { titleControls, titleLinkStatesControls };
};
