import { FormDropdownItem } from "~~/models/widgets/form.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

const defaultItems = () => {
  return [
    {
      value: 1,
      label: "Option 1",
    },
    {
      value: 2,
      label: "Option 2",
    },
  ];
};

const getItems = (details?: {
  items?: FormDropdownItem[] | null;
  options?: IWidgetOptions | FormDropdownItem[];
}): FormDropdownItem[] => {
  if (Array.isArray(details?.options)) {
    return details?.options || [];
  }

  const items = details?.items || details?.options?.items;

  if (!Array.isArray(items)) {
    return defaultItems() as unknown as FormDropdownItem[];
  }

  return items;
};

export const RADIO_GROUP_INITIAL = (
  fieldDetails?: {
    placeholder: string;
    label: string;
    options?: IWidgetOptions;
    items?: FormDropdownItem[] | null;
  },
  formName = "form"
) => {
  const items = getItems(fieldDetails);

  if (
    !Array.isArray(fieldDetails?.options) &&
    fieldDetails?.options &&
    Object.keys(fieldDetails.options).length > 2
  ) {
    return {
      options: {
        ...fieldDetails.options,
        items: items as FormDropdownItem[] | null,
      },
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      label: {
        _active: true,
        value: fieldDetails?.label,
      },
      tooltip: {
        _active: true,
        value: "Tooltip text",
      },
      items: items as FormDropdownItem[] | null,
    },
  };
};
