<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #deposit-design>
          <ControlsList
            :widget="widget"
            :controls="depositDesignControls"
          />
        </template>

        <template #deposit-content>
          <ControlsList
            :widget="widget"
            :controls="depositContentControls"
          />
        </template>

        <template #deposit-states>
          <ControlsList
            :widget="widget"
            :controls="depositStatesControls"
          />
        </template>

        <template #logout-design>
          <ControlsList
            :widget="widget"
            :controls="logoutDesignControls"
          />
        </template>

        <template #logout-content>
          <ControlsList
            :widget="widget"
            :controls="logoutContentControls"
          />
        </template>

        <template #logout-states>
          <ControlsList
            :widget="widget"
            :controls="logoutStatesControls"
          />
        </template>

        <template #registration-design>
          <ControlsList
            :widget="widget"
            :controls="registrationDesignControls"
          />
        </template>

        <template #registration-content>
          <ControlsList
            :widget="widget"
            :controls="registrationContentControls"
          />
        </template>

        <template #registration-states>
          <ControlsList
            :widget="widget"
            :controls="registrationStatesControls"
          />
        </template>

        <template #login-design>
          <ControlsList
            :widget="widget"
            :controls="loginDesignControls"
          />
        </template>

        <template #login-content>
          <ControlsList
            :widget="widget"
            :controls="loginContentControls"
          />
        </template>

        <template #login-states>
          <ControlsList
            :widget="widget"
            :controls="loginStatesControls"
          />
        </template>

        <template #dropdownContainer-design>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerDesignControls"
          />
        </template>

        <template #dropdownContainer-content>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerContentControls"
          />
        </template>

        <template #dropdown-design>
          <ControlsList
            :widget="widget"
            :controls="titleDesignControls"
          />
        </template>

        <template #dropdown-content>
          <ControlsList
            :widget="widget"
            :controls="titleContentControls"
          />
        </template>

        <template #dropdown-states>
          <ControlsList
            :widget="widget"
            :controls="titleStatesControls"
          />
        </template>

        <template #items>
          <ControlsList
            :widget="widget"
            :controls="itemsControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupStatesControls"
          />
        </template>

        <template #item>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #balanceContainer-design>
          <ControlsList
            :widget="widget"
            :controls="balanceDesignControls"
          />
        </template>

        <template #balanceContainer-content>
          <ControlsList
            :widget="widget"
            :controls="balanceContentControls"
          />
        </template>

        <template #balanceItems-design>
          <ControlsList
            :widget="widget"
            :controls="balanceItemsDesignControls"
          />
        </template>

        <template #balanceItems-states>
          <ControlsList
            :widget="widget"
            :controls="balanceItemsStatesControls"
          />
        </template>

        <template #balance>
          <ControlsList
            :widget="widget"
            :controls="balanceItemControls"
          />
        </template>

        <template #bonus>
          <ControlsList
            :widget="widget"
            :controls="balanceItemControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useProfileDropdownWidgetConfig } from "~~/composables/widgets/profile-dropdown/useProfileDropdownWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  states: Record<string, State>;
  widget: IWidgetWithFields;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "redirectAfterLogoutUrl": {
      label: "Redirect after logout URL",
      sources: ["custom"],
      required: true,
    },
    "bonuses": {
      label: "Bonuses",
      description: "Possible values: betting | casino",
      sources: ["custom"],
      required: false,
    },
    "balance": {
      label: "Balance",
      description: "Possible values: betting | casino",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const emit = defineEmits<{
  (e: "reset-states"): void;
  (e: "update-button-state", fieldName: FieldName, state: State): void;
  (e: "update-title-state", event: State): void;
  (e: "update-items-group-state", event: State): void;
  (e: "update-balance-items-state", event: State): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const widgetState = computed(() => props.states.widget);
const depositState = computed(() => props.states.deposit);
const logoutState = computed(() => props.states.logout);
const registrationState = computed(() => props.states.registration);
const loginState = computed(() => props.states.login);
const titleState = computed(() => props.states.title);
const itemsGroupState = computed(() => props.states.items);
const balanceItemsState = computed(() => props.states.balanceItems);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  depositDesignControls,
  depositContentControls,
  depositStatesControls,
  logoutDesignControls,
  logoutContentControls,
  logoutStatesControls,
  registrationDesignControls,
  registrationContentControls,
  registrationStatesControls,
  loginDesignControls,
  loginContentControls,
  loginStatesControls,
  dropdownContainerDesignControls,
  dropdownContainerContentControls,
  titleDesignControls,
  titleContentControls,
  titleStatesControls,
  itemsControls,
  itemsGroupDesignControls,
  itemsGroupContentControls,
  itemsGroupStatesControls,
  itemControls,
  balanceDesignControls,
  balanceContentControls,
  balanceItemsDesignControls,
  balanceItemsStatesControls,
  balanceItemControls,
} = useProfileDropdownWidgetConfig(
  ref(props.widget),
  widgetState,
  depositState,
  logoutState,
  registrationState,
  loginState,
  titleState,
  itemsGroupState,
  balanceItemsState,
  emit
);

const resetStates = (): void => {
  emit("reset-states");
};
</script>
