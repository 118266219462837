import { Ref } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useConfigArrayItemsControl } from "../items/useConfigArrayItemsControl";

export const useDropdownWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  titleField: Ref<IWidgetField | null>
) => {
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.display",
        valueSource: widget.value,
        options: {
          label: "Resizing",
        },
      },
      {
        componentPath: "ConfigDropdownTitle",
        valuePath: "value",
        valueSource: titleField.value,
        options: {
          label: "Dropdown container",
          fieldName: "title",
        },
      },
      {
        componentPath: "ConfigDropdownMenu",
        options: {
          label: "Dropdown menu",
          fieldName: "items",
        },
      },
      useConfigArrayItemsControl("Menu items"),
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
