<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <ComingEventsWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="coming-events-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="coming-events-widget__header-title"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <BetsListTabs
        :widget="widget"
        :states="states"
        icon="ant-design:check-circle-outlined"
      />

      <WidgetField
        :widget="widget"
        :field="fields.event_cards.field"
      >
        <ComingEventsCards
          v-if="states.event_cards === State.WITH_ACTIVE_EVENTS"
          :field="fields.event_cards.field"
          :state="states.event_cards_item"
        >
          <template #time>
            <WidgetField
              :widget="widget"
              :field="fields.event_time.field"
              :style="timeContainerInlineStyles"
            >
              <div class="coming-events-widget__time">
                <SportsBookLiveTime
                  :show-icon="fields.event_time.field.options.icon._active"
                  :field="fields.event_time.field"
                  :icon="
                    fields.event_time.field.options.icon.valueDate ??
                    'ant-design:calendar-filled'
                  "
                >
                  {{
                    fields.event_time.field.options.displayMode === "date"
                      ? "00-00-0000"
                      : "0 h 00 m"
                  }}
                </SportsBookLiveTime>

                <SportsBookLiveTime
                  v-if="fields.event_time.field.options.displayMode === 'date'"
                  :show-icon="fields.event_time.field.options.icon._activeTime"
                  :field="fields.event_time.field"
                  :icon="
                    fields.event_time.field.options.icon.valueTime ??
                    'ant-design:clock-circle-filled'
                  "
                >
                  00:00
                </SportsBookLiveTime>
              </div>
            </WidgetField>
          </template>

          <template #team>
            <WidgetField
              :widget="widget"
              :field="fields.teams_name.field"
            >
              <BasicTitleField
                :field="fields.teams_name.field"
                value="Team / Player #1 - Team / Player #2"
              />
            </WidgetField>
          </template>
        </ComingEventsCards>

        <BasicTitleField
          v-else
          :style="noEventsInlineStyle"
          :field="fields.no_events_title.field"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CellSizeType, type ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useInit } from "~~/composables/widgets/coming-events/useComingEventsInitData";
import { getWidthStyles } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useInit(props.widget);

const noEventsInlineStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.event_cards.field.options.card),
  };
});

const timeContainerInlineStyles = computed<ElementStyle>(() => {
  return {
    width: getWidthStyles(fields.value.event_time.field.options.width).width,
    flexShrink:
      fields.value.event_time.field.options.width.type === CellSizeType.HUG
        ? "initial"
        : "0",
  };
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

initFields();
</script>

<style lang="scss">
.coming-events-widget {
  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__header {
    @include flex(unset, space-between, 0px);
  }

  &__header-title {
    flex: 1;
  }

  &__time {
    @include flex(center, flex-start, 8px);
  }
}
</style>
