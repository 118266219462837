import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useBonusesTextConfig = () => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const textControls = computed(() => {
    return [
      selectedField.value?.options?.display
        ? {
            section: generateDesignSectionName("Display mode"),
            controls: [
              {
                componentPath: "common/ConfigRadioTextElements",
                valuePath: "options.display.layout",
                valueSource: selectedField.value,
                options: {
                  label: "Layout",
                  items: [
                    {
                      label: "Vertical",
                      value: "vertical",
                    },
                    {
                      label: "Horizontal",
                      value: "horizontal",
                    },
                  ],
                },
              },
              {
                componentPath: "ConfigColumnWidth",
                valuePath: "options.display.distance",
                valueSource: selectedField.value,
                options: {
                  label: "Distance between elements",
                  showIconPrefix: false,
                  showSuffix: true,
                  suffix: "px",
                },
              },
            ],
          }
        : {},
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: selectedField.value,
        options: {
          isBold: true,
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        section: generateDesignSectionName("Secondary text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.secondary.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.secondary.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.secondary.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Primary text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.primary.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.primary.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.primary.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    textControls,
  };
};
