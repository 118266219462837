import { Ref } from "vue";

import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useConfigArrayItemsControl } from "./useConfigArrayItemsControl";

export const useItemsWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  titleField: Ref<IWidgetField | null>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.display.wrap",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Use flex wrap",
              allowDisable: true,
              showOptions: false,
              size: "small",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.resizing",
            valueSource: widget.value,
            options: {
              label: "Resizing",
              items: [
                {
                  label: "Fill",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: titleField.value,
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: true,
          active: titleField.value?.options._active,
          showOptions: true,
          "onUpdate:active": (value: boolean) => {
            titleField.value!.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              titleField.value
            );
          },
        },
      },
      useConfigArrayItemsControl(),
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
