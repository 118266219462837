<template>
  <div>
    <BasicTitleField
      :field="field"
      :options="titleStyleOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
}>();

const titleStyleOptions = computed<IWidgetOptions>(() => {
  return props.stylingFields[0]?.options || {};
});
</script>
