import { CSSProperties } from "vue";

import {
  getColorStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { AlignItems, Display } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useDesignInitialStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetHelpers";

export const usePaginationStyles = (paginationOptions: any = {}) => {
  const paginationStyles = (): CSSProperties => {
    return {
      position: "absolute",
      zIndex: "9",
      right: "0",
      bottom: "0",
      left: "0",
      display: Display.FLEX,
      alignItems: AlignItems.CENTER,
      justifyContent: getFlexPosition(paginationOptions.alignment),

      ...useDesignInitialStyles(paginationOptions),
    };
  };

  const paginationDotsStyles = () => {
    const isStroke = paginationOptions.isStroke;

    const styles = {
      borderRadius: isStroke ? undefined : "100%",
      backgroundColor: paginationOptions.color
        ? getColorStyle(paginationOptions.color).color
        : undefined,
      width: paginationOptions.size
        ? getPxValueFromNumber(
            isStroke ? paginationOptions.size * 2 : paginationOptions.size
          )
        : undefined,
      height: paginationOptions.size
        ? getPxValueFromNumber(
            isStroke ? paginationOptions.size / 2 : paginationOptions.size
          )
        : undefined,
      fontSize: paginationOptions.size
        ? getPxValueFromNumber(paginationOptions.size)
        : undefined,
      margin: "6px",
    };
    return clearObject(styles);
  };

  return {
    paginationStyles,
    paginationDotsStyles,
  };
};
