import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { DESCRIPTION_INITIAL as initial } from "~~/constants/configs/card-common/initial-constants";

export const DESCRIPTION_INITIAL = (fieldDetails?: {
  options?: IWidgetOptions;
}) => {
  const initialValue = initial().options;

  if (fieldDetails?.options && Object.keys(fieldDetails.options).length) {
    return {
      options: { ...initialValue, ...fieldDetails.options },
    };
  }

  return {
    options: initialValue,
  };
};
