import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

type Args = {
  widget: IWidgetWithFields;
  states: Record<string, string>;
  stateName: string;
  noTextFieldName: string;
  customLabels?: Record<string, string>;
  except?: string[];
};

export const useNoItemsTextConfig = (args: Ref<Args>, emit: any) => {
  const fields = useWidgetFields(args.value.widget as IWidgetWithFields);

  const currState = computed<State>(() => {
    return args.value.states[args.value.stateName] as State;
  });

  const isEventsCardsContentMode = computed<boolean>(() => {
    return currState.value === State.ACTIVE_EVENTS;
  });

  const eventCardsNoContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        visible: !isEventsCardsContentMode.value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value[args.value.noTextFieldName].field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      typography({
        source: fields.value[args.value.noTextFieldName].field,
        visible: !isEventsCardsContentMode.value,
        except: args.value.except,
      }),
    ];
  });

  const stateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: currState.value,
        options: {
          items: [
            {
              label:
                args.value.customLabels?.[State.ACTIVE_EVENTS] ||
                STATE_KEY_LABELS[State.ACTIVE_EVENTS],
              value: State.ACTIVE_EVENTS,
            },
            {
              label:
                args.value.customLabels?.[State.NO_ACTIVE] ||
                STATE_KEY_LABELS[State.NO_ACTIVE],
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: args.value.stateName,
            value,
          });
        },
      },
    ];
  });

  return {
    eventCardsNoContentControls,
    stateControls,
  };
};
