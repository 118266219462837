import { useWidgetsStore } from "~~/store/widgets";
import { isSimpleValue, prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  DisplayOrientation,
  Alignment,
  State,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { DESIGN_INITIAL } from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { DESCRIPTION_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { CellSizeType, Dimension } from "~~/models/grid.interface";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, string> = {
    title: State.DEFAULT,
    button_allow_all: State.DEFAULT,
    button_allow_selection: State.DEFAULT,
    button_deny: State.DEFAULT,
    checkboxes_styling: State.SELECTED_DEFAULT,
  };

  const states = ref<Record<string, string>>({ ...DEFAULT_STATES });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, [], {
          [FormStyleFieldName.CHECKBOX]: {
            color: getDefaultFillValue("#ffffff"),
          },
        })
      );

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);

    widgetsStore.updateValue(
      useLocalizedValue().transformToLocalizedValue(
        "By continuing to browse, you agree to our use of cookies. To learn more click Cookie Policy"
      ).localizedValue,
      "value",
      fields.value.description.field,
      "value"
    );

    widgetsStore.updateValue(
      useLocalizedValue().transformToLocalizedValue(
        "This website uses cookies for analytics, personalization and advertising."
      ).localizedValue,
      "value",
      fields.value.title.field,
      "value"
    );
  };

  const initWidgetFieldsOptions = (): void => {
    /*
    Initialize widget with initial options
  */
    const initialWidgetOptions = initWidgetOptions(
      [],
      {
        displaySettings: {
          alignment: VerticalAlignment.TOP,
          layout: DisplayOrientation.HORIZONTAL,
          gap: 32,
        },
      },
      {
        fill: getDefaultFillValue("#000000"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(24),
        },
      }
    );

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#ffffff"),
        },
      }).options
    );

    const initialDescriptionOptions = prefillEmptyOptions(
      fields.value.description.field.options,
      getInitialOptions({
        data: DESCRIPTION_INITIAL(),
        exclude: ["displaySettings"],
      }).options
    );

    const initialCheckboxesContainerOptions = prefillEmptyOptions(
      fields.value.form.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["baseDisplaySettings", "size", "spacing"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(),
            displaySettings: {
              layout: DisplayOrientation.HORIZONTAL,
              gap: 32,
              alignment: Alignment.LEFT,
            },
          },
        },
      }).options
    );

    const initialButtonsContainerOptions = prefillEmptyOptions(
      fields.value.buttons_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["baseDisplaySettings", "size", "spacing"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(),
            displaySettings: {
              layout: DisplayOrientation.VERTICAL,
              gap: 24,
              alignment: Alignment.LEFT,
              width: {
                type: CellSizeType.FIXED,
                value: {
                  value: 320,
                  type: Dimension.PX,
                },
              },
              buttonsWidth: {
                type: CellSizeType.FILL,
                value: null,
              },
              buttonsAlignment: Alignment.CENTER,
            },
          },
        },
      }).options
    );

    const btnIconSettings = BUTTON_INITIAL().options.iconSettings;
    const btnTextSettings = BUTTON_INITIAL().options.buttonTextSettings;

    const initialButtoAllowAllnOptions = prefillEmptyOptions(
      fields.value.button_allow_all.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          iconSettings: {
            ...btnIconSettings,
            _active: false,
          },
        },
      }).options
    );

    const initialButtoAllowSelectionnOptions = prefillEmptyOptions(
      fields.value.button_allow_selection.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          iconSettings: {
            ...btnIconSettings,
            _active: false,
          },
          fill: getDefaultFillValue("#ffffff"),
          buttonTextSettings: {
            ...btnTextSettings,
            textColor: getDefaultFillValue("#000000"),
          },
        },
      }).options
    );

    const initialButtonDenyOptions = prefillEmptyOptions(
      fields.value.button_deny.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          iconSettings: {
            ...btnIconSettings,
            _active: false,
          },
          fill: getDefaultFillValue("#ffffff"),
          buttonTextSettings: {
            ...btnTextSettings,
            textColor: getDefaultFillValue("#000000"),
          },
        },
      }).options
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.description.field,
      initialDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.form.field,
      initialCheckboxesContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.buttons_container.field,
      initialButtonsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.button_allow_all.field,
      initialButtoAllowAllnOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.button_allow_selection.field,
      initialButtoAllowSelectionnOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.button_deny.field,
      initialButtonDenyOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  const checkboxesStylingFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      ["checkboxes_styling"].includes(field.name)
    );
  });

  return {
    states,
    DEFAULT_STATES,
    checkboxesStylingFields,

    initFields,
    initWidgetFieldsOptions,
  };
};
