import {
  Alignment,
  DisplayOrientation,
  State,
  TextPosition,
  TextStyle,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const ELEMENTS_INITIAL = () => ({
  options: {
    display: {
      position: Alignment.RIGHT,
      alignment: VerticalAlignment.TOP,
    },
    padding: {
      top: 8,
      bottom: 8,
      left: 8,
      right: 8,
    },
  },
});

export const HOVER_CONTENT_INITIAL = () => ({
  options: {
    alignment: VerticalAlignment.MIDDLE,
    gap: 12,
    position: TextPosition.TOP,
    layout: DisplayOrientation.VERTICAL,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const ICON_INITIAL = () => ({
  options: {
    iconSize: 24,
    color: {
      color: "#ffffff",
      opacity: 100,
    },
  },
});

export const TAG_INITIAL = () => ({
  value: "Promo tag",
  options: {
    cornerRadius: getDefaultCornerRadiusValue(2),
    padding: {
      top: 4,
      bottom: 4,
      left: 8,
      right: 8,
    },
    fill: {
      color: "#1677FF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#FFFFFF",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    states: {
      [State.DEFAULT]: {
        fill: {
          color: "#1677FF",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#FFFFFF",
            opacity: 100,
          },
          theme: 13,
          fontFamily: getDefaultFontFamily(),
          decoration: [TextStyle.BOLD],
        },
      },
      [State.JACKPOT]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#1677FF",
            opacity: 100,
          },
          theme: 13,
          fontFamily: getDefaultFontFamily(),
          decoration: [TextStyle.BOLD],
        },
      },
      [State.NEW]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#1677FF",
            opacity: 100,
          },
          theme: 13,
          fontFamily: getDefaultFontFamily(),
          decoration: [TextStyle.BOLD],
        },
      },
      [State.HOT]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#1677FF",
            opacity: 100,
          },
          theme: 13,
          fontFamily: getDefaultFontFamily(),
          decoration: [TextStyle.BOLD],
        },
      },
    },
  },
});
