import { IPage } from "~~/models/stores/pages-store.model";

// const SPECIAL_ENABLED_SYMBOLS = ["/", "-", ":"];

const URL_SYMBOLS_REGEXP = "(^/[a-zA-Z0-9/:-]*$)";
const NOT_TWO_SLASHES_REGEXP = "(?<!.*?/{2,}.*$)";
const NOT_COLON_SLASHES_REGEXP = "(?<!.*?:/.*$)";
const NOT_END_SYMBOLS_REGEXP = "(?<!.*(-|/|:)$)";
// TODO: Implement locale regexp
// TODO: Implement no two special symbols one by one

export const isSlugValid = (url: string): boolean => {
  const rules = [
    URL_SYMBOLS_REGEXP,
    NOT_TWO_SLASHES_REGEXP,
    NOT_COLON_SLASHES_REGEXP,
    NOT_END_SYMBOLS_REGEXP,
  ];

  return new RegExp(rules.join("")).test(url);
};

export const generateParamsFromSlug = (
  params: IPage["routeBinding"],
  slug: string
): IPage["routeBinding"] => {
  if (isSlugValid(slug) === false) {
    return params;
  }

  const steps = slug.split("/").filter((step: string) => step.startsWith(":"));

  return steps.reduce<IPage["routeBinding"]>((acc, step) => {
    acc[step] = params[step] || {
      entity_type: "",
      entity_variable: "",
    };

    return acc;
  }, {});
};
