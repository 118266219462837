<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupStatesControls"
          />
        </template>

        <template #item>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItemsWidgetConfig } from "~~/composables/widgets/items/useItemsWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  titleLinkState: State;
  itemsGroupState: State;
  widget: IWidgetWithFields;
}>();

const emit = defineEmits<{
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const titleLinkState = toRef(() => props.titleLinkState);
const itemsGroupState = toRef(() => props.itemsGroupState);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  titleControls,
  titleLinkStatesControls,
  itemsGroupDesignControls,
  itemsGroupContentControls,
  itemsGroupStatesControls,
  itemControls,
} = useItemsWidgetConfig(
  ref(props.widget),
  titleLinkState,
  itemsGroupState,
  emit
);

const { bindingParams } = useWidgetDynamicParams(
  {
    "useActiveStylesForChildRoutes": {
      label: "Use active styles for child routes",
      description: "Possible values: yes | no. Empty === yes",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const resetStates = () => {
  emit("reset-states");
};
</script>
