import { Ref } from "vue";

import { generateWidgetField } from "~~/assets/utils/widget";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { IInsertPosition } from "~~/models/grid.interface";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import type {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { SliderTransition } from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useTextSliderWidgetControls = (widget: Ref<IWidgetWithFields>) => {
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
        [
          4,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: widget.value,
            options: {
              label: "Margins",
              meta: {
                isBold: true,
              },
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const slides = computed<ICustomField[]>(
    () => widget.value.fields[0].value as ICustomField[]
  );

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigArrayItems",
        options: {
          items: slides.value,
          "onUpdate:items": (fields: IWidgetField[]) => {
            widget.value.fields[0].value = fields;
          },
          maxItemsCount: 20,
          subjectName: "slide",
          showOnlyInput: true,
          addItemHandler: () => {
            if (!slides.value?.[0]) return;
            const { type, parent_id, fields } = slides.value[0];
            const newItem = {
              ...generateWidgetField(
                FieldName.SLIDE,
                parent_id,
                type,
                "Slide",
                "Slide"
              ),
              fields: fields?.map(field =>
                generateWidgetField(
                  field.name,
                  field.parent_id,
                  field.type,
                  field.title,
                  field.default_value
                )
              ),
            };

            slides.value.push(newItem);
          },
          reorderHandler: (
            targetId: number | string,
            insertPosition: IInsertPosition
          ): void => {
            const [currFields] = reorderListElements(
              "id",
              targetId,
              insertPosition,
              slides.value
            );

            widget.value.fields[0].value = currFields;
          },
        },
      },
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigSliderColumns",
            valuePath: "options.columns",
            className: "group-control-element",
            valueSource: widget.value,
            options: {
              slidesCount: slides.value.length,
            },
          },

          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.transition",
            valueSource: widget.value,
            options: {
              label: "Transition",
              items: [
                { value: SliderTransition.ALL, label: "All" },
                { value: SliderTransition.BY_ONE, label: "By one" },
              ],
            },
          },

          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.spaceBetween",
            valueSource: widget.value,
            options: {
              label: "Space between slides",
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.slidePadding",
        valueSource: widget.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const widgetNavigationControls = computed(() => {
    return [
      {
        componentPath: "ConfigSliderTransition",
        valuePath: "options.sliderTransition",
        valueSource: widget.value,
      },
      {
        componentPath: "ConfigSliderPagination",
        valuePath: "options.sliderPagination",
        valueSource: widget.value,
      },
      {
        componentPath: "ConfigSliderArrows",
        valuePath: "options.sliderArrows",
        valueSource: widget.value,
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
    widgetNavigationControls,
  };
};
