import { CellId, ICellOptions } from "~~/models/grid.interface";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { VerticalAlignment } from "~~/models/widgets/widget-controls.model";

import {
  generateAlignItems,
  generateFillColorStyle,
  generateGap,
  generateMarginStyle,
  generatePosition,
  generateCellHeight,
  generateCellContentPosition,
} from "../helpers";

const generateCellAlignItems = (value?: string): string => {
  return generateAlignItems(value || "flex-start");
};

const widgetContainerPropertyMappings: Record<
  string,
  (value: any, important?: boolean, options?: IWidgetOptions) => string
> = {
  backgroundColor: generateFillColorStyle,
  margin: generateMarginStyle,
  alignment: generateCellAlignItems,
  spaceBetween: generateGap,
  position: generatePosition,
  height: generateCellHeight,
  cellContentSettings: generateCellContentPosition,
};

const generateDefaultWidgetContainerStyles = (
  options: IWidgetOptions,
  cellsOptions: Record<CellId, ICellOptions>
): string => {
  let cssString = "";

  if (!options.alignment) {
    cssString += "align-items: flex-start;";
  }

  /*
    If there is no specified options for parent id(because non options were applied to parent cell),
    align self to flex-start
  */
  if (options.parentId && !cellsOptions[options.parentId]) {
    cssString += "align-self: flex-start;";
  }

  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const value = options[key];
      if (value !== null && value !== undefined) {
        const mappingFunction = widgetContainerPropertyMappings[key];

        if (!mappingFunction) continue;

        cssString += mappingFunction(value, false, options);
      }
    }
  }

  return cssString;
};

export const generateWidgetContainerCssString = (
  key: CellId,
  options: ICellOptions,
  cssFileId: string,
  cellsOptions: Record<CellId, ICellOptions>
): string => {
  let cssString = "";

  const VerticalAlignmentArray = [
    VerticalAlignment.MIDDLE,
    VerticalAlignment.BOTTOM,
  ];

  if (VerticalAlignmentArray.includes(options.alignment as VerticalAlignment)) {
    cssString += `.grid-cell_${key}_${cssFileId}{align-items:stretch;}`;

    options.childIds?.forEach((childID: CellId) => {
      cssString += `.grid-cell_${childID}_${cssFileId}{justify-content:${options.alignment};}`;
    });

    cssString += `.grid-cell_${options.parentId}_${cssFileId}{justify-content:${options.alignment};}`;
  }

  cssString += `.grid-cell_${key}_${cssFileId}{${generateDefaultWidgetContainerStyles(
    options,
    cellsOptions
  )}}`;

  return cssString;
};
