import { TITLE_INITIAL as initial } from "~~/constants/configs/card-common/initial-constants";

const TITLE_STYLE_INITIAL = () => {
  return {
    ...initial(),
    title: "Title style",
  };
};

export { TITLE_STYLE_INITIAL };
