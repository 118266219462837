import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { StateController } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/composables/usePromoBetsFenixWidgetStateController";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const usePaginationConfig = (
  widget: Ref<IWidgetWithFields>,
  stateController: StateController
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const paginationDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const paginationContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.isStroke",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          shrink: true,
          customIconWidth: "calc(100% - 24px)",
          items: [
            {
              label: "Bullets",
              value: false,
              icon: "custom:bullets",
            },
            {
              label: "Strokes",
              value: true,
              icon: "custom:strokes",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        valueSource: selectedField.value,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 8,
          maxValue: 48,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          placeholder: "None",
          label: "Color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: selectedField.value,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
    ];
  });

  const paginationStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.pagination,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "pagination",
            value,
          });
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: `${stateController.getOptionsValuePath("pagination")}.size`,
        valueSource: selectedField.value,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 8,
          maxValue: 48,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${stateController.getOptionsValuePath("pagination")}.color`,
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          placeholder: "None",
          label: "Color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
    ];
  });

  return {
    paginationDesignControls,
    paginationContentControls,
    paginationStatesControls,
  };
};
