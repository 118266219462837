<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="handleChange"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #bonus_group_title>
          <ControlsList
            :widget="widget"
            :controls="bonusGroupTitleControls"
          />
        </template>

        <template #bonus_group_description>
          <ControlsList
            :widget="widget"
            :controls="bonusGroupDescriptionControls"
          />
        </template>

        <template #tabs-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #bonus_group-design>
          <ControlsList
            :widget="widget"
            :controls="bonusGroupDesignControls"
          />
        </template>

        <template #bonus_group-content>
          <ControlsList
            :widget="widget"
            :controls="bonusGroupContentControls"
          />
        </template>

        <!-- 
          Cards
         -->

        <template #cards-design>
          <ControlsList
            :widget="widget"
            :controls="cardsDesignControls"
          />
        </template>

        <template #cards-content>
          <ControlsList
            :widget="widget"
            :controls="cardsContentControls"
          />
        </template>

        <template #cards-states>
          <ControlsList
            :widget="widget"
            :controls="cardsStatesControls"
          />
        </template>

        <template #bonus_title>
          <ControlsList
            :widget="widget"
            :controls="defaultTextControls"
          />
        </template>

        <template #description>
          <ControlsList
            :widget="widget"
            :controls="defaultTextControls"
          />
        </template>

        <template #status>
          <ControlsList
            :widget="widget"
            :controls="statusControls"
          />
        </template>

        <template #dates>
          <ControlsList
            :widget="widget"
            :controls="textControls"
          />
        </template>

        <template #progress_bar>
          <ControlsList
            :widget="widget"
            :controls="progressControls"
          />
        </template>

        <template #amounts>
          <ControlsList
            :widget="widget"
            :controls="textControls"
          />
        </template>

        <template #terms_title>
          <ControlsList
            :widget="widget"
            :controls="termsDesignControls"
          />
        </template>

        <template #action_button-design>
          <ControlsList
            :widget="widget"
            :controls="actionButtonDesignControls"
          />
        </template>

        <template #action_button-content>
          <ControlsList
            :widget="widget"
            :controls="actionButtonContentControls"
          />
        </template>

        <template #action_button-states>
          <ControlsList
            :widget="widget"
            :controls="actionButtonStatesControls"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>

        <template #modal-design>
          <ControlsList
            :widget="widget"
            :controls="modalControls"
          />
        </template>

        <template #modal-content>
          <ControlsList
            :widget="widget"
            :controls="modalContentControls"
          />
        </template>

        <template #modal_title>
          <ControlsList
            :widget="widget"
            :controls="defaultTextControls"
          />
        </template>

        <template #modal_description>
          <ControlsList
            :widget="widget"
            :controls="defaultTextControls"
          />
        </template>

        <template #modal_primary_button-design>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonDesignControls"
          />
        </template>

        <template #modal_primary_button-content>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonContentControls"
          />
        </template>

        <template #modal_primary_button-states>
          <ControlsList
            :widget="widget"
            :controls="primaryButtonStatesControls"
          />
        </template>

        <template #modal_secondary_button-design>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonDesignControls"
          />
        </template>

        <template #modal_secondary_button-content>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonContentControls"
          />
        </template>

        <template #modal_secondary_button-states>
          <ControlsList
            :widget="widget"
            :controls="secondaryButtonStatesControls"
          />
        </template>

        <template #modal_close_icon-design>
          <ControlsList
            :widget="widget"
            :controls="closeButtonDesignControls"
          />
        </template>

        <template #modal_close_icon-content>
          <ControlsList
            :widget="widget"
            :controls="closeButtonContentControls"
          />
        </template>

        <template #modal_close_icon-states>
          <ControlsList
            :widget="widget"
            :controls="closeButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/bonuses/useBonusesConfig";
import { useConfig as useFenixConfig } from "~~/composables/widgets/bonuses/fenix/useBonusesFenixConfig";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
  (e: "change", event: string): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const {
  /* 
    Controls
  */
  titleControls,

  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,

  defaultTextControls,
  statusControls,
  textControls,
  progressControls,

  termsDesignControls,

  actionButtonStatesControls,
  actionButtonDesignControls,
  actionButtonContentControls,

  imageControls,

  modalControls,

  primaryButtonStatesControls,
  primaryButtonDesignControls,
  primaryButtonContentControls,

  secondaryButtonStatesControls,
  secondaryButtonDesignControls,
  secondaryButtonContentControls,

  closeButtonStatesControls,
  closeButtonDesignControls,
  closeButtonContentControls,
} = useConfig(toRef(props.widget), toRef(props.states), emit);

const {
  /* 
    Controls
  */
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  bonusGroupDesignControls,
  bonusGroupContentControls,

  bonusGroupTitleControls,

  bonusGroupDescriptionControls,

  cardsDesignControls,
  cardsContentControls,
  cardsStatesControls,

  modalContentControls,
} = useFenixConfig(toRef(props.widget), toRef(props.states), emit);

const resetStates = () => {
  emit("reset-states");
};

const handleChange = (item: string): void => {
  emit("change", item);
  resetStates();
};
</script>
