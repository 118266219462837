import { State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useRacingSportsButtonTabsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetsStore = useWidgetSettingsStore();

  const { selectedField } = storeToRefs(widgetsStore);

  const tabsContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const tabsContainerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigButtonDisplaySettings",
            valuePath: "options.displaySettings",
            valueSource: selectedField.value,
            options: {
              showAlignmentOnHugOnly: true,
              showTitle: false,
              isBold: true,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displaySettings.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between buttons",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "tabs", label: "Buttons" }],
        },
      },
    ];
  });

  /* 
    Tabs
  */
  const tabsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const tabsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.streamIcon",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Stream icon",
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.statsIcon",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Statistics icon",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSettings.iconSize",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const statesValuePath = computed<string>(() => {
    if (states.value.tabs === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.tabs}`;
  });

  const tabsStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    tabsContainerDesignControls,
    tabsContainerContentControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
  };
};
