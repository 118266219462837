import { generateWidgetField } from "~~/assets/utils/widget";
import { ROW_COLUMN_ALIGNMENT_MAP } from "~~/constants/widget-config";
import {
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { FieldType } from "~~/models/widgets/widget.core/field-types.enum";
import {
  IWidgetField,
  ICustomField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useWidgetWithArrayField = (
  widget: IWidgetWithFields,
  titleFieldName = FieldName.TITLE,
  itemsFieldName = FieldName.ITEMS
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const titleField = computed<IWidgetField | null>(() => {
    return widget.fields.find(field => field.name === titleFieldName) || null;
  });

  const arrayField = computed<IWidgetField | null>(() => {
    return widget.fields.find(field => field.name === itemsFieldName) || null;
  });

  const itemsGroupFieldExists = (
    parentField: ICustomField[] | null
  ): boolean => {
    if (!parentField) {
      return false;
    }

    if (!Array.isArray(parentField)) {
      return false;
    }

    const field = parentField.find(item => item.name === FieldName.ITEMS_GROUP);

    return !!field;
  };

  const initItemsGroup = () => {
    const itemsGroupExists = itemsGroupFieldExists(
      arrayField.value?.value as ICustomField[] | null
    );

    if (itemsGroupExists) {
      return;
    }

    arrayField.value!.value = [
      {
        ...generateWidgetField(FieldName.ITEMS_GROUP, widget.id),
        fields: [
          generateWidgetField(
            FieldName.ITEM,
            widget.id,
            FieldType.TEXT_INPUT,
            "Item",
            "Item"
          ),
        ],
        options: {},
      },
    ] as ICustomField[];
  };

  const itemsGroup = computed<ICustomField | null>(() => {
    if (!arrayField.value?.value) {
      return null;
    }

    if (!Array.isArray(arrayField.value.value)) {
      return null;
    }

    return arrayField.value.value[0] || null;
  });

  const itemsFields = computed<IWidgetField[]>(() => {
    return itemsGroup.value?.fields || [];
  });

  const isItemsGroupSelected = computed<boolean>(
    () =>
      isWidgetSelected.value &&
      selectedField.value?.name === FieldName.ITEMS_GROUP
  );

  const isTitleFieldSelected = computed<boolean>(
    () => selectedField.value?.id === titleField.value?.id
  );

  const isWidgetSelected = computed<boolean>(
    () => widget.id === selectedWidget.value?.id
  );

  const displaySettings = computed<IWidgetOptions>(
    () =>
      widget.options.display || {
        alignment: Alignment.LEFT,
        resizing: ResizingType.HUG,
      }
  );

  const wrapperStyle = computed<Record<string, string | undefined>>(() => {
    if (displaySettings.value.resizing === ResizingType.HUG) {
      return {
        alignItems:
          ROW_COLUMN_ALIGNMENT_MAP[displaySettings.value.alignment as string],
      };
    }

    return {};
  });

  const menuItemsOptions = computed<IWidgetOptions>(() => ({
    ...itemsGroup.value?.options,
    display: displaySettings.value,
  }));

  return {
    titleField,
    arrayField,
    itemsGroup,
    itemsFields,
    isItemsGroupSelected,
    isWidgetSelected,
    displaySettings,
    wrapperStyle,
    menuItemsOptions,
    selectedField,
    isTitleFieldSelected,
    initItemsGroup,
  };
};
