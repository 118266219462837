<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    class="container-widget__container"
    :class="`container-widget__container--${widget.options.display.mode}`"
  >
    <template
      v-if="widget.options.display.mode === 'carousel'"
      #root
    >
      <WidgetField
        v-if="fields.pagination.field.options._active"
        :widget="widget"
        :field="fields.pagination.field"
        class="container-widget__pagination-container"
      >
        <ContainerPagination
          :widget="widget"
          :field="fields.pagination.field"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.arrows.field.options._active"
        :widget="widget"
        :field="fields.arrows.field"
        class="container-widget__arrow-container"
      >
        <ContainerArrow
          :widget="widget"
          :field="fields.arrows.field"
          :state="states.arrows"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.arrows.field.options._active"
        :widget="widget"
        :field="fields.arrows.field"
        class="container-widget__arrow-container container-widget__arrow-container--right"
      >
        <ContainerArrow
          :widget="widget"
          :field="fields.arrows.field"
          :state="states.arrows"
        />
      </WidgetField>
    </template>

    <ContainerWidgetSettings
      :widget="widget"
      :states="states"
      @reset-states="resetStates"
      @update-state="handleStateUpdate"
    />

    <div
      :style="styleVars"
      class="container-widget"
    >
      <slot></slot>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/container-widget/useContainerWidgetInit";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getBackgroundColorStyle,
  getFlexAlign,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useStates } from "~~/composables/widgets/common/useStates";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { init, states, DEFAULT_STATES } = useInit(toRef(props.widget));

init();

const flexStyle = computed<ElementStyle>(() => {
  const options = props.widget.options.display;

  if (options.layout === DisplayOrientation.VERTICAL) {
    return {
      flexDirection: "column",
      gap: getPxValueFromNumber(options.horizontalGap),
      flexWrap: "nowrap",
    };
  }

  if (options.layout === DisplayOrientation.WRAP) {
    return {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: getPxValueFromNumber(options.horizontalGap),
    };
  }

  return {
    flexDirection: "row",
    gap: getPxValueFromNumber(options.horizontalGap),
    flexWrap: "nowrap",
  };
});

const containerStyles = computed<ElementStyle>(() => {
  const options = props.widget.options.display;

  return {
    ...flexStyle.value,
    ...getFlexAlign(
      props.widget.options.display.alignment,
      props.widget.options.display.layout
    ),
    overflowX: options.horizontalOverflow,
    overflowY: options.verticalOverflow,
  };
});

const styleVars = computed<ElementStyle>(() => {
  const background = getBackgroundColorStyle(props.widget.options.fill);

  return getStylesAsVars(
    {
      ...containerStyles.value,
      containerFill: background.backgroundColor,
    },
    "container"
  );
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});
</script>

<style lang="scss">
.container-widget {
  display: flex;
  height: 100%;

  &__arrow-container {
    align-self: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 6;

    &--right {
      right: 0;
      left: initial;

      .container-widget__arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__pagination-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 6;
  }

  &__container {
    .base-widget__content-container {
      position: relative;
    }

    &--carousel {
      // .container-widget {
      //   max-width: max-content;
      //   width: max-content;
      // }

      .base-widget__content {
        overflow-x: auto !important;
      }
    }
  }
}
</style>
