<template>
  <FormTextInputField
    ref="formTextInputField"
    :widget="widget"
    :field="field"
    :styling-fields="stylingFields"
    :value="value"
    :states="states"
    class="form-date-field position-relative"
  >
    <template #suffix>
      <CommonIcon
        :name="icon"
        :style="iconColorStyle"
      />
    </template>
    <a-calendar
      v-if="formTextInputField?.isCalendarVisible"
      :fullscreen="false"
      :style="calendarStyle"
      inert
    >
      <template #headerRender="{ value: current }">
        <div class="form-date-field__header">
          <CommonIcon
            name="ant-design:left-outlined"
            class="form-date-field__left-icon"
          />

          <span class="form-date-field__current">
            {{ month }}
          </span>

          <span class="form-date-field__current">{{ current.year() }}</span>

          <CommonIcon
            name="ant-design:right-outlined"
            class="form-date-field__right-icon"
          />
        </div>
      </template>
    </a-calendar>
  </FormTextInputField>
</template>

<script lang="ts" setup>
import { FunctionalComponent } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { findField, getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import {
  getSpacing,
  getCornerRadiusStyle,
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getFontSizeStyle,
  getFontFamilyStyle,
  getColorFromHex,
  getColorStyle,
} from "~~/assets/utils/widget-settings";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { iconMapping } from "~~/constants/icons-mapping";
import { getPxValueFromNumber } from "~~/assets/utils";

import FormTextInputField from "./FormTextInputField.vue";

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const formTextInputField = ref<InstanceType<typeof FormTextInputField> | null>(
  null
);

const triggerFieldExample = (fieldStylingName: FormStyleFieldName): void => {
  formTextInputField.value?.triggerFieldExample(fieldStylingName);
};

const hideFieldExample = (fieldStylingName: FormStyleFieldName): void => {
  formTextInputField.value?.hideFieldExample(fieldStylingName);
};

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});

const month = new Date().toLocaleDateString("en", { month: "long" });

const icon = computed<FunctionalComponent | string | null>(() => {
  return iconMapping[datePickerField.value?.options.icon];
});

const inputField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.FIELD, props.stylingFields);
});

const iconColorStyle = computed<ElementStyle>(() => {
  const options =
    fieldState.value === State.DEFAULT
      ? inputField.value?.options
      : inputField.value?.options.states[fieldState.value];

  return {
    ...getColorStyle(options.iconRight?.color),
    "margin-left": getPxValueFromNumber(
      inputField.value?.options?.iconRight?.gap || 4
    ),
  };
});

const datePickerField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.DATEPICKER, props.stylingFields);
});

const calendarField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.CALENDAR, props.stylingFields);
});

const calendarPaddingStyle = computed<ElementStyle>(() => {
  if (!calendarField.value) {
    return {};
  }

  return {
    ...getSpacing(calendarField.value.options.padding, SpacingKeyName.PADDING),
  };
});

const calendarStyle = computed<ElementStyle>(() => {
  if (!calendarField.value) {
    return {};
  }

  return {
    overflow: "hidden",
    ...calendarPaddingStyle.value,
    ...getCornerRadiusStyle(calendarField.value.options.cornerRadius),
    ...getBackgroundColorStyle(calendarField.value.options.fill),
    ...getBorderStyle(calendarField.value.options.border),
    ...getBoxShadowStyle(calendarField.value.options.shadow),
    ...getCornerRadiusStyle(calendarField.value.options.cornerRadius),
    ...calendarStyleAsVars.value,
  };
});

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.CALENDAR];
});

const fieldState = computed<string>(() => {
  return props.states[FormStyleFieldName.FIELD];
});

const selectedStateOptions = computed<IWidgetOptions>(() => {
  return state.value === State.SELECTED_HOVER
    ? calendarField.value?.options.states[State.SELECTED_HOVER]
    : calendarField.value?.options.states[State.SELECTED_DEFAULT];
});

const defaultStateOptions = computed<IWidgetOptions>(() => {
  return state.value === State.HOVER
    ? calendarField.value?.options.states[State.HOVER]
    : calendarField.value?.options;
});

const inactiveStateOptions = computed<IWidgetOptions>(() => {
  return state.value === State.DISABLED
    ? calendarField.value?.options.states[State.DISABLED]
    : calendarField.value?.options.states[State.INACTIVE];
});

const calendarStyleAsVars = computed<ElementStyle>(() => {
  if (!calendarField.value) {
    return {};
  }

  const styles = {
    ...getCornerRadiusStyle(calendarField.value.options.contentCornerRadius),
    ...getFontSizeStyle(calendarField.value.options.theme),
    ...getFontFamilyStyle(calendarField.value.options.fontFamily),
  };

  const defaultFillColor =
    getColorFromHex(defaultStateOptions.value.contentFill) || "";
  const defaultColor = getColorFromHex(defaultStateOptions.value.color) || "";
  const weekDaysColor =
    getColorFromHex(calendarField.value?.options.weekDaysColor) || "";
  const defaultBorderStyle = getBorderStyle(
    defaultStateOptions.value.contentBorder
  );

  const dividerBorderColor =
    getColorFromHex(calendarField.value.options.border.fill) || "";
  const dividerBorderWidth =
    getPxValueFromNumber(calendarField.value.options.border.width) || "";
  const dividerBorderStyle = calendarField.value.options.border.style || "";

  const selectedFillColor =
    getColorFromHex(selectedStateOptions.value.contentFill) || "";
  const selectedColor = getColorFromHex(selectedStateOptions.value.color) || "";
  const selectedBorderStyle = getBorderStyle(
    selectedStateOptions.value.contentBorder
  );

  const inactiveFillColor =
    getColorFromHex(inactiveStateOptions.value.contentFill) || "";
  const inactiveColor = getColorFromHex(inactiveStateOptions.value.color) || "";
  const inactiveBorderStyle = getBorderStyle(
    inactiveStateOptions.value.contentBorder
  );

  return getStylesAsVars({
    ...styles,
    defaultFillColor,
    defaultColor,
    weekDaysColor,
    dividerBorderColor,
    dividerBorderWidth,
    dividerBorderStyle,
    defaultBorderColor: defaultBorderStyle.borderColor || "",
    defaultBorderWidth: defaultBorderStyle.borderWidth || "",
    defaultBorderStyle: defaultBorderStyle.borderStyle || "",
    selectedFillColor,
    selectedColor,
    selectedBorderColor: selectedBorderStyle.borderColor || "",
    selectedBorderWidth: selectedBorderStyle.borderWidth || "",
    selectedBorderStyle: selectedBorderStyle.borderStyle || "",
    inactiveFillColor,
    inactiveColor,
    inactiveBorderColor: inactiveBorderStyle.borderColor || "",
    inactiveBorderWidth: inactiveBorderStyle.borderWidth || "",
    inactiveBorderStyle: inactiveBorderStyle.borderStyle || "",
  });
});
</script>

<style lang="scss">
.form-date-field {
  &__header {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    color: var(--defaultColor);
    position: relative;
    padding-bottom: 12px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -100px;
      right: -100px;
      border-bottom: var(--dividerBorderWidth) var(--dividerBorderStyle)
        var(--dividerBorderColor);
    }
  }

  &__left-icon {
    margin-right: auto;
  }

  &__right-icon {
    margin-left: auto;
  }

  &__current {
    display: inline-block;
    padding: 6px 12px;

    border-radius: var(--borderRadius) !important;
    background-color: var(--defaultFillColor);
    border-color: var(--defaultBorderColor);
    border-width: var(--defaultBorderWidth);
    border-style: var(--defaultBorderStyle);
  }

  .ant-picker-calendar {
    position: absolute;
    z-index: 2;
  }

  .ant-picker-panel {
    background: inherit;
    border-top: none;
  }

  .ant-picker-body {
    padding-top: $space-m !important;
    padding-bottom: 0 !important;
  }

  .ant-picker-cell {
    font-size: var(--fontSize);
    font-family: var(--fontFamily);

    .ant-picker-cell-inner {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      border-radius: var(--borderRadius) !important;

      color: var(--inactiveColor);
      background-color: var(--inactiveFillColor);
      border-color: var(--inactiveBorderColor);
      border-width: var(--inactiveBorderWidth);
      border-style: var(--inactiveBorderStyle);
    }

    &-in-view {
      .ant-picker-cell-inner {
        color: var(--defaultColor);
        background-color: var(--defaultFillColor);
        border-color: var(--defaultBorderColor);
        border-width: var(--defaultBorderWidth);
        border-style: var(--defaultBorderStyle);
      }
    }

    &-selected {
      .ant-picker-cell-inner {
        color: var(--selectedColor);
        background-color: var(--selectedFillColor);
        border-color: var(--selectedBorderColor);
        border-width: var(--selectedBorderWidth);
        border-style: var(--selectedBorderStyle);

        &::before {
          content: none;
        }
      }
    }
  }

  th {
    font-weight: bold;
    color: var(--weekDaysColor) !important;
  }
}
</style>
