import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateFontSize, generateTextColor } from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { generateStringDefault } from "../utils/pipe-helper-functions";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const widgetStyles = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: "column",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateAccountNumberStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const containerStyles = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: options.icon.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: containerStyles,
    });

    const iconCssStringContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconCssStringContent,
    });

    return cssString;
  };

export const generateUserIdWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}_content`);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.account_number.options.icon._cssClass = generateClassName(
    "account_number_icon"
  );

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStringDefault(prefilled.username),
    generateStringDefault(prefilled.account_number),
    generateAccountNumberStyles(prefilled.account_number)
  )("");
};
