import { useLocalizedValue } from "~~/composables/useLocalizedValue";

import type { IWidgetMigration } from "../runWidgetMigrations";

enum V7_WIDGET_FIELD_TYPES {
  TEXT_ELEMENT = "TextInputField",
  IMAGE_ELEMENT = "FileUploadField",
  ARRAY_ELEMENT = "ArrayField",
  FORM_ELEMENT = "FormField",
  TEXT_AREA_ELEMENT = "TextAreaField",
  TEXT_EDITOR_ELEMENT = "TextEditorField",
  DROPDOWN_ELEMENT = "DropdownField",
  ITEMS_ELEMENT = "ItemsField",
  BUTTON_LINK_ELEMENT = "ButtonLinkField",
}

const WIDGET_VERSION = 7;

const v7WidgetMigration: IWidgetMigration = (widget: any) => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  const { transformToLocalizedValue } = useLocalizedValue();

  Object.values(widget.content).forEach((field: any) => {
    // Localize values for widget with that have text input for .value
    if (
      [
        V7_WIDGET_FIELD_TYPES.IMAGE_ELEMENT,
        V7_WIDGET_FIELD_TYPES.TEXT_ELEMENT,
        V7_WIDGET_FIELD_TYPES.TEXT_EDITOR_ELEMENT,
        V7_WIDGET_FIELD_TYPES.DROPDOWN_ELEMENT,
        V7_WIDGET_FIELD_TYPES.TEXT_AREA_ELEMENT,
        V7_WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
      ].includes(field.type)
    ) {
      const { localizedValue, localized } = transformToLocalizedValue(
        field.value
      );
      field.value = localized ? localizedValue : field.value;

      if (field.options.link) {
        field.options.link.value = transformToLocalizedValue(
          field.options.link.value
        ).localizedValue;

        if (field.options.link.authorizeValue) {
          field.options.link.authorizeValue = transformToLocalizedValue(
            field.options.link.authorizeValue
          ).localizedValue;
        }
      }
    }
    // Localize array items
    else if (
      [
        V7_WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
        V7_WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
      ].includes(field.type)
    ) {
      const itemsFieldValue =
        Array.isArray(field.value) && field.value[0]?.fields;
      if (Array.isArray(itemsFieldValue)) {
        itemsFieldValue.forEach((item: any) => {
          const { localizedValue, localized } = transformToLocalizedValue(
            item.value
          );
          item.value = localized ? localizedValue : item.value;
        });
      }
    }
    // Localize form fields
    else if ([V7_WIDGET_FIELD_TYPES.FORM_ELEMENT].includes(field.type)) {
      Object.values(field?.value?.fields || {}).forEach((formField: any) => {
        // Localize form field items
        if (Array.isArray(formField.options.items)) {
          formField.options.items.forEach((formFieldItem: any) => {
            const { localizedValue, localized } = transformToLocalizedValue(
              formFieldItem.label
            );

            if (localized) {
              formFieldItem.label = localizedValue;
            }
          });
        }

        // Localize form field label
        if ("label" in formField.options) {
          const label = transformToLocalizedValue(
            formField.options.label.value
          );
          if (label.localized) {
            formField.options.label.value = label.localizedValue;
          }
        }

        // Localize form field placeholder

        if ("placeholder" in formField.options) {
          const placeholder = transformToLocalizedValue(
            formField.options.placeholder.value
          );

          if (placeholder.localized) {
            formField.options.placeholder.value = placeholder.localizedValue;
          }
        }

        // Localize form field tooltip
        if ("tooltip" in formField.options) {
          const tooltip = transformToLocalizedValue(
            formField.options.tooltip.value
          );
          if (tooltip.localized) {
            formField.options.tooltip.value = tooltip.localizedValue;
          }
        }
      });
    }
  });

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v7WidgetMigration;
