<template>
  <div class="form-fail-screen w-100">
    <WidgetField
      v-if="fields.fail_image.active"
      :widget="widget"
      :field="fields.fail_image.field"
      class="basic-card-widget__field w-100"
    >
      <BasicImageField :field="fields.fail_image.field" />
    </WidgetField>

    <WidgetField
      v-if="fields.fail_title.active"
      :widget="widget"
      :field="fields.fail_title.field"
      class="basic-card-widget__field"
    >
      <BasicTitleField
        :field="fields.fail_title.field"
        :state="titleLinkState"
      />
    </WidgetField>

    <WidgetField
      v-if="fields.fail_description_1.active"
      :widget="widget"
      :field="fields.fail_description_1.field"
      class="basic-card-widget__field"
    >
      <FormDescriptionField
        ref="descriptionField"
        :field="fields.fail_description_1.field"
        :widget="widget"
      />
    </WidgetField>

    <WidgetField
      v-if="fields.fail_button.active"
      :widget="widget"
      :field="fields.fail_button.field"
      class="basic-card-widget__field"
    >
      <BasicButtonField
        :field="fields.fail_button.field"
        :current-state="buttonState"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  buttonState: State;
  titleLinkState: State;
}>();

const fields = useWidgetFields(props.widget);
</script>
