import { State } from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const IMAGE_INITIAL = () => ({
  value: "",
  options: {
    _active: true,
    _seo: {
      title: "",
      alt: "",
    },
    _isImagePreload: false,
    _useLazyLoad: true,
    alignment: "left",
    containerSize: {
      size: 115,
      alignment: "center",
      resizing: "fit",
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    theme: 24,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: "left",
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
      states: {
        [State.HOVER]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
        [State.ACTIVE]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
      },
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
});

export const DESCRIPTION_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    displaySettings: 1,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const CLOSE_ICON_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    color: {
      color: "#000000",
      opacity: 100,
    },
    iconSize: 16,
  },
});

export const BUTTONS_GROUP_INITIAL = () => ({
  value: "Buttons group",
  options: {
    _active: true,
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    buttonDisplaySettings: {
      resizing: "fill",
      alignment: "center",
    },
    layout: "horizontal",
    distanceBetweenItems: 8,
    alignment: "left",
  },
});

export const BUTTON_INITIAL = () => ({
  value: "Button",
  options: {
    _active: true,
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    fill: {
      color: "#1677FF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    iconSettings: {
      default_value: "ant-design:arrow-right-outlined",
      textColor: {
        color: "#ffffff",
        opacity: 100,
      },
      iconSize: 16,
      position: "right",
      gap: 5,
      _active: true,
    },
    buttonDisplaySettings: {
      resizing: "fill",
      alignment: "center",
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#ffffff",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    states: {
      [State.HOVER]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: null,
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          default_value: "ant-design:arrow-right-outlined",
          textColor: {
            color: "#000000",
            opacity: 100,
          },
          iconSize: 16,
          position: "right",
          gap: 5,
          _active: true,
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: null,
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          default_value: "ant-design:arrow-right-outlined",
          textColor: {
            color: "#000000",
            opacity: 100,
          },
          iconSize: 16,
          position: "right",
          gap: 5,
          _active: true,
        },
      },
      [State.DISABLED]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: null,
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          default_value: "ant-design:arrow-right-outlined",
          textColor: {
            color: "#000000",
            opacity: 100,
          },
          iconSize: 16,
          position: "right",
          gap: 5,
          _active: true,
        },
      },
    },
  },
});
