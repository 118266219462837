<template>
  <div class="common-select">
    <a-select
      :value="modelValue"
      :placeholder="placeholder"
      :options="options"
      :size="size"
      class="common-select__element"
      :get-popup-container="getPopupContainer"
      @change="handleChange"
    ></a-select>
  </div>
</template>

<script lang="ts" setup>
import type { SelectProps } from "ant-design-vue";

withDefaults(
  defineProps<{
    modelValue: string | number | null | undefined;
    options: SelectProps["options"];
    size?: string;
    placeholder?: string;
  }>(),
  {
    size: "small",
    placeholder: "Select",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const handleChange = (value: string) => {
  emit("update:modelValue", value);
};

const getPopupContainer = (trigger: HTMLElement): HTMLElement | null => {
  return trigger.parentElement;
};
</script>

<style lang="scss">
.common-select {
  &__element {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }
}
</style>
