import {
  DEFAULT_ADAPTIVE_COL_WIDTH,
  MIN_FIXED_COL_WIDTH,
} from "~~/constants/width-validations";
import { ICell, Sizing, WidthParams } from "~~/models/grid.interface";

import { getEqualFraction } from "..";

export const autoSetColumnsWidth = (cell: ICell): void => {
  const widthParams = cell.children.map(child => ({
    width: child.settings.width ?? DEFAULT_ADAPTIVE_COL_WIDTH, // fixed cells always have width params; adaptive should be 100% width if other not specified
    sizing: child.settings.sizing,
  }));
  const normalizedWidthParams = recalculateCells(
    widthParams,
    cell.settings.sizing,
    cell.settings.width
  );

  cell.children.forEach((child, ind) => {
    const { width, sizing } = normalizedWidthParams[ind];
    child.settings.width = Number(width);
    child.settings.sizing = sizing;
  });
};

export const getFixedCellsCount = (widthParams: WidthParams[]): number => {
  return widthParams.filter(child => child.sizing === Sizing.FIXED).length;
};

export const getFixedCellsWidth = (widthParams: WidthParams[]): number => {
  return widthParams.reduce((acc, child) => {
    if (child.sizing === Sizing.FIXED) {
      acc += Number(child.width) ?? 0;
    }
    return acc;
  }, 0);
};

export const getAdaptiveCellsCount = (widthParams: WidthParams[]): number => {
  return widthParams.filter(child => child.sizing === Sizing.ADAPTIVE).length;
};

export const getAdaptiveCellsWidth = (widthParams: WidthParams[]): number => {
  return widthParams.reduce((acc, child) => {
    if (child.sizing === Sizing.ADAPTIVE) {
      acc += Number(child.width) ?? 0;
    }
    return acc;
  }, 0);
};

const setAdaptiveChildren = (widthParams: WidthParams[]): WidthParams[] => {
  const adaptiveCellsCount = getAdaptiveCellsCount(widthParams);
  const adaptiveCellWidth = getEqualFraction(adaptiveCellsCount);
  widthParams.forEach(cell => {
    if (cell.sizing === Sizing.ADAPTIVE) {
      cell.width = adaptiveCellWidth;
    }
  });
  return widthParams;
};

export const recalculateCells = (
  widthParams: WidthParams[],
  parentSizing: Sizing,
  parentWidth?: number
): WidthParams[] => {
  const childCount = widthParams.length;

  const currentFixedCellCount = getFixedCellsCount(widthParams);

  /* NOTE: one-col nested block gets width settings from its parent */
  if (widthParams.length === 1) {
    return [
      {
        width:
          parentSizing === Sizing.FIXED && parentWidth
            ? parentWidth
            : DEFAULT_ADAPTIVE_COL_WIDTH,
        sizing: parentSizing,
      },
    ];
  }
  /* NOTE: there should be at least one fixed child in a fixed container */
  if (parentSizing === Sizing.FIXED && currentFixedCellCount === 0) {
    const firstCol = widthParams[0];
    firstCol.sizing = Sizing.FIXED;
    firstCol.width = MIN_FIXED_COL_WIDTH;
    return setAdaptiveChildren(widthParams);
  }

  if (parentSizing === Sizing.ADAPTIVE) {
    /* NOTE: if all columns in ADAPTIVE parent are fixed, need to set the last col as adaptive */
    if (currentFixedCellCount === childCount) {
      const lastCol = widthParams[childCount - 1];
      lastCol.sizing = Sizing.ADAPTIVE;
      lastCol.width = DEFAULT_ADAPTIVE_COL_WIDTH;
      return widthParams;
    }
  }
  /* NOTE: get width of all fixed cells and check whether it can be distributed evenly */

  const totalFixedCellsWidth = getFixedCellsWidth(widthParams);

  /* NOTE: if no apaptive columns, the sum of fixed cells should be equal to parent */
  if (
    parentSizing === Sizing.FIXED &&
    currentFixedCellCount === childCount &&
    parentWidth &&
    totalFixedCellsWidth !== parentWidth
  ) {
    const childWidth = getEqualFraction(childCount, parentWidth);
    widthParams.forEach(child => {
      child.width = childWidth;
    });
    return widthParams;
  }
  /* NOTE: in the rest of cases preserve columns width */
  const defaultAdaptiveWidth = getEqualFraction(
    childCount - currentFixedCellCount
  );
  let defaultFixedWidth = 0;

  if (
    parentSizing === Sizing.FIXED &&
    parentWidth &&
    totalFixedCellsWidth > parentWidth
  ) {
    defaultFixedWidth = getEqualFraction(currentFixedCellCount, parentWidth);
  }

  /* If there are adaptive cells, recalculate their width */
  const totalAdaptiveCellWidth = getAdaptiveCellsWidth(widthParams);
  const shouldRecalculateAdaptiveCells =
    totalAdaptiveCellWidth !== DEFAULT_ADAPTIVE_COL_WIDTH;

  if (currentFixedCellCount < widthParams.length) {
    widthParams.forEach(child => {
      if (child.sizing === Sizing.FIXED) {
        let fixedChildWidth = child.width;
        /* NOTE: if FIXED child width is equal to parent, it means it was the only column
        but since user has changed columns count, we need to set a minimum default width;
        otherwise, preserve original width.
        */
        if (fixedChildWidth === parentWidth) {
          fixedChildWidth = MIN_FIXED_COL_WIDTH;
        }
        child.width =
          defaultFixedWidth > 0 ? defaultFixedWidth : fixedChildWidth;
      } else {
        child.width = shouldRecalculateAdaptiveCells
          ? defaultAdaptiveWidth
          : child.width;
      }
    });
  }
  return widthParams;
};

/**
 * Calculate cell width on drag-n-drop.
 * Adaptive children should be 100% width; fixed should get the parent width.
 * @param parentSizing
 * @param parentWidth
 * @returns width
 */
export function getDefaultContainerWidth(
  parentSizing: Sizing,
  parentWidth?: number
): number {
  return parentSizing === Sizing.ADAPTIVE
    ? DEFAULT_ADAPTIVE_COL_WIDTH
    : parentWidth ?? MIN_FIXED_COL_WIDTH;
}
