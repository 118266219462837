<template>
  <div>
    <section
      v-if="currentWidgetsLayout.length"
      :id="WIDGETS_GRID_ID"
      class="widgets-grid"
      :class="[`widgets-grid--${activeScreen}`]"
    >
      <div
        v-for="cell in currentWidgetsLayout"
        :key="cell.cellId"
        class="widgets-grid__row"
      >
        <WidgetCell
          :id="`cellId_${cell.cellId}`"
          :cell="cell"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { useProject } from "~~/composables/useProject";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const { currentWidgetsLayout } = storeToRefs(useGridConfig());
const { activeScreen } = storeToRefs(useUiStore());
const widgetSettingsStore = useWidgetSettingsStore();
const { useHotkeys } = useProject();

const toggleTagWidgets = (): void => {
  widgetSettingsStore.toggleWidgetsTagMode();
};

useHotkeys({
  "ctrl+shift+k": toggleTagWidgets,
  "cmd+shift+k": toggleTagWidgets,
});
</script>

<style lang="scss">
$border-params: 2px dashed $c-grey-15;
$padding: 24px;

.widgets-grid {
  margin: 0 auto;
  border-top: $border-params;
  border-left: $border-params;
  border-right: $border-params;
  min-height: calc(100vh - 200px);

  &--mob {
    max-width: calc($sm-larger + $padding);
  }

  &--tab {
    max-width: calc($md-larger + $padding);
  }

  &--desktop {
    min-width: calc($lg + $padding);
  }

  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 2px dashed $c-grey-15;
  }
}
</style>
