import { findCell } from "~~/assets/utils/grid/grid-tree";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ICell } from "~~/models/grid.interface";
import { WidgetDefaultSettingsDropdownItem } from "~~/models/widgets/widget.core/widget.model";

import { findField } from "../widget/form";

export const getWidgetCell = ({
  widget,
  selectedWidget,
  selectedCell,
  widgetsLayout,
}: {
  widget: IWidgetWithFields | null;
  selectedWidget: IWidgetWithFields | null;
  selectedCell: ICell | null;
  widgetsLayout: ICell[];
}): ICell | null => {
  if (!widget) {
    return null;
  }

  if (widget.id === selectedWidget?.id) {
    return selectedCell;
  }

  return findCell(widgetsLayout, widget.cell_id!);
};

export enum HistoryItemType {
  WIDGET = "widget",
  FIELD = "field",
  CELL = "cell",
}

export type HistoryItem = {
  value: string;
  type: HistoryItemType;
  customValue?: string;
};

export const WIDGET_CELL_SETTINGS_ITEMS: string[] = [
  WidgetDefaultSettingsDropdownItem.CELL_SETTINGS,
  WidgetDefaultSettingsDropdownItem.CHILD_SETTINGS,
  WidgetDefaultSettingsDropdownItem.PARENT_SETTINGS,
];

export const compareHistoryItems = (
  a: HistoryItem,
  b?: HistoryItem
): boolean => {
  if (a.value !== b?.value) {
    return false;
  }

  if (a.customValue !== b?.customValue) {
    return false;
  }

  if (a.type !== b?.type) {
    return false;
  }

  return true;
};

export const getHistoryItemType = (name: string): HistoryItemType => {
  if (WIDGET_CELL_SETTINGS_ITEMS.includes(name)) {
    return HistoryItemType.CELL;
  }

  if (name === WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS) {
    return HistoryItemType.WIDGET;
  }

  return HistoryItemType.FIELD;
};

type EntityProps = {
  widget: IWidgetWithFields;
  cell: ICell;
  prevHistoryItem: HistoryItem;
};

export const getPreviousEntity = ({
  widget,
  cell,
  prevHistoryItem,
}: EntityProps): {
  cell: ICell | null;
  widget: IWidgetWithFields;
  field: IWidgetField | null;
} => {
  if (prevHistoryItem.type === HistoryItemType.WIDGET) {
    return {
      cell: null,
      widget: widget,
      field: null,
    };
  }

  if (prevHistoryItem.type === HistoryItemType.CELL) {
    return {
      cell: cell,
      widget: widget,
      field: null,
    };
  }

  const field =
    findField(prevHistoryItem.value as string, widget.fields) || null;

  return {
    cell: null,
    widget: widget,
    field: field,
  };
};
