import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { generateTabsCssString } from "~~/assets/utils/widget-css/widgets/betslist-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateFlex,
  generateGrid,
  generateMarginStyle,
  generatePaddingStyle,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";

import {
  generateWidgetStyles,
  generateListStartTimeCssString,
} from "./sportsbook-widget-css";

export const generateEventCardsCssString =
  (eventCards: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventCards;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.columns}, minmax(0, 1fr))`,
      rowGap: options.distanceBetweenCards,
      columnGap: options.distanceBetweenCards,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        widgetPositionCssContent +
        generateMarginStyle(eventCards.options.card.spacing.margin) +
        generateDefaultStyles(eventCards.options),
    });

    const itemWidth = "width: 100%;";

    const flexString = generateFlex({
      flex: "flex",
      wrap: "wrap",
      align: "center",
      justify: "left",
    });

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      content:
        flexString +
        itemWidth +
        generatePaddingStyle(eventCards.options.card.spacing.padding) +
        generateDefaultStyles(eventCards.options.card, ["spacing"]),
    });

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      pseudoClassName: ":hover",
      content: generateDefaultStyles(eventCards.options.states.hover),
    });

    cssString += generateCssClassWithContent({
      className: options.items._cssClass,
      content:
        itemWidth +
        generateFlex({
          flex: "flex",
          align:
            options.items.displayMode === DisplayOrientation.HORIZONTAL
              ? "center"
              : "flex-start",
          justify: "flex-start",
          direction:
            options.items.displayMode === DisplayOrientation.HORIZONTAL
              ? "row"
              : "column",
          gap: options.items.distance,
        }),
    });

    let marketItemsCssContent;

    cssString += generateCssClassWithContent({
      className: options.items._cssMarketsClass,
      content: itemWidth + "flex: 1;" + marketItemsCssContent,
    });

    return cssString;
  };

export const generateNoEventsTextCss =
  (item: IWidgetField) =>
  (cssString: string): string => {
    const { options } = item;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "width: 100%;",
    });

    return cssString;
  };

export const generateTimeContainerCssString =
  (item: IWidgetField) =>
  (cssString: string): string => {
    const { options } = item;

    const flexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: "8",
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: flexString,
    });

    return cssString;
  };

export const generateComingEventsWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const event_cards = widget.content["event_cards"];
  const event_time = widget.content["event_time"];
  const teams_name = widget.content["teams_name"];
  const tabs = widget.content["tabs"];
  const arrow = widget.content["arrow"];
  const no_events_title = widget.content["no_events_title"];

  widget.options._cssClass = generateClassName("ComingEvents");
  title.options._cssClass = generateClassName("title");
  no_events_title.options._cssClass = generateClassName("no_events_title");

  arrow.options._cssClass = generateClassName("arrow");
  arrow.options._wrapperCssClass = generateClassName("arrow-wrapper");
  arrow.options._containerCssClass = generateClassName("arrow-container");

  tabs.options.container._cssClass = generateClassName("tabs-container");
  tabs.options._wrapperCssClass = generateClassName("tabs-wrapper");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options.iconSettings._cssClass = generateClassName("tabs_icon");
  tabs.options._itemSelectedCssClass =
    tabs.options._cssClass + '[data-selected="true"]';

  event_cards.options._cssClass = generateClassName("event_cards-container");
  event_cards.options.card._cssClass = generateClassName("event_cards");
  event_cards.options.items._cssClass = generateClassName("event_cards-items");
  event_cards.options.items._cssMarketsClass = generateClassName(
    "event_cards-market-items"
  );

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");

  teams_name.options._cssClass = generateClassName("teams_name");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStringDefault(title),
    generateStringDefault(teams_name),
    generateTabsCssString(tabs as IWidgetField, arrow as IWidgetField),
    generateEventCardsCssString(event_cards as IWidgetField),
    generateListStartTimeCssString(
      event_time as IWidgetField,
      "8",
      "min-width:max-content;"
    ),
    generateStringDefault(no_events_title),
    generateNoEventsTextCss(no_events_title as IWidgetField)
  )("");
};
