<template>
  <div
    v-if="hasDesignPermissions"
    class="config-display-settings p-l-16 p-r-16 p-t-16 widget-control-container p-b-16"
  >
    <p class="m-b-4">Display settings</p>
    <div class="config-display-settings__container">
      <span
        v-for="col in COLUMNS"
        :key="col.cols"
        :class="{
          'config-display-settings__icon--active': modelValue === col.cols,
        }"
        class="config-display-settings__icon pointer"
        @click="updateValue(col.cols)"
      >
        <CommonIcon :name="col.icon" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";

defineProps<{
  modelValue: number;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: number): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const updateValue = (value: number): void => {
  emit("update:model-value", value);
};

const COLUMNS: { cols: number; icon: string }[] = [
  {
    cols: 1,
    icon: "custom:one-column-setting",
  },
  {
    cols: 2,
    icon: "custom:two-columns-setting",
  },
  {
    cols: 3,
    icon: "custom:three-columns-setting",
  },
];
</script>

<style lang="scss">
.config-display-settings {
  &__container {
    @include flex(center, space-around, $space-s);
  }

  &__icon {
    @include flex(center, center, 0);
    border: 1px solid $c-grey-15;
    padding: 8px;
    border-radius: 6px;
    flex-grow: 1;

    svg {
      width: 32px;
      height: 32px;
    }

    &--active {
      border: 2px solid $c-primary-base !important;
    }
  }
}
</style>
