<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoGamesFiltersWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      :style="containerStyles"
      :class="`casino-games-filter--${displayOrientation}`"
      class="casino-games-filter"
    >
      <WidgetField
        v-for="(field, index) in filterFormFields"
        :key="field.name"
        :widget="widget"
        :field="field"
        class="casino-games-filter__item"
      >
        <FormDropdownField
          :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[index] = el;
              }
            "
          :field="field"
          :styling-fields="inputStyleFields"
          :states="states"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useCasinoGamesFiltersWidgetInitData } from "~~/composables/widgets/casino/filter/useCasinoGamesFiltersWidgetInitData";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import {
  FormElementName,
  TriggerComponent,
} from "~~/models/widgets/form.model";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const {
  fieldsList,
  states,
  INITIAL_STATES,
  initFields,
  initWidgetFieldsOptions,
} = useCasinoGamesFiltersWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: INITIAL_STATES,
  states,
});

initFields();
initWidgetFieldsOptions();

const displayOrientation = computed<string>(() => {
  return objectFields.value.form.options.displayMode;
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(objectFields.value.form.options),
    ...distanceStyles.value,
  };
});

const inputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DROPDOWN);
});

const distanceStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(objectFields.value.form.options.distance),
  };
});

const objectFields = computed<Record<string, IWidgetField>>(() => {
  return fieldsList.value.reduce((result, curr) => {
    return {
      ...result,
      [curr.name]: curr,
    };
  }, {});
});

const filterFormFields = computed<IWidgetField[]>(() => {
  return fieldsList.value.filter(field =>
    ["categories", "providers"].includes(field.name)
  );
});

const { fieldsRefsList, getFieldStylings } = useFormInner(
  props.widget,
  filterFormFields
);
</script>

<style lang="scss">
.casino-games-filter {
  @include flex(flex-end, flex-start, 24px);

  &__item {
    width: 100%;
  }

  &--vertical {
    @include flex-column(flex-start, flex-start, 24px);
  }
}
</style>
