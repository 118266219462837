<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="selectedField?.id === primaryField?.id"
    :style="descriptionInlineStyles"
    class="basic-description-widget__editor-ontent"
  >
    <CommonHtmlEditor
      :model-value="primaryField?.value || primaryField?.default_value"
      @update:model-value="handleUpdate"
    />
  </div>
  <div
    v-else
    class="basic-description-widget__content"
    :style="descriptionInlineStyles"
    v-html="localizedValue"
  ></div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getPrimaryDescriptionField } from "~~/assets/utils/widget/fields/description";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { useWidgetsStore } from "~~/store/widgets";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { getSpacing } from "~~/assets/utils/widget-settings";

const fields = defineModel<IWidgetField[]>("fields", {
  default: () => [],
});

const widgetsStore = useWidgetsStore();

const { selectedField } = storeToRefs(useWidgetSettingsStore());

const { getLocalizedValue } = useLocalizedValue();

const primaryField = computed<IWidgetField | undefined | null>(() => {
  return getPrimaryDescriptionField(fields.value!);
});

const currentField = computed<IWidgetField | undefined>(
  () => getCurrentField().value
);

const handleUpdate = (value: Record<string, any> | undefined | null): void => {
  if (!currentField.value) {
    return;
  }

  widgetsStore.updateFieldValue(currentField.value.id, value);
};

const localizedValue = computed(() =>
  getLocalizedValue.value(
    primaryField.value?.value || primaryField.value?.default_value || {}
  )
);

const descriptionInlineStyles = computed(() => {
  const padding = getSpacing(
    primaryField.value?.options?.padding,
    SpacingKeyName.PADDING
  );

  return {
    ...padding,
  };
});
</script>

<style lang="scss">
.basic-description-widget {
  &__content {
    ul {
      list-style-type: disc;
      padding-inline-start: 40px;
    }

    p {
      margin: 0;
    }

    a {
      pointer-events: none;
    }
  }

  &__editor-ontent {
    p {
      margin: 0;
    }
  }
}
</style>
