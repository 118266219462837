<template>
  <div
    class="basic-button-field"
    :style="containerStyleValue"
  >
    <button
      :style="buttonStyleValue"
      class="basic-button-field__button pointer"
    >
      <span :style="buttonTextStyle">{{
        getLocalizedValue(value || field.value)
      }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import {
  getColorFromHex,
  getDecorationValue,
  getFontSizeStyle,
  getFontFamilyStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import {
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  field: IWidgetField;
  currentState: State;
  value?: string;
  isButtonTextSettings?: boolean;
}>();

const { getLocalizedValue } = useLocalizedValue();

const activeStateOptions = computed<any>(() => {
  return props.currentState === State.DEFAULT
    ? props.field.options
    : props.field.options.states[props.currentState];
});

const containerStyleValue = computed<{ [key: string]: string }>(() => {
  return {
    justifyContent: props.field.options.buttonDisplaySettings?.alignment,
  };
});

const buttonTextStyle = computed<{ [key: string]: string }>(() => {
  const options = props.field.options;
  return {
    color: getColorFromHex(
      props.isButtonTextSettings
        ? activeStateOptions.value.buttonTextSettings.textColor
        : activeStateOptions.value.color
    ) as string,
    ...getFontSizeStyle(
      props.isButtonTextSettings
        ? options.buttonTextSettings.theme
        : options.theme
    ),
    ...getFontFamilyStyle(
      props.isButtonTextSettings
        ? options.buttonTextSettings.fontFamily
        : options.fontFamily
    ),
    ...getDecorationValue(
      props.isButtonTextSettings
        ? options.buttonTextSettings.decoration
        : options.decoration || []
    ),
  };
});

const buttonStyleValue = computed<any>(() => {
  const styles = {
    ...getCommonStyles({
      ...props.field.options,
      ...activeStateOptions.value,
    }),
    ...getSpacing(props.field.options.margins, SpacingKeyName.MARGIN),
  };

  if (
    props.field.options.buttonDisplaySettings?.resizing === ResizingType.FILL
  ) {
    styles.width = "100%";
    styles.justifyContent = props.field.options.buttonDisplaySettings.alignment;
  }

  return styles;
});
</script>

<style lang="scss">
.basic-button-field {
  @include flex(center, center, 0);

  &__button {
    @include flex(center, center, 6px);
  }
}
</style>
