<template>
  <div class="sport-event-fenix-widget-live-banner">
    <WidgetField
      :widget="widget"
      :field="fields.live_banner.field"
      :style="liveBannerStyles"
    >
      <template v-if="states.live_banner === State.AUTHORIZED">
        <WidgetField
          :widget="widget"
          class="sport-event-fenix-widget-live-banner__event-info"
          :style="eventInfoStyles"
          :field="fields.live_banner_event_info.field"
        >
          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__event-info-team-player"
            :style="teamPlayerStyles"
            :field="fields.live_banner_event_info_name.field"
          >
            Team/PLayer #1
          </WidgetField>

          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__event-info-score"
            :style="scoreContainerStyles"
            :field="fields.live_banner_event_info_score.field"
          >
            <span :style="scoreStyle"> 0 </span>
            <span> - </span>
            <span :style="scoreStyle"> 0 </span>
          </WidgetField>

          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__event-info-team-player"
            :style="teamPlayerStyles"
            :field="fields.live_banner_event_info_name.field"
          >
            Team/PLayer #2
          </WidgetField>
        </WidgetField>

        <WidgetField
          :widget="widget"
          class="sport-event-fenix-widget-live-banner__header"
          :style="headerStyles"
          :field="fields.live_banner_header.field"
        >
          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__header-statistics"
            :style="statisticStyles"
            :field="fields.live_banner_header_statistics.field"
          >
            <span> B:0 S:0 O:0 OB:0 </span>
          </WidgetField>

          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__header-values"
            :style="headerTitlesStyles"
            :field="fields.live_banner_header_titles.field"
          >
            <WidgetField
              v-for="index in 5"
              :key="index"
              :widget="widget"
              class="sport-event-fenix-widget-live-banner__header-value-titles"
              :style="headerValueTitlesCellStyles"
              :field="fields.live_banner_header_value_titles.field"
            >
              <div :style="headerValueTitlesBlockStyles">
                {{ index + 1 }}
              </div>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.live_banner_header_point_title.field"
              class="sport-event-fenix-widget-live-banner__header-value-point"
              :style="headerPointCellStyles"
            >
              <div :style="headerPointBlockStyles">Points</div>
            </WidgetField>
          </WidgetField>
        </WidgetField>

        <WidgetField
          v-for="(row, index) in rows"
          :key="`${index}__${row.team_player}`"
          :widget="widget"
          :style="rowsElementStyles"
          class="sport-event-fenix-widget-live-banner__rows-element"
          :field="fields.live_banner_rows.field"
        >
          <WidgetField
            :widget="widget"
            :style="rowTeamPlayerStyles"
            :field="fields.live_banner_rows_team_player.field"
          >
            {{ row.team_player }}
          </WidgetField>

          <WidgetField
            :widget="widget"
            class="sport-event-fenix-widget-live-banner__rows-team-player-info"
            :style="rowTeamPlayerInfoStyles"
            :field="fields.live_banner_rows_team_player_info.field"
          >
            <WidgetField
              v-for="(value, valueIndex) in row.values"
              :key="`${valueIndex}__${value}`"
              :widget="widget"
              class="sport-event-fenix-widget-live-banner__rows-team-player-info-values"
              :style="rowTeamPlayerInfoValuesCellStyles"
              :field="fields.live_banner_rows_team_player_info_values.field"
            >
              <div :style="rowTeamPlayerInfoValuesBlockStyles">
                {{ value }}
              </div>
            </WidgetField>

            <WidgetField
              :widget="widget"
              class="sport-event-fenix-widget-live-banner__rows-team-player-info-point"
              :style="rowTeamPlayerPointCellStyles"
              :field="fields.live_banner_rows_team_player_info_point.field"
            >
              <div :style="rowTeamPlayerPointBlockStyles">
                {{ row.points }}
              </div>
            </WidgetField>
          </WidgetField>
        </WidgetField>

        <WidgetField
          :widget="widget"
          class="sport-event-fenix-widget-live-banner__footer"
          :style="footerStyles"
          :field="fields.live_banner_footer.field"
        >
          <WidgetField
            :widget="widget"
            :style="footerTimeStyles"
            :field="fields.live_banner_footer_time.field"
          >
            00:00
          </WidgetField>

          <WidgetField
            :widget="widget"
            :style="footerPeriodStyles"
            :field="fields.live_banner_footer_period.field"
          >
            Period
          </WidgetField>
        </WidgetField>
      </template>

      <div v-else>
        <WidgetField
          :widget="widget"
          :style="liveBannerLoginTextStyles"
          :field="fields.live_banner_login_text.field"
        >
          Please log in to watch the event.
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.live_banner_login_button.field"
        >
          <BasicButtonField
            :field="fields.live_banner_login_button.field"
            :current-state="states.live_banner_login_button"
            custom-value="Login"
          />
        </WidgetField>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getSpacing,
  getTextShadowStyle,
} from "~~/assets/utils/widget-settings";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import {
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  fields: IFields;
}>();

const rows = [
  {
    team_player: "Team / Player #1",
    values: ["1", "-", "1", "23", "123"],
    points: "-",
  },
  {
    team_player: "Team / Player #2",
    values: ["7", "-", "5", "-", "56"],
    points: "-",
  },
];

function getBlockStyles(options: IWidgetOptions, currentState: IWidgetOptions) {
  return {
    ...getCommonStyles(options),
    ...getColorStyle(currentState.typography.color),
    ...getFontSizeStyle(currentState.typography.theme),
    ...getFontFamilyStyle(currentState.typography.fontFamily),
    ...getDecorationValue(currentState.typography.decoration),
    height: getPxValueFromNumber(currentState.block.height),
    width: getPxValueFromNumber(currentState.block.width),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
}

function getCellStyles(currentState: IWidgetOptions) {
  return {
    width: "100%",
    minWidth: getPxValueFromNumber(currentState.cell.minCellWidth),
    maxWidth: getPxValueFromNumber(currentState.cell.maxCellWidth),
  };
}

const liveBannerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner.field.options;

  if (props.states.live_banner === State.UNAUTHORIZED) {
    return {
      ...getCommonStyles(options),
      ...getBackgroundColorStyle(options.states[State.UNAUTHORIZED].fill),
    };
  }

  return getCommonStyles(options);
});

const liveBannerLoginTextStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_login_text.field.options;

  return {
    textAlign: options.alignment,
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});

const teamPlayerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_event_info_name.field.options;

  return {
    textAlign: options.alignment,
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});

const scoreContainerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_event_info_score.field.options;

  return {
    gap: getPxValueFromNumber(options.distance),
    ...getColorStyle(options.typography.color),
    ...getFontSizeStyle(options.typography.theme),
    ...getFontFamilyStyle(options.typography.fontFamily),
    ...getDecorationValue(options.typography.decoration),
  };
});

const scoreStyle = computed<CSSProperties>(() =>
  getCommonStyles(props.fields.live_banner_event_info_score.field.options)
);

const eventInfoStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_event_info.field.options;
  return {
    gap: getPxValueFromNumber(options.distance),
    ...getCommonStyles(options),
  };
});

const headerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_header.field.options;

  return {
    ...getCommonStyles(options),
    gap: getPxValueFromNumber(options.distance),
  };
});

const statisticStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_header_statistics.field.options;
  const isFill = options.display.resizing === ResizingType.FILL;

  return {
    width: isFill ? "50%" : `${options.display.width}%`,
    textAlign: options.display.alignment,
    ...getCommonStyles(options),
    ...getColorStyle(options.typography.color),
    ...getFontSizeStyle(options.typography.theme),
    ...getFontFamilyStyle(options.typography.fontFamily),
    ...getDecorationValue(options.typography.decoration),
  };
});

const headerTitlesStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_header_titles.field.options;
  const statisticsDisplay =
    props.fields.live_banner_header_statistics.field.options.display;
  const isStatisticsFill = statisticsDisplay.resizing === ResizingType.FILL;

  return {
    gap: getPxValueFromNumber(options.display.distance),
    justifyContent:
      options.display.alignmentType === "alignment"
        ? options.display.alignment
        : options.display.justifyContent,
    ...getCommonStyles(options),
    width: isStatisticsFill ? "50%" : `${100 - statisticsDisplay.width}%`,
  };
});

const headerValueTitlesBlockStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_header_value_titles.field.options;
  const currentState =
    options.states[props.states.live_banner_header_value_titles];

  return getBlockStyles(options, currentState);
});

const headerValueTitlesCellStyles = computed<CSSProperties>(() => {
  const currentState =
    props.fields.live_banner_header_value_titles.field.options.states[
      props.states.live_banner_header_value_titles
    ];

  return getCellStyles(currentState);
});

const headerPointBlockStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_header_point_title.field.options;
  const currentState =
    options.states[props.states.live_banner_header_point_title];

  return getBlockStyles(options, currentState);
});

const headerPointCellStyles = computed<CSSProperties>(() => {
  const currentState =
    props.fields.live_banner_header_point_title.field.options.states[
      props.states.live_banner_header_point_title
    ];

  return getCellStyles(currentState);
});

const rowsElementStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_rows.field.options;

  return {
    ...getCommonStyles(options),
    gap: getPxValueFromNumber(options.distance),
  };
});

const rowTeamPlayerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_rows_team_player.field.options;
  const isFill = options.display.resizing === ResizingType.FILL;

  return {
    width: isFill ? "50%" : `${options.display.width}%`,
    textAlign: options.display.alignment,
    ...getCommonStyles(options),
    ...getColorStyle(options.typography.color),
    ...getFontSizeStyle(options.typography.theme),
    ...getFontFamilyStyle(options.typography.fontFamily),
    ...getDecorationValue(options.typography.decoration),
  };
});

const rowTeamPlayerInfoStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_rows_team_player_info.field.options;
  const rowsTeamPlayerDisplay =
    props.fields.live_banner_rows_team_player.field.options.display;
  const isRowsTeamPlayerFill =
    rowsTeamPlayerDisplay.resizing === ResizingType.FILL;

  return {
    justifyContent:
      options.display.alignmentType === "alignment"
        ? options.display.alignment
        : options.display.justifyContent,
    gap: getPxValueFromNumber(options.display.distance),
    width: isRowsTeamPlayerFill
      ? "50%"
      : `${100 - rowsTeamPlayerDisplay.width}%`,
    ...getCommonStyles(options),
  };
});

const rowTeamPlayerInfoValuesBlockStyles = computed<CSSProperties>(() => {
  const isDefault =
    props.states.live_banner_rows_team_player_info_values_states ===
    State.DEFAULT;

  const options =
    props.fields.live_banner_rows_team_player_info_values.field.options;
  const currentState =
    options.states[props.states.live_banner_rows_team_player_info_values];

  const currentTextStylePath = isDefault
    ? options.states[props.states.live_banner_rows_team_player_info_values]
        .typography.color
    : options.winner.color;

  const currentStatesStylePath = isDefault ? options : options.winner;

  return {
    ...getSpacing(options.spacing.padding, SpacingKeyName.PADDING),
    ...getCornerRadiusStyle(currentStatesStylePath.cornerRadius),
    ...getBoxShadowStyle(currentStatesStylePath.shadow),
    ...getBorderStyle(currentStatesStylePath.border),
    ...getBackgroundColorStyle(currentStatesStylePath.fill),
    ...getColorStyle(currentTextStylePath),
    ...getFontSizeStyle(currentState.typography.theme),
    ...getFontFamilyStyle(currentState.typography.fontFamily),
    ...getDecorationValue(currentState.typography.decoration),
    height: getPxValueFromNumber(currentState.block.height),
    width: getPxValueFromNumber(currentState.block.width),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

const rowTeamPlayerInfoValuesCellStyles = computed<CSSProperties>(() => {
  const currentState =
    props.fields.live_banner_rows_team_player_info_values.field.options.states[
      props.states.live_banner_rows_team_player_info_values
    ];

  return getCellStyles(currentState);
});

const rowTeamPlayerPointBlockStyles = computed<CSSProperties>(() => {
  const isDefault =
    props.states.live_banner_rows_team_player_info_point_states ===
    State.DEFAULT;

  const options =
    props.fields.live_banner_rows_team_player_info_point.field.options;
  const currentState =
    options.states[props.states.live_banner_rows_team_player_info_point];

  const currentTextStylePath = isDefault
    ? options.states[props.states.live_banner_rows_team_player_info_point]
        .typography.color
    : options.winner.color;

  const currentStatesStylePath = isDefault ? options : options.winner;

  return {
    ...getSpacing(options.spacing.padding, SpacingKeyName.PADDING),
    ...getCornerRadiusStyle(currentStatesStylePath.cornerRadius),
    ...getBoxShadowStyle(currentStatesStylePath.shadow),
    ...getBorderStyle(currentStatesStylePath.border),
    ...getBackgroundColorStyle(currentStatesStylePath.fill),
    ...getColorStyle(currentTextStylePath),
    ...getFontSizeStyle(currentState.typography.theme),
    ...getFontFamilyStyle(currentState.typography.fontFamily),
    ...getDecorationValue(currentState.typography.decoration),
    height: getPxValueFromNumber(currentState.block.height),
    width: getPxValueFromNumber(currentState.block.width),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

const rowTeamPlayerPointCellStyles = computed<CSSProperties>(() => {
  const currentState =
    props.fields.live_banner_rows_team_player_info_point.field.options.states[
      props.states.live_banner_rows_team_player_info_point
    ];

  return getCellStyles(currentState);
});

const footerStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_footer.field.options;

  return {
    ...getCommonStyles(options),
    gap: getPxValueFromNumber(options.distance),
  };
});

const footerTimeStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_footer_time.field.options;

  return {
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});

const footerPeriodStyles = computed<CSSProperties>(() => {
  const options = props.fields.live_banner_footer_period.field.options;

  return {
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});
</script>

<style lang="scss">
.sport-event-fenix-widget-live-banner {
  &__event-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__event-info-team-player {
    flex: 1;
  }

  &__rows {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__header,
  &__rows-element,
  &__rows-team-player-info,
  &__header-values,
  &__event-info-score {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-value-titles {
    text-align: center;
  }
}
</style>
