import merge from "lodash/merge";

import {
  IWidgetBindingParam,
  IWidgetBindingParamsObject,
} from "~~/models/widgets/widget.core/widget.model";

interface IOptions {
  values?: IWidgetBindingParamsObject;
}

export interface IParams {
  [key: string]: Pick<
    IWidgetBindingParam,
    "label" | "description" | "sources" | "required" | "isHidden"
  >;
}

export const useWidgetDynamicParams = (
  params: IParams,
  options: IOptions = {}
) => {
  const bindingParams = ref<IWidgetBindingParamsObject>({});

  Object.keys(params).forEach(param => {
    const { required, sources, label, description, isHidden } = params[param];

    bindingParams.value[param] = {
      label,
      description,
      source: sources[0],
      sources: sources,
      value: null,
      required: Boolean(required),
      isHidden: Boolean(isHidden),
    };
  });

  if (options.values) {
    bindingParams.value = merge(bindingParams.value, options.values);
  }

  return {
    bindingParams,
  };
};
