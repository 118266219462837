import { defineStore } from "pinia";

import { IUiStore } from "~~/models/stores/ui-store.model";
import { Breakpoint } from "~~/models/breakpoints.model";
import { Step } from "~~/models/step.enum";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";

export const useUiStore = defineStore("ui", {
  state: (): IUiStore => ({
    activeStep: Step.LAYOUTS,
    activeScreen: Breakpoint.MOBILE,
    isSideMenuConfigOpened: true,
    activeSideMenuTab: SideMenuTab.CONTENT,
  }),
  getters: {},
  actions: {
    setScreen(screen: Breakpoint): void {
      this.activeScreen = screen;
    },

    switchTo(step: Step): void {
      this.activeStep = step;
      this.activeSideMenuTab = SideMenuTab.CONTENT;
    },

    closeSideMenu(): void {
      this.isSideMenuConfigOpened = false;
    },

    toggleConfigTabs(newActiveTab: SideMenuTab): void {
      if (this.isSideMenuConfigOpened) {
        if (this.activeSideMenuTab === newActiveTab) {
          this.isSideMenuConfigOpened = false;
        } else {
          this.activeSideMenuTab = newActiveTab;
        }
      } else {
        this.isSideMenuConfigOpened = true;
        this.activeSideMenuTab = newActiveTab;
      }
    },
  },
});
