import { getDefaultFillValue } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 42;

const getDefaultArrow = () => ({
  up: {
    color: getDefaultFillValue("#1677ff"),
  },
  down: {
    color: getDefaultFillValue("#1677ff"),
  },
});

const v42WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;
  if (["SportEventFenixWidget"].includes(widget.name)) {
    if (!widget.content.racing_odds.options.arrow?.up) {
      widget.content.racing_odds.options.arrow = getDefaultArrow();
      widget.content.racing_odds.options.states.up.arrow = getDefaultArrow();
      widget.content.racing_odds.options.states.down.arrow = getDefaultArrow();
      widget.content.racing_odds.options.states.hover.arrow = getDefaultArrow();
      widget.content.racing_odds.options.states.active.arrow =
        getDefaultArrow();
    }
  }

  return widget;
};
export default v42WidgetMigration;
