export const findAnyStartsWith = (
  stringList: string[],
  targetString: any
): boolean => {
  if (typeof targetString === "string") {
    return stringList.some(substring =>
      String(targetString).trim().toLowerCase().startsWith(substring)
    );
  }
  return false;
};
