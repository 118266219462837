<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
    class="container-widget__pagination"
    :style="containerStyle"
  >
    <span
      v-for="dot in DOTS"
      :key="dot"
      :style="dotStyle"
      class="container-widget__pagination-button"
      :class="[
        dotClassName,
        dot === 1 ? 'container-widget__pagination--active' : '',
      ]"
    >
    </span>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import { ElementStyle } from "~~/models/common";
import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import {
  getBackgroundColorStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const DOTS = 5;

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyle = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      alignment: getFlexPosition(props.field.options.alignment),
    },
    "pagination"
  );
});

const dotClassName = computed<string>(() => {
  if (props.field.options.isStroke) {
    return "container-widget__pagination--stroke";
  }

  return "container-widget__pagination--bullet";
});

const dotStyle = computed<ElementStyle>(() => {
  return {
    ...getBackgroundColorStyle(props.field.options.color),
    ...getStylesAsVars(
      {
        size: getPxValueFromNumber(props.field.options.size),
      },
      "pagination"
    ),
  };
});
</script>

<style lang="scss" scoped>
.container-widget {
  &__pagination {
    @include flex(center, var(--pagination-alignment), 12px);

    &-button {
      display: inline-block;
    }

    &--stroke {
      width: var(--pagination-size);
      height: 2px;
      opacity: 0.5;
    }

    &--bullet {
      width: calc(var(--pagination-size) - (var(--pagination-size) / 3));
      height: calc(var(--pagination-size) - (var(--pagination-size) / 3));
      border-radius: 50%;
      opacity: 0.5;

      &.container-widget__pagination--active {
        width: var(--pagination-size);
        height: var(--pagination-size);
      }
    }

    .container-widget__pagination--active {
      opacity: 1;
    }
  }
}
</style>
