<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div>
      <BonusesWidgetFenixSettings
        :widget="widget"
        :states="states"
        @update-state="handleStateUpdate"
        @reset-states="resetStates"
        @change="handleChangeSelectedItem"
      />
      <div class="bonuses-widget-fenix">
        <template v-if="states.screen !== State.MODAL">
          <WidgetField
            v-if="fields.title.active"
            :widget="widget"
            :field="fields.title.field"
          >
            <BasicTitleField :field="fields.title.field" />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.tabs.field"
          >
            <TransactionHistoryTabs
              :field="fields.tabs.field"
              :state="states.tabs"
              :items="TAB_ITEMS"
            />
          </WidgetField>
        </template>

        <WidgetField
          v-if="
            states.screen === State.ACTIVE_EVENTS ||
            states.screen === State.NO_ACTIVE
          "
          :widget="widget"
          :field="fields.bonus_group.field"
          :style="bonusGroupWrapper"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.bonus_group.field"
            :state="states.bonus_group"
            style="width: 100%"
          >
            <WidgetField
              :widget="widget"
              :field="fields.bonus_group_title.field"
            >
              <BasicTitleField :field="fields.bonus_group_title.field" />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.bonus_group_description.field"
            >
              <BasicTitleField :field="fields.bonus_group_description.field" />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.cards.field"
            >
              <BonusesCard
                v-if="states.screen === State.ACTIVE_EVENTS"
                :field="fields.cards.field"
                :state="states.cards"
                class="bonuses-widget-card"
              >
                <template #left>
                  <FlexibleImageField
                    :widget="widget"
                    :field="fields.image.field"
                    :class="{
                      'bonuses-widget-fenix__photo--is-vertical':
                        fields.cards.field.options.layout ===
                        DisplayOrientation.VERTICAL,
                    }"
                    value="/images/bonuses-widget-preview.png"
                  />
                </template>

                <template #title>
                  <WidgetField
                    :widget="widget"
                    :field="fields.bonus_title.field"
                  >
                    <BasicTitleField
                      :field="fields.bonus_title.field"
                      value="Title"
                    />
                  </WidgetField>
                </template>

                <template #status>
                  <WidgetField
                    :widget="widget"
                    :field="fields.description.field"
                  >
                    <BasicTitleField
                      :field="fields.description.field"
                      value="Description"
                    />
                  </WidgetField>

                  <WidgetField
                    :widget="widget"
                    :field="fields.status.field"
                  >
                    <BonusesText
                      :field="fields.status.field"
                      primary-text="Status:"
                      secondary-text="status description"
                      class="bonuses-widget-fenix__content-flex-field"
                    />
                  </WidgetField>
                </template>

                <template #date>
                  <WidgetField
                    :widget="widget"
                    :field="fields.dates.field"
                  >
                    <div
                      class="bonuses-widget-fenix__date bonuses-widget-fenix__content-flex-field"
                      :style="datesWrapper"
                    >
                      <BonusesText
                        :field="fields.dates.field"
                        primary-text="Acceptance date:"
                        secondary-text="21.07.2023"
                      />
                      <BonusesText
                        :field="fields.dates.field"
                        primary-text="Expires date:"
                        secondary-text="21.07.2023"
                      />
                    </div>
                  </WidgetField>
                </template>

                <template #progress_bar>
                  <WidgetField
                    :widget="widget"
                    :field="fields.progress_bar.field"
                  >
                    <BasicProgressField :field="fields.progress_bar.field" />
                  </WidgetField>
                </template>

                <template #amounts>
                  <WidgetField
                    :widget="widget"
                    :field="fields.amounts.field"
                  >
                    <div
                      class="bonuses-widget-fenix__amouts bonuses-widget-fenix__content-flex-field"
                    >
                      <BonusesText
                        :field="fields.amounts.field"
                        :primary-text="t('widgets.BonusesWidget.bonus_amount')"
                        secondary-text="$12,5"
                      />
                      <BonusesText
                        :field="fields.amounts.field"
                        :primary-text="
                          t('widgets.BonusesWidget.total_bet_amount')
                        "
                        secondary-text="$6,25"
                      />
                      <BonusesText
                        :field="fields.amounts.field"
                        :primary-text="t('widgets.BonusesWidget.rest_amount')"
                        secondary-text="$6,25"
                      />
                    </div>
                  </WidgetField>
                </template>

                <template #terms_title>
                  <WidgetField
                    :widget="widget"
                    :field="fields.terms_title.field"
                  >
                    <BasicTitleField
                      :field="fields.terms_title.field"
                      :state="states.terms_title"
                    />
                  </WidgetField>
                </template>

                <template #action_button>
                  <div class="">
                    <WidgetField
                      v-if="fields.action_button.active"
                      :widget="widget"
                      :field="fields.action_button.field"
                    >
                      <BasicTextButtonField
                        :field="fields.action_button.field"
                        :current-state="states.action_button"
                        :value="t('widgets.BonusesWidget.action_button')"
                        class="bonuses-widget-fenix__action-btn bonuses-widget-fenix__content-flex-field"
                      />
                    </WidgetField>
                  </div>
                </template>
              </BonusesCard>

              <BasicTitleField
                v-else-if="states.screen === State.NO_ACTIVE"
                :field="fields.no_active_bonuses.field"
              />
            </WidgetField>
          </BasicDesignWrapperField>
        </WidgetField>

        <WidgetField
          v-if="states.screen === State.MODAL"
          :widget="widget"
          :field="fields.modal.field"
          style="display: flex"
        >
          <BonusesModal :field="fields.modal.field">
            <template #title>
              <WidgetField
                :widget="widget"
                :field="fields.modal_title.field"
              >
                <BasicTitleField :field="fields.modal_title.field" />
              </WidgetField>
            </template>

            <template #description>
              <WidgetField
                :widget="widget"
                :field="fields.modal_description.field"
              >
                <BasicTitleField :field="fields.modal_description.field" />
              </WidgetField>
            </template>

            <template #primary_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_primary_button.field"
              >
                <BasicTextButtonField
                  :field="fields.modal_primary_button.field"
                  :value="t('widgets.BonusesWidget.modal_primary_button')"
                  :current-state="states.modal_primary_btn"
                />
              </WidgetField>
            </template>

            <template #secondary_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_secondary_button.field"
              >
                <BasicTextButtonField
                  :field="fields.modal_secondary_button.field"
                  :value="t('widgets.BonusesWidget.modal_secondary_button')"
                  :current-state="states.modal_secondary_btn"
                />
              </WidgetField>
            </template>

            <template #close_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_close_icon.field"
              >
                <BasicButtonField
                  :field="fields.modal_close_icon.field"
                  :current-state="states.modal_close_icon"
                />
              </WidgetField>
            </template>
          </BonusesModal>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { CellSizeType, ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/bonuses/fenix/useBonusesFenixInit";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import { getWidthStyles } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const { t } = useI18n();

const TAB_ITEMS = computed(() => [
  t("widgets.BonusesWidget.active_bonuses"),
  t("widgets.BonusesWidget.pending_bonuses"),
  t("widgets.BonusesWidget.previous_bonuses"),
]);

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, MODAL_FIELDS, init } = useInit(props.widget);

const bonusGroupWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.bonus_group.field.options;

  if (!settings) {
    return {};
  }
  return {
    display: "flex",
    width: getWidthStyles(settings.width).width,
    flex: settings.width.sizeType === CellSizeType.FILL ? "1" : "initial",
    gap: settings.display.distanceBetweenGroups,
  };
});

const datesWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.dates.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection:
      settings.display?.layout === DisplayOrientation.VERTICAL
        ? "column"
        : "row",
    gap: getPxValueFromNumber(settings.display?.distance ?? 16),
  };
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["screen"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

const handleChangeSelectedItem = (fieldName: string): void => {
  if (fieldName === "modal") {
    resetStates();
    states.value.screen = State.MODAL;
    return;
  }

  if (states.value.screen === State.ACTIVE_EVENTS) {
    resetStates();
    return;
  }

  if (!MODAL_FIELDS.includes(fieldName)) {
    resetStates();
    states.value.screen = State.ACTIVE_EVENTS;
    return;
  }

  resetStates();
};

init();
</script>

<style lang="scss">
.bonuses-widget-fenix {
  &__date {
    @include flex(flex-start, flex-start, 16px);

    align-items: var(--card-flexAlignment);
  }

  &-card {
    & > div:first-child {
      flex-grow: initial;
    }
  }

  &__amouts {
    @include flex(center, flex-start, 4px);

    .bonuses-widget-text {
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      text-align: var(--card-textAlignment);
    }
  }

  &__photo-field {
    height: 100%;
  }

  &__action-btn {
    display: inline-block;
  }

  &__content-flex-field {
    display: flex;
    justify-content: var(--card-flexAlignment);
  }

  .bonuses-widget-text {
    align-items: var(--card-flexAlignment);
  }

  .bonuses-widget-fenix__photo--is-vertical {
    .flexible-image-field {
      justify-content: var(--card-flexAlignment) !important;
    }
  }
}
</style>
