<template>
  <div
    :style="containerStyles"
    class="bonuses-widget-text"
  >
    <p :style="primaryTextStyles">
      {{ primaryText }}
    </p>

    <p :style="secondaryTextStyles">
      {{ secondaryText }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { getTextCommonStyles, getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";

const props = defineProps<{
  field: IWidgetField;
  primaryText: string;
  secondaryText: string;
}>();

const primaryTextStyles = computed<ElementStyle>(() => {
  return getTextCommonStyles({
    ...props.field.options,
    ...props.field.options.primary,
  });
});

const secondaryTextStyles = computed<ElementStyle>(() => {
  return getTextCommonStyles({
    ...props.field.options,
    ...props.field.options.secondary,
  });
});

const containerStyles = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});
</script>

<style lang="scss">
.bonuses-widget-text {
  @include flex(center, flex-start, 4px);

  p {
    margin: 0;
  }
}
</style>
