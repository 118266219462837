import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { IMAGE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { useWidgetsStore } from "~~/store/widgets";

export const useImageWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value);

  const {
    initWidgetOptions,
    initFields,

    addFieldInitialOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([]);

  const init = (): void => {
    initFields();

    addFieldInitialOptions("image", {
      data: IMAGE_INITIAL(),
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    init,
  };
};
