import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  DESIGN_INITIAL,
  getDefaultFillImage,
  getDefaultContentWidth,
  getDefaultFillValue,
  getDefaultTheme,
  getDefaultDecoration,
  getDefaultCornerRadiusValue,
  getDefaultBorderValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, string> = {
    event_button: State.DEFAULT,
    cards: State.ACTIVE_EVENTS,
    bet_items: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({
    ...DEFAULT_STATES,
  });

  /*
  Initialize widget with initial options
*/

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    contentWidth: getDefaultContentWidth(),
    bindingParams: {},
  });

  /*
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.content.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.content = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.containerWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, widgetFields);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing({ bottom: 12, left: 16 }),
        },
      }).options
    );

    const initialEventCardOptions = prefillEmptyOptions(
      fields.value.event_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
            displayMode: {
              layout: 1,
              cardsDistance: 16,
              eventInfoLayout: "row",
              betItemsLayout: DisplayOrientation.HORIZONTAL,
              betItemsDistance: 16,
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            margin: getDefaultSpacing({
              bottom: 16,
            }),
            padding: getDefaultSpacing(16),
          },
        },
      }).options
    );

    const initialEventTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            icon: {
              color: getDefaultFillValue("#69B1FF"),
              size: 16,
            },
          },
        },
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(13),
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialTeamPlayerOptions = prefillEmptyOptions(
      fields.value.event_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(13),
          color: getDefaultFillValue("#000000", 88),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const betItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing(8),
            name: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#e6f4ff"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialListItemMoreState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue(),
      };
    };

    const initialListItemMoreOptions = prefillEmptyOptions(
      fields.value.event_button.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [TextStyle.BOLD],
            states: {
              [State.HOVER]: initialListItemMoreState(),
              [State.ACTIVE]: initialListItemMoreState(),
              [State.DISABLED]: initialListItemMoreState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#000000", 6),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            margin: getDefaultSpacing({
              left: 16,
            }),
            padding: getDefaultSpacing({
              top: 4,
              bottom: 4,
              left: 8,
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialEventLiveStatusOptions = prefillEmptyOptions(
      fields.value.event_live_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F5222D"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing({
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_event_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          padding: getDefaultSpacing(16),
          theme: getDefaultTheme(13),
          alignment: Alignment.CENTER,
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_card.field,
      initialEventCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialEventTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_title.field,
      initialTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      betItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialListItemMoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_live_status.field,
      initialEventLiveStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_event_title.field,
      initialNoEventsTitleOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,
    initFields,
    initWidgetFieldsOptions,
  };
};
