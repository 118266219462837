<template>
  <div
    v-for="(provider, index) in widget.options.providers"
    :key="provider.provider_code"
    class="providers-list__item"
    :class="[`providers-list__item--${layoutPosition.position}`]"
    :style="cardItemStyle"
  >
    <WidgetField
      v-if="fields.providers_item_image.active"
      :widget="widget"
      :field="fields.providers_item_image.field"
      class="providers-list__field w-100"
    >
      <BasicImageField
        :field="fields.providers_item_image.field"
        :value="provider.logo.medium"
      />
    </WidgetField>

    <div class="w-100 providers-list__content">
      <WidgetField
        v-if="fields.providers_item_title.active"
        :widget="widget"
        :field="fields.providers_item_title.field"
        :style="cardsTitleInlineStyles"
        class="providers-list__title-field-container"
      >
        <BasicTitleField
          :field="fields.providers_item_title.field"
          :value="provider.name"
          class="providers-list__title-field"
          :state="cardsTitleLinkSelectedState"
        />
        <div class="providers-list__tooltip-icon">
          <a-tooltip
            :overlay-style="tooltipStyles"
            :visible="
              selectedDropdownItem ===
                fields.providers_item_tooltip.field.name && index < 1
            "
            :get-popup-container="getMenuContainer"
            placement="bottom"
            overlay-class-name="providers-list__tooltip-container"
          >
            <template #title>
              {{ provider.info || "Tooltip text" }}
            </template>

            <p v-if="tooltipIconComponent">
              <CommonIcon
                :name="tooltipIconComponent"
                :style="tooltipIconColorStyle"
              />
            </p>
          </a-tooltip>
        </div>
      </WidgetField>
      <!-- description -->
      <WidgetField
        v-if="provider.limits.length"
        :widget="widget"
        :field="fields.providers_item_limits.field"
        class="providers-list__field"
      >
        <BasicTitleField
          :field="fields.providers_item_limits.field"
          :value="getProviderDescription(index)"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.providers_item_button.active"
        :widget="widget"
        :field="fields.providers_item_button.field"
        class="providers-list__field"
      >
        <BasicButtonField
          :field="fields.providers_item_button.field"
          :current-state="cardsButtonState"
          :custom-value="providerButtonText"
        />
      </WidgetField>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ElementStyle } from "~~/models/common";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import {
  State,
  TextPositionComplex,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  layoutPosition: TextPositionComplex;
  cardItemStyle: ElementStyle;
  fields: IFields;
  cardsTitleInlineStyles: ElementStyle;
  tooltipStyles: ElementStyle;
  tooltipIconComponent: string | null;
  tooltipIconColorStyle: ElementStyle;
  cardsButtonState: State;
  selectedDropdownItem: string | null;
  cardsTitleLinkSelectedState: State;
  providerButtonText?: string;
}>();

const getMenuContainer = (trigger: HTMLElement): HTMLElement | null => {
  return trigger.parentElement;
};

const getProviderDescription = computed(() => {
  return (index: number): string => {
    return props.widget.options.providers[index].limits.join("<br />");
  };
});
</script>
<style lang="scss">
.providers-list {
  &__item {
    @include flex-column(center, space-around, 0);
    align-self: stretch;

    &--top {
      flex-direction: column-reverse;
    }

    &--right {
      flex-direction: row;
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  &__content {
    @include flex-column(stretch, flex-start, 0);
    align-self: stretch;
    flex: 1;
  }

  &__field {
    width: 100%;
  }

  &__title-field-container {
    width: 100%;
    flex: 1;
    @include flex(flex-start, flex-start, 8px);
  }

  &__title-field {
    .basic-title-widget__content {
      padding: 0 !important;
    }
  }

  &__tooltip-icon {
    line-height: 14px;
    height: 14px;
  }

  &__tooltip-container {
    font-size: 12px;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
  }
}
</style>
