<template>
  <WidgetField
    :widget="widget"
    :field="field"
    :style="fieldContainerStyle"
  >
    <div
      class="flexible-image-field"
      :style="imageContainerStyle"
    >
      <img
        class="flexible-image-field__image"
        :src="imageSrc"
        :style="imageStyleValue"
        draggable="false"
      />
    </div>
  </WidgetField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import useImageCdnChange from "~~/composables/useImageCdnChange";
import { ElementStyle } from "~~/models/common";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getCornerRadiusStyle,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  field: IWidgetField;
  value?: string;
  widget: IWidgetWithFields;
}>();

const containerOptions = computed<IWidgetOptions>(() => {
  return props.field.options.containerSize;
});

const containerWidthStyle = computed<ElementStyle>(() => {
  const widthOptions = containerOptions.value.width;

  if (widthOptions.type === ResizingType.FIXED) {
    return {
      width: getPxValueFromNumber(widthOptions.value),
    };
  }

  return {
    width: "100%",
  };
});

const containerHeightStyle = computed<ElementStyle>(() => {
  const heightOptions = containerOptions.value.height;

  if (heightOptions.type === ResizingType.FIXED) {
    return {
      height: getPxValueFromNumber(heightOptions.value),
    };
  }

  return {
    height: "100%",
  };
});

const fieldContainerWidth = computed<ElementStyle>(() => {
  const widthOptions = containerOptions.value.width;

  if (widthOptions.type === ResizingType.FIXED) {
    return {
      width: "initial",
      display: "inline-block",
      minWidth: getPxValueFromNumber(widthOptions.value),
    };
  }

  return {
    width: "100%",
  };
});

const fieldContainerHeight = computed<ElementStyle>(() => {
  const heightOptions = containerOptions.value.height;

  if (heightOptions.type === ResizingType.FIXED) {
    return {
      minHeight: getPxValueFromNumber(heightOptions.value),
    };
  }

  return {
    height: "100%",
  };
});

const fieldContainerStyle = computed<ElementStyle>(() => {
  return {
    ...fieldContainerWidth.value,
    ...fieldContainerHeight.value,
    overflow: "hidden",
    maxWidth: "100%",
  };
});

const imageResizingStyle = computed<ElementStyle>(() => {
  const resizing = props.field.options.resizing;

  // height: resizing.type === ResizingType.FILL ? `100%` : "initial",

  const styles: ElementStyle = {
    objectFit: resizing.type === ResizingType.FIT ? "contain" : "cover",
    width: resizing.type === ResizingType.FILL ? "100%" : "unset",
  };

  if (resizing.type === ResizingType.FIT) {
    styles.maxHeight = "100%";
  }

  if (resizing.type === ResizingType.FILL) {
    styles.objectPosition = `${resizing.x}% ${resizing.y}%`;
    styles.height = "100%";
  }

  return styles;
});

const imageStyleValue = computed<ElementStyle>(() => {
  const cornerRadiusStyle = getCornerRadiusStyle(
    props.field.options.cornerRadius
  ) as ElementStyle;

  return {
    ...cornerRadiusStyle,
    ...imageResizingStyle.value,
  };
});

const { getLocalizedValue } = useLocalizedValue();

const imageSrc = computed<string>(() => {
  const localizedValue = getLocalizedValue.value(
    props.value || props.field.value,
    ""
  );

  if (typeof localizedValue !== "string") {
    return "";
  }

  return useImageCdnChange(localizedValue);
});

const imageContainerStyle = computed<ElementStyle>(() => {
  const resizing = props.field.options.resizing;

  const position = resizing.position.split(" ");
  const align = getFlexPosition(position[0]);
  const justify = getFlexPosition(position[1]);

  const styles: ElementStyle = {
    ...getCommonStyles({
      ...props.field.options,
      cornerRadius: 0,
      shadow: null,
    }),
    ...containerWidthStyle.value,
    ...containerHeightStyle.value,
    alignItems: align,
    justifyContent: justify,
    maxWidth: "100%",
  };

  return styles;
});
</script>

<style lang="scss">
.flexible-image-field {
  @include flex(center, center, 0);
  border: 1px solid transparent;

  &__image {
    max-width: 100%;
  }
}
</style>
