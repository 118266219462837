import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { FormScreen } from "~~/models/widgets/form.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateCheckboxCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateStageButtonCssString,
  generateTextChooseIconCssString,
  generateScreensCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import { generateFormSuccessCssString } from "../utils/generate-form-success";

export const generateRegisterFormWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const form = widget.content["form"];
  const submitButton = widget.content["submit"];
  const title = widget.content["title"];
  const customFields = widget.options._customFields;
  const submitButtonClassName = generateClassName("submitButton");
  const titleClassName = generateClassName("title");
  const successImage = widget.content["success_image"];
  const successButton = widget.content["success_button"];
  const successDescription = widget.content["success_description_1"];
  const successTitle = widget.content["success_title"];
  const description1 = widget.content["description_1"];
  const description2 = widget.content["description_2"];

  // Success
  successImage.options._cssClass = generateClassName("succesImage");
  successImage.options._cssClass = generateClassName("succesImage");
  successButton.options._cssClass = generateClassName("succesButton");
  successDescription.options._cssClass = generateClassName("succesDescription");
  successTitle.options._cssClass = generateClassName("succesTitle");
  successButton.options.iconSettings._cssClass =
    generateClassName("buttonIcon");

  // Widget class name
  widget.options._cssClass = generateClassName("RegisterFormWidget");

  const screens = [FormScreen.SUCCESS];

  // Form, Label, Field, Error, Tooltip class names
  form.options._cssClass = generateClassName("form");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  description1.options._cssClass = generateClassName("description_1_");
  description2.options._cssClass = generateClassName("description_2_");

  // Submit button class name
  submitButton.options._cssClass = submitButtonClassName;
  submitButton.options.iconSettings._cssClass = generateClassName("buttonIcon");

  // customFields.buttons_styling.options._cssClass = submitButtonClassName;

  // Title class name
  title.options._cssClass = titleClassName;
  customFields.title_styling.options._cssClass = titleClassName;

  if (customFields.dropdowns_styling) {
    // Dropdown class names
    customFields.dropdowns_styling.options._cssClass =
      generateClassName("dropdown");
    customFields.dropdown_menu_styling.options._cssClass =
      generateClassName("dropdownMenu");
    customFields.dropdown_menu_items_styling.options._cssClass =
      generateClassName("dropdownItems");
  }

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.checkboxes_styling) {
    customFields.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    customFields.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");

    customFields.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (customFields.checkboxes_styling.options.check.icon) {
      customFields.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  const checkboxes =
    customFields.checkboxes_styling &&
    customFields.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          customFields.checkboxes_styling,
          customFields.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(customFields.checkboxes_styling);

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  return pipeSync<string>(
    generateStringDefault({
      ...widget,
      options: {
        ...widget.options,
        size: null,
        containerWidth: null,
      },
    }),
    generateFormCssString(form, widget),
    generateScreensCssString(widget, "RegisterFormWidget", screens),
    generateStringDefault(customFields.title_styling),
    generateStageButtonCssString(submitButton),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    checkboxes,
    generateFormSuccessCssString(widget as IPageContentWidget),
    generateStringDefault(description1),
    generateStringDefault(description2),
    ...dropdowns
  )("");
};
