import { TextInputValue } from "~~/models/common/text-input.enum";

export const useText = (maxLength: number) => {
  const canUpdateInputValue = (str: TextInputValue): boolean => {
    /* 
      If max length is not specified
      don't allow to edit input
    */
    if (maxLength === 0) {
      return false;
    }

    /* 
      We need to make sure str is not
      null or undefined
    */
    if (!str) {
      return true;
    }

    if (maxLength < str.length) {
      return false;
    }

    return true;
  };

  return {
    canUpdateInputValue,
  };
};
