<template>
  <div
    v-if="hasDesignPermissions"
    class="config-button-display-settings p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container"
  >
    <div class="w-100">
      <p
        v-if="showTitle"
        class="sub-heading config-button-display-settings__title"
      >
        Display settings
      </p>

      <div v-if="modelValue.resizing">
        <p
          v-if="label"
          class="widget-control__label m-t-16"
          :class="{
            'bold': isBold,
          }"
        >
          {{ label }}
        </p>

        <div class="config-button-display-settings__radio-group-container">
          <a-radio-group
            :value="modelValue.resizing"
            class="config-button-display-settings__radio-group w-100"
            @update:value="handleUpdate('resizing', $event)"
          >
            <a-radio-button :value="ResizingType.FILL">
              Fill container
            </a-radio-button>
            <a-radio-button :value="ResizingType.HUG">
              Hug content
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
    </div>

    <ConfigAlignment
      v-if="isAlignmentVisible"
      :model-value="modelValue.alignment || null"
      :is-text-content-icons="isTextContentIcons"
      class="config-button-display-settings__alignment"
      @update:model-value="handleUpdate('alignment', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    modelValue: {
      resizing?: string;
      alignment: string;
    };
    showAlignmentOnFillOnly?: boolean;
    showAlignmentOnHugOnly?: boolean;
    isTextContentIcons?: boolean;
    label?: string;
    showTitle?: boolean;
    isBold?: boolean;
    forceAlignmentHide?: boolean;
  }>(),
  {
    label: "",
    showTitle: true,
    isBold: false,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: { [key: string]: string }): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const isAlignmentVisible = computed<boolean>(() => {
  if (props.forceAlignmentHide) {
    return false;
  }

  if (props.showAlignmentOnFillOnly) {
    return props.modelValue.resizing === ResizingType.FILL;
  }

  if (props.showAlignmentOnHugOnly) {
    return props.modelValue.resizing === ResizingType.HUG;
  }

  return true;
});

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-button-display-settings {
  &__container {
    @include flex(center, space-around, $space-s);
  }

  &__title {
    margin-bottom: $space-m;
  }

  .config-alignment {
    padding: $space-m 0 0;
    border-bottom: unset;
  }

  .ant-radio-button-wrapper {
    width: 50%;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }

  &__radio-group {
    white-space: nowrap;
  }

  .bold {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 8px;
  }
}
</style>
