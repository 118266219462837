import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getSpacing,
  getColorFromHex,
  getDecorationValue,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";

import { IFields } from "../../useWidgetFields";

export const useBetslipSuccessInlineStyles = (fields: ComputedRef<IFields>) => {
  const successMessageContainerInlineStyles = computed<ElementStyle>(() => {
    const successContainerOptions =
      fields.value.success_message_title.field.options;
    return {
      ...getCommonStyles(successContainerOptions),
      color: getColorFromHex(successContainerOptions.color)!,
      fontSize: `${successContainerOptions.theme.value}px`,
      fontFamily: successContainerOptions.fontFamily,
      ...getSpacing(successContainerOptions.margins, SpacingKeyName.MARGIN),
      ...getDecorationValue(successContainerOptions.decoration),
      gap: `${successContainerOptions.iconSettings.gap}px`,
    };
  });

  const successTitleInlineStyles = computed<ElementStyle>(() => {
    return {
      gap: `${fields.value.success_message_title.field.options.iconSettings.gap}px`,
    };
  });

  const successIconInlineStyles = computed<ElementStyle>(() => {
    const successIconOptions =
      fields.value.success_message_title.field.options.iconSettings;
    return {
      color: getColorFromHex(successIconOptions.textColor)!,
      fontSize: `${successIconOptions.iconSize}px`,
    };
  });

  const successMessagePrintInlineStyles = computed<ElementStyle>(() => {
    const printSettings =
      fields.value.success_message_title.field.options.printSettings;
    return {
      color: getColorFromHex(printSettings.color)!,
      fontSize: `${printSettings.theme.value}px`,
      fontFamily: `${printSettings.fontFamily}`,
    };
  });

  return {
    successMessageContainerInlineStyles,
    successTitleInlineStyles,
    successIconInlineStyles,
    successMessagePrintInlineStyles,
  };
};
