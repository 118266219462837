<template>
  <div
    :style="modalStyles"
    class="bonuses-widget-modal"
  >
    <div>
      <div class="bonuses-widget-modal__header">
        <div class="bonuses-widget-modal__title">
          <slot name="title"></slot>
        </div>

        <div class="bonuses-widget-modal__close-btn">
          <slot name="close_button"></slot>
        </div>
      </div>
      <slot name="description"></slot>

      <div class="bonuses-widget-modal__btns">
        <slot name="primary_button"></slot>
        <slot name="secondary_button"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getSizeValue } from "~~/assets/utils";
import { CellSizeType, Dimension, Sizing } from "~~/models/grid.interface";
import { Alignment } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  field: IWidgetField;
}>();

const getModalAlignment = (alignment: Alignment): ElementStyle => {
  if (alignment === Alignment.LEFT) {
    return {
      marginRight: "auto",
    };
  }

  if (alignment === Alignment.RIGHT) {
    return {
      marginLeft: "auto",
    };
  }

  return {
    marginLeft: "auto",
    marginRight: "auto",
  };
};

const containerPositionStyles = computed<ElementStyle>(() => {
  if (
    props.field.options.contentWidth?.type === Sizing.ADAPTIVE ||
    props.field.options.width?.type === CellSizeType.FILL
  ) {
    return {
      width: "100%",
    };
  }

  if (props.field.options.width?.type === CellSizeType.HUG) {
    return {
      width: "auto",
      ...getModalAlignment(
        props.field.options.contentWidth?.alignment ??
          props.field.options.alignment
      ),
    };
  }

  return {
    width: getSizeValue(
      props.field.options.width.value ?? {
        value: props.field.options.contentWidth?.width,
        type: Dimension.PX,
      }
    ),
    ...getModalAlignment(
      props.field.options.contentWidth?.alignment ??
        props.field.options.alignment
    ),
  };
});

const modalStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...containerPositionStyles.value,
  };
});
</script>

<style lang="scss">
.bonuses-widget-modal {
  position: relative;
  max-width: 100%;
  &__btns {
    @include flex(center, flex-start, 0);
  }

  &__header {
    @include flex(flex-start, space-between, 0);
  }

  &__title {
    flex: 1;
  }
}
</style>
