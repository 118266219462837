export class Events {
  private _events: {
    [key: string]: Array<(nativeEvent: Event) => any>;
  };

  constructor() {
    this._events = {};
  }

  private _runThroughEventListeners(
    eventName: string,
    nativeEvent: Event
  ): void {
    this._events[eventName].forEach(cb => {
      cb(nativeEvent);
    });
  }

  public on(eventName: string, callback: (nativeEvent: Event) => any): void {
    if (!this._events[eventName]) {
      console.warn(`Event ${eventName} does not exist`);
      return;
    }

    this._events[eventName].push(callback);
  }

  public off(eventName: string, callback: () => any): void {
    if (!this._events[eventName]) {
      console.warn(`Event ${eventName} does not exist`);
      return;
    }

    const index = this._events[eventName].indexOf(callback);

    if (index < -1) {
      return;
    }

    this._events[eventName].splice(index, 1);
  }

  public createEvent(
    eventName: string,
    subscribeRealEvent: (eventHandler: (nativeEvent: Event) => void) => void
  ): void {
    if (this._events[eventName]) {
      console.warn(`Event ${eventName} already exists`);
      return;
    }

    this._events[eventName] = [];

    subscribeRealEvent(this._runThroughEventListeners.bind(this, eventName));
  }
}
