import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  INFORMATION_INITIAL,
  NO_PROVIDERS_INITIAL,
  PAYMENT_PROVIDERS_WIDGET_INITIAL,
  PROVIDERS_CARD_BUTTON_INITIAL,
  PROVIDERS_CARD_INITIAL,
  PROVIDERS_LIST_INITIAL,
} from "~~/constants/configs/payment-providers-list-fenix/initial-constants";
import { TOOLTIPS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/tooltips-styling";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["fill"], {
    ...PAYMENT_PROVIDERS_WIDGET_INITIAL().options,
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("page_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        padding: {
          top: 0,
          right: 0,
          bottom: 16,
          left: 0,
        },
      },
    });

    addFieldInitialOptions("providers_list", {
      data: PROVIDERS_LIST_INITIAL(),
    });

    addFieldInitialOptions("no_providers_title", {
      data: NO_PROVIDERS_INITIAL(),
    });

    addFieldInitialOptions("provider_image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
    });

    addFieldInitialOptions("provider_card", {
      data: PROVIDERS_CARD_INITIAL(),
    });

    addFieldInitialOptions("provider_name", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
    });

    addFieldInitialOptions("provider_description", {
      data: TITLE_INITIAL(),
      values: {
        theme: 13,
      },
      exclude: ["link"],
    });

    addFieldInitialOptions("provider_button", {
      data: PROVIDERS_CARD_BUTTON_INITIAL(),
      exclude: ["link"],
    });

    addFieldInitialOptions("tooltip", {
      data: TOOLTIPS_STYLE_INITIAL(),
    });

    addFieldInitialOptions("information_message", {
      data: INFORMATION_INITIAL(),
    });

    addFieldInitialOptions("information_title", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("information_description", {
      data: TITLE_INITIAL(),
      values: {
        theme: 13,
      },
    });

    addFieldInitialOptions("information_button", {
      data: PROVIDERS_CARD_BUTTON_INITIAL(),
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,

    init,
  };
};
