<template>
  <div class="sportsbook-events">
    <div
      :style="containerStyles"
      class="sportsbook-events__cards"
    >
      <div
        v-for="(item, index) in currItems"
        :key="index"
        :style="eventCardStyles"
      >
        <div>
          <WidgetField
            v-if="leagueField.options._active"
            :widget="widget"
            :field="leagueField"
          >
            <BasicTitleField :field="leagueField">
              <span> League </span>
            </BasicTitleField>
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="eventInfoField"
          >
            <div
              :style="eventInfoContanerStyles"
              class="sportsbook-events__event-info"
            >
              <div
                :style="eventInfoHeaderStyles"
                class="sportsbook-events__header"
              >
                <span class="sportsbook-events__time">
                  <WidgetField
                    v-if="startTimeField.options._active"
                    :widget="widget"
                    :field="startTimeField"
                  >
                    <BasicTitleField :field="startTimeField">
                      <span class="sportsbook-events__time-element">
                        <CommonIcon
                          v-if="startTimeField.options.icon._active"
                          :style="startTimeIconStyles"
                          :name="startTimeField.options.icon.value"
                          class="sportsbook-events__icon"
                        />

                        <span :style="currentTimeColorStyles"> 00:00 </span>
                      </span>
                    </BasicTitleField>
                  </WidgetField>

                  <WidgetField
                    v-if="dateField.options._active"
                    :widget="widget"
                    :field="dateField"
                  >
                    <BasicTitleField :field="dateField">
                      <span class="sportsbook-events__time-element">
                        <CommonIcon
                          v-if="dateField.options.icon._active"
                          :style="dateIconStyles"
                          :name="dateField.options.icon.value"
                          class="sportsbook-events__icon"
                        />

                        <span :style="currentDateColorStyles"> 00.00.00 </span>
                      </span>
                    </BasicTitleField>
                  </WidgetField>
                </span>

                <WidgetField
                  :widget="widget"
                  :field="teamPlayerField"
                >
                  <BasicTitleField :field="teamPlayerField">
                    <span :style="currentNameColorStyles">
                      Team / Player #1 - Team / Player #2
                    </span>
                  </BasicTitleField>
                </WidgetField>
              </div>

              <div class="sportsbook-events__top-content">
                <WidgetField
                  :widget="widget"
                  :field="betBuilderButtonField"
                >
                  <BasicTextButtonField
                    :field="betBuilderButtonField"
                    :current-state="betBuilderState"
                  />
                </WidgetField>

                <WidgetField
                  v-if="favoriteIconField.options._active"
                  :widget="widget"
                  :field="favoriteIconField"
                >
                  <CommonIcon
                    :style="favIconStyles"
                    name="ant-design:star-outlined"
                  />
                </WidgetField>
              </div>
            </div>
          </WidgetField>
        </div>

        <div>
          <div
            :style="itemsContanerStyles"
            class="sportsbook-events__items-container"
          >
            <WidgetField
              :widget="widget"
              :field="betItemField"
              class="sportsbook-events__items-list"
            >
              <div
                :style="betItemsContainerStyles"
                class="sportsbook-events__items-list"
                :class="{
                  'sportsbook-events__items-list--grid':
                    props.field.options.items.displayMode ===
                    DisplayOrientation.HORIZONTAL,
                }"
              >
                <div
                  v-for="(inner, index2) in item.bet_items"
                  :key="index2"
                  class="sportsbook-events__item"
                  :style="betItemContainerStyles"
                >
                  <SportsbookBetItem
                    :item-value="inner"
                    :state-options="currentStateOptions"
                    :bet-item-field="betItemField"
                  />
                </div>
              </div>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="eventButtonField"
            >
              <span
                :style="eventButtonStyles"
                class="sportsbook-events__button"
              >
                {{ item.event_button }}
              </span>
            </WidgetField>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getWidthStyles,
  getBackgroundColorStyle,
  getCornerRadiusStyle,
  getBoxShadowStyle,
  getBorderStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

type Item = {
  start_time: string;
  date: string;
  league: string;
  team_player: string;
  event_button: string;
  bet_items: {
    name: string;
    coef: string;
  }[];
};

const defaultItems: Item[] = [
  {
    start_time: "00:00",
    date: "00.00.00",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "00:00",
    date: "00.00.00",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "00:00",
    date: "00.00.00",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  eventInfoField: IWidgetField;
  startTimeField: IWidgetField;
  leagueField: IWidgetField;
  favoriteIconField: IWidgetField;
  teamPlayerField: IWidgetField;
  betItemField: IWidgetField;
  eventButtonField: IWidgetField;
  betBuilderButtonField: IWidgetField;
  betItemState: State;
  listItemMoreState: State;
  betBuilderState: State;
  items: Item[];
  dateField: IWidgetField;
  eventCardsState: State;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getDisplayGridStyles(props.field.options.gridSettings),
  };
});

const eventCardStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {
      ...getCommonStyles(props.field.options.card, props.eventCardsState),
    };
  }

  return {
    ...getBackgroundColorStyle(
      props.field.options.states[props.eventCardsState].card.fill
    ),
    ...getBoxShadowStyle(
      props.field.options.states[props.eventCardsState].card.shadow
    ),
    ...getBorderStyle(
      props.field.options.states[props.eventCardsState].card.border
    ),
    ...getCornerRadiusStyle(props.field.options.card.cornerRadius),
    ...getSpacing(
      props.field.options.card.spacing.margin,
      SpacingKeyName.MARGIN
    ),
    ...getSpacing(
      props.field.options.card.spacing.padding,
      SpacingKeyName.PADDING
    ),
  };
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const flexDirection =
    props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  const style = getCommonStyles(props.betItemField.options.container);

  return {
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
    flexDirection,
    ...style,
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});

const currentTimeColorStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {};
  }

  return {
    ...getColorStyle(
      props.field.options.states[props.eventCardsState].time.color
    ),
  };
});

const startTimeIconStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {
      ...getColorStyle(props.startTimeField.options.icon.color),
      ...getFontSizeStyle(props.startTimeField.options.icon.size),
    };
  }

  return {
    ...getColorStyle(
      props.field.options.states[props.eventCardsState].time.icon.color
    ),
    ...getFontSizeStyle(props.startTimeField.options.icon.size),
  };
});

const currentDateColorStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {};
  }

  return {
    ...getColorStyle(
      props.field.options.states[props.eventCardsState].date.color
    ),
  };
});

const dateIconStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {
      ...getColorStyle(props.dateField.options.icon.color),
      ...getFontSizeStyle(props.dateField.options.icon.size),
    };
  }

  return {
    ...getColorStyle(
      props.field.options.states[props.eventCardsState].date.icon.color
    ),
    ...getFontSizeStyle(props.dateField.options.icon.size),
  };
});

const currentNameColorStyles = computed<ElementStyle>(() => {
  if (props.eventCardsState === State.DEFAULT) {
    return {};
  }

  return {
    ...getColorStyle(
      props.field.options.states[props.eventCardsState].name.color
    ),
  };
});

const favIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.favoriteIconField.options.color),
    ...getFontSizeStyle(props.favoriteIconField.options.size),
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemState === State.DEFAULT) {
    return props.betItemField.options;
  }

  return props.betItemField.options.states[props.betItemState];
});

const itemsContanerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
  };
});

const currentEventButtonStateOptions = computed<IWidgetOptions>(() => {
  if (props.listItemMoreState === State.DEFAULT) {
    return props.eventButtonField.options;
  }

  return props.eventButtonField.options.states[props.listItemMoreState];
});

const eventButtonStyles = computed<ElementStyle>(() => {
  return {
    width: getWidthStyles(props.eventButtonField.options.width || {}).width,
    textAlign: "center",
    ...getCommonStyles({
      ...props.eventButtonField.options,
      ...currentEventButtonStateOptions.value,
    }),
    ...getColorStyle(currentEventButtonStateOptions.value.color),
    ...getFontSizeStyle(props.eventButtonField.options.theme),
    ...getDecorationValue(props.eventButtonField.options.decoration),
  };
});

const eventInfoContanerStyles = computed<ElementStyle>(() => {
  const baseStyles = {
    ...getCommonStyles(props.eventInfoField.options),
    gap: getPxValueFromNumber(props.eventInfoField.options.display.distance),
    "--gap": getPxValueFromNumber(
      props.eventInfoField.options.display.distance
    ),
  };

  return baseStyles;
});

const eventInfoHeaderStyles = computed<ElementStyle>(() => {
  const dir =
    props.eventInfoField.options.display.layout ===
    DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  const gap = getPxValueFromNumber(
    props.eventInfoField.options.display.distance
  );

  return {
    flexDirection: dir,
    alignItems: dir === "column" ? "stretch" : "center",
    gap,
  };
});

const currItems = computed<Item[]>(() => {
  if (props.items) {
    return props.items;
  }

  return defaultItems;
});
</script>

<style lang="scss" scoped>
.sportsbook-events {
  &__cards {
    display: grid;
    overflow: hidden;
  }

  &__top-content {
    @include flex(center, flex-start, 0);
    gap: var(--gap);
  }

  &__time {
    @include flex(flex-start, flex-start, 0);
    gap: var(--gap);
  }

  &__time-element {
    @include flex(center, flex-start, 4px);
  }

  &__header {
    @include flex-column(stretch, flex-start, 0);
    flex: 1;
    gap: var(--gap);
  }

  &__items-container {
    @include flex(center, flex-start, 0);
    overflow: hidden;
  }

  &__event-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__items-list {
    @include flex(flex-start, flex-start, 0);
    flex-grow: 1;
    width: 100%;

    &--grid {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      .sportsbook-events__item {
        width: 100%;
      }
    }
  }

  &__items-list {
    flex: 1;
  }

  &__item {
    flex: 1;
    width: 0;
  }

  &__button {
    display: inline-block;
    padding: 4px 8px;
  }
}
</style>
