import type { Ref } from "vue";

import { type IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

import { useButtonControls } from "../card/useButtonControls";

import { useTextSliderWidgetControls } from "./useTextSliderWidgetControls";
import { useTitleControls } from "./useTitleControls";
import { useSlideControls } from "./useSlideControls";

export const useTextSliderWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const DROPDOWN_ITEMS = computed(() => [
    generateDropdownItem(
      useLocalizedValue().getLocalizedValue.value(
        widgetSettingsStore.selectedField?.value as string
      ),
      "slide",
      "slide"
    ),
    generateDropdownItem("Title", "title", "title"),
    generateDropdownItem("Tile link states", "linkStates", "linkStates"),
    generateDropdownItem("Description", "description_1", "description_1"),
    generateDropdownItem("Button", "button", "button"),
  ]);

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Navigation", "navigation"),
    ],
    slide: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const {
    widgetDesignControls,
    widgetContentControls,
    widgetNavigationControls,
  } = useTextSliderWidgetControls(widget);

  const { slideDesignControls, slideContentControls, slideStatesControls } =
    useSlideControls(widget, states, emit);

  const { titleControls, titleLinkStatesControls } = useTitleControls(
    states,
    emit
  );

  const buttonState = computed<string>(() => {
    return states.value.button;
  });

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls({
      state: buttonState,
      stateHandler: (state: State) => {
        emit("update-state", { state: "button", value: state });
      },
    });

  return {
    DROPDOWN_ITEMS,
    TABS,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgetNavigationControls,

    slideDesignControls,
    slideContentControls,
    slideStatesControls,

    titleControls,
    titleLinkStatesControls,

    buttonDesignControls,
    buttonStatesControls,
    buttonContentControls,
  };
};
