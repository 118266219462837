import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  Alignment,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { CellSizeType } from "~~/models/grid.interface";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useCasinoListGamesControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const casinoListGamesDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.game_cards.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.game_cards.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.game_cards.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.game_cards.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.game_cards.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const cardsLayout = computed(() => {
    return (
      selectedField.value?.options.display.layout ||
      DisplayOrientation.HORIZONTAL
    );
  });

  const casinoListGamesContentControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.games_status,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.with_active_games,
              value: State.WITH_ACTIVE_GAMES,
            },
            {
              label: STATE_KEY_LABELS.no_active_games,
              value: State.NO_ACTIVE_GAMES,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "games_status", value });
        },
      },
    ];

    if (states.value.games_status === State.WITH_ACTIVE_GAMES) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Size"),
          controls: [
            {
              componentPath: "common/ConfigDimension",
              valuePath: "options.size.width",
              valueSource: selectedField.value,
              options: {
                label: "Width",
                icon: "ant-design:column-width-outlined",
                exclude: [CellSizeType.HUG],
              },
            },
            {
              componentPath: "common/ConfigDimensionValue",
              valuePath: "options.size.height.value",
              valueSource: selectedField.value,
              className: "group-control-element m-t-16 p-b-16 p-l-16 p-r-16",
              options: {
                label: "Height",
                icon: "ant-design:column-height-outlined",
              },
            },
          ],
        },
        {
          section: generateDesignSectionName("Display settings"),
          controls: [
            {
              componentPath: "common/ConfigRadioTextElements",
              valuePath: "options.display.layout",
              valueSource: selectedField.value,
              options: {
                label: "Layout",
                items: [
                  { label: "Horizontal", value: DisplayOrientation.HORIZONTAL },
                  { label: "Vertical", value: DisplayOrientation.VERTICAL },
                  { label: "Wrap", value: DisplayOrientation.WRAP },
                ],
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.display.gap.col",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Gap",
                showIconPrefix:
                  cardsLayout.value === DisplayOrientation.WRAP ? true : false,
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.display.gap.row",
              valueSource: selectedField.value,
              className: "group-control-element",
              visible:
                cardsLayout.value === DisplayOrientation.WRAP ? true : false,
              options: {
                label: "",
                showIconPrefix: true,
                iconName: "ant-design:column-height-outlined",
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "ConfigAlignmentFull",
              valuePath: "options.display.alignment",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Alignment",
                layout: cardsLayout.value,
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              { label: "Photo", value: "photo" },
              { label: "Cards elements", value: "cards_elements" },
            ],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "value",
          valueSource: fields.value.no_games_title.field,
          options: {
            placeholder: "Text",
            label: "Text",
          },
        },
        typography({ source: fields.value.no_games_title.field }, "Text"),
      ];
    }
  });

  const currentListGamesPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const casinoListGamesStatesControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
    ];

    if (states.value[selectedField.value!.name] === State.HOVER) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Fill"),
          controls: [
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${currentListGamesPath.value}.fill`,
              valueSource: selectedField.value,
              options: {
                label: "Card",
                type: ColorPickerType.BACKGROUND,
              },
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${currentListGamesPath.value}.overlay`,
              valueSource: selectedField.value,
              options: {
                label: "Blackout",
                type: ColorPickerType.BACKGROUND,
              },
            },
          ],
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentListGamesPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentListGamesPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [{ label: "Hover content", value: "hover_content" }],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentListGamesPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentListGamesPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentListGamesPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ];
    }
  });

  const casinoListGamesPhotoControls = computed(() => {
    return [
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.photo.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.photo.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.photo.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
    ];
  });

  const casinoListGamesCardsElementsControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigCommonRadio",
            valuePath: "options.display.position",
            valueSource: fields.value.cards_elements.field,
            className: "group-control-element--child",
            options: {
              label: "Position",
              firstVariant: "",
              firstVariantIcon: {
                icon: "custom:unordered-list-outlined",
              },
              firstValue: Alignment.LEFT,
              secondaryVariant: "",
              secondaryVariantIcon: {
                className: "r-180",
                icon: "custom:unordered-list-outlined",
              },
              secondaryValue: Alignment.RIGHT,
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.cards_elements.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.cards_elements.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Promo tag", value: "promo_tag" },
            { label: "Favorite icon", value: "favorite_icon" },
          ],
        },
      },
    ];
  });

  return {
    casinoListGamesDesignControls,
    casinoListGamesContentControls,
    casinoListGamesStatesControls,

    casinoListGamesPhotoControls,
    casinoListGamesCardsElementsControls,
  };
};
