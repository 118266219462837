import { nextTick } from "vue";

import { useGridConfig } from "~~/store/grid";
import {
  RECALCULATE_DIVIDER_FREQUENCY,
  PAYLOAD_DND_KEY,
  DND_CONTAINER_ELEMENT_ID,
  DND_DIVIDER_ID,
} from "~~/constants/dnd";
import {
  findCell,
  findParentLevelCell,
  hasChildNestingLevel,
  findCellRootElement,
} from "~~/assets/utils/grid/grid-tree";
import { getDefaultContainerWidth } from "~~/assets/utils/grid/grid-cell-width";
import {
  throttle,
  getCurrentDatasetElementId,
  getClosestPossibleDragTargetElement,
} from "~~/assets/utils";
import {
  ICell,
  CellId,
  NestingLevel,
  IInsertPosition,
  IGridDraggable,
} from "~~/models/grid.interface";
import { useDivider } from "~~/components/composables/grid/useDivider";
import { IDraggablePayload, IClientBoundingRect } from "~~/models/dnd.model";

export const useGridDnd = () => {
  const store = useGridConfig();
  const { currentLayout, currentDimensions } = storeToRefs(store);

  const {
    insertPosition,
    resetInsertPosition,
    findNearestRootBlockIndex,
    setOutsideCellDividerRenderData,
    setInsideCellDividerRenderData,
    calculateRootRowsDimensions,
  } = useDivider(currentLayout, true);

  const workspaceRootYPosition = ref<number>(0);

  const handleUpdateContainerPosition = (e: IClientBoundingRect) => {
    workspaceRootYPosition.value = e.top;
  };

  const moveGridElement = (
    cellToMoveId: CellId,
    targetCellId: CellId,
    cells: ICell[],
    insertPosition: IInsertPosition
  ): void => {
    /*
      We're replacing element to root level
    */
    if (targetCellId === DND_CONTAINER_ELEMENT_ID) {
      store.moveCellToRoot(cellToMoveId, insertPosition);
      return;
    }

    const cellToMove = findCell(cells, cellToMoveId);
    const targetCell = findCell(cells, targetCellId);

    if (!cellToMove || !targetCell) {
      return;
    }

    const cellToMoveRoot = findCellRootElement(cells, cellToMove);
    const targetCellRoot = findCellRootElement(cells, targetCell);

    /* 
      Do not allow cell to drop on itself
    */
    if (cellToMove.cellId === targetCell.cellId) {
      return;
    }

    /* 
      Do not allow parent cell to drop on it's own parent row
    */
    if (
      cellToMove.settings.level === NestingLevel.PARENT &&
      cellToMoveRoot?.cellId === targetCellRoot?.cellId
    ) {
      return;
    }

    /*
      We can not drag parent row into other container if it has children(with NestingLevel CHILD)
    */
    const hasChildNesting = hasChildNestingLevel(cells, cellToMove.cellId);

    if (cellToMove?.settings.level === NestingLevel.PARENT && hasChildNesting) {
      return;
    }

    /* 
      Target (hovered) cell is NestingLevel.PARENT type without children,
      so we can put our cell inside
    */
    if (
      targetCell.settings.level === NestingLevel.PARENT &&
      !targetCell.children.length
    ) {
      store.moveGridCellInside(cellToMove.cellId, targetCellId, -1);
      return;
    }

    /* 
      Target is NestingLevel.CHILD element. 
      We need to make current (dragging) element as 
      sibling to target
    */
    const targetCellParent = findParentLevelCell(cells, targetCell);

    if (!targetCellParent) {
      return;
    }

    store.moveGridCellInside(
      cellToMove.cellId,
      targetCellParent.cellId,
      insertPosition.index + insertPosition.insertionRule
    );
  };

  const moveElementFromSidebar = (
    targetId: CellId,
    columns: number,
    insertPosition: IInsertPosition
  ): void => {
    /*
      Root workspace is target
      we need to add new row on root level
    */
    if (targetId === DND_CONTAINER_ELEMENT_ID) {
      store.addCell(
        columns,
        NestingLevel.PARENT,
        insertPosition.index + insertPosition.insertionRule
      );
      return;
    }

    const cell = findCell(currentLayout.value, targetId);

    if (!cell) {
      return;
    }

    /* 
      If target cell is parent level and without children,
      create new container and paste cells
      inside
    */
    if (cell.settings.level === NestingLevel.PARENT && !cell.children.length) {
      store.addCell(columns, NestingLevel.CHILD, -1, {
        parentId: cell.cellId,
        parentSizing: cell.settings.sizing,
        parentWidth: getDefaultContainerWidth(
          cell.settings.sizing,
          Number(cell.settings.width)
        ),
      });

      return;
    }

    /* 
      Otherwise get current parent of cell
      and paste cells there
    */
    const parent: ICell | null = findParentLevelCell(currentLayout.value, cell);

    if (!parent) {
      return;
    }

    store.addCell(
      columns,
      NestingLevel.CHILD,
      insertPosition.index + insertPosition.insertionRule,
      { parentId: parent.cellId }
    );
  };

  const handleBlockDrop = (event: DragEvent): void => {
    const target = event.target as HTMLElement;
    const targetId: CellId | null = getCurrentDatasetElementId(target);

    /*
      If no target id,
      drop was not successfull
    */
    if (targetId === null) {
      return;
    }

    const payloadStringified = event.dataTransfer?.getData(
      PAYLOAD_DND_KEY
    ) as string;

    const payload: IDraggablePayload = JSON.parse(payloadStringified);
    const dndPayload: IGridDraggable = payload.payload;
    const { columns, isGridElement, draggingCellId } = dndPayload;

    /*
      We're moving element from grid
    */
    if (isGridElement) {
      moveGridElement(
        draggingCellId,
        targetId,
        currentLayout.value,
        insertPosition.value
      );

      /* 
        Recalculate root rows dimensions 
        after dom updated
      */
      nextTick(() => {
        calculateRootRowsDimensions();
      });
      return;
    }

    /* We're moving element from sidebar */
    moveElementFromSidebar(targetId, columns, insertPosition.value);

    resetInsertPosition();

    /* 
      Recalculate root rows dimensions 
      after dom updated
    */
    nextTick(() => {
      calculateRootRowsDimensions();
    });
  };

  const handleDragOver = throttle((e: DragEvent) => {
    let target = getClosestPossibleDragTargetElement(e.target as HTMLElement);
    /*
      Get current mouseY relative to window(default is relative to viewport)
    */
    const clientY = e.clientY - workspaceRootYPosition.value;

    const overElementId = getCurrentDatasetElementId(target);

    if (overElementId === DND_DIVIDER_ID) return;

    /*
      Mouse cursor is over workspace.
      We need to find nearest root row
    */
    if (!overElementId) {
      const rowIndex = findNearestRootBlockIndex(
        clientY,
        currentDimensions.value
      );

      const workspaceElement = document.getElementById("workspace");

      setOutsideCellDividerRenderData(rowIndex, workspaceElement);

      return;
    }

    const cell = findCell(currentLayout.value, overElementId);

    // dragged over parent level column cell with nested container
    // should change target to it's last child's first column cell
    if (
      target?.dataset.isParent === "true" &&
      cell?.settings.level === NestingLevel.PARENT &&
      cell.children.length
    ) {
      target = target!.querySelector(
        `#cellId_${cell.children[cell.children.length - 1].children[0].cellId}`
      )!;
    }

    /*
      Mouse cursor is over cell.
      If cell is child level, we need to empasize it's parent col
    */
    setInsideCellDividerRenderData(
      target,
      clientY,
      workspaceRootYPosition.value
    );
  }, RECALCULATE_DIVIDER_FREQUENCY);

  const checkDimensions = () => {
    if (currentDimensions.value.length) {
      return;
    }

    /*
      We need to calculate rows dimensions.
    */
    calculateRootRowsDimensions();
  };

  return {
    workspaceRootYPosition,
    moveGridElement,
    moveElementFromSidebar,
    handleBlockDrop,
    handleDragOver,
    handleUpdateContainerPosition,
    checkDimensions,
  };
};
