import { useWidgetsStore } from "~~/store/widgets";
import {
  State,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultBorderValue,
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value);

  const DEFAULT_STATES: Record<string, State> = {
    dropdown_container: State.DEFAULT,
    menu_items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([], {
    display: {
      resizing: "hug",
      alignment: "left",
    },
  });

  const init = (): void => {
    initFields();

    const dropdownStateValue = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        text: {
          color: getDefaultFillValue("#000000"),
        },
        arrow: {
          textColor: getDefaultFillValue("#000000"),
        },
      };
    };

    addFieldInitialOptions("dropdown_container", {
      data: DESIGN_INITIAL(),
      exclude: ["size"],
      additionalData: {
        options: {
          text: {
            _active: true,
            color: {
              color: "#000000",
              opacity: 100,
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
          icon: {
            _active: true,
            iconSize: 12,
            gap: 12,
          },
          arrow: {
            _active: true,
            value: "Down",
            default_value: "ant-design:arrow-down-outlined",
            rotate: true,
            arrowSize: 14,
            gap: 8,
            textColor: getDefaultFillValue("#000000"),
          },
          states: {
            [State.HOVER]: dropdownStateValue(),
            [State.ACTIVE]: dropdownStateValue(),
          },
        },
      },
      values: {
        cornerRadius: getDefaultCornerRadiusValue(4),
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 10),
        }),
        spacing: {
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
            top: 4,
            bottom: 4,
          }),
          margin: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("dropdown_menu", {
      data: DESIGN_INITIAL(),
      exclude: ["size", "_isWidget"],
      additionalData: {
        options: {
          baseDisplaySettings: {
            alignment: VerticalAlignment.TOP,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        cornerRadius: getDefaultCornerRadiusValue(4),
        spacing: {
          padding: getDefaultSpacing(4),
          margin: getDefaultSpacing({
            top: 4,
          }),
        },
      },
    });

    const dropdownMenuItemStateValue = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
      };
    };

    addFieldInitialOptions("menu_items", {
      data: DESIGN_INITIAL(),
      exclude: ["link", "spacing", "size"],
      additionalData: {
        options: {
          distance: 4,
          padding: getDefaultSpacing(),
          text: {
            ...TITLE_INITIAL().options,
            theme: 13,
          },
          icon: {
            _active: true,
            iconSize: 12,
            gap: 12,
          },
          states: {
            [State.HOVER]: dropdownMenuItemStateValue(),
            [State.ACTIVE]: dropdownMenuItemStateValue(),
          },
        },
      },
      values: {
        padding: getDefaultSpacing({
          left: 12,
          right: 12,
          top: 4,
          bottom: 4,
        }),
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
