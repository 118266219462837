import { useWidgetsStore } from "~~/store/widgets";
import {
  State,
  TextStyle,
  DisplayOrientation,
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultBorderValue,
  getDefaultFillImage,
  getDefaultTheme,
  getDefaultDecoration,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultButtonTextSettings,
  DESIGN_INITIAL,
  getDefaultSizeValue,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { TEXT_BUTTON_INITIAL } from "~~/constants/configs/common/text-button-initial";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";

const MODAL_FIELDS = [
  "modal",
  "modal_title",
  "modal_description",
  "modal_primary_button",
  "modal_secondary_button",
  "modal_close_icon",
];

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    event_button: State.DEFAULT,
    screen: State.ACTIVE_EVENTS,
    tabs: State.DEFAULT,
    cards: State.DEFAULT,
    terms_title: State.DEFAULT,
    action_button: State.DEFAULT,
    modal_primary_btn: State.DEFAULT,
    modal_secondary_btn: State.DEFAULT,
    modal_close_icon: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  /*
  Initialize widget with initial options
  */
  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    spacing: {
      padding: getDefaultSpacing(),
      margin: getDefaultSpacing({
        top: 24,
      }),
    },
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions(
      "title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing({ bottom: 24 }),
        },
      },
      { value: "Bonuses" }
    );

    addFieldInitialOptions("bonus_group", {
      data: DESIGN_INITIAL(),
      exclude: ["link"],
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          display: {
            distanceBetweenGroups: 8,
            distanceBetweenCards: 8,
          },
        },
      },
    });

    addFieldInitialOptions(
      "bonus_group_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(20),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({ bottom: 8 }),
        },
      },
      { value: "Bonus Group Title" }
    );

    addFieldInitialOptions(
      "bonus_group_description",
      {
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: getDefaultDecoration(),
          color: getDefaultFillValue("#000000"),
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({ bottom: 8 }),
        },
      },
      { value: "Bonus Group Description" }
    );

    addFieldInitialOptions("bonus_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({ bottom: 16 }),
      },
    });

    const initialTabStateOptions = () => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("", 100),
        }),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
      };
    };

    addFieldInitialOptions("tabs", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          ...DESIGN_INITIAL().options,
        },
      },
      exclude: ["link", "margins", "padding", "size"],
      values: {
        theme: getDefaultTheme(),
        fill: getDefaultFillValue("#FFFFFF"),
        spacing: {
          padding: getDefaultSpacing(8),
          margin: getDefaultSpacing({
            bottom: 16,
          }),
        },
      },
      additionalData: {
        options: {
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          distance: 16,
          states: {
            [State.HOVER]: initialTabStateOptions(),
            [State.ACTIVE]: initialTabStateOptions(),
          },
        },
      },
    });

    addFieldInitialOptions("cards", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          ...DESIGN_INITIAL().options,
        },
      },
      values: {
        theme: getDefaultTheme(),
        fill: getDefaultFillValue("#FFFFFF"),
        alignment: Alignment.LEFT,
        shadow: getDefaultShadow({
          fill: getDefaultFillValue("#000000", 10),
          blur: 20,
          y: 10,
        }),
        spacing: {
          padding: getDefaultSpacing(24),
          margin: getDefaultSpacing(),
        },
      },
      exclude: ["link", "margins", "padding", "size"],
      additionalData: {
        options: {
          layout: DisplayOrientation.HORIZONTAL,
          states: {
            [State.HOVER]: initialTabStateOptions(),
            [State.ACTIVE]: initialTabStateOptions(),
          },
        },
      },
    });

    addFieldInitialOptions("no_active_bonuses", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        alignment: Alignment.CENTER,
        theme: getDefaultTheme(),
      },
    });

    addFieldInitialOptions("bonus_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({ bottom: 12 }),
      },
    });

    addFieldInitialOptions("status", {
      data: {
        options: {
          fontFamily: getDefaultFontFamily(),
          secondary: {
            theme: getDefaultTheme(),
            decoration: getDefaultDecoration(),
            color: getDefaultFillValue("#8C8C8C"),
          },
          primary: {
            theme: getDefaultTheme(),
            decoration: getDefaultDecoration(),
            color: getDefaultFillValue("#000000"),
          },
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      },
    });

    addFieldInitialOptions("dates", {
      data: {
        options: {
          display: {
            distance: 8,
            layout: DisplayOrientation.HORIZONTAL,
          },
          fontFamily: getDefaultFontFamily(),
          secondary: {
            theme: getDefaultTheme(),
            decoration: getDefaultDecoration(),
            color: getDefaultFillValue("#8C8C8C"),
          },
          primary: {
            theme: getDefaultTheme(),
            decoration: getDefaultDecoration(),
            color: getDefaultFillValue("#000000"),
          },
          icon: {
            size: 20,
            color: getDefaultFillValue("#000000"),
          },
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      },
    });

    addFieldInitialOptions("description", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        decoration: getDefaultDecoration(),
        padding: getDefaultSpacing({ bottom: 12 }),
        theme: getDefaultTheme(),
      },
    });

    addFieldInitialOptions("progress_bar", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          bar: {
            size: 8,
            color: getDefaultFillValue("#1677FF"),
            fill: getDefaultFillValue("#F0F0F0"),
          },
        },
      },
      values: {
        theme: getDefaultTheme(),
        padding: getDefaultSpacing({
          bottom: 12,
        }),
      },
    });

    addFieldInitialOptions("amounts", {
      data: {
        options: {
          fontFamily: getDefaultFontFamily(),
          primary: {
            theme: getDefaultTheme(),
            decoration: getDefaultDecoration(),
            color: getDefaultFillValue("#8C8C8C"),
          },
          secondary: {
            theme: getDefaultTheme(24),
            decoration: getDefaultDecoration([TextStyle.BOLD]),
            color: getDefaultFillValue("#000000"),
          },
          padding: getDefaultSpacing({ bottom: 24 }),
        },
      },
    });

    const termsState = () => {
      return {
        color: getDefaultFillValue("#1677FF"),
      };
    };

    addFieldInitialOptions(
      "terms_title",
      {
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#1677FF"),
          padding: getDefaultSpacing(),
          theme: getDefaultTheme(),
          link: {
            states: {
              [State.HOVER]: termsState(),
              [State.ACTIVE]: termsState(),
            },
          },
        },
      },
      { value: "Terms & conditions" }
    );

    addFieldInitialOptions("action_button", {
      data: TEXT_BUTTON_INITIAL(),
      values: {
        margins: getDefaultSpacing({
          top: 24,
        }),
      },
    });

    addFieldInitialOptions("image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
      values: {
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        padding: getDefaultSpacing({ right: 12 }),
        containerSize: {
          width: {
            type: ResizingType.FIXED,
            value: 512,
          },
          height: {
            type: ResizingType.FILL,
            value: 320,
          },
        },
      },
    });

    addFieldInitialOptions("modal", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      values: {
        fill: getDefaultFillValue("#FFFFFF"),
        shadow: getDefaultShadow({
          fill: getDefaultFillValue("#000000", 10),
          blur: 20,
          y: 10,
        }),
        cornerRadius: getDefaultCornerRadiusValue({ value: 12 }),
      },
      additionalData: {
        options: {
          alignment: Alignment.CENTER,
          padding: getDefaultSpacing(24),
          width: getDefaultSizeValue(ResizingType.FIXED, "640"),
        },
      },
    });

    addFieldInitialOptions(
      "modal_title",
      {
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      },
      { value: "Modal title" }
    );

    addFieldInitialOptions(
      "modal_description",
      {
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
      },
      { value: "Modal description" }
    );

    addFieldInitialOptions("modal_primary_button", {
      data: TEXT_BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing({
          left: 12,
          right: 12,
          top: 8,
          bottom: 8,
        }),
        theme: getDefaultTheme(),
        margins: getDefaultSpacing({
          right: 8,
        }),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
      },
    });

    addFieldInitialOptions("modal_secondary_button", {
      data: TEXT_BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing({
          left: 12,
          right: 12,
          top: 8,
          bottom: 8,
        }),
        theme: getDefaultTheme(),
        fill: getDefaultFillValue("#FFFFFF"),
        color: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
      },
    });

    addFieldInitialOptions("modal_close_icon", {
      data: BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing(),
        fill: getDefaultFillValue(),
        iconSettings: getDefaultIconSettings({
          default_value: "ant-design:close-outlined",
          textColor: getDefaultFillValue("#000000", 45),
        }),
        buttonTextSettings: getDefaultButtonTextSettings({ _active: false }),
      },
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,
    MODAL_FIELDS,

    init,
  };
};
