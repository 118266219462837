import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 12;

const generateStreamIconField = () => ({
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
    "value": "ant-design:play-circle-filled",
    "iconSize": 20,
    "textColor": {
      "color": "#52C41A",
      "opacity": 100,
    },
  },
  "name": "stream_icon",
  "validation": [],
});

const v12WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    ["FavouritesEventsLiveWidget", "SportsBookLiveWidget"].includes(widget.name)
  ) {
    if (!("stream_icon" in widget.content)) {
      widget.content.stream_icon = generateStreamIconField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v12WidgetMigration;
