import { Ref } from "vue";

import {
  getBackgroundColorStyle,
  getBackgroundPosition,
} from "~~/assets/utils/widget-settings";
import { FillType } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import useImageCdnChange from "../useImageCdnChange";

export function useFillBackground(options: Ref<IWidgetOptions | undefined>) {
  const fillBackgroundStyling = computed<{
    backgroundColor?: string;
    backgroundImage?: string;
  }>(() => {
    if (options.value?.fillImageColor) {
      const { fillType, value, position } = options.value.fillImageColor;

      if (fillType === FillType.COLOR) {
        return getBackgroundColorStyle(value);
      }

      const changedValue = useImageCdnChange(value);

      if (!changedValue) return {};

      return {
        backgroundImage: `url('${changedValue}')`,
        backgroundSize: "cover",
        ...getBackgroundPosition(position),
      };
    } else {
      return getBackgroundColorStyle(options.value?.fill);
    }
  });

  return {
    fillBackgroundStyling,
  };
}
