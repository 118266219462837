import type { Ref } from "vue";

import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Data", "data"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...generateStyleDropdownItems(widget.value.fields)];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
