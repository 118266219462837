<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PromoBetsFenixWidgetSettings
      :widget="widget"
      :state-controller="stateController"
    />

    <template
      v-if="widget.options.displayMode === CardsDisplayMode.Carousel"
      #root
    >
      <NavigationArrows
        v-if="fields.arrows.field.options._active"
        :widget="widget"
        :field="fields.arrows.field"
        :arrows-styles="arrowsStyles"
        :arrows-container-styles="arrowsContainerStyles"
        :arrows-button-styles="arrowsButtonStyles"
      />
      <NavigationPagination
        v-if="fields.pagination.field.options._active"
        :widget="widget"
        :field="fields.pagination.field"
        :pagination-styles="paginationStyles"
        :pagination-dots-styles="paginationDotsStyles"
      />
    </template>
    <div :style="wrapperStyles">
      <WidgetField
        v-for="index in 5"
        :key="index"
        :widget="widget"
        :field="fields.cards.field"
        :style="gridCellStyles"
      >
        <PromoBetsFenixWidgetCard
          :widget="widget"
          :fields="fields"
          :position="index"
          :image-src="`/widgets/PromoBetsFenixWidget/${index}.png`"
          :style="cardsStyles"
          :event-info-styles="eventInfoStyles"
          :content-styles="contentStyles"
          :team-player-styles="teamPlayerStyles"
          :team-player-text-styles="teamPlayerTextStyles"
          :team-player-icon-container-styles="teamPlayerIconContainerStyles"
          :team-player-icon-styles="teamPlayerIconStyles"
          :date-time-styles="dateTimeStyles"
          :date-time-element-styles="dateTimeElementStyles"
          :date-time-icon-styles="dateTimeIconStyles"
          :date-time-text-styles="dateTimeTextStyles"
          :markets-styles="marketsStyles"
          :outcomes-styles="outcomesStyles"
          :outcomes-text-name-styles="outcomesTextNameStyles"
          :outcomes-text-coefficient-styles="outcomesTextCoefficientStyles"
          :button-container-styles="buttonContainerStyles"
          :button-styles="buttonStyles"
          :button-text-styles="buttonTextStyles"
          :button-icon-styles="buttonIconStyles"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import NavigationArrows from "~~/entities/carousel/components/NavigationArrows.vue";
import NavigationPagination from "~~/entities/carousel/components/NavigationPagination.vue";

import { CardsDisplayMode } from "./PromoBetsFenixWidgetTypes";
import { usePromoBetsFenixWidgetInit } from "./composables/usePromoBetsFenixWidgetInit";
import { usePromoBetsFenixWidgetStyles } from "./composables/usePromoBetsFenixWidgetStyles";
import { usePromoBetsFenixWidgetStateController } from "./composables/usePromoBetsFenixWidgetStateController";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widget = toRef(props.widget);

const { fields, init } = usePromoBetsFenixWidgetInit(widget);
const { stateController } = usePromoBetsFenixWidgetStateController();

init();

const {
  wrapperStyles,
  gridCellStyles,

  cardsStyles,

  contentStyles,

  eventInfoStyles,

  teamPlayerStyles,
  teamPlayerTextStyles,
  teamPlayerIconContainerStyles,
  teamPlayerIconStyles,

  dateTimeStyles,
  dateTimeElementStyles,
  dateTimeIconStyles,
  dateTimeTextStyles,

  marketsStyles,

  outcomesStyles,
  outcomesTextNameStyles,
  outcomesTextCoefficientStyles,

  buttonContainerStyles,
  buttonStyles,
  buttonTextStyles,
  buttonIconStyles,

  arrowsStyles,
  arrowsContainerStyles,
  arrowsButtonStyles,

  paginationStyles,
  paginationDotsStyles,
} = usePromoBetsFenixWidgetStyles(widget, fields, stateController);
</script>

<style lang="scss">
.promo-bets-fenix-widget {
  //
}
</style>
