<template>
  <div
    v-if="hasDesignPermissions"
    class="widget-control-container config-element-position p-t-16 p-r-16 p-l-16 p-b-16"
  >
    <ConfigRadioIcon
      :model-value="modelValue?.position"
      :items="[
        {
          icon: 'custom:text-center',
          value: TextPosition.TOP,
        },
        {
          icon: 'custom:text-left',
          value: TextPosition.LEFT,
        },
        {
          icon: 'custom:text-right',
          value: TextPosition.RIGHT,
        },
      ]"
      label="Position"
      class="config-element-position__radio"
      @update:model-value="updateValue"
    />

    <div
      v-if="modelValue && modelValue?.position !== TextPosition.TOP"
      class="config-element-position__alignment"
    >
      <div class="config-element-position__scale">
        <p class="config-element-position__scale-label">Scale</p>

        <ConfigInput
          v-number
          :is-number="true"
          :model-value="modelValue.scale"
          :input-props="{
            min: 0,
            max: 100,
            controls: false,
          }"
          class="config-element-position__scale-input"
          @update:model-value="updateAdditionalValue('scale', $event)"
        >
          <template #suffix>
            <span> % </span>
          </template>
        </ConfigInput>
      </div>

      <ConfigVerticalAlignment
        v-if="modelValue.alignment"
        :model-value="modelValue.alignment"
        class="config-element-position__v-align"
        @update:model-value="updateAdditionalValue('alignment', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  TextPosition,
  TextPositionComplex,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: TextPositionComplex;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: TextPositionComplex): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const updateValue = (value: TextPosition): void => {
  const currValue = props.modelValue || ({} as TextPositionComplex);

  emit("update:model-value", {
    ...currValue,
    position: value,
  });
};

const updateAdditionalValue = (fieldName: string, value: any): void => {
  emit("update:model-value", {
    ...props.modelValue,
    [fieldName]: value,
  });
};
</script>

<style lang="scss">
.config-element-position {
  &__container {
    @include flex(center, space-around, 8px);
  }

  &__radio {
    padding: 0;
    border-bottom: unset;
  }

  &__icon {
    @include flex(center, center, 0);
    border: 1px solid $c-grey-15;
    padding: 8px 20px;
    border-radius: 6px;

    &--active {
      border: 2px solid $c-primary-base !important;
    }
  }

  &__alignment {
    @include flex(flex-start, flex-start, 8px);
    margin-top: $space-m;

    .config-alignment {
      padding-left: 0;
      padding-right: 0;

      & > p {
        margin-top: 0;
        margin-bottom: $space-s;
      }
    }
  }

  &__scale-input {
    .ant-input-number {
      padding-left: 0 !important;
      width: auto !important;
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }

    .ant-input-number-group-wrapper {
      width: 100%;
    }

    .ant-input-number-group-addon {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__v-align {
    flex-basis: 50%;
  }

  &__scale {
    flex-basis: 50%;

    &-label {
      margin-bottom: $space-s;
    }
  }
}
</style>
