<template>
  <div class="common-html-editor">
    <Editor
      v-model="value"
      :init="editorUpdatedOptions"
      @blur.stop
    />

    <HtmlEditorLinkModal />
  </div>
</template>

<script lang="ts" setup>
import "tinymce/tinymce";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/models/dom/model";
import "tinymce/skins/ui/oxide/skin.css";

// TinyMCE plugins
// https://www.tiny.cloud/docs/tinymce/5/plugins/
import "tinymce/plugins/image/plugin";
import "tinymce/plugins/table/plugin";
import "tinymce/plugins/code/plugin";
import "tinymce/plugins/preview/plugin";
import "tinymce/plugins/emoticons/plugin";
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/advlist/plugin";
import "tinymce/plugins/codesample/plugin";
import "tinymce/plugins/autolink/plugin";
import "tinymce/plugins/searchreplace/plugin";
import "tinymce/plugins/accordion/plugin";
import "tinymce/plugins/autoresize/plugin";
import "tinymce/plugins/anchor/plugin";
import "tinymce/plugins/visualblocks/plugin";

import contentUiCss from "tinymce/skins/ui/oxide/content.css?inline";
import Editor from "@tinymce/tinymce-vue";

import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useDescriptionLink } from "~~/composables/fields/description/useDescriptionLink";
import HtmlEditorLinkModal from "~~/components/common/html-editor/HtmlEditorLinkModal.vue";

const props = defineProps<{
  modelValue?: Record<string, any> | undefined | null;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: Record<string, string>): void;
}>();

const { getLocalizedValue, transformToLocalizedValue, setLocalizedValue } =
  useLocalizedValue();

const { editorInitOptions } = useDescriptionLink();

const editorCss = `${String(
  contentUiCss
)} .tox-comments-visible span.tox-comment { background-color: magenta; }.tox-comments-visible span.tox-comment--active { background-color: cyan; }`;

const editorUpdatedOptions = computed(() => {
  return {
    ...editorInitOptions,
    skin: false,
    content_css: false,
    content_style: editorCss,
  };
});

const value = computed({
  get() {
    return getLocalizedValue.value(props.modelValue || "");
  },
  set(updatedValue?: string) {
    const newValue = setLocalizedValue.value(props.modelValue, updatedValue);
    emit("update:modelValue", newValue);
  },
});

onBeforeMount(() => {
  const { localized, localizedValue } = transformToLocalizedValue(
    props.modelValue
  );

  if (localized) {
    emit("update:modelValue", localizedValue);
  }
});
</script>

<style lang="scss">
// Need to remove "Upgrade" text from editor
.tox-promotion {
  display: none;
}

.tox.tox-tinymce-inline {
  position: absolute !important;
  top: 0 !important;
  left: 10% !important;
}
</style>
