<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
      has-text
      class="sportbook-live-mobile-fenix__racing-content"
    >
      <div
        :style="containerStyles"
        class="sportbook-live-mobile-fenix__racing-content-primary"
      >
        <div
          :style="headerStyles"
          class="sportbook-live-mobile-fenix__racing-content-primary__header"
        >
          <WidgetField
            v-if="streamIconField.options._active"
            :widget="widget"
            :field="streamIconField"
          >
            <CommonIcon
              :name="streamIconField.options.value"
              :style="streamIconStyles"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="leagueNameField"
            class="sportbook-live-mobile-fenix__racing-content-primary__name"
          >
            <BasicTitleField
              :field="leagueNameField"
              value="Venue — Race distance"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="statusField"
          >
            <BasicDesignWrapperField
              :widget="widget"
              :field="statusField"
            >
              <BasicTitleField
                :field="statusField"
                value="Status"
              />
            </BasicDesignWrapperField>
          </WidgetField>

          <WidgetField
            v-if="favoriteIconField.options._active"
            :widget="widget"
            :field="favoriteIconField"
          >
            <CommonIcon
              :style="favIconStyles"
              name="ant-design:star-outlined"
            />
          </WidgetField>
        </div>

        <div>
          <WidgetField
            v-if="buttonField.options._active"
            :widget="widget"
            :field="buttonField"
            :style="buttonContainerStyle"
          >
            <BasicButtonField
              :field="buttonField"
              :current-state="buttonState"
            />
          </WidgetField>
        </div>
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  leagueNameField: IWidgetField;
  favoriteIconField: IWidgetField;
  streamIconField: IWidgetField;
  statusField: IWidgetField;
  buttonField: IWidgetField;
  buttonState: string;
}>();

const containerStyles = computed<ElementStyle>(() => {
  const layout = props.field.options.display.layout;

  if (layout === DisplayOrientation.VERTICAL) {
    return {
      flexDirection: "column",
      gap: getPxValueFromNumber(props.field.options.display.distance),
      justifyContent: "center",
    };
  }

  return {
    flexDirection: "row",
    gap: getPxValueFromNumber(props.field.options.display.distance),
    alignItems: "center",
  };
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    flexDirection: "row",
    gap: getPxValueFromNumber(props.field.options.display.distance),
    alignItems: "center",
  };
});

const favIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.favoriteIconField.options.color),
    ...getFontSizeStyle(props.favoriteIconField.options.size),
  };
});

const streamIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.streamIconField.options.textColor),
    ...getFontSizeStyle(props.streamIconField.options.iconSize),
  };
});

const buttonContainerStyle = computed<ElementStyle>(() => {
  const options = props.buttonField.options;
  const buttonDisplaySettings = options.buttonDisplaySettings;

  if (buttonDisplaySettings.resizing === ResizingType.HUG)
    return { display: "inline-block" };

  return {};
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__racing-content-primary {
    display: flex;

    &__name {
      flex: 1;
    }

    &__header {
      display: flex;
      flex: 1;
    }
  }
}
</style>
