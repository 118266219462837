<template>
  <div class="tags-tab">
    <div class="tags-tab__search-container">
      <a-input
        v-model:value="searchValue"
        placeholder="Search tag"
        class="tags-tab__search-input ant-input--has-suffix-clickable"
      >
        <template #suffix>
          <span class="common-search__icon">
            <CommonIcon
              name="ant-design:search-outlined"
              class="tags-tab__search-icon"
            />

            <span
              v-if="searchValue"
              class="pointer tags-tab__search-icon"
              @click="searchValue = ''"
            >
              <CommonIcon name="custom:close-field-icon" />
            </span>
          </span>
        </template>
      </a-input>
    </div>

    <div class="tags-tab__list p-l-16 p-r-16">
      <template v-if="!loading">
        <div v-if="!searchResultTags.length">
          <p class="text-center tags-tab__empty">No tags found</p>
        </div>

        <div
          v-for="el in searchResultTags"
          :key="el"
          class="tags-tab__item-wrapper"
          @click="handleCopyClick(el)"
        >
          <span class="tags-tab__item-text">
            {{ el }}
          </span>

          <CommonIcon
            name="ant-design:copy-outlined"
            class="tags-tab__copy"
          />
        </div>
      </template>
    </div>
    <CommonSpinner v-if="loading" />
  </div>
</template>

<script lang="ts" setup>
import { useClipboard } from "@vueuse/core";
import { useNotification } from "@kyvg/vue3-notification";

import { useGridConfig } from "~~/store/grid";
import { useGlobalPages } from "~~/composables/widgets-config/useGlobalPages";
import { gatherWidgetsTags } from "~~/assets/utils";
import { usePagesStore } from "~~/store/pages";

const props = defineProps<{
  visible: boolean;
}>();

defineEmits<{
  (e: "select-page"): void;
}>();

const loading = ref<boolean>(false);
const searchValue = ref<string>("");

const clipboard = useClipboard({ legacy: true });
const notification = useNotification();
const gridStore = useGridConfig();
const pagesStore = usePagesStore();

const { pagesData, fetchAllPages } = useGlobalPages(toRef(true));

const { selectedPageId } = storeToRefs(pagesStore);

const handleCopyClick = async (tag: string): Promise<void> => {
  await clipboard.copy(tag);

  notification.notify("Copied to clipboard!");
};

const currentPageWidgetsTags = computed<string[]>(() => {
  return gatherWidgetsTags(gridStore.widgetsGridLayout);
});

const gatheredTags = computed<string[]>(() => {
  const pagesTags = Object.keys(pagesData.value).reduce(
    (result: string[], currPageId) => {
      /* 
        Current page tags are gathered in currentPageWidgetsTags
      */
      if (currPageId === selectedPageId.value) {
        return result;
      }

      const page = pagesData.value[currPageId];
      const pageTags = page.options.tags || [];

      return [...result, ...pageTags];
    },
    []
  );

  const set = new Set([...pagesTags, ...currentPageWidgetsTags.value]);
  const arr = [...set];

  return arr;
});

const searchResultTags = computed<string[]>(() => {
  return gatheredTags.value.filter(tag => {
    const currValue = tag.toLocaleLowerCase();
    const searchLower = searchValue.value.toLowerCase();

    return currValue.includes(searchLower);
  });
});

const fetchData = async (): Promise<void> => {
  try {
    loading.value = true;
    await fetchAllPages();
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
};

watch(
  async () => props.visible,
  newValue => {
    if (!newValue) {
      return;
    }

    if (Object.keys(pagesData.value).length) {
      return;
    }

    fetchData();
  }
);
</script>

<style lang="scss">
.tags-tab {
  width: $propertybar-width;

  &__search-container {
    padding: 0 $space-m;
  }

  &__empty {
    font-style: italic;
    color: $c-grey-45;
    font-size: 14px;
    margin: 0;
  }

  &__search-input {
    margin: $space-m 0;
    @include flex(center, space-between, 4px);

    // .ant-input {
    //   padding-right: 18px !important;
    // }

    .ant-input-suffix {
      position: static;
      transform: none;
      user-select: auto;
      user-select: initial;
      pointer-events: all;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;
  }

  &__list {
    overflow: auto;
    height: calc(100vh - 255px);
    width: 100%;
  }

  &__item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__item-wrapper {
    @include flex(center, space-between, 0px);
    padding: $space-s;
    margin: 4px 0;
    border-radius: 8px;
    height: 40px;
    background-color: $c-grey-02;
    cursor: pointer;

    &:hover {
      background-color: $c-light-blue;
      color: $c-primary-base;
    }
  }

  &__search-icon {
    color: $c-grey-25;
  }
}
</style>
