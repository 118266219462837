import { DEFAULT_FORM_WIDTH } from "~~/constants/widget-config";
import { FillType, State } from "~~/models/widgets/widget-controls.model";
import { DepositListScreen } from "~~/models/widgets/form.model";

import { cardStatesInitial } from "../states-initial/cardStatesInitial";
import { buttonStatesInitial } from "../states-initial/buttonStatesInitial";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const DEPOSIT_WITHDRAWAL_WIDGET_INITIAL = () => {
  return {
    options: {
      states: {
        [DepositListScreen.DATA_FILLED]: {
          contentWidth: {
            type: "fixed",
            width: DEFAULT_FORM_WIDTH,
          },
        },
        [DepositListScreen.PAYMENT_METHOD]: {
          contentWidth: {
            type: "fixed",
            width: DEFAULT_FORM_WIDTH,
          },
        },
        [DepositListScreen.SUCCESS]: {
          contentWidth: {
            type: "fixed",
            width: DEFAULT_FORM_WIDTH,
          },
        },
        [DepositListScreen.FAIL]: {
          contentWidth: {
            type: "fixed",
            width: DEFAULT_FORM_WIDTH,
          },
        },
      },
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      gridSettings: {
        cardsSize: 228,
        alignment: "flex-start",
        minCardsGap: 32,
        rowGap: 24,
      },
    },
  };
};

export const CARDS_INITIAL = () => ({
  options: {
    layoutPosition: {
      position: "bottom",
      alignment: null,
    },
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    states: {
      [State.HOVER]: cardStatesInitial(),
      [State.ACTIVE]: cardStatesInitial(),
    },
  },
});

export const CARDS_IMAGE_INITIAL = () => ({
  value: "",
  options: {
    _active: true,
    containerSize: {
      size: 180,
      alignment: "center",
      resizing: "fit",
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const CARDS_BUTTON_INITIAL = () => ({
  value: "Button",
  options: {
    _active: true,
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    fill: {
      color: "#1677FF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    buttonDisplaySettings: {
      resizing: "fill",
      alignment: "center",
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#ffffff",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    states: {
      [State.HOVER]: buttonStatesInitial(),
      [State.ACTIVE]: buttonStatesInitial(),
      [State.DISABLED]: buttonStatesInitial(),
    },
  },
});
