import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

const SETTINGS_ITEMS = [
  {
    label: "Iframe",
    value: "iframe",
  },
  {
    label: "Play toggler",
    value: "play_toggler",
  },
];

export const useItems = () => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("IFrame", "iframe"),
    generateDropdownItem("Play toggler", "play_toggler"),
    generateDropdownItem("Text", "play_toggler_title"),
    generateDropdownItem("Button", "play_toggler_button"),
    generateDropdownItem("Link", "linkStates", "link"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    play_toggler: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    play_toggler_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return DEFAULT_DROPDOWN_ITEMS;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
