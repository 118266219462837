import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  DepositListScreen,
  FAIL_SCREEN_FIELDS,
  FORM_CARD_FIELDS,
  FormScreen,
  PROVIDERS_FIELDS,
  SUCCESS_SCREEN_FIELDS,
} from "~~/models/widgets/form.model";
import { ICell } from "~~/models/grid.interface";
import { findCellRootElement } from "~~/assets/utils/grid/grid-tree";
import { useGridConfig } from "~~/store/grid";
import {
  MIN_CARD_WIDTH,
  MAX_CARD_WIDTH,
  MAX_CARDS_GAP_NUMBER_INPUT_VALUE,
} from "~~/constants/widget-config";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useTitleLinkStateControls } from "../card/useTitleLinkStatesControls";
import { useSuccessScreenConfig } from "../form/useSuccessScreenConfig";
import { useTitleInitialControls } from "../common/useTitleInitialControls";

import { useProvidersElementControls } from "./useProvidersElementControls";
import { useFormCardElementsControls } from "./useFormCardElementsControls";

const configDepositListItems: ConfigTabItem[] = [
  {
    label: "All personal data has been filled",
    value: DepositListScreen.DATA_FILLED,
  },
  {
    label: "Payment method page",
    value: DepositListScreen.PAYMENT_METHOD,
  },
  {
    label: "Success page",
    value: DepositListScreen.SUCCESS,
  },
  {
    label: "Fail page",
    value: DepositListScreen.FAIL,
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  activeView: Ref<string>,
  emit: any,
  screens: Ref<string[]>,
  states: Ref<Record<string, State>>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedCell } = storeToRefs(widgetSettingsStore);
  const gridStore = useGridConfig();

  widget.value.fields.forEach(field => {
    if (
      field.name === "form" ||
      field.options._custom ||
      SUCCESS_SCREEN_FIELDS.includes(field.name) ||
      FAIL_SCREEN_FIELDS.includes(field.name) ||
      PROVIDERS_FIELDS.includes(field.name) ||
      FORM_CARD_FIELDS.includes(field.name) ||
      field.type === "UnknownField"
    ) {
      field.options._hidden = true;
    }
  });

  const currentItems = computed<ConfigTabItem[]>(() => {
    const items = configDepositListItems.filter(
      item =>
        screens.value.includes(item.value) ||
        item.value === DepositListScreen.DATA_FILLED
    );

    return items;
  });

  const rootCell = computed<ICell | null>(() => {
    return findCellRootElement(
      gridStore.currentWidgetsLayout,
      selectedCell.value
    );
  });

  const isWidthConfigDisabled = computed<boolean>(() => {
    if (!rootCell.value) {
      return false;
    }

    return rootCell.value.settings.sizing === Sizing.FIXED;
  });

  const titleValue = computed<IWidgetField>(() => {
    return fields.value.title.field;
  });

  const { titleLinkStatesControls } = useTitleLinkStateControls(
    states,
    undefined,
    emit,
    titleValue,
    undefined,
    "title"
  );

  const cardsTitleValue = computed<IWidgetField>(() => {
    return fields.value.providers_item_title.field;
  });

  const { titleLinkStatesControls: cardsTitleLinkStatesControls } =
    useTitleLinkStateControls(
      states,
      undefined,
      emit,
      cardsTitleValue,
      undefined,
      "providers_item_title"
    );

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "update_profile_text"),
    generateDropdownItem("Cards", "providers_item"),
    generateDropdownItem("Photos styling", "providers_item_image"),
    generateDropdownItem("Titles styling", "providers_item_title"),
    generateDropdownItem("Descriptions styling", "providers_item_limits"),
    generateDropdownItem("Buttons styling", "providers_item_button"),
    generateDropdownItem(
      "Back button styling",
      "form_back_button",
      "form_card"
    ),
    generateDropdownItem("Card settings", "provider_card", "form_card"),
    generateDropdownItem("Photo", "provider_image", "form_card"),
    generateDropdownItem("Title", "provider_title", "form_card"),
    generateDropdownItem("Description", "provider_description_1", "form_card"),
    generateDropdownItem("Tooltips", "providers_item_tooltip"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
    generateDropdownItem("Link States", "cardsLinkStates", "cardsLinkStates"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    providers_item_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    providers_item: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    providers_item_tooltip: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    form_back_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    provider_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const {
    cardsListDesignControls,
    cardsListContentControls,
    cardsListStatesControls,
    cardsTitleControls,
    cardsDescriptionControls,
    cardsListButtonDesignControls,
    cardsListButtonContentControls,
    cardsListButtonStatesControls,
    cardsImageControls,
  } = useProvidersElementControls(fields, states, emit);

  const { titleControls } = useTitleInitialControls(widget);

  const {
    fromCardTitleControls,
    formCardDescriptionControls,
    formCardImageControls,
    formBackButtonDesignControls,
    formBackButtonContentControls,
    formBackButtonStatesControls,
    providerCardDesignControls,
    providerCardContentControls,
    providerCardStatesControls,
  } = useFormCardElementsControls(fields, states, emit);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: widget.value,
            options: {
              label: "Margin",
              meta: {
                isBold: true,
              },
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const widgetConfigItems: ConfigTabItem[] = [
    {
      label: "Title settings",
      value: "title",
    },
    {
      label: "Description settings",
      value: "update_profile_text",
    },
    {
      label: "Cards settings",
      value: "providers_item",
    },
  ];

  const widgetFormConfigItems: ConfigTabItem[] = [
    {
      label: "Back button",
      value: "form_back_button",
    },
    {
      label: "Card settings",
      value: "provider_card",
    },
    {
      label: "Form settings",
      value: "form",
    },
  ];

  const widgetContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        visible: currentItems.value.length > 1,
        options: {
          items: currentItems.value,
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: `options.states.${activeView.value}.contentWidth`,
        valueSource: widget.value,
        options: {
          items: currentItems.value,
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetConfigItems,
        },
      },
      {
        section: generateDesignSectionName("Grid settings"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.gridSettings.cardsSize",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Cards size",
              minValue: MIN_CARD_WIDTH,
              maxValue: MAX_CARD_WIDTH,
            },
          },
          {
            componentPath: "ConfigCardsAlignment",
            valuePath: "options.gridSettings.alignment",
            valueSource: widget.value,
            options: {
              label: "Cards alignment",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.gridSettings.minCardsGap",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: widget.value,
            options: {
              label: "Minimum cards gap",
              minValue: 0,
              maxValue: MAX_CARDS_GAP_NUMBER_INPUT_VALUE,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.gridSettings.rowGap",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: widget.value,
            options: {
              label: "Rows gap",
              minValue: 0,
              maxValue: MAX_CARDS_GAP_NUMBER_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  const formContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        visible: currentItems.value.length > 1,
        options: {
          items: currentItems.value,
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: `options.states.${activeView.value}.contentWidth`,
        valueSource: widget.value,
        options: {
          items: currentItems.value,
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetFormConfigItems,
        },
      },
    ];
  });

  const tooltipDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const tooltipContentStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.mode",
        valueSource: fields.value.providers_item_tooltip.field,
        options: {
          label: "Mode (visible on click at)",
          isBold: true,
          items: [
            {
              label: "Icon",
              value: "icon",
            },
            {
              label: "Field",
              value: "field",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.providers_item_tooltip.field,
        visible:
          fields.value.providers_item_tooltip.field.options.mode === "icon",
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.providers_item_tooltip.field,
          except: ["ConfigAlignment"],
        },
        "Tooltips text"
      ),
    ];
  });

  const currentViewItems = computed<Record<string, string>>(() => {
    return VIEWS_MAPPER[activeView.value];
  });

  type AciveViewItems = Record<FormScreen | string, Record<string, string>>;
  const VIEWS_MAPPER: AciveViewItems = {
    [FormScreen.SUCCESS]: {
      title: "success_title",
      image: "success_image",
      description: "success_description_1",
      button: "success_button",
    },
    [DepositListScreen.FAIL]: {
      title: "fail_title",
      image: "fail_image",
      description: "fail_description_1",
      button: "fail_button",
    },
  };

  const valueSources = computed<Record<string, IWidgetField>>(() => {
    return {
      title: fields.value[currentViewItems.value?.title]?.field,
      description: fields.value[currentViewItems.value?.description]?.field,
      image: fields.value[currentViewItems.value?.image]?.field,
      button: fields.value[currentViewItems.value?.button]?.field,
    };
  });

  const imageNameKey = computed<string>(() => {
    return currentViewItems.value.image;
  });

  const { screenContentControls } = useSuccessScreenConfig(
    widget,
    currentItems,
    activeView,
    emit,
    isWidthConfigDisabled,
    valueSources,
    imageNameKey
  );

  const depositListSettingsContentControls = computed(() => {
    if (activeView.value === DepositListScreen.PAYMENT_METHOD) {
      return formContentControls.value;
    }

    if (
      activeView.value === DepositListScreen.SUCCESS ||
      activeView.value === DepositListScreen.FAIL
    ) {
      return screenContentControls.value;
    }

    return widgetContentControls.value;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    tooltipDesignStyles,
    tooltipContentStyles,
    widgetDesignControls,
    depositListSettingsContentControls,
    titleControls,
    titleLinkStatesControls,
    cardsTitleLinkStatesControls,
    cardsTitleControls,
    fromCardTitleControls,
    cardsDescriptionControls,
    formCardDescriptionControls,
    cardsImageControls,
    formCardImageControls,
    cardsListButtonDesignControls,
    cardsListButtonContentControls,
    cardsListButtonStatesControls,
    formBackButtonDesignControls,
    formBackButtonContentControls,
    formBackButtonStatesControls,
    cardsListDesignControls,
    cardsListContentControls,
    cardsListStatesControls,
    providerCardDesignControls,
    providerCardContentControls,
    providerCardStatesControls,
  };
};
