<template>
  <div v-if="states[widget.name] === State.BETS_RESULT">
    <WidgetField
      v-if="fields.success_message_title.active"
      :widget="widget"
      :field="fields.success_message_title.field"
    >
      <div
        class="betslip-widget__result-success-message"
        :style="[
          successMessageContainerInlineStyles,
          successMessageContainerLayoutStyles,
        ]"
      >
        <div
          class="betslip-widget__success-message-element"
          :style="successTitleInlineStyles"
        >
          <CommonIcon
            v-if="
              fields.success_message_title.field.options.iconSettings._active
            "
            :style="successIconInlineStyles"
            name="ep:success-filled"
          />
          <span>{{ t("widgets.BetslipWidget.success_message") }}</span>
        </div>
        <div
          class="betslip-widget__success-message-element"
          :style="successMessagePrintInlineStyles"
        >
          <span>{{ t("widgets.BetslipWidget.print") }}</span>
        </div>
      </div>
    </WidgetField>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useBetslipSuccessInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipSuccessInlineStyles";
import { ElementStyle } from "~~/models/common";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  successMessageContainerInlineStyles,
  successTitleInlineStyles,
  successIconInlineStyles,
  successMessagePrintInlineStyles,
} = useBetslipSuccessInlineStyles(ref(props.fields));

const successMessageContainerLayoutStyles = computed<ElementStyle>(() => {
  const successContainerOptions =
    props.fields.success_message_title.field.options;

  return {
    flexDirection:
      successContainerOptions.layout === DisplayOrientation.VERTICAL
        ? "column"
        : "row",
    alignItems:
      successContainerOptions.layout === DisplayOrientation.VERTICAL
        ? generateFlexAlignment(successContainerOptions.alignment)
        : "center",
  };
});
</script>

<style lang="scss">
.betslip-widget {
  &__success-message-element {
    @include flex(center, flex-start, 0px);
  }
}
</style>
