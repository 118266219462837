<template>
  <slot name="list:prepend" />

  <div
    v-for="page in items"
    :key="`${page.id}-pageId`"
    class="pages-list__item-wrapper pointer"
    :class="{
      'pages-list__item-wrapper--active':
        selectedPageId === page.id && !isCreatingNewPage,
    }"
  >
    <PageItem
      :page="page"
      :search-value="searchValue"
      :is-active="selectedPageId === page.id && !isCreatingNewPage"
      class="pages-list__page-item"
      @click-copy="handleCopyPage"
      @show-delete="showDeleteConfirmModal"
      @select-page="$emit('select-page', $event)"
    />

    <CommonModal
      v-if="selectedPageId === page.id"
      :visible="visible"
      :icon-color="IconColor.RED"
      :icon-type="IconType.WARNING"
      :confirm-loading="modalConfirmLoading"
      submit-button-label="Delete"
      title="Delete page"
      @submit="hideModal(page.name)"
      @cancel="visible = false"
    >
      <template #content>
        <div class="grid-saved-sidebar__modal-content">
          <p>Do you want to delete your {{ selectedPageName }}?</p>
        </div>
      </template>
    </CommonModal>
  </div>
</template>

<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";

import { usePagesStore } from "~~/store/pages";
import { useMetaStore } from "~~/store/meta";
import { useStaticTranslations } from "~~/store/staticTranslations";
import { IPage } from "~~/models/stores/pages-store.model";
import { PageIdType } from "~~/models/widgets/widget.core/widget.model";
import { IconColor, IconType } from "~~/models/common/common-modal.enum";
import { DELETING_PAGE_ERROR } from "~~/constants/notification-messages";

const emit = defineEmits(["copy", "select-page"]);

defineProps<{
  items: IPage[];
  searchValue: string;
}>();

const pagesStore = usePagesStore();
const metaStore = useMetaStore();
const router = useRouter();
const staticTranslations = useStaticTranslations();
const notification = useNotification();

const { pages, selectedPageId, selectedPageName, isCreatingNewPage } =
  storeToRefs(pagesStore);

const modalConfirmLoading = ref<boolean>(false);

const visible = ref<boolean>(false);

const handleCopyPage = (data: { id: PageIdType; name: string }): void => {
  emit("copy", { id: data.id, name: data.name });
};

const showDeleteConfirmModal = (): void => {
  visible.value = true;
};

const hideModal = (name: string): void => {
  modalConfirmLoading.value = true;
  pagesStore
    .deletePage(selectedPageId.value)
    .then(() => {
      notification.notify({
        text: `The page ${name} has been deleted`,
        type: "success",
      });
      if (pages.value.length) {
        selectPage(pages.value[0]);
      } else {
        selectPage(null);
        router.push({
          path: "layout",
          query: {
            id: pagesStore.templateId,
            project_id: metaStore.projectId,
            lang: staticTranslations.translationLanguage,
          },
        });
      }
    })
    .catch(() => {
      notification.notify({
        text: DELETING_PAGE_ERROR,
        type: "error",
      });
    })
    .finally(() => {
      modalConfirmLoading.value = false;
      visible.value = false;
    });
};

const selectPage = async (page: IPage | null): Promise<void> => {
  if (selectedPageId.value === page?.id) return;

  const prevPage = pages.value.find(
    element => element.id === selectedPageId.value
  );

  if (prevPage && page?.id !== selectedPageId.value) {
    prevPage.isEditing = false;
  }

  emit("select-page", page?.id);
  pagesStore.setSelectedPage(page);
  // TODO: add published status to this request
  await pagesStore.fetchPageContent();
};
</script>

<style lang="scss">
.pages-list {
  &__item-wrapper {
    @include flex(center, space-between, 0px);
    padding: $space-s;
    margin: 4px 0;
    border-radius: 8px;
    height: 40px;

    &--active,
    &:hover {
      background-color: $c-light-blue;
      color: $c-primary-base;
    }

    &:hover:not(.pages-list__item-wrapper--active) {
      .page-item__page-name-input {
        background-color: $c-light-blue;
      }
    }

    &--active {
      position: relative;

      .page-item__more-button {
        display: flex;
      }
    }

    &:hover {
      .page-item__more-button {
        display: flex;
      }
    }

    &--highlighted {
      background-color: $c-warning;
    }
  }
}
</style>
