import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useItemControls = () => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.TEXT, FieldContentItem.ICON]
  );

  const itemControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItemText",
        valuePath: `options.text`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.icon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: `options.link`,
        valueSource: selectedField.value,
        options: {
          shouldShowStyleSettings: false,
        },
      },
    ];
  });

  return {
    itemControls,
  };
};
