<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    :value="value"
    class="form-dropdown-field"
  >
    <div :style="dropdownCssVars">
      <a-dropdown
        :visible="isDropdownVisible"
        :trigger="[]"
        :get-popup-container="getMenuContainer"
        class="w-100 form-dropdown-field__dropdown"
      >
        <template #overlay>
          <div>
            <a-menu
              :style="dropdownMenuStyle"
              class="form-dropdown-field__dropdown-items-container"
            >
              <a-menu-item
                key="1"
                :style="dropdownItemStyle"
                class="form-dropdown-field__item"
              >
                1 menu item
              </a-menu-item>

              <a-menu-item
                key="2"
                :style="dropdownItemStyle"
                class="form-dropdown-field__item"
              >
                2 menu item
              </a-menu-item>
            </a-menu>
          </div>
        </template>

        <a-button>
          <slot>
            <span
              v-if="placeholder?._active"
              class="form-dropdown-field__placeholder"
            >
              {{ getLocalizedValue(placeholder.value) }}
            </span>

            <span v-else>&nbsp; </span>
          </slot>

          <span class="form-dropdown-field__icon">
            <CommonIcon
              :name="icon"
              :style="iconColorStyle"
            />
          </span>
        </a-button>
      </a-dropdown>
    </div>
  </FormInputBase>
</template>

<script lang="ts" setup>
import { FunctionalComponent } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  findField,
  getStylesAsVars,
  IFormTextInputProp,
} from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { iconMapping } from "~~/constants/icons-mapping";
import {
  getDecorationValue,
  getCornerRadiusStyle,
  getFontSizeStyle,
  getFontFamilyStyle,
  getColorStyle,
  getSpacing,
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
} from "~~/assets/utils/widget-settings";
import { IFillControl } from "~~/models/widgets/widget-controls.model";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const itemState = computed<string>(() => {
  return props.states[FormStyleFieldName.DROPDOWN_MENU_ITEMS];
});

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.FIELD];
});

const {
  inputStyles,
  isErrorVisible,
  isTooltipVisible,
  isDropdownVisible,
  triggerFieldExample,
  hideFieldExample,
} = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

const { getLocalizedValue } = useLocalizedValue();

const dropdownField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.DROPDOWNS, props.stylingFields);
});

const inputField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.FIELD, props.stylingFields);
});

const dropdownMenuField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.DROPDOWN_MENU, props.stylingFields);
});

const dropdownItemField = computed<IWidgetField | undefined>(() => {
  return findField(FormStyleFieldName.DROPDOWN_MENU_ITEMS, props.stylingFields);
});

const activeItemStateOptions = computed<IWidgetOptions>(() => {
  if (itemState?.value === State.DEFAULT || !itemState?.value) {
    return dropdownItemField.value?.options || {};
  }

  return dropdownItemField.value?.options.states[itemState.value];
});

const iconSettings = computed<{
  value: string;
  textColor: IFillControl;
}>(() => {
  return dropdownField.value?.options.icon;
});

const icon = computed<FunctionalComponent | string | null>(() => {
  return iconMapping[iconSettings.value?.value];
});

const iconColorStyle = computed<ElementStyle>(() => {
  const options =
    state.value === State.DEFAULT
      ? inputField.value?.options
      : inputField.value?.options.states[state.value];

  return {
    ...getColorStyle(options.iconRight?.color),
    "margin-left": getPxValueFromNumber(
      inputField.value?.options?.iconRight?.gap || 4
    ),
  };
});

const currStyles = computed<ElementStyle>(() => {
  const currentStyles = {
    ...inputStyles.value,
  };

  delete currentStyles.textAlign;

  return currentStyles;
});

const dropdownCssVars = computed<ElementStyle>(() => {
  const cssVars = getStylesAsVars(currStyles.value);

  return cssVars;
});

const placeholder = computed<IFormTextInputProp | undefined>(() => {
  return props.field.options?.placeholder;
});

const dropdownMenuStyle = computed<ElementStyle>(() => {
  if (!dropdownMenuField.value) {
    return {};
  }

  return {
    ...getSpacing(
      dropdownMenuField.value.options.padding,
      SpacingKeyName.PADDING
    ),
    ...getCornerRadiusStyle(dropdownMenuField.value.options.cornerRadius),
    ...getBackgroundColorStyle(dropdownMenuField.value.options.fill),
    ...getBorderStyle(dropdownMenuField.value.options.border),
    ...getBoxShadowStyle(dropdownMenuField.value.options.shadow),
    ...getCornerRadiusStyle(dropdownMenuField.value.options.cornerRadius),
    gap: `${dropdownItemField.value?.options.spaceBetween}px`,
    /*
      Hide menu content. Needed for menu container border radius
    */
    overflow: "hidden !important",
  };
});

const dropdownItemStyle = computed<ElementStyle>(() => {
  if (!dropdownItemField.value) {
    return {};
  }

  return {
    ...getBackgroundColorStyle(activeItemStateOptions.value.fill),
    ...getBorderStyle(activeItemStateOptions.value.border),
    ...getBoxShadowStyle(activeItemStateOptions.value.shadow),
    ...getCornerRadiusStyle(dropdownItemField.value.options.cornerRadius),
    ...getSpacing(
      dropdownItemField.value.options.padding,
      SpacingKeyName.PADDING
    ),
    /*
      Content
    */
    ...getColorStyle(activeItemStateOptions.value.color),
    ...getFontSizeStyle(dropdownItemField.value.options.theme),
    ...getFontFamilyStyle(dropdownItemField.value.options.fontFamily),
    ...getDecorationValue(activeItemStateOptions.value.decoration),
  };
});

const getMenuContainer = (): HTMLElement | null => {
  return document.querySelector("#widgets-grid");
};

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});
</script>

<style lang="scss">
$default-ant-line-height: 1.5715;

.form-dropdown-field {
  &__placeholder {
    padding-right: calc(var(--fontSize) + 4px);
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(var(--fontSize) * #{$default-ant-line-height});
    text-decoration-color: var(--textDecorationColor) !important;
    text-decoration: var(--textDecoration) !important;
  }

  &__dropdown {
    transition: all 0s !important;
    font-size: var(--fontSize) !important;
    font-style: var(--fontStyle) !important;
    font-weight: var(--fontWeight) !important;
    justify-content: var(--justifyContent) !important;
    height: initial;

    & > span {
      &:first-child {
        color: var(--placeholderColor);
      }
    }

    @include flex(center, space-between, 0);
    transition: all 0s !important;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    background-color: var(--backgroundColor) !important;
    color: var(--color) !important;
    border-color: var(--borderColor) !important;
    border-width: var(--borderWidth) !important;
    border-style: var(--borderStyle) !important;
    border-radius: var(--borderRadius) !important;
    box-shadow: var(--boxShadow);
  }

  &__icon {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
  }

  &__item {
    height: unset !important;
    line-height: #{$default-ant-line-height} !important;
  }

  &__dropdown-items-container {
    display: flex;
    flex-direction: column;
  }
}

.ant-menu-vertical .ant-menu-item {
  margin: 0px !important;
}

.ant-menu-vertical::before,
.ant-menu-vertical::after {
  display: none !important;
}
</style>
