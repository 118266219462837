export interface DropdownItem {
  label: string;
  value: string;
  group?: string | null;
  custom?: Record<string, any>;
}

export const generateDropdownItem = (
  label: string,
  value: string,
  group: string | null = null,
  custom?: Record<string, any>
): DropdownItem => {
  const item: DropdownItem = { label, value };
  if (group) {
    item.group = group;
  }

  if (custom) {
    item.custom = custom;
  }
  return item;
};
