<template>
  <div
    class="p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container config-select"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4 p-b-4"
    >
      {{ label }}
    </p>
    <div class="config-select__select-container">
      <CommonSelect
        :model-value="currentOption"
        :options="options"
        :placeholder="placeholder || 'Select theme'"
        class="config-select__select"
        @update:model-value="handleChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SelectProps } from "ant-design-vue";

import { SimpleControlValue } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: SimpleControlValue;
  selectOptions: SelectProps["options"];
  label: string;
  placeholder?: string;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: SimpleControlValue): void;
}>();

const currentOption = computed<SimpleControlValue>(() => {
  return props.modelValue;
});

const options = ref<SelectProps["options"]>(props.selectOptions);

const handleChange = (value: SimpleControlValue) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.config-select {
  &__select-container {
    @include flex(center, space-between, 0);
    width: 100%;
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }

  &__icon {
    color: $c-grey-45;
  }
}
</style>
