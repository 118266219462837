<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @blur-tab="$emit('reset-states')"
        @change="$emit('reset-states')"
        @close="$emit('reset-states')"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-design`"
          #[`${menuField}-design`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemDesignControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-content`"
          #[`${menuField}-content`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemContentControls"
          />
        </template>

        <template
          v-for="menuField in menuFields"
          :key="`${menuField}-states`"
          #[`${menuField}-states`]
        >
          <ControlsList
            :widget="widget"
            :controls="menuItemStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/casino-menu/useCasinoMenuConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  useWidgetDynamicParams,
  IParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  menuFields: Array<string>;
  isLive: boolean;
  isRacing?: boolean;
  isFenix?: boolean;
}>();

defineEmits<{
  (e: "reset-states"): void;
}>();

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  titleControls,
  widgetDesignControls,
  widgetContentControls,
  menuItemDesignControls,
  menuItemContentControls,
  menuItemStatesControls,
} = useConfig(ref(props.widget), states);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const bindingParamsArg = computed<IParams>(() => {
  if (props.isFenix) {
    return {
      "gamePageURL": {
        label: "Game page URL",
        sources: ["custom"],
        required: true,
      },
    } as IParams;
  }

  return {
    "categoryPageURL": {
      label: "Category page URL",
      sources: ["custom"],
      required: true,
    },
    "favoritesPageURL": {
      label: "Favorites page URL",
      sources: ["custom"],
      required: true,
    },
    "providersCategoryPageURL": {
      label: "Providers page URL",
      sources: ["custom"],
      required: true,
    },
    "subcategoryPageURL": {
      label: "Sub category page URL",
      sources: ["custom"],
    },
    "providersSubcategoryPageURL": {
      label: "Providers sub category page URL",
      sources: ["custom"],
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});
</script>
