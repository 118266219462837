<template>
  <div
    :class="{
      'language-tabs__steps-wrapper': true,
    }"
  >
    <CommonSpinner
      v-if="!currentLanguage"
      class="language-tabs__loader"
    />

    <div
      v-else
      class="language-tabs__steps"
    >
      <a-tabs
        :default-active-key="currentLanguage.codeWithRegion"
        @change="changeCurrentLanguage($event)"
      >
        <a-tab-pane
          v-for="language in availableLanguages"
          :key="language.codeWithRegion"
          :tab="language.name"
          force-render
        ></a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useLanguagesStore } from "~~/store/languages";
import { useStaticTranslations } from "~~/store/staticTranslations";

const staticTranslationsStore = useStaticTranslations();
const languagesStore = useLanguagesStore();
const i18n = useI18n();

const { staticTranslations } = storeToRefs(staticTranslationsStore);
const { availableLanguages, currentLanguage } = storeToRefs(languagesStore);
const { locale } = i18n;

const changeCurrentLanguage = (activeKey: string) => {
  const selectedLanguage = Object.values(availableLanguages.value).find(
    language => language.codeWithRegion === activeKey
  );

  if (selectedLanguage) {
    locale.value = selectedLanguage.codeWithRegion;
    languagesStore.setCurrentLanguage(selectedLanguage);

    i18n.setLocaleMessage(
      selectedLanguage.codeWithRegion,
      staticTranslations.value[selectedLanguage.codeWithRegion]
    );
  }
};
</script>

<style lang="scss">
.language-tabs {
  width: 100%;

  &__steps-wrapper {
    @include flex(center, end);
    position: fixed;
    z-index: 2;
    width: 100%;
    top: $header-height;
    left: 0;
    height: $space-lg;
    background-color: $c-white;
    padding-left: $space-m;
    border-bottom: 1px solid $c-grey-15;
    z-index: $z-index-max;
  }

  &__steps {
    height: 100%;
    overflow-y: auto;

    .ant-tabs {
      max-height: 100%;
    }

    .ant-tabs-tab {
      padding: 13px 0;
      margin: 0 !important;
      border-bottom: unset;
      text-transform: uppercase;
    }
    .ant-tabs-nav-list {
      gap: 8px;
      margin-right: 8px;
    }

    .ant-tabs-tab-btn {
      margin: 0 auto;
    }
  }

  &__loader {
    justify-content: flex-end;
  }
}
</style>
