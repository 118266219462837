<template>
  <ul
    class="dropdown-menu-field"
    :style="wrapperStyle"
  >
    <SpacingHighlight
      :is-target-selected="isFieldSelected"
      :padding="
        props.field.options.padding || props.field.options.spacing.padding
      "
      :margin="
        props.field.options.margins || props.field.options.spacing.margin
      "
    />
    <slot></slot>
  </ul>
</template>

<script setup lang="ts">
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);

const props = withDefaults(
  defineProps<{
    field: IWidgetField;
    isWrapper?: boolean;
  }>(),
  {
    isWrapper: true,
  }
);

const wrapperStyle = computed<ElementStyle>(() => {
  if (!props.isWrapper) return {};
  return getCommonStyles(props.field.options);
});

const isFieldSelected = computed<boolean>(
  () => props.field.id === selectedField.value?.id
);
</script>

<style>
.dropdown-menu-field {
  position: relative;
}
</style>
