import {
  IVerticalSpacingInput,
  ISpacingInput,
  SpacingInput,
  SpacingKeyName,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

export const useSpacingHighlightStyles = () => {
  const calculateSpacingHighlightStyles = (
    hoveredControl: string,
    widgetSpacing: {
      margin: IVerticalSpacingInput;
      padding: ISpacingInput;
    }
  ) => {
    const [spacingName, value] = (hoveredControl?.split("-") || []) as [
      SpacingKeyName | undefined,
      SpacingInput | undefined
    ];

    let spacing: ISpacingInput;
    let result: { [key: string]: string | number } = {};

    if (spacingName === SpacingKeyName.MARGIN) {
      spacing = widgetSpacing.margin as ISpacingInput;

      result = {
        transform:
          value === SpacingInput.TOP ? "translateY(-100%)" : "translateY(100%)",
      };
    } else {
      spacing = widgetSpacing.padding;
    }

    if (value === SpacingInput.LEFT) {
      result = {
        height: "100%",
        width: getPxValueFromNumber(spacing.left),
        top: 0,
        left: 0,
      };
    }

    if (value === SpacingInput.RIGHT) {
      result = {
        height: "100%",
        width: getPxValueFromNumber(spacing.right),
        top: 0,
        right: 0,
      };
    }

    if (value === SpacingInput.TOP) {
      result = {
        ...result,
        height: getPxValueFromNumber(spacing.top),
        width: "100%",
        top: 0,
        left: 0,
      };
    }

    if (value === SpacingInput.BOTTOM) {
      result = {
        ...result,
        height: getPxValueFromNumber(spacing.bottom),
        width: "100%",
        bottom: 0,
        left: 0,
      };
    }

    return result;
  };

  return {
    calculateSpacingHighlightStyles,
  };
};
