<template>
  <div
    v-if="hasDesignPermissions"
    class="config-radio-text widget-control-container p-t-16 p-b-16 p-l-16 p-r-16"
    :class="{ 'config-radio-text--no-wrap': noTextWrap }"
  >
    <p
      v-if="label"
      :class="{ bold: isBold }"
      class="m-b-8"
    >
      {{ label }}
    </p>

    <div class="config-radio-text__elements">
      <div
        v-for="radio in filteredItems"
        :key="radio.value + ''"
        :aria-checked="
          modelValue === radio.value ||
          (!modelValue && radio.value === defaultSelected)
        "
        :class="{
          'config-radio-text__element--active':
            modelValue === radio.value ||
            (!modelValue && radio.value === defaultSelected),
        }"
        tabindex="0"
        role="radio"
        class="config-radio-text__element f-base pointer"
        @click="handleUpdate(radio.value)"
      >
        <div class="config-radio-text__label text-center">
          {{ radio.label }}

          <CommonIcon
            v-if="radio.icon"
            :name="radio.icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";

type RadioValue = string | number | boolean;

const { items } = defineProps<{
  modelValue?: RadioValue;
  label?: string;
  items: {
    label: string;
    value: RadioValue;
    icon?: string;
    active?: boolean;
  }[];
  isBold?: boolean;
  noTextWrap?: boolean;
  defaultSelected?: string;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: RadioValue): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const filteredItems = computed(() =>
  items.filter(item => item.active !== false)
);

const handleUpdate = (value: RadioValue) => {
  emit("update:model-value", value);
};
</script>

<style lang="scss">
$border-active: 1px solid $c-primary-base;

$configRadio: config-radio-text;

.#{$configRadio} {
  &__elements {
    @include flex(flex-start, flex-start, 0);
  }

  &--no-wrap {
    .#{$configRadio}__label {
      white-space: nowrap;
    }
  }

  &__element {
    @include flex(center, center, 0);
    height: 32px;
    padding: 0 10px;
    color: $c-black;
    border: 1px solid $c-grey-15;
    transition: $base-transition;
    flex-grow: 1;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:not(:last-child):not(.#{$configRadio}__element--active) {
      border-right-color: transparent;
    }

    &--active {
      border: $border-active;
      color: $c-primary-base;

      & + .#{$configRadio}__element {
        border-left-color: transparent;
      }
    }
  }
}
</style>
