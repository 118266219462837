import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DepositListScreen } from "~~/models/widgets/form.model";
import { TextPosition } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateStageButtonCssString,
  generateImageCssString,
  generateCardCssString,
  generateCheckboxCssString,
  generateDropdownMenuCssString,
  generateDropdownMenuItemStyling,
  generateFormCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarWrapperCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateTextChooseIconCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import {
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import { generateFlex, generateTextColor, generateWidth } from "../helpers";
import { getColorFromHex } from "../../widget-settings";
import { generateFormSuccessCssString } from "../utils/generate-form-success";
import { generateFormFailCssString } from "../utils/generate-form-fail";
import { CssGenerator } from "../utils/css-generator";

const generateDepositListWidgetString =
  (widget: IWidgetOptions, list: IWidgetOptions, prefix: string) =>
  (cssString: string): string => {
    const widgetRootCssClass = widget.options._cssClass;
    const widgetFilledCssClass = widget.options.states._cssClassFilled;
    const widgetPaymentCssClass = widget.options.states._cssPaymentClass;
    const widgetFailCssClass = widget.options.states._cssFailClass;
    const widgetSuccessCssClass = widget.options.states._cssSuccessClass;

    // Generating width for payment mathod page here, because we have access to widget
    cssString += CssGenerator.init()
      .className(`${widgetRootCssClass} .${prefix}provider-payment-form-view`)
      .openClassBody()
      .property("width", "100%")
      .property("align-items", "unset")
      .property("justify-content", "center")
      .property("flex-direction", "column")
      .property("margin-left", "auto")
      .property("margin-right", "auto")
      .property("display", "flex")
      .property("gap", "24px")
      .property("flex-direction", "column")
      .closeClassBody()
      .toString();

    cssString += `.${widgetRootCssClass} {${generateDefaultStyles({
      ...widget.options,
      contentWidth: null,
    })}}`;

    /* 
      Content
    */

    cssString += `.${
      widget.options._cssClass
    } .${widgetFilledCssClass} {${generateDefaultStyles({
      ...widget.options.states[DepositListScreen.DATA_FILLED],
    })}}`;

    cssString += `.${
      widget.options._cssClass
    } .${widgetPaymentCssClass} {${generateDefaultStyles({
      ...widget.options.states[DepositListScreen.PAYMENT_METHOD],
    })}}`;

    cssString += `.${
      widget.options._cssClass
    } .${widgetFailCssClass} {${generateDefaultStyles({
      ...widget.options.states[DepositListScreen.FAIL],
    })}}`;

    cssString += `.${
      widget.options._cssClass
    } .${widgetSuccessCssClass} {${generateDefaultStyles({
      ...widget.options.states[DepositListScreen.SUCCESS],
    })}}`;

    cssString += `.${list.options._cssClass}{${generateFlex({
      flex: "flex",
      align: "unset",
      justify: widget.options.gridSettings.alignment,
      gap: widget.options.gridSettings.minCardsGap,
      rowGap: widget.options.gridSettings.rowGap,
      wrap: "wrap",
    })}${generateWidth(100, "%")}}`;

    return cssString;
  };

export const generateDepositListTooltipStyling =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += `.${element.options._cssClass} {${generateDefaultStyles(
      element.options
    )}fill:${getColorFromHex(element.options.fill)}}
    .${element.options._cssClass} svg{fill:${getColorFromHex(
      element.options.fill
    )};}`;

    cssString += `.${element.options._triggerCssClass} {${generateTextColor(
      element.options.textChooseIcon.color
    )}}`;
    return cssString;
  };

export const generateProvidersTitleCssString =
  (element: IWidgetOptions, isLinkStates: boolean) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(element, isLinkStates);

    const flexStyle = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: element.options.alignment,
      gap: "8",
    });

    cssString += `.${element.options._cssClass}{${
      flexStyle + "flex:1;word-break:break-word;"
    }}`;

    return cssString;
  };

export const generateDepositListWidgetCssString = (
  widget: IWidgetOptions,
  prefix?: string
): string => {
  const providersButton = widget.content["providers_item_button"];
  const title = widget.content["title"];
  const providersTitle = widget.content["providers_item_title"];
  const description = widget.content["update_profile_text"];
  const providersDescription = widget.content["providers_item_limits"];
  const providersTooltip = widget.content["providers_item_tooltip"];
  const providersImage = widget.content["providers_item_image"];
  const providersList = widget.content["providers_list"];
  const providersItem = widget.content["providers_item"];
  const formSubmitButton = widget.content["form_submit"];
  const formBackButton = widget.content["form_back_button"];
  const providerCard = widget.content["provider_card"];
  const providerDescription = widget.content["provider_description_1"];
  const providerImage = widget.content["provider_image"];
  const providerTitle = widget.content["provider_title"];
  const form = widget.content["form"];
  const customFields = widget.options._customFields;

  // // Widget class name
  widget.options._cssClass = generateClassName(`${prefix}ListWidget`);

  widget.options.states._cssClassFilled = generateClassName(
    `${prefix}ListWidget_${DepositListScreen.DATA_FILLED}`
  );
  widget.options.states._cssPaymentClass = generateClassName(
    `${prefix}ListWidget_${DepositListScreen.PAYMENT_METHOD}`
  );
  widget.options.states._cssFailClass = generateClassName(
    `${prefix}ListWidget_${DepositListScreen.FAIL}`
  );
  widget.options.states._cssSuccessClass = generateClassName(
    `${prefix}ListWidget_${DepositListScreen.SUCCESS}`
  );

  // Providers class names
  providersButton.options._cssClass = generateClassName(
    `${prefix}providersButton`
  );
  providersTitle.options._cssClass = generateClassName(
    `${prefix}providersTitle`
  );
  providersDescription.options._cssClass = generateClassName(
    `${prefix}providersDescription`
  );
  providersImage.options._cssClass = generateClassName(
    `${prefix}providersImage`
  );
  providersTooltip.options._cssClass = generateClassName(
    `${prefix}providersTooltip`
  );
  providersTooltip.options._triggerCssClass = generateClassName(
    `${prefix}tooltipTrigger`
  );
  providersList.options._cssClass = generateClassName(`${prefix}providersList`);
  providersItem.options._cssClass = generateClassName(`${prefix}providersItem`);

  // // Title and description class name
  title.options._cssClass = generateClassName(`${prefix}title`);
  description.options._cssClass = generateClassName(`${prefix}description`);

  // Cards additional data - width from widget settings

  const cardLayout = providersItem.options.layoutPosition.position;
  let cardsStretchStyle = `.${widget.options._cssClass} .${providersItem.options._cssClass} .card-element:last-child{align-self:stretch;`;

  if (cardLayout === TextPosition.BOTTOM || cardLayout === TextPosition.TOP) {
    cardsStretchStyle += `flex:1;`;
  }

  cardsStretchStyle += "}";

  const cardsAdditionalData = `.${widget.options._cssClass} .${
    providersItem.options._cssClass
  }{${generateWidth(widget.options.gridSettings.cardsSize, "px")}}`;

  // FormBackButton & FormSubmitButton
  formBackButton.options._cssClass = generateClassName(
    `${prefix}formBackButton`
  );
  formSubmitButton.options._cssClass = generateClassName(
    `${prefix}formSubmitButton`
  );
  formSubmitButton.options.iconSettings._cssClass =
    generateClassName("succesButtonIcon");

  // ProviderCard

  providerCard.options._cssClass = generateClassName(`${prefix}providerCard`);
  providerDescription.options._cssClass = generateClassName(
    `${prefix}providerDescription`
  );
  providerImage.options._cssClass = generateClassName(`${prefix}providerImage`);
  providerTitle.options._cssClass = generateClassName(`${prefix}providerTitle`);

  // Form
  form.options._cssClass = generateClassName(`${prefix}form`);
  customFields.labels_styling.options._cssClass = generateClassName(
    `${prefix}label`
  );
  customFields.fields_styling.options._cssClass = generateClassName(
    `${prefix}field`
  );
  customFields.errors_styling.options._cssClass = generateClassName(
    `${prefix}error`
  );
  customFields.tooltips_styling.options._cssClass = generateClassName(
    `${prefix}tooltip`
  );

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass = generateClassName(
    `${prefix}fieldIcon`
  );

  if (customFields.checkboxes_styling) {
    customFields.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName(`${prefix}checkbox_container`);

    customFields.checkboxes_styling.options._checkoxCssClass =
      generateClassName(`${prefix}checkbox`);

    customFields.checkboxes_styling.options._checkoxLabelCssClass =
      generateClassName(`${prefix}checkoxLabel`);

    if (customFields.checkboxes_styling.check) {
      customFields.checkboxes_styling.check._cssClass = generateClassName(
        `${prefix}check`
      );
    }
  }

  //  Dropdown
  if (customFields.dropdowns_styling) {
    // Dropdown class names
    customFields.dropdowns_styling.options._cssClass = generateClassName(
      `${prefix}dropdown`
    );
    customFields.dropdown_menu_styling.options._cssClass = generateClassName(
      `${prefix}dropdownMenu`
    );
    customFields.dropdown_menu_items_styling.options._cssClass =
      generateClassName(`${prefix}dropdownItems`);
  }

  // Checkbox
  const checkboxes =
    customFields.checkboxes_styling &&
    customFields.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          customFields.checkboxes_styling,
          customFields.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(customFields.checkboxes_styling);

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass = generateClassName(
      `${prefix}dropdownIcon`
    );
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName(`${prefix}textChooseIcon`);

  if (customFields.calendar_styling) {
    // Calendar class names
    customFields.calendar_styling.options._wrapperCssClass = generateClassName(
      `${prefix}calendarWrapper`
    );
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName(`${prefix}calendarHeaderWrapper`);
    customFields.calendar_styling.options._weekDayCssClass = generateClassName(
      `${prefix}calendarWeekDay`
    );

    customFields.calendar_styling.options._cellCssClass = generateClassName(
      `${prefix}cell`
    );
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName(`${prefix}cellWrapperCssClass`);

    customFields.calendar_styling.options._navCssClass = generateClassName(
      `${prefix}calendarNav`
    );

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName(`${prefix}datepicker`);
  }

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  return pipeSync<string>(
    generateDepositListWidgetString(widget, providersList, prefix!),
    generateStringWithStates(title, true),
    generateProvidersTitleCssString(providersTitle, true),
    generateStringDefault(description),
    generateStringDefault(providersDescription),
    generateStageButtonCssString(providersButton),
    generateImageCssString(providersImage as IWidgetField),
    generateDepositListTooltipStyling(providersTooltip),
    generateCardCssString(
      providersItem,
      cardsAdditionalData + cardsStretchStyle
    ),
    generateCardCssString(providerCard, cardsAdditionalData),
    generateFormSuccessCssString(widget as IPageContentWidget),
    generateFormFailCssString(widget as IPageContentWidget),
    generateStageButtonCssString(formSubmitButton),
    generateStageButtonCssString(formBackButton),
    generateStringDefault(providerDescription),
    generateStringDefault(providerTitle),
    generateImageCssString(providerImage),
    generateFormCssString(form, widget),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    checkboxes,
    generateIconRightCssString(customFields.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
