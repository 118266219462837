<template>
  <div class="add-widget">
    <div class="add-widget__search-category">
      <CommonDropdownSearch
        v-model:input-value="categoryInputValue"
        :model-value="selectedCategoryOrGroup"
        :options="groupsList"
        :loading="loading"
        :input-placeholder="'Select group'"
        label="Group or category"
        @update:input-value="handleUpdateCategoryInput"
        @update:model-value="handleUpdateCategory"
        @focus="handleSearchFocus"
      />
    </div>

    <transition name="slide-y">
      <div
        v-if="selectedCategoryOrGroup"
        class="add-widget__elements"
      >
        <div class="add-widget__search-widget">
          <a-input
            :value="searchValue"
            placeholder="Search widget"
            @update:value="handleWidgetSearchInput"
          >
            <template #suffix>
              <span class="common-search__icon">
                <CommonIcon
                  v-if="widgetsLoading"
                  name="ant-design:loading-outlined"
                  spin
                  class="add-widget__loader"
                />

                <template v-else>
                  <CommonIcon
                    name="ant-design:search-outlined"
                    @click="handleSearchFocus"
                  />
                  <span
                    v-if="searchValue"
                    class="pointer"
                    @click="handleWidgetSearchInput('')"
                  >
                    <CommonIcon name="custom:close-field-icon" />
                  </span>
                </template>
              </span>
            </template>
          </a-input>
        </div>

        <WidgetsList
          class="m-t-16"
          :items="widgets"
        />

        <div
          v-if="
            !widgets.length &&
            !initialWidgetsLoading &&
            !widgetsLoading &&
            !isTyping
          "
        >
          <p class="text-center italic add-widget__empty">No widgets found</p>
        </div>

        <div
          v-if="initialWidgetsLoading"
          class="text-center"
        >
          <CommonIcon
            name="ant-design:loading-outlined"
            spin
            class="add-widget__loader add-widget__loader-initial"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import CommonDropdownSearch from "~~/components/common/CommonDropdownSearch.vue";
import WidgetsList from "~~/components/widgets/WidgetsList.vue";
import { useCategoriesSearch } from "~~/composables/widgets-config/useCategoriesSearch";
import { useWidgetsSearch } from "~~/composables/widgets-config/useWidgetsSearch";

const {
  groupsList,
  loading,
  selectedCategoryOrGroup,
  categoryInputValue,

  handleUpdateCategoryInput,
  handleSearchFocus,
  handleUpdateCategory,
} = useCategoriesSearch();

const {
  widgets,
  searchValue,
  widgetsLoading,
  initialWidgetsLoading,
  isTyping,
  handleWidgetSearchInput,
} = useWidgetsSearch();
</script>

<style lang="scss">
.add-widget {
  width: 100%;
  padding: $space-m 0;

  &__search-category {
    padding: 0 $space-m $space-m;
  }

  &__elements {
    max-height: calc(100vh - 242px);
    padding: $space-m 0 $space-m $space-m;
    overflow: auto;
    background-color: $c-grey-02;
  }

  &__search-widget {
    max-width: 191px;
    .ant-input-affix-wrapper {
      border-radius: 6px;
      height: $space-xl;
    }
  }

  &__loader {
    display: inline-flex;
    font-size: 14px;
    color: $c-primary-base;

    &-initial {
      font-size: 20px;
    }
  }

  &__empty {
    font-style: italic;
    color: $c-grey-45;
    font-size: 14px;
    margin: 0;
  }

  .ant-input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
