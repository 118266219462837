import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 24;

const generateHoverButtonField = () => {
  return {
    type: "UnknownField",
    options: { _active: false },
    name: "hover_button",
  };
};

const v24WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["BetsListFenixMobileWidget"].includes(widget.name)) {
    if (!("hover_button" in widget.content)) {
      widget.content.hover_button = generateHoverButtonField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v24WidgetMigration;
