import { State, TextStyle } from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    fill: {
      color: "#FFFFFF",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 15),
    }),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: { top: 4, bottom: 4, right: 16, left: 16 },
    margins: {
      top: 0,
      bottom: 0,
    },
    text: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    icon: {
      value: "ant-design:arrow-right-outlined",
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      _active: false,
    },
    arrow: {
      value: "Down",
      default_value: "Down",
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      _active: true,
    },
    states: {
      [State.HOVER]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        icon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        arrow: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        icon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        arrow: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const ITEMS_INITIAL = () => ({
  options: {
    _active: true,
    fill: {
      color: "#FFFFFF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "#000000",
        opacity: 12,
      },
      x: 0,
      y: 3,
      blur: 6,
      spread: 4,
    },
    cornerRadius: getDefaultCornerRadiusValue(8),
    padding: {
      top: 4,
      bottom: 4,
      left: 4,
      right: 4,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const ITEMS_GROUP_INITIAL = () => ({
  options: {
    _active: true,
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: {
      top: 4,
      bottom: 4,
      right: 12,
      left: 12,
    },
    margins: {
      top: 4,
      bottom: 4,
    },
    spaceBetween: 1,
    itemsText: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    itemsIcon: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      position: "right",
    },
    states: {
      [State.HOVER]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const ITEM_INITIAL = () => ({
  options: {
    _active: true,
    text: {
      _active: true,
    },
    icon: {
      _active: false,
      value: "ant-design:arrow-right-outlined",
    },
    link: {
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      type: null,
      isNewTabOpen: false,
    },
  },
});
