<template>
  <div :style="containerStyles">
    <div
      :style="itemsContainerStyles"
      class="sportsbbok-fenix-mobile-prematch__tabs"
    >
      <template
        v-for="item in itemsGroupField[0].fields"
        :key="item.id"
      >
        <WidgetField
          v-if="
            (states.widget === State.SPORTSBOOK_LEAGUE &&
              item.name === FieldName.LEAGUE_WINNER) ||
            (states.widget === State.SPORTSBOOK_LEAGUE &&
              item.name === FieldName.EVENTS) ||
            (states.widget !== State.SPORTSBOOK_LEAGUE &&
              item.name !== FieldName.LEAGUE_WINNER)
          "
          :widget="widget"
          :field="item"
          :style="itemContainerFlexStyles"
        >
          <ArrayItemField
            :field="item"
            :state="states.itemsGroup"
            :wrapper-options="wrapperOptions"
            :style="itemStyles"
            :is-items-group-selected="isItemsGroupSelected"
          />
        </WidgetField>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  itemsGroupField: any;
  states: Record<string, State>;
  wrapperOptions: IWidgetOptions;
  isItemsGroupSelected: boolean;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});

const itemsContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.itemsGroupField[0].options.distance),
    justifyContent: props.itemsGroupField[0].options.containerAlignment,
  };
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.states.itemsGroup === State.DEFAULT) {
    return props.itemsGroupField[0].options;
  }

  return props.itemsGroupField[0].options.states[props.states.itemsGroup];
});

const itemContainerFlexStyles = computed<ElementStyle>(() => {
  return {
    flex:
      props.itemsGroupField[0].options.resizing === ResizingType.FILL
        ? "auto"
        : "unset",
  };
});

const itemStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.itemsGroupField[0].options,
      ...activeStateOptions.value,
    }),
    ...getTextCommonStyles({
      ...props.itemsGroupField[0].options,
      ...activeStateOptions.value,
    }),
    justifyContent: props.itemsGroupField[0].options.alignment,
  };
});
</script>

<style lang="scss">
.sportsbbok-fenix-mobile-prematch {
  &__tabs {
    display: flex;
  }
}
</style>
