<template>
  <div>
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useImageWidgetConfig } from "~~/composables/widgets/image/useImageWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const { selectedWidget } = storeToRefs(useWidgetSettingsStore());

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  imageControls,
} = useImageWidgetConfig(ref(props.widget));
</script>
