import { pipeSync } from "~~/helpers/pipe";
import type { IPageContentWidget } from "~~/models/page.model";
import type {
  ICustomField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  type IFillControl,
  TextPosition,
  SliderItemsPosition,
} from "~~/models/widgets/widget-controls.model";
import { Sizing } from "~~/models/grid.interface";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import {
  TEXT_SLIDER_ARROWS_WIDTH,
  TEXT_SLIDER_PAGINATION_HEIGHT,
} from "~~/constants/configs/text-slider/settings";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateBorderStyle,
  generateFillImageColorStyle,
  generateFlex,
  generateMarginStyle,
  generateMaxWidth,
  generateShadowStyle,
  generateTextColor,
  generateWidth,
} from "../helpers";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import { getColorFromHex } from "../../widget-settings";

export const generateWidgetCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateFillImageColorStyle(widget.options.fillImageColor),
      generateBorderStyle(widget.options.border),
      generateShadowStyle(widget.options.shadow),
      generateMarginStyle(widget.options.margins),
      generateWidth(100, "%"),
    ];

    if (widget.options.size.width.type === Sizing.FIXED) {
      content.push(
        generateWidth(
          widget.options.size.width.value.value,
          widget.options.size.width.value.type
        ),
        generateMaxWidth(`100%`)
      );
    }

    return (
      cssString +
      generateCssClassWithContent({
        className: widget.options._cssClass,
        content: content.join(""),
      })
    );
  };

export const generateIconColorCssString =
  (className: string, color: IFillControl) =>
  (cssString: string): string => {
    return (
      cssString +
      generateCssClassWithContent({
        className,
        content: generateTextColor(color),
      })
    );
  };

export const getSliderPaddingData = (widget: IWidgetOptions) => {
  const paddingData = { ...widget.options.slidePadding };
  const items = widget.content["items"];

  if (
    widget.options.sliderPagination.isActive &&
    items.value.length > 1 &&
    widget.options.sliderPagination.position === SliderItemsPosition.INSIDE
  ) {
    paddingData.bottom = paddingData.bottom + TEXT_SLIDER_PAGINATION_HEIGHT;
  }

  if (
    widget.options.sliderArrows.isActive &&
    items.value.length > 1 &&
    widget.options.sliderArrows.position === SliderItemsPosition.INSIDE
  ) {
    paddingData.left = paddingData.left + TEXT_SLIDER_ARROWS_WIDTH;
    paddingData.right = paddingData.right + TEXT_SLIDER_ARROWS_WIDTH;
  }

  return paddingData;
};

const generateSlidesCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const items = widget.content["items"];

    (items.value as ICustomField[]).forEach(slide => {
      slide.options._cssClass = generateClassName("slide");
      slide.options._textContainerCssClass =
        generateClassName("slideTextContainer");

      cssString += generateDefaultStylesWithStates(
        {
          ...slide,
          options: {
            ...slide.options,
            padding: getSliderPaddingData(widget),
          },
        },
        false
      );

      const flexDirections: Partial<Record<TextPosition, string>> = {
        [TextPosition.TOP]: "flex-direction: column;",
        [TextPosition.RIGHT]: "flex-direction: row-reverse;",
      };

      const { textPosition } = slide.options;
      const currentTextPosition = textPosition.position as TextPosition;
      const sliderTextPositionCssContent =
        generateFlex({
          flex: "flex",
          align: "flex-start",
          justify: "flex-start",
        }) +
        (flexDirections[currentTextPosition] || "") +
        (currentTextPosition !== TextPosition.TOP
          ? `align-items:${textPosition.alignment};`
          : "");

      cssString += generateCssClassWithContent({
        className: slide.options._textContainerCssClass,
        content: sliderTextPositionCssContent,
      });

      slide?.fields?.forEach(slideField => {
        slideField.options._cssClass = generateClassName(slideField.name);

        switch (slideField.name) {
          case FieldName.TITLE:
            cssString += generateStringWithStates(slideField, true)("");
            break;

          case FieldName.DESCRIPTION_1:
            cssString += generateStringDefault(slideField)("");
            break;

          case FieldName.BUTTON:
            cssString += generateStageButtonCssString(slideField)("");
            break;
        }
      });
    });

    return cssString;
  };

export const generatePaginationColorCssString =
  (className: string, color: IFillControl) =>
  (cssString: string): string => {
    return (
      cssString +
      generateCssClassWithContent({
        className,
        pseudoClassName: " .ssr-carousel-dots",
        childClassName: "ssr-carousel-dot-icon",
        content: `background-color: ${getColorFromHex(color)}`,
      })
    );
  };

export const generateTextSliderWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("TextSliderWidget");
  widget.options.sliderPagination._cssClass =
    generateClassName("sliderPagination");
  widget.options.sliderArrows._cssClass = generateClassName("sliderArrows");

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateSlidesCssString(widget),
    generatePaginationColorCssString(
      widget.options.sliderPagination._cssClass,
      widget.options.sliderPagination.color
    ),
    generateIconColorCssString(
      widget.options.sliderArrows._cssClass,
      widget.options.sliderArrows.color
    )
  )("");
};
