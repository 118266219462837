<template>
  <div :class="['config-slider-arrows', customClasses]">
    <ConfigToggle
      v-if="hasContentPermissions && showToggle"
      label="Arrows"
      size="small"
      :allow-disable="true"
      :model-value="modelValue.isActive"
      @update:model-value="handleUpdate('isActive', $event)"
    />

    <div
      v-if="hasContentPermissions"
      class="config-slider-arrows__radio-wrapper m-t-16"
    >
      <div
        class="config-slider-arrows__radio-element f-base pointer"
        :class="{
          'config-slider-arrows__radio-element--active':
            modelValue.icon === SliderArrowIcon.OUTLINED,
        }"
        @click="handleUpdate('icon', SliderArrowIcon.OUTLINED)"
      >
        <CommonIcon name="custom:slider-arrows-1" />
      </div>

      <div
        class="config-slider-arrows__radio-element f-base pointer"
        :class="{
          'config-slider-arrows__radio-element--active':
            modelValue.icon === SliderArrowIcon.ARROW,
        }"
        @click="handleUpdate('icon', SliderArrowIcon.ARROW)"
      >
        <CommonIcon name="custom:slider-arrows-2" />
      </div>

      <div
        class="config-slider-arrows__radio-element f-base pointer"
        :class="{
          'config-slider-arrows__radio-element--active':
            modelValue.icon === SliderArrowIcon.CARET,
        }"
        @click="handleUpdate('icon', SliderArrowIcon.CARET)"
      >
        <CommonIcon name="custom:slider-arrows-3" />
      </div>
    </div>

    <template v-if="modelValue.isActive">
      <ConfigColorPickerInput
        class="p-l-0 p-b-16 p-r-0 border-bottom-none"
        :model-value="modelValue.color"
        label="Color"
        :type="ColorPickerType.TEXT"
        @update:model-value="handleUpdate('color', $event)"
      />

      <ConfigRangeSlider
        v-if="showSizeControl"
        :model-value="modelValue.size"
        :min-value="minValue"
        :max-value="maxValue"
        label="Size"
        class="widget-control__input m-t-16 p-t-16"
        @update:model-value="handleUpdate('size', $event)"
      />

      <ConfigSliderItemsPosition
        :model-value="modelValue.position"
        @update:model-value="handleUpdate('position', $event)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  IFillControl,
  SliderArrowIcon,
  SliderItemsPosition,
} from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

interface SliderArrows {
  isActive: boolean;
  icon: SliderArrowIcon;
  color: IFillControl;
  position: SliderItemsPosition;
  size?: number;
}

const props = withDefaults(
  defineProps<{
    modelValue: SliderArrows;
    showToggle?: boolean;
    showSizeControl?: boolean;
    customClasses?: string;
    minValue?: number;
    maxValue?: number;
  }>(),
  {
    showToggle: true,
    customClasses: "wconfig-item m-t-16",
    minValue: 12,
    maxValue: 48,
    showSizeControl: false,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: SliderArrows): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleUpdate = (key: keyof SliderArrows, value: unknown) => {
  emit("update:modelValue", { ...props.modelValue, [key]: value });
};
</script>

<style lang="scss">
.config-slider-arrows {
  &__radio-wrapper {
    @include flex(center, space-between, $space-s);
  }

  &__radio-element {
    position: relative;
    padding: 8px;
    color: $c-black;
    border: 1px solid $c-grey-15;
    border-radius: 6px;
    @include flex-column(center, space-between, 0);
    flex-grow: 1;

    svg {
      width: 100%;
      height: 36px;
    }

    &--active {
      padding: 7px;
      border: 2px solid $c-primary-base;
    }
  }
}
</style>
