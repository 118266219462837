<template>
  <div
    :style="containerStyles"
    class="event-markets"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :style="marketCardsStyles"
    >
      <div class="event-markets__header">
        <WidgetField
          :widget="widget"
          :field="marketSuspendedStatusField"
          class="event-markets-widget__field"
        >
          <div
            :style="marketSuspendedStatusStyles"
            class="event-markets-suspended-status"
          >
            <BasicTitleField :field="marketSuspendedStatusField">
              <span>{{ item.market_suspended_status }}</span>
            </BasicTitleField>
          </div>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="marketNameField"
          class="event-markets-widget__field"
        >
          <BasicTitleField :field="marketNameField">
            <span> {{ item.market_name }} </span>
          </BasicTitleField>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="favouriteMarketField"
          :style="favouriteMarketStyles"
        >
          <CommonIcon
            :size="favouriteMarketField.options.iconSize"
            :name="`ant-design:pushpin-${index === 0 ? 'filled' : 'outlined'}`"
          />
        </WidgetField>
      </div>
      <div>
        <WidgetField
          :widget="widget"
          :field="betItemField"
        >
          <div
            :style="itemsContanerStyles"
            class="event-markets__items-container"
          >
            <div
              :style="betItemsContainerStyles"
              class="event-markets__items-list"
            >
              <div
                v-for="(inner, index2) in item.bet_items"
                :key="index2"
                class="event-markets__item"
                :style="betItemContainerStyles"
              >
                <!-- <EventBetItem
                  :state-options="currentStateOptions"
                  :item-value="inner"
                  :bet-item-field="betItemField"
                /> -->

                <SportsbookBetItem
                  :item-value="inner"
                  :state-options="currentStateOptions"
                  :bet-item-field="betItemField"
                  :is-up="betItemState === State.UP"
                  :is-down="betItemState === State.DOWN"
                />
              </div>
            </div>
          </div>
        </WidgetField>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { getColorStyle } from "~~/assets/utils/widget-settings";

const items = [
  {
    market_name: "Market Name",
    market_suspended_status: "Suspended",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    market_name: "Market Name",
    market_suspended_status: "Suspended",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    market_name: "Market Name",
    market_suspended_status: "Suspended",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  marketNameField: IWidgetField;
  favouriteMarketField: IWidgetField;
  marketSuspendedStatusField: IWidgetField;
  betItemField: IWidgetField;
  betItemState: State;
}>();

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemState === State.DEFAULT) {
    return props.betItemField.options;
  }

  return props.betItemField.options.states[props.betItemState];
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    "grid-template-columns": `repeat(${props.field.options.columns}, 1fr)`,
    "column-gap": getPxValueFromNumber(
      props.field.options.distanceBetweenCards
    ),
    "row-gap": getPxValueFromNumber(props.field.options.distanceBetweenCards),
  };
});

const marketCardsStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options.card),
  };
});

const marketSuspendedStatusStyles = computed<ElementStyle>(() => {
  const styles = { ...props.marketSuspendedStatusField.options };
  delete styles.padding;
  return {
    ...getCommonStyles(styles),
  };
});

const favouriteMarketStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.favouriteMarketField.options.color),
    marginLeft: "auto",
  };
});

const itemsContanerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
  };
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const flexDirection =
    props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  return {
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
    flexDirection,
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});
</script>

<style lang="scss" scoped>
.event-markets {
  display: grid;
  overflow: hidden;
  &__header {
    @include flex(center, flex-start, 0);
    margin-bottom: 16px;
  }

  &-widget__field:last-child {
    flex-grow: 1;
  }

  &__items-container {
    @include flex(center, flex-start, 0);
  }

  &__items-list {
    @include flex(flex-start, flex-start, 0);
    flex-grow: 1;
    width: 100%;
  }

  &__item {
    flex: 1;
    width: 0;
  }
}
</style>
