type Args = {
  states: Ref<Record<string, string>>;
  defaultStates: Record<string, string>;
  resetConditionHandler?: (stateName: string) => boolean;
};

export const useStates = ({
  states,
  defaultStates,
  resetConditionHandler,
}: Args) => {
  const resetStates = (): void => {
    for (const key in states.value) {
      if (resetConditionHandler) {
        const result = resetConditionHandler(key);

        if (!result) {
          continue;
        }
      }
      states.value[key] = defaultStates[key];
    }
  };

  const handleStateUpdate = (stateDetails: {
    state: string;
    value: string;
  }): void => {
    states.value[stateDetails.state] = stateDetails.value;
  };

  return {
    resetStates,
    handleStateUpdate,
  };
};
