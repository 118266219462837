<template>
  <div class="sportsbook-fenix-mobile-prematch-search">
    <WidgetField
      :widget="widget"
      :style="searchContainerStyles"
      :field="fields.search_container.field"
    >
      <WidgetField
        v-if="fields.search_title.field.options._active"
        :widget="widget"
        :field="fields.search_title.field"
      >
        <BasicTitleField :field="fields.search_title.field" />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :style="searchBoxStyles"
        :field="fields.search_box.field"
      >
        <WidgetField
          :widget="widget"
          :field="fields.search_field.field"
        >
          <DropdownTriggerField
            :widget="widget"
            :field="fields.search_field.field"
            :value="fields.search_field.field.options.text.value"
            :placeholder-value="
              fields.search_field.field.options.placeholder.value
            "
            :state="states.search_field"
          />
        </WidgetField>
      </WidgetField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="fields.search_result_items.field"
      :style="searchResultsBoxStyle"
      class="sportsbook-fenix-mobile-prematch-search-results__items"
    >
      <span
        v-for="id in 7"
        :key="id"
      >
        Country - League Name
      </span>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getBackgroundFillStyle } from "~~/assets/utils/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const fields = useWidgetFields(props.widget);

const searchResultsBoxStyle = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "flex-direction": "column",
    "gap": `${fields.value.search_result_items.field.options.spaceBetween}px`,
    ...getCommonStyles({
      ...fields.value.search_result_items.field.options,
      ...activeStateOptions.value,
    }),
    ...getTextCommonStyles({
      ...textOptions.value,
      ...activeStateOptions.value,
    }),
  };
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.states.search_result_items === State.DEFAULT) {
    return fields.value.search_result_items.field.options;
  }

  return fields.value.search_result_items.field.options.states[
    props.states.search_result_items
  ];
});

const textOptions = computed<IWidgetOptions>(() => ({
  ...activeStateOptions.value.itemsText,
}));

const searchContainerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.search_container.field.options,
    }),
    ...getBackgroundFillStyle(
      fields.value.search_container.field.options.fillImageColor
    ),
  };
});

const searchBoxStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.search_box.field.options,
    }),
    ...getBackgroundFillStyle(
      fields.value.search_box.field.options.fillImageColor
    ),
  };
});
</script>

<style lang="scss">
.sportsbook-fenix-mobile-prematch-search {
  position: relative;

  .dropdown-trigger-field {
    .common-icon {
      font-size: 1.5em;
    }
  }
  &-results {
    &__items {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
