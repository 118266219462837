<template>
  <BasicSlideContainer
    :slide-field="slideField"
    :widget="widget"
    :show-arrows="showArrows"
    :show-pagination="showPagination"
    :states="states"
    class="text-slide"
  >
    <div
      class="text-slide__content"
      :style="containerStyles"
      :class="{
        'text-slide__content--top':
          textPositionInWidget?.position === TextPosition.TOP,
        'text-slide__content--left':
          textPositionInWidget?.position === TextPosition.LEFT,
        'text-slide__content--right':
          textPositionInWidget?.position === TextPosition.RIGHT,
      }"
    >
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        :style="titleStyles"
        class="text-slide__field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :state="states.title_link"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.description_1.active"
        :widget="widget"
        :field="fields.description_1.field"
        :style="descriptionStyle"
        class="text-slide__field"
      >
        <BasicDescriptionField
          ref="descriptionField"
          :fields="descriptionFields"
          :widget="widget"
        />
      </WidgetField>
    </div>

    <WidgetField
      v-if="fields.button.active"
      :widget="widget"
      :field="fields.button.field"
      class="text-slide__field"
    >
      <BasicButtonField
        :field="fields.button.field"
        :current-state="states.button"
      />
    </WidgetField>
  </BasicSlideContainer>
</template>

<script setup lang="ts">
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import type {
  IWidgetWithFields,
  IWidgetField,
  ICustomField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESCRIPTION_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { useWidgetsStore } from "~~/store/widgets";
import {
  SpacingKeyName,
  State,
  TextPosition,
  TextPositionComplex,
} from "~~/models/widgets/widget-controls.model";
import {
  SLIDE_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/text-slider/initial-data";
import { getSpacing } from "~~/assets/utils/widget-settings";
import { useTextPositionStyles } from "~~/composables/fields/description/useTextPositionStyles";
import { getDescriptionFields } from "~~/assets/utils/widget/fields/description";

import BasicSlideContainer from "../basicSlider/BasicSlideContainer.vue";

const props = defineProps<{
  slideField: ICustomField;
  states: Record<string, State>;
  widget: IWidgetWithFields;
  showPagination: boolean;
  showArrows: boolean;
}>();

const widgetsStore = useWidgetsStore();

const fields = useWidgetFields(
  props.slideField as unknown as IWidgetWithFields
);

const textPositionInWidget = computed<TextPositionComplex>(() => {
  return props.slideField.options.textPosition;
});

const { titleStyles, descriptionStyles, containerStyles } =
  useTextPositionStyles(textPositionInWidget);

const descriptionStyle = computed(() => ({
  ...descriptionStyles.value,
  ...getSpacing(
    fields.value.description_1.field.options.margins,
    SpacingKeyName.MARGIN
  ),
}));

const descriptionFields = computed<IWidgetField[]>(() => {
  return getDescriptionFields(props.slideField.fields, true);
});

const initiaSlideOptions = prefillEmptyOptions(
  props.slideField.options,
  SLIDE_INITIAL().options
);

const initialTitleOptions = prefillEmptyOptions(
  fields.value.title.field.options,
  TITLE_INITIAL().options
);

const initialDescriptionOptions = prefillEmptyOptions(
  fields.value.description_1.field.options,
  {
    ...DESCRIPTION_INITIAL().options,
    margins: {
      top: 0,
      bottom: 0,
    },
  }
);

const initialButtonOptions = prefillEmptyOptions(
  fields.value.button.field.options,
  BUTTON_INITIAL().options
);

widgetsStore.updateFieldOptions(props.slideField, initiaSlideOptions);
widgetsStore.updateFieldOptions(fields.value.title.field, initialTitleOptions);
widgetsStore.updateFieldOptions(
  fields.value.description_1.field,
  initialDescriptionOptions
);
widgetsStore.updateFieldOptions(
  fields.value.button.field,
  initialButtonOptions
);
</script>

<style lang="scss">
$text-slide: "text-slide";

.#{$text-slide} {
  height: 100%;

  &__content {
    @include flex(flex-start, flex-start, 0);

    &--top {
      flex-direction: column;

      .#{$text-slide}__field {
        width: 100%;
      }
    }

    &--right {
      flex-direction: row-reverse;
    }
  }

  &__field {
    flex-grow: 1;
  }

  &__description-typography {
    border-bottom: 1px solid $c-grey-15;

    .widget-control-container {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }
}
</style>
