<!-- eslint-disable vue/no-v-html -->
<template>
  <CommonIcon
    :style="iconStyle"
    :name="value"
  />
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import {
  getFontSizeStyle,
  getColorStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";

const props = defineProps<{
  options?: IWidgetOptions;
  value: string;
  state?: State;
  field?: IWidgetField;
}>();

const iconStyle = computed<ElementStyle>(() => {
  const options = props.options || props.field?.options || {};

  let styles: ElementStyle = {
    ...getFontSizeStyle(options.size),
    ...getColorStyle(options.color),
    fill: getColorStyle(options.color).color,
  };

  if (options.padding) {
    styles = {
      ...styles,
      ...getSpacing(options.padding, SpacingKeyName.PADDING),
    };
  }

  return styles;
});
</script>

<style lang="scss">
.basic-title-widget {
  word-wrap: break-word;

  &__content {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
