<template>
  <WidgetField
    v-if="field.options._active"
    :widget="widget"
    :field="field"
    class="bets-list-fenix-mobile-sports__event-button"
    :style="eventButtonStyles"
  >
    <BasicTitleField
      v-if="field.options.text._active"
      :field="field"
      :options="field.options"
      :state="state"
      :value="value"
    />

    <CommonIcon
      v-if="field.options.icon._active"
      :name="field.options.icon.value"
      :style="eventButtonIconStyles"
    />
  </WidgetField>
</template>

<script setup lang="ts">
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { Alignment, State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  state: State;
  isLayoutFirstMode?: boolean;
  value?: string;
}>();

const activeButtonStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const eventButtonIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(activeButtonStateOptions.value.icon.textColor),
    ...getFontSizeStyle(props.field.options.icon.iconSize),
  };
});

const eventButtonStyles = computed<ElementStyle>(() => {
  const result = getCommonStyles(props.field.options, props.state);

  result["gap"] = getPxValueFromNumber(props.field.options.icon.gap);
  result["justify-content"] = props.field.options.display.alignment;

  if (props.field.options.icon.position === Alignment.LEFT) {
    result["flex-direction"] = "row-reverse";
  }

  if (props.field.options.display.resizing === "fill") {
    result["flex-grow"] = "1";
  }

  if (
    props.field.name === "event_button" &&
    !props.isLayoutFirstMode &&
    props.field.options.display.resizing === "hug"
  ) {
    result["width"] = "fit-content";
  }

  return result;
});
</script>
