<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <UserIdWidgetSettings :widget="widget" />
    <div
      :style="widgetContentStyle"
      class="uset-id-widget"
    >
      <WidgetField
        :widget="widget"
        :field="fields.username.field"
      >
        <BasicTitleField :field="fields.username.field">
          Username</BasicTitleField
        >
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.account_number.field"
      >
        <BasicTitleField :field="fields.account_number.field">
          <p
            :style="accountNumberStyles"
            class="uset-id-widget__account-number"
          >
            <span>Account number</span>
            <CommonIcon
              :name="fields.account_number.field.options.icon.value"
            />
          </p>
        </BasicTitleField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/user-id/useUserIdInit";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { getColorFromHex } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields } = useInit(toRef(props.widget));

const accountNumberStyles = computed<ElementStyle>(() => {
  const icon = fields.value.account_number.field.options.icon;

  return {
    ...getStylesAsVars(
      {
        color: getColorFromHex(icon.color) as string,
        fontSize: getPxValueFromNumber(icon.size),
      },
      "icon"
    ),
    gap: getPxValueFromNumber(
      fields.value.account_number.field.options.icon.distance
    ),
  };
});

const widgetContentStyle = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.widget.options.display.distance),
  };
});

init();
</script>

<style lang="scss">
.uset-id-widget {
  @include flex-column(stretch, flex-start, 0);
  &__account-number {
    @include flex(center, flex-start, 0);
    margin: 0;

    svg {
      color: var(--icon-color);
      font-size: var(--icon-fontSize);
    }
  }
}
</style>
