<template>
  <div
    :style="style"
    class="event-bet-builder__header"
  >
    <BasicTitleField
      :field="field"
      class="event-bet-builder__header__text"
      :value="value"
    />

    <CommonIcon
      :style="iconStyle"
      :name="
        iconMapping[field.options.arrowSettings.value] ||
        field.options.arrowSettings.value
      "
    />
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
  getColorFromHex,
  getDecorationValue,
} from "~~/assets/utils/widget-settings";
import { iconMapping } from "~~/constants/icons-mapping";
import { getStylesAsVars } from "~~/assets/utils/widget/form";

const props = defineProps<{
  field: IWidgetField;
  state: State;
  value?: string;
}>();

const stateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const style = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.field.options,
      ...stateOptions.value,
    }),
    ...getStylesAsVars({
      textColor: getColorFromHex(stateOptions.value.color) as string,
      ...getDecorationValue(stateOptions.value.decoration),
    }),
  };
});

const iconStyle = computed<ElementStyle>(() => {
  const options = props.field.options?.arrowSettings || {};

  return {
    ...getFontSizeStyle(options.iconSize),
    ...getColorStyle(stateOptions.value.arrowSettings.textColor),
  };
});
</script>

<style lang="scss">
.event-bet-builder {
  &__header {
    @include flex(center, space-between);

    .basic-title-widget__content {
      color: var(--textColor) !important;
      font-style: var(--fontStyle) !important;
      text-decoration: var(--textDecoration) !important;
      font-weight: var(--fontWeight, 400) !important;
    }

    .common-icon {
      transform: rotate(180deg);
    }

    &__text {
      flex-grow: 1;
    }
  }
}
</style>
