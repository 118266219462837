import {
  getDefaultBorderValue,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { TextStyle } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 18;

const initialBetItemState = () => {
  return {
    fill: getDefaultFillValue(),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    name: {
      color: getDefaultFillValue("#1677ff"),
      theme: 13,
      decoration: [],
    },
    coef: {
      color: getDefaultFillValue("#1677ff"),
      theme: 13,
      decoration: [TextStyle.BOLD],
    },
  };
};

const v17WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["EventWidget"].includes(widget.name)) {
    if ("bet_items" in widget.content) {
      if (!widget.content.bet_items.options.states.up) {
        widget.content.bet_items.options.states.up = initialBetItemState();
      }

      if (!widget.content.bet_items.options.states.down) {
        widget.content.bet_items.options.states.down = initialBetItemState();
      }
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v17WidgetMigration;
