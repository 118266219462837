import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

import { useFormFieldConfig } from "../form/useFormFieldConfig";

import { useBetslipItems } from "./useBetslipItems";
import { useBetslipWidgetControls } from "./useBetslipWidgetControls";
import { useBetslipHeaderControls } from "./useBetslipHeaderControls";
import { useBetslipTabsControls } from "./useBetslipTabsControls";
import { useBetslipBetsContainerControls } from "./useBetslipBetsContainerControls";
import { useBetslipMessagesControls } from "./useBetslipMessagesControls";
import { useBetslipResultContainerControls } from "./useBetslipResultContainerControls";
import { useBetslipButtonsControls } from "./useBetslipButtonsControls";

export const useBetslipConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const {
    BETSLIP_DROPDOWN_ITEMS,
    MY_BETS_DROPDOWN_ITEMS,
    BETS_RESULT_DROPDOWN_ITEMS,
    TABS,

    BETS_SETTINGS_BETSLIP_ITEMS,
    BETS_SETTINGS_BETS_RESULT_ITEMS,
    BETS_SETTINGS_DEFAULT_ITEMS,
    BETS_CARDS_DEFAULT_SETTINGS_ITEMS,
    BETS_CARDS_MY_BETS_SETTINGS_ITEMS,
    BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS,
    EVENT_CONTAINER_ITEMS,
  } = useBetslipItems(widget);

  const currentTabs = computed<any>(() => {
    if (states.value.BetslipWidget !== State.BETSLIP) {
      TABS.bets_container = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];
    } else {
      TABS.bets_container = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
        generateTabItem("Preview", "preview"),
      ];
    }
    return TABS;
  });

  const { widgetDesignControls, widgetContentControls } =
    useBetslipWidgetControls(widget, states, emit);

  const {
    headerDesignControls,
    headerContentControls,
    headerStatesControls,
    headerCountersDesignControls,
    headerCountersContentControls,
  } = useBetslipHeaderControls(states, emit, statesValuePath, fields);

  const {
    betslipTabsDesignControls,
    betslipTabsContentControls,
    betslipTabsStatesControls,
  } = useBetslipTabsControls(states, emit, statesValuePath);

  const {
    betsDesignControls,
    betsContentControls,
    betsPreviewControls,

    betsCardsDesignControls,
    betsCardsContentControls,

    liveStatusDesignControls,
    liveStatusContentControls,

    notAviableStatusDesignControls,
    notAviableStatusContentControls,

    closeIconControls,

    eventNameControls,

    betMarketControls,

    betNameControls,

    coefficientDesignControls,
    coefficientContentControls,
    coefficientStatesControls,

    betsTypeDesignControls,
    betsTypeContentControls,
    betsTypeStatesControls,

    timeControls,

    eventContainerDesignControls,
    eventContainerContentControls,
  } = useBetslipBetsContainerControls(widget, states, emit, statesValuePath, {
    BETS_SETTINGS_BETSLIP_ITEMS,
    BETS_SETTINGS_BETS_RESULT_ITEMS,
    BETS_SETTINGS_DEFAULT_ITEMS,
    BETS_CARDS_DEFAULT_SETTINGS_ITEMS,
    BETS_CARDS_MY_BETS_SETTINGS_ITEMS,
    BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS,
    EVENT_CONTAINER_ITEMS,
  });

  const {
    warningMessageDesignControls,
    warningMessageContentControls,

    myBetsWarningMessageDesignControls,
    myBetsWarningMessageContentControls,

    betsResultSuccessMessageDesignControls,
    betsResultSuccessMessageContentControls,

    successMessageDesignControls,
    successMessageContentControls,
  } = useBetslipMessagesControls();

  const {
    resultDesignControls,
    resultContentControls,

    totalAmountsControls,

    betAmountsControls,
  } = useBetslipResultContainerControls(widget, states);

  const {
    actionButtonDesignControls,
    actionButtonContentControls,
    actionButtonStatesControls,

    removeButtonDesignControls,
    removeButtonContentControls,
    removeButtonStatesControls,

    copyButtonDesignControls,
    copyButtonContentControls,
    copyButtonStatesControls,

    saveChoiseButtonDesignControls,
    saveChoiseButtonContentControls,
    saveChoiseButtonStatesControls,

    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,

    primaryButtonDesignControls,
    primaryButtonContentControls,
    primaryButtonStatesControls,

    secondaryButtonDesignControls,
    secondaryButtonContentControls,
    secondaryButtonStatesControls,
  } = useBetslipButtonsControls(states, emit, statesValuePath);

  // FORM
  const {
    formLabelsStyles,
    formFieldDesignStyles,
    formFieldContentStylesWithIconGap: formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const formContentControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
    };
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return [
      {
        "value": "labels_styling",
        "label": "Label style",
      },
      {
        "value": "fields_styling",
        "label": "Fields container style",
      },
      {
        "value": "errors_styling",
        "label": "Field errors style",
      },
    ];
  });

  const betslipFormContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  const betslipFormDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    BETSLIP_DROPDOWN_ITEMS,
    MY_BETS_DROPDOWN_ITEMS,
    BETS_RESULT_DROPDOWN_ITEMS,
    currentTabs,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    headerDesignControls,
    headerContentControls,
    headerStatesControls,

    headerCountersDesignControls,
    headerCountersContentControls,

    betslipTabsDesignControls,
    betslipTabsContentControls,
    betslipTabsStatesControls,

    betsDesignControls,
    betsContentControls,
    betsPreviewControls,

    betsCardsDesignControls,
    betsCardsContentControls,

    liveStatusDesignControls,
    liveStatusContentControls,

    notAviableStatusDesignControls,
    notAviableStatusContentControls,

    closeIconControls,

    eventNameControls,

    betMarketControls,

    betNameControls,

    coefficientDesignControls,
    coefficientContentControls,
    coefficientStatesControls,

    resultDesignControls,
    resultContentControls,

    totalAmountsControls,

    warningMessageDesignControls,
    warningMessageContentControls,

    myBetsWarningMessageDesignControls,
    myBetsWarningMessageContentControls,

    betsResultSuccessMessageDesignControls,
    betsResultSuccessMessageContentControls,

    successMessageDesignControls,
    successMessageContentControls,

    betsTypeDesignControls,
    betsTypeContentControls,
    betsTypeStatesControls,

    timeControls,

    eventContainerDesignControls,
    eventContainerContentControls,

    betAmountsControls,

    actionButtonDesignControls,
    actionButtonContentControls,
    actionButtonStatesControls,

    removeButtonDesignControls,
    removeButtonContentControls,
    removeButtonStatesControls,

    copyButtonDesignControls,
    copyButtonContentControls,
    copyButtonStatesControls,

    saveChoiseButtonDesignControls,
    saveChoiseButtonContentControls,
    saveChoiseButtonStatesControls,

    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,

    primaryButtonDesignControls,
    primaryButtonContentControls,
    primaryButtonStatesControls,

    secondaryButtonDesignControls,
    secondaryButtonContentControls,
    secondaryButtonStatesControls,

    formContentControls,

    betslipFormDesignControls,
    betslipFormContentControls,
  };
};
