<!-- TODO(refactoring): create  component label wrapper -->
<template>
  <div class="wconfig-dropdown-menu wconfig-item">
    <div class="wconfig-dropdown-menu__label-container">
      <p class="wconfig-dropdown-menu__label m-b-0 sub-heading">
        {{ label }}
      </p>

      <CommonIcon
        name="ant-design:setting-outlined"
        class="wconfig-dropdown-menu__icon"
        @click="handleFieldOptionsClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getFieldByName } from "~~/assets/utils/widget";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  label?: string;
  fieldName?: string;
}>();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const currentField = computed<IWidgetField | undefined>(() =>
  getFieldByName(selectedWidget.value!, props.fieldName!)
);

const handleFieldOptionsClick = (): void => {
  widgetSettingsStore.setActiveElement(
    selectedWidget.value,
    currentField.value
  );
};
</script>

<style lang="scss">
.wconfig-dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: $space-m;

  padding: $space-m;

  &__label-container {
    @include flex(center, space-between, 0);
  }

  &__icon {
    color: $c-grey-45;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
