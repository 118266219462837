<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgeDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardsDesignControls"
          />
        </template>

        <template #event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardsContentControls"
          />
        </template>

        <template #event_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedDesignControls"
          />
        </template>

        <template #event_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedContentControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_info>
          <ControlsList
            :widget="widget"
            :controls="eventInfoControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef, ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/racing-sportbook-live/useRacingSportbookLiveConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "sportId": {
      label: "Sport ID",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "leagueId": {
      label: "League ID",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "sportsBookPageUrl": {
      label: "Sports book page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgeDesignControls,
  widgetContentControls,

  titleControls,

  eventCardsDesignControls,
  eventCardsContentControls,

  eventSuspendedDesignControls,
  eventSuspendedContentControls,

  eventTimeControls,

  eventInfoControls,

  favIconControls,

  streamIconControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
