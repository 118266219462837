import { BUTTON_INITIAL as initial } from "~~/constants/configs/card-common/initial-constants";

const BUTTON_STYLE_INITIAL = () => {
  return {
    ...initial(),
    title: "Button style",
  };
};

export { BUTTON_STYLE_INITIAL };
