import { useNotification } from "@kyvg/vue3-notification";

export const useFormErrors = () => {
  const notification = useNotification();

  const errors = ref<Record<string, string>>({});

  const parseError = (error: any) => {
    try {
      resetErrors();

      const { data } = error.response;

      Object.entries(data).forEach(([key, items]: any) => {
        errors.value[key] = items[0];
      });
    } catch (err) {
      console.error(err);
      notification.notify({
        type: "error",
        text: "Something went wrong",
      });
    }
  };

  const resetErrors = () => {
    errors.value = {};
  };

  return {
    errors,

    parseError,
    resetErrors,
  };
};
