<template>
  <div v-if="hasDesignPermissions">
    <ConfigColorPickerInput
      :model-value="selectedChildColumnStyles?.backgroundColor"
      :type="ColorPickerType.BACKGROUND"
      label="Background color"
      @update:model-value="
        handleUpdateChildStyling({ backgroundColor: { ...$event } })
      "
    />

    <ConfigVerticalAlignment
      v-if="showMultiColumnSettings"
      class="wconfig-item"
      :model-value="selectedChildColumnStyles?.alignment || null"
      @update:model-value="handleUpdateChildStyling({ alignment: $event })"
    />

    <ConfigColumnWidth
      v-if="showMultiColumnSettings"
      label="Spacing"
      :model-value="selectedChildColumnStyles?.spaceBetween || null"
      class="widget-child-settings__spacing"
      @update:model-value="handleUpdateChildStyling({ spaceBetween: $event })"
    />

    <ConfigMarginInputs
      :model-value="selectedChildColumnStyles?.margin"
      label="Margins"
      :is-all-side-setting="true"
      @update:model-value="handleUpdateChildStyling({ margin: { ...$event } })"
    />

    <ConfigCellHeight
      :model-value="selectedChildColumnStyles?.height"
      @update:model-value="
        handleUpdateChildStyling({
          height: $event,
        })
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { ICellOptions } from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const gridStore = useGridConfig();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedColumn } = storeToRefs(widgetSettingsStore);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const selectedChildColumnStyles = computed<ICellOptions>(() => {
  if (!selectedColumn.value?.cellId) return {};
  return gridStore.cellsOptions[selectedColumn.value?.cellId];
});

const handleUpdateChildStyling = (style: Partial<ICellOptions>): void => {
  if (!selectedColumn.value?.cellId) {
    return;
  }

  gridStore.updateCellsOptions(selectedColumn.value?.cellId, style);
};

const showMultiColumnSettings = computed<boolean>(() => {
  return (selectedColumn.value?.children || []).length > 1;
});
</script>

<style lang="scss">
.widget-child-settings {
  &__spacing {
    border-bottom: unset;
    padding-bottom: 0;
  }
}
</style>
