import { usePermissions } from "~~/composables/permissions/usePermissions";
import { Permissions } from "~~/models/stores/auth-store.model";

const createSectionNameGenerator =
  (permissionType: Permissions) =>
  (value: string): string | undefined => {
    const hasPermissions = usePermissions().has(
      usePermissions().Permissions[permissionType]
    );

    return hasPermissions ? value : undefined;
  };

export const generateDesignSectionName = createSectionNameGenerator(
  Permissions.DESIGN
);
export const generateContentSectionName = createSectionNameGenerator(
  Permissions.CONTENT
);
