<template>
  <div
    v-if="hasContentPermissions"
    :class="{ 'config-form-items--no-order': !isOrderAllowed }"
    class="p-l-16 p-r-16 p-b-16 p-t-16 widget-control-container config-form-items"
  >
    <p class="config-form-items__title sub-heading">{{ label }}</p>

    <DNDContainer
      :container-id="DndContainerId.FORM_ITEMS"
      class="wconfig-dropdown-menu-items__list"
      show-divider
      :divider-position="dividerPosition"
      @drop="handleBlockDrop"
      @over="handleDragOver"
      @update-container-position="handleUpdateContainerPosition"
    >
      <ul>
        <li
          v-for="field in sortedFields"
          :id="`form-item-${field.id}`"
          :key="field.id"
          class="config-form-items__list-element m-b-16"
        >
          <span
            v-if="isOrderAllowed"
            v-draggable="{
              payload: {
                id: field.id,
              },
              containerId: DndContainerId.FORM_ITEMS,
            }"
            class="config-form-items__btn config-form-items__btn-dnd"
          >
            <CommonIcon
              name="custom:dnd-marker"
              class="config-form-items__dnd-icon"
            />
          </span>

          <input
            v-if="field.type !== FormElementName.DESCRIPTION"
            class="config-form-items__value"
            :value="getValue(field)"
            :maxlength="MAX_BUTTON"
            :readonly="isReadOnly(field)"
            @input="handleInput($event, field)"
          />

          <div
            v-else
            class="config-form-items__value config-form-items__editor-container"
          >
            <contenteditable
              :model-value="getLocalizedValue(field.value)"
              :no-html="false"
              tag="div"
              class="config-form-items__editor"
              @update:model-value="handleDescriptionInput($event, field)"
            ></contenteditable>
          </div>

          <span class="config-form-items__edit-btns">
            <span
              class="config-form-items__btn"
              @click="handleEditClick(field)"
            >
              <CommonIcon name="ant-design:edit-outlined" />
            </span>

            <CommonToggleVisibilityButton
              v-if="showVisibilityButton && showVisibilityToggle(field)"
              :model-value="getVisibilityValue(field)"
              class="config-form-items__btn"
              @update:model-value="updateFieldActive($event, field)"
            />
          </span>
        </li>
      </ul>
    </DNDContainer>
  </div>
</template>

<script setup lang="ts">
import contenteditable from "vue-contenteditable";

import { DndContainerId } from "~~/models/common/dropdown.model";
import { FormElementName } from "~~/models/widgets/form.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { IInsertPosition } from "~~/models/grid.interface";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { useDropdownItemsDnd } from "~~/composables/widgets-config/useDropdownItemsDnd";
import { removeHTMLFromString } from "~~/assets/utils/widget/form";
import { useText } from "~~/components/composables/fields/text/useText";
import { MAX_BUTTON } from "~~/constants/input-validation";
import { UpdateFieldEvent } from "~~/models/fields/description.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    fields: {
      visible: IWidgetField[];
      hidden: IWidgetField[];
    };
    customEditHandler?: (field: IWidgetField) => void;
    isOrderAllowed?: boolean;
    showVisibilityButton?: boolean;
    visibilityPath?: string;
    readonlyFieldNames?: string[];
    label?: string;
  }>(),
  {
    customEditHandler: undefined,
    isOrderAllowed: true,
    showVisibilityButton: true,
    readonlyFieldNames: undefined,
    label: "Items",
    visibilityPath: undefined,
  }
);

const emit = defineEmits<{
  (e: "update:description", event: UpdateFieldEvent): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { getLocalizedValue, setLocalizedValue } = useLocalizedValue();

const store = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const updateFieldActive = (value: boolean, field: IWidgetField): void => {
  store.updateFieldOptionValue(
    value,
    props.visibilityPath || "_active",
    field.id
  );
};

const { canUpdateInputValue } = useText(MAX_BUTTON);
const handleEditClick = (field: IWidgetField) => {
  if (props.customEditHandler) {
    props.customEditHandler(field);
    return;
  }

  widgetSettingsStore.setActiveElement(selectedWidget.value, field);
};

const isReadOnly = (field: IWidgetField): boolean => {
  if (
    field.type === FormElementName.DESCRIPTION ||
    props.readonlyFieldNames?.includes(field.name)
  ) {
    return true;
  }

  return false;
};

/*
  TODO extend method with new possible fields
*/
const getValue = (field: IWidgetField) => {
  switch (field.type) {
    case FormElementName.TEXT_INPUT:
    case FormElementName.EMAIL:
    case FormElementName.NUMBER:
    case FormElementName.PASSWORD:
    case FormElementName.DROPDOWN:
    case FormElementName.CHECKBOX:
    case FormElementName.RADIO_GROUP:
    case FormElementName.DATE:
    case FormElementName.PHONE:
    case FormElementName.UPLOAD:
    case FormElementName.FORM_TEXT_INPUT_FIELD:
      return getLocalizedValue.value(field.options.label?.value);

    case FormElementName.DESCRIPTION:
      return removeHTMLFromString(
        getLocalizedValue.value(field.value) as string
      );

    case FormElementName.BUTTON:
    case FormElementName.TITLE:
    default:
      return getLocalizedValue.value(field.value);
  }
};

const handleDescriptionInput = (value: string, field: IWidgetField) => {
  store.updateFieldProperty(
    field,
    setLocalizedValue.value(field.value, value),
    "value"
  );

  emit("update:description", {
    fieldId: field.id,
    value: setLocalizedValue.value(field.value, value),
  });
};

/*
  TODO extend method with new possible fields
*/
const handleInput = (event: Event, field: IWidgetField): void => {
  const target = event.target as HTMLInputElement;
  const value = target.value;

  if (!canUpdateInputValue(value)) return;

  switch (field.type) {
    case FormElementName.TEXT_INPUT:
    case FormElementName.EMAIL:
    case FormElementName.NUMBER:
    case FormElementName.PASSWORD:
    case FormElementName.DROPDOWN:
    case FormElementName.CHECKBOX:
    case FormElementName.RADIO_GROUP:
    case FormElementName.DATE:
    case FormElementName.PHONE:
    case FormElementName.UPLOAD:
    case FormElementName.FORM_TEXT_INPUT_FIELD:
      store.updateFieldOptionValue(
        setLocalizedValue.value(field.options.label.value, value),
        "label.value",
        field.id
      );
      return;

    case FormElementName.DESCRIPTION:
    case FormElementName.BUTTON:
    case FormElementName.TITLE:
    default:
      store.updateFieldValue(
        field.id,
        setLocalizedValue.value(field.value, value)
      );
      return;
  }
};

const handleReorder = (
  targetId: number | string,
  insertPosition: IInsertPosition
): void => {
  const [currFields] = reorderListElements(
    "id",
    targetId,
    insertPosition,
    sortedFields.value
  );

  currFields.forEach((field, index) => {
    field.options.position = index;
  });

  store.updateActiveWidgetFields([...currFields, ...props.fields.hidden]);
};

const sortedFields = computed<IWidgetField[]>(() => {
  const res = [...props.fields.visible];

  return res.sort(
    (fieldA, fieldB) => fieldA.options.position - fieldB.options.position
  );
});

const {
  handleBlockDrop,
  handleDragOver,
  handleUpdateContainerPosition,
  dividerPosition,
} = useDropdownItemsDnd(handleReorder);

const showVisibilityToggle = (field: IWidgetField): boolean => {
  /*
    Element does not belong to form
  */
  if (!field.options._parent) {
    return true;
  }

  /*
    Field is not required,
    so we allow visibility toggle
  */
  if (!field.validation.required) {
    return true;
  }

  return false;
};

const getCustomVisibilityValue = (
  field: IWidgetField,
  value: string
): unknown => {
  const values = value.split(".");
  const last: string = values.pop() as string;
  let src: IWidgetOptions = field.options;

  values.forEach(value => {
    src = src[value] as IWidgetOptions;
  });

  return src[last];
};

const getVisibilityValue = (field: IWidgetField): boolean => {
  if (!props.visibilityPath) {
    return field.options._active as boolean;
  }

  return getCustomVisibilityValue(field, props.visibilityPath) as boolean;
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-alignment {
  @include controls-menu(33%);
}

.config-form-items {
  &__title {
    margin-bottom: $space-m;
  }

  &__edit-btns {
    @include flex(center, flex-start, 4px);
  }

  &__btn {
    @include flex(center, center);
    width: $space-l;
    min-width: $space-l;
    height: $space-l;
    cursor: pointer;

    &-dnd {
      width: calc($space-s + $space-xs);
      min-width: calc($space-s + $space-xs);
    }

    svg {
      fill: $c-grey-45;
      * {
        fill: $c-grey-45;
      }
    }
  }

  &__dnd-icon {
    svg {
      width: 7px;
      height: 11px;
    }
  }

  &__list-element {
    @include flex(center, flex-start, 8px);
  }

  &__value {
    width: 150px;
    min-width: 150px;
    height: 24px;
    background-color: $c-grey-02;
    border-radius: $space-xs;
    padding: 0 $space-s;
    outline: none;
    border: none;
  }

  &--no-order {
    .config-form-items__value {
      width: 100%;
    }
  }

  &__editor {
    height: 24px;
    outline: none;
    border: none;

    &-container {
      padding: 0 $space-s;
      overflow: hidden;
      min-width: 150px;
      max-height: 24px;
    }

    // overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;

    * {
      font-weight: 400 !important;
      text-decoration: none !important;
      text-transform: none !important;
      font-style: normal !important;
      background-color: transparent !important;
      color: #000 !important;
      text-align: left !important;
      font-size: 14px !important;
      cursor: text !important;
    }
  }
}
</style>
