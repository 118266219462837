<template>
  <div
    :class="{
      'widget-settings-sidebar--empty':
        !selectedWidget || widgetIdDetailsLoading,
    }"
    class="widget-settings-sidebar"
  >
    <CommonSpinner v-if="widgetIdDetailsLoading" />

    <div
      v-else-if="activeSideMenuTab === SideMenuTab.CONTENT && !selectedWidget"
      class="widget-settings-sidebar__empty"
    >
      <p class="widget-settings-sidebar__cursor">
        <CommonIcon name="fa:hand-pointer-o" />
      </p>
      <p>Select a widget or element to activate this panel</p>
    </div>

    <div
      v-show="selectedWidget"
      id="settings_sidebar"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import CommonSpinner from "~~/components/common/CommonSpinner.vue";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";
import { useWidgetsStore } from "~~/store/widgets";
import { useUiStore } from "~~/store/ui";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const store = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const uiStore = useUiStore();

const { widgetIdDetailsLoading } = storeToRefs(store);
const { selectedWidget } = storeToRefs(widgetSettingsStore);
const { activeSideMenuTab } = storeToRefs(uiStore);
</script>

<style lang="scss">
.widget-settings-sidebar {
  position: fixed;
  top: ($header-height + $space-lg);
  right: 0;
  height: calc(100vh - ($header-height + $space-lg));
  border-left: 1px solid $c-grey-15;
  background-color: $c-white;
  overflow-y: auto;
  z-index: 6;

  &--empty {
    width: $settings-sidebar-width;
  }

  &__empty {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $c-black;
    padding: 72px 16px;
  }

  &__cursor {
    display: flex;
    justify-content: center;
    margin-bottom: calc($space-xs + $space-s);
  }
}
</style>
