import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useBetslipStatusConfig = (
  widget: Ref<IWidgetWithFields>,
  state: Ref<State>,
  emit: any
) => {
  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
      generateTabItem("Data", "data"),
    ],
    betslip_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = [generateDropdownItem("Counters", "betslip_status")];

  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const statesValuePath = computed<string>(() => {
    if (state.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${state.value}`;
  });

  const widgetDesignControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: widget.value,
        except: ["ConfigSpacingInputs"],
        elements: [
          [
            4,
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: "options.padding",
              valueSource: widget.value,
              options: {
                label: "Padding",
                meta: {
                  isBold: true,
                },
              },
            },
          ],
          [
            5,
            {
              componentPath: "ConfigMarginInputs",
              valuePath: "options.margins",
              valueSource: widget.value,
              options: {
                label: "Margin",
                meta: {
                  isBold: true,
                },
                isAllSideSetting: true,
                showTooltip: true,
              },
            },
          ],
        ],
      }),
    ];
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: widget.value,
        options: {
          label: "Display mode",
          showTitle: false,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: widget.value,
        options: {
          label: "Distance between tabs",
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.headerElementsSettings.fill",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.headerElementsSettings.border",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.headerElementsSettings.shadow",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.headerElementsSettings.cornerRadius",
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.headerElementsSettings.padding",
        valueSource: widget.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.gapBetweenTextAndCounter",
        valueSource: widget.value,
        options: {
          label: "Distance between text and counter",
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      typography({
        source: widget.value as unknown as IWidgetField,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ label: "Counters", value: "betslip_status" }],
        },
      },
    ];
  });

  const widgetStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: state.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.headerElementsSettings.fill`,
        valueSource: widget.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.headerElementsSettings.border`,
        valueSource: widget.value,
        options: {
          label: "Border",
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.headerElementsSettings.shadow`,
        valueSource: widget.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: widget.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Counter"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.fill`,
            valueSource: fields.value.betslip_status.field,
            options: {
              label: "Background color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: fields.value.betslip_status.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const headerCountersDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const headerCountersContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
    ];
  });

  return {
    TABS,
    DROPDOWN_ITEMS,

    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgetStatesControls,

    headerCountersDesignControls,
    headerCountersContentControls,
  };
};
