<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :value="value"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    class="form-upload-input-field"
  >
    <BasicTitleField
      v-if="field.options.caption._active"
      :field="captionField"
      :value="field.options.caption.value"
    />

    <div :style="uploadListStyle">
      <div
        :style="uploadItemStyle"
        class="form-upload-input-field__item"
      >
        <div
          :style="uploadItemLeftStyle"
          class="form-upload-input-field__item-left"
        >
          <CommonIcon
            :style="uploadIconStyle"
            :name="uploadItemField?.options.fileIcon.value"
          />

          <BasicTitleField
            :field="uploadItemField"
            :options="uploadItemTextOptions"
            value="filename.png"
          />
        </div>

        <CommonIcon
          :style="removeIconStyle"
          :name="uploadItemField?.options.deleteIcon.value"
        />
      </div>
    </div>

    <BasicButtonField
      :field="uploadButtonField"
      :current-state="buttonState"
      :custom-value="field.options.uploadButton.value"
    />
  </FormInputBase>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { State } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const itemState = computed<string>(() => {
  return props.states[FormStyleFieldName.UPLOAD_ITEMS];
});

const buttonState = computed<string>(() => {
  return props.states[FormStyleFieldName.UPLOAD_BUTTON];
});

const {
  isErrorVisible,
  isTooltipVisible,
  isCalendarVisible,
  triggerFieldExample,
  hideFieldExample,
} = useFormInputStyling(ref<IWidgetField[]>(props.stylingFields), props.widget);

defineExpose({
  triggerFieldExample,
  hideFieldExample,
  isCalendarVisible,
});

const captionField = computed<IWidgetField | undefined>(() => {
  return props.stylingFields.find(
    field => field.name === FormStyleFieldName.UPLOAD_CAPTION
  );
});

const uploadListField = computed<IWidgetField | undefined>(() => {
  return props.stylingFields.find(
    field => field.name === FormStyleFieldName.UPLOAD_LIST
  );
});

const uploadItemField = computed<IWidgetField | undefined>(() => {
  return props.stylingFields.find(
    field => field.name === FormStyleFieldName.UPLOAD_ITEMS
  );
});

const uploadButtonField = computed<IWidgetField | undefined>(() => {
  return props.stylingFields.find(
    field => field.name === FormStyleFieldName.UPLOAD_BUTTON
  );
});

const uploadItemStateOptions = computed<IWidgetOptions>(() => {
  if (itemState.value === State.DEFAULT) {
    return uploadItemField.value?.options;
  }

  return uploadItemField.value?.options.states[itemState.value];
});

const uploadItemTextOptions = computed<IWidgetOptions>(() => {
  if (itemState.value === State.DEFAULT) {
    return uploadItemField.value?.options.text;
  }

  return {
    ...uploadItemField.value?.options.text,
    ...uploadItemStateOptions.value.text,
  };
});

const uploadListStyle = computed<ElementStyle>(() => {
  return getCommonStyles(uploadListField.value?.options || {});
});

const uploadItemStyle = computed<ElementStyle>(() => {
  const options = uploadItemField.value?.options || {};

  return {
    ...getCommonStyles({
      ...options,
      ...uploadItemStateOptions.value,
    }),
    gap: getPxValueFromNumber(options.distance),
  };
});

const uploadItemLeftStyle = computed<ElementStyle>(() => {
  const options = uploadItemField.value?.options || {};

  return {
    gap: getPxValueFromNumber(options.distance),
  };
});

const uploadIconStyle = computed<ElementStyle>(() => {
  const options = uploadItemField.value?.options?.fileIcon || {};

  return {
    ...getFontSizeStyle(options.iconSize),
    ...getColorStyle(uploadItemStateOptions.value.fileIcon.textColor),
    marginRight: getPxValueFromNumber(options.gap),
  };
});

const removeIconStyle = computed<ElementStyle>(() => {
  const options = uploadItemField.value?.options?.deleteIcon || {};

  return {
    ...getFontSizeStyle(options.iconSize),
    ...getColorStyle(uploadItemStateOptions.value.deleteIcon.textColor),
    marginLeft: getPxValueFromNumber(options.gap),
  };
});
</script>

<style lang="scss">
.form-upload-input-field {
  &__item {
    @include flex(center, space-between, 0);

    &-left {
      @include flex(center, flex-start, 0);
    }
  }
}
</style>
