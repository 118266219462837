import {
  ResizingType,
  SliderTransition,
} from "~~/models/widgets/widget-controls.model";

export const useCarouselInit = () => {
  return {
    carouselSettings: {
      layout: ResizingType.FILL,
      gap: 16,
      maxVisibleCards: 3,
      width: 400,
    },
    carousel: {
      transition: SliderTransition.ALL,
      alignment: "",
      infinite: false,
      autoplay: false,
      speed: 7,
    },
  };
};
