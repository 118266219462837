<template>
  <div class="html-editor-link-modal">
    <CommonModal
      :visible="isLinkModalVisible"
      submit-button-label="Save"
      title="Link"
      :is-submit-disabled="!canSubmitModal"
      @submit="submitLinkModal"
      @cancel="hideLinkModal"
    >
      <template #content>
        <CommonSelect
          :model-value="linkType"
          :options="LINK_TYPE_OPTIONS"
          placeholder="Select link type"
          @update:model-value="handleChangeLinkType"
        />

        <div
          v-if="linkType"
          class="m-t-8 m-b-8"
        >
          <div
            v-if="linkType === LinkType.EXTERNAL || linkType === LinkType.MODAL"
            class="html-editor-link-modal__toggle-container"
          >
            <a-switch
              v-model:checked="isOneLinkForAll"
              size="small"
              @change="handleHasAuthToggleChange"
            />

            <div>General for all users</div>
          </div>

          <div
            v-if="!isOneLinkForAll"
            class="m-t-8 m-b-8"
          >
            <span>{{ currentLinkLabels?.authorizeField?.label }}</span>
            <a-input
              v-model:value="authLinkValue"
              :default-value="authLinkValue"
              class="html-editor-link-modal__input"
              :class="{
                'html-editor-link-modal__input--error': isAuthorizeLinkError,
              }"
              :placeholder="currentLinkLabels?.authorizeField?.placeholder"
              :maxlength="MAX_TEXT"
              @blur="handleAuthLinkValueChange"
            />
            <span
              v-if="isAuthorizeLinkError"
              class="html-editor-link-modal__link-error"
            >
              Must be valid {{ linkType }} value
            </span>
          </div>

          <span>{{ currentLinkLabels?.label }}</span>

          <a-input
            v-model:value="linkValue"
            :default-value="linkValue"
            class="html-editor-link-modal__input"
            :class="{ 'html-editor-link-modal__input--error': isLinkError }"
            :placeholder="currentLinkLabels?.placeholder"
            :maxlength="MAX_TEXT"
            @blur="handleLinkValueChange"
          />

          <span
            v-if="isLinkError"
            class="html-editor-link-modal__link-error"
          >
            Must be valid {{ linkType }} value
          </span>

          <div
            v-if="linkType === LinkType.EXTERNAL"
            class="m-t-8 m-b-8"
          >
            <a-checkbox v-model:checked="isNewTabOpen">
              Open link in new tab
            </a-checkbox>
          </div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>

<script lang="ts" setup>
import { MAX_TEXT } from "~~/constants/input-validation";
import { LinkType } from "~~/models/widgets/widget-controls.model";
import { isValidLink } from "~~/helpers/link-validation";
import { useDescriptionLink } from "~~/composables/fields/description/useDescriptionLink";

const {
  isLinkModalVisible,
  linkType,
  isOneLinkForAll,
  isNewTabOpen,
  linkValue,
  authLinkValue,
  isLinkError,
  isAuthorizeLinkError,
  hideLinkModal,
  submitLinkModal,
  canSubmitModal,
} = useDescriptionLink();

const LINK_TYPE_OPTIONS = [
  { value: null, label: "None" },
  { value: LinkType.EXTERNAL, label: "Link" },
  { value: LinkType.MODAL, label: "Modal" },
  { value: LinkType.PHONE, label: "Phone" },
  { value: LinkType.EMAIL, label: "Email" },
];

const currentLinkLabels = computed<{
  label: string;
  placeholder: string;
  authorizeField?: { label: string; placeholder: string };
} | null>(() => {
  switch (linkType.value) {
    case LinkType.EXTERNAL:
      return {
        label: isOneLinkForAll.value
          ? "Page url"
          : "Page url for unauthorized users",
        placeholder: "Paste URL here",
        authorizeField: {
          label: "Page url for authorized users",
          placeholder: "Paste URL here",
        },
      };

    case LinkType.MODAL:
      return {
        label: isOneLinkForAll.value
          ? "Modal url"
          : "Modal url for unauthorized users",
        placeholder: "Paste URL here",
        authorizeField: {
          label: "Modal url for authorized users",
          placeholder: "Paste URL here",
        },
      };

    case LinkType.EMAIL:
      return {
        label: "Email Address",
        placeholder: "feedback@company.com",
      };

    case LinkType.PHONE:
      return {
        label: "Phone number",
        placeholder: "+1-234-5678910",
      };

    default:
      return null;
  }
});

const handleChangeLinkType = (type: LinkType) => {
  isOneLinkForAll.value = true;
  isLinkError.value = false;
  isAuthorizeLinkError.value = false;

  linkType.value = type;
};

const handleHasAuthToggleChange = () => {
  isAuthorizeLinkError.value = false;
  authLinkValue.value = "";
};

const handleAuthLinkValueChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  isAuthorizeLinkError.value = false;

  if (!isValidLink(target.value, linkType.value as LinkType) && target.value) {
    isAuthorizeLinkError.value = true;
    return;
  }
  authLinkValue.value = target.value;
};

const handleLinkValueChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  isLinkError.value = false;

  if (!isValidLink(target.value, linkType.value as LinkType) && target.value) {
    isLinkError.value = true;
    return;
  }

  linkValue.value = target.value;
};
</script>

<style lang="scss">
.html-editor-link-modal {
  &__input {
    height: $space-l;
    border-radius: 4px;

    &--error {
      border-color: $c-red;
    }
  }

  &__link-error {
    color: $c-red;
  }

  &__toggle-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px auto;
  }
}

.ant-modal-body {
  padding: 0 24px;
}
</style>
