<template>
  <DraggableList
    :key="nodeWidgetData?.cell_id || treeData[0].widgetData?.cell_id"
    v-model="treeData"
    tag="ul"
    class="widgets-tree-view"
    item-key="key"
    group="widgets"
    :scroll-sensitivity="200"
    filter=".non-draggable"
    :data-cell-id="nodeWidgetData?.cell_id || treeData[0].widgetData?.cell_id"
    :data-widget-id="nodeWidgetData?.id || treeData[0].widgetData?.id"
    :data-parent-widget-id="nodeWidgetData?.id"
    :data-is-last-child="isLastChild"
    :data-is-children="isChild"
    :data-disable-drop-inside="disableDropInside"
    :data-empty="!treeData.length"
    @add="onEnd"
    @update="handleUpdate"
    @move="onDragMove"
  >
    <template #item="{ element: node }">
      <li
        class="widgets-tree-view__element-container"
        :class="{ 'non-draggable': node.isLastChild }"
        :data-is-last-child="node.isLastChild"
        :data-cell-id="node.widgetData?.cell_id"
        :data-widget-id="node.widgetData?.id"
        :data-parent-widget-id="node.parentWidgetData?.id"
      >
        <WidgetsTreeElement
          :expanded-state="expandedState"
          :selected-node-id="selectedNodeId"
          :node="node"
        />

        <WidgetsTreeView
          v-if="node.children && expandedState?.get(node.key)"
          v-model:tree-data="node.children"
          :expanded-state="expandedState"
          :selected-node-id="selectedNodeId"
          :node-widget-data="node.widgetData"
          :parent-widget-data="node.parentWidgetData"
          :is-last-child="node.isLastChild"
          :disable-drop-inside="node.disableDropInside"
          :is-child="true"
          @add="onEnd"
          @update="handleUpdate"
          @move="onDragMove"
        />
      </li>
    </template>
  </DraggableList>
</template>

<script lang="ts" setup>
import { useWidgetsNewDnd } from "~~/components/composables/widgets/useWidgetsNewDnd";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

import { ITreeNode } from "./WidgetsTreeTab.vue";

const { expandedState, selectedNodeId } = defineProps<{
  expandedState: Map<string, boolean>;
  selectedNodeId: string;
  nodeWidgetData?: IWidgetWithFields;
  isChild?: boolean;
  isLastChild?: boolean;
  disableDropInside?: boolean;
}>();

const treeData = defineModel<Array<ITreeNode>>("treeData", {
  required: true,
});

const { onEnd, handleUpdate, onDragMove } = useWidgetsNewDnd();

const selectedNodeKey = ref<string>("");

const expandNodeAndParents = (treeNode: ITreeNode[], selectedKey: string) => {
  const findNodeAndExpandParents = (
    nodes: ITreeNode[],
    key: string
  ): boolean => {
    for (const node of nodes) {
      if (node.key === key) {
        expandedState.set(key, true);
        return true;
      }

      if (node.children && node.children.length > 0) {
        const found = findNodeAndExpandParents(node.children, key);
        if (found) {
          expandedState.set(node.key, true);
          return true;
        }
      }
    }
    return false;
  };

  findNodeAndExpandParents(treeNode, selectedKey);
};

watch(
  () => selectedNodeId,
  newValue => {
    selectedNodeKey.value = newValue;

    if (selectedNodeKey.value) {
      expandNodeAndParents(treeData.value, selectedNodeKey.value);
    }
  }
);

onMounted(() => {
  if (selectedNodeId) {
    selectedNodeKey.value = selectedNodeId;
    expandNodeAndParents(treeData.value, selectedNodeId);
  }
});
</script>

<style scoped lang="scss">
.widgets-tree-view {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0 2px 6px;

  &__element-container {
    cursor: pointer;
    padding: 2px 0 0 2px;
    list-style: none;
  }
}
</style>
