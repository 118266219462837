import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ISettingsDropdownItem } from "~~/models/settings/settings-sidebar.model";

export const useViewActiveWidgetSettings = () => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const isWidgetSettingsScreen = computed<boolean>(() => {
    return selectedField.value === null && !!selectedWidget.value;
  });

  const getFieldByName = (name: string): IWidgetField | undefined => {
    if (!selectedWidget.value) {
      return;
    }

    return selectedWidget.value.fields.find(field => field.name === name);
  };

  const closeSettingsTab = (
    dropdownItemName: string | number | undefined | null,
    dropdownItems: ISettingsDropdownItem[]
  ): void => {
    const item = dropdownItems.find(item => item.value === dropdownItemName);
    widgetSettingsStore.clearHistoryStack();

    if (!item || !item.parent) {
      widgetSettingsStore.setActiveElement(selectedWidget.value, null);
      return;
    }

    const field = getFieldByName(item.parent);

    if (!field) {
      return;
    }

    widgetSettingsStore.setActiveElement(selectedWidget.value, field);
  };

  return {
    closeSettingsTab,
    isWidgetSettingsScreen,
  };
};
