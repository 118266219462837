import { useWidgetsStore } from "~~/store/widgets";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  HEADER_CONTAINER_INITIAL,
  HEADER_COUNTERS_INITIAL,
} from "~~/constants/configs/betslip-common/initial-constants";

export const useBetslipStatusInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();

  const { addFieldInitialOptions, initFields, initWidgetOptions } =
    useCreateFields(widget, undefined, true);

  const widgetOptions = initWidgetOptions(
    [],
    {},
    HEADER_CONTAINER_INITIAL().options
  );

  const init = (): void => {
    initFields();

    addFieldInitialOptions("betslip_status", {
      data: HEADER_COUNTERS_INITIAL(),
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    init,
  };
};
