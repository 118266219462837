<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoGamesSearchFieldWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="casino-games-search">
      <WidgetField
        v-if="fields.field_container?.field.options"
        :widget="widget"
        :field="fields.field_container.field"
        class="casino-games-search__item"
      >
        <DropdownTriggerField
          :widget="widget"
          :field="fields.field_container.field"
          :value="fields.field_container.field.options.text.value"
          :placeholder-value="
            fields.field_container.field.options.placeholder.value
          "
          :state="states.field_container"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useCasinoGamesSearchWidgetInitData } from "~~/composables/widgets/casino/search/useCasinoGamesSearchWidgetInitData";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { states, INITIAL_STATES, initFields, initWidgetFieldsOptions } =
  useCasinoGamesSearchWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: INITIAL_STATES,
  states,
});

initFields();
initWidgetFieldsOptions();

const fields = useWidgetFields(props.widget);
</script>

<style lang="scss">
.casino-games-search {
  @include flex(flex-end, flex-start, 24px);

  &__item {
    width: 100%;
  }
}
</style>
