<script setup lang="ts">
import { IPageRouteBindingObject } from "~~/models/stores/pages-store.model";
// import http from "~~/services/http";

import { IPageConfigParamEntity } from "../types";

const props = defineProps<{
  modelValue: IPageRouteBindingObject;
  validationPath: string[];
  labelType?: string;
  labelVariable?: string;
  rules?: any[];
  disabled?: boolean;
}>();

const entities = ref<IPageConfigParamEntity[]>([]);

const variableOptions = computed(() => {
  if (props.modelValue.entity_type) {
    const item = entities.value.find(
      entity => entity.value === props.modelValue.entity_type
    );

    return item?.children ?? [];
  }

  return [];
});

const emits = defineEmits<{
  (e: "update:modelValue", $event: IPageRouteBindingObject): void;
  (e: "change", event: any): void;
}>();

const formData = computed({
  get() {
    return props.modelValue;
  },

  set(val) {
    emits("update:modelValue", val);
  },
});

onMounted(async () => {
  // TODO: Refactor to use global state if you have time ^-^
  // TODO: uncomment after back fix
  // const { data } = await http.get<IPageConfigParamEntity[]>(
  //   `/projects/entities`
  // );

  const data = [
    {
      "label": "Sport",
      "value": "SPORT",
      "children": [
        {
          "label": "Sport ID",
          "value": "SPORT.id",
        },
        {
          "label": "Sport slug",
          "value": "SPORT.slug",
        },
      ],
    },
    {
      "label": "Country",
      "value": "COUNTRY",
      "children": [
        {
          "label": "Country ID",
          "value": "COUNTRY.id",
        },
        {
          "label": "Country slug",
          "value": "COUNTRY.slug",
        },
      ],
    },
    {
      "label": "League",
      "value": "LEAGUE",
      "children": [
        {
          "label": "League ID",
          "value": "LEAGUE.id",
        },
        {
          "label": "League slug",
          "value": "LEAGUE.slug",
        },
      ],
    },
    {
      "label": "Event",
      "value": "EVENT",
      "children": [
        {
          "label": "Event ID",
          "value": "EVENT.id",
        },
      ],
    },
    {
      "label": "Casino category",
      "value": "CASINO_CATEGORY",
      "children": [
        {
          "label": "Casino category ID",
          "value": "CASINO_CATEGORY.id",
        },
        {
          "label": "Casino category slug",
          "value": "CASINO_CATEGORY.slug",
        },
      ],
    },
    {
      "label": "Casino subcategory",
      "value": "CASINO_SUBCATEGORY",
      "children": [
        {
          "label": "Casino subcategory ID",
          "value": "CASINO_SUBCATEGORY.id",
        },
        {
          "label": "Casino subcategory slug",
          "value": "CASINO_SUBCATEGORY.slug",
        },
      ],
    },
    {
      "label": "Casino game",
      "value": "CASINO_GAME",
      "children": [
        {
          "label": "Casino game ID",
          "value": "CASINO_GAME.id",
        },
        {
          "label": "Casino game slug",
          "value": "CASINO_GAME.slug",
        },
      ],
    },
    {
      "label": "Payment provider",
      "value": "PAYMENT_PROVIDER",
      "children": [
        {
          "label": "Payment provider ID",
          "value": "PAYMENT_PROVIDER.id",
        },
        {
          "label": "Payment provider slug",
          "value": "PAYMENT_PROVIDER.slug",
        },
      ],
    },
    {
      "label": "Promotion",
      "value": "PROMOTION",
      "children": [
        {
          "label": "Promotion ID",
          "value": "PROMOTION.id",
        },
        {
          "label": "Promotion slug",
          "value": "PROMOTION.slug",
        },
      ],
    },
    {
      "label": "Promotion category",
      "value": "PROMOTION_CATEGORY",
      "children": [
        {
          "label": "Promotion category ID",
          "value": "PROMOTION_CATEGORY.id",
        },
        {
          "label": "Promotion category slug",
          "value": "PROMOTION_CATEGORY.slug",
        },
      ],
    },
  ];

  entities.value = data;
});

const validationFieldsNames = computed(() => {
  return {
    type: [...props.validationPath, "entity_type"],
    variable: [...props.validationPath, "entity_variable"],
  };
});
</script>

<template>
  <a-space
    style="width: 100%"
    :size="8"
    direction="vertical"
  >
    <a-form-item
      class="margin-bottom-none"
      :label="labelType"
      :name="validationFieldsNames.type"
      :rules="rules"
      layout="vertical"
    >
      <a-select
        v-model:value="formData.entity_type"
        :options="entities"
        :disabled="disabled"
        @change="formData.entity_variable = ''"
      />
    </a-form-item>

    <a-form-item
      class="margin-bottom-none"
      :label="labelVariable"
      :name="validationFieldsNames.variable"
      :rules="rules"
      layout="vertical"
    >
      <a-select
        v-model:value="formData.entity_variable"
        :disabled="disabled || !formData.entity_type"
        :options="variableOptions"
        @change="$emit('change', $event)"
      />
    </a-form-item>
  </a-space>
</template>
