import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 4;

const v2WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["ModalWindowHeaderWidget"].includes(widget.name)) {
    if (widget.content.title?.options?.fill) {
      delete widget.content.title?.options?.fill;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v2WidgetMigration;
