import { typography } from "~~/constants/configs/text-common/typography-config";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useSportsbookDayLabelConfig = () => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const dayLabelDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },

      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margin",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          isAllSideSetting: true,
          meta: {
            isBold: true,
          },
        },
      },

      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const dayLabelContentControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        visible: selectedField.value?.options.resizing === ResizingType.FILL,
        options: {
          isTextContentIcons: false,
        },
      },
    ];
  });

  return {
    dayLabelDesignControls,
    dayLabelContentControls,
  };
};
