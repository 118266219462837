import { type Ref } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { IPageWidgetDetails, InnerFieldValue } from "~~/models/page.model";
import {
  DepositListScreen,
  FormFieldDetails,
  HIDE_DEPOSIT_LIST_FORM_SETTINGS_DICT,
} from "~~/models/widgets/form.model";
import { FORM_INITIAL } from "~~/constants/widget-details/form-initial-options";
import {
  generateWidgetField,
  prefillEmptyOptions,
} from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  IMAGE_INITIAL,
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import {
  INITIAL_STATES,
  DYNAMIC_FIELD_OPTIONS,
} from "~~/constants/widget-details/form/dynamic-fields";
import { State } from "~~/models/widgets/widget-controls.model";
import { FormElementName } from "~~/models/widgets/form.model";
import {
  generateStylingFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import {
  CARDS_BUTTON_INITIAL,
  CARDS_IMAGE_INITIAL,
  CARDS_INITIAL,
  DEPOSIT_WITHDRAWAL_WIDGET_INITIAL,
} from "~~/constants/configs/deposit-list-common/initial-data";
import { TOOLTIPS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/tooltips-styling";
import { useMetaStore } from "~~/store/meta";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useDepositListForm = (
  widget: Ref<IWidgetWithFields>,
  screens: DepositListScreen[]
) => {
  const widgetsStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const metaStore = useMetaStore();

  const fields = useWidgetFields(widget.value);

  const currentStates = ref<Record<string, string>>({});

  const { widgetsDetailsCache } = storeToRefs(widgetsStore);
  const { selectedDropdownItem, selectedWidget } =
    storeToRefs(widgetSettingsStore);

  const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
    () => {
      return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
        widget.value.static_id
      ];
    }
  );

  const { initWidgetOptions } = useCreateFields(widget.value);

  const createWidgetFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];
      widgetFields.push(
        generateWidgetField(
          fieldDetails.name,
          widget.value.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value || fieldDetails.default_value || "",
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget.value, widgetFields);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 4,
            left: 4,
          },
        },
      }).options
    );

    const initialCardTitleOptions = prefillEmptyOptions(
      fields.value.providers_item_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 4,
            left: 4,
          },
        },
      }).options
    );

    const initialFormCardTitleOptions = prefillEmptyOptions(
      fields.value.provider_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 4,
            left: 4,
          },
        },
      }).options
    );

    const initialCardDescriptionOptions = prefillEmptyOptions(
      fields.value.providers_item_limits.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 4,
            left: 4,
          },
          theme: 16,
        },
      }).options
    );

    const initialFormCardDescriptionOptions = prefillEmptyOptions(
      fields.value.provider_description_1.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 4,
            left: 4,
          },
          theme: 16,
        },
      }).options
    );

    const initialDescriptionOptions = prefillEmptyOptions(
      fields.value.update_profile_text.field.options,
      getInitialOptions({
        data: DESCRIPTION_INITIAL(),
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }).options
    );

    const initialCardsOptions = prefillEmptyOptions(
      fields.value.providers_item.field.options,
      getInitialOptions({
        data: CARDS_INITIAL(),
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }).options
    );

    const initialImagesOptions = prefillEmptyOptions(
      fields.value.providers_item_image.field.options,
      getInitialOptions({
        data: CARDS_IMAGE_INITIAL(),
        values: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
      }).options
    );

    const initialFormCardImagesOptions = prefillEmptyOptions(
      fields.value.provider_image.field.options,
      getInitialOptions({
        data: CARDS_IMAGE_INITIAL(),
        values: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
      }).options
    );

    const initialProviderButtonsOptions = prefillEmptyOptions(
      fields.value.providers_item_button.field.options,
      getInitialOptions({
        data: CARDS_BUTTON_INITIAL(),
        exclude: ["iconSettings"],
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }).options
    );

    const initialBackButtonsOptions = prefillEmptyOptions(
      fields.value.form_back_button.field.options,
      getInitialOptions({
        data: CARDS_BUTTON_INITIAL(),
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }).options
    );

    const initialProviderCardOptions = prefillEmptyOptions(
      fields.value.provider_card.field.options,
      getInitialOptions({
        data: CARDS_INITIAL(),
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }).options
    );

    const initialTooltipsOptions = prefillEmptyOptions(
      fields.value.providers_item_tooltip.field.options,
      getInitialOptions({
        data: TOOLTIPS_STYLE_INITIAL(),
        values: {
          padding: {
            top: 4,
            right: 4,
            bottom: 4,
            left: 4,
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.update_profile_text.field,
      initialDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item.field,
      initialCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item_image.field,
      initialImagesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item_title.field,
      initialCardTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item_limits.field,
      initialCardDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item_button.field,
      initialProviderButtonsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.form_back_button.field,
      initialBackButtonsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.providers_item_tooltip.field,
      initialTooltipsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.provider_image.field,
      initialFormCardImagesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.provider_title.field,
      initialFormCardTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.provider_description_1.field,
      initialFormCardDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.provider_card.field,
      initialProviderCardOptions
    );
  };

  const initWidgetFields = () => {
    if (!widget.value.fields.length) {
      createWidgetFields();
    }

    initWidgetFieldsOptions();
  };

  const initWidgetData = () => {
    if (widget.value.options && Object.keys(widget.value.options).length) {
      return {
        options: {},
      };
    }

    const dataOptions = {
      options: {
        providers: widgetCacheData.value?.data?.data["providers"],
        isFullProfile: widgetCacheData.value?.data?.data["isFullProfile"],
      },
    };
    return dataOptions;
  };

  const widgetOptions = initWidgetOptions(["fill"], {
    ...DEPOSIT_WITHDRAWAL_WIDGET_INITIAL().options,
    ...initWidgetData().options,
  });

  widgetsStore.updateWidgetOptions(widget.value, widgetOptions);

  initWidgetFields();

  const isWholeWidgetSelected = computed(() => {
    if (selectedWidget.value?.id !== widget.value.id) {
      return false;
    }

    return !!STYLING_FIELDS[selectedDropdownItem.value as string];
  });

  const generateFormFields = (
    formFieldDetails: any,
    widget: IWidgetWithFields
  ): IWidgetField[] => {
    if (!formFieldDetails) {
      return [];
    }

    const result: IWidgetField[] = [];
    let stylingFields: Record<string, IWidgetField> = {};

    for (const fieldName in formFieldDetails.value.fields) {
      const fieldDetails = formFieldDetails.value.fields[fieldName];
      const fieldInitialOptionsCallback =
        DYNAMIC_FIELD_OPTIONS[fieldDetails.type];

      if (!fieldInitialOptionsCallback) {
        console.warn(`Missing initial options for ${fieldName} field`);
        continue;
      }

      const fieldInitialOptions = fieldInitialOptionsCallback(fieldDetails);

      const field = generateWidgetField(
        fieldName,
        widget.id,
        fieldDetails.type,
        fieldDetails.label,
        fieldDetails.value,
        {
          _parent: "form",
          ...fieldInitialOptions.options,
        },
        fieldDetails.validation,
        fieldDetails.items || fieldInitialOptions.options.items
      );

      const currStylingFields = generateStylingFormFields(
        fieldDetails.type,
        widget.id
      );

      stylingFields = {
        ...stylingFields,
        ...currStylingFields,
      };

      result.push(field);
    }

    widget.fields.forEach(field => {
      const currStylingFields = generateStylingFormFields(
        field.type,
        widget.id
      );

      stylingFields = {
        ...stylingFields,
        ...currStylingFields,
      };
    });

    const fieldStylingFieldsList = Object.keys(stylingFields)
      .map(fieldName => {
        return stylingFields[fieldName];
      })
      .filter(field => field.name !== "title_styling");

    return [...result, ...fieldStylingFieldsList];
  };

  let formInnerFields: IWidgetField[] = [];
  let customWidgetFields: IWidgetField[] = [];

  if (
    !widget.value.options?._customFields ||
    !Object.keys(widget.value.options._customFields).length
  ) {
    formInnerFields = generateFormFields(
      widgetCacheData.value?.fields?.form as FormFieldDetails | undefined,
      widget.value
    );
  } else {
    customWidgetFields = prefillCustomFields(
      widget.value.options,
      widget.value.id
    );
  }

  const initialFormOptions = prefillEmptyOptions(
    fields.value.form.field.options,
    FORM_INITIAL(widgetCacheData.value?.fields?.form).options
  );

  widgetsStore.updateFieldOptions(fields.value.form.field, initialFormOptions);

  const initStates = () => {
    for (const stateName in INITIAL_STATES) {
      currentStates.value[stateName] = INITIAL_STATES[stateName];
    }
  };

  const initFormElementsOptions = () => {
    widget.value.fields.forEach(field => {
      if (
        !DYNAMIC_FIELD_OPTIONS[field.type] ||
        HIDE_DEPOSIT_LIST_FORM_SETTINGS_DICT.includes(field.name)
      ) {
        return;
      }

      const cacheFieldData = (
        widgetCacheData.value?.fields?.form?.value as InnerFieldValue
      )?.fields?.[field.name];

      const initial = DYNAMIC_FIELD_OPTIONS[field.type]({
        ...field,
        placeholder: field.placeholder || cacheFieldData?.placeholder || "",
        label: field.title || cacheFieldData?.label || "",
        options: field.options,
      });

      widgetsStore.updateFieldOptions(field, initial.options);
    });
  };

  initFormElementsOptions();
  initStates();

  let fieldsResult: Array<IWidgetField> = [];

  if (!widget.value.fields.find(field => field.options._custom)) {
    fieldsResult = [
      ...widget.value.fields,
      ...formInnerFields,
      ...customWidgetFields,
    ];
  } else {
    fieldsResult = [...widget.value.fields, ...formInnerFields];
  }

  widgetsStore.updateWidgetFields(widget.value, fieldsResult);

  if (screens.includes(DepositListScreen.SUCCESS)) {
    const initialSuccessImageOptions = prefillEmptyOptions(
      fields.value.success_image.field.options,
      IMAGE_INITIAL().options
    );

    const initialSuccessTitleOptions = prefillEmptyOptions(
      fields.value.success_title.field.options,
      TITLE_INITIAL().options
    );

    const initialSuccessDescriptionptions = prefillEmptyOptions(
      fields.value.success_description_1.field.options,
      DESCRIPTION_INITIAL().options
    );

    const initialSuccessButtonOptions = prefillEmptyOptions(
      fields.value.success_button.field.options,
      BUTTON_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      fields.value.success_image.field,
      initialSuccessImageOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.success_title.field,
      initialSuccessTitleOptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.success_description_1.field,
      initialSuccessDescriptionptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.success_button.field,
      initialSuccessButtonOptions
    );
  }

  if (screens.includes(DepositListScreen.FAIL)) {
    const initialImageOptions = prefillEmptyOptions(
      fields.value.fail_image.field.options,
      IMAGE_INITIAL().options
    );

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.fail_title.field.options,
      TITLE_INITIAL().options
    );

    const initialDescriptionptions = prefillEmptyOptions(
      fields.value.fail_description_1.field.options,
      DESCRIPTION_INITIAL().options
    );

    const initialFailButtonOptions = prefillEmptyOptions(
      fields.value.fail_button.field.options,
      BUTTON_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      fields.value.fail_image.field,
      initialImageOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.fail_title.field,
      initialTitleOptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.fail_description_1.field,
      initialDescriptionptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.fail_button.field,
      initialFailButtonOptions
    );
  }

  const handleUpdateButtonState = (value: State) => {
    currentStates.value[FormElementName.BUTTON] = value;
  };

  const handleResetStates = () => {
    for (const key in currentStates.value) {
      currentStates.value[key] = INITIAL_STATES[key];
    }
  };

  return {
    currentStates,
    isWholeWidgetSelected,
    handleUpdateButtonState,
    handleResetStates,
  };
};
