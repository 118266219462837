import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useSportsbookLiveFenixCardContentConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    League name
  */

  const textWithDisplayControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.display",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      typography({
        source: selectedField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  const favIconControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const streamIconControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: selectedField.value,
        options: {
          hideSwitch: true,
          className: "p-l-16 p-r-16 p-t-16",
        },
      },
    ];
  });

  const eventTimeControls = computed(() => {
    return [
      typography({
        source: fields.value.event_time.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.event_time.field.options.icon,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.event_time.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_time.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Event info
  */

  const eventInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_info.field,
    });
  });

  const eventInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Event info layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Distance between event info items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Event name",
              value: "event_name",
            },
            {
              label: "Score",
              value: "event_score",
              isActiveValueSource: fields.value.event_score.field.options,
            },
          ],
        },
      },
    ];
  });

  const eventNameControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  /* 
    Event score
  */

  const eventScoreContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_score.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const eventScoreDesignControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.event_score.field,
        except: ["ConfigBoxShadow"],
      }),
    ];
  });

  /* 
    Event button
  */

  const eventButtonDesignControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: selectedField.value,
        except: ["ConfigSpacingInputs"],
        elements: [
          [
            4,
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: "options.padding",
              valueSource: selectedField.value,
              options: {
                label: "Padding",
              },
            },
          ],
          [
            5,
            {
              componentPath: "ConfigMarginInputs",
              valuePath: "options.margins",
              valueSource: selectedField.value,
              options: {
                meta: {
                  isBold: true,
                },
                label: "Margin",
                isAllSideSetting: true,
                showTooltip: true,
              },
            },
          ],
        ],
      }),
    ];
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const eventButtonContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          placeholder: "Enter button text",
          label: "Text",
          allowDisable: allowDisableFieldsItem(
            FieldContentItem.BUTTON_TEXT_SETTINGS
          ).value,
          active: selectedField.value!.options.buttonTextSettings._active,
          showOptions: false,
          max: 50,
          "onUpdate:active": (value: boolean) => {
            selectedField.value!.options.buttonTextSettings._active = value;
          },
        },
      },
      {
        componentPath: "ConfigButtonTextSettings",
        valuePath: "options.buttonTextSettings",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
          label: "Text color",
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const eventButtonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const eventButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit(`update-state`, { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${eventButtonSettingsValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${eventButtonSettingsValuePath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${eventButtonSettingsValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${eventButtonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${eventButtonSettingsValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /* 
    Racing
  */

  const racingStatusDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.racing_event_status.field,
      except: ["ConfigBoxShadow"],
    });
  });

  const racingStatusContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.racing_event_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  return {
    textWithDisplayControls,
    favIconControls,
    streamIconControls,
    eventTimeControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    eventNameControls,

    eventScoreContentControls,
    eventScoreDesignControls,

    eventButtonDesignControls,
    eventButtonContentControls,
    eventButtonStatesControls,

    racingStatusDesignControls,
    racingStatusContentControls,
  };
};
