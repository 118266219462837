<template>
  <div
    ref="dragNDropContainer"
    :data-element-id="DND_CONTAINER_ELEMENT_ID"
    class="drag-n-drop-container"
    @drop="onDrop"
    @dragover="onDragOver"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
  >
    <div
      v-if="showDivider && dndStore.isDragging && isActiveContainer"
      :data-element-id="DND_DIVIDER_ID"
      class="drag-n-drop-container__divider"
      :style="{
        top: `${dividerPosition.top}px`,
        left: `${dividerPosition.left}px`,
        width: `${dividerPosition.width}px`,
      }"
    ></div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from "vue";

import {
  DND_CONTAINER_ELEMENT_ID,
  RECALCULATE_TOP_POSITION_FREQUENCY,
  DND_COPY_OPERATION_NAME,
  PROMPT_OFFSET_OUTER,
  DND_DIVIDER_ID,
} from "~~/constants/dnd";
import { IClientBoundingRect, IDividerPosition } from "~~/models/dnd.model";
import { debounce, throttle } from "~~/assets/utils";
import { events, GlobalEvents } from "~~/services/global-events";
import { DndContainerId } from "~~/models/common/dropdown.model";
import { useDndStore } from "~~/store/dnd";

const dndStore = useDndStore();

const props = defineProps<{
  dividerPosition: IDividerPosition;
  showDivider?: boolean;
  containerId: DndContainerId;
}>();

const emit = defineEmits<{
  (e: "drop", event: DragEvent): void;
  (e: "over", event: DragEvent): void;
  (e: "enter", event: DragEvent): void;
  (e: "update-container-position", event: IClientBoundingRect): void;
}>();

const dragNDropContainer = ref<HTMLElement | null>(null);

// to prevent too many changes
const setIsDragging = debounce(dndStore.setIsDragging, 1);

const isActiveContainer = computed<boolean>(
  () => props.containerId === dndStore.activeDndContainerId
);

/*
  Adds to cursor DND icon in all browsers
*/
const setDndCopyOperation = (e: DragEvent): void => {
  if (!e.dataTransfer) {
    return;
  }

  e.dataTransfer.dropEffect = DND_COPY_OPERATION_NAME;
};

const onDragOver = (e: DragEvent) => {
  if (!isActiveContainer.value) return;
  // prevent default to allow drop
  e.preventDefault();

  setIsDragging(true);

  setDndCopyOperation(e);
  emit("over", e);
};

const onDragEnter = (e: DragEvent) => {
  recalculateOffsetTop();
  setIsDragging(true);

  setDndCopyOperation(e);
  emit("enter", e);
};

const onDragLeave = () => {
  setIsDragging(false);
};

/*
  Offset top of container is different each time,
  depending on distace bentween top of container
  and top viewport
*/
const recalculateOffsetTop = throttle((): void => {
  if (!dragNDropContainer.value) {
    return;
  }

  const containerRenderData: IClientBoundingRect =
    dragNDropContainer.value.getBoundingClientRect();

  emit("update-container-position", containerRenderData);
}, RECALCULATE_TOP_POSITION_FREQUENCY);

const onDrop = (e: DragEvent) => {
  if (!isActiveContainer.value) return;

  // prevent default action (open as link for some elements)
  e.preventDefault();

  setIsDragging(false);

  emit("drop", e);
};

const dividerPosition = computed<{ top: number; left: number; width: number }>(
  () => {
    let top = props.dividerPosition.top;

    if (dndStore.draggingLayoutPromptData) {
      const { offsetTop, offsetBottom } = dndStore.draggingLayoutPromptData;

      if (offsetTop) {
        // 20 is a static height of absolute label
        // TODO: refactor and remove it
        const labelOffset = offsetTop === PROMPT_OFFSET_OUTER ? 20 : 0;
        top = top - labelOffset - offsetTop / 2;
      } else if (offsetBottom) {
        top = top + offsetBottom / 2;
      }
    }

    return {
      top,
      left: props.dividerPosition.left,
      width: props.dividerPosition.width,
    };
  }
);

onMounted(() => {
  recalculateOffsetTop();

  /*
    We need to recalculate position of dnd container
    each time on scroll, as we always need latest
    container top position.
  */
  events.on(GlobalEvents.WINDOW_SCROLL, recalculateOffsetTop);
});

onBeforeUnmount(() => {
  events.off(GlobalEvents.WINDOW_SCROLL, recalculateOffsetTop);
});
</script>

<style lang="scss">
.drag-n-drop-container {
  position: relative;

  &__divider {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $c-primary-base;
    top: 0px;
    z-index: 1;
    pointer-events: none;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      background-color: $c-primary-base;
      top: 0;
      transform: translateY(calc(-50% + 1px));
      transform-origin: bottom;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}
</style>
