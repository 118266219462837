<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :style="checkboxElementContainerStyles"
    class="form-checkbox-button-field__container"
  >
    <div
      v-for="(item, index) in currentItems"
      :key="index"
      :style="checkboxElementStyles"
      class="form-checkbox-button-field"
    >
      <span
        v-if="styleField?.options.check._active"
        :style="checkboxStyles"
        class="form-checkbox-button-field__checkbox"
      >
        <CommonIcon
          v-if="iconVisible"
          name="ant-design:check-outlined"
        />
      </span>
      <span
        class="form-checkbox-button-field__text"
        v-html="getLabel(item.label)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import sanitizeHtml from "sanitize-html";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

type Item = {
  label: string;
};

const props = defineProps<{
  widget?: IWidgetWithFields;
  field?: IWidgetField;
  stylingFields: IWidgetField[];
  items?: Item[];
  value?: string;
  states: Record<string, string>;
  stateName?: string;
  customFieldName?: string;
}>();

const { getLocalizedValue } = useLocalizedValue();

const state = computed<string>(() => {
  return props.states[props.stateName || FormStyleFieldName.CHECKBOX];
});

const currentItems = computed<Item[]>(() => {
  if (props.items) {
    return props.items;
  }

  if (props.value) {
    return [
      {
        label: getLocalizedValue.value(props.value),
      },
    ];
  }

  return [
    { label: getLocalizedValue.value(props.field?.options?.label?.value) },
  ];
});

const { triggerFieldExample, hideFieldExample } = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

const styleField = computed<IWidgetField>(() => {
  return props.stylingFields.find(
    field =>
      field.name === (props.customFieldName || FormStyleFieldName.CHECKBOX)
  )!;
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (state.value === State.SELECTED_DEFAULT) {
    return styleField.value?.options;
  }

  return styleField.value?.options.states[state.value];
});

const iconVisible = computed<boolean>(() => {
  if (
    state.value === State.SELECTED_DEFAULT ||
    state.value === State.SELECTED_DISABLED ||
    state.value === State.SELECTED_HOVER
  ) {
    return true;
  }

  return false;
});

const checkboxElementStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...styleField.value?.options,
      ...activeStateOptions.value,
    }),
    ...getTextCommonStyles({
      ...styleField.value?.options,
      ...activeStateOptions.value,
    }),
    gap: getPxValueFromNumber(styleField.value?.options.check.distance),
  };
});

const checkboxElementContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(styleField.value?.options.display.distance),
  };
});

const checkboxIconStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...getCommonStyles({
        ...activeStateOptions.value.check,
        fill: activeStateOptions.value.check.icon,
      }),
    },
    "icon"
  );
});

const checkboxStyles = computed<ElementStyle>(() => {
  return {
    width: getPxValueFromNumber(styleField.value?.options.size),
    height: getPxValueFromNumber(styleField.value?.options.size),
    ...getCommonStyles(activeStateOptions.value.check),
    ...checkboxIconStyles.value,
  };
});

const getLabel = (label: string): string => {
  if (!label) {
    return "";
  }

  return sanitizeHtml(label, {
    allowedAttributes: {
      "a": ["href", "style"],
    },
  });
};

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});
</script>

<style lang="scss">
.form-checkbox-button-field {
  flex: 1;
  &__container {
    @include flex(center, flex-start, 0);
    min-width: 0;
  }

  &__checkbox {
    @include flex(center, center, 0);
    width: 16px;
    height: 16px;
    border-radius: 2px;

    svg {
      color: var(--icon-backgroundColor);
    }
  }

  @include flex(center, flex-start, 0);
}
</style>
