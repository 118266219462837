import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 16;

const v16WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["ProfileDropdownWidget"].includes(widget.name)) {
    if (widget.options.bindingParams.bonuses) {
      widget.options.bindingParams.bonuses.description =
        "Possible values: betting | casino";
      widget.options.bindingParams.bonuses.required = false;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v16WidgetMigration;
