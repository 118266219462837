import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  Overflow,
  ResizingType,
  SliderItemsPosition,
  State,
} from "~~/models/widgets/widget-controls.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { betDefaultSpacingCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";
import { CellSizeType } from "~~/models/grid.interface";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateDisplayOrientation,
  generateFlex,
  generateFlexAlignment,
  generateFontSize,
  generateGrid,
  generateMarginStyle,
  generatePaddingStyle,
  generateTextColor,
  generateWidgetWidth,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import { getPxValueFromNumber } from "../..";

import {
  generateWidgetStyles,
  generateItemMoreStyles,
} from "./sportsbook-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";

export const generateHeaderCssString = (header: IWidgetField) => {
  return (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: header.options._cssClass,
      content: header.options,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: header.options._cssClass,
      content: [
        generateDisplayOrientation(header.options.displayOrientation),
        generateMarginStyle(header.options.spacing.margin),
      ].join(""),
    });

    return cssString;
  };
};

export const generateTabsCssString =
  (tabs: IWidgetField, arrow: IWidgetField) =>
  (cssString: string): string => {
    const { options } = tabs;
    const isFill = options.displaySettings.resizing === ResizingType.FILL;
    const tabsStates = tabs.options.states;
    const arrowStates = arrow.options.states;

    const tabsWrapperStyles =
      generateGrid({
        templateColumns:
          options.overflow === Overflow.ARROWS ? "auto 1fr auto" : "",
        gap:
          arrow.options.arrowSettings?.position === SliderItemsPosition.OUTSIDE
            ? "8"
            : "0",
      }) + "justify-items: center;align-items: center;";

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      content: tabsWrapperStyles,
    });

    const widgetPositionCssContainer = generateFlex({
      flex: "flex",
      wrap: "nowrap",
      align: "center",
      justify: isFill
        ? Alignment.CENTER
        : generateFlexAlignment(options.displaySettings.alignment),
      gap: options.spaceBetween,
    });

    const scrollBarStyles =
      options.overflow === Overflow.SCROLL ? "scrollbar-width:thin;" : "";

    cssString += generateCssClassWithContent({
      className: options.container._cssClass,
      content:
        widgetPositionCssContainer +
        scrollBarStyles +
        "width:100%;" +
        "overflow:auto;",
    });

    const arrowWrapperStyles =
      generateFlex({
        flex: "flex",
        align: "center",
        justify: "center",
      }) + "cursor:pointer;";

    cssString += generateCssClassWithContent({
      className: arrow.options._wrapperCssClass,
      content: arrowWrapperStyles,
    });

    cssString += generateCssClassWithContent({
      className: arrow.options._wrapperCssClass,
      content: arrow.options,
      customFunction: generateDefaultStyles,
    });

    const tabsArrowContainerStyles =
      generateFlex({
        flex: "flex",
        align: "flex-start",
        justify: "flex-end",
      }) + "z-index:1;height:100%;width:100%;";

    cssString += generateCssClassWithContent({
      className: arrow.options._containerCssClass,
      content: tabsArrowContainerStyles,
    });

    for (const state in arrowStates) {
      cssString += generateCssClassWithContent({
        className: arrow.options._wrapperCssClass,
        content: arrowStates[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    const arrowIconStyles =
      generateTextColor(arrow.options.arrowSettings?.color) +
      generateFontSize(arrow.options.arrowSettings?.size) +
      "padding:2px;";

    cssString += generateCssClassWithContent({
      className: arrow.options._cssClass,
      content: arrowIconStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${arrow.options._cssClass}--right`,
      content: "transform: rotate(180deg);",
    });

    for (const state in tabsStates) {
      if (state === State.HOVER) {
        cssString += `@media (hover: hover) and (pointer: fine) {.${
          options._cssClass
        }:hover{${generateDefaultStyles(tabsStates[state])}}}`;
        cssString += `.${
          options._cssClass
        }:focus-visible{${generateDefaultStyles(tabsStates[state])}}`;
        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: tabsStates[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      content: generateDefaultStyles(tabsStates.active),
    });

    const flexForTabButton = generateFlex({
      flex: "flex",
      wrap: "nowrap",
      align: "center",
      justify: isFill
        ? Alignment.CENTER
        : generateFlexAlignment(options.displaySettings.alignment),
      gap: options.iconSettings.gap,
    });

    const widthForTabButton = isFill ? "width:100%;" : "width:auto;";
    const shrinkForTabButton = isFill ? "flex-shrink:1;" : "flex-shrink:0;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        flexForTabButton +
        shrinkForTabButton +
        widthForTabButton +
        generateDefaultStyles(options, ["spaceBetween"]) +
        "white-space:nowrap;" +
        "cursor: pointer;",
    });

    return cssString;
  };

export const generateBetItemsCssString =
  (betItem: IWidgetField, allowItemWidth?: boolean) =>
  (cssString: string): string => {
    const { options } = betItem;

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content:
        nameCssContent +
        "overflow: hidden;white-space: nowrap;text-overflow:ellipsis;",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const flexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    const itemWidth = allowItemWidth ? "min-width: 0" : "";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(betItem.options) +
        flexString +
        "flex: 1; cursor: pointer;" +
        itemWidth,
    });

    cssString += generateDefaultStylesWithStates(betItem, false);

    for (const state in betItem.options.states) {
      const pseudoClassName = state === "active" ? `--selected` : `:${state}`;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        content: generateDefaultStyles(betItem.options.states[state], [
          "theme",
        ]),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: betItem.options.name._cssClass,
        content: generateDefaultStyles(betItem.options.states[state].name, [
          "theme",
        ]),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: betItem.options.coef._cssClass,
        content: generateDefaultStyles(betItem.options.states[state].coef, [
          "theme",
        ]),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      content: generateDefaultStyles(betItem.options.states.active),
    });

    return cssString;
  };

export const generateEventCardsCssString =
  (eventCards: IWidgetField, betItemsGap = "0" as string) =>
  (cssString: string): string => {
    const { options } = eventCards;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.columns}, minmax(0, 1fr))`,
      rowGap: options.distanceBetweenCards,
      columnGap: options.distanceBetweenCards,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        widgetPositionCssContent +
        generateMarginStyle(eventCards.options.card.spacing.margin) +
        generateDefaultStyles(eventCards.options),
    });

    const itemWidth = "width: 100%;";

    const flexString = generateFlex({
      flex: "flex",
      wrap: "wrap",
      align: "center",
      justify: "left",
    });

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      content:
        flexString +
        itemWidth +
        generatePaddingStyle(eventCards.options.card.spacing.padding) +
        generateDefaultStyles(eventCards.options.card, ["spacing"]),
    });

    cssString += generateCssClassWithContent({
      className: options.items._cssClass,
      content:
        itemWidth +
        generateFlex({
          flex: "flex",
          wrap: "wrap",
          align:
            options.items.displayMode === DisplayOrientation.HORIZONTAL
              ? "center"
              : "flex-start",
          justify: "flex-start",
          direction:
            options.items.displayMode === DisplayOrientation.HORIZONTAL
              ? "row"
              : "column",
          gap:
            options.items.displayMode === DisplayOrientation.HORIZONTAL
              ? 0
              : options.items.distanceBetweenItems,
        }),
    });

    let marketItemsCssContent;

    if (options.items.displayMode === DisplayOrientation.HORIZONTAL) {
      marketItemsCssContent = generateGrid({
        rowGap: betItemsGap,
        columnGap: betItemsGap,
        templateColumns: `repeat(auto-fit, minmax(calc(33.33334% - ${getPxValueFromNumber(
          betItemsGap
        )}), 1fr))`,
      });
    } else {
      marketItemsCssContent = generateFlex({
        flex: "flex",
        direction: "column",
        align: "stretch",
        justify: "flex-start",
        gap: betItemsGap,
      });
    }

    cssString += generateCssClassWithContent({
      className: options.items._cssMarketsClass,
      content: itemWidth + "flex: 1;" + marketItemsCssContent,
    });

    return cssString;
  };

const generateListStartTimeCssString =
  (startTime: IWidgetField) =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options, ["spaceBetween", "padding", "margins"]) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          gap: "4",
        }),
    });

    const flexShrink = `flex-shrink: ${
      options.width?.type === CellSizeType.FIXED ? "0" : "initial"
    };`;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content:
        generateWidgetWidth(options.width) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: options.alignment,
          gap: options.spaceBetween,
        }) +
        flexShrink +
        generatePaddingStyle(options.padding) +
        generateMarginStyle(options.margins ?? { right: 4 }),
    });

    return cssString;
  };

export const generateBetsListWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const header = widget.content["header"];
  const title = widget.content["title"];
  const bet_items = widget.content["bet_items"];
  const event_button = widget.content["event_button"];
  const event_cards = widget.content["event_cards"];
  const event_time = widget.content["event_time"];
  const tabs = widget.content["tabs"];
  const arrow = widget.content["arrow"];
  const no_events_title = widget.content["no_events_title"];

  widget.options._cssClass = generateClassName("Sportsbook");
  header.options._cssClass = generateClassName("header");
  title.options._cssClass = generateClassName("title");
  no_events_title.options._cssClass = generateClassName("no_events_title");

  arrow.options._cssClass = generateClassName("arrow");
  arrow.options._wrapperCssClass = generateClassName("arrow-wrapper");
  arrow.options._containerCssClass = generateClassName("arrow-container");

  tabs.options.container._cssClass = generateClassName("tabs-container");
  tabs.options._wrapperCssClass = generateClassName("tabs-wrapper");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options.iconSettings._cssClass = generateClassName("tabs_icon");
  tabs.options._itemSelectedCssClass =
    tabs.options._cssClass + '[data-selected="true"]';

  event_cards.options._cssClass = generateClassName("event_cards-container");
  event_cards.options.card._cssClass = generateClassName("event_cards");
  event_cards.options.items._cssClass = generateClassName("event_cards-items");
  event_cards.options.items._cssMarketsClass = generateClassName(
    "event_cards-market-items"
  );

  bet_items.options._cssClass = generateClassName("bet_items");
  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");
  bet_items.options._itemSelectedCssClass =
    bet_items.options._cssClass + '[data-selected="true"]';

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_button.options._cssClass = generateClassName("event_button");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateHeaderCssString(header as IWidgetField),
    generateStringDefault(title),
    generateTabsCssString(tabs as IWidgetField, arrow as IWidgetField),
    generateEventCardsCssString(
      event_cards as IWidgetField,
      bet_items.options.spaceBetween
    ),
    generateBetItemsCssString(bet_items as IWidgetField),
    generateListStartTimeCssString(event_time as IWidgetField),
    generateItemMoreStyles(event_button.options),
    generateStringDefault(no_events_title),
    generateGridLayoutCssString(event_cards)
  )("");
};
