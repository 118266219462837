<template>
  <div
    :style="containerStyles"
    class="racing-sports-event-live-widget__event-info"
  >
    <slot name="title"></slot>
    <slot name="description"></slot>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";

const props = defineProps<{
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});
</script>

<style lang="scss"></style>
