<template>
  <div
    :style="containerStyles"
    class="form-success-message"
  >
    <CommonIcon
      v-if="field.options.iconSettings._active"
      :name="field.options.iconSettings.value"
      :style="iconStyles"
    />

    <BasicTitleField
      :field="field"
      class="form-success-message__title"
    />
  </div>
</template>

<script lang="ts" setup>
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...getTextCommonStyles(props.field.options),
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.field.options.iconSettings.textColor),
    ...getFontSizeStyle(props.field.options.iconSettings.iconSize),
  };
});
</script>

<style lang="scss">
.form-success-message {
  @include flex(center, flex-start, 4px);

  &__title {
    flex: 1;
  }
}
</style>
