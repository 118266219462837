<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookLiveSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sportsbook-live-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField
          :field="fields.title.field"
          value="Default"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.event_cards.active"
        :widget="widget"
        :field="fields.event_cards.field"
      >
        <SportsbookLiveEventCards
          :widget="widget"
          :field="fields.event_cards.field"
          :suspended-field="fields.event_suspended_status.field"
          :event-cards-state="states.event_cards"
        >
          <template #stream>
            <WidgetField
              :widget="widget"
              :field="fields.stream_icon.field"
            >
              <CommonIcon
                :name="fields.stream_icon.field.options.value"
                :style="streamIconStyles"
              />
            </WidgetField>
          </template>

          <template #empty>
            <BasicTitleField
              :field="fields.event_cards.field"
              :value="fields.event_cards.field.options.noEventsText"
            />
          </template>

          <template #suspended>
            <WidgetField
              :widget="widget"
              :field="fields.event_suspended_status.field"
            >
              <SportsBookLiveSuspended
                :field="fields.event_suspended_status.field"
                value="Suspended"
              />
            </WidgetField>
          </template>

          <template #time>
            <WidgetField
              :widget="widget"
              :field="fields.event_time.field"
            >
              <SportsBookLiveTime :field="fields.event_time.field" />
            </WidgetField>
          </template>

          <template #league>
            <WidgetField
              :widget="widget"
              :field="fields.event_league.field"
            >
              <BasicTitleField
                :field="fields.event_league.field"
                value="League"
              />
            </WidgetField>
          </template>

          <template #event_info>
            <WidgetField
              :widget="widget"
              :field="fields.event_info.field"
            >
              <SportsbookLiveEventInfo
                :widget="widget"
                :field="fields.event_info.field"
                :team-player-field="fields.event_team_player.field"
                :score-field="fields.event_score.field"
              />
            </WidgetField>
          </template>

          <template #header_append>
            <WidgetField
              :widget="widget"
              :field="fields.favourites_button.field"
              :style="iconStyles"
              class="sportsbook-live-widget__fav-button"
            >
              <CommonIcon
                :size="fields.favourites_button.field.options.iconSize"
                :name="fields.favourites_button.field.options.value"
              />
            </WidgetField>
          </template>

          <template #market_name>
            <WidgetField
              :widget="widget"
              :field="fields.market_name.field"
            >
              <BasicTitleField
                :field="fields.market_name.field"
                value="Market name"
              />
            </WidgetField>
          </template>

          <template #bet_items="{ items }">
            <WidgetField
              :widget="widget"
              :field="fields.bet_items.field"
            >
              <SportsbookLiveBetItems
                :field="fields.bet_items.field"
                :items="items"
                :state="states.bet_items"
              />
            </WidgetField>
          </template>

          <template #action_button>
            <WidgetField
              :widget="widget"
              :field="fields.event_button.field"
            >
              <SportsBookLiveEventsButton
                :field="fields.event_button.field"
                :state="states.event_button"
              />
            </WidgetField>
          </template>
        </SportsbookLiveEventCards>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/sportbook-live/useSportBookLiveInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
/*  */
const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const streamIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(fields.value.stream_icon.field.options.textColor),
    ...getFontSizeStyle(fields.value.stream_icon.field.options.iconSize),
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(fields.value.favourites_button.field.options.color),
    zIndex: 1,
  };
});

initFields();
</script>

<style lang="scss">
.sportsbook-live-widget {
  &__stream-icon {
    padding: 16px;

    .wconfig-toggle__label {
      font-weight: 400;
    }

    .config-icon-settings__select-container {
      padding-top: 8px !important;
    }
  }

  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__fav-button {
    margin-left: auto;
  }
}
</style>
