import { computed, type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/racing-sports-live/useRacingSportsLiveItems";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useTitleControls } from "~~/composables/widgets/items/useTitleControls";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { useRacingSportsLiveBetConfig } from "~~/composables/widgets/racing-sports-live/useRacingSportsLiveBetConfig";
import { useRacingSportsButtonTabsConfig } from "~~/composables/widgets/racing-sports-live/useRacingSportsButtonTabsConfig";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";

const WIDGET_SETTINGS = [
  {
    label: "View more switch",
    value: "tabs_container",
  },
  {
    label: "Iframe",
    value: "iframe_container",
  },
  {
    label: "Event info",
    value: "event_info_container",
  },
  {
    label: "Players info",
    value: "players_info_container",
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetsStore = useWidgetSettingsStore();

  const { selectedField } = storeToRefs(widgetsStore);

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);

  const { isWidthConfigDisabled } = useWidthConfig();

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: WIDGET_SETTINGS,
        },
      },
    ];
  });

  /* 
    Event info
  */

  const eventInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_info_container.field,
    });
  });

  const eventInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.event_info_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Race distance and venue",
              value: "event_info_title",
            },
            {
              label: "Group stage and conditions",
              value: "event_info_description",
            },
          ],
        },
      },
    ];
  });

  const { titleControls: eventInfoTitleControls } = useTitleControls({
    field: selectedField,
    except: ["content/ConfigTextInput", "ConfigLinkType"],
  });

  /* 
    Playes info
  */

  const playersInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.players_info_container.field,
    });
  });

  const playersInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.mode",
            valueSource: selectedField.value,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Cards items layout ",
              items: [
                { icon: "custom:players-info-column-1", value: "table" },
                { icon: "custom:players-info-column-2", value: "rows" },
                { icon: "custom:players-info-column-vertical", value: "wrap" },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenItems",
            valueSource: fields.value.players_info_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenCards",
            valueSource: fields.value.players_info_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header card",
              value: "players_info_header",
            },
            {
              label: "Player cards",
              value: "players_info_card",
            },
          ],
        },
      },
    ];
  });

  const headerCardDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.players_info_header.field,
    });
  });

  const headerCardContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.players_info_header.field,
      }),
    ];
  });

  const playerCardsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.players_info_card.field,
    });
  });

  const playerCardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "№",
              value: "racing_number",
            },
            {
              label: "Last 5 races",
              value: "players_info_player_last_score",
            },
            {
              label: "Previous odds",
              value: "players_info_player_prev_odds",
            },
            {
              label: "Odds",
              value: "players_info_player_current_odds",
            },
          ],
        },
      },
    ];
  });

  const currentPlayerCardsStatePath = computed<string>(() => {
    if (states.value.players_info_card === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.players_info_card}`;
  });

  const getValueSource = (fieldName: string): IWidgetField => {
    if (states.value.players_info_card === State.DEFAULT) {
      return fields.value[fieldName].field;
    }

    return fields.value.players_info_card.field;
  };

  const getValuePath = (fieldName: string): string => {
    if (states.value.players_info_card === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.players_info_card}.${fieldName}`;
  };

  const numberStateSource = computed<IWidgetField>(() => {
    return getValueSource("racing_number");
  });

  const numberValuePath = computed<string>(() => {
    return getValuePath("racing_number");
  });

  const nameStateSource = computed<IWidgetField>(() => {
    return getValueSource("players_info_player_name");
  });

  const nameValuePath = computed<string>(() => {
    return getValuePath("players_info_player_name");
  });

  const lastRacesStateSource = computed<IWidgetField>(() => {
    return getValueSource("players_info_player_last_score");
  });

  const lastRacesValuePath = computed<string>(() => {
    return getValuePath("players_info_player_last_score");
  });

  const prevOddsStateSource = computed<IWidgetField>(() => {
    return getValueSource("players_info_player_prev_odds");
  });

  const prevOddsValuePath = computed<string>(() => {
    return getValuePath("players_info_player_prev_odds");
  });

  const playerCardsStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.players_info_card,
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "players_info_card", value });
        },
      }),
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentPlayerCardsStatePath.value}.fill`,
        valueSource: fields.value.players_info_card.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        section: generateDesignSectionName("№"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${numberValuePath.value}.color`,
            valueSource: numberStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Horse or greyhound"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${nameValuePath.value}.color`,
            valueSource: nameStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Last 5 races"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${lastRacesValuePath.value}.color`,
            valueSource: lastRacesStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Previous odds"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${prevOddsValuePath.value}.color`,
            valueSource: prevOddsStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Odds"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPlayerCardsStatePath.value}.fill`,
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${currentPlayerCardsStatePath.value}.border`,
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPlayerCardsStatePath.value}.color`,
            valueSource: fields.value.players_info_player_current_odds.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
    Player info text
  */

  const playerNumberControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.racing_number.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.racing_number.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const playerTextControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  const oddsDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.players_info_player_current_odds.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: `options.padding`,
        valueSource: fields.value.players_info_player_current_odds.field,
        options: {
          placeholder: "None",
          label: "Padding",
        },
      },
    ];
  });

  const oddsContentControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.players_info_player_current_odds.field,
        },
        "Text"
      ),
    ];
  });

  const {
    betItemDesignControls,
    betItemContentControls,
    betItemStatesControls,
  } = useRacingSportsLiveBetConfig(widget, states, emit);

  const {
    tabsContainerDesignControls,
    tabsContainerContentControls,
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
  } = useRacingSportsButtonTabsConfig(widget, states, emit);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    tabsContainerDesignControls,
    tabsContainerContentControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    eventInfoTitleControls,

    playersInfoDesignControls,
    playersInfoContentControls,

    headerCardDesignControls,
    headerCardContentControls,

    playerCardsDesignControls,
    playerCardsContentControls,

    playerCardsStatesControls,

    playerNumberControls,
    playerTextControls,
    oddsDesignControls,
    oddsContentControls,

    betItemDesignControls,
    betItemContentControls,
    betItemStatesControls,
  };
};
