import { useWidgetsStore } from "~~/store/widgets";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { HIDE_PROVIDER_FORM_FENIX_LIST } from "~~/models/widgets/form.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { FillType, State } from "~~/models/widgets/widget-controls.model";
import {
  BUTTON_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";

import { useCreateFields } from "../common/useCreateFields";

const buttonStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    buttonTextSettings: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
    },
    iconSettings: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
    },
  };
};

const CARDS_BUTTON_INITIAL = () => ({
  value: "Button",
  options: {
    _active: true,
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    fill: {
      color: "#1677FF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: {
      top: 5,
      bottom: 5,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    buttonDisplaySettings: {
      resizing: "fill",
      alignment: "center",
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#ffffff",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    iconSettings: {
      ...BUTTON_INITIAL().options.iconSettings,
      _active: false,
    },
    states: {
      [State.HOVER]: buttonStatesInitial(),
      [State.ACTIVE]: buttonStatesInitial(),
      [State.DISABLED]: buttonStatesInitial(),
    },
  },
});

const CARDS_INITIAL = () => ({
  options: {
    layoutPosition: {
      position: "right",
      alignment: "center",
      horizontalAlignment: "left",
    },
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(0),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const STATUS_MESSAGE_INITIAL = (
  fillColor: string,
  color: string,
  icon: string
) => ({
  options: {
    _active: true,
    fill: {
      color: fillColor,
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: getDefaultSpacing(12),
    color: {
      color,
      opacity: 100,
    },
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: "left",
    icon: {
      _active: true,
      value: icon,
      textColor: {
        color,
        opacity: 100,
      },
      iconSize: 16,
    },
    layout: "horizontal",
    verticalAlignment: "center",
    horizontalAlignment: "center",
    distance: 8,
  },
});

export const CARDS_IMAGE_INITIAL = () => ({
  value: "",
  options: {
    _active: true,
    containerSize: {
      size: 180,
      alignment: "center",
      resizing: "fit",
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(0),
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export const useProviderFormFenixInitFieldsOptions = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();

  const { addFieldInitialOptions, initFields, initWidgetOptions } =
    useCreateFields(widget, undefined, true, HIDE_PROVIDER_FORM_FENIX_LIST);

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("form_back_button", {
      data: CARDS_BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing(),
        margins: getDefaultSpacing({ bottom: 16 }),
      },
    });

    addFieldInitialOptions("provider_card", {
      data: CARDS_INITIAL(),
      values: {
        padding: getDefaultSpacing(16),
        spaceBetween: 4,
      },
    });

    addFieldInitialOptions("provider_title", {
      data: TITLE_INITIAL(),
      values: {
        color: {
          color: "#000000",
          opacity: 100,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 4,
          left: 4,
        },
      },
    });

    addFieldInitialOptions("provider_description_1", {
      data: TITLE_INITIAL(),
      values: {
        color: {
          color: "#000000",
          opacity: 100,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 4,
          left: 4,
        },
        theme: 16,
      },
    });

    addFieldInitialOptions("provider_image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
    });

    addFieldInitialOptions("success_message", {
      data: STATUS_MESSAGE_INITIAL(
        "#F6FFED",
        "#52C41A",
        "ant-design:check-circle-filled"
      ),
    });

    addFieldInitialOptions("additional_info", {
      data: DESIGN_INITIAL(),
      exclude: ["link", "padding"],
      additionalData: {
        options: {
          ...TITLE_INITIAL().options,
        },
      },
      values: {
        theme: getDefaultTheme(13),
        spacing: {
          margin: getDefaultSpacing({
            top: 10,
            bottom: 10,
          }),
          padding: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("fail_message", {
      data: STATUS_MESSAGE_INITIAL(
        "#FFF1F0",
        "#FF4D4F",
        "ant-design:close-circle-filled"
      ),
    });

    addFieldInitialOptions("pending_message", {
      data: STATUS_MESSAGE_INITIAL(
        "#FFFBE6",
        "#FAAD14",
        "ant-design:info-circle-filled"
      ),
    });

    addFieldInitialOptions("deposit_form_description", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        theme: getDefaultTheme(13),
        padding: getDefaultSpacing({
          top: 15,
        }),
      },
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return { init };
};
