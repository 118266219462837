<script lang="ts" setup>
import { Form } from "ant-design-vue";

import { IWidgetBindingParamsObject } from "~~/models/widgets/widget.core/widget.model";
import { IUseQueueHandler } from "~~/composables/useQueueHandler";
import { useWidgetsStore } from "~~/store/widgets";

type IWidget = any;

const props = defineProps<{
  widget: IWidget;
  initialValue: IWidgetBindingParamsObject;
  index: number;
}>();

const widgetsStore = useWidgetsStore();

const antForm = ref<typeof Form | null>();

const saveQueueHandler = inject<IUseQueueHandler | null>("saveQueueHandler");
const resetQueueHandler = inject<IUseQueueHandler | null>("resetQueueHandler");

const widgetData = computed({
  get() {
    return props.widget;
  },

  set(val) {
    widgetsStore.updateWidgetOptions(props.widget, val.options);
  },
});

const onSubmit = async () => {
  await antForm.value?.validateFields();
  // TODO: Make own error class
};

const reset = () => {
  widgetData.value.options.breadcrumbs[props.index] = props.initialValue;
};

saveQueueHandler?.addToQueue(onSubmit);
resetQueueHandler?.addToQueue(reset);

// TODO: Check this
reset();
</script>

<template>
  <div class="breadcrumbs-dynamic-params">
    <a-form
      ref="antForm"
      name="dynamic_form_nest_item"
      :model="widgetData"
      layout="vertical"
    >
      <a-space
        style="
           {
            width: 100%;
            gap: 0;
          }
        "
        direction="vertical"
      >
        <template
          v-for="(param, name) in widgetData.options.breadcrumbs[index]"
          :key="name"
        >
          <BreadcrumbsDynamicParam
            v-if="!param.isHidden"
            v-model="widgetData.options.breadcrumbs[index][name]"
            :param-name="name"
            :validation-path="['options', 'breadcrumbs', index]"
          />
        </template>
      </a-space>
    </a-form>
  </div>
</template>
