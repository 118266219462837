import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  DisplayOrientation,
  Grid,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetBindingParamsObject,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  WIDGET_INITIAL,
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultButtonTextSettings,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import {
  ARROW_INITIAL,
  TABS_INITIAL,
} from "~~/constants/configs/bets-list-common/initial-contants";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useLiveBetsListWidgetInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions, addFieldInitialOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    event_cards: State.ACTIVE_EVENTS,
    tabs: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    show_more_title: State.DEFAULT,
    arrow: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {
      maxEvents: {
        source: "custom",
        value: "5",
      },
      sportIds: {
        source: "custom",
        value: [],
      },
    } as unknown as IWidgetBindingParamsObject,
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  // /*
  //   This needed for dnd
  // */
  // if (
  //   parentCell.value?.settings.sizing === Sizing.FIXED &&
  //   initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  // ) {
  //   initialWidgetOptions.contentWidth = {
  //     type: Sizing.FIXED,
  //     width: parentCell.value?.settings.width,
  //     alignment: initialWidgetOptions.contentWidth.alignment,
  //   };
  // }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          padding: getDefaultSpacing(),
        },
      }).options
    );

    const initialTabsContainerOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: TABS_INITIAL(),
        additionalData: {
          options: {
            container: {},
            text: {
              _active: true,
            },
          },
        },
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_cards.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "padding", "size"],
        additionalData: {
          options: {
            gridSettings: {
              grid: Grid.FIXED,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 2,
              minCardsInRow: 1,
            },
            betItemsLayout: DisplayOrientation.HORIZONTAL,
            betItemsDistance: 16,
          },
        },
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialSuspendedOptions = prefillEmptyOptions(
      fields.value.event_suspended_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F62F39"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#F62F39"),
          }),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing({
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["alignment"],
        additionalData: {
          options: {
            icon: {
              size: 20,
              color: getDefaultFillValue("#69B1FF"),
              value: "mdi:clock",
            },
          },
        },
        values: {
          theme: 13,
          padding: getDefaultSpacing(),
        },
      }).options
    );

    const initialLeagueOptions = prefillEmptyOptions(
      fields.value.event_league.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          padding: getDefaultSpacing(),
        },
      }).options
    );

    const initialInfoOptions = prefillEmptyOptions(
      fields.value.event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            displayMode: "center",
            distance: 8,
          },
        },
        values: {
          spacing: {
            padding: {
              top: 0,
              bottom: 16,
              left: 0,
              right: 0,
            },
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        },
      }).options
    );

    const initialEventTeamPlayerOptions = prefillEmptyOptions(
      fields.value.event_team_player.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialEventScoreOptions = prefillEmptyOptions(
      fields.value.event_score.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "alignment", "size"],
        additionalData: {
          options: {},
        },
        values: {
          theme: 13,
          spacing: {
            padding: getDefaultSpacing({
              top: 8,
              bottom: 8,
              left: 12,
              right: 12,
            }),
            margin: getDefaultSpacing(0),
          },
          fill: getDefaultFillValue("#4096FF"),
          color: getDefaultFillValue("#ffffff"),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing(8),
            distance: 8,
            displayMode: "horizontal",
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            arrow: {
              up: {
                color: getDefaultFillValue("#1677ff"),
              },
              down: {
                color: getDefaultFillValue("#1677ff"),
              },
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
              [State.UP]: initialBetItemState(),
              [State.DOWN]: initialBetItemState(),
              suspended: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          alignment: Alignment.CENTER,
          theme: getDefaultTheme(16),
        },
      }).options
    );

    const initialShowMoreTitleOptions = prefillEmptyOptions(
      fields.value.show_more_title.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          iconSettings: getDefaultIconSettings({
            _active: false,
          }),
          buttonTextSettings: getDefaultButtonTextSettings({
            textColor: getDefaultFillValue("#1677FF"),
          }),
        },
      }).options
    );

    const initialArrowOptions = prefillEmptyOptions(
      fields.value.arrow.field.options,
      getInitialOptions({
        data: ARROW_INITIAL(),
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_cards.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_suspended_status.field,
      initialSuspendedOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_league.field,
      initialLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info.field,
      initialInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_team_player.field,
      initialEventTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_score.field,
      initialEventScoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.show_more_title.field,
      initialShowMoreTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.arrow.field,
      initialArrowOptions
    );

    addFieldInitialOptions("header", {
      data: WIDGET_INITIAL(),
      exclude: ["link", "padding", "alignment", "baseDisplaySettings", "size"],
      values: {
        displayOrientation: DisplayOrientation.VERTICAL,
      },
    });
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
