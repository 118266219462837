import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import {
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";

export const generateStringDefault =
  (element: IWidgetOptions, except?: string[], additionalStyles?: string) =>
  (cssString: string): string => {
    if (!element) return cssString;

    cssString += `.${element.options._cssClass} {${generateDefaultStyles(
      element.options,
      except
    )}}`;

    if (additionalStyles) {
      cssString += `.${element.options._cssClass} {${additionalStyles}}`;
    }

    return cssString;
  };

export const generateStringWithStates =
  (
    element: IWidgetOptions,
    isLinkStates: boolean,
    stateParentElementClass?: string,
    addDataActive = false
  ) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(
      element,
      isLinkStates,
      undefined,
      undefined,
      undefined,
      stateParentElementClass,
      addDataActive
    );
    return cssString;
  };

export const generateCustomStyles =
  (cssClass: string, styles: Record<string, any>) =>
  (cssString: string): string => {
    let currCss = "";

    for (const key in styles) {
      currCss += `${key}:${styles[key]};`;
    }

    cssString += `.${cssClass} {${currCss}}`;
    return cssString;
  };

export const generateDefaultStylesWithStateClassName =
  (
    element: any,
    stateName: string,
    stateClassName: string,
    except: string[] = []
  ) =>
  (cssString: string): string => {
    const { options } = element;

    const stateOptions = options.states[stateName];

    cssString += `.${stateClassName} {${generateDefaultStyles(
      stateOptions,
      except
    )}}`;

    return cssString;
  };
