<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-states>
          <ControlsList
            :widget="widget"
            :controls="widgetStatesControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #description_1>
          <ControlsList
            :widget="widget"
            :controls="descriptionControls"
          />
        </template>

        <template #buttons_group-design>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupDesignControls"
          />
        </template>

        <template #buttons_group-content>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupContentControls"
          />
        </template>

        <template #button_1-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_1-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_1-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #button_2-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_2-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_2-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/card/fenix/useCardFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "useSeoTitle": {
      label: "Use SEO h1",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
    "useSeoDescription": {
      label: "Use SEO description",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const states = toRef(() => props.states);
const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,
  widgetStatesControls,

  imageControls,
  titleControls,
  descriptionControls,

  buttonsGroupDesignControls,
  buttonsGroupContentControls,

  buttonDesignControls,
  buttonContentControls,
  buttonStatesControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
