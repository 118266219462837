import _get from "lodash/get";

import { State } from "~~/models/widgets/widget-controls.model";
import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { generateStringDefault } from "~~/assets/utils/widget-css/utils/pipe-helper-functions";
import { generateClassName } from "~~/assets/utils/widget-css/utils/generate-class-name";
import { styleObjectToStringAdvanced } from "~~/assets/utils";
import { generateCssClassWithContent } from "~~/assets/utils/widget-css/compiler/default-css-compiler";
import { generateFlexibleImageCssString } from "~~/assets/utils/widget-css/utils/form-helper-functions";
import { useArrowsStyles } from "~~/entities/carousel/composables/styles/useArrowsStyles";
import { usePaginationStyles } from "~~/entities/carousel/composables/styles/usePaginationStyles";

import { useWrapperStyles } from "./composables/styles/useWrapperStyles";
import { useGridCellStyles } from "./composables/styles/useGridCellStyles";
import { useCardsStyles } from "./composables/styles/useCardsStyles";
import { useContentStyles } from "./composables/styles/useContentStyles";
import { useEventInfoStyles } from "./composables/styles/useEventInfoStyles";
import { useTeamPlayerStyles } from "./composables/styles/useTeamPlayerStyles";
import { useDateTimeStyles } from "./composables/styles/useDateTimeStyles";
import { useMarketsStyles } from "./composables/styles/useMarketsStyles";
import { useOutcomesStyles } from "./composables/styles/useOutcomesStyles";
import { useButtonStyles } from "./composables/styles/useButtonStyles";
import { usePromoBetsFenixWidgetStateController } from "./composables/usePromoBetsFenixWidgetStateController";

export const generatePromoBetsFenixWidgetCssString = (
  widget: IPageContentWidget
) => {
  const { stateController } = usePromoBetsFenixWidgetStateController();
  widget.options._cssClass = generateClassName("PromoBetsFenixWidget");
  widget.options._wrapperCssClass = generateClassName("wrapper");
  widget.options._gridCellCssClass = generateClassName("gridCell");

  const cards = widget.content["cards"];
  cards.options._cssClass = generateClassName("cards");

  const image = widget.content["image"];
  image.options._cssClass = generateClassName("image");

  const content = widget.content["content"];
  content.options._cssClass = generateClassName("content");

  const eventInfo = widget.content["event_info"];
  eventInfo.options._cssClass = generateClassName("eventInfo");

  const teamPlayer = widget.content["team_player"];
  teamPlayer.options._cssClass = generateClassName("teamPlayer");
  teamPlayer.options._textCssClass = generateClassName("teamPlayerText");
  teamPlayer.options._iconContainerCssClass = generateClassName(
    "teamPlayerIconContainer"
  );
  teamPlayer.options.icon._cssClass = generateClassName("teamPlayerIcon");

  const dateTime = widget.content["date_time"];
  dateTime.options._cssClass = generateClassName("dateTime");
  dateTime.options._elementCssClass = generateClassName("dateTimeElement");
  dateTime.options.icon._cssClass = generateClassName("dateTimeIcon");
  dateTime.options._textCssClass = generateClassName("dateTimeText");

  const markets = widget.content["markets"];
  markets.options._cssClass = generateClassName("markets");

  const outcomes = widget.content["outcomes"];
  outcomes.options._cssClass = generateClassName("outcomes");
  outcomes.options._selectedCssClass = generateClassName("outcomesSelected");
  outcomes.options.textName._cssClass = generateClassName("outcomesTextName");
  outcomes.options.textCoefficient._cssClass = generateClassName(
    "outcomesTextCoefficient"
  );

  const button = widget.content["button"];
  button.options._containerCssClass = generateClassName("buttonContainer");
  button.options._cssClass = generateClassName("button");
  button.options.buttonTextSettings._cssClass =
    generateClassName("buttonTextSettings");
  button.options.iconSettings._cssClass = generateClassName("iconSettings");

  const arrows = widget.content["arrows"];
  arrows.options._cssClass = generateClassName("arrows");
  arrows.options._containerCssClass = generateClassName("arrowsContainer");
  arrows.options.button._cssClass = generateClassName("arrowsButton");

  const pagination = widget.content["pagination"];
  pagination.options._cssClass = generateClassName("pagination");
  pagination.options._dotsCssClass = generateClassName("paginationDots");

  const generate = () => {
    return (cssString: string): string => {
      // wrapper
      cssString += generateCssClassWithContent({
        className: widget.options._wrapperCssClass,
        content: styleObjectToStringAdvanced(useWrapperStyles(widget)),
      });

      // gridCell
      cssString += generateCssClassWithContent({
        className: widget.options._gridCellCssClass,
        content: styleObjectToStringAdvanced(useGridCellStyles(widget)),
      });

      // content
      cssString += generateCssClassWithContent({
        className: content.options._cssClass,
        content: styleObjectToStringAdvanced(useContentStyles(content.options)),
      });

      // eventInfo
      cssString += generateCssClassWithContent({
        className: eventInfo.options._cssClass,
        content: styleObjectToStringAdvanced(
          useEventInfoStyles(eventInfo.options)
        ),
      });

      // markets
      cssString += generateCssClassWithContent({
        className: markets.options._cssClass,
        content: styleObjectToStringAdvanced(useMarketsStyles(markets.options)),
      });

      return cssString;
    };
  };

  return pipeSync<string>(
    generateStringDefault(widget),
    generate(),
    generateCards(),
    generateFlexibleImageCssString(image as IWidgetField),
    generateTeamPlayer(),
    generateDateTime(),
    generateOutcomes(),
    generateButton(),
    generateArrows(),
    generatePagination()
  )("");

  function generateCards() {
    return (cssString: string): string => {
      // states cards && states - teamPlayerText
      iterableStates(cards.options.states, (stateName, isDefaultStateName) => {
        const cardsOptions =
          _get(
            cards,
            `${stateController.getOptionsValuePath(undefined, stateName)}`
          ) || {};

        cssString += generateCssClassWithContent({
          className: cards.options._cssClass,
          pseudoClassName: !isDefaultStateName ? `:${stateName}` : undefined,
          content: styleObjectToStringAdvanced(useCardsStyles(cardsOptions)),
        });

        if (!isDefaultStateName) {
          const teamPlayerOptions =
            _get(
              teamPlayer,
              `${stateController.getOptionsValuePath(undefined, stateName)}`
            ) || {};

          cssString += generateCssClassWithContent({
            className: cards.options._cssClass,
            pseudoClassName: !isDefaultStateName
              ? `:${stateName} .${teamPlayer.options._textCssClass}`
              : undefined,
            content: styleObjectToStringAdvanced(
              useTeamPlayerStyles(teamPlayerOptions).teamPlayerTextStyles()
            ),
          });
        }
      });
      return cssString;
    };
  }

  function generateTeamPlayer() {
    return (cssString: string): string => {
      // teamPlayer
      cssString += generateCssClassWithContent({
        className: teamPlayer.options._cssClass,
        content: styleObjectToStringAdvanced(
          useTeamPlayerStyles(
            teamPlayer.options,
            eventInfo.options._active
          ).teamPlayerStyles()
        ),
      });

      // teamPlayerText
      cssString += generateCssClassWithContent({
        className: teamPlayer.options._textCssClass,
        content: styleObjectToStringAdvanced(
          useTeamPlayerStyles(
            teamPlayer.options,
            eventInfo.options._active
          ).teamPlayerTextStyles()
        ),
      });

      // teamPlayerIconContainer
      cssString += generateCssClassWithContent({
        className: teamPlayer.options._iconContainerCssClass,
        content: styleObjectToStringAdvanced(
          useTeamPlayerStyles(
            teamPlayer.options,
            eventInfo.options._active
          ).teamPlayerIconContainerStyles()
        ),
      });

      // teamPlayerIcon
      cssString += generateCssClassWithContent({
        className: teamPlayer.options.icon._cssClass,
        content: styleObjectToStringAdvanced(
          useTeamPlayerStyles(
            teamPlayer.options,
            eventInfo.options._active
          ).teamPlayerIconStyles()
        ),
      });
      return cssString;
    };
  }

  function generateDateTime() {
    return (cssString: string): string => {
      // dateTime
      cssString += generateCssClassWithContent({
        className: dateTime.options._cssClass,
        content: styleObjectToStringAdvanced(
          useDateTimeStyles(
            dateTime.options,
            eventInfo.options._active
          ).dateTimeStyles(eventInfo.options)
        ),
      });

      // dateTimeElement
      cssString += generateCssClassWithContent({
        className: dateTime.options._elementCssClass,
        content: styleObjectToStringAdvanced(
          useDateTimeStyles(
            dateTime.options,
            eventInfo.options._active
          ).dateTimeElementStyles()
        ),
      });

      // dateTimeIcon
      cssString += generateCssClassWithContent({
        className: dateTime.options.icon._cssClass,
        content: styleObjectToStringAdvanced(
          useDateTimeStyles(
            dateTime.options,
            eventInfo.options._active
          ).dateTimeIconStyles()
        ),
      });

      // dateTimeText
      cssString += generateCssClassWithContent({
        className: dateTime.options._textCssClass,
        content: styleObjectToStringAdvanced(
          useDateTimeStyles(
            dateTime.options,
            eventInfo.options._active
          ).dateTimeTextStyles()
        ),
      });
      return cssString;
    };
  }

  function generateOutcomes() {
    return (cssString: string): string => {
      // outcomes
      iterableStates(
        outcomes.options.states,
        (stateName, isDefaultStateName) => {
          const outcomesOptions =
            _get(
              outcomes,
              `${stateController.getOptionsValuePath(undefined, stateName)}`
            ) || {};

          cssString += generateCssClassWithContent({
            className: outcomes.options._cssClass,
            pseudoClassName: !isDefaultStateName ? `:${stateName}` : undefined,
            content: styleObjectToStringAdvanced(
              useOutcomesStyles(outcomesOptions).outcomesStyles()
            ),
          });

          if (stateName === State.ACTIVE) {
            cssString += generateCssClassWithContent({
              className: outcomes.options._selectedCssClass,
              content: styleObjectToStringAdvanced(
                useOutcomesStyles(outcomesOptions).outcomesStyles()
              ),
            });
          }

          if (!isDefaultStateName) {
            cssString += generateCssClassWithContent({
              className: outcomes.options._cssClass,
              pseudoClassName: !isDefaultStateName
                ? `:${stateName} .${outcomes.options.textName._cssClass}`
                : undefined,
              content: styleObjectToStringAdvanced(
                useOutcomesStyles(outcomesOptions).outcomesTextNameStyles()
              ),
            });

            cssString += generateCssClassWithContent({
              className: outcomes.options._cssClass,
              pseudoClassName: !isDefaultStateName
                ? `:${stateName} .${outcomes.options.textCoefficient._cssClass}`
                : undefined,
              content: styleObjectToStringAdvanced(
                useOutcomesStyles(
                  outcomesOptions
                ).outcomesTextCoefficientStyles()
              ),
            });
          }
        }
      );

      // outcomesTextName
      cssString += generateCssClassWithContent({
        className: outcomes.options.textName._cssClass,
        content: styleObjectToStringAdvanced(
          useOutcomesStyles(outcomes.options).outcomesTextNameStyles()
        ),
      });

      // outcomesTextCoefficient
      cssString += generateCssClassWithContent({
        className: outcomes.options.textCoefficient._cssClass,
        content: styleObjectToStringAdvanced(
          useOutcomesStyles(outcomes.options).outcomesTextCoefficientStyles()
        ),
      });
      return cssString;
    };
  }

  function generateButton() {
    return (cssString: string): string => {
      // buttonContainer
      cssString += generateCssClassWithContent({
        className: button.options._containerCssClass,
        content: styleObjectToStringAdvanced(
          useButtonStyles(button.options).buttonContainerStyles()
        ),
      });

      // button
      iterableStates(button.options.states, (stateName, isDefaultStateName) => {
        const buttonOptions =
          _get(
            button,
            `${stateController.getOptionsValuePath(undefined, stateName)}`
          ) || {};

        cssString += generateCssClassWithContent({
          className: button.options._cssClass,
          pseudoClassName: !isDefaultStateName ? `:${stateName}` : undefined,
          content: styleObjectToStringAdvanced(
            useButtonStyles(buttonOptions).buttonStyles()
          ),
        });

        if (!isDefaultStateName) {
          cssString += generateCssClassWithContent({
            className: button.options._cssClass,
            pseudoClassName: !isDefaultStateName
              ? `:${stateName} .${button.options.buttonTextSettings._cssClass}`
              : undefined,
            content: styleObjectToStringAdvanced(
              useButtonStyles(buttonOptions).buttonTextStyles()
            ),
          });

          cssString += generateCssClassWithContent({
            className: button.options._cssClass,
            pseudoClassName: !isDefaultStateName
              ? `:${stateName} .${button.options.iconSettings._cssClass}`
              : undefined,
            content: styleObjectToStringAdvanced(
              useButtonStyles(buttonOptions).buttonIconStyles()
            ),
          });
        }
      });

      // buttonTextSettings
      cssString += generateCssClassWithContent({
        className: button.options.buttonTextSettings._cssClass,
        content: styleObjectToStringAdvanced(
          useButtonStyles(button.options).buttonTextStyles()
        ),
      });

      // iconSettings
      cssString += generateCssClassWithContent({
        className: button.options.iconSettings._cssClass,
        content: styleObjectToStringAdvanced(
          useButtonStyles(button.options).buttonIconStyles()
        ),
      });

      return cssString;
    };
  }

  function generateArrows() {
    return (cssString: string): string => {
      // arrows
      cssString += generateCssClassWithContent({
        className: arrows.options._cssClass,
        content: styleObjectToStringAdvanced(
          useArrowsStyles(arrows.options).arrowsStyles()
        ),
      });

      // arrowsContainer
      cssString += generateCssClassWithContent({
        className: arrows.options._containerCssClass,
        content: styleObjectToStringAdvanced(
          useArrowsStyles(arrows.options).arrowsContainerStyles()
        ),
      });

      // arrowsButton
      iterableStates(arrows.options.states, (stateName, isDefaultStateName) => {
        const arrowsOptions =
          _get(
            arrows,
            `${stateController.getOptionsValuePath(undefined, stateName)}`
          ) || {};

        cssString += generateCssClassWithContent({
          className: arrows.options.button._cssClass,
          pseudoClassName: !isDefaultStateName ? `:${stateName}` : undefined,
          content: styleObjectToStringAdvanced(
            useArrowsStyles(arrowsOptions).arrowsButtonStyles()
          ),
        });
      });

      return cssString;
    };
  }

  function generatePagination() {
    return (cssString: string): string => {
      // pagination
      cssString += generateCssClassWithContent({
        className: pagination.options._cssClass,
        content: styleObjectToStringAdvanced(
          usePaginationStyles(pagination.options).paginationStyles()
        ),
      });

      // arrowsButton
      iterableStates(
        pagination.options.states,
        (stateName, isDefaultStateName) => {
          const paginationOptions =
            _get(
              pagination,
              `${stateController.getOptionsValuePath(undefined, stateName)}`
            ) || {};

          paginationOptions.isStroke = pagination.options.isStroke;

          cssString += generateCssClassWithContent({
            className: pagination.options._dotsCssClass,
            pseudoClassName: !isDefaultStateName ? `.${stateName}` : undefined,
            content: styleObjectToStringAdvanced(
              usePaginationStyles(paginationOptions).paginationDotsStyles()
            ),
          });
        }
      );

      return cssString;
    };
  }
};

const iterableStates = (
  states: any,
  callback: (stateName: string, isDefaultStateName: boolean) => void
) => {
  const localStates = {
    [State.DEFAULT]: {},
    ...states,
  };

  for (const stateName in localStates) {
    const isDefaultStateName = stateName === State.DEFAULT;
    callback(stateName, isDefaultStateName);
  }
};
