import { defineStore } from "pinia";

import {
  IStaticTranslations,
  CategoryTranslations,
} from "~~/models/stores/static-translation-store.model";
import { ILanguageProperties } from "~~/models/widgets/languages.interface";

export const useStaticTranslations = defineStore("staticTranslations", {
  state: (): IStaticTranslations => ({
    staticTranslations: {} as CategoryTranslations,
    translationLanguage: "",
  }),
  getters: {},
  actions: {
    setTranslations(translations: any, lang: ILanguageProperties): void {
      this.staticTranslations = Object.assign({}, this.staticTranslations, {
        [lang.codeWithRegion]: translations,
      });
    },
    setTranslationLanguage(lang: string): void {
      this.translationLanguage = lang;
    },
  },
});
