import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useBetslipItems } from "./useBetslipItems";

export const useBetslipResultContainerControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { RESULT_SETTINGS_ITEMS_DEFAULT, RESULT_SETTINGS_ITEMS_BETS_RESULT } =
    useBetslipItems(widget);

  const resultDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const currentResultSettingsItems = computed<
    Array<{ label: string; value: string }>
  >(() => {
    if (states.value[widget.value.name] === State.BETS_RESULT) {
      return RESULT_SETTINGS_ITEMS_BETS_RESULT;
    }

    return RESULT_SETTINGS_ITEMS_DEFAULT;
  });

  const resultContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: currentResultSettingsItems.value,
        },
      },
    ];
  });

  const totalAmountsControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.position",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            { value: "vertical", label: "Vertical" },
            { value: "horizontal", label: "Horizontal" },
          ],
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.gap",
        valueSource: selectedField.value,
        options: {
          label: "Distance between items",
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        section: generateDesignSectionName("Label text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.labelSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.labelSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.labelSettings.color",
            valueSource: selectedField.value,
            options: {
              label: "Color ",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Value text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.valueSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.valueSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.valueSettings.color",
            valueSource: selectedField.value,
            options: {
              label: "Color ",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betAmountsControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.position",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            { value: "vertical", label: "Vertical" },
            { value: "horizontal", label: "Horizontal" },
          ],
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.gap",
        valueSource: selectedField.value,
        options: {
          label: "Distance between items",
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: selectedField.value,
        options: {
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        section: generateDesignSectionName("Label text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.labelSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.labelSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.labelSettings.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Value text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.valueSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.valueSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.valueSettings.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });
  return {
    resultDesignControls,
    resultContentControls,

    totalAmountsControls,

    betAmountsControls,
  };
};
