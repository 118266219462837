import { Grid } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 37;

const v37WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;

  if (["SportsBookLiveFenixWidget"].includes(widget.name)) {
    if (!widget.content.sport_events_container.options.gridSettings) {
      widget.content.sport_events_container.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      };
    }

    if (!widget.content.racing_events_container.options.gridSettings) {
      widget.content.racing_events_container.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      };
    }
  }

  if (["LiveBetsListWidget", "BetsListWidget"].includes(widget.name)) {
    if (!widget.content.event_cards.options.gridSettings) {
      widget.content.event_cards.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      };
    }
  }

  if (["SportsBookPreMatchFenixMobileWidget"].includes(widget.name)) {
    if (!widget.content.list_container.options.gridSettings) {
      widget.content.list_container.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIXED,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 1,
        minCardsInRow: 1,
      };
    }

    if (!widget.content.top_leagues_list_container.options.gridSettings) {
      widget.content.top_leagues_list_container.options.gridSettings = {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIXED,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 1,
        minCardsInRow: 1,
      };
    }
  }

  return widget;
};

export default v37WidgetMigration;
