<template>
  <div :style="containerStyle">
    <slot name="header"></slot>
    <slot name="content_container"></slot>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyle = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});
</script>
