import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
  ICustomField,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";

import { useSportsbookInitData } from "../sportsbook-fenix-mobile/useSportsbookInitData";
import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useSportsBookTabsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { itemsGroup } = useSportsbookInitData(widget.value);

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);

  const tabsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.items.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.items.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.items.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.items.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.items.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const itemsField = computed<IWidgetField | undefined>(() => {
    const field = (selectedWidget.value?.fields || []).find(
      field => field.name === FieldName.ITEMS
    );

    return field;
  });

  const itemsGroupField = computed<ICustomField>(
    () => (itemsField.value?.value as ICustomField[])[0]
  );

  const filteredItemsGroupFields = computed(() => {
    if (states.value.widget === State.SPORTSBOOK_LEAGUE) {
      return itemsGroupField.value?.fields?.filter(
        field =>
          field.name === FieldName.LEAGUE_WINNER ||
          field.name === FieldName.EVENTS
      );
    }

    return itemsGroupField.value?.fields?.filter(
      field => field.name !== FieldName.LEAGUE_WINNER
    );
  });

  const tabsContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItems",
        options: {
          label: "Tab Items",
          items: filteredItemsGroupFields.value,
          "onUpdate:items": (fields: IWidgetField[]) => {
            itemsGroupField.value.fields = fields;
          },
          hideCRUD: true,
          hideDND: true,
          optionsClickHandler: () => {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              itemsGroupField.value
            );
          },
        },
      },
    ];
  });

  const tabItemsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const tabItemsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.resizing",
        valueSource: itemsGroup.value,
        className: "group-control-element",
        options: {
          label: "Resizing",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.containerAlignment",
        valueSource: itemsGroup.value,
        className: "group-control-element--child",
        visible: itemsGroup.value!.options.resizing === ResizingType.HUG,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.distance",
        valueSource: itemsGroup.value,
        options: {
          label: "Distance between tabs",
          suffixContent: "px",
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: itemsGroup.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.theme",
            valueSource: itemsGroup.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.fontFamily",
            valueSource: itemsGroup.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.decoration",
            valueSource: itemsGroup.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: itemsGroup.value,
            options: {
              label: "Alignment",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconSettings",
            valuePath: "options.itemsIcon",
            valueSource: itemsGroup.value,
            options: {
              isStatesTab: false,
              hideSwitch: true,
            },
          },
        ],
      },
    ];
  });

  const statesValuePath = computed<string>(() => {
    if (states.value.itemsGroup === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.itemsGroup}`;
  });

  const tabItemsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.TEXT, FieldContentItem.ICON]
  );

  const itemControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItemText",
        valuePath: `options.text`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.icon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
    ];
  });

  return {
    tabsDesignControls,
    tabsContentControls,
    tabItemsDesignControls,
    tabItemsContentControls,
    tabItemsStatesControls,
    itemControls,
  };
};
