<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <ComingEventsSwitcherWidgetSettings
      :widget="widget"
      :items-group-state="states.items"
      @update-items-group-state="
        handleStateUpdate({ state: 'items', value: $event })
      "
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="coming-events-switcher-widget">
      <div
        :style="itemsWrapperStyle"
        class="coming-events-switcher-widget__items"
      >
        <WidgetField
          v-for="itemField in itemsFields"
          :key="itemField.id"
          :field="itemField"
          :widget="widget"
          :class="{
            'coming-events-switcher-widget__field--selected':
              isItemsGroupSelected,
          }"
          :style="itemWrapperStyle"
        >
          <ArrayItemField
            :field="itemField"
            :state="states.items"
            :wrapper-options="menuItemsOptions"
            :is-items-group-selected="isItemsGroupSelected"
          />
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useInit } from "~~/composables/widgets/coming-events-switcher/useComingEventsSwitcherInitData";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const {
  states,
  DEFAULT_STATES,
  initFields,
  itemsFields,
  isItemsGroupSelected,
  menuItemsOptions,
  itemsGroup,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const isItemsGroupHug = computed<boolean>(
  () => itemsGroup.value?.options.resizing === ResizingType.HUG
);

const itemsWrapperStyle = computed<CSSProperties>(() => {
  return {
    gap: `${menuItemsOptions.value.spaceBetween}px`,
    justifyContent: isItemsGroupHug.value
      ? itemsGroup.value?.options.alignment
      : "unset",
  };
});

const itemWrapperStyle = computed<CSSProperties>(() => {
  if (isItemsGroupHug.value) {
    return {};
  }

  return {
    flex: 1,
  };
});

initFields();
</script>

<style lang="scss">
.coming-events-switcher-widget {
  display: flex;
  flex-direction: row;

  &__field--selected {
    border: 2px solid rgba($c-primary-base, 1);
  }

  &__title {
    position: relative;
    display: flex;
  }

  &__items {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
</style>
