import { generateId } from "~~/assets/utils";
import { DEFAULT_ADAPTIVE_COL_WIDTH } from "~~/constants/width-validations";
import {
  ICell,
  CellId,
  Sizing,
  BlockType,
  InsertionRule,
  NestingLevel,
  ICreateCellSettings,
  IParentData,
} from "~~/models/grid.interface";

import {
  autoSetColumnsWidth,
  getDefaultContainerWidth,
} from "./grid-cell-width";

/**
 * Find item by ID in the cells tree.
 * @param cells: a tree to search into
 * @param id: cellId
 * @returns the original (not a copy of) cell with given ID; or nothing if not found
 */
export function findCell(cells: ICell[], cellId: CellId | null): ICell | null {
  for (const cell of cells) {
    if (cell.cellId === cellId) return cell;
    if (cell.children?.length) {
      const result = findCell(cell.children, cellId);
      if (result) return result;
    }
  }
  return null;
}

/**
 * Having a current cell, finds the closest cell with NestingLevel.PARENT up the grid tree.
 * Returns current cell if it matches the requirement, i.e. has NestingLevel.PARENT.
 * @param cells: a tree to search into
 * @param cell: a current cell from which we start searching
 * @returns the cell with NestingLevel.PARENT or null if not found
 */
export function findParentLevelCell(
  cells: ICell[],
  cell: ICell | null
): ICell | null {
  if (!cell) {
    return null;
  }

  if (cell.settings.level === NestingLevel.PARENT) {
    return cell;
  }

  /* NOTE: if a cell is of NestingLevel.CHILD, it must have parentId */
  const parent: ICell | null = findCell(cells, cell.parentId as number);

  return findParentLevelCell(cells, parent);
}

/**
 * Having a current cell, finds the closest cell with blockType.CONTAINER up the grid tree.
 * Returns current cell if it matches the requirement, i.e. has blockType.CONTAINER.
 * @param cells: a tree to search into
 * @param cell: a current cell from which we start searching
 * @returns the cell with blockType.CONTAINER or null if not found
 */
export function findCellContainer(
  cells: ICell[],
  cell: ICell | null
): ICell | null {
  if (!cell) {
    return null;
  }

  if (cell.settings.blockType === BlockType.CONTAINER) {
    return cell;
  }

  /*
    NOTE: cell will always have a parentId as case when current cell is root container(with parendId null)
    is covered in previous condition
  */
  const parent: ICell | null = findCell(cells, cell.parentId as CellId);

  return findCellContainer(cells, parent);
}

/** Recursively copy cell and it's children creating new cellId
 * @param cell - cell to copy
 * @returns new cell
 */
export function deepCopyCell(cell: ICell, parentId?: CellId | null): ICell {
  const cellId = generateId();
  const newParentID =
    parentId === null || typeof parentId === "number"
      ? parentId
      : cell.parentId;
  return {
    cellId,
    parentId: newParentID,
    settings: {
      ...cell.settings,
    },
    children: cell.children.map(child => deepCopyCell(child, cellId)),
  };
}

/**
 * Creates new cell with given params
 * @param columns: number of columns
 * @param level: level of generated cell(parent or child)
 * @param parentId: parentId of generated cell(if needed)
 * @returns: created cell
 */
export function createCell(
  columns: number,
  level: NestingLevel,
  parentId: CellId | null | undefined = null,
  parentSizing = Sizing.ADAPTIVE,
  parentWidth?: number
): ICell {
  const cellId: CellId = generateId();

  const cell: ICell = {
    cellId,
    parentId,
    settings: {
      sizing: parentSizing,
      level,
      blockType: BlockType.CONTAINER,
      width: parentWidth ?? DEFAULT_ADAPTIVE_COL_WIDTH,
    },
    children: generateColumnsList(cellId, columns, level, parentSizing),
  };
  autoSetColumnsWidth(cell);
  return cell;
}

/**
 * Find root element (cell with parentId: null) of a current cell.
 * Returns current cell if it matches the requirement, i.e. is a root-level container.
 * @param cells: a tree to search into
 * @param cell: cell, where root element should be found
 * @returns the original root element of current cell or null if not found
 */
export function findCellRootElement(
  cells: ICell[],
  cell: ICell | null
): ICell | null {
  if (!cell) {
    return null;
  }

  if (!cell.parentId) {
    return cell;
  }

  const parent: ICell | null = findCell(cells, cell.parentId);

  return findCellRootElement(cells, parent);
}

/**
 * Delete cell from grid tree by id
 * @param grid: a tree modify
 * @param cellId: target cellId
 * @param parentId?: source cellId; if not available, no need for recursive search deep in tree as the cell can be deleted from the root with one iteration
 * @returns: original mutated tree
 */

export function deleteCell(
  grid: ICell[],
  cellId: CellId,
  parentId: CellId | null = null
): ICell[] {
  if (parentId) {
    const parentCell = findCell(grid, parentId);
    if (parentCell?.children.length) {
      const updatedChildren = parentCell.children.filter(
        cell => cell.cellId !== cellId
      );
      parentCell.children = updatedChildren;
    }
  } else {
    grid = grid.filter(cell => cell.cellId !== cellId);
  }
  return grid;
}

/**
 * Modify properties of a given cell and its children
 * @param cell: cell to modify
 * @param props: params we want to change, inluding NestingLevel, parentId
 * @returns: modified cell (no deep copy)
 */
export function changeCellParams(
  cell: ICell,
  props: {
    level?: NestingLevel;
    parentId?: CellId | null;
    width?: number;
    sizing?: Sizing;
  }
): ICell {
  const level = props.level ?? cell.settings.level;
  const parentId = props.parentId ?? null;
  const width = props.width ?? cell.settings.width;
  const sizing = props.sizing ?? cell.settings.sizing;

  const newCell = {
    ...cell,
    parentId,
    settings: {
      ...cell.settings,
      level,
      width,
      sizing,
    },
    children: cell.children.map(child => {
      return {
        ...child,
        settings: {
          ...child.settings,
          level,
        },
      };
    }),
  };
  return newCell;
}

/**
 * Add a cell into the given target cell
 * @param grid: a grid tree to modify
 * @param cell: id of the cell to move
 * @param targetID: id of the cell to move into
 * @param index: index to insert new generated cell. Default is -1 to push the cell to the end of list
 * @returns original mutated tree
 */

export function putCellInside(
  grid: ICell[],
  cell: ICell,
  parentId: CellId,
  index: number = -1
): ICell[] {
  const targetCell = findCell(grid, parentId);
  if (!targetCell) {
    handleError("putCellInside");
    return grid;
  }
  /* NOTE: changing parentId and nesting level,
  adjust width params of a child to parent
  */
  const cellToPut = changeCellParams(cell, {
    level: NestingLevel.CHILD,
    parentId: targetCell.cellId,
    sizing: targetCell.settings.sizing,
    width: targetCell.settings.width,
  });
  autoSetColumnsWidth(cellToPut);

  grid = deleteCell(grid, cell.cellId, cell.parentId);

  if (index > -1) {
    targetCell.children.splice(index, 0, cellToPut);
  } else {
    targetCell.children.push(cellToPut);
  }

  return grid;
}

/**
 * Change position of a given cell in a grid
 * @param grid - a grid tree to modify
 * @param cell - a cell object to change position
 * @param oldIndex - initial index of cell in a corresponding container's children array
 * @param newIndex - desired index of cell
 * @returns - mutated grid
 */

export function changeCellPosition(
  grid: ICell[],
  cell: ICell,
  oldIndex: number,
  newIndex: number
): ICell[] {
  let targetGrid: ICell[] = [];
  if (cell.parentId) {
    const parentCell = findCell(grid, cell.parentId);
    if (!parentCell) {
      handleError("changeCellPosition");
      return grid;
    }
    if (parentCell) {
      targetGrid = parentCell.children;
    }
  } else {
    targetGrid = grid;
  }
  const deletedCell = targetGrid.splice(oldIndex, 1)[0];
  targetGrid.splice(newIndex, 0, deletedCell);
  return grid;
}

/**
 * Insert a cell before or after the given cell on the same level.
 * @param grid: a grid tree to modify
 * @param cell: a cell to move
 * @param targetID: a cell to place the given cell after
 * @param insertionRule: where to insert (before by default)
 * @returns original mutated tree
 */

export function insertCell(
  grid: ICell[],
  cell: ICell,
  targetID: CellId | null,
  insertionRule: InsertionRule = InsertionRule.BEFORE
): ICell[] {
  const targetCell = findCell(grid, targetID);
  if (!targetCell) {
    handleError("insertCell");
    return grid;
  }
  // Copy of cell with edited params
  const cellAfterMove = changeCellParams(cell, {
    level: targetCell.settings.level,
    parentId: targetCell.parentId,
    sizing: targetCell.settings.sizing,
    width: targetCell.settings.width,
  });
  // Remove old cell
  grid = deleteCell(grid, cell.cellId, cell.parentId);

  // NOTE: slightly different logic for root and nested level
  if (targetCell.parentId) {
    const targetGrid = findCell(grid, targetCell.parentId);
    if (targetGrid === null) {
      handleError("insertCell error for child root");
    } else {
      const indexBefore =
        targetGrid.children.indexOf(targetCell) + insertionRule;
      targetGrid.children.splice(indexBefore, 0, cellAfterMove);
    }
  } else {
    // Root level
    const indexBefore = grid.indexOf(targetCell) + insertionRule;
    grid.splice(indexBefore, 0, cellAfterMove);
  }

  return grid;
}

/**
 * Generate an array of columns
 * @param parentId: ID of parent cell
 * @param columns: number of columns
 * @param level: nesting level of columns
 * @returns: generated array of columns
 */
export function generateColumnsList(
  parentId: CellId,
  columns: number = 1,
  level: NestingLevel,
  parentSizing = Sizing.ADAPTIVE
): ICell[] {
  const result = [] as ICell[];
  for (let i = 0; i < columns; i++) {
    const newCell: ICell = {
      cellId: generateId(),
      parentId,
      settings: {
        sizing: parentSizing,
        level,
        blockType: BlockType.COLUMN,
      },
      children: [],
    };
    result.push(newCell);
  }
  return result;
}

/**
 * Add given number of columns to the cell
 * @param grid: a grid tree to modify
 * @param cellId: target column id
 * @param columns: number of columns
 * @returns: original mutated tree
 */
export function addColumnsToCell(
  grid: ICell[],
  cellId: CellId,
  columns: number = 1,
  level?: NestingLevel
): ICell[] {
  const targetCell = findCell(grid, cellId);
  if (!targetCell) {
    handleError("addColumnsToCell");
    return grid;
  }
  const columnsList: ICell[] = generateColumnsList(
    targetCell.cellId,
    columns,
    level || (targetCell.settings.level as NestingLevel)
  );
  targetCell.children.push(...columnsList);
  autoSetColumnsWidth(targetCell);
  return grid;
}

/**
 * Delete given number of columns from the cell
 * @param grid: a grid tree to modify
 * @param cellId: target column id
 * @param columns: number of columns
 * @returns: original mutated tree
 */
export function deleteColumnsFromCell(
  grid: ICell[],
  cellId: CellId,
  columns: number = 1
): ICell[] {
  const targetCell = findCell(grid, cellId);
  if (!targetCell) {
    return grid;
  }
  targetCell.children.splice(0, columns);
  autoSetColumnsWidth(targetCell);
  return grid;
}

/**
 * Adds new cell with given params
 * @param grid: a grid tree to modify
 * @param parent: an object to define a parent of a new cell (if needed; otherwise root)
 * @param cellSettings.columns: number of columns
 * @param cellSettings.level: level of generated cell(parent or child)
 * @param cellSettings.indexToInsert: index where we want to insert new generated cell. Default is -1 to push the cell to the end of list
 * @returns: original mutated tree and added cell
 */
export function addCellToGrid(
  grid: ICell[],
  parent: IParentData,
  cellSettings: ICreateCellSettings
): { grid: ICell[]; newCell: ICell | null } {
  const { columns, level, indexToInsert = -1 } = cellSettings;
  /* 
    Current row we're working with. 
    It can be specific cell children or root level of grid without parentId
  */
  let parentCell: ICell | undefined = parent.parentCell;
  let currentDirectory = grid;
  /* Work with parent cell directly if it's available to avoid additional search in a grid tree */
  if (parent.parentCell) {
    currentDirectory = parent.parentCell.children;
  } else if (parent.parentId) {
    /* Otherwise, look for a parent by id */
    const cell = findCell(grid, parent.parentId);

    if (!cell) {
      handleError("addCellToGrid");

      return { grid, newCell: null };
    }
    parentCell = cell;
    currentDirectory = cell.children;
  }
  const parentSizing = parentCell
    ? parentCell.settings.sizing
    : parent.parentSizing;
  const parentWidth = parentCell
    ? parentCell.settings.sizing === Sizing.ADAPTIVE
      ? DEFAULT_ADAPTIVE_COL_WIDTH
      : parentCell.settings.width
    : parent.parentWidth;

  const newCell = createCell(
    columns,
    level,
    parent.parentId,
    parentSizing,
    parentWidth
  );

  if (indexToInsert > -1) {
    currentDirectory.splice(indexToInsert, 0, newCell);
  } else {
    currentDirectory.push(newCell);
  }

  return { grid, newCell };
}

/**
 * Move cell to root level of grid tree
 * @param grid: a grid tree to modify
 * @param cellId: target cell id
 * @param index: index to insert cell starting from 0.
 * @returns: original tree
 */
export function moveCellToRootLevel(
  grid: ICell[],
  cellId: CellId,
  index: number,
  minWidth: number
): ICell[] {
  const cell = findCell(grid, cellId);

  if (!cell) {
    handleError("moveCellToRootLevel");
    return grid;
  }

  const cellRootLevel = changeCellParams(cell, {
    level: NestingLevel.PARENT,
    width: getDefaultContainerWidth(cell.settings.sizing, minWidth),
  });

  autoSetColumnsWidth(cellRootLevel);
  grid = deleteCell(grid, cell.cellId, cell.parentId);

  grid.splice(index, 0, cellRootLevel);

  return grid;
}

/**
 * Check if cell has children with NestingLevel.CHILD
 * @param grid: a grid tree to check
 * @param cellId: target cell id
 * @returns: boolean
 */
export function hasChildNestingLevel(grid: ICell[], cellId: CellId): boolean {
  const cell = findCell(grid, cellId);

  if (!cell) {
    handleError("hasChildNestingLevel");
    return false;
  }

  if (!cell.children.length) {
    return false;
  }

  for (let i = 0; i < cell.children.length; i++) {
    const child = cell.children[i];

    if (child.settings.level === NestingLevel.CHILD) {
      return true;
    }

    const hasChildNesting = hasChildNestingLevel(grid, child.cellId);

    if (hasChildNesting) {
      return true;
    }
  }

  return false;
}

// Temporary helper
function handleError(functionName: string): void {
  console.warn(`Illegal operation in ${functionName}. Changes are not applied`);
}
