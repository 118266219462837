<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-color p-l-16 p-r-16 p-t-16 widget-control-container p-b-16"
  >
    <p
      class="wconfig-color__label"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>
    <div v-click-outside="hidePicker">
      <ConfigInput :stretch-input="true">
        <template #inputs>
          <a-input
            :value="currentColor?.color"
            class="wconfig-color__fill"
            placeholder="#ffffff"
            @mousedown="togglePicker"
            @update:value="handleColorInput($event)"
          >
            <template #prefix>
              <span
                v-if="!currentColor?.color"
                class="wconfig-color__icon wconfig-color__placeholder"
                @click="togglePicker"
              >
                <img :src="pickColorImage" />
              </span>

              <span
                v-else
                class="wconfig-color__current-color-block wconfig-color__placeholder"
                :style="'background-color: ' + currentColor?.color"
                @click="togglePicker"
              ></span>
            </template>
          </a-input>
          <a-input-number
            v-number
            :value="currentColor?.opacity"
            :min="0"
            :max="100"
            :controls="false"
            :formatter="(value: string) => `${value}%`"
            :parser="(value: string) => value.replace('%', '')"
            class="wconfig-color__opacity"
            size="small"
            placeholder="100%"
            @blur="handleOpacityInput($event)"
            @press-enter="handleOpacityEnterPress"
          />
        </template>

        <template #after>
          <div class="wconfig-color__controls">
            <span class="wconfig-color__input-btn">
              <CommonIcon name="ant-design:highlight-outlined" />
            </span>
            <span
              v-if="type !== ColorPickerType.TEXT"
              class="wconfig-color__input-btn"
            >
              <CommonIcon
                name="ant-design:delete-outlined"
                @click="clearColorInput"
              />
            </span>
          </div>
        </template>
      </ConfigInput>
      <Chrome
        v-if="displayPicker"
        :model-value="currentColor?.color || ''"
        class="wconfig-color__picker"
        @update:model-value="handleColorPickerUpdate($event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { Chrome } from "@ckpack/vue-color";

import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { IFillControl } from "~~/models/widgets/widget-controls.model";
import { debounce } from "~~/assets/utils";
import {
  DEFAULT_COLOR_VALUE,
  DEFAULT_OPACITY_VALUE,
} from "~~/constants/widget-config";
import pickColorImage from "~~/assets/images/pick-color.png";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue?: IFillControl;
    label?: string;
    isBold?: boolean;
    type?: ColorPickerType;
  }>(),
  {
    type: ColorPickerType.BACKGROUND,
    label: "Color",
    modelValue: () => ({
      color: "",
      opacity: 100,
    }),
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: IFillControl): void;
}>();

const innerColorValue = ref<string>("");
const displayPicker = ref<boolean>(false);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentColor = computed<IFillControl | undefined>(() => {
  return props.modelValue;
});

const getValueToUpdate = (
  key: keyof IFillControl | null,
  value: string | IFillControl
): IFillControl => {
  let body: IFillControl = {} as IFillControl;

  if (key) {
    body = {
      ...(currentColor.value || ({} as IFillControl)),
      [key as keyof IFillControl]: value,
    };
  } else if (value && typeof value === "object") {
    body = {
      ...value,
    };
  }

  return body;
};

const handleUpdate = (
  key: keyof IFillControl | null,
  value: string | IFillControl
) => {
  const body = getValueToUpdate(key, value);

  emit("update:modelValue", body);
};

const handleOpacityEnterPress = (event: Event): void => {
  const target = event.target as HTMLInputElement;
  target.blur();
};

const handleColorInput = (value: string): void => {
  handleUpdate("color", value);
};

const handleOpacityInput = (event: Event): void => {
  const target = event.target as HTMLInputElement;
  /*
    Fetch value from format "value%"
  */
  let value = parseInt(target.value);

  value = Math.min(value, 100);
  handleUpdate("opacity", String(value));
};

const handleColorPickerUpdate = debounce((value: { hex: string }) => {
  innerColorValue.value = value.hex.toUpperCase();
}, 5);

const hidePicker = (update = true): void => {
  if (!displayPicker.value) {
    return;
  }

  displayPicker.value = false;

  if (!update) {
    return;
  }

  handleUpdate("color", innerColorValue.value);
};

const togglePicker = (event: Event): void => {
  event.preventDefault();
  displayPicker.value = !displayPicker.value;
};

const clearColorInput = (): void => {
  let color = "";

  if (props.type === ColorPickerType.TEXT) {
    color = DEFAULT_COLOR_VALUE;
  }

  innerColorValue.value = color;
  handleUpdate(null, {
    color: innerColorValue.value,
    opacity: DEFAULT_OPACITY_VALUE,
  });

  hidePicker(false);
};

onMounted(() => {
  innerColorValue.value = currentColor.value?.color || "";
});
</script>

<style lang="scss">
.wconfig-color {
  &__label {
    margin-bottom: $space-xs;
  }

  &__opacity {
    &.ant-input-number {
      width: 100px;
      border-left: none;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  &__placeholder {
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }

  &__input-btn {
    @include flex(center, center, 0);

    width: 20px;
    height: 20px;
    color: $c-grey-45;
    cursor: pointer;
  }

  &__controls {
    @include flex(center, flex-start, 10px);
  }

  &__picker {
    position: relative;
    top: 15px;
    z-index: 22;
  }

  &__current-color-block,
  &__icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #000;
    border-radius: 4px;
    cursor: pointer;
  }

  &__icon {
    background-color: transparent;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.vc-editable-input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.vc-input__input {
  width: 80% !important;
}

.vc-input__label {
  margin-top: 0 !important;
}

.vc-input__desc {
  display: none;
}

.vc-chrome-active-color {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px !important;
}

.vc-checkerboard {
  background-image: none !important;
}

.vc-chrome-toggle-btn,
.vc-chrome-alpha-wrap {
  display: none;
}

.vc-chrome-controls {
  align-items: center;
}

.vc-chrome-hue-wrap {
  margin: 0 !important;
}
</style>
