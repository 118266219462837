<template>
  <div>
    <WidgetField
      v-if="fields.result_container.active"
      :widget="widget"
      :field="fields.result_container.field"
      class="w-100"
    >
      <div :style="resultContainerInlineStyles">
        <WidgetField
          v-if="fields.result_container_total_amounts.active"
          :widget="widget"
          :field="fields.result_container_total_amounts.field"
        >
          <div
            class="betslip-widget__total-amounts-container"
            :class="[
              `betslip-widget__total-amounts-container--${fields.result_container_total_amounts.field.options.position}`,
            ]"
            :style="totalAmountContainerInlineStyles"
          >
            <div class="betslip-widget__result-total-amounts-element">
              <span :style="totalAmountLabelInlineStyles">{{
                t("widgets.BetslipWidget.total_stake")
              }}</span>
              <span :style="totalAmountValueInlineStyles"> $ 0,00 </span>
            </div>
            <div class="betslip-widget__result-total-amounts-element">
              <span :style="totalAmountLabelInlineStyles">
                {{ t("widgets.BetslipWidget.potential_return") }}
              </span>
              <span :style="totalAmountValueInlineStyles"> $ 0,00 </span>
            </div>
          </div>
        </WidgetField>

        <div class="betslip-widget__result-buttons-container-field w-100">
          <WidgetField
            v-if="fields.bets_result_save_choice_button.active"
            :widget="widget"
            class="betslip-widget__result-button-field"
            :field="fields.bets_result_save_choice_button.field"
          >
            <BasicButtonField
              :field="fields.bets_result_save_choice_button.field"
              :current-state="states.bets_result_save_choice_button"
              :custom-value="t('widgets.BetslipWidget.save_choice')"
            />
          </WidgetField>
          <WidgetField
            v-if="fields.bets_result_continue_button.active"
            :widget="widget"
            class="betslip-widget__result-button-field"
            :field="fields.bets_result_continue_button.field"
          >
            <BasicButtonField
              :field="fields.bets_result_continue_button.field"
              :current-state="states.bets_result_continue_button"
              :custom-value="t('widgets.BetslipWidget.continue')"
            />
          </WidgetField>
        </div>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipBetsResultInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipBetsResultInlineStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  totalAmountValueInlineStyles,
  resultContainerInlineStyles,
  totalAmountContainerInlineStyles,
  totalAmountLabelInlineStyles,
} = useBetslipBetsResultInlineStyles(ref(props.fields));
</script>
