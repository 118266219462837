import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

export const PRIMARY_DESCRIPTION_FIELD_NAME = "description_1";

export const getDescriptionFields = (
  fields: IWidgetField[] = [],
  isMultiple: boolean = false
): IWidgetField[] => {
  if (isMultiple) {
    return fields.filter(field => {
      return field.name.includes("description_");
    });
  }

  const field = fields.find(field => {
    return field.name === PRIMARY_DESCRIPTION_FIELD_NAME;
  });

  return field ? [field] : [];
};

export const getPrimaryDescriptionField = (
  fields: IWidgetField[]
): IWidgetField | null => {
  if (fields.length === 1) {
    return fields[0];
  }

  const fieldName = PRIMARY_DESCRIPTION_FIELD_NAME;

  return (
    fields.find(f => {
      return f.name.includes(fieldName);
    }) || null
  );
};

export const getDescriptionFieldByIndex = (
  fields: IWidgetField[] = [],
  index: number,
  getFieldNameByIndex?: (editorIndex: number) => string
): IWidgetField | undefined => {
  const DEFAULT_FN = (index: number) => `description_${index}`;
  const getFieldName = getFieldNameByIndex || DEFAULT_FN;

  return fields.find(f => f.name === getFieldName(index));
};

/*
  Parses input content from description content input
  to description editor
*/
export const parseContentIn = (content: string): string => {
  return content
    .replaceAll("<p><br></p>", "<p></p>")
    .replaceAll("<div><br></div>", "<p></p>");
};

/*
  Parses output content from description editor
  to description content input
*/
export const parseContentOut = (content: string): string => {
  return content.replaceAll("<p></p>", "<p><br></p>");
};
