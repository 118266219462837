import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorFromHex,
  getDecorationValue,
} from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";

import { IFields } from "../../useWidgetFields";

import { getSpacing } from "./../../../../assets/utils/widget-settings/index";

export const useBetslipResultInlineStyles = (fields: ComputedRef<IFields>) => {
  const resultContainerInlineStyles = computed<ElementStyle>(() => {
    return {
      ...getCommonStyles(fields.value.result_container.field.options),
    };
  });

  const totalAmountContainerInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      gap: `${totalAmountContainerOptions.gap}px`,
      ...getSpacing(
        totalAmountContainerOptions.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  const totalAmountLabelInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      color: getColorFromHex(totalAmountContainerOptions.labelSettings.color)!,
      fontSize: `${totalAmountContainerOptions.labelSettings.theme.value}px`,
      ...getDecorationValue(
        totalAmountContainerOptions.labelSettings.decoration
      ),
    };
  });

  const totalAmountValueInlineStyles = computed<ElementStyle>(() => {
    const totalAmountContainerOptions =
      fields.value.result_container_total_amounts.field.options;
    return {
      color: getColorFromHex(totalAmountContainerOptions.valueSettings.color)!,
      fontSize: `${totalAmountContainerOptions.valueSettings.theme.value}px`,
      ...getDecorationValue(
        totalAmountContainerOptions.valueSettings.decoration
      ),
    };
  });

  const warningMessageContainerInlineStyles = computed<ElementStyle>(() => {
    const warningContainerOptions =
      fields.value.result_container_warning_message_title.field.options;
    return {
      ...getCommonStyles(warningContainerOptions),
      color: getColorFromHex(warningContainerOptions.color)!,
      fontSize: `${warningContainerOptions.theme.value}px`,
      fontFamily: warningContainerOptions.fontFamily,
      ...getSpacing(warningContainerOptions.margins, SpacingKeyName.MARGIN),
      ...getSpacing(warningContainerOptions.padding, SpacingKeyName.PADDING),
      ...getDecorationValue(warningContainerOptions.decoration),
      gap: `${warningContainerOptions.iconSettings.gap}px`,
    };
  });

  const warningIconInlineStyles = computed<ElementStyle>(() => {
    const warningIconOptions =
      fields.value.result_container_warning_message_title.field.options
        .iconSettings;
    return {
      color: getColorFromHex(warningIconOptions.textColor)!,
      fontSize: `${warningIconOptions.iconSize}px`,
    };
  });

  return {
    warningMessageContainerInlineStyles,
    warningIconInlineStyles,
    totalAmountValueInlineStyles,
    resultContainerInlineStyles,
    totalAmountContainerInlineStyles,
    totalAmountLabelInlineStyles,
  };
};
