import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultFillValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    title: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([], {
    fill: getDefaultFillValue("#ffffff"),
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "alignment"],
      values: {
        theme: getDefaultTheme(32),
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
