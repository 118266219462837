import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import {
  generateFlexAlignment,
  generateInvisibleScrollCssString,
} from "~~/assets/utils/widget-css/helpers";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateItemsGroupCssString,
  generateTitleCssString,
  generateWidgetCssString,
  itemsGroupClassNameGenerator,
} from "../compiler/items-css-compiler";
import { generateFlex, generateWidth } from "../helpers";
import { generateCssClassWithContent } from "../compiler/default-css-compiler";

const generateItemsCssString =
  (element: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const display = element.value[0].options.display;
    const widgetDisplay = widget.options.display;

    const elementContent = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: widgetDisplay?.wrap
        ? generateFlexAlignment(widgetDisplay?.alignment)
        : "flex-start",
      direction:
        display?.layout === DisplayOrientation.HORIZONTAL ? "row" : "column",
      gap: element.value[0].options.spaceBetween,
      wrap: widgetDisplay?.wrap ? "wrap" : "nowrap",
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementContent,
    });

    if (widgetDisplay?.resizing === ResizingType.FILL && widgetDisplay?.wrap) {
      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} > li`,
        content: "flex:1;",
      });
    }

    cssString += generateCssClassWithContent({
      className: element.options._rootCssClass,
      content: `overflow: auto;${generateWidth(100, "%")}`,
    });

    return cssString;
  };

export const generateItemsWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const title = widget.content["title"];
  const items = widget.content["items"];

  widget.options._cssClass = generateClassName("ItemsWidget");
  widget.options._contentCssClass = generateClassName("ItemsWidget_content");
  title.options._cssClass = generateClassName("title");
  items.options._cssClass = generateClassName("items");
  items.options._rootCssClass = generateClassName("items-root");
  items.value[0].options.itemsIcon._cssClass = generateClassName("itemsIcon");
  items.value[0].options.itemsText._cssClass = generateClassName("itemsText");
  itemsGroupClassNameGenerator(items);

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateTitleCssString(
      title,
      widget,
      title.options.link?.states !== undefined
    ),
    generateItemsCssString(items, widget),
    generateItemsGroupCssString(items.value[0], widget.options.display),
    generateInvisibleScrollCssString({
      _cssClass: items.options._rootCssClass,
    })
  )("");
};
