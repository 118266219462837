<template>
  <div class="base-grid-sidebar text-center">
    <slot />
    <div class="base-grid-sidebar__buttons-container">
      <slot name="buttons" />
    </div>
  </div>
</template>

<style lang="scss">
.base-grid-sidebar {
  @include flex-column(center, flex-start, $space-l);
  position: fixed;
  top: $header-height;
  left: 0;
  height: calc(100vh - $header-height);
  width: $settings-sidebar-width;
  background-color: $c-white;
  border-right: 1px solid $c-grey-15;
  transition: transform $base-transition;
  z-index: 1;
  padding: 36px $space-m;

  &__buttons-container {
    @include flex(center, flex-start);

    & > button {
      border-radius: 6px;
    }
  }
}
</style>
