import { typography } from "~~/constants/configs/text-common/typography-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../useWidgetFields";

export const useFormSuccessMessageConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const successMessageDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.success_message.field,
    });
  });

  const successMessageContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.success_message.field,
        className: "config-group-last-element",
        options: {
          placeholder: "Enter text",
          label: "Active",
          active: true,
        },
      },
      typography({ source: fields.value.success_message.field }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.iconSettings._active",
            valueSource: fields.value.success_message.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Icon",
              allowDisable: true,
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.iconSettings.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.success_message.field,
          },

          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.iconSettings.textColor",
            valueSource: fields.value.success_message.field,
            options: {
              placeholder: "Enter text",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },

          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSettings.iconSize",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.success_message.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  return {
    successMessageDesignControls,
    successMessageContentControls,
  };
};
