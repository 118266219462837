import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 52;

const v52WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "ItemsWidget" &&
    widget.options.display.wrap === "undefined"
  ) {
    widget.options.display.wrap = false;
  }

  return widget;
};
export default v52WidgetMigration;
