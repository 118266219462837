import { Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

type Args = {
  field: Ref<IWidgetField | null>;
  state?: Ref<string>;
};

export const useLinkStatesControls = ({ field, state }: Args, emit: any) => {
  const titleLinkSettingsValuePath = computed<string>(() => {
    if (!state || state.value === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${state.value}`;
  });

  const titleLinkStatesControls = computed(() => {
    if (!state) {
      return [];
    }

    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: state.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-title-link-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${titleLinkSettingsValuePath.value}.color`,
        valueSource: field.value,
        options: {
          label: "Text color",
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: `${titleLinkSettingsValuePath.value}.decoration`,
        valueSource: field.value,
        options: {
          label: "Decoration",
        },
      },
    ];
  });

  return { titleLinkStatesControls };
};
