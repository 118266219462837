import { LinkType } from "~~/models/widgets/widget-controls.model";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 20;

const updateHtmlEditorValue = (
  stringToUpdate: string,
  currentLocale: string,
  defaultLocale: string
) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(stringToUpdate, "text/html");

  const links = doc.querySelectorAll("a");

  links.forEach(link => {
    const href = link.getAttribute("href") || "";
    const type = link.getAttribute("data-type") || "";
    const authorizeHref = link.getAttribute("data-authorize-href") || "";
    const showAuthorizeValue =
      link.getAttribute("data-has-authorize") === "true";

    if (type === LinkType.PHONE && !href.startsWith("tel:")) {
      link.setAttribute("href", `tel:${href.replace("/", "")}`);
    }

    if (type === LinkType.EMAIL && !href.startsWith("mailto:")) {
      link.setAttribute("href", `mailto:${href.replace("/", "")}`);
    }

    if (
      type === LinkType.EXTERNAL &&
      currentLocale !== defaultLocale &&
      !href.startsWith("http") &&
      !href.startsWith(`/${currentLocale.toLocaleLowerCase()}`)
    ) {
      link.setAttribute("href", `/${currentLocale.toLocaleLowerCase()}${href}`);

      if (showAuthorizeValue) {
        link.setAttribute(
          "data-authorize-href",
          `/${currentLocale.toLocaleLowerCase()}${authorizeHref}`
        );
      }
    }

    if (
      type === LinkType.MODAL &&
      currentLocale !== defaultLocale &&
      !href.startsWith(`/${currentLocale.toLocaleLowerCase()}`)
    ) {
      link.setAttribute("href", `/${currentLocale.toLocaleLowerCase()}${href}`);

      if (showAuthorizeValue) {
        link.setAttribute(
          "data-authorize-href",
          `/${currentLocale.toLocaleLowerCase()}${authorizeHref}`
        );
      }
    }
  });

  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

const v20WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  Object.keys(widget.content).forEach(key => {
    if (widget.content[key].type === WIDGET_FIELD_TYPES.UNKNOWN) {
      return;
    }

    if (key.startsWith("description") || key.startsWith("html_editor_field")) {
      const defaultLocaleValue = widget.content[key].value["pt-BR"];
      const ptLocaleValue = widget.content[key].value["pt-PT"];
      widget.content[key].value["pt-BR"] = updateHtmlEditorValue(
        defaultLocaleValue,
        "pt-BR",
        "pt-BR"
      );
      widget.content[key].value["pt-PT"] = updateHtmlEditorValue(
        ptLocaleValue,
        "pt-PT",
        "pt-BR"
      );
    }
  });

  return widget;
};

export default v20WidgetMigration;
