import {
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 43;

const v43WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;

  if (["ComingSportEventsWidget"].includes(widget.name)) {
    if (!widget.content.event_cards.options.states?.hover) {
      widget.content.event_cards.options.states = {};
      widget.content.event_cards.options.states.hover = {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    }
  }

  return widget;
};
export default v43WidgetMigration;
