import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

const generatePromoPrimaryDescriptionField = (options: IWidgetOptions) => ({
  "name": "promo_primary_description",
  "type": "UnknownField",
  "value": null,
  "options": options,
});

const generatePromoSecondaryDescriptionField = (options: IWidgetOptions) => ({
  "name": "promo_secondary_description",
  "type": "UnknownField",
  "value": null,
  "options": options,
});

const WIDGET_VERSION = 67;

const v61WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["PromotionsListWidget"].includes(widget.name)) {
    if (
      !("promo_primary_description" in widget.content) &&
      !("promo_primary_description" in widget.content)
    ) {
      widget.content.promo_primary_description =
        generatePromoPrimaryDescriptionField(
          widget.content.promo_description.options
        );
      widget.content.promo_secondary_description =
        generatePromoSecondaryDescriptionField(
          widget.content.promo_description.options
        );
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v61WidgetMigration;
