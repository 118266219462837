<template>
  <div
    v-if="currentOption"
    class="widget-settings-dropdown"
  >
    <a-button
      v-if="historyStack.length > 1"
      shape="circle"
      class="widget-settings-content__icon"
      @click="widgetSettingsStore.selectPreviousHistoryItem()"
    >
      <template #icon>
        <CommonIcon name="ant-design:arrow-left-outlined" />
      </template>
    </a-button>

    <h3
      v-if="isSingleOption"
      :title="currentOption.label"
      class="heading widget-settings-dropdown__title"
    >
      <span class="widget-settings-dropdown__title-value">
        {{ currentOption.label }}
      </span>
    </h3>

    <a-dropdown
      v-else
      :trigger="['click']"
      overlay-class-name="widget-settings-dropdown__list"
    >
      <h3
        class="heading widget-settings-dropdown__title"
        :title="currentOption.label"
      >
        <span class="widget-settings-dropdown__title-value">
          {{ currentOption.label }}
        </span>
        <span>
          <CommonIcon name="ant-design:caret-down-outlined" />
        </span>
      </h3>
      <template #overlay>
        <a-menu>
          <a-menu-item
            v-for="(item, index) in items"
            :key="`${item.value}-${index}`"
            :disabled="item.disabled"
            :class="{
              'widget-settings-dropdown__item-selected': isOptionSelected(item),
            }"
            @click="$emit('update:model-value', item)"
          >
            <span>{{ item.label }}</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  modelValue: string | number | null;
  items: any[];
  customActiveOptionSelector?: (options: DropdownItem[]) => DropdownItem;
}>();

defineEmits<{
  (e: "update:model-value", event: DropdownItem): void;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { historyStack } = storeToRefs(widgetSettingsStore);

const isSingleOption = computed<boolean>(() => props.items.length === 1);

const currentOption = computed<any | undefined>(() => {
  if (props.customActiveOptionSelector) {
    return props.customActiveOptionSelector(props.items);
  }

  return props.items.find(option => option.value === props.modelValue);
});

const isOptionSelected = (option: DropdownItem): boolean => {
  if (!option.custom) {
    return option.value === currentOption.value;
  }

  return option.custom.fieldId === currentOption.value.custom?.fieldId;
};
</script>

<style lang="scss">
.widget-settings-dropdown {
  width: 100%;
  @include flex(center, flex-start, 10px);

  &__list {
    .ant-dropdown-menu-root {
      max-height: 500px;
      overflow-y: auto;
    }

    .ant-dropdown-menu-title-content {
      span {
        width: 180px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }

    .ant-dropdown-menu {
      padding: 4px;
    }

    .ant-dropdown-menu-item {
      border-radius: 4px;
      margin-bottom: 4px;
      padding: 10px 12px;

      span {
        line-height: 1;
      }

      &:hover,
      &.widget-settings-dropdown__item-selected {
        color: $c-primary-base;
        background-color: $c-light-blue;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    gap: 4px;

    &-value {
      display: inline-block;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }

    span {
      vertical-align: middle;
    }
  }
}
</style>
