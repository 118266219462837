<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookLiveFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      v-if="states.events_status === State.ACTIVE_EVENTS"
      class="sportsbook-live-widget-fenix"
      :style="sportsContainerStyles"
    >
      <div class="sportsbook-live-widget-sport">
        <WidgetField
          :widget="widget"
          :field="fields.sports_container.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.sports_container.field"
            :state="states.sports_container"
          >
            <SportsbookLiveSportsTitle
              :widget="widget"
              :field="fields.title.field"
              :button-field="fields.title_button.field"
              :hover-button-field="fields.title_hover_button.field"
              :state="states.title"
              :sports-state="states.sports_container"
              :button-field-state="states.title_button"
              :hover-button-field-state="states.title_hover_button"
            >
              <span>Sport</span>
            </SportsbookLiveSportsTitle>

            <div
              class="sportsbook-live-widget-sport-leagues__container"
              :style="sportLeaguesContainerStyles"
            >
              <div
                v-for="index in 2"
                :key="index"
                class="sportsbook-live-widget-sport-leagues"
              >
                <WidgetField
                  v-if="fields.leagues_titles.active"
                  :widget="widget"
                  :field="fields.leagues_titles.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.leagues_titles.field"
                  >
                    <BasicTitleField
                      :field="fields.leagues_titles.field"
                      :options="leaguesTitlesOptions"
                      value="League Name"
                    />
                  </BasicDesignWrapperField>
                </WidgetField>

                <WidgetField
                  v-if="fields.sport_events_container.active"
                  :widget="widget"
                  :field="fields.sport_events_container.field"
                >
                  <SportsbookLiveFenixEventCards
                    :widget="widget"
                    :field="fields.event_cards.field"
                    :state="states.event_cards"
                    :header-field="fields.event_header.field"
                    :cards="cardsSport[index - 1]"
                    :style="sportEventsContainerStyles"
                  >
                    <template #stream>
                      <WidgetField
                        v-if="fields.stream_icon.active"
                        :widget="widget"
                        :field="fields.stream_icon.field"
                      >
                        <CommonIcon
                          :name="fields.stream_icon.field.options.value"
                          :style="streamIconStyles"
                        />
                      </WidgetField>
                    </template>

                    <template #suspended>
                      <WidgetField
                        :widget="widget"
                        :field="fields.event_suspended_status.field"
                      >
                        <SportsBookLiveSuspended
                          :field="fields.event_suspended_status.field"
                          value="Status"
                        />
                      </WidgetField>
                    </template>

                    <template #time>
                      <WidgetField
                        :widget="widget"
                        :field="fields.event_time.field"
                      >
                        <SportsBookLiveTime
                          :field="fields.event_time.field"
                          :show-icon="
                            fields.event_time.field.options.icon._active
                          "
                        >
                          00:00
                        </SportsBookLiveTime>
                      </WidgetField>
                    </template>

                    <template #period>
                      <WidgetField
                        v-if="fields.event_period.active"
                        :widget="widget"
                        :field="fields.event_period.field"
                        :style="periodStyles"
                      >
                        <BasicTitleField
                          :field="fields.event_period.field"
                          value="Period"
                        />
                      </WidgetField>
                    </template>

                    <template #league>
                      <WidgetField
                        v-if="fields.event_league.active"
                        :widget="widget"
                        :field="fields.event_league.field"
                        :style="leagueStyles"
                      >
                        <BasicTitleField
                          :field="fields.event_league.field"
                          value="League Name"
                        />
                      </WidgetField>
                    </template>

                    <template #event_info>
                      <WidgetField
                        :widget="widget"
                        :field="fields.event_info.field"
                        style="width: 100%"
                      >
                        <SportsbookLiveEventInfo
                          :widget="widget"
                          :field="fields.event_info.field"
                          :team-player-field="fields.event_name.field"
                          :score-field="fields.event_score.field"
                          :event-card-field="fields.event_cards.field"
                          :event-card-state="states.event_cards"
                        />
                      </WidgetField>
                    </template>

                    <template #header_append>
                      <WidgetField
                        v-if="fields.favourites_button.active"
                        :widget="widget"
                        :field="fields.favourites_button.field"
                        :style="iconStyles"
                      >
                        <CommonIcon
                          :size="
                            fields.favourites_button.field.options.iconSize
                          "
                          :name="fields.favourites_button.field.options.value"
                        />
                      </WidgetField>
                    </template>

                    <template #bet_items="{ items }">
                      <WidgetField
                        :widget="widget"
                        :field="fields.bet_items.field"
                      >
                        <SportsbookLiveBetItems
                          :widget="widget"
                          :field="fields.bet_items.field"
                          :items="items"
                          :state="states.bet_items"
                        />
                      </WidgetField>
                    </template>

                    <template #action_button>
                      <div class="event-button">
                        <WidgetField
                          v-if="fields.event_button.active"
                          :widget="widget"
                          :field="fields.event_button.field"
                          :style="eventButtonStyles"
                        >
                          <BasicButtonField
                            :field="fields.event_button.field"
                            :current-state="states.event_button"
                          />
                        </WidgetField>
                      </div>
                    </template>
                  </SportsbookLiveFenixEventCards>
                </WidgetField>
              </div>
            </div>
          </BasicDesignWrapperField>
        </WidgetField>
      </div>
      <div class="sportsbook-live-widget-racing">
        <WidgetField
          :widget="widget"
          :field="fields.sports_container.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.sports_container.field"
            :state="states.sports_container"
          >
            <SportsbookLiveSportsTitle
              :widget="widget"
              :field="fields.title.field"
              :button-field="fields.title_button.field"
              :hover-button-field="fields.title_hover_button.field"
              :state="states.title"
              :sports-state="states.sports_container"
              :button-field-state="states.title_button"
              :hover-button-field-state="states.title_hover_button"
            >
              <span>Horses or greyhounds</span>
            </SportsbookLiveSportsTitle>

            <WidgetField
              v-if="fields.racing_events_container.active"
              :widget="widget"
              :field="fields.racing_events_container.field"
            >
              <BasicDesignWrapperField
                :widget="widget"
                :field="fields.racing_events_container.field"
              >
                <SportsbookLiveEventCards
                  :widget="widget"
                  :field="fields.racing_event_cards.field"
                  :suspended-field="fields.racing_event_status.field"
                  :container-field="fields.racing_events_container.field"
                  :cards="cardsSport[1]"
                  :event-cards-state="states.racing_event_cards"
                  :style="racingEventsContainerStyles"
                >
                  <template #stream>
                    <WidgetField
                      v-if="fields.racing_event_stream_icon.active"
                      :widget="widget"
                      :field="fields.racing_event_stream_icon.field"
                    >
                      <CommonIcon
                        :name="
                          fields.racing_event_stream_icon.field.options.value
                        "
                        :style="streamIconRacingStyles"
                      />
                    </WidgetField>
                  </template>

                  <template #event_name>
                    <WidgetField
                      :widget="widget"
                      :field="fields.racing_event_name.field"
                      style="flex-grow: 1"
                    >
                      <BasicTitleField
                        :field="fields.racing_event_name.field"
                        value="Venue - Race distance"
                        :options="racingEventNameOptions"
                      />
                    </WidgetField>
                  </template>

                  <template #suspended>
                    <WidgetField
                      :widget="widget"
                      :field="fields.racing_event_status.field"
                    >
                      <SportsBookLiveSuspended
                        :field="fields.racing_event_status.field"
                        value="Status"
                      />
                    </WidgetField>
                  </template>

                  <template #header_append>
                    <WidgetField
                      v-if="fields.racing_event_favourites_button.active"
                      :widget="widget"
                      :field="fields.racing_event_favourites_button.field"
                      :style="iconRacingStyles"
                    >
                      <CommonIcon
                        :size="
                          fields.racing_event_favourites_button.field.options
                            .iconSize
                        "
                        :name="
                          fields.racing_event_favourites_button.field.options
                            .value
                        "
                      />
                    </WidgetField>
                  </template>

                  <template #action_button>
                    <div class="event-button">
                      <WidgetField
                        v-if="fields.racing_event_button.active"
                        :widget="widget"
                        :field="fields.racing_event_button.field"
                        :style="racingEventButtonStyles"
                      >
                        <BasicButtonField
                          :field="fields.racing_event_button.field"
                          :current-state="states.racing_event_button"
                        />
                      </WidgetField>
                    </div>
                  </template>
                </SportsbookLiveEventCards>
              </BasicDesignWrapperField>
            </WidgetField>
          </BasicDesignWrapperField>
        </WidgetField>
      </div>
    </div>
    <template v-else>
      <BasicTitleField
        :field="fields.no_events_title.field"
        :state="states.no_events_title"
      />
    </template>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useSportsbookLiveFenixInitData } from "~~/composables/widgets/sportbook-live/fenix/useSportsbookLiveFenixInitData";
import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import { useStates } from "~~/composables/widgets/common/useStates";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const cardsSport = computed(() => {
  return [
    [
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
    ],
    [
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
    ],
  ];
});

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useSportsbookLiveFenixInitData(
  props.widget
);

const sportsContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.sports_container.field?.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distanceBetweenSports),
  };
});

const racingEventNameOptions = computed<IWidgetOptions>(() => {
  const settings = fields.value.racing_event_cards.field.options;
  const settingsName = fields.value.racing_event_name.field.options;
  if (!settings || !settingsName) {
    return {};
  }

  let textColor: Record<string, string> = {};
  if (
    fields.value.racing_event_cards.field.options.color &&
    states.value.racing_event_cards
  ) {
    textColor =
      settings.states[states.value.racing_event_cards]?.color ??
      settingsName.color;
  }
  return {
    ...settingsName,
    color: textColor,
  };
});

const streamIconStyles = computed<ElementStyle>(() => {
  const settings = fields.value.stream_icon.field?.options;

  if (!settings) {
    return {};
  }
  return {
    ...getColorStyle(settings.textColor),
    ...getFontSizeStyle(settings.iconSize),
  };
});

const streamIconRacingStyles = computed<ElementStyle>(() => {
  const settings = fields.value.racing_event_stream_icon.field?.options;

  if (!settings) {
    return {};
  }
  return {
    ...getColorStyle(settings.textColor),
    ...getFontSizeStyle(settings.iconSize),
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(fields.value.favourites_button.field.options.textColor),
    zIndex: 1,
  };
});
const iconRacingStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(
      fields.value.racing_event_favourites_button.field.options.textColor
    ),
    zIndex: 1,
  };
});

const periodStyles = computed<ElementStyle>(() => {
  const settings = fields.value.event_period.field.options;
  return {
    flexGrow: settings.resizing === ResizingType.FILL ? "1" : "initial",
  };
});

const leagueStyles = computed<ElementStyle>(() => {
  const settings = fields.value.event_league.field.options;
  if (!settings) {
    return {};
  }
  const headerSettings = fields.value.event_header.field.options;
  const isFill = settings.resizing === ResizingType.FILL;
  const leaguePositionByLayout =
    headerSettings.leaguePosition[headerSettings.contentLayout];
  const orderFirst =
    leaguePositionByLayout === VerticalPosition.TOP ||
    leaguePositionByLayout === Alignment.LEFT;
  return {
    width:
      headerSettings.contentLayout === DisplayOrientation.VERTICAL
        ? "100%"
        : "auto",
    flexGrow: isFill ? "1" : "initial",
    order: orderFirst ? "0" : "1",
  };
});

const racingEventsContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.racing_events_container.field?.options;

  if (!settings) {
    return {};
  }

  return {
    ...getDisplayGridStyles(settings.gridSettings),
  };
});

const sportEventsContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.sport_events_container.field?.options;

  if (!settings) {
    return {};
  }
  return {
    ...getDisplayGridStyles(settings.gridSettings),
    ...getCommonStyles(settings),
  };
});

const sportLeaguesContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.sports_container.field?.options;

  if (!settings) {
    return {};
  }
  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distanceBetweenLeagueEvents),
  };
});

const eventButtonStyles = computed<ElementStyle>(() => {
  const settings = fields.value.event_button.field?.options;

  if (!settings) {
    return {};
  }
  const isFill = settings.buttonDisplaySettings.resizing === ResizingType.FILL;

  return {
    flexGrow: isFill ? 1 : 0,
  };
});

const racingEventButtonStyles = computed<ElementStyle>(() => {
  const settings = fields.value.racing_event_button.field?.options;

  if (!settings) {
    return {};
  }
  const isFill = settings.buttonDisplaySettings.resizing === ResizingType.FILL;

  return {
    flexGrow: isFill ? 1 : 0,
  };
});

const leaguesTitlesOptions = computed<IWidgetOptions>(() => {
  const settings = fields.value.leagues_titles.field?.options;

  if (!settings) {
    return {};
  }

  return {
    ...settings,
    padding: null,
  };
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["events_status"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

initFields();
</script>

<style lang="scss" scoped>
.sportsbook-live-widget-fenix {
  .racing__header,
  .event-button {
    display: flex;
  }
}
</style>
