<template>
  <div class="racing-sports-table">
    <div v-if="field.options.mode === 'rows' || field.options.mode === 'table'">
      <RacingSportsCards
        :widget="widget"
        :table-header="TABLE_HEADER"
        :rows="TABLE_CONTENT"
        :field="field"
        :header-field="headerField"
        :rows-field="cardField"
        :mode="field.options.mode"
        :states="states"
      >
        <template #players_info_player_number="{ value }">
          <slot
            name="players_info_player_number"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_name="{ value }">
          <slot
            name="players_info_player_name"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_last_score="{ value }">
          <slot
            name="players_info_player_last_score"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_prev_odds="{ value }">
          <slot
            name="players_info_player_prev_odds"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_current_odds="{ value }">
          <slot
            name="players_info_player_current_odds"
            :value="value"
          ></slot>
        </template>
      </RacingSportsCards>
    </div>

    <div v-else>
      <RacingSportsCards
        :widget="widget"
        :table-header="TABLE_HEADER"
        :rows="TABLE_CONTENT"
        :field="field"
        :header-field="headerField"
        :rows-field="cardField"
        :states="states"
        mode="wrap"
        :header-titles="[
          'players_info_player_name',
          'players_info_player_current_odds',
        ]"
        :sections="{
          players_info_player_name: [
            'players_info_player_number',
            'players_info_player_name',
            'players_info_player_last_score',
          ],
          players_info_player_current_odds: [
            'players_info_player_current_odds',
            'players_info_player_prev_odds',
          ],
        }"
      >
        <template #players_info_player_number="{ value }">
          <slot
            name="players_info_player_number"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_name="{ value }">
          <slot
            name="players_info_player_name"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_last_score="{ value }">
          <slot
            name="players_info_player_last_score"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_prev_odds="{ value }">
          <slot
            name="players_info_player_prev_odds"
            :value="value"
          ></slot>
        </template>

        <template #players_info_player_current_odds="{ value }">
          <slot
            name="players_info_player_current_odds"
            :value="value"
          ></slot>
        </template>
      </RacingSportsCards>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";

defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  states: Record<string, State>;
  cardField: IWidgetField;
  headerField: IWidgetField;
  numberField: IWidgetField;
  nameField: IWidgetField;
  lastRacesField: IWidgetField;
  prevOddsField: IWidgetField;
  oddsField: IWidgetField;
}>();

const TABLE_HEADER = ref([
  { label: "№", name: "players_info_player_number", width: 52 },
  { label: "Horse or greyhound", name: "players_info_player_name" },
  { label: "Last 5 races", name: "players_info_player_last_score", width: 132 },
  { label: "Previous odds", name: "players_info_player_prev_odds", width: 132 },
  { label: "Odds", name: "players_info_player_current_odds", width: 132 },
]);

const TABLE_CONTENT = ref([
  {
    players_info_player_name: "Greyhound name",
    players_info_player_last_score: "1-2-3-4-5",
    players_info_player_prev_odds: "+4.00 +3.50",
    players_info_player_current_odds: "+000",
    players_info_player_number: 1,
  },
  {
    players_info_player_name: "Greyhound name",
    players_info_player_last_score: "1-2-3-4-5",
    players_info_player_prev_odds: "+4.00 +3.50",
    players_info_player_current_odds: "+000",
    players_info_player_number: 2,
  },
  {
    players_info_player_name: "Greyhound name",
    players_info_player_last_score: "1-2-3-4-5",
    players_info_player_prev_odds: "+4.00 +3.50",
    players_info_player_current_odds: "+000",
    players_info_player_number: 3,
  },
  {
    players_info_player_name: "Greyhound name",
    players_info_player_last_score: "1-2-3-4-5",
    players_info_player_prev_odds: "+4.00 +3.50",
    players_info_player_current_odds: "+000",
    players_info_player_number: 4,
  },
  {
    players_info_player_name: "Greyhound name",
    players_info_player_last_score: "1-2-3-4-5",
    players_info_player_prev_odds: "+4.00 +3.50",
    players_info_player_current_odds: "+000",
    players_info_player_number: 5,
  },
]);
</script>
