import { defineStore } from "pinia";
import axios from "axios";

import { IMetaStore } from "~~/models/stores/meta-store.model";
import { CONFIG_APP_BASE_API_URL } from "~~/constants";

const UPDATE_GLOBAL_STATUS_FREAQUENCY = 10000;

export const useMetaStore = defineStore("meta", {
  state: (): IMetaStore => ({
    projectId: null,
    interfaceLang: null,
    globalProcessIsRunning: false,
    workingIntervalId: null,
  }),
  getters: {
    httpConfig({ projectId }) {
      return {
        baseURL: CONFIG_APP_BASE_API_URL,
        timeout: 120000, // 20000, // Temporary solution to avoid timeout
        withCredentials: false,
        headers: {
          "X-Project-Id": projectId,
        },
      };
    },
  },
  actions: {
    setGlobalProcessState(value: boolean): void {
      this.globalProcessIsRunning = value;
    },
    runUpdateWorkingStatusInterval(): void {
      this.workingIntervalId = setInterval(() => {
        this.updateGlobalStatus(true);
      }, UPDATE_GLOBAL_STATUS_FREAQUENCY);
    },
    stopWorkingStatusInterval(): void {
      clearInterval(this.workingIntervalId!);
    },
    updateGlobalStatus(status: boolean): Promise<any> {
      return axios.post(
        `/global-changes/status`,
        {
          status: status,
        },
        this.httpConfig
      );
    },
    fetchGlobalStatus(): Promise<boolean> {
      return axios
        .get(`/global-changes/status`, this.httpConfig)
        .then((response: { data: { status: boolean } }) => {
          return response.data.status;
        })
        .catch(err => {
          console.error(err);
          return false;
        });
    },
    setProjectId(projectId: string) {
      this.projectId = projectId;
    },
    setInterfaceLang(interfaceLang: string) {
      this.interfaceLang = interfaceLang;
    },
  },
});
