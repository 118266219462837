import {
  ICustomField,
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  ResizingType,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";

import {
  generateFlex,
  generateFontSize,
  generateTextAlignStyle,
  generateTextColor,
  generateFlexAlignment,
  generateWidth,
  generateSize,
} from "../helpers";
import { generateClassName } from "../utils/generate-class-name";

import {
  generateDefaultStylesWithStates,
  generateDefaultStyles,
  generateCssClassWithContent,
} from "./default-css-compiler";
// Since this logic repeats for DropdownWidget and ItemsWidget it's here.
export const generateWidgetCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const isDisplayFill =
      element.options.display?.resizing === ResizingType.FILL;

    const widgetAlignItems = isDisplayFill
      ? "stretch"
      : generateFlexAlignment(element.options.display?.alignment);

    const widgetCssContent = generateDefaultStyles(element.options);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: widgetCssContent,
    });

    cssString += generateCssClassWithContent({
      className: element.options._contentCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: widgetAlignItems,
          justify: "center",
          direction: "column",
        }) + "height: 100%;",
    });

    return cssString;
  };

export const generateItemsGroupCssString =
  (itemsGroup: ICustomField, displayOptions?: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = itemsGroup;
    const iconCssStringContent =
      generateTextColor(options.itemsIcon?.textColor) +
      generateFontSize(options.itemsIcon?.iconSize);

    const isElementsDisplayVertical =
      options.display?.elementsLayout === DisplayOrientation.VERTICAL;
    // const isReverse =
    //   options.itemsIcon?.position === TextPosition.LEFT ? "-reverse" : "";
    const isDisplayFill = displayOptions?.resizing === ResizingType.FILL;
    const itemsGroupAlignment = isDisplayFill
      ? displayOptions?.alignment
      : "flex-start";
    const isReversed = options.itemsIcon?.position === TextPosition.LEFT;

    const itemsGroupWidth = isDisplayFill ? `${generateWidth(100, "%")}` : "";

    cssString += generateDefaultStylesWithStates(itemsGroup, false);
    cssString += `.${itemsGroup.options._cssClass}{${generateFlex({
      flex: "flex",
      align: "center",
      justify: itemsGroupAlignment,
      direction: isElementsDisplayVertical ? `column` : `row`,

      // direction: isElementsDisplayVertical
      //   ? `column${isReverse}`
      //   : `row${isReverse}`,
      gap: options.itemsIcon?.gap,
    })}${generateSize(options.size)};${itemsGroupWidth}}`;

    cssString += generateCssClassWithContent({
      className: itemsGroup.fields?.[0].options._cssClass || options._cssClass,
      content: options.itemsText,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.itemsIcon?._cssClass,
      content: iconCssStringContent,
    });

    if (isReversed) {
      cssString += generateCssClassWithContent({
        className: options.itemsIcon?._cssClass,
        content: "order: -1;",
      });
    }

    for (const state in itemsGroup.options.states) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.itemsIcon?._cssClass,
        content: options.states[state].itemsIcon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].itemsText,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    /*
      Styles for active route
    */

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}.router-link-active`,
      content: options.states.active,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}.router-link-active`,
      childClassName: options.itemsIcon?._cssClass,
      content: options.states.active.itemsIcon?.textColor,
      customFunction: generateTextColor,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}.router-link-active`,
      content: options.states.active.itemsText,
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

export const itemsGroupClassNameGenerator = (
  items: IWidgetOptions
): IWidgetOptions => {
  const itemsGroupClassName = generateClassName("itemsGroup");
  items.value[0].options._cssClass = itemsGroupClassName;
  items.value[0].fields.forEach((item: IWidgetField) => {
    item.options._cssClass = itemsGroupClassName;
  });

  return items;
};

export const generateTitleCssString =
  (element: IWidgetOptions, widget: IWidgetOptions, isLinkStates: boolean) =>
  (cssString: string): string => {
    const { options } = element;
    const isDisplayFill = widget.options.display.resizing === ResizingType.FILL;

    const alignment = isDisplayFill
      ? widget.options.display.alignment
      : "unset";

    const titleCssContent =
      generateTextAlignStyle(alignment) +
      generateDefaultStylesWithStates(element, isLinkStates);

    cssString += generateDefaultStylesWithStates(element, isLinkStates);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: titleCssContent,
    });

    return cssString;
  };
