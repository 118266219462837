<template>
  <div
    v-if="hasContentPermissions"
    class="widget-control-container config-text-input-with-settings"
  >
    <div>
      <span class="config-text-input-with-settings__item p-b-16">
        <p class="config-text-input-with-settings__el heading">{{ label }}</p>

        <CommonIcon
          name="ant-design:setting-outlined"
          class="config-text-input-with-settings__icon pointer"
          @click="handleClick()"
        />
      </span>
    </div>
    <div class="p-b-16">
      <a-textarea
        show-count
        :value="getLocalizedValue(modelValue)"
        :maxlength="max || MAX_TEXT"
        class="config-text-content__input f-base"
        :placeholder="placeholder"
        @update:value="handleInput(modelValue, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MAX_TEXT } from "~~/constants/input-validation";
import { useTextInputConfig } from "~~/components/composables/widgets/useTextInputConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue?: string | undefined | null;
  placeholder: string | undefined;
  max?: number;
  label?: string;
  itemName: string;
}>();

const { getLocalizedValue, transformToLocalizedValue } = useLocalizedValue();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { handleInput } = useTextInputConfig(props, emit);

const handleClick = (): void => {
  if (!selectedWidget.value) {
    return;
  }

  const field = selectedWidget.value.fields.find(
    field => field.name === props.itemName
  );

  if (!field) {
    return;
  }

  widgetSettingsStore.setActiveElement(selectedWidget.value, field);
};

onBeforeMount(() => {
  const { localized, localizedValue } = transformToLocalizedValue(
    props.modelValue
  );

  if (localized) {
    emit("update:modelValue", localizedValue);
  }
});
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.config-text-input-with-settings {
  @include widgets-content-input-container;

  &__icon {
    margin-left: auto;
    color: $c-grey-45;
    font-size: 14px;
  }

  &__el {
    font-size: 14px;
  }

  &__item {
    @include flex(center, flex-start, 0);

    &:not(:last-child) {
      border-bottom: 1px solid $c-grey-15;
    }
  }
}
</style>
