<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <FavoritesEventsLiveFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="favorites-events-live-widget">
      <WidgetField
        :widget="widget"
        :field="fields.heading.field"
      >
        <DropdownTriggerField
          :value="fields.heading.field?.value"
          :field="fields.heading.field"
          :widget="widget"
          :state="states['heading']"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.event_container.field.options._active"
        :widget="widget"
        :field="fields.event_container.field"
        class="sports-menu-live-fenix-widget__field-menu-3"
        :style="eventsContainerStyles"
      >
        <template v-if="states.event_container === State.ACTIVE_EVENTS">
          <SportsMenuLiveFenixEventCardsList
            v-slot="{ card }"
            :widget="widget"
            :container-field="fields.event_container.field"
            :card-field="fields.event_card.field"
            :card-state="states['event_card']"
            :data="sportCards"
          >
            <SportsMenuliveFenixEventSportCard
              :data="card"
              :widget="widget"
              :state="states['event_card']"
              :header-field="fields.header.field"
              :footer-field="fields.footer.field"
              :card-field="fields.event_card.field"
              :favorite-icon-field="fields.favourites_button.field"
              :league-name-field="fields.event_league.field"
              :event-status-field="fields.event_suspended_status.field"
              :stream-icon-field="fields.stream_icon.field"
              :event-name-field="fields.event_team_player.field"
              :event-time-field="fields.event_time.field"
              :event-period-field="fields.event_period.field"
              :event-score-field="fields.event_score.field"
              icon-name="ant-design:star-filled"
            />
          </SportsMenuLiveFenixEventCardsList>
          <!-- Racing events -->
          <SportsMenuLiveFenixEventCardsList
            v-slot="{ card }"
            :widget="widget"
            :container-field="fields.event_container.field"
            :card-field="fields.racing_event_card.field"
            :card-state="states['racing_event_card']"
            :data="racingCards"
          >
            <SportsMenuliveFenixEventRacingCard
              :data="card"
              :widget="widget"
              :card-field="fields.racing_event_card.field"
              :favorite-icon-field="fields.racing_favourites_icon.field"
              :event-status-field="fields.racing_event_status.field"
              :stream-icon-field="fields.racing_stream_icon.field"
              :event-name-field="fields.racing_event_name.field"
              :state="states['racing_event_card']"
              icon-name="ant-design:star-filled"
            />
          </SportsMenuLiveFenixEventCardsList>
        </template>

        <template v-else>
          <BasicTitleField :field="fields.no_event_title.field" />
        </template>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script setup lang="ts">
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useFavoritesEventsLiveFenixWidgetInitData } from "~~/composables/widgets/favorites-events-live-fenix/useFavoritesEventsLiveFenixWidgetInitData";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useFavoritesEventsLiveFenixWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const sportCards = [
  {
    leagueName: "League Name",
    status: "Status",
    eventName: "Team / Player",
    teamPlayer1: "Team / Player #1",
    teamPlayer2: "Team / Player #2",
    time: "00:00",
    period: "Period",
    score1: 0,
    score2: 0,
  },
  {
    leagueName: "League Name",
    status: "Status",
    eventName: "Team / Player",
    teamPlayer1: "Team / Player #1",
    teamPlayer2: "Team / Player #2",
    time: "00:00",
    period: "Period",
    score1: 0,
    score2: 0,
  },
];

const racingCards = [
  {
    status: "Status",
    eventName: "Venue — Race distance",
  },
  {
    status: "Status",
    eventName: "Venue — Race distance",
  },
];

const eventsContainerStyles = computed<ElementStyle>(() => {
  const settings = fields.value.event_container.field.options;

  if (!settings) {
    return {};
  }

  return {
    ...getCommonStyles(settings),
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

initFields();
</script>

<style lang="scss">
.favorites-events-live-widget {
  &__btn {
    margin-left: auto;
  }
}
</style>
