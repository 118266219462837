import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();

export function handleActionSuccess(
  text: string,
  successCallback?: () => void
): void {
  notification.notify({
    text,
    type: "success",
  });

  if (successCallback) successCallback();
}
