<template>
  <div
    v-if="hasContentPermissions"
    class="wconfig-image"
  >
    <ConfigToggle
      v-if="hideLabel"
      size="small"
      :model-value="active"
      :label="'Icon'"
      :allow-disable="true"
      :show-options="true"
      @update:model-value="handleActive"
      @options-click="handleFieldOptionsClick"
    />

    <div
      v-if="active"
      class="p-b-16"
    >
      <ConfigImageUploader
        :value="getLocalizedValue(currentFieldValue)"
        :upload-function="handleImageUpload"
        :field-name="fieldName"
        :meta="{ isContentImage }"
        @update:value="handleValueUpdate"
      />

      <a-button
        v-if="!isDefaultLanguage && defaultLanguage"
        class="copy-value-button"
        @click="useValueFromDefaultLanguage"
      >
        Use picture from {{ defaultLanguage?.name }}
      </a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTextInputConfig } from "~~/components/composables/widgets/useTextInputConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { handleActionError } from "~~/helpers/handle-action-error";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import {
  FILE_UPLOADING_ERROR,
  FILE_UPLOADING_SUCCESS,
} from "~~/constants/notification-messages";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: string | undefined | null;
    fieldName?: string;
    active?: boolean;
    label?: string;
    hideLabel?: boolean;
    isValueOption?: boolean;
    isContentImage?: boolean;
    attribute?: string;
    dependentFields?: IWidgetField[];
  }>(),
  {
    fieldName: "",
    label: "",
    isContentImage: true,
    attribute: "",
    dependentFields: () => [],
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: Record<string, string> | undefined): void;
  (e: "update:active", event: boolean): void;
  (e: "update:field-value", event: Record<string, string> | undefined): void;
}>();

const {
  setLocalizedValue,
  getLocalizedValue,
  isDefaultLanguage,
  defaultLanguage,
  getLocalizedValueFromDefaultLanguage,
} = useLocalizedValue();

const currentFieldValue = computed(() =>
  props.isValueOption ? selectedField?.value : props.modelValue
);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);

const { handleFieldOptionsClick } = useTextInputConfig(props, emit);

const handleValueUpdate = (data: string | undefined) => {
  const localizedValue = setLocalizedValue.value(
    props.modelValue as unknown as Record<string, string>,
    data
  );

  if (props.isValueOption) {
    emit("update:field-value", localizedValue);
    return;
  }

  emit("update:modelValue", localizedValue);
};

function useValueFromDefaultLanguage() {
  const value = getLocalizedValueFromDefaultLanguage.value(
    currentFieldValue.value
  );

  handleValueUpdate(value);
}

const handleImageUpload = (file: Blob): Promise<string> => {
  const widgetId = selectedWidget.value?.static_id;

  if (!widgetId) {
    handleActionError(FILE_UPLOADING_ERROR);
    throw new Error();
  }

  return widgetsStore
    .contentImageUpload({
      file,
      widgetId: String(widgetId),
      attribute: props.attribute || "image",
    })
    .then(resp => {
      handleActionSuccess(FILE_UPLOADING_SUCCESS);
      return resp.data.path;
    })
    .catch(error => {
      handleActionError(FILE_UPLOADING_ERROR, error);
      throw new Error();
    });
};

const handleActive = (value: boolean): void => {
  emit("update:active", value);
};
</script>

<style lang="scss" scoped>
.copy-value-button {
  margin: 16px 16px 0;
  height: 100%;
  max-width: 240px;
  white-space: pre-wrap;
}
</style>
