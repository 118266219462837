import { ref, Ref } from "vue";

interface IUseInput {
  value: Ref<string>;
  valueInputRef: Ref<HTMLInputElement | null>;
  focusInput: () => void;
}

export default function useInput(): IUseInput {
  const value = ref("");
  const valueInputRef = ref<HTMLInputElement | null>(null);

  const focusInput = () => {
    valueInputRef.value?.focus();
  };

  return {
    value,
    valueInputRef,
    focusInput,
  };
}
