<template>
  <div class="profile-dropdown-balance-item">
    <CommonIcon
      v-if="field.options.icon._active"
      :style="iconStyle"
      :name="iconName"
    />
    <span
      v-if="field.options.text._active"
      :style="secondaryTextStyle"
    >
      {{ getLocalizedValue(field.value) }}
    </span>

    <span :style="primaryTextStyle"> 0,00 USD</span>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  getColorValue,
  getDecorationValue,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  field: IWidgetField;
  wrapperField: IWidgetField;
  state: State;
}>();

const { getLocalizedValue } = useLocalizedValue();

const activeStateOptions = computed<IWidgetOptions>(() =>
  isDefaultState.value
    ? props.wrapperField.options
    : props.wrapperField.options.states[props.state]
);

const isDefaultState = computed<boolean>(() => props.state === State.DEFAULT);

const iconName = computed<string>(() => {
  if (props.field.options.icon) {
    return (
      props.field.options.icon?.value ?? props.field.options.icon?.default_value
    );
  }

  return props.field.name === FieldName.BONUS
    ? "ant-design:gift-filled"
    : "ant-design:dollar-circle-filled";
});

const fontFamilyStyle = computed<CSSProperties>(() => ({
  fontFamily: props.wrapperField.options.fontFamily,
}));

const getTextStyle = (optionsKey: string): CSSProperties => ({
  ...fontFamilyStyle.value,
  ...getFontSizeStyle(props.wrapperField.options[optionsKey].theme),
  color: getColorValue(activeStateOptions.value[optionsKey].color),
  ...getDecorationValue(activeStateOptions.value[optionsKey].decoration),
});

const secondaryTextStyle = computed<CSSProperties>(() => getTextStyle("text"));

const primaryTextStyle = computed<CSSProperties>(() =>
  getTextStyle("primaryText")
);

const iconStyle = computed<CSSProperties>(() => {
  return {
    fontSize: getPxValueFromNumber(props.wrapperField.options.icon.size),
    color: getColorValue(activeStateOptions.value.icon.color),
  };
});
</script>

<style lang="scss">
.profile-dropdown-balance-item {
  display: flex;
  gap: 4px;
  align-items: center;
}
</style>
