import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateItemsGroupCssString,
  itemsGroupClassNameGenerator,
} from "../compiler/items-css-compiler";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateDecorationStyle,
  generateFlex,
  generateFlexAlignment,
  generateFontSize,
  generateGap,
  generateTextColor,
} from "../helpers";
import { generateStageButtonCssString } from "../utils/form-helper-functions";

import { generateTitleCssString } from "./dropdown-widget-css";

export const generateWidgetCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const displaySettings = element.options.profileDropdownDisplay;

    const widgetCssContent =
      generateDefaultStyles(element.options) +
      generateFlex({
        flex: "flex",
        justify: generateFlexAlignment(displaySettings.alignment),
        align:
          displaySettings.layout === DisplayOrientation.HORIZONTAL
            ? "start"
            : generateFlexAlignment(displaySettings.alignment),
        direction:
          displaySettings.layout === DisplayOrientation.HORIZONTAL
            ? "row"
            : "column",
        gap: displaySettings.distanceBetweenItems,
      });

    cssString += generateCssClassWithContent({
      className: element.options._contentCssClass,
      content: widgetCssContent,
    });

    return cssString;
  };

const generateProfileDropdownMenuCssString =
  (
    element: IWidgetOptions,
    displayOptions: IWidgetOptions,
    widgetCssClass: string
  ) =>
  (cssString: string): string => {
    cssString += `.${element.options._cssClass} {${generateDefaultStyles(
      element.options
    )}}`;
    cssString += `.${
      element.options._cssClass
    } {.dropdown-element__container-override {${generateGap(
      element.value[0].options.spaceBetween
    )} align-items:${generateFlexAlignment(displayOptions.alignment)};}}`;

    cssString += `.${widgetCssClass} .dropdown-trigger-title-override{${
      displayOptions.resizing === ResizingType.FILL
        ? `width:100%; text-align:${displayOptions.alignment};`
        : ""
    }}`;
    return cssString;
  };

const generateBalanceContainerCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += `.${
      element.options._containerCssClass
    } {${generateDefaultStyles(element.options)}${generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      gap: element.options.gap,
      direction:
        element.options.layout === DisplayOrientation.VERTICAL
          ? "column"
          : "row",
    })}}`;
    return cssString;
  };

export const generateBalanceItemsCssString =
  (element: IWidgetOptions, states = true) =>
  (cssString: string): string => {
    const iconCssStringContent =
      generateTextColor(element.options.icon.color) +
      generateFontSize(element.options.icon.size);

    const generateTextStringContent = (
      options: IWidgetOptions,
      isStatesTab = false
    ) => {
      let res =
        generateDecorationStyle(options.decoration) +
        generateTextColor(options.color);

      if (!isStatesTab) res += generateFontSize(options.theme);

      return res;
    };

    cssString += generateCssClassWithContent({
      className: element.options.text._cssClass,
      content: generateTextStringContent(element.options.text),
    });

    cssString += generateCssClassWithContent({
      className: element.options.primaryText._cssClass,
      content: generateTextStringContent(element.options.primaryText),
    });

    cssString += generateCssClassWithContent({
      className: element.options.icon._cssClass,
      content: iconCssStringContent,
    });

    if (states) {
      for (const state in element.options.states) {
        cssString += generateCssClassWithContent({
          className: element.options._itemCssClass,
          childClassName: element.options.primaryText?._cssClass,
          content: generateTextStringContent(
            element.options.states[state].primaryText,
            true
          ),
          pseudoClassName: `:${state}`,
        });

        cssString += generateCssClassWithContent({
          className: element.options._itemCssClass,
          childClassName: element.options.text?._cssClass,
          content: generateTextStringContent(
            element.options.states[state].text,
            true
          ),
          pseudoClassName: `:${state}`,
        });

        cssString += generateCssClassWithContent({
          className: element.options._itemCssClass,
          childClassName: element.options.icon?._cssClass,
          content: element.options.states[state].icon?.color,
          customFunction: generateTextColor,
          pseudoClassName: `:${state}`,
        });
      }
    }

    return cssString;
  };

const generateProfileButtonCssString =
  (element: IWidgetField, widgetOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateStageButtonCssString(element)("");

    if (
      widgetOptions.profileDropdownDisplay.layout ===
      DisplayOrientation.VERTICAL
    ) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: `align-self:${widgetOptions.profileDropdownDisplay.alignment}`,
      });
    }

    return cssString;
  };

export const generateProfileDropdownWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const title = widget.content["dropdown"];
  const dropdownMenu = widget.content["items"];
  const dropdownContainer = widget.content["dropdownContainer"];
  const deposit = widget.content["deposit"];
  const logout = widget.content["logout"];
  const registration = widget.content["registration"];
  const login = widget.content["login"];
  const balance = widget.content["balance"];
  const bonus = widget.content["bonus"];

  widget.options._cssClass = generateClassName("ProfileDropdownWidget");
  widget.options._contentCssClass = generateClassName(
    "ProfileDropdownWidget_content"
  );
  title.options._cssClass = generateClassName("title");
  title.options.arrow._cssClass = generateClassName("titleArrow");
  title.options.icon._cssClass = generateClassName("titleIcon");
  dropdownMenu.options._cssClass = generateClassName("items");
  dropdownContainer.options._cssClass = generateClassName("dropdownContainer");

  dropdownMenu.value[0].options.itemsIcon._cssClass =
    generateClassName("itemsIcon");
  dropdownMenu.value[0].options.itemsText._cssClass =
    generateClassName("itemsText");
  itemsGroupClassNameGenerator(dropdownMenu);
  deposit.options._cssClass = generateClassName("deposit");
  deposit.options.iconSettings._cssClass = generateClassName("depositIcon");
  logout.options._cssClass = generateClassName("logout");
  logout.options.iconSettings._cssClass = generateClassName("logoutIcon");
  registration.options._cssClass = generateClassName("registration");
  registration.options.iconSettings._cssClass =
    generateClassName("registrationIcon");
  login.options._cssClass = generateClassName("login");
  login.options.iconSettings._cssClass = generateClassName("loginIcon");

  balance.options._containerCssClass = generateClassName("balanceContainer");

  const balanceItemClass = generateClassName("balanceItem");
  const balanceSecondaryTextClass = generateClassName("balanceSecondaryText");
  const balancePrimaryTextClass = generateClassName("balancePrimaryText");
  const balanceIconClass = generateClassName("balanceIcon");
  balance.options._itemCssClass = balanceItemClass;
  balance.options.text._cssClass = balanceSecondaryTextClass;
  balance.options.primaryText._cssClass = balancePrimaryTextClass;
  balance.options.icon._cssClass = balanceIconClass;
  bonus.options._itemCssClass = balanceItemClass;
  bonus.options.text._cssClass = balanceSecondaryTextClass;
  bonus.options.primaryText._cssClass = balancePrimaryTextClass;
  bonus.options.icon._cssClass = balanceIconClass;
  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateTitleCssString(title, title.options.display),
    generateProfileDropdownMenuCssString(
      dropdownMenu,
      title.options.display,
      widget.options._cssClass
    ),
    generateStringDefault(dropdownContainer),
    generateItemsGroupCssString(dropdownMenu.value[0], title.options.display),
    generateProfileButtonCssString(deposit as IWidgetField, widget.options),
    generateProfileButtonCssString(logout as IWidgetField, widget.options),
    generateProfileButtonCssString(
      registration as IWidgetField,
      widget.options
    ),
    generateProfileButtonCssString(login as IWidgetField, widget.options),
    generateBalanceContainerCssString(balance),
    generateBalanceItemsCssString(balance)
  )("");
};
