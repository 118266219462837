import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useSportsbookItems } from "~~/composables/widgets/sportsbook/useSportsbookItems";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";
import { useEventCardsConfig } from "~~/composables/widgets/sportsbook/useEventCardsConfig";
import { useSportsbookHeaderConfig } from "~~/composables/widgets/sportsbook/useSportsbookHeaderConfig";
import { useLeagueWinnerCardConfig } from "~~/composables/widgets/sportsbook/useLeagueWinnerCardConfig";
import { useFormDropdownConfig } from "~~/composables/widgets/form/useFormDropdownConfig";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useFormDatePickerConfig } from "~~/composables/widgets/form/useFormDatepickerConfig";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useSportsbookConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useSportsbookItems(widget);
  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit);

  const {
    eventCardsDesignControls,
    eventCardsContentControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  } = useEventCardsConfig(widget, states, emit);

  const {
    headerDesignControls,
    headerContentControls,
    headerButtonStatesControls,
    headerButtonDesignControls,
    headerButtonContentControls,
  } = useSportsbookHeaderConfig(widget, states, emit);

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);
  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  } = useFormDatePickerConfig(widget, states);

  const formStylesControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "tooltips_styling-design": formTooltipDesignStyles.value,
      "tooltips_styling-content": formTooltipContentStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
      "calendar_styling-design": formCalendarDesignStyles.value,
      "calendar_styling-content": formCalendarContentStyles.value,
      "calendar_styling-states": formCalendarStates.value,
      "datepicker_styling": formDatePickerStyles.value,
      "dropdowns_styling": formDropdownStyles.value,
      "dropdown_menu_styling": formDropdownMenuStyles.value,
      "dropdown_menu_items_styling-design":
        formDropdownMenuItemsDesignStyles.value,
      "dropdown_menu_items_styling-content":
        formDropdownMenuItemsContentStyles.value,
      "dropdown_menu_items_styling-states": formDropdownMenuItemsStates.value,
    };
  });

  const { leagueWinnerCardDesignControls, leagueWinnerCardContentControls } =
    useLeagueWinnerCardConfig(widget);

  /* 
    Filter elements controls
  */
  const { dropdownFieldControls, calendarFieldControls } =
    useFormContentConfig();

  /* 
    Widget config
  */

  const widgeDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
   Common title config
  */

  const titleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
   Common title league config
  */

  const titleLeagueControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.winner_league_title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          active: fields.value.winner_league_title.active,
          showOptions: fields.value.winner_league_title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.winner_league_title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.winner_league_title.field
            );
          },
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
   Common title list config
  */

  const titleListControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.list_title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          active: fields.value.list_title.active,
          showOptions: fields.value.list_title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.list_title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.list_title.field
            );
          },
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Events filter settings
  */

  const eventsFilterSettingsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.filter.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.distance",
        valueSource: fields.value.filter.field,
        options: {
          label: "Distance between fields",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.filter.field,
        options: {},
      },
    ];
  });

  const formContentFields = computed<IWidgetField[]>(() => {
    return [
      fields.value.date_from.field,
      fields.value.date_to.field,
      fields.value.markets.field,
    ];
  });

  const eventsFilterSettingsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.displayMode",
        valueSource: fields.value.filter.field,
        options: {
          label: "Display mode",
          isBold: true,
          items: [
            {
              label: "Horizontal",
              value: "horizontal",
            },
            {
              label: "Vertical",
              value: "vertical",
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigFormItems",
        valuePath: "",
        valueSource: null,
        options: {
          fields: {
            visible: formContentFields.value,
            hidden: [],
          },
          isOrderAllowed: false,
          customEditHandler(field: IWidgetField) {
            widgetSettingsStore.setActiveElement(selectedWidget.value, field);
          },
        },
      },
    ];
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields);
  });

  const eventsFilterSettingsStyleControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  /* 
    Build button config
  */

  const buildBtnState = computed<State>(() => {
    return states.value.betbuilder_button;
  });

  const {
    buttonStatesControls: buildButtonStateControls,
    buttonDesignControls: buildButtonDesignControls,
    buttonContentControls,
  } = useSimpleButtonControls(buildBtnState, "betbuilder_button", emit);

  const buildButtonContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
        },
      },
      ...buttonContentControls.value,
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgeDesignControls,
    widgetContentControls,

    headerDesignControls,
    headerContentControls,

    titleControls,
    titleLeagueControls,
    titleListControls,

    headerButtonStatesControls,
    headerButtonDesignControls,
    headerButtonContentControls,

    leagueWinnerCardDesignControls,
    leagueWinnerCardContentControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    eventsFilterSettingsDesignControls,
    eventsFilterSettingsContentControls,
    eventsFilterSettingsStyleControls,

    eventCardsDesignControls,
    eventCardsContentControls,

    startTimeDateControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,

    buildButtonDesignControls,
    buildButtonContentControls,
    buildButtonStateControls,

    /* 
      Filter content
    */
    dropdownFieldControls,
    calendarFieldControls,

    formStylesControls,
  };
};
