<template>
  <div
    class="betslip-fenix-widget__collapse"
    :style="collapseGapStyle"
  >
    <WidgetField
      :widget="widget"
      :field="fields.collapse.field"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.collapse.field"
        :state="states.collapse"
        :options="fields.collapse.field.options"
      >
        <div
          class="betslip-fenix-widget__collapse-content"
          :style="betslipFenixCollapseGap"
        >
          <div class="betslip-fenix-widget__collapse-title-container">
            <BasicTitleField
              :widget="widget"
              :field="fields.collapse.field"
              :options="stateOptions"
            >
              {{ title }}
            </BasicTitleField>
            <a-tooltip
              v-if="tooltipField !== undefined"
              :key="tooltipIconOptions?.gap"
              :overlay-style="tooltipStyles"
              :visible="selectedField?.name === tooltipField.name"
              :get-popup-container="getMenuContainer"
              placement="bottom"
              overlay-class-name="betslip-fenix-widget__tooltip remove-arrow"
            >
              <template #title> Tooltips text </template>

              <span v-if="tooltipIconComponent">
                <CommonIcon
                  :name="tooltipIconComponent"
                  :style="tooltipIconColorStyle"
                />
              </span>
            </a-tooltip>
          </div>

          <CommonIcon
            v-if="!hideIcon"
            :style="iconStyle"
            :name="
              iconMapping[fields.collapse.field.options.arrowSettings.value]
            "
          />
        </div>
      </BasicDesignWrapperField>
    </WidgetField>

    <div :style="collapseContentStyles">
      <slot
        v-for="index in cardsCount"
        :key="index"
      ></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  IWidgetWithFields,
  IWidgetOptions,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IFillControl,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontSizeStyle,
  getSpacing,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { ElementStyle, StyleValue } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { iconMapping } from "~~/constants/icons-mapping";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
  title: string;
  cardsCount: number;
  hideIcon?: boolean;
  tooltipField?: IWidgetField;
}>();

const { selectedField } = storeToRefs(useWidgetSettingsStore());

const stateOptions = computed<IWidgetOptions>(() => {
  if (props.states.collapse === State.DEFAULT) {
    return props.fields.collapse.field.options;
  }

  return props.fields.collapse.field.options.states[props.states.collapse];
});

const options = computed<IWidgetOptions>(() => {
  return props.fields.collapse.field.options?.arrowSettings || {};
});

const iconStyle = computed<ElementStyle>(() => {
  return {
    ...getFontSizeStyle(options.value.iconSize),
    ...getColorStyle(stateOptions.value.arrowSettings.textColor),
  };
});

const betslipFenixCollapseGap = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(
      props.fields.collapse.field.options.display?.distance
    ),
  };
});

const collapseGapStyle = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(
      props.fields.bets_container.field.options.distance
    ),
  };
});

const collapseContentStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(
      props.fields.bets_container.field.options.distance
    ),
  };
});

const tooltipIconOptions = computed<{
  icon: string;
  color: IFillControl;
  gap: number;
} | null>(() => {
  if (!props.tooltipField) {
    return null;
  }

  return props.tooltipField.options.textChooseIcon;
});

const tooltipStyles = computed<ElementStyle>(() => {
  if (!props.tooltipField) {
    return {};
  }

  const textColor = getColorFromHex(
    props.tooltipField.options.color
  ) as StyleValue;

  const styles = {
    ...getSpacing(props.tooltipField.options.padding, SpacingKeyName.PADDING),
    ...getCornerRadiusStyle(props.tooltipField.options.cornerRadius),
    ...getBackgroundColorStyle(props.tooltipField.options.fill),
    ...getBorderStyle(props.tooltipField.options.border),
    ...getBoxShadowStyle(props.tooltipField.options.shadow),
    ...getFontSizeStyle(props.tooltipField.options.theme),
    ...getDecorationValue(props.tooltipField.options.decoration),
    ...getTextAlignStyle(props.tooltipField.options.alignment),
    textColor,
  };

  return getStylesAsVars(styles);
});

const tooltipIconComponent = computed<string | null>(() => {
  if (!tooltipIconOptions.value) {
    return null;
  }

  return "ant-design:question-circle-outlined";
});

const tooltipIconColorStyle = computed<ElementStyle>(() => {
  if (!tooltipIconOptions.value || !props.tooltipField) {
    return {};
  }

  return getColorStyle(tooltipIconOptions.value.color);
});

const getMenuContainer = (): HTMLElement | null => {
  return document.querySelector(`#${WIDGETS_GRID_ID}`);
};
</script>

<style lang="scss">
.betslip-fenix-widget {
  &__collapse {
    display: flex;
    flex-direction: column;
  }

  &__collapse-content {
    @include flex(center, space-between, 0);
  }

  &__collapse {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__tooltip {
    font-size: 12px;
    flex-grow: 1;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        text-align: var(--textAlign);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
    &.remove-arrow {
      &[class*="ant-tooltip-placement"] {
        padding: 0 !important;
        margin-top: 10px !important;
      }
      .ant-tooltip-arrow-content {
        display: none;
      }
    }
  }

  &__collapse-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
