import { Ref } from "vue";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

import { IFields } from "../useWidgetFields";

export const useBalanceFields = (fields: Ref<IFields>) => {
  const balanceFields = computed<IWidgetField[]>(() =>
    [fields.value.balance.field, fields.value.bonus.field].sort(
      (a, b) => a.options._position - b.options._position
    )
  );

  return {
    balanceFields,
  };
};
