import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const usePromotedInfoConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Event info
  */

  const eventInfoDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_info.field,
    });
  });

  const eventInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Bet items layout",
              items: [
                { label: "Horizontal", value: DisplayOrientation.HORIZONTAL },
                { label: "Vertical", value: DisplayOrientation.VERTICAL },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Distance between items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Team / player",
              value: "event_name",
            },
            {
              label: "Date / time",
              value: "event_date_time",
            },
          ],
        },
      },
    ];
  });

  /* 
    Team player
  */

  const teamPlayerTitleControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.event_name.field,
      }),
    ];
  });

  /* 
    Time
  */

  const eventTimeControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.event_date_time.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.dateIcon",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_date_time.field,
            options: {
              label: "Date icon",
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.timeIcon",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_date_time.field,
            options: {
              label: "Time icon",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_date_time.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.event_date_time.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: fields.value.event_date_time.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  return {
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventTimeControls,
  };
};
