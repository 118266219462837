import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { Sizing } from "~~/models/grid.interface";
import { betDefaultSpacingCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGrid,
  generateWidth,
  generateMaxWidth,
  generateTextColor,
  generateFontSize,
  generateFlexAlignment,
  generateLineClampStyleCssString,
  generateBreakWordStyleCssString,
  generateFillColorStyle,
  generateBorderStyle,
  generateShadowStyle,
  generateDecorationStyle,
  generateSpacingStyle,
} from "../helpers";
import { getPxValueFromNumber } from "../..";

import { generateFavouriteIconCssString } from "./casino-games-list-widget-css";

export const generateBetItemCssString =
  (marketBetItem: IWidgetField, allowItemWidth?: boolean) =>
  (cssString: string): string => {
    const { options } = marketBetItem;

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content: nameCssContent + "overflow: hidden;",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const flexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    const itemWidth = allowItemWidth ? "width: 100%" : "";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(marketBetItem.options) +
        flexString +
        "flex: 1;" +
        itemWidth,
    });

    cssString += generateDefaultStylesWithStates(marketBetItem, false, [
      "up",
      "down",
    ]);

    for (const state in marketBetItem.options.states) {
      if (state == "up" || state === "down") {
        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: marketBetItem.options.name._cssClass,
        content: generateDefaultStyles(
          marketBetItem.options.states[state].name,
          ["theme"]
        ),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: marketBetItem.options.coef._cssClass,
        content: generateDefaultStyles(
          marketBetItem.options.states[state].coef,
          ["theme"]
        ),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      content: generateDefaultStyles(marketBetItem.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      childClassName: marketBetItem.options.coef._cssClass,
      content:
        generateDefaultStyles(marketBetItem.options.states.active.coef) +
        betDefaultSpacingCssString(),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      childClassName: marketBetItem.options.coef._cssClass,
      content:
        generateDefaultStyles(marketBetItem.options.states.active.name) +
        betDefaultSpacingCssString(),
    });

    /* 
      Up/down
    */

    const betAdditionalStateCss = "position: relative;overflow: hidden;";

    const afterDefaultCss =
      "display: block;" +
      "position: absolute;" +
      "right: -10px;" +
      "width: 27px;" +
      "height: 15px;" +
      "z-index: 2;" +
      "content:'';";

    const afteUpCss =
      "top: -7px;" +
      "transform: rotate(45deg);" +
      generateFillColorStyle(options.states.up.arrow?.color, true);

    const afteDownCss =
      "transform: rotate(-45deg);" +
      "bottom: -7px;" +
      generateFillColorStyle(options.states.down.arrow?.color, true);

    const classNames: Record<string, string> = {
      up: options._itemUpCssClass,
      down: options._itemDownCssClass,
    };

    for (const stateKey of ["up", "down"]) {
      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        content:
          generateBorderStyle(options.states[stateKey].border, true) +
          generateFillColorStyle(options.states[stateKey].fill, true) +
          generateShadowStyle(options.states[stateKey].shadow, true) +
          betAdditionalStateCss,
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']:after`,
        content:
          afterDefaultCss + (stateKey === "down" ? afteDownCss : afteUpCss),
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        childClassName: options.coef._cssClass,
        content:
          generateTextColor(options.states[stateKey].coef.color, true) +
          generateDecorationStyle(
            options.states[stateKey].coef.decoration,
            true
          ) +
          generateFontSize(options.states[stateKey].coef.theme, true),
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        childClassName: options.name._cssClass,
        content:
          generateTextColor(options.states[stateKey].name.color, true) +
          generateDecorationStyle(
            options.states[stateKey].name.decoration,
            true
          ),
      });
    }

    return cssString;
  };

export const generateMarketContainerCssString =
  (eventsCard: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventsCard;

    const overflow = "overflow:hidden;";

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.columns}, 1fr)`,
      rowGap: options.distanceBetweenCards,
      columnGap: options.distanceBetweenCards,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      content: generateDefaultStyles(options.card) + overflow,
    });

    const { distanceBetweenItems } = options.items;
    let itemsCssContent;

    if (options.items.displayMode === DisplayOrientation.HORIZONTAL) {
      itemsCssContent = generateGrid({
        rowGap: distanceBetweenItems,
        columnGap: distanceBetweenItems,
        templateColumns: `repeat(auto-fit, minmax(calc(33.33334% - ${getPxValueFromNumber(
          distanceBetweenItems
        )}), 1fr))`,
        gridAutoFlow: "column",
      });
    } else {
      itemsCssContent = generateFlex({
        flex: "flex",
        direction: "column",
        align: "stretch",
        justify: "flex-start",
        gap: distanceBetweenItems,
      });
    }

    cssString += generateCssClassWithContent({
      className: options.items._cssClass,
      content: itemsCssContent,
    });

    return cssString;
  };

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    let widgetStyles = "";

    if (options.contentWidth.type === Sizing.FIXED) {
      widgetStyles = generateWidth(options.contentWidth.width, "px");
    } else {
      widgetStyles += generateWidth(100, "%");
    }

    widgetStyles += generateMaxWidth(`100%`);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateIconCssString =
  (icon: IWidgetOptions, elementClass?: string) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(icon.textColor),
      generateFontSize(icon.iconSize),
    ];

    cssString += generateCssClassWithContent({
      className: elementClass || icon._cssClass,
      content: content.join(""),
    });

    return cssString;
  };

export const generateIframeCssString =
  (containerOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: containerOptions.options._cssClass,
      pseudoClassName: " iframe",
      content: "width: 100%; height: 520px;",
    });

    return cssString;
  };

export const generateBetBuilderSpacingCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options.spacing,
      customFunction: generateSpacingStyle,
    });

    return cssString;
  };

export const generateStateIconCssString =
  (
    containerOptions: IWidgetOptions,
    iconOptionsName = "arrowSettings",
    elementSelector?: string
  ) =>
  (cssString: string): string => {
    for (const state in containerOptions.states) {
      const css = generateTextColor(containerOptions.states[state].textColor);
      const defaultElementSelector = `.${containerOptions[iconOptionsName]._cssClass}`;

      cssString += generateCssClassWithContent({
        className: containerOptions._cssClass,
        content: css,
        pseudoClassName: `:${state} ${
          elementSelector || defaultElementSelector
        }`,
      });
    }

    return cssString;
  };

export const generateTabsCssString =
  (containerOptions: IWidgetOptions, tabOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = tabOptions;
    const isFill =
      containerOptions.options.displaySettings.resizing === ResizingType.FILL;

    const states = tabOptions.options.states;

    for (const state in states) {
      const iconCss = generateTextColor(
        options.states[state].iconSettings.textColor
      );

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: iconCss,
        pseudoClassName: `:${state} svg`,
      });

      if (state === State.HOVER) {
        cssString += `@media (hover: hover) and (pointer: fine) {.${
          options._cssClass
        }:hover{${generateDefaultStyles(states[state])}}}`;
        cssString += `.${
          options._cssClass
        }:focus-visible{${generateDefaultStyles(states[state])}}`;

        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: states[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    cssString += generateCssClassWithContent({
      className: containerOptions.options._cssClass,
      pseudoClassName: ` button[data-active='true'] .${options._cssClass}`,
      content: generateDefaultStyles(states.active),
    });

    cssString += generateCssClassWithContent({
      className: containerOptions.options._cssClass,
      pseudoClassName: " button[data-active='true'] svg",
      content: generateDefaultStyles(states.active.iconSettings),
    });

    const flexForTabButton = generateFlex({
      flex: "flex",
      wrap: "nowrap",
      align: "center",
      justify: isFill
        ? Alignment.CENTER
        : generateFlexAlignment(
            containerOptions.options.displaySettings.alignment
          ),
    });

    const widthForTabButton = isFill ? "width:100%;" : "width:auto;";
    const shrinkForTabButton = isFill ? "flex-shrink:1;" : "flex-shrink:0;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        flexForTabButton +
        shrinkForTabButton +
        widthForTabButton +
        "cursor: pointer;" +
        generateDefaultStyles(options, ["spaceBetween"]) +
        "white-space:nowrap;",
    });

    return cssString;
  };

export const generateTabsContainerString =
  (tabs: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = tabs;
    const isFill = options.displaySettings.resizing === ResizingType.FILL;

    const widgetPositionCssContainer = generateFlex({
      flex: "flex",
      wrap: "nowrap",
      align: "center",
      justify: isFill
        ? Alignment.CENTER
        : generateFlexAlignment(options.displaySettings.alignment),
      gap: options.displaySettings.distance,
    });

    const overflow = "overflow: auto;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        widgetPositionCssContainer + overflow + generateDefaultStyles(options),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " button",
      content: isFill ? "width: 100%;" : "width: initial;",
    });

    return cssString;
  };

export const generateEventWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const banner_container = widget.content["banner_container"];
  const event_title = widget.content["event_title"];
  const event_time = widget.content["event_time"];
  const market_cards = widget.content["market_cards"];
  const market_name = widget.content["market_name"];
  const favourite_event_market = widget.content["favourite_event_market"];
  const market_suspended_status = widget.content["market_suspended_status"];
  const bet_items = widget.content["bet_items"];
  const betbuilder_container = widget.content["betbuilder_container"];
  const betbuilder_header = widget.content["betbuilder_header"];
  const betbuilder_content_container =
    widget.content["betbuilder_content_container"];
  const betbuilder_content_description =
    widget.content["betbuilder_content_description"];
  const tabs_container = widget.content["tabs_container"];
  const tabs = widget.content["tabs"];

  widget.options._cssClass = generateClassName("Event");
  title.options._cssClass = generateClassName("title");

  /*
    Banner
  */
  banner_container.options._cssClass = generateClassName("banner_container");
  event_title.options._cssClass = generateClassName("event_title");
  event_time.options._cssClass = generateClassName("event_time");

  /*
    Market
  */
  market_cards.options._cssClass = generateClassName("market_cards");
  market_name.options._cssClass = generateClassName("market_name");

  favourite_event_market.options._cssClass = generateClassName(
    "market_favourite_event"
  );

  market_suspended_status.options._cssClass = generateClassName(
    "market_suspended_status"
  );
  market_cards.options.card._cssClass = generateClassName("market_card");
  market_cards.options.items._cssClass = generateClassName("market_items");

  /*
    Bet items
  */

  bet_items.options._cssClass = generateClassName("bet_items");
  bet_items.options.name._cssClass = generateClassName("bet_item_name");
  bet_items.options.coef._cssClass = generateClassName("bet_item_coef");

  bet_items.options._itemSelectedCssClass =
    bet_items.options._cssClass + '[data-selected="true"]';
  bet_items.options._itemUpCssClass =
    bet_items.options._cssClass + '[data-up="true"]';
  bet_items.options._itemDownCssClass =
    bet_items.options._cssClass + '[data-down="true"]';

  betbuilder_container.options._cssClass = generateClassName(
    "betbuilder_container"
  );

  betbuilder_header.options._cssClass = generateClassName("betbuilder_header");
  betbuilder_header.options.arrowSettings._cssClass = generateClassName(
    "betbuilder_header_arrow"
  );

  betbuilder_content_container.options._cssClass = generateClassName(
    "betbuilder_content_container"
  );

  betbuilder_content_description.options._cssClass = generateClassName(
    "betbuilder_content_description"
  );

  tabs_container.options._cssClass = generateClassName("tabs_container");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options.iconSettings._cssClass = generateClassName("tabs_icon");
  tabs.options._itemSelectedCssClass =
    tabs.options._cssClass + '[data-selected="true"]';

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStringDefault(title),
    generateStringDefault(banner_container),
    generateStringDefault(event_title),
    generateStringDefault(event_time),
    generateMarketContainerCssString(market_cards as IWidgetField),
    generateStringDefault(market_name),
    generateLineClampStyleCssString(market_name.options),
    generateBreakWordStyleCssString(market_name.options),
    generateFavouriteIconCssString(favourite_event_market),
    generateStringDefault(market_suspended_status),
    generateBetItemCssString(bet_items as IWidgetField, true),
    generateStringDefault(betbuilder_container),
    generateStringDefault(betbuilder_content_container),
    generateStringWithStates(betbuilder_header, false),
    generateIconCssString(betbuilder_header.options.arrowSettings),
    generateStateIconCssString(betbuilder_header.options),
    generateBetBuilderSpacingCssString(betbuilder_header),
    generateStringDefault(betbuilder_content_description),
    generateIframeCssString(betbuilder_container),
    generateTabsContainerString(tabs_container),
    generateTabsCssString(tabs_container, tabs),
    generateIconCssString(
      tabs.options.iconSettings,
      `${tabs.options._cssClass} svg`
    )
  )("");
};
