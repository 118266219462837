import { events, GlobalEvents } from "~~/services/global-events";

interface IClickOutsideElement extends HTMLElement {
  _clickOutsideEvent: (...props: any[]) => void;
}

const VClickOutside = {
  mounted(
    el: IClickOutsideElement,
    binding: { value: (event: Event, el: HTMLElement) => void }
  ) {
    el._clickOutsideEvent = function (event: Event) {
      const target = event.target as HTMLElement;

      if (el === event.target || el.contains(target)) {
        return;
      }

      binding.value(event, el);
    };

    events.on(GlobalEvents.BODY_CLICK, el._clickOutsideEvent);
  },
  beforeUnmount(el: IClickOutsideElement) {
    events.off(GlobalEvents.BODY_CLICK, el._clickOutsideEvent);
  },
};

export default VClickOutside;
