<template>
  <div class="sport-menu-live-fenix-sport-card">
    <WidgetField
      :widget="widget"
      :field="headerField"
      :style="headerStyles"
      class="sport-menu-live-fenix-sport-card__header"
    >
      <div
        class="sport-menu-live-fenix-sport-card__header-left"
        :style="headerLeftStyles"
      >
        <WidgetField
          v-if="isLeagueNameVisible"
          :widget="widget"
          :field="leagueNameField"
          :style="leagueNameStyles"
        >
          <BasicTitleField
            :field="leagueNameField"
            :value="data.leagueName"
            :state="state"
          />
        </WidgetField>

        <div
          class="sport-menu-live-fenix-sport-card__header-bottom"
          :style="headerBottomStyles"
        >
          <WidgetField
            :widget="widget"
            :field="eventStatusField"
          >
            <SportsBookLiveSuspended
              :field="eventStatusField"
              :value="data.status"
              :state="state"
            />
          </WidgetField>

          <WidgetField
            v-if="streamIconField.options._active"
            :widget="widget"
            :field="streamIconField"
            :style="streamIconStyles"
          >
            <CommonIcon
              :size="streamIconField.options.iconSize"
              :name="streamIconField.options.value"
              :style="streamIconStyles"
            />
          </WidgetField>

          <WidgetField
            v-if="isLayoutFirstMode"
            :field="eventNameField"
            :widget="widget"
            :style="teamPlayerStyles"
          >
            <BasicTitleField
              :field="eventNameField"
              :value="data.eventName"
              :state="state"
            />
          </WidgetField>

          <template v-else>
            <WidgetField
              :widget="widget"
              :field="eventTimeField"
              :style="timeStyles"
            >
              <SportsBookLiveTime
                :field="eventTimeField"
                :show-icon="eventTimeField.options.icon._active"
                :state="state"
              >
                {{ data.time }}
              </SportsBookLiveTime>
            </WidgetField>

            <WidgetField
              v-if="eventPeriodField.options._active"
              :widget="widget"
              :field="eventPeriodField"
              :style="periodStyles"
            >
              <BasicTitleField
                :field="eventPeriodField"
                :value="data.period"
                :state="state"
              />
            </WidgetField>
          </template>
        </div>
      </div>

      <WidgetField
        v-if="isFavoriteIconVisible"
        :widget="widget"
        :field="favoriteIconField"
        :style="favoriteIconStyles"
      >
        <CommonIcon
          :size="favoriteIconField.options.iconSize"
          :name="iconName || favoriteIconField.options.value"
        />
      </WidgetField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="footerField"
      :style="footerStyles"
      class="sport-menu-live-fenix-sport-card__footer"
    >
      <template v-if="isLayoutFirstMode">
        <div
          :style="footerFirstModeItemStyles"
          class="sport-menu-live-fenix-sport-card__footer-left"
        >
          <WidgetField
            :widget="widget"
            :field="eventTimeField"
            :style="timeStyles"
          >
            <SportsBookLiveTime
              :field="eventTimeField"
              :show-icon="eventTimeField.options.icon._active"
              :state="state"
            >
              {{ data.time }}
            </SportsBookLiveTime>
          </WidgetField>

          <WidgetField
            v-if="eventPeriodField.options._active"
            :widget="widget"
            :field="eventPeriodField"
            :style="periodStyles"
          >
            <BasicTitleField
              :field="eventPeriodField"
              :value="data.period"
              :state="state"
            />
          </WidgetField>
        </div>

        <div :style="footerFirstModeItemStyles">
          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sport-menu-live-fenix-sport-card__score"
              :style="baseScoreStyles"
            >
              {{ data.score1 }}
            </span>
          </WidgetField>

          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sport-menu-live-fenix-sport-card__score"
              :style="baseScoreStyles"
            >
              {{ data.score2 }}
            </span>
          </WidgetField>
        </div>
      </template>

      <template v-else>
        <div :style="footerSecondModeItem1Styles">
          <WidgetField
            class="sport-menu-live-fenix-sport-card__footer-left"
            :field="eventNameField"
            :widget="widget"
            :style="teamPlayerStyles"
          >
            <BasicTitleField
              :field="eventNameField"
              :value="data.teamPlayer1"
              :state="state"
            />
          </WidgetField>

          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sport-menu-live-fenix-sport-card__score"
              :style="baseScoreStyles"
            >
              {{ data.score1 }}
            </span>
          </WidgetField>
        </div>

        <div :style="footerSecondModeItem2Styles">
          <WidgetField
            :field="eventNameField"
            :widget="widget"
            :style="teamPlayerStyles"
            class="sport-menu-live-fenix-sport-card__footer-left"
          >
            <BasicTitleField
              :field="eventNameField"
              :value="data.teamPlayer2"
              :state="state"
            />
          </WidgetField>

          <WidgetField
            v-if="eventScoreField.options._active"
            :widget="widget"
            :field="eventScoreField"
          >
            <span
              class="sport-menu-live-fenix-sport-card__score"
              :style="baseScoreStyles"
            >
              {{ data.score2 }}
            </span>
          </WidgetField>
        </div>
      </template>
    </WidgetField>
  </div>
</template>

<script setup lang="ts">
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getColorStyle } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  data: {
    leagueName: string;
    status: string;
    eventName: string;
    teamPlayer1: string;
    teamPlayer2: string;
    time: string;
    period: string;
    score1: number;
    score2: number;
  };
  widget: IWidgetWithFields;
  headerField: IWidgetField;
  footerField: IWidgetField;
  cardField: IWidgetField;
  favoriteIconField: IWidgetField;
  leagueNameField: IWidgetField;
  eventStatusField: IWidgetField;
  streamIconField: IWidgetField;
  eventNameField: IWidgetField;
  eventTimeField: IWidgetField;
  eventPeriodField: IWidgetField;
  eventScoreField: IWidgetField;
  state: State;
  iconName?: string;
}>();

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.headerField.options, props.state),
    "display": "flex",
    "alignItems": "center",
    "gap": `${props.headerField.options.verticalSpace}px`,
  };
});

const headerLeftStyles = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "flexGrow": 1,
    "maxWidth": "100%",
    "overflow": "hidden",
    "gap": `${props.headerField.options.verticalSpace}px`,
    "flex-direction": isHeaderLayoutVertical.value
      ? leagueNamePosition.value === "top"
        ? "column"
        : "column-reverse"
      : leagueNamePosition.value === "left"
      ? "row"
      : "row-reverse",
  };
});

const headerBottomStyles = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "alignItems": "center",
    "gap": `${props.headerField.options.verticalSpace}px`,
    "maxWidth": "100%",
    "overflow": "hidden",
    "flexShrink": 0,
  };
});

const footerStyles = computed<ElementStyle>(() => {
  const commonStyles = getCommonStyles(props.footerField.options, props.state);

  return {
    ...commonStyles,
    "display": "flex",
    "gap": `${props.footerField.options.verticalSpace}px`,
    "flexDirection":
      !isLayoutFirstMode.value && isFooterLayoutVertical.value
        ? "column"
        : "row",
  };
});

const footerFirstModeItemStyles = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "flexDirection": isFooterLayoutVertical.value ? "column" : "row",
    "gap": `${props.footerField.options.verticalSpace}px`,
    ...(isFooterLayoutVertical.value
      ? { justifyContent: "space-around" }
      : { "alignItems": "center" }),
  };
});

const footerSecondModeItem1Styles = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "alignItems": "center",
    "gap": `${props.footerField.options.verticalSpace}px`,
    "maxWidth": "100%",
    "overflow": "hidden",
  };
});

const footerSecondModeItem2Styles = computed<ElementStyle>(() => {
  return {
    ...footerSecondModeItem1Styles.value,
    "flexDirection": isFooterLayoutVertical.value ? "row" : "row-reverse",
  };
});

const isLeagueNameVisible = computed(
  () => props.leagueNameField.options._active
);

const isFavoriteIconVisible = computed(
  () => props.favoriteIconField.options._active
);

const leagueNameStyles = computed(() => {
  return {
    "minWidth": "3ch",
    "width":
      props.leagueNameField.options.displayMode === "fill"
        ? "100%"
        : "fit-content",
  };
});

const favoriteIconStyles = computed(() => {
  return {
    ...getColorStyle(
      props.state === State.DEFAULT
        ? props.favoriteIconField.options.color
        : props.favoriteIconField.options.states[props.state].color
    ),
    "alignSelf": props.headerField.options.favoriteIconAlignment,
    "justifySelf": "flex-end",
    "flexShrink": 0,
  };
});

const streamIconStyles = computed(() => {
  return getColorStyle(
    props.state === State.DEFAULT
      ? props.streamIconField.options.color
      : props.streamIconField.options.states[props.state].color
  );
});

const teamPlayerStyles = computed(() => {
  const base = { "minWidth": "3ch", "width": "100%" };

  if (isLayoutFirstMode.value) {
    return {
      ...base,
      "flexGrow": 1,
    };
  }

  return base;
});

const isLayoutFirstMode = computed(
  () =>
    props.cardField.options.layoutMode === SportMenuLiveBet9CardLayoutMode.FIRST
);

const isHeaderLayoutVertical = computed(
  () => props.headerField.options.layout === DisplayOrientation.VERTICAL
);

const isFooterLayoutVertical = computed(
  () => props.footerField.options.layout === DisplayOrientation.VERTICAL
);

const leagueNamePosition = computed(
  () =>
    props.headerField.options?.[
      `leagueName${
        isHeaderLayoutVertical.value ? "Vertical" : "Horizontal"
      }Position`
    ]
);

const baseScoreStyles = computed(() => ({
  ...getTextCommonStyles(props.eventScoreField.options, props.state),
  ...getCommonStyles(props.eventScoreField.options, props.state),
}));

const timeStyles = computed<ElementStyle>(() => {
  return {};
});

const periodStyles = computed<ElementStyle>(() => {
  return {
    "width":
      props.eventPeriodField.options.displayMode === "fill"
        ? "100%"
        : "fit-content",
  };
});
</script>

<style lang="scss">
.sport-menu-live-fenix-sport-card {
  &__score {
    display: inline-block;
  }

  &__footer-left {
    flex-grow: 1;
  }
}
</style>
