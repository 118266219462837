import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { getTextAlignStyleReversed } from "~~/assets/utils/widget-settings";
import { betDefaultSpacingCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";
import { generateIconCssString } from "~~/assets/utils/widget-css/widgets/racing-sportsbook-live-widget-css";
import { FRONTEND_APP_CONTAINER_ID } from "~~/constants";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGrid,
  generateMarginStyle,
  generateTextColor,
  generateFontSize,
  generatePaddingStyle,
  generateFillColorStyle,
  generateBorderStyle,
  generateShadowStyle,
  generateDecorationStyle,
} from "../helpers";
import { getPxValueFromNumber } from "../..";

import { generateFavouritesButtonCssString } from "./racing-sportsbook-live-widget-css";

export const generateEventCardsCssString =
  (eventCards: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventCards;

    const withoutNoItems = {
      ...options,
    };

    delete withoutNoItems.decoration;
    delete withoutNoItems.theme;
    delete withoutNoItems.alignment;
    delete withoutNoItems.color;
    delete withoutNoItems.fontFamily;

    const cardsGridCss = generateGrid({
      templateColumns: `repeat(${withoutNoItems.columns}, minmax(0, 1fr))`,
      rowGap: withoutNoItems.distance,
      columnGap: withoutNoItems.distance,
    });

    cssString += generateCssClassWithContent({
      className: withoutNoItems._cssClass,
      content:
        cardsGridCss + generateMarginStyle(withoutNoItems.spacing.margin),
    });

    cssString += generateCssClassWithContent({
      className: withoutNoItems._itemCssClass,
      content: generateDefaultStyles(withoutNoItems),
    });

    return cssString;
  };

export const generateEventInfoCssString =
  (eventInfo: IWidgetField) =>
  (cssString: string): string => {
    const { options } = eventInfo;

    const eventInfoDisplaySettings = generateFlex({
      flex: "flex",
      direction: options.displayMode === "center" ? "row" : "column",
      align: options.displayMode === "center" ? "center" : "stretch",
      justify: "flex-start",
      gap: options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        eventInfoDisplaySettings +
        "min-width:0;",
    });

    /*
      Event info item
    */

    const eventInfoItemSettings = generateFlex({
      flex: "flex",
      direction: "row",
      align: "center",
      justify: "space-between",
      gap: "0",
    });

    let secondItemCss = "";

    if (options.displayMode === "center") {
      secondItemCss += "flex-direction: row-reverse;";
    }

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: eventInfoItemSettings + "flex: 1;",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":nth-child(2)",
      content: secondItemCss,
    });

    return cssString;
  };

export const generatePlayerNameCssString =
  (player: IWidgetField, eventInfo: IWidgetField) =>
  (cssString: string): string => {
    const { options } = player;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        "flex: 1;min-width:0;overflow:hidden;text-overflow:ellipsis;",
    });

    if (eventInfo.options.displayMode === "center") {
      let secondItemCss = "";

      secondItemCss +=
        `text-align: ${
          getTextAlignStyleReversed(options.alignment).textAlign
        };` +
        generatePaddingStyle({
          ...options.padding,
          left: options.padding.right,
          right: options.padding.left,
        });

      cssString += generateCssClassWithContent({
        className: eventInfo.options._itemCssClass,
        childClassName: options._cssClass,
        pseudoClassName: ":nth-child(2)",
        content: secondItemCss,
      });
    }
    return cssString;
  };

export const generateBetItemsCssString =
  (betItems: IWidgetField, toRemoveGridOptions?: string[]) =>
  (cssString: string): string => {
    const { options } = betItems;

    const gridOptions: Record<string, string | number> = {
      gridAutoFlow:
        options.displayMode === DisplayOrientation.HORIZONTAL
          ? "column"
          : "row",
      rowGap: options.distance,
      columnGap: options.distance,
      templateColumns:
        options.displayMode === DisplayOrientation.HORIZONTAL
          ? `repeat(auto-fit, minmax(calc(33.33334% - ${getPxValueFromNumber(
              options.distance
            )}), 1fr))`
          : "",
    };

    if (toRemoveGridOptions) {
      toRemoveGridOptions.forEach(optionName => {
        delete gridOptions[optionName];
      });
    }

    const betItemsContainerCss = generateGrid(gridOptions);
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        betItemsContainerCss +
        generateMarginStyle(options.containerMargins ?? 0),
    });

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content:
        nameCssContent +
        "overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:calc(100% - 8px);",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const itemFlexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content:
        generateDefaultStyles(options) +
        itemFlexString +
        "min-width: 0px;" +
        "cursor: pointer;",
    });

    const classNames: Record<string, string> = {
      up: options._itemUpCssClass,
      down: options._itemDownCssClass,
    };

    const betAdditionalStateCss = "position: relative;overflow: hidden;";

    const afterDefaultCss =
      "display: block;" +
      "position: absolute;" +
      "right: -10px;" +
      "width: 27px;" +
      "height: 15px;" +
      "z-index: 2;" +
      "content:'';";

    const afteUpCss =
      "top: -7px;" +
      "transform: rotate(45deg);" +
      generateFillColorStyle(betItems.options.states.up.arrow?.up?.color);

    const afteDownCss =
      "transform: rotate(-45deg);" +
      "bottom: -7px;" +
      generateFillColorStyle(betItems.options.states.down.arrow?.down?.color);

    for (const stateKey of ["up", "down"]) {
      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        content:
          generateBorderStyle(betItems.options.states[stateKey].border) +
          generateFillColorStyle(betItems.options.states[stateKey].fill) +
          generateShadowStyle(betItems.options.states[stateKey].shadow) +
          betAdditionalStateCss,
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']:after`,
        content:
          afterDefaultCss + (stateKey === "down" ? afteDownCss : afteUpCss),
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        childClassName: betItems.options.coef._cssClass,
        content:
          generateTextColor(betItems.options.states[stateKey].coef.color) +
          generateDecorationStyle(
            betItems.options.states[stateKey].coef.decoration
          ),
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        childClassName: betItems.options.name._cssClass,
        content:
          generateTextColor(betItems.options.states[stateKey].name.color) +
          generateDecorationStyle(
            betItems.options.states[stateKey].name.decoration
          ),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      parentId: FRONTEND_APP_CONTAINER_ID,
      content: generateDefaultStyles(betItems.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      childClassName: betItems.options.coef._cssClass,
      content: generateDefaultStyles(betItems.options.states.active.coef),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      childClassName: betItems.options.name._cssClass,
      content: generateDefaultStyles(betItems.options.states.active.name),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true'][data-up='true']:after",
      content:
        afterDefaultCss +
        "top: -7px;" +
        "transform: rotate(45deg);" +
        generateFillColorStyle(betItems.options.states.active.arrow?.up?.color),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true'][data-down='true']:after",
      content:
        afterDefaultCss +
        "transform: rotate(-45deg);" +
        "bottom: -7px;" +
        generateFillColorStyle(
          betItems.options.states.active.arrow?.down?.color
        ),
    });

    cssString += generateDefaultStylesWithStates(
      {
        options: {
          ...options,
          _cssClass: options._itemCssClass,
        },
      },
      false,
      ["up", "down", "suspended", "hover"]
    );

    // Hover styles for bet items
    cssString += `@media (hover: hover) and (pointer: fine) {.${
      options._itemCssClass
    }:hover {${generateDefaultStyles(betItems.options.states.hover)}}}`;
    cssString += `.${
      options._itemCssClass
    }:focus-visible {${generateDefaultStyles(betItems.options.states.hover)}}`;

    cssString += `@media (hover: hover) and (pointer: fine) {.${
      options._itemCssClass
    }:hover .${betItems.options.coef._cssClass} {${generateDefaultStyles(
      betItems.options.states.hover.coef,
      ["theme"]
    )}}}`;
    cssString += `.${options._itemCssClass}:focus-visible .${
      betItems.options.coef._cssClass
    } {${generateDefaultStyles(betItems.options.states.hover.coef, [
      "theme",
    ])}}`;

    cssString += `@media (hover: hover) and (pointer: fine) {.${
      options._itemCssClass
    }:hover .${betItems.options.name._cssClass} {${generateDefaultStyles(
      betItems.options.states.hover.name,
      ["theme"]
    )}}}`;
    cssString += `.${options._itemCssClass}:focus-visible .${
      betItems.options.name._cssClass
    } {${generateDefaultStyles(betItems.options.states.hover.name, [
      "theme",
    ])}}`;

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":hover[data-up='true']:after",
      content:
        afterDefaultCss +
        "top: -7px;" +
        "transform: rotate(45deg);" +
        generateFillColorStyle(betItems.options.states.hover.arrow?.up?.color),
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":hover[data-down='true']:after",
      content:
        afterDefaultCss +
        "transform: rotate(-45deg);" +
        "bottom: -7px;" +
        generateFillColorStyle(
          betItems.options.states.hover.arrow?.down?.color
        ),
    });

    for (const state in betItems.options.states) {
      if (
        state == "hover" ||
        state == "up" ||
        state === "down" ||
        state === "suspended"
      ) {
        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:${state}`,
        childClassName: betItems.options.name._cssClass,
        content: generateDefaultStyles(betItems.options.states[state].name, [
          "theme",
        ]),
      });

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:${state}`,
        childClassName: betItems.options.coef._cssClass,
        content: generateDefaultStyles(betItems.options.states[state].coef, [
          "theme",
        ]),
      });
    }

    return cssString;
  };

export const generateListTimeCssString =
  (startTime: IWidgetField) =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generatePaddingStyle(options.padding),
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(
          {
            ...startTime.options,
            padding: null,
          },
          ["spaceBetween"]
        ) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          gap: "4",
        }) +
        generateMarginStyle({ right: options.spaceBetween }),
    });

    return cssString;
  };

export const actionButtonCustomCssString =
  (button: IWidgetField) =>
  (cssString: string): string => {
    const { options } = button;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "display: inline-block;",
    });

    return cssString;
  };

export const noItemsTextBlockStylesCssString =
  (cssClass: string, el: IWidgetField) =>
  (cssString: string): string => {
    const { options } = el;

    cssString += generateCssClassWithContent({
      className: cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateSportsbookLiveWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const event_cards = widget.content["event_cards"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const event_time = widget.content["event_time"];
  const event_league = widget.content["event_league"];
  const event_info = widget.content["event_info"];
  const market_name = widget.content["market_name"];
  const event_team_player = widget.content["event_team_player"];
  const event_score = widget.content["event_score"];
  const bet_items = widget.content["bet_items"];
  const action_button = widget.content["event_button"];
  const stream_icon = widget.content["stream_icon"];
  const favourites_button = widget.content["favourites_button"];

  widget.options._cssClass = generateClassName("SportsbookLive");
  title.options._cssClass = generateClassName("title");

  event_cards.options._cssClass = generateClassName("event_cards");
  event_cards.options._itemCssClass = generateClassName("event_cards_item");
  // event_cards.options._noContentTextCssClass = generateClassName(
  //   "event_cards_no_content"
  // );
  event_suspended_status.options._cssClass = generateClassName(
    "event_suspended_status"
  );
  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_league.options._cssClass = generateClassName("event_league");
  event_info.options._cssClass = generateClassName("event_info");
  event_info.options._itemCssClass = generateClassName("event_info_item");
  market_name.options._cssClass = generateClassName("market_name");
  event_team_player.options._cssClass = generateClassName("event_team_player");
  event_score.options._cssClass = generateClassName("event_score");
  bet_items.options._cssClass = generateClassName("bet_items");

  bet_items.options._itemCssClass = generateClassName("bet_items_item");
  bet_items.options._itemSelectedCssClass = bet_items.options._itemCssClass;
  bet_items.options._itemUpCssClass = bet_items.options._itemCssClass;
  bet_items.options._itemDownCssClass = bet_items.options._itemCssClass;

  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");

  action_button.options._cssClass = generateClassName("event_button");
  favourites_button.options._cssClass = generateClassName("favourites_button");

  stream_icon.options._cssClass = generateClassName("stream_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringWithStates(title, false),
    generateEventCardsCssString(event_cards as IWidgetField),
    generateStringDefault(event_suspended_status, ["size"]),
    generateListTimeCssString(event_time as IWidgetField),
    generateStringDefault(event_league),
    generateStringDefault(event_info),
    generateStringDefault(market_name),
    generatePlayerNameCssString(
      event_team_player as IWidgetField,
      event_info as IWidgetField
    ),
    generateStringDefault(event_score),
    generateStringWithStates(action_button, false),
    actionButtonCustomCssString(action_button as IWidgetField),
    generateEventInfoCssString(event_info as IWidgetField),
    generateBetItemsCssString(bet_items as IWidgetField),
    generateIconCssString(stream_icon),
    generateFavouritesButtonCssString(favourites_button)
  )("");
};
