<template>
  <div
    :class="`betslip-widget__form-container--${fields.form.field.options.display?.layout}`"
    class="betslip-widget__form-container w-100"
    :style="stakeInfoStyle"
  >
    <div class="betslip-widget__cards-form w-100">
      <WidgetField
        v-if="fields.form.field.options._active"
        :widget="widget"
        class="w-100"
        :style="betslipFormContainerInlineStyles"
        :field="fields.form.field"
      >
        <div
          v-if="
            mode === 'multibet' || states.bets_container === State.MULTI_BETS
          "
          class="betslip-widget__cards-coef-container betslip-widget__form-title"
        >
          <WidgetField
            v-if="isFenix"
            :widget="widget"
            :field="fields.event_name_title.field"
          >
            <span :style="eventNameInlineStyles">Multibet</span>
          </WidgetField>

          <span
            v-else
            :style="eventNameInlineStyles"
            >Multibet</span
          >
          <WidgetField
            v-if="fields.coefficient_title.active"
            :widget="widget"
            :field="fields.coefficient_title.field"
          >
            <div :style="coefficientInlineStyles">0,00</div>
          </WidgetField>
        </div>

        <div
          v-if="
            mode === 'system' || states.bets_container === State.SYSTEM_BETS
          "
          class="betslip-widget__cards-coef-container betslip-widget__form-title"
        >
          <WidgetField
            v-if="isFenix"
            :widget="widget"
            :field="fields.event_name_title.field"
          >
            <span :style="eventNameInlineStyles"> Double 2/3 (3 bets) </span>
          </WidgetField>

          <span
            v-else
            :style="eventNameInlineStyles"
          >
            Double 2/3 (3 bets)
          </span>

          <WidgetField
            v-if="showSystemBet && fields.coefficient_title.active"
            :widget="widget"
            :field="fields.coefficient_title.field"
          >
            <div :style="coefficientInlineStyles">0,00</div>
          </WidgetField>
        </div>

        <div
          :style="inputsContainerStyle"
          class="betslip-widget__form-inputs"
        >
          <div :class="{ 'm-b-8': !isFenix }">
            <div
              class="betslip-widget__form-content"
              :class="`betslip-widget__form-content--${fields.labels_styling.field.options.position}`"
            >
              <div class="betslip-widget__label-container">
                <span class="betslip-widget__label-text-container">
                  <span
                    class="betslip-widget__label-text"
                    :style="formLabelsInlineStyle"
                  >
                    Stake
                    <CommonIcon
                      v-if="states.bets_container === State.SINGLE_BETS"
                      :style="formLabelIconInlineStyle"
                      :name="
                        fields.fields_styling.field.options.iconRight.value ||
                        'ant-design:copy-outlined'
                      "
                      :size="
                        fields.fields_styling.field.options.iconRight.iconSize
                      "
                    />
                  </span>
                </span>
              </div>

              <div class="betslip-widget__form-input-container">
                <a-input
                  placeholder="0.00"
                  :style="formInputInlineStyles"
                  class="betslip-widget__form-input w-100"
                  type="text"
                  readonly
                >
                </a-input>

                <p
                  v-if="isErrorVisible"
                  :style="formErrorStyles"
                  class="betslip-widget__error"
                >
                  Error text goes here
                </p>
              </div>
            </div>
          </div>

          <div v-if="showSystemBet">
            <div
              class="betslip-widget__form-content"
              :class="`betslip-widget__form-content--${fields.labels_styling.field.options.position}`"
            >
              <div class="betslip-widget__label-container">
                <span class="betslip-widget__label-text-container">
                  <span
                    class="betslip-widget__label-text"
                    :style="formLabelsInlineStyle"
                  >
                    Total Stake
                  </span>
                </span>
              </div>

              <BetslipInput
                :widget="widget"
                :fields="fields"
                :states="states"
              />
            </div>
          </div>

          <div>
            <div
              class="betslip-widget__form-content"
              :class="`betslip-widget__form-content--${fields.labels_styling.field.options.position}`"
            >
              <div class="betslip-widget__label-container">
                <span class="betslip-widget__label-text-container">
                  <span
                    class="betslip-widget__label-text"
                    :style="formLabelsInlineStyle"
                  >
                    {{ t("widgets.BetslipWidget.potential_return") }}</span
                  >
                </span>
              </div>

              <div class="betslip-widget__form-input-container">
                <a-input
                  placeholder="0.00"
                  :style="formInputInlineStyles"
                  class="betslip-widget__form-input w-100"
                  type="text"
                  readonly
                >
                </a-input>
                <p
                  v-if="isErrorVisible"
                  :style="formErrorStyles"
                  class="betslip-widget__error"
                >
                  Error text goes here
                </p>
              </div>
            </div>
          </div>
        </div>
      </WidgetField>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipFormInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipFormInlineStyles";
import { useBetslipCardsInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipCardsInlineStyles";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
  mode?: string;
  showSystemBet?: boolean;
  isFenix?: boolean;
  stakeInfo?: IWidgetField;
}>();

const { t } = useI18n();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);

const isErrorVisible = computed<boolean>(() => {
  if (!selectedField.value) return false;

  return (
    selectedField.value.name === "errors_styling" &&
    selectedWidget.value?.id === props.widget.id
  );
});

const {
  formErrorStyles,
  formInputInlineStyles,
  formLabelsInlineStyle,
  formLabelIconInlineStyle,
  coefficientInlineStyles,
  betslipFormContainerInlineStyles,
} = useBetslipFormInlineStyles(
  ref(props.fields),
  ref(props.states),
  isErrorVisible
);

const { eventNameInlineStyles } = useBetslipCardsInlineStyles(
  ref(props.fields),
  ref(props.states)
);

const inputsContainerStyle = computed<ElementStyle>(() => {
  if (!props.fields.form.field.options.display) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: getPxValueFromNumber(props.fields.form.field.options.display.distance),
  };
});

const stakeInfoStyle = computed<ElementStyle>(() => {
  if (!props.stakeInfo) {
    return {};
  }

  return {
    flexDirection: "column",
    alignItems: "stretch",
    gap: getPxValueFromNumber(props.stakeInfo.options.display.distance),
  };
});
</script>

<style lang="scss">
.betslip-widget {
  &__form-content {
    @include flex(center, flex-start, 0px);

    &--horizontal {
      flex-direction: row;
    }

    &--vertical {
      flex-direction: column;
    }
  }

  &__label-container,
  &__form-input-container {
    width: 100%;
  }

  &__label-text {
    display: flex;
    align-items: center;
  }

  &__form-input::placeholder {
    color: var(--placeholderColor) !important;
    text-decoration: var(--textDecoration) !important;
    text-decoration-color: var(--textDecorationColor) !important;
  }

  &__form-title {
    padding-bottom: 8px;
  }
}
</style>
