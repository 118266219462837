import { uuidv7 } from "uuidv7";

import { deepCopy, generateId } from "~~/assets/utils";
import {
  IWidgetWithFields,
  WidgetType,
  IWidgetField,
  ICustomField,
  IWidgetOptions,
  IWidgetMetaOptions,
  IWidgetFieldItem,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IPageContent,
  IGridWidgetsData,
  IPageContentWidget,
  IContentField,
  IPageWidgetDetails,
  InnerFieldValue,
} from "~~/models/page.model";
import { ICell, Grid, CellId } from "~~/models/grid.interface";
import { Breakpoint } from "~~/models/breakpoints.model";
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";
import { FieldType } from "~~/models/widgets/widget.core/field-types.enum";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useLocalisedField } from "~~/composables/fields/useLocalisedField";

import { resetOnlyNumericId } from "../grid";

import { generateStylingFormFields } from "./form";

/** Recursively copy widgetWithFields cell and it's children creating new id
 * @param widgetWithFields - widgetWithFields to copy
 * @param parentId - id of parent widgetWithFields
 * @returns new widgetWithFields
 */
export function deepCopyWidgetWithFields(
  widgetWithFields: IWidgetWithFields,
  parentId?: string
): IWidgetWithFields {
  const copy = deepCopy(widgetWithFields);

  return changeWidgetWithFieldsIds(copy, parentId);
}

export const changeWidgetWithFieldsIds = (
  widgetWithFields: IWidgetWithFields,
  parentId?: string
): IWidgetWithFields => {
  const widgetId = uuidv7();

  return {
    ...widgetWithFields,
    id: widgetId,
    parentId: parentId || widgetWithFields.parentId,
    fields: widgetWithFields.fields.map(field => ({
      ...field,
      id: generateId(),
      parent_id: widgetId,
      value: Array.isArray(field.value)
        ? (field.value as ICustomField[]).map(customField => ({
            ...customField,
            fields: customField.fields?.map(customFieldItem => ({
              ...customFieldItem,
              id: generateId(),
            })),
            id: generateId(),
          }))
        : field.value,
    })),
    // children: widgetWithFields.children?.map(child =>
    //   changeWidgetWithFieldsIds(child)
    // ),
  };
};

export const generateWidgetField = (
  name: string,
  parentId: number | string,
  type = "",
  title = "",
  value:
    | Record<string, any>
    | string
    | null
    | number
    | ICustomField[]
    | InnerFieldValue = "",
  initialOptions?: IWidgetOptions,
  validation: Record<string, any> = {},
  items?: IWidgetFieldItem[],
  readonly?: boolean,
  exactName?: boolean
): IWidgetField => ({
  id: generateId(),
  parent_id: parentId,
  type,
  name: getFieldName(type, name, exactName),
  title,
  placeholder: "Enter value",
  default_value: useLocalizedValue().getLocalizedValue.value(value),
  validation,
  options: {
    _active: true,
    ...(initialOptions || {}),
  },
  value: useLocalizedValue().transformToLocalizedValue(value).localizedValue,
  items,
  readonly,
});

const generateFieldItems = (parentId: string | number) => {
  const itemField = generateWidgetField(
    FieldName.ITEM,
    parentId,
    FieldType.TEXT_INPUT,
    "Item",
    "item"
  );

  return [
    {
      ...itemField,
      options: {
        ...itemField.options,
      },
    },
  ];
};

/** Generate widget fields
 * @param widgetElement - IWidgetsListElement
 * @returns array of widget fields
 */
const generateWidgetFields = (
  widgetElement: IWidgetsListElement,
  parentId: string | number
): IWidgetField[] => {
  // const widgetFields: IWidgetField[] = [
  //   ...generateCustomWidgetFields(
  //     clientEndWidgetData,
  //     customOptionsList,
  //     parentId
  //   ),
  // ];
  const widgetFields: IWidgetField[] = [];

  for (const key in widgetElement.content) {
    const fieldDetails = widgetElement.content[key];

    // const customStyleFieldsList = fieldOptions._meta?._customFieldsNames || [];
    // const customStyleFields = generateCustomWidgetFields(
    //   clientEndWidgetData,
    //   customStyleFieldsList,
    //   parentId
    // );

    const field: IWidgetField = generateWidgetField(
      key,
      parentId,
      fieldDetails.type,
      "",
      fieldDetails.value
    );

    if (fieldDetails.type === FieldType.ARRAY) {
      field.value = [
        {
          ...generateWidgetField(FieldName.ITEMS_GROUP, parentId),
          fields: generateFieldItems(parentId),
          options: {},
        },
      ];
    }

    field.options = {
      ...field.options,
    };

    // widgetFields.push(...[field, ...customStyleFields]);
    widgetFields.push(field);
  }

  return widgetFields;
};

/** Create widget from widget list element
 * @param widgetElement - IWidgetsListElement
 * @returns new widgetWithFields
 */
export function createWidgetFromListElement(
  widgetElement: IWidgetsListElement,
  cell_id?: CellId
): IWidgetWithFields {
  const widgetId = uuidv7();

  const fields = generateWidgetFields(widgetElement, widgetId);

  const widget: IWidgetWithFields = {
    id: widgetId,
    static_id: widgetElement.uuid,
    parentId: null,
    cell_id,
    widget_id: widgetElement.name,
    name: widgetElement.name,
    type: WidgetType.BASIC,
    position: 0,
    options: {},
    fields,
  };

  return widget;
}

/*
  Creates field, which is considered as styles storage(doesn't have own value).
  It behaves like a normal field(has own dropdown item),
  but ON BE it will be saved inside widget.options._customFields value.
*/
export const createCustomField = (
  sourceOptions: IWidgetMetaOptions,
  parentId: string | number,
  name: string,
  title: string
): IWidgetField => {
  return {
    id: generateId(),
    parent_id: parentId,
    name: name,
    type: "",
    title: title,
    validation: {},
    options: {
      /*
        Add flag to know that this is custom field
      */
      _custom: true,
      /*
          Make active true by default.
          Otherwise field won't be active in dropdown
      */
      _active: true,
      ...sourceOptions,
    },
  };
};

const generateContentInnerFields = (
  innerFields: Record<string, IContentField>,
  parentId: string | number
): IWidgetField[] => {
  const fields: IWidgetField[] = [];

  for (const key in innerFields) {
    const fieldDetails = innerFields[key];

    const field = {
      id: generateId(),
      parent_id: parentId,
      name: fieldDetails.name,
      type: fieldDetails.type,
      validation: fieldDetails.validation || {},
      options: fieldDetails.options,
      items: fieldDetails.items,
      position: fieldDetails.position,
      readonly: fieldDetails.readonly,
    };

    fields.push(field as IWidgetField);
  }

  return fields;
};

/** Generate widget fields
 * @param widgetElement - IWidgetsListElement
 * @returns object with widget options
 */
const generateWidgetFieldsFromContent = (
  widgetElement: IPageContentWidget
): IWidgetField[] => {
  return Object.keys(widgetElement.content).reduce<IWidgetField[]>(
    (widgetFields, key, index) => {
      const fetchedFieldData = widgetElement.content[key];
      const fieldId = generateId();

      let innerFields: IWidgetField[] = [];
      const fetchedFieldValue = fetchedFieldData.value as any;

      if (fetchedFieldValue?.fields) {
        innerFields = generateContentInnerFields(
          fetchedFieldValue.fields as Record<string, IContentField>,
          fieldId
        );
      }

      const field = {
        ...fetchedFieldData,
        id: fieldId,
        parent_id: widgetElement.id!,
        type: fetchedFieldData.type,
        name: key,
        // title: fetchedFieldData.title,
        position: index,
        placeholder: "Enter value",
        default_value: "Enter value",
        options: fetchedFieldData.options || {},
        validation: fetchedFieldData.validation || {},
        readonly: fetchedFieldData.readonly,
        fields:
          fetchedFieldData.fields && Array.isArray(fetchedFieldData.fields)
            ? fetchedFieldData.fields.map(field => ({
                ...field,
                id: generateId(),
              }))
            : undefined,
        value: Array.isArray(fetchedFieldData.value)
          ? (fetchedFieldData.value as ICustomField[]).map(valueItem => ({
              ...valueItem,
              fields: valueItem.fields?.map(fieldItem => ({
                ...fieldItem,
                id: generateId(),
              })),
              id: generateId(),
              options: valueItem.options,
            }))
          : fetchedFieldData.value,
      };

      widgetFields.push(...[field, ...innerFields]);

      return widgetFields;
    },
    []
  );
};

/** Create widget from page content element
 * @param widgetElement - IWidgetsListElement
 * @returns new widgetWithFields
 */
export function createWidgetFromContentElement(
  widgetElement: IPageContentWidget
): IWidgetWithFields {
  const fields = generateWidgetFieldsFromContent(widgetElement);

  let childrenWidgets: IWidgetWithFields[] | undefined = undefined;

  if (widgetElement.options._children) {
    childrenWidgets = widgetElement.options._children.map(child =>
      createWidgetFromContentElement(child)
    );
  }

  const widget: IWidgetWithFields = {
    id: widgetElement.id!,
    static_id: widgetElement.staticId,
    parentId: widgetElement.parentId,
    cell_id: widgetElement.cellId,
    widget_id: widgetElement.name,
    name: widgetElement.name,
    type: WidgetType.BASIC,
    position: widgetElement.position ?? 0,
    options: {
      ...widgetElement.options,
      _children: childrenWidgets,
    },
    fields: fields,
  };

  return widget;
}

/* 
  Generate list of widgets with format:
  {
    ...widget,
    _children: childrenWidgets[]
  }
*/
export const generateGroupedWidgets = (
  widgets: IWidgetWithFields[]
): IWidgetWithFields[] => {
  const currWidgets = [...widgets];

  const asObjectById: Record<string, IWidgetWithFields> = currWidgets.reduce(
    (result, widget) => {
      (result as Record<string, IWidgetWithFields>)[widget.id] = widget;

      return result;
    },
    {}
  );

  const resultWidgets: IWidgetWithFields[] = [];

  currWidgets.forEach(widget => {
    if (!widget.parentId) {
      /* 
        Push root widgets to result.
        Children widgets will be placed in _children
      */
      resultWidgets.push(widget);
      return;
    }

    const parentWidget = asObjectById[widget.parentId];

    if (!parentWidget.options._children) {
      parentWidget.options._children = [];
    }

    parentWidget.options._children.push(widget);
  });

  return resultWidgets;
};

export const generateContentFromField = (
  field: IWidgetField
): IContentField => ({
  // hack: to pass backend validation for blank types
  type: field.type || "blankType",
  items: field.items,
  value: field.value || null,
  options: field.options,
  name: field.name,
  validation: field.validation,
  readonly: field.readonly,
});

/*
  Replace all forms fields to one name
*/
export const getFieldName = (
  fieldType: string,
  fieldName: string,
  exactName?: boolean
): string => {
  if (fieldType === "FormField" && !exactName) {
    return "form";
  }

  return fieldName;
};

export const generateContentFromFields = (
  fields: IWidgetField[]
): Record<string, IContentField> => {
  /*
    If field has parentName, we need to move
    it inside other field(parent).value
  */
  const childrenFields: Record<string, Record<string, IContentField>> = {};

  const generatedContent = fields.reduce(
    (result: Record<string, IContentField>, field) => {
      /*
      Check if field has parent name(place then field inside value of parent)
    */
      const parentName = field.options._parent;

      if (parentName) {
        if (!childrenFields[parentName]) {
          childrenFields[parentName] = {};
        }

        childrenFields[parentName][field.name] =
          generateContentFromField(field);

        return result;
      }

      result[field.name] = generateContentFromField(field);

      return result;
    },
    {}
  );

  Object.keys(childrenFields).forEach(fieldName => {
    if (!generatedContent[fieldName]) {
      return;
    }

    generatedContent[fieldName].value = {
      fields: childrenFields[fieldName],
    };
  });

  return generatedContent;
};

const divideWidgetFields = (fields: IWidgetField[]) => {
  const customFields: IWidgetField[] = [];
  const defaultFields: IWidgetField[] = [];

  for (const field of fields) {
    if (field.options._custom) {
      customFields.push(field);
      continue;
    }

    defaultFields.push(field);
  }

  return {
    customFields,
    defaultFields,
  };
};

export const createContentWidget = (
  widget: IWidgetWithFields,
  cellId: string
): IPageContentWidget => {
  const dividedFields = divideWidgetFields(widget.fields);
  const customFieldsData = generateContentFromFields(
    dividedFields.customFields
  );

  const customFields =
    customFieldsData && Object.keys(customFieldsData).length
      ? customFieldsData
      : widget.options._customFields;

  return {
    id: resetOnlyNumericId(widget.id),
    staticId: widget.static_id,
    name: widget.name,
    position: widget.position,
    cellId: cellId,
    parentId: widget.parentId,
    options: {
      ...widget.options,
      _customFields: customFields,
      /* 
        Clear children here, since they will be saved on root level
      */
      _children: undefined,
    },
    content: generateContentFromFields(dividedFields.defaultFields),
  };
};

/* 
  Recursively generate content widgets list 
  {
    ...contentWidget,
    _children:[
      ...,
      {
        ...,
        _children: [...]
      }
    ]
  }
*/
export const createContentWidgetsList = (
  widgets: IWidgetWithFields[],
  cellId: string
): IPageContentWidget[] => {
  const result: IPageContentWidget[] = [];

  widgets.forEach(curr => {
    result.push(createContentWidget(curr, cellId));

    if (curr.options._children) {
      result.push(...createContentWidgetsList(curr.options._children, cellId));
    }
  });

  return result;
};

export const generatePageWidgetsContent = (cells: ICell[]): IPageContent => {
  return cells.reduce((cellsContent, currCell) => {
    let result = {};

    if (currCell.children && currCell.children.length) {
      result = {
        ...generatePageWidgetsContent(currCell.children),
      };
    }

    if (!currCell.widgets || !currCell.widgets.length) {
      return { ...cellsContent, ...result };
    }

    const contentWidgets = createContentWidgetsList(
      currCell.widgets,
      currCell.cellId as string
    );

    return {
      ...cellsContent,
      ...result,
      [currCell.cellId]: {
        cellId: currCell.cellId,
        widgets: contentWidgets,
      },
    };
  }, {});
};

export const generateGridWidgetsData = (
  grid: Grid,
  templateId: string | number | null
): IGridWidgetsData | null => {
  if (!templateId) {
    return null;
  }

  const result: IGridWidgetsData = {
    templateId: templateId,
    pageContent: {},
  };

  for (const key in grid) {
    const cells = grid[key as Breakpoint] as ICell[];

    result.pageContent = {
      ...result.pageContent,
      ...generatePageWidgetsContent(cells),
    };
  }

  return result;
};

export const getFieldByName = (widget: IWidgetWithFields, name: string) => {
  for (const field of widget.fields) {
    if (field.name === name) return field;

    if (Array.isArray(field.value)) {
      const customFields = field.value as ICustomField[];

      for (const customField of customFields) {
        if (customField.name === name) return customField;
      }
    }
  }
};

export const getFieldById = (
  widget: IWidgetWithFields,
  id: string | number
) => {
  for (const field of widget.fields) {
    if (field.id === id) return field;

    if (!Array.isArray(field.value)) continue;

    const customFields = field.value as ICustomField[];

    for (const customField of customFields) {
      if (customField.id === id) return customField;

      if (!customField.fields) continue;

      for (const fieldItem of customField.fields) {
        if (fieldItem.id === id) return fieldItem;
      }
    }
  }
};

export const isObject = (value: unknown): boolean => {
  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    return false;
  }

  return typeof value === "object";
};

/*
  Source options - already existing options(Can be empty).
  prefillOptions - options generated on FE
*/
export const prefillEmptyOptions = (
  sourceOptions: IWidgetOptions,
  prefillOptions: IWidgetOptions
): IWidgetOptions => {
  const result: IWidgetOptions = {};

  const srcOptions = sourceOptions || {};

  for (const key in prefillOptions) {
    if (key === "states") {
      result[key] = {};

      for (const stateName in prefillOptions[key]) {
        const prefillStateData = prefillOptions[key][stateName];
        const savedStateData = srcOptions?.[key]?.[stateName];

        if (isObject(savedStateData)) {
          result[key][stateName] = {
            ...prefillStateData,
            ...savedStateData,
          };

          continue;
        }

        result[key][stateName] = {
          ...prefillStateData,
        };
      }

      continue;
    }

    if (Object.hasOwn(srcOptions, key)) {
      if (isObject(srcOptions[key]) && isObject(prefillOptions[key])) {
        result[key] = {
          ...prefillOptions[key],
          ...srcOptions[key],
        };

        continue;
      }

      if (isObject(prefillOptions[key])) {
        result[key] = {
          ...prefillOptions[key],
        };

        continue;
      }

      result[key] = srcOptions[key];
      continue;
    }

    result[key] = prefillOptions[key];
  }

  return {
    ...srcOptions,
    ...result,
  };
};

export const isSimpleValue = (value: unknown): boolean => {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "undefined" ||
    value === null
  );
};

const mergeFieldItems = (
  fieldFromStatic: IContentField,
  fieldFromGetContent?: IContentField
) => {
  const fieldFromStaticItems = fieldFromStatic?.items;

  const itemsListFromStatic = fieldFromStaticItems?.reduce((acc, item) => {
    acc[item.value] = {
      label: item.label,
      value: item.value,
    };
    return acc;
  }, {} as Record<string, any>);

  const items: any[] = fieldFromGetContent?.options?.items || [];

  const existedOldKeys: Record<string, true> = {};

  if (itemsListFromStatic) {
    items.forEach((item, index) => {
      if (!itemsListFromStatic[item.value]) {
        items.splice(index, 1);
      } else {
        existedOldKeys[item.value] = true;
      }
    });

    Object.keys(itemsListFromStatic).forEach(itemValue => {
      if (!existedOldKeys[itemValue]) {
        items.push({
          label: useLocalizedValue().transformToLocalizedValue(
            itemsListFromStatic[itemValue].label
          ).localizedValue,
          value: itemValue,
        });
      }
    });
  }

  const uniqItems: any[] = [];

  items.reduce((acc, item) => {
    if (!acc[item.value]) {
      acc[item.value] = true;

      uniqItems.push(item);
    }

    return acc;
  }, {});

  return uniqItems;
};

export const mergeForms = (
  widgetFromStatic: Partial<IPageWidgetDetails>,
  widgetFromGetContent: IPageContentWidget
): IPageContentWidget => {
  const targetForm = Object.values(widgetFromStatic.fields!).find(
    field => field.type === "FormField"
  );

  const sourceFormName = Object.keys(widgetFromGetContent.content).find(
    fieldName => widgetFromGetContent.content[fieldName].type === "FormField"
  );

  const sourceForm = widgetFromGetContent.content[sourceFormName!];

  const resultFields: Record<string, IContentField> = {};
  let newCustomFields: IWidgetOptions = {};

  const sourceValue = sourceForm.value as InnerFieldValue;
  const targetValue = targetForm!.value as InnerFieldValue;

  for (const fieldName in targetValue.fields) {
    const fieldFromStatic = targetValue.fields[fieldName];
    const fieldFromGetContent = sourceValue?.fields?.[fieldName];

    const itemsFromStatic = fieldFromStatic?.items;

    const mergedItems = mergeFieldItems(fieldFromStatic, fieldFromGetContent);

    if (fieldFromGetContent) {
      resultFields[fieldName] = {
        ...fieldFromGetContent,
        items: itemsFromStatic,
        readonly: Boolean(fieldFromStatic?.readonly),
        validation: fieldFromStatic?.validation || {},
      };

      resultFields[fieldName].options = resultFields[fieldName].options || {};
      resultFields[fieldName].options._active =
        fieldFromStatic?.validation?.required ||
        resultFields[fieldName].options._active ||
        false;
      resultFields[fieldName].options.items = mergedItems;
    } else {
      resultFields[fieldName] = useLocalisedField().toLocalised({
        ...fieldFromStatic,
        options:
          fieldFromStatic.type === "FormHiddenField"
            ? { _parent: "form" }
            : {
                items: mergedItems?.length ? mergedItems : undefined,
              },
        type: fieldFromStatic.type || "",
      });

      newCustomFields = {
        ...newCustomFields,
        ...generateStylingFormFields(
          fieldFromStatic.type,
          widgetFromGetContent.id!
        ),
      };
    }
  }

  return {
    ...widgetFromGetContent,
    content: {
      ...widgetFromGetContent.content,
      [sourceFormName!]: {
        ...sourceForm,
        value: {
          fields: resultFields,
        },
      },
    },
    options: {
      ...widgetFromGetContent.options,
      _customFields: {
        ...newCustomFields,
        ...widgetFromGetContent.options._customFields,
      },
    },
  };
};
