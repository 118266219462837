<!-- eslint-disable vue/no-v-html -->
<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div class="html-editor-widget">
      <HtmlEditorWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
      />

      <div v-if="selectedWidget?.id === widget?.id">
        <CommonHtmlEditor
          :model-value="
            fields.html_editor_field.field.value ||
            fields.html_editor_field.field.default_value
          "
          @update:model-value="handleUpdate"
        />
      </div>

      <div
        v-else
        class="html-editor-widget__content"
        v-html="localizedValue"
      ></div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);
const { initWidgetOptions } = useCreateFields(props.widget);

const { getLocalizedValue } = useLocalizedValue();

/*
  Initialize widget with initial options
*/

const localizedValue = computed(() =>
  getLocalizedValue.value(
    fields.value.html_editor_field.field.value ||
      fields.value.html_editor_field.field.default_value ||
      {}
  )
);

const handleUpdate = (value: Record<string, any> | undefined | null): void => {
  widgetsStore.updateFieldValue(fields.value.html_editor_field.field.id, value);
};

const initialWidgetOptions = initWidgetOptions();

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
</script>

<style lang="scss">
.html-editor-widget {
  &__content {
    ul {
      list-style-type: disc;
      padding-inline-start: 40px;
    }

    a {
      pointer-events: none;
    }
  }
}
</style>
