import { parseId, throttle } from "~~/assets/utils";
import {
  PAYLOAD_DND_KEY,
  RECALCULATE_DIVIDER_FREQUENCY,
} from "~~/constants/dnd";
import {
  IDraggablePayload,
  IClientBoundingRect,
  IDividerPosition,
} from "~~/models/dnd.model";
import { IInsertPosition, InsertionRule } from "~~/models/grid.interface";
import { IWidgetFieldDraggable } from "~~/models/widgets/widget.core/widget.model";

const MENU_ITEM_HEIGHT = 24;
const MENU_ITEMS_LIST_GAP = 16;
const MENU_ITEM_HEIGHT_WITH_GAP = MENU_ITEM_HEIGHT + MENU_ITEMS_LIST_GAP;

export const useDropdownItemsDnd = (
  reorderHandler: (
    targetId: number | string,
    insertPosition: IInsertPosition
  ) => void
) => {
  const handleBlockDrop = (e: DragEvent) => {
    const insertPosition = calcInsertPosition(e);

    const payloadStringified = e.dataTransfer?.getData(
      PAYLOAD_DND_KEY
    ) as string;

    const payload: IDraggablePayload = JSON.parse(payloadStringified);
    const dndPayload: IWidgetFieldDraggable = payload.payload;

    reorderHandler(parseId(dndPayload.id)!, insertPosition);
  };

  const handleDragOver = throttle((e: DragEvent) => {
    updateDividerPosition(calcInsertPosition(e));
  }, RECALCULATE_DIVIDER_FREQUENCY);

  const calcInsertPosition = (e: DragEvent): IInsertPosition => {
    let mouseYPosition = e.clientY - workspaceRootYPosition.value;

    if (mouseYPosition < 0) mouseYPosition = 0;
    const index = Math.floor(mouseYPosition / MENU_ITEM_HEIGHT_WITH_GAP);
    const innerYPosition = mouseYPosition - MENU_ITEM_HEIGHT_WITH_GAP * index;

    return {
      index,
      insertionRule:
        innerYPosition < MENU_ITEM_HEIGHT / 2
          ? InsertionRule.BEFORE
          : InsertionRule.AFTER,
    };
  };

  const workspaceRootYPosition = ref<number>(0);

  const handleUpdateContainerPosition = (e: IClientBoundingRect) => {
    workspaceRootYPosition.value = e.top;

    dividerPosition.width = e.width;
  };

  const dividerPosition = reactive<IDividerPosition>({
    top: 0,
    left: 0,
    width: 0,
  });

  const updateDividerPosition = (insertPosition: IInsertPosition) => {
    const top =
      insertPosition.index * MENU_ITEM_HEIGHT_WITH_GAP +
      (insertPosition.insertionRule === InsertionRule.BEFORE
        ? 0
        : MENU_ITEM_HEIGHT);

    dividerPosition.top = top;
  };

  return {
    handleBlockDrop,
    handleDragOver,
    handleUpdateContainerPosition,
    dividerPosition,
  };
};
