import {
  Overflow,
  SliderArrowIcon,
  SliderItemsPosition,
  State,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const TABS_INITIAL = () => ({
  value: "Header",
  options: {
    _active: true,
    fill: getDefaultFillValue("#FFFFFF"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(""),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    spaceBetween: 12,
    padding: {
      top: 8,
      bottom: 8,
      left: 8,
      right: 8,
    },
    margins: {
      top: 0,
      bottom: 16,
    },
    displaySettings: {
      resizing: "hug",
      alignment: "left",
    },
    theme: 16,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    color: getDefaultFillValue("#000000"),
    iconSettings: {
      textColor: getDefaultFillValue("#000000"),
      iconSize: 16,
      gap: 5,
      _active: true,
    },
    overflow: Overflow.HIDDEN,
    states: {
      [State.HOVER]: {
        fill: getDefaultFillValue(""),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(""),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue("#ffffff"),
        decoration: [],
        iconSettings: {
          textColor: {
            color: "#ffffff",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: getDefaultFillValue(""),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(""),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue("#ffffff"),
        decoration: [],
        iconSettings: {
          textColor: {
            color: "#ffffff",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const ARROW_INITIAL = () => ({
  value: "Arrow",
  options: {
    _active: true,
    fill: getDefaultFillValue("#FFFFFF"),
    border: getDefaultBorderValue(),
    shadow: {
      fill: getDefaultFillValue(""),
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    arrowSettings: {
      isActive: true,
      icon: SliderArrowIcon.OUTLINED,
      color: getDefaultFillValue("#4096FF"),
      size: 24,
      position: SliderItemsPosition.OUTSIDE,
    },
    cornerRadius: getDefaultCornerRadiusValue(50),
    states: {
      [State.HOVER]: {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(""),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
      },
      [State.ACTIVE]: {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(""),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
      },
    },
  },
});
