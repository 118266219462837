<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <ImageWidgetSettings :widget="widget" />

    <div class="image-widget">
      <WidgetField
        :widget="widget"
        :field="fields.image.field"
      >
        <BasicImageField :field="fields.image.field" />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useImageWidgetInit } from "~~/composables/widgets/image/useImageWidgetInit";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { fields, init } = useImageWidgetInit(toRef(props.widget));

init();
</script>

<style lang="scss">
.image-widget {
  position: relative;
}
</style>
