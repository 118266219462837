import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import { generateCssClassWithContent } from "../compiler/default-css-compiler";
import {
  generateGap,
  generateFlexDirection,
  generateAlignItems,
  generateJustifyContent,
} from "../helpers";

export const generateFilterStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      content:
        generateGap(filterOptions.distance) +
        generateAlignItems("flex-start") +
        generateFlexDirection(
          filterOptions.displayMode === DisplayOrientation.HORIZONTAL
            ? "row"
            : "column"
        ) +
        generateJustifyContent("flex-start"),
    });

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      pseudoClassName: " li",
      content: "cursor: pointer",
    });

    return cssString;
  };

export const generateCasinoGamesFiltersWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const form = widget.content["form"];

  const customFields = widget.options._customFields;

  widget.options._cssClass = generateClassName("CasinoGamesFilters");

  /* 
    Form
  */
  form.options._cssClass = generateClassName("filter");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  // Dropdown class names
  customFields.dropdowns_styling.options._cssClass =
    generateClassName("dropdown");
  customFields.dropdown_menu_styling.options._cssClass =
    generateClassName("dropdownMenu");
  customFields.dropdown_menu_items_styling.options._cssClass =
    generateClassName("dropdownItems");

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  return pipeSync<string>(
    generateStringDefault(widget),
    /* 
      Form
    */
    generateFormCssString(form, widget),
    generateFilterStyles(form.options),
    generateTooltipStyling(customFields.tooltips_styling),
    generateIconCssString(customFields.tooltips_styling.options.textChooseIcon),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    ...dropdowns
  )("");
};
