<template>
  <div class="sport-event-fenix-widget-racing-banner">
    <WidgetField
      :widget="widget"
      :style="racingBannerStyles"
      :field="fields.racing_banner.field"
    >
      <WidgetField
        v-if="states.racing_banner === State.AUTHORIZED"
        :widget="widget"
        :style="racingBannerDescriptionStyles"
        :field="fields.racing_banner_event_description.field"
      >
        The event will start soon
      </WidgetField>

      <div v-else>
        <WidgetField
          :widget="widget"
          :style="racingBannerLoginTextStyles"
          :field="fields.racing_banner_login_text.field"
        >
          Please log in to watch the event.
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.racing_banner_login_button.field"
        >
          <BasicButtonField
            :field="fields.racing_banner_login_button.field"
            :current-state="states.racing_banner_login_button"
            custom-value="Login"
          />
        </WidgetField>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getColorStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getSpacing,
  getTextShadowStyle,
} from "~~/assets/utils/widget-settings";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  fields: IFields;
}>();

const racingBannerStyles = computed<CSSProperties>(() => {
  const options = props.fields.racing_banner.field.options;

  if (props.states.racing_banner === State.UNAUTHORIZED) {
    return {
      ...getCommonStyles(options),
      ...getBackgroundColorStyle(options.states[State.UNAUTHORIZED].fill),
    };
  }

  return getCommonStyles(options);
});

const racingBannerDescriptionStyles = computed<CSSProperties>(() => {
  const options = props.fields.racing_banner_event_description.field.options;

  return {
    textAlign: options.alignment,
    ...getTextShadowStyle(options.textShadow),
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});

const racingBannerLoginTextStyles = computed<CSSProperties>(() => {
  const options = props.fields.racing_banner_login_text.field.options;

  return {
    textAlign: options.alignment,
    ...getColorStyle(options.color),
    ...getFontSizeStyle(options.theme),
    ...getFontFamilyStyle(options.fontFamily),
    ...getDecorationValue(options.decoration),
    ...getSpacing(options.padding, SpacingKeyName.PADDING),
  };
});
</script>
