import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  ResizingType,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetsStore } from "~~/store/widgets";
import { Dimension } from "~~/models/grid.interface";

type Args = {
  widget: Ref<IWidgetWithFields>;
  key: string;
  label: string;
  className?: string;
  getInitialFixedValue?: () => number | null;
};

export const extendedSizeElement = ({
  widget,
  label,
  key,
  getInitialFixedValue,
  className,
}: Args): ControlProp[] => {
  const store = useWidgetsStore();

  const minmaxMode = widget.value.options.size[key]?.minMax?.mode;
  const showMinField =
    (minmaxMode === "min" || minmaxMode === "min_max") &&
    widget.value.options.size[key]?.minMax?._active;
  const showMaxField =
    (minmaxMode === "max" || minmaxMode === "min_max") &&
    widget.value.options.size[key]?.minMax?._active;

  // const hideValueInput = computed<boolean>(() => {
  //   if (widget.value.options.size.type === ResizingType.FIXED) {
  //     return false;
  //   }

  //   const minmax = widget.value.options.size[key].minMax;

  //   if (!minmax._active) {
  //     return false;
  //   }

  //   if (!minmax.min?.value && !minmax.max?.value) {
  //     return false;
  //   }

  //   return true;
  // });

  const sizeElementControls: ControlProp[] = [
    {
      componentPath: "common/ConfigDimension",
      valuePath: `options.size.${key}`,
      valueSource: widget.value,
      className,
      options: {
        placeholder: "Enter text",
        type: ColorPickerType.TEXT,
        label: label,
        excludeValues: [],
      },
      onUpdate(value: WidgetDimension) {
        if (!getInitialFixedValue || value.type !== ResizingType.FIXED) {
          store.updateWidgetOptionValue(value, `size.${key}`, widget.value);
          return;
        }

        const prevType = widget.value.options.size[key].type;

        if (prevType === ResizingType.FIXED) {
          store.updateWidgetOptionValue(value, `size.${key}`, widget.value);
          return;
        }

        const initialFixedValue = getInitialFixedValue();

        store.updateWidgetOptionValue(
          {
            ...value,
            value: {
              value: initialFixedValue,
              type: Dimension.PX,
            },
          },
          `size.${key}`,
          widget.value
        );
      },
    },
    {
      componentPath: "common/ConfigToggle",
      valuePath: `options.size.${key}.minMax._active`,
      valueSource: widget.value,
      className: "p-t-8 p-l-16 p-r-16 m-b-8",
      options: {
        placeholder: "Enter text",
        type: ColorPickerType.TEXT,
        label: "Set min/max",
        size: "small",
        allowDisable: true,
      },
    },
    {
      componentPath: "common/ConfigRadioTextElements",
      valuePath: `options.size.${key}.minMax.mode`,
      valueSource: widget.value,
      className: "m-b-8 p-t-0",
      visible: widget.value.options.size[key]?.minMax?._active,
      options: {
        items: [
          {
            label: "Min",
            value: "min",
          },
          {
            label: "Max",
            value: "max",
          },
          {
            label: "Min Max",
            value: "min_max",
          },
        ],
      },
    },
    {
      componentPath: "common/ConfigDimensionValue",
      valuePath: `options.size.${key}.minMax.min`,
      valueSource: widget.value,
      visible: showMinField,
      className: "group-control-element m-b-8 p-l-16 p-r-16",
      options: {
        prefix: "Min",
      },
    },
    {
      componentPath: "common/ConfigDimensionValue",
      valuePath: `options.size.${key}.minMax.max`,
      valueSource: widget.value,
      visible: showMaxField,
      className: "group-control-element p-b-16 p-l-16 p-r-16",
      options: {
        prefix: "Max",
      },
    },
  ];

  return sizeElementControls;
};
