import type { Ref } from "vue";

import { type IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useCasinoGamesSearchWidgetControls = (
  widget: Ref<IWidgetWithFields>
) => {
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ label: "Field container", value: "field_container" }],
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
