import { ResizingType } from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultShadow,
  getDefaultSpacing,
} from "./widget-initial";

export const FLEXIBLE_IMAGE_INITIAL = () => {
  return {
    options: {
      _active: true,
      containerSize: {
        width: {
          type: ResizingType.FILL,
          value: 100,
        },
        height: {
          type: ResizingType.FILL,
          value: 100,
        },
      },
      resizing: {
        type: ResizingType.FIT,
        position: "center center",
        x: 50,
        y: 50,
      },
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: getDefaultSpacing(0),
    },
  };
};
