import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useBonusesStatusConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const statusControls = computed(() => {
    return [
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: fields.value.status.field,
        options: {
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        section: generateDesignSectionName("Secondary text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.secondary.theme",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Enter text",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.secondary.decoration",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.secondary.color",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Primary text style"),
        controls: [
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.primary.theme",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Enter text",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.primary.decoration",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.primary.color",
            valueSource: fields.value.status.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.status.field,
        options: {
          label: "Padding",
          isBold: true,
        },
      },
    ];
  });

  return {
    statusControls,
  };
};
