<template>
  <div
    v-if="(selectedField?.value || isShow) && hasDesignPermissions"
    class="wconfig-container-size wconfig-item m-t-16"
  >
    <h3 class="f-base">Resizing</h3>

    <a-radio-group
      :value="modelValue.resizing"
      class="wconfig-item__radio-group"
      @update:value="handleUpdate('resizing', $event)"
    >
      <a-radio-button
        :value="ResizingType.FILL"
        @click="handleFitSizeClick"
        >Fill container</a-radio-button
      >
      <a-radio-button :value="ResizingType.FIT">Fit container</a-radio-button>
    </a-radio-group>

    <ConfigAlignment
      v-if="modelValue.resizing === ResizingType.FIT"
      :model-value="modelValue.alignment || null"
      :is-text-content-icons="isTextContentIcons"
      class="wconfig-container-size__alignment m-t-16"
      @update:model-value="handleUpdate('alignment', $event)"
    />

    <h3 class="f-base m-t-16">Photo container size</h3>

    <div class="wconfig-container-size__resize-container">
      <a-slider
        :value="modelValue.size"
        :min="MIN_IMAGE_CONTAINER_SIZE"
        :max="MAX_IMAGE_CONTAINER_SIZE"
        @update:value="handleUpdate('size', $event)"
      />
      <ConfigInput
        v-number
        :model-value="modelValue.size"
        :stretch-input="true"
        :is-number="true"
        :input-props="{
          min: MIN_IMAGE_CONTAINER_SIZE,
          max: MAX_IMAGE_CONTAINER_SIZE,
          controls: false,
        }"
        class="wconfig-container-size__resize-input"
        @update:model-value="handleUpdate('size', $event)"
      >
        <template #suffix>
          <span> px </span>
        </template>
      </ConfigInput>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  MIN_IMAGE_CONTAINER_SIZE,
  MAX_IMAGE_CONTAINER_SIZE,
} from "~~/constants/widget-config";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: {
      resizing: ResizingType;
      alignment: string;
      size: number | string;
    };
    isShow?: boolean;
    isTextContentIcons?: boolean;
  }>(),
  {
    modelValue: () => ({
      resizing: ResizingType.FILL,
      alignment: Alignment.CENTER,
      size: "250",
    }),
    isShow: false,
    isTextContentIcons: false,
  }
);

const widgetSettingsStore = useWidgetSettingsStore();
const { selectedField } = storeToRefs(widgetSettingsStore);

const handleFitSizeClick = (): void => {
  handleUpdate("alignment", Alignment.CENTER);
};

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      resizing: string;
      alignment: string;
      size: number | string;
    }
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.wconfig-container-size {
  &__option {
    width: 100%;
  }

  &__alignment {
    padding: 0;
    margin-bottom: 15px;
    border-bottom: unset;
  }

  &__resize-container {
    @include flex(center, center, 8px);
  }

  &__resize-input {
    max-width: 64px;

    .ant-input-number {
      padding-left: 0 !important;
    }
  }

  .ant-slider {
    width: 100%;
    margin-left: 0;
  }
}
</style>
