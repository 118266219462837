import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  MENU_ITEM_INITIAL,
  SPORTS_MENU_WIDGET_INITIAL,
} from "~~/constants/configs/sports-menu/initial-constants";

export const usePopularLeaguesWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    widget: State.DEFAULT,
    menu_level_1: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["fill"], {
    ...SPORTS_MENU_WIDGET_INITIAL().options,
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        color: {
          color: "#ffffff",
          opacity: 100,
        },
        padding: {
          top: 12,
          right: 12,
          bottom: 12,
          left: 12,
        },
      },
    });

    addFieldInitialOptions("menu_level_1", {
      data: MENU_ITEM_INITIAL(),
      additionalData: {
        options: {
          items: {
            displayOrientation: DisplayOrientation.VERTICAL,
            gap: 0,
          },
          elements: {
            displayOrientation: DisplayOrientation.HORIZONTAL,
            gap: 16,
          },
        },
      },
    });

    addFieldInitialOptions("no_events_message", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        theme: 13,
        color: {
          color: "#ffffff",
          opacity: 100,
        },
        padding: {
          top: 12,
          right: 12,
          bottom: 12,
          left: 12,
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
