import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 13;

const v13WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["BetslipWidget"].includes(widget.name)) {
    if (widget.content.bet_amounts_container.options.distanceToOtherElements) {
      delete widget.content.bet_amounts_container.options
        .distanceToOtherElements;
    }

    if (
      widget.content.result_container_total_amounts.options
        .distanceToOtherElements
    ) {
      delete widget.content.result_container_total_amounts.options
        .distanceToOtherElements;
    }

    if (
      widget.content.my_bets_warning_message.options.distanceToOtherElements
    ) {
      delete widget.content.my_bets_warning_message.options
        .distanceToOtherElements;
    }

    if (
      widget.content.result_container_warning_message_title.options
        .distanceToOtherElements
    ) {
      delete widget.content.result_container_warning_message_title.options
        .distanceToOtherElements;
    }

    if (widget.content.success_message_title.options.distanceToOtherElements) {
      delete widget.content.success_message_title.options
        .distanceToOtherElements;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v13WidgetMigration;
