import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IFillControl, State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../../useWidgetFields";

export const useFieldContainerControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const fieldContainerDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.field_container.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.field_container.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.field_container.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.field_container.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.field_container.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.field_container.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const fieldContainerContentControls = computed(() => [
    {
      section: generateDesignSectionName("Typography"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.text.color",
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Value color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.text.theme",
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },
        {
          componentPath: "ConfigFontFamilySelect",
          valuePath: "options.text.fontFamily",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Font family",
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: "options.text.decoration",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "content/ConfigTextInputWithToggle",
          valuePath: "options.placeholder.value",
          valueSource: selectedField.value,
          options: {
            label: "Placeholder",
            placeholder: "Search text",
            color: selectedField.value?.options.placeholder.color,
            isBold: true,
            allowDisable: true,
            active: selectedField.value?.options.placeholder._active,
            "onUpdate:active": (value: boolean) => {
              fields.value.field_container.field.options.placeholder._active =
                value;
            },
            "onUpdate:color": (value: IFillControl) => {
              fields.value.field_container.field.options.placeholder.color =
                value;
            },
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigIconSettings",
          valuePath: "options.icon",
          valueSource: selectedField.value,
          options: {
            label: "Search icon",
            isBold: true,
            allowDisable: true,
          },
        },
      ],
    },
  ]);

  const currentFieldContainerPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }
    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const fieldContainerStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentFieldContainerPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentFieldContainerPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.text.color`,
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Value color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.placeholder.color`,
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Placeholder color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Search icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.icon.textColor`,
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  return {
    fieldContainerDesignControls,
    fieldContainerContentControls,
    fieldContainerStatesControls,
  };
};
