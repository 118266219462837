import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import {
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 78;

const generateDescription = () => {
  const initial = TITLE_INITIAL().options;

  delete (initial as { link?: any }).link;

  return {
    name: "deposit_form_description",
    type: "UnknownField",
    value: null,
    options: {
      ...initial,
      theme: getDefaultTheme(13),
      padding: getDefaultSpacing({
        top: 15,
      }),
    },
  };
};

const v74WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "PaymentProviderFormFenixWidget" &&
    !widget.content.deposit_form_description
  ) {
    widget.content.deposit_form_description = generateDescription();
  }

  return widget;
};
export default v74WidgetMigration;
