import { ComputedRef } from "vue";

import { preDefinedWidthValues } from "~~/constants/cell-params";
import {
  BlockType,
  ICell,
  NestingLevel,
  Sizing,
} from "~~/models/grid.interface";
import { useGridConfig } from "~~/store/grid";

export function useClasses(cell: ICell): ComputedRef<string[]> {
  const store = useGridConfig();
  return computed<string[]>(() => {
    const { width, sizing, level, blockType } = cell.settings;
    const baseClass = "grid-cell";
    const classesArr = [
      baseClass,
      `${baseClass}__${blockType}`,
      `${baseClass}--${sizing}`,
      level === NestingLevel.PARENT
        ? `${baseClass}--parent`
        : `${baseClass}--child`,
    ];
    if (cell.children.length === 0) classesArr.push(`${baseClass}--empty`);

    if (!width) classesArr.push(`${baseClass}--expanded`);

    /* WIDTH predefined class (if available) */
    if (width && preDefinedWidthValues.includes(width)) {
      if (sizing === Sizing.ADAPTIVE) {
        classesArr.push(`w-pct-${width}`);
      } else {
        classesArr.push(`w-px-${width}`);
      }
    }

    /* HOVER state */
    if (
      blockType === BlockType.CONTAINER &&
      /* Taking the last element in array */
      store.highlightedCells[store.highlightedCells.length - 1] === cell.cellId
    ) {
      classesArr.push(`${baseClass}--highlighted`);
    }

    /* Helper css class to manage collapsing borders; set only on columns */
    if (cell.cellId === store.colWithHover) {
      classesArr.push(`${baseClass}--with-hover`);
    }

    /* SELECTION state */
    if (cell.cellId === store.selectedCell?.cellId) {
      classesArr.push(`${baseClass}--selected`);
    }

    /* Helper css class to manage collapsing borders; set only on columns */
    if (cell.cellId === store.selectedCell?.parentId) {
      classesArr.push(`${baseClass}--with-selection`);
    }

    return classesArr;
  });
}
