import { Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useTitleControls = (
  titleState: Ref<State>,
  titleField: Ref<IWidgetField | null>,
  emit: any
) => {
  const titleDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: titleField.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: titleField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: titleField.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: titleField.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: titleField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: titleField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    titleField,
    [FieldContentItem.TEXT, FieldContentItem.ICON, FieldContentItem.ARROW]
  );

  const titleContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: "options.text",
        valueSource: titleField.value,
        options: {
          label: "Text",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
          text: titleField.value?.value,
          "onUpdate:text": (value: string) => {
            titleField.value!.value = value;
          },
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: titleField.value,
        options: {
          label: "Icon",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrow",
        valueSource: titleField.value,
        options: {
          label: "Arrow",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ARROW).value,
        },
      },
    ];
  });

  const titleSettingsValuePath = computed<string>(() => {
    if (titleState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${titleState.value}`;
  });

  const titleStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: titleState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-title-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${titleSettingsValuePath.value}.fill`,
        valueSource: titleField.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${titleSettingsValuePath.value}.border`,
        valueSource: titleField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${titleSettingsValuePath.value}.shadow`,
        valueSource: titleField.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: `${titleSettingsValuePath.value}.text`,
        valueSource: titleField.value,
        options: {
          label: "Text",
          isBold: true,
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `${titleSettingsValuePath.value}.icon`,
        valueSource: titleField.value,
        options: {
          label: "Icon",
          isBold: true,
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigArrowSettings",
        valuePath: `${titleSettingsValuePath.value}.arrow`,
        valueSource: titleField.value,
        options: {
          label: "Arrow",
          isBold: true,
          isStatesTab: true,
        },
      },
    ];
  });

  return {
    titleDesignControls,
    titleContentControls,
    titleStatesControls,
  };
};
