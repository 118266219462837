<template>
  <div v-if="hasContentPermissions">
    <ConfigToggle
      size="small"
      :model-value="active"
      :label="label"
      :placeholder="placeholder"
      :allow-disable="allowDisable"
      :show-options="showOptions"
      :class="[
        'widget-control-container p-l-16 p-r-16 p-b-16 p-t-16',
        className,
      ]"
      @update:model-value="handleActive"
      @options-click="$emit('show-options')"
    >
      <ConfigTextInput
        v-if="active"
        :placeholder="placeholder"
        :model-value="modelValue"
        :max="max"
        class="p-t-8 config-toggle-textarea__input"
        @update:model-value="handleValueUpdate"
      />
    </ConfigToggle>
    <template v-if="color !== undefined && active">
      <ConfigColorPickerInput
        class="config-toggle-textarea__color-picker p-l-16 p-r-16"
        :model-value="color"
        :type="ColorPickerType.TEXT"
        :label="label + ' color'"
        @update:model-value="handleColorUpdate"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { MAX_TEXT } from "~~/constants/input-validation";
import { IFillControl } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

withDefaults(
  defineProps<{
    modelValue?: Record<string, string> | null;
    placeholder: string | undefined;
    label: string;
    color?: Record<string, string> | undefined;
    active: boolean;
    allowDisable: boolean;
    showOptions: boolean;
    max?: number;
    className?: string;
  }>(),
  {
    placeholder: "Type value...",
    active: false,
    modelValue: () => ({}),
    max: MAX_TEXT,
    color: undefined,
    className: "",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
  (e: "update:active", event: boolean): void;
  (e: "update:color", event: IFillControl): void;
  (e: "show-options"): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleActive = (value: boolean): void => {
  emit("update:active", value);
};

const handleValueUpdate = (value: string): void => {
  emit("update:modelValue", value);
};

const handleColorUpdate = (value: IFillControl): void => {
  emit("update:color", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-text {
  @include widgets-content-input-container;
}

.config-toggle-textarea {
  &__element {
    @include flex(center, flex-start, 0);
  }

  &__label {
    margin-left: $space-s;
  }

  &__input {
    padding-right: 0 !important;
    padding-left: 0 !important;
    // padding-bottom: 0 !important;
    border-bottom: unset;
  }
}
</style>
