import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useNoItemsTextConfig } from "~~/composables/widgets/common/useNoItemsTextConfig";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

type Args = {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  stateName: string;
  screenStateName: string;
  fieldName: string;
  configTabsList: ConfigTabItem[];
};

export const useCardsConfig = (
  {
    widget,
    states,
    stateName,
    screenStateName,
    fieldName,
    configTabsList,
  }: Args,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: screenStateName,
      noTextFieldName: "no_active_bonuses",
      customLabels: {
        [State.ACTIVE_EVENTS]: "There are active bonuses",
        [State.NO_ACTIVE]: "No active bonuses",
      },
    }),
    emit
  );
  const cardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value[fieldName].field,
      elements: [
        [
          4,
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.distance",
            valueSource: fields.value[fieldName].field,
            options: {
              label: "Distance between cards",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const cardsContentControls = computed(() => {
    if (states.value[screenStateName] === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: fields.value[fieldName].field,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: fields.value[fieldName].field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: configTabsList,
        },
      },
    ];
  });

  const currentcardsStatePath = computed<string>(() => {
    if (states.value[stateName] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[stateName]}`;
  });

  const cardsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value[stateName],
        onUpdate: value => {
          emit("update-state", { state: stateName, value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value[fieldName].field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: currentcardsStatePath.value,
      }),
    ];
  });

  return {
    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,
  };
};
