import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { IFields } from "../useWidgetFields";

export const useBetsListFenixMobileWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: widget.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: widget.value,
            options: {
              label: "Margin",
              meta: {
                isBold: true,
              },
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    const sharedControls = [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value["widget"],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.active_events,
              value: State.WITH_ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS.no_active,
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "widget", value });
        },
      },
    ];

    if (states.value["widget"] === State.NO_ACTIVE) {
      return [
        ...sharedControls,
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "value",
          valueSource: fields.value.no_events_title.field,
          options: {
            placeholder: "Text",
            label: "Text",
          },
        },
        typography({ source: fields.value.no_events_title.field }, "Text"),
      ];
    }

    return [
      ...sharedControls,
      {
        section: generateDesignSectionName("Sports"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.sports.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.spaceBetween",
            valueSource: fields.value.sports.field,
            options: {
              label: "Distance between sports",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
    ];
  });

  const widgetDataControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Sports"),
        controls: [
          {
            componentPath: "ConfigItemsMultiSelect",
            valuePath: "options.bindingParams.sportIds.value",
            valueSource: selectedWidget.value,
          },
        ],
      },
      {
        section: generateContentSectionName("Events"),
        controls: [
          {
            componentPath: "content/ConfigNumberInput",
            valuePath: "options.bindingParams.maxEvents.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Maximum number of events",
            },
          },
          {
            componentPath: "common/ConfigCommonSelect",
            valuePath: "options.bindingParams.sorting.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Sorting",
              selectOptions: [
                {
                  label: "By date",
                  value: "by_date",
                },
                {
                  label: "By price",
                  value: "by_price",
                },
                {
                  label: "By popularity",
                  value: "by_popular",
                },
              ],
            },
          },
          {
            componentPath: "content/ConfigNumberInput",
            valuePath: "options.bindingParams.eventsInterval.value",
            valueSource: selectedWidget.value,
            options: {
              label: "Time interval for events",
              suffixContent: "hours",
              showTooltip: true,
              tooltipContent:
                "If enter 6(h), in the Front-End App will be displayed events which will take place in the next 6 hours.",
            },
          },
        ],
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,
  };
};
