export enum WIDGET_FIELD_TYPES {
  TEXT_ELEMENT = "TextInputField",
  IMAGE_ELEMENT = "FileUploadField",
  ARRAY_ELEMENT = "ArrayField",
  FORM_ELEMENT = "FormField",
  TEXT_EDITOR_ELEMENT = "TextEditorField",
  TEXT_AREA_ELEMENT = "TextAreaField",
  DROPDOWN_ELEMENT = "DropdownField",
  ITEMS_ELEMENT = "ItemsField",
  BUTTON_LINK_ELEMENT = "ButtonLinkField",

  /* 
    Fields are outside of form
  */
  CHECKBOX = "CheckboxField",
  INPUT = "TextInputField",
  UNKNOWN = "UnknownField",
}
