<script setup lang="ts">
import CommonDraggable from "~~/components/common/common-draggable/CommonDraggable.vue";

import AccessScenarioEditorForm from "./AccessScenarioEditorForm.vue";
import type { IAccessScenario } from "./type";
import { AccessScenario } from "./constants";

const scenarios = defineModel<IAccessScenario[]>("scenarios", {
  required: true,
});

const accessScenariosSelectOptions = [
  {
    value: AccessScenario.ONLY_AUTHORIZED_USERS,
    label: "Only authorized users",
  },
  {
    value: AccessScenario.ONLY_GUEST_USERS,
    label: "Only guests",
  },
  {
    value: AccessScenario.ONLY_USER_WITH_FULL_PROFILE,
    label: "Only users with full profile",
  },
];

function addNewScenario() {
  const firstAvailableScenario = accessScenariosSelectOptions[0];

  scenarios.value.push({
    type: firstAvailableScenario.value,
    action: "",
    meta: {},
  });
}

function removeScenario(arrElIndex: number) {
  scenarios.value.splice(arrElIndex, 1);
}
</script>

<template>
  <div class="page-config-permissions-editor">
    <a-space
      style="width: 100%"
      :size="8"
      direction="vertical"
    >
      <template v-if="scenarios.length">
        <CommonDraggable
          v-model="scenarios"
          group="accessScenariosList"
          item-class="m-b-16"
          handle-selector=".drag-btn"
        >
          <template #default="{ index }">
            <AccessScenarioEditorForm
              v-model:scenario="scenarios[index]"
              :available-scenarios="accessScenariosSelectOptions"
              :step="index + 1"
              @remove-scenario="removeScenario(index)"
            />
          </template>
        </CommonDraggable>
      </template>

      <div v-else>Everyone can visit this page.</div>

      <a-button @click="addNewScenario">Add rule</a-button>
    </a-space>
  </div>
</template>

<style scoped lang="scss">
.page-config-permissions-editor {
  &__scenario-select {
    width: 100%;
  }
}
</style>
