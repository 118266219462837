<template>
  <div class="widget-preview">
    <slot>
      <div v-if="widgetImage">
        <img
          :src="widgetImage"
          alt=""
        />
        <!-- 
          TODO replace image with demo component + fix demo components styles
         -->
        <!-- <BaseWidget
          :disabled="true"
          :widget="widgetDetails"
        /> -->
      </div>
      <div
        v-else
        class="widget-preview__empty"
      >
        Preview
      </div>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";

const props = defineProps<{
  widget: IWidgetsListElement;
}>();

const getImageUrl = (name: string): string | null => {
  const modules = import.meta.glob("/src/assets/images/widgets/preview/*", {
    eager: true,
  }) as {
    [key: string]: any;
  };
  const path = `/src/assets/images/widgets/preview/${name}.png`;

  try {
    const image = modules[path].default;

    return image;
  } catch (e) {
    return null;
  }
};

/*
  TODO replace preview images with demo widgets(components)
*/
// const widgetComponent = computed<Component>(() => {
//   const existingWidget = (widgetsList as { [key: string]: Component })[
//     props.widget.name
//   ];

//   return existingWidget;
// });
// const widgetDetails = computed<IWidgetWithFields>(() => {
//   return createWidgetFromListElement(props.widget);
// });
const widgetImage = computed<string | null>(() => {
  const image = getImageUrl(props.widget.name);

  return image;
});
</script>

<style lang="scss">
.widget-preview {
  @include flex(center, center);
  width: 170px;
  height: 267px;
  text-align: center;

  img {
    width: 138px;
  }

  &__empty {
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
