import {
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";
import {
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";
const WIDGET_VERSION = 39;
const v39WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;
  if (
    [
      "SportsBookPreMatchFenixWidget",
      "SportsBookPreMatchFenixMobileWidget",
    ].includes(widget.name)
  ) {
    const dayLabelOptions = widget.content.day_label.options;
    if (!dayLabelOptions.fill) {
      dayLabelOptions.fill = getDefaultFillValue();
      dayLabelOptions.border = getDefaultBorderValue();
      dayLabelOptions.shadow = getDefaultShadow();
      dayLabelOptions.cornerRadius = 0;
      dayLabelOptions.resizing = ResizingType.FILL;
      dayLabelOptions.alignment = Alignment.LEFT;
    }
  }
  return widget;
};
export default v39WidgetMigration;
