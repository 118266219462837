<template>
  <div
    v-if="hasContentPermissions"
    class="wconfig-array-items wconfig-item"
  >
    <div
      v-if="label"
      class="wconfig-array-items__label-container"
    >
      <p class="wconfig-array-items__label m-b-0 sub-heading">
        {{ label }}
      </p>

      <CommonIcon
        name="ant-design:setting-outlined"
        class="wconfig-array-items__icon"
        @click="optionsClickHandler"
      />
    </div>

    <DNDContainer
      :container-id="DndContainerId.ARRAY_ITEMS"
      class="wconfig-array-items__list"
      show-divider
      :divider-position="dividerPosition"
      @drop="handleBlockDrop"
      @over="handleDragOver"
      @update-container-position="handleUpdateContainerPosition"
    >
      <div
        v-for="(itemField, index) in items"
        :id="`menu-item-${itemField.id}`"
        :key="itemField.id"
        class="wconfig-array-items__list-item"
      >
        <span
          v-if="!hideDND"
          v-draggable="{
            payload: {
              id: itemField.id,
            },
            containerId: DndContainerId.ARRAY_ITEMS,
          }"
          class="wconfig-array-items__list-item-icon anticon"
        >
          <CommonIcon
            name="custom:dnd-marker"
            class="wconfig-array-items__drag-icon"
          />
        </span>

        <div class="wconfig-array-items__input-container">
          <CommonIcon
            v-if="!showOnlyInput && itemField.options.icon?._active"
            name="ant-design:picture-outlined"
            class="wconfig-array-items__picture-icon m-r-8"
          />

          <a-input
            v-if="showOnlyInput || itemField.options.text?._active"
            :value="getLocalizedValue(itemField.value)"
            :bordered="false"
            :maxlength="MAX_BUTTON"
            class="wconfig-array-items__input"
            @update:value="handleInput(itemField, $event)"
          />

          <CommonIcon
            v-if="!showOnlyInput && itemField.options.link?.value"
            name="ant-design:link-outlined"
            class="wconfig-array-items__link-icon m-l-8"
          />
        </div>

        <CommonIcon
          name="ant-design:edit-outlined"
          class="wconfig-array-items__list-item-icon"
          @click="handleItemFieldOptionsClick(itemField)"
        />

        <a-tooltip
          v-if="!hideCRUD"
          placement="bottomLeft"
        >
          <template
            v-if="!canAddNewItem"
            #title
          >
            <span>{{ cannotAddHint }}</span>
          </template>

          <CommonIcon
            name="ant-design:copy-outlined"
            class="wconfig-array-items__list-item-icon"
            :class="{
              'disabled': !canAddNewItem,
            }"
            @click="handleDuplicate(index)"
          />
        </a-tooltip>

        <a-tooltip
          v-if="!hideCRUD"
          placement="bottomLeft"
        >
          <template
            v-if="!canDeleteItem"
            #title
          >
            <span>{{ cannotDeleteHint }}</span>
          </template>

          <CommonIcon
            name="ant-design:delete-outlined"
            class="wconfig-array-items__list-item-icon"
            :class="{
              'disabled': !canDeleteItem,
            }"
            @click="handleDeleteItem(itemField.id)"
          />
        </a-tooltip>

        <CommonToggleVisibilityButton
          v-if="showVisibility"
          :model-value="itemField.options._active"
          class="wconfig-array-items__icon"
          @update:model-value="handleHideItem(itemField, $event)"
        />
      </div>
    </DNDContainer>

    <a-tooltip
      v-if="!hideCRUD"
      placement="top"
    >
      <template
        v-if="!canAddNewItem"
        #title
      >
        <span>{{ cannotAddHint }}</span>
      </template>

      <a-button
        :disabled="!canAddNewItem"
        type="primary"
        class="wconfig-array-items__button"
        ghost
        @click="handleAddItem"
      >
        Add a new {{ subjectName }}
      </a-button>
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import { MAX_BUTTON } from "~~/constants/input-validation";
import {
  DROPDOWN_MENU_MAX_ITEMS_COUNT,
  DROPDOWN_MENU_MIN_ITEMS_COUNT,
} from "~~/constants/widget-config";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { IInsertPosition } from "~~/models/grid.interface";
import { DndContainerId } from "~~/models/common/dropdown.model";
import { deepCopy, generateId } from "~~/assets/utils";
import { useText } from "~~/components/composables/fields/text/useText";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useDropdownItemsDnd } from "~~/composables/widgets-config/useDropdownItemsDnd";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const { canUpdateInputValue } = useText(MAX_BUTTON);

const props = withDefaults(
  defineProps<{
    label?: string;
    items?: IWidgetField[];
    hideCRUD?: boolean;
    hideDND?: boolean;
    showVisibility?: boolean;
    showOnlyInput?: boolean;
    maxItemsCount?: number;
    minItemsCount?: number;
    subjectName?: string;
    // eslint-disable-next-line vue/require-default-prop
    addItemHandler?: () => void;
    optionsClickHandler: () => void;
    reorderHandler: (
      targetId: number | string,
      insertPosition: IInsertPosition
    ) => void;
  }>(),
  {
    label: "",
    items: () => [],
    maxItemsCount: DROPDOWN_MENU_MAX_ITEMS_COUNT,
    minItemsCount: DROPDOWN_MENU_MIN_ITEMS_COUNT,
    subjectName: "item",
    hideDND: false,
    showVisibility: false,
  }
);
const emit = defineEmits<{
  (e: "update:items", items: IWidgetField[]): void;
}>();

const { setLocalizedValue, getLocalizedValue } = useLocalizedValue();

const widgetSettingsStore = useWidgetSettingsStore();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const currentWidget = computed<IWidgetWithFields | null>(() => {
  return widgetSettingsStore.selectedWidget;
});

const handleItemFieldOptionsClick = (itemField: IWidgetField): void => {
  widgetSettingsStore.setActiveElement(currentWidget.value, itemField);
};

const canAddNewItem = computed(
  () => props.items?.length !== props.maxItemsCount
);

const cannotAddHint = computed(() =>
  canAddNewItem.value
    ? ""
    : `You cannot create more than ${props.maxItemsCount} ${props.subjectName}s`
);

const cannotDeleteHint = computed(() =>
  canDeleteItem.value ? "" : `You cannot delete the last ${props.subjectName}`
);

const canDeleteItem = computed(
  () => props.items?.length !== props.minItemsCount
);

const handleAddItem = () => {
  if (!canAddNewItem.value) return;

  props.addItemHandler?.();
};

const handleDeleteItem = (id: string | number) => {
  if (!canDeleteItem.value || !props.items) return;

  emit(
    "update:items",
    props.items.filter(item => item.id !== id)
  );
};

const handleHideItem = (itemField: IWidgetField, value: boolean) => {
  emit(
    "update:items",
    props.items.map(item => {
      if (item.id === itemField.id) item.options._active = value;
      return item;
    })
  );
};

const handleInput = (itemField: IWidgetField, value: string) => {
  if (!canUpdateInputValue(value)) return;

  itemField.value = setLocalizedValue.value(itemField.value, value);
};

const handleDuplicate = (targetIndex: number) => {
  if (!canAddNewItem.value || !props.items) return;

  const copy = {
    ...deepCopy(props.items[targetIndex]),
    id: generateId(),
  };
  const copyItems = [...props.items];

  copyItems.splice(targetIndex + 1, 0, copy);

  emit("update:items", copyItems);
};

const {
  handleBlockDrop,
  handleDragOver,
  handleUpdateContainerPosition,
  dividerPosition,
} = useDropdownItemsDnd(props.reorderHandler);
</script>

<style lang="scss">
.wconfig-array-items {
  display: flex;
  flex-direction: column;
  gap: $space-m;

  padding: $space-m;

  &__label-container {
    @include flex(center, space-between, 0);
  }

  &__icon {
    color: $c-grey-45;
    font-size: 14px;
    cursor: pointer;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: $space-s;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }
  }

  &__input-container {
    height: 24px;
    padding: 0px 8px;
    border-radius: 4px;
    overflow: hidden;
    background: $c-grey-02 !important;
    text-overflow: ellipsis;
    flex-grow: 1;

    display: flex;
    align-items: center;
  }

  &__input {
    height: 100%;
    padding: 0;
    border: none;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__list-item-icon {
    padding: 0 5px;
    cursor: pointer;
  }

  &__drag-icon {
    transform: scale(1.3);
    opacity: 0.45;
    font-size: 8px;
  }

  &__button {
    width: 100%;
    border-radius: 6px;
  }

  &__picture-icon {
    font-size: 16px;
    color: $c-grey-45;
  }

  &__link-icon {
    font-size: 12px;
    color: $c-primary-base;
  }
}
</style>
