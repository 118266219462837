import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 35;

const v35WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    ["PaymentProviderFormFenixWidget"].includes(widget.name) &&
    widget.content.provider_card.options.states
  ) {
    delete widget.content.provider_card.options.states;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v35WidgetMigration;
