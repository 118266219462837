<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    class="banner-slider-widget"
  >
    <BannerSliderWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <BasicSliderField
      :widget="widget"
      :cell="cell"
      style="height: 100%; align-content: stretch"
    >
      <template #slide="{ slideField, showArrows, showPagination }">
        <BannerSlide
          :ref="
            el => {
              updateVisibleSlidesMap(slideField.id, el as InstanceType<typeof BannerSlide>);
            }
          "
          :show-pagination="showPagination"
          :show-arrows="showArrows"
          :slide-field="slideField"
          :widget="widget"
          :states="states"
        />
      </template>
    </BasicSliderField>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { generateWidgetField } from "~~/assets/utils/widget";
import type { ICell } from "~~/models/grid.interface";
import {
  Alignment,
  SliderArrowIcon,
  SliderItemsPosition,
  SliderTransition,
  State,
} from "~~/models/widgets/widget-controls.model";
import type {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import type {
  IPageFieldDetails,
  IPageWidgetDetails,
} from "~~/models/page.model";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useMetaStore } from "~~/store/meta";
import {
  getDefaultFillImage,
  getDefaultSize,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

import BasicSliderField from "./basicSlider/BasicSliderField.vue";
import BannerSlide from "./bannerSlider/BannerSlide.vue";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();
const metaStore = useMetaStore();
const { initWidgetOptions } = useCreateFields(props.widget);

const { selectedWidget } = storeToRefs(widgetSettingsStore);
const { widgetsDetailsCache } = storeToRefs(widgetsStore);

const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
  () => {
    return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
      props.widget.static_id
    ];
  }
);

const DEFAULT_STATES = {
  slide: State.DEFAULT,
  title_link: State.DEFAULT,
  button_1: State.DEFAULT,
  button_2: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const arrayField = computed<IWidgetField | null>(() => {
  return (
    props.widget.fields.find(field => field.name === FieldName.ITEMS) || null
  );
});

const slides = computed<ICustomField[]>(
  () => (arrayField.value?.value as ICustomField[]) || []
);

if (!slides.value.length || !Object.keys(props.widget.options).length) {
  arrayField.value!.value = (
    widgetCacheData.value!.fields![arrayField.value!.name].items as {
      fields: IPageFieldDetails[];
    }[]
  ).map(item => ({
    ...generateWidgetField(
      FieldName.SLIDE,
      props.widget.id,
      "",
      "Slide",
      "Slide"
    ),
    fields: item.fields.map(field =>
      generateWidgetField(
        field.name,
        props.widget.id,
        field.type,
        field.title,
        (field.value || field.default_value) as string
      )
    ),
    options: {},
  }));
}

const visibleSlidesMap = ref<
  Record<number | string, InstanceType<typeof BannerSlide>>
>({});

const updateVisibleSlidesMap = (
  id: number | string,
  el: InstanceType<typeof BannerSlide>
) => {
  visibleSlidesMap.value[id] = el;
};

const initialWidgetOptions = initWidgetOptions(["fill"], {
  slideOptions: {
    size: getDefaultSize(),
  },
  fillImageColor: getDefaultFillImage(),
  margins: getDefaultSpacing(),
  columns: 1,
  transition: SliderTransition.ALL,
  spaceBetween: 24,
  slidePadding: getDefaultSpacing(8),
  sliderTransition: {
    isAuto: true,
    delay: 7,
    isInfinite: true,
  },
  sliderPagination: {
    isActive: true,
    isStroke: false,
    color: {
      color: "",
      opacity: 100,
    },
    alignment: Alignment.CENTER,
    position: SliderItemsPosition.INSIDE,
  },
  sliderArrows: {
    isActive: true,
    icon: SliderArrowIcon.OUTLINED,
    color: {
      color: "",
      opacity: 100,
    },
    position: "inside",
  },
});

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
</script>
