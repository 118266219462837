<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="emit('reset-states')"
        @blur-tab="emit('reset-states')"
        @change="emit('reset-states')"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { State } from "~~/models/widgets/widget-controls.model";
import { useButtonWidgetConfig } from "~~/composables/widgets/button/useButtonWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  IParams,
  useWidgetDynamicParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  buttonState: State;
}>();

const emit = defineEmits<{
  (e: "update-button-state", event: State): void;
  (e: "reset-states"): void;
}>();

const { selectedWidget } = storeToRefs(useWidgetSettingsStore());
const buttonState = toRef(() => props.buttonState);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  buttonDesignControls,
  buttonContentControls,
  buttonStatesControls,
} = useButtonWidgetConfig(ref(props.widget), buttonState, emit);

const bindingParamsArg = computed<IParams>(() => {
  return {
    "toggledVisibilityForElTag": {
      label: "Change visibility for element by tag",
      sources: ["custom"],
      required: false,
    },
    "textWhenToggledVisibility": {
      label: "Text when visibility is toggled",
      sources: ["custom"],
      required: false,
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});
</script>
