<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PromotionsListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />
    <div class="promotions-list-widget">
      <WidgetField
        v-if="fields.page_title.active"
        :widget="widget"
        :field="fields.page_title.field"
      >
        <BasicTitleField :field="fields.page_title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.tabs.active"
        :widget="widget"
        :field="fields.tabs.field"
        :style="tabsStyling"
        class="promotions-list-widget__tabs-container"
      >
        <div
          v-for="item in 3"
          :key="item"
          :style="tabItemStyle"
        >
          Group Name
        </div>
      </WidgetField>

      <WidgetField
        v-if="fields.promo_list.active"
        :widget="widget"
        :field="fields.promo_list.field"
        :style="promoListStyles"
      >
        <template v-if="states.promo_list === State.PROMO_EXIST">
          <WidgetField
            v-if="fields.promo_group.active"
            :widget="widget"
            :field="fields.promo_group.field"
            :style="promoGroupStyles"
          >
            <div>
              <WidgetField
                v-if="fields.promo_group_title.active"
                :widget="widget"
                :field="fields.promo_group_title.field"
              >
                <BasicTitleField
                  :field="fields.promo_group_title.field"
                  :style="groupNameStyles"
                  value="Group name"
                />
              </WidgetField>

              <PromoGroup
                :widget="widget"
                :group-list-field="fields.promo_group_list.field"
                :group-cards-field="fields.promo_cards.field"
                :card-image-field="fields.promo_image.field"
                :card-title-field="fields.promo_title.field"
                :card-description-field="fields.promo_primary_description.field"
                :card-secondary-description-field="
                  fields.promo_secondary_description.field
                "
                :card-button-group-field="fields.promo_button_group.field"
                :card-button1-field="fields.promo_button_1.field"
                :card-button2-field="fields.promo_button_2.field"
                :states="states"
                :cards-count="5"
                image-value-path="/promotions-list/"
                image-format="png"
              />
            </div>
          </WidgetField>

          <WidgetField
            v-if="fields.promo_group.active"
            :widget="widget"
            :field="fields.promo_group.field"
            :style="promoGroupStyles"
          >
            <template v-if="states.widget === State.PROMOTIONS_ALL_GROUPS">
              <WidgetField
                v-if="fields.promo_group_title.active"
                :widget="widget"
                :field="fields.promo_group_title.field"
              >
                <BasicTitleField
                  :field="fields.promo_group_title.field"
                  :style="groupNameStyles"
                  value="Group name"
                />
              </WidgetField>

              <PromoGroup
                :widget="widget"
                :group-list-field="fields.promo_group_list.field"
                :group-cards-field="fields.promo_cards.field"
                :card-image-field="fields.promo_image.field"
                :card-title-field="fields.promo_title.field"
                :card-description-field="fields.promo_primary_description.field"
                :card-secondary-description-field="
                  fields.promo_secondary_description.field
                "
                :card-button-group-field="fields.promo_button_group.field"
                :card-button1-field="fields.promo_button_1.field"
                :card-button2-field="fields.promo_button_2.field"
                :states="states"
                :cards-count="3"
                image-value-path="/promotions-list/second-group/"
                image-format="png"
              />
            </template>
          </WidgetField>
        </template>

        <BasicTitleField
          v-else
          :field="fields.no_promo_title.field"
          :style="noPromoTextStyles"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/promotions-list/usePromotionsListInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getBackgroundFillStyle,
  getBorderStyle,
  getColorStyle,
  getDecorationValue,
  getFlexPosition,
  getFontFamilyStyle,
  getFontSizeStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { useLanguagesStore } from "~~/store/languages";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);

const DEFAULT_STATES = {
  widget: State.PROMOTIONS_ALL_GROUPS,
  promo_list: State.PROMO_EXIST,
  promo_cards: State.DEFAULT,
  tabs: State.DEFAULT,
  promo_button_1: State.DEFAULT,
  promo_button_2: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { fields, init } = useInit(ref(props.widget));

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["widget"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

const activeTabsStateOptions = computed<any>(() => {
  return states.value.tabs === State.DEFAULT
    ? fields.value.tabs.field.options
    : fields.value.tabs.field.options.states[states.value.tabs];
});

const tabsStyling = computed<ElementStyle>(() => {
  const tabs = fields.value.tabs.field;

  return {
    ...getCommonStyles({
      ...tabs.options,
      ...activeTabsStateOptions.value,
    }),
    ...getColorStyle(activeTabsStateOptions.value.text.color),
    ...getFontSizeStyle(tabs.options.text.theme),
    ...getDecorationValue(tabs.options.text.decoration),
    ...getFontFamilyStyle(tabs.options.text.fontFamily),
    ...getTextAlignStyle(tabs.options.text.alignment),
    gap: getPxValueFromNumber(tabs.options.gap),
    justifyContent: getFlexPosition(tabs.options.display.alignment),
  };
});

const tabItemStyle = computed<ElementStyle>(() => {
  const tabs = fields.value.tabs.field;
  const flexStyle =
    tabs.options.display.resizing === ResizingType.HUG ? "unset" : "1";

  return {
    ...getTextAlignStyle(tabs.options.text.alignment),
    flex: flexStyle,
    ...getBorderStyle(activeTabsStateOptions.value.itemBorder),
  };
});

const promoListStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.promo_list.field.options,
    }),
    ...getBackgroundFillStyle(
      fields.value.promo_list.field.options.fillImageColor
    ),
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(fields.value.promo_list.field.options.gap),
  };
});

const promoGroupStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.promo_group.field.options,
    }),
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(fields.value.promo_group.field.options.gap),
  };
});

const groupNameStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.promo_group_title.field.options,
    }),
  };
});

const noPromoTextStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles({
      ...fields.value.no_promo_title.field.options,
    }),
  };
});

onMounted(() => {
  const widgetBindingParams = props.widget.options.bindingParams;

  if (widgetBindingParams && widgetBindingParams.pageType?.value) {
    const parsedPageTypeParam = (
      widgetBindingParams.pageType.value as Record<string, string>
    )[currentLanguageCode.value]
      .toLowerCase()
      .trim()
      .split(" ")
      .join("_");

    if (parsedPageTypeParam === "one") {
      states.value.widget = State.PROMOTIONS_ONE_GROUP;
    } else {
      states.value.widget = State.PROMOTIONS_ALL_GROUPS;
    }
  }
});

init();
</script>

<style lang="scss">
.promotions-list-widget {
  &__tabs-container {
    display: flex;
    align-items: center;
  }
}
</style>
