import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useWidgetsStore } from "~~/store/widgets";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const usePromotedCardsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();

  /* 
    Cards
  */

  const cardsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_card.field,
    });
  });

  const cardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_card.field,
            className:
              "group-control-element promoted-bets-widget__cards-radio",
            options: {
              label: "Layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.distance",
            valueSource: fields.value.event_card.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.display.contentPosition",
            valueSource: fields.value.event_card.field,
            options: {
              label: "Content position",
            },
          },
        ],
      },

      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Photo",
              value: "event_image",
            },
          ],
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "form/ConfigTabs",
            options: {
              items: [
                {
                  label: "Event info",
                  value: "event_info",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: fields.value.bet_items.field,
            options: {
              label: "Bet items layout",
              items: [
                { label: "Horizontal", value: DisplayOrientation.HORIZONTAL },
                { label: "Vertical", value: DisplayOrientation.VERTICAL },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.bet_items.field,
            className: "group-control-element",
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.event_button.field,
        options: {
          placeholder: "Enter text",
          label: "Button",
          active: true,
          showOptions: true,
          allowDisable: false,
          "onUpdate:active": (value: boolean) => {
            useWidgetsStore().updateFieldOptionValue(
              value,
              "_active",
              fields.value.event_button.field.id
            );
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.event_button.field
            );
          },
        },
      },
    ];
  });

  const currentCardsStatePath = computed<string>(() => {
    if (states.value.event_card === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.event_card}`;
  });

  const cardsStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.event_card,
        onUpdate: value => {
          emit("update-state", { state: "event_card", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.event_card.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: currentCardsStatePath.value,
      }),
    ];
  });

  return {
    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,
  };
};
