<template>
  <div class="basic-form-widget">
    <FormWidgetSettings
      :widget="widget"
      :active-view="activeView"
      :states="states"
      :screens="screens"
      :show-form-settings="showFormSettings"
      :custom-filtered-fields="customFilteredFields"
      :hide-form-content-controls="hideFormContentControls"
      @update-active-view="handleUpdateActiveView"
      @update-button-state="$emit('update-button-state', $event)"
      @reset-states="$emit('reset-states')"
      @update-state="$emit('update-state', $event)"
    />

    <div
      :style="contentStyles"
      class="basic-form-widget__content"
    >
      <WidgetField
        :widget="widget"
        :field="fields.form.field"
        :no-overflow="true"
      >
        <div
          :style="formSettingsStyle"
          class="basic-form-widget__fields"
        >
          <template
            v-if="activeView === 'form' || activeView === customFormName"
          >
            <div
              v-for="(field, index) in fieldsList"
              :key="field.id"
              class="basic-form-widget__field"
              :class="{
                'hidden': !field.options._active,
              }"
            >
              <template v-if="field.options._active">
                <slot :name="field.name">
                  <WidgetField
                    v-if="FORM_FIELD_COMPONENTS[field.type]"
                    :widget="widget"
                    :field="field"
                    :custom-dropdown-value="field.type"
                    :no-overflow="true"
                  >
                    <component
                      :is="FORM_FIELD_COMPONENTS[field.type]"
                      :key="field.name"
                      :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[index] = el;
              }
              "
                      :field="field"
                      :widget="widget"
                      :styling-fields="getFieldStylings(field.type)"
                      :states="states"
                    />
                  </WidgetField>

                  <span v-else> No field data {{ field.type }} </span>
                </slot>
              </template>
            </div>
          </template>

          <FormSuccessScreen
            v-else-if="activeView === 'success'"
            ref="customScreen"
            :widget="widget"
            :button-state="states.success_button"
            :title-link-state="states.success_title"
          />

          <FormExpiredScreen
            v-else-if="activeView === 'expired'"
            ref="customScreen"
            :widget="widget"
            :button-state="states.expired_button"
            :title-link-state="states.expired_title"
          />

          <FormFailScreen
            v-else-if="activeView === 'fail'"
            ref="customScreen"
            :widget="widget"
            :button-state="states.fail_button"
            :title-link-state="states.fail_title"
          />
        </div>
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type ComponentPublicInstance, watch } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  FormScreen,
  ProviderFormFenixScreen,
} from "~~/models/widgets/form.model";
import { DepositListScreen } from "~~/models/widgets/form.model";
import {
  SpacingKeyName,
  IFormSettingsSpacing,
  State,
} from "~~/models/widgets/widget-controls.model";
import { FORM_FIELD_COMPONENTS } from "~~/constants/widget-details/form/form-dynamic-components";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useUiStore } from "~~/store/ui";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getFormWidth, getFormElements } from "~~/assets/utils/widget/form";
import { DescriptionMethods } from "~~/composables/fields/description/useDescriptionField";
import {
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { TriggerComponent } from "~~/models/widgets/form.model";
import { useWidgetsStore } from "~~/store/widgets";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    states: Record<string, string>;
    screens: string[];
    activeView: string;
    // eslint-disable-next-line vue/require-default-prop
    customGetFormElements?: (widget: IWidgetWithFields) => IWidgetField[];
    showFormSettings?: boolean;
    customFormName?: string;
    // eslint-disable-next-line vue/require-default-prop
    customFilteredFields?: IWidgetField[];
    hideFormContentControls?: boolean;
  }>(),
  {
    showFormSettings: true,
    customFormName: "",
  }
);

const emit = defineEmits<{
  (e: "update-button-state", event: State): void;
  (e: "reset-states"): void;
  (e: "update-active-view", value: string): void;
  (e: "update-state", event: { state: string; value: State }): void;
}>();

type FormDescriptionMethods = TriggerComponent & DescriptionMethods;

const widgetSettingsStore = useWidgetSettingsStore();
const store = useWidgetsStore();
const uiStore = useUiStore();

const { activeSideMenuTab } = storeToRefs(uiStore);
const { selectedWidget } = storeToRefs(widgetSettingsStore);

const fields = useWidgetFields(props.widget);

const formFields = computed<IWidgetField[]>(() => {
  return (
    props.customGetFormElements?.(props.widget) ||
    getFormElements(props.widget.fields)
  );
});

const fieldsList = computed<IWidgetField[]>(() => {
  return [...formFields.value]
    .filter(field => FORM_FIELD_COMPONENTS[field.type])
    .sort((a, b) => a.options.position - b.options.position);
});

const {
  fieldsRefsList,

  getFieldStylings,
} = useFormInner(props.widget, fieldsList);

const customScreen =
  ref<ComponentPublicInstance<FormDescriptionMethods> | null>(null);

const formSettingsSpacing = computed<IFormSettingsSpacing>(() => {
  const spacing: IFormSettingsSpacing = fields.value.form.field.options.spacing;

  return spacing;
});

const { fillBackgroundStyling } = useFillBackground(
  ref(fields.value.form.field.options)
);

const formSettingsStyle = computed<Record<string, string | undefined>>(() => {
  const fill = fillBackgroundStyling.value;
  const border = getBorderStyle(fields.value.form.field.options.border);
  const shadow = getBoxShadowStyle(fields.value.form.field.options.shadow);
  const cornerRadius = getCornerRadiusStyle(
    fields.value.form.field.options.cornerRadius
  );

  const margin = getSpacing(
    formSettingsSpacing.value.margin,
    SpacingKeyName.MARGIN
  );
  const padding = getSpacing(
    formSettingsSpacing.value.padding,
    SpacingKeyName.PADDING
  );

  const gap =
    props.activeView === FormScreen.FORM ||
    props.activeView === DepositListScreen.PAYMENT_METHOD ||
    props.activeView === ProviderFormFenixScreen.PROVIDER_PAGE
      ? getPxValueFromNumber(fields.value.form.field.options.verticalSpace)
      : "0";

  return {
    ...padding,
    ...margin,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...fill,
    gap,
  };
});

const contentStyles = computed<
  { [key: string]: string | undefined } | undefined
>(() => {
  const activeStateOptions = props.widget.options.states?.[props.activeView];

  const widthOptions = activeStateOptions || props.widget.options.contentWidth;

  const res: { [key: string]: string | undefined } = {
    ...getFormWidth(widthOptions),
  };

  return res;
});

const handleUpdateActiveView = (value: string): void => {
  emit("update-active-view", value);
};

const findDescriptionField = (fieldId: string | number): number | null => {
  const descriptionFieldIndex = fieldsList.value.findIndex(field => {
    return field.id === fieldId;
  });

  if (descriptionFieldIndex < 0) {
    return null;
  }

  return descriptionFieldIndex;
};

watch(
  () => selectedWidget.value,
  () => {
    handleUpdateActiveView(FormScreen.FORM);
  }
);

watch(
  () => activeSideMenuTab.value,
  () => {
    handleUpdateActiveView(FormScreen.FORM);
  }
);

type DescriptionValue = {
  fieldId: string | number;
  value: string;
};

const setScreenDescriptionValue = (
  value: string,
  field: IWidgetField
): void => {
  if (!customScreen.value) {
    return;
  }

  customScreen.value.setContentByField(field.name, value);
};

const isScreenDescription = (fieldName: string | undefined): boolean => {
  return props.screens.filter(screen => fieldName?.includes(screen)).length > 0;
};

const handleUpdateDescriptionValue = ({ fieldId, value }: DescriptionValue) => {
  store.updateFieldValue(fieldId, value);

  const field = props.widget.fields.find(field => field.id === fieldId);
  const isSceen = isScreenDescription(field?.name);

  if (isSceen) {
    setScreenDescriptionValue(value, field!);
    return;
  }

  const descriptionFieldIndex = findDescriptionField(fieldId);

  if (descriptionFieldIndex === null || !field) {
    return null;
  }

  const descriptionField = fieldsRefsList.value[
    descriptionFieldIndex
  ] as ComponentPublicInstance<FormDescriptionMethods>;

  descriptionField.setContentByField(field.name, value);
};

const setContentByField = (fieldName: string, value: string) => {
  const field = props.widget.fields.find(field => field.name === fieldName);

  if (!field) {
    return;
  }

  handleUpdateDescriptionValue({ fieldId: field.id, value });
};

defineExpose({
  setContentByField,
});
</script>

<style lang="scss">
.basic-form-widget {
  @include flex(flex-start, center, 0);

  &__field {
    width: 100%;
  }

  &__fields {
    @include flex-column(flex-start, flex-start, 0);
    min-height: 100px;
  }
}
</style>
