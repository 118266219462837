<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="basic-title-widget">
    <p
      v-if="slots.default"
      :style="textStyleValue"
      class="basic-title-widget__content"
    >
      <slot name="default">
        {{ localizedValue }}
      </slot>
    </p>

    <p
      v-else
      class="basic-title-widget__content"
      :style="textStyleValue"
      v-html="sanitizedValue"
    ></p>
  </div>
</template>

<script lang="ts" setup>
import sanitizeHtml from "sanitize-html";

import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import {
  getSpacing,
  getDecorationValue,
  getColorValue,
  getFontSizeStyle,
  getTextShadowStyle,
} from "~~/assets/utils/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const field = defineModel<IWidgetField>("field", {
  default: () => ({}),
});

const props = defineProps<{
  options?: IWidgetOptions;
  value?: Record<string, string> | string;
  state?: State;
}>();

const slots = useSlots();

const { getLocalizedValue } = useLocalizedValue();

const localizedValue = computed(() => {
  return getLocalizedValue.value(props.value || field.value.value);
});

const sanitizedValue = computed<string>(() => {
  return sanitizeHtml(localizedValue.value);
});

const options = computed<IWidgetOptions>(
  () => props.options || field.value.options
);

const colorStyle = computed<{ color: string }>(() => {
  if (options.value.color) {
    const color = getColorValue(
      isDefaultState.value
        ? options.value.color ?? null
        : options.value.link?.states[props.state!].color ??
            options.value.states[props.state!].color
    );

    return { color };
  }
  return { color: "unset" };
});

const textStyleValue = computed<{
  [key: string]: string | number;
}>(() => {
  let result: Record<string, string> = {};

  if (options.value?.textShadow) {
    result = { ...getTextShadowStyle(options.value?.textShadow) };
  }

  return {
    ...result,
    textAlign: options.value.alignment,
    ...getFontSizeStyle(options.value.theme),
    fontFamily: options.value.fontFamily,
    ...colorStyle.value,
    ...getDecorationValue(
      isDefaultState.value
        ? options.value.decoration
        : options.value.link?.states[props.state!].decoration ??
            options.value.states[props.state!].decoration ??
            options.value.decoration
    ),
    ...getSpacing(options.value.margins, SpacingKeyName.MARGIN),
    ...getSpacing(options.value.padding, SpacingKeyName.PADDING),
  };
});

const isDefaultState = computed<boolean>(
  () => props.state === undefined || props.state === State.DEFAULT
);
</script>

<style lang="scss">
.basic-title-widget {
  word-wrap: break-word;

  &__content {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
