import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const generateTabsContainerField = () => ({
  "name": "tabs_container",
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
  },
});

const generateBetbuilderContainerField = () => ({
  "name": "betbuilder_container",
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
  },
});

const generateBetbuilderHeaderField = () => ({
  "name": "betbuilder_header",
  "type": "TextInputField",
  "value": "Default",
  "options": {
    "_active": true,
  },
});

const generateBetbuilderContentContainerField = () => ({
  "name": "betbuilder_content_container",
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
  },
});

const generateBetbuilderContentDescriptionField = () => ({
  "name": "betbuilder_content_description",
  "type": "TextEditorField",
  "value": "Default",
  "options": {
    "_active": true,
  },
});

const generateBetbuilderContentIframeField = () => ({
  "name": "betbuilder_content_iframe",
  "type": "UnknownField",
  "value": null,
  "options": {
    "_active": true,
  },
});

const generateTabsField = () => ({
  "name": "tabs",
  "type": "ButtonLinkField",
  "value": "Tab",
  "options": {
    "_active": true,
  },
});

const WIDGET_VERSION = 10;

const v10WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["EventWidget"].includes(widget.name)) {
    if (!("tabs" in widget.content)) {
      widget.content.tabs = generateTabsField();
    }
    if (!("tabs_container" in widget.content)) {
      widget.content.tabs_container = generateTabsContainerField();
    }
    if (!("betbuilder_container" in widget.content)) {
      widget.content.betbuilder_container = generateBetbuilderContainerField();
    }
    if (!("betbuilder_content_container" in widget.content)) {
      widget.content.betbuilder_content_container =
        generateBetbuilderContentContainerField();
    }
    if (!("betbuilder_header" in widget.content)) {
      widget.content.betbuilder_header = generateBetbuilderHeaderField();
    }
    if (!("betbuilder_content_description" in widget.content)) {
      widget.content.betbuilder_content_description =
        generateBetbuilderContentDescriptionField();
    }
    if (!("betbuilder_content_iframe" in widget.content)) {
      widget.content.betbuilder_content_iframe =
        generateBetbuilderContentIframeField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v10WidgetMigration;
