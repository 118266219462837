<template>
  <div
    v-if="hasDesignPermissions"
    class="config-shadow widget-control-container p-t-16 p-b-16"
  >
    <ConfigColorPickerInput
      :model-value="modelValue.fill"
      :is-bold="typeof isBold === 'boolean' ? isBold : true"
      :type="ColorPickerType.BACKGROUND"
      :label="label"
      class="config-shadow__color"
      :class="{
        'config-shadow__color--has-value': modelValue.fill.color,
      }"
      @update:model-value="handleUpdateColor"
    />

    <div
      v-if="modelValue.fill.color"
      class="config-shadow__params p-l-16 p-r-16 p-t-16"
    >
      <div class="config-shadow__row w-100">
        <div class="config-shadow__styling">
          <p class="f-base widget-control__title">X</p>
          <a-input-number
            v-number="{ allowNegative: true }"
            :value="modelValue.x"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input"
            @update:value="handleUpdateStyleValue('x', $event)"
          />
        </div>

        <div class="config-shadow__styling">
          <p class="f-base widget-control__title">Y</p>
          <a-input-number
            v-number="{ allowNegative: true }"
            :value="modelValue.y"
            :max="MAX_NUMBER_INPUT_VALUE"
            :controls="false"
            placeholder="None"
            size="small"
            class="widget-control__input"
            @update:value="handleUpdateStyleValue('y', $event)"
          />
        </div>
      </div>

      <div class="config-shadow__params">
        <div class="config-shadow__styling w-100">
          <p class="f-base widget-control__title">Blur</p>
          <ConfigRangeSlider
            :model-value="modelValue.blur"
            :min-value="0"
            :max-value="MAX_SHADOW_OFFSET_NUMBER_INPUT_VALUE"
            class="widget-control__input"
            @update:model-value="handleUpdateStyleValue('blur', $event)"
          />
        </div>

        <div class="config-shadow__styling w-100">
          <p class="f-base widget-control__title">Spread</p>
          <ConfigRangeSlider
            :model-value="modelValue.spread"
            :min-value="0"
            :max-value="MAX_SHADOW_OFFSET_NUMBER_INPUT_VALUE"
            class="widget-control__input"
            @update:model-value="handleUpdateStyleValue('spread', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IFillControl,
  IShadowControl,
} from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import { MAX_SHADOW_OFFSET_NUMBER_INPUT_VALUE } from "~~/constants/widget-config";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: IShadowControl;
    isBold?: boolean;
    label?: string;
  }>(),
  {
    label: "Shadow",
  }
);

const emit = defineEmits<{
  (e: "update:model-value", value: IShadowControl): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdateStyleValue = (propertyName: string, value: any) => {
  emit("update:model-value", {
    ...props.modelValue,
    [propertyName]: value,
  });
};

const handleUpdateColor = (fill: IFillControl) => {
  const emptyModelValue = {
    x: null,
    y: null,
    blur: null,
    spread: null,
  };

  if (!fill.color || !fill.opacity) {
    emit("update:model-value", {
      ...emptyModelValue,
      fill,
    });
    return;
  }

  emit("update:model-value", {
    ...props.modelValue,
    fill,
  });
};
</script>

<style lang="scss">
.config-shadow {
  &__params {
    @include flex-column(flex-start, flex-start, 15px);
    width: 100%;
  }

  &__row {
    @include flex(flex-start, flex-start, 5px);
    width: 100%;
  }

  &__styling {
    flex-basis: 50%;
  }

  &__color {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;

    &--has-value {
      padding-bottom: 0;
    }
  }

  .widget-control {
    &__input {
      width: 100%;
    }
  }
}
</style>
