import { type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useEventCardsFenixConfig } from "./useEventCardsFenixConfig";

export const useFenixEventCardsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any,
  selectedField: Ref<IWidgetField | null>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const {
    eventCardsDesignControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  } = useEventCardsFenixConfig(widget, states, emit);

  const {
    eventCardsDesignControls: topLeaguesEventCardsDesignControls,
    startTimeDateControls: topLeaguesStartTimeDateControls,
    eventButtonLinkDesignControls: topLeaguesEventButtonLinkDesignControls,
    eventButtonLinkContentControls: topLeaguesEventButtonLinkContentControls,
    eventButtonLinkStateControls: topLeaguesEventButtonLinkStateControls,
  } = useEventCardsFenixConfig(widget, states, emit, "top_leagues_");

  const eventCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: fields.value.list_container.field,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: fields.value.list_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League name",
              value: "league_title",
              isActiveValueSource: fields.value.league_title.field.options,
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Event info",
              value: "list_event_info",
            },
            {
              label: "Bet items",
              value: "list_item",
            },
            {
              label: "Event button link",
              value: "list_item_more",
            },
          ],
        },
      },
    ];
  });

  const eventCardsValuePath = computed<string>(() => {
    if (states.value.event_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.event_cards}`;
  });

  const eventCardsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value.event_cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.event_cards = value;
        },
      },
      {
        section: generateDesignSectionName("Card"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${eventCardsValuePath.value}.card.fill`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${eventCardsValuePath.value}.card.border`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigBoxShadow",
            valuePath: `${eventCardsValuePath.value}.card.shadow`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Shadow",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Team/Player"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.event_cards === State.DEFAULT
                ? "options.color"
                : `${eventCardsValuePath.value}.name.color`,
            valueSource:
              states.value.event_cards === State.DEFAULT
                ? fields.value.list_event_name.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Start Time"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.event_cards === State.DEFAULT
                ? "options.color"
                : `${eventCardsValuePath.value}.time.color`,
            valueSource:
              states.value.event_cards === State.DEFAULT
                ? fields.value.list_start_time.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.event_cards === State.DEFAULT
                ? "options.icon.color"
                : `${eventCardsValuePath.value}.time.icon.color`,
            valueSource:
              states.value.event_cards === State.DEFAULT
                ? fields.value.list_start_time.field
                : selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Date"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.event_cards === State.DEFAULT
                ? "options.color"
                : `${eventCardsValuePath.value}.date.color`,
            valueSource:
              states.value.event_cards === State.DEFAULT
                ? fields.value.list_date.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.event_cards === State.DEFAULT
                ? "options.icon.color"
                : `${eventCardsValuePath.value}.date.icon.color`,
            valueSource:
              states.value.event_cards === State.DEFAULT
                ? fields.value.list_date.field
                : selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const topLeaguesEventCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: fields.value.top_leagues_list_container.field,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: fields.value.top_leagues_list_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League name",
              value: "league_title",
              isActiveValueSource:
                fields.value.top_leagues_league_title.field.options,
            },
          ],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Event info",
              value: "top_leagues_list_event_info",
            },
            {
              label: "Bet items",
              value: "top_leagues_list_item",
            },
            {
              label: "Event button link",
              value: "top_leagues_list_item_more",
            },
          ],
        },
      },
    ];
  });

  const topLeaguesEventCardsValuePath = computed<string>(() => {
    if (states.value.top_leagues_event_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.top_leagues_event_cards}`;
  });

  const topLeaguesEventCardsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value.top_leagues_event_cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.top_leagues_event_cards = value;
        },
      },
      {
        section: generateDesignSectionName("Card"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${topLeaguesEventCardsValuePath.value}.card.fill`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${topLeaguesEventCardsValuePath.value}.card.border`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigBoxShadow",
            valuePath: `${topLeaguesEventCardsValuePath.value}.card.shadow`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Shadow",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Team/Player"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? "options.color"
                : `${topLeaguesEventCardsValuePath.value}.name.color`,
            valueSource:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? fields.value.top_leagues_list_event_name.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Start Time"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? "options.color"
                : `${topLeaguesEventCardsValuePath.value}.time.color`,
            valueSource:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? fields.value.top_leagues_list_start_time.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? "options.icon.color"
                : `${topLeaguesEventCardsValuePath.value}.time.icon.color`,
            valueSource:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? fields.value.top_leagues_list_start_time.field
                : selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Date"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? "options.color"
                : `${topLeaguesEventCardsValuePath.value}.date.color`,
            valueSource:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? fields.value.top_leagues_list_date.field
                : selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? "options.icon.color"
                : `${topLeaguesEventCardsValuePath.value}.date.icon.color`,
            valueSource:
              states.value.top_leagues_event_cards === State.DEFAULT
                ? fields.value.top_leagues_list_date.field
                : selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,
    startTimeDateControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,

    topLeaguesEventCardsDesignControls,
    topLeaguesEventCardsContentControls,
    topLeaguesEventCardsStatesControls,
    topLeaguesStartTimeDateControls,
    topLeaguesEventButtonLinkDesignControls,
    topLeaguesEventButtonLinkContentControls,
    topLeaguesEventButtonLinkStateControls,
  };
};
