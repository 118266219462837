<template>
  <div v-if="hasContentPermissions">
    <ConfigTextInput
      placeholder="Enter"
      :model-value="value"
      :active="true"
      @update:model-value="handleUpdate"
    />
  </div>
</template>

<script lang="ts" setup>
import { useWidgetsStore } from "~~/store/widgets";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  meta?: {
    valueField?: keyof IWidgetField;
  };
}>();

const store = useWidgetsStore();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const currentField: IWidgetField | undefined = getCurrentField().value;

type FieldValue = string | number | undefined;
const value = computed<FieldValue>(() => {
  if (props.meta?.valueField) {
    return currentField?.[props.meta?.valueField] as string;
  }

  return currentField?.value as FieldValue;
});

const handleUpdate = (value: string | number): void => {
  if (!currentField) {
    return;
  }

  if (props.meta?.valueField) {
    store.updateFieldProperty(currentField, value, props.meta?.valueField);
    return;
  }

  store.updateFieldValue(currentField.id, value);
};
</script>
