import { computed, type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/gdpr/useGdprItems";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useTitleControls } from "~~/composables/widgets/items/useTitleControls";
import { CellSizeType } from "~~/models/grid.interface";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useLinkStatesControls } from "~~/composables/widgets/common/useLinkStatesControls";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { useFormElementConfig } from "~~/composables/widgets/form/useFormElementConfig";

const WIDGET_SETTINGS = [
  {
    label: "Title",
    value: "title",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Checkboxes container",
    value: "form",
  },
  {
    label: "Buttons container",
    value: "buttons_container",
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displaySettings.layout",
            valueSource: widget.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displaySettings.gap",
            valueSource: widget.value,
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.displaySettings.alignment",
            valueSource: widget.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: WIDGET_SETTINGS,
        },
      },
    ];
  });

  /* 
    Title
  */

  const { titleControls } = useTitleControls(
    {
      field: toRef(fields.value.title.field),
      state: toRef(states.value.title),
      label: "",
    },
    emit
  );

  const linkState = computed<string>(() => {
    return states.value.title;
  });

  const { titleLinkStatesControls } = useLinkStatesControls(
    { state: linkState, field: toRef(fields.value.title.field) },
    emit
  );

  /* 
    Checkboxes container
  */
  const checkboxesContainerDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.form.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.form.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const checkboxesContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displaySettings.layout",
            valueSource: fields.value.form.field,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displaySettings.gap",
            valueSource: fields.value.form.field,
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.displaySettings.alignment",
            valueSource: fields.value.form.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Checkboxes",
              value: "checkboxes_styling",
            },
          ],
        },
      },
    ];
  });

  /* 
    Buttons container
  */
  const buttonsContainerDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.buttons_container.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.buttons_container.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const buttonsContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.displaySettings.width",
            valueSource: fields.value.buttons_container.field,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Width",
              icon: "ant-design:column-width-outlined",
              exclude: [CellSizeType.HUG],
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displaySettings.layout",
            valueSource: fields.value.buttons_container.field,
            options: {
              label: "Buttons layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displaySettings.gap",
            valueSource: fields.value.buttons_container.field,
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.displaySettings.buttonsWidth",
            valueSource: fields.value.buttons_container.field,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Buttons width",
              except: [CellSizeType.FIXED],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.displaySettings.buttonsAlignment",
            valueSource: fields.value.buttons_container.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Allow all",
              value: "button_allow_all",
            },
            {
              label: "Allow selection",
              value: "button_allow_selection",
            },
            {
              label: "Deny",
              value: "button_deny",
            },
          ],
        },
      },
    ];
  });

  /* 
    Buttons
  */

  const allowAllState = computed<State>(() => {
    return states.value.button_allow_all;
  });

  const {
    buttonStatesControls: allowAllStateControls,
    buttonDesignControls: allowAllDesignControls,
    buttonContentControls: allowAllContentControls,
  } = useButtonControls({
    state: allowAllState,
    stateHandler: (state: State) => {
      emit("update-state", { state: "button_allow_all", value: state });
    },
  });

  const allowSelectionState = computed<State>(() => {
    return states.value.button_allow_selection;
  });

  const {
    buttonDesignControls: allowSelectionDesignControls,
    buttonContentControls: allowSelectionContentControls,
    buttonStatesControls: allowSelectionStatesControls,
  } = useButtonControls({
    state: allowSelectionState,
    stateHandler: (state: State) => {
      emit("update-state", { state: "button_allow_selection", value: state });
    },
  });

  const denyState = computed<State>(() => {
    return states.value.button_deny;
  });

  const {
    buttonDesignControls: denyDesignControls,
    buttonContentControls: denyContentControls,
    buttonStatesControls: denyStatesControls,
  } = useButtonControls({
    state: denyState,
    stateHandler: (state: State) => {
      emit("update-state", { state: "button_deny", value: state });
    },
  });

  /* 
    Checkboxes
  */

  /* 
    Checkbox
  */

  const {
    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,
  } = useFormElementConfig(widget, states, emit);

  // const checkboxDesignControls = computed<ControlProp[]>(() => {
  //   return [
  //     {
  //       componentPath: "common/ConfigRangeSlider",
  //       valuePath: "options.cornerRadius",
  //       valueSource: fields.value.checkboxes_styling.field,
  //       className: "p-l-16 p-r-16 p-t-16",
  //       options: {
  //         label: "Corner radius",
  //         isBold: true,
  //         minValue: 0,
  //         maxValue: 100,
  //       },
  //     },
  //     {
  //       componentPath: "common/ConfigRangeSlider",
  //       valuePath: "options.size",
  //       valueSource: fields.value.checkboxes_styling.field,
  //       className: "p-l-16 p-r-16 p-t-16",
  //       options: {
  //         label: "Size",
  //         isBold: true,
  //         minValue: 12,
  //         maxValue: 32,
  //       },
  //     },
  //     typography({
  //       source: fields.value.checkboxes_styling.field,
  //       except: ["ConfigAlignment"],
  //     }),
  //   ];
  // });

  // const formCheckboxStatePath = computed<string>(() => {
  //   if (states.value.checkboxes_styling === State.SELECTED_DEFAULT) {
  //     return "options";
  //   }

  //   return `options.states.${states.value.checkboxes_styling}`;
  // });

  // const checkboxStatesControls = computed<ControlProp[]>(() => {
  //   return [
  //     {
  //       componentPath: "common/ConfigStatesList",
  //       valuePath: "",
  //       // valueSource: titleLinkState.value,
  //       valueSource: states.value.checkboxes_styling,
  //       options: {
  //         items: [
  //           {
  //             label: STATE_KEY_LABELS.selected_default,
  //             value: State.SELECTED_DEFAULT,
  //           },
  //           {
  //             label: STATE_KEY_LABELS.selected_hover,
  //             value: State.SELECTED_HOVER,
  //           },
  //           {
  //             label: STATE_KEY_LABELS.unselected_default,
  //             value: State.UNSELECTED_DEFAULT,
  //           },
  //           {
  //             label: STATE_KEY_LABELS.unselected_hover,
  //             value: State.UNSELECTED_HOVER,
  //           },
  //         ],
  //       },
  //       onUpdate(value: State) {
  //         states.value.checkboxes_styling = value;
  //       },
  //     },
  //     {
  //       componentPath: "ConfigColorPickerInput",
  //       valuePath: `${formCheckboxStatePath.value}.fill`,
  //       valueSource: fields.value.checkboxes_styling.field,
  //       options: {
  //         placeholder: "None",
  //         label: "Fill",
  //         isBold: true,
  //         type: ColorPickerType.BACKGROUND,
  //       },
  //     },
  //     {
  //       componentPath: "ConfigBorder",
  //       valuePath: `${formCheckboxStatePath.value}.border`,
  //       valueSource: fields.value.checkboxes_styling.field,
  //       options: {
  //         placeholder: "None",
  //         label: "Border",
  //         isBold: true,
  //       },
  //     },
  //     {
  //       componentPath: "ConfigColorPickerInput",
  //       valuePath: `${formCheckboxStatePath.value}.checkIcon`,
  //       valueSource: fields.value.checkboxes_styling.field,
  //       options: {
  //         placeholder: "None",
  //         label: "Check icon",
  //         isBold: true,
  //         type: ColorPickerType.BACKGROUND,
  //       },
  //     },
  //   ];
  // });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,
    titleLinkStatesControls,

    /* 
      Checkboxes
    */
    checkboxesContainerDesignControls,
    checkboxesContainerContentControls,

    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    /* 
      Buttons
    */

    buttonsContainerDesignControls,
    buttonsContainerContentControls,

    allowAllStateControls,
    allowAllDesignControls,
    allowAllContentControls,

    allowSelectionDesignControls,
    allowSelectionContentControls,
    allowSelectionStatesControls,

    denyDesignControls,
    denyContentControls,
    denyStatesControls,

    // /*
    //   Checkbox
    // */
    // checkboxDesignControls,
    // checkboxStatesControls,
  };
};
