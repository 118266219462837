import {
  DESIGN_INITIAL,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";
import { TOOLTIPS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/tooltips-styling";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 75;

const generateStakeInfo = () => ({
  name: "stake_info",
  type: "UnknownField",
  value: null,
  options: {
    ...DESIGN_INITIAL(),
    display: {
      distance: 12,
    },
  },
});

const generateEachWay = () => ({
  name: "each_way_bet",
  type: "UnknownField",
  value: null,
  options: {
    ...DESIGN_INITIAL(),
    display: {
      distance: 12,
    },
  },
});

const defaultTextOptions = (): IWidgetOptions => {
  const initialOptions: IWidgetOptions = TITLE_INITIAL().options;

  delete initialOptions.link;

  return {
    ...initialOptions,
    theme: getDefaultTheme(13),
  };
};

const generateTotalStake = () => ({
  name: "total_stake",
  type: "UnknownField",
  value: null,
  options: {
    ...DESIGN_INITIAL(),
    display: {
      distance: 12,
    },
    fontFamily: getDefaultFontFamily(),
    label: defaultTextOptions(),
    value: defaultTextOptions(),
  },
});

const generateCheckbox = () => ({
  name: "each_way_bet_checkbox",
  type: "UnknownField",
  value: null,
  options: {
    ...CHECKBOX_STYLE_INITIAL(),
    color: getDefaultFillValue("#000000"),
  },
});

const tooltipInitial = TOOLTIPS_STYLE_INITIAL().options;

const generateTooltip = () => ({
  name: "each_way_bet_tooltip",
  type: "UnknownField",
  value: null,
  options: {
    ...tooltipInitial,
    textChooseIcon: {
      ...tooltipInitial.textChooseIcon,
      gap: undefined,
    },
  },
});

const v74WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "BetslipFenixWidget" && !widget.content.stake_info) {
    widget.content.stake_info = generateStakeInfo();
    widget.content.each_way_bet = generateEachWay();
    widget.content.total_stake = generateTotalStake();
    widget.content.each_way_bet_checkbox = generateCheckbox();
    widget.content.each_way_bet_tooltip = generateTooltip();
  }

  return widget;
};
export default v74WidgetMigration;
