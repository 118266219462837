import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex } from "../helpers";

export const generateWidgetStyles =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const settings = widget.options.displaySettings;

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: generateDefaultStyles(widget.options),
    });

    cssString += generateCssClassWithContent({
      className: widget.options._contentCssClass,
      content: generateFlex({
        flex: "flex",
        align: "stretch",
        justify: "flex-start",
        direction: settings.layout === "top" ? "column" : "column-reverse",
        gap: "0",
      }),
    });

    return cssString;
  };

export const generatePlayTogglerStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content:
        generateDefaultStyles(field.options) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "center",
          direction: "row",
          gap: "0",
        }),
    });

    for (const state in field.options.states) {
      const stateDetails = field.options.states[state];

      const flex = generateFlex({
        flex: "flex",
        align: stateDetails.displaySettings.alignment,
        justify: "center",
        direction:
          stateDetails.displaySettings.layout === DisplayOrientation.HORIZONTAL
            ? "row"
            : "column",
        gap: stateDetails.displaySettings.gap,
      });

      cssString += generateCssClassWithContent({
        className: stateDetails._cssClass,
        content: flex,
      });
    }

    return cssString;
  };

export const generateCasinoGameWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("CasinoGameWidget");
  widget.options._contentCssClass = generateClassName(
    "CasinoGameWidget_content"
  );

  const iframe = widget.content["iframe"];
  const play_toggler = widget.content["play_toggler"];
  const play_toggler_title = widget.content["play_toggler_title"];
  const play_toggler_button = widget.content["play_toggler_button"];

  iframe.options._cssClass = generateClassName("iframe");

  play_toggler.options._cssClass = generateClassName("play_toggler");
  play_toggler.options.states.free._cssClass =
    generateClassName("play_toggler_free");
  play_toggler.options.states.money._cssClass =
    generateClassName("play_toggler_money");

  play_toggler_title.options._cssClass =
    generateClassName("play_toggler_title");

  play_toggler_button.options._cssClass = generateClassName(
    "play_toggler_button"
  );
  play_toggler_button.options.iconSettings._cssClass = generateClassName(
    "play_toggler_button_icon"
  );

  return pipeSync<string>(
    generateWidgetStyles(widget),
    generateStringDefault(iframe),
    generatePlayTogglerStyles(play_toggler),
    generateStringDefault(play_toggler_title),
    generateStageButtonCssString(play_toggler_button as IWidgetField)
  )("");
};
