import { Ref } from "vue";

import { type IWidgetField } from "~~/models/widgets/widget.core/widget.model";

import { useConfigArrayItemsControl } from "../items/useConfigArrayItemsControl";

export const useDropdownContainerControls = (
  field: Ref<IWidgetField>,
  titleField: Ref<IWidgetField | null>
) => {
  const dropdownContainerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: field.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: field.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: field.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: field.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: field.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const dropdownContainerContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.display",
        valueSource: titleField.value,
        options: {
          label: "Resizing",
          isBold: true,
          showAlignmentOnFillOnly: true,
        },
      },
      {
        componentPath: "ConfigDropdownTitle",
        valuePath: "value",
        valueSource: titleField.value,
        options: {
          label: "Dropdown container",
          fieldName: "dropdown",
        },
      },
      {
        componentPath: "ConfigDropdownMenu",
        options: {
          label: "Dropdown menu",
          fieldName: "items",
        },
      },
      useConfigArrayItemsControl("Menu items"),
    ];
  });

  return {
    dropdownContainerDesignControls,
    dropdownContainerContentControls,
  };
};
