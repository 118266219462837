<template>
  <a-button
    type="primary"
    ghost
    @click="showModal"
    >{{ activatorTitle }}</a-button
  >

  <CommonModal
    :visible="visible"
    :icon-color="IconColor.RED"
    :icon-type="IconType.WARNING"
    submit-button-label="Create Page"
    title="Pay attention"
    @submit="hideModal"
    @cancel="handleCancelClick"
  >
    <template #content>
      <div>
        <p>Once you add a page to a template, its grid cannot be changed.</p>
        <p>
          If you haven't created a grid for the rest of the resolutions, they
          will be stretched from the mobile.
        </p>
        <a-input
          v-model:value="newPageName"
          placeholder="Enter page name"
          :maxlength="MAX_TEXT"
          class="grid-saved-sidebar__new-page-name-input"
          @keyup.enter="hideModal"
        >
        </a-input>
      </div>
    </template>
  </CommonModal>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

import { useGridConfig } from "~~/store/grid";
import { usePagesStore } from "~~/store/pages";
import { useMetaStore } from "~~/store/meta";
import { useStaticTranslations } from "~~/store/staticTranslations";
import { MAX_TEXT } from "~~/constants/input-validation";
import { IconColor, IconType } from "~~/models/common/common-modal.enum";
import {
  PAGE_NAME_REQUIRED,
  CREATING_PAGE_SUCCESS,
  CREATING_PAGE_ERROR,
} from "~~/constants/notification-messages";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";

withDefaults(
  defineProps<{
    activatorTitle?: string;
  }>(),
  {
    activatorTitle: "Add page",
  }
);

const router = useRouter();

const gridStore = useGridConfig();
const pagesStore = usePagesStore();
const metaStore = useMetaStore();
const notification = useNotification();
const staticTranslations = useStaticTranslations();

const { templateId } = storeToRefs(gridStore);

const { selectedPageId } = storeToRefs(pagesStore);

const visible = ref<boolean>(false);

const newPageName = ref<string>("");

const showModal = (): void => {
  visible.value = true;
};

const hideModal = async (): Promise<void> => {
  if (!newPageName.value) {
    notification.notify({
      text: PAGE_NAME_REQUIRED,
      type: "warn",
      duration: 1000,
    });
    return;
  }
  pagesStore
    .createPage(newPageName.value)
    .then(() => {
      notification.notify({
        text: CREATING_PAGE_SUCCESS,
        type: "success",
      });
      router.push({
        path: "page",
        query: {
          id: selectedPageId.value,
          project_id: metaStore.projectId,
          template_id: templateId.value,
          lang: staticTranslations.translationLanguage,
        },
      });
      gridStore.changeIsLayoutSavedStatus(false);

      useIframePostMessage().sendFullRedirect(
        `/pages/${selectedPageId.value}/setup`
      );
    })
    .catch(error => {
      if (error.response && error.response.status === 422) {
        notification.notify({
          text: error.response.data.name,
          type: "error",
        });
      } else {
        notification.notify({
          text: CREATING_PAGE_ERROR,
          type: "error",
        });
      }
    })
    .finally(() => {
      visible.value = false;
      newPageName.value = "";
    });
};

const handleCancelClick = (): void => {
  visible.value = false;
  newPageName.value = "";
};

onMounted(() => {
  /*TODO: need to remove this logic, when templateId will be moved to the meta store */
  pagesStore.setTemplateId(templateId.value);
});
</script>

<style lang="scss">
.grid-saved-sidebar {
  &__new-page-name-input {
    border-radius: 6px;
  }

  p {
    margin-bottom: $space-m;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
