import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";

export const MIN_SEARCH_CATEGORY_LENGTH = 1;
export const SEARCH_INPUT_DELAY = 300;
export const CATEGORY_INPUT_DELAY = 300;
export const MAX_DESCRIPTION_COLUMNS = 3;
export const DEFAULT_COLOR_VALUE = "#000000";
export const DEFAULT_OPACITY_VALUE = "100";
export const DEFAULT_RGBA_COLOR_VALUE = "rgba(0,0,0,1)";
export const VENDOR_PREFIX = "feco-";
export const DEFAULT_FONT = "Roboto";

export const THEME_OPTIONS = [
  { value: "", label: "No heading type", size: 14 },
  { value: "h1", label: "Heading 1", size: 32 },
  { value: "h2", label: "Heading 2", size: 24 },
  { value: "h3", label: "Heading 3", size: 19 },
  { value: "h4", label: "Heading 4", size: 16 },
  { value: "h5", label: "Heading 5", size: 13 },
  { value: "h6", label: "Heading 6", size: 11 },
];

export const FONT_FAMILY_OPTIONS = [
  {
    value: `var(--${VENDOR_PREFIX}font-family-1, ${DEFAULT_FONT})`,
    label: "Font Family 1",
  },
  {
    value: `var(--${VENDOR_PREFIX}font-family-2, ${DEFAULT_FONT})`,
    label: "Font Family 2",
  },
  {
    value: `var(--${VENDOR_PREFIX}font-family-3, ${DEFAULT_FONT})`,
    label: "Font Family 3",
  },
  {
    value: `var(--${VENDOR_PREFIX}font-family-4, ${DEFAULT_FONT})`,
    label: "Font Family 4",
  },
  {
    value: `var(--${VENDOR_PREFIX}font-family-5, ${DEFAULT_FONT})`,
    label: "Font Family 5",
  },
  {
    value: `var(--${VENDOR_PREFIX}font-family-6, ${DEFAULT_FONT})`,
    label: "Font Family 6",
  },
];

export const MIN_IMAGE_CONTAINER_SIZE = 48;
export const MIN_CARD_WIDTH = 124;

export const MAX_IMAGE_CONTAINER_SIZE = 512;
export const MAX_CARD_WIDTH = 640;

export const MAX_CORNER_RADIUS_NUMBER_INPUT_VALUE = 100;

export const MAX_SHADOW_OFFSET_NUMBER_INPUT_VALUE = 50;

export const MAX_CARDS_GAP_NUMBER_INPUT_VALUE = 72;

export const WIDGETS_GRID_ID = "widgets-grid";
export const ARROW_OPTIONS = [
  { value: "Down", label: "Down" },
  { value: "CaretDown", label: "CaretDown" },
  { value: "More", label: "More" },
  { value: "Ellipsis", label: "Ellipsis" },
];
export const DEFAULT_FORM_WIDTH = 320;

export const ROW_COLUMN_ALIGNMENT_MAP: { [key: string]: string } = {
  left: "start",
  right: "end",
  center: "center",
};

export const DROPDOWN_MENU_MAX_ITEMS_COUNT = 20;
export const DROPDOWN_MENU_MIN_ITEMS_COUNT = 1;
export const FORM_WIDGET_HIDDEN_FIELDS = ["form"];
export const DEFAULT_LINK_COLOR = "rgba(22, 90, 255, 1)";
export const PROFILE_DROPDOWN_WIDGET_HIDDEN_FIELDS = [
  FieldName.DROPDOWN_CONTAINER,
  FieldName.ITEMS,
  FieldName.BONUS,
];
export const PROFILE_DROPDOWN_WIDGET_AUTHORIZED_FIELDS = [
  FieldName.DEPOSIT,
  FieldName.LOGOUT,
  FieldName.DROPDOWN,
  FieldName.BALANCE,
];
