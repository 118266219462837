<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <TimezonesWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      :style="wrapperStyle"
      class="timezones-widget"
    >
      <WidgetField
        v-if="fields.dropdown_container.active"
        :widget="widget"
        :field="fields.dropdown_container.field"
      >
        <DropdownTriggerField
          :widget="widget"
          :field="fields.dropdown_container.field"
          :state="states.dropdown_container"
        />
      </WidgetField>

      <WidgetField
        v-if="isWidgetSelected"
        :widget="widget"
        :field="fields.dropdown_menu.field"
      >
        <DropdownMenuField :field="fields.dropdown_menu.field">
          <WidgetField
            :widget="widget"
            :field="fields.search_field.field"
          >
            <DropdownTriggerField
              :widget="widget"
              :field="fields.search_field.field"
              :value="fields.search_field.field.options.text.value"
              :placeholder-value="
                fields.search_field.field.options.placeholder.value
              "
              :state="states.search_field"
              class="timezones-widget__search"
            />
          </WidgetField>

          <div
            :style="itemsContainerStyle"
            class="timezones-widget__items-container"
          >
            <WidgetField
              v-for="idx in 3"
              :key="idx"
              :field="fields.menu_items.field"
              :widget="widget"
            >
              <ArrayItemField
                :field="fields.menu_items.field"
                :wrapper-options="itemDisplaySettings"
                :state="states.menu_items"
                :is-items-group-selected="false"
              />
            </WidgetField>
          </div>
        </DropdownMenuField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useTimezonesInitData } from "~~/composables/widgets/timezones/useTimezonesInitData";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getFlexPosition } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { states, DEFAULT_STATES, initFields } = useTimezonesInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const isWidgetSelected = computed<boolean>(() => {
  return props.widget.id === selectedWidget.value?.id;
});

const displaySettings = computed<IWidgetOptions>(() => {
  return props.widget.options.display;
});

const itemDisplaySettings = computed<IWidgetOptions>(() => {
  return {
    display: {
      alignment: getFlexPosition(displaySettings.value.alignment),
    },
    ...fields.value.menu_items.field.options,
  };
});

const flexStyles: ElementStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};

const wrapperStyle = computed<ElementStyle>(() => {
  if (displaySettings.value.resizing === ResizingType.HUG) {
    return {
      ...flexStyles,
      alignItems: getFlexPosition(displaySettings.value.alignment),
    };
  }
  return {
    ...flexStyles,
    alignItems: "stretch",
  };
});

const itemsContainerStyle = computed<ElementStyle>(() => {
  return {
    ...flexStyles,
    alignItems: "stretch",
    gap: getPxValueFromNumber(
      fields.value.menu_items.field.options.spaceBetween
    ),
  };
});

initFields();
</script>

<style lang="scss">
.timezones-widget {
  position: relative;
  &__search {
    .basic-title-widget__content {
      text-align: left;
    }
  }
  .dropdown-menu-field {
    .dropdown-trigger-field {
      .common-icon {
        font-size: 1.5em;
      }
    }
  }
}
</style>
