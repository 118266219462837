<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <BetsListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="bets-list-widget">
      <WidgetField
        v-if="fields.header?.active"
        :widget="widget"
        :field="fields.header.field"
        :style="headerStyles"
        class="bets-list-widget__header"
      >
        <WidgetField
          v-if="fields.title.active"
          :widget="widget"
          :field="fields.title.field"
        >
          <BasicTitleField :field="fields.title.field" />
        </WidgetField>

        <BetsListTabs
          :widget="widget"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.event_cards.active"
        :widget="widget"
        :field="fields.event_cards.field"
      >
        <BetsListEvents
          v-if="states.event_cards === State.WITH_ACTIVE_EVENTS"
          :widget="widget"
          :field="fields.event_cards.field"
          :start-time-field="fields.event_time.field"
          :bet-item-field="fields.bet_items.field"
          :bet-item-state="states.bet_items"
          :list-item-more-state="states.event_button"
          :event-button-field="fields.event_button.field"
        />

        <BasicTitleField
          v-else
          :style="noEventsInlineStyle"
          :field="fields.no_events_title.field"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useBetsListWidgetInitData } from "~~/composables/widgets/bets-list/useBetsListWidgetInitData";
import { State } from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useBetsListStyles } from "~~/composables/widgets/bets-list/useBetsListStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useBetsListWidgetInitData(
  props.widget
);

const noEventsInlineStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.event_cards.field.options.card),
  };
});

const { headerStyles } = useBetsListStyles(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

initFields();
</script>

<style lang="scss">
.bets-list-widget {
  .config-radio-icons__container {
    justify-content: flex-start;
  }
}
</style>
