<template>
  <div
    v-if="hasDesignPermissions"
    class="p-t-16 p-b-16 config-text-icon widget-control-container"
  >
    <p
      :class="{
        bold: isBold,
      }"
      class="m-b-4 p-l-16 p-r-16"
    >
      Icon
    </p>

    <div>
      <ConfigColorPickerInput
        v-if="!hideColor"
        :model-value="modelValue.color"
        :type="ColorPickerType.TEXT"
        class="config-text-icon__color"
        label="Icon color"
        @update:model-value="handleColorUpdate"
      />
      <ConfigSlider
        :min="4"
        :max="32"
        :model-value="modelValue.distance"
        label="Spacing between text and icon"
        class="config-text-icon__corner-radius"
        @update:model-value="handleDistanceUpdate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { IFillControl } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

type TextIconProps = {
  color: IFillControl;
  distance?: number;
};

const props = defineProps<{
  modelValue: TextIconProps;
  isBold?: boolean;
  hideColor?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: TextIconProps): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleColorUpdate = (color: IFillControl): void => {
  emit("update:modelValue", {
    ...props.modelValue,
    color,
  });
};

const handleDistanceUpdate = (distance?: number) => {
  emit("update:modelValue", {
    ...props.modelValue,
    distance,
  });
};
</script>

<style lang="scss">
.config-text-icon {
  &__color {
    border-bottom: unset;
    padding-bottom: 0;
  }

  &__corner-radius {
    border-bottom: unset;
    padding-bottom: 0;
  }
}
</style>
