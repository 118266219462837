import type { Ref } from "vue";

import {
  ColorPickerType,
  type IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useSportbookLiveCardInfo } from "../sportbook-live/useSportbookLiveCardInfo";
import { useBetItemsConfig } from "../sportsbook/useBetItemsConfig";
import { useBetsListTabsControls } from "../bets-list/useBetsListTabsControls";
import { useTitleControls } from "../dropdown/useTitleControls";
import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

import { useFavoritesEventsLiveWidgetControls } from "./useFavoritesEventsLiveWidgetControls";
import { useFavoritesEventsLiveItems } from "./useFavoritesEventsLiveItems";
import { useFavoritesEventsLiveCardsControls } from "./useFavoritesEventsLiveCardsControls";

export const useFavoritesEventsLiveConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const { DROPDOWN_ITEMS, TABS } = useFavoritesEventsLiveItems(widget);

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const { widgetDesignControls, widgetContentControls } =
    useFavoritesEventsLiveWidgetControls(widget);

  const { tabsDesignControls, tabsContentControls, tabsStatesControls } =
    useBetsListTabsControls(statesValuePath, states, emit);

  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit, true);

  const { eventCardsDesignControls, eventCardsContentControls } =
    useFavoritesEventsLiveCardsControls(fields, states, emit);

  const {
    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventTimeControls,
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventScoreContentControls,
    eventScoreDesignControls,
  } = useSportbookLiveCardInfo(widget);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    toRef(() => fields.value.heading.field),
    [FieldContentItem.TEXT, FieldContentItem.ICON, FieldContentItem.ARROW]
  );

  const headingContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: "options.text",
        valueSource: fields.value.heading.field,
        options: {
          label: "Text",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
          text: fields.value.heading.field?.value,
          hideSwitch: true,
          "onUpdate:text": (value: string) => {
            fields.value.heading.field.value = value;
          },
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: fields.value.heading.field,
        options: {
          label: "Icon",
          isBold: true,
          hideSwitch: true,
          hidePicker: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrow",
        valueSource: fields.value.heading.field,
        options: {
          label: "Arrow",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ARROW).value,
        },
      },
    ];
  });

  const { titleDesignControls: headingDesignControls, titleStatesControls } =
    useTitleControls(
      toRef(() => states.value.heading),
      toRef(() => fields.value.heading.field),
      emit
    );

  const headingStatesControls = computed(() => {
    const result = titleStatesControls.value;
    result[0].onUpdate = (value: State) => {
      emit("update-state", { state: fields.value.heading.field.name, value });
    };

    return result;
  });

  const eventLeagueControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const favIconControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // TODO - move to useSportbookLiveCardInfo when field added
  const streamIconControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.stream_icon.field,
        options: {
          hideSwitch: true,
          className: "p-l-16 p-r-16 p-t-16",
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,

    headingDesignControls,
    headingContentControls,
    headingStatesControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    eventCardsDesignControls,
    eventCardsContentControls,

    streamIconControls,
    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventLeagueControls,
    eventTimeControls,
    eventInfoDesignControls,
    eventInfoContentControls,
    teamPlayerTitleControls,
    eventScoreContentControls,
    eventScoreDesignControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    favIconControls,
  };
};
