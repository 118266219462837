import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useRacingSportbookLiveCardInfo = (
  widget: Ref<IWidgetWithFields>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Suspended
  */

  const eventSuspendedDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_suspended_status.field,
      except: ["ConfigBoxShadow"],
    });
  });

  const eventSuspendedContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_suspended_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /* 
      Time
    */

  const eventTimeControls = computed(() => {
    return [
      typography({
        source: fields.value.event_time.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.event_time.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_time.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
      Info
    */

  const eventInfoControls = computed(() => {
    return [
      typography({
        source: fields.value.event_info.field,
        visible: true,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_info.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventTimeControls,
    eventInfoControls,
  };
};
