import { IFields } from "~~/composables/widgets/useWidgetFields";

export const calculateDisableFieldsAllowed = (
  primaryWidgetFields: Array<string>,
  fields: Ref<IFields>
): ComputedRef<boolean> => {
  return computed(() => {
    return (
      primaryWidgetFields.filter(fieldName => {
        return fields.value[fieldName].active;
      }).length > 1
    );
  });
};
