<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        v-if="show"
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @select="handleSelectDropdownItem"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #deposit_form_description>
          <ControlsList
            :widget="widget"
            :controls="formDescriptionTitleField"
          />
        </template>

        <template #form_back_button-design>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonDesignControls"
          />
        </template>

        <template #form_back_button-content>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonContentControls"
          />
        </template>

        <template #form_back_button-states>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonStatesControls"
          />
        </template>

        <template #provider_card-design>
          <ControlsList
            :widget="widget"
            :controls="providerCardDesignControls"
          />
        </template>

        <template #provider_card-content>
          <ControlsList
            :widget="widget"
            :controls="providerCardContentControls"
          />
        </template>

        <template #provider_image>
          <ControlsList
            :widget="widget"
            :controls="formCardImageControls"
          />
        </template>

        <template #provider_title>
          <ControlsList
            :widget="widget"
            :controls="formCardTitleControls"
          />
        </template>

        <template #provider_description_1>
          <ControlsList
            :widget="widget"
            :controls="formCardDescriptionControls"
          />
        </template>

        <template #success_message-design>
          <ControlsList
            :widget="widget"
            :controls="statusMessageDesignControls"
          />
        </template>

        <template #success_message-content>
          <ControlsList
            :widget="widget"
            :controls="statusMessageContentControls"
          />
        </template>

        <template #fail_message-design>
          <ControlsList
            :widget="widget"
            :controls="statusMessageDesignControls"
          />
        </template>

        <template #fail_message-content>
          <ControlsList
            :widget="widget"
            :controls="statusMessageContentControls"
          />
        </template>

        <template #pending_message-design>
          <ControlsList
            :widget="widget"
            :controls="statusMessageDesignControls"
          />
        </template>

        <template #pending_message-content>
          <ControlsList
            :widget="widget"
            :controls="statusMessageContentControls"
          />
        </template>

        <template #additional_info-design>
          <ControlsList
            :widget="widget"
            :controls="additionalInfoDesignControls"
          />
        </template>

        <template #additional_info-content>
          <ControlsList
            :widget="widget"
            :controls="additionalInfoContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { useProviderFormFenixConfig } from "~~/composables/widgets/provider-form-fenix/useProviderFormFenixConfig";
import { ProviderFormFenixScreen } from "~~/models/widgets/form.model";
import {
  IParams,
  useWidgetDynamicParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  show: boolean;
  activeView: ProviderFormFenixScreen;
}>();

const bindingParamsArg = computed<IParams>(() => {
  return {
    "pageType": {
      label: "Page type",
      description: "Possible values: Deposit | Withdrawal",
      sources: ["custom"],
      required: true,
    },
    "providerCode": {
      label: "Provider code",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "providersListPageUrl": {
      label: "Providers list page url",
      sources: ["custom"],
      required: true,
    },
    "successPageUrl": {
      label: "Success page url",
      sources: ["custom"],
      required: false,
    },
    "failPageUrl": {
      label: "Fail page url",
      sources: ["custom"],
      required: false,
    },
    "pendingPageUrl": {
      label: "Pending page url",
      sources: ["custom"],
      required: false,
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});

const emit = defineEmits<{
  (e: "description-field-option-updated"): void;
  (e: "update-button-state", event: State): void;
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "update-active-view", event: string): void;
  (e: "select", event: string | null): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const activeView = toRef(() => props.activeView);
const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  formBackButtonDesignControls,
  formBackButtonContentControls,
  formBackButtonStatesControls,
  providerCardDesignControls,
  providerCardContentControls,
  formCardImageControls,
  formCardTitleControls,
  formCardDescriptionControls,
  statusMessageDesignControls,
  statusMessageContentControls,
  additionalInfoDesignControls,
  additionalInfoContentControls,
  formDescriptionTitleField,
} = useProviderFormFenixConfig(ref(props.widget), activeView, states, emit);

const handleSelectDropdownItem = (item: DropdownItem) => {
  emit("select", item.value as string | null);

  const selectedItem = DROPDOWN_ITEMS.value.find(
    curr => curr.custom?.fieldId === item?.custom?.fieldId
  );

  if (!selectedItem || !selectedItem.custom?.fieldId) {
    return;
  }

  const field = props.widget.fields.find(
    f => f.id === selectedItem.custom?.fieldId
  );

  if (!field) {
    return;
  }

  widgetsStore.setActiveElement(props.widget, field, undefined, field.type);
};

const resetStates = (): void => {
  emit("reset-states");
};
</script>
