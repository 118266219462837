import { CSSProperties } from "vue";

import {
  Display,
  FlexDirection,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

import { clearObject } from "../../PromoBetsFenixWidgetHelpers";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useContentStyles = (contentOptions: any = {}): CSSProperties => {
  const styles = {
    ...useDesignInitialStyles(contentOptions),

    display: Display.FLEX,
    flexDirection: FlexDirection.COLUMN,
    width: "100%",
    height: "100%",
    ...(contentOptions.layoutAlignment || {}),
    gap: contentOptions.gap
      ? getPxValueFromNumber(contentOptions.gap)
      : undefined,
    flexShrink: "9999",
  };

  return clearObject(styles);
};
