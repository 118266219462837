<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SearchResultsWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div>
      <WidgetField
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.event_card.field"
      >
        <SearchResultsCards
          :field="fields.event_card.field"
          :bet-items-field="fields.bet_items.field"
          :event-button-field="fields.event_button.field"
          :bet-items-state="states.bet_items"
          :event-button-state="states.event_button"
          :state="states.cards"
          :widget="widget"
        >
          <template #tag>
            <WidgetField
              :widget="widget"
              :field="fields.event_live_status.field"
            >
              <SportsBookLiveSuspended
                :field="fields.event_live_status.field"
                value="Live"
              />
            </WidgetField>
          </template>

          <template #event_time>
            <WidgetField
              :widget="widget"
              :field="fields.event_time.field"
            >
              <SportsBookLiveTime
                :field="fields.event_time.field"
                icon="ant-design:clock-circle-filled"
              >
                <span>00-00-0000 00:00 </span>
              </SportsBookLiveTime>
            </WidgetField>
          </template>

          <template #event_title>
            <WidgetField
              :widget="widget"
              :field="fields.event_title.field"
            >
              <BasicTitleField
                :field="fields.event_title.field"
                value="Team / Player #1 - Team / Player #2"
              />
            </WidgetField>
          </template>

          <template #empty>
            <BasicTitleField :field="fields.no_event_title.field" />
          </template>
        </SearchResultsCards>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/search-results/useSearchResultsInit";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

/*
  Initialize widget with initial options
*/

initFields();
</script>

<style lang="scss"></style>
