import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  DisplayOrientation,
  Alignment,
  Align,
  State,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, string> = {
    play_toggler: "free",
    title: State.DEFAULT,
    play_toggler_button: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({ ...DEFAULT_STATES });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions([], {
    bindingParams: {},
    displaySettings: {
      alignment: `${Align.MIDDLE} ${Align.MIDDLE}`,
      layout: "top",
    },
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialIframeOptions = prefillEmptyOptions(
      fields.value.iframe.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["baseDisplaySettings", "size", "spacing"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(),
          },
        },
      }).options
    );

    const playTogglerStateInitial = (): IWidgetOptions => {
      return {
        displaySettings: {
          layout: DisplayOrientation.HORIZONTAL,
          alignment: Alignment.CENTER,
          gap: 24,
        },
      };
    };

    const initialPlayTogglerOptions = prefillEmptyOptions(
      fields.value.play_toggler.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["baseDisplaySettings", "size", "spacing"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(20),
            color: getDefaultFillValue("#ffffff"),
            states: {
              free: {
                ...playTogglerStateInitial(),
                text: "You're playing for free. Let's",
                button: "Play for money",
              },
              money: {
                ...playTogglerStateInitial(),
                text: "You're playing for money. Let's",
                button: "Play for free",
              },
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#1677FF"),
        },
      }).options
    );

    const initialPlayTogglerTextOptions = prefillEmptyOptions(
      fields.value.play_toggler_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(13),
        },
      }).options
    );

    const btnInitial = BUTTON_INITIAL();

    const initialPlayTogglerButtonOptions = prefillEmptyOptions(
      fields.value.play_toggler_button.field.options,
      getInitialOptions({
        data: btnInitial,
        values: {
          iconSettings: {
            ...btnInitial.options.iconSettings,
            _active: false,
          },
          fill: getDefaultFillValue("#1EB691"),
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
            top: 5,
            bottom: 5,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.iframe.field,
      initialIframeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.play_toggler.field,
      initialPlayTogglerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.play_toggler_title.field,
      initialPlayTogglerTextOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.play_toggler_button.field,
      initialPlayTogglerButtonOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
