<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    :current-state="states.widget"
  >
    <!-- All controls in right sidebar -->
    <CardFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="card-fenix-widget">
      <BasicCardFenixWidget
        :widget="widget"
        :states="states"
      />
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/card/fenix/useCardFenixInit";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, /*fields,*/ states, DEFAULT_STATES } = useInit(
  toRef(props.widget)
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = [""];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();
</script>

<style lang="scss">
.card-fenix-widget {
  position: relative;
  width: 100%;
}
</style>
