import { type Ref, toRef } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useImageControls } from "~~/composables/widgets/card/useImageControls";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { DepositListScreen, FormScreen } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useTitleLinkStateControls } from "~~/composables/widgets/card/useTitleLinkStatesControls";

type AciveViewItems = Record<FormScreen | string, Record<string, string>>;
const VIEWS_MAPPER: AciveViewItems = {
  [FormScreen.SUCCESS]: {
    title: "success_title",
    image: "success_image",
    description: "success_description_1",
    button: "success_button",
  },
  [FormScreen.EXPIRED]: {
    title: "expired_title",
    image: "expired_image",
    description: "expired_description_1",
    button: "expired_button",
  },
  [DepositListScreen.FAIL]: {
    title: "fail_title",
    image: "fail_image",
    description: "fail_description_1",
    button: "fail_button",
  },
};

export const useFormScreenConfig = (
  widget: Ref<IWidgetWithFields>,
  activeView: Ref<FormScreen>,
  states: Ref<Record<string, string>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const currentViewItems = computed<Record<string, string>>(() => {
    return VIEWS_MAPPER[activeView.value];
  });

  const imageNameKey = computed<string>(() => {
    return currentViewItems.value.image;
  });

  const valueSources = computed<Record<string, IWidgetField>>(() => {
    return {
      title: fields.value[currentViewItems.value?.title]?.field,
      description: fields.value[currentViewItems.value?.description]?.field,
      image: fields.value[currentViewItems.value?.image]?.field,
      button: fields.value[currentViewItems.value?.button]?.field,
    };
  });

  const titleLinkStatePath = computed<string>(() => {
    return currentViewItems.value?.title;
  });

  const titleLinkState = computed<Record<string, State>>(() => {
    const fieldName = selectedField.value?.name;
    const stateValue = states.value?.[titleLinkStatePath.value] as State;

    if (fieldName) {
      return {
        [fieldName]: stateValue,
      };
    } else {
      return {};
    }
  });

  const titleValue = computed<IWidgetField>(() => {
    return valueSources.value.title;
  });

  const { titleLinkStatesControls } = useTitleLinkStateControls(
    titleLinkState,
    undefined,
    undefined,
    titleValue,
    state => {
      states.value[titleLinkStatePath.value] = state;
    }
  );

  const imageValue = toRef(() => valueSources.value.image);
  const buttonState = computed<State>(() => {
    const currState =
      states.value[VIEWS_MAPPER[activeView.value]?.button] || State.DEFAULT;
    return currState as State;
  });

  const { imageControls: screenImageControls } = useImageControls(
    null,
    imageValue,
    imageNameKey
  );

  const {
    buttonStatesControls: screenButtonStatesControls,
    buttonDesignControls: screenButtonDesignControls,
    buttonContentControls: screenButtonContentControls,
  } = useButtonControls({
    state: buttonState,
    stateHandler: (state: State) => {
      states.value[VIEWS_MAPPER[activeView.value]?.button] = state;
    },
  });

  const screenTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: valueSources.value.title,
        className: "config-group-last-element",
        options: {
          placeholder: "Enter text",
          label: "Active",
          active: true,
        },
      },
      typography({ source: valueSources.value.title! }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: valueSources.value.title,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({
              name: "customScreenTitleLink",
            });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: valueSources.value.title,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    valueSources,
    imageNameKey,

    screenTitleControls,
    screenImageControls,

    screenButtonStatesControls,
    screenButtonDesignControls,
    screenButtonContentControls,

    titleLinkStatesControls,
  };
};
