import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  State,
  DisplayOrientation,
  Alignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultFillImage,
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, State> = {
    event_cards: State.ACTIVE_EVENTS,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
  Initialize widget with initial options
*/

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_cards.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "padding", "size"],
        additionalData: {
          options: {
            /* 
              Active events
            */
            columns: 1,
            distance: 16,
            betItemsLayout: DisplayOrientation.HORIZONTAL,
            betItemsDistance: 16,

            /* 
              No active events
            */

            noEventsText: "There is no active events",
          },
        },
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialSuspendedOptions = prefillEmptyOptions(
      fields.value.event_suspended_status.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "shadow", "padding", "alignment", "size"],
        values: {
          theme: 13,
          fill: getDefaultFillValue("#FFF1F0"),
          color: getDefaultFillValue("#F62F39"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#F62F39"),
          }),
          spacing: {
            padding: getDefaultSpacing({
              left: 8,
              right: 8,
              top: 2,
              bottom: 2,
            }),
            margin: getDefaultSpacing({
              right: 8,
              left: 8,
            }),
          },
        },
      }).options
    );

    const initialTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["alignment"],
        additionalData: {
          options: {
            icon: {
              size: 20,
              color: getDefaultFillValue("#69B1FF"),
              value: "mdi:clock",
            },
          },
        },
        values: {
          theme: 13,
        },
      }).options
    );

    const initialInfoOptions = prefillEmptyOptions(
      fields.value.event_info.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: 14,
        },
        additionalData: {
          options: {},
        },
      }).options
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favourites_button.field.options,
      {
        iconSize: 24,
        value: "ant-design:star-outlined",
        color: {
          color: "#FFC53D",
          opacity: 100,
        },
      }
    );

    const initialStreamIconOptions = prefillEmptyOptions(
      fields.value.stream_icon.field.options,
      {
        textColor: {
          color: "#52C41A",
          opacity: 100,
        },
        iconSize: 20,
        value: "ant-design:play-circle-filled",
      }
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_cards.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_suspended_status.field,
      initialSuspendedOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourites_button.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info.field,
      initialInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.stream_icon.field,
      initialStreamIconOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
      widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  return {
    states,
    DEFAULT_STATES,
    initFields,
    initWidgetFieldsOptions,
  };
};
