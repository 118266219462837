<template>
  <BasicDesignWrapperField
    :widget="widget"
    :field="field"
  >
    <div
      :style="containerStyles"
      :class="`sport-event-fenix__markets-card--${field.options.display.layout}`"
      class="sport-event-fenix__markets-card"
    >
      <div class="sport-event-fenix__markets-card__top">
        <div class="sport-event-fenix__markets-card__status">
          <slot name="status"></slot>
        </div>

        <div class="sport-event-fenix__markets-card__name">
          <slot
            name="name"
            class="sport-event-fenix__markets-card__name"
          ></slot>
        </div>

        <div class="sport-event-fenix__markets-card__pin">
          <slot
            name="pin"
            class="sport-event-fenix__markets-card__pin"
          ></slot>
        </div>
      </div>

      <div>
        <slot name="bet_items"></slot>
      </div>
    </div>
  </BasicDesignWrapperField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
$name: sport-event-fenix__markets-card;

.#{$name} {
  @include flex-column(stretch, flex-start, 0);

  &__top {
    @include flex(center, flex-start, 0);
  }

  &--1 {
    .#{$name}__name {
      order: 2;
      flex: 1;
    }

    .#{$name}__status {
      order: 1;
    }

    .#{$name}__pin {
      order: 3;
    }
  }

  &--2 {
    .#{$name}__name {
      order: 1;
    }

    .#{$name}__status {
      order: 2;
      flex: 1;
      display: flex;
    }

    .#{$name}__pin {
      order: 3;
    }
  }

  &--3 {
    .#{$name}__name {
      order: 1;
      flex: 1;
    }

    .#{$name}__status {
      order: 2;
    }

    .#{$name}__pin {
      order: 3;
    }
  }
}
</style>
