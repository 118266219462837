<template>
  <ul class="config-tabs">
    <li
      v-for="item in items"
      :key="item.value"
      class="widget-control-container"
    >
      <div :class="itemClassName">
        <ConfigToggle
          v-if="item.isActiveValueSource !== undefined && hasContentPermissions"
          size="small"
          :model-value="item.isActiveValueSource?._active"
          :allow-disable="item.allowDisable ?? true"
          :show-options="false"
          is-field-item-toggle
          @update:model-value="
            handleUpdateActiveValue('_active', $event, item.isActiveValueSource)
          "
        />

        <p class="config-tabs__el heading">{{ item.label }}</p>

        <CommonIcon
          name="ant-design:setting-outlined"
          class="config-tabs__icon pointer"
          @click="handleClick(item)"
        />
      </div>

      <ConfigStatesList
        v-if="
          item.isActiveValueSource !== undefined &&
          item.isActiveValueSource._active &&
          item.states
        "
        class="config-tabs__states-container"
        :model-value="item.states?.value"
        :items="item.states?.items"
        @update:model-value="handleStateUpdate"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  modelValue: any;
  items?: ConfigTabItem[];
  className?: string;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: State): void;
}>();

const handleStateUpdate = (value: State) => {
  emit("update:model-value", value);
};

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const itemClassName = computed<string>(
  () => props.className || "config-tabs__item p-t-16 p-b-16 p-l-16 p-r-16"
);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleClick = (item: ConfigTabItem): void => {
  if (!selectedWidget.value) {
    return;
  }

  const field = selectedWidget.value.fields.find(
    field => field.name === item.value
  );

  if (!field) {
    return;
  }

  widgetSettingsStore.setActiveElement(
    selectedWidget.value,
    field,
    undefined,
    item.customDropdownValue
  );
};

const handleUpdateActiveValue = (
  prop: string,
  value: boolean,
  path: Record<string, any>
) => {
  if (path[prop] !== null && path[prop] !== undefined) {
    path[prop] = value;
  }
};
</script>

<style lang="scss">
.config-tabs {
  margin-bottom: 0;

  &__icon {
    margin-left: auto;
    color: $c-grey-45;
    font-size: 14px;
  }

  &__el {
    font-size: 14px;
  }

  &__item {
    @include flex(center, flex-start, 0);
  }

  &__states-container {
    border-bottom: unset;
    padding-top: 0;
  }
}
</style>
