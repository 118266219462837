<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div class="payment-provider-form-fenix-widget">
      <PaymentProviderFormFenixWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
        :show="!shouldShowFormSettings"
        :states="states"
        :active-view="activeView"
        @update-state="handleStateUpdate"
        @reset-states="resetStates"
        @update-active-view="handleUpdateActiveView"
      />

      <WidgetField
        :widget="widget"
        :field="fields.form_back_button.field"
        class="payment-provider-form-fenix-widget__payment-method-page-view--element"
      >
        <BasicButtonField
          :field="fields.form_back_button.field"
          :current-state="states.form_back_button"
          :custom-value="t('widgets.DepositListWidget.form_back_button')"
        />
      </WidgetField>

      <div
        v-if="activeView === ProviderFormFenixScreen.PROVIDER_PAGE"
        class="payment-provider-form-fenix-widget__payment-method-page-view"
      >
        <WidgetField
          :widget="widget"
          :field="fields.provider_card.field"
          class="payment-provider-form-fenix-widget__payment-method-page-view--element"
        >
          <FormCardField
            :widget="widget"
            :field="fields.provider_card.field"
            :card-state="states.provider_card"
            :cell="cell"
            class="payment-provider-form-fenix-widget__payment-method-page-view--element"
          />
        </WidgetField>

        <CommonFormWidget
          ref="commonFormWidget"
          :widget="widget"
          :states="states"
          :screens="SCREENS"
          :custom-get-form-elements="customGetFormElements"
          :active-view="activeView"
          :show-form-settings="shouldShowFormSettings"
          :custom-form-name="ProviderFormFenixScreen.PROVIDER_PAGE"
          :custom-filtered-fields="customFilteredFields"
          hide-form-content-controls
          class="payment-provider-form-fenix-widget__payment-method-page-view--element"
          @update-active-view="handleUpdateActiveView"
          @update-button-state="handleUpdateButtonState"
          @reset-states="handleResetStates"
          @update-state="handleStateUpdate"
        />

        <WidgetField
          :widget="widget"
          :field="fields.deposit_form_description.field"
        >
          <BasicTitleField
            :field="fields.deposit_form_description.field"
            value="Description"
          />
        </WidgetField>
      </div>

      <template v-else>
        <WidgetField
          :widget="widget"
          :field="fields.additional_info.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.additional_info.field"
          >
            <BasicTitleField
              :field="fields.additional_info.field"
              value="Additional info"
            />
          </BasicDesignWrapperField>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="currentStatusMessageField"
          :style="statusMessageStyles"
        >
          <CommonIcon
            :style="statusMessageIconStyles"
            :name="currentStatusMessageField?.options.icon.value"
          />

          <span :style="statusMessageTextStyles">{{
            currentStatusMessage
          }}</span>
        </WidgetField>
      </template>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useForm } from "~~/composables/widgets/form/useForm";
import { useProviderFormFenixInitData } from "~~/composables/widgets/provider-form-fenix/useProviderFormFenixInitData";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { ICell } from "~~/models/grid.interface";
import {
  FormElementName,
  FormScreen,
  HIDE_PROVIDER_FORM_FENIX_LIST,
  ProviderFormFenixScreen,
} from "~~/models/widgets/form.model";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { t } = useI18n();

const fields = useWidgetFields(props.widget as IWidgetWithFields);

const activeView = ref<string>(ProviderFormFenixScreen.PROVIDER_PAGE);
const SCREENS = [
  ProviderFormFenixScreen.PROVIDER_PAGE,
  ProviderFormFenixScreen.SUCCESS_STATUS_PAGE,
  ProviderFormFenixScreen.PENDING_STATUS_PAGE,
  ProviderFormFenixScreen.FAIL_STATUS_PAGE,
];

const customGetFormElements = (widget: IWidgetWithFields): IWidgetField[] => {
  return widget.fields.filter(field => {
    return (
      field.name !== "form" &&
      !field.options._custom &&
      field.type !== "UnknownField" &&
      !HIDE_PROVIDER_FORM_FENIX_LIST.includes(field.name)
    );
  });
};

const shouldShowFormSettings = computed<boolean>(() => {
  const field = selectedField.value;

  if (!field) return false;

  return !HIDE_PROVIDER_FORM_FENIX_LIST.includes(field.name);
});

const customFilteredFields = computed<IWidgetField[]>(() => {
  return customGetFormElements(props.widget);
});

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

const { states, DEFAULT_STATES, init } = useProviderFormFenixInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler(stateName) {
    if (stateName === props.widget.name) {
      return false;
    }

    return true;
  },
});

init();

const { handleResetStates } = useForm(
  ref(props.widget),
  SCREENS,
  "form",
  customGetFormElements
);

const handleUpdateButtonState = (value: State) => {
  handleStateUpdate({
    state: FormElementName.BUTTON,
    value,
  });
};

const handleUpdateActiveView = (value: string): void => {
  if (value === FormScreen.FORM) {
    activeView.value = ProviderFormFenixScreen.PROVIDER_PAGE;
    return;
  }
  activeView.value = value;
};

const statusMessageStyles = computed<ElementStyle>(() => {
  const field = currentStatusMessageField.value;

  if (!field) return {};

  return {
    ...getCommonStyles(field.options),
    display: "flex",
    gap: getPxValueFromNumber(field.options.distance),
    flexDirection:
      field.options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    alignItems:
      field.options.layout === DisplayOrientation.HORIZONTAL
        ? field.options.verticalAlignment
        : generateFlexAlignment(field.options.horizontalAlignment),
  };
});

const statusMessageIconStyles = computed<ElementStyle>(() => {
  const field = currentStatusMessageField.value;

  if (!field) return {};

  return {
    ...getColorStyle(field.options.icon.textColor),
    ...getFontSizeStyle(field.options.icon.iconSize),
  };
});

const statusMessageTextStyles = computed<ElementStyle>(() => {
  const field = currentStatusMessageField.value;

  if (!field) return {};

  return {
    ...getTextCommonStyles(field.options),
    flexGrow: 1,
    width: "100%",
  };
});

const currentStatusMessageField = computed(() => {
  switch (activeView.value) {
    case ProviderFormFenixScreen.SUCCESS_STATUS_PAGE:
      return fields.value.success_message.field;
    case ProviderFormFenixScreen.FAIL_STATUS_PAGE:
      return fields.value.fail_message.field;
    case ProviderFormFenixScreen.PENDING_STATUS_PAGE:
      return fields.value.pending_message.field;
  }
});

const currentStatusMessage = computed(() => {
  switch (activeView.value) {
    case ProviderFormFenixScreen.SUCCESS_STATUS_PAGE:
      return "Success Status Message";
    case ProviderFormFenixScreen.FAIL_STATUS_PAGE:
      return "Fail Status Message";
    case ProviderFormFenixScreen.PENDING_STATUS_PAGE:
      return "Pending Status Message";
  }
});
</script>

<style lang="scss">
.payment-provider-form-fenix-widget {
  &__fields-wrapper {
    @include flex-column(flex-start, flex-start);
  }

  &__field-element {
    width: 100%;
  }

  &__provider-cards-field {
    width: 100%;
    @include flex(center, center, 0px, true);
  }

  &__payment-method-page-view {
    @include flex-column(unset, unset, 0);
  }
}
</style>
