<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoGameWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
      @update-title-link-state="
        handleStateUpdate({ state: 'title', value: $event })
      "
    />

    <div
      :style="widgetStyles"
      class="casino-game-widget"
    >
      <WidgetField
        :widget="widget"
        :field="fields.iframe.field"
      >
        <div
          :style="iframeStyles"
          class="casino-game-widget__iframe-container"
        >
          <img
            :src="'/images/casino-game-preview.jpg'"
            class="casino-game-widget__img"
            alt="Casino image preview"
          />
        </div>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.play_toggler.field"
      >
        <div
          :style="playTogglerStyles"
          class="casino-game-widget__play_toggler"
        >
          <div
            :style="playTogglerContentStyles"
            class="casino-game-widget__play_toggler-content"
          >
            <WidgetField
              :widget="widget"
              :field="fields.play_toggler_title.field"
            >
              <BasicTitleField
                :field="fields.play_toggler_title.field"
                :state="states.title"
                :value="playTogglerActiveOptions.text"
              />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.play_toggler_button.field"
            >
              <BasicButtonField
                :field="fields.play_toggler_button.field"
                :current-state="states.play_toggler_button"
                :custom-value="playTogglerActiveOptions.button"
              />
            </WidgetField>
          </div>
        </div>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/casino-game/useCasinoGameInitData";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    if (stateName === "play_toggler") {
      return false;
    }

    return true;
  },
});

const iframeStyles = computed<ElementStyle>(() => {
  return getCommonStyles(fields.value.iframe.field.options);
});

const widgetStyles = computed<ElementStyle>(() => {
  const settings = props.widget.options.displaySettings;

  return {
    flexDirection: settings.layout === "top" ? "column" : "column-reverse",
  };
});

const playTogglerActiveOptions = computed<IWidgetOptions>(() => {
  return fields.value.play_toggler.field.options.states[
    states.value.play_toggler
  ];
});

const playTogglerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...fields.value.play_toggler.field.options,
      ...playTogglerActiveOptions.value,
    }),
    ...getTextCommonStyles(fields.value.play_toggler.field.options),
  };
});

const playTogglerContentStyles = computed<ElementStyle>(() => {
  return {
    alignItems: playTogglerActiveOptions.value.displaySettings.alignment,
    gap: getPxValueFromNumber(
      playTogglerActiveOptions.value.displaySettings.gap
    ),
    flexDirection:
      playTogglerActiveOptions.value.displaySettings.layout ===
      DisplayOrientation.HORIZONTAL
        ? "row"
        : "column",
  };
});

initFields();
</script>

<style lang="scss">
.casino-game-widget {
  @include flex(stretch, start, 0);
  flex-direction: column;

  &__img {
    max-width: 100%;
  }

  &__iframe-container {
    overflow: hidden;
  }

  &__play_toggler {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__play_toggler-content {
    display: flex;
    justify-content: center;
  }
}
</style>
