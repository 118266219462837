<template>
  <div
    v-if="hasContentPermissions"
    class="wconfig-dropdown-menu-item-text wconfig-item"
  >
    <ConfigToggle
      size="small"
      :model-value="active"
      :label="label"
      :allow-disable="allowDisable"
      :show-options="false"
      is-field-item-toggle
      @update:model-value="handleActive"
    />

    <div
      v-if="active"
      class="p-t-16 p-b-16"
    >
      <a-textarea
        :value="getLocalizedValue(currentField?.value)"
        show-count
        :rows="1"
        :maxlength="MAX_BUTTON"
        class="wconfig-dropdown-menu-item-text__input f-base"
        @update:value="handleInput"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ComputedRef } from "vue";

import { MAX_BUTTON } from "~~/constants/input-validation";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { useText } from "~~/components/composables/fields/text/useText";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useWidgetsStore } from "~~/store/widgets";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const widgetStore = useWidgetsStore();

const props = defineProps<{
  modelValue: {
    _active: boolean;
  };
  label?: string;
  allowDisable: boolean;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: { [key: string]: { [key: string]: string } | boolean | string }
  ): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { setLocalizedValue, getLocalizedValue } = useLocalizedValue();

const currentField: ComputedRef<IWidgetField | undefined> = getCurrentField(
  FieldName.ITEM
);

const active = computed(() => props.modelValue._active);
const handleActive = (value: boolean): void => {
  emit("update:modelValue", {
    ...props.modelValue,
    _active: value,
  });
};

const { canUpdateInputValue } = useText(MAX_BUTTON);

const handleInput = (value: string): void => {
  const updateAvailable: boolean = canUpdateInputValue(value);

  if (!updateAvailable) {
    return;
  }

  const localizedValue = setLocalizedValue.value(
    currentField.value?.value,
    value
  );

  widgetStore.updateFieldValue(currentField.value!.id, localizedValue, true);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-dropdown-menu-item-text {
  @include widgets-content-input-container;
}
</style>
