<template>
  <div
    v-if="hasContentPermissions"
    class="config-description-content p-b-16"
  >
    <div
      v-for="field in visibleFields"
      :key="field.id"
      class="position-relative f-base"
    >
      <p
        v-if="!field.value"
        class="config-description-content__placeholder"
      >
        {{ placeholder }}
      </p>
      <contenteditable
        :model-value="getLocalizedValue(field.value)"
        :no-html="false"
        tag="div"
        class="config-description-content__input f-base m-b-8"
        @update:model-value="handleInput($event, field)"
      ></contenteditable>
    </div>
  </div>
</template>

<script lang="ts" setup>
import contenteditable from "vue-contenteditable";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue?: Record<string, string> | undefined | null;
  placeholder: string | undefined;
  label?: string;
  isMultiple: boolean;
  fields: IWidgetField[];
  primaryField?: IWidgetField;
}>();

const store = useWidgetsStore();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { getLocalizedValue, setLocalizedValue } = useLocalizedValue();

const columns = computed<number>(() => {
  if (props?.isMultiple) {
    return props.primaryField?.options?.displaySettings || 0;
  }

  return 1;
});

const visibleFields = computed<IWidgetField[]>(() => {
  if (!props.isMultiple && props.primaryField) {
    return [props.primaryField];
  }

  return props.fields.filter((_, index) => {
    return index < columns.value;
  });
});

const handleInput = (event: string, field: IWidgetField): void => {
  store.updateFieldProperty(
    field,
    setLocalizedValue.value(field.value, event),
    "value"
  );
};
</script>

<style lang="scss">
.config-description-content {
  padding: $space-m $space-m 0 $space-m;

  &__placeholder {
    position: absolute;
    margin: 0;
    top: 4px;
    left: 11px;
    color: $c-grey-25;
    user-select: none;
    pointer-events: none;
  }

  &__input {
    border-radius: 4px;
    border-color: $c-grey-15;
    padding: 4px 11px;
    color: $c-grey-85;
    background-color: $c-white;
    border: 1px solid $c-grey-15;
    border-radius: 2px;
    min-height: 54px;
    outline: none;
    resize: vertical;
    overflow: auto;
    max-height: 230px;

    * {
      font-weight: 400 !important;
      text-decoration: none !important;
      text-transform: none !important;
      font-style: normal !important;
      background-color: transparent !important;
      color: #000 !important;
      text-align: left !important;
      font-size: 14px !important;
      cursor: text !important;
    }

    p {
      margin: 0;
      &:empty {
        padding-bottom: 14px;
        content: " ";
      }
    }
  }
}
</style>
