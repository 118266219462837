<script lang="ts" setup>
import { Form } from "ant-design-vue";

import { useLanguagesStore } from "~~/store/languages";
import { IWidgetBindingParamsObject } from "~~/models/widgets/widget.core/widget.model";
import { IUseQueueHandler } from "~~/composables/useQueueHandler";
import { useWidgetsStore } from "~~/store/widgets";
import { usePagesStore } from "~~/store/pages";
import { usePermissions } from "~~/composables/permissions/usePermissions";

type IWidget = any;

const props = defineProps<{
  widget: IWidget;
  initialValue: IWidgetBindingParamsObject;
}>();

const languagesStore = useLanguagesStore();
const { currentLanguageCode } = storeToRefs(languagesStore);

const widgetsStore = useWidgetsStore();

const antForm = ref<typeof Form | null>();

const { selectedPage } = storeToRefs(usePagesStore());

const saveQueueHandler = inject<IUseQueueHandler | null>("saveQueueHandler");
const resetQueueHandler = inject<IUseQueueHandler | null>("resetQueueHandler");

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const widgetData = computed({
  get() {
    return props.widget;
  },

  set(val) {
    widgetsStore.updateWidgetOptions(props.widget, val.options);
  },
});

const pageRoute = computed(() => {
  return selectedPage.value!.routes[currentLanguageCode.value];
});

const onSubmit = async () => {
  await antForm.value?.validateFields();
  // TODO: Make own error class
};

const reset = () => {
  widgetData.value.options.bindingParams = props.initialValue;
};

saveQueueHandler?.addToQueue(onSubmit);
resetQueueHandler?.addToQueue(reset);

// TODO: Check this
reset();
</script>

<template>
  <div
    v-if="hasContentPermissions"
    class="widget-dynamic-params"
  >
    <a-form
      ref="antForm"
      name="dynamic_form_nest_item"
      :model="widgetData"
      layout="vertical"
    >
      <a-space
        style="width: 100%"
        direction="vertical"
      >
        <a-typography-title :level="5"> Dynamic parameters </a-typography-title>
        <template
          v-for="(param, name) in widgetData.options.bindingParams"
          :key="name"
        >
          <WidgetDynamicParam
            v-if="!param.isHidden"
            v-model="widgetData.options.bindingParams[name]"
            :param-name="name"
            :validation-path="['options', 'bindingParams']"
            :page-route="pageRoute"
          />
        </template>
      </a-space>
    </a-form>
  </div>
</template>

<style lang="scss">
.widget-dynamic-params {
  padding: 16px;
}
</style>
