<template>
  <div
    class="form-card-field w-100"
    :class="[`form-card-field--${layoutPosition.position}`]"
    :style="[layoutPositionStyles, containerInlineStyles, cardGapStyles]"
  >
    <FlexibleImageField
      :widget="widget"
      :field="fields.provider_image.field"
      class="form-card-field__field"
    />

    <div
      class="form-card-field__text w-100"
      :style="cardGapStyles"
    >
      <WidgetField
        v-if="fields.provider_title.active"
        :widget="widget"
        :field="fields.provider_title.field"
        class="form-card-field__field"
      >
        <BasicTitleField :field="fields.provider_title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.provider_description_1.active"
        :widget="widget"
        :field="fields.provider_description_1.field"
        class="form-card-field__field"
      >
        <BasicTitleField :field="fields.provider_description_1.field" />
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  TextPositionComplex,
  TextPosition,
  State,
  SpacingKeyName,
} from "~~/models/widgets/widget-controls.model";
import { ICell } from "~~/models/grid.interface";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import {
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getFlexPosition,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  cardState: State;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const layoutPosition = computed<TextPositionComplex>(() => {
  return props.field.options.layoutPosition;
});

const layoutPositionStyles = computed<CSSProperties>(() => {
  if (
    [TextPosition.TOP, TextPosition.BOTTOM].includes(
      layoutPosition.value.position as TextPosition
    ) &&
    layoutPosition.value.horizontalAlignment
  ) {
    return {
      alignItems: getFlexPosition(layoutPosition.value.horizontalAlignment),
    };
  }

  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return { alignItems: "unset" };
  }

  return {
    alignItems: String(layoutPosition.value.alignment),
  };
});

const activeStateOptions = computed<any>(() => {
  return props.cardState && props.cardState !== State.DEFAULT
    ? props.field.options.states[props.cardState]
    : props.field.options;
});

const { fillBackgroundStyling } = useFillBackground(ref(activeStateOptions));

const containerInlineStyles = computed<CSSProperties>(() => {
  const fill = fillBackgroundStyling.value;
  const border = getBorderStyle(
    props.cardState && props.cardState !== State.DEFAULT
      ? props.field.options.states[props.cardState]?.border
      : props.field.options?.border
  );
  const shadow = getBoxShadowStyle(
    props.cardState && props.cardState !== State.DEFAULT
      ? props.field.options.states[props.cardState]?.shadow
      : props.field.options?.shadow
  );
  const cornerRadius = getCornerRadiusStyle(props.field.options.cornerRadius);
  const padding = getSpacing(
    props.field.options.padding,
    SpacingKeyName.PADDING
  );

  return {
    ...padding,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...fill,
  };
});

const cardGapStyles = computed<CSSProperties>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.spaceBetween),
  };
});
</script>

<style lang="scss">
.form-card-field {
  @include flex-column(center, flex-start, 0);

  &--top {
    flex-direction: column-reverse;
  }

  &--right {
    flex-direction: row;
  }

  &--left {
    flex-direction: row-reverse;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }
}
</style>
