import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { TextPosition } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex, generateWidth } from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";

// Generating styles here, since button and image has custom fields
const generateWidgetCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { textPosition } = widget.options;
    const currentTextPosition = textPosition.position as TextPosition;
    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: widget.options,
      customFunction: generateDefaultStyles,
    });

    const flexDirections: Partial<Record<TextPosition, string>> = {
      [TextPosition.TOP]: "flex-direction: column;",
      [TextPosition.RIGHT]: "flex-direction: row-reverse;",
    };

    const widgetPositionCssContent =
      generateFlex({
        flex: "flex",
        align: "flex-start",
        justify: "flex-start",
      }) +
      (flexDirections[currentTextPosition] || "") +
      (currentTextPosition !== TextPosition.TOP
        ? `align-items:${textPosition.alignment};`
        : "");

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: widgetPositionCssContent,
    });

    const containerStyles = generateWidth(100, "%");

    cssString += generateCssClassWithContent({
      className: widget.options._contentCssClass,
      content: containerStyles,
    });

    return cssString;
  };

export const generateTextWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName("content-container");
  const prefilled = prefillWithClasses(widget.content);

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateStringWithStates(prefilled.title, true),
    generateStringDefault(prefilled.description_1)
  )("");
};
