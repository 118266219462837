<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-navigation>
          <ControlsList
            :widget="widget"
            :controls="widgetNavigationControls"
          />
        </template>

        <template #slide-design>
          <ControlsList
            :widget="widget"
            :controls="slideDesignControls"
          />
        </template>

        <template #slide-content>
          <ControlsList
            :widget="widget"
            :controls="slideContentControls"
          />
        </template>

        <template #slide-states>
          <ControlsList
            :widget="widget"
            :controls="slideStatesControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #description_1>
          <div>
            <ConfigPaddingInputs
              :model-value="selectedField?.options.padding"
              :meta="{
                isBold: true,
              }"
              @update:model-value="
                updateFieldOption('padding', $event, selectedField!)
              "
            />

            <ConfigToggle
              size="small"
              :model-value="selectedField?.options.withTranslations"
              :label="'With Translations'"
              class="p-l-16 p-t-16"
              :allow-disable="true"
              :show-options="false"
              is-field-item-toggle
              @update:model-value="
                updateFieldOption('withTranslations', $event, selectedField!)
              "
            />
          </div>
        </template>

        <template #button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import {
  type IWidgetField,
  type IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useTextSliderWidgetConfig } from "~~/composables/widgets/text-slider/useTextSliderWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetsStore();
const widgetsSettingsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField } = storeToRefs(widgetsSettingsStore);

const states = toRef(() => props.states);

const updateFieldOption = (
  optionName: string,
  value: any,
  field: IWidgetField
): void => {
  widgetsStore.updateFieldOptionValue(value, optionName, field.id);
};

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  widgetNavigationControls,
  slideDesignControls,
  slideContentControls,
  slideStatesControls,
  titleControls,
  titleLinkStatesControls,
  buttonDesignControls,
  buttonContentControls,
  buttonStatesControls,
} = useTextSliderWidgetConfig(ref(props.widget), states, emit);

const resetStates = (): void => {
  emit("reset-states");
};
</script>
