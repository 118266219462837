import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  BALANCE_INITIAL,
  BONUS_INITIAL,
  BUTTON_INITIAL,
  DROPDOWN_CONTAINER_INITIAL,
} from "~~/constants/configs/profile-dropdown/initial-data";
import { ArrowType } from "~~/constants/icons-mapping";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import {
  ITEM_INITIAL,
  ITEMS_GROUP_INITIAL,
  ITEMS_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { getInitialOptions } from "~~/assets/utils/common/init-data";

import { useWidgetWithArrayField } from "../../useWidgetWithArrayField";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const DEFAULT_STATES = {
    status: State.AUTHORIZED,
    login: State.DEFAULT,
    registration: State.DEFAULT,
    deposit: State.DEFAULT,
    logout: State.DEFAULT,
    dropdown_container: State.DEFAULT,
    balanceItems: State.DEFAULT,
    itemsGroup: State.DEFAULT,
    account_menu: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["contentWidth"], {
    display: {
      layout: DisplayOrientation.HORIZONTAL,
      alignment: Alignment.RIGHT,
      distance: 8,
    },
  });

  const init = (): void => {
    initFields();

    const { arrayField, itemsFields, itemsGroup, initItemsGroup } =
      useWidgetWithArrayField(
        widget.value,
        FieldName.DROPDOWN, // dummy
        FieldName.ACCOUNT_MENU
      );

    initItemsGroup();

    addFieldInitialOptions(
      "login",
      {
        data: BUTTON_INITIAL(),
        additionalData: {
          options: {
            position: 0,
          },
        },
      },
      { value: "Log in" }
    );

    addFieldInitialOptions(
      "registration",
      {
        data: BUTTON_INITIAL(),
        additionalData: {
          options: {
            position: 1,
          },
        },
      },
      { value: "Sign up" }
    );

    addFieldInitialOptions(
      "deposit",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          iconSettings: {
            default_value: "ant-design:dollar-circle-filled",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 16,
            position: Alignment.LEFT,
            gap: 5,
            _active: true,
          },
        },
        additionalData: {
          options: {
            position: 1,
          },
        },
      },
      { value: "Deposit" }
    );

    addFieldInitialOptions(
      "logout",
      {
        data: BUTTON_INITIAL(),
        additionalData: {
          options: {
            position: 3,
          },
        },
      },
      { value: "Logout" }
    );

    addFieldInitialOptions("account", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            mode: "dropdown",
          },
        },
      },
    });

    const dropdownContainerState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        decoration: getDefaultDecoration(),
        text: {
          color: getDefaultFillValue("#000000", 65),
        },
        icon: {
          textColor: getDefaultFillValue("#000000", 65),
        },
        arrow: {
          textColor: getDefaultFillValue("#000000", 65),
        },
      };
    };

    addFieldInitialOptions("dropdown_container", {
      data: DROPDOWN_CONTAINER_INITIAL(),
      exclude: ["fillImageColor"],
      values: {
        padding: getDefaultSpacing({
          top: 4,
          bottom: 4,
          left: 16,
          right: 16,
        }),
      },
      additionalData: {
        options: {
          fill: getDefaultFillValue("#FFFFFF"),
          cornerRadius: getDefaultCornerRadiusValue(4),
          display: {
            resizing: getDefaultSizeValue(ResizingType.HUG),
            alignment: Alignment.LEFT,
          },
          text: {
            _active: true,
            color: getDefaultFillValue("#000000", 65),
            theme: getDefaultTheme(),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          icon: {
            _active: true,
            value: "octicon:person-24",
            textColor: getDefaultFillValue("#000000", 65),
            iconSize: 20,
            gap: 8,
          },
          arrow: {
            _active: true,
            value: ArrowType.Down,
            textColor: getDefaultFillValue("#000000", 65),
          },
          states: {
            [State.HOVER]: dropdownContainerState(),
            [State.ACTIVE]: dropdownContainerState(),
            [State.DISABLED]: dropdownContainerState(),
          },
        },
      },
    });

    addFieldInitialOptions("dropdown_menu", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#D5D5D5"),
        }),
        shadow: getDefaultShadow({
          fill: getDefaultFillValue("#000000", 10),
          y: 10,
          blur: 20,
        }),
        cornerRadius: getDefaultCornerRadiusValue(8),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(4),
        },
      },
    });

    addFieldInitialOptions("account_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            resizing: getDefaultSizeValue(ResizingType.HUG),
            distance: 8,
          },
        },
      },
    });

    addFieldInitialOptions("user_id", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            alignment: Alignment.LEFT,
            distance: 8,
          },
        },
      },
    });

    addFieldInitialOptions("account_number", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "alignment"],
      additionalData: {
        options: {
          icon: {
            size: 16,
            color: getDefaultFillValue("#000000", 45),
            distance: 8,
            value: "ant-design:copy-outlined",
          },
        },
      },
      values: {
        theme: getDefaultTheme(),
      },
    });

    addFieldInitialOptions("username", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "alignment"],
      values: {
        theme: getDefaultTheme(16),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
      },
    });

    addFieldInitialOptions("balance", {
      data: BALANCE_INITIAL(),
      exclude: ["layout", "gap", "link", "states"],
      additionalData: {
        options: {
          displayItems: {
            layout: DisplayOrientation.HORIZONTAL,
            alignment: Alignment.LEFT,
            distance: 4,
          },
          display: {
            layout: DisplayOrientation.VERTICAL,
            alignment: Alignment.LEFT,
            distance: 12,
          },
        },
      },
    });

    addFieldInitialOptions(
      "real",
      {
        data: BONUS_INITIAL(),
        values: {
          icon: {
            _active: true,
            size: 16,
            color: {
              color: "#000000",
              opacity: 100,
            },
            default_value: "ant-design:dollar-circle-filled",
          },
        },
      },
      { value: "Money" }
    );

    addFieldInitialOptions(
      "bonus",
      {
        data: BONUS_INITIAL(),
      },
      { value: "Bonus" }
    );

    const initialItemsOptions = prefillEmptyOptions(
      arrayField.value!.options,
      getInitialOptions({
        data: ITEMS_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(),
          shadow: getDefaultShadow(),
        },
        additionalData: {
          options: {
            display: {
              alignment: Alignment.LEFT,
              distance: 0,
            },
          },
        },
      }).options
    );

    const initialItemsGroupOptions = prefillEmptyOptions(
      itemsGroup.value!.options,
      getInitialOptions({
        data: ITEMS_GROUP_INITIAL(),
        exclude: ["margins", "spaceBetween"],
        values: {
          fill: getDefaultFillValue(),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
          }),
        },
        additionalData: {
          options: {
            display: {
              elementsLayout: DisplayOrientation.HORIZONTAL,
              distance: 0,
            },
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(arrayField.value!, initialItemsOptions);
    widgetsStore.updateFieldOptions(
      itemsGroup.value!,
      initialItemsGroupOptions
    );

    itemsFields.value.forEach(itemField => {
      widgetsStore.updateFieldOptions(
        itemField,
        prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
      );
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
