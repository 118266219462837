import { type Ref } from "vue";

import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";

import type { IFields } from "../useWidgetFields";

export const useSportEventConstructorConfig = (
  fields: Ref<IFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  /* 
    Betting constructor
  */

  const bettingConstructorContentControls = [
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            value: "betting_constructor_header",
            label: "Constructor header",
          },
          {
            value: "betting_constructor_content",
            label: "Content",
          },
        ],
      },
    },
  ];

  /* 
      Constructor header
    */

  const constructorHeaderTextContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.betting_constructor_header.field,
      }),
      {
        section: generateDesignSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrowSettings",
            valueSource: fields.value.betting_constructor_header.field,
            className: "p-t-0",
            options: {
              label: "Fill",
              isBold: true,
              allowDisable: false,
              showOptions: false,
              meta: {
                hideToggle: true,
              },
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.arrowSettings.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: fields.value.betting_constructor_header.field,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const constructorHeaderStatesValuePath = computed<string>(() => {
    if (states.value.betting_constructor_header === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.betting_constructor_header}`;
  });

  const constructorHeaderStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.betting_constructor_header,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "betting_constructor_header", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${constructorHeaderStatesValuePath.value}.fill`,
        valueSource: fields.value.betting_constructor_header.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${constructorHeaderStatesValuePath.value}.border`,
        valueSource: fields.value.betting_constructor_header.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${constructorHeaderStatesValuePath.value}.shadow`,
        valueSource: fields.value.betting_constructor_header.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${constructorHeaderStatesValuePath.value}.color`,
            valueSource: fields.value.betting_constructor_header.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${constructorHeaderStatesValuePath.value}.decoration`,
            valueSource: fields.value.betting_constructor_header.field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${constructorHeaderStatesValuePath.value}.arrowSettings.textColor`,
            valueSource: fields.value.betting_constructor_header.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
      Constructor content
    */

  const constructorHeaderContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Description",
              value: "betting_constructor_description",
            },
            {
              label: "Iframe",
              value: "betting_constructor_iframe",
            },
          ],
        },
      },
    ];
  });

  /* 
      Constructor description
    */

  const constructorDescriptionControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.betting_constructor_description.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    bettingConstructorContentControls,

    constructorHeaderTextContentControls,
    constructorHeaderStatesControls,
    constructorHeaderContentControls,

    constructorDescriptionControls,
  };
};
