import { Ref } from "vue";

import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

export const useAllowDisableDependentFieldContentItems = (
  field: Ref<IWidgetField | null>,
  dependentFieldContentItems: FieldContentItem[]
) => {
  const allowDisableFieldsItem = (fieldContentItem: FieldContentItem) =>
    computed<boolean>(() => {
      return (
        dependentFieldContentItems.filter(
          optionKey =>
            fieldContentItem !== optionKey &&
            field.value?.options[optionKey]?._active
        ).length > 0
      );
    });

  return {
    allowDisableFieldsItem,
  };
};

export const useAllowDisableDependentTabs = (tabs: IWidgetField[]) => {
  const allowDisableTabItem = (targetField: IWidgetField | null) =>
    computed<boolean>(() => {
      return (
        tabs.filter(
          field => targetField?.id !== field.id && field?.options._active
        ).length > 0
      );
    });

  return {
    allowDisableTabItem,
  };
};
