import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  Alignment,
  DisplayOrientation,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFillColorStyle,
  generateFlex,
  generateFontSize,
  generatePaddingStyle,
  generateSize,
  generateTextColor,
} from "../helpers";
import { getFlexAlign, getFlexPosition } from "../../widget-settings";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import { getPxValueFromNumber } from "../..";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateSize(options.size) + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateFavouriteIconCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(element.options.color),
      generateFontSize(element.options.iconSize),
      `min-width: ${getPxValueFromNumber(element.options.iconSize)};`,
    ];

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content:
        content.join("") +
        "z-index:1;" +
        `min-width: ${getPxValueFromNumber(
          element.options.iconSize
        )};cursor:pointer;`,
    });

    return cssString;
  };

export const generateGameCardsStyles =
  (element: IWidgetOptions, cardClass: string) =>
  (cssString: string): string => {
    const { options } = element;
    const states = options.states;

    const flexAlign = getFlexAlign(options.display.alignment);

    const flexStyles = generateFlex({
      flex: "flex",
      align: flexAlign["align-items"],
      justify: flexAlign["justify-content"],
      direction:
        options.display.layout === DisplayOrientation.VERTICAL
          ? "column"
          : "row",
      wrap:
        options.display.layout === DisplayOrientation.WRAP ? "wrap" : "unset",
      gap: options.display.gap.col,
      rowGap: options.display.gap.row,
      alignSelf: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: flexStyles + "overflow: auto;",
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      childClassName: cardClass,
      content:
        generateSize(options.size) +
        generateDefaultStyles(options) +
        "flex-shrink:0;",
    });

    for (const state in states) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: `${cardClass}:${state}`,
        content: states[state],
        customFunction: generateDefaultStyles,
      });
    }

    return cssString;
  };

export const generateCardsElementsStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    const flexStyles = generateFlex({
      flex: "flex",
      align: getFlexPosition(options.display.alignment),
      justify: "space-between",
      direction:
        options.display.position === Alignment.RIGHT ? "row" : "row-reverse",
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: flexStyles + generatePaddingStyle(options.padding),
    });

    return cssString;
  };

export const generateHoverContentStyles =
  (element: IWidgetOptions, gameCards: IWidgetOptions, btnGroupClass: string) =>
  (cssString: string): string => {
    const { options } = element;
    const { states } = gameCards.options;

    const flexStyles = generateFlex({
      flex: "flex",
      align: "initial",
      justify: getFlexPosition(options.alignment),
      direction:
        options.position === TextPosition.TOP ? "column" : "column-reverse",
      gap: options.gap,
    });

    const content = [
      generateDefaultStyles(options),
      generateDefaultStyles(states.hover) +
        generateFillColorStyle(states.hover.overlay),
    ];

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flexStyles + content.join(""),
    });

    const flexBtnGroupStyles = generateFlex({
      flex: "flex",
      align: "initial",
      justify: "center",
      direction:
        options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
      gap: options.gap,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      childClassName: btnGroupClass,
      content: flexBtnGroupStyles + "margin:0 auto;",
    });

    return cssString;
  };

export const generateTagStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;
    const states = options.states;

    if (states) {
      for (const stateName in states) {
        const className =
          stateName === State.DEFAULT
            ? `${options._cssClass}.${State.PROMO}`
            : `${options._cssClass}.${stateName}`;

        cssString += generateCssClassWithContent({
          className: className,
          content:
            generateDefaultStyles(options) +
            generateDefaultStyles(options.buttonTextSettings) +
            generateDefaultStyles(states[stateName]) +
            generateDefaultStyles(states[stateName].buttonTextSettings),
        });
      }
    } else {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options,
        customFunction: generateDefaultStyles,
      });
    }

    return cssString;
  };

export const generateCasinoGamesListWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const no_games_title = widget.content["no_games_title"];
  const game_cards = widget.content["game_cards"];
  const photo = widget.content["photo"];
  const cards_elements = widget.content["cards_elements"];
  const hover_content = widget.content["hover_content"];
  const game_title = widget.content["game_title"];
  const play_money_button = widget.content["play_money_button"];
  const play_free_button = widget.content["play_free_button"];
  const promo_tag = widget.content["promo_tag"];
  const favorite_icon = widget.content["favorite_icon"];

  widget.options._cssClass = generateClassName("CasinoGamesList");

  no_games_title.options._cssClass = generateClassName("no_games_title");
  game_cards.options._cssClass = generateClassName("game_cards");

  photo.options._cssClass = generateClassName("photo");
  cards_elements.options._cssClass = generateClassName("cards_elements");
  hover_content.options._cssClass = generateClassName("hover_content");

  game_title.options._cssClass = generateClassName("game_title");
  play_money_button.options._cssClass = generateClassName("play_money_button");
  play_free_button.options._cssClass = generateClassName("play_free_button");

  favorite_icon.options._cssClass = generateClassName("favorite_icon");
  promo_tag.options._cssClass = generateClassName("promo_tag");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),

    generateStringDefault(no_games_title),
    generateGameCardsStyles(game_cards, "casino-game-card"),

    generateStringDefault(photo),
    generateCardsElementsStyles(cards_elements),
    generateHoverContentStyles(hover_content, game_cards, "btn-group"),

    generateStringWithStates(game_title, true),
    generateStageButtonCssString(play_money_button as IWidgetField),
    generateStageButtonCssString(play_free_button as IWidgetField),

    generateFavouriteIconCssString(favorite_icon),
    generateTagStyles(promo_tag)
  )("");
};
