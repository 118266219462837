import {
  HEADER_CONTAINER_INITIAL,
  HEADER_COUNTERS_INITIAL,
  BETS_CONTAINER_INITIAL,
  BETS_TYPE_INITIAL,
  BET_AMOUNTS_INITIAL,
  MY_BETS_WARNING_MESSAGE_INITIAL,
  RESULT_BUTTON_WITHOUT_ICON_INITIAL,
} from "~~/constants/configs/betslip-common/initial-constants";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultButtonTextSettings,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultShadow,
  getDefaultSize,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  Alignment,
  DisplayOrientation,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ArrowType } from "~~/constants/icons-mapping";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";
import { TOOLTIPS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/tooltips-styling";
import { FIELDS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/fields-styling";

import {
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
} from "../text/useTextWidgetConfig";
import { STATUS_MESSAGE_INITIAL } from "../provider-form-fenix/useProviderFormFenixInitFieldsOptions";

export const useInitWidgetFieldsOptions = (
  widget: IWidgetWithFields,
  formFilterFields?: Array<string>
) => {
  const widgetsStore = useWidgetsStore();

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget, formFilterFields);

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("header_tabs_container", {
      data: HEADER_CONTAINER_INITIAL(),
      exclude: ["theme", "fontFamily", "color"],
      additionalData: {
        options: {
          size: getDefaultSize(),
        },
      },
      values: {
        cornerRadius: getDefaultCornerRadiusValue(),
      },
    });

    addFieldInitialOptions("header_tabs_container_counters", {
      data: HEADER_COUNTERS_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        color: getDefaultFillValue("#000000"),
      },
    });

    addFieldInitialOptions("betslip_tabs_container", {
      data: HEADER_CONTAINER_INITIAL(),
      exclude: ["gapBetweenTextAndCounter"],
      values: {
        fill: getDefaultFillValue("#000000", 85),
        padding: getDefaultSpacing({
          left: 12,
          right: 12,
          top: 16,
          bottom: 16,
        }),
        headerElementsSettings: {
          ...HEADER_CONTAINER_INITIAL().options.headerElementsSettings,
          fill: getDefaultFillValue("#171717"),
          theme: getDefaultTheme(12),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 20,
            right: 20,
          }),
          cornerRadius: getDefaultCornerRadiusValue({ value: 50 }),
        },
      },
    });

    addFieldInitialOptions("bets_container", {
      data: BETS_CONTAINER_INITIAL(),
      exclude: ["margins"],
      values: {
        fill: getDefaultFillValue("#ffffff"),
      },
      additionalData: {
        options: {
          distance: 12,
        },
      },
    });

    addFieldInitialOptions("cards_container", {
      data: BETS_CONTAINER_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 8,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#F2F4F8"),
        padding: getDefaultSpacing(12),
        margins: getDefaultSpacing({
          left: 12,
          right: 12,
        }),
      },
    });

    addFieldInitialOptions("event_container", {
      data: BETS_CONTAINER_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 4,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        padding: getDefaultSpacing(12),
        margins: getDefaultSpacing({ top: 12 }),
      },
    });

    addFieldInitialOptions("time_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
    });

    addFieldInitialOptions("bets_type_title", {
      data: BETS_TYPE_INITIAL(),
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        theme: getDefaultTheme(12),
        color: getDefaultFillValue("#1677FF"),
        decoration: [TextStyle.BOLD],
        padding: getDefaultSpacing({
          top: 1,
          bottom: 1,
          left: 4,
          right: 4,
        }),
      },
    });

    addFieldInitialOptions("live_status_title", {
      data: BETS_TYPE_INITIAL(),
      exclude: ["states"],
      values: {
        fill: getDefaultFillValue("#389E0D"),
        color: getDefaultFillValue("#ffffff"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        theme: getDefaultTheme(12),
        padding: getDefaultSpacing({
          top: 4,
          bottom: 4,
          left: 8,
          right: 8,
        }),
      },
    });

    addFieldInitialOptions("not_available_status_title", {
      data: BETS_TYPE_INITIAL(),
      exclude: ["states"],
      values: {
        fill: getDefaultFillValue("#FFF1F0"),
        border: getDefaultBorderValue({ fill: getDefaultFillValue("#FFA39E") }),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        padding: getDefaultSpacing(4),
        color: getDefaultFillValue("#F62F39"),
        theme: getDefaultTheme(12),
      },
    });

    addFieldInitialOptions("event_name_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
      values: {
        decoration: [TextStyle.BOLD],
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions(
      "form",
      {
        data: BETS_CONTAINER_INITIAL(),
        exclude: ["spaceBetween", "margins"],
        additionalData: {
          options: {
            display: {
              distance: 4,
              layout: DisplayOrientation.VERTICAL,
            },
          },
        },
        values: {
          fill: getDefaultFillValue("E8EBF2"),
          padding: getDefaultSpacing(12),
          cornerRadius: getDefaultCornerRadiusValue({ value: 0 }),
          margin: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 0,
            bottom: 0,
          }),
        },
      },
      {
        type: WIDGET_FIELD_TYPES.FORM_ELEMENT,
      }
    );

    addFieldInitialOptions("close_icon", {
      data: {
        options: {
          _active: true,
          color: {
            color: "#989898",
            opacity: 100,
          },
          iconSize: 16,
        },
      },
    });

    addFieldInitialOptions("market_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
      values: {
        color: getDefaultFillValue("#000000", 65),
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("bet_name_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment", "margins"],
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("bet_amounts_container", {
      data: BET_AMOUNTS_INITIAL(),
      exclude: ["margins"],
      values: {
        labelSettings: {
          theme: getDefaultTheme(12),
          decoration: [],
          color: getDefaultFillValue("#000000", 45),
        },
        valueSettings: {
          theme: getDefaultTheme(12),
          decoration: [],
          color: getDefaultFillValue("#000000"),
        },
      },
    });

    addFieldInitialOptions("coefficient_title", {
      data: BETS_TYPE_INITIAL(),
      additionalData: {
        options: {
          arrow: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      },
      values: {
        states: {
          [State.UP]: {
            fill: {
              color: "",
              opacity: 100,
            },
            border: getDefaultBorderValue(),
            color: {
              color: "#123123",
              opacity: 100,
            },
            arrow: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
          [State.DOWN]: {
            fill: {
              color: "",
              opacity: 100,
            },
            border: getDefaultBorderValue(),
            color: {
              color: "#000000",
              opacity: 100,
            },
            arrow: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
        },
        fill: getDefaultFillValue("#E6F4FF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        color: getDefaultFillValue("#1677FF"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        padding: getDefaultSpacing({
          top: 1,
          bottom: 1,
          left: 8,
          right: 8,
        }),
      },
    });

    addFieldInitialOptions("result_container", {
      data: BETS_CONTAINER_INITIAL(),
      exclude: ["spaceBetween"],
      values: {
        fill: getDefaultFillValue("#F2F4F8"),
        padding: getDefaultSpacing(12),
      },
    });

    addFieldInitialOptions("my_bets_warning_message", {
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
      values: {
        fill: getDefaultFillValue("#FFF7E6"),
        color: getDefaultFillValue("#FA8C16"),
        padding: getDefaultSpacing(12),
        iconSettings: {
          _active: true,
          iconSize: 20,
          gap: 8,
          textColor: getDefaultFillValue("#FA8C16"),
        },
      },
    });

    addFieldInitialOptions("result_container_total_amounts", {
      data: BET_AMOUNTS_INITIAL(),
      exclude: ["margins"],
      values: {
        labelSettings: {
          theme: getDefaultTheme(14),
          decoration: getDefaultDecoration([]),
          color: getDefaultFillValue("#8C8C8C"),
        },
        valueSettings: {
          theme: getDefaultTheme(20),
          decoration: [TextStyle.BOLD],
          color: getDefaultFillValue("#000000"),
        },
      },
    });

    addFieldInitialOptions("result_container_warning_message_title", {
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
      values: {
        fill: getDefaultFillValue("#FFF7E6"),
        color: getDefaultFillValue("#FA8C16"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
        iconSettings: {
          _active: true,
          iconSize: 20,
          gap: 8,
          textColor: getDefaultFillValue("#FA8C16"),
        },
        padding: getDefaultSpacing(12),
        margins: getDefaultSpacing({ bottom: 16 }),
      },
    });

    addFieldInitialOptions("result_container_bet_button", {
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL({
        withStateCoefficientChange: true,
      }),
    });

    addFieldInitialOptions("result_container_remove_button", {
      data: BUTTON_INITIAL(),
      values: {
        fill: getDefaultFillValue(""),
        padding: getDefaultSpacing({ top: 8, bottom: 8 }),
        buttonTextSettings: getDefaultButtonTextSettings({
          textColor: getDefaultFillValue("#F76768"),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          theme: getDefaultTheme(14),
        }),
        iconSettings: getDefaultIconSettings({ _active: false }),
      },
    });

    addFieldInitialOptions("result_container_copy_button", {
      data: BUTTON_INITIAL(),
      values: {
        fill: getDefaultFillValue(""),
        padding: getDefaultSpacing({ top: 8, bottom: 8 }),
        buttonTextSettings: getDefaultButtonTextSettings({
          textColor: getDefaultFillValue("#000000", 65),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          theme: getDefaultTheme(14),
        }),
        iconSettings: getDefaultIconSettings({ _active: false }),
      },
    });

    addFieldInitialOptions("success_message_title", {
      data: MY_BETS_WARNING_MESSAGE_INITIAL(),
      additionalData: {
        options: {
          printSettings: {
            fontFamily: getDefaultFontFamily(),
            theme: 24,
            color: getDefaultFillValue("#ffffff"),
          },
          layout: DisplayOrientation.VERTICAL,
          alignment: Alignment.CENTER,
        },
      },
    });

    addFieldInitialOptions("no_bets_description", {
      data: DESCRIPTION_INITIAL(),
      values: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    });

    addFieldInitialOptions("no_bets_description_mybets", {
      data: DESCRIPTION_INITIAL(),
      values: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    });

    addFieldInitialOptions("bets_result_save_choice_button", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("bets_result_continue_button", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("my_bets_primary_button", {
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL(),
      values: {
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        buttonTextSettings: getDefaultButtonTextSettings({
          theme: getDefaultTheme(14),
        }),
      },
    });

    addFieldInitialOptions("my_bets_secondary_button", {
      data: RESULT_BUTTON_WITHOUT_ICON_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 90),
        }),
        buttonTextSettings: getDefaultButtonTextSettings({
          textColor: getDefaultFillValue("#000000", 90),
          theme: getDefaultTheme(14),
        }),
      },
    });

    const collapseState = (): IWidgetOptions => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
        decoration: getDefaultDecoration(),
        arrowSettings: {
          textColor: getDefaultFillValue("#000000"),
        },
      };
    };

    addFieldInitialOptions("collapse", {
      data: DESIGN_INITIAL(),
      exclude: ["padding", "link"],
      additionalData: {
        options: {
          ...TITLE_INITIAL().options,
          display: {
            distance: 16,
          },
          arrowSettings: {
            textColor: getDefaultFillValue("#000000"),
            iconSize: 14,
            rotate: true,
            value: ArrowType.CaretDown,
          },
          states: {
            [State.HOVER]: collapseState(),
            [State.ACTIVE]: collapseState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#F2F4F8"),
        theme: getDefaultTheme(14),
        decoration: [TextStyle.BOLD],
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing({
            top: 8,
            bottom: 8,
            left: 12,
            right: 12,
          }),
        },
      },
    });

    addFieldInitialOptions("fields_title", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("bets_result_system_name", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
        padding: getDefaultSpacing(0),
      },
    });

    addFieldInitialOptions("multibet_tooltip", {
      data: {
        title: "Multibet tooltip",
        options: {
          _active: true,
          fill: {
            color: "#000000",
            opacity: 100,
          },
          border: getDefaultBorderValue(),
          shadow: {
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
            fill: {
              color: "",
              opacity: 100,
            },
          },
          cornerRadius: getDefaultCornerRadiusValue(8),
          padding: {
            top: 8,
            right: 8,
            bottom: 8,
            left: 8,
          },
          color: {
            color: "#ffffff",
            opacity: 100,
          },
          fontFamily: getDefaultFontFamily(),
          theme: 13,
          decoration: [],
          alignment: "center",
          textChooseIcon: {
            icon: "ant-design:question-circle-outlined",
            color: {
              color: "#898989",
              opacity: 100,
            },
          },
        },
      },
    });

    addFieldInitialOptions("stake_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 12,
          },
        },
      },
    });

    addFieldInitialOptions("each_way_bet", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 12,
          },
        },
      },
    });

    const defaultTextOptions = (): IWidgetOptions => {
      const initialOptions: IWidgetOptions = TITLE_INITIAL().options;

      delete initialOptions.link;

      return {
        ...initialOptions,
        theme: getDefaultTheme(13),
      };
    };

    addFieldInitialOptions("total_stake", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 12,
          },
          fontFamily: getDefaultFontFamily(),
          label: defaultTextOptions(),
          value: defaultTextOptions(),
        },
      },
    });

    addFieldInitialOptions("each_way_bet_checkbox", {
      data: CHECKBOX_STYLE_INITIAL(),
      values: {
        color: getDefaultFillValue("#000000"),
      },
    });

    const tooltipInitial = TOOLTIPS_STYLE_INITIAL().options;

    addFieldInitialOptions("each_way_bet_tooltip", {
      data: {
        options: {
          ...tooltipInitial,
          textChooseIcon: {
            ...tooltipInitial.textChooseIcon,
            gap: undefined,
          },
        },
      },
    });

    addFieldInitialOptions("loader", {
      data: {
        options: {
          fill: getDefaultFillValue("#000000", 60),
          progressColor: getDefaultFillValue("#1677FF"),
          backgroundColor: getDefaultFillValue("#F0F0F0"),
          stroke: 52,
          size: 5,
        },
      },
    });

    addFieldInitialOptions("cashout", {
      data: {
        options: {
          _active: true,
          theme: getDefaultTheme(12),
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: Alignment.LEFT,
          color: getDefaultFillValue("#ffffff"),
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
    });

    addFieldInitialOptions("loader", {
      data: {
        options: {
          fill: getDefaultFillValue("#000000", 60),
          progressColor: getDefaultFillValue("#1677FF"),
          backgroundColor: getDefaultFillValue("#F0F0F0"),
          stroke: 52,
          size: 5,
        },
      },
    });

    const headerLabelState = (): IWidgetOptions => {
      return {
        color: getDefaultFillValue("#FFFFFF"),
      };
    };

    addFieldInitialOptions("header_label", {
      data: {
        options: {
          theme: getDefaultTheme(),
          fontFamily: getDefaultFontFamily(),
          alignment: Alignment.CENTER,
          decoration: getDefaultDecoration(),
          color: getDefaultFillValue("#FFFFFF"),
          states: {
            [State.HOVER]: headerLabelState(),
            [State.ACTIVE]: headerLabelState(),
            [State.DISABLED]: headerLabelState(),
          },
        },
      },
    });

    addFieldInitialOptions("booking_label", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding"],
      values: {
        alignment: Alignment.CENTER,
        theme: getDefaultTheme(16),
      },
    });

    addFieldInitialOptions("booking_button", {
      data: BUTTON_INITIAL(),
      exclude: ["link", "iconSettings"],
    });

    addFieldInitialOptions("booking_input", {
      data: FIELDS_STYLE_INITIAL(),
    });

    addFieldInitialOptions(
      "booking_error",
      {
        data: STATUS_MESSAGE_INITIAL(
          "",
          "#FF4D4F",
          "ant-design:close-circle-filled"
        ),
        exclude: ["alignment"],
        values: {
          padding: getDefaultSpacing(0),
          cornerRadius: getDefaultCornerRadiusValue(),
        },
        additionalData: {
          options: {
            margins: getDefaultSpacing(0),
          },
        },
      },
      { value: "Error" }
    );

    addFieldInitialOptions("cashout_tooltip", {
      data: TOOLTIPS_STYLE_INITIAL(),
      values: {
        textChooseIcon: {
          icon: "ant-design:question-circle-outlined",
          color: getDefaultFillValue("#FFFFFF"),
          gap: 5,
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    init,
  };
};
