<template>
  <div class="sportsbook-events">
    <div
      v-if="eventCardState === State.ACTIVE_EVENTS"
      :style="containerStyles"
      class="sportsbook-events__cards"
    >
      <div
        v-for="(item, index) in currItems"
        :key="index"
        :style="eventCardStyles"
      >
        <div>
          <div>
            <div class="sportsbook-events__header">
              <WidgetField
                :widget="widget"
                :field="startTimeField"
              >
                <BasicTitleField :field="startTimeField">
                  <div>
                    <span
                      :style="timeElementContainerStyles"
                      class="sportsbook-events__time"
                    >
                      <span class="sportsbook-events__time-element">
                        <CommonIcon
                          :style="iconStyles"
                          name="ant-design:clock-circle-filled"
                          class="sportsbook-events__icon"
                        />

                        <span> Start time </span>
                      </span>
                      <span class="sportsbook-events__time-element">
                        <CommonIcon
                          :style="iconStyles"
                          name="ant-design:calendar-filled"
                          class="sportsbook-events__icon"
                        />

                        <span> Date </span>
                      </span>
                    </span>
                  </div>
                </BasicTitleField>
              </WidgetField>

              <WidgetField
                :widget="widget"
                :field="betBuilderButtonField"
              >
                <BasicTextButtonField
                  :field="betBuilderButtonField"
                  :current-state="betBuilderState"
                />
              </WidgetField>
            </div>

            <WidgetField
              :widget="widget"
              :field="leagueField"
            >
              <BasicTitleField :field="leagueField">
                <span> League </span>
              </BasicTitleField>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="teamPlayerField"
            >
              <BasicTitleField :field="teamPlayerField">
                <span> Team / Player #1 - Team / Player #2 </span>
              </BasicTitleField>
            </WidgetField>
          </div>
        </div>

        <div>
          <div
            :style="itemsContanerStyles"
            class="sportsbook-events__items-container"
          >
            <WidgetField
              :widget="widget"
              :field="betItemField"
              class="sportsbook-events__items-list"
            >
              <div
                :style="betItemsContainerStyles"
                class="sportsbook-events__items-list"
                :class="{
                  'sportsbook-events__items-list--grid':
                    props.field.options.items.displayMode ===
                    DisplayOrientation.HORIZONTAL,
                }"
              >
                <div
                  v-for="(inner, index2) in item.bet_items"
                  :key="index2"
                  class="sportsbook-events__item"
                  :style="betItemContainerStyles"
                >
                  <SportsbookBetItem
                    :item-value="inner"
                    :state-options="currentStateOptions"
                    :bet-item-field="betItemField"
                  />
                </div>
              </div>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="eventButtonField"
            >
              <span
                :style="eventButtonStyles"
                class="sportsbook-events__button"
              >
                {{ item.event_button }}
              </span>
            </WidgetField>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <BasicTitleField :field="noEventsField" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
} from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { getFlexPosition } from "~~/assets/utils/widget-settings";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

type Item = {
  start_time: string;
  date: string;
  league: string;
  team_player: string;
  event_button: string;
  bet_items: {
    name: string;
    coef: string;
  }[];
};

const defaultItems: Item[] = [
  {
    start_time: "Start time",
    date: "Date",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "Start time",
    date: "Date",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "Start time",
    date: "Date",
    league: "League",
    team_player: "Team / player #1 - Team / player #2",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  startTimeField: IWidgetField;
  leagueField: IWidgetField;
  teamPlayerField: IWidgetField;
  betItemField: IWidgetField;
  eventButtonField: IWidgetField;
  betBuilderButtonField: IWidgetField;
  noEventsField: IWidgetField;
  betItemState: State;
  listItemMoreState: State;
  eventCardState: State;
  betBuilderState: State;
  items: Item[];
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    "grid-template-columns": `repeat(${props.field.options.columns}, minmax(0, 1fr))`,
    "column-gap": getPxValueFromNumber(
      props.field.options.distanceBetweenCards
    ),
    "row-gap": getPxValueFromNumber(props.field.options.distanceBetweenCards),
  };
});

const eventCardStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options.card),
  };
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const flexDirection =
    props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  return {
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
    flexDirection,
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.startTimeField.options.icon.color),
    ...getFontSizeStyle(props.startTimeField.options.icon.size),
  };
});

const timeElementContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.startTimeField.options.spaceBetween),
    justifyContent: getFlexPosition(props.startTimeField.options.alignment),
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemState === State.DEFAULT) {
    return props.betItemField.options;
  }

  return props.betItemField.options.states[props.betItemState];
});

const itemsContanerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
  };
});

const currentEventButtonStateOptions = computed<IWidgetOptions>(() => {
  if (props.listItemMoreState === State.DEFAULT) {
    return props.eventButtonField.options;
  }

  return props.eventButtonField.options.states[props.listItemMoreState];
});

const eventButtonStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.eventButtonField.options,
      ...currentEventButtonStateOptions.value,
    }),
    ...getColorStyle(currentEventButtonStateOptions.value.color),
    ...getFontSizeStyle(props.eventButtonField.options.theme),
    ...getDecorationValue(props.eventButtonField.options.decoration),
  };
});

const currItems = computed<Item[]>(() => {
  if (props.items) {
    return props.items;
  }

  return defaultItems;
});
</script>

<style lang="scss" scoped>
.sportsbook-events {
  &__cards {
    display: grid;
    overflow: hidden;
  }

  &__time {
    @include flex(flex-start, flex-start, 0);
  }

  &__time-element {
    @include flex(center, flex-start, 4px);
  }

  &__header {
    @include flex(center, space-between, 0);
  }

  &__items-container {
    @include flex(center, flex-start, 0);
    overflow: hidden;
  }

  &__items-list {
    @include flex(flex-start, flex-start, 0);
    flex-grow: 1;
    width: 100%;

    &--grid {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      .sportsbook-events__item {
        width: 100%;
      }
    }
  }

  &__items-list {
    flex: 1;
  }

  &__item {
    flex: 1;
    width: 0;
  }

  &__button {
    display: inline-block;
    padding: 4px 8px;
  }
}
</style>
