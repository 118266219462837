import { IContentField } from "~~/models/page.model";

import { generateClassName } from "./generate-class-name";

export const prefillWithClasses = (
  content: Record<string, IContentField>
): Record<string, IContentField> => {
  for (const key in content) {
    const item = content[key];
    item.options._cssClass = generateClassName(key.replaceAll("_", "-"));
  }

  return content;
};
