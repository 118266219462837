<template>
  <div
    class="basic-card-field"
    :class="[`basic-card-field__${layoutPosition.position}`]"
    :style="containerStyles"
  >
    <slot
      v-if="fields.image.active"
      name="image"
    >
      <FlexibleImageField
        :widget="widget"
        :field="fields.image.field"
      />
    </slot>
    <BasicGridWrapper
      :options="element.options"
      :style="gridContentAlignStyles"
    >
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :state="states.title"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.description_1.active"
        :widget="widget"
        :field="fields.description_1.field"
      >
        <BasicDescriptionField
          :fields="[fields.description_1.field]"
          :widget="widget"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.buttons_group.active"
        :widget="widget"
        :field="fields.buttons_group.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.buttons_group.field"
          class="basic-card-field__field basic-card-field__buttons-group"
          :style="buttonsGroupStyle"
        >
          <WidgetField
            v-if="fields.button_1.active"
            :widget="widget"
            class="basic-card-field__field"
            :field="fields.button_1.field"
            :style="button1Style"
          >
            <BasicButtonField
              :field="fields.button_1.field"
              :current-state="states.button_1"
            />
          </WidgetField>

          <WidgetField
            v-if="fields.button_2.active"
            :widget="widget"
            class="basic-card-field__field"
            :field="fields.button_2.field"
            :style="button2Style"
          >
            <BasicButtonField
              :field="fields.button_2.field"
              :current-state="states.button_2"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>
    </BasicGridWrapper>
  </div>
</template>

<script lang="ts" setup>
import type {
  ICustomField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  TextPositionComplex,
  TextPosition,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { ElementStyle } from "~~/models/common";
import { getGridAlign } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  field?: ICustomField;
}>();

const element = computed<IWidgetWithFields | ICustomField>(
  () => props.field || props.widget
);

const fields = useWidgetFields(element.value);

const layoutPosition = computed<TextPositionComplex>(() => {
  return element.value.options.display?.layout;
});

const gridContentAlignStyles = computed<ElementStyle>(() => {
  return getGridAlign(element.value.options.display?.alignment);
});

const containerStyles = computed<ElementStyle | undefined>(() => {
  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return undefined;
  }

  return {
    alignItems: layoutPosition.value.alignment || undefined,
  };
});

const buttonsGroupStyle = computed<ElementStyle>(() => {
  const style = useWidgetStyle(fields.value.buttons_group.field);

  const { layout, distanceBetweenItems, alignment } =
    fields.value.buttons_group.field.options;

  if (layout === DisplayOrientation.VERTICAL) {
    style.value.flexDirection = "column";
  }

  style.value.gap = getPxValueFromNumber(distanceBetweenItems);
  style.value.justifyContent = alignment;
  style.value.alignItems =
    layout === DisplayOrientation.HORIZONTAL
      ? "start"
      : generateFlexAlignment(alignment);
  return style.value;
});

const getButtonStyle = (name: "button_1" | "button_2") => {
  const buttonDisplaySettings =
    fields.value[name].field.options.buttonDisplaySettings;

  if (buttonDisplaySettings.resizing === ResizingType.HUG) return {};

  if (
    buttonDisplaySettings.resizing === ResizingType.FILL &&
    fields.value.buttons_group.field.options.layout ===
      DisplayOrientation.VERTICAL
  )
    return { width: "100%" };

  return { flexGrow: 1 };
};

const button1Style = computed<ElementStyle>(() => getButtonStyle("button_1"));
const button2Style = computed<ElementStyle>(() => getButtonStyle("button_2"));
</script>

<style lang="scss" scoped>
.basic-card-field {
  height: 100%;
  @include flex-column(stretch, flex-start, 0);

  &__top {
    flex-direction: column-reverse;
  }

  &__right {
    flex-direction: row;
  }

  &__left {
    flex-direction: row-reverse;
  }

  &__buttons-group {
    display: flex;
    width: 100%;
  }

  &__description {
    flex-grow: 1;
  }
}
</style>
