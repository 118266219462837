import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { FRONTEND_APP_CONTAINER_ID } from "~~/constants";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex } from "../helpers";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import { getFlexPosition } from "../../widget-settings";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const flexStyles = generateFlex({
      flex: "flex",
      align: getFlexPosition(options.baseDisplaySettings.alignment),
      justify: "flex-start",
      wrap: "nowrap",
      gap: options.baseDisplaySettings.gap,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flexStyles + generateDefaultStyles(options),
      parentId: FRONTEND_APP_CONTAINER_ID,
    });

    return cssString;
  };

const generateTitleStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "flex-grow:1;" + generateDefaultStyles(options),
    });

    return cssString;
  };

const generateWidgetAdditionalCssStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._widgetWrapperCssClass,
      content: "margin-left:auto;margin-right:auto;width:100%;",
    });

    return cssString;
  };

export const generateModalWindowHeaderWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const close_button = widget.content["close_button"];

  widget.options._cssClass = generateClassName("ModalWindowHeader");
  title.options._cssClass = generateClassName("title");
  close_button.options._cssClass = generateClassName("close_button");
  close_button.options.iconSettings._cssClass = generateClassName("close_icon");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateWidgetAdditionalCssStyles(widget.options),
    generateTitleStyles(title.options),
    generateStageButtonCssString(close_button as IWidgetField)
  )("");
};
