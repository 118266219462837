// import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
// import { DEFAULT_FONT, VENDOR_PREFIX } from "~~/constants/widget-config";

import type { IWidgetMigration } from "../runWidgetMigrations";

// const WIDGET_VERSION = 63;
//

const v63WidgetMigration: IWidgetMigration = widget => {
  // if (widget.options?.__VERSION >= WIDGET_VERSION) {
  //   return widget;
  // }

  // function findFont(obj: Record<string, any>) {
  //   for (const key in obj) {
  //     if (key === "fontFamily") {
  //       obj[key] = `var(--${VENDOR_PREFIX}font-family-1, ${DEFAULT_FONT})`;
  //     }

  //     if (typeof obj[key] === "object" && obj[key] !== null) {
  //       findFont(obj[key]);
  //     }
  //   }
  // }

  // Object.values(widget.content).forEach((field: any) => {
  //   if (
  //     [
  //       WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
  //       WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
  //     ].includes(field.type)
  //   ) {
  //     for (let index = 0; index < field.value.length; index++) {
  //       const itemsFieldValue = field.value[index]?.fields;
  //       if (Array.isArray(itemsFieldValue)) {
  //         itemsFieldValue.forEach((item: any) => {
  //           findFont(item.options);
  //         });
  //       }
  //     }
  //   } else {
  //     findFont(field.options);
  //   }
  // });

  // if (widget.options._customFields) {
  //   Object.values(widget.options._customFields).forEach((field: any) => {
  //     findFont(field.options);
  //   });
  // }

  // widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};

export default v63WidgetMigration;
