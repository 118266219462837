import {
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";

export const UPLOAD_LIST_INITIAL = () => ({
  title: "Upload list style",
  options: {
    _active: true,
    fill: getDefaultFillValue(),
    border: getDefaultBorderValue(),
    shadow: getDefaultShadow(),
    cornerRadius: getDefaultCornerRadiusValue(2),
    padding: getDefaultSpacing({
      top: 8,
      bottom: 8,
    }),
  },
});
