<template>
  <div>
    <div
      v-if="!isEmpty"
      :style="containerStyles"
    >
      <table
        v-if="field.options.mode === 'table'"
        :style="tableStyles"
        :class="{
          'widget-table--bordered': field.options.borderType === 'bordered',
        }"
        class="widget-table"
      >
        <thead :style="tableHeaderStyles">
          <tr>
            <th
              v-for="headerItem in headerItems"
              :key="headerItem.name"
            >
              <slot
                :name="`header-${headerItem.name}`"
                :value="headerItem.name"
              >
                {{ headerItem.label }}
              </slot>
            </th>
          </tr>
        </thead>

        <tbody :style="tableRowsStyles">
          <tr
            v-for="(row, index) in rows"
            :key="index"
          >
            <td
              v-for="headerItem in headerItems"
              :key="headerItem.name"
            >
              <slot
                :name="headerItem.name"
                :value="row[headerItem.name]"
                >{{ row[headerItem.name] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-else
        class="widget-table widget-table-cards"
        :class="{
          'widget-table--bordered': field.options.borderType === 'bordered',
        }"
      >
        <div
          v-for="(row, index) in rows"
          :key="index"
          :style="mobileRowStyles"
          class="widget-table-cards__element"
        >
          <div
            v-for="headerItem in headerItems"
            :key="headerItem.name"
            class="widget-table-cards__row"
          >
            <div
              :style="tableHeaderStyles"
              class="widget-table-cards__header"
            >
              <p class="widget-table-cards__header-text">
                {{ headerItem.label }}
              </p>
            </div>

            <div
              :style="tableRowsStyles"
              class="widget-table-cards__value widget-table-cards__row-content"
            >
              <slot
                :name="headerItem.name"
                :value="row[headerItem.name]"
                >{{ row[headerItem.name] }}
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      :style="tableStyles"
    >
      <slot name="empty"> </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElementStyle } from "~~/models/common";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getBorderStyle,
  getBackgroundColorStyle,
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";

type TableHeader = {
  label: string;
  name: string;
};

type Row = Record<string, string>;

const props = defineProps<{
  tableHeader: TableHeader[];
  rows: Row[];
  field: IWidgetField;
  headerField: IWidgetField;
  rowsField?: IWidgetField;
  hidden?: string[];
  isEmpty?: boolean;
}>();

const headerItems = computed<TableHeader[]>(() => {
  if (!props.hidden) {
    return props.tableHeader;
  }

  return props.tableHeader.filter(
    item => !(props.hidden || []).includes(item.name)
  );
});

const tableStyles = computed<ElementStyle>(() => {
  const options = { ...props.field.options };

  delete options.spacing;

  const horizontalSize = options.horizontalSize;
  const verticalSize = options.verticalSize;

  return {
    ...getCommonStyles(options),
    ...getStylesAsVars({
      ...(getBorderStyle(options.border) as ElementStyle),
      horizontalSize: getPxValueFromNumber(horizontalSize),
      verticalSize: getPxValueFromNumber(verticalSize),
    }),
  };
});

const mobileRowStyles = computed<ElementStyle>(() => {
  const options = { ...props.field.options };

  delete options.spacing;

  const horizontalSize = options.horizontalSize;
  const verticalSize = options.verticalSize;

  return {
    ...getCommonStyles(options),
    ...getStylesAsVars({
      ...(getBorderStyle(options.border) as ElementStyle),
      horizontalSize: getPxValueFromNumber(horizontalSize),
      verticalSize: getPxValueFromNumber(verticalSize),
    }),
    marginBottom: getPxValueFromNumber(
      props.field.options.spacing.margin.bottom
    ),
  };
});

const separatorStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...(getBorderStyle(props.headerField.options.separator) as ElementStyle),
    },
    "separator"
  );
});

const tableHeaderStyles = computed<ElementStyle>(() => {
  return {
    ...separatorStyles.value,
    ...getBackgroundColorStyle(props.headerField.options.fill),
    ...getFontSizeStyle(props.headerField.options.theme),
    ...getStylesAsVars(
      {
        ...getTextAlignStyle(props.headerField.options.alignment),
        ...getColorStyle(props.headerField.options.color),
        ...getDecorationValue(props.headerField.options.decoration),
        ...getFontFamilyStyle(props.headerField.options.fontFamily),
      },
      "header"
    ),
  };
});

const tableRowsIconStyles = computed<ElementStyle>(() => {
  if (!props.rowsField) {
    return {};
  }

  return {
    ...getStylesAsVars(
      {
        ...getColorStyle(props.rowsField.options.icon.color),
        size: getPxValueFromNumber(props.rowsField.options.icon.size),
      },
      "icon"
    ),
  };
});

const tableRowsStyles = computed<ElementStyle>(() => {
  if (!props.rowsField) {
    return {};
  }

  return {
    ...getBackgroundColorStyle(props.rowsField.options.fill),
    ...getFontSizeStyle(props.rowsField.options.theme),
    ...tableRowsIconStyles.value,

    ...getStylesAsVars(
      {
        ...getTextAlignStyle(props.rowsField.options.alignment),
        ...getColorStyle(props.rowsField.options.color),
        ...getDecorationValue(props.rowsField.options.decoration),
        ...getFontFamilyStyle(props.rowsField.options.fontFamily),
      },
      "row"
    ),
  };
});

const containerStyles = computed<ElementStyle>(() => {
  const options = {
    spacing: props.field.options.spacing,
  };

  return {
    ...getCommonStyles(options),
  };
});
</script>

<style lang="scss">
.widget-table {
  $borderStyle: var(--borderWidth) var(--borderStyle) var(--borderColor);
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &:not(.widget-table-cards) {
    overflow: hidden;
  }

  td,
  th,
  .widget-table-cards__header,
  .widget-table-cards__row-content {
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    padding-top: var(--verticalSize);
    padding-bottom: var(--verticalSize);
    padding-left: var(--horizontalSize);
    padding-right: var(--horizontalSize);
  }

  td,
  th,
  .widget-table-cards__row-content {
    border-bottom: $borderStyle;

    &:last-child {
      border-right: none !important;
    }
  }

  .widget-table-cards__row {
    &:last-child {
      .widget-table-cards__value {
        border-bottom: none;
      }
    }
  }

  th {
    &:not(:first-child)::before {
      content: "";
      display: block;
      width: 1px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: var(--separator-borderWidth) var(--separator-borderStyle)
        var(--separator-borderColor);
    }
  }

  th,
  .widget-table-cards__header-text {
    color: var(--header-color);
    font-family: var(--header-fontFamily);
    font-style: var(--header-fontStyle);
    text-decoration: var(--header-textDecoration);
    font-weight: var(--header-fontWeight, 400);
    text-align: var(--header-textAlign);
    position: relative;
  }

  td,
  .widget-table-cards__row-content {
    color: var(--row-color);
    font-family: var(--row-fontFamily);
    font-style: var(--row-fontStyle);
    text-decoration: var(--row-textDecoration);
    font-weight: var(--row-fontWeight, 400);
    text-align: var(--row-textAlign);

    svg {
      color: var(--icon-color);
      width: var(--icon-size) !important;
      height: var(--icon-size) !important;
    }
  }

  &--bordered {
    td {
      border-inline-end: $borderStyle;
    }

    .widget-table-cards__header {
      border-bottom: $borderStyle;
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.widget-table-cards {
  &__header-text {
    margin-bottom: 0;
  }

  &__element {
    overflow: hidden;
  }
}
</style>
