<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-container-size wconfig-item m-t-16"
  >
    <h3 class="sub-heading wconfig-container-size__heading">Content width</h3>

    <ConfigSizing
      :sizing="modelValue.type"
      :is-disabled="isDisabled"
      :tooltip-message="tooltipMessage"
      @update:sizing="updateSizing"
    ></ConfigSizing>

    <div
      v-if="modelValue.type === ControlSizing.FIXED"
      class="m-t-16 wconfig-container-size__width"
    >
      <ConfigColumnWidth
        :model-value="modelValue.width"
        :label="label"
        @update:model-value="handleUpdate('width', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ControlSizing } from "~~/models/widgets/widget-controls.model";
import { DEFAULT_FORM_WIDTH } from "~~/constants/widget-config";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue?: {
      type: ControlSizing;
      width: number;
    };
    isDisabled?: boolean;
    label?: string;
    tooltipMessage?: string;
  }>(),
  {
    modelValue: () => ({
      type: ControlSizing.FIXED,
      width: DEFAULT_FORM_WIDTH,
    }),
    label: "",
    tooltipMessage: "",
  }
);

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      type: ControlSizing;
      width: number;
    }
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const updateSizing = (event: ControlSizing): void => {
  handleUpdate("type", event);
};

const handleUpdate = (key: string, value: string | number) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.wconfig-container-size {
  &__heading {
    margin-bottom: $space-m;
  }

  &__width {
    .widget-control-container {
      padding: 0;
      border-bottom: none;
    }
  }
}
</style>
