<template>
  <div
    :style="containerStyles"
    class="bets-list-events"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :style="eventCardStyles"
    >
      <div
        :style="itemsContanerStyles"
        class="bets-list-events__items-container"
      >
        <WidgetField
          :widget="widget"
          :field="startTimeField"
          :style="timeContainerInlineStyles"
          class="bets-list-events__time-container"
        >
          <span class="bets-list-events__time">
            <span
              v-if="startTimeField.options.displayMode === 'date'"
              class="bets-list-events__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="iconStyles"
                :name="
                  startTimeField.options.icon.valueDate ??
                  'ant-design:calendar-filled'
                "
                class="bets-list-events__icon"
              />

              <span :style="timeLabelInlineStyles"> 00-00-0000 </span>
            </span>
            <span
              v-if="startTimeField.options.displayMode === 'date'"
              class="bets-list-events__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="iconStyles"
                :name="
                  startTimeField.options.icon.valueTime ??
                  'ant-design:clock-circle-filled'
                "
                class="bets-list-events__icon"
              />

              <span :style="timeLabelInlineStyles"> 00:00 </span>
            </span>
            <span
              v-if="startTimeField.options.displayMode === 'time'"
              class="bets-list-events__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="iconStyles"
                :name="
                  startTimeField.options.icon.valueTime ??
                  'ant-design:clock-circle-filled'
                "
                class="bets-list-events__icon"
              />

              <span :style="timeLabelInlineStyles"> 0 h 00 m </span>
            </span>
          </span>
        </WidgetField>
        <WidgetField
          :widget="widget"
          :field="betItemField"
          class="w-100"
        >
          <div
            :style="betItemsContainerStyles"
            class="bets-list-events__items-list"
          >
            <div
              v-for="(inner, index2) in item.bet_items"
              :key="index2"
              class="bets-list-events__item"
              :style="betItemContainerStyles"
            >
              <SportsbookBetItem
                :item-value="inner"
                :state-options="currentStateOptions"
                :bet-item-field="betItemField"
                :state="betItemState"
                :show-both-arrows-states="[State.ACTIVE, State.HOVER]"
              />
            </div>
          </div>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="eventButtonField"
        >
          <span
            :style="eventButtonStyles"
            class="bets-list-events__button"
          >
            {{ item.event_button }}
          </span>
        </WidgetField>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getSpacing,
  getWidthStyles,
} from "~~/assets/utils/widget-settings";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { CellSizeType } from "~~/models/grid.interface";

const items = [
  {
    start_time: "Start time",
    date: "Date",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "Start time",
    date: "Date",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "Start time",
    date: "Date",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
  {
    start_time: "Start time",
    date: "Date",
    bet_items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
    event_button: "+000",
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  startTimeField: IWidgetField;
  betItemField: IWidgetField;
  eventButtonField: IWidgetField;
  betItemState: State;
  listItemMoreState: State;
}>();

const dateGap = computed<string>(() =>
  getPxValueFromNumber(props.startTimeField.options.spaceBetween ?? 4)
);

const containerStyles = computed<ElementStyle>(() => {
  const { spacing } = props.field.options.card;

  return {
    ...getDisplayGridStyles(props.field.options.gridSettings),
    ...getSpacing(spacing.margin, SpacingKeyName.MARGIN),
  };
});

const eventCardStyles = computed<ElementStyle>(() => {
  const { spacing, ...cardStyles } = props.field.options.card;

  return {
    ...getSpacing(spacing.padding, SpacingKeyName.PADDING),
    ...getCommonStyles(cardStyles),
  };
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const flexDirection =
    props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  return {
    gap: getPxValueFromNumber(props.betItemField.options.spaceBetween),
    flexDirection,
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    width: "100%",
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.startTimeField.options.icon.color),
    ...getFontSizeStyle(props.startTimeField.options.icon.size),
  };
});

const timeContainerInlineStyles = computed<ElementStyle>(() => {
  return {
    width: getWidthStyles(props.startTimeField.options.width).width,
    flexShrink:
      props.startTimeField.options.width.type === CellSizeType.HUG
        ? "initial"
        : "0",
    ...getCommonStyles(props.startTimeField.options),
  };
});

const timeLabelInlineStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.startTimeField.options.color),
    ...getFontSizeStyle(props.startTimeField.options.theme),
    ...getDecorationValue(props.startTimeField.options.decoration),
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.betItemState === State.DEFAULT) {
    return props.betItemField.options;
  }

  return props.betItemField.options.states[props.betItemState];
});

const itemsContanerStyles = computed<ElementStyle>(() => {
  if (props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL) {
    return {};
  }

  return {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: getPxValueFromNumber(props.field.options.items.distanceBetweenItems),
  };
});

const currentEventButtonStateOptions = computed<IWidgetOptions>(() => {
  if (props.listItemMoreState === State.DEFAULT) {
    return props.eventButtonField.options;
  }

  return props.eventButtonField.options.states[props.listItemMoreState];
});

const eventButtonStyles = computed<ElementStyle>(() => {
  return {
    width: getWidthStyles(props.eventButtonField.options.width).width,
    textAlign: "center",
    ...getCommonStyles({
      ...props.eventButtonField.options,
      ...currentEventButtonStateOptions.value,
    }),
    ...getColorStyle(currentEventButtonStateOptions.value.color),
    ...getFontSizeStyle(props.eventButtonField.options.theme),
    ...getDecorationValue(props.eventButtonField.options.decoration),
  };
});
</script>

<style lang="scss" scoped>
.bets-list-events {
  display: grid;
  overflow: hidden;

  &__time-container {
    min-width: fit-content;
  }

  &__time {
    @include flex(flex-start, flex-start, v-bind(dateGap));
  }

  &__time-element {
    @include flex(center, flex-start, 4px);
  }

  &__items-container {
    @include flex(center, flex-start, 0);
  }

  &__items-list {
    @include flex(flex-start, flex-start, 0);
    flex-grow: 1;
  }

  &__item {
    flex: 1;
    width: 0;
  }

  &__button {
    display: inline-block;
    padding: 4px 8px;
  }
}
</style>
