<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-states>
          <ControlsList
            :widget="widget"
            :controls="widgetStatesControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #betslip_status-design>
          <ControlsList
            :widget="widget"
            :controls="headerCountersDesignControls"
          />
        </template>

        <template #betslip_status-content>
          <ControlsList
            :widget="widget"
            :controls="headerCountersContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useBetslipStatusConfig } from "~~/composables/widgets/betslip/useBetslipStatusConfig";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  state: State;
}>();

const emit = defineEmits<{
  (e: "update-state", event: State): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingsStore);

const state = toRef(() => props.state);

const {
  TABS,
  DROPDOWN_ITEMS,
  widgetDesignControls,
  widgetContentControls,
  widgetStatesControls,

  headerCountersDesignControls,
  headerCountersContentControls,
} = useBetslipStatusConfig(ref(props.widget), state, emit);

const { bindingParams } = useWidgetDynamicParams(
  {
    "modalURL": {
      label: "Modal URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
