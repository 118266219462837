import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

type Args = {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  stateName: string;
  fieldName: string;
};

export const useTabsConfig = (
  { widget, states, stateName, fieldName }: Args,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const tabsDesignControls = computed(() => {
    return getInitialDesignList({ source: fields.value[fieldName].field });
  });

  const isDisplaySettingsAlignmentVisible = computed<boolean>(() => {
    return (
      fields.value[fieldName].field.options.buttonDisplaySettings.resizing ===
      ResizingType.FILL
    );
  });

  const tabsContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: fields.value[fieldName].field,
        className: "group-control-element",
        options: {
          showAlignmentOnHugOnly: true,
        },
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.distance",
        className: isDisplaySettingsAlignmentVisible.value ? "p-t-16" : "",
        valueSource: fields.value[fieldName].field,
        options: {
          label: "Distance between tabs",
        },
      },
      typography({
        source: fields.value[fieldName].field,
        except: ["link"],
      }),
    ];
  });

  const currentTabsStatePath = computed<string>(() => {
    if (states.value[stateName] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[stateName]}`;
  });

  const tabsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value[stateName],
        onUpdate: value => {
          emit("update-state", { state: stateName, value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value[fieldName].field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: currentTabsStatePath.value,
      }),
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentTabsStatePath.value}.color`,
            valueSource: fields.value[fieldName].field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
    ];
  });

  return {
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
  };
};
