import { Ref } from "vue";

import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { IFields } from "../useWidgetFields";

export const useProvidersElementControls = (
  fields: Ref<IFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const cardsListDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.providers_item.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const cardsListContentConfigItems: ConfigTabItem[] = [
    {
      label: "Photos styling",
      value: "providers_item_image",
    },
    {
      label: "Titles styling",
      value: "providers_item_title",
    },
    {
      label: "Descriptions styling",
      value: "providers_item_limits",
    },
    {
      label: "Buttons styling",
      value: "providers_item_button",
    },
    {
      label: "Tooltips styling",
      value: "providers_item_tooltip",
    },
  ];
  const cardsListContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigLayoutPosition",
        valuePath: "options.layoutPosition",
        valueSource: fields.value.providers_item.field,
        options: {},
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: cardsListContentConfigItems,
        },
      },
    ];
  });

  const cardsListStateValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const cardsListStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${cardsListStateValuePath.value}.fillImageColor`,
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${cardsListStateValuePath.value}.border`,
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${cardsListStateValuePath.value}.shadow`,
        valueSource: fields.value.providers_item.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  const cardsTitleControls = computed(() => {
    return [
      typography({ source: fields.value.providers_item_title.field! }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: fields.value.providers_item_title.field,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({
              name: "cardsLinkStates",
            });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.providers_item_title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const cardsDescriptionControls = computed(() => {
    return [
      typography({ source: fields.value.providers_item_limits.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.providers_item_limits.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const cardsListButtonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.providers_item_button.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.providers_item_button.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.providers_item_button.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.providers_item_button.field,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.providers_item_button.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.providers_item_button.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const cardsListButtonContentControls = computed(() => {
    const items = [
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "p-t-16",
            valueSource: fields.value.providers_item_button.field,
            options: {
              label: "Text",
            },
          },
        ],
      },
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: fields.value.providers_item_button.field,
        options: {
          showAlignmentOnFillOnly: false,
        },
      },
    ];

    return items;
  });

  const buttonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const cardsListButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.fill`,
      valueSource: fields.value.providers_item_button.field,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${buttonSettingsValuePath.value}.border`,
      valueSource: fields.value.providers_item_button.field,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${buttonSettingsValuePath.value}.shadow`,
      valueSource: fields.value.providers_item_button.field,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${buttonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: fields.value.providers_item_button.field,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const cardsImageControls = computed(() => [
    {
      componentPath: "ConfigContainerSize",
      valuePath: "options.containerSize",
      valueSource: fields.value.providers_item_image.field,
      options: {
        isShow: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.providers_item_image.field,
      options: {
        label: "Border",
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.providers_item_image.field,
      options: {
        label: "Shadow",
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.providers_item_image.field,
      options: {
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.providers_item_image.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  return {
    cardsListDesignControls,
    cardsListContentControls,
    cardsListStatesControls,
    cardsTitleControls,
    cardsDescriptionControls,
    cardsListButtonDesignControls,
    cardsListButtonContentControls,
    cardsListButtonStatesControls,
    cardsImageControls,
  };
};
