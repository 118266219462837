import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  State,
  TextStyle,
  DisplayOrientation,
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultBorderValue,
  getDefaultFillImage,
  getDefaultTheme,
  getDefaultDecoration,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultButtonTextSettings,
  DESIGN_INITIAL,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { TEXT_BUTTON_INITIAL } from "~~/constants/configs/common/text-button-initial";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const MODAL_FIELDS = [
  "modal",
  "modal_title",
  "modal_description",
  "modal_primary_button",
  "modal_secondary_button",
  "modal_close_icon",
];

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, string> = {
    event_button: State.DEFAULT,
    screen: State.ACTIVE_EVENTS,
    tabs: State.DEFAULT,
    cards: State.DEFAULT,
    terms_title: State.DEFAULT,
    action_button: State.DEFAULT,
    modal_primary_btn: State.DEFAULT,
    modal_secondary_btn: State.DEFAULT,
    modal_close_icon: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({
    ...DEFAULT_STATES,
  });

  /*
  Initialize widget with initial options
*/

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /*
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      }).options
    );

    const initialTabStateOptions = () => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("", 100),
        }),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#000000"),
      };
    };

    const initialTabsOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "margins", "padding", "size"],
        additionalData: {
          options: {
            buttonDisplaySettings: {
              resizing: "hug",
              alignment: "left",
            },
            distance: 16,
            states: {
              [State.HOVER]: initialTabStateOptions(),
              [State.ACTIVE]: initialTabStateOptions(),
            },
          },
        },
        values: {
          theme: 13,
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(8),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialCardsOptions = prefillEmptyOptions(
      fields.value.cards.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "margins", "padding", "size"],
        additionalData: {
          options: {
            layout: DisplayOrientation.HORIZONTAL,
            distance: 16,
            states: {
              [State.HOVER]: initialTabStateOptions(),
              [State.ACTIVE]: initialTabStateOptions(),
            },
          },
        },
        values: {
          theme: 13,
          fill: getDefaultFillValue("#ffffff"),
          alignment: Alignment.LEFT,
          shadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000", 10),
            blur: 20,
            y: 10,
          }),
          spacing: {
            padding: getDefaultSpacing(24),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialNoBonusesOptions = prefillEmptyOptions(
      fields.value.no_active_bonuses.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          alignment: Alignment.CENTER,
          theme: getDefaultTheme(13),
        },
      }).options
    );

    const initialBonusOptions = prefillEmptyOptions(
      fields.value.bonus_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      }).options
    );

    const initiaStatusOptions = prefillEmptyOptions(
      fields.value.status.field.options,
      getInitialOptions({
        data: {
          options: {
            fontFamily: getDefaultFontFamily(),
            secondary: {
              theme: getDefaultTheme(),
              decoration: getDefaultDecoration(),
              color: getDefaultFillValue("#8C8C8C"),
            },
            primary: {
              theme: getDefaultTheme(),
              decoration: getDefaultDecoration(),
              color: getDefaultFillValue("#000000"),
            },
            padding: getDefaultSpacing({ bottom: 12 }),
          },
        },
      }).options
    );

    const initialDatesOptions = prefillEmptyOptions(
      fields.value.dates.field.options,
      getInitialOptions({
        data: {
          options: {
            fontFamily: getDefaultFontFamily(),
            secondary: {
              theme: getDefaultTheme(13),
              decoration: getDefaultDecoration(),
              color: getDefaultFillValue("#8C8C8C"),
            },
            primary: {
              theme: getDefaultTheme(13),
              decoration: getDefaultDecoration(),
              color: getDefaultFillValue("#000000"),
            },
            icon: {
              size: 20,
              color: getDefaultFillValue("#000000"),
            },
            padding: getDefaultSpacing({ bottom: 12 }),
          },
        },
      }).options
    );

    const initialDescriptionOptions = prefillEmptyOptions(
      fields.value.description.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [],
          padding: getDefaultSpacing({ bottom: 12 }),
          theme: getDefaultTheme(13),
        },
      }).options
    );

    const initialProgressBarOptions = prefillEmptyOptions(
      fields.value.progress_bar.field.options,
      getInitialOptions({
        data: {
          options: {
            ...TITLE_INITIAL().options,
            bar: {
              size: 8,
              color: getDefaultFillValue("#1677FF"),
              fill: getDefaultFillValue("#F0F0F0"),
            },
          },
        },
        values: {
          theme: getDefaultTheme(13),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
      }).options
    );

    const initialAmountsOptions = prefillEmptyOptions(
      fields.value.amounts.field.options,
      getInitialOptions({
        data: {
          options: {
            fontFamily: getDefaultFontFamily(),
            primary: {
              theme: getDefaultTheme(13),
              decoration: getDefaultDecoration(),
              color: getDefaultFillValue("#8C8C8C"),
            },
            secondary: {
              theme: getDefaultTheme(24),
              decoration: getDefaultDecoration([TextStyle.BOLD]),
              color: getDefaultFillValue("#000000"),
            },
            padding: getDefaultSpacing({ bottom: 24 }),
          },
        },
      }).options
    );

    const termsState = () => {
      return {
        color: getDefaultFillValue("#1677FF"),
      };
    };

    const initialTermsOptions = prefillEmptyOptions(
      fields.value.terms_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#1677FF"),
          padding: getDefaultSpacing(),
          theme: getDefaultTheme(13),
          link: {
            states: {
              [State.HOVER]: termsState(),
              [State.ACTIVE]: termsState(),
            },
          },
        },
      }).options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.action_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
      }).options
    );

    const initialPhotoOptions = prefillEmptyOptions(
      fields.value.image.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            resizing: ResizingType.FILL,
            containerSize: 350,
          },
        },
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
          spacing: {
            margin: getDefaultSpacing(),
            padding: getDefaultSpacing({ right: 12 }),
          },
        },
      }).options
    );

    const initialModalOptions = prefillEmptyOptions(
      fields.value.modal.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            padding: getDefaultSpacing(24),
            contentWidth: {
              type: Sizing.FIXED,
              width: 500,
              alignment: "center",
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          shadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000", 10),
            blur: 20,
            y: 10,
          }),
          cornerRadius: getDefaultCornerRadiusValue({ value: 12 }),
        },
      }).options
    );

    const initialModalTitleOptions = prefillEmptyOptions(
      fields.value.modal_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(13),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      }).options
    );

    const initialModalDescrOptions = prefillEmptyOptions(
      fields.value.modal_description.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(13),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
      }).options
    );

    const initialModalPrimaryButtonOptions = prefillEmptyOptions(
      fields.value.modal_primary_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
        values: {
          padding: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 8,
            bottom: 8,
          }),
          theme: getDefaultTheme(13),
          margins: getDefaultSpacing({
            right: 8,
          }),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#1677FF"),
          }),
        },
      }).options
    );

    const initialModalSecondaryButtonOptions = prefillEmptyOptions(
      fields.value.modal_secondary_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
        values: {
          padding: getDefaultSpacing({
            left: 12,
            right: 12,
            top: 8,
            bottom: 8,
          }),
          theme: getDefaultTheme(13),
          fill: getDefaultFillValue("#ffffff"),
          color: getDefaultFillValue("#1677FF"),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#1677FF"),
          }),
        },
      }).options
    );

    const initialModalCloseButton = prefillEmptyOptions(
      fields.value.modal_close_icon.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          padding: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          iconSettings: getDefaultIconSettings({
            default_value: "ant-design:close-outlined",
            textColor: getDefaultFillValue("#000000", 45),
          }),
          buttonTextSettings: getDefaultButtonTextSettings({ _active: false }),
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.cards.field,
      initialCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_active_bonuses.field,
      initialNoBonusesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bonus_title.field,
      initialBonusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.status.field,
      initiaStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.dates.field,
      initialDatesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.description.field,
      initialDescriptionOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.progress_bar.field,
      initialProgressBarOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.amounts.field,
      initialAmountsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.terms_title.field,
      initialTermsOptions
    );

    widgetsStore.updateValue(
      useLocalizedValue().transformToLocalizedValue("Terms & conditions")
        .localizedValue,
      "value",
      fields.value.terms_title.field,
      "value"
    );

    widgetsStore.updateFieldOptions(
      fields.value.action_button.field,
      initialButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.image.field,
      initialPhotoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal.field,
      initialModalOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal_title.field,
      initialModalTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal_description.field,
      initialModalDescrOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal_primary_button.field,
      initialModalPrimaryButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal_secondary_button.field,
      initialModalSecondaryButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.modal_close_icon.field,
      initialModalCloseButton
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,
    MODAL_FIELDS,
    initFields,
    initWidgetFieldsOptions,
  };
};
