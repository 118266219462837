import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";
import { SEARCH_INPUT_DELAY } from "~~/constants/widget-config";
import { debounce } from "~~/assets/utils";
import { useWidgetsStore } from "~~/store/widgets";
import { useListSearch } from "~~/composables/widgets-config/useListSearch";

// TODO: Move to constants/widget-icons.ts
const WIDGET_ICONS = {
  FakeWidget: "widgets:fake-widget",
  TextWidget: "widgets:text-widget",
  CardWidget: "widgets:card-widget",
  ItemsWidget: "widgets:items-widget",
  DropdownWidget: "widgets:dropdown-widget",
} as Record<string, string>;

const WIDGET_ICONS_DEFAULT = "custom:widget-icon-placeholder";

const WIDGET_NAME_KEYNAME = "title";

export const useWidgetsSearch = () => {
  const widgetsStore = useWidgetsStore();
  const { searchValue, getSearchHighlightedItem } = useListSearch();

  const widgetsLoading = ref<boolean>(false);
  const isTyping = ref<boolean>(false);

  const initialWidgetsLoading = computed<boolean>(() => {
    return widgetsStore.initialWidgetsLoading;
  });

  const widgetsList = computed<IWidgetsListElement[]>(() => {
    return widgetsStore.availableWidgets;
  });

  const widgets = computed<IWidgetsListElement[]>(() => {
    const widgets = getSearchHighlightedItem(
      widgetsList.value,
      WIDGET_NAME_KEYNAME
    ).map(widget => {
      const icon = WIDGET_ICONS[widget.name] || WIDGET_ICONS_DEFAULT;

      return {
        ...widget,
        icon: icon,
      };
    });

    return widgets;
  });

  const fetchWidgets = (): void => {
    widgetsLoading.value = true;

    widgetsStore.fetchWidgetsList(searchValue.value).finally(() => {
      widgetsLoading.value = false;
      isTyping.value = false;
    });
  };

  const fetchWidgetsDebounced = debounce((): void => {
    fetchWidgets();
  }, SEARCH_INPUT_DELAY);

  const handleWidgetSearchInput = (value: string): void => {
    searchValue.value = value;
    isTyping.value = true;

    fetchWidgetsDebounced();
  };

  return {
    searchValue,
    widgets,
    widgetsLoading,
    initialWidgetsLoading,
    isTyping,

    handleWidgetSearchInput,
  };
};
