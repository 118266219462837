<template>
  <span
    :style="eventButtonStyles"
    class="sportsbook-live__action-btn"
  >
    +000
  </span>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
} from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  field: IWidgetField;
  state: State;
}>();

const currentEventButtonStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const eventButtonStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.field.options,
      ...currentEventButtonStateOptions.value,
    }),
    ...getColorStyle(currentEventButtonStateOptions.value.color),
    ...getFontSizeStyle(props.field.options.theme),
    ...getDecorationValue(props.field.options.decoration),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__action-btn {
    display: inline-block;
  }
}
</style>
