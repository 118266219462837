<template>
  <div
    v-if="modelValue?.length && hasContentPermissions"
    class="wconfig-form-field-items wconfig-item"
  >
    <div class="wconfig-form-field-items__label-container">
      <p class="wconfig-form-field-items__label m-b-0 sub-heading">
        {{ label }}
      </p>
    </div>

    <DNDContainer
      :container-id="DndContainerId.ARRAY_ITEMS"
      class="wconfig-form-field-items__list"
      show-divider
      :divider-position="dividerPosition"
      @drop="handleBlockDrop"
      @over="handleDragOver"
      @update-container-position="handleUpdateContainerPosition"
    >
      <div
        v-for="item in modelValue"
        :id="`menu-item-${item.value}`"
        :key="item.value"
        class="wconfig-form-field-items__list-item"
      >
        <span
          v-draggable="{
            payload: {
              id: item.value,
            },
            containerId: DndContainerId.ARRAY_ITEMS,
          }"
          class="wconfig-form-field-items__list-item-icon anticon"
        >
          <CommonIcon
            name="custom:dnd-marker"
            class="wconfig-form-field-items__drag-icon"
          />
        </span>

        <div class="wconfig-form-field-items__input-container">
          <a-input
            :value="getLocalizedValue(item.label)"
            :bordered="false"
            :maxlength="MAX_BUTTON"
            class="wconfig-form-field-items__input"
            @update:value="handleInput(item, $event)"
          />
        </div>
      </div>
    </DNDContainer>
  </div>
</template>

<script setup lang="ts">
import { MAX_BUTTON } from "~~/constants/input-validation";
import { IInsertPosition } from "~~/models/grid.interface";
import { DndContainerId } from "~~/models/common/dropdown.model";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { useText } from "~~/components/composables/fields/text/useText";
import { useDropdownItemsDnd } from "~~/composables/widgets-config/useDropdownItemsDnd";
import { IWidgetFieldItem } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const { canUpdateInputValue } = useText(MAX_BUTTON);

const props = defineProps<{
  modelValue: IWidgetFieldItem[];
  label?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: IWidgetFieldItem[]): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { getLocalizedValue, setLocalizedValue } = useLocalizedValue();

const handleInput = (item: IWidgetFieldItem, value: string) => {
  const canBeUpdated = canUpdateInputValue(getLocalizedValue.value(item.label));

  if (!canBeUpdated) {
    return;
  }

  item.label = setLocalizedValue.value(item.label, value);
};

const handleReorder = (
  targetId: number | string,
  insertPosition: IInsertPosition
) => {
  const [items] = reorderListElements(
    "value",
    targetId,
    insertPosition,
    props.modelValue
  );

  emit("update:modelValue", items);
};

const {
  handleBlockDrop,
  handleDragOver,
  handleUpdateContainerPosition,
  dividerPosition,
} = useDropdownItemsDnd(handleReorder);
</script>

<style lang="scss">
.wconfig-form-field-items {
  display: flex;
  flex-direction: column;
  gap: $space-m;

  padding: $space-m;

  &__label-container {
    @include flex(center, space-between, 0);
  }

  &__icon {
    color: $c-grey-45;
    font-size: 14px;
    cursor: pointer;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: $space-s;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }
  }

  &__input-container {
    height: 24px;
    padding: 0px 8px;
    border-radius: 4px;
    overflow: hidden;
    background: $c-grey-02 !important;
    text-overflow: ellipsis;
    flex-grow: 1;

    display: flex;
    align-items: center;
  }

  &__input {
    height: 100%;
    padding: 0;
    border: none;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__list-item-icon {
    padding: 0 5px;
    cursor: pointer;
  }

  &__drag-icon {
    transform: scale(1.3);
    opacity: 0.45;

    svg {
      width: 7px;
      height: 11px;
    }
  }
}
</style>
