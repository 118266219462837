import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useAllowDisableDependentFieldContentItems } from "~~/composables/widgets/useAllowDisableDependentFieldContentItems";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { CellSizeType } from "~~/models/grid.interface";

import { usePromoGroupControls } from "../../../common/usePromoGroupControls";

import { useCasinoListFenixItems } from "./useCasinoListFenixItems";

export const useCasinoListFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } =
    useCasinoListFenixItems(widget);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.page_type,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.casino_list,
              value: State.CASINO_LIST,
            },
            {
              label: STATE_KEY_LABELS.casino_search,
              value: State.CASINO_SEARCH,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "page_type", value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            ...SETTINGS_ITEMS,
            states.value.page_type === State.CASINO_LIST
              ? { label: "Games List", value: "games_list" }
              : { label: "All Games", value: "all_games" },
          ],
        },
      },
    ];
  });

  // back_button
  const backButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const backButtonContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        options: {
          isTextContentIcons: false,
        },
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const backButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  //header
  const headerDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const headerContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            className: "event-cards-radio",
            options: {
              label: "Layout",
              items: [
                {
                  icon: "custom:casino-list-fenix-3rows",
                  value: 1,
                },
                {
                  icon: "custom:casino-list-fenix-2rows",
                  value: 2,
                },
                {
                  icon: "custom:casino-list-fenix-1rows",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            states.value.page_type === State.CASINO_LIST
              ? { label: "Page Title", value: "page_title" }
              : { label: "Search Result", value: "search_result" },
            {
              value: "sorting",
              label: "Sorting",
            },
            {
              value: "search",
              label: "Search",
            },
          ],
        },
      },
    ];
  });

  //page_title

  const pageTitleDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: `options.padding`,
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const pageTitleContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.icon.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
      {
        section: generateContentSectionName("Icon"),
        toggleable: true,
        valueSource: selectedField.value?.options.icon,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.textColor",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  //search_result
  const searchResultDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: `options.padding`,
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const searchResultContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Search Result"),
        toggleable: true,
        valueSource: selectedField.value?.options,
        controls: typography({
          source: selectedField.value,
          except: ["ConfigAlignment"],
        }).controls,
      },
      {
        section: generateContentSectionName("Search query"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.query.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.query.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.query.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.query.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  // sorting
  const sortingDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const sortingContentControls = computed(() => {
    return [
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
    ];
  });

  const sortingStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateContentSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.color`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: `${selectedElementPath.value}.decoration`,
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
  ]);

  // search
  const searchDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const searchContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              type: ColorPickerType.TEXT,
              exclude: [CellSizeType.HUG],
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.text.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.text.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.text.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.text.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Placeholder"),
        toggleable: true,
        valueSource: selectedField.value?.options.placeholder,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "options.placeholder.value",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              showOptions: false,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.placeholder.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
        },
      },
    ];
  });

  const searchStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateContentSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.text.color`,
          valueSource: selectedField.value,
          options: {
            label: "Value color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.placeholder.color`,
          valueSource: selectedField.value,
          options: {
            label: "Placeholder color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateContentSectionName("Search icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.icon.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  // games_list
  const gamesListDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const gamesListContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "games_category",
              label: "Category / Subcategory",
            },
            {
              value: "all_games",
              label: "All Games",
            },
          ],
        },
      },
    ];
  });

  // games_category
  const gamesCategoryDesignControls = computed(() => {
    return getInitialDesignList({ source: selectedField.value });
  });

  const gamesCategoryContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "games_category_title",
              label: "Category / Subcategory Title",
            },
            {
              value: "games_category_list",
              label: "Category / Subcategory List",
            },
          ],
        },
      },
    ];
  });

  const gamesCategoryStateControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
      },
    ];

    if (states.value[selectedField.value!.name] === State.HOVER) {
      return [
        ...sharedControls,
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              {
                value: "games_category_hover_button",
                label: "Hover Button",
                isActiveValueSource:
                  fields.value.games_category_hover_button.field.options,
              },
            ],
          },
        },
      ];
    } else {
      return [...sharedControls];
    }
  });

  // games_category_title
  const categoryTitleDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: `options.padding`,
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const categoryTitleContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distanceBetweenItems",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          hidePicker: true,
          isStatesTab: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "games_category_button",
              label: "Games Button",
              isActiveValueSource:
                fields.value.games_category_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const categoryTitleStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.icon.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // games_category_button
  const categoryButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const categoryButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        visible:
          selectedField.value?.options.buttonDisplaySettings.resizing ===
          ResizingType.FILL,
        options: {
          isTextContentIcons: false,
        },
      },
    ];
  });

  const categoryButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  // games_category_hover_button
  const categoryHoverButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const categoryHoverButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        visible:
          selectedField.value?.options.buttonDisplaySettings.resizing ===
          ResizingType.FILL,
        options: {
          isTextContentIcons: false,
        },
      },
    ];
  });

  const categoryHoverButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  // games_category_list
  const categoryListDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const categoryListContentControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.games_status_category,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.with_active_games,
              value: State.WITH_ACTIVE_GAMES,
            },
            {
              label: STATE_KEY_LABELS.no_active_games,
              value: State.NO_ACTIVE_GAMES,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "games_status_category", value });
        },
      },
    ];

    if (states.value.games_status_category === State.WITH_ACTIVE_GAMES) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Display mode"),
          controls: [
            {
              componentPath: "common/ConfigRadioTextElements",
              valuePath: "options.mode",
              valueSource: selectedField.value,
              options: {
                items: [
                  { label: "Grid", value: 1 },
                  { label: "Slider", value: 2 },
                ],
              },
            },
            {
              componentPath: "common/ConfigRangeSlider",
              valuePath: "options.maxGamesRow",
              valueSource: selectedField.value,
              className: "p-l-16 p-r-16 p-t-16",
              options: {
                label:
                  selectedField.value?.options.mode === 1
                    ? "Maximum number of games in a row"
                    : "Мaximum number of visible games",
                minValue: 1,
                maxValue: 10,
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gap.col",
              valueSource: selectedField.value,
              options: {
                label: "Distance between games",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gap.row",
              valueSource: selectedField.value,
              visible: selectedField.value?.options.mode === 1,
              options: {
                label: "Distance between rows",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [{ label: "Games cards", value: "game_cards" }],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          section: generateContentSectionName("Title"),
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "value",
              valueSource: fields.value.games_category_nogames_title.field,
            },
            ...(typography({
              source: fields.value.games_category_nogames_title.field,
            }).controls ?? []),
          ],
        },
        {
          section: generateContentSectionName("Description"),
          toggleable: true,
          valueSource:
            fields.value.games_category_nogames_title.field.options.description,
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "options.description.value",
              valueSource: fields.value.games_category_nogames_title.field,
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: "options.description.textColor",
              valueSource: fields.value.games_category_nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Text color",
                type: ColorPickerType.TEXT,
              },
            },
            {
              componentPath: "ConfigThemeSelect",
              valuePath: "options.description.theme",
              valueSource: fields.value.games_category_nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Font size",
              },
            },
          ],
        },
      ];
    }
  });

  // all_games_list
  const allGamesDesignControls = computed(() => {
    return getInitialDesignList({ source: selectedField.value });
  });

  const allGamesContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.distance",
        valueSource: selectedField.value,
        options: {
          label: "Distance between elements",
          showIconPrefix: false,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        section: generateContentSectionName("All Games Title"),
        toggleable: true,
        valueSource: fields.value.all_games_title.field.options,
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.all_games_title.field
          );
        },
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.all_games_title.field,
            options: {
              placeholder: "Text",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "All Games List", value: "all_games_list" },
            { label: "Show More Button", value: "show_more_button" },
          ],
        },
      },
    ];
  });

  // all_games_title
  const allGamesTitleDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const allGamesTitleContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          showOptions: false,
        },
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
    ];
  });

  // all_games_list
  const allGamesListDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const allGamesListContentControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.games_status_allgames,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.with_active_games,
              value: State.WITH_ACTIVE_GAMES,
            },
            {
              label: STATE_KEY_LABELS.no_active_games,
              value: State.NO_ACTIVE_GAMES,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "games_status_allgames", value });
        },
      },
    ];

    if (states.value.games_status_allgames === State.WITH_ACTIVE_GAMES) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Display mode"),
          controls: [
            {
              componentPath: "common/ConfigRadioTextElements",
              valuePath: "options.mode",
              valueSource: selectedField.value,
              options: {
                items: [
                  { label: "Grid", value: 1 },
                  { label: "Slider", value: 2 },
                ],
              },
            },
            {
              componentPath: "common/ConfigRangeSlider",
              valuePath: "options.maxGamesRow",
              valueSource: selectedField.value,
              className: "p-l-16 p-r-16 p-t-16",
              options: {
                label:
                  selectedField.value?.options.mode === 1
                    ? "Maximum number of games in a row"
                    : "Мaximum number of visible games",
                minValue: 1,
                maxValue: 10,
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gap.col",
              valueSource: selectedField.value,
              options: {
                label: "Distance between games",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gap.row",
              valueSource: selectedField.value,
              visible: selectedField.value?.options.mode === 1,
              options: {
                label: "Distance between rows",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [{ label: "Games cards", value: "game_cards" }],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          section: generateContentSectionName("Title"),
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "value",
              valueSource: fields.value.all_games_nogames_title.field,
            },
            ...(typography({
              source: fields.value.all_games_nogames_title.field,
            }).controls ?? []),
          ],
        },
        {
          section: generateContentSectionName("Description"),
          toggleable: true,
          valueSource:
            fields.value.all_games_nogames_title.field.options.description,
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "options.description.value",
              valueSource: fields.value.all_games_nogames_title.field,
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: "options.description.color",
              valueSource: fields.value.all_games_nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Text color",
                type: ColorPickerType.TEXT,
              },
            },
            {
              componentPath: "ConfigThemeSelect",
              valuePath: "options.description.theme",
              valueSource: fields.value.all_games_nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Font size",
              },
            },
          ],
        },
      ];
    }
  });

  // show_more_button
  const showMoreButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const showMoreButtonContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        options: {
          isTextContentIcons: false,
        },
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const showMoreButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  // game_name
  const gameNameControls = computed(() => {
    return [
      typography({ source: selectedField.value }, "Text"),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  //game_cards

  const gameCardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const gameCardsContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "game_cover",
              label: "Game Cover",
            },
            {
              value: "game_name",
              label: "Game Name",
              isActiveValueSource: fields.value.game_name.field.options,
            },
          ],
        },
      },
    ];
  });

  // game_cover
  const gameCoverDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const gameCoverContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Image"),
        controls: [
          {
            componentPath: "ConfigCornerRadius",
            valuePath: "options.image.cornerRadius",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Corner radius",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Cover elements layout"),
        controls: [
          {
            componentPath: "common/ConfigCommonRadio",
            valuePath: "options.display.position",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Position",
              firstVariant: "",
              firstVariantIcon: {
                icon: "custom:unordered-list-outlined",
              },
              firstValue: Alignment.LEFT,
              secondaryVariant: "",
              secondaryVariantIcon: {
                className: "r-180",
                icon: "custom:unordered-list-outlined",
              },
              secondaryValue: Alignment.RIGHT,
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.image.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "promo_tag",
              label: "Promo Tag",
            },
            {
              value: "favorite_icon",
              label: "Favourite Icon",
            },
          ],
        },
      },
    ];
  });

  const gameCoverStatesControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
    ];

    if (states.value[selectedField.value!.name] === State.HOVER) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Fill"),
          controls: [
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${selectedElementPath.value}.fill`,
              valueSource: selectedField.value,
              options: {
                label: "Card",
                type: ColorPickerType.BACKGROUND,
              },
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${selectedElementPath.value}.overlay`,
              valueSource: selectedField.value,
              options: {
                label: "Blackout",
                type: ColorPickerType.BACKGROUND,
              },
            },
          ],
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${selectedElementPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${selectedElementPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [{ label: "Hover content", value: "hover_content" }],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${selectedElementPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${selectedElementPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ];
    }
  });

  const casinoListFavIconControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.default_value",
        valueSource: selectedField.value,
        className: "event-cards-radio border-bottom-none p-t-0",
        options: {
          items: [
            {
              icon: "custom:casino-list-star",
              value: "ant-design:star-outlined",
            },
            {
              icon: "custom:casino-list-heart",
              value: "ant-design:heart-outlined",
            },
            {
              icon: "custom:casino-list-tag",
              value: "ant-design:tag-outlined",
            },
          ],
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: selectedField.value,
        className: "border-bottom-none p-t-0",
        options: {
          label: "Icon color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.iconSize",
        valueSource: selectedField.value,
        className: "border-bottom-none p-l-16 p-r-16",
        options: {
          label: "Size",
          minValue: MIN_ICON_SIZE_INPUT_VALUE,
          maxValue: MAX_ICON_SIZE_INPUT_VALUE,
        },
      },
    ];
  });

  // promo_tag
  const casinoListGameTagButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs", "ConfigBoxShadow"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListGameTagButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            valueSource: selectedField.value,
          },
        ],
      },
    ];
  });

  const casinoListGameTagButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.tag_promo,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.tag_jackpot,
              value: State.JACKPOT,
            },
            {
              label: STATE_KEY_LABELS.tag_new,
              value: State.NEW,
            },
            {
              label: STATE_KEY_LABELS.tag_hot,
              value: State.HOT,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // hover_content
  const casinoListHoverContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.position",
            valueSource: selectedField.value,
            options: {
              label: "Title position",
              items: [
                {
                  label: "Top",
                  value: TextPosition.TOP,
                },
                {
                  label: "Bottom",
                  value: TextPosition.BOTTOM,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Buttons layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Game Name Hover",
              value: "game_title",
              isActiveValueSource: fields.value.game_title.field.options,
            },
            { label: "Play for money button", value: "play_money_button" },
            { label: "Play for free button", value: "play_free_button" },
          ],
        },
      },
    ];
  });

  // game_title
  const casinoListGameTitleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  // play_money_button
  const casinoListPlayMoneyButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListPlayMoneyButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListPlayMoneyButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // play_free_button
  const casinoListPlayFreeButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListPlayFreeButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListPlayFreeButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const {
    promoGroupListDesignControls,
    promoGroupListContentControls,

    promoCardsDesignControls,
    promoCardsContentControls,
    promoCardsStatesControls,

    promoImageControls,

    promoTitleControls,

    promoButtonGroupDesignControls,
    promoButtonGroupContentControls,
    promoButtonDesignControls,
    promoButtonContentControls,
    promoButtonStatesControls,
  } = usePromoGroupControls(widget, states);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    backButtonDesignControls,
    backButtonContentControls,
    backButtonStateControls,

    headerDesignControls,
    headerContentControls,

    pageTitleDesignControls,
    pageTitleContentControls,

    searchResultDesignControls,
    searchResultContentControls,

    sortingDesignControls,
    sortingContentControls,
    sortingStateControls,

    searchDesignControls,
    searchContentControls,
    searchStateControls,

    gamesListDesignControls,
    gamesListContentControls,

    gamesCategoryDesignControls,
    gamesCategoryContentControls,
    gamesCategoryStateControls,

    categoryTitleDesignControls,
    categoryTitleContentControls,
    categoryTitleStateControls,

    categoryButtonDesignControls,
    categoryButtonContentControls,
    categoryButtonStateControls,

    categoryHoverButtonDesignControls,
    categoryHoverButtonContentControls,
    categoryHoverButtonStateControls,

    categoryListDesignControls,
    categoryListContentControls,

    allGamesDesignControls,
    allGamesContentControls,

    allGamesTitleDesignControls,
    allGamesTitleContentControls,

    allGamesListDesignControls,
    allGamesListContentControls,

    showMoreButtonDesignControls,
    showMoreButtonContentControls,
    showMoreButtonStateControls,

    gameNameControls,

    gameCardsDesignControls,
    gameCardsContentControls,

    gameCoverDesignControls,
    gameCoverContentControls,
    gameCoverStatesControls,

    casinoListFavIconControls,

    casinoListGameTagButtonDesignControls,
    casinoListGameTagButtonContentControls,
    casinoListGameTagButtonStateControls,

    casinoListHoverContentControls,

    casinoListGameTitleControls,

    casinoListPlayMoneyButtonDesignControls,
    casinoListPlayMoneyButtonContentControls,
    casinoListPlayMoneyButtonStateControls,

    casinoListPlayFreeButtonDesignControls,
    casinoListPlayFreeButtonContentControls,
    casinoListPlayFreeButtonStateControls,

    promoGroupListDesignControls,
    promoGroupListContentControls,

    promoCardsDesignControls,
    promoCardsContentControls,
    promoCardsStatesControls,

    promoImageControls,

    promoTitleControls,

    promoButtonGroupDesignControls,
    promoButtonGroupContentControls,
    promoButtonDesignControls,
    promoButtonContentControls,
    promoButtonStatesControls,
  };
};
