<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #betting_constructor_iframe>
          <p class="p-l-16 p-t-16 p-r-16 p-b-16">
            The functionality for creating a bet will be added here
          </p>
        </template>

        <template #live_header-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #live_header-content>
          <ControlsList
            :widget="widget"
            :controls="liveHeaderContentControls"
          />
        </template>

        <template #live_header_back_button>
          <ControlsList
            :widget="widget"
            :controls="backButtonControls"
          />
        </template>

        <template #live_header_sport_league_name>
          <ControlsList
            :widget="widget"
            :controls="sportLeagueNameControls"
          />
        </template>

        <template #live_header_view_mode_switch-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #live_header_view_mode_switch-content>
          <ControlsList
            :widget="widget"
            :controls="viewModeSwitchContentControls"
          />
        </template>

        <template #live_header_view_mode_switch-states>
          <ControlsList
            :widget="widget"
            :controls="viewModeSwitchStatesControls"
          />
        </template>

        <template #betting_constructor-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #betting_constructor-content>
          <ControlsList
            :widget="widget"
            :controls="bettingConstructorContentControls"
          />
        </template>

        <template #betting_constructor_header-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #betting_constructor_header-content>
          <ControlsList
            :widget="widget"
            :controls="constructorHeaderTextContentControls"
          />
        </template>

        <template #betting_constructor_header-states>
          <ControlsList
            :widget="widget"
            :controls="constructorHeaderStatesControls"
          />
        </template>

        <template #betting_constructor_content-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #betting_constructor_content-content>
          <ControlsList
            :widget="widget"
            :controls="constructorHeaderContentControls"
          />
        </template>

        <template #betting_constructor_description>
          <ControlsList
            :widget="widget"
            :controls="constructorDescriptionControls"
          />
        </template>

        <template #markets_container-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #markets_container-content>
          <ControlsList
            :widget="widget"
            :controls="marketsContainerContentControls"
          />
        </template>

        <template #markets_cards-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #markets_cards-content>
          <ControlsList
            :widget="widget"
            :controls="marketCardsContentControls"
          />
        </template>

        <template #markets_market_name>
          <ControlsList
            :widget="widget"
            :controls="marketNameControls"
          />
        </template>

        <template #markets_status-design>
          <ControlsList
            :widget="widget"
            :controls="marketSuspendedStatusDesignControls"
          />
        </template>

        <template #markets_status-content>
          <ControlsList
            :widget="widget"
            :controls="marketSuspendedStatusContentControls"
          />
        </template>

        <template #markets_pin_icon>
          <ControlsList
            :widget="widget"
            :controls="pinIconControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #racing_event_info-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #racing_event_info-content>
          <ControlsList
            :widget="widget"
            :controls="racingLiveHeaderContentControls"
          />
        </template>

        <template #racing_title>
          <ControlsList
            :widget="widget"
            :controls="racingTitleControls"
          />
        </template>

        <template #racing_description>
          <ControlsList
            :widget="widget"
            :controls="racingDescriptionControls"
          />
        </template>

        <template #racing_players_info-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #racing_players_info-content>
          <ControlsList
            :widget="widget"
            :controls="playersInfoContentControls"
          />
        </template>

        <template #racing_header_card-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #racing_header_card-content>
          <ControlsList
            :widget="widget"
            :controls="racingHeaderCardContentControls"
          />
        </template>

        <template #racing_player_cards-design>
          <ControlsList
            :widget="widget"
            :controls="defaultDesignControls"
          />
        </template>

        <template #racing_player_cards-content>
          <ControlsList
            :widget="widget"
            :controls="playerCardsContentControls"
          />
        </template>

        <template #racing_player_cards-states>
          <ControlsList
            :widget="widget"
            :controls="playerCardsStatesControls"
          />
        </template>

        <template #racing_number>
          <ControlsList
            :widget="widget"
            :controls="playerNumberControls"
          />
        </template>

        <template #racing_player_name>
          <ControlsList
            :widget="widget"
            :controls="playerTextControls"
          />
        </template>

        <template #racing_last_5_races>
          <ControlsList
            :widget="widget"
            :controls="playerTextControls"
          />
        </template>

        <template #racing_prev_odds>
          <ControlsList
            :widget="widget"
            :controls="playerTextControls"
          />
        </template>

        <template #racing_odds-design>
          <ControlsList
            :widget="widget"
            :controls="oddsDesignControls"
          />
        </template>

        <template #racing_odds-content>
          <ControlsList
            :widget="widget"
            :controls="oddsContentControls"
          />
        </template>
        <template #racing_odds-states>
          <ControlsList
            :widget="widget"
            :controls="oddsStateControls"
          />
        </template>

        <template #no_events_title>
          <ControlsList
            :widget="widget"
            :controls="noEventsTextControls"
          />
        </template>

        <template #live_banner-design>
          <ControlsList
            :widget="widget"
            :controls="bannerDesignControls"
          />
        </template>

        <template #live_banner-content>
          <ControlsList
            :widget="widget"
            :controls="bannerContentControls"
          />
        </template>

        <template #live_banner_event_info-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_event_info-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventInfoContentControls"
          />
        </template>

        <template #live_banner_event_info_name>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventNameControls"
          />
        </template>

        <template #live_banner_event_info_score-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_event_info_score-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventScoreContentControls"
          />
        </template>

        <template #live_banner_header-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_header-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerHeaderContentControls"
          />
        </template>

        <template #live_banner_header_statistics-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_header_statistics-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerStatisticsContentControls"
          />
        </template>

        <template #live_banner_header_titles-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_header_titles-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerTitlesContentControls"
          />
        </template>

        <template #live_banner_header_value_titles-design>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesDesignControls"
          />
        </template>

        <template #live_banner_header_value_titles-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesContentControls"
          />
        </template>

        <template #live_banner_header_point_title-design>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesDesignControls"
          />
        </template>

        <template #live_banner_header_point_title-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesContentControls"
          />
        </template>

        <template #live_banner_rows-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_rows-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerRowsContentControls"
          />
        </template>

        <template #live_banner_rows_team_player-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_rows_team_player-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerStatisticsContentControls"
          />
        </template>

        <template #live_banner_rows_team_player_info-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_rows_team_player_info-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerRowsTeamPlayerInfoContentControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_values-design>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesDesignControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_values-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesContentControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_values-states>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesStateControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_point-design>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesDesignControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_point-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerValuesTitlesContentControls"
          />
        </template>

        <template #live_banner_rows_team_player_info_point-states>
          <ControlsList
            :widget="widget"
            :controls="liveBannerRowsTeamPlayerInfoPointsStateControls"
          />
        </template>

        <template #live_banner_footer-design>
          <ControlsList
            :widget="widget"
            :controls="bannerEventInfoDesignControls"
          />
        </template>

        <template #live_banner_footer-content>
          <ControlsList
            :widget="widget"
            :controls="liveBannerFooterContentControls"
          />
        </template>

        <template #live_banner_footer_time>
          <ControlsList
            :widget="widget"
            :controls="liveBannerFooterElementsControls"
          />
        </template>

        <template #live_banner_footer_period>
          <ControlsList
            :widget="widget"
            :controls="liveBannerFooterElementsControls"
          />
        </template>

        <template #live_banner_login_text>
          <ControlsList
            :widget="widget"
            :controls="liveBannerLoginTextControls"
          />
        </template>

        <template #live_banner_login_button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #live_banner_login_button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #live_banner_login_button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #prematch_banner-design>
          <ControlsList
            :widget="widget"
            :controls="bannerDesignControls"
          />
        </template>

        <template #prematch_banner-content>
          <ControlsList
            :widget="widget"
            :controls="prematchBannerContentControls"
          />
        </template>

        <template #racing_banner-design>
          <ControlsList
            :widget="widget"
            :controls="bannerDesignControls"
          />
        </template>

        <template #racing_banner-content>
          <ControlsList
            :widget="widget"
            :controls="bannerContentControls"
          />
        </template>

        <template #prematch_banner_event_title>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventNameControls"
          />
        </template>

        <template #prematch_banner_event_time>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventNameControls"
          />
        </template>

        <template #racing_banner_event_description>
          <ControlsList
            :widget="widget"
            :controls="liveBannerEventNameControls"
          />
        </template>

        <template #racing_banner_login_button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #racing_banner_login_button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #racing_banner_login_button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #racing_banner_login_text>
          <ControlsList
            :widget="widget"
            :controls="liveBannerLoginTextControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/sport-event-fenix/useSportEventConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: string }): void;
  (e: "reset-states"): void;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "pageType": {
      label: "Page type",
      description: "Possible values: prematch | live | racing",
      sources: ["custom"],
      required: false,
    },
    "eventId": {
      label: "Event ID",
      sources: ["pageRoute", "custom"],
      required: true,
    },
    "leaguePageURL": {
      label: "League page URL",
      sources: ["custom"],
      required: false,
    },
    "sportId": {
      label: "Sport ID",
      sources: ["custom", "pageRoute"],
      required: false,
    },
    "leagueId": {
      label: "league ID",
      sources: ["pageRoute"],
      required: false,
    },
    "countryId": {
      label: "Country ID",
      sources: ["pageRoute"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  defaultDesignControls,
  liveHeaderContentControls,

  backButtonControls,

  sportLeagueNameControls,

  viewModeSwitchContentControls,
  viewModeSwitchStatesControls,

  bettingConstructorContentControls,

  constructorHeaderTextContentControls,
  constructorHeaderStatesControls,
  constructorHeaderContentControls,

  constructorDescriptionControls,

  marketsContainerContentControls,

  marketCardsContentControls,

  marketNameControls,

  marketSuspendedStatusDesignControls,

  marketSuspendedStatusContentControls,

  pinIconControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  /* 
  Racing
  */

  racingLiveHeaderContentControls,

  racingTitleControls,

  racingDescriptionControls,

  playersInfoContentControls,

  racingHeaderCardContentControls,

  playerCardsContentControls,

  playerCardsStatesControls,

  playerNumberControls,

  playerTextControls,

  oddsDesignControls,

  oddsContentControls,
  oddsStateControls,

  noEventsTextControls,

  bannerDesignControls,
  bannerContentControls,

  bannerEventInfoDesignControls,

  liveBannerEventInfoContentControls,
  liveBannerEventNameControls,
  liveBannerEventScoreContentControls,
  liveBannerHeaderContentControls,

  liveBannerStatisticsContentControls,
  liveBannerTitlesContentControls,

  liveBannerValuesTitlesDesignControls,
  liveBannerValuesTitlesContentControls,

  liveBannerRowsContentControls,
  liveBannerRowsTeamPlayerInfoContentControls,

  liveBannerFooterContentControls,
  liveBannerLoginTextControls,

  buttonStatesControls,
  buttonDesignControls,
  buttonContentControls,
  liveBannerFooterElementsControls,
  prematchBannerContentControls,

  liveBannerValuesTitlesStateControls,
  liveBannerRowsTeamPlayerInfoPointsStateControls,
} = useConfig(ref(props.widget), toRef(props.states), emit);
</script>
