<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PromotedBetsListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="promoted-bets-widget">
      <WidgetField
        :widget="widget"
        :field="fields.event_card.field"
      >
        <PromoCardsContainer :field="fields.event_card.field">
          <PromoCard
            v-for="(card, index) in CARDS"
            :key="index"
            :field="fields.event_card.field"
            :event-info-field="fields.event_info.field"
            :state="states.event_card"
            :widget="widget"
          >
            <template #photo>
              <WidgetField
                :widget="widget"
                :field="fields.event_image.field"
              >
                <BonusesPhoto :field="fields.event_image.field">
                  <template #default="{ styles }">
                    <img
                      :src="card.img"
                      :style="styles"
                      draggable="false"
                    />
                  </template>
                </BonusesPhoto>
              </WidgetField>
            </template>

            <template #event_info>
              <WidgetField
                :widget="widget"
                :field="fields.event_name.field"
              >
                <BasicTitleField
                  :field="fields.event_name.field"
                  value="Team / Player #1 Team / Player #2"
                />
              </WidgetField>

              <WidgetField
                :widget="widget"
                :field="fields.event_date_time.field"
              >
                <div
                  :style="timeContainerStyle"
                  class="promoted-bets-widget__time"
                >
                  <SportsBookLiveTime
                    :field="fields.event_date_time.field"
                    :icon="fields.event_date_time.field.options.icon.dateIcon"
                  >
                    00-00-0000
                  </SportsBookLiveTime>

                  <SportsBookLiveTime
                    :field="fields.event_date_time.field"
                    :icon="fields.event_date_time.field.options.icon.timeIcon"
                  >
                    00:00
                  </SportsBookLiveTime>
                </div>
              </WidgetField>
            </template>

            <template #bet_items>
              <WidgetField
                :widget="widget"
                :field="fields.bet_items.field"
              >
                <div
                  :style="betItemsContainerStyles"
                  class="promoted-bets-widget__bet-items"
                >
                  <div
                    v-for="(inner, index2) in card.bet_items"
                    :key="index2"
                    class="promoted-bets-widget__item"
                    :style="betItemContainerStyles"
                  >
                    <SportsbookBetItem
                      :item-value="inner"
                      :state-options="currentStateOptions"
                      :bet-item-field="fields.bet_items.field"
                    />
                  </div>
                </div>
              </WidgetField>
            </template>

            <template #button>
              <WidgetField
                :widget="widget"
                :field="fields.event_button.field"
              >
                <BasicButtonField
                  :field="fields.event_button.field"
                  :current-state="states.event_button"
                />
              </WidgetField>
            </template>
          </PromoCard>
        </PromoCardsContainer>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/promoted-bets-list/usePromotedBetsInitData";
import { useStates } from "~~/composables/widgets/common/useStates";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexPosition } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const CARDS = [
  {
    img: "/images/bets/promo_bet_1.jpg",
    bet_items: [
      { name: "1", coef: "0.00" },
      { name: "X", coef: "0.00" },
      { name: "2", coef: "0.00" },
    ],
  },
  {
    img: "/images/bets/promo_bet_2.jpg",
    bet_items: [
      { name: "1", coef: "0.00" },
      { name: "X", coef: "0.00" },
      { name: "2", coef: "0.00" },
    ],
  },
  {
    img: "/images/bets/promo_bet_3.jpg",
    bet_items: [
      { name: "1", coef: "0.00" },
      { name: "X", coef: "0.00" },
      { name: "2", coef: "0.00" },
    ],
  },
];

const timeContainerStyle = computed<ElementStyle>(() => {
  const alignment = fields.value.event_date_time.field.options.alignment;

  return {
    justifyContent: getFlexPosition(alignment),
  };
});

const betItemContainerStyles = computed<ElementStyle>(() => {
  if (
    fields.value.bet_items.field.options.displayMode ===
    DisplayOrientation.HORIZONTAL
  ) {
    return {};
  }

  return {
    width: "100%",
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (states.value.bet_items === State.DEFAULT) {
    return fields.value.bet_items.field.options;
  }

  return fields.value.bet_items.field.options.states[states.value.bet_items];
});

const betItemsContainerStyles = computed<ElementStyle>(() => {
  const direction =
    fields.value.bet_items.field.options.displayMode ===
    DisplayOrientation.VERTICAL
      ? "row"
      : "column";

  return {
    gap: getPxValueFromNumber(fields.value.bet_items.field.options.distance),
    "grid-template-columns":
      direction === "row" ? "1fr" : "repeat(auto-fit, minmax(0px, 1fr))",
    "grid-auto-flow": direction,
  };
});

initFields();
</script>

<style lang="scss">
.promoted-bets-widget {
  overflow: hidden;
  &__cards-radio {
    .config-radio-icons__icon-wrapper {
      padding: 8px 0;
      flex-grow: 1;
    }
  }

  &__time {
    @include flex(center, flex-start, 8px);
  }

  &__bet-items {
    display: grid;
  }

  &__item {
    line-height: 1;
  }
}
</style>
