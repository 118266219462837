import type { Ref } from "vue";

import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";
import { useTitleControls } from "../dropdown/useTitleControls";
import { useFavoritesEventsLiveWidgetControls } from "../favorites-events-live/useFavoritesEventsLiveWidgetControls";

export const useFavoritesEventsLiveFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Heading", "heading"),
    generateDropdownItem("Events", "event_container"),
    generateDropdownItem("Sport event cards", "event_card"),
    generateDropdownItem("Header", "header"),
    generateDropdownItem("Footer", "footer"),
    generateDropdownItem("Favorite Icon", "favourites_button"),
    generateDropdownItem("League Name", "event_league"),
    generateDropdownItem("Status", "event_suspended_status"),
    generateDropdownItem("Stream Icon", "stream_icon"),
    generateDropdownItem("Event Name", "event_team_player"),
    generateDropdownItem("Time", "event_time"),
    generateDropdownItem("Period", "event_period"),
    generateDropdownItem("Score", "event_score"),
    generateDropdownItem("Racing event cards ", "racing_event_card"),
    generateDropdownItem("Stream Icon", "racing_stream_icon"),
    generateDropdownItem("Event Name", "racing_event_name"),
    generateDropdownItem("Race Status", "racing_event_status"),
    generateDropdownItem("Favorite Icon", "racing_favourites_icon"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    heading: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    footer: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_suspended_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_event_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_event_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  } as Record<string, TabItem[]>;

  const widgetContentItems = [
    {
      label: "Heading",
      value: "heading",
    },
    {
      label: "Events",
      value: "event_container",
    },
  ];

  const { widgetDesignControls, widgetContentControls } =
    useFavoritesEventsLiveWidgetControls(widget, widgetContentItems);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    toRef(() => fields.value.heading.field),
    [FieldContentItem.TEXT, FieldContentItem.ICON, FieldContentItem.ARROW]
  );

  const headingContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: "options.text",
        valueSource: fields.value.heading.field,
        options: {
          label: "Text",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
          text: fields.value.heading.field?.value,
          hideSwitch: true,
          "onUpdate:text": (value: string) => {
            fields.value.heading.field.value = value;
          },
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: fields.value.heading.field,
        options: {
          label: "Icon",
          isBold: true,
          hideSwitch: true,
          hidePicker: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrow",
        valueSource: fields.value.heading.field,
        options: {
          label: "Arrow",
          isBold: true,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ARROW).value,
        },
      },
    ];
  });

  const { titleDesignControls: headingDesignControls, titleStatesControls } =
    useTitleControls(
      toRef(() => states.value.heading),
      toRef(() => fields.value.heading.field),
      emit
    );

  const headingStatesControls = computed(() => {
    const result = titleStatesControls.value;
    result[0].onUpdate = (value: State) => {
      emit("update-state", { state: fields.value.heading.field.name, value });
    };

    return result;
  });

  const currentField = computed<IWidgetField | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return fields.value?.[selectedField.value.name].field;
  });

  const currentStatePath = computed(() => {
    if (!selectedField.value) {
      return "";
    }

    const currState = states.value?.[selectedField.value.name];

    if (currState === State.DEFAULT) {
      return `options`;
    }

    return `options.states.${currState}`;
  });

  const stateValue = computed<State | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return states.value[selectedField.value.name];
  });

  const sportEventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const sportEventCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layoutMode",
            valueSource: currentField.value,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: SportMenuLiveBet9CardLayoutMode.FIRST,
                },
                {
                  label: "Second",
                  value: SportMenuLiveBet9CardLayoutMode.SECOND,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header",
              value: "header",
            },
            {
              label: "Footer",
              value: "footer",
            },
          ],
        },
      },
    ];
  });

  const sportEventCardsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: stateValue.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      section: generateDesignSectionName("Card"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: currentField.value,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: currentField.value,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentStatePath.value}.shadow`,
          valueSource: currentField.value,
          options: {
            label: "Shadow",
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Header"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.header.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Footer"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.footer.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("League"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_league.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Status"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_suspended_status.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Stream icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.stream_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Event Name"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_team_player.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Time"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_time.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.icon.color`,
          valueSource: fields.value.event_time.field,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Period"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_period.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Favorite icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.favourites_button.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Score"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.event_score.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.event_score.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const headerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const isEventCardLayoutFirstMode = computed(
    () =>
      fields.value.event_card.field.options.layoutMode ===
      SportMenuLiveBet9CardLayoutMode.FIRST
  );

  const isEventCardHeaderVertical = computed(
    () =>
      fields.value.header.field.options.layout === DisplayOrientation.VERTICAL
  );

  const headerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: currentField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: `options.leagueName${
              isEventCardHeaderVertical.value ? "Vertical" : "Horizontal"
            }Position`,
            valueSource: currentField.value,
            options: {
              label: "League name position",
              items: isEventCardHeaderVertical.value
                ? [
                    {
                      label: "Top",
                      value: "top",
                    },
                    {
                      label: "Bottom",
                      value: "bottom",
                    },
                  ]
                : [
                    {
                      label: "Left",
                      value: "left",
                    },
                    {
                      label: "Right",
                      value: "right",
                    },
                  ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.favoriteIconAlignment",
            valueSource: currentField.value,
            options: {
              label: "Favorite icon alignment",
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League Name",
              value: "event_league",
              isActiveValueSource: fields.value.event_league.field.options,
            },
            {
              label: "Favorite icon",
              value: "favourites_button",
              isActiveValueSource: fields.value.favourites_button.field.options,
            },
            {
              label: "Stream Icon",
              value: "stream_icon",
              isActiveValueSource: fields.value.stream_icon.field.options,
            },
            ...(isEventCardLayoutFirstMode.value
              ? [
                  {
                    label: "Status",
                    value: "event_suspended_status",
                  },
                  {
                    label: "Event name",
                    value: "event_team_player",
                  },
                ]
              : [
                  {
                    label: "Period",
                    value: "event_period",
                    isActiveValueSource:
                      fields.value.event_period.field.options,
                  },
                  {
                    label: "Time",
                    value: "event_time",
                  },
                  {
                    label: "Status",
                    value: "event_suspended_status",
                  },
                ]),
          ],
        },
      },
    ];
  });

  const footerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const footerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: currentField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            ...(isEventCardLayoutFirstMode.value
              ? [
                  {
                    label: "Start Time",
                    value: "event_time",
                  },
                  {
                    label: "Period",
                    value: "event_period",
                    isActiveValueSource:
                      fields.value.event_period.field.options,
                  },
                ]
              : [
                  {
                    label: "Event Name",
                    value: "event_team_player",
                  },
                ]),

            {
              label: "Score",
              value: "event_score",
              isActiveValueSource: fields.value.event_score.field.options,
            },
          ],
        },
      },
    ];
  });

  const favIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.favourites_button.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: fields.value.favourites_button.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const leagueNameControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: "fill",
                },
                {
                  label: "Hug content",
                  value: "hug",
                },
              ],
            },
          },
        ],
      },
      typography({
        source: currentField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventSuspendedStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: currentField.value,
      except: ["ConfigBoxShadow"],
    });
  });

  const eventSuspendedStatusContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_suspended_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const streamIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const eventNameControls = computed(() => [
    typography({ source: currentField.value }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: currentField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const eventTimeControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.icon._active",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Icon",
              allowDisable: true,
            },
          },
          ...(currentField.value?.options.icon._active
            ? [
                {
                  componentPath: "ConfigIconPicker",
                  valuePath: "options.icon.value",
                  className: "p-l-16 p-r-16 p-t-16",
                  valueSource: currentField.value,
                },

                {
                  componentPath: "ConfigColorPickerInput",
                  valuePath: "options.icon.color",
                  valueSource: currentField.value,
                  options: {
                    placeholder: "Enter text",
                    label: "Icon color",
                    type: ColorPickerType.TEXT,
                  },
                },

                {
                  componentPath: "common/ConfigRangeSlider",
                  valuePath: "options.icon.size",
                  className: "p-l-16 p-r-16 p-t-16",
                  valueSource: currentField.value,
                  options: {
                    label: "Size",
                    minValue: MIN_ICON_SIZE_INPUT_VALUE,
                    maxValue: MAX_ICON_SIZE_INPUT_VALUE,
                  },
                },
              ]
            : []),
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventPeriodControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: "fill",
                },
                {
                  label: "Hug content",
                  value: "hug",
                },
              ],
            },
          },
        ],
      },
      typography({
        source: currentField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventScoreDesignControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: currentField.value,
        except: ["ConfigBoxShadow"],
      }),
    ];
  });

  const eventScoreContentControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const racingEventCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const racingEventCardsContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Stream Icon",
              value: "racing_stream_icon",
              isActiveValueSource:
                fields.value.racing_stream_icon.field.options,
            },
            {
              label: "Event Name",
              value: "racing_event_name",
            },
            {
              label: "Race Status",
              value: "racing_event_status",
            },
            {
              label: "Favorite icon",
              value: "racing_favourites_icon",
              isActiveValueSource:
                fields.value.racing_favourites_icon.field.options,
            },
          ],
        },
      },
    ];
  });

  const racingEventCardsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: stateValue.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      section: generateDesignSectionName("Card"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: currentField.value,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: currentField.value,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentStatePath.value}.shadow`,
          valueSource: currentField.value,
          options: {
            label: "Shadow",
            isBold: false,
          },
        },
      ],
    },

    {
      section: generateDesignSectionName("Status"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.fill`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            label: "Fill",
            type: ColorPickerType.BACKGROUND,
            isBold: false,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentStatePath.value}.border`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            label: "Border",
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_event_status.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Stream icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_stream_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Event Name"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_event_name.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Favorite icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentStatePath.value}.color`,
          valueSource: fields.value.racing_favourites_icon.field,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const racingStreamIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const racingEventNameControls = computed(() => [
    typography({ source: currentField.value }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: currentField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const racingEventStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: currentField.value,
      except: ["ConfigBoxShadow"],
    });
  });

  const racingEventStatusContentControls = computed(() => {
    return [
      typography({
        source: currentField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const racingFavIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: currentField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const menuItemDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const isEventsCardsContentMode = computed<boolean>(() => {
    return states.value.event_container === State.ACTIVE_EVENTS;
  });

  const menuItemSportNoContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        visible: !isEventsCardsContentMode.value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.no_event_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      typography({
        source: fields.value.no_event_title.field,
        visible: !isEventsCardsContentMode.value,
      }),
    ];
  });

  const menuItemContentControls = computed(() => {
    const baseControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.event_container,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS[State.WITH_FAVORITE_BETS],
              value: State.ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS[State.NO_FAVORITE_BETS],
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "event_container",
            value,
          });
        },
      },
    ];

    if (states.value.event_container === State.NO_ACTIVE) {
      return [...baseControls, ...menuItemSportNoContentControls.value];
    }

    return [
      ...baseControls,
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.columns",
            valueSource: currentField.value,
            className: "event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distance",
            valueSource: currentField.value,
            options: {
              label: "Distance between cards",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Sport event cards",
              value: "event_card",
            },
            {
              label: "Racing event cards",
              value: "racing_event_card",
            },
          ],
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    headingContentControls,
    headingDesignControls,
    headingStatesControls,

    sportEventCardsDesignControls,
    sportEventCardsContentControls,
    sportEventCardsStatesControls,

    headerDesignControls,
    headerContentControls,

    footerDesignControls,
    footerContentControls,

    favIconControls,

    leagueNameControls,

    eventSuspendedStatusDesignControls,
    eventSuspendedStatusContentControls,

    streamIconControls,

    eventNameControls,

    eventTimeControls,

    eventPeriodControls,

    eventScoreDesignControls,
    eventScoreContentControls,

    racingEventCardsDesignControls,
    racingEventCardsContentControls,
    racingEventCardsStatesControls,

    racingStreamIconControls,

    racingEventNameControls,

    racingEventStatusDesignControls,
    racingEventStatusContentControls,

    racingFavIconControls,

    menuItemDesignControls,
    menuItemContentControls,
  };
};
