<template>
  <div class="promo-group">
    <WidgetField
      :widget="widget"
      :field="groupListField"
      :style="promoGroupListStyles"
      class="promo-group__field"
    >
      <PromoGroupCard
        v-for="idx in cardsCount"
        :key="idx"
        :widget="widget"
        :field="groupCardsField"
        :field-state="states[groupCardsField.name]"
        :image-field="cardImageField"
        :title-field="cardTitleField"
        :description-field="cardDescriptionField"
        :secondary-description-field="cardSecondaryDescriptionField"
        :button-group-field="cardButtonGroupField"
        :button1-field="cardButton1Field"
        :button2-field="cardButton2Field"
        :image-value="`${imageValuePath}${idx}.${imageFormat}`"
        :button-state-1="states[cardButton1Field.name]"
        :button-state-2="states[cardButton2Field.name]"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  groupListField: IWidgetField;
  groupCardsField: IWidgetField;
  cardImageField: IWidgetField;
  cardTitleField: IWidgetField;
  cardDescriptionField: IWidgetField;
  cardSecondaryDescriptionField?: IWidgetField;
  cardButtonGroupField: IWidgetField;
  cardButton1Field: IWidgetField;
  cardButton2Field: IWidgetField;
  states: Record<string, State>;
  cardsCount: number;
  imageValuePath: string;
  imageFormat: string;
  imageValue?: string;
  titleValue?: string;
  descriptionValue?: string;
  button1Value?: string;
  button2Value?: string;
}>();

const promoGroupListStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.groupListField.options,
    }),
    ...getDisplayGridStyles(props.groupListField.options.gridSettings),
  };
});
</script>

<style lang="scss"></style>
