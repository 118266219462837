import type { Ref } from "vue";

import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { IInsertPosition } from "~~/models/grid.interface";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { IFields } from "../useWidgetFields";

import { useBalanceFields } from "./useBalanceFields";

export const useBalanceControls = (
  fields: Ref<IFields>,
  balanceItemsState: Ref<State>,
  emit: any
) => {
  const balanceDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.balance.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.balance.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const { balanceFields } = useBalanceFields(fields);

  const widgetSettingsStore = useWidgetSettingsStore();

  const balanceContentControls = computed(() => [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigDisplayOrientation",
          valuePath: "options.layout",
          valueSource: fields.value.balance.field,
          options: {
            isBold: false,
          },
        },
        {
          componentPath: "ConfigColumnWidth",
          valuePath: "options.gap",
          valueSource: fields.value.balance.field,
          className: "group-control-element",
          options: {
            label: "Distance between items",
            showIconPrefix: false,
            showSuffix: true,
            suffix: "px",
          },
        },
      ],
    },
    {
      componentPath: "ConfigArrayItems",
      options: {
        items: balanceFields.value,
        hideCRUD: true,
        label: "Items",
        optionsClickHandler: () => {
          widgetSettingsStore.setActiveElement(
            widgetSettingsStore.selectedWidget,
            fields.value.balance.field,
            widgetSettingsStore.selectedCell || undefined,
            "balanceItems"
          );
        },
        reorderHandler: (
          targetId: number | string,
          insertPosition: IInsertPosition
        ): void => {
          const [currFields] = reorderListElements(
            "id",
            targetId,
            insertPosition,
            balanceFields.value
          );

          currFields.forEach((field, index) => {
            field.options._position = index;
          });
        },
      },
    },
  ]);

  const balanceItemsDesignControls = computed(() => [
    {
      componentPath: "ConfigFontFamilySelect",
      valuePath: "options.fontFamily",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "Select",
        label: "Font family",
        isBold: true,
      },
    },
    {
      section: generateDesignSectionName("Secondary text style"),
      controls: [
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.text.theme",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },

        {
          componentPath: "ConfigDecoration",
          valuePath: "options.text.decoration",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.text.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Secondary text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Primary text style"),
      controls: [
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.primaryText.theme",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },

        {
          componentPath: "ConfigDecoration",
          valuePath: "options.primaryText.decoration",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.primaryText.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Primary text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "common/ConfigRangeSlider",
          valuePath: "options.icon.size",
          className: "p-l-16 p-r-16 p-t-16",
          valueSource: fields.value.balance.field,
          options: {
            label: "Size",
            minValue: MIN_ICON_SIZE_INPUT_VALUE,
            maxValue: MAX_ICON_SIZE_INPUT_VALUE,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.icon.color",
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      componentPath: "ConfigVerticalSpace",
      valuePath: "options.gap",
      valueSource: fields.value.balance.field,
      options: {
        placeholder: "None",
        label: "Distance between items",
        isBold: true,
        notRotateIcon: true,
      },
    },
  ]);

  const balanceSettingsValuePath = computed<string>(() => {
    if (balanceItemsState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${balanceItemsState.value}`;
  });

  const balanceItemsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: balanceItemsState.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        emit(`update-balance-items-state`, value);
      },
    },
    {
      section: generateDesignSectionName("Secondary text style"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${balanceSettingsValuePath.value}.text.color`,
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: `${balanceSettingsValuePath.value}.text.decoration`,
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Primary text style"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${balanceSettingsValuePath.value}.primaryText.color`,
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: `${balanceSettingsValuePath.value}.primaryText.decoration`,
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${balanceSettingsValuePath.value}.icon.color`,
          valueSource: fields.value.balance.field,
          options: {
            placeholder: "Enter text",
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  return {
    balanceDesignControls,
    balanceContentControls,

    balanceItemsDesignControls,
    balanceItemsStatesControls,
  };
};
