import { type Ref } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { IPageWidgetDetails, InnerFieldValue } from "~~/models/page.model";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import { FORM_INITIAL } from "~~/constants/widget-details/form-initial-options";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  IMAGE_INITIAL,
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import {
  INITIAL_STATES,
  DYNAMIC_FIELD_OPTIONS,
} from "~~/constants/widget-details/form/dynamic-fields";
import { State } from "~~/models/widgets/widget-controls.model";
import { FormElementName, FormScreen } from "~~/models/widgets/form.model";
import {
  generateFormFields,
  getFormElements,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { getDefaultFillImage } from "~~/constants/configs/common/widget-initial";
import { DEFAULT_FORM_WIDTH } from "~~/constants/widget-config";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useMetaStore } from "~~/store/meta";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { SUCCESS_MESSAGE_INITIAL } from "~~/constants/widget-details/form-initial-options";

export const useForm = (
  widget: Ref<IWidgetWithFields>,
  screens: string[],
  formName: string,
  customGetFormElements?: (widget: IWidgetWithFields) => IWidgetField[]
) => {
  const widgetsStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const metaStore = useMetaStore();
  const { initWidgetOptions } = useCreateFields(widget.value);

  const activeView = ref("form");

  const handleUpdateActiveView = (value: string): void => {
    activeView.value = value;
  };

  const fields = useWidgetFields(widget.value);

  const currentStates = ref<Record<string, string>>({});

  const { resetStates, handleStateUpdate } = useStates({
    defaultStates: INITIAL_STATES,
    states: currentStates,
  });

  const { widgetsDetailsCache } = storeToRefs(widgetsStore);
  const { selectedDropdownItem, selectedWidget } =
    storeToRefs(widgetSettingsStore);

  const isWholeWidgetSelected = computed(() => {
    if (selectedWidget.value?.id !== widget.value.id) {
      return false;
    }

    return !!STYLING_FIELDS[selectedDropdownItem.value as string];
  });

  const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
    () => {
      return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
        widget.value.static_id
      ];
    }
  );

  let formInnerFields: IWidgetField[] = [];
  let customWidgetFields: IWidgetField[] = [];

  if (!widget.value.options || !Object.keys(widget.value.options).length) {
    formInnerFields = generateFormFields(
      widgetCacheData.value?.fields?.[formName] as FormFieldDetails | undefined,
      widget.value
    );
  } else {
    customWidgetFields = prefillCustomFields(
      widget.value.options,
      widget.value.id
    );
  }

  const initialStateOptions = screens.reduce((res, curr) => {
    return {
      ...res,
      [curr]: {
        contentWidth: {
          type: "fixed",
          width: DEFAULT_FORM_WIDTH,
        },
      },
    };
  }, {});

  // const initialWidgetOptions = prefillEmptyOptions(
  //   widget.value.options,
  //   getInitialOptions({
  //     data: WIDGET_INITIAL(),
  //     exclude: ["fill"],
  //     additionalData: {
  //       options: {
  //         fillImageColor: getDefaultFillImage(),
  //         verticalSpace: 0,
  //         states: {
  //           [State.FORM_DEFAULT]: {
  //             contentWidth: { type: "fixed", width: DEFAULT_FORM_WIDTH },
  //           },
  //           ...initialStateOptions,
  //         },
  //       },
  //     },
  //   }).options
  // );

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    verticalSpace: 0,
    states: {
      [State.FORM_DEFAULT]: {
        contentWidth: { type: "fixed", width: DEFAULT_FORM_WIDTH },
      },
      ...initialStateOptions,
    },
  });

  const initialFormOptions = prefillEmptyOptions(
    fields.value.form.field.options,
    FORM_INITIAL(widgetCacheData.value?.fields?.[formName]).options
  );

  widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  widgetsStore.updateFieldOptions(fields.value.form.field, initialFormOptions);

  const initStates = () => {
    for (const stateName in INITIAL_STATES) {
      currentStates.value[stateName] = INITIAL_STATES[stateName];
    }
  };

  const initFormElementsOptions = () => {
    widget.value.fields.forEach(field => {
      if (!DYNAMIC_FIELD_OPTIONS[field.type]) {
        return;
      }

      const cacheFieldData = (
        widgetCacheData.value?.fields?.[formName]?.value as InnerFieldValue
      )?.fields?.[field.name];

      const initial = DYNAMIC_FIELD_OPTIONS[field.type]({
        ...field,
        placeholder: field.placeholder || cacheFieldData?.placeholder || "",
        label: field.title || cacheFieldData?.label || "",
        options: field.options,
      });

      widgetsStore.updateFieldOptions(field, initial.options);
    });
  };

  initFormElementsOptions();
  initStates();

  const formFields =
    customGetFormElements?.(widget.value) ||
    getFormElements(widget.value.fields);

  if (formFields[0]?.options.position === undefined) {
    // TODO: move this logic to back
    const submitBtnIndex = formFields.findIndex(
      item => item.type === "ButtonLinkField"
    );
    if (![formFields.length - 1, -1].includes(submitBtnIndex)) {
      formFields.push(formFields.splice(submitBtnIndex, 1)[0]);
    }

    formFields.forEach((field, index) => {
      field.options.position = index;
    });
  }

  let fieldsResult: Array<IWidgetField> = [];

  if (!widget.value.fields.find(field => field.options._custom)) {
    fieldsResult = [
      ...widget.value.fields,
      ...formInnerFields,
      ...customWidgetFields,
    ];
  } else {
    fieldsResult = [...widget.value.fields, ...formInnerFields];
  }

  widgetsStore.updateWidgetFields(widget.value, fieldsResult);

  if (fields.value.success_message?.field) {
    const initialSuccessMessageOptions = prefillEmptyOptions(
      fields.value.success_message.field.options,
      SUCCESS_MESSAGE_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      fields.value.success_message.field,
      initialSuccessMessageOptions
    );
  }

  if (screens.includes(FormScreen.SUCCESS)) {
    const initialSuccessImageOptions = prefillEmptyOptions(
      fields.value.success_image.field.options,
      IMAGE_INITIAL().options
    );

    const initialSuccessTitleOptions = prefillEmptyOptions(
      fields.value.success_title.field.options,
      TITLE_INITIAL().options
    );

    const initialSuccessDescriptionptions = prefillEmptyOptions(
      fields.value.success_description_1.field.options,
      DESCRIPTION_INITIAL().options
    );

    const initialSuccessButtonOptions = prefillEmptyOptions(
      fields.value.success_button.field.options,
      BUTTON_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      fields.value.success_image.field,
      initialSuccessImageOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.success_title.field,
      initialSuccessTitleOptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.success_description_1.field,
      initialSuccessDescriptionptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.success_button.field,
      initialSuccessButtonOptions
    );
  }

  if (screens.includes(FormScreen.EXPIRED)) {
    const initialImageOptions = prefillEmptyOptions(
      fields.value.expired_image.field.options,
      IMAGE_INITIAL().options
    );

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.expired_title.field.options,
      TITLE_INITIAL().options
    );

    const initialDescriptionptions = prefillEmptyOptions(
      fields.value.expired_description_1.field.options,
      DESCRIPTION_INITIAL().options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.expired_button.field.options,
      BUTTON_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      fields.value.expired_image.field,
      initialImageOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.expired_title.field,
      initialTitleOptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.expired_description_1.field,
      initialDescriptionptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.expired_button.field,
      initialButtonOptions
    );
  }

  const handleUpdateButtonState = (value: State) => {
    handleStateUpdate({
      state: FormElementName.BUTTON,
      value,
    });
  };

  const handleResetStates = () => {
    resetStates();
  };
  return {
    fields,
    currentStates,
    isWholeWidgetSelected,
    handleUpdateButtonState,
    handleResetStates,
    handleUpdateActiveView,
    activeView,
    handleStateUpdate,
  };
};
