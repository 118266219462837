import { ComputedRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
  ICustomField,
} from "~~/models/widgets/widget.core/widget.model";

export interface IFields {
  [key: string]: {
    field: IWidgetField;
    active: boolean;
  };
}

export function useWidgetFields(
  widget: IWidgetWithFields | ICustomField
): ComputedRef<IFields> {
  return computed<IFields>(() => {
    const fields: IFields = {};

    widget?.fields?.forEach(field => {
      const fieldRef = ref<IWidgetField>(field);
      const isActive = ref<boolean>(fieldRef.value?.options._active || false);
      fields[field.name] = {
        field: fieldRef.value,
        active: isActive.value,
      };
    });

    return fields;
  });
}
