import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { BUTTON_INITIAL as BUTTON_INITIAL_VALUE } from "~~/constants/configs/card-common/initial-constants";

export const BUTTON_INITIAL = (fieldDetails?: { options?: IWidgetOptions }) => {
  const initial = BUTTON_INITIAL_VALUE();

  if (fieldDetails?.options && Object.keys(fieldDetails.options).length) {
    return {
      options: { ...initial.options, ...fieldDetails.options },
    };
  }

  return {
    options: initial.options,
  };
};
