<template>
  <div class="template-status f-small">
    <span class="template-status__el template-status__draft">Draft</span>

    <!-- <span class="template-status__el template-status__saved"> Saved </span> -->
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
$block-border-radius: 6px;

.template-status {
  &__el {
    display: inline-block;
    padding: 5px 22px;
    border: 1px solid transparent;
  }

  &__draft {
    color: $c-primary-base;
    border-radius: $block-border-radius;
    background-color: $c-light-blue;

    &--active {
      border-color: $c-primary-base;
    }
  }

  &__saved {
    color: $c-green;
    background-color: $c-light-green;
    border-radius: 0px $block-border-radius $block-border-radius 0px;

    &--active {
      border-color: $c-light-green;
    }
  }
}
</style>
