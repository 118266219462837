<template>
  <PageLoader :handler="loadData">
    <ConfigHeader>
      <template #input>
        <ResizableInput
          ref="input"
          placeholder="Template Name"
          :validation-tooltip="TEMPLATE_NAME_VALIDATION"
          :model-value="templateName"
          @update:model-value="handleTemplateNameChange"
        />
      </template>
    </ConfigHeader>

    <div class="grid-config">
      <ConfigSideMenu v-if="!isLayoutSaved && isTemplateEditable">
        <template #title>
          <h3
            v-translate:grid="'Layout'"
            class="sub-heading"
          ></h3>
        </template>
        <template #content>
          <a-tooltip placement="bottom">
            <template
              v-if="hasWidthSettingsErrors"
              #title
            >
              <span>You cannot modify the grid with errors</span>
            </template>
            <div class="grid-config__layouts">
              <GridLayoutOption
                v-for="option in LAYOUTS"
                :key="option"
                :is-disabled="hasWidthSettingsErrors"
                :columns="option"
              ></GridLayoutOption>
            </div>
          </a-tooltip>
        </template>
      </ConfigSideMenu>

      <CommonSpinner v-if="loading" />

      <div @click="handleWorkspaceClick">
        <ConstructorWorkspace
          :inert="!isTemplateEditable || isLayoutSaved"
          @drop="handleBlockDrop"
          @over="handleDragOver"
          @enter="checkDimensions"
          @update-container-position="handleUpdateContainerPosition"
        />
      </div>

      <GridSavedSidebar v-if="isLayoutSaved" />

      <GridSettingsSidebar v-else-if="isTemplateEditable" />

      <GridWithPagesSidebar v-else />
    </div>
  </PageLoader>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";

import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { useGridDnd } from "~~/components/composables/grid/useGridDnd";
import { useFetchTranslations } from "~~/composables/translations/useFetchTranslations";
import { LAYOUTS, DEFAULT_LAYOUT_NAME } from "~~/constants/layouts";
import { TEMPLATE_NAME_VALIDATION } from "~~/constants/name-validations";
import { generateInitiaLayout } from "~~/assets/utils/grid";
import { Step } from "~~/models/step.enum";
import { TemplateQueryType } from "~~/models/grid.interface";
import { TemplateStatus } from "~~/models/stores/grid-store.model";
import { TranslationCategory } from "~~/models/stores/static-translation-store.model";
import { useLanguagesStore } from "~~/store/languages";

const store = useGridConfig();
const {
  handleBlockDrop,
  handleDragOver,
  handleUpdateContainerPosition,
  checkDimensions,
} = useGridDnd();

const uiStore = useUiStore();

const languagesStore = useLanguagesStore();

const {
  isLayoutSaved,
  templateName,
  isTemplateEditable,
  hasWidthSettingsErrors,
} = storeToRefs(store);

const loading = ref(false);
const input = ref<ComponentPublicInstance<{ focusInput: () => void }> | null>(
  null
);

const prevTemplateName = ref<string>("");

const handleWorkspaceClick = (): void => {
  if (!hasWidthSettingsErrors.value) {
    store.setSelectedCell(null);
  }
};

const handleTemplateNameChange = (value: string): void => {
  store.setTemplateName(value);
};

const loadData = async () => {
  store.changeTemplateEditability(true);

  uiStore.switchTo(Step.LAYOUTS);

  const route = useRoute();

  if (languagesStore.currentLanguage) {
    useFetchTranslations(
      [TranslationCategory.GRID],
      languagesStore.currentLanguage
    );
  }

  store.setTemplateId(route.query.id as TemplateQueryType);

  await nextTick();

  const draft = store.getGridLayoutDraft();

  /*
    Fill template with default values
    if there is no templateId and draft
    (case when user creates template)
  */
  if (!store.templateId && !draft) {
    store.setTemplateName(DEFAULT_LAYOUT_NAME);
    store.updateGrid(generateInitiaLayout());

    input.value?.focusInput();
    prevTemplateName.value = templateName.value;

    return;
  }

  store.checkTemplateEditability();

  if (draft) {
    store.updateGridWithMeta(draft);
    store.setTemplateStatus(TemplateStatus.DRAFT);
    prevTemplateName.value = templateName.value;

    return;
  }

  loading.value = true;
  store
    .fetchGridLayout()
    .then(gridWithMeta => {
      store.updateGridWithMeta(gridWithMeta);
      prevTemplateName.value = templateName.value;
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style lang="scss">
.grid-config {
  &__message {
    padding: $space-s;
  }

  &__layouts {
    padding-top: $space-l;
    @include flex-column(center, flex-start, $space-l);
  }
}
</style>
