import { CSSProperties } from "vue";

import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getResizingHeightStyles,
  getTextPosition,
} from "~~/assets/utils/widget-settings";

import { clearObject } from "../../PromoBetsFenixWidgetHelpers";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useCardsStyles = (cardsOptions: any = {}): CSSProperties => {
  const size = cardsOptions.size?.height
    ? getResizingHeightStyles(cardsOptions.size?.height)
    : {};
  const layoutPosition = cardsOptions.layoutPosition?.position
    ? getTextPosition(cardsOptions.layoutPosition?.position)
    : {};

  const styles = {
    ...useDesignInitialStyles(cardsOptions),

    ...size,
    ...layoutPosition,
    ...(cardsOptions.layoutAlignment || {}),
    gap: cardsOptions.gap ? getPxValueFromNumber(cardsOptions.gap) : undefined,
  };

  return clearObject(styles);
};
