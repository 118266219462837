import { ComputedRef } from "vue";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const widgetSettingsStore = useWidgetSettingsStore();

export function getCurrentField(
  fieldName?: string
): ComputedRef<IWidgetField | undefined> {
  return computed(() => {
    if (widgetSettingsStore.selectedField) {
      return widgetSettingsStore.selectedField;
    }

    return (widgetSettingsStore.selectedWidget?.fields || []).find(
      field => field.name === fieldName
    );
  });
}
