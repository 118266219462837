import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Page Title", "title"),
    generateDropdownItem("Filter", "filter"),
    generateDropdownItem("Filter Title", "filter_title"),
    generateDropdownItem("Description", "pending_description"),

    generateDropdownItem("Filter Container", "form"),
    generateDropdownItem("Date Filter", "date_filter", "form"),
    generateDropdownItem("Date Title", "date_filter_title", "form"),
    generateDropdownItem("Transactions Filter", "transactions_filter", "form"),
    generateDropdownItem(
      "Transactions Title",
      "transactions_filter_title",
      "form"
    ),

    generateDropdownItem("Transaction info", "table"),
    generateDropdownItem("Header settings", "table_header_settings", "table"),
    generateDropdownItem("Rows settings", "table_row_settings", "table"),
    generateDropdownItem("Checkboxes", "table_checkboxes_settings", "table"),

    generateDropdownItem("From Date", "date_from", "filter"),
    generateDropdownItem("To Date", "date_to", "filter"),
    generateDropdownItem("Transaction types", "transaction_types", "filter"),
    generateDropdownItem(
      "Transaction subtypes",
      "transaction_subtypes",
      "filter"
    ),
    generateDropdownItem("Cancel button", "action_button", "pending"),
    generateDropdownItem("Fail Message", "pending_error_text", "pending"),

    generateDropdownItem("Data", "table_data", "table"),
    generateDropdownItem("Date", "table_date", "table"),
    generateDropdownItem("Reference", "table_reference", "table"),
    generateDropdownItem("Description", "table_description", "table"),
    generateDropdownItem("Parameters", "table_parameters", "table"),
    generateDropdownItem("Transaction type", "table_transaction_type", "table"),
    generateDropdownItem("Amount", "table_amount", "table"),
    generateDropdownItem("Status", "table_status", "table"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    filter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    table_checkboxes_settings: [
      generateTabItem("Design", "design"),
      generateTabItem("States", "states"),
    ],
    table_header_settings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    table_row_settings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    table_data: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    table_parameters: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    date_filter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    transactions_filter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    form: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
    ],
    table: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    action_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pending_error_text: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
