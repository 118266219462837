<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div class="text-widget">
      <TextWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
        :description-fields="descriptionFields"
        :title-link-state="states.title"
        @update-title-link-state="
          handleStateUpdate({ state: 'title', value: $event })
        "
        @reset-states="resetStates"
      />

      <div
        class="text-widget__content"
        :style="containerStyles"
        :class="{
          'text-widget--top':
            textPositionInWidget?.position === TextPosition.TOP,
          'text-widget--left':
            textPositionInWidget?.position === TextPosition.LEFT,
          'text-widget--right':
            textPositionInWidget?.position === TextPosition.RIGHT,
        }"
      >
        <WidgetField
          v-if="fields.title.active"
          :widget="widget"
          :field="fields.title.field"
          class="text-widget__field"
          :style="titleStyles"
        >
          <BasicTitleField
            :field="fields.title.field"
            :state="states.title"
          />
        </WidgetField>

        <WidgetField
          v-if="fields[PRIMARY_DESCRIPTION_FIELD_NAME].active"
          :widget="widget"
          :field="fields[PRIMARY_DESCRIPTION_FIELD_NAME].field"
          :style="descriptionStyles"
          class="text-widget__field"
        >
          <BasicDescriptionField
            ref="descriptionField"
            :fields="descriptionFields"
            :widget="widget"
          />
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  TextPositionComplex,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { VerticalAlignment } from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  TITLE_INITIAL,
  DESCRIPTION_INITIAL,
} from "~~/composables/widgets/text/useTextWidgetConfig";
import { PRIMARY_DESCRIPTION_FIELD_NAME } from "~~/assets/utils/widget/fields/description";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { useTextPositionStyles } from "~~/composables/fields/description/useTextPositionStyles";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();
const widgetsStore = useWidgetsStore();

const fields = useWidgetFields(props.widget);
const { selectedWidget } = storeToRefs(widgetSettingsStore);
const { initWidgetOptions } = useCreateFields(props.widget);

const DEFAULT_STATES = {
  title: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const descriptionFields = computed(() => {
  return props.widget.fields.filter(field =>
    field.name.includes("description")
  );
});

const textPositionInWidget = computed<TextPositionComplex>(() => {
  return props.widget.options.textPosition;
});
const { titleStyles, descriptionStyles, containerStyles } =
  useTextPositionStyles(textPositionInWidget);

/*
  Initialize widget with initial options
*/

const initialWidgetOptions = initWidgetOptions([], {
  textPosition: {
    position: "top",
    scale: 50,
    alignment: VerticalAlignment.TOP,
  },
});

const initialTitleOptions = prefillEmptyOptions(
  fields.value.title.field.options,
  TITLE_INITIAL().options
);

const initialDescriptionOptions = prefillEmptyOptions(
  fields.value.description_1.field.options,
  DESCRIPTION_INITIAL().options
);

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
widgetsStore.updateFieldOptions(fields.value.title.field, initialTitleOptions);
widgetsStore.updateFieldOptions(
  fields.value.description_1.field,
  initialDescriptionOptions
);
</script>

<style lang="scss">
$text-widget: "text-widget";

.#{$text-widget} {
  &__content {
    @include flex(flex-start, flex-start, 0);
  }

  // &__link-settings-color {
  //   border-bottom: unset;
  //   padding-bottom: 0;
  // }

  &__field {
    flex-grow: 1;
  }

  &__description-typography {
    border-bottom: 1px solid $c-grey-15;

    .widget-control-container {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }

  &--top {
    flex-direction: column;
    align-items: stretch;

    .#{$text-widget}__field {
      width: 100%;
    }
  }

  &--right {
    flex-direction: row-reverse;
  }
}
</style>
