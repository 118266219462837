import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import {
  SPORTS_MENU_WIDGET_INITIAL,
  MENU_ITEM_INITIAL,
} from "~~/constants/configs/sports-menu/initial-constants";
import { iconMapping } from "~~/constants/icons-mapping";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["fill"], {
    ...SPORTS_MENU_WIDGET_INITIAL().options,
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        color: {
          color: "#ffffff",
          opacity: 100,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 12,
          left: 12,
        },
      },
    });

    addFieldInitialOptions("menu_level_1", {
      data: MENU_ITEM_INITIAL(),
      values: {
        verticalSpace: 0,
      },
      additionalData: {
        options: {
          displayMode: DisplayOrientation.VERTICAL,
          elementsDisplayMode: DisplayOrientation.HORIZONTAL,
        },
      },
    });

    addFieldInitialOptions("menu_level_2", {
      data: MENU_ITEM_INITIAL(),
      values: {
        verticalSpace: 0,
      },
      additionalData: {
        options: {
          displayMode: DisplayOrientation.VERTICAL,
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  const getIcon = (icon: string): string => {
    return iconMapping[icon];
  };

  const itemFirstLevelIcon = computed<string | null>(() => {
    return getIcon(fields.value.menu_level_1.field.options.arrowSettings.value);
  });

  const itemSecondLevelIcon = computed<string | null>(() => {
    return getIcon(
      fields.value.menu_level_2?.field.options.arrowSettings.value
    );
  });

  return {
    itemFirstLevelIcon,
    itemSecondLevelIcon,
    fields,

    init,
  };
};
