import { type Ref, computed } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useItems } from "~~/composables/widgets/bonuses/useBonusesItems";
import { Sizing } from "~~/models/grid.interface";
import { useTabsConfig } from "~~/composables/widgets/common/useTabsConfig";
import { useCardsConfig } from "~~/composables/widgets/bonuses/useCardsConfig";
import { useBonusesStatusConfig } from "~~/composables/widgets/bonuses/useBonusesStatusConfig";
import { useBonusesTextConfig } from "~~/composables/widgets/bonuses/useBonusesTextConfig";
import { useBonusesProgressConfig } from "~~/composables/widgets/bonuses/useBonusesProgressConfig";
import { useBonusesTermsConfig } from "~~/composables/widgets/bonuses/useBonusesTermsConfig";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { useBonusesModalConfig } from "~~/composables/widgets/bonuses/useBonusesModalConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

const CARDS_CONFIG_TABS = [
  {
    label: "Photo",
    value: "image",
  },
  {
    label: "Title",
    value: "bonus_title",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Dates",
    value: "dates",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Progress bar",
    value: "progress_bar",
  },
  {
    label: "Amounts",
    value: "amounts",
  },
  {
    label: "Terms and conditions",
    value: "terms_title",
  },
  {
    label: "Button",
    value: "action_button",
  },
];

const CARD_TEXT_FIELDS = ["bonus_title", "terms_title", "description"];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems();

  const { tabsDesignControls, tabsContentControls, tabsStatesControls } =
    useTabsConfig(
      {
        widget: widget,
        states,
        stateName: "tabs",
        fieldName: "tabs",
      },
      emit
    );

  const { cardsDesignControls, cardsContentControls, cardsStatesControls } =
    useCardsConfig(
      {
        widget: widget,
        states,
        stateName: "cards",
        screenStateName: "screen",
        fieldName: "cards",
        configTabsList: CARDS_CONFIG_TABS,
      },
      emit
    );

  const { statusControls } = useBonusesStatusConfig(widget);
  const { textControls } = useBonusesTextConfig();
  const { progressControls } = useBonusesProgressConfig(widget);
  const { flexibleImageControls: imageControls } = useFlexibleImageControls(
    toRef(fields.value.image.field)
  );
  const { termsDesignControls, termsStatesControls } = useBonusesTermsConfig(
    {
      widget: widget,
      states,
      stateName: "terms_title",
    },
    emit
  );

  const actionButtonState = computed<State>(() => {
    return states.value.action_button as State;
  });

  const {
    buttonStatesControls: actionButtonStatesControls,
    buttonDesignControls: actionButtonDesignControls,
    buttonContentControls: actionButtonContentControls,
  } = useSimpleButtonControls(actionButtonState, "action_button", emit);

  const {
    modalControls,
    modalContentControls,

    primaryButtonStatesControls,
    primaryButtonDesignControls,
    primaryButtonContentControls,

    secondaryButtonStatesControls,
    secondaryButtonDesignControls,
    secondaryButtonContentControls,

    closeButtonStatesControls,
    closeButtonDesignControls,
    closeButtonContentControls,
  } = useBonusesModalConfig(
    {
      widget: widget,
      states,
      primaryBtnStateName: "modal_primary_btn",
      secondaryBtnStateName: "modal_secondary_btn",
      closeBtnStateName: "modal_close_icon",
    },
    emit
  );

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const showModalControls = computed(() => {
    return states.value.screen === "modal";
  });

  const widgetContentModalScreenControls = computed(() => {
    return [
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: fields.value.modal.field,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: fields.value.modal.field,
        className: "group-control-element--child",
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "modal",
              label: "Modal settings",
            },
          ],
        },
      },
    ];
  });

  const widgetMainScreenContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  const widgetContentControls = computed(() => {
    const baseControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.screen,
        options: {
          items: [
            {
              label: "Bonuses page",
              value: State.ACTIVE_EVENTS,
            },
            {
              label: "Modal",
              value: "modal",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "screen", value });
        },
      },
    ];

    if (showModalControls.value) {
      return [...baseControls, ...widgetContentModalScreenControls.value];
    }

    return [...baseControls, ...widgetMainScreenContentControls.value];
  });

  /* 
   Widget title
  */

  const defaultTextControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          isBold: true,
        },
      },
      ...defaultTextControls.value,
    ];
  });

  /* 
    Update alignment option for text fiels on card alignment update
  */
  watch(
    () => {
      return fields.value.cards.field.options.alignment;
    },
    newValue => {
      CARD_TEXT_FIELDS.forEach(fieldName => {
        widgetsStore.updateFieldOptionValue(
          newValue,
          "alignment",
          fields.value[fieldName].field.id
        );
      });
    }
  );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,
    defaultTextControls,

    /* 
      Tabs
    */
    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,

    /* 
    Cards  
   */

    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,

    /* 
      Bonus
    */

    statusControls,
    textControls,
    progressControls,

    /* 
      Terms
    */
    termsDesignControls,
    termsStatesControls,

    /* 
      Action button
    */

    actionButtonStatesControls,
    actionButtonDesignControls,
    actionButtonContentControls,

    /* 
      Image
    */

    imageControls,

    /* Modal */
    modalControls,
    modalContentControls,

    primaryButtonStatesControls,
    primaryButtonDesignControls,
    primaryButtonContentControls,

    secondaryButtonStatesControls,
    secondaryButtonDesignControls,
    secondaryButtonContentControls,

    closeButtonStatesControls,
    closeButtonDesignControls,
    closeButtonContentControls,
  };
};
