<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #game_cards-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesDesignControls"
          />
        </template>

        <template #game_cards-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesContentControls"
          />
        </template>

        <template #game_cards-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesStatesControls"
          />
        </template>

        <template #photo>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesPhotoControls"
          />
        </template>

        <template #cards_elements>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesCardsElementsControls"
          />
        </template>

        <template #hover_content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesHoverContentControls"
          />
        </template>

        <template #game_title>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTitleControls"
          />
        </template>

        <template #gameTitleLinkStates>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTitleLinkControls"
          />
        </template>

        <template #play_money_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayMoneyButtonDesignControls"
          />
        </template>

        <template #play_money_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayMoneyButtonContentControls"
          />
        </template>

        <template #play_money_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayMoneyButtonStateControls"
          />
        </template>

        <template #play_free_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayFreeButtonDesignControls"
          />
        </template>

        <template #play_free_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayFreeButtonContentControls"
          />
        </template>

        <template #play_free_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamePlayFreeButtonStateControls"
          />
        </template>

        <template #favorite_icon>
          <ControlsList
            :widget="widget"
            :controls="casinoListGamesFavIconControls"
          />
        </template>

        <template #promo_tag-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonDesignControls"
          />
        </template>

        <template #promo_tag-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonContentControls"
          />
        </template>

        <template #promo_tag-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonStateControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useCasinoListConfig } from "~~/composables/widgets/casino/list/useCasinoListConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "oneGameForMoneyPageUrl": {
      label: "One game for money page URL",
      sources: ["custom"],
      required: true,
    },
    "oneGameForFunPageUrl": {
      label: "One game for fun page URL",
      sources: ["custom"],
      required: true,
    },
    "dataSourceMode": {
      label: "Data source mode",
      description: "Possible values: Default | Recommendations",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  casinoListGamesDesignControls,
  casinoListGamesContentControls,
  casinoListGamesStatesControls,

  casinoListGamesPhotoControls,
  casinoListGamesCardsElementsControls,

  casinoListGamesHoverContentControls,

  casinoListGameTitleControls,
  casinoListGameTitleLinkControls,

  casinoListGamePlayMoneyButtonDesignControls,
  casinoListGamePlayMoneyButtonContentControls,
  casinoListGamePlayMoneyButtonStateControls,

  casinoListGamePlayFreeButtonDesignControls,
  casinoListGamePlayFreeButtonContentControls,
  casinoListGamePlayFreeButtonStateControls,

  casinoListGamesFavIconControls,

  casinoListGameTagButtonDesignControls,
  casinoListGameTagButtonContentControls,
  casinoListGameTagButtonStateControls,
} = useCasinoListConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
