import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { generateStageButtonCssString } from "~~/assets/utils/widget-css/utils/form-helper-functions";

import { generateClassName } from "../../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../../compiler/default-css-compiler";

export const generateWidgetStyles = (options: IWidgetOptions) => {
  return (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content: generateDefaultStyles(options),
    });
    return cssString;
  };
};

export const generateButtonCssString = (element: IWidgetField) => {
  return (cssString: string): string => {
    cssString += generateStageButtonCssString(element)("");
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: `align-self: unset !important;
      white-space: nowrap;`,
    });

    return cssString;
  };
};

export const generateButtonWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}_content`);

  const button = widget.content["button"];

  button.options._cssClass = generateClassName("button");
  button.options.iconSettings._cssClass = generateClassName("buttonIcon");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateButtonCssString(button as IWidgetField)
  )("");
};
