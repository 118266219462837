<template>
  <WidgetField
    :field="fields.provider_card.field"
    :widget="widget"
    class="payment-providers-list-fenix__item"
    :class="[`payment-providers-list-fenix__item--${layoutPosition.position}`]"
    :style="cardItemStyle"
  >
    <FlexibleImageField
      v-if="fields.provider_image.active"
      class="payment-providers-list-fenix__field w-100"
      :field="fields.provider_image.field"
      :widget="widget"
      :value="`/payment-providers/${itemIndex}.png`"
    />

    <div
      class="w-100 payment-providers-list-fenix__content"
      :style="cardGapStyles"
    >
      <WidgetField
        v-if="fields.provider_name.active"
        :widget="widget"
        :field="fields.provider_name.field"
        :style="cardsTitleInlineStyles"
        class="payment-providers-list-fenix__title-field-container"
      >
        <BasicTitleField
          :field="fields.provider_name.field"
          value="Provider Name"
          class="payment-providers-list-fenix__title-field"
        />

        <div
          v-if="
            selectedDropdownItem === fields.tooltip.field.name &&
            itemIndex === 1
          "
          class="payment-providers-list-fenix__tooltip-icon"
        >
          <a-tooltip
            :overlay-style="tooltipStyles"
            :visible="
              selectedDropdownItem === fields.tooltip.field.name &&
              itemIndex === 1
            "
            :get-popup-container="getMenuContainer"
            placement="bottom"
            overlay-class-name="payment-providers-list-fenix__tooltip-container"
          >
            <template #title> Tooltip text </template>

            <p v-if="tooltipIconComponent">
              <CommonIcon
                :name="tooltipIconComponent"
                :style="tooltipIconColorStyle"
              />
            </p>
          </a-tooltip>
        </div>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.provider_description.field"
        class="payment-providers-list-fenix__field"
      >
        <BasicTitleField
          :field="fields.provider_description.field"
          :value="providerDescriptionText"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.provider_button.active"
        :widget="widget"
        :field="fields.provider_button.field"
        class="payment-providers-list-fenix__field"
      >
        <BasicButtonField
          :field="fields.provider_button.field"
          :current-state="states.provider_button"
        />
      </WidgetField>
    </div>
  </WidgetField>
</template>

<script lang="ts" setup>
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontSizeStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle, StyleValue } from "~~/models/common";
import {
  IFillControl,
  SpacingKeyName,
  State,
  TextPosition,
  TextPositionComplex,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: IFields;
  itemIndex: number;
  states: Record<string, State>;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedDropdownItem } = storeToRefs(widgetSettingsStore);

const providerDescriptionText = computed<string>(
  () => `Min amount: 1 USD <br />
Max amount: 10 000 USD`
);

const getMenuContainer = (trigger: HTMLElement): HTMLElement | null => {
  return trigger.parentElement;
};

const tooltipField = computed<IWidgetField | undefined>(() => {
  return props.fields.tooltip.field;
});

const tooltipStyles = computed<ElementStyle>(() => {
  if (!tooltipField.value) {
    return {};
  }

  const textColor = getColorFromHex(
    tooltipField.value.options.color
  ) as StyleValue;

  const styles = {
    ...getSpacing(tooltipField.value.options.padding, SpacingKeyName.PADDING),
    ...getCornerRadiusStyle(tooltipField.value.options.cornerRadius),
    ...getBackgroundColorStyle(tooltipField.value.options.fill),
    ...getBorderStyle(tooltipField.value.options.border),
    ...getBoxShadowStyle(tooltipField.value.options.shadow),
    ...getFontSizeStyle(tooltipField.value.options.theme),
    ...getDecorationValue(tooltipField.value.options.decoration),
    textColor,
  };

  return getStylesAsVars(styles as never);
});

const layoutPosition = computed<TextPositionComplex>(() => {
  return props.fields.provider_card.field.options.layoutPosition;
});

const cardsTitleInlineStyles = computed<ElementStyle>(() => {
  return {
    justifyContent: props.fields.provider_name.field.options.alignment,
    ...getSpacing(
      props.fields.provider_name.field.options.padding,
      SpacingKeyName.PADDING
    ),
    gap: getPxValueFromNumber(
      props.fields.tooltip.field.options.textChooseIcon.gap
    ),
  };
});

const tooltipIconOptions = computed<{
  icon: string;
  color: IFillControl;
} | null>(() => {
  if (!tooltipField.value) {
    return null;
  }

  return tooltipField.value.options.textChooseIcon;
});

const tooltipIconComponent = computed<string | null>(() => {
  if (!tooltipIconOptions.value) {
    return null;
  }

  return "ant-design:question-circle-outlined";
});

const tooltipIconColorStyle = computed<ElementStyle>(() => {
  if (!tooltipIconOptions.value || !tooltipField.value) {
    return {};
  }

  return {
    ...getColorStyle(tooltipIconOptions.value.color),
  };
});

const activeCardStateOptions = computed(() => {
  if (props.states.provider_card === State.DEFAULT) {
    return props.fields.provider_card.field.options;
  }

  return props.fields.provider_card.field.options.states[
    props.states.provider_card
  ];
});

const cardGapStyles = computed<ElementStyle>(() => {
  const cardsOptions = props.fields.provider_card.field.options;
  return {
    gap: getPxValueFromNumber(cardsOptions.gap),
    justifyContent: layoutPosition.value.alignment || "unset",
  };
});

const cardItemStyle = computed<ElementStyle>(() => {
  const cardsOptions = props.fields.provider_card.field.options;
  const fill = getBackgroundColorStyle(activeCardStateOptions.value.fill);
  const border = getBorderStyle(activeCardStateOptions.value.border);
  const shadow = getBoxShadowStyle(activeCardStateOptions.value.shadow);
  const cornerRadius = getCornerRadiusStyle(cardsOptions.cornerRadius);
  const padding = getSpacing(cardsOptions.padding, SpacingKeyName.PADDING);
  const gap = { gap: getPxValueFromNumber(cardsOptions.gap) };

  const stylesObj: ElementStyle = {
    ...padding,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...fill,
    ...gap,
  };

  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return stylesObj;
  } else {
    stylesObj.alignItems = layoutPosition.value.alignment || undefined;
  }

  return stylesObj;
});
</script>

<style lang="scss">
.payment-providers-list-fenix {
  &__item {
    @include flex-column(center, space-around, 0);
    align-self: stretch;

    &--top {
      flex-direction: column-reverse;
    }

    &--right {
      flex-direction: row;
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    flex: 1;
  }

  &__field {
    width: 100%;
  }

  &__title-field-container {
    width: 100%;
    @include flex(center, flex-start, 0);
  }

  &__title-field {
    .basic-title-widget__content {
      padding: 0 !important;
    }
  }

  &__tooltip-icon {
    line-height: 14px;
    height: 14px;
  }

  &__tooltip-container {
    font-size: 12px;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
  }
}
</style>
