import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  ResizingType,
  SportMenuLiveBet9CardLayoutMode,
  State,
  TextStyle,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetBindingParamsObject,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useBetsListFenixMobileWidgetInitData = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { parentCell } = useWidthConfig();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    widget: State.WITH_ACTIVE_EVENTS,
    event_card: State.DEFAULT,
    sports: State.DEFAULT,
    title: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
    sports_event_button: State.DEFAULT,
    hover_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {
      maxEvents: {
        source: "custom",
        value: "5",
      },
      sorting: {
        source: "custom",
        value: "by_popular",
      },
      eventsInterval: {
        source: "custom",
        value: "24",
      },
      sportIds: {
        source: "custom",
        value: [],
      },
    } as unknown as IWidgetBindingParamsObject,
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing.margin"],
        additionalData: {
          options: {
            spaceBetween: 4,
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            color: getDefaultFillValue("#000000"),
            iconSettings: {
              textColor: getDefaultFillValue("#000000"),
              iconSize: 16,
              _active: true,
            },
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
                color: getDefaultFillValue("#000000"),
                iconSettings: {
                  textColor: getDefaultFillValue("#000000"),
                },
              },
            },
          },
        },
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialEventCardOptions = prefillEmptyOptions(
      fields.value.event_card.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            spacing: {
              padding: getDefaultSpacing(12),
              margin: getDefaultSpacing(),
            },
            fill: getDefaultFillValue("#ffffff"),
            layoutMode: SportMenuLiveBet9CardLayoutMode.FIRST,
            spaceBetween: 12,
            columns: 1,
            containerSpaceBetween: 12,
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue("#ffffff"),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
              [State.ACTIVE]: {
                fill: getDefaultFillValue("#ffffff"),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
              [State.DISABLED]: {
                fill: getDefaultFillValue("#ffffff"),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
            },
          },
        },
      }).options
    );

    const initialTimeDateOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            width: getDefaultSizeValue(ResizingType.HUG),
            icon: {
              _active: true,
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
              valueDate: "ant-design:calendar-filled",
              valueTime: "ant-design:clock-circle-filled",
            },
            spaceBetween: 12,
            displayMode: "date",
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            spaceBetween: 8,
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing({
              top: 5,
              right: 8,
              bottom: 5,
              left: 8,
            }),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            layout: "horizontal",
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        },
      }).options
    );

    const initialEventButtonState = () => ({
      fill: getDefaultFillValue(),
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
      color: getDefaultFillValue("#000000"),
      icon: {
        textColor: getDefaultFillValue("#000000"),
      },
    });

    const initialEventButtonOptions = prefillEmptyOptions(
      fields.value.event_button.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            display: {
              resizing: "hug",
              alignment: "left",
            },
            text: {
              _active: false,
            },
            icon: {
              _active: true,
              value: "ant-design:right-outlined",
              textColor: getDefaultFillValue("#000000"),
              iconSize: 20,
              position: Alignment.RIGHT,
              gap: 8,
            },
            states: {
              [State.HOVER]: initialEventButtonState(),
              [State.ACTIVE]: initialEventButtonState(),
              [State.DISABLED]: initialEventButtonState(),
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(4),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialHoverButtonOptions = prefillEmptyOptions(
      fields.value.hover_button.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            display: {
              resizing: "hug",
              alignment: "left",
            },
            text: {
              _active: true,
            },
            icon: {
              _active: false,
              value: "ant-design:right-outlined",
              textColor: getDefaultFillValue("#000000"),
              iconSize: 20,
              position: Alignment.RIGHT,
              gap: 8,
            },
            states: {
              [State.HOVER]: initialEventButtonState(),
              [State.ACTIVE]: initialEventButtonState(),
              [State.DISABLED]: initialEventButtonState(),
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(4),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    initialHoverButtonOptions._active = false;

    // new fields
    const initialSportsContainerOptions = prefillEmptyOptions(
      fields.value.sports.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            spaceBetween: 20,
            spaceBetweenEvents: 20,
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue(""),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
            },
          },
        },
      }).options
    );

    const initialLeaguesContainerOptions = prefillEmptyOptions(
      fields.value.leagues.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            spaceBetween: 16,
            color: getDefaultFillValue("#595959"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            displayMode: "fill",
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing({ bottom: 12 }),
          },
        },
      }).options
    );

    const initialSportsEventButtonOptions = prefillEmptyOptions(
      fields.value.sports_event_button.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            display: {
              resizing: "hug",
              alignment: "left",
            },
            text: {
              _active: false,
            },
            icon: {
              _active: true,
              value: "ant-design:right-outlined",
              textColor: getDefaultFillValue("#000000"),
              iconSize: 20,
              position: Alignment.RIGHT,
              gap: 8,
            },
            states: {
              [State.HOVER]: initialEventButtonState(),
              [State.ACTIVE]: initialEventButtonState(),
              [State.DISABLED]: initialEventButtonState(),
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(4),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialHeaderOptions = prefillEmptyOptions(
      fields.value.header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            layout: "vertical",
            leagueNameVerticalPosition: "top",
            leagueNameHorizontalPosition: "left",
            favoriteIconAlignment: VerticalAlignment.TOP,
            verticalSpace: 4,
          },
        },
      }).options
    );

    const initialEventLeagueOptions = prefillEmptyOptions(
      fields.value.event_league.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        displayMode: "fill",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      }
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favourites_button.field.options,
      {
        iconSize: 24,
        value: "ant-design:star-outlined",
        color: {
          color: "#FFC53D",
          opacity: 100,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
          [State.ACTIVE]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    const initialEventNameOptions = prefillEmptyOptions(
      fields.value.event_name.field.options,
      {
        color: getDefaultFillValue("#595959"),
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
        alignment: "left",
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        states: {
          [State.HOVER]: {
            color: getDefaultFillValue(),
          },
        },
      }
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_card.field,
      initialEventCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialEventButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.sports.field,
      initialSportsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.leagues.field,
      initialLeaguesContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.sports_event_button.field,
      initialSportsEventButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.header.field,
      initialHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_league.field,
      initialEventLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourites_button.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_name.field,
      initialEventNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.hover_button.field,
      initialHoverButtonOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
