import { Ref } from "vue";

import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { IFields } from "../useWidgetFields";

export const useFormCardElementsControls = (
  fields: Ref<IFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const fromCardTitleControls = computed(() => {
    return [
      typography({ source: fields.value.provider_title.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const formCardDescriptionControls = computed(() => {
    return [
      typography({ source: fields.value.provider_description_1.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_description_1.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const formCardImageControls = computed(() => [
    {
      componentPath: "ConfigContainerSize",
      valuePath: "options.containerSize",
      valueSource: fields.value.provider_image.field,
      options: {
        isShow: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.provider_image.field,
      options: {
        label: "Border",
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.provider_image.field,
      options: {
        label: "Shadow",
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.provider_image.field,
      options: {
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.provider_image.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const formBackButtonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.form_back_button.field,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const formBackButtonContentControls = computed(() => {
    const items = [
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "p-t-16",
            valueSource: fields.value.form_back_button.field,
            options: {
              label: "Text",
            },
          },
        ],
      },
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: fields.value.form_back_button.field,
        options: {
          showAlignmentOnFillOnly: false,
        },
      },
    ];

    return items;
  });

  const formBackButtonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const formBackButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: "form_back_button", value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${formBackButtonSettingsValuePath.value}.fill`,
      valueSource: fields.value.form_back_button.field,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${formBackButtonSettingsValuePath.value}.border`,
      valueSource: fields.value.form_back_button.field,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${formBackButtonSettingsValuePath.value}.shadow`,
      valueSource: fields.value.form_back_button.field,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${formBackButtonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: fields.value.form_back_button.field,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const providerCardStateValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const providerCardDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.provider_card.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const providerCardContentItems: ConfigTabItem[] = [
    {
      label: "Photo style",
      value: "provider_image",
    },
    {
      label: "Title style",
      value: "provider_title",
    },
    {
      label: "Description style",
      value: "provider_description_1",
    },
  ];

  const providerCardContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigLayoutPosition",
        valuePath: "options.layoutPosition",
        valueSource: fields.value.provider_card.field,
        options: {},
        visible: true,
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: providerCardContentItems,
        },
      },
    ];
  });

  const providerCardStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "provider_card", value });
          emit("update-provider-card-state", value);
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${providerCardStateValuePath.value}.fillImageColor`,
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${providerCardStateValuePath.value}.border`,
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${providerCardStateValuePath.value}.shadow`,
        valueSource: fields.value.provider_card.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  return {
    fromCardTitleControls,
    formCardDescriptionControls,
    formCardImageControls,
    formBackButtonDesignControls,
    formBackButtonContentControls,
    formBackButtonStatesControls,
    providerCardDesignControls,
    providerCardContentControls,
    providerCardStatesControls,
  };
};
