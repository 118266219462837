import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { getDefaultFillValue } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 32;

const v27WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;

  if (!["GdprWidget"].includes(widget.name)) {
    return widget;
  }

  const checkboxInitial = CHECKBOX_STYLE_INITIAL();

  const currentOptions =
    widget.options._customFields.checkboxes_styling.options;

  const checkBorder = currentOptions.border;
  const checkIcon = currentOptions.checkIcon;
  const textColor = currentOptions.color;
  const checkCornerRadius = currentOptions.cornerRadius;
  const decoration = currentOptions.decoration;
  const checkFill = currentOptions.fill;
  const fontFamily = currentOptions.fontFamily;
  const theme = currentOptions.theme;
  const size = currentOptions.size;

  checkboxInitial.options.check.fill = checkFill;
  checkboxInitial.options.check.border = checkBorder;
  checkboxInitial.options.check.icon =
    checkIcon || getDefaultFillValue("#000000");

  checkboxInitial.options.cornerRadius = checkCornerRadius;
  checkboxInitial.options.color = textColor;
  checkboxInitial.options.decoration = decoration;
  checkboxInitial.options.fontFamily = fontFamily;
  checkboxInitial.options.theme = theme;
  checkboxInitial.options.size = size;

  for (const state in currentOptions.states) {
    const currOptions = currentOptions.states[state];

    const checkBorder = currOptions.border as IWidgetOptions;
    const checkIcon = currOptions.checkIcon;
    const textColor = currOptions.color;
    const checkFill = currOptions.fill;

    const initialStates = checkboxInitial.options.states as Record<
      string,
      IWidgetOptions
    >;

    initialStates[state].check.border = checkBorder;
    initialStates[state].check.icon = checkIcon;
    initialStates[state].check.fill = checkFill;
    initialStates[state].color = textColor;
  }

  widget.options._customFields.checkboxes_styling.options =
    checkboxInitial.options;

  return widget;
};

export default v27WidgetMigration;
