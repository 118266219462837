import {
  Alignment,
  DisplayOrientation,
  FillType,
  Grid,
  State,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "../common/widget-initial";

export const PAYMENT_PROVIDERS_WIDGET_INITIAL = () => {
  return {
    options: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
    },
  };
};

export const PROVIDERS_LIST_INITIAL = () => {
  return {
    options: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "",
          opacity: 100,
        },
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      gridSettings: {
        grid: Grid.FIT,
        minCardWidth: 228,
        gridFixedType: Grid.FIT,
        gap: 12,
        rowGap: 12,
        maxCardsInRow: 10,
        minCardsInRow: 1,
      },
      insufficientOpacity: 50,
    },
  };
};

const cardStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
  };
};

export const PROVIDERS_CARD_INITIAL = () => {
  return {
    options: {
      fill: {
        color: "#ffffff",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      gap: 4,
      layoutPosition: {
        position: "bottom",
        alignment: null,
      },
      states: {
        [State.HOVER]: cardStatesInitial(),
        [State.ACTIVE]: cardStatesInitial(),
      },
    },
  };
};

export const PROVIDERS_TOOLTIP_INITIAL = () => {
  return {
    options: {
      _active: true,
      fill: {
        color: "#000000",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
        fill: {
          color: "",
          opacity: 100,
        },
      },
      cornerRadius: getDefaultCornerRadiusValue(8),
      padding: {
        top: 4,
        bottom: 4,
        left: 4,
        right: 4,
      },
      color: {
        color: "#ffffff",
        opacity: 100,
      },
      fontFamily: getDefaultFontFamily(),
      theme: 13,
      decoration: [],
      textChooseIcon: {
        icon: "ant-design:question-circle-outlined",
        color: {
          color: "#898989",
          opacity: 100,
        },
        gap: 5,
      },
    },
  };
};

const buttonStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    iconSettings: {
      textColor: getDefaultFillValue("#000000"),
    },
  };
};

export const PROVIDERS_CARD_BUTTON_INITIAL = () => {
  return {
    options: {
      _active: true,
      link: {
        type: null,
        value: null,
        authorizeValue: null,
        hasAuthorizeValue: false,
        isNewTabOpen: null,
      },
      fill: {
        color: "#1677FF",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(6),
      spacing: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      buttonDisplaySettings: {
        resizing: "fill",
        alignment: "center",
      },
      buttonTextSettings: {
        _active: true,
        textColor: {
          color: "#ffffff",
          opacity: 100,
        },
        theme: 16,
        fontFamily: getDefaultFontFamily(),
        decoration: [],
      },
      iconSettings: {
        _active: false,
        default_value: "ant-design:left-outlined",
        textColor: getDefaultFillValue("#000000"),
        iconSize: 20,
        position: Alignment.LEFT,
        gap: 8,
      },
      states: {
        [State.HOVER]: buttonStatesInitial(),
        [State.ACTIVE]: buttonStatesInitial(),
        [State.DISABLED]: buttonStatesInitial(),
      },
    },
  };
};

const informationStatesInitial = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
  };
};

export const INFORMATION_INITIAL = () => {
  return {
    options: {
      _active: true,
      fill: {
        color: "#ffffff",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(6),
      spacing: {
        padding: {
          top: 8,
          bottom: 8,
          left: 8,
          right: 8,
        },
        margin: {
          top: 0,
          bottom: 16,
          left: 0,
          right: 0,
        },
      },
      gap: 4,
      layout: DisplayOrientation.VERTICAL,
      alignment: VerticalAlignment.TOP,
      states: {
        [State.HOVER]: informationStatesInitial(),
        [State.ACTIVE]: informationStatesInitial(),
        [State.DISABLED]: informationStatesInitial(),
      },
    },
  };
};

export const NO_PROVIDERS_INITIAL = () => {
  return {
    options: {
      _active: true,
      fill: {
        color: "#ffffff",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: {
        top: 8,
        bottom: 8,
        left: 8,
        right: 8,
      },
      color: {
        color: "#000000",
        opacity: 100,
      },
      fontFamily: getDefaultFontFamily(),
      theme: 16,
      decoration: [],
      alignment: Alignment.LEFT,
      iconSettings: {
        _active: false,
        default_value: "ant-design:left-outlined",
        textColor: getDefaultFillValue("#000000"),
        iconSize: 20,
        position: Alignment.LEFT,
        gap: 8,
      },
    },
  };
};
