import { IPageWidgetDetails } from "~~/models/page.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useMetaStore } from "~~/store/meta";

export const useWidgetCacheData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const metaStore = useMetaStore();

  const { widgetsDetailsCache } = storeToRefs(widgetsStore);

  const widgetCacheData = computed<Partial<IPageWidgetDetails> | undefined>(
    () => {
      return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[
        widget.static_id
      ];
    }
  );

  return {
    widgetCacheData,
  };
};
