import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 71;

const additionalInfoField = () => {
  return {
    "name": "additional_info",
    "type": "UnknownField",
    "value": null,
    "options": {},
  };
};

const v70WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (["PaymentProviderFormFenixWidget"].includes(widget.name)) {
    if (!("additional_info" in widget.content)) {
      widget.content.additional_info = additionalInfoField();
    }
  }

  return widget;
};
export default v70WidgetMigration;
