import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";

export const useFormContentConfig = () => {
  const widgetSettings = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettings);
  const store = useWidgetsStore();

  const tooltipControl = computed(() => ({
    componentPath: "content/ConfigTextInputWithToggle",
    valuePath: "options.tooltip.value",
    valueSource: selectedField.value,
    options: {
      placeholder: "Enter tooltip text",
      label: "Tooltip",
      allowDisable: true,
      active: selectedField.value?.options.tooltip._active,
      showOptions: false,
      "onUpdate:active": (value: boolean) => {
        if (!selectedField.value) {
          return;
        }

        selectedField.value.options.tooltip._active = value;
      },
    },
  }));

  const labelControl = computed(() => ({
    componentPath: "content/ConfigTextInputWithToggle",
    valuePath: "options.label.value",
    valueSource: selectedField.value,
    options: {
      placeholder: "Enter label text",
      label: "Label",
      allowDisable: true,
      active: selectedField.value?.options.label._active,
      showOptions: false,
      "onUpdate:active": (value: boolean) => {
        if (!selectedField.value) {
          return;
        }

        selectedField.value.options.label._active = value;
      },
    },
  }));

  const placeholderControl = computed(() => ({
    componentPath: "content/ConfigTextInputWithToggle",
    valuePath: "options.placeholder.value",
    valueSource: selectedField.value,
    options: {
      placeholder: "Enter placeholder text",
      label: "Placeholder",
      allowDisable: true,
      active: selectedField.value?.options.placeholder._active,
      showOptions: false,
      "onUpdate:active": (value: boolean) => {
        if (!selectedField.value) {
          return;
        }

        selectedField.value.options.placeholder._active = value;
      },
    },
  }));

  const formFieldItemsControl = computed(() => ({
    componentPath: "form/ConfigFormFieldItems",
    valuePath: "options.items",
    valueSource: selectedField.value,
    options: {
      label: "Items",
      isBold: true,
    },
  }));

  /*
    Title
  */
  const textFieldControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Title",
          isBold: true,
        },
      },
    ];
  });

  /*
    Text input
  */
  const textInputFieldControls = computed(() => {
    return [labelControl.value, tooltipControl.value, placeholderControl.value];
  });

  /*
    Text input
  */
  const calendarFieldControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Label"),
        controls: [labelControl.value],
      },
      tooltipControl.value,
      placeholderControl.value,
    ];
  });

  /*
    Checkbox
  */
  const checkboxFieldControls = computed(() => {
    return [
      // {
      //   componentPath: "content/ConfigTextInputWithToggle",
      //   valuePath: "options.title.value",
      //   valueSource: selectedField.value,
      //   className: "group-control-element",
      //   options: {
      //     placeholder: "Enter text",
      //     label: "Label",
      //     allowDisable: true,
      //     active: selectedField.value!.options.title._active,
      //     showOptions: false,
      //     "onUpdate:active": (value: boolean) => {
      //       selectedField.value!.options.title._active = value;
      //     },
      //   },
      // },
      // tooltipControl.value,
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "options.label.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter label text",
        },
      },
    ];
  });

  /*
    Radiogroup
  */
  const radioGroupFieldControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "options.label.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter label text",
        },
      },
      tooltipControl.value,
      formFieldItemsControl.value,
    ];
  });

  /*
    Dropdown
  */
  const dropdownFieldControls = computed(() => [
    ...textInputFieldControls.value,
    formFieldItemsControl.value,
  ]);

  /* 
    Upload
  */
  const uploadFieldControls = computed(() => {
    return [
      labelControl.value,
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "options.uploadButton.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Upload button",
          isBold: true,
        },
      },
      tooltipControl.value,
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "options.caption.value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter caption text",
          label: "Caption",
          allowDisable: true,
          active: selectedField.value?.options.caption._active,
          showOptions: false,
          "onUpdate:active": (value: boolean) => {
            if (!selectedField.value) {
              return;
            }

            store.updateFieldOptionValue(
              value,
              "caption._active",
              selectedField.value.id
            );
          },
        },
      },
    ];
  });

  return {
    textFieldControls,
    calendarFieldControls,
    textInputFieldControls,
    dropdownFieldControls,
    checkboxFieldControls,
    radioGroupFieldControls,
    uploadFieldControls,
  };
};
