<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-radius widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <p
      class="wconfig-color__label m-b-4"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>

    <ConfigInput
      v-number
      :is-number="true"
      :model-value="modelValue"
      :stretch-input="true"
      :input-props="{
        min: 0,
        max: MAX_NUMBER_INPUT_VALUE,
        controls: false,
      }"
      class="wconfig-radius__option"
      @update:model-value="handleUpdate"
    >
      <template
        v-if="showIconPrefix"
        #prefix
        ><CommonIcon
          :name="iconName"
          class="wconfig-radius__column-width-icon"
      /></template>
      <template
        v-if="showSuffix"
        #suffix
        >{{ suffix }}</template
      >
    </ConfigInput>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";

withDefaults(
  defineProps<{
    modelValue: string | number | null;
    label?: string;
    isBold?: boolean;
    iconName?: string;
    showIconPrefix?: boolean;
    suffix?: string;
    showSuffix?: boolean;
  }>(),
  {
    label: "Space between columns",
    isBold: false,
    iconName: "ant-design:column-width-outlined",
    showIconPrefix: true,
    suffix: "",
    showSuffix: false,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.wconfig-radius {
  &__option {
    width: 100%;
  }

  &__column-width-icon {
    color: $c-grey-45;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
