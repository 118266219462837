<template>
  <div
    v-if="hasDesignPermissions"
    class="config-language-config"
  >
    <ConfigRadioTextElements
      :model-value="value"
      :items="[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]"
      class="border-bottom-none p-b-0"
      label="Visibility"
      @update:model-value="handleUpdate"
    />
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const { getLocalizedValue, setLocalizedValue } = useLocalizedValue();

const props = defineProps<{
  modelValue?: IWidgetOptions;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: IWidgetOptions): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const value = computed<boolean>(() => {
  if (props.modelValue && typeof props.modelValue === "object") {
    const result = getLocalizedValue.value(props.modelValue, true);

    return typeof result === "boolean" ? result : true;
  }

  return true;
});

const handleUpdate = (value: boolean): void => {
  const newValue = setLocalizedValue.value(props.modelValue, value);

  emit("update:model-value", newValue);
};
</script>

<style lang="scss">
.config-language-config {
  .ant-checkbox-wrapper {
    width: 100%;
  }
}
</style>
