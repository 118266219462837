<template>
  <a-modal
    v-model:visible="isVisible"
    :ok-text="submitButtonLabel"
    :cancel-text="cancelButtonLabel"
    class="common-modal"
    :width="width"
    :centered="true"
    :closable="closable"
    :confirm-loading="confirmLoading"
    :ok-button-props="{ disabled: isSubmitDisabled }"
    @ok="handleOkClick"
    @cancel="handleCancelClick"
  >
    <template #title>
      <CommonIcon
        v-if="getIconByType"
        :name="getIconByType"
        :class="`common-modal__icon--${iconColor}`"
      />

      <span class="common-modal__title-text text-bold">{{ title }}</span>
    </template>
    <div class="common-modal__content">
      <slot name="content"></slot>
    </div>

    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer"></slot>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { IconColor, IconType } from "~~/models/common/common-modal.enum";

const props = withDefaults(
  defineProps<{
    iconColor?: IconColor;
    iconType?: IconType | null;
    visible: boolean;
    submitButtonLabel?: string;
    cancelButtonLabel?: string;
    title: string;
    closable?: boolean;
    width?: string;
    confirmLoading?: boolean;
    isSubmitDisabled?: boolean;
  }>(),
  {
    width: "400px",
    closable: false,
    iconColor: IconColor.DEFAULT,
    iconType: null,
    confirmLoading: false,
    cancelButtonLabel: "Cancel",
    submitButtonLabel: "Submit",
    isSubmitDisabled: false,
  }
);

const isVisible = ref<boolean>(props.visible);

const emit = defineEmits(["submit", "cancel"]);

const getIconByType = computed<string | null>(() => {
  switch (props.iconType) {
    case IconType.WARNING:
      return "ant-design:exclamation-circle-outlined";
  }
  return "ant-design:question-circle-outlined";
});

const handleOkClick = () => {
  emit("submit");
};

const handleCancelClick = () => {
  emit("cancel");
};

watch(
  () => props.visible,
  newVal => {
    isVisible.value = newVal;
  }
);
</script>

<style lang="scss">
.common-modal {
  &__title-text {
    margin-left: $space-m;
  }

  &__content {
    margin: 0 auto;
  }

  &__icon--red {
    color: $c-icon-font-red;
  }

  &__icon--default {
    color: $c-primary-base;
  }

  .ant-modal-title {
    @include flex(center, flex-start, 0px);
  }
}
</style>
