import _get from "lodash/get";
import upperFirst from "lodash/upperFirst";

import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import {
  AlignContent,
  Display,
  DisplayOrientation,
  FlexDirection,
  ResizingType,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { textPositionToFlexDirection } from "~~/constants/dictionaries";
import { useCarouselConfig } from "~~/entities/carousel/composables/useCarouselConfig";

import {
  EventInfoDirection,
  EventInfoVariant,
} from "../PromoBetsFenixWidgetTypes";
import { CardsDisplayMode } from "../PromoBetsFenixWidgetTypes";
import { StateController } from "../composables/usePromoBetsFenixWidgetStateController";

export const usePromoBetsFenixWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  stateController: StateController
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Cards", "cards"),
    generateDropdownItem("Image", "image"),
    generateDropdownItem("Content", "content"),
    generateDropdownItem("Event info", "event_info"),
    generateDropdownItem("Team / Player", "team_player"),
    generateDropdownItem("Date / time", "date_time"),
    generateDropdownItem("Markets", "markets"),
    generateDropdownItem("Outcomes", "outcomes"),
    generateDropdownItem("Button", "button"),
    generateDropdownItem("Arrows", "arrows"),
    generateDropdownItem("Pagination", "pagination"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    arrows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pagination: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    content: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    date_time: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    markets: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    outcomes: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });
  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: widget.value,
            options: {
              items: [
                {
                  label: CardsDisplayMode.Grid,
                  value: CardsDisplayMode.Grid,
                },
                {
                  label: CardsDisplayMode.Carousel,
                  value: CardsDisplayMode.Carousel,
                },
              ],
            },
          },
          ...(widget.value.options.displayMode === CardsDisplayMode.Grid
            ? [
                {
                  componentPath: "ConfigDisplayGridSettings",
                  valuePath: "options.gridSettings",
                  valueSource: widget.value,
                },
              ]
            : useCarouselConfig(widget).carouselSettings.value),
        ],
      },
      ...(widget.value.options.displayMode === CardsDisplayMode.Carousel
        ? useCarouselConfig(widget).carousel.value
        : []),
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Cards",
              value: "cards",
            },
          ],
        },
      },
    ];
  });

  const cardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const cardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.height",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Height",
              icon: "ant-design:column-height-outlined",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.layoutPosition",
            valueSource: selectedField.value,
            options: {
              showAlignment: false,
            },
          },
          {
            componentPath: "ConfigAlignmentAdvanced",
            valuePath: "options.layoutAlignment",
            valueSource: selectedField.value,
            options: {
              label: "Align elements",
              display: Display.FLEX,
              direction:
                textPositionToFlexDirection[
                  selectedField.value?.options.layoutPosition
                    .position as TextPosition
                ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Image",
              value: "image",
              isActiveValueSource: fields.value.image.field.options,
            },
            {
              label: "Content",
              value: "content",
            },
            {
              label: "Event info",
              value: "event_info",
              isActiveValueSource: fields.value.event_info.field.options,
            },
            {
              label: "Markets",
              value: "markets",
            },
            {
              label: "Button",
              value: "button",
              isActiveValueSource: fields.value.button.field.options,
            },
          ],
        },
      },
    ];
  });
  const cardsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "cards",
            value,
          });
          stateController.handleStateUpdate({
            state: "team_player",
            value,
          });
        },
      },
      ...getInitialDesignList({
        source: selectedField.value,
        basePath: stateController.getOptionsValuePath(
          selectedField.value?.name
        ),
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: generateDesignSectionName("Team / Player"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${stateController.getOptionsValuePath(
              "team_player"
            )}.color`,
            valueSource: fields.value.team_player.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const { flexibleImageControls: imageControls } = useFlexibleImageControls(
    toRef(fields.value.image.field)
  );

  const contentDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const contentContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigAlignmentAdvanced",
            valuePath: "options.layoutAlignment",
            valueSource: selectedField.value,
            options: {
              label: "Align elements",
              display: Display.FLEX,
              direction: FlexDirection.COLUMN,
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.alignContent",
            valueSource: selectedField.value,
            options: {
              label: "Align content",
              items: [
                {
                  label: upperFirst(AlignContent.START),
                  value: AlignContent.START,
                },
                {
                  label: upperFirst(AlignContent.END),
                  value: AlignContent.END,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Event info",
              value: "event_info",
              isActiveValueSource: fields.value.event_info.field.options,
            },
            {
              label: "Markets",
              value: "markets",
            },
            {
              label: "Button",
              value: "button",
              isActiveValueSource: fields.value.button.field.options,
            },
          ],
        },
      },
    ];
  });

  const eventInfoDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const eventInfoContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.width",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Width",
              exclude: [ResizingType.FIXED],
            },
          },
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.height",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Height",
              exclude: [ResizingType.FIXED],
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.variant",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: Object.values(EventInfoVariant).map((value, index) => {
                return {
                  label: index + 1,
                  value,
                };
              }),
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.direction",
            valueSource: selectedField.value,
            visible:
              fields.value.event_info.field.options.variant !==
              EventInfoVariant.Variant5,
            options: {
              label: "Direction",
              items: Object.values(EventInfoDirection).map(value => {
                return {
                  label: value,
                  value,
                };
              }),
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Align elements"),
        controls: [
          {
            componentPath: "ConfigAlignmentAdvanced",
            valuePath: "options.layoutAlignment",
            valueSource: selectedField.value,
            options: {
              display: Display.GRID,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Team / Player",
              value: "team_player",
            },
            {
              label: "Date / time",
              value: "date_time",
            },
          ],
        },
      },
    ];
  });

  const teamPlayerControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.height",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Height",
              icon: "ant-design:column-height-outlined",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Layout",
              exclude: [ResizingType.FIXED],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.horizontalAlignment",
            valueSource: selectedField.value,
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      typography(
        {
          source: selectedField.value,
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.team_player.field.options?.icon,
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-t-16 p-r-16 p-l-16",
            valueSource: selectedField.value,
            options: {
              label: "Size",
              minValue: 8,
              maxValue: 48,
            },
          },
          {
            componentPath: "common/ConfigCommonRadio",
            valuePath: "options.icon.position",
            className: "p-t-16 p-r-16 p-l-16",
            valueSource: selectedField.value,
            options: {
              label: "Position",
              firstVariant: "",
              firstVariantIcon: {
                className: "r-90",
                icon: "custom:unordered-list-outlined",
              },
              firstValue: TextPosition.BOTTOM,
              secondaryVariant: "",
              secondaryVariantIcon: {
                icon: "custom:unordered-list-outlined",
              },
              secondaryValue: TextPosition.RIGHT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: selectedField.value,
            className: "p-t-16 p-r-16 p-l-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 0,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const dateTimeDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const dateTimeContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayOrientation",
            valueSource: selectedField.value,
            options: {
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      typography(
        {
          source: selectedField.value,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.date_time.field.options?.icon,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueDate",
            valueSource: selectedField.value,
            className: "p-t-16 p-r-16 p-l-16",
            options: {
              label: "Date icon",
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueTime",
            className: "p-t-16 p-r-16 p-l-16",
            valueSource: selectedField.value,
            options: {
              label: "Time icon",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-t-16 p-r-16 p-l-16",
            valueSource: selectedField.value,
            options: {
              label: "Size",
              minValue: 8,
              maxValue: 48,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: selectedField.value,
            className: "p-t-16 p-r-16 p-l-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 0,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const marketsDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const marketsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayOrientation",
            valueSource: selectedField.value,
            options: {
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Outcomes",
              value: "outcomes",
            },
          ],
        },
      },
    ];
  });

  const outcomesDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const outcomesContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: selectedField.value,
        options: {
          placeholder: "Select",
          label: "Font family",
          isBold: true,
        },
      },
      typography(
        {
          source: selectedField.value?.options.textName,
          isOptionPath: false,
          except: ["ConfigFontFamilySelect", "ConfigAlignment"],
        },
        "Bet name text style"
      ),
      typography(
        {
          source: selectedField.value?.options.textName,
          isOptionPath: false,
          except: ["ConfigFontFamilySelect", "ConfigAlignment"],
        },
        "Coefficient text style"
      ),
    ];
  });
  const outcomesStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.outcomes,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "outcomes",
            value,
          });
        },
      },
      ...getInitialDesignList({
        source: selectedField.value,
        basePath: `${stateController.getOptionsValuePath(
          selectedField.value?.name
        )}`,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      typography(
        {
          source: _get(
            selectedField.value,
            `${stateController.getOptionsValuePath(
              selectedField.value?.name
            )}.textName`
          ) as unknown as IWidgetField,
          isOptionPath: false,
          except: [
            "ConfigThemeSelect",
            "ConfigFontFamilySelect",
            "ConfigDecoration",
            "ConfigAlignment",
          ],
        },
        "Bet name text style"
      ),
      typography(
        {
          source: _get(
            selectedField.value,
            `${stateController.getOptionsValuePath(
              selectedField.value?.name
            )}.textCoefficient`
          ) as unknown as IWidgetField,
          isOptionPath: false,
          except: [
            "ConfigThemeSelect",
            "ConfigFontFamilySelect",
            "ConfigDecoration",
            "ConfigAlignment",
          ],
        },
        "Coefficient text style"
      ),
    ];
  });

  const buttonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });
  const buttonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Layout",
              exclude: [ResizingType.FIXED],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "p-t-0",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Typography"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
      },
    ];
  });
  const buttonStatesControls = computed(() => {
    const basePath = stateController.getOptionsValuePath(
      selectedField.value?.name
    );

    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.button,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "button",
            value,
          });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${basePath}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${basePath}.border`,
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${basePath}.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${basePath}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${basePath}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,

    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,

    imageControls,

    contentDesignControls,
    contentContentControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    teamPlayerControls,

    dateTimeDesignControls,
    dateTimeContentControls,

    marketsDesignControls,
    marketsContentControls,

    outcomesDesignControls,
    outcomesContentControls,
    outcomesStatesControls,

    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,
  };
};
