import { State } from "~~/models/widgets/widget-controls.model";

import { getDefaultFontFamily } from "./widget-initial";

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    theme: 24,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: "left",
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
      states: {
        [State.HOVER]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
        [State.ACTIVE]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
      },
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    margins: {
      top: 0,
      bottom: 0,
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});
