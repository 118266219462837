<!-- 
  TODO emit update after input blur only  
 -->
<template>
  <div
    v-if="hasDesignPermissions"
    class="m-t-16 p-b-16 p-l-16 p-r-16 config-margin-inputs widget-control-container"
  >
    <p
      class="config-margin-inputs__label"
      :class="{
        'sub-heading': meta.isBold,
      }"
    >
      {{ label }}
    </p>

    <div class="m-t-12 config-margin-inputs__container">
      <a-tooltip
        v-for="marginProperty in MARGIN_PROPERTIES"
        :key="marginProperty.value"
        placement="bottomRight"
      >
        <template
          v-if="showTooltip"
          #title
        >
          <span>{{ marginProperty.label }}</span>
        </template>

        <ConfigInput
          v-number="{ allowNegative: true }"
          :is-number="true"
          :model-value="modelValue[marginProperty.value as keyof IVerticalSpacingInput]"
          :input-props="{
            min: MIN_NUMBER_INPUT_VALUE,
            max: MAX_NUMBER_INPUT_VALUE,
            controls: false,
          }"
          class="config-margin-inputs__option"
          @update:model-value="handleUpdate(marginProperty.value, $event)"
          @mouseenter="
            handleMouseIn(`${SpacingKeyName.MARGIN}-${marginProperty.value}`)
          "
          @mouseleave="handleMouseOut"
        >
          <template #prefix>
            <CommonIcon
              :name="marginProperty.icon"
              :class="marginProperty.iconClassName"
            />
          </template>
          <template #suffix>
            <span> px </span>
          </template>
        </ConfigInput>
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  MIN_NUMBER_INPUT_VALUE,
  MAX_NUMBER_INPUT_VALUE,
} from "~~/constants/input-validation";
import {
  SpacingInput,
  IVerticalSpacingInput,
  SpacingKeyName,
  ISpacingControl,
} from "~~/models/widgets/widget-controls.model";
import { useControlHover } from "~~/composables/widgets-config/useControlHover";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = withDefaults(
  defineProps<{
    modelValue: IVerticalSpacingInput;
    meta?: {
      isBold: boolean;
    };
    showTooltip?: boolean;
    label?: string;
    isAllSideSetting?: boolean;
    isHorizontal?: boolean;
  }>(),
  {
    meta: () => ({
      isBold: false,
    }),
    label: "Distance to other elements",
    showTooltip: false,
    modelValue: (): IVerticalSpacingInput => ({
      top: 0,
      bottom: 0,
    }),
    isAllSideSetting: false,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: IVerticalSpacingInput): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const { handleMouseIn, handleMouseOut } = useControlHover();

const HORIZONTAL_MARGINS = computed<ISpacingControl[]>(() => {
  return [
    {
      label: "Left margin",
      value: SpacingInput.LEFT,
      icon: "custom:space-left",
      iconClassName: "",
    },
    {
      label: "Right margin",
      value: SpacingInput.RIGHT,
      icon: "custom:space-left",
      iconClassName: "r-180",
    },
  ];
});

const VERTICAL_MARGINS = computed<ISpacingControl[]>(() => {
  return [
    {
      label: "Top margin",
      value: SpacingInput.TOP,
      icon: "custom:margin-bottom",
      iconClassName: "r-180",
    },
    {
      label: "Bottom margin",
      value: SpacingInput.BOTTOM,
      icon: "custom:margin-bottom",
      iconClassName: "",
    },
  ];
});

const MARGIN_PROPERTIES = computed<ISpacingControl[]>(() => {
  if (props.isHorizontal) {
    return HORIZONTAL_MARGINS.value;
  }

  if (props.isAllSideSetting) {
    return [
      HORIZONTAL_MARGINS.value[0],
      VERTICAL_MARGINS.value[0],
      HORIZONTAL_MARGINS.value[1],
      VERTICAL_MARGINS.value[1],
    ];
  }

  return [...VERTICAL_MARGINS.value];
});

const handleUpdate = (key: string, value: number | string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss">
.config-margin-inputs {
  &__container {
    @include flex(flex-start, space-between, 8px, true);
  }

  &__option {
    margin-bottom: 10px;
    width: calc(50% - 8px);

    svg {
      height: 24px;
      width: 24px;
    }

    .ant-input {
      padding-right: 28px !important;
    }
  }
  &__label {
    margin-bottom: $space-s;
  }
}
</style>
