import { BUTTON_INITIAL as initial } from "~~/constants/configs/card-common/initial-constants";
import {
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import {
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

const UPLOAD_BUTTON_STYLE_INITIAL = () => {
  const initialValue = initial();

  const iconSettings = initialValue.options.iconSettings;
  const textSettings = initialValue.options.buttonTextSettings;

  return {
    ...initialValue,
    options: {
      ...initialValue.options,
      iconSettings: {
        ...iconSettings,
        position: Alignment.LEFT,
        value: "ant-design:upload-outlined",
        default_value: "ant-design:upload-outlined",
        textColor: getDefaultFillValue("#000000"),
      },
      buttonDisplaySettings: {
        resizing: ResizingType.HUG,
        alignment: Alignment.LEFT,
      },
      buttonTextSettings: {
        ...textSettings,
        textColor: getDefaultFillValue("#000000"),
        theme: 13,
      },
      fill: getDefaultFillValue("#ffffff"),
      border: getDefaultBorderValue({
        fill: getDefaultFillValue("#000000", 15),
      }),
      padding: getDefaultSpacing({
        left: 16,
        right: 16,
        top: 5,
        bottom: 5,
      }),
    },
    title: "Upload Button style",
  };
};

export { UPLOAD_BUTTON_STYLE_INITIAL };
