import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  Align,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { CellSizeType } from "~~/models/grid.interface";
import { useWidgetsStore } from "~~/store/widgets";

import { useFlexibleImageControls } from "../../common/useFlexibleCardWidgetControls";
import { useButtonControls } from "../useButtonControls";
import { useButtonsGroupControls } from "../useButtonsGroupControls";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Image", "image"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "description_1"),
    generateDropdownItem("Button group", "buttons_group"),
    generateDropdownItem("Button", "button_1", "buttonsGroup"),
    generateDropdownItem("Button", "button_2", "buttonsGroup"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
      generateTabItem("Data", "data"),
    ],
    buttons_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const updateFieldOption = (
    optionName: string,
    value: any,
    field: IWidgetField
  ): void => {
    widgetStore.updateFieldOptionValue(value, optionName, field.id);
  };

  const statesWidgetValuePath = computed<string>(() => {
    if (states.value.widget === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.widget}`;
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.display.layout",
            valueSource: widget.value,
            options: {},
            visible: true,
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            visible:
              widget.value.options.size.height.type === CellSizeType.FIXED,
            valueSource: widget.value,
            options: {
              label: "Widget alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.gap",
            valueSource: widget.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "group-control-element",
            options: {
              label: "Alignment",
              layout: DisplayOrientation.VERTICAL,
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.alignSelf",
            valueSource: widget.value,
            options: {
              label: "Align content",
              items: [
                {
                  label: "Start",
                  value: Align.START,
                },
                {
                  label: "End",
                  value: Align.END,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: widget.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "content/ConfigImageWithToggle",
        valuePath: "value",
        valueSource: fields.value.image.field,
        options: {
          label: "Image",
          allowDisable: true,
          active: fields.value.image.field.options._active,
          showOptions: fields.value.image.field.options._active,
          "onUpdate:active": (value: boolean) => {
            fields.value.image.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.image.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: true,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "widget-control-container",
        options: {
          isMultiple: false,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: true,
          fields: [fields.value.description_1],
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description_1.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options._active",
        valueSource: fields.value.buttons_group.field,
        className: "p-l-16 p-t-16 p-r-16 p-b-16 widget-control-container",
        options: {
          label: "Buttons group",
          size: "small",
          allowDisable: true,
          showOptions: fields.value.buttons_group.active,
          "onOptions-click": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.buttons_group.field
            );
          },
        },
      },
    ];
  });

  const widgetStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "widget", value });
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${statesWidgetValuePath.value}.fillImageColor`,
        valueSource: widget.value,
        options: {
          label: "Fill",
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesWidgetValuePath.value}.border`,
        valueSource: widget.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesWidgetValuePath.value}.shadow`,
        valueSource: widget.value,
      },
      {
        section: generateDesignSectionName("Title"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.link.states.${State.HOVER}.color`,
            valueSource: fields.value.title.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Description"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.states.${State.HOVER}.color`,
            valueSource: fields.value.description_1.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const imageControls = computed<ControlProp[]>(() => {
    const { flexibleImageControls } = useFlexibleImageControls(
      toRef(fields.value.image.field)
    );
    return [
      {
        componentPath: "ConfigImageUpload",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          active: selectedField.value?.options._active,
        },
      },
      ...flexibleImageControls.value,
    ];
  });

  const titleControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          isBold: true,
          showOptions: true,
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const descriptionControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.withTranslations",
        valueSource: selectedField.value,
        className: "widget-control-container p-l-16 p-t-16 p-r-16 p-b-16",
        options: {
          label: "With Translations",
          size: "small",
          allowDisable: true,
          "onUpdate:modelValue": (value: boolean) => {
            updateFieldOption(
              "withTranslations",
              value,
              fields.value.description_1.field
            );
          },
        },
      },
    ];
  });

  const { buttonsGroupDesignControls, buttonsGroupContentControls } =
    useButtonsGroupControls(widget, fields);

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: computed(() => {
          switch (selectedField.value?.name) {
            case "button_1":
              return states.value.button_1;
            case "button_2":
              return states.value.button_2;
            default:
              return State.DEFAULT;
          }
        }),
        stateHandler: (state: State) => {
          states.value[selectedField.value!.name] = state;
        },
      },
      emit
    );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgetStatesControls,

    imageControls,
    titleControls,
    descriptionControls,

    buttonsGroupDesignControls,
    buttonsGroupContentControls,

    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,
  };
};
