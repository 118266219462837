import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  MAX_GAP_INPUT_VALUE,
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_GAP_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";

import { useAllowDisableDependentTabs } from "../useAllowDisableDependentFieldContentItems";
import { useButtonControls } from "../card/useButtonControls";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Booking code", "booking_code"),
    generateDropdownItem("Description", "description"),
    generateDropdownItem("Link button", "button"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const { allowDisableTabItem } = useAllowDisableDependentTabs([
    fields.value.title.field,
    fields.value.description.field,
    fields.value.booking_code.field,
    fields.value.button.field,
  ]);

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateContentSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: widget.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              allowDisable: allowDisableTabItem(fields.value.title.field).value,
              label: "Title",
              value: "title",
              isActiveValueSource: fields.value.title.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.booking_code.field)
                .value,
              label: "Booking code",
              value: "booking_code",
              isActiveValueSource: fields.value.booking_code.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.description.field)
                .value,
              label: "Description",
              value: "description",
              isActiveValueSource: fields.value.description.field.options,
            },
            {
              allowDisable: allowDisableTabItem(fields.value.button.field)
                .value,
              label: "Link button",
              value: "button",
              isActiveValueSource: fields.value.button.field.options,
            },
          ],
        },
      },
    ];
  });

  /**
   * Title controls
   */
  const titleControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Text",
        },
      },
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Description controls
   */
  const descriptionControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Link button controls
   */
  const { buttonDesignControls, buttonContentControls, buttonStatesControls } =
    useButtonControls(
      {
        state: toRef(() => states.value.button),
        stateHandler: (state: State) => {
          emit("update-state", { state: "button", value: state });
        },
        valueSource: toRef(fields.value.button.field),
        exclude: {
          content: ["ConfigLinkType"],
        },
      },
      emit
    );

  /**
   * Booking code
   */

  const bookingCodeControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.distance",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Spacing between text and icon",
              minValue: MIN_GAP_INPUT_VALUE,
              maxValue: MAX_GAP_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    bookingCodeControls,

    descriptionControls,

    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,
  };
};
