<template>
  <div>
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
      :state="state"
    >
      <div
        :style="containerStyles"
        class="sportbook-live-mobile-fenix__event-card"
      >
        <slot />
      </div>
    </BasicDesignWrapperField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  state: string;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__event-card {
    display: flex;
    flex-direction: column;
  }
}
</style>
