<template>
  <article class="promo-bets-fenix-widget-card">
    <FlexibleImageField
      v-if="fields.image?.field.options._active"
      class="promo-bets-fenix-widget-card__image w-100"
      :field="fields.image.field"
      :widget="widget"
      :value="imageSrc"
    />

    <WidgetField
      :widget="widget"
      :field="fields.content.field"
      :style="contentStyles"
      class="promo-bets-fenix-widget-card__content"
    >
      <WidgetField
        v-if="fields.event_info.field.options._active"
        :widget="widget"
        :field="fields.event_info.field"
        :style="eventInfoStyles"
        class="promo-bets-fenix-widget-card__event-info"
      >
        <PromoBetsFenixWidgetTeamPlayer
          v-for="index in 2"
          :key="index"
          :widget="widget"
          :field="fields.team_player.field"
          :image="`/widgets/PromoBetsFenixWidget/logos/${index}.png`"
          :name="getTeamPlayerName(position, index)"
          :style="teamPlayerStyles"
          :image-container-styles="teamPlayerIconContainerStyles"
          :image-styles="teamPlayerIconStyles"
          :name-styles="teamPlayerTextStyles"
        />

        <PromoBetsFenixWidgetDateTime
          :widget="widget"
          :field="fields.date_time.field"
          :icon-active="fields.date_time.field.options.icon._active"
          :value-date="fields.date_time.field.options.icon.valueDate"
          :value-time="fields.date_time.field.options.icon.valueTime"
          :style="dateTimeStyles"
          :element-styles="dateTimeElementStyles"
          :icon-styles="dateTimeIconStyles"
          :text-styles="dateTimeTextStyles"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.markets.field"
        :style="marketsStyles"
        class="promo-bets-fenix-widget-card__markers"
      >
        <PromoBetsFenixWidgetOutcomesButton
          v-for="(item, index) in outcomes"
          :key="index"
          :widget="widget"
          :field="fields.outcomes.field"
          :style="outcomesStyles"
          :text-name="item.name"
          :text-coefficient="item.coefficient"
          :text-name-styles="outcomesTextNameStyles"
          :text-coefficient-styles="outcomesTextCoefficientStyles"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.button.field.options._active"
        :widget="widget"
        :field="fields.button.field"
        :style="buttonContainerStyles"
      >
        <PromoBetsFenixWidgetButton
          :icon-active="fields.button.field.options.iconSettings._active"
          :icon="
            fields.button.field.options.iconSettings.value ||
            fields.button.field.options.iconSettings.default_value
          "
          :text="'See more bets'"
          :button-style="buttonStyles"
          :button-text-style="buttonTextStyles"
          :button-icon-style="buttonIconStyles"
        />
      </WidgetField>
    </WidgetField>
  </article>
</template>
<script setup lang="ts">
import { CSSProperties } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { IFields } from "~~/composables/widgets/useWidgetFields";

defineProps<{
  widget: IWidgetWithFields;
  fields: IFields;

  position: number;
  imageSrc: string;

  contentStyles: CSSProperties;

  eventInfoStyles: CSSProperties;

  teamPlayerStyles: CSSProperties;
  teamPlayerTextStyles: CSSProperties;
  teamPlayerIconContainerStyles: CSSProperties;
  teamPlayerIconStyles: CSSProperties;

  dateTimeStyles: CSSProperties;
  dateTimeElementStyles: CSSProperties;
  dateTimeIconStyles: CSSProperties;
  dateTimeTextStyles: CSSProperties;

  marketsStyles: CSSProperties;

  outcomesStyles: CSSProperties;
  outcomesTextNameStyles: CSSProperties;
  outcomesTextCoefficientStyles: CSSProperties;

  buttonContainerStyles: CSSProperties;
  buttonStyles: CSSProperties;
  buttonTextStyles: CSSProperties;
  buttonIconStyles: CSSProperties;
}>();

const outcomes = [
  {
    name: "1",
    coefficient: "0.00",
  },
  {
    name: "X",
    coefficient: "0.00",
  },
  {
    name: "2",
    coefficient: "0.00",
  },
];

const getTeamPlayerName = (position: number, index: number) => {
  return position === 1 && index === 1
    ? "Team / Player #1 has a very long name, which can be displayed in several lines"
    : `Team / Player #${index}`;
};
</script>
<style lang="scss">
.promo-bets-fenix-widget-card {
  //
}
</style>
