<template>
  <div
    v-if="hasContentPermissions"
    class="wconfig-sports-multi-select p-t-8 p-r-16 p-l-16"
  >
    <DraggableList
      v-model="sortedSelectedSports"
      tag="div"
      item-key="id"
      class="wconfig-sports-multi-select__selected-sports-container m-b-16"
    >
      <template #item="{ element: sport }">
        <div
          class="wconfig-sports-multi-select__selected-sport"
          :class="{
            'wconfig-sports-multi-select__selected-sport--active':
              sport.published,
          }"
        >
          <span class="pointer">
            <CommonIcon
              name="custom:dnd-marker"
              :size="8"
            />
          </span>
          {{ sport.name }}
          <span
            class="pointer"
            @click="removeSelectedSport(sport.id)"
          >
            <CommonIcon
              name="custom:close-icon"
              class="wconfig-sports-multi-select__selected-sport-icon"
              :size="8"
            />
          </span>
        </div>
      </template>
    </DraggableList>

    <a-drawer
      v-model:visible="isDrawerOpened"
      class="wconfig-sports-multi-select__drawer"
      :width="500"
      placement="right"
      :footer-style="{ textAlign: 'right' }"
      :closable="false"
      @close="onClose"
    >
      <a-space
        class="wconfig-sports-multi-select w-100"
        :size="16"
        direction="vertical"
      >
        <a-typography-title :level="5">
          <div class="wconfig-sports-multi-select__header">
            <span> Add new sport </span>
            <span
              class="pointer"
              @click="onClose"
            >
              <CommonIcon
                name="custom:close-icon"
                :size="9"
              />
            </span>
          </div>
        </a-typography-title>

        <a-input
          :value="searchValue"
          placeholder="Sport name"
          @update:value="handleSearchInput"
        >
          <template #suffix>
            <span class="common-search__icon">
              <CommonIcon name="ant-design:search-outlined" />
            </span>
          </template>
        </a-input>

        <div
          v-for="sport in searchedSports"
          :key="sport.name"
          class="wconfig-sports-multi-select__select-element"
        >
          <a-checkbox
            :checked="sports.includes(sport.id)"
            @change="handleItemSelect(sport.id)"
          >
            {{ sport.name }}
          </a-checkbox>
          <div
            class="wconfig-sports-multi-select__sport-status"
            :class="{
              'wconfig-sports-multi-select__sport-status--active':
                sport.published,
            }"
          >
            {{ sport.published ? "Active" : "Inactive" }}
          </div>
        </div>
      </a-space>

      <template #footer>
        <a-button
          style="margin-right: 8px"
          type="primary"
          :disabled="isEqual"
          @click="handleChange"
          >Add</a-button
        >
        <a-button @click="onClose">Cancel</a-button>
      </template>
    </a-drawer>

    <a-button
      type="primary"
      class="w-100"
      ghost
      @click="showDrawer"
      >Add new sports</a-button
    >
  </div>
</template>

<script setup lang="ts">
import { deepCopy } from "~~/assets/utils";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ISportItem } from "~~/models/stores/widgets-store.model";
import { useBetsListSortedSelectedSports } from "~~/composables/widgets/bets-list/useBetsListSortedSelectedSports";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const widgetsStore = useWidgetsStore();

const { sportsItems } = storeToRefs(widgetsStore);

const props = defineProps<{
  modelValue: Array<string>;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const searchValue = ref<string>("");
const isDrawerOpened = ref<boolean>(false);

const emit = defineEmits<{
  (e: "update:modelValue", event: Array<string>): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

let sports = ref<Array<string>>(deepCopy(props.modelValue));

const showDrawer = (): void => {
  sports.value = deepCopy(props.modelValue);
  isDrawerOpened.value = true;
};

const onClose = (): void => {
  isDrawerOpened.value = false;
  setTimeout(() => {
    searchValue.value = "";
  }, 300);
};

const handleItemSelect = (id: string): void => {
  const idx = sports.value.indexOf(id);

  if (idx !== -1) {
    sports.value.splice(idx, 1);
  } else {
    sports.value.push(id);
  }
};

const arraysAreEqual = (arr1: Array<any>, arr2: Array<any>): boolean => {
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) {
    return false;
  }

  const isObjectEqual = (
    obj1: Record<any, any>,
    obj2: Record<any, any>
  ): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every(key => {
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        return isObjectEqual(obj1[key], obj2[key]);
      }
      return obj1[key] === obj2[key];
    });
  };

  return arr1.every((obj1, index) => {
    const obj2 = arr2[index];
    return isObjectEqual(obj1, obj2);
  });
};

const isEqual = computed<boolean>(() =>
  arraysAreEqual(props.modelValue, sports.value)
);

const searchedSports = computed<Array<ISportItem>>(() => {
  return sportsItems.value.filter(sport =>
    sport.name.toLowerCase().includes(searchValue.value.toLowerCase())
  );
});

const sortedSelectedSports: WritableComputedRef<Array<ISportItem>> = computed({
  get(): Array<ISportItem> {
    return (
      useBetsListSortedSelectedSports(selectedWidget.value!, sportsItems) || []
    );
  },

  set(val) {
    if (Array.isArray(val)) {
      sports.value = [];

      val.forEach(sport => sports.value.push(sport.id));
      emit("update:modelValue", sports.value);
    }
  },
});

const removeSelectedSport = (id: string): void => {
  sports.value = sports.value.filter(elem => elem !== id);

  emit("update:modelValue", sports.value);
};

// Using nextTick to make sure the props.modelValue is updated before updating the sports.value
const handleChange = async (): Promise<void> => {
  emit("update:modelValue", sports.value);

  await nextTick(() => {});

  sports.value = props.modelValue;
  onClose();
};

const handleSearchInput = (value: string): void => {
  searchValue.value = value;
};
</script>

<style lang="scss">
.wconfig-sports-multi-select {
  &__label {
    display: flex;
    align-items: center;
    gap: 4px;

    .common-icon {
      opacity: 0.88;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;
  }

  &__alert-message {
    max-width: 300px;
  }

  &__header,
  &__select-element {
    @include flex(center, space-between, 0px);
  }

  &__sport-status {
    padding: 4px 8px;
    border-radius: 4px;
    background: $c-dark-grey;
    border: 1px solid $c-grey-06;
    color: #000000e0;

    &--active {
      background: $c-light-blue;
      border: 1px solid $c-layout-inner-bg-blue;
      color: $c-primary-base;
    }
  }

  &__selected-sports-container {
    @include flex(center, flex-start, 8px, true);
  }

  &__selected-sport {
    @include flex(center, flex-start, 8px, true);
    background: $c-dark-grey;
    border: 1px solid $c-grey-06;
    color: #000000e0;
    border-radius: 4px;
    padding: 0 8px;
    width: fit-content;

    &:hover {
      border-color: $c-grey-15;
    }

    &--active {
      background: $c-light-blue;
      color: $c-primary-base;
      border: 1px solid $c-layout-inner-bg-blue;

      &:hover {
        border-color: #69b1ff;
      }
    }
  }
}
</style>
