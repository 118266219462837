import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { ArrowType } from "~~/constants/icons-mapping";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const DEFAULT_STATES: Record<string, string> = {
    widget: "prematch_event",
    live_header_view_mode_switch: State.DEFAULT,
    betting_constructor_header: State.DEFAULT,
    racing_player_cards: State.DEFAULT,
    racing_odds: State.DEFAULT,
    bet_items: State.DEFAULT,
    live_banner: State.AUTHORIZED,
    live_banner_login_button: State.DEFAULT,
    live_banner_header_value_titles: State.SMALL_SIZE,
    live_banner_header_point_title: State.SMALL_SIZE,
    live_banner_rows_team_player_info_values: State.SMALL_SIZE,
    live_banner_rows_team_player_info_values_states: State.DEFAULT,
    live_banner_rows_team_player_info_point: State.SMALL_SIZE,
    live_banner_rows_team_player_info_point_states: State.DEFAULT,
    racing_banner: State.AUTHORIZED,
    racing_banner_login_button: State.DEFAULT,
  };

  const states = ref<Record<string, string>>({ ...DEFAULT_STATES });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions();

  const init = (): void => {
    initFields();

    addFieldInitialOptions("live_header", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 12,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#1677FF"),
        spacing: {
          margin: getDefaultSpacing({
            right: 8,
            bottom: 8,
          }),
          padding: getDefaultSpacing(8),
        },
      },
    });

    addFieldInitialOptions("live_header_back_button", {
      data: {
        options: {
          _active: true,
          value: "ant-design:arrow-left-outlined",
          color: getDefaultFillValue("#ffffff"),
          size: 20,
          padding: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("live_header_sport_league_name", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          icon: {
            value: "custom:sport-icon",
            color: getDefaultFillValue("#ffffff"),
            size: 14,
            distance: 8,
          },
        },
      },
      values: {
        theme: getDefaultTheme(14),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing(0),
        color: getDefaultFillValue("#ffffff"),
      },
    });

    const switchState = (): IWidgetOptions => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        icon: {
          color: getDefaultFillValue("#1677FF"),
        },
      };
    };

    addFieldInitialOptions("live_header_view_mode_switch", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          icon: {
            streamIconValue: "ant-design:video-camera-filled",
            statsIconValue: "ant-design:bar-chart-outlined",
            color: getDefaultFillValue("#91CAFF"),
            size: 20,
          },
          states: {
            [State.HOVER]: switchState(),
            [State.ACTIVE]: switchState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        spacing: {
          padding: getDefaultSpacing(8),
          margin: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("banner", {
      data: { options: {} },
    });

    addFieldInitialOptions("betting_constructor", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          fill: getDefaultFillValue("#ebebeb"),
          padding: getDefaultSpacing(),
          margin: getDefaultSpacing({
            bottom: 24,
            top: 24,
          }),
        },
      },
    });

    const headerState = () => {
      return {
        fill: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#ffffff"),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        arrowSettings: {
          textColor: getDefaultFillValue("#ffffff"),
        },
      };
    };

    addFieldInitialOptions("betting_constructor_header", {
      data: DESIGN_INITIAL(),
      exclude: ["padding"],
      additionalData: {
        options: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          theme: 24,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
          alignment: "left",
          arrowSettings: {
            _active: true,
            default_value: ArrowType.CaretDown,
            value: ArrowType.CaretDown,
            textColor: getDefaultFillValue("#ffffff"),
            iconSize: 14,
            rotate: true,
          },
          states: {
            [State.HOVER]: headerState(),
            [State.ACTIVE]: headerState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#1677FF"),
        color: getDefaultFillValue("#ffffff"),
        theme: getDefaultTheme(20),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        spacing: {
          fill: getDefaultFillValue("#ebebeb"),
          padding: getDefaultSpacing({
            left: 20,
            right: 20,
            top: 12,
            bottom: 12,
          }),
          margin: getDefaultSpacing({
            bottom: 0,
          }),
        },
      },
    });

    addFieldInitialOptions("betting_constructor_content", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing({
            bottom: 24,
          }),
        },
      },
    });

    addFieldInitialOptions("betting_constructor_description", {
      data: { options: { padding: getDefaultSpacing() } },
    });

    addFieldInitialOptions("betting_constructor_iframe", {
      data: { options: {} },
    });

    addFieldInitialOptions("markets_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: 1,
            distance: 16,
          },
        },
      },
    });

    addFieldInitialOptions("markets_cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: 1,
            distance: 12,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          margin: getDefaultSpacing(0),
          padding: getDefaultSpacing(16),
        },
      },
    });

    addFieldInitialOptions("markets_market_name", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        color: getDefaultFillValue("#595959"),
        theme: getDefaultTheme(16),
      },
    });

    addFieldInitialOptions("markets_status", {
      data: DESIGN_INITIAL(),
      exclude: ["shadow", "padding"],
      additionalData: TITLE_INITIAL(),
      values: {
        cornerRadius: getDefaultCornerRadiusValue(4),
        theme: getDefaultTheme(13),
        color: getDefaultFillValue("#F5222D"),
        fill: getDefaultFillValue("#FFF1F0"),
        spacing: {
          padding: getDefaultSpacing({
            left: 8,
            right: 8,
            top: 2,
            bottom: 2,
          }),
          margin: getDefaultSpacing({ left: 8, right: 8 }),
        },
      },
    });

    addFieldInitialOptions("markets_pin_icon", {
      data: {
        options: {
          color: getDefaultFillValue("#1677FF"),
          size: 20,
          padding: getDefaultSpacing(),
        },
      },
    });

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      };
    };

    addFieldInitialOptions("bet_items", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          displayMode: DisplayOrientation.VERTICAL,
          distance: 8,
          fontFamily: getDefaultFontFamily(),
          padding: getDefaultSpacing(8),
          name: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [],
          },
          coef: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [TextStyle.BOLD],
          },
          arrow: {
            up: {
              color: getDefaultFillValue("#1677ff"),
            },
            down: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
          states: {
            [State.HOVER]: initialBetItemState(),
            [State.ACTIVE]: initialBetItemState(),
            [State.DISABLED]: initialBetItemState(),
            [State.UP]: initialBetItemState(),
            [State.DOWN]: initialBetItemState(),
            suspended: initialBetItemState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#e6f4ff"),
        cornerRadius: getDefaultCornerRadiusValue(8),
      },
    });

    addFieldInitialOptions("racing_event_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 16,
          },
        },
      },
      values: {
        spacing: {
          margin: getDefaultSpacing({
            bottom: 24,
            top: 24,
          }),
          padding: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("racing_title", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(16),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({
          bottom: 8,
        }),
      },
    });

    addFieldInitialOptions("racing_description", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(16),
        padding: getDefaultSpacing({
          bottom: 8,
        }),
      },
    });

    addFieldInitialOptions("racing_players_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          mode: "table",
          displayMode: {
            distanceBetweenItems: 16,
            distanceBetweenCards: 1,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#000000", 5),
      },
    });

    addFieldInitialOptions("racing_header_card", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: TITLE_INITIAL().options,
      },
      exclude: ["padding"],
      values: {
        fill: getDefaultFillValue("#ffffff"),
        theme: getDefaultTheme(14),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
    });

    const playerInfoCardStateOptions = () => {
      return {
        racing_number: {
          color: getDefaultFillValue("#000000"),
        },
        racing_player_name: {
          color: getDefaultFillValue("#000000"),
        },
        racing_last_5_races: {
          color: getDefaultFillValue("#000000"),
        },
        racing_prev_odds: {
          color: getDefaultFillValue("#000000"),
        },
        racing_odds: {
          fill: getDefaultFillValue("#E6F4FF"),
          border: getDefaultBorderValue(),
          color: getDefaultFillValue("#1677FF"),
        },
      };
    };

    addFieldInitialOptions("racing_player_cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...playerInfoCardStateOptions(),
          states: {
            [State.DISABLED]: {
              fill: getDefaultFillValue("#ffffff"),
              ...playerInfoCardStateOptions(),
            },
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("racing_number", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          iconSize: 20,
        },
      },
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("racing_player_name", {
      data: TITLE_INITIAL(),
      values: {
        decoration: [TextStyle.BOLD],
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("racing_last_5_races", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("racing_prev_odds", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(14),
      },
    });

    const initialRacingBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        color: getDefaultFillValue("#1677FF"),
        arrow: {
          up: {
            color: getDefaultFillValue("#1677ff"),
          },
          down: {
            color: getDefaultFillValue("#1677ff"),
          },
        },
      };
    };

    addFieldInitialOptions("racing_odds", {
      data: {
        options: {
          ...TITLE_INITIAL().options,
          fill: getDefaultFillValue("#E6F4FF"),
          border: getDefaultBorderValue(),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(4),
          padding: getDefaultSpacing({
            left: 0,
            right: 0,
            top: 4,
            bottom: 4,
          }),
          arrow: {
            up: {
              color: getDefaultFillValue("#1677ff"),
            },
            down: {
              color: getDefaultFillValue("#1677ff"),
            },
          },
          states: {
            [State.HOVER]: initialRacingBetItemState(),
            [State.ACTIVE]: initialRacingBetItemState(),
            [State.DISABLED]: initialRacingBetItemState(),
            [State.UP]: initialRacingBetItemState(),
            [State.DOWN]: initialRacingBetItemState(),
            [State.NOT_RUNNER]: initialRacingBetItemState(),
          },
        },
      },
      values: {
        color: getDefaultFillValue("#1677FF"),
        theme: getDefaultTheme(13),
        decoration: [],
        alignment: Alignment.CENTER,
      },
    });

    addFieldInitialOptions("no_events_title", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(14),
        alignment: Alignment.CENTER,
      },
    });

    /* Scoreboard */
    addFieldInitialOptions("live_banner", {
      data: DESIGN_INITIAL(),
      exclude: ["fill"],
      additionalData: {
        options: {
          states: {
            [State.UNAUTHORIZED]: {
              fill: getDefaultFillValue("#C0C0C0"),
            },
          },
        },
      },
      values: {
        spacing: {
          padding: getDefaultSpacing({
            left: 36,
            right: 16,
            top: 36,
            bottom: 36,
          }),
          margin: getDefaultSpacing({ bottom: 0 }),
        },
      },
    });

    addFieldInitialOptions("live_banner_event_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 8,
        },
      },
    });

    addFieldInitialOptions("live_banner_event_info_name", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(20),
        alignment: Alignment.CENTER,
        color: getDefaultFillValue("#FFFFFF"),
      },
      exclude: ["link", "margins"],
      additionalData: {
        options: {
          textShadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000"),
            blur: 5,
          }),
        },
      },
    });

    addFieldInitialOptions("live_banner_event_info_score", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 8,
          typography: {
            color: getDefaultFillValue("#FFFFFF"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#4096FF"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(4),
        },
      },
    });

    addFieldInitialOptions("live_banner_header", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 32,
        },
      },
      values: {
        fill: getDefaultFillValue("#000000"),
        spacing: {
          margin: getDefaultSpacing({
            bottom: 16,
            top: 16,
          }),
          padding: getDefaultSpacing(4),
        },
      },
    });

    addFieldInitialOptions("live_banner_header_statistics", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            resizing: ResizingType.FILL,
            alignment: Alignment.LEFT,
            width: 50,
          },
          typography: {
            color: getDefaultFillValue("#FFFFFF"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
        },
      },
    });

    addFieldInitialOptions("live_banner_header_titles", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 0,
            alignment: Alignment.LEFT,
            alignmentType: "alignment",
            justifyContent: "space-between",
          },
        },
      },
    });

    const initialHeaderValueTitlesState = () => {
      return {
        block: {
          width: 32,
          height: 32,
        },
        cell: {
          minCellWidth: 32,
          maxCellWidth: 96,
        },
        typography: {
          color: getDefaultFillValue("#FFFFFF"),
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [],
        },
      };
    };

    addFieldInitialOptions("live_banner_header_value_titles", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          states: {
            [State.SMALL_SIZE]: initialHeaderValueTitlesState(),
            [State.BIG_SIZE]: initialHeaderValueTitlesState(),
          },
        },
      },
    });

    addFieldInitialOptions("live_banner_header_point_title", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          states: {
            [State.SMALL_SIZE]: initialHeaderValueTitlesState(),
            [State.BIG_SIZE]: initialHeaderValueTitlesState(),
          },
        },
      },
    });

    addFieldInitialOptions("live_banner_rows", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 32,
        },
      },
      values: {
        fill: getDefaultFillValue("#000000", 20),
      },
    });

    addFieldInitialOptions("live_banner_rows_team_player", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            resizing: ResizingType.FILL,
            alignment: Alignment.LEFT,
            width: 50,
          },
          typography: {
            color: getDefaultFillValue("#FFFFFF"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
          },
        },
      },
    });

    addFieldInitialOptions("live_banner_rows_team_player_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            distance: 0,
            alignment: Alignment.LEFT,
            alignmentType: "alignment",
            justifyContent: "space-between",
          },
        },
      },
    });

    addFieldInitialOptions("live_banner_rows_team_player_info_values", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          winner: {
            fill: getDefaultFillValue("#000000"),
            border: getDefaultBorderValue(),
            shadow: getDefaultShadow(),
            color: getDefaultFillValue("#FFFFFF"),
          },
          states: {
            [State.SMALL_SIZE]: initialHeaderValueTitlesState(),
            [State.BIG_SIZE]: initialHeaderValueTitlesState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#000000"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#B8B8B7"),
        }),
      },
    });

    addFieldInitialOptions("live_banner_rows_team_player_info_point", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          winner: {
            fill: getDefaultFillValue("#000000"),
            border: getDefaultBorderValue(),
            shadow: getDefaultShadow(),
            color: getDefaultFillValue("#FFFFFF"),
          },
          states: {
            [State.SMALL_SIZE]: initialHeaderValueTitlesState(),
            [State.BIG_SIZE]: initialHeaderValueTitlesState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#000000"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#B8B8B7"),
        }),
      },
    });

    addFieldInitialOptions("live_banner_footer", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 8,
        },
      },
      values: {
        fill: getDefaultFillValue("#000000", 50),
        spacing: {
          margin: getDefaultSpacing({
            top: 16,
          }),
          padding: getDefaultSpacing(4),
        },
      },
    });

    addFieldInitialOptions("live_banner_footer_time", {
      data: TITLE_INITIAL(),
      values: {
        color: getDefaultFillValue("#1677FF"),
        theme: 16,
      },
      exclude: ["link", "margins", "alignment"],
      additionalData: {
        options: {
          textShadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000"),
            blur: 5,
          }),
        },
      },
    });

    addFieldInitialOptions("live_banner_footer_period", {
      data: TITLE_INITIAL(),
      values: {
        color: getDefaultFillValue("#FFFFFF"),
        theme: 16,
      },
      exclude: ["link", "margins", "alignment"],
      additionalData: {
        options: {
          textShadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000"),
            blur: 5,
          }),
        },
      },
    });

    addFieldInitialOptions("live_banner_login_text", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins"],
    });

    addFieldInitialOptions("live_banner_login_button", {
      data: BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing({ top: 4, bottom: 4, right: 16, left: 16 }),
        iconSettings: getDefaultIconSettings({ _active: false }),
      },
    });

    addFieldInitialOptions("prematch_banner", {
      data: DESIGN_INITIAL(),
      exclude: ["fill"],
      additionalData: {
        options: {
          distance: 8,
        },
      },
      values: {
        spacing: {
          margin: getDefaultSpacing({
            bottom: 16,
          }),
          padding: getDefaultSpacing(16),
        },
      },
    });

    addFieldInitialOptions("prematch_banner_event_title", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(20),
        alignment: Alignment.CENTER,
        color: getDefaultFillValue("#FFFFFF"),
      },
      exclude: ["link", "margins"],
      additionalData: {
        options: {
          textShadow: {
            fill: {
              color: null,
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
      },
    });

    addFieldInitialOptions("prematch_banner_event_time", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(18),
        alignment: Alignment.CENTER,
        color: getDefaultFillValue("#FFFFFF"),
      },
      exclude: ["link", "margins"],
      additionalData: {
        options: {
          textShadow: {
            fill: {
              color: null,
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
      },
    });

    addFieldInitialOptions("racing_banner", {
      data: DESIGN_INITIAL(),
      exclude: ["fill"],
      additionalData: {
        options: {
          states: {
            [State.UNAUTHORIZED]: {
              fill: getDefaultFillValue("#C0C0C0"),
            },
          },
        },
      },
      values: {
        spacing: {
          margin: getDefaultSpacing({
            bottom: 16,
          }),
          padding: getDefaultSpacing({
            left: 36,
            top: 36,
            right: 36,
            bottom: 36,
          }),
        },
      },
    });

    addFieldInitialOptions("racing_banner_event_description", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(18),
        alignment: Alignment.CENTER,
        color: getDefaultFillValue("#FFFFFF"),
      },
      exclude: ["link", "margins"],
      additionalData: {
        options: {
          textShadow: {
            fill: {
              color: null,
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
      },
    });

    addFieldInitialOptions("racing_banner_login_text", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("racing_banner_login_button", {
      data: BUTTON_INITIAL(),
      values: {
        padding: getDefaultSpacing({ top: 4, bottom: 4, right: 16, left: 16 }),
        iconSettings: getDefaultIconSettings({ _active: false }),
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
