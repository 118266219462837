import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { CellPosition } from "~~/models/grid.interface";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFillColorStyle,
  generateFlex,
  generateTextColor,
  generateVerticalPlacement,
} from "../helpers";
import { getFlexAlign, getFlexPosition } from "../../widget-settings";
import { getPxValueFromNumber } from "../..";

export const generateContainerPosition = (position: string): string => {
  if (!position) {
    return "";
  }

  const cellPosition = position === "fix_top" ? "sticky" : "fixed";

  return `position: ${cellPosition};left:0;width:100%;z-index:100;${generateVerticalPlacement(
    position === "fix_top" ? CellPosition.TOP : CellPosition.BOTTOM
  )}`;
};

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const isVertical = options.display.layout === DisplayOrientation.VERTICAL;
    const isWrap = options.display.layout === DisplayOrientation.WRAP;
    const alignment = getFlexAlign(
      options.display?.alignment,
      options.display.layout
    );

    const widgetStyles = generateFlex({
      flex: "flex",
      align: alignment["align-items"],
      justify: alignment["justify-content"],
      direction: isVertical ? "column" : "row",
      wrap: isWrap ? "wrap" : "nowrap",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        `overflow-x: ${options.display.horizontalOverflow};` +
        `overflow-y: ${options.display.horizontalGap};` +
        generateContainerPosition(options.display.scrolling),
    });

    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content:
        widgetStyles +
        `column-gap: ${getPxValueFromNumber(options.display.horizontalGap)};` +
        `row-gap: ${getPxValueFromNumber(options.display.horizontalGap)};`,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-mask",
      content: generateFlex({
        flex: "flex",
        align: alignment["align-items"],
        justify: alignment["justify-content"],
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-track",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .base-carousel-slide",
      content: generateFlex({
        flex: "flex",
        align: alignment["align-items"],
        justify: alignment["justify-content"],
      }),
    });

    return cssString;
  };

export const generateCarouselCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-slides",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-mask",
      content: "height: 100%;",
    });

    return cssString;
  };

export const generateArrowsCssString =
  (cssClass: string, options: IWidgetOptions) =>
  (cssString: string): string => {
    const styles =
      generateDefaultStyles(options) +
      "height: 100%;" +
      `display:flex;justify-content:center;align-items:${getFlexPosition(
        options.content.alignment
      )};`;

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName:
        " .ssr-carousel-arrows > button.ssr-carousel-back-button",
      content: styles + "left:0;",
    });

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName:
        " .ssr-carousel-arrows > button.ssr-carousel-next-button",
      content: styles + "right:0;",
    });

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName: " .ssr-carousel-arrows > button[disabled]",
      content: "display:none!important;",
    });

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName: " .base-icon",
      content:
        generateDefaultStyles(options.content) +
        `width: ${getPxValueFromNumber(
          options.content.icon.size
        )};height: ${getPxValueFromNumber(
          options.content.icon.size
        )};font-size: ${getPxValueFromNumber(
          Math.floor(options.content.icon.size / 2)
        )};${generateTextColor(options.content.icon.color)}` +
        "display:flex;align-items:center;justify-content:center;",
    });

    for (const state in options.states) {
      cssString += generateCssClassWithContent({
        className: `${cssClass} .ssr-carousel-arrows .base-icon`,
        pseudoClassName: `:${state}`,
        content: options.states[state].icon.color,
        customFunction: generateTextColor,
      });

      cssString += generateCssClassWithContent({
        className: `${cssClass} .ssr-carousel-arrows .base-icon`,
        pseudoClassName: `:${state}`,
        content: options.states[state],
        customFunction: generateDefaultStyles,
      });
    }

    return cssString;
  };

export const generatePaginationCssString =
  (cssClass: string, options: IWidgetOptions) =>
  (cssString: string): string => {
    const styles =
      generateDefaultStyles(options) +
      `display:flex;justify-content:${getFlexPosition(
        options.alignment
      )};align-items:center;gap:12px;`;

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName: " .ssr-carousel-dots",
      content: styles,
    });

    cssString += generateCssClassWithContent({
      className: cssClass,
      pseudoClassName: " .ssr-carousel-dot-icon",
      content: `opacity:0.5;` + generateFillColorStyle(options.color),
    });

    if (options.isStroke) {
      cssString += generateCssClassWithContent({
        className: cssClass,
        pseudoClassName: " .ssr-carousel-dot-icon",
        content: `width: ${getPxValueFromNumber(options.size)};height:2px;`,
      });

      cssString += generateCssClassWithContent({
        className: cssClass,
        pseudoClassName: ` .ssr-carousel-dot-icon[data-active='true']`,
        content: `opacity:1;`,
      });
    } else {
      const calc = `calc(${getPxValueFromNumber(
        options.size
      )} - (${getPxValueFromNumber(options.size)} / 3))`;

      cssString += generateCssClassWithContent({
        className: cssClass,
        pseudoClassName: " .ssr-carousel-dot-icon",
        content: `width: ${calc};height: ${calc};border-radius:50%;`,
      });

      cssString += generateCssClassWithContent({
        className: cssClass,
        pseudoClassName: ` .ssr-carousel-dot-icon[data-active='true']`,
        content: `width: ${getPxValueFromNumber(
          options.size
        )};height: ${getPxValueFromNumber(options.size)};opacity:1;`,
      });
    }

    return cssString;
  };

export const generateContainerWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}Content`);

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateCarouselCssString(widget.options),
    generateArrowsCssString(
      widget.options._cssClass,
      widget.content.arrows.options
    ),
    generatePaginationCssString(
      widget.options._cssClass,
      widget.content.pagination.options
    )
  )("");
};
