import {
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { Alignment } from "~~/models/widgets/widget-controls.model";

export const ERRORS_STYLE_INITIAL = () => ({
  title: "Field errors style",
  options: {
    _active: true,
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000"),
    }),
    color: {
      color: "#FF7875",
      opacity: 100,
    },
    theme: 13,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    alignment: Alignment.LEFT,
  },
});
