<template>
  <div
    :style="cardStyle"
    class="promoted-bets-widget__card"
    :class="[`promoted-bets-widget__card--${layoutPosition.position}`]"
  >
    <slot name="photo"></slot>

    <div>
      <WidgetField
        :widget="widget"
        :field="eventInfoField"
      >
        <div
          class="promoted-bets-widget__event-info"
          :style="eventInfoStyle"
        >
          <slot name="event_info"></slot>
        </div>
      </WidgetField>

      <slot name="bet_items"></slot>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import {
  State,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  TextPositionComplex,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  eventInfoField: IWidgetField;
  state: string;
}>();

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const layoutPosition = computed<TextPositionComplex>(() => {
  return props.field.options.display.contentPosition;
});

const containerStyles = computed<
  { [key: string]: string | undefined } | undefined
>(() => {
  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return undefined;
  }

  return {
    alignItems: layoutPosition.value.alignment || undefined,
  };
});

const cardStyle = computed<ElementStyle>(() => {
  return {
    ...containerStyles.value,
    ...getCommonStyles({
      ...props.field.options,
      ...currentStateOptions.value,
    }),
  };
});

const eventInfoStyle = computed<ElementStyle>(() => {
  const options = props.eventInfoField.options;
  const isHorizontal = options.display.layout === DisplayOrientation.HORIZONTAL;

  const direction = isHorizontal ? "row" : "column";

  return {
    ...getCommonStyles(props.eventInfoField.options),
    flexDirection: direction,
    gap: getPxValueFromNumber(options.display.distance),
    alignItems: isHorizontal ? "center" : "stretch",
  };
});
</script>

<style lang="scss">
.promoted-bets-widget {
  &__card {
    @include flex(flex-start, flex-start, 0);
    flex-direction: column;
    align-items: stretch;

    & > div {
      flex: 1;
    }

    &--top {
      flex-direction: column-reverse;
    }

    &--right {
      flex-direction: row;
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  &__event-info {
    @include flex(stretch, flex-start, 0);
  }
}
</style>
