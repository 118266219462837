import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useFormDatePickerConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Calendar
  */

  const formCalendarDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.calendar_styling.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const formCalendarContentStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.contentFill",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.contentBorder.fill",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.contentCornerRadius",
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Typography"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: fields.value.calendar_styling.field,
            options: {
              label: "Days color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.weekDaysColor",
            valueSource: fields.value.calendar_styling.field,
            options: {
              label: "Week days color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.fontFamily",
            valueSource: fields.value.calendar_styling.field,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
        ],
      },
    ];
  });

  const formCalendarStatePath = computed(() => {
    if (states.value.calendar_styling === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.calendar_styling}`;
  });

  const formCalendarStates = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.calendar_styling,
        className: "text-widget__link-settings-color",
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.selected_default,
              value: State.SELECTED_DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.inactive,
              value: State.INACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.selected_hover,
              value: State.SELECTED_HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.calendar_styling = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${formCalendarStatePath.value}.contentFill`,
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${formCalendarStatePath.value}.contentBorder.fill`,
        valueSource: fields.value.calendar_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${formCalendarStatePath.value}.color`,
            valueSource: fields.value.calendar_styling.field,
            options: {
              placeholder: "None",
              label: "Days color",
            },
          },
        ],
      },
    ];
  });

  /*
      Datepicker
    */
  const formDatePickerStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.icon",
        valueSource: fields.value.datepicker_styling.field,
        className: "group-control-element",
        options: {
          label: "Calendar icon",
          items: [
            {
              icon: "ant-design:calendar-outlined",
              value: "CalendarOutlined",
            },
            {
              icon: "ant-design:calendar-filled",
              value: "CalendarFilled",
            },
            {
              icon: "fluent:calendar-28-regular",
              value: "FluentCalendarOutlined",
            },
            {
              icon: "fluent:calendar-28-filled",
              value: "FluentCalendarFilled",
            },
          ],
          isSquareIcons: true,
          isBold: true,
        },
      },
    ];
  });

  return {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  };
};
