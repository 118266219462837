<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div
      class="basic-items-widget"
      :style="wrapperStyle"
    >
      <ItemsWidgetSettings
        v-if="selectedWidget?.id === widget.id"
        :widget="widget"
        :title-link-state="states.title"
        :items-group-state="states.items"
        @update-title-link-state="
          handleStateUpdate({ state: 'title', value: $event })
        "
        @update-items-group-state="
          handleStateUpdate({ state: 'items', value: $event })
        "
        @reset-states="resetStates"
      />

      <WidgetField
        v-if="titleField?.options._active"
        :widget="widget"
        :field="titleField"
        class="basic-items-widget__title"
        :style="titleWrapperStyle"
      >
        <SpacingHighlight
          :is-target-selected="isTitleFieldSelected"
          :padding="titleField?.options.padding"
          :margin="{ top: 0, right: 0, bottom: 0, left: 0 }"
        />

        <BasicTitleField
          :field="titleField"
          :widget="widget"
          :state="states.title"
          :options="titleField?.options"
        />
      </WidgetField>

      <div
        :style="itemsWrapperStyle"
        class="basic-items-widget__items"
      >
        <WidgetField
          v-for="itemField in itemsFields"
          :key="itemField.id"
          :field="itemField"
          :widget="widget"
          :class="{
            'basic-items-widget__field--selected': isItemsGroupSelected,
          }"
          :style="itemWrapperStyle"
        >
          <ArrayItemField
            :field="itemField"
            :state="states.items"
            :wrapper-options="menuItemsOptions"
            :items-group-options="itemsGroup?.options"
            :is-items-group-selected="isItemsGroupSelected"
          />
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";

import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  TITLE_INITIAL,
  ITEMS_GROUP_INITIAL,
  ITEM_INITIAL,
} from "~~/constants/configs/items/initial-data";
import { useWidgetWithArrayField } from "~~/composables/widgets/useWidgetWithArrayField";
import { ICell } from "~~/models/grid.interface";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import {
  getDefaultFillImage,
  getDefaultSize,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { initWidgetOptions } = useCreateFields(props.widget);

const {
  titleField,
  itemsFields,
  isItemsGroupSelected,
  wrapperStyle,
  menuItemsOptions,
  isTitleFieldSelected,
  displaySettings,
  itemsGroup,
  initItemsGroup,
} = useWidgetWithArrayField(props.widget);

const DEFAULT_STATES = {
  title: State.DEFAULT,
  items: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

initItemsGroup();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const titleWrapperStyle = computed(() => {
  return {
    justifyContent: props.widget.options.display.alignment,
  };
});

const isItemsOrientationHorizontal = computed<boolean>(
  () =>
    itemsGroup.value?.options.display?.layout === DisplayOrientation.HORIZONTAL
);

const itemsWrapperStyle = computed<CSSProperties>(() => {
  return {
    flexDirection: isItemsOrientationHorizontal.value ? "row" : "column",
    alignItems: "stretch",
    gap: `${menuItemsOptions.value.spaceBetween}px`,
    flexWrap: displaySettings.value.wrap ? "wrap" : "nowrap",
    justifyContent: displaySettings.value.wrap
      ? displaySettings.value.alignment
      : "unset",
  };
});

const itemWrapperStyle = computed<CSSProperties>(() => {
  const isGrow =
    isItemsOrientationHorizontal.value &&
    displaySettings.value.resizing === ResizingType.FILL;
  return {
    flexGrow: isGrow ? 1 : 0,
  };
});

const initialWidgetOptions = initWidgetOptions(["fill"], {
  display: { resizing: "hug", alignment: "left", wrap: false },
  fillImageColor: getDefaultFillImage(),
  bindingParams: {},
});

const initialTitleOptions = prefillEmptyOptions(
  titleField.value!.options,
  TITLE_INITIAL().options
);

const initialItemsGroupOptions = prefillEmptyOptions(
  itemsGroup.value!.options,
  getInitialOptions({
    data: ITEMS_GROUP_INITIAL(),
    values: {
      size: getDefaultSize(),
    },
    additionalData: {
      options: {
        display: {
          layout: DisplayOrientation.VERTICAL,
          elementsLayout: DisplayOrientation.HORIZONTAL,
        },
      },
    },
  }).options
);

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
widgetsStore.updateFieldOptions(titleField.value!, initialTitleOptions);
widgetsStore.updateFieldOptions(itemsGroup.value!, initialItemsGroupOptions);

itemsFields.value.forEach(itemField => {
  widgetsStore.updateFieldOptions(
    itemField,
    prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
  );
});
</script>

<style lang="scss">
.basic-items-widget {
  display: flex;
  flex-direction: column;

  &__field--selected {
    border: 2px solid rgba($c-primary-base, 1);
  }

  &__title {
    position: relative;
    display: flex;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }
}
</style>
