import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateSuccessMessageCssString,
  generateMessageCssString,
  generateResultTotalAmountsCssString,
  generateHeaderTabsCssString,
  generateCoefficientCssString,
  generateCloseIconCssString,
  generateLabelCopyIconCssString,
  generateBetslipFormCssString,
  generateResultContainerCssString,
  generateBetsContainerCssString,
  generateBetsTypeTitleCssString,
} from "../utils/betslip-helpers";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateErrorCssString,
  generateFieldsWithStatesCssString,
  generateFormCssString,
  generateIconRightCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";

export const generateBetslipWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const betAmountsContainer = widget.content["bet_amounts_container"];
  const betNameTitle = widget.content["bet_name_title"];
  const betsContainer = widget.content["bets_container"];
  const betsResultContinueButton =
    widget.content["bets_result_continue_button"];
  const betsResultSaveChoiceButton =
    widget.content["bets_result_save_choice_button"];
  const betsTypeTitle = widget.content["bets_type_title"];
  const betslipTabsContainer = widget.content["betslip_tabs_container"];
  const cardsContainer = widget.content["cards_container"];
  const closeIcon = widget.content["close_icon"];
  const coefficientTitle = widget.content["coefficient_title"];
  const eventNameTitle = widget.content["event_name_title"];
  const betslipForm = widget.content["form"];
  const headerTabsContainer = widget.content["header_tabs_container"];
  const headerTabsContainerCounters =
    widget.content["header_tabs_container_counters"];
  const liveStatusTitle = widget.content["live_status_title"];
  const marketTitle = widget.content["market_title"];
  const myBetsPrimaryButton = widget.content["my_bets_primary_button"];
  const myBetsSecondaryButton = widget.content["my_bets_secondary_button"];
  const myBetsWarningMessage = widget.content["my_bets_warning_message"];
  const noBetsDescription = widget.content["no_bets_description"];
  const notAvailableStatusTitle = widget.content["not_available_status_title"];
  const resultContainer = widget.content["result_container"];
  const resultContainerBetButton =
    widget.content["result_container_bet_button"];
  const resultContainerCopyButton =
    widget.content["result_container_copy_button"];
  const resultContainerRemoveButton =
    widget.content["result_container_remove_button"];
  const resultContainerTotalAmounts =
    widget.content["result_container_total_amounts"];
  const resultContainerWarningMessageTitle =
    widget.content["result_container_warning_message_title"];
  const successMessageTitle = widget.content["success_message_title"];
  const timeTitle = widget.content["time_title"];
  const customFields = widget.options._customFields;
  const eventContainer = widget.content["event_container"];

  // Widget class name
  widget.options._cssClass = generateClassName("betslipWidget");

  // Header class name
  headerTabsContainer.options._cssClass = generateClassName("headerContainer");
  headerTabsContainer.options.headerElementsSettings._cssClass =
    generateClassName("headerContainerElement");
  headerTabsContainerCounters.options._cssClass =
    generateClassName("headerCounters");
  betslipTabsContainer.options._cssClass = generateClassName("betslipTabs");
  betslipTabsContainer.options.headerElementsSettings._cssClass =
    generateClassName("betslipTabsElement");

  // Form class name
  betslipForm.options._cssClass = generateClassName("betslipForm");
  customFields.errors_styling.options._cssClass =
    generateClassName("errorsStyling");
  customFields.fields_styling.options._cssClass =
    generateClassName("fieldsStyling");
  customFields.labels_styling.options._cssClass =
    generateClassName("labelsStyling");
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("labelIcon");

  // Cards class name
  cardsContainer.options._cssClass = generateClassName("cardsContainer");
  liveStatusTitle.options._cssClass = generateClassName("liveStatusTitle");
  marketTitle.options._cssClass = generateClassName("marketTitle");
  notAvailableStatusTitle.options._cssClass = generateClassName("notAvailable");
  timeTitle.options._cssClass = generateClassName("timeTitle");
  betNameTitle.options._cssClass = generateClassName("betNameTitle");
  betsContainer.options._cssClass = generateClassName("betsContainer");
  betsTypeTitle.options._cssClass = generateClassName("betsTypeTitle");
  betsTypeTitle.options._systemCssClass = generateClassName(
    "betsTypeSystemTitle"
  );
  betsTypeTitle.options._multiCssClass =
    generateClassName("betsTypeMultiTitle");
  closeIcon.options._cssClass = generateClassName("closeIcon");
  coefficientTitle.options._cssClass = generateClassName(
    "coefficientTitleDefault"
  );
  coefficientTitle.options._upCssClass =
    generateClassName("coefficientTitleUp");
  coefficientTitle.options._downCssClass = generateClassName(
    "coefficientTitleDown"
  );
  eventNameTitle.options._cssClass = generateClassName("eventNameTitle");
  eventContainer.options._cssClass = generateClassName("eventContainer");

  // Messages class names
  successMessageTitle.options._cssClass = generateClassName(
    "successMessageTitle"
  );
  successMessageTitle.options.iconSettings._cssClass =
    generateClassName("successMessageIcon");
  successMessageTitle.options.printSettings._cssClass =
    generateClassName("successMessageLink");

  resultContainerWarningMessageTitle.options._cssClass =
    generateClassName("resultWarningTitle");
  resultContainerWarningMessageTitle.options.iconSettings._cssClass =
    generateClassName("resultWarningIcon");

  myBetsWarningMessage.options._cssClass = generateClassName(
    "myBetsWarningMessage"
  );
  myBetsWarningMessage.options.iconSettings._cssClass =
    generateClassName("myBetsWarningIcon");

  // Buttons class names
  myBetsPrimaryButton.options._cssClass = generateClassName(
    "myBetsPrimaryButton"
  );
  myBetsSecondaryButton.options._cssClass = generateClassName(
    "myBetsSecondaryButton"
  );
  betsResultContinueButton.options._cssClass = generateClassName(
    "betsResultContinueButton"
  );
  betsResultSaveChoiceButton.options._cssClass = generateClassName(
    "betsResultSaveChoiceButton"
  );
  resultContainerBetButton.options._cssClass = generateClassName(
    "resultContainerBetButton"
  );
  resultContainerBetButton.options._coefficientChangeCssClass =
    generateClassName("coefficientChangeResultContainerBetButton");
  resultContainerCopyButton.options._cssClass = generateClassName(
    "resultContainerCopyButton"
  );
  resultContainerRemoveButton.options._cssClass = generateClassName(
    "resultContainerRemoveButton"
  );

  // Buttons icons class names
  resultContainerRemoveButton.options.iconSettings._cssClass =
    generateClassName("resultContainerRemoveButtonIcon");
  resultContainerCopyButton.options.iconSettings._cssClass = generateClassName(
    "resultContainerCopyButtonIcon"
  );
  betsResultSaveChoiceButton.options.iconSettings._cssClass = generateClassName(
    "betsResultSaveChoiceButtonIcon"
  );
  betsResultContinueButton.options.iconSettings._cssClass = generateClassName(
    "betsResultContinueButtonIcon"
  );

  // Description class name
  noBetsDescription.options._cssClass = generateClassName("noBetsDescription");

  // Bet Amounts
  betAmountsContainer.options._rootCssClass =
    generateClassName("betAmountsRoot");
  betAmountsContainer.options._cssClass = generateClassName(
    "betAmountsContainer"
  );
  betAmountsContainer.options.labelSettings._cssClass =
    generateClassName("betAmountsLabel");
  betAmountsContainer.options.valueSettings._cssClass =
    generateClassName("betAmountsValue");

  // Total Amounts
  resultContainerTotalAmounts.options._rootCssClass = generateClassName(
    "resultRootTotalAmounts"
  );
  resultContainerTotalAmounts.options._cssClass =
    generateClassName("resultTotalAmounts");
  resultContainerTotalAmounts.options.labelSettings._cssClass =
    generateClassName("resultTotalAmountsLabel");
  resultContainerTotalAmounts.options.valueSettings._cssClass =
    generateClassName("resultTotalAmountsValue");

  // Result container class name
  resultContainer.options._cssClass = generateClassName("resultContainer");

  return pipeSync<string>(
    // Widget
    generateStringDefault(widget),
    // Buttons
    generateStageButtonCssString(myBetsPrimaryButton),
    generateStageButtonCssString(myBetsSecondaryButton),
    generateStageButtonCssString(betsResultContinueButton),
    generateStageButtonCssString(betsResultSaveChoiceButton),
    generateStageButtonCssString(resultContainerBetButton),
    generateStageButtonCssString(resultContainerCopyButton),
    generateStageButtonCssString(resultContainerRemoveButton),
    // Messages
    generateSuccessMessageCssString(successMessageTitle),
    generateMessageCssString(resultContainerWarningMessageTitle),
    generateMessageCssString(myBetsWarningMessage),
    // Description
    generateStringDefault(noBetsDescription),
    // Result
    generateResultContainerCssString(resultContainer),
    generateResultTotalAmountsCssString(resultContainerTotalAmounts),
    // Bet amounts container
    generateResultTotalAmountsCssString(betAmountsContainer),
    // Header container
    generateHeaderTabsCssString(betslipTabsContainer),
    generateHeaderTabsCssString(
      headerTabsContainer,
      headerTabsContainerCounters
    ),
    // Card container
    generateStringDefault(cardsContainer),
    generateStringDefault(betNameTitle),
    generateCloseIconCssString(closeIcon),
    generateBetsContainerCssString(betsContainer),
    generateBetsTypeTitleCssString(betsTypeTitle),
    generateStringDefault(liveStatusTitle),
    generateStringDefault(marketTitle),
    generateStringDefault(notAvailableStatusTitle),
    generateStringDefault(timeTitle),
    generateStringDefault(eventNameTitle),
    generateStringDefault(eventContainer),
    // Form container
    generateFormCssString(betslipForm, widget),
    generateBetslipFormCssString(betslipForm),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget
      // customFields.labels_styling,
      // customFields.field_success_styling
    ),
    generateLabelCopyIconCssString(customFields, betslipForm),
    // Coefficient
    generateCoefficientCssString(coefficientTitle),
    generateIconRightCssString(customFields.fields_styling.options)
  )("");
};
