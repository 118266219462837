import { Permissions } from "~~/models/stores/auth-store.model";
import { useAuthStore } from "~~/store/auth";

export const usePermissions = () => {
  const authStore = useAuthStore();

  const { permissions } = storeToRefs(authStore);

  const has = (permissionTo: Permissions): boolean => {
    if (
      import.meta.env.DEV &&
      import.meta.env.MODE.toLowerCase() === "development"
    )
      return true;
    return permissions.value[permissionTo];
  };

  return {
    Permissions,
    has,
  };
};
