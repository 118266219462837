import {
  DisplayOrientation,
  Alignment,
} from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 27;

const v27WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["BetslipFenixWidget"].includes(widget.name)) {
    const successMessageOptions = widget.content.success_message_title.options;
    if (!successMessageOptions.layout) {
      successMessageOptions.layout = DisplayOrientation.VERTICAL;
      successMessageOptions.alignment = Alignment.CENTER;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v27WidgetMigration;
