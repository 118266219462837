import { useLanguagesStore } from "~~/store/languages";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

enum V7_WIDGET_FIELD_TYPES {
  TEXT_ELEMENT = "TextInputField",
  IMAGE_ELEMENT = "FileUploadField",
  ARRAY_ELEMENT = "ArrayField",
  FORM_ELEMENT = "FormField",
  // TEXT_AREA_ELEMENT = "TextAreaField",
  // TEXT_EDITOR_ELEMENT = "TextEditorField",
  DROPDOWN_ELEMENT = "DropdownField",
  ITEMS_ELEMENT = "ItemsField",
  BUTTON_LINK_ELEMENT = "ButtonLinkField",
  // HTML_LINK_ELEMENT = "HtmlContentField",
}

const WIDGET_VERSION = 60;
//

const applyDefaultLangValue = (
  value: IWidgetField["value"]
): Record<string, any> => {
  const languagesStore = useLanguagesStore();

  const defaultLang: keyof IWidgetField["value"] =
    languagesStore.defaultLanguage!
      .codeWithRegion as keyof IWidgetField["value"];

  if (!value) {
    return {};
  }

  const defaultValue = value![defaultLang];

  for (const lang in languagesStore.availableLanguagesCodes) {
    value![lang as keyof IWidgetField["value"]] = defaultValue;
  }

  return value as Record<string, any>;
};

const isMultiLangField = (value: any) => {
  if (typeof value !== "object" || !value) {
    return false;
  }

  const languagesStore = useLanguagesStore();

  const defaultLang: keyof IWidgetField["value"] =
    languagesStore.defaultLanguage!
      .codeWithRegion as keyof IWidgetField["value"];

  if (!Object.hasOwn(value, defaultLang)) {
    return false;
  }

  return true;
};

const updateOptionsLangValue = (options: IWidgetOptions): void => {
  for (const optionName in options) {
    if (isMultiLangField(options[optionName])) {
      options[optionName] = applyDefaultLangValue(options[optionName]);
      continue;
    }

    if (typeof options[optionName] === "object" && options[optionName]) {
      updateOptionsLangValue(options[optionName]);
    }
  }
};

const v50WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    window.location.hostname !== "grid.bavtest4.net" &&
    window.location.hostname !== "local.bavtest4.net" &&
    window.location.hostname !== "grid.bavtest2.net"
  ) {
    return widget;
  }

  if (widget.options.breadcrumbs) {
    widget.options.breadcrumbs.forEach(
      (item: {
        title: { value: Record<string, string> };
        link: { value: Record<string, string> };
      }) => {
        item.title.value = applyDefaultLangValue(item.title.value);
        item.link.value = applyDefaultLangValue(item.link.value);
      }
    );
  }

  if (widget.options.bindingParams) {
    Object.keys(widget.options.bindingParams).forEach(paramName => {
      const currParam = widget.options.bindingParams[paramName];

      if (typeof currParam.value === "object" && currParam.value) {
        currParam.value = applyDefaultLangValue(currParam.value);
      }

      if (typeof currParam.children === "object" && currParam.children) {
        currParam.children = applyDefaultLangValue(currParam.children);
      }
    });
  }

  Object.values(widget.content).forEach((field: any) => {
    if (field.options._seo) {
      if (field.options._seo.title) {
        field.options._seo.title = applyDefaultLangValue(
          field.options._seo.title
        );
      }

      if (field.options._seo.alt) {
        field.options._seo.alt = applyDefaultLangValue(field.options._seo.alt);
      }
    }

    if (field.type === "UnknownField") {
      updateOptionsLangValue(field.options);
    } else if (
      [
        V7_WIDGET_FIELD_TYPES.IMAGE_ELEMENT,
        V7_WIDGET_FIELD_TYPES.TEXT_ELEMENT,
        // V7_WIDGET_FIELD_TYPES.TEXT_EDITOR_ELEMENT,
        V7_WIDGET_FIELD_TYPES.DROPDOWN_ELEMENT,
        // V7_WIDGET_FIELD_TYPES.TEXT_AREA_ELEMENT,
        V7_WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
        // V7_WIDGET_FIELD_TYPES.HTML_LINK_ELEMENT,
      ].includes(field.type)
    ) {
      field.value = applyDefaultLangValue(field.value);

      if (field.options.link) {
        if (field.options.link.value) {
          field.options.link.value = applyDefaultLangValue(
            field.options.link.value
          );
        }

        if (field.options.link.authorizeValue) {
          field.options.link.authorizeValue = applyDefaultLangValue(
            field.options.link.authorizeValue
          );
        }
      }
    } else if (
      [
        V7_WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
        V7_WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
      ].includes(field.type)
    ) {
      const itemsFieldValue =
        Array.isArray(field.value) && field.value[0]?.fields;
      if (Array.isArray(itemsFieldValue)) {
        itemsFieldValue.forEach((item: any) => {
          item.value = applyDefaultLangValue(item.value);
        });
      }
    } else if ([V7_WIDGET_FIELD_TYPES.FORM_ELEMENT].includes(field.type)) {
      Object.values(field?.value?.fields || {}).forEach((formField: any) => {
        // Localize form field items
        if (Array.isArray(formField.options.items)) {
          formField.options.items.forEach((formFieldItem: any) => {
            formFieldItem.label = applyDefaultLangValue(formFieldItem.label);
          });
        }

        // Localize form field label
        if ("label" in formField.options) {
          formField.options.label.value = applyDefaultLangValue(
            formField.options.label.value
          );
        }

        // Localize form field placeholder

        if ("placeholder" in formField.options) {
          formField.options.placeholder.value = applyDefaultLangValue(
            formField.options.placeholder.value
          );
        }

        // Localize form field tooltip
        if ("tooltip" in formField.options) {
          formField.options.tooltip.value = applyDefaultLangValue(
            formField.options.tooltip.value
          );
        }
      });
    }
  });

  return widget;
};
export default v50WidgetMigration;
