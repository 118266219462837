<template>
  <div :style="containerStyles">
    <WidgetField
      :widget="widget"
      :field="leagueField"
    >
      <div
        :style="itemsContainerStyles"
        class="sportsbbok-bet-9__leagues"
      >
        <div
          v-for="i in 9"
          :key="i"
          :style="itemStyles"
        >
          League Name
        </div>
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { State } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getBackgroundFillStyle } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  leagueField: IWidgetField;
  state: State;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...getBackgroundFillStyle(props.field.options.fillImageColor),
  };
});

const itemsContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.display.distance),
    "grid-template-columns": `repeat(${props.field.options.display.layout}, 1fr)`,
  };
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.leagueField.options;
  }

  return props.leagueField.options.states[props.state];
});

const itemStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...props.leagueField.options,
      ...activeStateOptions.value,
    }),
    ...getTextCommonStyles({
      ...props.leagueField.options,
      ...activeStateOptions.value,
    }),
  };
});
</script>

<style lang="scss">
.sportsbbok-bet-9 {
  &__leagues {
    display: grid;
  }
}
</style>
