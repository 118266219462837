import { getDefaultBorderValue } from "~~/constants/configs/common/widget-initial";
import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";
import { State } from "~~/models/widgets/widget-controls.model";

const WIDGET_VERSION = 3;

const generateCalendarCellDisabledState = () => ({
  contentFill: {
    color: null,
    opacity: 100,
  },
  contentBorder: getDefaultBorderValue(),
  color: {
    color: "#000000",
    opacity: 25,
  },
});

const v3WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  const calendarStyling = widget.options?._customFields?.calendar_styling;
  if (calendarStyling && !calendarStyling.options.states[State.DISABLED]) {
    calendarStyling.options.states[State.DISABLED] =
      generateCalendarCellDisabledState();
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v3WidgetMigration;
