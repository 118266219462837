import { type Ref } from "vue";

import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import {
  ButtonWithoutIconDesign,
  ButtonWithIconContent,
  ButtonWithoutIconContent,
  ButtonStatesControls,
  ButtonWithIconDesign,
} from "./betslipButtonsControlsHelpers";

export const useBetslipButtonsControls = (
  states: Ref<Record<string, State>>,
  emit: any,
  statesValuePath: ComputedRef<string>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const actionButtonDesignControls = computed(() =>
    ButtonWithoutIconDesign(selectedField.value)
  );

  const primaryButtonDesignControls = computed(() =>
    ButtonWithoutIconDesign(selectedField.value)
  );

  const secondaryButtonDesignControls = computed(() =>
    ButtonWithoutIconDesign(selectedField.value)
  );

  const removeButtonContentControls = computed(() => {
    return ButtonWithIconContent(selectedField.value);
  });

  const copyButtonContentControls = computed(() => {
    return [
      ...ButtonWithIconContent(selectedField.value),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
    ];
  });

  const saveChoiseButtonContentControls = computed(() => {
    return ButtonWithIconContent(selectedField.value);
  });

  const continueButtonContentControls = computed(() => {
    return ButtonWithIconContent(selectedField.value);
  });

  const actionButtonContentControls = computed(() =>
    ButtonWithoutIconContent(selectedField.value)
  );

  const secondaryButtonContentControls = computed(() =>
    ButtonWithoutIconContent(selectedField.value)
  );

  const primaryButtonContentControls = computed(() =>
    ButtonWithoutIconContent(selectedField.value)
  );

  const actionButtonStatesControls = computed(() =>
    ButtonStatesControls(states, emit, statesValuePath, selectedField)
  );

  const primaryButtonStatesControls = computed(() =>
    ButtonStatesControls(states, emit, statesValuePath, selectedField)
  );

  const secondaryButtonStatesControls = computed(() =>
    ButtonStatesControls(states, emit, statesValuePath, selectedField)
  );

  const removeButtonDesignControls = computed(() =>
    ButtonWithoutIconDesign(selectedField.value)
  );

  const copyButtonDesignControls = computed(() =>
    ButtonWithIconDesign(selectedField.value)
  );

  const copyButtonStatesControls = computed(() => {
    const result = ButtonStatesControls(
      states,
      emit,
      statesValuePath,
      selectedField
    );
    result.push({
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${statesValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    });

    return result;
  });

  const saveChoiseButtonDesignControls = computed(() =>
    ButtonWithIconDesign(selectedField.value)
  );

  const continueButtonDesignControls = computed(() =>
    ButtonWithIconDesign(selectedField.value)
  );

  const removeButtonStatesControls = computed(() => {
    const result = ButtonStatesControls(
      states,
      emit,
      statesValuePath,
      selectedField
    );
    result.push({
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${statesValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    });

    return result;
  });

  const saveChoiseButtonStatesControls = computed(() => {
    const result = ButtonStatesControls(
      states,
      emit,
      statesValuePath,
      selectedField
    );
    result.push({
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${statesValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    });

    return result;
  });

  const continueButtonStatesControls = computed(() => {
    const result = ButtonStatesControls(
      states,
      emit,
      statesValuePath,
      selectedField
    );
    result.push({
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${statesValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    });

    return result;
  });

  return {
    actionButtonDesignControls,
    actionButtonContentControls,
    actionButtonStatesControls,

    removeButtonDesignControls,
    removeButtonContentControls,
    removeButtonStatesControls,

    copyButtonDesignControls,
    copyButtonContentControls,
    copyButtonStatesControls,

    saveChoiseButtonDesignControls,
    saveChoiseButtonContentControls,
    saveChoiseButtonStatesControls,

    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,

    primaryButtonDesignControls,
    primaryButtonContentControls,
    primaryButtonStatesControls,

    secondaryButtonDesignControls,
    secondaryButtonContentControls,
    secondaryButtonStatesControls,
  };
};
