import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Align,
  Alignment,
  DisplayOrientation,
  SliderArrowIcon,
  SliderTransition,
  State,
  ContainerMode,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSize,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const { initFields, initWidgetOptions, addFieldInitialOptions } =
    useCreateFields(widget.value);

  const DEFAULT_STATES: Record<string, string> = {
    arrows: State.DEFAULT,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const widgetOptions = initWidgetOptions(
    [],
    {
      display: {
        mode: ContainerMode.FLEX,
        layout: DisplayOrientation.HORIZONTAL,
        verticalGap: 0,
        horizontalGap: 0,
        alignment: `${Align.MIDDLE} ${Align.MIDDLE}`,
        horizontalOverflow: "hidden",
        verticalOverflow: "hidden",
        scrolling: "",
      },
      slider: {
        transition: SliderTransition.ALL,
        infinite: false,
        auto: false,
        delay: 7,
      },
    },
    {
      fill: getDefaultFillValue("#ffffff"),
      size: {
        width: {
          ...getDefaultSize().width,
          minMax: {
            _active: false,
            min: null,
            max: null,
            mode: "min",
          },
        },
        height: {
          ...getDefaultSize().height,
          minMax: {
            _active: false,
            min: null,
            max: null,
            mode: "min",
          },
        },
      },
    }
  );

  const contentInitial = (): IWidgetOptions => {
    const initialDesign = DESIGN_INITIAL().options as IWidgetOptions;

    delete initialDesign.spacing;

    return {
      ...initialDesign,
      cornerRadius: getDefaultCornerRadiusValue(50),
      fill: getDefaultFillValue("#ffffff"),
    };
  };

  const init = (): void => {
    initFields();

    addFieldInitialOptions("arrows", {
      data: DESIGN_INITIAL(),
      values: {
        spacing: {
          padding: getDefaultSpacing(12),
          margin: 0,
        },
      },
      additionalData: {
        options: {
          content: {
            ...contentInitial(),
            alignment: Alignment.CENTER,
            icon: {
              size: 24,
              value: SliderArrowIcon.OUTLINED,
              color: getDefaultFillValue("#1677FF"),
            },
          },
          states: {
            [State.HOVER]: {
              fill: getDefaultFillValue(""),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
              icon: {
                color: getDefaultFillValue(),
              },
            },
            [State.ACTIVE]: {
              fill: getDefaultFillValue(""),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
              icon: {
                color: getDefaultFillValue(),
              },
            },
          },
        },
      },
    });

    addFieldInitialOptions("pagination", {
      data: DESIGN_INITIAL(),
      values: {
        spacing: {
          padding: getDefaultSpacing(12),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          isStroke: false,
          size: 12,
          color: getDefaultFillValue("#1677FF"),
          alignment: Alignment.CENTER,
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
