import { type Ref } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  INITIAL_STATES,
  DYNAMIC_FIELD_OPTIONS,
} from "~~/constants/widget-details/form/dynamic-fields";
import {
  generateSpecificFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { getDefaultFillImage } from "~~/constants/configs/common/widget-initial";
import { DEFAULT_FORM_WIDTH } from "~~/constants/widget-config";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";

export const useNewForm = (data: {
  widget: Ref<IWidgetWithFields>;
  screens: string[];
  formElements: Record<string, string[]>;
  initialView: string;
  initialStates?: Record<string, string>;
  ignoreDefaultFormStyles?: string[];
}) => {
  const { widget, screens, formElements, initialView, initialStates } = data;

  const widgetsStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const { widgetCacheData } = useWidgetCacheData(widget.value);
  const fields = useWidgetFields(widget.value);
  const { initWidgetOptions, initFields } = useCreateFields(widget.value);
  const { selectedDropdownItem, selectedWidget } =
    storeToRefs(widgetSettingsStore);

  const currentInitialStates = computed<Record<string, string>>(() => {
    if (initialStates) {
      return {
        ...INITIAL_STATES,
        ...initialStates,
      };
    }

    return INITIAL_STATES;
  });

  const currentStates = ref<Record<string, string>>({
    ...currentInitialStates.value,
  });

  const { resetStates, handleStateUpdate } = useStates({
    defaultStates: currentInitialStates.value,
    states: currentStates,
  });

  const activeView = ref(initialView);

  const isWholeWidgetSelected = computed(() => {
    if (selectedWidget.value?.id !== widget.value.id) {
      return false;
    }

    return !!STYLING_FIELDS[selectedDropdownItem.value as string];
  });

  const handleUpdateActiveView = (value: string): void => {
    activeView.value = value;
  };

  const initialStateOptions = screens.reduce((res, curr) => {
    return {
      ...res,
      [curr]: {
        contentWidth: {
          type: "fixed",
          width: DEFAULT_FORM_WIDTH,
        },
      },
    };
  }, {});

  const initFormElementsOptions = () => {
    for (const formName in formElements) {
      const formCustomElements = formElements[formName];

      /* 
        Prefill custom form fields options(fields, which are not located inside form)
      */
      formCustomElements.forEach(elementName => {
        const currField = fields.value[elementName].field;

        const initial = DYNAMIC_FIELD_OPTIONS[currField.type](
          {
            ...currField,
            placeholder: currField.placeholder || "",
            label: currField.title || "",
          },
          formName
        );

        widgetsStore.updateFieldOptions(currField, {
          ...initial.options,
          position: initial.options.position || 0,
        });
      });

      /* 
        Prefill inner form fields
      */
      const parentForm = widgetCacheData.value?.fields?.[formName]
        ?.value as InnerFieldValue;

      for (const innerFormFieldName in parentForm.fields) {
        const currField = fields.value[innerFormFieldName].field;
        const parentFormData = parentForm.fields[currField.name];

        const initial = DYNAMIC_FIELD_OPTIONS[currField.type](
          {
            ...currField,
            placeholder:
              currField.placeholder || parentFormData?.placeholder || "",
            label: currField.title || parentFormData?.label || "",
          },
          formName
        );

        widgetsStore.updateFieldOptions(currField, {
          ...initial.options,
          position: initial.options.position || 0,
        });
      }
    }
  };

  const initWidget = (): IWidgetOptions => {
    const widgetOptions = initWidgetOptions(["fill"], {
      fillImageColor: getDefaultFillImage(),
      verticalSpace: 0,
      states: {
        ...initialStateOptions,
      },
    });

    initFields({ skipFormFields: true });

    let fieldsResult: Array<IWidgetField> = [];

    let formInnerFields: IWidgetField[] = [];
    let customWidgetFields: IWidgetField[] = [];

    if (!widget.value.options || !Object.keys(widget.value.options).length) {
      formInnerFields = generateSpecificFormFields({
        widget: widget.value,
        formElements: formElements,
        widgetCacheData,
      });
    } else {
      customWidgetFields = prefillCustomFields(
        widget.value.options,
        widget.value.id
      );
    }

    if (!widget.value.fields.find(field => field.options._custom)) {
      fieldsResult = [
        ...widget.value.fields,
        ...formInnerFields,
        ...customWidgetFields,
      ];
    } else {
      fieldsResult = [...widget.value.fields, ...formInnerFields];
    }

    widgetsStore.updateWidgetFields(widget.value, fieldsResult);
    initFormElementsOptions();

    return widgetOptions;
  };

  const handleResetStates = () => {
    resetStates();
  };

  return {
    currentStates,
    isWholeWidgetSelected,
    handleResetStates,
    handleUpdateActiveView,
    activeView,
    handleStateUpdate,
    initWidget,
  };
};
