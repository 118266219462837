import { useUiStore } from "~~/store/ui";
import { Breakpoint } from "~~/models/breakpoints.model";

const store = useUiStore();

const { activeScreen } = storeToRefs(store);

export const useFilteredColumnsIcons = computed<string[]>(() => {
  const columnIcons = [
    "custom:one-column",
    "custom:two-columns",
    "custom:three-columns",
  ];

  if (activeScreen.value === Breakpoint.DESKTOP) {
    return columnIcons;
  } else if (activeScreen.value === Breakpoint.TABLET) {
    return columnIcons.slice(0, 2);
  } else {
    return [];
  }
});
