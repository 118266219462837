<template>
  <div
    :style="containerStyles"
    class="coming-events-widget__cards"
  >
    <div
      v-for="item in 5"
      :key="item"
    >
      <div
        :style="cardStyles"
        class="coming-events-widget__card-container"
      >
        <slot name="time"></slot>
        <slot name="team"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getSpacing } from "~~/assets/utils/widget-settings";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  field: IWidgetField;
  state: string;
}>();

const containerStyles = computed<ElementStyle>(() => {
  const { spacing } = props.field.options.card;

  return {
    "display": "grid",
    "grid-template-columns": `repeat(${props.field.options.columns}, minmax(0, 1fr))`,
    "column-gap": getPxValueFromNumber(
      props.field.options.distanceBetweenCards
    ),
    "row-gap": getPxValueFromNumber(props.field.options.distanceBetweenCards),
    ...getSpacing(spacing.margin, SpacingKeyName.MARGIN),
  };
});

const itemsContainerStyles = computed<ElementStyle>(() => {
  const flexDirection =
    props.field.options.items.displayMode === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  return {
    display: "flex",
    gap: getPxValueFromNumber(props.field.options.items.distance),
    flexDirection,
    alignItems: flexDirection === "row" ? "center" : "stretch",
  };
});

const cardOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options.card;
  }

  return {
    ...props.field.options.card,
    ...props.field.options.states[props.state],
  };
});

const cardStyles = computed<ElementStyle>(() => {
  const { spacing } = props.field.options.card;

  return {
    ...getSpacing(spacing.padding, SpacingKeyName.PADDING),
    ...getCommonStyles(cardOptions.value),
    ...itemsContainerStyles.value,
  };
});
</script>

<style lang="scss" scoped>
.bets-list-events {
  display: grid;
  overflow: hidden;

  &__time-container {
    min-width: fit-content;
  }

  &__time {
    @include flex(flex-start, flex-start, 8px);
    margin-right: 16px;
  }

  &__time-element {
    @include flex(center, flex-start, 4px);
  }

  &__items-container {
    @include flex(center, flex-start, 0);
  }

  &__items-list {
    @include flex(flex-start, flex-start, 0);
    flex-grow: 1;
  }

  &__item {
    flex: 1;
    width: 0;
  }

  &__button {
    display: inline-block;
    padding: 4px 8px;
  }
}
</style>
