import { computed, type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/casino-game/useCasinoGameItems";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useTitleControls } from "~~/composables/widgets/items/useTitleControls";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useLinkStatesControls } from "~~/composables/widgets/common/useLinkStatesControls";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems();

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.displaySettings.layout",
            valueSource: widget.value,
            className:
              "group-control-element event-cards-radio casino-game-config-layout-icons",
            options: {
              label: "Layout",
              items: [
                { icon: "custom:casino-game-layout-top", value: "top" },
                { icon: "custom:casino-game-layout-bottom", value: "bottom" },
              ],
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
    Iframe
  */

  const iframeControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.iframe.field,
        except: ["ConfigSpacingInputs"],
        elements: [
          [
            4,
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: `options.padding`,
              valueSource: fields.value.iframe.field,
              options: {
                placeholder: "None",
                label: "Padding",
                meta: {
                  isBold: true,
                },
              },
            },
          ],
        ],
      }),
    ];
  });

  /* 
    Play toggler
  */

  const playTogglerDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.play_toggler.field,
        except: ["ConfigSpacingInputs"],
        elements: [
          [
            4,
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: `options.padding`,
              valueSource: fields.value.play_toggler.field,
              options: {
                placeholder: "None",
                label: "Padding",
                meta: {
                  isBold: true,
                },
              },
            },
          ],
        ],
      }),
    ];
  });

  const togglerStatePath = computed<string>(() => {
    return `options.states.${states.value.play_toggler}`;
  });

  const playTogglerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.play_toggler,
        options: {
          items: [
            {
              label: "Play for free",
              value: "free",
            },
            {
              label: "Play for money",
              value: "money",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "play_toggler",
            value,
          });
        },
      },
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: `${togglerStatePath.value}.displaySettings.layout`,
            valueSource: fields.value.play_toggler.field,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: `${togglerStatePath.value}.displaySettings.gap`,
            valueSource: fields.value.play_toggler.field,
            className: "group-control-element",
            options: {
              label: "Distance between bets items",
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: `${togglerStatePath.value}.displaySettings.alignment`,
            valueSource: fields.value.play_toggler.field,
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigTextInputWithSettings",
        valuePath: `${togglerStatePath.value}.text`,
        valueSource: fields.value.play_toggler.field,
        options: {
          isBold: true,
          label: "Text",
          itemName: "play_toggler_title",
          placeholder: "Enter",
        },
      },
      {
        componentPath: "content/ConfigTextInputWithSettings",
        valuePath: `${togglerStatePath.value}.button`,
        valueSource: fields.value.play_toggler.field,
        options: {
          isBold: true,
          label: "Button",
          placeholder: "Enter",
          itemName: "play_toggler_button",
        },
      },
    ];
  });

  const textValuePath = computed<string>(() => {
    return `${togglerStatePath.value}.text`;
  });

  const linkState = computed<string>(() => {
    return states.value.title;
  });

  const toggleTextElementsConfig = computed<ControlProp>(() => {
    return {
      componentPath: "content/ConfigTextInput",
      valuePath: textValuePath.value,
      valueSource: fields.value.play_toggler.field,
      options: {
        placeholder: "Enter text",
        label: "",
        active: true,
      },
    };
  });

  /* 
    Text
  */
  const { titleControls } = useTitleControls(
    {
      field: toRef(fields.value.play_toggler_title.field),
      state: linkState,
      label: "",
      except: ["content/ConfigTextInput"],
    },
    emit
  );

  const stateTitleControls = computed<ControlProp[]>(() => {
    return [toggleTextElementsConfig.value, ...titleControls.value];
  });

  const { titleLinkStatesControls } = useLinkStatesControls(
    { state: linkState, field: toRef(fields.value.play_toggler_title.field) },
    emit
  );

  /* 
    Button
  */

  const buttonValuePath = computed<string>(() => {
    return `${togglerStatePath.value}.button`;
  });

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: toRef(() => states.value.play_toggler_button),
        stateHandler: (state: State) => {
          emit("update-state", { state: "play_toggler_button", value: state });
        },
        valuePath: buttonValuePath,
        valueSource: toRef(fields.value.play_toggler.field),
        exclude: {
          content: ["ConfigButtonDisplaySettings", "ConfigLinkType"],
        },
      },
      emit
    );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    iframeControls,

    playTogglerDesignControls,
    playTogglerContentControls,

    stateTitleControls,
    titleLinkStatesControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
  };
};
