<template>
  <div
    v-if="
      states[widget.name] === State.BETSLIP ||
      states[widget.name] === State.MY_BETS ||
      states[widget.name] === State.BETS_RESULT
    "
    class="betslip-widget__tabs-container"
  >
    <WidgetField
      v-if="fields.betslip_tabs_container.active"
      :widget="widget"
      :field="fields.betslip_tabs_container.field"
    >
      <div
        v-if="states[widget.name] === State.BETSLIP"
        class="betslip-widget__header-items-container"
        :style="tabsContainerInlineStyles"
      >
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.single")
          }}</span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.multi")
          }}</span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.system")
          }}</span>
        </div>
      </div>
      <div
        v-else-if="
          states[widget.name] === State.MY_BETS ||
          states[widget.name] === State.BETS_RESULT
        "
        class="betslip-widget__header-items-container"
        :style="tabsContainerInlineStyles"
      >
        <div
          class="betslip-widget__header-item betslip-cash-out w-100"
          :style="tabsAlignmentStyles"
        >
          <span
            class="flex items-center"
            :style="tabsContentInlineStyles"
          >
            {{ t("widgets.BetslipWidget.cash_out") }}

            <WidgetField
              :widget="widget"
              :field="fields.cashout_tooltip.field"
            >
              <a-tooltip
                :overlay-style="tooltipStyles"
                :visible="
                  selectedField?.name === fields.cashout_tooltip.field.name
                "
                :get-popup-container="getMenuContainer"
                placement="bottom"
                overlay-class-name="betslip-cash-out__tooltip"
              >
                <template #title> Tooltip text </template>

                <span v-if="tooltipIconComponent">
                  <CommonIcon
                    :name="tooltipIconComponent"
                    :style="tooltipIconStyle"
                  />
                </span>
              </a-tooltip>
            </WidgetField>
          </span>
        </div>
        <div
          class="betslip-widget__header-item w-100"
          :style="tabsAlignmentStyles"
        >
          <span :style="tabsContentInlineStyles">{{
            t("widgets.BetslipWidget.all_bets")
          }}</span>
        </div>
      </div>
    </WidgetField>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { IFillControl, State } from "~~/models/widgets/widget-controls.model";
import { useBetslipTabsInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipTabsInlineStyles";
import { ElementStyle, StyleValue } from "~~/models/common";
import {
  getColorFromHex,
  getColorStyle,
} from "~~/assets/utils/widget-settings";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { WIDGETS_GRID_ID } from "~~/constants/widget-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
}>();

const { t } = useI18n();

const {
  tabsContainerInlineStyles,
  tabsAlignmentStyles,
  tabsContentInlineStyles,
} = useBetslipTabsInlineStyles(ref(props.fields), ref(props.states));

const { selectedField } = storeToRefs(useWidgetSettingsStore());

const tooltipStyles = computed<ElementStyle>(() => {
  const textColor = getColorFromHex(
    props.fields.cashout_tooltip.field.options.color
  ) as StyleValue;

  const styles = {
    ...getCommonStyles(props.fields.cashout_tooltip.field.options),
    ...getTextCommonStyles(props.fields.cashout_tooltip.field.options),
    textColor,
  };

  return getStylesAsVars(styles);
});

const tooltipIconStyle = computed<ElementStyle>(() => {
  if (!tooltipIconOptions.value || !props.fields.cashout_tooltip.field) {
    return {};
  }

  return {
    ...getColorStyle(tooltipIconOptions.value.color),
    marginLeft: getPxValueFromNumber(tooltipIconOptions.value.gap),
  };
});

const getMenuContainer = (): HTMLElement | null => {
  return document.querySelector(`#${WIDGETS_GRID_ID}`);
};

const tooltipIconOptions = computed<{
  icon: string;
  color: IFillControl;
  gap: number;
} | null>(() => {
  if (!props.fields.cashout_tooltip.field) {
    return null;
  }

  return props.fields.cashout_tooltip.field.options.textChooseIcon;
});

const tooltipIconComponent = computed<string | null>(() => {
  if (!tooltipIconOptions.value) {
    return null;
  }

  return tooltipIconOptions.value.icon || "ant-design:question-circle-outlined";
});
</script>

<style lang="scss">
.betslip-cash-out {
  @include flex(center, flex-start, 0);

  &__tooltip {
    font-size: 12px;
    flex-grow: 1;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: var(--backgroundColor) !important;
        border-radius: var(--borderRadius) !important;
        border-style: var(--borderStyle) !important;
        border-width: var(--borderWidth) !important;
        border-color: var(--borderColor) !important;
        box-shadow: var(--boxShadow) !important;
        color: var(--textColor) !important;
        padding-bottom: var(--padding-bottom) !important;
        padding-left: var(--padding-left) !important;
        padding-right: var(--padding-right) !important;
        padding-top: var(--padding-top) !important;
        font-size: var(--fontSize) !important;
        font-style: var(--fontStyle);
        text-decoration: var(--textDecoration);
        text-align: var(--textAlign);
        font-weight: var(--fontWeight, 400);
      }

      .ant-tooltip-arrow-content {
        background-color: var(--backgroundColor) !important;
      }
    }
    &.remove-arrow {
      &[class*="ant-tooltip-placement"] {
        padding: 0 !important;
        margin-top: 10px !important;
      }
      .ant-tooltip-arrow-content {
        display: none;
      }
    }
  }
}
</style>
