<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew :tabs="TABS">
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useHtmlEditorConfig } from "~~/composables/widgets/html-editor/useHtmlEditorWidgetConfig";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

const TABS = {
  widgetSettings: [
    generateTabItem("Design", "design"),
    generateTabItem("Content", "content"),
  ],
};

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const { widgetDesignControls, widgetContentControls } = useHtmlEditorConfig(
  ref(props.widget)
);
</script>
