import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 8;

const v8WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  const { transformToLocalizedValue } = useLocalizedValue();

  const additionalContent = {
    button_1: {
      name: "button_1",
      options: {},
      type: "ButtonLinkField",
      value: transformToLocalizedValue("This is default description")
        .localizedValue,
    },
    button_2: {
      name: "button_2",
      options: {},
      type: "ButtonLinkField",
      value: transformToLocalizedValue("This is default description")
        .localizedValue,
    },
    buttons_group: {
      name: "buttons_group",
      options: {},
      type: "UnknownField",
    },
  };

  if (widget.name === "CardWidget" && !widget.content["buttons_group"]) {
    widget.content = { ...widget.content, ...additionalContent };
  } else if (
    widget.name === "BannerSliderWidget" &&
    !widget.content.items.value[0].fields.some(
      (field: any) => field.name === "buttons_group"
    )
  ) {
    widget.content.items.value.forEach((item: any) => {
      item.fields.push(...Object.values(additionalContent));
    });
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v8WidgetMigration;
