import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

const WIDGET_CONTENT_ITEMS = [
  {
    label: "Breadcrumb Link",
    value: "links",
  },
  {
    label: "Breadcrumb Separator",
    value: "separators",
  },
];

export const useBreadcrumbsItems = () => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Breadcrumb Link", "links"),
    generateDropdownItem("Breadcrumb Separator", "separators"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...DEFAULT_DROPDOWN_ITEMS];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    WIDGET_CONTENT_ITEMS,
  };
};
