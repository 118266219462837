<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <FakeWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="widget-name">
      <WidgetField
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField
          :field="fields.title.field"
          value="Hello, world!"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/fake/useFakeInit";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = [""];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();
</script>

<style lang="scss">
.widget-name {
  position: relative;
}
</style>
