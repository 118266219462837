<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="pageTitleControls"
          />
        </template>

        <template #filter_title>
          <ControlsList
            :widget="widget"
            :controls="filterTitleControls"
          />
        </template>

        <template #pending_description>
          <ControlsList
            :widget="widget"
            :controls="descriptionControls"
          />
        </template>

        <template #filter-design>
          <ControlsList
            :widget="widget"
            :controls="filterDesignControls"
          />
        </template>

        <template #filter-content>
          <ControlsList
            :widget="widget"
            :controls="filterContentControls"
          />
        </template>

        <template #form-design>
          <ControlsList
            :widget="widget"
            :controls="filterContainerDesignControls"
          />
        </template>

        <template #form-content>
          <ControlsList
            :widget="widget"
            :controls="filterContainerContentControls"
          />
        </template>

        <template #form-styles>
          <ControlsList
            :widget="widget"
            :controls="filterContainerStyleControls"
          />
        </template>

        <template #date_filter-design>
          <ControlsList
            :widget="widget"
            :controls="dateFilterDesignControls"
          />
        </template>

        <template #date_filter-content>
          <ControlsList
            :widget="widget"
            :controls="dateFilterContentControls"
          />
        </template>

        <template #transactions_filter-design>
          <ControlsList
            :widget="widget"
            :controls="transactionFilterDesignControls"
          />
        </template>

        <template #transactions_filter-content>
          <ControlsList
            :widget="widget"
            :controls="transactionFilterContentControls"
          />
        </template>

        <template #date_filter_title>
          <ControlsList
            :widget="widget"
            :controls="dateFilterTitleControls"
          />
        </template>

        <template #transactions_filter_title>
          <ControlsList
            :widget="widget"
            :controls="transactionsFilterTitleControls"
          />
        </template>

        <template #table-design>
          <ControlsList
            :widget="widget"
            :controls="tableDesignControls"
          />
        </template>

        <template #table-content>
          <ControlsList
            :widget="widget"
            :controls="tableContentControls"
          />
        </template>

        <template #table_header_settings-design>
          <ControlsList
            :widget="widget"
            :controls="tableHeaderDesignControls"
          />
        </template>

        <template #table_header_settings-content>
          <ControlsList
            :widget="widget"
            :controls="tableHeaderContentControls"
          />
        </template>

        <template #table_row_settings-design>
          <ControlsList
            :widget="widget"
            :controls="tableRowsDesignControls"
          />
        </template>

        <template #table_row_settings-content>
          <ControlsList
            :widget="widget"
            :controls="tableRowsContentControls"
          />
        </template>

        <template #table_checkboxes_settings-design>
          <ControlsList
            :widget="widget"
            :controls="tableCheckboxesDesignControls"
          />
        </template>

        <template #table_checkboxes_settings-states>
          <ControlsList
            :widget="widget"
            :controls="tableCheckboxesContentControls"
          />
        </template>

        <template #date_from>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #date_to>
          <ControlsList
            :widget="widget"
            :controls="textInputFieldControls"
          />
        </template>

        <template #transaction_types>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #transaction_subtypes>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #action_button-design>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonDesignControls"
          />
        </template>

        <template #action_button-content>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonContentControls"
          />
        </template>

        <template #action_button-states>
          <ControlsList
            :widget="widget"
            :controls="cancelButtonStatesControls"
          />
        </template>

        <template #pending_error_text-design>
          <ControlsList
            :widget="widget"
            :controls="pendingTextErrorDesignControls"
          />
        </template>

        <template #pending_error_text-content>
          <ControlsList
            :widget="widget"
            :controls="pendingTextErrorContentControls"
          />
        </template>

        <template #table_data-design>
          <ControlsList
            :widget="widget"
            :controls="dataDesignControls"
          />
        </template>

        <template #table_data-content>
          <ControlsList
            :widget="widget"
            :controls="dataContentControls"
          />
        </template>

        <template #table_date>
          <ControlsList
            :widget="widget"
            :controls="tableDateControls"
          />
        </template>

        <template #table_reference>
          <ControlsList
            :widget="widget"
            :controls="tableReferenceControls"
          />
        </template>

        <template #table_parameters-design>
          <ControlsList
            :widget="widget"
            :controls="parametrsDesignControls"
          />
        </template>

        <template #table_parameters-content>
          <ControlsList
            :widget="widget"
            :controls="parametrsContentControls"
          />
        </template>

        <template #table_status>
          <ControlsList
            :widget="widget"
            :controls="tableStatusControls"
          />
        </template>

        <template #table_transaction_type>
          <ControlsList
            :widget="widget"
            :controls="tableTransactionTypeControls"
          />
        </template>

        <template #table_amount>
          <ControlsList
            :widget="widget"
            :controls="tableAmountControls"
          />
        </template>

        <template #table_description>
          <ControlsList
            :widget="widget"
            :controls="tableDescriptionControls"
          />
        </template>

        <template
          v-for="(controlValues, controlOptionName) in formStylesControls"
          #[controlOptionName]
          :key="controlOptionName"
        >
          <ControlsList
            :widget="widget"
            :controls="controlValues"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/transaction-history/fenix/useTransactionHistoryFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "pageType": {
      label: "Page type",
      description:
        "Possible values: transaction | betting | pending. Empty value means 'transaction'",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);
const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,

  pageTitleControls,

  filterTitleControls,

  dateFilterTitleControls,
  transactionsFilterTitleControls,

  filterDesignControls,
  filterContentControls,

  filterContainerDesignControls,
  filterContainerContentControls,
  filterContainerStyleControls,

  dateFilterDesignControls,
  dateFilterContentControls,

  transactionFilterDesignControls,
  transactionFilterContentControls,

  tableDesignControls,
  tableContentControls,

  tableHeaderDesignControls,
  tableHeaderContentControls,

  tableRowsDesignControls,
  tableRowsContentControls,

  tableCheckboxesDesignControls,
  tableCheckboxesContentControls,

  formStylesControls,
  textInputFieldControls,
  dropdownFieldControls,

  cancelButtonStatesControls,
  cancelButtonDesignControls,
  cancelButtonContentControls,

  descriptionControls,

  pendingTextErrorDesignControls,
  pendingTextErrorContentControls,

  dataDesignControls,
  dataContentControls,

  tableDateControls,
  tableReferenceControls,
  parametrsDesignControls,
  parametrsContentControls,

  tableStatusControls,
  tableTransactionTypeControls,
  tableAmountControls,
  tableDescriptionControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  resetStates();
});
</script>
