<template>
  <a-tooltip
    :visible="isColumnSettingsDisabled && isColumnsHovered"
    placement="bottom"
    :overlay-style="{ 'maxWidth': '285px' }"
  >
    <template #title>
      <span>{{ CHANGING_COLUMNS_NOT_POSSIBLE }}</span>
    </template>
    <div class="grid-cell-settings">
      <div
        v-if="filteredColumnIcons.length"
        class="grid-cell-settings__container"
      >
        <div
          class="grid-cell-settings__column-icons-container"
          @mouseenter="isColumnsHovered = true"
          @mouseleave="isColumnsHovered = false"
        >
          <button
            v-for="(icon, index) in filteredColumnIcons"
            :key="index"
            class="grid-cell-settings__icon pointer"
            :class="{
              'grid-cell-settings__icon--disabled':
                isColumnSettingsDisabled || hasWidthSettingsErrors,
              'grid-cell-settings__icon--active':
                selectedCell?.children.length === index + 1,
            }"
            :disabled="isColumnSettingsDisabled || hasWidthSettingsErrors"
            @click.stop="onChangeColumnsCount(index + 1)"
          >
            <span class="anticon">
              <CommonIcon :name="icon" />
            </span>
          </button>
        </div>
      </div>
      <div class="grid-cell-settings__container">
        <button
          class="grid-cell-settings__icon pointer"
          :class="{
            'grid-cell-settings__icon--disabled': isMovingUpDisabled(),
          }"
          :disabled="isMovingUpDisabled()"
          @click.stop="onMoveGridUp"
        >
          <CommonIcon name="ant-design:arrow-up-outlined" />
        </button>
        <button
          class="grid-cell-settings__icon pointer"
          :class="{
            'grid-cell-settings__icon--disabled': isMovingDownDisabled(),
          }"
          :disabled="isMovingDownDisabled()"
          @click.stop="onMoveGridDown"
        >
          <CommonIcon name="ant-design:arrow-down-outlined" />
        </button>
      </div>
      <div class="grid-cell-settings__container">
        <button
          :class="{
            'grid-cell-settings__icon pointer': true,
            'grid-cell-settings__icon--disabled': hasWidthSettingsErrors,
          }"
          :disabled="hasWidthSettingsErrors"
          @click.stop="onCopyGrid"
        >
          <CommonIcon name="ant-design:copy-outlined" />
        </button>
        <button
          class="grid-cell-settings__icon grid-cell-settings__icon--red pointer"
          @click.stop="onDeleteSelectedCell"
        >
          <CommonIcon name="ant-design:delete-outlined" />
        </button>
      </div>
      <!-- NOTE: Hidden, since for now this button is doing nothing -->
      <div
        v-if="false"
        class="grid-cell-settings__container"
      >
        <button class="grid-cell-settings__icon pointer">
          <CommonIcon name="ant-design:more-outlined" />
        </button>
      </div>
    </div>
  </a-tooltip>
</template>

<script setup lang="ts">
import { useGridConfig } from "~~/store/grid";
import {
  hasChildNestingLevel,
  findParentLevelCell,
} from "~~/assets/utils/grid/grid-tree";
import { NestingLevel, ICell, MoveDirection } from "~~/models/grid.interface";
import { useFilteredColumnsIcons } from "~~/components/composables/grid/useFilteredColumnsIcons";
import { CHANGING_COLUMNS_NOT_POSSIBLE } from "~~/constants/tooltip-messages";

const gridStore = useGridConfig();

const { selectedCell, currentLayout } = storeToRefs(gridStore);

const {
  deleteGridCell,
  changeColumnsNumber,
  moveGridCell,
  copyCell,
  setSelectedCell,
  setValidationErrors,
} = gridStore;

const { hasWidthSettingsErrors } = storeToRefs(gridStore);

const filteredColumnIcons = useFilteredColumnsIcons.value;

const isColumnsHovered = ref<boolean>(false);

const targetCellParent = computed<ICell | null>(() => {
  return findParentLevelCell(currentLayout.value, selectedCell?.value);
});

const isColumnSettingsDisabled = computed<boolean>(() => {
  if (
    selectedCell.value &&
    selectedCell.value.settings.level === NestingLevel.PARENT
  ) {
    return hasChildNestingLevel(currentLayout.value, selectedCell.value.cellId);
  }
  return false;
});

const isMovingUpDisabled = (): boolean => {
  return isMovingDisabled(MoveDirection.UP);
};

const isMovingDownDisabled = (): boolean => {
  return isMovingDisabled(MoveDirection.DOWN);
};

const isMovingDisabled = (direction: MoveDirection): boolean => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return false;

  const selectedCellID = selectedCellVal.cellId;
  let currentGrid: ICell[];
  if (selectedCellVal.settings.level === NestingLevel.PARENT) {
    currentGrid = currentLayout.value;
  } else {
    const children = targetCellParent.value?.children;
    if (children) {
      currentGrid = children;
    } else return false;
  }

  let cellToCompare: ICell =
    direction === MoveDirection.UP
      ? currentGrid[0]
      : currentGrid[currentGrid.length - 1];

  if (cellToCompare) {
    return selectedCellID === cellToCompare.cellId;
  } else return false;
};

const onChangeColumnsCount = (colNum: number): void => {
  changeColumnsNumber(colNum);
};

const onDeleteSelectedCell = (): void => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return;
  setSelectedCell(null);
  deleteGridCell(selectedCellVal.cellId, selectedCellVal.parentId!);
  /* NOTE: user can delete a cell with invalid width settings, but we need to clear errors in this case */
  if (hasWidthSettingsErrors.value) {
    hasWidthSettingsErrors.value = false;
    setValidationErrors(false);
  }
};

const onMoveGridDown = (): void => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return;
  moveGridCell(selectedCellVal, MoveDirection.DOWN);
};

const onMoveGridUp = (): void => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return;
  moveGridCell(selectedCellVal, MoveDirection.UP);
};

const onCopyGrid = (): void => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return;
  copyCell(selectedCellVal);
};
</script>

<style lang="scss">
.grid-cell-settings {
  @include flex(center, space-around, 0);
  background-color: $c-white;
  height: $space-xl;
  max-width: 336px;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin: 2px 4px;

  &__container {
    @include flex(center, space-around, 12px);
    padding: 0 10px;
    height: 100%;
    border-right: 1px solid $c-grey-15;

    &:last-child {
      border-right: none;
    }
  }

  &__column-icons-container {
    @include flex(center, center, 12px);
  }

  &__icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &--red {
      color: $c-icon-font-red;
    }

    &:hover {
      background-color: $c-grey-06;
    }

    &--disabled {
      cursor: not-allowed;
      color: $c-grey-25;
      pointer-events: none;
    }

    &--active {
      background-color: $c-grey-06;
    }
  }
}
</style>
