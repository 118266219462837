import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  generateTabsContainerString,
  generateTabsCssString,
  generateIconCssString,
} from "~~/assets/utils/widget-css/widgets/1event-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateBorderStyle,
  generateFillColorStyle,
  generateFlex,
  generateGrid,
  generateTextColor,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import { getPxValueFromNumber } from "../..";

export const generateCardsContainerCssString =
  (cssClass: string, el: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: cssClass,
      content: generateGrid({
        rowGap: el.options.displayMode.distanceBetweenCards,
        columnGap: "0",
      }),
    });

    return cssString;
  };

export const generateCardsGridContainerCssString =
  (
    containerField: IWidgetOptions,
    headerField: IWidgetOptions,
    cardField: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const view = containerField.options.mode;

    let viewGridCss =
      'grid-template-areas: "number name races prev odds";grid-template-columns: 52px 1fr 132px 132px 132px;';

    const headerClass = headerField.options._cssClass;
    const headerClasses = ` ._number, .${headerClass} ._races, .${headerClass} ._prev`;

    if (view === "rows") {
      viewGridCss = `grid-template-columns: 132px;grid-template-areas: "number" "name" "races" "prev" "odds";`;
    } else if (view === "wrap") {
      viewGridCss = `
      grid-template-columns: 1fr 116px;
      grid-template-areas:
      "number odds"
      "name prev"
      "races .";`;

      cssString += generateCssClassWithContent({
        className: headerField.options._cssClass,
        pseudoClassName: headerClasses,
        content: "display: none;",
      });

      cssString += generateCssClassWithContent({
        className: headerField.options._cssClass,
        content:
          generateGrid({
            rowGap: containerField.options.displayMode.distanceBetweenItems,
            columnGap: containerField.options.displayMode.distanceBetweenItems,
          }) +
          'grid-template-columns: 1fr 116px;grid-template-areas: "name odds";display:grid;',
      });
    }

    if (view === "rows" || view === "table") {
      cssString += generateCssClassWithContent({
        className: headerField.options._cssClass,
        content:
          generateGrid({
            rowGap: containerField.options.displayMode.distanceBetweenItems,
            columnGap: containerField.options.displayMode.distanceBetweenItems,
          }) + viewGridCss,
      });
    }

    cssString += generateCssClassWithContent({
      className: cardField.options._cssClass,
      content:
        generateGrid({
          rowGap: containerField.options.displayMode.distanceBetweenItems,
          columnGap: containerField.options.displayMode.distanceBetweenItems,
          alignItems: "center",
        }) + viewGridCss,
    });

    return cssString;
  };

export const generateContainerCssString =
  (el: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: el.options._cssClass,
      content: "overflow: hidden;",
    });

    return cssString;
  };

export const generateBetItemsCssString =
  (betItems: IWidgetField) =>
  (cssString: string): string => {
    const { options } = betItems;

    const coefCssContent = generateDefaultStyles(options);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: coefCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content:
        generateDefaultStyles(options) +
        "display: block;" +
        "min-width: 0px;" +
        "cursor: pointer;" +
        "width: 100%;",
    });

    const classNames: Record<string, string> = {
      up: options._itemUpCssClass,
      down: options._itemDownCssClass,
    };

    const betAdditionalStateCss = "position: relative;overflow: hidden;";

    const afterDefaultCss =
      "display: block;" +
      "position: absolute;" +
      "right: -10px;" +
      "width: 27px;" +
      "height: 15px;" +
      "z-index: 2;" +
      "content:'';";

    const afteUpCss =
      "top: -7px;" +
      "transform: rotate(45deg);" +
      generateFillColorStyle(betItems.options.states.up.arrow?.up?.color, true);

    const afteDownCss =
      "transform: rotate(-45deg);" +
      "bottom: -7px;" +
      generateFillColorStyle(
        betItems.options.states.down.arrow?.down?.color,
        true
      );

    for (const stateKey of ["up", "down"]) {
      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        content:
          generateBorderStyle(betItems.options.states[stateKey].border, true) +
          generateFillColorStyle(betItems.options.states[stateKey].fill, true) +
          generateTextColor(betItems.options.states[stateKey].color, true) +
          betAdditionalStateCss,
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']:after`,
        content:
          afterDefaultCss + (stateKey === "down" ? afteDownCss : afteUpCss),
      });

      cssString += generateCssClassWithContent({
        className: classNames[stateKey],
        pseudoClassName: `[data-${stateKey}='true']`,
        childClassName: betItems.options._cssClass,
        content: generateDefaultStyles(betItems.options.states[stateKey].coef),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      content: generateDefaultStyles(betItems.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true']",
      childClassName: betItems.options._cssClass,
      content: generateDefaultStyles(betItems.options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true'][data-up='true']:after",
      content:
        afterDefaultCss +
        "top: -7px;" +
        "transform: rotate(45deg);" +
        generateFillColorStyle(betItems.options.states.active.arrow?.up?.color),
    });

    cssString += generateCssClassWithContent({
      className: options._itemSelectedCssClass,
      pseudoClassName: "[data-selected='true'][data-down='true']:after",
      content:
        afterDefaultCss +
        "transform: rotate(-45deg);" +
        "bottom: -7px;" +
        generateFillColorStyle(
          betItems.options.states.active.arrow?.down?.color
        ),
    });

    cssString += generateDefaultStylesWithStates(
      {
        options: {
          ...options,
          _cssClass: options._itemCssClass,
        },
      },
      false,
      ["up", "down"]
    );

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":hover[data-up='true']:after",
      content:
        afterDefaultCss +
        "top: -7px;" +
        "transform: rotate(45deg);" +
        generateFillColorStyle(betItems.options.states.hover.arrow?.up?.color),
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      pseudoClassName: ":hover[data-down='true']:after",
      content:
        afterDefaultCss +
        "transform: rotate(-45deg);" +
        "bottom: -7px;" +
        generateFillColorStyle(
          betItems.options.states.hover.arrow?.down?.color
        ),
    });

    for (const state in betItems.options.states) {
      if (state == "up" || state === "down") {
        continue;
      }

      cssString += generateCssClassWithContent({
        className: options._itemCssClass,
        pseudoClassName: `:${state}`,
        childClassName: betItems.options._cssClass,
        content: generateDefaultStyles(betItems.options.states[state], [
          "theme",
        ]),
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: `[data-disabled=true]`,
      content:
        generateDefaultStyles(betItems.options.states.disabled, ["theme"]) +
        "cursor: not-allowed !important;",
    });

    return cssString;
  };

export const generateNumberssString =
  (el: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: el.options._cssClass,
      pseudoClassName: " > div",
      content: generateFlex({
        flex: "flex",
        justify: "flex-start",
        align: "flex-start",
      }),
    });

    cssString += generateCssClassWithContent({
      className: el.options._cssClass,
      pseudoClassName: " img",
      content: `width:${getPxValueFromNumber(
        el.options.iconSize
      )};height:${getPxValueFromNumber(el.options.iconSize)};`,
    });

    return cssString;
  };

// export const generateRowNumberCssString =
//   (rowField: IWidgetOptions, numberField: IWidgetOptions) =>
//   (cssString: string): string => {
//     // cssString += generateCssClassWithContent({
//     //   className: rowField.options._cssClass,
//     //   pseudoClassName: `:first-child .${numberField.options._cssClass} img, .${rowField.options._cssClass}:nth-child(2) .${numberField.options._cssClass} img`,
//     //   content: `display: none;`,
//     // });

//     // const boxStyle =
//     //   "width: 30px;height:30px;font-size:18px;font-weight:600;color:#ffffff;border:1px solid #000000;";

//     // cssString += generateCssClassWithContent({
//     //   className: rowField.options._cssClass,
//     //   pseudoClassName: `:first-child .${numberField.options._cssClass} p`,
//     //   content:
//     //     `background:#ff2a2b;` +
//     //     boxStyle +
//     //     generateFlex({
//     //       flex: "flex",
//     //       align: "center",
//     //       justify: "center",
//     //     }),
//     // });

//     // cssString += generateCssClassWithContent({
//     //   className: rowField.options._cssClass,
//     //   pseudoClassName: `:nth-child(2) .${numberField.options._cssClass} p`,
//     //   content:
//     //     `background:#3679bb;` +
//     //     boxStyle +
//     //     generateFlex({
//     //       flex: "flex",
//     //       align: "center",
//     //       justify: "center",
//     //     }),
//     // });

//     return cssString;
//   };

export const generateRowDisabledCssString =
  (
    rowField: IWidgetOptions,
    {
      number,
      name,
      lastRaces,
      prevOdds,
      currOdds,
    }: {
      number: IWidgetOptions;
      name: IWidgetOptions;
      lastRaces: IWidgetOptions;
      prevOdds: IWidgetOptions;
      currOdds: IWidgetOptions;
    }
  ) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"]`,
      content: generateDefaultStyles(rowField.options.states.disabled),
    });

    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"] .${number.options._cssClass}`,
      content: generateDefaultStyles(
        rowField.options.states.disabled.players_info_player_number
      ),
    });

    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"] .${name.options._cssClass}`,
      content: generateDefaultStyles(
        rowField.options.states.disabled.players_info_player_name
      ),
    });

    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"] .${lastRaces.options._cssClass}`,
      content: generateDefaultStyles(
        rowField.options.states.disabled.players_info_player_last_score
      ),
    });

    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"] .${prevOdds.options._cssClass}`,
      content: generateDefaultStyles(
        rowField.options.states.disabled.players_info_player_prev_odds
      ),
    });

    cssString += generateCssClassWithContent({
      className: rowField.options._cssClass,
      pseudoClassName: `[data-disabled="true"] .${currOdds.options._cssClass}`,
      content: generateDefaultStyles(currOdds.options.states.disabled),
    });

    if (currOdds.options.states.not_runner) {
      cssString += generateCssClassWithContent({
        className: currOdds.options._notRunnerCssClass,
        content:
          generateFillColorStyle(
            currOdds.options.states.not_runner.fill,
            true
          ) +
          generateBorderStyle(currOdds.options.states.not_runner.border, true) +
          generateTextColor(currOdds.options.states.not_runner.color, true),
      });
    }

    return cssString;
  };

export const generateRacingEventLiveWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["event_info_title"];
  const event_info_description = widget.content["event_info_description"];
  const event_info_container = widget.content["event_info_container"];
  const players_info_container = widget.content["players_info_container"];
  const players_info_header = widget.content["players_info_header"];
  const players_info_card = widget.content["players_info_card"];
  /* table content */
  const players_info_player_number =
    widget.content["players_info_player_number"];
  const players_info_player_name = widget.content["players_info_player_name"];
  const players_info_player_last_score =
    widget.content["players_info_player_last_score"];
  const players_info_player_prev_odds =
    widget.content["players_info_player_prev_odds"];
  const players_info_player_current_odds =
    widget.content["players_info_player_current_odds"];
  const tabs_container = widget.content["tabs_container"];
  const tabs = widget.content["tabs"];

  widget.options._cssClass = generateClassName("RacingEventLive");

  event_info_container.options._cssClass = generateClassName(
    "event_info_container"
  );
  title.options._cssClass = generateClassName("event_info_title");
  event_info_description.options._cssClass = generateClassName(
    "event_info_description"
  );

  players_info_container.options._cssClass = generateClassName(
    "players_info_container"
  );

  players_info_header.options._cssClass = generateClassName(
    "players_info_header"
  );

  players_info_player_prev_odds.options._cssClass = generateClassName(
    "players_info_player_prev_odds"
  );

  players_info_card.options._cssClass = generateClassName("players_info_card");

  players_info_card.options._containerCssClass = generateClassName(
    "players_info_card_container"
  );

  /* 
    table content
  */

  players_info_player_number.options._cssClass = generateClassName(
    "players_info_player_number"
  );
  players_info_player_name.options._cssClass = generateClassName(
    "players_info_player_name"
  );
  players_info_player_last_score.options._cssClass = generateClassName(
    "players_info_player_last_score"
  );
  players_info_player_prev_odds.options._cssClass = generateClassName(
    "players_info_player_prev_odds"
  );

  players_info_player_current_odds.options._cssClass = generateClassName(
    "players_info_player_current_odds"
  );
  players_info_player_current_odds.options._itemCssClass =
    generateClassName("bet_items_item");
  players_info_player_current_odds.options._itemSelectedCssClass =
    generateClassName("bet_items_item_selected");
  players_info_player_current_odds.options._itemUpCssClass =
    generateClassName("bet_items_item_up");
  players_info_player_current_odds.options._itemDownCssClass =
    generateClassName("bet_items_item_down");

  tabs_container.options._cssClass = generateClassName("tabs_container");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options.iconSettings._cssClass = generateClassName("tabs_icon");
  tabs.options._itemSelectedCssClass =
    tabs.options._cssClass + '[data-selected="true"]';

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(event_info_container),
    generateStringWithStates(title, false),
    generateStringWithStates(event_info_description, false),

    generateStringDefault(players_info_container),
    generateStringDefault(players_info_header),
    generateStringDefault(players_info_card),
    generateCardsContainerCssString(
      players_info_card.options._containerCssClass,
      players_info_container
    ),
    generateContainerCssString(players_info_container),
    generateCardsGridContainerCssString(
      players_info_container,
      players_info_header,
      players_info_card
    ),

    generateStringDefault(players_info_player_number),
    generateNumberssString(players_info_player_number),
    generateStringDefault(players_info_player_name),
    generateStringDefault(players_info_player_last_score),
    generateStringDefault(players_info_player_prev_odds),
    generateBetItemsCssString(players_info_player_current_odds as IWidgetField),

    generateTabsContainerString(tabs_container),
    generateTabsCssString(tabs_container, tabs),
    generateIconCssString(
      tabs.options.iconSettings,
      `${tabs.options._cssClass} svg`
    ),
    // generateRowNumberCssString(players_info_card, players_info_player_number),
    generateRowDisabledCssString(players_info_card, {
      number: players_info_player_number,
      name: players_info_player_name,
      lastRaces: players_info_player_last_score,
      prevOdds: players_info_player_prev_odds,
      currOdds: players_info_player_current_odds,
    })
  )("");
};
