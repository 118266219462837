<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <FavoritesEventsLiveWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="favorites-events-live-widget">
      <WidgetField
        :widget="widget"
        :field="fields.heading.field"
      >
        <DropdownTriggerField
          :value="fields.heading.field?.value"
          :field="fields.heading.field"
          :widget="widget"
          :state="states['heading']"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.event_card.active"
        :widget="widget"
        :field="fields.event_card.field"
      >
        <BasicTitleField
          v-if="states.event_card === State.NO_ACTIVE"
          :field="fields.no_event_title.field"
        />

        <SportsbookLiveEventCards
          v-else
          :widget="widget"
          :field="fields.event_card.field"
          :suspended-field="fields.event_suspended_status.field"
          :cards="eventCards"
        >
          <template #stream>
            <WidgetField
              :widget="widget"
              :field="fields.stream_icon.field"
            >
              <CommonIcon
                :name="fields.stream_icon.field.options.value"
                :style="streamIconStyles"
              />
            </WidgetField>
          </template>

          <template #suspended>
            <WidgetField
              :widget="widget"
              :field="fields.event_suspended_status.field"
            >
              <SportsBookLiveSuspended
                :field="fields.event_suspended_status.field"
                value="Suspended"
              />
            </WidgetField>
          </template>

          <template #time>
            <WidgetField
              :widget="widget"
              :field="fields.event_time.field"
            >
              <SportsBookLiveTime :field="fields.event_time.field">
                00:00 Period
              </SportsBookLiveTime>
            </WidgetField>
          </template>

          <template #league>
            <WidgetField
              :widget="widget"
              :field="fields.event_league.field"
            >
              <BasicTitleField
                :field="fields.event_league.field"
                value="League"
              />
            </WidgetField>
          </template>

          <template #event_info>
            <WidgetField
              :widget="widget"
              :field="fields.event_info.field"
            >
              <SportsbookLiveEventInfo
                :widget="widget"
                :field="fields.event_info.field"
                :team-player-field="fields.event_team_player.field"
                :score-field="fields.event_score.field"
              />
            </WidgetField>
          </template>

          <template #header_append>
            <WidgetField
              :widget="widget"
              :field="fields.favourites_button.field"
              :style="iconStyles"
              class="favorites-events-live-widget__btn"
            >
              <CommonIcon
                :size="fields.favourites_button.field.options.iconSize"
                :name="fields.favourites_button.field.options.value"
              />
            </WidgetField>
          </template>
        </SportsbookLiveEventCards>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script setup lang="ts">
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useFavoritesEventsLiveWidgetInitData } from "~~/composables/widgets/favorites-events-live/useFavoritesEventsLiveWidgetInitData";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const streamIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(fields.value.stream_icon.field.options.textColor),
    ...getFontSizeStyle(fields.value.stream_icon.field.options.iconSize),
  };
});

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(fields.value.favourites_button.field.options.color),
    zIndex: 1,
  };
});

const {
  states,
  DEFAULT_STATES,

  initFields,
} = useFavoritesEventsLiveWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const eventCards = [
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    items: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
];

initFields();
</script>

<style lang="scss">
.favorites-events-live-widget {
  &__btn {
    margin-left: auto;
  }
}
</style>
