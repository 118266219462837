<script lang="ts" setup>
import { generateParamsFromSlug } from "~~/components/dynamic-params/utils";
import { IWidgetBindingParam } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { deepCopy } from "~~/assets/utils";

const props = defineProps<{
  pageRoute: string;
  paramName: string;
  validationPath: string[];
}>();

defineEmits<{
  (e: "change"): void;
}>();

const {
  getLocalizedValue,
  setLocalizedValue,
  currentLanguageCode,
  transformToLocalizedValue,
  isDefaultLanguage,
  availableLanguagesCodes,
  defaultLanguage,
} = useLocalizedValue();

function onCustomValueChange(value: unknown) {
  formData.value.value = setLocalizedValue.value(
    formData.value.value,
    String(value)
  );

  if (!isDefaultLanguage.value) {
    return;
  }

  for (const langName in availableLanguagesCodes.value) {
    if (langName === defaultLanguage.value?.codeWithRegion) {
      continue;
    }

    formData.value.value[langName] = deepCopy(
      formData.value.value[defaultLanguage.value!.codeWithRegion]
    );
  }
}

const listToOptions = (list: string[]) => {
  return list.map(item => ({
    label: item,
    value: item,
  }));
};

const formData = defineModel<IWidgetBindingParam>("modelValue", {
  required: true,
});

const generateLocaleCustomFieldValue = (langCode: string) => {
  const { value, children } = formData.value;

  if (!value || !children) {
    formData.value.children = transformToLocalizedValue({}).localizedValue;
  }

  const params = generateParamsFromSlug({}, getLocalizedValue.value(value));

  formData.value.children![langCode] = Object.keys(params).reduce<
    Record<string, IWidgetBindingParam>
  >((acc, key) => {
    const localized = formData.value.children?.[langCode];
    const value = localized?.[key]?.value ?? params[key];

    acc[key] = {
      label: key,
      source: "widgetData",
      sources: ["widgetData"],
      value,
    } as IWidgetBindingParam;

    return acc;
  }, {});
};

const customFieldUpdate = () => {
  generateLocaleCustomFieldValue(currentLanguageCode.value);

  if (isDefaultLanguage.value) {
    for (const langName in availableLanguagesCodes.value) {
      if (langName === defaultLanguage.value?.codeWithRegion) {
        continue;
      }

      generateLocaleCustomFieldValue(langName);
    }
  }
};

const handleChildrenSelectChange = () => {
  if (!isDefaultLanguage.value) {
    return;
  }

  for (const langName in availableLanguagesCodes.value) {
    if (langName === defaultLanguage.value?.codeWithRegion) {
      continue;
    }

    formData.value.children![langName] = deepCopy(
      formData.value.children![defaultLanguage.value!.codeWithRegion]
    );
  }
};

const onSourceChange = () => {
  if (formData.value.source === "custom") {
    formData.value.value = transformToLocalizedValue("").localizedValue;
  }

  if (formData.value.source === "pageRoute") {
    formData.value.value = 1;
  }

  if (formData.value.source === "widgetData") {
    formData.value.value = {
      entity_type: "",
      entity_variable: "",
    };
  }
};

const pageRouteOptions = computed(() => {
  return props.pageRoute
    .split("/")
    .filter(Boolean)
    .map((item, index) => ({
      label: item,
      value: index,
    }))
    .filter(item => item.label.startsWith(":"));
});

const rules = computed(() => {
  return [
    formData.value.required && {
      required: true,
      message: "Field is required!",
    },
  ].filter(Boolean);
});

const validationFieldsNames = computed(() => {
  const validationPath = {
    value: [...props.validationPath, props.paramName, "value"],
    source: [...props.validationPath, props.paramName, "source"],
    children: [...props.validationPath, props.paramName, "children"],
  };

  if (formData.value.source === "custom") {
    validationPath.value.push(currentLanguageCode.value);
    validationPath.children.push(currentLanguageCode.value);
  }

  return validationPath;
});

const hasChildren = computed(() => {
  const localizedChildren = getLocalizedValue.value(formData.value);
  return Object.keys(localizedChildren || {}).length > 0;
});
</script>

<template>
  <div
    v-if="formData"
    :key="currentLanguageCode"
    class="widget-dynamic-param"
    :class="{
      'widget-dynamic-param__children': hasChildren,
    }"
  >
    <DynamicParamField
      :label="formData.label"
      :description="formData.description"
    >
      <a-form-item
        class="margin-bottom-none"
        label="Source"
        layout="vertical"
        :name="validationFieldsNames.source"
        :rules="rules"
      >
        <a-select
          v-model:value="formData.source"
          :options="listToOptions(formData.sources)"
          @change="onSourceChange"
        />
      </a-form-item>

      <a-form-item
        v-if="formData.source === 'pageRoute'"
        class="margin-bottom-none"
        label="Value"
        :name="validationFieldsNames.value"
        :rules="rules"
        layout="vertical"
      >
        <a-select
          v-model:value="formData.value"
          :options="pageRouteOptions"
          @change="$emit('change')"
        />
      </a-form-item>

      <a-form-item
        v-if="formData.source === 'custom'"
        class="margin-bottom-none"
        label="Value"
        :name="validationFieldsNames.value"
        :rules="rules"
        layout="vertical"
      >
        <a-input
          :value="getLocalizedValue(formData.value, '')"
          placeholder="Type value"
          :on-blur="customFieldUpdate"
          @update:value="onCustomValueChange"
        />
      </a-form-item>

      <DynamicEntityInput
        v-if="formData.source === 'widgetData'"
        v-model="formData.value"
        label-type="Value"
        :rules="rules"
        :validation-path="validationFieldsNames.value"
        @change="$emit('change')"
      />
    </DynamicParamField>

    <template v-if="formData.children?.[currentLanguageCode]">
      <WidgetDynamicParam
        v-for="(_, name) in formData.children![currentLanguageCode]"
        :key="`${currentLanguageCode}-${name}`"
        v-model="formData.children![currentLanguageCode]![name]"
        :param-name="name"
        :page-route="pageRoute"
        :validation-path="validationFieldsNames.children"
        @change="handleChildrenSelectChange"
      />
    </template>
  </div>
</template>

<style lang="scss">
.widget-dynamic-param {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.widget-dynamic-param__children {
  border-left: 2px solid #0080ff;
}
</style>
