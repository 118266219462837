<template>
  <a-input
    v-model:value="width"
    v-number
    @keyup.enter="onChange"
    @blur="onChange"
  >
    <template #suffix>
      <div class="input-suffix">
        {{ props.input.sizing === Sizing.FIXED ? "px" : "%" }}
      </div>
    </template>
  </a-input>
</template>

<script lang="ts" setup>
import { Sizing, WidthParams } from "~~/models/grid.interface";

const props = defineProps<{
  input: WidthParams;
}>();
const emit = defineEmits<{
  (e: "update:input", value: WidthParams): void;
  (e: "change"): void;
}>();

const width = computed<number | string>({
  get() {
    return props.input.width;
  },
  set(value) {
    emit("update:input", {
      sizing: props.input.sizing,
      width: value,
    });
  },
});
const onChange = (): void => {
  emit("change");
};
</script>
