import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { useText } from "~~/components/composables/fields/text/useText";
import { MAX_TEXT } from "~~/constants/input-validation";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const widgetSettingsStore = useWidgetSettingsStore();

export const useTextInputConfig = (
  props: IWidgetOptions,
  emit: any,
  maxTextLength = MAX_TEXT
) => {
  const { canUpdateInputValue } = useText(maxTextLength);

  const currentField: IWidgetField | undefined = getCurrentField(
    props.fieldName
  ).value;

  const handleFieldOptionsClick = (): void => {
    if (props.meta?.emitOptionsClick) {
      emit("options-click");
      return;
    }

    widgetSettingsStore.setActiveElement(
      widgetSettingsStore.selectedWidget,
      currentField
    );
  };

  const isWidgetSettings = computed<boolean>(() => {
    return widgetSettingsStore.selectedField === null;
  });

  const { getLocalizedValue, setLocalizedValue } = useLocalizedValue();

  const handleInput = (source: IWidgetField["value"], value: string): void => {
    if (!canUpdateInputValue(getLocalizedValue.value(value))) return;

    const newValue = setLocalizedValue.value(source, value);

    if (props.meta?.isValueOption) {
      emit("update:field-value", newValue);
      return;
    }

    emit("update:modelValue", newValue);
  };

  return {
    handleFieldOptionsClick,
    isWidgetSettings,
    currentField,
    handleInput,
  };
};
