<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    :current-state="State.DEFAULT"
  >
    <BetslipWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />
    <div
      class="betslip-widget"
      :class="{
        'betslip-widget--full-height': states.BetslipWidget !== State.NO_BETS,
      }"
    >
      <BetslipHeaderContainer
        :widget="widget"
        :fields="fields"
        :states="states"
      />

      <div class="betslip-widget__content-container">
        <BetslipTabsContainer
          v-if="states.BetslipWidget !== State.NO_BETS"
          :widget="widget"
          :fields="fields"
          :states="states"
        />

        <WidgetField
          v-if="fields.bets_container.active"
          :widget="widget"
          class="betslip-widget__betslip-cards-container"
          :class="{
            'betslip-widget__betslip-cards-container--single-bets-view':
              states.bets_container === State.SINGLE_BETS ||
              states.BetslipWidget === State.BETS_RESULT,
          }"
          :style="betslipCardContainerInlineStyles"
          :field="fields.bets_container.field"
        >
          <BetslipSuccessMessageContainer
            :widget="widget"
            :fields="fields"
            :states="states"
          />

          <BetslipCardsContainer
            v-if="states.BetslipWidget !== State.NO_BETS"
            :widget="widget"
            :fields="fields"
            :states="states"
          />

          <BetslipForm
            v-if="states.BetslipWidget === State.BETSLIP"
            :widget="widget"
            :fields="fields"
            :states="states"
          />

          <div v-if="states.BetslipWidget === State.NO_BETS">
            <WidgetField
              v-if="fields.no_bets_description.active"
              :widget="widget"
              :field="fields.no_bets_description.field"
              class="basic-deposit-list-widget__field"
            >
              <BasicDescriptionField
                ref="descriptionField"
                :fields="descriptionFields"
                :widget="widget"
              />
            </WidgetField>
          </div>
        </WidgetField>

        <BetslipResultContainer
          v-if="states.BetslipWidget === State.BETSLIP"
          :widget="widget"
          :fields="fields"
          :states="states"
        />

        <BetslipBetsResultContainer
          v-if="states.BetslipWidget === State.BETS_RESULT"
          :widget="widget"
          :fields="fields"
          :states="states"
        />
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { SpacingKeyName, State } from "~~/models/widgets/widget-controls.model";
import { useBetslipInitData } from "~~/composables/widgets/betslip/useBetslipInitData";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getSpacing,
  getBorderStyle,
  getCornerRadiusStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, initFields } = useBetslipInitData(props.widget);

const betslipCardContainerInlineStyles = computed<ElementStyle>(() => {
  const betslipCardsContainerOptions =
    fields.value.bets_container.field.options;
  return {
    ...getCommonStyles(betslipCardsContainerOptions),
    ...getSpacing(betslipCardsContainerOptions.margins, SpacingKeyName.MARGIN),
    ...getBorderStyle(betslipCardsContainerOptions.border),
    ...getCornerRadiusStyle(betslipCardsContainerOptions.cornerRadius),
    gap: `${betslipCardsContainerOptions.spaceBetween}`,
  };
});

const descriptionFields = computed(() => {
  return props.widget.fields.filter(field =>
    field.name.includes("no_bets_description")
  );
});

const handleStateUpdate = (stateDetails: {
  state: string;
  value: State;
}): void => {
  states.value[stateDetails.state] = stateDetails.value;
};

const resetStates = (): void => {
  states.value.header_tabs_container = State.DEFAULT;
  states.value.betslip_tabs_container = State.DEFAULT;
  states.value.coefficient_title = State.DEFAULT;
  states.value.result_container_bet_button = State.DEFAULT;
  states.value.result_container_remove_button = State.DEFAULT;
  states.value.result_container_copy_button = State.DEFAULT;
  states.value.my_bets_secondary_button = State.DEFAULT;
  states.value.my_bets_primary_button = State.DEFAULT;
  states.value.bets_result_save_choice_button = State.DEFAULT;
  states.value.bets_result_continue_button = State.DEFAULT;
};
initFields();
</script>

<style lang="scss">
.betslip-widget {
  &--full-height {
    min-height: 1060px;
  }

  @include flex-column(unset, unset, 0px);

  &__content-container {
    @include flex-column(unset, flex-start, 0px);
    flex: 1;
  }

  &__betslip-bets-field {
    flex: 1;
  }

  &__header-items-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item-container {
    @include flex(center, space-around, 0px);
  }

  &__header-item {
    @include flex(center, center, 0px);
  }

  &__total-amounts-container {
    @include flex(center, space-around, 0px);

    &--horizontal {
      flex-direction: column;

      .betslip-widget__result-total-amounts-element {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__result-total-amounts-element {
    width: 100%;
  }

  &__result-total-amounts-element {
    @include flex-column(flex-start, center, 0px);
  }

  &__result-warning-message,
  &__result-success-message {
    @include flex(center, flex-start, 0px);
  }

  &__result-success-message {
    justify-content: space-between;
  }

  &__result-buttons-container {
    @include flex-column(center, flex-start, 0px);
  }

  &__result-buttons-container-field {
    display: flex;
  }

  &__betslip-cards-container {
    flex: 1;
    @include flex-column(unset, space-between, 0px);

    &--single-bets-view {
      justify-content: flex-start;
    }
  }

  &__result-button-field {
    width: 50%;
  }
}
</style>
