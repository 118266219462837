<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #event_card-design>
          <ControlsList
            :widget="widget"
            :controls="cardsDesignControls"
          />
        </template>

        <template #event_card-content>
          <ControlsList
            :widget="widget"
            :controls="cardsContentControls"
          />
        </template>

        <template #event_card-states>
          <ControlsList
            :widget="widget"
            :controls="cardsStatesControls"
          />
        </template>

        <template #event_image>
          <ControlsList
            :widget="widget"
            :controls="photoControls"
          />
        </template>

        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>

        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #event_name>
          <ControlsList
            :widget="widget"
            :controls="teamPlayerTitleControls"
          />
        </template>

        <template #event_date_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/promoted-bets-list/usePromotedBetsConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "betDisplayMode": {
      label: "Bet display mode",
      sources: ["custom"],
      description: "Possible values: Name | Code",
      required: true,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  cardsDesignControls,
  cardsContentControls,
  cardsStatesControls,

  photoControls,

  eventInfoDesignControls,
  eventInfoContentControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  buttonStatesControls,
  buttonDesignControls,
  buttonContentControls,

  teamPlayerTitleControls,

  eventTimeControls,
} = useConfig(toRef(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
