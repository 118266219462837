import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

export const useBetsListFenixMobileItems = () => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Sports", "sports"),
    generateDropdownItem("Sports Title", "title"),
    generateDropdownItem("Sports Events Button", "sports_event_button"),
    generateDropdownItem("Event card", "event_card"),
    generateDropdownItem("Start time", "event_time"),
    generateDropdownItem("Bet items", "bet_items"),
    generateDropdownItem("Event button link", "event_button"),
    generateDropdownItem("Header", "header"),
    generateDropdownItem("League Name", "event_league"),
    generateDropdownItem("Favorite Icon", "favourites_button"),
    generateDropdownItem("Event Name", "event_name"),
    generateDropdownItem("Leagues", "leagues"),
    generateDropdownItem("Hover", "hover_button"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    sports: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    sports_event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    leagues: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    hover_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...DEFAULT_DROPDOWN_ITEMS];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
  };
};
