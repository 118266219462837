<template>
  <div
    class="wconfig-dropdown-container-text wconfig-item p-t-16 p-r-16 p-b-16 p-l-16"
  >
    <ConfigToggle
      v-if="hasContentPermissions"
      :allow-disable="allowDisable"
      :hide-switch="hideSwitch || isStatesTab"
      :size="'small'"
      :model-value="modelValue._active"
      :label="label"
      is-field-item-toggle
      @update:model-value="handleUpdate('_active', $event)"
    />

    <div
      v-if="modelValue._active || isStatesTab"
      class="m-t-16"
    >
      <a-textarea
        v-if="text !== undefined && hasContentPermissions"
        show-count
        :rows="1"
        :value="getLocalizedValue(text)"
        :maxlength="MAX_BUTTON"
        class="wconfig-dropdown-container-text__control m-b-16"
        @update:value="handleInput"
      />

      <ConfigColorPickerInput
        :model-value="modelValue.color"
        label="Text color"
        class="wconfig-dropdown-container-text__control"
        :type="ColorPickerType.TEXT"
        @update:model-value="handleUpdate('color', $event)"
      />

      <ConfigThemeSelect
        v-if="!isStatesTab"
        :model-value="modelValue.theme"
        class="wconfig-dropdown-container-text__control"
        @update:model-value="handleUpdate('theme', $event)"
      />

      <ConfigFontFamilySelect
        v-if="!isStatesTab"
        :model-value="modelValue.fontFamily"
        class="wconfig-dropdown-container-text__control"
        @update:model-value="handleUpdate('fontFamily', $event)"
      />

      <ConfigDecoration
        :model-value="modelValue.decoration"
        class="wconfig-dropdown-container-text__control"
        @update:model-value="handleUpdate('decoration', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useText } from "~~/components/composables/fields/text/useText";
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { MAX_BUTTON } from "~~/constants/input-validation";
import { TextStyle } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  modelValue: {
    _active: boolean;
    color: Record<string, string>;
    theme: string;
    fontFamily: string;
    decoration: TextStyle[];
  };
  text?: Record<string, any> | undefined | null;
  label?: string;
  allowDisable?: boolean;
  isStatesTab?: boolean;
  hideSwitch?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: Record<
      string,
      Record<string, string> | string | boolean | TextStyle[]
    >
  ): void;
  (e: "update:text", event: Record<string, string>): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const { getLocalizedValue, transformToLocalizedValue, setLocalizedValue } =
  useLocalizedValue();

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};

const { canUpdateInputValue } = useText(MAX_BUTTON);

const handleInput = (value: string): void => {
  if (!canUpdateInputValue(getLocalizedValue.value(value))) return;

  const newValue = setLocalizedValue.value(props.text, value);

  emit("update:text", newValue);
};

onBeforeMount(() => {
  const { localized, localizedValue } = transformToLocalizedValue(props.text);

  if (localized) {
    emit("update:text", localizedValue);
  }
});
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-dropdown-container-text {
  @include widgets-content-input-container;

  &__control {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
