<template>
  <div>
    <component
      :is="controlComponent"
      v-if="typeof control.visible !== 'boolean' ? true : control.visible"
      :model-value="value"
      :class="customClassName"
      :value-path="control.valuePath"
      v-bind="control.options"
      @update:model-value="handleControlUpdate($event, control)"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  type Component,
  type AsyncComponentLoader,
  defineAsyncComponent,
  h,
} from "vue";

import { type ControlProp } from "~~/models/settings/settings-sidebar.model";

/*
Import components from file system with using Vite import.meta.glob.
Will be transformed to:

const MODULES = {
  '../configs/ConfigExampleName.vue': () => import([import_path]]),
  '../configs/configFolder/ConfigExampleName.vue': () => import([import_path]]),
  ...
}

https://vitejs.dev/guide/features.html
*/
const MODULES = import.meta.glob("../../../widgets/settings/configs/**");

type ValueContainer = Record<string, any>;

const props = defineProps<{
  control: ControlProp;
}>();

const customClassName = computed<string>(() => {
  return props.control.className || "";
});

const getControlValue = (instance: ValueContainer, path: string): unknown => {
  if (typeof path !== "string") {
    console.warn(
      `Property "path" is not provided for ${JSON.stringify(instance)}`
    );
    return;
  }

  return getValueFromPath(instance, path);
};

const modules = computed<Record<string, AsyncComponentLoader>>(() => {
  return MODULES;
});

const path = computed<string>(() => {
  return `../configs/${props.control.componentPath}.vue`;
});

const currentComponentImportFunc = computed<AsyncComponentLoader>(() => {
  if (!modules.value[path.value]) {
    console.error(`Can not find component ${path.value}`);
    return () =>
      new Promise(resolve => {
        resolve(h(""));
      });
  }

  return modules.value[path.value];
});

/*
  Please, do not touch this computed,
  since it can lead to component rerendering on
  state update
*/
const controlComponent = computed<Component>(() => {
  return defineAsyncComponent(() => currentComponentImportFunc.value());
});

const value = computed<unknown>(() => {
  if (!props.control.valuePath) {
    return props.control.valueSource;
  }

  return getControlValue(
    props.control.valueSource,
    props.control.valuePath as string
  );
});

const getPath = (path: string, breakLevel = 0): string[] => {
  const currPath = path.split(".");

  if (breakLevel === 0) {
    return currPath;
  }

  for (let i = 0; i < breakLevel; i++) {
    currPath.pop();
  }

  return currPath;
};

const getValueFromPath = (
  instance: ValueContainer,
  path: string,
  breakLevel = 0
): any => {
  const currPathElements = getPath(path, breakLevel);
  let value = instance;

  for (const keyName of currPathElements) {
    if (!value || typeof value !== "object") {
      console.error(
        `Property ${keyName} does not exist in path ${path} inside object`
      );

      console.error(instance);

      return "";
    }

    value = value[keyName];
  }

  return value;
};

const handleControlUpdate = ($event: any, control: ControlProp): void => {
  const { valueSource, valuePath, onUpdate } = control;

  if (onUpdate) {
    onUpdate($event);
    return;
  }

  const valueContainer = getValueFromPath(valueSource, valuePath as string, 1);

  const pathList = valuePath?.split(".") as string[];
  const valueKey = pathList[pathList?.length - 1];

  if (valueKey in valueContainer) {
    valueContainer[valueKey] = $event;
  } else {
    console.error(`No ${valuePath} exist in ===>`);
    console.warn(JSON.parse(JSON.stringify(valueSource)));
  }
};
</script>
