<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookFenixMobilePrematchWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sportsbook-prematch-fenix-mobile-widget">
      <WidgetField
        v-if="fields.header.active"
        :widget="widget"
        :field="fields.header.field"
      >
        <div
          :style="headerStyles"
          class="sportsbook-prematch-fenix-mobile-widget__header"
        >
          <WidgetField
            v-if="fields.title.active"
            :widget="widget"
            :field="fields.title.field"
            class="sportsbook-prematch-fenix-mobile-widget__header-title"
          >
            <BasicTitleField
              :field="fields.title.field"
              value="Default"
            />
          </WidgetField>

          <WidgetField
            v-if="fields.live_button_title.active"
            :widget="widget"
            :field="fields.live_button_title.field"
            class="basic-card-widget__field"
          >
            <BasicButtonField
              :field="fields.live_button_title.field"
              :current-state="states.live_button_title"
              custom-value="Live"
            />
          </WidgetField>
        </div>
      </WidgetField>

      <WidgetField
        v-if="fields.items.active && states.widget !== State.SPORTSBOOK_COUNTRY"
        :widget="widget"
        :field="fields.items.field"
      >
        <SportsbookFenixMobilePrematchTabs
          :widget="widget"
          :items-fields="itemsFields"
          :field="fields.items.field"
          :items-group-field="fields.items.field.value"
          :states="states"
          :wrapper-options="menuItemsOptions"
          :is-items-group-selected="isItemsGroupSelected"
        />
      </WidgetField>

      <WidgetField
        v-if="
          fields.leagues_container.active &&
          (states.widget === State.SPORTSBOOK_COUNTRY ||
            (states.widget === State.SPORTSBOOK_LEAGUE &&
              states.items === State.SPORTSBOOK_TABS_EVENTS))
        "
        :widget="widget"
        :field="fields.leagues_container.field"
      >
        <SportsbookFenixMobilePrematchLeagues
          :widget="widget"
          :field="fields.leagues_container.field"
          :league-field="fields.league_card.field"
          :state="states.league_card"
        />
      </WidgetField>

      <WidgetField
        v-if="
          fields.filter_container.active &&
          states.widget !== State.SPORTSBOOK_ALL_LEAGUES &&
          fields.filter_container.field.options.display.layout ===
            SportMenuLiveBet9CardLayoutMode.FIRST &&
          !isWidgetLeagueEventsState
        "
        :widget="widget"
        :style="filterContainerWidthStyles"
        :field="fields.filter_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.filter_container.field"
        >
          <WidgetField
            v-if="fields.list_title.active"
            :widget="widget"
            :field="fields.list_title.field"
          >
            <BasicTitleField :field="fields.list_title.field" />
          </WidgetField>

          <WidgetField
            v-if="fields.filter.active"
            :widget="widget"
            :field="fields.filter.field"
            :is-custom-selected="isFilterFieldSelected"
            no-overflow
          >
            <SportsbookFilter
              :widget="widget"
              :field="fields.filter.field"
              :fields="filterFields"
              :states="states"
              :visibility="fields.filter.field.options.visibility"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="fields.winner_container.active && showWinnerLeague"
        :widget="widget"
        :field="fields.winner_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.winner_container.field"
        >
          <WidgetField
            v-if="fields.winner_league_title.active"
            :widget="widget"
            :field="fields.winner_league_title.field"
          >
            <BasicTitleField
              :field="fields.winner_league_title.field"
              value="League Winner"
            />
          </WidgetField>

          <WidgetField
            v-if="fields.winner_league_card.active"
            :widget="widget"
            :field="fields.winner_league_card.field"
          >
            <SportsbookLeagueWinnerCard
              :widget="props.widget"
              :field="fields.winner_league_card.field"
              :bet-item-field="fields.winner_league_bet_items.field"
              :bet-item-state="states.winner_league_bet_items"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="
          fields.events_container.active &&
          states.widget !== State.SPORTSBOOK_ALL_LEAGUES &&
          states.widget !== State.SPORTSBOOK_TOP_LEAGUES &&
          !isWidgetLeagueEventsState
        "
        :widget="widget"
        :field="fields.events_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.events_container.field"
          :style="dateSpacingStyle"
          class="sportsbook-prematch-fenix-mobile-widget__dates"
        >
          <template v-if="states.events === State.ACTIVE_EVENTS">
            <div
              v-for="(dateItem, index) in items"
              :key="index"
            >
              <div>
                <div
                  v-if="
                    index === 0 &&
                    fields.filter_container.field.options.display.layout ===
                      SportMenuLiveBet9CardLayoutMode.SECOND
                  "
                  class="sportsbook-prematch-fenix-mobile-widget__filter-second-view"
                >
                  <div
                    class="sportsbook-prematch-fenix-mobile-widget__filter-second-view-league-container"
                  >
                    <WidgetField
                      v-if="fields.day_label.active"
                      :widget="widget"
                      :field="fields.day_label.field"
                      :style="dayLabelStyles"
                    >
                      <BasicTitleField
                        :field="fields.day_label.field"
                        :value="dateItem.date"
                      />
                    </WidgetField>
                    <div
                      v-for="(leagueItem, index2) in dateItem.items"
                      :key="`${index}-${index2}`"
                    >
                      <WidgetField
                        v-if="fields.league_name.active && index2 === 0"
                        :widget="widget"
                        :field="fields.league_name.field"
                      >
                        <BasicTitleField
                          :field="fields.league_name.field"
                          :value="leagueItem.league"
                        />
                      </WidgetField>
                    </div>
                  </div>

                  <WidgetField
                    v-if="
                      fields.filter_container.active &&
                      fields.filter_container.field.options.display.layout ===
                        SportMenuLiveBet9CardLayoutMode.SECOND &&
                      !isWidgetLeagueEventsState
                    "
                    :widget="widget"
                    :style="filterContainerWidthStyles"
                    :field="fields.filter_container.field"
                  >
                    <BasicDesignWrapperField
                      :widget="widget"
                      :field="fields.filter_container.field"
                    >
                      <WidgetField
                        v-if="fields.list_title.active"
                        :widget="widget"
                        :field="fields.list_title.field"
                      >
                        <BasicTitleField :field="fields.list_title.field" />
                      </WidgetField>

                      <WidgetField
                        v-if="fields.filter.active"
                        :widget="widget"
                        :field="fields.filter.field"
                        :is-custom-selected="isFilterFieldSelected"
                        no-overflow
                      >
                        <SportsbookFilter
                          :widget="widget"
                          :field="fields.filter.field"
                          :fields="filterFields"
                          :states="states"
                          :visibility="fields.filter.field.options.visibility"
                        />
                      </WidgetField>
                    </BasicDesignWrapperField>
                  </WidgetField>
                </div>

                <WidgetField
                  v-if="
                    fields.day_label.active &&
                    fields.filter_container.field.options.display.layout ===
                      SportMenuLiveBet9CardLayoutMode.FIRST
                  "
                  :widget="widget"
                  :field="fields.day_label.field"
                  :style="dayLabelStyles"
                >
                  <BasicTitleField
                    :field="fields.day_label.field"
                    :value="dateItem.date"
                  />
                </WidgetField>

                <div
                  :style="cardsSpacingStyle"
                  class="sportsbook-prematch-fenix-mobile-widget__leagues"
                >
                  <div
                    v-for="(leagueItem, index2) in dateItem.items"
                    :key="`${index}-${index2}`"
                  >
                    <WidgetField
                      v-if="
                        fields.league_name.active &&
                        fields.filter_container.field.options.display.layout ===
                          SportMenuLiveBet9CardLayoutMode.FIRST
                      "
                      :widget="widget"
                      :field="fields.league_name.field"
                    >
                      <BasicTitleField
                        :field="fields.league_name.field"
                        :value="leagueItem.league"
                      />
                    </WidgetField>

                    <WidgetField
                      v-if="fields.list_container.active"
                      :widget="widget"
                      :field="fields.list_container.field"
                    >
                      <SportsbookFenixMobilePrematchEventCards
                        :widget="widget"
                        :field="fields.list_container.field"
                        :event-info-field="fields.list_event_info.field"
                        :start-time-field="fields.list_start_time.field"
                        :date-field="fields.list_date.field"
                        :favorite-icon-field="fields.list_favourite_icon.field"
                        :league-field="fields.league_title.field"
                        :team-player-field="fields.list_event_name.field"
                        :bet-item-field="fields.list_bet_item.field"
                        :bet-builder-button-field="
                          fields.betbuilder_button.field
                        "
                        :bet-item-state="states.list_bet_item"
                        :list-item-more-state="states.list_item_more"
                        :event-button-field="fields.list_item_more.field"
                        :bet-builder-state="states.betbuilder_button"
                        :items="leagueItem.items"
                        :event-cards-state="states.event_cards"
                      />
                    </WidgetField>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <BasicTitleField :field="fields.no_events_title.field" />
          </template>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="
          fields.top_leagues_events_container.active &&
          states.widget === State.SPORTSBOOK_TOP_LEAGUES
        "
        :widget="widget"
        :field="fields.top_leagues_events_container.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.top_leagues_events_container.field"
          :style="topLeaguesSpacingStyle"
          class="sportsbook-prematch-fenix-mobile-widget__dates"
        >
          <template v-if="states.top_leagues_events === State.ACTIVE_EVENTS">
            <div
              v-for="(dateItem, index) in items"
              :key="index"
            >
              <div>
                <WidgetField
                  v-if="fields.top_leagues_day_label.active"
                  :widget="widget"
                  :field="fields.top_leagues_day_label.field"
                >
                  <BasicTitleField
                    :field="fields.top_leagues_day_label.field"
                    :value="dateItem.date"
                  />
                </WidgetField>

                <div
                  :style="topLeaguesCardsSpacingStyle"
                  class="sportsbook-prematch-fenix-mobile-widget__leagues"
                >
                  <div
                    v-for="(leagueItem, index2) in dateItem.items"
                    :key="`${index}-${index2}`"
                  >
                    <WidgetField
                      v-if="fields.top_leagues_league_name.active"
                      :widget="widget"
                      :field="fields.top_leagues_league_name.field"
                    >
                      <BasicTitleField
                        :field="fields.top_leagues_league_name.field"
                        :value="leagueItem.league"
                      />
                    </WidgetField>

                    <WidgetField
                      v-if="fields.top_leagues_list_container.active"
                      :widget="widget"
                      :field="fields.top_leagues_list_container.field"
                    >
                      <SportsbookFenixMobilePrematchEventCards
                        :widget="widget"
                        :field="fields.top_leagues_list_container.field"
                        :event-info-field="
                          fields.top_leagues_list_event_info.field
                        "
                        :start-time-field="
                          fields.top_leagues_list_start_time.field
                        "
                        :date-field="fields.top_leagues_list_date.field"
                        :favorite-icon-field="
                          fields.top_leagues_list_favourite_icon.field
                        "
                        :league-field="fields.top_leagues_league_title.field"
                        :team-player-field="
                          fields.top_leagues_list_event_name.field
                        "
                        :bet-item-field="fields.top_leagues_list_bet_item.field"
                        :bet-builder-button-field="
                          fields.top_leagues_betbuilder_button.field
                        "
                        :bet-item-state="states.top_leagues_list_bet_item"
                        :list-item-more-state="
                          states.top_leagues_list_item_more
                        "
                        :event-button-field="
                          fields.top_leagues_list_item_more.field
                        "
                        :bet-builder-state="
                          states.top_leagues_betbuilder_button
                        "
                        :items="leagueItem.items"
                        :event-cards-state="states.top_leagues_event_cards"
                      />
                    </WidgetField>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <BasicTitleField
              :field="fields.top_leagues_no_events_title.field"
            />
          </template>
        </BasicDesignWrapperField>
      </WidgetField>

      <div v-if="states.widget === State.SPORTSBOOK_ALL_LEAGUES">
        <SportsbookFenixMobilePrematchSearch
          :widget="widget"
          :states="states"
        />
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CellSizeType, ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useSportsbookInitData } from "~~/composables/widgets/sportsbook-fenix-mobile/useSportsbookInitData";
import { getCommonStyles } from "~~/assets/utils/styles";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  State,
  SportMenuLiveBet9CardLayoutMode,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { useLanguagesStore } from "~~/store/languages";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();
const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);

const { selectedDropdownItem, selectedWidget, selectedField } =
  storeToRefs(widgetSettingsStore);

const {
  states,
  filterFields,
  DEFAULT_STATES,

  initFields,
  menuItemsOptions,
  itemsFields,
} = useSportsbookInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const isWidgetSelected = computed<boolean>(
  () => props.widget.id === selectedWidget.value?.id
);

const isItemsGroupSelected = computed<boolean>(() => {
  return (
    (isWidgetSelected.value &&
      selectedField.value?.name === FieldName.EVENTS) ||
    selectedField.value?.name === FieldName.TOP_LEAGUES ||
    selectedField.value?.name === FieldName.ALL_LEAGUES
  );
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.header.field.options),
    alignItems: fields.value.header.field.options.alignment,
  };
});

const dayLabelStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.day_label.field.options),
    width:
      fields.value.day_label.field.options.resizing === ResizingType.FILL
        ? "100%"
        : "fit-content",
  };
});

const isFilterFieldSelected = computed<boolean>(() => {
  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  if (!selectedDropdownItem.value) {
    return false;
  }

  return !!STYLING_FIELDS[selectedDropdownItem.value];
});

const showWinnerLeague = computed<boolean>(() => {
  if (states.value.widget !== State.SPORTSBOOK_LEAGUE) {
    return false;
  }

  if (!fields.value.items.active) {
    return true;
  }

  if (states.value.items === State.SPORTSBOOK_TABS_LEAGUE_WINNER) {
    return true;
  }

  return false;
});

const isWidgetLeagueEventsState = computed<boolean>(() => {
  if (states.value.widget !== State.SPORTSBOOK_LEAGUE) {
    return false;
  }

  if (!fields.value.items.active) {
    return false;
  }

  if (states.value.items === State.SPORTSBOOK_TABS_EVENTS) {
    return false;
  }

  return true;
});

initFields();
const items = [
  {
    date: "Today",
    items: [
      {
        league: "League name",
        items: [
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
      {
        league: "League name",
        items: [
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
    ],
  },
  {
    date: "Tomorrow",
    items: [
      {
        league: "League name",
        items: [
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
      {
        league: "League name",
        items: [
          {
            start_time: "00:00",
            date: "00.00.00",
            league: "League",
            team_player: "Team / player #1 - Team / player #2",
            bet_items: [
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
              { name: "Bet name", coef: "0.00" },
            ],
            event_button: "+000",
          },
        ],
      },
    ],
  },
];

const cardsSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.league_name.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.list_container.field.options.distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.events_container.field.options.distanceBetweenLeagueEvents
    ),
  };
});

const dateSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.day_label.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.list_container.field.options.distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.events_container.field.options.distanceBetweenDateEvents
    ),
  };
});

const topLeaguesCardsSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.top_leagues_league_name.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.top_leagues_list_container.field.options
          .distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.top_leagues_events_container.field.options
        .distanceBetweenLeagueEvents
    ),
  };
});

const topLeaguesSpacingStyle = computed<ElementStyle>(() => {
  if (!fields.value.top_leagues_day_label.field.options._active) {
    return {
      gap: getPxValueFromNumber(
        fields.value.top_leagues_list_container.field.options
          .distanceBetweenCards
      ),
    };
  }

  return {
    gap: getPxValueFromNumber(
      fields.value.top_leagues_events_container.field.options
        .distanceBetweenDateEvents
    ),
  };
});

const filterContainerWidthStyles = computed<ElementStyle>(() => {
  const width = fields.value.filter_container.field.options.size.width;
  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.type === CellSizeType.HUG) {
    return {
      width: "initial",
      display: "inline-block",
    };
  }

  return {
    width: `${width.value?.value}${width.value?.type}`,
  };
});

onMounted(() => {
  const widgetBindingParams = props.widget.options.bindingParams;

  if (widgetBindingParams && widgetBindingParams.pageType) {
    const parsedPageTypeParam = (
      widgetBindingParams.pageType.value as Record<string, string>
    )[currentLanguageCode.value]
      .toLowerCase()
      .trim()
      .split(" ")
      .join("_");

    switch (parsedPageTypeParam) {
      case State.SPORTSBOOK_SPORT:
        states.value.widget = State.SPORTSBOOK_SPORT;
        break;

      case State.SPORTSBOOK_COUNTRY:
        states.value.widget = State.SPORTSBOOK_COUNTRY;
        break;

      case State.SPORTSBOOK_LEAGUE:
        states.value.widget = State.SPORTSBOOK_LEAGUE;
        break;

      case State.SPORTSBOOK_TOP_LEAGUES:
        states.value.widget = State.SPORTSBOOK_TOP_LEAGUES;
        break;

      case State.SPORTSBOOK_ALL_LEAGUES:
        states.value.widget = State.SPORTSBOOK_ALL_LEAGUES;
        break;

      default:
        states.value.widget = State.SPORTSBOOK_SPORT;
    }
  }
});
</script>

<style lang="scss">
.sportsbook-prematch-fenix-mobile-widget {
  .config-radio-icons__container {
    justify-content: flex-start;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    flex: 1;
  }

  &__dates {
    @include flex-column(stretch, flex-start, 0px);
  }

  &__leagues {
    @include flex-column(stretch, flex-start, 0px);
  }

  &__filter-second-view {
    display: flex;
  }

  &__filter-second-view-league-container {
    flex: auto;
    min-width: fit-content;
  }
}
</style>
