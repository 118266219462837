import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

import { useWidgetWithArrayField } from "../useWidgetWithArrayField";

import { useDropdownWidgetControls } from "./useDropdownWidgetControls";
import { useTitleControls } from "./useTitleControls";
import { useItemsControls } from "./useItemsControls";
import { useItemsGroupControls } from "./useItemsGroupControls";
import { useItemControls } from "./useItemControls";

export const useDropdownWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  titleState: Ref<State>,
  itemsGroupState: Ref<State>,
  emit: any
) => {
  const { titleField, arrayField, itemsGroup } = useWidgetWithArrayField(
    widget.value
  );

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Dropdown container", "title"),
    generateDropdownItem("Dropdown menu", "items"),
    generateDropdownItem("Menu items", "itemsGroup"),
    generateDropdownItem("Item", "item", "item"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const { widgetDesignControls, widgetContentControls } =
    useDropdownWidgetControls(widget, titleField);

  const { titleDesignControls, titleContentControls, titleStatesControls } =
    useTitleControls(titleState, titleField, emit);

  const { itemsControls } = useItemsControls(arrayField);

  const {
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  } = useItemsGroupControls(itemsGroup, itemsGroupState, emit);

  const { itemControls } = useItemControls();

  return {
    DROPDOWN_ITEMS,
    TABS,
    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    titleDesignControls,
    titleContentControls,
    titleStatesControls,
    itemsControls,
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
    itemControls,
  };
};
