import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import {
  Alignment,
  SliderArrowIcon,
  State,
} from "~~/models/widgets/widget-controls.model";

export const useArrowsInit = () => {
  return {
    data: DESIGN_INITIAL(),
    values: {
      spacing: {
        padding: getDefaultSpacing(12),
        margin: getDefaultSpacing(0),
      },
    },
    additionalData: {
      options: {
        button: {
          ...getInitialOptions({
            data: DESIGN_INITIAL(),
            exclude: ["spacing"],
            values: {
              fill: getDefaultFillValue("#ffffff"),
              shadow: getDefaultShadow({
                fill: getDefaultFillValue("#000000", 4),
                y: 2,
                blur: 4,
              }),
              cornerRadius: getDefaultCornerRadiusValue(50),
            },
          }).options,
          alignment: Alignment.CENTER,
          icon: {
            size: 24,
            value: SliderArrowIcon.OUTLINED,
            color: getDefaultFillValue("#1677FF"),
          },
        },
        states: {
          [State.HOVER]: {
            button: {
              fill: getDefaultFillValue("#ffffff"),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow({
                fill: getDefaultFillValue("#000000", 4),
                y: 2,
                blur: 4,
              }),
              cornerRadius: getDefaultCornerRadiusValue(50),
              icon: {
                color: getDefaultFillValue(),
              },
            },
          },
          [State.ACTIVE]: {
            button: {
              fill: getDefaultFillValue("#ffffff"),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow({
                fill: getDefaultFillValue("#000000", 4),
                y: 2,
                blur: 4,
              }),
              cornerRadius: getDefaultCornerRadiusValue(50),
              icon: {
                color: getDefaultFillValue(),
              },
            },
          },
        },
      },
    },
  };
};
