<template>
  <div v-if="hasDesignPermissions">
    <p
      v-if="label"
      class="m-b-4"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>

    <div class="config-range-slider__inputs">
      <a-slider
        :value="modelValue"
        :min="minValue"
        :max="maxValue"
        class="config-range-slider__slider"
        @update:value="handleUpdate"
      />

      <ConfigInput
        v-number
        :model-value="modelValue"
        :stretch-input="true"
        :is-number="true"
        :input-props="{
          min: minValue,
          max: maxValue,
          controls: false,
        }"
        class="config-range-slider__input"
        @update:model-value="handleUpdate"
      >
        <template #suffix>
          <span> {{ suffix ? suffix : "px" }} </span>
        </template>
      </ConfigInput>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";

defineProps<{
  modelValue: number;
  maxValue: number;
  minValue: number;
  label?: string;
  isBold?: boolean;
  suffix?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: string | number): void;
}>();

const handleUpdate = (value: string): void => {
  emit("update:modelValue", value);
};

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);
</script>

<style lang="scss">
.config-range-slider {
  &__inputs {
    width: 100%;
    @include flex(center, center, 7px);

    input {
      padding: 0;
    }
  }

  &__slider {
    flex-basis: 70%;
  }

  &__input {
    flex-basis: 30%;
  }
}
</style>
