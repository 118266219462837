import { Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { FillType, State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { CellSizeType } from "~~/models/grid.interface";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

import { IFields } from "../useWidgetFields";

export function useCardWidgetControls(
  widget: Ref<IWidgetWithFields>,
  widgetState: Ref<State>,
  fields: Ref<IFields>,
  disableFieldsAllowed: Ref<boolean>,
  emit: any
) {
  const descriptionFields = computed(() => {
    return widget.value.fields.filter(field =>
      field.name.includes("description")
    );
  });

  const widgetSettingsValuePath = computed<string>(() => {
    if (widgetState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${widgetState.value}`;
  });

  const widgetSettingsStore = useWidgetSettingsStore();

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],

        widget.value.options.fillImageColor.fillType === FillType.IMAGE && [
          1,
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.fillImageColor._useLazyLoad",
            valueSource: widget.value,
            options: {
              label: "Use lazy load",
              items: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
            },
          },
        ],

        widget.value.options.fillImageColor.fillType === FillType.IMAGE && [
          2,
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.fillImageColor._isImagePreload",
            valueSource: widget.value,
            options: {
              label: "Should image preload",
              items: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ].filter(Boolean) as [number, ControlProp][],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.layoutPosition",
            valueSource: widget.value,
            options: {},
            visible: true,
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.baseDisplaySettings.alignment",
            visible:
              widget.value.options.size.height.type === CellSizeType.FIXED,
            valueSource: widget.value,
            options: {
              label: "Widget alignment",
            },
          },
        ],
      },
      {
        componentPath: "content/ConfigImageWithToggle",
        valuePath: "value",
        valueSource: fields.value.image.field,
        options: {
          label: "Image",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.image.field.options._active,
          showOptions: fields.value.image.field.options._active,
          "onUpdate:active": (value: boolean) => {
            fields.value.image.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.image.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: disableFieldsAllowed.value,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "widget-control-container",
        options: {
          isMultiple: false,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: disableFieldsAllowed.value,
          fields: descriptionFields.value,
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description_1.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options._active",
        valueSource: fields.value.buttons_group.field,
        className: "p-l-16 p-t-16 p-r-16 p-b-16 widget-control-container",
        options: {
          label: "Buttons group",
          size: "small",
          allowDisable: disableFieldsAllowed.value,
          showOptions: fields.value.buttons_group.active,
          "onOptions-click": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.buttons_group.field
            );
          },
        },
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: widget.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
    ];
  });

  const widgeStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: widgetState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-widget-state", value);
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${widgetSettingsValuePath.value}.fillImageColor`,
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${widgetSettingsValuePath.value}.border`,
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${widgetSettingsValuePath.value}.shadow`,
        valueSource: widget.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
    widgeStatesControls,
  };
}
