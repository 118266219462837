import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useMarketCardsConfig = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Market card
  */
  const marketCardsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.card.fill",
        valueSource: fields.value.market_cards.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.card.border",
        valueSource: fields.value.market_cards.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.card.shadow",
        valueSource: fields.value.market_cards.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.card.cornerRadius",
        valueSource: fields.value.market_cards.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.card.spacing",
        valueSource: fields.value.market_cards.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const showDisplayMode = computed<boolean>(() => {
    /* 
      Show display mode control only for 1 and 2 columns layout.
    */
    return fields.value.market_cards.field.options.columns < 3;
  });

  const marketCardsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.columns",
            valueSource: fields.value.market_cards.field,
            className: "group-control-element event-widget__market-cards-radio",
            options: {
              label: "Market cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
              "onUpdate:modelValue": (value: number) => {
                if (value < 3) {
                  return;
                }
                if (
                  fields.value.market_cards.field.options.items.displayMode ===
                  DisplayOrientation.HORIZONTAL
                ) {
                  widgetsStore.updateFieldOptionValue(
                    DisplayOrientation.VERTICAL,
                    "items.displayMode"
                  );
                }
              },
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.distanceBetweenCards",
            valueSource: fields.value.market_cards.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.items.displayMode",
            valueSource: fields.value.market_cards.field,
            visible: showDisplayMode.value,
            options: {
              label: "Bet items layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.items.distanceBetweenItems",
            valueSource: fields.value.market_cards.field,
            className: "group-control-element",
            visible: showDisplayMode.value,
            options: {
              label: "Distance between bets items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Suspended status",
              value: "market_suspended_status",
            },
            {
              label: "Market name",
              value: "market_name",
            },
            {
              label: "Pin icon",
              value: "favourite_event_market",
            },
            {
              label: "Bet items",
              value: "bet_items",
            },
          ],
        },
      },
    ];
  });

  const marketNameControls = computed(() => {
    return [
      typography({
        source: fields.value.market_name.field,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.market_name.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const favouriteEventMarketControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.favourite_event_market.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: fields.value.favourite_event_market.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const marketSuspendedStatusDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.market_suspended_status.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.market_suspended_status.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.market_suspended_status.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.market_suspended_status.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.margin",
        valueSource: fields.value.market_suspended_status.field,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const marketSuspendedStatusContentControls = computed(() => {
    return [
      typography({
        source: fields.value.market_suspended_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  return {
    marketCardsDesignControls,
    marketCardsContentControls,

    marketNameControls,

    favouriteEventMarketControls,

    marketSuspendedStatusDesignControls,
    marketSuspendedStatusContentControls,
  };
};
