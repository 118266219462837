import { ICell } from "~~/models/grid.interface";
import { Sizing } from "~~/models/grid.interface";
import {
  findCellRootElement,
  findParentLevelCell,
} from "~~/assets/utils/grid/grid-tree";
import { useGridConfig } from "~~/store/grid";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useWidthConfig = () => {
  const gridStore = useGridConfig();
  const widgetsSettingStore = useWidgetSettingsStore();

  const { selectedCell } = storeToRefs(widgetsSettingStore);

  const rootCell = computed<ICell | null>(() => {
    return findCellRootElement(
      gridStore.currentWidgetsLayout,
      selectedCell.value
    );
  });

  const parentCell = computed<ICell | null>(() => {
    return findParentLevelCell(
      gridStore.currentWidgetsLayout,
      selectedCell.value
    );
  });

  const isWidthConfigDisabled = computed<boolean>(() => {
    if (!parentCell.value) {
      return false;
    }

    return parentCell.value.settings.sizing === Sizing.FIXED;
  });

  return {
    isWidthConfigDisabled,
    rootCell,
    parentCell,
  };
};
