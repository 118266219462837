import {
  State,
  TextPosition,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../../useWidgetFields";
import { useTitleLinkStateControls } from "../../card/useTitleLinkStatesControls";
import { useAllowDisableDependentFieldContentItems } from "../../useAllowDisableDependentFieldContentItems";

export const useCasinoListHoverContentControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const casinoListGamesHoverContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.alignment",
            valueSource: fields.value.hover_content.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gap",
            valueSource: fields.value.hover_content.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.position",
            valueSource: fields.value.hover_content.field,
            options: {
              label: "Title position",
              items: [
                {
                  label: "Top",
                  value: TextPosition.TOP,
                },
                {
                  label: "Bottom",
                  value: TextPosition.BOTTOM,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: fields.value.hover_content.field,
            options: {
              label: "Buttons layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.hover_content.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Title",
              value: "game_title",
              isActiveValueSource: fields.value.game_title.field.options,
            },
            { label: "Play for money button", value: "play_money_button" },
            { label: "Play for free button", value: "play_free_button" },
          ],
        },
      },
    ];
  });

  const titleValue = computed<IWidgetField>(() => {
    return fields.value.game_title.field;
  });

  const { titleLinkStatesControls: casinoListGameTitleLinkControls } =
    useTitleLinkStateControls(
      states,
      undefined,
      emit,
      titleValue,
      undefined,
      "game_title"
    );

  const casinoListGameTitleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({
              name: "gameTitleLinkStates",
            });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const casinoListGamePlayMoneyButtonDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const currentButtonPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const casinoListGamePlayMoneyButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListGamePlayMoneyButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentButtonPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentButtonPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentButtonPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentButtonPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentButtonPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const casinoListGamePlayFreeButtonDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const casinoListGamePlayFreeButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListGamePlayFreeButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentButtonPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentButtonPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentButtonPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentButtonPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentButtonPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    casinoListGamesHoverContentControls,
    casinoListGameTitleControls,
    casinoListGameTitleLinkControls,

    casinoListGamePlayMoneyButtonDesignControls,
    casinoListGamePlayMoneyButtonContentControls,
    casinoListGamePlayMoneyButtonStateControls,

    casinoListGamePlayFreeButtonDesignControls,
    casinoListGamePlayFreeButtonContentControls,
    casinoListGamePlayFreeButtonStateControls,
  };
};
