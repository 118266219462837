import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useItems } from "~~/composables/widgets/bonuses/fenix/useBonusesFenixItems";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { stateConfig } from "~~/constants/configs/common/state-config";

import { useNoItemsTextConfig } from "../../common/useNoItemsTextConfig";

const MODAL_CONFIG_TABS = [
  {
    label: "Modal title",
    value: "modal_title",
  },
  {
    label: "Modal description",
    value: "modal_description",
  },
  {
    label: "Modal primary button",
    value: "modal_primary_button",
  },
  {
    label: "Modal secondary button",
    value: "modal_secondary_button",
  },
  {
    label: "Modal icon",
    value: "modal_close_icon",
  },
];

const CARDS_CONFIG_TABS = [
  {
    label: "Photo",
    value: "image",
  },
  {
    label: "Title",
    value: "bonus_title",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Dates",
    value: "dates",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Progress bar",
    value: "progress_bar",
  },
  {
    label: "Amounts",
    value: "amounts",
  },
  {
    label: "Terms and conditions",
    value: "terms_title",
  },
  {
    label: "Button",
    value: "action_button",
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems(widget);

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: widget.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: widget.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.screen,
        options: {
          items: [
            {
              label: "Bonuses page",
              value: State.ACTIVE_EVENTS,
            },
            {
              label: "Modal",
              value: State.MODAL,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "screen", value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items:
            states.value.screen === State.MODAL
              ? [{ label: "Modal Settings", value: "modal" }]
              : [...SETTINGS_ITEMS],
        },
      },
    ];
  });

  /* 
    Bonus group
  */
  const bonusGroupDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const bonusGroupContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              label: "Width",
              type: ColorPickerType.TEXT,
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distanceBetweenGroups",
            valueSource: selectedField.value,
            options: {
              label: "Distance between bonus groups",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distanceBetweenCards",
            valueSource: selectedField.value,
            options: {
              label: "Distance between bonus cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Group Title", value: "bonus_group_title" },
            { label: "Group Description", value: "bonus_group_description" },
            { label: "Bonus Card", value: "cards" },
          ],
        },
      },
    ];
  });

  /* 
    Bonus group title
  */

  const bonusGroupTitleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Bonus group description
  */

  const bonusGroupDescriptionControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
   Cards
  */

  const cardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedField.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const cardsContentControls = computed(() => {
    const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
      toRef({
        widget: widget.value,
        states: states.value,
        stateName: "screen",
        noTextFieldName: "no_active_bonuses",
        customLabels: {
          [State.ACTIVE_EVENTS]: "There are active bonuses",
          [State.NO_ACTIVE]: "No active bonuses",
        },
      }),
      emit
    );

    if (states.value.screen === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: CARDS_CONFIG_TABS,
        },
      },
    ];
  });

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const cardsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value.cards,
        onUpdate: value => {
          emit("update-state", { state: "cards", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.cards.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: selectedElementPath.value,
      }),
    ];
  });

  /*
   Cards
  */
  const modalContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              label: "Width",
              type: ColorPickerType.TEXT,
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: MODAL_CONFIG_TABS,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    bonusGroupTitleControls,
    bonusGroupDescriptionControls,

    bonusGroupDesignControls,
    bonusGroupContentControls,

    cardsDesignControls,
    cardsContentControls,
    cardsStatesControls,

    modalContentControls,
  };
};
