import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { CellSizeType } from "~~/models/grid.interface";

import { IFields } from "../useWidgetFields";

export const useComingEventsCardsControls = (
  statesValuePath: ComputedRef<string>,
  states: Ref<Record<string, State>>,
  fields: Ref<IFields>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const eventCardsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_cards.field,
      basePath: "options.card",
    });
  });

  const eventCardsContentControls = computed<ControlProp[]>(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.with_active_events,
              value: State.WITH_ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS.no_active_events,
              value: State.NO_ACTIVE_EVENTS,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
    ];

    if (states.value.event_cards === State.WITH_ACTIVE_EVENTS) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Display mode"),
          controls: [
            {
              componentPath: "common/ConfigRadioIcon",
              valuePath: "options.columns",
              valueSource: selectedField.value,
              className: "group-control-element event-cards-radio",
              options: {
                label: "Event cards layout",
                items: [
                  { icon: "custom:layout-column-1", value: 1 },
                  { icon: "custom:layout-column-2", value: 2 },
                  { icon: "custom:layout-column-3", value: 3 },
                ],
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.distanceBetweenCards",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Distance between cards",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "common/ConfigRadioTextElements",
              valuePath: "options.items.displayMode",
              valueSource: selectedField.value,
              options: {
                label: "Content items layout",
                items: [
                  { label: "Horizontal", value: "horizontal" },
                  { label: "Vertical", value: "vertical" },
                ],
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.items.distance",
              valueSource: selectedField.value,
              className: "group-control-element",
              options: {
                label: "Distance between content items",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              { label: "Start time / date", value: "event_time" },
              { label: "Team / player", value: "teams_name" },
            ],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "value",
          valueSource: fields.value.no_events_title.field,
          options: {
            placeholder: "Text",
            label: "Text",
          },
        },
        typography({ source: fields.value.no_events_title.field }, "Text"),
      ];
    }
  });

  const eventCardsStatePath = computed<string>(() => {
    if (states.value.event_cards_item === State.DEFAULT) {
      return "options.card";
    }

    return `options.states.${states.value.event_cards_item}`;
  });

  const eventCardsStatesControls = computed<ControlProp[]>(() => {
    const controls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.event_cards_item,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "event_cards_item", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${eventCardsStatePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${eventCardsStatePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${eventCardsStatePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
        },
      },
    ];

    return controls;
  });
  const isDateIconVisible = computed(
    () =>
      fields.value.event_time.field.options.displayMode === "date" &&
      fields.value.event_time.field.options.icon._active
  );
  const isTimeIconVisible = computed(
    () => fields.value.event_time.field.options.icon._activeTime
  );

  const startTimeDateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigCommonRadio",
        valuePath: "options.displayMode",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          firstVariant: "Date & time",
          firstValue: "date",
          secondaryVariant: "Time to start",
          secondaryValue: "time",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              type: ColorPickerType.TEXT,
              icon: "ant-design:column-width-outlined",
              isWidth: true,
              exclude: [CellSizeType.FILL],
            },
          },
        ],
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icons"),
        controls: [
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.icon._active",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            visible: selectedField.value?.options.displayMode === "date",
            options: {
              label: "Date icon",
              size: "small",
              allowDisable: true,
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueDate",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            visible: isDateIconVisible.value,
            options: {
              label: "",
            },
          },
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.icon._activeTime",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Time icon",
              size: "small",
              allowDisable: true,
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueTime",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            visible: isTimeIconVisible.value,
            options: {
              label: "",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            visible: isTimeIconVisible.value || isDateIconVisible.value,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            visible: isTimeIconVisible.value || isDateIconVisible.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventButtonLinkDesignControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.spacing.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.spacing.margin",
        valueSource: selectedField.value,
        options: {
          label: "Margin",
          isHorizontal: true,
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const eventButtonLinkContentControls = computed<ControlProp[]>(() => {
    return [
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
    ];
  });

  const eventButtonLinkStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value.event_button,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "event_button", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const betItemsDesignControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betItemsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: selectedField.value,
        options: {
          label: "Distance between bet items",
          showIconPrefix: false,
          isBold: true,
          showSuffix: true,
          suffix: "px",
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.name.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.name.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.name.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.coef.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.coef.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.coef.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  const betItemsStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  return {
    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStatesControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    startTimeDateControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  };
};
