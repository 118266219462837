<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @blur-tab="resetStates"
        @change="resetStates"
        @close="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #login-design>
          <ControlsList
            :widget="widget"
            :controls="loginButtonDesignControls"
          />
        </template>

        <template #login-content>
          <ControlsList
            :widget="widget"
            :controls="loginButtonContentControls"
          />
        </template>

        <template #login-states>
          <ControlsList
            :widget="widget"
            :controls="loginButtonStatesControls"
          />
        </template>

        <template #registration-design>
          <ControlsList
            :widget="widget"
            :controls="signupButtonDesignControls"
          />
        </template>

        <template #registration-content>
          <ControlsList
            :widget="widget"
            :controls="signupButtonContentControls"
          />
        </template>

        <template #registration-states>
          <ControlsList
            :widget="widget"
            :controls="signupButtonStatesControls"
          />
        </template>

        <template #deposit-design>
          <ControlsList
            :widget="widget"
            :controls="depositButtonDesignControls"
          />
        </template>

        <template #deposit-content>
          <ControlsList
            :widget="widget"
            :controls="depositButtonContentControls"
          />
        </template>

        <template #deposit-states>
          <ControlsList
            :widget="widget"
            :controls="depositButtonStatesControls"
          />
        </template>

        <template #logout-design>
          <ControlsList
            :widget="widget"
            :controls="logoutButtonDesignControls"
          />
        </template>

        <template #logout-content>
          <ControlsList
            :widget="widget"
            :controls="logoutButtonContentControls"
          />
        </template>

        <template #logout-states>
          <ControlsList
            :widget="widget"
            :controls="logoutButtonStatesControls"
          />
        </template>

        <template #account-design>
          <ControlsList
            :widget="widget"
            :controls="accountDesignControls"
          />
        </template>

        <template #account-content>
          <ControlsList
            :widget="widget"
            :controls="accountContentControls"
          />
        </template>

        <template #dropdown_container-design>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerDesignControls"
          />
        </template>

        <template #dropdown_container-content>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerContentControls"
          />
        </template>

        <template #dropdown_container-states>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerStatesControls"
          />
        </template>

        <template #dropdown_menu>
          <ControlsList
            :widget="widget"
            :controls="dropdownMenuControls"
          />
        </template>

        <template #account_info-design>
          <ControlsList
            :widget="widget"
            :controls="accountInfoDesignControls"
          />
        </template>

        <template #account_info-content>
          <ControlsList
            :widget="widget"
            :controls="accountInfoContentControls"
          />
        </template>

        <template #user_id-design>
          <ControlsList
            :widget="widget"
            :controls="userIdDesignControls"
          />
        </template>

        <template #user_id-content>
          <ControlsList
            :widget="widget"
            :controls="userIdContentControls"
          />
        </template>

        <template #username>
          <ControlsList
            :widget="widget"
            :controls="usernameControls"
          />
        </template>

        <template #account_number>
          <ControlsList
            :widget="widget"
            :controls="accountNumberControls"
          />
        </template>

        <template #balance-design>
          <ControlsList
            :widget="widget"
            :controls="balanceDesignControls"
          />
        </template>

        <template #balance-content>
          <ControlsList
            :widget="widget"
            :controls="balanceContentControls"
          />
        </template>

        <template #bonus>
          <ControlsList
            :widget="widget"
            :controls="balanceItemControls"
          />
        </template>

        <template #real>
          <ControlsList
            :widget="widget"
            :controls="balanceItemControls"
          />
        </template>

        <template #balanceItems>
          <ControlsList
            :widget="widget"
            :controls="balanceItemsControls"
          />
        </template>

        <template #account_menu-design>
          <ControlsList
            :widget="widget"
            :controls="accountMenuDesignControls"
          />
        </template>

        <template #account_menu-content>
          <ControlsList
            :widget="widget"
            :controls="accountMenuContentControls"
          />
        </template>

        <template #item>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="itemsGroupStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/profile-dropdown/fenix/useProfileDropdownFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const { bindingParams } = useWidgetDynamicParams(
  {
    "redirectAfterLogoutUrl": {
      label: "Redirect after logout URL",
      sources: ["custom"],
      required: false,
    },
    "depositPageUrl": {
      label: "Deposit page URL",
      sources: ["custom"],
      required: false,
    },
    "bonuses": {
      label: "Bonuses",
      description: "Possible values: betting | casino",
      sources: ["custom"],
      required: false,
    },
    "balance": {
      label: "Balance",
      description: "Possible values: betting | casino | withdrawable",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  loginButtonDesignControls,
  loginButtonContentControls,
  loginButtonStatesControls,

  signupButtonDesignControls,
  signupButtonContentControls,
  signupButtonStatesControls,

  logoutButtonDesignControls,
  logoutButtonContentControls,
  logoutButtonStatesControls,

  depositButtonDesignControls,
  depositButtonContentControls,
  depositButtonStatesControls,

  accountDesignControls,
  accountContentControls,

  dropdownContainerDesignControls,
  dropdownContainerContentControls,
  dropdownContainerStatesControls,

  dropdownMenuControls,

  accountInfoDesignControls,
  accountInfoContentControls,

  userIdDesignControls,
  userIdContentControls,

  usernameControls,
  accountNumberControls,

  balanceDesignControls,
  balanceContentControls,

  balanceItemsControls,

  itemControls,
  balanceItemControls,

  accountMenuDesignControls,
  accountMenuContentControls,

  itemsGroupDesignControls,
  itemsGroupContentControls,
  itemsGroupStatesControls,
} = useConfig(ref(props.widget), toRef(props.states), emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
