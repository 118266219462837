import { ResizingType } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 40;

const v40WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;
  if (["CasinoMenuWidget"].includes(widget.name)) {
    widget.options.contentWidth = ResizingType.FILL;
  }

  // if (["CasinoGamesListWidget"].includes(widget.name)) {
  //   if(widget.content.)

  // }
  return widget;
};
export default v40WidgetMigration;
