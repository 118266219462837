import {
  getBackgroundFillStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getDisplayOrientationStyles,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

export const useBetsListStyles = (widget: IWidgetWithFields) => {
  const fields = useWidgetFields(widget as IWidgetWithFields);

  const headerStyles = computed(() => {
    const headerOptions = fields.value.header?.field?.options || {};

    const fill = getBackgroundFillStyle(headerOptions.fill);
    const border = getBorderStyle(headerOptions.border);
    const shadow = getBoxShadowStyle(headerOptions.shadow);
    const cornerRadius = getCornerRadiusStyle(headerOptions.cornerRadius);
    const margin = getSpacing(
      headerOptions.spacing?.margin,
      SpacingKeyName.MARGIN
    );
    const padding = getSpacing(
      headerOptions.spacing?.padding,
      SpacingKeyName.PADDING
    );
    const displayOrientation = getDisplayOrientationStyles(
      headerOptions.displayOrientation
    );

    return {
      ...fill,
      ...border,
      ...shadow,
      ...cornerRadius,
      ...margin,
      ...padding,
      ...displayOrientation,
    };
  });

  return {
    headerStyles,
  };
};
