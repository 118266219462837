import { getDefaultCornerRadiusValue } from "~~/constants/configs/common/widget-initial";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { ICornerRadiusControl } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 49;

const applyNewOptions = (
  oldValue: string | number | null
): ICornerRadiusControl => {
  if (typeof oldValue === "object" && oldValue) {
    return oldValue;
  }

  const newOptions = getDefaultCornerRadiusValue(oldValue as number);

  return newOptions;
};

const updateCornerValue = (options: IWidgetOptions): void => {
  for (const optionName in options) {
    if (optionName === "cornerRadius" || optionName === "contentCornerRadius") {
      options[optionName] = applyNewOptions(options[optionName]);
      continue;
    }

    if (typeof options[optionName] === "object" && options[optionName]) {
      updateCornerValue(options[optionName]);
    }
  }
};

const v46WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  widget.options.cornerRadius = applyNewOptions(widget.options.cornerRadius);

  for (const fieldName in widget.content) {
    const field = widget.content[fieldName];

    updateCornerValue(field.options);
  }

  if (widget.options._customFields) {
    for (const fieldName in widget.options._customFields) {
      const field = widget.options._customFields[fieldName];

      updateCornerValue(field.options);
    }
  }
  return widget;
};
export default v46WidgetMigration;
