import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

export const useTableFieldsConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const dataDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.table_data.field,
    });
  });

  const dataContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigDimension",
        valuePath: "options.width",
        valueSource: fields.value.table_data.field,
        visible: fields.value.table.field.options.mode !== "wrap",
        options: {
          placeholder: "Enter text",
          type: ColorPickerType.TEXT,
          label: "Width",
          icon: "ant-design:column-width-outlined",
          isWidth: true,
          exclude: [ResizingType.HUG, ResizingType.FILL],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Checkboxes",
              value: "table_checkboxes_settings",
            },
            {
              label: "Date",
              value: "table_date",
            },
            {
              label: "Reference",
              value: "table_reference",
            },
          ],
        },
      },
    ];
  });

  const tableDateControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.table_date.field,
      }),
    ];
  });

  const tableReferenceControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.table_reference.field,
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.table_reference.field,
            options: {},
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.table_reference.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.table_reference.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  /* 
    Parametrs
  */

  const parametrsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.table_parameters.field,
    });
  });

  const parametrsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigDimension",
        valuePath: "options.width",
        valueSource: fields.value.table_parameters.field,
        visible: fields.value.table.field.options.mode !== "wrap",
        options: {
          placeholder: "Enter text",
          type: ColorPickerType.TEXT,
          label: "Width",
          icon: "ant-design:column-width-outlined",
          isWidth: true,
          exclude: [ResizingType.HUG, ResizingType.FILL],
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Transaction type",
              value: "table_transaction_type",
            },
            {
              label: "Amount",
              value: "table_amount",
            },
            {
              label: "Status",
              value: "table_status",
            },
          ],
        },
      },
    ];
  });

  const tableTransactionTypeControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.table_transaction_type.field,
      }),
    ];
  });

  const tableStatusControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.table_status.field,
      }),
    ];
  });

  const tableAmountControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.table_amount.field,
            className: "group-control-element",
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.table_amount.field,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.table_amount.field.options.label,
          isOptionPath: false,
          toggleable: true,
          except: ["ConfigAlignment"],
        },
        "Amount"
      ),
      typography(
        {
          source: fields.value.table_amount.field.options.value,
          isOptionPath: false,
          except: ["ConfigAlignment"],
        },
        "Value"
      ),
    ];
  });

  const tableDescriptionControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.table_description.field,
      }),
    ];
  });

  return {
    dataDesignControls,
    dataContentControls,

    tableDateControls,
    tableReferenceControls,

    parametrsDesignControls,
    parametrsContentControls,

    tableStatusControls,
    tableTransactionTypeControls,
    tableAmountControls,
    tableDescriptionControls,
  };
};
