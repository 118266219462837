<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @blur-tab="$emit('reset-states')"
        @change="$emit('reset-states')"
        @close="$emit('reset-states')"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #page_title>
          <ControlsList
            :widget="widget"
            :controls="pageTitleControls"
          />
        </template>

        <template #tabs-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #tabs-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #tabs-states>
          <ControlsList
            :widget="widget"
            :controls="tabsStatesControls"
          />
        </template>

        <template #promo_list-design>
          <ControlsList
            :widget="widget"
            :controls="promoListDesignControls"
          />
        </template>

        <template #promo_list-content>
          <ControlsList
            :widget="widget"
            :controls="promoListContentControls"
          />
        </template>

        <template #promo_group-design>
          <ControlsList
            :widget="widget"
            :controls="promoGroupDesignControls"
          />
        </template>

        <template #promo_group-content>
          <ControlsList
            :widget="widget"
            :controls="promoGroupContentControls"
          />
        </template>

        <template #promo_group_title-design>
          <ControlsList
            :widget="widget"
            :controls="promoGroupTitleDesignControls"
          />
        </template>

        <template #promo_group_title-content>
          <ControlsList
            :widget="widget"
            :controls="promoGroupTitleContentControls"
          />
        </template>

        <template #promo_group_list-design>
          <ControlsList
            :widget="widget"
            :controls="promoGroupListDesignControls"
          />
        </template>

        <template #promo_group_list-content>
          <ControlsList
            :widget="widget"
            :controls="promoGroupListContentControls"
          />
        </template>

        <template #promo_cards-design>
          <ControlsList
            :widget="widget"
            :controls="promoCardsDesignControls"
          />
        </template>

        <template #promo_cards-content>
          <ControlsList
            :widget="widget"
            :controls="promoCardsContentControls"
          />
        </template>

        <template #promo_cards-states>
          <ControlsList
            :widget="widget"
            :controls="promoCardsStatesControls"
          />
        </template>

        <template #promo_image>
          <ControlsList
            :widget="widget"
            :controls="promoImageControls"
          />
        </template>

        <template #promo_title>
          <ControlsList
            :widget="widget"
            :controls="promoTitleControls"
          />
        </template>

        <template #promo_primary_description>
          <ControlsList
            :widget="widget"
            :controls="promoTitleControls"
          />
        </template>

        <template #promo_secondary_description>
          <ControlsList
            :widget="widget"
            :controls="promoTitleControls"
          />
        </template>

        <template #promo_button_group-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonGroupDesignControls"
          />
        </template>

        <template #promo_button_group-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonGroupContentControls"
          />
        </template>

        <template #promo_button_1-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonDesignControls"
          />
        </template>

        <template #promo_button_1-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonContentControls"
          />
        </template>

        <template #promo_button_1-states>
          <ControlsList
            :widget="widget"
            :controls="promoButtonStatesControls"
          />
        </template>

        <template #promo_button_2-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonDesignControls"
          />
        </template>

        <template #promo_button_2-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonContentControls"
          />
        </template>

        <template #promo_button_2-states>
          <ControlsList
            :widget="widget"
            :controls="promoButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/promotions-list/usePromotionsListConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  useWidgetDynamicParams,
  IParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

defineEmits<{
  (e: "reset-states"): void;
}>();

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,

  pageTitleControls,

  promoListDesignControls,
  promoListContentControls,

  promoGroupDesignControls,
  promoGroupContentControls,
  promoGroupTitleDesignControls,
  promoGroupTitleContentControls,
  promoGroupListDesignControls,
  promoGroupListContentControls,

  promoCardsDesignControls,
  promoCardsContentControls,
  promoCardsStatesControls,

  promoImageControls,

  promoTitleControls,

  promoButtonGroupDesignControls,
  promoButtonGroupContentControls,
  promoButtonDesignControls,
  promoButtonContentControls,
  promoButtonStatesControls,

  tabsDesignControls,
  tabsContentControls,
  tabsStatesControls,
} = useConfig(ref(props.widget), states);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const bindingParamsArg = computed<IParams>(() => {
  return {
    "pageType": {
      label: "Page type",
      description: "Possible values: All | One",
      sources: ["custom"],
      required: true,
    },
    "promotionPageUrl": {
      label: "Promotion Page URL",
      sources: ["custom"],
      required: true,
    },
    "groupUrl": {
      label: "Group URL",
      sources: ["custom"],
      required: true,
    },
    "groupId": {
      label: "Group Id",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "mainPromotionsPageUrl": {
      label: "Main promotions page URL",
      sources: ["custom"],
      required: false,
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});
</script>
