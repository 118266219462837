<template>
  <div
    class="basic-image-field"
    :style="imageContainerStyle"
  >
    <img
      class="basic-image-field__image"
      :src="imageSrc"
      :style="imageStyleValue"
      draggable="false"
    />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import useImageCdnChange from "~~/composables/useImageCdnChange";

const field = defineModel<IWidgetField>("field", {
  default: () => ({}),
});

const props = defineProps<{
  value?: string;
}>();

const imageStyleValue = computed(() => {
  if (!imageSrc.value) {
    return Object.assign(useWidgetStyle(field.value).value, { opacity: "0" });
  }
  return useWidgetStyle(field.value).value;
});

const { getLocalizedValue } = useLocalizedValue();

const imageSrc = computed<string>(() => {
  const localizedValue = getLocalizedValue.value(
    props.value || field.value.value
  );

  return useImageCdnChange(localizedValue);
});

const imageContainerStyle = computed<{
  [key: string]: string | number;
}>(() => {
  const options = field.value.options;

  return {
    "justify-content": options.containerSize.alignment,
  };
});
</script>

<style lang="scss">
.basic-image-field {
  @include flex(center, center, 0);
  border: 1px solid transparent;

  &__image {
    max-width: 100%;
  }
}
</style>
