import { State } from "~~/models/widgets/widget-controls.model";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useWidgetsStore } from "~~/store/widgets";

export const useButtonWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value);

  const DEFAULT_STATES = {
    button: State.DEFAULT,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const { handleStateUpdate, resetStates } = useStates({
    defaultStates: DEFAULT_STATES,
    states,
  });

  const {
    initWidgetOptions,
    initFields,

    addFieldInitialOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([]);

  const init = (): void => {
    initFields();

    addFieldInitialOptions("button", {
      data: BUTTON_INITIAL(),
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    states,
    fields,

    init,
    handleStateUpdate,
    resetStates,
  };
};
