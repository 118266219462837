import { type Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidthConfig } from "../common/useWidthConfig";
import { IFields } from "../useWidgetFields";

import { useFenixBetslipItems } from "./useFenixBetslipItems";

export const useFenixBetslipWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any,
  fields: ComputedRef<IFields>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedWidget } = storeToRefs(widgetSettingsStore);

  const {
    WIDGET_SETTINGS_ITEMS,
    WIDGET_SETTINGS_MY_BETS_ITEMS,
    WIDGET_SETTINGS_NO_BETS_ITEMS,
    WIDGET_SETTINGS_LOADER_ITEMS,
  } = useFenixBetslipItems(widget);

  const { isWidthConfigDisabled } = useWidthConfig();

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const currentWidgetSettingsItems = computed<
    Array<{ label: string; value: string }>
  >(() => {
    if (states.value[widget.value.name] === State.MY_BETS) {
      return WIDGET_SETTINGS_MY_BETS_ITEMS;
    }

    if (
      states.value[widget.value.name] === State.NO_BETS ||
      states.value[widget.value.name] === State.BOOKING_CODE
    ) {
      return WIDGET_SETTINGS_NO_BETS_ITEMS;
    }

    if (states.value[widget.value.name] === State.LOADER) {
      return WIDGET_SETTINGS_LOADER_ITEMS;
    }

    return WIDGET_SETTINGS_ITEMS;
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedWidget.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.betslip,
              value: State.BETSLIP,
            },
            {
              label: STATE_KEY_LABELS.my_bets,
              value: State.MY_BETS,
            },
            {
              label: STATE_KEY_LABELS.bets_result,
              value: State.BETS_RESULT,
            },
            {
              label: STATE_KEY_LABELS.booking_code,
              value: State.BOOKING_CODE,
            },
            {
              label: STATE_KEY_LABELS.LOADER,
              value: State.LOADER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedWidget.value!.name, value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: currentWidgetSettingsItems.value,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.loader.field,
        visible: states.value[selectedWidget.value!.name] === State.LOADER,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
  };
};
