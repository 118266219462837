import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  MAX_GAP_INPUT_VALUE,
  MIN_GAP_INPUT_VALUE,
} from "~~/constants/input-validation";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useFormFieldConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  customNames?: Record<string, string>
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  /* 
    Label
  */
  const formLabelsStyles = computed(() => {
    return [
      typography({
        source: fields.value.labels_styling.field,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.position",
        valueSource: fields.value.labels_styling.field,
        options: {
          label: "Position",
          isBold: true,
          items: [
            { value: "vertical", label: "Vertical" },
            { value: "horizontal", label: "Horizontal" },
          ],
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.containerPadding",
        valueSource: fields.value.labels_styling.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const formLabelsStylesWithIconSettings = computed(() => {
    return [
      typography({
        source: fields.value.labels_styling.field,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.position",
        valueSource: fields.value.labels_styling.field,
        options: {
          label: "Position",
          isBold: true,
          items: [
            { value: "vertical", label: "Vertical" },
            { value: "horizontal", label: "Horizontal" },
          ],
        },
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.iconRight.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value[currentFieldName.value].field,
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.iconRight.color",
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              placeholder: "Enter text",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconRight.iconSize",
            valueSource: fields.value[currentFieldName.value].field,
            className: "p-l-16 p-t-16 p-r-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconRight.gap",
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              minValue: MIN_GAP_INPUT_VALUE,
              maxValue: MAX_GAP_INPUT_VALUE,
              label: "Spacing between text and icon",
              className: "p-l-16 p-t-16 p-r-16",
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.containerPadding",
        valueSource: fields.value.labels_styling.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  /* 
    Tooltip
  */

  const formTooltipDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const formTooltipContentStyles = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.mode",
        valueSource: fields.value.tooltips_styling.field,
        options: {
          label: "Mode (visible on click at)",
          isBold: true,
          items: [
            {
              label: "Icon",
              value: "icon",
            },
            {
              label: "Field",
              value: "field",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.tooltips_styling.field,
        visible: fields.value.tooltips_styling.field.options.mode === "icon",
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.tooltips_styling.field,
          except: ["ConfigAlignment"],
        },
        "Tooltips text"
      ),
    ];
  });

  /* 
    Field
  */

  const currentFieldName = computed<string>(() => {
    if (customNames?.fields_styling) {
      return customNames.fields_styling;
    }

    return "fields_styling";
  });

  const formFieldDesignStyles = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const getIconRightControls = (withGap: boolean) => {
    const colorPickerControl = {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.iconRight.color",
      valueSource: fields.value[currentFieldName.value].field,
      options: {
        placeholder: "Enter text",
        label: "Icon color",
        type: ColorPickerType.TEXT,
      },
    };

    return withGap
      ? [
          colorPickerControl,
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconRight.gap",
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              minValue: MIN_GAP_INPUT_VALUE,
              maxValue: MAX_GAP_INPUT_VALUE,
              label: "Spacing between text and icon",
              className: "p-l-16 p-t-16 p-r-16",
            },
          },
        ]
      : [colorPickerControl];
  };

  const formFieldTypograpyControls = computed(() => {
    return {
      section: generateDesignSectionName("Typography"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.color",
          valueSource: fields.value[currentFieldName.value].field,
          options: {
            placeholder: "Enter text",
            label: "Value color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.placeholderColor",
          valueSource: fields.value[currentFieldName.value].field,
          options: {
            placeholder: "Enter text",
            label: "Placeholder color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.theme",
          valueSource: fields.value[currentFieldName.value].field,
          options: {
            placeholder: "Enter text",
            label: "Theme",
          },
        },
        {
          componentPath: "ConfigFontFamilySelect",
          valuePath: "options.fontFamily",
          valueSource: fields.value[currentFieldName.value].field,
          options: {
            placeholder: "Select",
            label: "Font family",
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: "options.decoration",
          valueSource: fields.value[currentFieldName.value].field,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    };
  });

  const formFieldContentStyles = computed(() => {
    return [
      formFieldTypograpyControls.value,
      {
        section: generateDesignSectionName("Icon right"),
        controls: getIconRightControls(true),
      },
    ];
  });

  const formFieldContentStylesWithIconGap = computed(() => {
    return [
      formFieldTypograpyControls.value,
      {
        section: generateDesignSectionName("Icon right"),
        controls: getIconRightControls(true),
      },
    ];
  });

  const formFieldStatePath = computed(() => {
    if (states.value.fields_styling === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.fields_styling}`;
  });

  const formFieldStates = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.fields_styling,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.fields_styling = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${formFieldStatePath.value}.fill`,
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${formFieldStatePath.value}.border`,
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${formFieldStatePath.value}.shadow`,
        valueSource: fields.value[currentFieldName.value].field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${formFieldStatePath.value}.color`,
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              placeholder: "Enter text",
              label: "Value color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${formFieldStatePath.value}.placeholderColor`,
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              placeholder: "Enter text",
              label: "Placeholder color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${formFieldStatePath.value}.decoration`,
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon right"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${formFieldStatePath.value}.iconRight.color`,
            valueSource: fields.value[currentFieldName.value].field,
            options: {
              placeholder: "Enter text",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
      Error
    */

  const formErrorStyles = computed(() => {
    return [
      {
        section: "Label",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.states.error.color",
            valueSource: fields.value.labels_styling.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      typography({ source: fields.value.errors_styling.field }),
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.errors_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
    ];
  });

  const successFieldControls = computed(() => {
    return [
      {
        section: "Label",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.states.success.color",
            valueSource: fields.value.labels_styling.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.field_success_styling.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
    ];
  });

  return {
    formLabelsStyles,
    formLabelsStylesWithIconSettings,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldContentStylesWithIconGap,
    formFieldTypograpyControls,
    formFieldStates,
    formErrorStyles,
    successFieldControls,
  };
};
