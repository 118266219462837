<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @blur-tab="$emit('reset-states')"
        @change="$emit('reset-states')"
        @close="$emit('reset-states')"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #page_title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #providers_list-design>
          <ControlsList
            :widget="widget"
            :controls="providerListDesignControls"
          />
        </template>

        <template #providers_list-content>
          <ControlsList
            :widget="widget"
            :controls="providerListContentControls"
          />
        </template>

        <template #provider_card-design>
          <ControlsList
            :widget="widget"
            :controls="providerCardDesignControls"
          />
        </template>

        <template #provider_card-content>
          <ControlsList
            :widget="widget"
            :controls="providerCardContentControls"
          />
        </template>

        <template #provider_card-states>
          <ControlsList
            :widget="widget"
            :controls="providerCardStatesControls"
          />
        </template>

        <template #provider_image>
          <ControlsList
            :widget="widget"
            :controls="providerImageControls"
          />
        </template>

        <template #provider_name>
          <ControlsList
            :widget="widget"
            :controls="providerTextControls"
          />
        </template>

        <template #provider_description>
          <ControlsList
            :widget="widget"
            :controls="providerTextControls"
          />
        </template>

        <template #provider_button-design>
          <ControlsList
            :widget="widget"
            :controls="providerButtonDesignControls"
          />
        </template>

        <template #provider_button-content>
          <ControlsList
            :widget="widget"
            :controls="providerButtonContentControls"
          />
        </template>

        <template #provider_button-states>
          <ControlsList
            :widget="widget"
            :controls="providerButtonStatesControls"
          />
        </template>

        <template #tooltip-design>
          <ControlsList
            :widget="widget"
            :controls="tooltipDesignControls"
          />
        </template>

        <template #tooltip-content>
          <ControlsList
            :widget="widget"
            :controls="tooltipContentControls"
          />
        </template>

        <template #information_message-design>
          <ControlsList
            :widget="widget"
            :controls="informationDesignControls"
          />
        </template>

        <template #information_message-content>
          <ControlsList
            :widget="widget"
            :controls="informationContentControls"
          />
        </template>

        <template #information_message-states>
          <ControlsList
            :widget="widget"
            :controls="informationStatesControls"
          />
        </template>

        <template #information_title>
          <ControlsList
            :widget="widget"
            :controls="informationTitleControls"
          />
        </template>

        <template #information_description>
          <ControlsList
            :widget="widget"
            :controls="informationDescriptionControls"
          />
        </template>

        providerButtonContentControls
        <template #information_button-design>
          <ControlsList
            :widget="widget"
            :controls="providerButtonDesignControls"
          />
        </template>

        <template #information_button-content>
          <ControlsList
            :widget="widget"
            :controls="informationButtonContentControls"
          />
        </template>

        <template #information_button-states>
          <ControlsList
            :widget="widget"
            :controls="providerButtonStatesControls"
          />
        </template>

        <template #no_providers_title-design>
          <ControlsList
            :widget="widget"
            :controls="noProvidersDesignControls"
          />
        </template>

        <template #no_providers_title-content>
          <ControlsList
            :widget="widget"
            :controls="noProvidersContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useConfig } from "~~/composables/widgets/payment-providers-list-fenix/usePaymentProvidersListFenixConfig";
import { State } from "~~/models/widgets/widget-controls.model";
import {
  useWidgetDynamicParams,
  IParams,
} from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

defineEmits<{
  (e: "reset-states"): void;
}>();

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  titleControls,
  widgetDesignControls,
  widgetContentControls,

  providerListDesignControls,
  providerListContentControls,

  providerCardDesignControls,
  providerCardContentControls,
  providerCardStatesControls,

  providerImageControls,
  providerTextControls,
  providerButtonDesignControls,
  providerButtonContentControls,
  informationButtonContentControls,
  providerButtonStatesControls,

  informationDesignControls,
  informationContentControls,
  informationStatesControls,
  informationTitleControls,
  informationDescriptionControls,

  tooltipDesignControls,
  tooltipContentControls,

  noProvidersDesignControls,
  noProvidersContentControls,
} = useConfig(ref(props.widget), states);

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const bindingParamsArg = computed<IParams>(() => {
  return {
    "pageType": {
      label: "Page type",
      description: "Possible values: Deposit | Withdrawal",
      sources: ["custom"],
      required: true,
    },
    "redirectURL": {
      label: "Redirect URL",
      sources: ["custom"],
      required: true,
    },
  } as IParams;
});

const { bindingParams } = useWidgetDynamicParams(bindingParamsArg.value, {
  values: props.widget.options.bindingParams || {},
});
</script>
