<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container config-theme"
  >
    <p class="m-b-4">Heading type</p>
    <div class="config-theme__select-container">
      <CommonSelect
        :model-value="currentType"
        :options="themeOptions"
        placeholder="Select theme"
        class="config-theme__select"
        @update:model-value="handleChangeType"
      />
      <CommonIcon
        name="ant-design:highlight-outlined"
        class="config-theme__icon"
      />
    </div>

    <p class="m-t-16 m-b-4">Font size</p>

    <ConfigInput
      v-number
      :is-number="true"
      :model-value="currentSize"
      :stretch-input="true"
      :input-props="{
        min: 0,
        max: MAX_NUMBER_INPUT_VALUE,
        controls: false,
      }"
      class="wconfig-radius__option"
      @update:model-value="handleChangeValue"
    >
      <template #suffix>px</template>
    </ConfigInput>
  </div>
</template>

<script setup lang="ts">
import type { SelectProps } from "ant-design-vue";

import { usePermissions } from "~~/composables/permissions/usePermissions";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";
import { THEME_OPTIONS } from "~~/constants/widget-config";
import {
  HeadingType,
  IHeadingControl,
  SimpleControlValue,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: IHeadingControl & SimpleControlValue;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: IHeadingControl): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentType = computed<HeadingType | string>(() => {
  return props.modelValue?.type ?? THEME_OPTIONS[0].value;
});

const currentSize = computed<SimpleControlValue>(() => {
  return props.modelValue?.value ?? props.modelValue;
});

const themeOptions = ref<SelectProps["options"]>(THEME_OPTIONS);

const handleChangeValue = (value: SimpleControlValue) => {
  emit("update:modelValue", {
    type: currentType.value,
    value: value,
  });
};

const handleChangeType = (value: HeadingType) => {
  emit("update:modelValue", {
    type: value,
    value: currentSize.value,
  });
};
</script>

<style lang="scss">
.config-theme {
  &__select-container {
    @include flex(center, space-between, 0);
    width: 100%;
  }

  &__select {
    width: 90%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }

  &__icon {
    color: $c-grey-45;
  }
}
</style>
