<template>
  <notifications
    position="top center"
    width="400px"
    class="common-notification"
  >
    <template #body="props">
      <div class="common-notification__content">
        <div class="common-notification__icon m-r-8">
          <CommonIcon
            v-if="props.item.type === 'success'"
            name="ant-design:check-circle-filled"
            class="common-notification__icon--success"
          />

          <CommonIcon
            v-else-if="props.item.type === 'error'"
            name="ant-design:exclamation-circle-filled"
            class="common-notification__icon--error"
          />

          <CommonIcon
            v-else
            name="ant-design:info-circle-filled"
            class="common-notification__icon--info"
          />
        </div>
        <p class="common-notification__text f-base">
          {{ props.item.text }}
        </p>
      </div>
    </template>
  </notifications>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.common-notification {
  top: $space-lg !important;

  .vue-notification-wrapper {
    overflow: initial;
  }

  &__content {
    @include flex(center, flex-start, 0);
    border-radius: 6px;
    padding: $space-s $space-m;
    background-color: $c-white;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  &__text {
    margin: 0;
  }

  &__icon {
    &--success {
      svg {
        color: $c-success;
      }
    }

    &--info {
      svg {
        color: $c-primary-base;
      }
    }

    &--error {
      svg {
        color: $c-icon-font-red;
      }
    }
  }
}
</style>
