import { IPageContentWidget, InnerFieldValue } from "~~/models/page.model";
import { pipeSync } from "~~/helpers/pipe";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";

import { generateFieldContainerCssString } from "./search-field-widget-css";

export const generateCasinoGamesSearchFieldWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const form = widget.content["form"];

  const field_container = (form.value as InnerFieldValue).fields
    .field_container;

  widget.options._cssClass = generateClassName("CasinoGamesSearchField");

  field_container.options._cssClass = generateClassName("field_container");
  field_container.options.text._cssClass = generateClassName(
    "field_container--text"
  );
  field_container.options.icon._cssClass = generateClassName(
    "field_container--icon"
  );

  return pipeSync<string>(
    generateStringDefault(widget),
    generateFieldContainerCssString(field_container as IWidgetField)
  )("");
};
