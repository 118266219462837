import { pipeSync } from "~~/helpers/pipe";
import { IContentField, IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateMarginStyle,
  generateTextColor,
} from "../helpers";

import {
  generateEventCardsCssString,
  generateEventInfoCssString,
  generateListTimeCssString,
  generatePlayerNameCssString,
} from "./sportsbook-live-widget-css";
import {
  generateFavouritesButtonCssString,
  generateIconCssString,
} from "./racing-sportsbook-live-widget-css";

export const generateTitleCssString =
  (title: IContentField) =>
  (cssString: string): string => {
    const { options } = title;
    const iconFontCssContent =
      generateTextColor(options.icon.textColor) +
      generateFontSize(options.icon.iconSize) +
      generateMarginStyle({ right: options.icon.gap });

    cssString += generateDefaultStylesWithStates(title, false);

    const titleContent = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: titleContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options.text,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.arrow._cssClass,
      content: `${generateTextColor(
        options.arrow.textColor
      )}margin-left: auto;`,
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    for (const state in title.options.states) {
      const currentState = options.states[state];

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.arrow?._cssClass,
        content: currentState.arrow?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.icon?._cssClass,
        content: currentState.icon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: currentState.text,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

export const generateNoEventsSpacingCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateFavoritesEventsLiveWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const heading = widget.content["heading"];

  const event_cards = widget.content["event_card"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const event_time = widget.content["event_time"];
  const event_league = widget.content["event_league"];
  const event_info = widget.content["event_info"];
  const event_team_player = widget.content["event_team_player"];
  const event_score = widget.content["event_score"];
  const no_events_title = widget.content["no_event_title"];
  const stream_icon = widget.content["stream_icon"];
  const favourites_button = widget.content["favourites_button"];

  widget.options._cssClass = generateClassName("FavoritesEventsLiveWidget");

  heading.options._cssClass = generateClassName("heading");
  heading.options.arrow._cssClass = generateClassName("titleArrow");
  heading.options.icon._cssClass = generateClassName("titleIcon");

  event_cards.options._cssClass = generateClassName("event_cards");
  event_cards.options._itemCssClass = generateClassName("event_cards");
  event_suspended_status.options._cssClass = generateClassName(
    "event_suspended_status"
  );
  no_events_title.options._cssClass = generateClassName("no_events_title");

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_league.options._cssClass = generateClassName("event_league");
  event_info.options._cssClass = generateClassName("event_info");
  event_info.options._itemCssClass = generateClassName("event_info_item");
  event_team_player.options._cssClass = generateClassName("event_team_player");
  event_score.options._cssClass = generateClassName("event_score");
  favourites_button.options._cssClass = generateClassName("favourites_button");
  stream_icon.options._cssClass = generateClassName("stream_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateTitleCssString(heading),
    generateEventCardsCssString(event_cards as IWidgetField),
    generateStringDefault(event_suspended_status, ["size"]),
    generateListTimeCssString(event_time as IWidgetField),
    generateStringDefault(event_league),
    generateStringDefault(event_info),
    generatePlayerNameCssString(
      event_team_player as IWidgetField,
      event_info as IWidgetField
    ),
    generateStringDefault(event_score, ["size"]),
    generateEventInfoCssString(event_info as IWidgetField),
    generateStringDefault(no_events_title),
    generateFavouritesButtonCssString(favourites_button),
    generateIconCssString(stream_icon),
    generateNoEventsSpacingCssString({
      _cssClass: no_events_title.options._cssClass,
      padding: event_cards.options.spacing.padding,
    })
  )("");
};
