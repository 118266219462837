import {
  Alignment,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { BUTTON_INITIAL } from "~~/constants/configs/profile-dropdown/initial-data";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([], {
    display: {
      alignment: Alignment.LEFT,
      distance: 16,
    },
    fill: getDefaultFillValue("#ffffff"),
    spacing: {
      padding: getDefaultSpacing(16),
    },
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions(
      "title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "padding", "margins", "alignment"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      },
      { value: "Title" }
    );

    addFieldInitialOptions("description", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "margins", "alignment"],
      values: {
        theme: getDefaultTheme(16),
        decoration: getDefaultDecoration(),
      },
    });

    addFieldInitialOptions("booking_code", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding", "margins", "alignment"],
      values: {
        theme: getDefaultTheme(),
        color: getDefaultFillValue("#000000", 45),
      },
      additionalData: {
        options: {
          icon: {
            size: 16,
            color: getDefaultFillValue("#000000", 45),
            distance: 8,
            value: "ant-design:copy-outlined",
          },
        },
      },
    });

    addFieldInitialOptions(
      "button",
      {
        data: BUTTON_INITIAL(),
      },
      { value: "Button" }
    );

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
