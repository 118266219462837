import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { extendedSizeElement } from "~~/constants/configs/common/extended-size-config";
import {
  ContainerMode,
  SliderArrowIcon,
  SliderTransition,
  State,
} from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Arrow settings", "arrows"),
    generateDropdownItem("Pagination settings", "pagination"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    arrows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pagination: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const getWidgetHeight = inject<() => number | null>("getWidgetHeight");
  const getWidgetWidth = inject<() => number | null>("getWidgetWidth");

  const heightSizeControls = computed<ControlProp[]>(() => {
    return extendedSizeElement({
      widget,
      label: "Height",
      key: "height",
      getInitialFixedValue: getWidgetHeight,
      className: "p-t-8",
    });
  });

  const widthSizeControls = computed<ControlProp[]>(() => {
    return extendedSizeElement({
      widget,
      label: "Width",
      key: "width",
      getInitialFixedValue: getWidgetWidth,
    });
  });

  const carouselControls = computed<ControlProp[]>(() => {
    if (widget.value.options.display.mode === ContainerMode.FLEX) {
      return [];
    }

    return [
      {
        section: generateContentSectionName("Transition"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.slider.transition",
            valueSource: widget.value,
            options: {
              items: [
                { value: SliderTransition.ALL, label: "All" },
                { value: SliderTransition.BY_ONE, label: "By one" },
              ],
            },
          },
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.slider.infinite",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Infinite loop",
              size: "small",
              allowDisable: true,
              showOptions: false,
            },
          },
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.slider.auto",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Auto slide",
              size: "small",
              allowDisable: true,
              showOptions: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.slider.delay",
            valueSource: widget.value,
            className: "group-control-element",
            visible: widget.value.options.slider.auto,
            options: {
              label: "Delay",
              suffix: "sec",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "pagination",
              label: "Pagination",
              isActiveValueSource: fields.value.pagination.field.options,
            },
            {
              value: "arrows",
              label: "Arrows",
              isActiveValueSource: fields.value.arrows.field.options,
            },
          ],
        },
      },
    ];
  });

  const layoutItems = computed<{ icon: string; value: string }[]>(() => {
    if (widget.value.options.display.mode === ContainerMode.FLEX) {
      return [
        {
          icon: "ant-design:arrow-down-outlined",
          value: "vertical",
        },
        {
          icon: "ant-design:arrow-right-outlined",
          value: "horizontal",
        },
        {
          icon: "ant-design:enter-outlined",
          value: "wrap",
        },
      ];
    }

    return [
      {
        icon: "ant-design:arrow-down-outlined",
        value: "vertical",
      },
      {
        icon: "ant-design:arrow-right-outlined",
        value: "horizontal",
      },
    ];
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [...widthSizeControls.value, ...heightSizeControls.value],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.mode",
            valueSource: widget.value,
            className: "group-control-element container-widget__layout-config",
            options: {
              items: [
                {
                  value: ContainerMode.FLEX,
                  label: "Flex",
                },
                {
                  value: ContainerMode.CAROUSEL,
                  label: "Carousel",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: widget.value,
            visible: widget.value.options.display.mode === ContainerMode.FLEX,
            className: "group-control-element container-widget__layout-config",
            options: {
              items: layoutItems.value,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.horizontalGap",
            valueSource: widget.value,
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
              layout: widget.value.options.display.layout,
            },
          },
        ],
      },
      ...carouselControls.value,
      {
        section: generateContentSectionName("Overflow"),
        visible: widget.value.options.display.mode === "flex",
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.horizontalOverflow",
            valueSource: widget.value,
            options: {
              label: "Horizontal overflow",
              items: [
                {
                  label: "Hidden",
                  value: "hidden",
                },
                {
                  label: "Scroll",
                  value: "auto",
                },
                {
                  label: "Visible",
                  value: "visible",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.verticalOverflow",
            valueSource: widget.value,
            options: {
              label: "Vertical overflow",
              items: [
                {
                  label: "Hidden",
                  value: "hidden",
                },
                {
                  label: "Scroll",
                  value: "auto",
                },
                {
                  label: "Visible",
                  value: "visible",
                },
              ],
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Scrolling"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.scrolling",
            valueSource: widget.value,
            options: {
              label: "Position when scrolling",
              items: [
                {
                  label: "Not fix",
                  value: "",
                },
                {
                  label: "Fix top",
                  value: "fix_top",
                },
                {
                  label: "Fix bottom",
                  value: "fix_bottom",
                },
              ],
            },
          },
        ],
      },
    ];
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const arrowsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.arrows.field,
    });
  });

  const arrowsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.content.icon.size",
        valueSource: fields.value.arrows.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      ...getInitialDesignList({
        source: fields.value.arrows.field,
        basePath: "options.content",
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.content.alignment",
        valueSource: fields.value.arrows.field,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.content.icon.value",
        valueSource: fields.value.arrows.field,
        className: "group-control-element container-widget__arrows-settings",
        options: {
          label: "Icon",
          items: [
            { icon: SliderArrowIcon.OUTLINED, value: SliderArrowIcon.OUTLINED },
            { icon: SliderArrowIcon.ARROW, value: SliderArrowIcon.ARROW },
            { icon: SliderArrowIcon.CARET, value: SliderArrowIcon.CARET },
          ],
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.content.icon.color",
        valueSource: fields.value.arrows.field,
        options: {
          placeholder: "None",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
    ];
  });

  const stateValue = computed<string | null>(() => {
    return states.value.arrows;
  });

  const currentStatePath = computed<string>(() => {
    if (states.value.arrows === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.arrows}`;
  });

  const arrowsStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateValue.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "arrows", value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: fields.value.arrows.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: fields.value.arrows.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: fields.value.arrows.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.icon.color`,
            valueSource: fields.value.arrows.field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const paginationDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.pagination.field,
    });
  });

  const paginationContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.isStroke",
        valueSource: fields.value.pagination.field,
        className: "group-control-element",
        options: {
          shrink: true,
          customIconWidth: "calc(100% - 24px)",
          items: [
            {
              label: "Bullets",
              value: false,
              icon: "custom:bullets",
            },
            {
              label: "Strokes",
              value: true,
              icon: "custom:strokes",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        valueSource: fields.value.pagination.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 8,
          maxValue: 48,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: fields.value.pagination.field,
        className: "group-control-element",
        options: {
          placeholder: "None",
          label: "Color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: fields.value.pagination.field,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
    ];
  });

  return {
    TABS,
    DEFAULT_DROPDOWN_ITEMS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    arrowsDesignControls,
    arrowsContentControls,
    arrowsStateControls,

    paginationDesignControls,
    paginationContentControls,
  };
};
