import { Editor as EditorType } from "tinymce";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";

import { LinkType } from "~~/models/widgets/widget-controls.model";
import { isValidLink } from "~~/helpers/link-validation";
import { useLanguagesStore } from "~~/store/languages";

const linkOptionKeyDict: Record<string, string> = {
  href: "href",
  hasAuthorize: "data-has-authorize",
  authorizeHref: "data-authorize-href",
  dataType: "data-type",
  target: "target",
};

const isLinkModalVisible = ref<boolean>(false);
const linkType = ref<LinkType>(LinkType.EXTERNAL);
const isOneLinkForAll = ref<boolean>(true);
const isNewTabOpen = ref<boolean>(true);
const linkValue = ref<string>("");
const authLinkValue = ref<string>("");

const isLinkError = ref<boolean>(false);
const isAuthorizeLinkError = ref<boolean>(false);

export const useDescriptionLink = () => {
  const languagesStore = useLanguagesStore();
  const { currentLanguageCode, isDefaultLanguage } =
    storeToRefs(languagesStore);

  const editorInitOptions = {
    custom_elements: "style",
    extended_valid_elements: "b,strong",
    min_height: 400,
    inline: true,
    license_key: "gpl",
    plugins:
      "image table code preview emoticons lists advlist codesample autolink searchreplace accordion autoresize anchor visualblocks",
    toolbar: [
      "undo redo |styles removeformat | fontsizeinput | forecolor backcolor | searchreplace visualblocks",
      "hr codesample table link-dialog-button numlist bullist accordion anchor",
    ],

    fullscreen_native: false,
    // Cleanup/Output
    fix_list_elements: true,
    forced_root_block: "div",
    /*
      All formats added mannualy, since we
      added custom format "strong"
    */
    style_formats_merge: false,
    verify_html: true,
    relative_urls: false,
    convert_urls: false,
    remove_script_host: false,

    font_size_formats:
      "8px 10px 12px 14px 16px 18px 20px 22px 24px 28px 36px 42px 48px",
    font_size_input_default_unit: "px",

    menu: {
      file: { title: "File", items: "preview" },
      edit: {
        title: "Edit",
        items:
          "undo redo | cut copy paste pastetext | selectall | searchreplace",
      },
      view: {
        title: "View",
        items: "code | visualaid visualchars visualblocks | spellchecker",
      },
      insert: {
        title: "Insert",
        items:
          "image link codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
      },
      format: {
        title: "Format",
        items:
          "bold strong italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | removeformat",
      },
      tools: { title: "Tools", items: "spellchecker | code wordcount" },
      table: {
        title: "Table",
        items:
          "inserttable | cell row column | advtablesort | tableprops deletetable",
      },
    },
    tinycomments_author: "Embedded Journalist",
    tinycomments_mode: "embedded",

    content_style:
      ".tox-comments-visible span.tox-comment { background-color: magenta; }.tox-comments-visible span.tox-comment--active { background-color: cyan; }",
    style_formats: [
      {
        title: "Headings",
        items: [
          { title: "Heading 1", format: "h1" },
          { title: "Heading 2", format: "h2" },
          { title: "Heading 3", format: "h3" },
          { title: "Heading 4", format: "h4" },
          { title: "Heading 5", format: "h5" },
          { title: "Heading 6", format: "h6" },
        ],
      },
      {
        title: "Inline",
        items: [
          { title: "Bold", format: "bold" },
          { title: "Strong", format: "strong" },
          { title: "Italic", format: "italic" },
          { title: "Underline", format: "underline" },
          { title: "Strikethrough", format: "strikethrough" },
          { title: "Superscript", format: "superscript" },
          { title: "Subscript", format: "subscript" },
          { title: "Code", format: "code" },
        ],
      },
      {
        title: "Blocks",
        items: [
          { title: "Paragraph", format: "p" },
          { title: "Blockquote", format: "blockquote" },
          { title: "Div", format: "div" },
          { title: "Pre", format: "pre" },
        ],
      },
      {
        title: "Align",
        items: [
          { title: "Left", format: "alignleft" },
          { title: "Center", format: "aligncenter" },
          { title: "Right", format: "alignright" },
          { title: "Justify", format: "alignjustify" },
        ],
      },
    ],
    formats: {
      strong: {
        inline: "strong",
      },
      bold: { inline: "b" },
    },
    setup: (editor: EditorType) => {
      editor.ui.registry.addMenuItem("strong", {
        text: "Strong",
        icon: "bold",
        onAction: function () {
          editor.formatter.toggle("strong");
          editor.focus();
        },
      });

      editor.ui.registry.addButton("link-dialog-button", {
        icon: "link",
        tooltip: "Insert/edit link",
        onAction: () => {
          handleLinkSettingsButtonClick(editor);
        },
      });
    },
  };

  const isAnchorElement = (node: any) => {
    return node.nodeName.toLowerCase() === "a" && node.href;
  };

  const parsedLangCode = computed<string>(
    () => `/${currentLanguageCode.value.toLocaleLowerCase()}`
  );

  const replacer = (
    value: string,
    valueToReplace: string,
    replaceTo = ""
  ): string => {
    return value.replace(valueToReplace, replaceTo);
  };

  // On button click - prefilling link options if existing
  const handleLinkSettingsButtonClick = (editor: EditorType) => {
    const selectedNode = editor.selection.getNode();

    if (isAnchorElement(selectedNode)) {
      const type = selectedNode.getAttribute("data-type") as LinkType;
      linkValue.value = String(selectedNode.getAttribute("href"));

      if (type === LinkType.EXTERNAL || type === LinkType.MODAL) {
        if (selectedNode.getAttribute("data-has-authorize") === "true") {
          isOneLinkForAll.value = false;
          authLinkValue.value =
            selectedNode.getAttribute("data-authorize-href") || "";
        }

        if (!isDefaultLanguage.value) {
          const currentLangParsed = parsedLangCode.value;
          linkValue.value = replacer(linkValue.value, currentLangParsed);

          if (!isOneLinkForAll.value) {
            authLinkValue.value = replacer(
              authLinkValue.value,
              currentLangParsed
            );
          }
        }
      }

      if (type === LinkType.EXTERNAL) {
        isNewTabOpen.value = selectedNode.getAttribute("target") === "_blank";
      }

      if (type === LinkType.EMAIL) {
        linkValue.value = replacer(linkValue.value, "/");
        if (linkValue.value.startsWith("mailto:")) {
          linkValue.value = replacer(linkValue.value, "mailto:");
        }
      }

      if (type === LinkType.PHONE) {
        if (linkValue.value.startsWith("tel:")) {
          linkValue.value = replacer(linkValue.value, "tel:");
        }
      }

      if (type) {
        linkType.value = type;
      }
    }

    isLinkModalVisible.value = true;
  };

  const nullifyLinkOptionsValues = () => {
    linkValue.value = "";
    authLinkValue.value = "";
    linkType.value = LinkType.EXTERNAL;
    isOneLinkForAll.value = true;
    isNewTabOpen.value = true;
    isAuthorizeLinkError.value = false;
    isLinkError.value = false;
  };

  const hideLinkModal = () => {
    nullifyLinkOptionsValues();

    isLinkModalVisible.value = false;
  };

  const canSubmitModal = computed<boolean>(() => {
    // Type is null
    if (!linkType.value) {
      return true;
    }
    // Empty link value
    if (!linkValue.value) {
      return false;
    }
    // Links errors exist
    if (isLinkError.value || isAuthorizeLinkError.value) {
      return false;
    }
    // Not valid link
    if (!isValidLink(linkValue.value, linkType.value)) {
      isLinkError.value = true;
      return false;
    }
    // Not valid auth link
    if (
      authLinkValue.value &&
      !isValidLink(authLinkValue.value, linkType.value)
    ) {
      isAuthorizeLinkError.value = true;
      return false;
    }

    return true;
  });

  const getHrefAttr = computed<string>(() => {
    switch (linkType.value) {
      case LinkType.EXTERNAL:
      case LinkType.MODAL:
        return "";
      case LinkType.PHONE:
        return "tel:";
      case LinkType.EMAIL:
        return "mailto:";
      default:
        return "";
    }
  });

  const getCurrentLocales = computed(() => {
    let generalValue = "";
    let authedValue = "";
    // If internal link for NOT default locale starts without locale
    if (
      (linkType.value === LinkType.EXTERNAL ||
        linkType.value === LinkType.MODAL) &&
      linkValue.value &&
      !linkValue.value.startsWith("http") &&
      !isDefaultLanguage.value &&
      !linkValue.value.startsWith(parsedLangCode.value)
    ) {
      generalValue = parsedLangCode.value;
    }
    // If internal authed link for NOT default locale starts without locale
    if (
      (linkType.value === LinkType.EXTERNAL ||
        linkType.value === LinkType.MODAL) &&
      authLinkValue.value &&
      !authLinkValue.value.startsWith("http") &&
      !isDefaultLanguage.value &&
      !authLinkValue.value.startsWith(parsedLangCode.value)
    ) {
      authedValue = parsedLangCode.value;
    }

    return { general: generalValue, authed: authedValue };
  });

  const submitLinkModal = () => {
    if (canSubmitModal.value) {
      const linkOptions = {
        href: `${getCurrentLocales.value.general}${getHrefAttr.value}${linkValue.value}`,
        hasAuthorize: String(!isOneLinkForAll.value),
        authorizeHref:
          `${getCurrentLocales.value.authed}${getHrefAttr.value}${authLinkValue.value}` ||
          null,
        dataType: linkType.value,
        target: isNewTabOpen.value ? "_blank" : null,
      };

      setAttr(linkOptions);

      nullifyLinkOptionsValues();

      isLinkModalVisible.value = false;
    }
  };

  const setAttr = (linkOptions: Record<string, string | null>) => {
    const tinymce = getTinymce();

    const { href, hasAuthorize, authorizeHref, dataType, target } = linkOptions;

    if (!dataType) {
      tinymce.activeEditor.execCommand("Unlink");
      return;
    }

    const currentNode = tinymce.activeEditor.selection.getNode();
    const selectedContent = tinymce.activeEditor.selection.getContent();

    // Configuring the link with arguments, if selected text not the link yet
    if (!isAnchorElement(currentNode)) {
      const anchor = document.createElement("a");
      anchor.href = href || "";
      anchor.setAttribute("data-has-authorize", hasAuthorize || "");
      anchor.setAttribute("data-type", dataType);
      anchor.setAttribute("data-authorize-href", authorizeHref || "");
      anchor.target = target || "_self";

      anchor.innerHTML = selectedContent;

      tinymce.activeEditor.selection.setContent(anchor.outerHTML);

      return;
    }

    Object.entries(linkOptions).forEach(([optionKey, optionValue]) => {
      const attribute = linkOptionKeyDict[optionKey];
      if (attribute) {
        tinymce.activeEditor.dom.setAttrib(currentNode, attribute, optionValue);
      }
    });
  };

  return {
    editorInitOptions,
    hideLinkModal,
    submitLinkModal,

    isLinkModalVisible,
    linkType,
    isOneLinkForAll,
    isNewTabOpen,
    linkValue,
    authLinkValue,
    isLinkError,
    isAuthorizeLinkError,
    canSubmitModal,
  };
};
