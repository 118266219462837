<template>
  <li
    class="dropdown-list-item-field background-image"
    :style="wrapperStyle"
  >
    <SpacingHighlight
      :is-target-selected="isItemsGroupSelected"
      :padding="wrapperOptions.padding"
      :margin="wrapperOptions.margins"
    />

    <BasicTitleField
      v-if="textIsActive"
      :value="value || field.value"
      :field="field"
      :options="textOptions"
    />

    <span
      v-if="field.options.icon && field.options.icon._active"
      :style="iconStyleValue"
    >
      <CommonIcon :name="field.options.icon.value" />
    </span>
  </li>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue";

import {
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getCornerRadiusStyle,
  getFontSizeStyle,
  getSpacing,
  getWidthStyles,
  getHeightStyles,
} from "~~/assets/utils/widget-settings";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import {
  DisplayOrientation,
  SpacingKeyName,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

const props = defineProps<{
  field: IWidgetField;
  state: State;
  wrapperOptions: IWidgetOptions;
  itemsGroupOptions?: IWidgetOptions;
  isItemsGroupSelected: boolean;
  value?: string;
}>();

const activeStateOptions = computed<IWidgetOptions>(() => {
  return props.state === State.DEFAULT
    ? props.wrapperOptions
    : props.wrapperOptions.states[props.state];
});

const { fillBackgroundStyling } = useFillBackground(activeStateOptions);

const wrapperStyle = computed<CSSProperties>(() => {
  const widthStyles = activeStateOptions.value.size
    ? getWidthStyles(activeStateOptions.value.size.width)
    : {};
  const heightStyles = activeStateOptions.value.size
    ? getHeightStyles(activeStateOptions.value.size.height)
    : {};

  const fill = fillBackgroundStyling.value;
  const border = getBorderStyle(activeStateOptions.value.border);
  const shadow = getBoxShadowStyle(activeStateOptions.value.shadow);
  const cornerRadius = getCornerRadiusStyle(props.wrapperOptions.cornerRadius);
  const margin = getSpacing(
    props.wrapperOptions.margins,
    SpacingKeyName.MARGIN
  );
  const padding = getSpacing(
    props.wrapperOptions.padding,
    SpacingKeyName.PADDING
  );

  const isElementsVertical =
    props.itemsGroupOptions?.display?.elementsLayout ===
    DisplayOrientation.VERTICAL;
  const isReverse =
    iconSettings.value?.position === TextPosition.LEFT ? "-reverse" : "";

  return {
    width: widthStyles.width,
    height: heightStyles.height,
    ...fill,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...margin,
    ...padding,
    justifyContent: props.wrapperOptions?.display?.alignment as string,
    flexDirection: isElementsVertical
      ? `column${isReverse}`
      : `row${isReverse}`,

    gap: `${iconSettings.value?.gap ?? 0}px`,
  };
});

const iconSettings = computed(() => props.wrapperOptions.itemsIcon);

const iconStyleValue = computed<{ [key: string]: string }>(() => {
  return {
    color: getColorFromHex(
      activeStateOptions.value?.itemsIcon?.textColor
    ) as string,
    ...getFontSizeStyle(iconSettings.value?.iconSize),
  };
});

const textOptions = computed<IWidgetOptions>(() => ({
  ...props.wrapperOptions.itemsText,
  ...activeStateOptions.value.itemsText,
}));

const textIsActive = computed<boolean>(() => {
  return (
    props.field.options.text?._active ||
    props.field.options.itemsText?._active ||
    props.field.options.buttonTextSettings?._active
  );
});
</script>

<style lang="scss">
.dropdown-list-item-field {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
