<template>
  <WidgetField
    :widget="widget"
    :field="fields.event_card.field"
    :style="cardStyles"
    class="bets-list-fenix-mobile-event-card"
  >
    <WidgetField
      :widget="widget"
      :field="headerField"
      :style="headerStyles"
    >
      <div :style="headerLeftStyles">
        <WidgetField
          v-if="leagueNameField.options._active"
          :widget="widget"
          :field="leagueNameField"
          :style="leagueNameStyles"
          class="overflow-ellipsis"
        >
          <BasicTitleField
            :field="leagueNameField"
            value="League Name"
            :state="states.event_league"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="startTimeField"
          :style="timeContainerInlineStyles"
          class="bets-list-fenix-mobile-event-card__time-container"
        >
          <span class="bets-list-fenix-mobile-event-card__time">
            <span
              v-if="startTimeField.options.displayMode === 'date'"
              class="bets-list-fenix-mobile-event-card__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="startTimeIconStyles"
                :name="
                  startTimeField.options.icon.valueTime ??
                  'ant-design:clock-circle-filled'
                "
                class="bets-list-fenix-mobile-event-card__icon"
              />

              <span :style="timeLabelInlineStyles"> 00:00 </span>
            </span>
            <span
              v-if="startTimeField.options.displayMode === 'date'"
              class="bets-list-fenix-mobile-event-card__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="startTimeIconStyles"
                :name="
                  startTimeField.options.icon.valueDate ??
                  'ant-design:calendar-filled'
                "
                class="bets-list-fenix-mobile-event-card__icon"
              />

              <span :style="timeLabelInlineStyles"> 00.00.00 </span>
            </span>
            <span
              v-if="startTimeField.options.displayMode === 'time'"
              class="bets-list-fenix-mobile-event-card__time-element"
            >
              <CommonIcon
                v-if="startTimeField.options.icon._active"
                :style="startTimeIconStyles"
                :name="
                  startTimeField.options.icon.valueTime ??
                  'ant-design:clock-circle-filled'
                "
                class="bets-list-fenix-mobile-event-card__icon"
              />

              <span :style="timeLabelInlineStyles"> 0 h 00 m </span>
            </span>
          </span>
        </WidgetField>
      </div>

      <WidgetField
        v-if="favoriteIconField.options._active"
        :widget="widget"
        :field="favoriteIconField"
        :style="favoriteIconStyles"
      >
        <CommonIcon
          :size="favoriteIconField.options.iconSize"
          :name="favoriteIconField.options.value"
        />
      </WidgetField>
    </WidgetField>

    <div :style="footerStyles">
      <WidgetField
        :field="eventNameField"
        :widget="widget"
        :style="eventNameStyles"
      >
        <BasicTitleField
          :field="eventNameField"
          :state="states.sports"
        >
          Event name
        </BasicTitleField>
      </WidgetField>

      <BetsListFenixMobileBetItems
        :widget="widget"
        :states="states"
        :is-layout-first-mode="isLayoutFirstMode"
      />

      <BetsListFenixMobileEventButton
        :widget="widget"
        :field="fields.event_button.field"
        :state="states.event_button"
        :is-layout-first-mode="isLayoutFirstMode"
      />
    </div>
  </WidgetField>
</template>

<script setup lang="ts">
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getDecorationValue,
  getFontSizeStyle,
  getWidthStyles,
} from "~~/assets/utils/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { CellSizeType } from "~~/models/grid.interface";

import BetsListFenixMobileBetItems from "./BetsListFenixMobileBetItems.vue";
import BetsListFenixMobileEventButton from "./BetsListFenixMobileEventButton.vue";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const fields = useWidgetFields(props.widget);

const cardStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(
      fields.value.event_card.field.options,
      props.states.event_card
    ),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    gap: `${fields.value.event_card.field.options.spaceBetween}px`,
  };
});

const headerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(headerField.value.options, props.states.header),
    "display": "flex",
    "alignItems": "center",
    "gap": `${headerField.value.options.verticalSpace}px`,
  };
});

const headerField = computed(() => fields.value.header.field);

const leagueNameField = computed(() => fields.value.event_league.field);

const leagueNameStyles = computed(() => {
  return {
    "minWidth": "3ch",
    "width":
      leagueNameField.value.options.displayMode === "fill"
        ? "100%"
        : "fit-content",
  };
});

const headerLeftStyles = computed<ElementStyle>(() => {
  return {
    "display": "flex",
    "flexGrow": 1,
    "maxWidth": "100%",
    "overflow": "hidden",
    "gap": `${headerField.value.options.verticalSpace}px`,
    "flex-direction": isHeaderLayoutVertical.value
      ? leagueNamePosition.value === "top"
        ? "column"
        : "column-reverse"
      : leagueNamePosition.value === "left"
      ? "row"
      : "row-reverse",
  };
});

const isHeaderLayoutVertical = computed(
  () => headerField.value.options.layout === DisplayOrientation.VERTICAL
);

const leagueNamePosition = computed(
  () =>
    headerField.value.options?.[
      `leagueName${
        isHeaderLayoutVertical.value ? "Vertical" : "Horizontal"
      }Position`
    ]
);
const favoriteIconStyles = computed(() => {
  return {
    ...getColorStyle(favoriteIconField.value.options.color),
    "alignSelf": headerField.value.options.favoriteIconAlignment,
    "justifySelf": "flex-end",
    "flexShrink": 0,
  };
});

const favoriteIconField = computed(() => fields.value.favourites_button.field);
const startTimeField = computed(() => fields.value.event_time.field);

const timeContainerInlineStyles = computed<ElementStyle>(() => {
  return {
    width: getWidthStyles(startTimeField.value.options.width).width,
    flexShrink:
      startTimeField.value.options.width.type === CellSizeType.HUG
        ? "initial"
        : "0",
    ...getCommonStyles(startTimeField.value.options),
  };
});

const timeLabelInlineStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(startTimeField.value.options.color),
    ...getFontSizeStyle(startTimeField.value.options.theme),
    ...getDecorationValue(startTimeField.value.options.decoration),
  };
});

const startTimeIconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(startTimeField.value.options.icon.color),
    ...getFontSizeStyle(startTimeField.value.options.icon.size),
  };
});

const eventNameStyles = computed(() => {
  const base = { minWidth: "3ch", whiteSpace: "nowrap", flexGrow: 1 };

  if (isLayoutFirstMode.value) {
    return {
      ...base,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    };
  }

  return base;
});

const eventNameField = computed(() => fields.value.event_name.field);

const footerStyles = computed<ElementStyle>(() => ({
  display: "flex",
  alignItems: isLayoutFirstMode.value ? "center" : "unset",
  flexDirection: isLayoutFirstMode.value ? "row" : "column",
  gap: `${fields.value.event_card.field.options.spaceBetween}px`,
}));

const isLayoutFirstMode = computed(
  () =>
    fields.value.event_card.field.options.layoutMode ===
    SportMenuLiveBet9CardLayoutMode.FIRST
);
</script>
<style lang="scss">
.bets-list-fenix-mobile-event-card {
  &__time-container {
    min-width: fit-content;
    display: flex;
    align-items: center;
  }

  &__time {
    @include flex(flex-start, flex-start, 8px);
    margin-right: 16px;
  }

  &__time-element {
    @include flex(center, flex-start, 4px);
  }
}
</style>
