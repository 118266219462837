import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 65;

const v65WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (widget.name === "SportEventFenixWidget") {
    if (
      widget.options.bindingParams.length &&
      widget.options.bindingParams.sportId?.sources?.indexOf("custom") === -1
    ) {
      widget.options.bindingParams.sportId.sources.push("custom");
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;
  return widget;
};
export default v65WidgetMigration;
