<template>
  <div class="config-dimension-input">
    <p
      v-if="label"
      class="m-b-0"
    >
      {{ label }}
    </p>

    <a-input
      :value="modelValue?.value"
      size="small"
      placeholder="Enter"
      @update:value="handleUpdateInput"
      @blur="handleBlur"
    >
      <template
        v-if="icon || prefix"
        #prefix
      >
        <span v-if="prefix"> {{ prefix }}</span>
        <CommonIcon
          v-if="icon"
          :name="icon"
          class="config-dimension-input__icon"
        />
      </template>

      <template #addonAfter>
        <a-select
          :value="modelValue?.type || DEFAULT_TYPE"
          class="config-dimension-input__select"
          popup-class-name="config-dimension-input__popup"
          @update:value="handleUpdateDropdown"
        >
          <a-select-option
            v-for="dimension in dimensions"
            :key="dimension.value"
            :value="dimension.value"
            >{{ dimension.label }}</a-select-option
          >
        </a-select>
      </template>
    </a-input>
  </div>
</template>

<script lang="ts" setup>
import { CSS_CALC_HEIGHT_REGEX } from "~~/constants/css-validations";
import { ICellDimensionValue, Dimension } from "~~/models/grid.interface";

const HEIGHT_DIMENSIONS = [
  {
    label: "px",
    value: Dimension.PX,
  },
  {
    label: "%",
    value: Dimension.PERCENT,
  },
  {
    label: "vh",
    value: Dimension.VH,
  },
  {
    label: "svh",
    value: Dimension.SVH,
  },
  {
    label: "calc",
    value: Dimension.CALC,
  },
  {
    label: "custom",
    value: Dimension.CUSTOM,
  },
];

const WIDTH_DIMENSIONS = [
  {
    label: "px",
    value: Dimension.PX,
  },
  {
    label: "%",
    value: Dimension.PERCENT,
  },
  {
    label: "vw",
    value: Dimension.VW,
  },
  {
    label: "svw",
    value: Dimension.SVW,
  },
  {
    label: "calc",
    value: Dimension.CALC,
  },
  {
    label: "custom",
    value: Dimension.CUSTOM,
  },
];

const DEFAULT_TYPE = Dimension.PX;

const props = defineProps<{
  modelValue?: ICellDimensionValue;
  label?: string;
  exclude?: Dimension[];
  icon?: string;
  prefix?: string;
  isWidth?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: ICellDimensionValue): void;
}>();

type DimesionItem = { label: string; value: Dimension };

const initialDimensions = computed<DimesionItem[]>(() => {
  if (props.isWidth) {
    return WIDTH_DIMENSIONS;
  }

  return HEIGHT_DIMENSIONS;
});

const dimensions = computed<DimesionItem[]>(() => {
  if (!props.exclude) {
    return initialDimensions.value;
  }

  return initialDimensions.value.filter(
    item => !props.exclude?.includes(item.value)
  );
});

const validate = (currValue?: ICellDimensionValue): boolean => {
  if (currValue?.type === Dimension.CALC) {
    const val = currValue?.value ? currValue?.value + "" : "";

    const isValid = val.match(CSS_CALC_HEIGHT_REGEX);

    return !!isValid;
  }

  if (currValue?.type === Dimension.CUSTOM) {
    return true;
  }

  const asNumber = Number(currValue?.value);
  const parsed = parseInt(currValue?.value || "");

  if (asNumber < 0) {
    return false;
  }

  return parsed === asNumber;
};

const handleUpdateInput = (value: string): void => {
  let type = props.modelValue?.type || DEFAULT_TYPE;

  if (CSS_CALC_HEIGHT_REGEX.test(value)) {
    type = Dimension.CALC;
  }

  emit("update:model-value", {
    value: value,
    type: type,
  });
};

const handleUpdateDropdown = (value: Dimension): void => {
  const isValid = validate({
    value: props.modelValue?.value || "",
    type: value!,
  });

  emit("update:model-value", {
    value: isValid ? props.modelValue?.value! : "",
    type: value,
  });
};

const getNumberValue = (value?: ICellDimensionValue): string => {
  if (value?.type === Dimension.CALC) {
    return "";
  }

  const parsed = parseInt(value?.value || "");

  if (parsed === 0) {
    return parsed + "";
  }

  return parsed ? parsed + "" : "";
};

const checkValue = (): void => {
  const isValid = validate(props.modelValue);

  if (isValid) {
    return;
  }

  emit("update:model-value", {
    type: props.modelValue?.type || DEFAULT_TYPE,
    value: getNumberValue(props.modelValue),
  });
};

const handleBlur = (): void => {
  checkValue();
};
</script>

<style lang="scss">
.config-dimension-input {
  .ant-input {
    border: 1px solid $c-grey-15 !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: calc($space-s + $space-xs) !important;
  }

  .ant-input-group-addon {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &__select {
    width: 70px;
    margin: -6px -11px !important;

    .ant-select-selector {
      box-shadow: none !important;
      border: none !important;
    }
  }

  &__icon {
    svg {
      font-size: 24px;
      color: $c-grey-45;
    }
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
    border: none;
    padding: 0 !important;
    box-shadow: none;
  }

  .ant-input-suffix {
    display: none !important;
  }
}
</style>
