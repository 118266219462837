import {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetsStore } from "~~/store/widgets";
import {
  ContainerMode,
  DisplayOrientation,
  Grid,
  ResizingType,
  SliderTransition,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { IInsertPosition } from "~~/models/grid.interface";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { reorderListElements } from "~~/assets/utils/widget-settings";

import { useFlexibleImageControls } from "../common/useFlexibleCardWidgetControls";
import { useButtonsGroupControls } from "../card/useButtonsGroupControls";
import { useButtonControls } from "../card/useButtonControls";
import { useCarouselConfig } from "../container-widget/useCarouselConfig";
import { useCreateFields } from "../common/useCreateFields";

import { useCardSlideControls } from "./useCardSlideControls";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { generateDummyField } = useCreateFields(widget.value);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Image", "image"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Description", "description_1"),
    generateDropdownItem("Button group", "buttons_group"),
    generateDropdownItem("Button", "button_1", "buttonsGroup"),
    generateDropdownItem("Button", "button_2", "buttonsGroup"),
    generateDropdownItem("Card", "card", "slider"),
    generateDropdownItem("Arrow settings", "arrows", "slider"),
    generateDropdownItem("Pagination settings", "pagination", "slider"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    buttons_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    arrows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pagination: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const updateFieldOption = (
    optionName: string,
    value: any,
    field: IWidgetField
  ): void => {
    widgetStore.updateFieldOptionValue(value, optionName, field.id);
  };

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    const slides = computed<ICustomField[]>(
      () => fields.value.items.field.value as ICustomField[]
    );
    const arrayField = computed<IWidgetField | null>(() => {
      return (
        widget.value.fields.find(field => field.name === FieldName.ITEMS) ||
        null
      );
    });
    const isSlider = computed(
      () => widget.value.options.display.mode === ContainerMode.CAROUSEL
    );

    const sliderControls = computed(() =>
      isSlider.value
        ? [
            {
              section: generateContentSectionName("Transition"),
              controls: [
                {
                  componentPath: "common/ConfigRadioTextElements",
                  valuePath: "options.display.slider.transition",
                  valueSource: widget.value,
                  options: {
                    items: [
                      { value: SliderTransition.ALL, label: "All" },
                      { value: SliderTransition.BY_ONE, label: "By one" },
                    ],
                  },
                },
                {
                  componentPath: "common/ConfigToggle",
                  valuePath: "options.display.slider.infinite",
                  valueSource: widget.value,
                  className: "p-l-16 p-r-16 p-t-16",
                  options: {
                    label: "Infinite loop",
                    size: "small",
                    allowDisable: true,
                    showOptions: false,
                  },
                },
                {
                  componentPath: "common/ConfigToggle",
                  valuePath: "options.display.slider.auto",
                  valueSource: widget.value,
                  className: "p-l-16 p-r-16 p-t-16",
                  options: {
                    label: "Auto slide",
                    size: "small",
                    allowDisable: true,
                    showOptions: false,
                  },
                },
                {
                  componentPath: "ConfigHorizontalSpace",
                  valuePath: "options.display.slider.delay",
                  valueSource: widget.value,
                  className: "group-control-element",
                  visible: widget.value.options.display.slider.auto,
                  options: {
                    label: "Delay",
                    suffix: "sec",
                  },
                },
              ],
            },
            {
              componentPath: "form/ConfigTabs",
              options: {
                items: [
                  {
                    value: "pagination",
                    label: "Pagination",
                    isActiveValueSource: fields.value.pagination.field.options,
                  },
                  {
                    value: "arrows",
                    label: "Arrows",
                    isActiveValueSource: fields.value.arrows.field.options,
                  },
                ],
              },
            },
          ]
        : []
    );

    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.mode",
            valueSource: widget.value,
            options: {
              label: "Mode",
              items: [
                {
                  label: "Grid",
                  value: ContainerMode.GRID,
                },
                {
                  label: "Carousel",
                  value: ContainerMode.CAROUSEL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: widget.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Fit",
                  value: ResizingType.FIT,
                  active: !isSlider.value,
                },
                {
                  label: "Fill",
                  value: ResizingType.FILL,
                },
                {
                  label: "Fixed",
                  value: ResizingType.FIXED,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.grid.gridFixedType",
            valueSource: widget.value,
            visible:
              !isSlider.value &&
              widget.value.options.display.layout === ResizingType.FIXED,
            options: {
              label: "Resizing type",
              items: [
                {
                  label: "Fit",
                  value: Grid.FIT,
                },
                {
                  label: "Fill",
                  value: Grid.FILL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.width",
            valueSource: widget.value,
            visible:
              !isSlider.value ||
              (isSlider.value &&
                widget.value.options.display.layout === ResizingType.FIXED),
            options: {
              label: "Card width",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.display.grid.maxCardsInRow",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            visible:
              !isSlider.value &&
              widget.value.options.display.layout === ResizingType.FIXED,
            options: {
              label: "Max. number of elements in a row",
              minValue: 1,
              maxValue: 10,
              suffix: "pcs",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.display.grid.minCardsInRow",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            visible:
              !isSlider.value &&
              widget.value.options.display.layout === ResizingType.FIXED,
            options: {
              label: "Min. number of elements in a row",
              minValue: 1,
              maxValue: 10,
              suffix: "pcs",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.gap.column",
            valueSource: widget.value,
            visible: isSlider.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigGapSpace",
            valuePath: "options.display.gap",
            valueSource: widget.value,
            visible: !isSlider.value,
            options: {
              label: "Gap",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.display.slider.visible",
            valueSource: widget.value,
            className: "p-l-16 p-r-16 p-t-16",
            visible:
              isSlider.value &&
              widget.value.options.display.layout === ResizingType.FILL,
            options: {
              label: "Max. number of visible cards",
              minValue: 1,
              maxValue: 10,
              suffix: "pcs",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: widget.value,
            className: "group-control-element",
            visible: isSlider.value,
            options: {
              label: "Alignment",
              layout: DisplayOrientation.VERTICAL,
            },
          },
        ],
      },
      ...sliderControls.value,
      {
        componentPath: "ConfigArrayItems",
        options: {
          items: slides.value,
          "onUpdate:items": (fields: IWidgetField[]) => {
            arrayField.value!.value = fields;
          },
          maxItemsCount: 10,
          subjectName: "card",
          showOnlyInput: true,
          addItemHandler: () => {
            if (!slides.value?.[0]) return;
            const { fields, type, name, parent_id, options } = slides.value[0];

            const newItem = {
              ...generateDummyField({
                name,
                type,
                parent_id,
                options,
                title: "Card",
                value: "Card",
              }),
              fields: fields?.map(field =>
                generateDummyField({
                  name: field.name,
                  parent_id: field.parent_id,
                  type: field.type,
                  title: field.title,
                  value: field.default_value,
                  options: field.options,
                })
              ),
            };
            slides.value.push(newItem as IWidgetField);
          },
          reorderHandler: (
            targetId: number | string,
            insertPosition: IInsertPosition
          ): void => {
            const [currFields] = reorderListElements(
              "id",
              targetId,
              insertPosition,
              slides.value
            );

            arrayField.value!.value = currFields;
          },
        },
      },
    ];
  });

  const imageControls = computed<ControlProp[]>(() => {
    const { flexibleImageControls } = useFlexibleImageControls(
      toRef(fields.value.image.field)
    );
    return [
      {
        componentPath: "ConfigImageUpload",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          active: selectedField.value?.options._active,
        },
      },
      ...flexibleImageControls.value,
    ];
  });

  const titleControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          isBold: true,
          showOptions: true,
        },
      },
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const descriptionControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.withTranslations",
        valueSource: selectedField.value,
        className: "widget-control-container p-l-16 p-t-16 p-r-16 p-b-16",
        options: {
          label: "With Translations",
          size: "small",
          allowDisable: true,
          "onUpdate:modelValue": (value: boolean) => {
            updateFieldOption(
              "withTranslations",
              value,
              fields.value.description_1.field
            );
          },
        },
      },
    ];
  });

  const currentSlideFields = computed(() => {
    const currentSlide = (
      fields.value.items.field.value as ICustomField[]
    ).find(slideField =>
      slideField.fields?.some(f => f.id === selectedField.value?.id)
    );
    if (!currentSlide) return null;

    return useWidgetFields(currentSlide).value;
  });

  const { buttonsGroupDesignControls, buttonsGroupContentControls } =
    useButtonsGroupControls(widget, currentSlideFields, ["ConfigAlignment"]);

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls(
      {
        state: computed(() => {
          switch (selectedField.value?.name) {
            case "button_1":
              return states.value.button_1;
            case "button_2":
              return states.value.button_2;
            default:
              return State.DEFAULT;
          }
        }),
        stateHandler: (state: State) => {
          states.value[selectedField.value!.name] = state;
        },
      },
      emit
    );

  const {
    arrowsDesignControls,
    arrowsContentControls,
    arrowsStateControls,
    paginationDesignControls,
    paginationContentControls,
  } = useCarouselConfig({ widget, states }, emit);

  const {
    cardSlideDesignControls,
    cardSlideContentControls,
    cardSlideStatesControls,
  } = useCardSlideControls(widget, states, emit);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    imageControls,
    titleControls,
    descriptionControls,

    buttonsGroupDesignControls,
    buttonsGroupContentControls,

    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,

    cardSlideDesignControls,
    cardSlideContentControls,
    cardSlideStatesControls,

    arrowsDesignControls,
    arrowsContentControls,
    arrowsStateControls,

    paginationDesignControls,
    paginationContentControls,
  };
};
