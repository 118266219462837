import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetControls } from "~~/composables/widgets/coming-events-switcher/useComingEventsSwitcherWidgetControls";
import { useInit } from "~~/composables/widgets/coming-events-switcher/useComingEventsSwitcherInitData";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";

import { useAllowDisableDependentFieldContentItems } from "../useAllowDisableDependentFieldContentItems";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  itemsGroupState: Ref<State>,
  emit: any
) => {
  const { itemsGroup } = useInit(widget.value);

  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.TEXT, FieldContentItem.ICON]
  );

  const itemsGroupSettingsValuePath = computed<string>(() => {
    if (itemsGroupState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${itemsGroupState.value}`;
  });

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Items", "itemsGroup"),
    generateDropdownItem("Live", "live", "item"),
    generateDropdownItem("Coming Events", "comingEvents", "item"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    itemsGroup: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...DEFAULT_DROPDOWN_ITEMS];
  });

  const { widgetDesignControls, widgetContentControls } =
    useWidgetControls(widget);

  const itemsGroupDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: itemsGroup.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: itemsGroup.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: itemsGroup.value,
        options: {
          meta: {
            isBold: true,
          },
          label: "Margin",
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const itemsGroupContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.resizing",
        valueSource: itemsGroup.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.alignment",
        valueSource: itemsGroup.value,
        className: "border-bottom-none",
        visible: itemsGroup.value?.options.resizing === ResizingType.HUG,
        options: {
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "ConfigColumnWidth",
        valuePath: "options.spaceBetween",
        valueSource: itemsGroup.value,
        options: {
          label: "Distance between tabs",
        },
      },
      {
        componentPath: "ConfigArrayItemsText",
        valuePath: `options.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.itemsIcon`,
        valueSource: itemsGroup.value,
        options: {
          label: "Icon",
        },
      },
    ];
  });

  const itemsGroupStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: itemsGroupState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-items-group-state", value);
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${itemsGroupSettingsValuePath.value}.fill`,
        valueSource: itemsGroup.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${itemsGroupSettingsValuePath.value}.border`,
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${itemsGroupSettingsValuePath.value}.shadow`,
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsIcon`,
        valueSource: itemsGroup.value,
        options: {
          label: "Icon",
          isStatesTab: true,
        },
      },
    ];
  });

  const itemControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItemText",
        valuePath: `options.text`,
        valueSource: selectedField.value,
        options: {
          label: "Text",
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `options.icon`,
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,

    itemControls,
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  };
};
