import { useWidgetsStore } from "~~/store/widgets";
import {
  Alignment,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {};

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  /*
    Initialize widget with initial options
  */

  const widgetOptions = initWidgetOptions();

  const init = (): void => {
    initFields();
    addFieldInitialOptions(
      "page_content",
      {
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#FFFFFF"),
          spacing: {
            padding: getDefaultSpacing(16),
          },
        },
        additionalData: {
          options: {
            theme: getDefaultTheme(22),
            decoration: getDefaultDecoration([TextStyle.BOLD]),
            alignment: Alignment.CENTER,
          },
        },
      },
      { value: "Promotion Content" }
    );

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,

    init,
  };
};
