import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
} from "~~/models/widgets/widget-controls.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFlex,
  generateFontSize,
  generateGrid,
  generateTextColor,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";

import {
  generateWidgetStyles,
  generateListStartTimeCssString,
  generateItemMoreStyles,
} from "./sportsbook-widget-css";

const generateSportsContainerCssString =
  (field: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._containerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "unset",
        direction: "column",
        justify: "unset",
        gap: field.options.spaceBetween,
      }),
    });
    return cssString;
  };

const generateSportsTitleCssString =
  (field: IWidgetField) =>
  (cssString: string): string => {
    const { options } = field;
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "unset",
        gap: options.spaceBetween,
      }),
    });

    cssString += generateDefaultStylesWithStates(field, false);

    const iconFontCssContent =
      generateTextColor(options.iconSettings.textColor) +
      generateFontSize(options.iconSettings.iconSize);

    cssString += generateCssClassWithContent({
      className: options.iconSettings._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: `:hover .${options.iconSettings._cssClass}`,
      content: generateTextColor(options.states.hover.iconSettings.textColor),
    });

    return cssString;
  };

const generateEventCardsContainerCssString =
  (field: IWidgetField) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: field.options._containerCssClass,
      content: generateGrid({
        templateColumns: `repeat(${options.columns}, minmax(0, 1fr))`,
        gap: options.containerSpaceBetween,
      }),
    });
    return cssString;
  };

const generateEventCardCssString =
  (field: IWidgetField, isLayoutFirstMode: boolean) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._footerCssClass,
      content: `${generateFlex({
        flex: "flex",
        align: isLayoutFirstMode ? "center" : "unset",
        direction: isLayoutFirstMode ? "row" : "column",
        justify: "unset",
        gap: field.options.spaceBetween,
      })}
      overflow: hidden;
      `,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `${generateFlex({
        flex: "flex",
        align: "unset",
        direction: "column",
        justify: "unset",
        gap: field.options.spaceBetween,
      })}
      overflow: hidden;
      `,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateHeaderCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const headerFlex = generateFlex({
      flex: "flex",
      align: "center",
      gap: field.options.verticalSpace,
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: headerFlex,
    });

    const isHeaderLayoutVertical =
      field.options.layout === DisplayOrientation.VERTICAL;

    const leagueNamePosition =
      field.options?.[
        `leagueName${
          isHeaderLayoutVertical ? "Vertical" : "Horizontal"
        }Position`
      ];

    const headerLeftFlex = generateFlex({
      flex: "flex",
      align: "unset",
      gap: field.options.verticalSpace,
      justify: "unset",
      direction: isHeaderLayoutVertical
        ? leagueNamePosition === "top"
          ? "column"
          : "column-reverse"
        : leagueNamePosition === "left"
        ? "row"
        : "row-reverse",
    });

    cssString += generateCssClassWithContent({
      className: field.options._leftCssClass,
      content: `${headerLeftFlex}
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;
      `,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateEventLeagueCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `min-width: 3ch;
      width: ${field.options.displayMode === "fill" ? "100%" : "fit-content"}
      `,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateFavouritesButtonCssString =
  (element: IWidgetOptions, headerElement?: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateFontSize(element.options.iconSize),
      `align-self: ${headerElement?.options.favoriteIconAlignment || "unset"};`,
      "justify-self: flex-end;",
      "flex-shrink: 0;",
      generateDefaultStyles(element.options),
    ];
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: content.join(""),
    });

    return cssString;
  };

const generateEventNameCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `min-width: 3ch;flex-grow:1;`,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateBetItemsCssString =
  (field: IWidgetOptions, isLayoutFirstMode: boolean) =>
  (cssString: string): string => {
    const nameCssContent = generateDefaultStyles(field.options.name);

    cssString += generateCssClassWithContent({
      className: field.options.name._cssClass,
      content: nameCssContent + "overflow: hidden;",
    });

    const coefCssContent = generateDefaultStyles(field.options.coef);

    cssString += generateCssClassWithContent({
      className: field.options.coef._cssClass,
      content: coefCssContent,
    });

    const containerFlex = generateFlex({
      flex: "flex",
      direction: field.options.layout === "horizontal" ? "row" : "column",
      align: "unset",
      gap: field.options.spaceBetween,
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._containerCssClass,
      content: containerFlex,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `
      ${generateDefaultStyles(field.options, ["spaceBetween"])}
      display: flex;
      flex-grow:1;
      flex-direction:${isLayoutFirstMode ? "column" : "row"};
      font-family: ${field.options.fontFamily};
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
      `,
    });

    for (const state in field.options.states) {
      const pseudoClassName = state === "active" ? `--selected` : `:${state}`;

      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName,
        content: generateDefaultStyles(field.options.states[state], ["theme"]),
      });

      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName,
        childClassName: field.options.name._cssClass,
        content: generateDefaultStyles(field.options.states[state].name, [
          "theme",
        ]),
      });

      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName,
        childClassName: field.options.coef._cssClass,
        content: generateDefaultStyles(field.options.states[state].coef, [
          "theme",
        ]),
      });
    }

    cssString += generateCssClassWithContent({
      className: field.options._itemSelectedCssClass,
      content: generateDefaultStyles(field.options.states.active),
    });

    return cssString;
  };

const generateEventButtonCssString =
  (field: IWidgetOptions, isLayoutFirstMode?: boolean) =>
  (cssString: string): string => {
    const containerFlex = generateFlex({
      flex: "flex",
      direction:
        field.options.icon.position === Alignment.LEFT ? "row-reverse" : "row",
      align: "center",
      gap: field.options.icon.gap,
      justify: field.options.display.alignment,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `${containerFlex}
      ${field.options.display.resizing === "fill" ? "flex-grow:1;" : ""}
      ${
        field.name === "event_button" &&
        !isLayoutFirstMode &&
        field.options.display.resizing === "hug"
          ? "width:fit-content;"
          : ""
      }
      `,
    });

    const iconFontCssContent =
      generateTextColor(field.options.icon.textColor) +
      generateFontSize(field.options.icon.iconSize);

    cssString += generateCssClassWithContent({
      className: field.options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateDefaultStylesWithStates(field, false);

    for (const state in field.options.states) {
      const pseudoClassName = `:${state}`;

      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName,
        childClassName: field.options.icon._cssClass,
        content: generateTextColor(field.options.states[state].icon.textColor),
      });
    }

    return cssString;
  };

const generateHoverButtonCssString =
  (field: IWidgetOptions, sportsField: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: "visibility: hidden;",
    });

    cssString += generateCssClassWithContent({
      className: sportsField.options._cssClass,
      pseudoClassName: ":hover",
      childClassName: field.options._cssClass,
      content: "visibility: visible;",
    });

    return cssString;
  };

const generateLeaguesContainerCssString =
  (field: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._containerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "unset",
        direction: "column",
        justify: "unset",
        gap: field.options.spaceBetween,
      }),
    });
    return cssString;
  };

export const generateBetsListFenixMobileWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const sports = widget.content["sports"];
  const title = widget.content["title"];
  const leagues = widget.content["leagues"];
  const bet_items = widget.content["bet_items"];
  const event_button = widget.content["event_button"];
  const sports_event_button = widget.content["sports_event_button"];
  const hover_button = widget.content["hover_button"];
  const event_card = widget.content["event_card"];
  const event_name = widget.content["event_name"];
  const event_league = widget.content["event_league"];
  const event_time = widget.content["event_time"];
  const no_events_title = widget.content["no_events_title"];
  const header = widget.content["header"];
  const favourites_button = widget.content["favourites_button"];

  widget.options._cssClass = generateClassName("BetsListFenixMobileWidget");
  sports.options._cssClass = generateClassName("sports");
  sports.options._containerCssClass = generateClassName("sportsContainer");
  title.options._cssClass = generateClassName("title");
  title.options.iconSettings._cssClass = generateClassName("titleIconSettings");
  leagues.options._cssClass = generateClassName("leagues");
  leagues.options._containerCssClass = generateClassName("leaguesContainer");
  event_card.options._cssClass = generateClassName("eventCard");
  event_name.options._cssClass = generateClassName("eventName");
  event_card.options._footerCssClass = generateClassName("eventCardFooter");
  event_card.options._containerCssClass = generateClassName(
    "eventCardsContainer"
  );
  no_events_title.options._cssClass = generateClassName("no_events_title");
  event_league.options._cssClass = generateClassName("eventLeague");

  bet_items.options._cssClass = generateClassName("bet_items");
  bet_items.options._containerCssClass =
    generateClassName("bet_itemsContainer");
  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");
  bet_items.options._itemSelectedCssClass =
    bet_items.options._cssClass + '[data-selected="true"]';

  header.options._cssClass = generateClassName("header");
  header.options._leftCssClass = generateClassName("headerLeft");

  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");

  event_button.options._cssClass = generateClassName("event_button");
  event_button.options.icon._cssClass = generateClassName("event_button_icon");

  hover_button.options._cssClass = generateClassName("hover_button");
  hover_button.options.icon._cssClass = generateClassName("hover_button_icon");

  sports_event_button.options._cssClass = generateClassName(
    "sports_event_button"
  );
  sports_event_button.options.icon._cssClass = generateClassName(
    "sports_event_button_icon"
  );

  favourites_button.options._cssClass = generateClassName("favouritesButton");

  const isLayoutFirstMode =
    event_card.options.layoutMode === SportMenuLiveBet9CardLayoutMode.FIRST;

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateSportsTitleCssString(title as IWidgetField),
    generateBetItemsCssString(bet_items as IWidgetField, isLayoutFirstMode),
    generateListStartTimeCssString(event_time as IWidgetField),
    generateItemMoreStyles(event_button.options),
    generateStringDefault(no_events_title),
    generateSportsContainerCssString(sports as IWidgetField),
    generateStringWithStates(sports, false),
    generateStringDefault(leagues),
    generateLeaguesContainerCssString(leagues as IWidgetField),
    generateEventCardsContainerCssString(event_card as IWidgetField),
    generateEventCardCssString(event_card as IWidgetField, isLayoutFirstMode),
    generateHeaderCssString(header),
    generateEventLeagueCssString(event_league),
    generateFavouritesButtonCssString(favourites_button, header),
    generateEventNameCssString(event_name),
    generateEventButtonCssString(event_button, isLayoutFirstMode),
    generateEventButtonCssString(sports_event_button),
    generateEventButtonCssString(hover_button),
    generateHoverButtonCssString(hover_button, sports)
  )("");
};
