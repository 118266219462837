<template>
  <button
    v-if="isLayoutAvailable"
    class="settings-layout"
    :class="{
      'settings-layout--active':
        selectedCell?.children.length === props.columns,
      'settings-layout--disabled': props.isDisabled,
    }"
    :disabled="props.isDisabled"
    @click="$emit('select', props.columns)"
  >
    <div class="settings-layout__option">
      <div
        v-for="layout in props.columns"
        :key="layout"
        class="settings-layout__inner"
      ></div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { useGridConfig } from "~~/store/grid";
import { useLayoutOption } from "~~/components/composables/grid/useLayoutOptions";

const props = defineProps<{
  columns: number;
  isDisabled: boolean;
}>();

const { isLayoutAvailable } = useLayoutOption(props.columns);

defineEmits<{
  (e: "select", event: number): void;
}>();

const gridStore = useGridConfig();
const { selectedCell } = storeToRefs(gridStore);
</script>

<style lang="scss">
.settings-layout {
  @include flex-column(flex-start, space-between, 10px);
  padding: $space-s;
  border: 1px solid $c-grey-15;
  border-radius: 6px;
  height: auto;
  background-color: $c-white;
  cursor: pointer;

  &__option {
    @include flex(center, center, 4px);
    width: 52px;
    background-color: $c-light-blue;
    border-radius: 2px;
    padding: 4px;
  }

  &__inner {
    height: 28px;
    flex-basis: 100%;
    border-radius: 4px;
    background-color: $c-layout-inner-bg-blue;
  }

  &--active {
    border: 2px solid $c-primary-base;
  }

  &--disabled {
    background: $c-grey-04;
    border: 2px solid $c-grey-15;

    .settings-layout__option {
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }
}
</style>
