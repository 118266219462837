<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoGamesListWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="casino-games-list-widget">
      <WidgetField
        v-if="
          fields.game_cards.active &&
          states.games_status === State.WITH_ACTIVE_GAMES
        "
        :widget="widget"
        :field="fields.game_cards.field"
      >
        <div
          class="casino-games-list-widget-cards"
          :style="cardsContentStyles"
        >
          <CasinoGameCard
            v-for="idx in 5"
            :key="idx"
            :photo-id="idx"
            :style="cardStyles"
            :widget="widget"
            :field="fields.game_cards.field"
            :state="states.game_cards"
            :photo-field="fields.photo.field"
            :hover-content-field="fields.hover_content.field"
            :game-title-field="fields.game_title.field"
            :play-money-button-field="fields.play_money_button.field"
            :play-money-button-state="states.play_money_button"
            :play-free-button-field="fields.play_free_button.field"
            :play-free-button-state="states.play_free_button"
            :cards-elements-field="fields.cards_elements.field"
            :promo-tag-field="fields.promo_tag.field"
            :promo-tag-state="states.promo_tag"
            :favorite-icon-field="fields.favorite_icon.field"
            :is-hover-content="isHoverContent"
          />
        </div>
      </WidgetField>
      <BasicTitleField
        v-else
        :field="fields.no_games_title.field"
        :state="states.no_games_title"
      />
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CellSizeType, ICell } from "~~/models/grid.interface";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCasinoListInitData } from "~~/composables/widgets/casino/list/useCasinoListInitData";
import {
  DisplayOrientation,
  State,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";
import { getFlexAlign } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);
const { states, DEFAULT_STATES, initFields } = useCasinoListInitData(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["games_status"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

const isHoverContent = computed<boolean>(() => {
  return (
    selectedField.value?.name === fields.value.hover_content.field.name ||
    selectedField.value?.name === fields.value.game_title.field.name ||
    selectedField.value?.name === fields.value.play_money_button.field.name ||
    selectedField.value?.name === fields.value.play_free_button.field.name
  );
});

const cardsContentStyles = computed<ElementStyle>(() => {
  const settings = fields.value.game_cards.field.options.display;

  if (!settings) {
    return {};
  }

  const style: ElementStyle = {};

  const layout = settings.layout;

  style.flexDirection =
    layout === DisplayOrientation.VERTICAL ? "column" : "row";
  style.flexWrap = layout === DisplayOrientation.WRAP ? "wrap" : "unset";
  style.columnGap = getPxValueFromNumber(settings.gap.col);
  style.rowGap = getPxValueFromNumber(settings.gap.row);

  return {
    display: "flex",
    overflow: "auto",
    ...getFlexAlign(settings.alignment),
    ...style,
  };
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  const currentState = states.value.game_cards;
  if (isHoverContent.value) {
    return fields.value.game_cards.field.options.states[State.HOVER];
  }

  if (currentState === State.DEFAULT) {
    return fields.value.game_cards.field.options;
  }

  return fields.value.game_cards.field.options.states[currentState];
});

const cardStyles = computed<ElementStyle>(() => {
  const { size } = fields.value.game_cards.field.options;
  return {
    flexShrink: 0,
    ...getWidthStyles(size.width),
    ...getHeightStyles(size.height.value),
    ...getCommonStyles({
      ...fields.value.game_cards.field.options,
      ...currentStateOptions.value,
    }),
  };
});

const getWidthStyles = (width: WidgetDimension): ElementStyle => {
  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.type === CellSizeType.HUG) {
    return {
      width: "initial",
      display: "inline-block",
    };
  }

  return {
    width: `${width.value?.value}${width.value?.type}`,
  };
};

const getHeightStyles = (height: Partial<WidgetDimension>): ElementStyle => {
  return {
    height: `${height?.value}${height?.type}`,
  };
};

initFields();
</script>

<style lang="scss">
.casino-games-list-widget {
  position: relative;
  &-cards {
    position: relative;
  }
}
</style>
