<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <div>
      <BonusesWidgetSettings
        :widget="widget"
        :states="states"
        @update-state="handleStateUpdate"
        @reset-states="resetStates"
        @change="handleChangeSelectedItem"
      />

      <div class="bonuses-widget">
        <template v-if="states.screen !== 'modal'">
          <WidgetField
            v-if="fields.title.active"
            :widget="widget"
            :field="fields.title.field"
          >
            <BasicTitleField :field="fields.title.field" />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.tabs.field"
          >
            <TransactionHistoryTabs
              :field="fields.tabs.field"
              :state="states.tabs"
              :items="TAB_ITEMS"
            /> </WidgetField
        ></template>

        <WidgetField
          v-if="
            states.screen === State.ACTIVE_EVENTS ||
            states.screen === State.NO_ACTIVE
          "
          :widget="widget"
          :field="fields.cards.field"
        >
          <BonusesCard
            v-if="states.screen === State.ACTIVE_EVENTS"
            :field="fields.cards.field"
            :state="states.cards"
          >
            <template #image>
              <WidgetField
                :widget="widget"
                :field="fields.image.field"
                class="bonuses-widget__photo-field"
              >
                <BonusesPhoto
                  :field="fields.image.field"
                  :class="{
                    'bonuses-widget__photo--is-vertical':
                      fields.cards.field.options.layout ===
                      DisplayOrientation.VERTICAL,
                  }"
                >
                  <template #default="{ styles }">
                    <img
                      :src="'/images/bonuses-widget-preview.png'"
                      :style="styles"
                      draggable="false"
                    />
                  </template>
                </BonusesPhoto>
              </WidgetField>
            </template>

            <template #title>
              <WidgetField
                :widget="widget"
                :field="fields.bonus_title.field"
              >
                <BasicTitleField
                  :field="fields.bonus_title.field"
                  value="Title"
                /> </WidgetField
            ></template>

            <template #status>
              <WidgetField
                :widget="widget"
                :field="fields.status.field"
              >
                <BonusesText
                  :field="fields.status.field"
                  primary-text="Status:"
                  secondary-text="status description"
                  class="bonuses-widget__content-flex-field"
                /> </WidgetField
            ></template>

            <template #date>
              <WidgetField
                :widget="widget"
                :field="fields.dates.field"
              >
                <div
                  class="bonuses-widget__date bonuses-widget__content-flex-field"
                >
                  <BonusesText
                    :field="fields.dates.field"
                    primary-text="Acceptance date:"
                    secondary-text="21.07.2023"
                  />
                  <BonusesText
                    :field="fields.dates.field"
                    primary-text="Expires date:"
                    secondary-text="21.07.2023"
                  />
                </div> </WidgetField
            ></template>

            <template #description>
              <WidgetField
                :widget="widget"
                :field="fields.description.field"
              >
                <BasicTitleField
                  :field="fields.description.field"
                  value="Description"
                  class="bonuses-widget__content-flex-field"
                />
              </WidgetField>
            </template>

            <template #progress_bar>
              <WidgetField
                :widget="widget"
                :field="fields.progress_bar.field"
              >
                <BasicProgressField
                  :field="fields.progress_bar.field"
                /> </WidgetField
            ></template>

            <template #amounts>
              <WidgetField
                :widget="widget"
                :field="fields.amounts.field"
              >
                <div
                  class="bonuses-widget__amouts bonuses-widget__content-flex-field"
                >
                  <BonusesText
                    :field="fields.amounts.field"
                    :primary-text="t('widgets.BonusesWidget.bonus_amount')"
                    secondary-text="$12,5"
                  />
                  <BonusesText
                    :field="fields.amounts.field"
                    :primary-text="t('widgets.BonusesWidget.total_bet_amount')"
                    secondary-text="$6,25"
                  />
                  <BonusesText
                    :field="fields.amounts.field"
                    :primary-text="t('widgets.BonusesWidget.rest_amount')"
                    secondary-text="$6,25"
                  />
                </div> </WidgetField
            ></template>

            <template #terms_title>
              <WidgetField
                :widget="widget"
                :field="fields.terms_title.field"
              >
                <BasicTitleField
                  :field="fields.terms_title.field"
                  :state="states.terms_title"
                /> </WidgetField
            ></template>

            <template #action_button>
              <WidgetField
                v-if="fields.action_button.active"
                :widget="widget"
                :field="fields.action_button.field"
              >
                <BasicTextButtonField
                  :field="fields.action_button.field"
                  :current-state="states.action_button"
                  :value="t('widgets.BonusesWidget.action_button')"
                  class="bonuses-widget__action-btn bonuses-widget__content-flex-field"
                />
              </WidgetField>
            </template>
          </BonusesCard>

          <BasicTitleField
            v-else-if="states.screen === State.NO_ACTIVE"
            :field="fields.no_active_bonuses.field"
          />
        </WidgetField>

        <WidgetField
          v-if="states.screen === 'modal'"
          :widget="widget"
          :field="fields.modal.field"
        >
          <BonusesModal :field="fields.modal.field">
            <template #title>
              <WidgetField
                :widget="widget"
                :field="fields.modal_title.field"
              >
                <BasicTitleField
                  :field="fields.modal_title.field"
                  value="Modal title"
                />
              </WidgetField>
            </template>

            <template #description>
              <WidgetField
                :widget="widget"
                :field="fields.modal_description.field"
              >
                <BasicTitleField
                  :field="fields.modal_description.field"
                  value="Modal description"
                />
              </WidgetField>
            </template>

            <template #primary_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_primary_button.field"
              >
                <BasicTextButtonField
                  :field="fields.modal_primary_button.field"
                  :value="t('widgets.BonusesWidget.modal_primary_button')"
                  :current-state="states.modal_primary_btn"
                />
              </WidgetField>
            </template>

            <template #secondary_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_secondary_button.field"
              >
                <BasicTextButtonField
                  :field="fields.modal_secondary_button.field"
                  :value="t('widgets.BonusesWidget.modal_secondary_button')"
                  :current-state="states.modal_secondary_btn"
                />
              </WidgetField>
            </template>

            <template #close_button>
              <WidgetField
                :widget="widget"
                :field="fields.modal_close_icon.field"
              >
                <BasicButtonField
                  :field="fields.modal_close_icon.field"
                  :current-state="states.modal_close_icon"
                />
              </WidgetField>
            </template>
          </BonusesModal>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/bonuses/useBonusesInit";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useStates } from "~~/composables/widgets/common/useStates";

const { t } = useI18n();

const TAB_ITEMS = computed(() => [
  t("widgets.BonusesWidget.active_bonuses"),
  t("widgets.BonusesWidget.pending_bonuses"),
  t("widgets.BonusesWidget.previous_bonuses"),
  t("widgets.BonusesWidget.blocked_bonuses"),
]);

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const { states, DEFAULT_STATES, MODAL_FIELDS, initFields } = useInit(
  props.widget
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    if (states.value[stateName] === "modal") {
      return false;
    }

    return true;
  },
});

const handleChangeSelectedItem = (fieldName: string): void => {
  if (fieldName === "modal") {
    resetStates();
    states.value.screen = "modal";
    return;
  }

  if (states.value.screen === State.ACTIVE_EVENTS) {
    resetStates();
    return;
  }

  if (!MODAL_FIELDS.includes(fieldName)) {
    resetStates();
    states.value.screen = State.ACTIVE_EVENTS;
    return;
  }

  resetStates();
};

initFields();
</script>

<style lang="scss">
.bonuses-widget {
  &__date {
    @include flex(center, flex-start, 16px);
  }

  &__amouts {
    @include flex(center, flex-start, 0);

    .bonuses-widget-text {
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__photo-field {
    height: 100%;
  }

  &__action-btn {
    display: inline-block;
  }

  .bonuses-widget__content-flex-field {
    display: flex;
    justify-content: var(--card-flexAlignment);
  }

  .bonuses-widget-text {
    align-items: var(--card-flexAlignment);
  }

  .bonuses-widget__photo--is-vertical {
    justify-content: var(--card-flexAlignment);
  }
}
</style>
