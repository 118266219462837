import { createRouter, createWebHistory } from "vue-router";

import { Step } from "~~/models/step.enum";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Constructor",
      redirect: "layout",
      component: () => import("~~/pages/MainLayout.vue"),
      children: [
        {
          path: "/layout",
          name: Step.LAYOUTS,
          component: () => import("~~/components/grid/GridConfig.vue"),
        },
        {
          path: "/page",
          name: Step.WIDGETS,
          component: () => import("~~/components/widgets/WidgetsConfig.vue"),
        },
      ],
    },
  ],
});
