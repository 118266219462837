<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <DropdownWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :title-state="states.title"
      :items-group-state="states.items"
      @update-title-state="handleStateUpdate({ state: 'title', value: $event })"
      @update-items-group-state="
        handleStateUpdate({ state: 'items', value: $event })
      "
      @reset-states="resetStates"
    />

    <BasicDropdownField
      :widget="widget"
      :title-state="states.title"
      :items-group-state="states.items"
    />
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { useWidgetWithArrayField } from "~~/composables/widgets/useWidgetWithArrayField";
import { State } from "~~/models/widgets/widget-controls.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import {
  TITLE_INITIAL,
  ITEMS_INITIAL,
  ITEMS_GROUP_INITIAL,
  ITEM_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { ICell } from "~~/models/grid.interface";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    cell: ICell;
    titleFieldName?: FieldName;
  }>(),
  {
    titleFieldName: FieldName.TITLE,
  }
);

const { initWidgetOptions } = useCreateFields(props.widget);

const { titleField, arrayField, itemsFields, itemsGroup, initItemsGroup } =
  useWidgetWithArrayField(props.widget, props.titleFieldName);

initItemsGroup();

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const DEFAULT_STATES = {
  title: State.DEFAULT,
  items: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const initialWidgetOptions = initWidgetOptions([], {
  display: { resizing: "hug", alignment: "left" },
});

const initialTitleOptions = prefillEmptyOptions(
  titleField.value!.options,
  TITLE_INITIAL().options
);

const initialItemsOptions = prefillEmptyOptions(
  arrayField.value!.options,
  ITEMS_INITIAL().options
);

const initialItemsGroupOptions = prefillEmptyOptions(
  itemsGroup.value!.options,
  ITEMS_GROUP_INITIAL().options
);

widgetsStore.updateWidgetOptions(props.widget, initialWidgetOptions);
widgetsStore.updateFieldOptions(titleField.value!, initialTitleOptions);
widgetsStore.updateFieldOptions(arrayField.value!, initialItemsOptions);
widgetsStore.updateFieldOptions(itemsGroup.value!, initialItemsGroupOptions);

itemsFields.value.forEach(itemField => {
  widgetsStore.updateFieldOptions(
    itemField,
    prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
  );
});
</script>

<style lang="scss">
.dropdown-widget {
  display: flex;
  flex-direction: column;

  &__field--selected {
    border: 2px solid rgba($c-primary-base, 1);
  }
}
</style>
