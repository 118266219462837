<template>
  <div :style="style">
    <slot name="description" />

    <WidgetField
      :widget="widget"
      :field="iframeField"
    >
      <div class="event-bet-builder__iframe">
        Frame for the betting constructor
      </div>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  iframeField: IWidgetField;
}>();

const style = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});
</script>

<style lang="scss">
.event-bet-builder {
  &__iframe {
    @include flex(center, center, 0);
    padding: 48px 0;
    font-size: 24px;
    font-weight: 600;
    background-color: $c-grey-04;
  }
}
</style>
