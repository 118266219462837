import sanitizeHtml from "sanitize-html";

const DEFAULT_NAME_KEY = "name";

export const useListSearch = () => {
  const searchValue = ref("");

  const getHightlightText = (
    value: string,
    startPost: number,
    endPos: number
  ): string => {
    const text = value.slice(startPost, endPos);
    const highlightText = `<span class="highilgted-text">${sanitizeHtml(
      text
    )}</span>`;
    const startText = value.slice(0, startPost);
    const endText = value.slice(endPos, value.length + 1);

    const result = startText + highlightText + endText;

    return result;
  };

  const getSearchHighlightedItem = (
    items: any[],
    nameKey = DEFAULT_NAME_KEY
  ) => {
    const result = [];
    const search = searchValue.value.trim().toLocaleLowerCase();

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const value = item[nameKey];

      const name = value.toLocaleLowerCase();

      /* 
        TODO review logic of isEditing field and
        remove it from current composable, 
        as it used only on pages logic
      */
      if (!name.includes(search) && !item.isEditing) {
        continue;
      }

      const startPosition: number = name.indexOf(search);
      const endPosition: number = startPosition + search.length;

      /*
        Get highilgted text only if search value
        is not empty; otherwise use default label
      */
      const nameHighlighted = search.length
        ? getHightlightText(value, startPosition, endPosition)
        : value;

      result.push({
        ...item,
        nameHighlighted,
      });
    }

    return result;
  };

  return {
    searchValue,
    getSearchHighlightedItem,
  };
};
