import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";

import { useAllowDisableDependentFieldContentItems } from "../../useAllowDisableDependentFieldContentItems";

export const useSportbookLiveFenixRacingConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const racingContainerDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.racing_events_container.field,
    });
  });

  const racingContainerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "racing_event_cards", label: "Racing event card" }],
        },
      },
    ];
  });

  /* 
   Racing events cards
  */

  const racingEventCardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.racing_event_cards.field,
    });
  });

  const racingEventCardsContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.contentLayout",
            valueSource: fields.value.racing_event_cards.field,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.contentItemsDistance",
            valueSource: fields.value.racing_event_cards.field,
            options: {
              label: "Distance between header elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { value: "racing_event_name", label: "Event Name" },
            { value: "racing_event_status", label: "Status" },
            {
              value: "racing_event_stream_icon",
              label: "Stream Icon",
              isActiveValueSource:
                fields.value.racing_event_stream_icon.field.options,
            },
            {
              value: "racing_event_favourites_button",
              label: "Favorite icon",
              isActiveValueSource:
                fields.value.racing_event_favourites_button.field.options,
            },
            {
              value: "racing_event_button",
              label: "Event Button",
              isActiveValueSource:
                fields.value.racing_event_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const racingEventCardsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        section: generateContentSectionName("Card"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.fill`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${selectedElementPath.value}.border`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigBoxShadow",
            valuePath: `${selectedElementPath.value}.shadow`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Shadow",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Event Name"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value[selectedField.value!.name] !== State.DEFAULT
                ? `${selectedElementPath.value}.color`
                : "options.color",
            valueSource:
              states.value[selectedField.value!.name] !== State.DEFAULT
                ? selectedField.value
                : fields.value.racing_event_name.field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /**
   * Event name
   */

  const racingEventNameControls = computed(() => {
    return [
      typography({ source: fields.value.racing_event_name.field }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.racing_event_name.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /**
   * Racing event status
   */

  const racingEventStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.racing_event_status.field,
      except: ["ConfigSpacingInputs", "ConfigBoxShadow"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.racing_event_status.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.racing_event_status.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const racingEventStatusContentControls = computed(() => {
    return [
      typography({
        source: fields.value.racing_event_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Favorites Button
   */

  const racingFavouritesButtonControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.racing_event_favourites_button.field,
        options: {
          hidePicker: true,
          hideSwitch: true,
          className: "p-l-16 p-t-16 p-r-16",
        },
      },
    ];
  });

  /**
   * Racing Event button
   */

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const racingEventButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.racing_event_button.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.racing_event_button.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.racing_event_button.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const racingEventButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            visible:
              selectedField.value?.options.buttonDisplaySettings.resizing ===
              ResizingType.FILL,
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const racingEventButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /**
   * Racing event stream icon
   */

  const racingStreamIconControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.racing_event_stream_icon.field,
        options: {
          hideSwitch: true,
          className: "p-l-16 p-t-16 p-r-16",
        },
      },
    ];
  });

  return {
    racingContainerDesignControls,
    racingContainerContentControls,

    racingEventCardsDesignControls,
    racingEventCardsContentControls,
    racingEventCardsStateControls,

    racingEventNameControls,

    racingEventStatusDesignControls,
    racingEventStatusContentControls,

    racingFavouritesButtonControls,

    racingEventButtonDesignControls,
    racingEventButtonContentControls,
    racingEventButtonStateControls,

    racingStreamIconControls,
  };
};
