import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

import { useItems } from "./usePromotionsPageItems";

export const useConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);

  /* 
    Widget config
  */

  const widgetDesignText = computed(() => {
    return "The content configured in the admin panel will be displayed here";
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignText,
  };
};
