<template>
  <div
    v-if="hasDesignPermissions"
    class="wconfig-vertical-space widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <p
      v-if="label"
      class="m-b-4"
      :class="{
        'bold': isBold,
      }"
    >
      {{ label }}
    </p>

    <ConfigInput
      v-number
      :is-number="true"
      :model-value="modelValue"
      :stretch-input="true"
      :input-props="{
        min: 0,
        max: MAX_NUMBER_INPUT_VALUE,
        controls: false,
      }"
      class="wconfig-vertical-space__option"
      :class="{ 'm-t-16': isBold }"
      @update:model-value="handleUpdate"
    >
      <template #prefix
        ><CommonIcon
          name="ant-design:column-width-outlined"
          class="wconfig-vertical-space__icon"
          :class="{ 'r-90': !notRotateIcon }"
      /></template>

      <template #suffix>
        <span> px </span>
      </template>
    </ConfigInput>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";

defineProps<{
  modelValue: string | number | null;
  label?: string;
  isBold?: boolean;
  notRotateIcon?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.wconfig-vertical-space {
  &__option {
    &,
    .ant-input-number-group-wrapper {
      width: 100%;
    }
  }

  &__icon {
    svg {
      fill: $c-grey-45;
      width: 18px;
      height: 16px;
    }
  }
}
</style>
