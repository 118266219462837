import { useLanguagesStore } from "~~/store/languages";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ILanguageProperties } from "~~/models/widgets/languages.interface";

const isObject = (value: any) =>
  Boolean(value && typeof value === "object" && !Array.isArray(value));

export function useLocalizedValue() {
  const {
    currentLanguageCode,
    availableLanguagesCodes,
    isDefaultLanguage,
    defaultLanguage,
  } = storeToRefs(useLanguagesStore());

  const isLocalized = (value: any) => {
    return (
      value &&
      typeof value === "object" &&
      defaultLanguage.value?.codeWithRegion &&
      defaultLanguage.value.codeWithRegion in value
    );
  };

  const getLocalizedValue = computed(() => {
    const localeCode = currentLanguageCode;

    const getLocalizedValue = (value: any, defaultValue: any) => {
      if (value[localeCode.value]) {
        return value[localeCode.value];
      }

      if (typeof value[localeCode.value] === "boolean") {
        return value[localeCode.value];
      }

      return defaultValue;
    };

    return (value: any, defaultValue: any = "") => {
      return isLocalized(value)
        ? getLocalizedValue(value, defaultValue)
        : value;
    };
  });

  const getLocalizedValueFromDefaultLanguage = computed(() => {
    const localeCode = defaultLanguage.value?.codeWithRegion || "";

    return (value: any) => {
      return isLocalized(value) && localeCode in value ? value[localeCode] : "";
    };
  });

  const setLocalizedValue = computed(() => {
    const localeCode = currentLanguageCode;

    return (
      modelValue: IWidgetField["value"],
      newInputValue: any
    ): Record<string, any> => {
      let localizedValue = modelValue;

      if (!localizedValue || !isObject(localizedValue)) {
        localizedValue = Object.keys(availableLanguagesCodes.value).reduce(
          (acc, languageCode) => {
            acc[languageCode] = isObject(newInputValue)
              ? newInputValue[languageCode] || ""
              : "";

            return acc;
          },
          {} as Record<string, string>
        );
      }

      if (
        isObject(localizedValue) &&
        // @ts-ignore
        typeof localizedValue[currentLanguageCode.value] === "undefined"
      ) {
        const firstLanguageCode = Object.keys(localizedValue).filter(
          // @ts-ignore
          locale => localizedValue[locale] !== undefined
        )[0];
        // @ts-ignore
        const fallbackValue = localizedValue[firstLanguageCode];

        localizedValue = Object.keys(availableLanguagesCodes.value).reduce(
          (acc, languageCode) => {
            acc[languageCode] = fallbackValue || "";

            return acc;
          },
          {} as Record<string, string>
        );
      }

      (localizedValue as Record<string, any>)[localeCode.value] = newInputValue;

      return localizedValue as Record<string, string>;
    };
  });

  const getLangLocalizedValue = (
    value: any,
    langCode: string,
    defaultLang?: ILanguageProperties
  ): string => {
    if (!isObject(value)) {
      return value;
    }

    const defaultLocaleCode = defaultLang?.codeWithRegion || "";

    if (value[langCode]) {
      return value[langCode];
    }

    if (value[defaultLocaleCode]) {
      return value[defaultLocaleCode];
    }

    const firstLangCode = Object.keys(value)[0];

    return value[firstLangCode];
  };

  /* 
    force - reassign value even if it was localized before.
    default - false
  */
  const transformToLocalizedValue = (inputValue: any, force = false) => {
    const valueIsObject = isObject(inputValue);
    const asObjectOrStringValue = inputValue || "";

    const result = {
      localized: force === true ? false : valueIsObject,
      localizedValue: valueIsObject
        ? { ...asObjectOrStringValue }
        : asObjectOrStringValue,
    };

    if (!valueIsObject || !asObjectOrStringValue || force) {
      result.localizedValue = Object.keys(availableLanguagesCodes.value).reduce(
        (acc, languageCode) => {
          acc[languageCode] = getLangLocalizedValue(
            asObjectOrStringValue,
            languageCode,
            defaultLanguage.value
          );

          return acc;
        },
        {} as Record<string, string>
      );

      result.localized = true;
    } else if (
      valueIsObject &&
      !(currentLanguageCode.value in asObjectOrStringValue)
    ) {
      const firstLanguageCode = Object.keys(asObjectOrStringValue)[0];

      result.localizedValue = Object.keys(availableLanguagesCodes.value).reduce(
        (acc, languageCode) => {
          acc[languageCode] = asObjectOrStringValue[firstLanguageCode] || "";

          return acc;
        },
        {} as Record<string, string>
      );

      result.localized = true;
    }

    return result;
  };

  return {
    getLocalizedValue,
    setLocalizedValue,
    currentLanguageCode,
    transformToLocalizedValue,
    isDefaultLanguage,
    availableLanguagesCodes,
    defaultLanguage,
    getLocalizedValueFromDefaultLanguage,
    isLocalized,
  };
}
