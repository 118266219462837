import { type Ref } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

type ConfigItem = [number, ControlProp];

export const useBetItemsConfig = (
  states: Ref<Record<string, string>>,
  emit: any,
  isLive?: boolean,
  additionalData?: Record<string, ConfigItem[]>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    Bet items.
    Please NOTE. Used for winner_league_bet_items AND list_item
  */

  const betItemsDesignControls = computed(() => {
    const marginsControls = {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.containerMargins",
      valueSource: selectedField.value,
      options: {
        label: "Margin",
        showTooltip: true,
        isAllSideSetting: true,
        meta: {
          isBold: true,
        },
      },
    };
    const result: ControlProp[] = [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      selectedField.value?.options.containerMargins ? marginsControls : {},
    ];

    if (additionalData?.design) {
      additionalData?.design.forEach(el => {
        result.splice(el[0], 0, el[1]);
      });
    }

    return result;
  });

  const betItemsLiveControls = computed(() => {
    if (!isLive) {
      return [];
    }

    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: selectedField.value,
            options: {
              label: "Bet items layout",
              items: [
                { value: "vertical", label: "Vertical" },
                { value: "horizontal", label: "Horizontal" },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between bet items",
            },
          },
        ],
      },
    ];
  });

  const betItemsContentControls = computed(() => {
    return [
      ...betItemsLiveControls.value,
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: selectedField.value,
        options: {
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.name.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.name.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.name.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.coef.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.coef.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.coef.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  const currentBetItemPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const liveBetStates = computed(() => {
    if (!isLive) {
      return [];
    }

    return [
      {
        label: STATE_KEY_LABELS.up,
        value: State.UP,
      },
      {
        label: STATE_KEY_LABELS.down,
        value: State.DOWN,
      },
    ];
  });

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            ...liveBetStates.value,

            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        visible: [State.UP, State.DOWN, State.HOVER, State.ACTIVE].includes(
          (states.value as Record<string, State>)[selectedField.value!.name]
        ),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.up.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !==
              State.DOWN,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Up color",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.down.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !== State.UP,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Down color",
            },
          },
        ],
      },
    ];
  });

  return {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    liveBetStates,
    currentBetItemPath,
  };
};
