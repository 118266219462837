<!-- 
  Review wit ConfigRangeSlider. Remove unnecessary one. Reuse primary everywhere
 -->
<template>
  <div
    class="widget-control-container config-slider p-l-16 p-r-16 p-b-16 p-t-16"
  >
    <p
      v-if="label"
      class="m-b-4"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>
    <div class="config-slider__inputs">
      <a-slider
        :value="modelValue"
        :min="min"
        :max="max"
        @update:value="handleUpdate"
      />
      <ConfigInput
        v-number
        :model-value="modelValue"
        :stretch-input="true"
        :is-number="true"
        :input-props="{
          min: min,
          max: max,
          controls: false,
        }"
        class="config-slider__resize-input"
        @update:model-value="handleUpdate"
      >
        <template #suffix>
          <span> px </span>
        </template>
      </ConfigInput>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  modelValue: number;
  label?: string;
  isBold?: boolean;
  min: number;
  max: number;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: number): void;
}>();

const handleUpdate = (value: number) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.config-slider {
  &__resize-input {
    max-width: 64px;
  }

  &__inputs {
    @include flex(center, center, 5px);

    .ant-slider {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
