<script lang="ts" setup>
import { ILanguageProperties } from "~~/models/widgets/languages.interface";
import { usePagesStore } from "~~/store/pages";

import { generateParamsFromSlug } from "../dynamic-params/utils";

const languageRoute = defineModel<string>("route", {
  required: true,
});

const errors = defineModel<Record<string, string>>("errors", {
  required: true,
});

const props = defineProps<{
  language: ILanguageProperties;
}>();

const language = computed<ILanguageProperties>(() => {
  return props.language;
});

const { selectedPage } = storeToRefs(usePagesStore());

const route = useRoute();

const isRouteFieldDisabled = ref(true);

const editRoute = () => {
  isRouteFieldDisabled.value = false;
};

const languageRouteMatch = ref<{ value: string; isDynamic: boolean }[]>([]);

const routeUpdate = () => {
  languageRoute.value =
    "/" + languageRouteMatch.value.map(({ value }) => value).join("/");
};

const updateRouteMatch = () => {
  let url = languageRoute.value;

  if (!url) {
    return;
  }

  if (url.startsWith("/")) {
    url = url.slice(1);
  }

  languageRouteMatch.value = url.split("/").map(item => ({
    value: item,
    isDynamic: item.startsWith(":"),
  }));
};

const defaultRouteUpdate = () => {
  if (!languageRoute.value) {
    selectedPage.value!.routeBinding = {};

    return;
  }

  const params = generateParamsFromSlug(
    selectedPage.value!.routeBinding || {},
    languageRoute.value
  );

  selectedPage.value!.routeBinding = params;

  // Set default language route for all languages
  Object.keys(selectedPage.value!.routes).forEach(key => {
    selectedPage.value!.routes[key] = languageRoute.value;
  });
};

watch(languageRoute, updateRouteMatch, { immediate: true });

watch(route, () => {
  isRouteFieldDisabled.value = true;
});

const hasError = computed<boolean>(() => {
  if (!errors.value) {
    return false;
  }

  for (const key in errors.value) {
    if (key === "name") {
      continue;
    }

    return true;
  }

  return false;
});

const errorMessage = computed<string | null>(() => {
  if (!errors.value) {
    return null;
  }

  for (const key in errors.value) {
    if (key === "name") {
      continue;
    }

    return errors.value[key];
  }

  return null;
});
</script>

<template>
  <a-form-item
    class="margin-bottom-none w-100"
    :name="['routes', language.codeWithRegion]"
    :rules="[{ required: true, message: 'Field is required!' }]"
    layout="vertical"
    :validate-status="hasError ? 'error' : undefined"
    :help="errorMessage"
  >
    <template #label>
      <div class="page-config-drawer__label">
        Page url

        <a-tooltip>
          <template #title>
            URL example: «/events/:sportID» events - not dynamic parameters
            :sportID - dynamic parameters (use «:» before name )
          </template>

          <CommonIcon
            name="ant-design:question-circle-outlined"
            :size="16"
          />
        </a-tooltip>
      </div>
    </template>

    <div
      v-if="language.isDefault"
      class="w-100 flex items-center gap-8"
    >
      <a-input
        v-model:value="languageRoute"
        class="w-100"
        :on-blur="defaultRouteUpdate"
        :disabled="isRouteFieldDisabled"
      />

      <a-popover
        v-if="isRouteFieldDisabled"
        trigger="click"
        placement="left"
      >
        <template #content>
          <p class="page-config-drawer__alert-message">
            <b>Attention!</b> <br />
            You have changed the link to the page. This may break the links
            between widgets on this page or the links between this page and
            widgets on other pages.
          </p>

          <a-button
            type="primary"
            @click="editRoute"
          >
            Edit
          </a-button>
        </template>

        <a-button>
          <template #icon>
            <CommonIcon name="ant-design:edit-outlined" />
          </template>
        </a-button>
      </a-popover>
    </div>

    <div
      v-else
      class="flex items-center gap-8"
    >
      <template
        v-for="(item, index) in languageRouteMatch"
        :key="index"
      >
        <div>/</div>

        <a-form-item class="margin-bottom-none">
          <a-input
            v-model:value="languageRouteMatch[index].value"
            :on-blur="routeUpdate"
            :disabled="item.isDynamic"
          />
        </a-form-item>
      </template>
    </div>
  </a-form-item>
</template>
