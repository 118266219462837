import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const SETTINGS_ITEMS = [
    {
      label: "Title settings",
      value: "title",
      isActiveValueSource: fields.value.title.field.options,
    },
    {
      label: "Tabs settings",
      value: "tabs",
    },
    {
      label: "Bonus Group",
      value: "bonus_group",
    },
  ];

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Tabs", "tabs"),
    generateDropdownItem("Bonus Group", "bonus_group"),
    generateDropdownItem("Group Title", "bonus_group_title", "group"),
    generateDropdownItem(
      "Group Description",
      "bonus_group_description",
      "group"
    ),
    generateDropdownItem("Bonus Card", "cards"),
    generateDropdownItem("Bonus Title", "bonus_title", "card"),
    generateDropdownItem("Status", "status", "card"),
    generateDropdownItem("Dates", "dates", "card"),
    generateDropdownItem("Description", "description", "card"),
    generateDropdownItem("Progress bar", "progress_bar", "card"),
    generateDropdownItem("Amounts", "amounts", "card"),
    generateDropdownItem("Terms and conditions", "terms_title", "card"),
    generateDropdownItem("Button", "action_button", "card"),
    generateDropdownItem("Photo", "image", "card"),
    generateDropdownItem("Modal", "modal"),
    generateDropdownItem("Title", "modal_title", "modal"),
    generateDropdownItem("Description", "modal_description", "modal"),
    generateDropdownItem("Primary button", "modal_primary_button", "modal"),
    generateDropdownItem("Secondary button", "modal_secondary_button", "modal"),
    generateDropdownItem("Close button", "modal_close_icon", "modal"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bonus_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    action_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    modal: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    modal_primary_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    modal_secondary_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    modal_close_icon: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...DEFAULT_DROPDOWN_ITEMS];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
