<template>
  <div>
    <WidgetField
      :widget="widget"
      :field="fields.cards_container.field"
    >
      <div
        class="betslip-widget__cards"
        :style="[betslipCardsContainerInlineStyles, elementsGap]"
      >
        <div class="betslip-widget__cards-header w-100">
          <div class="betslip-widget__cards-header-labels">
            <WidgetField
              v-if="
                currNotAvaiableField.options._active &&
                states[widget.name] === State.BETSLIP
              "
              :widget="widget"
              :field="currNotAvaiableField"
            >
              <div
                :style="[notAvailableMarginStyles, notAvailableInlineStyles]"
              >
                Not available
              </div>
            </WidgetField>

            <WidgetField
              v-if="
                states[widget.name] === State.MY_BETS ||
                states[widget.name] === State.BETS_RESULT
              "
              :widget="widget"
              :field="fields.bets_type_title.field"
            >
              <div :style="[betsTypeMarginStyles, betsTypeInlineStyles]">
                {{ betType || "Bets type" }}
              </div>
            </WidgetField>

            <WidgetField
              v-if="fields.live_status_title.active"
              :widget="widget"
              :field="fields.live_status_title.field"
            >
              <div :style="[liveMarginStyles, liveInlineStyles]">
                {{ t("widgets.BetslipWidget.live") }}
              </div>
            </WidgetField>
          </div>

          <WidgetField
            v-if="
              fields.close_icon.active && states[widget.name] === State.BETSLIP
            "
            :widget="widget"
            :field="fields.close_icon.field"
          >
            <CommonIcon
              :style="closeIconInlineStyles"
              name="ant-design:close"
            />
          </WidgetField>

          <WidgetField
            v-if="
              states[widget.name] === State.MY_BETS ||
              states[widget.name] === State.BETS_RESULT
            "
            :widget="widget"
            :field="fields.time_title.field"
          >
            <div :style="timeFieldInlineStyles">00:00 00.00</div>
          </WidgetField>
        </div>

        <div class="betslip-widget__cards-body w-100">
          <WidgetField
            v-if="
              states[widget.name] === State.BETS_RESULT &&
              fields.bets_result_system_name?.field &&
              isSystem
            "
            :widget="widget"
            :field="fields.bets_result_system_name.field"
          >
            <BasicTitleField
              :field="fields.bets_result_system_name.field"
              value="System bet name"
            />
          </WidgetField>

          <WidgetField
            v-if="states[widget.name] !== State.NO_BETS"
            :widget="widget"
            :field="fields.event_container.field"
            :style="eventContainerInlineStyles"
          >
            <BetslipCardBodyField
              :widget="widget"
              :fields="fields"
              :states="states"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.each_way_bet.field"
          >
            <BetslipEachWayBet
              :widget="widget"
              :field="fields.each_way_bet.field"
              :checkbox-field="fields.each_way_bet_checkbox.field"
              :tooltip-field="fields.each_way_bet_tooltip.field"
              :states="states"
            />
          </WidgetField>

          <WidgetField
            v-if="
              states[widget.name] === State.MY_BETS ||
              states[widget.name] === State.BETS_RESULT
            "
            :widget="widget"
            :style="cardGapStyle"
            :field="fields.bet_amounts_container.field"
          >
            <div
              class="betslip-widget__total-amounts-container"
              :class="[
                `betslip-widget__total-amounts-container--${fields.bet_amounts_container.field.options.position}`,
              ]"
              :style="betAmountContainerInlineStyles"
            >
              <div class="betslip-widget__result-total-amounts-element">
                <span :style="betAmountLabelInlineStyles">{{
                  t("widgets.BetslipWidget.bet_amount")
                }}</span>
                <span :style="betAmountValueInlineStyles">0,00</span>
              </div>
              <div class="betslip-widget__result-total-amounts-element">
                <span :style="betAmountLabelInlineStyles">
                  {{ t("widgets.BetslipWidget.possible_refund") }}
                </span>
                <span :style="betAmountValueInlineStyles">0,00</span>
              </div>
            </div>
          </WidgetField>

          <WidgetField
            v-if="
              fields.my_bets_warning_message.active &&
              states[widget.name] === State.MY_BETS
            "
            :widget="widget"
            :style="cardGapStyle"
            :field="fields.my_bets_warning_message.field"
          >
            <div
              class="betslip-widget__result-warning-message"
              :style="myBetsWarningMessageContainerInlineStyles"
            >
              <CommonIcon
                v-if="
                  fields.my_bets_warning_message.field.options.iconSettings
                    ._active
                "
                :style="myBetsWarningIconInlineStyles"
                name="ant-design:info-circle-filled"
              />
              <span>Warning message</span>
            </div>
          </WidgetField>

          <div
            v-if="states[widget.name] === State.MY_BETS"
            class="betslip-widget__result-buttons-container-field w-100"
            :style="cardGapStyle"
          >
            <WidgetField
              v-if="fields.my_bets_primary_button.field.options._active"
              :widget="widget"
              class="betslip-widget__result-button-field"
              :style="primaryButtonInlineMargin"
              :field="fields.my_bets_primary_button.field"
            >
              <BasicTextButtonField
                :field="fields.my_bets_primary_button.field"
                :current-state="states.my_bets_primary_button"
                :value="t('widgets.BetslipWidget.my_bets_primary_button')"
                :is-button-text-settings="true"
              />
            </WidgetField>

            <WidgetField
              v-if="fields.my_bets_secondary_button.field.options._active"
              :widget="widget"
              class="betslip-widget__result-button-field"
              :style="secondaryButtonInlineMargin"
              :field="fields.my_bets_secondary_button.field"
            >
              <BasicTextButtonField
                :field="fields.my_bets_secondary_button.field"
                :current-state="states.my_bets_secondary_button"
                :value="t('widgets.BetslipWidget.my_bets_secondary_button')"
                :is-button-text-settings="true"
              />
            </WidgetField>
          </div>
        </div>
      </div>
    </WidgetField>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useBetslipCardsInlineStyles } from "~~/composables/widgets/betslip/inline-styles/useBetslipCardsInlineStyles";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: any;
  states: Record<string, State>;
  notAvailableField?: IWidgetField;
  betType?: string;
  isSystem?: boolean;
}>();

const { t } = useI18n();

const currNotAvaiableField = computed<IWidgetField>(() => {
  if (props.notAvailableField) {
    return props.notAvailableField;
  }

  return props.fields.not_available_status_title.field;
});

const {
  myBetsWarningIconInlineStyles,
  betslipCardsContainerInlineStyles,
  notAvailableMarginStyles,
  betsTypeMarginStyles,
  notAvailableInlineStyles,
  secondaryButtonInlineMargin,
  primaryButtonInlineMargin,
  betsTypeInlineStyles,
  myBetsWarningMessageContainerInlineStyles,
  betAmountValueInlineStyles,
  liveInlineStyles,
  liveMarginStyles,
  closeIconInlineStyles,
  timeFieldInlineStyles,
  betAmountContainerInlineStyles,
  betAmountLabelInlineStyles,
  eventContainerInlineStyles,
} = useBetslipCardsInlineStyles(
  ref(props.fields),
  ref(props.states),
  currNotAvaiableField
);

const cardGapStyle = computed<ElementStyle>(() => {
  const options = props.fields.cards_container.field.options;

  return {
    marginTop: getPxValueFromNumber(options.display?.distance),
  };
});

const elementsGap = computed<ElementStyle>(() => {
  const options = props.fields.cards_container.field.options;
  return {
    gap: getPxValueFromNumber(options.display?.distance),
  };
});
</script>

<style lang="scss">
.betslip-widget {
  &__cards {
    @include flex-column(center, center, 0px);
  }

  &__cards-header {
    @include flex(center, space-between, 0px);
  }

  &__cards-header-labels {
    @include flex(center, center, 8px);
  }

  &__cards-coef-container {
    @include flex(center, space-between, 8px);
  }

  &__coefficient-field {
    position: relative;
  }

  &__coefficient {
    &--show-triangle {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: -10px;
        top: -4px;
        width: 27px;
        height: 15px;
        background-color: var(--color);
        z-index: 2;
        transform: rotate(45deg);
      }
    }

    &--triangle-down {
      &::after {
        transform: rotate(-45deg);
        top: initial;
        bottom: -7px;
      }
    }
  }
}
</style>
