import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useBetItemsConfig } from "~~/composables/widgets/sportsbook/useBetItemsConfig";

import type { IFields } from "../useWidgetFields";

export const useSportEventFenixMarketsConfig = (
  fields: Ref<IFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    Markets
  */

  const marketsContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.display.layout",
            valueSource: fields.value.markets_container.field,
            className: "group-control-element event-widget__market-cards-radio",
            options: {
              shrink: true,
              label: "Market cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.distance",
            valueSource: fields.value.markets_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Market cards",
              value: "markets_cards",
            },
          ],
        },
      },
    ];
  });

  /* 
    Market cards
  */

  const marketCardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.markets_cards.field,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: 1,
                },
                {
                  label: "Second",
                  value: 2,
                },
                {
                  label: "Third",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            options: {
              label: "Distance between card elements",
              showIconPrefix: false,
              isBold: true,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Market name",
              value: "markets_market_name",
            },
            {
              label: "Status",
              value: "markets_status",
            },
            {
              label: "Pin icon",
              value: "markets_pin_icon",
            },
            {
              label: "Bet items",
              value: "bet_items",
            },
          ],
        },
      },
    ];
  });

  /* 
    Market name
  */

  const marketNameControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.markets_market_name.field,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.markets_market_name.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    market status
  */

  const marketSuspendedStatusDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.markets_status.field,
      except: ["ConfigBoxShadow"],
    });
  });

  const marketSuspendedStatusContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.markets_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /* 
    Pin icon
  */

  const pinIconControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: fields.value.markets_pin_icon.field,
        className: "border-bottom-none p-b-0",
        options: {
          label: "Icon color",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        valueSource: fields.value.markets_pin_icon.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.markets_pin_icon.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Bet items
  */

  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useBetItemsConfig(states, emit, true);
  /* ======================== */

  return {
    marketsContainerContentControls,

    marketCardsContentControls,

    marketNameControls,

    marketSuspendedStatusDesignControls,

    marketSuspendedStatusContentControls,

    pinIconControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  };
};
