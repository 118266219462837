import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 15;

const v15WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["RacingSportsEventLiveWidget"].includes(widget.name)) {
    if ("players_info_header" in widget.content) {
      if (widget.content.players_info_header.options.padding) {
        delete widget.content.players_info_header.options.padding;
      }
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v15WidgetMigration;
