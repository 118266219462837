import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetBindingParamsObject,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import {
  ARROW_INITIAL,
  TABS_INITIAL,
} from "~~/constants/configs/bets-list-common/initial-contants";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { parentCell } = useWidthConfig();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    event_cards: State.WITH_ACTIVE_EVENTS,
    event_cards_item: State.DEFAULT,
    tabs: State.DEFAULT,
    arrow: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {
      maxEvents: {
        source: "custom",
        value: "5",
      },
      sorting: {
        source: "custom",
        value: "by_popular",
      },
      eventsInterval: {
        source: "custom",
        value: "24",
      },
      sportIds: {
        source: "custom",
        value: [],
      },
    } as unknown as IWidgetBindingParamsObject,
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialNoEventsTitleOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(13),
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialTeamsNameTitleOptions = prefillEmptyOptions(
      fields.value.teams_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(13),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      }).options
    );

    const initialTabsContainerOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: TABS_INITIAL(),
        additionalData: {
          options: {
            container: {},
            text: {
              _active: true,
            },
          },
        },
      }).options
    );

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.event_cards.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distance: 16,
              displayMode: DisplayOrientation.HORIZONTAL,
            },
            states: {
              [State.HOVER]: {
                fill: getDefaultFillValue("#ffffff"),
                border: getDefaultBorderValue(),
                shadow: getDefaultShadow(),
              },
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            columns: 1,
            distanceBetweenCards: 16,
          },
        },
      }).options
    );

    const initialTimeDateOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            width: getDefaultSizeValue(ResizingType.HUG),
            icon: {
              _active: true,
              _activeTime: true,
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
              valueDate: "ant-design:calendar-filled",
              valueTime: "ant-design:clock-circle-filled",
            },
            displayMode: "date",
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialArrowOptions = prefillEmptyOptions(
      fields.value.arrow.field.options,
      getInitialOptions({
        data: ARROW_INITIAL(),
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_cards.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.teams_name.field,
      initialTeamsNameTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.arrow.field,
      initialArrowOptions
    );
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
