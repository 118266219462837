import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateImageCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";

export const generateFormFailCssString =
  (widget: IPageContentWidget) => (cssString: string) => {
    const failImage = widget.content["fail_image"];
    const failButton = widget.content["fail_button"];
    const failDescription = widget.content["fail_description_1"];
    const failTitle = widget.content["fail_title"];

    failButton.options.iconSettings._cssClass =
      generateClassName("failButtonIcon");
    failImage.options._cssClass = generateClassName("failImage");
    failImage.options._cssClass = generateClassName("failImage");
    failButton.options._cssClass = generateClassName("failButton");
    failDescription.options._cssClass = generateClassName("failDescription");
    failTitle.options._cssClass = generateClassName("failTitle");
    failButton.options.iconSettings._cssClass = generateClassName("buttonIcon");

    return pipeSync<string>(
      generateImageCssString(failImage as IWidgetField),
      generateStringWithStates(failTitle, true),
      generateStringDefault(failDescription),
      generateStageButtonCssString(failButton as IWidgetField)
    )(cssString);
  };
