import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  Alignment,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ICustomField,
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  getDefaultFillImage,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { COMING_EVENTS_ITEMS_GROUP_INITIAL } from "~~/constants/configs/items/initial-data";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { FieldType } from "~~/models/widgets/widget.core/field-types.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();
  const { initWidgetOptions } = useCreateFields(widget);

  const { parentCell } = useWidthConfig();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const arrayField = computed<IWidgetField | null>(() => {
    return widget.fields.find(field => field.name === FieldName.ITEMS) || null;
  });

  const isWidgetSelected = computed<boolean>(
    () => widget.id === selectedWidget.value?.id
  );

  const isItemsGroupSelected = computed<boolean>(
    () =>
      isWidgetSelected.value &&
      selectedField.value?.name === FieldName.ITEMS_GROUP
  );

  const displaySettings = computed<IWidgetOptions>(
    () =>
      widget.options.display || {
        alignment: Alignment.LEFT,
        resizing: ResizingType.HUG,
      }
  );

  const menuItemsOptions = computed<IWidgetOptions>(() => ({
    ...itemsGroup.value?.options,
    display: displaySettings.value,
  }));

  const ITEM_INITIAL = () => ({
    options: {
      _active: true,
      text: {
        _active: true,
      },
      icon: {
        _active: false,
        value: "ant-design:arrow-right-outlined",
      },
      fill: {
        color: "#000000",
        opacity: 100,
      },
    },
  });

  const itemsGroupFieldExists = (
    parentField: ICustomField[] | null
  ): boolean => {
    if (!parentField) {
      return false;
    }

    if (!Array.isArray(parentField)) {
      return false;
    }

    const field = parentField.find(item => item.name === FieldName.ITEMS_GROUP);

    return !!field;
  };

  const initItemsGroup = () => {
    const itemsGroupExists = itemsGroupFieldExists(
      arrayField.value?.value as ICustomField[] | null
    );

    if (itemsGroupExists) {
      return;
    }

    arrayField.value!.value = [
      {
        ...generateWidgetField(FieldName.ITEMS_GROUP, widget.id),
        fields: [
          generateWidgetField(
            FieldName.LIVE,
            widget.id,
            FieldType.TEXT_INPUT,
            "Live",
            "Live"
          ),
          generateWidgetField(
            FieldName.COMING_EVENTS,
            widget.id,
            FieldType.TEXT_INPUT,
            "Coming Events",
            "Coming Events"
          ),
        ],
        options: {},
      },
    ] as ICustomField[];
  };

  const itemsGroup = computed<ICustomField | null>(() => {
    if (!arrayField.value?.value) {
      return null;
    }

    if (!Array.isArray(arrayField.value.value)) {
      return null;
    }

    return arrayField.value.value[0] || null;
  });

  const itemsFields = computed<IWidgetField[]>(() => {
    return itemsGroup.value?.fields || [];
  });

  const DEFAULT_STATES = {
    items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    items: State.DEFAULT,
  });

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage({
      value: getDefaultFillValue("#1677FF"),
      position: {
        x: "",
        y: "",
        value: "",
      },
    }),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  initItemsGroup();

  const initWidgetFieldsOptions = (): void => {
    const initialItemsGroupOptions = prefillEmptyOptions(
      itemsGroup.value!.options,
      COMING_EVENTS_ITEMS_GROUP_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      itemsGroup.value!,
      initialItemsGroupOptions
    );

    itemsFields.value.forEach(itemField => {
      widgetsStore.updateFieldOptions(
        itemField,
        prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
      );
    });
  };

  const initFields = (): void => {
    if (!widget.fields.length) {
      createFields();
    } else {
      widgetsStore.updateWidgetFields(widget, [...widget.fields]);
    }

    initWidgetFieldsOptions();
  };

  widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,

    itemsFields,
    isItemsGroupSelected,
    itemsGroup,
    menuItemsOptions,
    displaySettings,
  };
};
