<template>
  <div class="sport-event-view-mode-switch">
    <div class="sport-event-view-mode-switch__header-tabs">
      <div
        :style="tabsStyles"
        class="sport-event-view-mode-switch__tabs-element"
      >
        <BasicIconField
          v-if="streamIconName"
          :options="iconStyleOptions"
          :value="streamIconName"
        />
      </div>

      <div
        :style="tabsStyles"
        class="sport-event-view-mode-switch__tabs-element"
      >
        <BasicIconField
          v-if="statsIconName"
          :options="iconStyleOptions"
          :value="statsIconName"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElementStyle } from "~~/models/common";
import { State } from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  state: string;
  field: IWidgetField;
}>();

const tabsOptions = computed<IWidgetOptions>(() => {
  return props.field.options;
});

const activeTabsStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return tabsOptions.value;
  }

  return tabsOptions.value.states[props.state];
});

const streamIconName = computed<string>(() => {
  return props.field.options.icon.streamIconValue || "";
});

const statsIconName = computed<string>(() => {
  return props.field.options.icon.statsIconValue || "";
});

const iconStyleOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options.icon;
  }

  return {
    ...props.field.options.icon,
    ...props.field.options.states[props.state].icon,
  };
});

const tabsStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles({
      ...tabsOptions.value,
      ...activeTabsStateOptions.value,
    }),
  };
});
</script>

<style lang="scss">
.sport-event-view-mode-switch {
  &__header-tabs {
    @include flex(center, flex-start, 0px);
  }

  &__tabs-element {
    @include flex(center, center, 8px);
  }
}
</style>
