type ValueType = number | string | null | undefined;
type HtmlInputWithKeydownHandler = HTMLElement & {
  _handleKeyDown?: (event: KeyboardEvent) => void;
};

const isSystemKey = (e: KeyboardEvent): boolean => {
  if (e.key === "Backspace" || e.key === "Delete") {
    return true;
  }

  // Allow common keys combinations, like Ctrl|CMD + A, Ctrl|CMD + C etc.
  if (
    (e.ctrlKey || e.metaKey) &&
    ["a", "c", "x", "z", "v"].includes(e.key.toLowerCase())
  ) {
    return true;
  }

  if (
    e.key === "ArrowLeft" ||
    e.key === "ArrowRight" ||
    e.key === "ArrowTop" ||
    e.key === "ArrowBottom"
  ) {
    return true;
  }

  return false;
};

const getNumberValue = (value: ValueType): number | null => {
  const asNumber = Number(value);

  if (typeof asNumber === "number" && !isNaN(asNumber)) {
    return asNumber;
  }

  return null;
};

const validateCharacter = (
  event: KeyboardEvent,
  allowNegative: boolean = false
): void => {
  const keyValue = event.key;

  /* 
    Ignore system keys
  */
  const isSystemKeyValue = isSystemKey(event);

  if (isSystemKeyValue) {
    return;
  }

  const input = event.target as HTMLInputElement;
  const value = input.value;
  const selStart = input.selectionStart;

  if (
    keyValue === "-" &&
    !value.includes("-") &&
    allowNegative &&
    selStart === 0
  ) {
    return;
  }

  if (keyValue === "." && !value.includes(".")) {
    return;
  }

  const numberValue = getNumberValue(keyValue);

  if (numberValue !== null) {
    return;
  }

  event.preventDefault();
};

const VNumber = {
  mounted(el: HTMLElement, binding: { value: { [key: string]: boolean } }) {
    const allowNegative: boolean =
      binding.value && binding.value.allowNegative ? true : false;

    const input = el.querySelector("input") as HtmlInputWithKeydownHandler;

    if (!input) {
      return;
    }

    input._handleKeyDown = (event: KeyboardEvent) => {
      validateCharacter(event, allowNegative);
    };

    input.addEventListener("keydown", input._handleKeyDown, true);
  },
  beforeUnmount(el: HTMLElement) {
    const input = el.querySelector("input") as HtmlInputWithKeydownHandler;

    if (!input) {
      return;
    }

    input.removeEventListener("keydown", input._handleKeyDown!, true);
  },
};

export default VNumber;
