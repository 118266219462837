import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { FormScreen } from "~~/models/widgets/form.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import {
  generateErrorCssString,
  generateFieldsWithStatesCssString,
  generateFormCssString,
  generateStageButtonCssString,
  generateTextChooseIconCssString,
  generateTooltipStyling,
  generateScreensCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";

export const generateLoginFormWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const form = widget.content["form"];
  const submitButton = widget.content["submit"];
  const title = widget.content["title"];
  const customFields = widget.options._customFields;
  const submitButtonClassName = generateClassName("submitButton");
  const titleClassName = generateClassName("title");
  const description1 = widget.content["description_1"];
  const description2 = widget.content["description_2"];

  // Widget class name
  widget.options._cssClass = generateClassName("LoginFormWidget");

  const screens = [FormScreen.SUCCESS];

  // Form, Label, Field, Error, Tooltip class names
  form.options._cssClass = generateClassName("form");
  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");
  description1.options._cssClass = generateClassName("description_1_");
  description2.options._cssClass = generateClassName("description_2_");

  // Submit button class name
  submitButton.options._cssClass = submitButtonClassName;
  submitButton.options.iconSettings._cssClass = generateClassName("buttonIcon");
  // customFields.buttons_styling.options._cssClass = submitButtonClassName;

  // Title class name
  title.options._cssClass = titleClassName;
  customFields.title_styling.options._cssClass = titleClassName;

  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");
  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  return pipeSync<string>(
    generateStringDefault({
      ...widget,
      options: {
        ...widget.options,
        size: null,
      },
    }),
    generateFormCssString(form, widget),
    generateScreensCssString(widget, "LoginFormWidget", screens),
    generateStringDefault(customFields.title_styling),
    generateStageButtonCssString(submitButton),
    generateTooltipStyling(customFields.tooltips_styling),
    generateTextChooseIconCssString(
      customFields.tooltips_styling.options.textChooseIcon,
      widget
    ),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    generateStringDefault(description1),
    generateStringDefault(description2)
  )("");
};
