import type { IWidgetMigration } from "../runWidgetMigrations";

import m01 from "./m.01";
import m02 from "./m.02";
import m03 from "./m.03";
import m04 from "./m.04";
import m05 from "./m.05";
import m06 from "./m.06";
import m07 from "./m.07";
import m08 from "./m.08";
import m09 from "./m.09";
import m10 from "./m.10";
import m11 from "./m.11";
import m12 from "./m.12";
import m13 from "./m.13";
import m14 from "./m.14";
import m15 from "./m.15";
import m16 from "./m.16";
import m17 from "./m.17";
import m18 from "./m.18";
import m19 from "./m.19";
import m20 from "./m.20";
import m21 from "./m.21";
import m22 from "./m.22";
import m23 from "./m.23";
import m24 from "./m.24";
import m25 from "./m.25";
import m26 from "./m.26";
import m27 from "./m.27";
import m28 from "./m.28";
import m29 from "./m.29";
import m30 from "./m.30";
import m31 from "./m.31";
import m32 from "./m.32";
import m33 from "./m.33";
import m34 from "./m.34";
import m35 from "./m.35";
import m36 from "./m.36";
import m37 from "./m.37";
import m38 from "./m.38";
import m39 from "./m.39";
import m40 from "./m.40";
import m41 from "./m.41";
import m42 from "./m.42";
import m43 from "./m.43";
import m44 from "./m.44";
import m47 from "./m.47";
import m48 from "./m.48";
import m49 from "./m.49";
import m51 from "./m.51";
import m52 from "./m.52";
import m60 from "./m.60";
import m61 from "./m.61";
import m63 from "./m.63";
import m64 from "./m.64";
import m65 from "./m.65";
import m66 from "./m.66";
import m67 from "./m.67";
import m68 from "./m.68";
import m69 from "./m.69";
import m70 from "./m.70";
import m71 from "./m.71";
import m72 from "./m.72";
import m74 from "./m.74";
import m75 from "./m.75";
import m76 from "./m.76";
import m77 from "./m.77";
import m78 from "./m.78";
import m79 from "./m.79";
import m81 from "./m.81";
/*  */
/*  */

export default <IWidgetMigration[]>[
  m01,
  m02,
  m03,
  m04,
  m05,
  m06,
  m07,
  m08,
  m09,
  m10,
  m11,
  m12,
  m13,
  m14,
  m15,
  m16,
  m17,
  m18,
  m19,
  m20,
  m21,
  m22,
  m23,
  m24,
  m25,
  m26,
  m27,
  m28,
  m29,
  m30,
  m31,
  m32,
  m33,
  m34,
  m35,
  m36,
  m37,
  m38,
  m39,
  m40,
  m41,
  m42,
  m43,
  m44,
  m47,
  m48,
  m49,
  m51,
  m52,
  m60,
  m61,
  m63,
  m64,
  m65,
  m66,
  m67,
  m68,
  m69,
  m70,
  m71,
  m72,
  m74,
  m75,
  m76,
  m77,
  m78,
  m79,
  m81,
];
