import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  State,
  TextStyle,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  getDefaultFillImage,
  getDefaultSpacing,
  getDefaultFillValue,
  DESIGN_INITIAL,
  getDefaultTheme,
  getDefaultDecoration,
  getDefaultBorderValue,
  getDefaultShadow,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import {
  CLOSE_ICON_INITIAL,
  DESCRIPTION_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { ArrowType } from "~~/constants/icons-mapping";
import {
  BUTTON_TABS_CONTAINER_INITIAL,
  BUTTON_TABS_ELEMENT_INITIAL,
} from "~~/constants/configs/common/button-tabs-initial";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useEventInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const states = ref<Record<string, State>>({
    bet_items: State.DEFAULT,
    betbuilder_header: State.DEFAULT,
    tabs: State.DEFAULT,
  });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
  });

  /* 
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          padding: getDefaultSpacing({
            bottom: 16,
          }),
        },
      }).options
    );

    const initialBannerContainerOptions = prefillEmptyOptions(
      fields.value.banner_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing({
              top: 36,
              bottom: 36,
              left: 48,
              right: 48,
            }),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialEventTitleOptions = prefillEmptyOptions(
      fields.value.event_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          decoration: [TextStyle.BOLD],
          alignment: "center",
          color: getDefaultFillValue("#ffffff"),
          textShadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000"),
            blur: 5,
          }),
        },
      }).options
    );

    const initialEventTimeOptions = prefillEmptyOptions(
      fields.value.event_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 16,
          alignment: "center",
          color: getDefaultFillValue("#ffffff"),
          textShadow: getDefaultShadow({
            fill: getDefaultFillValue("#000000"),
            blur: 5,
          }),
        },
      }).options
    );

    const initialTabsContainerOptions = prefillEmptyOptions(
      fields.value.tabs_container.field.options,
      getInitialOptions({
        data: BUTTON_TABS_CONTAINER_INITIAL(),
      }).options
    );

    const initialTabsOptions = prefillEmptyOptions(
      fields.value.tabs.field.options,
      getInitialOptions({
        data: BUTTON_TABS_ELEMENT_INITIAL(),
      }).options
    );

    const initialMarketCardOptions = prefillEmptyOptions(
      fields.value.market_cards.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distanceBetweenItems: 16,
              displayMode: DisplayOrientation.VERTICAL,
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            columns: 1,
            distanceBetweenCards: 16,
          },
        },
      }).options
    );

    const initialEventMarketNameOptions = prefillEmptyOptions(
      fields.value.market_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "alignment"],
        values: {
          theme: 16,
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialFavouriteEventMarketOptions = prefillEmptyOptions(
      fields.value.favourite_event_market.field.options,
      getInitialOptions({
        data: CLOSE_ICON_INITIAL(),
        values: {
          color: getDefaultFillValue("#1677FF"),
          iconSize: 20,
        },
      }).options
    );

    const initialMarketSuspendedStatusOptions = prefillEmptyOptions(
      fields.value.market_suspended_status.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
            margin: getDefaultSpacing({
              right: 8,
            }),
            border: getDefaultBorderValue({
              fill: getDefaultFillValue("#ffa393"),
            }),
          },
        },
        values: {
          theme: 16,
          color: {
            color: "#f62f39",
            opacity: 100,
          },
          fill: getDefaultFillValue("#fff1f0"),
          padding: getDefaultSpacing({
            top: 2,
            bottom: 2,
            left: 8,
            right: 8,
          }),
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          color: getDefaultFillValue("#1677ff"),
        },
      };
    };

    const initialMarketBetItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing(8),
            name: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
              [State.UP]: initialBetItemState(),
              [State.DOWN]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#e6f4ff"),
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        },
      }).options
    );

    const initialBetBuilderContainerOptions = prefillEmptyOptions(
      fields.value.betbuilder_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            fill: getDefaultFillValue("#ebebeb"),
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const headerState = () => {
      return {
        fill: getDefaultFillValue("1677FF"),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue("#ffffff"),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        arrowSettings: {
          textColor: getDefaultFillValue("#ffffff"),
        },
      };
    };

    const initialHeaderOptions = prefillEmptyOptions(
      fields.value.betbuilder_header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["padding"],
        additionalData: {
          options: {
            color: {
              color: "#000000",
              opacity: 100,
            },
            theme: 24,
            fontFamily: getDefaultFontFamily(),
            decoration: [],
            alignment: "left",
            arrowSettings: {
              _active: true,
              default_value: ArrowType.CaretDown,
              value: ArrowType.CaretDown,
              textColor: getDefaultFillValue("#ffffff"),
              iconSize: 14,
              rotate: true,
            },
            states: {
              [State.HOVER]: headerState(),
              [State.ACTIVE]: headerState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#1677FF"),
          color: getDefaultFillValue("#ffffff"),
          theme: getDefaultTheme(20),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          spacing: {
            fill: getDefaultFillValue("#ebebeb"),
            padding: getDefaultSpacing({
              left: 20,
              right: 20,
              top: 12,
              bottom: 12,
            }),
            margin: getDefaultSpacing({
              bottom: 0,
            }),
          },
        },
      }).options
    );

    const initialContentOptions = prefillEmptyOptions(
      fields.value.betbuilder_content_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialBuilderDescriptiontOptions = prefillEmptyOptions(
      fields.value.betbuilder_content_description.field.options,
      getInitialOptions({
        data: DESCRIPTION_INITIAL(),
        values: {
          padding: {
            top: 0,
            right: 0,
            bottom: 24,
            left: 0,
          },
        },
      }).options
    );

    const initialBuilderIframeOptions = prefillEmptyOptions(
      fields.value.betbuilder_content_iframe.field.options,
      getInitialOptions({
        data: {
          value: null,
          options: {},
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs_container.field,
      initialTabsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.tabs.field,
      initialTabsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.banner_container.field,
      initialBannerContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_title.field,
      initialEventTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_time.field,
      initialEventTimeOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.market_cards.field,
      initialMarketCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.market_name.field,
      initialEventMarketNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favourite_event_market.field,
      initialFavouriteEventMarketOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.market_suspended_status.field,
      initialMarketSuspendedStatusOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      initialMarketBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_container.field,
      initialBetBuilderContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_header.field,
      initialHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_content_container.field,
      initialContentOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_content_description.field,
      initialBuilderDescriptiontOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_content_iframe.field,
      initialBuilderIframeOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);

      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  return {
    states,

    initFields,
    initWidgetFieldsOptions,
  };
};
