<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <BookingCodeFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      class="booking-code-widget"
      :style="bookingWrapperStyles"
    >
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.booking_code.active"
        :widget="widget"
        :field="fields.booking_code.field"
      >
        <BasicTitleField :field="fields.booking_code.field">
          <div
            class="booking-code"
            :style="bookingCodeStyles"
          >
            <span>9BD30A80</span>
            <CommonIcon :name="fields.booking_code.field.options.icon.value" />
          </div>
        </BasicTitleField>
      </WidgetField>

      <WidgetField
        v-if="fields.description.active"
        :widget="widget"
        :field="fields.description.field"
      >
        <BasicTitleField
          :field="fields.description.field"
          value="Or"
        />
      </WidgetField>

      <div class="w-100">
        <WidgetField
          v-if="fields.button.active"
          :widget="widget"
          :field="fields.button.field"
        >
          <BasicButtonField
            :field="fields.button.field"
            :current-state="states.button"
            :style="linkButtonStyles"
          />
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/booking-code/useBookingCodeInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorFromHex,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const bookingWrapperStyles = computed<ElementStyle>(() => {
  const bookingCodeOptions = props.widget.options;

  if (!bookingCodeOptions) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: getFlexPosition(bookingCodeOptions.display?.alignment),
    gap: getPxValueFromNumber(bookingCodeOptions.display?.distance ?? 0),
  };
});

const bookingCodeStyles = computed<ElementStyle>(() => {
  const icon = fields.value.booking_code.field.options.icon;

  if (!icon) {
    return {};
  }

  return {
    display: "flex",
    alignItems: "center",
    gap: getPxValueFromNumber(
      fields.value.booking_code.field.options.icon.distance
    ),
    ...getStylesAsVars(
      {
        color: getColorFromHex(icon.color) as string,
        fontSize: getPxValueFromNumber(icon.size),
      },
      "icon"
    ),
  };
});

const linkButtonStyles = computed<ElementStyle>(() => {
  const display = fields.value.button.field.options.buttonDisplaySettings;

  if (!display) {
    return {};
  }

  return {
    justifyContent:
      display.resizing === ResizingType.FILL
        ? display.alignment
        : props.widget.options.display?.alignment,
  };
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

init();
</script>

<style lang="scss">
.booking-code-widget {
  position: relative;
  .booking-code {
    svg {
      color: var(--icon-color);
      font-size: var(--icon-fontSize);
    }
  }
}
</style>
