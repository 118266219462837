import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import {
  ResizingType,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import { generateStageButtonCssString } from "~~/assets/utils/widget-css/utils/form-helper-functions";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFillColorStyle,
  generateWidgetWidth,
} from "../helpers";
import { getFlexPosition } from "../../widget-settings";
import {
  generateStringDefault,
  generateStringWithStates,
  generateCustomStyles,
} from "../utils/pipe-helper-functions";
import { getPxValueFromNumber } from "../..";

export const generateCardsCssString =
  (items: IWidgetOptions, image: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const containerFlex = generateFlex({
      flex: "flex",
      direction: "column",
      align: "stretch",
      justify: "flex-start",
      gap: options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: containerFlex,
    });

    const isHorizontal = options.layout === DisplayOrientation.HORIZONTAL;

    const itemFlex = generateFlex({
      flex: "flex",
      direction: isHorizontal ? "row" : "column",
      align: isHorizontal ? "flex-start" : "stretch",
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: itemFlex,
    });

    if (isHorizontal) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: " > div:first-child",
        content: `flex-grow: 0;flex-shrink: 0;flex-basis: ${image.options.containerSize.width.value}px;`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName: " > div:last-child",
        content: "flex-grow: 2;",
      });
    }

    const alignment = options.alignment;

    const infoContainerFlex = generateFlex({
      flex: "flex",
      direction: "column",
      align: "stretch",
      justify: alignment,
      gap: "0",
    });

    cssString += generateDefaultStylesWithStates(items, false);

    cssString += generateCssClassWithContent({
      className: options._infoCssClass,
      content: infoContainerFlex,
    });

    return cssString;
  };

export const generateTabsStyles =
  (fieldDetails: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = fieldDetails;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "stretch",
          justify: options.buttonDisplaySettings.alignment,
          gap: options.distance,
        }) + "overflow: auto;",
    });

    const flex =
      options.buttonDisplaySettings.resizing === ResizingType.HUG
        ? ""
        : "flex: 1;";

    cssString += generateDefaultStylesWithStates(
      {
        options: {
          ...options,
          _cssClass: `${options._containerCssClass} button`,
          spacing: options.spacing,
        },
      },
      false
    );

    cssString += generateCssClassWithContent({
      className: `${options._cssClass} button[data-active='true']`,
      content: {
        ...options.states.active,
        spacing: {
          ...options.spacing,
          margin: null,
        },
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${options._containerCssClass} button[data-selected='true']`,
      content: {
        ...options.states.active,
        spacing: {
          ...options.spacing,
          margin: null,
        },
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + "cursor: pointer;",
    });

    if (options.buttonDisplaySettings.resizing === ResizingType.HUG) {
      cssString += generateCssClassWithContent({
        className: options._containerCssClass,
        childClassName: "base-tabs__trigger",
        content: "width: initial;",
      });
    }

    return cssString;
  };

export const generateTextCssString =
  (items: IWidgetOptions, stretchOnVertical = false) =>
  (cssString: string): string => {
    const { options } = items;

    if (options.display) {
      const flex = generateFlex({
        flex: "flex",
        align:
          stretchOnVertical &&
          options.display.layout === DisplayOrientation.VERTICAL
            ? "stretch"
            : "flex-start",
        justify: "flex-start",
        direction:
          options.display.layout === DisplayOrientation.HORIZONTAL
            ? "row"
            : "column",
        gap: options.display.distance,
      });

      cssString += generateCssClassWithContent({
        className: options._containerCssClass,
        content: flex,
      });
    }

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generateDefaultStyles(options),
    });

    cssString += generateCssClassWithContent({
      className: options._primaryCssClass,
      content: generateDefaultStyles(options.primary),
    });

    cssString += generateCssClassWithContent({
      className: options._secondaryCssClass,
      content:
        generateDefaultStyles(options.secondary) + "word-break:break-word;",
    });

    return cssString;
  };

export const generateProgressCssString =
  (items: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles({
        padding: options.padding,
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._textCssClass,
      content: generateDefaultStyles({
        ...options,
        padding: null,
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._progressCssClass,
      content:
        generateFillColorStyle(options.bar.color) +
        `height: ${getPxValueFromNumber(options.bar.size)};` +
        "border-radius: 100px;",
    });

    cssString += generateCssClassWithContent({
      className: options._bgCssClass,
      content:
        generateFillColorStyle(options.bar.fill) +
        `height: ${getPxValueFromNumber(options.bar.size)};` +
        "border-radius: 100px;",
    });

    return cssString;
  };

export const generateAmountsCssString =
  (items: IWidgetOptions, card: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const alignment = card.options.alignment;

    const containerFlex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: getFlexPosition(alignment),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        containerFlex +
        generateDefaultStyles({
          padding: options.padding,
        }),
    });

    cssString += generateCssClassWithContent({
      className: options._primaryCssClass,
      content: generateDefaultStyles(options.primary),
    });

    cssString += generateCssClassWithContent({
      className: options._secondaryCssClass,
      content: generateDefaultStyles(options.secondary),
    });

    const itemFlex = generateFlex({
      flex: "flex",
      direction: "column",
      align: getFlexPosition(alignment),
      justify: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._itemCssClass,
      content: itemFlex + "flex: 1;",
    });

    return cssString;
  };

export const generateImageCssString =
  (items: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles({
        spacing: options.spacing,
      }),
    });

    const imgCustomStyles = "";

    const { resizing, containerSize } = options;
    const objectFit = resizing === ResizingType.FIT ? "contain" : "cover";
    const width = resizing === ResizingType.FILL ? "100%" : "unset";
    const height = getPxValueFromNumber(containerSize);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " img",
      content:
        imgCustomStyles +
        generateDefaultStyles({
          ...options,
          spacing: null,
        }) +
        `object-fit: ${objectFit};` +
        `width: ${width};` +
        `height: ${height};`,
    });

    return cssString;
  };

export const generateModalCssString =
  (modal: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = modal;

    cssString += generateCssClassWithContent({
      className: options._buttonsContainerCssClass,
      content: "display: flex;",
    });

    if (options.width) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: generateWidgetWidth(options.width),
      });
    }

    return cssString;
  };

export const generateCancelBtnCssString =
  (btn: IWidgetOptions, cards: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = btn;
    const alignment = cards.options.alignment;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: `align-self: ${getFlexPosition(alignment)}`,
    });

    return cssString;
  };

export const generateStatusAlignCssString =
  (status: IWidgetOptions, cards: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = status;
    const alignment = cards.options.alignment;

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: `justify-content: ${getFlexPosition(alignment)}`,
    });

    return cssString;
  };

export const generateBonusesCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("BonusesWidget");

  const title = widget.content["title"];
  const tabs = widget.content["tabs"];
  const cards = widget.content["cards"];
  const bonus_title = widget.content["bonus_title"];
  const status = widget.content["status"];
  const dates = widget.content["dates"];
  const description = widget.content["description"];
  const progress = widget.content["progress_bar"];
  const terms_title = widget.content["terms_title"];
  const amounts = widget.content["amounts"];
  const action_button = widget.content["action_button"];
  const image = widget.content["image"];
  const no_active_bonuses = widget.content["no_active_bonuses"];

  const modal = widget.content["modal"];
  const modal_title = widget.content["modal_title"];
  const modal_description = widget.content["modal_description"];
  const modal_primary_button = widget.content["modal_primary_button"];
  const modal_secondary_button = widget.content["modal_secondary_button"];
  const modal_close_icon = widget.content["modal_close_icon"];

  no_active_bonuses.options._cssClass = generateClassName("no_active_bonuses");

  title.options._cssClass = generateClassName("title");
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options._containerCssClass = generateClassName("tabs_container");

  cards.options._cssClass = generateClassName("cards");
  cards.options._containerCssClass = generateClassName("cards_container");
  cards.options._infoCssClass = generateClassName("info_container");

  bonus_title.options._cssClass = generateClassName("bonus_title");
  image.options._cssClass = generateClassName("image");

  status.options._containerCssClass = generateClassName(
    "status_primary_container"
  );
  status.options._primaryCssClass = generateClassName("status_primary");
  status.options._secondaryCssClass = generateClassName("status_secondary");

  dates.options._containerCssClass = generateClassName(
    "dates_primary_container"
  );
  dates.options._primaryCssClass = generateClassName("dates_primary");
  dates.options._secondaryCssClass = generateClassName("dates_secondary");

  description.options._cssClass = generateClassName("description");

  progress.options._cssClass = generateClassName("progress");
  progress.options._progressCssClass = generateClassName("progress_progress");
  progress.options._bgCssClass = generateClassName("progress_bg");
  progress.options._textCssClass = generateClassName("progress_text");

  terms_title.options._cssClass = generateClassName("terms_title");

  amounts.options._cssClass = generateClassName("amounts");
  amounts.options._itemCssClass = generateClassName("amounts_item");
  amounts.options._primaryCssClass = generateClassName("amounts_item_primary");
  amounts.options._secondaryCssClass = generateClassName(
    "amounts_item_secondary"
  );

  action_button.options._cssClass = generateClassName("action_button");

  modal.options._cssClass = generateClassName("modal");
  modal.options._buttonsContainerCssClass = generateClassName(
    "modal_buttons_container"
  );
  modal_title.options._cssClass = generateClassName("modal_title");
  modal_description.options._cssClass = generateClassName("modal_description");
  modal_primary_button.options._cssClass = generateClassName(
    "modal_primary_button"
  );
  modal_secondary_button.options._cssClass = generateClassName(
    "modal_secondary_button"
  );
  modal_close_icon.options._cssClass = generateClassName("modal_close_btn");
  modal_close_icon.options.iconSettings._cssClass =
    generateClassName("modal_close_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(title),
    generateTabsStyles(tabs),
    generateCardsCssString(cards, image),
    generateStringDefault(bonus_title),
    generateStringDefault(no_active_bonuses),
    generateTextCssString(status),
    generateTextCssString(dates),
    generateStringDefault(description),
    generateProgressCssString(progress),
    generateAmountsCssString(amounts, cards),
    generateStringWithStates(terms_title, true),
    generateCustomStyles(terms_title.options._cssClass, { cursor: "pointer" }),
    generateStringWithStates(action_button, false),
    generateCancelBtnCssString(action_button, cards),
    generateImageCssString(image),
    generateStatusAlignCssString(status, cards),

    generateStringDefault(modal),
    generateStringDefault(modal_title),
    generateStringDefault(modal_description),
    generateStringWithStates(modal_primary_button, false),
    generateStringWithStates(modal_secondary_button, false),
    generateModalCssString(modal),
    generateStageButtonCssString(modal_close_icon as IWidgetField)
  )("");
};
