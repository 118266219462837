<template>
  <div
    :style="containerStyles"
    class="bonuses-widget-photo"
  >
    <slot
      name="default"
      :styles="imagesStyles"
    ></slot>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
} from "~~/assets/utils/widget-settings";

const props = defineProps<{
  field: IWidgetField;
}>();

const containerStyles = computed<ElementStyle>(() => {
  const options = {
    spacing: props.field.options.spacing || {
      padding: props.field.options.padding,
    },
  };

  return getCommonStyles(options);
});

const imagesStyles = computed<ElementStyle>(() => {
  const { resizing, containerSize } = props.field.options;
  const objectFit = resizing === ResizingType.FIT ? "contain" : "cover";
  const width = resizing === ResizingType.FILL ? "100%" : "unset";
  const height = getPxValueFromNumber(containerSize);

  return {
    objectFit,
    width,
    height,
    ...getBorderStyle(props.field.options.border),
    ...getBoxShadowStyle(props.field.options.shadow),
    ...getCornerRadiusStyle(props.field.options.cornerRadius),
  };
});
</script>

<style lang="scss">
.bonuses-widget-photo {
  @include flex(center, flex-start);
  height: 100%;
}
</style>
