<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #dropdown_container-design>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerDesignControls"
          />
        </template>

        <template #dropdown_container-content>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerContentControls"
          />
        </template>

        <template #dropdown_container-states>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerStatesControls"
          />
        </template>

        <template #dropdown_menu>
          <ControlsList
            :widget="widget"
            :controls="dropdownMenuControls"
          />
        </template>

        <template #search_field-design>
          <ControlsList
            :widget="widget"
            :controls="searchFieldDesignControls"
          />
        </template>

        <template #search_field-content>
          <ControlsList
            :widget="widget"
            :controls="searchFieldContentControls"
          />
        </template>

        <template #search_field-states>
          <ControlsList
            :widget="widget"
            :controls="searchFieldStatesControls"
          />
        </template>

        <template #menu_items-design>
          <ControlsList
            :widget="widget"
            :controls="menuItemsDesignControls"
          />
        </template>

        <template #menu_items-content>
          <ControlsList
            :widget="widget"
            :controls="menuItemsContentControls"
          />
        </template>

        <template #menu_items-states>
          <ControlsList
            :widget="widget"
            :controls="menuItemsStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useTimezonesConfig } from "~~/composables/widgets/timezones/useTimezonesConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  dropdownContainerDesignControls,
  dropdownContainerContentControls,
  dropdownContainerStatesControls,

  dropdownMenuControls,

  searchFieldDesignControls,
  searchFieldContentControls,
  searchFieldStatesControls,

  menuItemsDesignControls,
  menuItemsContentControls,
  menuItemsStatesControls,
} = useTimezonesConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
