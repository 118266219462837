export enum CardsDisplayMode {
  Grid = "Grid",
  Carousel = "Carousel",
}

export enum EventInfoVariant {
  Variant1 = "Variant1",
  Variant2 = "Variant2",
  Variant3 = "Variant3",
  Variant4 = "Variant4",
  Variant5 = "Variant5",
}

export enum EventInfoDirection {
  Default = "Default",
  Reverse = "Reverse",
}
