import { Ref } from "vue";

import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import {
  Alignment,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";

export const useItemsGroupControls = (
  itemsGroup: Ref<IWidgetField | null>,
  itemsGroupState: Ref<State>,
  emit: any
) => {
  const itemsGroupDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: itemsGroup.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: itemsGroup.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: itemsGroup.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const itemsGroupContentControls = computed(() => {
    const isElementsHorizontal =
      itemsGroup.value?.options.display?.elementsLayout ===
      DisplayOrientation.HORIZONTAL;
    return [
      ...getInitialContentControlsList({
        source: itemsGroup.value,
      }),
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: itemsGroup.value,
            options: {
              label: "Items layout",
              items: [
                {
                  label: "Vertiсal",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.spaceBetween",
            valueSource: itemsGroup.value,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.elementsLayout",
            valueSource: itemsGroup.value,
            options: {
              label: "Item elements layout",
              items: [
                {
                  label: "Vertiсal",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigCommonRadio",
            valuePath: "options.itemsIcon.position",
            valueSource: itemsGroup.value,
            className: "group-control-element--child",
            options: {
              label: "Position",
              firstVariant: "",
              firstVariantIcon: {
                className: isElementsHorizontal ? "" : "r-90",
                icon: "custom:unordered-list-outlined",
              },
              firstValue: Alignment.LEFT,
              secondaryVariant: "",
              secondaryVariantIcon: {
                className: isElementsHorizontal ? "r-180" : "rn-90",
                icon: "custom:unordered-list-outlined",
              },
              secondaryValue: Alignment.RIGHT,
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.itemsIcon.gap",
            valueSource: itemsGroup.value,
            options: {
              label: "Distance between item elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigArrayItemsText",
        valuePath: `options.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
        },
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.itemsIcon.textColor`,
            valueSource: itemsGroup.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.itemsIcon.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: itemsGroup.value,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  const itemsGroupSettingsValuePath = computed<string>(() => {
    if (itemsGroupState.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${itemsGroupState.value}`;
  });

  const itemsGroupStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: itemsGroupState.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-items-group-state", value);
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${itemsGroupSettingsValuePath.value}.fillImageColor`,
        valueSource: itemsGroup.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${itemsGroupSettingsValuePath.value}.border`,
        valueSource: itemsGroup.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${itemsGroupSettingsValuePath.value}.shadow`,
        valueSource: itemsGroup.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigDropdownContainerText",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsText`,
        valueSource: itemsGroup.value,
        options: {
          label: "Text",
          isStatesTab: true,
        },
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: `${itemsGroupSettingsValuePath.value}.itemsIcon`,
        valueSource: itemsGroup.value,
        options: {
          label: "Icon",
          isStatesTab: true,
        },
      },
    ];
  });

  return {
    itemsGroupDesignControls,
    itemsGroupContentControls,
    itemsGroupStatesControls,
  };
};
