<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!--All controls in right sidebar-->
    <ButtonWidgetSettings
      :widget="widget"
      :button-state="states.button"
      @update-button-state="
        handleStateUpdate({ state: 'button', value: $event })
      "
      @reset-states="resetStates"
    />

    <div class="button-widget">
      <WidgetField
        :widget="widget"
        :field="fields.button.field"
      >
        <BasicButtonField
          :field="fields.button.field"
          :current-state="states.button"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useButtonWidgetInit } from "~~/composables/widgets/button/useButtonWidgetInit";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const {
  states,
  fields,

  init,
  handleStateUpdate,
  resetStates,
} = useButtonWidgetInit(toRef(props.widget));

init();
</script>

<style lang="scss">
.button-widget {
  position: relative;
}
</style>
