import { CSSProperties } from "vue";

import { Align, Display } from "~~/models/widgets/widget-controls.model";
import {
  getColorValue,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";

import { clearObject } from "../../PromoBetsFenixWidgetHelpers";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useOutcomesStyles = (outcomesOptions: any = {}) => {
  const outcomesStyles = (): CSSProperties => {
    return {
      ...useDesignInitialStyles(outcomesOptions),

      display: Display.FLEX,
      flex: "1",
      alignItems: Align.MIDDLE,
      justifyContent: "space-between",
    };
  };

  const outcomesTextNameStyles = (): CSSProperties => {
    const styles = {
      color: outcomesOptions.textName?.color
        ? getColorValue(outcomesOptions.textName?.color)
        : undefined,

      ...(outcomesOptions.textName?.theme
        ? getFontSizeStyle(outcomesOptions.textName?.theme)
        : {}),
      ...(outcomesOptions.textName?.decoration
        ? getDecorationValue(outcomesOptions.textName?.decoration)
        : {}),
      ...(outcomesOptions.fontFamily
        ? getFontFamilyStyle(outcomesOptions.fontFamily)
        : {}),
    };
    return clearObject(styles);
  };

  const outcomesTextCoefficientStyles = (): CSSProperties => {
    const styles = {
      color: outcomesOptions.textCoefficient?.color
        ? getColorValue(outcomesOptions.textCoefficient?.color)
        : undefined,

      ...(outcomesOptions.textCoefficient?.theme
        ? getFontSizeStyle(outcomesOptions.textCoefficient?.theme)
        : {}),
      ...(outcomesOptions.textCoefficient?.decoration
        ? getDecorationValue(outcomesOptions.textCoefficient?.decoration)
        : {}),
      ...(outcomesOptions.fontFamily
        ? getFontFamilyStyle(outcomesOptions.fontFamily)
        : {}),
    };
    return clearObject(styles);
  };

  return {
    outcomesStyles,
    outcomesTextNameStyles,
    outcomesTextCoefficientStyles,
  };
};
