<template>
  <WidgetField
    :widget="widget"
    :field="field"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="field"
      :options="stateOptions"
    >
      <div :style="sportTitle.containerStyles">
        <CommonIcon
          v-if="titleIconVisibility"
          :name="sportIcon"
          :style="sportIconStyles"
        />
        <BasicTitleField
          :field="field"
          :state="state"
          :options="sportTitle.options"
        >
          <slot></slot>
        </BasicTitleField>
        <WidgetField
          v-if="buttonFieldVisibility"
          :widget="widget"
          :field="buttonField"
          :style="buttonStyles"
        >
          <BasicButtonField
            :field="buttonField"
            :current-state="buttonFieldState"
          />
        </WidgetField>

        <WidgetField
          v-if="buttonHoverFieldVisibility"
          :widget="widget"
          :field="hoverButtonField"
          :style="hoverButtonStyles"
        >
          <BasicButtonField
            :field="hoverButtonField"
            :current-state="hoverButtonFieldState"
            custom-value="Other events of the sport"
          />
        </WidgetField>
      </div>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  buttonField: IWidgetField;
  hoverButtonField: IWidgetField;
  state: State;
  sportsState: State;
  buttonFieldState: State;
  hoverButtonFieldState: State;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);

const stateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return {
    ...props.field.options,
    ...props.field.options.states[props.state],
  };
});

const sportIcon = computed(
  () =>
    props.field.options.icon?.value ||
    props.field.options.iconSettings?.value ||
    "ion:basketball-sharp"
);

const sportIconStyles = computed<ElementStyle>(() => {
  const { icon } = props.field.options;
  if (!icon) {
    return {};
  }
  return {
    ...getColorStyle(stateOptions.value.icon.textColor),
    ...getFontSizeStyle(icon.iconSize),
  };
});

const sportTitle = computed(() => {
  const settings = props.field?.options;
  if (!settings) {
    return {};
  }
  return {
    containerStyles: {
      display: "flex",
      alignItems: "center",
      columnGap: getPxValueFromNumber(settings.distanceBetweenItems),
    },
    options: { ...settings, padding: null },
  };
});

const hoverButtonStyles = computed<ElementStyle>(() => {
  const settings = props.hoverButtonField?.options;
  const style: Record<string, string> = {};
  if (!settings) {
    return {};
  }

  if (settings.buttonDisplaySettings?.resizing === ResizingType.FILL) {
    style.width = "100%";
    style.justifyContent = settings.buttonDisplaySettings.alignment;
  }

  return {
    ...style,
  };
});

const buttonStyles = computed<ElementStyle>(() => {
  const settings = props.buttonField?.options;
  const style: Record<string, string> = {};
  if (!settings) {
    return {};
  }

  if (settings.buttonDisplaySettings?.resizing === ResizingType.FILL) {
    style.width = "100%";
    style.justifyContent = settings.buttonDisplaySettings.alignment;
  }

  return {
    ...style,
  };
});

const titleIconVisibility = computed<boolean | undefined>(
  () => props.field.options.icon._active
);

const buttonFieldVisibility = computed<boolean | undefined>(
  () => props.buttonField.options._active
);

const buttonHoverFieldVisibility = computed<boolean | undefined>(
  () =>
    (props.sportsState === State.HOVER &&
      props.hoverButtonField.options._active) ||
    selectedField.value?.name === props.hoverButtonField.name
);
</script>

<style lang="scss">
.sportsbook-live {
  &__event-info {
    @include flex(center, flex-start, 0);

    &-item {
      @include flex(center, space-between, 0);
      flex: 1;
    }

    &-score {
      display: inline-block;
      padding: 4px 15px;
      background-color: #4096ff;
      color: #fff;
    }

    &-text-container {
      flex: 1;

      span {
        display: inline-block;
        width: 100%;
      }
    }

    &--row {
      .sportsbook-live__event-info-item:nth-child(2) {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
