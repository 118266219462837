<template>
  <div
    :style="style"
    class="promoted-bet-list-widget__promo-container"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  field: IWidgetField;
}>();

const style = computed<ElementStyle>(() => {
  return {
    "grid-template-columns": `repeat(${props.field.options.display.layout}, 1fr)`,
    "column-gap": getPxValueFromNumber(props.field.options.display.distance),
    "row-gap": getPxValueFromNumber(props.field.options.display.distance),
  };
});
</script>

<style lang="scss">
.promoted-bet-list-widget {
  &__promo-container {
    display: grid;
  }
}
</style>
