<template>
  <div
    :style="headerTabsInlineStyles"
    class="racing-button-tabs__header-tabs"
  >
    <WidgetField
      :widget="widget"
      :field="tabsField"
      :style="tabsStyles"
    >
      <div class="racing-button-tabs__tabs-element">
        <CommonIcon
          v-if="streamIconName"
          :name="streamIconName"
          :style="headerTabIconInlineStyle"
        /></div
    ></WidgetField>

    <WidgetField
      :widget="widget"
      :field="tabsField"
      :style="tabsStyles"
    >
      <div class="racing-button-tabs__tabs-element">
        <CommonIcon
          v-if="statsIconName"
          :name="statsIconName"
          :style="headerTabIconInlineStyle"
        />
      </div>
    </WidgetField>
  </div>
</template>

<script setup lang="ts">
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { getColorFromHex } from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import {
  State,
  ResizingType,
  Alignment,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  containerField: IWidgetField;
  tabsField: IWidgetField;
}>();

const tabsContainerOptions = computed<IWidgetOptions>(() => {
  return props.containerField.options;
});

const tabsOptions = computed<IWidgetOptions>(() => {
  return props.tabsField.options;
});

const activeTabsStateOptions = computed<IWidgetOptions>(() => {
  if (props.states.tabs === State.DEFAULT) {
    return tabsOptions.value;
  }

  return tabsOptions.value.states[props.states.tabs];
});

const streamIconName = computed<string>(() => {
  return props.tabsField.options.streamIcon || "";
});

const statsIconName = computed<string>(() => {
  return props.tabsField.options.statsIcon || "";
});

const tabsStyles = computed<ElementStyle>(() => {
  const isFill =
    tabsContainerOptions.value.displaySettings.resizing === ResizingType.FILL;

  return {
    ...getCommonStyles({
      ...tabsOptions.value,
      ...activeTabsStateOptions.value,
    }),
    width: isFill ? "100%" : "auto",
    flexShrink: isFill ? "1" : "0",
    whiteSpace: "nowrap",
    justifyContent: isFill
      ? Alignment.CENTER
      : generateFlexAlignment(
          tabsContainerOptions.value.displaySettings.alignment
        ),
  };
});

const headerTabIconInlineStyle = computed<ElementStyle>(() => {
  return {
    color: getColorFromHex(
      activeTabsStateOptions.value?.iconSettings?.textColor
    )!,
    fontSize: getPxValueFromNumber(tabsOptions.value.iconSettings?.iconSize),
  };
});

const headerTabsInlineStyles = computed<ElementStyle>(() => {
  const isFill =
    tabsContainerOptions.value.displaySettings.resizing === ResizingType.FILL;

  return {
    ...getCommonStyles(tabsContainerOptions.value),
    gap: getPxValueFromNumber(
      tabsContainerOptions.value.displaySettings.distance
    ),
    justifyContent: isFill
      ? ""
      : generateFlexAlignment(
          tabsContainerOptions.value.displaySettings.alignment
        ),
    overflow: "auto",
  };
});
</script>

<style lang="scss">
.racing-button-tabs {
  &__header-tabs {
    @include flex(center, flex-start, 0px);
  }

  &__tabs-element {
    @include flex(center, center, 8px);
  }
}
</style>
