import {
  generateWidgetField,
  prefillEmptyOptions,
} from "~~/assets/utils/widget";
import { reorderListElements } from "~~/assets/utils/widget-settings";
import { ITEM_INITIAL } from "~~/constants/configs/dropdown/initial-data";
import {
  DROPDOWN_MENU_MAX_ITEMS_COUNT,
  DROPDOWN_MENU_MIN_ITEMS_COUNT,
} from "~~/constants/widget-config";
import { IInsertPosition } from "~~/models/grid.interface";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import type {
  ICustomField,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";

const widgetsStore = useWidgetsStore();
const widgetSettingsStore = useWidgetSettingsStore();

export const useConfigArrayItemsControl = (
  label = "Items",
  hideCRUD = false,
  fieldName = FieldName.ITEMS
) => {
  const itemsField = computed<IWidgetField | undefined>(() => {
    const field = (widgetSettingsStore.selectedWidget?.fields || []).find(
      field => field.name === fieldName
    );

    return field;
  });

  const itemsGroupField = computed<ICustomField>(
    () => (itemsField.value?.value as ICustomField[])[0]
  );

  return {
    componentPath: "ConfigArrayItems",
    options: {
      label,
      items: itemsGroupField.value?.fields,
      "onUpdate:items": (fields: IWidgetField[]) => {
        itemsGroupField.value.fields = fields;
      },
      maxItemsCount: DROPDOWN_MENU_MAX_ITEMS_COUNT,
      minItemsCount: DROPDOWN_MENU_MIN_ITEMS_COUNT,
      hideCRUD,
      optionsClickHandler: () => {
        widgetSettingsStore.setActiveElement(
          widgetSettingsStore.selectedWidget,
          itemsGroupField.value
        );
      },
      addItemHandler: () => {
        if (!itemsGroupField.value?.fields?.[0]) return;
        const { type, parent_id } = itemsGroupField.value.fields[0];
        const newItem = generateWidgetField(
          FieldName.ITEM,
          parent_id,
          type,
          "Item",
          "Item"
        );

        widgetsStore.updateFieldOptions(
          newItem,
          prefillEmptyOptions(newItem.options, ITEM_INITIAL().options)
        );

        itemsGroupField.value.fields.push(newItem);
      },
      reorderHandler: (
        targetId: number | string,
        insertPosition: IInsertPosition
      ) => {
        const [items, replacedItem] = reorderListElements(
          "id",
          targetId,
          insertPosition,
          itemsGroupField.value!.fields!
        );

        if (!replacedItem) {
          return;
        }

        const itemsInner = (itemsField.value?.value as ICustomField[]) || [];
        const itemsPrimary = itemsInner[0];

        const itemsPrimaryTmp = { ...itemsPrimary };
        itemsPrimaryTmp.fields = items;
        itemsInner.splice(0, 1, itemsPrimaryTmp);

        widgetsStore.updateFieldProperty(
          itemsField.value!,
          itemsInner,
          "value"
        );
      },
    },
  };
};
