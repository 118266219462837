import { useStaticTranslations } from "~~/store/staticTranslations";
import http from "~~/services/http";
import {
  IFrontendTranslationsResponse,
  TranslationCategory,
} from "~~/models/stores/static-translation-store.model";
import { ILanguageProperties } from "~~/models/widgets/languages.interface";

export const useFetchTranslations = async (
  category: TranslationCategory[],
  lang: ILanguageProperties
): Promise<void> => {
  const staticTranslations = useStaticTranslations();
  const config = {
    headers: {
      "Accept-Language": lang.codeWithRegion,
    },
  };
  // We don't need this translations for now
  // await http
  //   .get(`/translations/search?category=${category.join(",")}`, config)
  //   .then((response: ITranslationsResponse) => {
  //     const data = response.data;
  //     staticTranslations.setTranslations(data.items, lang);
  //   });

  await http
    .get(`/translations/frontend`, config)
    .then((response: IFrontendTranslationsResponse) => {
      const data = response.data;
      staticTranslations.setTranslations(data.translations, lang);
    });
};
