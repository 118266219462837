import { defineStore } from "pinia";

import { DndContainerId } from "~~/models/common/dropdown.model";
import {
  DraggingLayoutPromptData,
  IDndStore,
} from "~~/models/stores/dnd-store.model";

export const useDndStore = defineStore("dnd", {
  state: (): IDndStore => ({
    draggingLayoutPromptData: null,
    isDragging: false,
    activeDndContainerId: null,
  }),
  getters: {},
  actions: {
    setDraggingLayoutPromptData(data: DraggingLayoutPromptData | null): void {
      this.draggingLayoutPromptData = data;
    },
    setIsDragging(value: boolean): void {
      this.isDragging = value;
    },
    setActiveDndContainerId(value: DndContainerId | null): void {
      this.activeDndContainerId = value;
    },
  },
});
