<template>
  <div
    class="resizable-input-container"
    :data-name="modelValue.length > 0 ? modelValue : placeholder"
  >
    <input
      ref="valueInputRef"
      :value="modelValue"
      class="resizable-input heading overflow-ellipsis"
      :placeholder="props.placeholder"
      :maxlength="MAX_TEXT"
      @click="handleInputClick"
      @focusout="emitFocusout"
      @change="emitChange"
      @input="handleUpdate"
    />
  </div>
  <CommonIcon
    v-if="editNameMode"
    class="edit-icon pointer"
    name="ant-design:check-outlined"
    @click="editNameMode = false"
  />
  <CommonIcon
    v-else
    class="edit-icon pointer"
    name="ant-design:edit-outlined"
    @click="handleEditIconClick"
  />
  <CommonTooltip
    v-if="validationTooltip && modelValue.length === 0 && editNameMode"
    class="input-tooltip"
    :message="validationTooltip"
  />
</template>

<script setup lang="ts">
import focusOnInput from "~~/composables/focusOnInput";
import { MAX_TEXT } from "~~/constants/input-validation";

const { focusInput, valueInputRef } = focusOnInput();

const emit = defineEmits<{
  (e: "change", event: Event): void;
  (e: "update:model-value", event: string): void;
  (e: "focusout"): void;
}>();

defineExpose({
  focusInput,
});

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    modelValue: string;
    validationTooltip: string;
  }>(),
  {
    placeholder: "Type value...",
  }
);

const editNameMode = ref<boolean>(false);

const emitChange = (e: Event): void => {
  emit("change", e);
};

const handleUpdate = (e: Event): void => {
  const target = e.target as HTMLInputElement;
  const value: string = target.value;

  emit("update:model-value", value);
};

const handleEditIconClick = (): void => {
  editNameMode.value = true;
  focusInput();
};

const handleInputClick = (): void => {
  if (editNameMode.value) return;

  const input = valueInputRef.value;
  if (!input) return;

  editNameMode.value = true;

  input.setSelectionRange(input.value.length, input.value.length);
  input.scrollLeft += input.scrollWidth;
};

const emitFocusout = (): void => {
  editNameMode.value = false;
  emit("focusout");
};
</script>

<style lang="scss">
.resizable-input-container {
  position: relative;
  max-width: 625px; // allows around 90 symbols without overflow

  &::after {
    /* Typography needs to match to .heading class, the same as input value */
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    content: attr(data-name);
    visibility: hidden;
    white-space: pre; // include whitespaces
  }
}

.resizable-input {
  position: absolute;
  left: 0;
  padding: 0;
  border: 0;
  width: 100%;
  color: $c-black;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

.edit-icon {
  color: $c-primary-base;
}

.input-tooltip {
  top: 55px;
}
</style>
