import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { ControlProp } from "~~/models/settings/settings-sidebar.model";
import type { State } from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useTitleInitialControls } from "~~/composables/widgets/common/useTitleInitialControls";
import { useTitleLinkStateControls } from "~~/composables/widgets/card/useTitleLinkStatesControls";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useSimpleTextWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  titleLinkState: Ref<State>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Link States", "linkStates", "linkStates"),
  ];

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Title"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.title.field
          );
        },
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.title.field,
            options: {
              placeholder: "Enter text",
            },
          },
        ],
      },
    ];
  });

  const { titleControls } = useTitleInitialControls(widget);

  const titleLinkStateValue = computed<Record<string, State>>(() => {
    const fieldName = selectedField.value?.name;
    if (fieldName) {
      return {
        [fieldName]: titleLinkState.value,
      };
    } else {
      return {};
    }
  });
  const { titleLinkStatesControls } = useTitleLinkStateControls(
    titleLinkStateValue,
    fields,
    emit
  );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    titleControls,
    titleLinkStatesControls,
  };
};
