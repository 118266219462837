import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 28;

const v28WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (
    [
      "BetsListWidget",
      "LiveBetsListWidget",
      "ComingSportEventsWidget",
    ].includes(widget.name)
  ) {
    if (!widget.content.arrow) {
      widget.content.arrow = {
        "type": "UnknownField",
        "name": "arrow",
        "options": null,
      };
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v28WidgetMigration;
