import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";
import { State } from "~~/models/widgets/widget-controls.model";

export const DRODOWN_ITEM_STYLE_INITIAL = () => ({
  title: "Dropdown item style",
  options: {
    _active: true,
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
      fill: {
        color: "",
        opacity: 100,
      },
    },
    cornerRadius: getDefaultCornerRadiusValue(5),
    spaceBetween: 4,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    color: {
      color: "#000000",
      opacity: 100,
    },
    theme: 13,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    states: {
      [State.ACTIVE]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
      },
      [State.HOVER]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
      },
      [State.DISABLED]: {
        fill: {
          color: "",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
          fill: {
            color: "",
            opacity: 100,
          },
        },
        color: {
          color: "#000000",
          opacity: 100,
        },
        decoration: [],
      },
    },
  },
});
