import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 30;

const v30WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["PaymentProvidersListFenixWidget"].includes(widget.name)) {
    /* 
      if old version
    */
    if (!widget.content.provider_image.options.resizing) {
      widget.content.provider_image.options = FLEXIBLE_IMAGE_INITIAL().options;
    }
  }

  if (["BonusesFenixWidget"].includes(widget.name)) {
    /* 
      if old version
    */
    if (!widget.content.image.options.resizing) {
      widget.content.image.options = FLEXIBLE_IMAGE_INITIAL().options;
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v30WidgetMigration;
