import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateAlignSelf,
  generateFlex,
  generateFlexAlignment,
  generateFontSize,
  generateTextColor,
} from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import { generateStageButtonCssString } from "../utils/form-helper-functions";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const widgetStyles = generateFlex({
      flex: "flex",
      align: generateFlexAlignment(options.display?.alignment),
      justify: "initial",
      direction: "column",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateCodeStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const containerStyles = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: options.icon.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: containerStyles + "cursor: pointer;",
    });

    const iconCssStringContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconCssStringContent,
    });

    return cssString;
  };

export const generateButtonStyles =
  (field: IWidgetOptions, widget: IPageContentWidget) =>
  (cssString: string): string => {
    const { options } = field;

    const isFill =
      options.buttonDisplaySettings.display?.resizing === ResizingType.FILL;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: isFill
        ? generateAlignSelf(options.buttonDisplaySettings.display?.alignment)
        : generateAlignSelf(widget.options.display?.alignment),
    });

    return cssString;
  };

export const generateBookingCodeWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}_content`);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.booking_code.options.icon._cssClass =
    generateClassName("booking-code-icon");

  prefilled.button.options.iconSettings._cssClass =
    generateClassName("button-icon");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateStringDefault(prefilled.title),
    generateStringDefault(prefilled.description),
    generateStringDefault(prefilled.booking_code),
    generateCodeStyles(prefilled.booking_code),
    generateStageButtonCssString(prefilled.button as IWidgetField),
    generateButtonStyles(prefilled.button, widget)
  )("");
};
