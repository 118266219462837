<template>
  <div
    class="wconfig-spacing widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <p
      class="m-b-4"
      :class="{
        'bold': isBold,
      }"
    >
      {{ label }}
    </p>

    <ConfigInput
      v-number
      :is-number="true"
      :model-value="modelValue"
      :stretch-input="true"
      :input-props="{
        min: 0,
        max: MAX_NUMBER_INPUT_VALUE,
        controls: false,
      }"
      class="wconfig-spacing__option"
      @update:model-value="handleUpdate"
    >
      <template #suffix>
        <span> {{ suffix }} </span>
      </template>
    </ConfigInput>
  </div>
</template>

<script lang="ts" setup>
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";

withDefaults(
  defineProps<{
    modelValue: string | number | null;
    label?: string;
    isBold?: boolean;
    suffix?: string;
  }>(),
  { suffix: "px", label: "" }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const handleUpdate = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.wconfig-spacing {
  &__option {
    width: 100%;

    input {
      padding: 0 !important;
    }

    .ant-input-number-wrapper,
    .ant-input-number-group-wrapper {
      width: 100%;
    }
  }

  &__icon {
    svg {
      fill: $c-grey-45;
      width: 18px;
      height: 16px;
    }
  }
}
</style>
