import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  State,
  Alignment,
  DisplayOrientation,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultButtonTextSettings,
  getDefaultCornerRadiusValue,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultIconSettings,
  getDefaultShadow,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

import { TITLE_INITIAL } from "../text/useTextWidgetConfig";

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES: Record<string, State> = {
    event_card: State.DEFAULT,
    bet_items: State.DEFAULT,
    event_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({ ...DEFAULT_STATES });

  /*
  Initialize widget with initial options
*/

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: Alignment.CENTER,
    },
  });

  const createFields = (): void => {
    if (!widgetCacheData.value || !widgetCacheData.value.fields) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            String(fieldDetails.value || fieldDetails.default_value || ""),
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          String(fieldDetails.value || fieldDetails.default_value || ""),
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const cardState = (): IWidgetOptions => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    const initialCardsOptions = prefillEmptyOptions(
      fields.value.event_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            display: {
              layout: 1,
              distance: 16,
              contentPosition: {
                position: "bottom",
                alignment: null,
              },
            },
            betItemsLayout: DisplayOrientation.HORIZONTAL,
            states: {
              [State.HOVER]: cardState(),
              [State.ACTIVE]: cardState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialPhotoOptions = prefillEmptyOptions(
      fields.value.event_image.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "fill"],
        additionalData: {
          options: {
            resizing: ResizingType.FILL,
            containerSize: 250,
            padding: getDefaultSpacing({
              bottom: 8,
            }),
          },
        },
        values: {},
      }).options
    );

    const initialEventInfoOptions = prefillEmptyOptions(
      fields.value.event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            display: {
              layout: DisplayOrientation.VERTICAL,
              distance: 4,
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing({ bottom: 8 }),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const betItemsOptions = prefillEmptyOptions(
      fields.value.bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            displayMode: DisplayOrientation.HORIZONTAL,
            distance: 8,
            fontFamily: getDefaultFontFamily(),
            padding: getDefaultSpacing(8),
            name: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677ff"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#e6f4ff"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.event_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          padding: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          iconSettings: getDefaultIconSettings({ _active: false }),
          buttonTextSettings: getDefaultButtonTextSettings({
            _active: true,
            textColor: getDefaultFillValue("#1677FF"),
            theme: 13,
          }),
          buttonDisplaySettings: {
            alignment: Alignment.LEFT,
            resizing: ResizingType.HUG,
          },
        },
      }).options
    );

    const initialTeamPlayerOptions = prefillEmptyOptions(
      fields.value.event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "spacing"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialEventTimeOptions = prefillEmptyOptions(
      fields.value.event_date_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link", "padding"],
        values: {
          theme: 13,
          alignment: Alignment.LEFT,
          icon: {
            dateIcon: "ant-design:calendar-filled",
            timeIcon: "ant-design:clock-circle-filled",
            size: 20,
            color: getDefaultFillValue("#69B1FF"),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_card.field,
      initialCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_image.field,
      initialPhotoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_info.field,
      initialEventInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.bet_items.field,
      betItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_button.field,
      initialButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_name.field,
      initialTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.event_date_time.field,
      initialEventTimeOptions
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();

      widgetsStore.updateValue(
        useLocalizedValue().transformToLocalizedValue("See more bets")
          .localizedValue,
        "value",
        fields.value.event_button.field,
        "value"
      );
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
