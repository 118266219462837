<template>
  <div
    v-if="hasDesignPermissions"
    class="config-slider-pagination wconfig-item m-t-16"
  >
    <ConfigToggle
      size="small"
      label="Pagination"
      :allow-disable="true"
      :model-value="modelValue.isActive"
      @update:model-value="handleUpdate('isActive', $event)"
    />

    <template v-if="modelValue.isActive">
      <div class="config-slider-pagination__radio-wrapper m-t-16">
        <div
          class="config-slider-pagination__radio-element f-base pointer"
          :class="{
            'config-slider-pagination__radio-element--active':
              !modelValue.isStroke,
          }"
          @click="handleUpdate('isStroke', false)"
        >
          <CommonIcon name="custom:bullets" />

          <div
            class="config-slider-pagination__radio-element-label text-center"
          >
            Bullets
          </div>
        </div>

        <div
          class="config-slider-pagination__radio-element f-base pointer"
          :class="{
            'config-slider-pagination__radio-element--active':
              modelValue.isStroke,
          }"
          @click="handleUpdate('isStroke', true)"
        >
          <CommonIcon name="custom:strokes" />

          <div
            class="config-slider-pagination__radio-element-label text-center"
          >
            Strokes
          </div>
        </div>
      </div>

      <ConfigColorPickerInput
        class="p-l-0 p-b-0 p-r-0 border-bottom-none"
        :model-value="modelValue.color"
        label="Color"
        :type="ColorPickerType.TEXT"
        @update:model-value="handleUpdate('color', $event)"
      />

      <ConfigRadioTextElements
        class="p-l-0 p-b-0 p-r-0 border-bottom-none"
        label="Alignment"
        :items="[
          { value: Alignment.LEFT, label: 'Left' },
          { value: Alignment.CENTER, label: 'Center' },
          { value: Alignment.RIGHT, label: 'Right' },
        ]"
        :model-value="modelValue.alignment"
        @update:model-value="handleUpdate('alignment', $event)"
      />

      <ConfigSliderItemsPosition
        :model-value="modelValue.position"
        @update:model-value="handleUpdate('position', $event)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  Alignment,
  type IFillControl,
  SliderItemsPosition,
} from "~~/models/widgets/widget-controls.model";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";

interface SliderPagination {
  isActive: boolean;
  isStroke: boolean;
  color: IFillControl;
  alignment: Alignment;
  position: SliderItemsPosition;
}

const props = defineProps<{
  modelValue: SliderPagination;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: SliderPagination): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (key: keyof SliderPagination, value: unknown) => {
  emit("update:modelValue", { ...props.modelValue, [key]: value });
};
</script>

<style lang="scss">
.config-slider-pagination {
  &__radio-wrapper {
    @include flex(center, space-between, $space-s);
  }

  &__radio-element {
    position: relative;
    padding: 12px;
    color: $c-black;
    border: 1px solid $c-grey-15;
    border-radius: 6px;
    @include flex-column(center, space-between, 0);
    flex-grow: 1;

    svg {
      width: 100%;
      height: 52px;
    }

    &--active {
      padding: 11px;
      border: 2px solid $c-primary-base;
    }
  }

  &__radio-element-label {
    margin-top: 4px;
  }
}
</style>
