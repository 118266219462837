<script lang="ts" setup>
import { useNotification } from "@kyvg/vue3-notification";
import isObject from "lodash/isObject";

import { OSG_KEY_START, DEFAULT_CONTENT_VALUES } from "~~/constants";
import { findAnyStartsWith } from "~~/helpers/findAnyStartsWith";
import { useGridConfig } from "~~/store/grid";
import { useLanguagesStore } from "~~/store/languages";

import { useWidgetContentChanges } from "../composables/useWidgetContentChanges";

const loading = defineModel<boolean>("loading");

const gridStore = useGridConfig();
const languagesStore = useLanguagesStore();
const widgetChanges = useWidgetContentChanges();
const notification = useNotification();

const replaceAll = ref<Record<string, boolean>>({
  osgTranslationsContent: false,
  imagesContent: false,
  dataParamsContent: false,
  linksContent: false,
  simpleContent: false,
  htmlContent: false,
});

const replaceVariantText = (variant: boolean): string => {
  return variant ? "Replace all values" : "Replace only empty";
};

const processContent = (
  handler: (
    widget: any,
    callback: (value: string | Record<string, string>) => void
  ) => void,
  key: string
) => {
  for (const widget of gridStore.pageWidgets) {
    handler(widget, (value: string | Record<string, string>) => {
      const defaultLanguageCode =
        languagesStore.defaultLanguage?.codeWithRegion;

      if (!defaultLanguageCode) {
        notification.notify({
          text: "There are problems with default language.",
          type: "error",
        });
        throw new Error("Default language is not defined");
      }

      if (isObject(value)) {
        const defaultLanguageValue = value[defaultLanguageCode];
        Object.keys(languagesStore.availableLanguagesCodes).forEach(
          languageCode => {
            if (replaceAll.value[key]) {
              value[languageCode] = defaultLanguageValue;
            } else {
              value[languageCode] =
                value[languageCode] &&
                !findAnyStartsWith(DEFAULT_CONTENT_VALUES, value[languageCode])
                  ? value[languageCode]
                  : defaultLanguageValue;
            }
          }
        );
      }
    });
  }
  notification.notify({
    text: "Operation was successful!",
    type: "success",
  });
};

interface IMultiLangFeatures {
  key: string;
  handler: () => void;
  label: string;
  hint: string;
}

const features: IMultiLangFeatures[] = [
  {
    key: "osgTranslationsContent",
    handler: () =>
      processContent(
        widgetChanges.processWidgetTextContent,
        "osgTranslationsContent"
      ),
    label: "Copy translation code from default to other languages",
    hint: `Code (starts with ${OSG_KEY_START})`,
  },
  {
    key: "imagesContent",
    handler: () =>
      processContent(widgetChanges.processWidgetImageContent, "imagesContent"),
    label: "Copy image from default to other languages",
    hint: "",
  },
  {
    key: "dataParamsContent",
    handler: () =>
      processContent(
        widgetChanges.processWidgetDataParamsContent,
        "dataParamsContent"
      ),
    label: "Copy Data params from default to other languages",
    hint: "",
  },
  {
    key: "linksContent",
    handler: () =>
      processContent(widgetChanges.processWidgetLinksContent, "linksContent"),
    label: "Copy links from default to other languages",
    hint: "",
  },
  {
    key: "simpleContent",
    handler: () =>
      processContent(widgetChanges.processWidgetTextContent, "simpleContent"),
    label: "Copy simple text content from default to other languages",
    hint: "",
  },
  {
    key: "htmlContent",
    handler: () =>
      processContent(widgetChanges.processWidgetHtmlContent, "htmlContent"),
    label: "Copy HTML content from default to other languages",
    hint: "",
  },
];

const runAll = () => {
  features.forEach(feature => feature.handler());
};
</script>

<template>
  <a-typography-title :level="5">Multi language features:</a-typography-title>
  <a-table
    :data-source="features"
    :pagination="false"
    row-key="key"
  >
    <a-table-column
      key="action"
      title="Action"
    >
      <template #default="{ record }">
        <a-space nowrap>
          <a-button
            class="config__action-button"
            html-type="button"
            :disabled="loading"
            @click="record.handler"
          >
            <template #icon>
              <CopyOutlined />
            </template>
            {{ record.label }}
          </a-button>
          <a-tooltip v-if="record.hint">
            <template #title>
              {{ record.hint }}
            </template>

            <CommonIcon
              name="ant-design:question-circle-outlined"
              :size="16"
            />
          </a-tooltip>
        </a-space>
      </template>
    </a-table-column>
    <a-table-column
      key="variant"
      title="Variant"
    >
      <template #default="{ record }">
        <a-space>
          <a-switch
            v-model:checked="replaceAll[record.key]"
            :disabled="loading"
          />
          <a-text-typography>{{
            replaceVariantText(replaceAll[record.key])
          }}</a-text-typography>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
  <a-divider
    orientation="left"
    orientation-margin="0px"
  >
    or
  </a-divider>
  <a-button
    class="config__action-button"
    type="primary"
    :loading="loading"
    @click="runAll"
  >
    Run all actions
  </a-button>
</template>
