import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { CellSizeType } from "~~/models/grid.interface";

import { IFields } from "../useWidgetFields";

export const useBetsListFenixMobileEventControls = (
  statesValuePath: ComputedRef<string>,
  states: Ref<Record<string, State>>,
  fields: Ref<IFields>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const eventCardDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.spacing.padding",
        valueSource: selectedField.value,
        options: {
          meta: {
            isBold: true,
          },
          label: "Padding",
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.spacing.margin",
        valueSource: selectedField.value,
        options: {
          meta: {
            isBold: true,
          },
          label: "Margin",
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  const eventCardContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layoutMode",
            valueSource: selectedField.value,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: SportMenuLiveBet9CardLayoutMode.FIRST,
                },
                {
                  label: "Second",
                  value: SportMenuLiveBet9CardLayoutMode.SECOND,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.spaceBetween",
            valueSource: selectedField.value,
            options: {
              label: "Distance between card elements",
              showIconPrefix: false,
              isBold: true,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header",
              value: "header",
            },
            {
              label: "Event Name",
              value: "event_name",
            },
            {
              label: "Bet Items",
              value: "bet_items",
            },
            {
              label: "Event Button",
              value: "event_button",
              isActiveValueSource: fields.value.event_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const eventCardStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          states.value[selectedField.value!.name] = value;
        },
      },
      {
        section: generateDesignSectionName("Card"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.fill`,
            valueSource: selectedField.value,
            options: {
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
              isBold: false,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${statesValuePath.value}.border`,
            valueSource: selectedField.value,
            options: {
              label: "Border",
              isBold: false,
            },
          },
          {
            componentPath: "ConfigBoxShadow",
            valuePath: `${statesValuePath.value}.shadow`,
            valueSource: selectedField.value,
            options: {
              label: "Shadow",
              isBold: false,
            },
          },
        ],
      },
    ];
  });

  const startTimeDateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigCommonRadio",
        valuePath: "options.displayMode",
        valueSource: selectedField.value,
        options: {
          label: "Display mode",
          firstVariant: "Date & time",
          firstValue: "date",
          secondaryVariant: "Time to start",
          secondaryValue: "time",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: selectedField.value,
            options: {
              type: ColorPickerType.TEXT,
              icon: "ant-design:column-width-outlined",
              isWidth: true,
              exclude: [CellSizeType.FILL],
            },
          },
        ],
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: selectedField.value?.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueDate",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            visible: selectedField.value?.options.displayMode === "date",
            options: {
              label: "Date icon",
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.valueTime",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Time icon",
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betItemsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const betItemsContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.spaceBetween",
            valueSource: selectedField.value,
            options: {
              label: "Distance between bet items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.name.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.name.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.name.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.coef.color",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.coef.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Theme",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.coef.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const headerDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const isEventCardHeaderVertical = computed(
    () =>
      fields.value.header.field.options.layout === DisplayOrientation.VERTICAL
  );

  const headerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: `options.leagueName${
              isEventCardHeaderVertical.value ? "Vertical" : "Horizontal"
            }Position`,
            valueSource: selectedField.value,
            options: {
              label: "League name position",
              items: isEventCardHeaderVertical.value
                ? [
                    {
                      label: "Top",
                      value: "top",
                    },
                    {
                      label: "Bottom",
                      value: "bottom",
                    },
                  ]
                : [
                    {
                      label: "Left",
                      value: "left",
                    },
                    {
                      label: "Right",
                      value: "right",
                    },
                  ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.favoriteIconAlignment",
            valueSource: selectedField.value,
            options: {
              label: "Favorite icon alignment",
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Distance between header elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League Name",
              value: "event_league",
              isActiveValueSource: fields.value.event_league.field.options,
            },
            {
              label: "Favorite icon",
              value: "favourites_button",
              isActiveValueSource: fields.value.favourites_button.field.options,
            },
            {
              label: "Start time",
              value: "event_time",
            },
          ],
        },
      },
    ];
  });

  const eventLeagueControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: selectedField.value,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: "fill",
                },
                {
                  label: "Hug content",
                  value: "hug",
                },
              ],
            },
          },
        ],
      },
      typography({
        source: selectedField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const favIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const eventNameControls = computed(() => {
    return [
      typography({
        source: selectedField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    eventCardDesignControls,
    eventCardContentControls,
    eventCardStatesControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    startTimeDateControls,

    headerDesignControls,
    headerContentControls,

    eventLeagueControls,

    favIconControls,

    eventNameControls,
  };
};
