import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useItems } from "~~/composables/widgets/sport-event-fenix/useSportEventItems";
import { useSportEventFenixRacingConfig } from "~~/composables/widgets/sport-event-fenix/useSportEventFenixRacingConfig";
import { useSportEventFenixMarketsConfig } from "~~/composables/widgets/sport-event-fenix/useSportEventFenixMarketsConfig";
import { useSportEventConstructorConfig } from "~~/composables/widgets/sport-event-fenix/useSportEventConstructorConfig";
import { useSportEventBannerConfig } from "~~/composables/widgets/sport-event-fenix/useSportEventBannerConfig";

const PREMATCH_CONTENT_ITEMS = [
  {
    value: "prematch_banner",
    label: "Prematch banner",
  },
  {
    value: "betting_constructor",
    label: "Betting constructor",
  },
  {
    value: "markets_cards",
    label: "Market cards",
  },
];

const LIVE_CONTENT_ITEMS = [
  {
    value: "live_header",
    label: "Live header",
  },
  {
    value: "live_banner",
    label: "Live Banner",
  },
  {
    value: "markets_cards",
    label: "Market cards",
  },
];

const RACING_CONTENT_ITEMS = [
  {
    value: "live_header",
    label: "Live header",
  },
  {
    value: "racing_banner",
    label: "Racing banner",
  },
  {
    value: "racing_event_info",
    label: "Event info",
  },
  {
    value: "racing_players_info",
    label: "Players info",
  },
];

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS } = useItems(states);

  const defaultDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const widgetContentItems = computed<{ label: string; value: string }[]>(
    () => {
      if (states.value.widget === "prematch_event") {
        return PREMATCH_CONTENT_ITEMS;
      }

      if (states.value.widget === "live_event") {
        return LIVE_CONTENT_ITEMS;
      }

      if (states.value.widget === "racing_event") {
        return RACING_CONTENT_ITEMS;
      }

      return [];
    }
  );

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: "Prematch event",
              value: "prematch_event",
            },
            {
              label: "Live event",
              value: "live_event",
            },
            {
              label: "Racing event",
              value: "racing_event",
            },
            {
              label: "No events",
              value: "no_events",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "widget", value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetContentItems.value,
        },
      },
    ];
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const liveHeaderContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.distance",
            valueSource: fields.value.live_header.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "live_header_back_button",
              label: "Back button",
              isActiveValueSource:
                fields.value.live_header_back_button.field.options,
            },
            {
              value: "live_header_sport_league_name",
              label: "Sport / League name",
            },
            {
              value: "live_header_view_mode_switch",
              label: "View mode switch",
            },
          ],
        },
      },
    ];
  });

  /* 
    Back button
  */

  const backButtonControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigIconPicker",
        valuePath: "options.value",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: fields.value.live_header_back_button.field,
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: fields.value.live_header_back_button.field,
        className: "border-bottom-none p-b-0",
        options: {
          placeholder: "Enter text",
          label: "Color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        className: "p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container",
        valueSource: fields.value.live_header_back_button.field,
        options: {
          label: "Size",
          minValue: MIN_ICON_SIZE_INPUT_VALUE,
          maxValue: MAX_ICON_SIZE_INPUT_VALUE,
        },
      },

      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.live_header_back_button.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Sport / league name
  */

  const sportLeagueNameControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.live_header_sport_league_name.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.live_header_sport_league_name.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.live_header_sport_league_name.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.distance",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.live_header_sport_league_name.field,
            options: {
              label: "Spacing between text and icon",
              minValue: 8,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.live_header_sport_league_name.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    View mode switch
  */

  const viewModeSwitchContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigIconPicker",
        valuePath: "options.icon.streamIconValue",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          label: "Stream icon",
        },
      },
      {
        componentPath: "ConfigIconPicker",
        valuePath: "options.icon.statsIconValue",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          label: "Statistics icon",
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.icon.color",
        valueSource: fields.value.live_header_view_mode_switch.field,
        className: "border-bottom-none p-b-0",
        options: {
          placeholder: "Enter text",
          label: "Icon color",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.icon.size",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          label: "Size",
          minValue: MIN_ICON_SIZE_INPUT_VALUE,
          maxValue: MAX_ICON_SIZE_INPUT_VALUE,
        },
      },
    ];
  });

  const viewModeSwitchStatePath = computed<string>(() => {
    if (states.value.live_header_view_mode_switch === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.live_header_view_mode_switch}`;
  });

  const viewModeSwitchStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.live_header_view_mode_switch,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "live_header_view_mode_switch",
            value,
          });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${viewModeSwitchStatePath.value}.fill`,
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          placeholder: "Enter text",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${viewModeSwitchStatePath.value}.border`,
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${viewModeSwitchStatePath.value}.shadow`,
        valueSource: fields.value.live_header_view_mode_switch.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${viewModeSwitchStatePath.value}.icon.color`,
            valueSource: fields.value.live_header_view_mode_switch.field,
            options: {
              placeholder: "Enter text",
              label: "Icon color",
              type: ColorPickerType.BACKGROUND,
              isBold: true,
            },
          },
        ],
      },
    ];
  });

  const {
    marketsContainerContentControls,

    marketCardsContentControls,

    marketNameControls,

    marketSuspendedStatusDesignControls,

    marketSuspendedStatusContentControls,

    pinIconControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useSportEventFenixMarketsConfig(fields, states, emit);

  const {
    racingLiveHeaderContentControls,
    racingTitleControls,
    racingDescriptionControls,
    playersInfoContentControls,
    racingHeaderCardContentControls,

    playerCardsContentControls,
    playerCardsStatesControls,

    playerNumberControls,
    playerTextControls,

    oddsDesignControls,
    oddsContentControls,
    oddsStateControls,
  } = useSportEventFenixRacingConfig(fields, states, emit);

  const {
    bettingConstructorContentControls,

    constructorHeaderTextContentControls,
    constructorHeaderStatesControls,
    constructorHeaderContentControls,

    constructorDescriptionControls,
  } = useSportEventConstructorConfig(fields, states, emit);

  /* 
    No events
  */

  const noEventsTextControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.no_events_title.field,
        options: {
          placeholder: "Text",
          label: "Text",
        },
      },
      typography({ source: fields.value.no_events_title.field }),
    ];
  });

  const {
    bannerDesignControls,
    bannerContentControls,

    bannerEventInfoDesignControls,

    liveBannerEventInfoContentControls,
    liveBannerEventNameControls,
    liveBannerEventScoreContentControls,
    liveBannerHeaderContentControls,

    liveBannerStatisticsContentControls,
    liveBannerTitlesContentControls,

    liveBannerValuesTitlesDesignControls,
    liveBannerValuesTitlesContentControls,

    liveBannerRowsContentControls,
    liveBannerRowsTeamPlayerInfoContentControls,

    liveBannerFooterContentControls,
    liveBannerLoginTextControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
    liveBannerFooterElementsControls,
    prematchBannerContentControls,

    liveBannerValuesTitlesStateControls,
    liveBannerRowsTeamPlayerInfoPointsStateControls,
  } = useSportEventBannerConfig(states);
  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    defaultDesignControls,

    liveHeaderContentControls,

    backButtonControls,

    sportLeagueNameControls,

    viewModeSwitchContentControls,
    viewModeSwitchStatesControls,

    bettingConstructorContentControls,

    constructorHeaderTextContentControls,
    constructorHeaderStatesControls,
    constructorHeaderContentControls,

    constructorDescriptionControls,

    marketsContainerContentControls,

    marketCardsContentControls,

    marketNameControls,

    marketSuspendedStatusDesignControls,

    marketSuspendedStatusContentControls,

    pinIconControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    /* 
      Racing
    */
    racingLiveHeaderContentControls,

    racingTitleControls,

    racingDescriptionControls,

    playersInfoContentControls,

    racingHeaderCardContentControls,

    playerCardsContentControls,

    playerCardsStatesControls,

    playerNumberControls,

    playerTextControls,

    oddsDesignControls,

    oddsContentControls,
    oddsStateControls,

    noEventsTextControls,

    bannerDesignControls,
    bannerContentControls,

    bannerEventInfoDesignControls,

    liveBannerEventInfoContentControls,
    liveBannerEventNameControls,
    liveBannerEventScoreContentControls,
    liveBannerHeaderContentControls,

    liveBannerStatisticsContentControls,
    liveBannerTitlesContentControls,

    liveBannerValuesTitlesDesignControls,
    liveBannerValuesTitlesContentControls,

    liveBannerRowsContentControls,
    liveBannerRowsTeamPlayerInfoContentControls,

    liveBannerFooterContentControls,
    liveBannerLoginTextControls,

    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
    liveBannerFooterElementsControls,
    prematchBannerContentControls,

    liveBannerValuesTitlesStateControls,
    liveBannerRowsTeamPlayerInfoPointsStateControls,
  };
};
