import {
  Align,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultAlignValue,
  getDefaultBorderValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  BUTTON_INITIAL,
  BUTTONS_GROUP_INITIAL,
  DESCRIPTION_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    widget: State.DEFAULT,
    title: State.DEFAULT,
    button_1: State.DEFAULT,
    button_2: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const initialWidgetState = () => {
    return {
      fillImageColor: getDefaultFillImage({
        value: getDefaultFillValue("#F5F5F5"),
      }),
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
    };
  };

  const widgetOptions = initWidgetOptions(["fill"], {
    display: {
      layout: {
        position: "bottom",
        alignment: null,
      },
      gap: 8,
      alignment: getDefaultAlignValue(),
      alignSelf: Align.START,
    },
    link: {
      type: null,
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      isNewTabOpen: null,
    },
    fillImageColor: getDefaultFillImage({
      value: getDefaultFillValue("#FFFFFF"),
      _useLazyLoad: false,
      _isImagePreload: false,
    }),
    states: {
      [State.HOVER]: initialWidgetState(),
    },
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
      values: {
        resizing: {
          type: ResizingType.FILL,
          position: "center center",
          x: 50,
          y: 50,
        },
      },
    });

    addFieldInitialOptions(
      "title",
      {
        data: TITLE_INITIAL(),
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
          }),
        },
      },
      { value: "Title" }
    );

    addFieldInitialOptions(
      "description_1",
      {
        data: DESCRIPTION_INITIAL(),
        values: {
          padding: getDefaultSpacing({
            left: 16,
            right: 16,
          }),
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                color: getDefaultFillValue("#000000"),
              },
            },
          },
        },
      },
      { value: "Description" }
    );

    addFieldInitialOptions(
      "buttons_group",
      {
        data: BUTTONS_GROUP_INITIAL(),
        exclude: ["buttonDisplaySettings"],
        values: {
          padding: getDefaultSpacing({
            top: 8,
            bottom: 16,
            left: 16,
            right: 16,
          }),
        },
      },
      { value: "Description" }
    );

    addFieldInitialOptions(
      "button_1",
      {
        data: BUTTON_INITIAL(),
      },
      { value: "Button" }
    );

    addFieldInitialOptions(
      "button_2",
      {
        data: BUTTON_INITIAL(),
      },
      { value: "Button" }
    );

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
