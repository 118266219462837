<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <EventWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="event-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="text-widget__field"
      >
        <BasicTitleField
          :field="fields.title.field"
          value="Title"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.tabs_container.field"
      >
        <RacingSportsButtonTabs
          :container-field="fields.tabs_container.field"
          :tabs-field="fields.tabs.field"
          :widget="widget"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.banner_container.active"
        :widget="widget"
        :field="fields.banner_container.field"
      >
        <div
          :style="bannerContainerStyles"
          class="event-widget__banner"
        >
          <BasicTitleField
            :field="fields.event_title.field"
            value="Event Title"
          />
          <BasicTitleField
            :field="fields.event_time.field"
            value="Event Time"
          />
        </div>
      </WidgetField>

      <WidgetField
        v-if="fields.betbuilder_container.active"
        :widget="widget"
        :field="fields.betbuilder_container.field"
      >
        <EventBetBuilder
          :widget="props.widget"
          :field="fields.betbuilder_container.field"
        >
          <template #header>
            <WidgetField
              :widget="widget"
              :field="fields.betbuilder_header.field"
            >
              <EventBetBuilderHeader
                :field="fields.betbuilder_header.field"
                :state="states.betbuilder_header"
              />
            </WidgetField>
          </template>

          <template #content_container>
            <WidgetField
              :widget="widget"
              :field="fields.betbuilder_content_container.field"
            >
              <EventBetBuilderContent
                :widget="widget"
                :field="fields.betbuilder_content_container.field"
                :iframe-field="fields.betbuilder_content_iframe.field"
              >
                <template #description>
                  <BasicDescriptionField
                    ref="descriptionField"
                    :fields="[fields.betbuilder_content_description.field]"
                    :widget="widget"
                    @click.stop="handleDescriptionClick"
                  />
                </template>
              </EventBetBuilderContent>
            </WidgetField>
          </template>
        </EventBetBuilder>
      </WidgetField>

      <WidgetField
        v-if="fields.market_cards.active"
        :widget="widget"
        :field="fields.market_cards.field"
      >
        <EventMarketCards
          :widget="props.widget"
          :field="fields.market_cards.field"
          :market-name-field="fields.market_name.field"
          :favourite-market-field="fields.favourite_event_market.field"
          :market-suspended-status-field="fields.market_suspended_status.field"
          :bet-item-field="fields.bet_items.field"
          :bet-item-state="states.bet_items"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useEventInitData } from "~~/composables/widgets/1event/useEventInitData";
import { getCommonStyles } from "~~/assets/utils/styles";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { states, initFields } = useEventInitData(props.widget);

const bannerContainerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.banner_container.field.options),
  };
});

const handleStateUpdate = (stateDetails: {
  state: string;
  value: State;
}): void => {
  states.value[stateDetails.state] = stateDetails.value;
};

const resetStates = (): void => {
  for (const key in states.value) {
    states.value[key] = State.DEFAULT;
  }
};

const handleDescriptionClick = () => {
  widgetSettingsStore.setActiveElement(
    widgetSettingsStore.selectedWidget,
    fields.value.betbuilder_content_description.field
  );
};
initFields();
</script>

<style lang="scss">
.event-widget {
  &__market-cards-radio {
    .config-radio-icons__icon-wrapper {
      padding: 8px 0;
      flex-grow: 1;
    }
  }
}
</style>
