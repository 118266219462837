<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <BetsListFenixMobileWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <BasicTitleField
      v-if="states.widget === State.NO_ACTIVE"
      :style="noEventsInlineStyle"
      :field="fields.no_events_title.field"
    />

    <div
      v-else
      :style="sportItemsContainerStyles"
    >
      <BetsListFenixMobileSportItem
        v-for="sport in sortedSelectedSports"
        :key="sport.id"
        :widget="widget"
        :states="states"
        :item="sport"
      />
    </div>
  </BaseWidgetNew>
</template>

<script setup lang="ts">
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useBetsListFenixMobileWidgetInitData } from "~~/composables/widgets/bets-list-fenix-mobile/useBetsListFenixMobileWidgetInitData";
import { useBetsListSortedSelectedSports } from "~~/composables/widgets/bets-list/useBetsListSortedSelectedSports";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ElementStyle } from "~~/models/common";
import { ICell } from "~~/models/grid.interface";
import { ISportItem } from "~~/models/stores/widgets-store.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { states, DEFAULT_STATES, initFields } =
  useBetsListFenixMobileWidgetInitData(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

initFields();

const noEventsInlineStyle = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.event_card.field.options),
  };
});

const widgetsStore = useWidgetsStore();

const { sportsItems } = storeToRefs(widgetsStore);

const sortedSelectedSports = computed<Array<ISportItem>>(() => {
  const sports = useBetsListSortedSelectedSports(props.widget, sportsItems);
  return sports?.length
    ? sports
    : [{ id: "1", name: "Sport", published: true, position: 1, slug: "slug" }];
});

const sportItemsContainerStyles = computed<ElementStyle>(() => ({
  display: "flex",
  flexDirection: "column",
  gap: getPxValueFromNumber(fields.value.sports.field.options.spaceBetween),
}));
</script>
