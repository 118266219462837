import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

const WIDGET_CONTENT_ITEMS = [
  {
    label: "Header",
    value: "header",
  },
  {
    label: "Events",
    value: "event_cards",
  },
];

const HEADER_CONTENT_ITEMS = [
  {
    label: "Tabs",
    value: "tabs",
  },
];

export const useBetsListItems = () => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Header", "header"),
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Tabs", "tabs"),
    generateDropdownItem("Events", "event_cards"),
    generateDropdownItem("Start time", "event_time"),
    generateDropdownItem("Bet items", "bet_items"),
    generateDropdownItem("Event button link", "event_button"),
    generateDropdownItem("Arrows settings", "arrow"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    arrow: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [...DEFAULT_DROPDOWN_ITEMS];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    WIDGET_CONTENT_ITEMS,
    HEADER_CONTENT_ITEMS,
  };
};
