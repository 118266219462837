import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { useTransformEmptyArray } from "~~/composables/useTransformEmptyArray";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 76;

const v76WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (!widget.name.toLowerCase().includes("form")) return widget;

  Object.values(widget.content).forEach((field: any) => {
    if ([WIDGET_FIELD_TYPES.FORM_ELEMENT].includes(field.type)) {
      Object.values(field?.value?.fields || {}).forEach((formField: any) => {
        if ("label" in formField.options) {
          formField.options.label.value = useTransformEmptyArray(
            formField.options.label.value
          );
        }

        if ("placeholder" in formField.options) {
          formField.options.placeholder.value = useTransformEmptyArray(
            formField.options.placeholder.value
          );
        }

        if ("tooltip" in formField.options) {
          formField.options.tooltip.value = useTransformEmptyArray(
            formField.options.tooltip.value
          );
        }
      });
    }
  });

  return widget;
};
export default v76WidgetMigration;
