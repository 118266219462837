import { CSSProperties } from "vue";

type ICallback = (cssGenerator: CssGenerator) => void;

export class CssGenerator {
  protected cssString: string;

  constructor() {
    this.cssString = "";
  }

  public static init() {
    return new CssGenerator();
  }

  protected addString(line: string) {
    this.cssString += line;
    return this;
  }

  public rawCss(rawCss: string) {
    return this.addString(rawCss);
  }

  public className(className: string) {
    return this.addString(`.${className}`);
  }

  public bemElement(elName: string) {
    return this.addString(`--${elName}`);
  }

  public pseudoClass(value: string) {
    return this.addString(`:${value}`);
  }

  public pseudoSelector(value: string) {
    return this.addString(`::${value}`);
  }

  public property(name: keyof CSSProperties, value: string) {
    return this.addString(`${name}:${value};`);
  }

  public openClassBody() {
    return this.addString("{");
  }

  public closeClassBody() {
    return this.addString("}");
  }

  public ifCondition(condition: boolean, ifCallback: ICallback) {
    if (condition) {
      ifCallback(this);
    }

    return this;
  }

  public ifElseCondition(
    condition: boolean,
    ifCallback: ICallback,
    elseCallback: ICallback
  ) {
    if (condition) {
      ifCallback(this);
    } else {
      elseCallback(this);
    }

    return this;
  }

  public toString() {
    return this.cssString;
  }

  public attachToAnotherString(otherString: string) {
    return otherString + this.cssString;
  }
}
