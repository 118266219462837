import { Ref } from "vue";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";

export const useItemsControls = (arrayField: Ref<IWidgetField | null>) => {
  const itemsControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: arrayField.value,
        options: {
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: arrayField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: arrayField.value,
        options: {
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: arrayField.value,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: arrayField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margins",
        valueSource: arrayField.value,
        options: {
          label: "Margin",
          meta: {
            isBold: true,
          },
          isAllSideSetting: true,
          showTooltip: true,
        },
      },
    ];
  });

  return { itemsControls };
};
