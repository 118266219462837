import { type Ref, type ComputedRef } from "vue";

import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { State } from "~~/models/widgets/widget-controls.model";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useAllowDisableDependentFieldContentItems } from "~~/composables/widgets/useAllowDisableDependentFieldContentItems";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";

type Args = {
  state: Ref<string> | ComputedRef<string>;
  stateHandler?: (state: State, fieldName?: string, fieldType?: string) => void;
  exclude?: Record<string, string[]>;
  valueSource?: Ref<IWidgetField>;
  valuePath?: Ref<string>;
  showAlignmentOnFillOnly?: boolean;
};

export const useButtonControls = (
  {
    state,
    stateHandler,
    exclude,
    valueSource,
    valuePath,
    showAlignmentOnFillOnly = true,
  }: Args,
  emit?: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const buttonSettingsValuePath = computed<string>(() => {
    if (state.value === State.DEFAULT) {
      return "options";
    }

    return `options.states.${state.value}`;
  });

  const buttonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: state.value,
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        if (stateHandler) {
          stateHandler(
            value,
            selectedField.value?.name,
            selectedField.value?.type
          );
          return;
        }

        emit(`update-${selectedField.value?.name}-state`, value);
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${buttonSettingsValuePath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${buttonSettingsValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${buttonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${buttonSettingsValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const buttonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: selectedField.value,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const buttonContentControls = computed(() => {
    const items = [
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.buttonDisplaySettings",
        valueSource: selectedField.value,
        options: {
          showAlignmentOnFillOnly: showAlignmentOnFillOnly,
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: valuePath?.value || "value",
        valueSource: valueSource?.value || selectedField.value,
        className: "group-control-element",
        options: {
          placeholder: "Enter button text",
          label: "Text",
          allowDisable: allowDisableFieldsItem(
            FieldContentItem.BUTTON_TEXT_SETTINGS
          ).value,
          active: selectedField.value!.options.buttonTextSettings._active,
          showOptions: false,
          max: 50,
          "onUpdate:active": (value: boolean) => {
            selectedField.value!.options.buttonTextSettings._active = value;
          },
        },
      },
      {
        section: generateContentSectionName("Typography"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
    ];

    if (exclude?.content) {
      return items.filter(
        item => !exclude.content.includes(item.componentPath as string)
      );
    }

    return items;
  });

  return {
    buttonStatesControls,
    buttonDesignControls,
    buttonContentControls,
  };
};
