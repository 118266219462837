import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IGridWidgetsCssData,
  IGridWidgetsData,
  IPageContentWidget,
} from "~~/models/page.model";
import { CellId, Grid, ICell, ICellOptions } from "~~/models/grid.interface";
import { Breakpoint } from "~~/models/breakpoints.model";
import {
  generateBasicWidgetStyles,
  generateWidgetWrapperStyles,
} from "~~/assets/utils/widget-css/compiler/default-css-compiler";

import { widgetGenerators } from "./widget-generators";
import { generateWidgetContainerCssString } from "./container/container-css";
import { generateClassName } from "./utils/generate-class-name";

const generateElementCssString = (
  widgets: IWidgetOptions,
  cellsOptions: Record<CellId, ICellOptions>
): string[] => {
  return widgets.map((widget: IPageContentWidget) => {
    const cssStringGeneratorFunction = widgetGenerators[widget.name];

    if (!cssStringGeneratorFunction) {
      console.warn(`Add css generator function for ${widget.name} widget`);
      return "";
    }

    const parentWidget = widgets.find(
      (currWidget: IWidgetWithFields) =>
        widget.parentId && currWidget.id === widget.parentId
    );

    const hasChildren = Boolean(
      widgets.find(
        (currWidget: IWidgetWithFields) => currWidget.parentId === widget.id
      )
    );

    widget.options._widgetWrapperCssClass = generateClassName(
      `${widget.name}Wrapper`
    );

    let widgetCss = "";

    try {
      if (widget.name === "DepositListWidget") {
        widgetCss = cssStringGeneratorFunction(widget, "deposit");
      } else if (widget.name === "WithdrawalListWidget") {
        widgetCss = cssStringGeneratorFunction(widget, "withdrawal");
      } else if (widget.name === "SportsMenuLiveWidget") {
        widgetCss = cssStringGeneratorFunction(widget, "live");
      } else {
        widgetCss = cssStringGeneratorFunction(widget);
      }
    } catch (e: any) {
      throw new Error(
        `Failed to generate css for widget - ${widget.name} with error: '${e}'. Try to initialize each resolution`
      );
    }

    const baseWidgetStyles = generateBasicWidgetStyles(
      widget.options,
      cellsOptions[widget.cellId],
      hasChildren
    );

    const widgetWrapperStyles = generateWidgetWrapperStyles(
      widget.options,
      cellsOptions[widget.cellId],
      parentWidget
    );

    return baseWidgetStyles + widgetCss + widgetWrapperStyles;
  });
};

const generateCellStylesString = (
  cell: ICell,
  gridWidgetsData: IGridWidgetsData,
  cellsOptions: Record<CellId, ICellOptions>
): string => {
  let cellStyles = "";

  if (cell.children && cell.children.length) {
    cell.children.forEach(child => {
      cellStyles += generateCellStylesString(
        child,
        gridWidgetsData,
        cellsOptions
      );
    });
  }

  const currentCellData = gridWidgetsData.pageContent[cell.cellId];

  /* 
    Page content includes only cells that contain widgets,
    so currentCellData may not exist 
  */
  if (currentCellData?.widgets && currentCellData?.widgets.length) {
    cellStyles += generateElementCssString(
      currentCellData.widgets,
      cellsOptions
    ).join("");
  }

  return cellStyles;
};

const generatePageElementsStyles = (
  cells: ICell[],
  gridWidgetsData: IGridWidgetsData,
  cellsOptions: Record<CellId, ICellOptions>
): string => {
  return cells.reduce(
    (styles, cell) =>
      styles + generateCellStylesString(cell, gridWidgetsData, cellsOptions),
    ""
  );
};

export const generateWidgetsCssData = (
  grid: Grid,
  gridWidgetsData: IGridWidgetsData | null,
  cellsOptions: Record<CellId, ICellOptions>
): IGridWidgetsCssData | null => {
  if (!gridWidgetsData) return null;

  const result: any = {
    pageElementsStyles: {},
  };

  for (const key in grid) {
    const cells = grid[key as Breakpoint] as ICell[];

    result.pageElementsStyles[key] = generatePageElementsStyles(
      cells,
      gridWidgetsData,
      cellsOptions
    );
  }

  return result;
};

export const generateWidgetContainersCssData = (
  cellsOptions: Record<CellId, ICellOptions>,
  cssFileId: string
): string => {
  if (!cellsOptions) return "";

  let result = "";

  Object.entries(cellsOptions).forEach(([key, value]) => {
    result += generateWidgetContainerCssString(
      key,
      value,
      cssFileId,
      cellsOptions
    );
  });

  return result;
};
