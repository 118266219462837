import { State } from "~~/models/widgets/widget-controls.model";

import {
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultShadow,
  getDefaultSpacing,
  DESIGN_INITIAL,
} from "./widget-initial";

export const BUTTON_TABS_CONTAINER_INITIAL = () => ({
  value: null,
  options: {
    _active: true,
    ...DESIGN_INITIAL().options,
    displaySettings: {
      resizing: "hug",
      alignment: "left",
      distance: 4,
    },
  },
});

export const BUTTON_TABS_ELEMENT_INITIAL = () => ({
  value: "Button",
  options: {
    ...DESIGN_INITIAL().options,
    fill: getDefaultFillValue("#ffffff"),
    spacing: {
      padding: getDefaultSpacing(8),
    },
    streamIcon: "ant-design:video-camera-filled",
    statsIcon: "ant-design:bar-chart-outlined",
    iconSettings: {
      textColor: getDefaultFillValue("#000000"),
      iconSize: 20,
    },
    states: {
      [State.HOVER]: {
        fill: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        iconSettings: {
          textColor: {
            color: "#ffffff",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: getDefaultFillValue("#1677FF"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(""),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        iconSettings: {
          textColor: {
            color: "#ffffff",
            opacity: 100,
          },
        },
      },
    },
  },
});
