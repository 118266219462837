<template>
  <div :style="containerStyles">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { useFillBackground } from "~~/composables/widgets/useFillBackground";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  options?: IWidgetOptions;
  state?: string;
}>();

const options = computed<IWidgetOptions>(() => {
  if (props.options) {
    return props.options;
  }

  return props.field.options;
});

const currentStateOptions = computed<IWidgetOptions>(() => {
  if (!props.state || props.state === State.DEFAULT) {
    return options.value;
  }

  return {
    ...options.value,
    ...options.value.states[props.state],
  };
});

const { fillBackgroundStyling } = useFillBackground(currentStateOptions);

const containerStyles = computed<ElementStyle>(() => {
  const fill = fillBackgroundStyling.value;
  return {
    ...getCommonStyles(currentStateOptions.value),
    ...fill,
  };
});
</script>
