import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import { generateFontSize, generateTextColor } from "../helpers";
import { generateCssClassWithContent } from "../compiler/default-css-compiler";

import {
  generateEventCardsCssString,
  generateListTimeCssString,
} from "./sportsbook-live-widget-css";

export const generateFavouritesButtonCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(element.options.color),
      generateFontSize(element.options.iconSize),
      "margin-left: auto;",
      "z-index:1;",
      "cursor: pointer;",
    ];
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: content.join(""),
    });
    return cssString;
  };

// keys: { size = "iconSize", color = "textColor" }
export const generateIconCssString =
  (
    icon: IWidgetOptions,
    keys: { size: string; color: string } = {
      size: "iconSize",
      color: "textColor",
    }
  ) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(icon.options[keys.color]),
      generateFontSize(icon.options[keys.size]),
    ];

    cssString += generateCssClassWithContent({
      className: icon.options._cssClass,
      content: content.join(""),
    });

    return cssString;
  };

export const generateRacingSportsbookLiveWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const event_cards = widget.content["event_cards"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const event_time = widget.content["event_time"];
  const event_info = widget.content["event_info"];
  const favourites_button = widget.content["favourites_button"];
  const stream_icon = widget.content["stream_icon"];

  widget.options._cssClass = generateClassName("RacingSportsbookLive");
  title.options._cssClass = generateClassName("title");

  event_cards.options._cssClass = generateClassName("event_cards");
  event_cards.options._itemCssClass = generateClassName("event_cards_item");
  event_suspended_status.options._cssClass = generateClassName(
    "event_suspended_status"
  );
  event_time.options._cssClass = generateClassName("event_time");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("event_time_icon");
  event_info.options._cssClass = generateClassName("event_info");
  favourites_button.options._cssClass = generateClassName("favourites_button");
  stream_icon.options._cssClass = generateClassName("stream_icon");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringWithStates(title, false),
    generateEventCardsCssString(event_cards as IWidgetField),
    generateStringDefault(event_suspended_status, ["size"]),
    generateListTimeCssString(event_time as IWidgetField),
    generateStringDefault(event_info),
    generateFavouritesButtonCssString(favourites_button),
    generateIconCssString(stream_icon)
  )("");
};
