<template>
  <div
    class="basic-progress-field"
    :style="progressStyle"
  >
    <a-progress :percent="50" />
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";
import { getSpacing } from "~~/assets/utils/widget-settings";
import { getTextCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { getColorFromHex } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  field: IWidgetField;
}>();

const progressStyle = computed<ElementStyle>(() => {
  return {
    ...getSpacing(props.field.options.padding, SpacingKeyName.PADDING),
    ...getStylesAsVars(
      {
        ...getTextCommonStyles(props.field.options),
      },
      "text"
    ),
    ...getStylesAsVars(
      {
        background: getColorFromHex(props.field.options.bar.fill) as string,
        color: getColorFromHex(props.field.options.bar.color) as string,
        size: getPxValueFromNumber(props.field.options.bar.size),
      },
      "bar"
    ),
  };
});
</script>

<style lang="scss">
.basic-progress-field {
  .ant-progress-inner {
    background-color: var(--bar-background);
  }

  .ant-progress-bg {
    background-color: var(--bar-color);
    height: var(--bar-size, 8px) !important;
  }

  .ant-progress-text {
    font-size: var(--text-fontSize);
    font-family: var(--text-fontFamily);
    font-style: var(--text-fontStyle);
    text-decoration: var(--text-textDecoration);
    font-weight: var(--text-fontWeight, 400);
    color: var(--text-color);
  }

  .ant-progress {
    @include flex(center, flex-start, 0);
  }
}
</style>
