<template>
  <div class="sportsbook-live__cards-container">
    <WidgetField
      v-for="(card, index) in cards"
      :key="index"
      :widget="widget"
      :field="field"
      :style="cardStyles"
      class="sportsbook-live__card"
    >
      <WidgetField
        :widget="widget"
        :field="headerField"
        :style="headerStyles"
      >
        <div :style="headerContainerStyles">
          <slot name="league"></slot>
          <slot
            v-if="cardLayout === 1"
            name="suspended"
          ></slot>
          <slot name="stream"></slot>
          <slot name="time"></slot>
          <slot name="period"></slot>
        </div>
        <slot name="header_append"></slot>
      </WidgetField>

      <div class="sportsbook-live__info-container">
        <slot
          v-if="cardLayout === 2"
          name="suspended"
        ></slot>
        <slot
          name="event_info"
          :layout="cardLayout"
        ></slot>
      </div>
      <slot
        name="bet_items"
        :items="card.items"
      ></slot>
      <slot name="action_button"></slot>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { State } from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    widget: IWidgetWithFields;
    field: IWidgetField;
    state: State;
    headerField: IWidgetField;
    cards?: { items: { name: string; coef: string }[] }[];
  }>(),
  {
    cards: () => [
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
      {
        items: [
          { name: "1", coef: "0.00" },
          { name: "X", coef: "0.00" },
          { name: "2", coef: "0.00" },
        ],
      },
    ],
  }
);

type LayoutVariant = 1 | 2;

const cardLayout = computed<LayoutVariant>(
  () => props.field.options.contentLayout
);

const cardStyles = computed<ElementStyle>(() => {
  const settings = props.field?.options;

  if (!settings) {
    return {};
  }

  const activeStateOptions =
    props.state === State.DEFAULT
      ? props.field.options
      : props.field.options.states[props.state];

  const styles = {
    ...settings,
    ...activeStateOptions,
  };

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(styles.contentItemsDistance),
    ...getCommonStyles({ ...styles }),
  };
});

const headerStyles = computed<ElementStyle>(() => {
  const settings = props.headerField?.options;

  if (!settings) {
    return {};
  }
  return {
    display: "flex",
    alignItems: settings.iconAlignment,
    ...getCommonStyles({ ...settings }),
  };
});

const headerContainerStyles = computed<ElementStyle>(() => {
  const settings = props.headerField?.options;

  if (!settings) {
    return {};
  }
  return {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
    columnGap: getPxValueFromNumber(settings.distance),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__card {
    overflow: hidden;
  }

  &__info-container {
    @include flex(center, flex-start, 0);
  }

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 16px;
    column-gap: 16px;
  }
}
</style>
