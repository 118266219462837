<template>
  <div
    class="basic-card-field"
    :class="[`basic-card-field--${layoutPosition.position}`]"
    :style="containerStyles"
  >
    <slot
      v-if="fields.image.active"
      name="image"
    >
      <WidgetField
        :widget="widget"
        :field="fields.image.field"
        class="basic-card-field__field w-100"
      >
        <BasicImageField :field="fields.image.field" />
      </WidgetField>
    </slot>

    <div class="w-100 basic-card-field__text">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="basic-card-field__field basic-card-field__title w-100"
      >
        <BasicTitleField
          :field="fields.title.field"
          :state="titleLinkSelectedState"
          :options="titleOptions"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.description_1.active"
        :widget="widget"
        :field="fields.description_1.field"
        class="basic-card-field__field basic-card-field__description"
      >
        <BasicDescriptionField
          ref="descriptionField"
          :fields="descriptionFields"
          :widget="widget"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.buttons_group.active"
        :widget="widget"
        :field="fields.buttons_group.field"
        :style="buttonsGroupStyle"
        class="basic-card-field__field basic-card-field__buttons-group"
      >
        <WidgetField
          v-if="fields.button_1.active"
          :widget="widget"
          :field="fields.button_1.field"
          class="basic-card-field__field"
          :style="button1Style"
        >
          <BasicButtonField
            :field="fields.button_1.field"
            :current-state="buttonState1"
          />
        </WidgetField>

        <WidgetField
          v-if="fields.button_2.active"
          :widget="widget"
          :field="fields.button_2.field"
          class="basic-card-field__field"
          :style="button2Style"
        >
          <BasicButtonField
            :field="fields.button_2.field"
            :current-state="buttonState2"
          />
        </WidgetField>
      </WidgetField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  ICustomField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  TextPositionComplex,
  TextPosition,
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";

const props = defineProps<{
  widget: IWidgetWithFields;
  field?: ICustomField;
  buttonState1?: State;
  buttonState2?: State;
  titleOptions?: IWidgetOptions;
}>();

const element = computed<IWidgetWithFields | ICustomField>(
  () => props.field || props.widget
);

const fields = useWidgetFields(element.value);

const titleLinkSelectedState = ref(State.DEFAULT);

const descriptionFields = computed(() => {
  return element.value.fields?.filter(field =>
    field.name.includes("description")
  );
});

const layoutPosition = computed<TextPositionComplex>(() => {
  return element.value.options.layoutPosition;
});

const containerStyles = computed<
  { [key: string]: string | undefined } | undefined
>(() => {
  if (
    !layoutPosition.value ||
    layoutPosition.value.position === TextPosition.TOP
  ) {
    return undefined;
  }

  return {
    alignItems: layoutPosition.value.alignment || undefined,
  };
});

const buttonsGroupStyle = computed(() => {
  const style = useWidgetStyle(fields.value.buttons_group.field);

  const { layout, distanceBetweenItems, alignment } =
    fields.value.buttons_group.field.options;

  if (layout === DisplayOrientation.VERTICAL) {
    style.value.flexDirection = "column";
  }

  style.value.gap = getPxValueFromNumber(distanceBetweenItems);
  style.value.justifyContent = alignment;
  style.value.alignItems =
    layout === DisplayOrientation.HORIZONTAL
      ? "start"
      : generateFlexAlignment(alignment);
  return style.value;
});

const getButtonStyle = (name: "button_1" | "button_2") => {
  const buttonDisplaySettings =
    fields.value[name].field.options.buttonDisplaySettings;

  if (buttonDisplaySettings.resizing === ResizingType.HUG) return {};

  if (
    buttonDisplaySettings.resizing === ResizingType.FILL &&
    fields.value.buttons_group.field.options.layout ===
      DisplayOrientation.VERTICAL
  )
    return { width: "100%" };

  return { flexGrow: 1 };
};

const button1Style = computed(() => getButtonStyle("button_1"));
const button2Style = computed(() => getButtonStyle("button_2"));
</script>

<style lang="scss">
.basic-card-field {
  height: 100%;
  @include flex-column(center, flex-start, 0);

  &--top {
    flex-direction: column-reverse;
  }

  &--right {
    flex-direction: row;
  }

  &--left {
    flex-direction: row-reverse;
  }

  &__buttons-group {
    display: flex;
    width: 100%;
  }

  &__text {
    @include flex-column(flex-start, flex-start, 0);
    flex: 1;
  }

  &__description {
    flex-grow: 1;
  }
}
</style>
