<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        v-if="show"
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @select="handleSelectDropdownItem"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="depositListSettingsContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template
          v-if="fields.update_profile_text.field"
          #update_profile_text
        >
          <FormDescriptionSettings
            :is-bold="true"
            @update-field-option="updateFieldOption"
          />
        </template>

        <template #providers_item_image>
          <ControlsList
            :widget="widget"
            :controls="cardsImageControls"
          />
        </template>

        <template #providers_item_title>
          <ControlsList
            :widget="widget"
            :controls="cardsTitleControls"
          />
        </template>

        <template #providers_item_limits>
          <ControlsList
            :widget="widget"
            :controls="cardsDescriptionControls"
          />
        </template>

        <template #provider_card-content>
          <ControlsList
            :widget="widget"
            :controls="providerCardContentControls"
          />
        </template>

        <template #provider_card-design>
          <ControlsList
            :widget="widget"
            :controls="providerCardDesignControls"
          />
        </template>

        <template #provider_card-states>
          <ControlsList
            :widget="widget"
            :controls="providerCardStatesControls"
          />
        </template>

        <template #provider_image>
          <ControlsList
            :widget="widget"
            :controls="formCardImageControls"
          />
        </template>

        <template #provider_title>
          <ControlsList
            :widget="widget"
            :controls="fromCardTitleControls"
          />
        </template>

        <template #provider_description_1>
          <ControlsList
            :widget="widget"
            :controls="formCardDescriptionControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template #cardsLinkStates>
          <ControlsList
            :widget="widget"
            :controls="cardsTitleLinkStatesControls"
          />
        </template>

        <template #providers_item_button-design>
          <ControlsList
            :widget="widget"
            :controls="cardsListButtonDesignControls"
          />
        </template>

        <template #providers_item_button-content>
          <ControlsList
            :widget="widget"
            :controls="cardsListButtonContentControls"
          />
        </template>

        <template #providers_item_button-states>
          <ControlsList
            :widget="widget"
            :controls="cardsListButtonStatesControls"
          />
        </template>

        <template #form_back_button-design>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonDesignControls"
          />
        </template>

        <template #form_back_button-content>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonContentControls"
          />
        </template>

        <template #form_back_button-states>
          <ControlsList
            :widget="widget"
            :controls="formBackButtonStatesControls"
          />
        </template>

        <template #providers_item-design>
          <ControlsList
            :widget="widget"
            :controls="cardsListDesignControls"
          />
        </template>

        <template #providers_item-content>
          <ControlsList
            :widget="widget"
            :controls="cardsListContentControls"
          />
        </template>

        <template #providers_item-states>
          <ControlsList
            :widget="widget"
            :controls="cardsListStatesControls"
          />
        </template>

        <template #providers_item_tooltip-design>
          <ControlsList
            :widget="widget"
            :controls="tooltipDesignStyles"
          />
        </template>

        <template #providers_item_tooltip-content>
          <ControlsList
            :widget="widget"
            :controls="tooltipContentStyles"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/deposit-list/useDepositListWidgetConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  activeView: string;
  screens: string[];
  show: boolean;
}>();

const emit = defineEmits<{
  (e: "description-field-option-updated"): void;
  (e: "update-button-state", event: State): void;
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "update-active-view", event: string): void;
  (e: "select", event: string | null): void;
  (e: "reset-states"): void;
}>();

const store = useWidgetsStore();
const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const activeView = toRef(() => props.activeView);
const screens = toRef(() => props.screens);
const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  depositListSettingsContentControls,
  titleControls,
  titleLinkStatesControls,
  cardsTitleLinkStatesControls,
  fields,
  cardsImageControls,
  cardsListButtonDesignControls,
  cardsListButtonContentControls,
  cardsListButtonStatesControls,
  tooltipDesignStyles,
  tooltipContentStyles,
  cardsTitleControls,
  cardsListDesignControls,
  cardsListContentControls,
  cardsListStatesControls,
  cardsDescriptionControls,
  formBackButtonDesignControls,
  formBackButtonContentControls,
  formBackButtonStatesControls,
  formCardImageControls,
  formCardDescriptionControls,
  fromCardTitleControls,
  providerCardDesignControls,
  providerCardContentControls,
  providerCardStatesControls,
} = useConfig(ref(props.widget), activeView, emit, screens, states);

const updateFieldOption = (options: {
  key: string;
  value: any;
  field: IWidgetField;
}): void => {
  store.updateFieldOptionValue(options.value, options.key, options.field.id);
};

const handleSelectDropdownItem = (item: DropdownItem) => {
  emit("select", item.value as string | null);

  const selectedItem = DROPDOWN_ITEMS.find(
    curr => curr.custom?.fieldId === item?.custom?.fieldId
  );

  if (!selectedItem || !selectedItem.custom?.fieldId) {
    return;
  }

  const field = props.widget.fields.find(
    f => f.id === selectedItem.custom?.fieldId
  );

  if (!field) {
    return;
  }

  widgetsStore.setActiveElement(props.widget, field, undefined, field.type);
};

const resetStates = (): void => {
  emit("reset-states");
};
</script>
