import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useWidgetInitialDesignOptions = (
  widget: Ref<IWidgetWithFields>
) => {
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({ source: widget.value });
  });

  return {
    widgetDesignControls,
  };
};
