import { useWidgetsStore } from "~~/store/widgets";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { getDefaultSpacing } from "~~/constants/configs/common/widget-initial";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import {
  BUTTON_INITIAL,
  IMAGE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { useNewForm } from "~~/composables/widgets/form/useNewForm";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { FORM_INITIAL } from "~~/constants/widget-details/form-initial-options";

import { TITLE_INITIAL } from "../text/useTextWidgetConfig";

export const useInit = (data: {
  widget: Ref<IWidgetWithFields>;
  screens: string[];
  formElements: Record<string, string[]>;
  initialView: string;
}) => {
  const { widget, screens, formElements, initialView } = data;

  const widgetsStore = useWidgetsStore();
  const { widgetCacheData } = useWidgetCacheData(widget.value);

  const { addFieldInitialOptions } = useCreateFields(widget.value);

  const DEFAULT_STATES: Record<string, State> = {
    result_back_button: State.DEFAULT,
    login_submit: State.DEFAULT,
    login_forgot_password_button: State.DEFAULT,
    login_button: State.DEFAULT,

    forgot_password_submit: State.DEFAULT,
    forgot_password_back_button: State.DEFAULT,
  };

  const {
    currentStates,
    isWholeWidgetSelected,
    handleResetStates,
    handleUpdateActiveView,
    activeView,
    handleStateUpdate,
    initWidget,
  } = useNewForm({
    widget,
    screens,
    formElements,
    initialView,
    initialStates: DEFAULT_STATES,
  });

  const init = (): void => {
    const widgetOptions = initWidget();

    addFieldInitialOptions("login_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
    });

    addFieldInitialOptions("login_submit", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("login_forgot_password_button", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("login_description_1", {
      data: {
        options: {
          padding: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("login_description_2", {
      data: {
        options: {
          padding: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("login_text", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("login_button", {
      data: BUTTON_INITIAL(),
    });

    /* 
      forgot password
    */

    addFieldInitialOptions("forgot_password_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
    });

    addFieldInitialOptions("forgot_password_submit", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("forgot_password_back_button", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("forgot_password_description_1", {
      data: {
        options: {
          padding: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("forgot_password_description_2", {
      data: {
        options: {
          padding: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("result_title", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("result_back_button", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("result_image", {
      data: IMAGE_INITIAL(),
    });

    addFieldInitialOptions("result_message", {
      data: TITLE_INITIAL(),
    });

    addFieldInitialOptions("result_timer", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
    });

    addFieldInitialOptions("login_form", {
      data: FORM_INITIAL(widgetCacheData.value?.fields?.login_form),
    });

    addFieldInitialOptions("forgot_password_form", {
      data: FORM_INITIAL(widgetCacheData.value?.fields?.forgot_password_form),
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    currentStates,
    isWholeWidgetSelected,
    handleResetStates,
    handleUpdateActiveView,
    activeView,
    handleStateUpdate,
    init,
  };
};
