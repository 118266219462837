import { type Ref, computed } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { Sizing } from "~~/models/grid.interface";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useRacingSportsbookLiveEventCardConfig } from "./useRacingSportsbookLiveEventCardConfig";
import { useRacingSportbookLiveCardInfo } from "./useRacingSportbookLiveCardInfo";
import { useItems } from "./useRacingSportsbookLiveItems";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { isWidthConfigDisabled } = useWidthConfig();
  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems(widget);

  /* 
    Widget config
  */

  const widgeDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className: "group-control-element",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
   Common title
  */

  const titleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Event card
  */

  const { eventCardsContentControls, eventCardsDesignControls } =
    useRacingSportsbookLiveEventCardConfig(widget, states, emit);

  /* 
    Card info
  */

  const {
    eventSuspendedDesignControls,
    eventSuspendedContentControls,
    eventTimeControls,
    eventInfoControls,
  } = useRacingSportbookLiveCardInfo(widget);

  const favIconControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.favourites_button.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: fields.value.favourites_button.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const streamIconControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.stream_icon.field,
        options: {
          hideSwitch: true,
          className: "p-l-16 p-t-16 p-r-16",
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgeDesignControls,
    widgetContentControls,

    titleControls,
    eventCardsDesignControls,
    eventCardsContentControls,

    eventSuspendedDesignControls,
    eventSuspendedContentControls,

    eventTimeControls,

    eventInfoControls,

    favIconControls,

    streamIconControls,
  };
};
