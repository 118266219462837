export function useIframePostMessage() {
  const sendMessage = (event: any) => {
    parent.postMessage(JSON.stringify(event), "*");
  };

  const sendFullRedirect = (path: string) => {
    sendMessage({ type: "redirectWithPageReload", value: path });
  };

  const sendPathChange = (path: string) => {
    sendMessage({ type: "pathChange", value: path });
  };

  return {
    sendFullRedirect,
    sendPathChange,
  };
}
