import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
} from "~~/constants/configs/common/widget-initial";

export const TOOLTIPS_STYLE_INITIAL = () => ({
  title: "Tooltip style",
  options: {
    _active: true,
    fill: {
      color: "#000000",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
      fill: {
        color: "",
        opacity: 100,
      },
    },
    cornerRadius: getDefaultCornerRadiusValue(8),
    padding: {
      top: 4,
      bottom: 4,
      left: 4,
      right: 4,
    },
    color: {
      color: "#ffffff",
      opacity: 100,
    },
    fontFamily: getDefaultFontFamily(),
    theme: 13,
    decoration: [],
    mode: "icon",
    textChooseIcon: {
      icon: "ant-design:question-circle-outlined",
      color: {
        color: "#898989",
        opacity: 100,
      },
      gap: 5,
    },
  },
});
