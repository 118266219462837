import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  MAX_GAP_INPUT_VALUE,
  MIN_GAP_INPUT_VALUE,
} from "~~/constants/input-validation";

export const useConfig = (widget: Ref<IWidgetWithFields>) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Account number", "account_number"),
    generateDropdownItem("Username", "username"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: widget.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "username",
              label: "Username",
            },
            {
              value: "account_number",
              label: "Account number",
            },
          ],
        },
      },
    ];
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const usernameControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.username.field!,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const accountNumberControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.account_number.field!,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: selectedField.value,
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: selectedField.value,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: selectedField.value,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.distance",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Spacing between text and icon",
              minValue: MIN_GAP_INPUT_VALUE,
              maxValue: MAX_GAP_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    usernameControls,
    accountNumberControls,
  };
};
