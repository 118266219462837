import {
  getDefaultFillValue,
  getDefaultTextShadow,
} from "~~/constants/configs/common/widget-initial";
import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 14;

const v14WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["EventWidget"].includes(widget.name)) {
    if ("event_title" in widget.content) {
      widget.content.event_title.options.color = getDefaultFillValue("#ffffff");
      widget.content.event_title.options.textShadow = getDefaultTextShadow({
        fill: getDefaultFillValue("#000000"),
        blur: 5,
      });
    }

    if ("event_time" in widget.content) {
      widget.content.event_time.options.color = getDefaultFillValue("#ffffff");
      widget.content.event_time.options.textShadow = getDefaultTextShadow({
        fill: getDefaultFillValue("#000000"),
        blur: 5,
      });
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v14WidgetMigration;
