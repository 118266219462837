import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IContentField, IPageContentWidget } from "~~/models/page.model";
import { TextPosition } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import { generateFlexibleImageCssString } from "../utils/form-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateAlignSelf,
  generateFlex,
  generateGrid,
  generateJustifyItems,
  generateTextColor,
} from "../helpers";
import { getGridAlign } from "../../widget-settings";

import {
  generateButtonCssString,
  generateButtonsGroupCssString,
} from "./card-widget-css";

export const generateCardFenixCssString =
  (
    options: IWidgetOptions,
    content: Record<string, IContentField>,
    gridElementsCount = 3
  ) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(options, false);

    const flexDirections: Record<TextPosition, string> = {
      [TextPosition.BOTTOM]: "column",
      [TextPosition.TOP]: "column-reverse",
      [TextPosition.RIGHT]: "row",
      [TextPosition.LEFT]: "row-reverse",
    };

    const widgetPositionCssContent = generateFlex({
      flex: "flex",
      align: options.display.layout.alignment,
      justify: "flex-start",
      gap: options.display.gap,
      direction:
        flexDirections[options.display.layout.position as TextPosition],
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent,
    });

    const align = getGridAlign(options.display.alignment);
    const styles =
      "width:100%;" +
      generateAlignSelf(align["align-self"]) +
      generateJustifyItems(align["justify-items"]);

    let gridTemplateValue = "";

    for (let i = 1; i <= gridElementsCount; i++) {
      if (i == gridElementsCount) gridTemplateValue += "1fr";
      else gridTemplateValue += "min-content ";
    }

    const gridTextPosition =
      generateGrid({
        templateRows: gridTemplateValue,
        gap: options.display.gap,
      }) + styles;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: options._textContainerCssClass,
      content: gridTextPosition,
    });

    // TODO: make a login to choose concrete element
    cssString += generateCssClassWithContent({
      className: options._textContainerCssClass,
      pseudoClassName: " > *:last-child",
      content: generateAlignSelf(options.display.alignSelf),
    });

    cssString += generateCssClassWithContent({
      className: options._textContainerCssClass,
      pseudoClassName: " > *",
      content: "width: auto;",
    });

    for (const state in options.states) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: content.title.options._cssClass,
        pseudoClassName: `:${state}`,
        content: generateTextColor(
          content.title.options.link.states[state].color
        ),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: content.description_1.options._cssClass,
        pseudoClassName: `:${state}`,
        content: generateTextColor(
          content.description_1.options.states[state].color
        ),
      });
    }

    return cssString;
  };

export const generateCardFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._textContainerCssClass = generateClassName("card-text-group");
  const prefilled = prefillWithClasses(widget.content);

  prefilled.button_1.options.iconSettings._cssClass =
    generateClassName("buttonIcon");
  prefilled.button_2.options.iconSettings._cssClass =
    generateClassName("buttonIcon");

  return pipeSync<string>(
    generateCardFenixCssString(widget.options, prefilled),
    generateFlexibleImageCssString(prefilled.image as IWidgetField),
    generateStringWithStates(prefilled.title, true),
    generateStringDefault(prefilled.description_1),
    generateButtonsGroupCssString(prefilled.buttons_group as IWidgetField),
    generateButtonCssString(prefilled.button_1 as IWidgetField),
    generateButtonCssString(prefilled.button_2 as IWidgetField)
  )("");
};
