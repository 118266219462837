import { ComputedRef } from "vue";

import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import {
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
  getBackgroundColorStyle,
  getColorFromHex,
  getDecorationValue,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import {
  SpacingKeyName,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

// This function should be used directly for the widget field that you are working with.
// If you want to retrieve styles from field controls that have their own controls, you can use the utility file 'src\assets\utils\widget-settings\index.ts'.
// You can find an example of usage in the 'BasicImageField.vue' file.
export function useWidgetStyle(
  widget: IWidgetField
): ComputedRef<{ [key: string]: string | number }> {
  return computed<{ [key: string]: string | number }>(() => {
    const widgetStyle = {};

    if (widget.options) {
      if (widget.options.containerSize) {
        const { resizing, size } = widget.options.containerSize;
        const objectFit = resizing === ResizingType.FIT ? "contain" : "cover";
        const width = resizing === ResizingType.FILL ? "100%" : "unset";

        Object.assign(widgetStyle, {
          height: `${size}px`,
          width,
          objectFit,
        });
      }

      if (widget.options.border) {
        Object.assign(widgetStyle, getBorderStyle(widget.options.border));
      }

      if (widget.options.shadow) {
        Object.assign(widgetStyle, getBoxShadowStyle(widget.options.shadow));
      }

      if (widget.options.cornerRadius) {
        Object.assign(
          widgetStyle,
          getCornerRadiusStyle(widget.options.cornerRadius)
        );
      }

      if (widget.options.padding) {
        Object.assign(
          widgetStyle,
          getSpacing(widget.options.padding, SpacingKeyName.PADDING)
        );
      }

      if (widget.options.margins) {
        Object.assign(
          widgetStyle,
          getSpacing(widget.options.margins, SpacingKeyName.MARGIN)
        );
      }

      if (widget.options.spacing) {
        Object.assign(widgetStyle, {
          ...getSpacing(widget.options.spacing.margin, SpacingKeyName.MARGIN),
          ...getSpacing(widget.options.spacing.padding, SpacingKeyName.PADDING),
        });
      }

      if (widget.options.fill) {
        Object.assign(
          widgetStyle,
          getBackgroundColorStyle(widget.options.fill)
        );
      }

      if (widget.options.textColor) {
        Object.assign(widgetStyle, {
          color: getColorFromHex(widget.options.textColor),
        });
      }

      if (widget.options.theme) {
        Object.assign(widgetStyle, {
          ...getFontSizeStyle(widget.options.theme),
        });
      }
      if (widget.options.fontFamily) {
        Object.assign(widgetStyle, {
          fontFamily: widget.options.fontFamily,
        });
      }

      if (widget.options.decoration) {
        Object.assign(
          widgetStyle,
          getDecorationValue(widget.options.decoration)
        );
      }
    }

    return widgetStyle;
  });
}
