import { DEFAULT_CONTENT_VALUES, TEST_HTML } from "~~/constants";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import type { IPageContentWidget } from "~~/models/page.model";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { findAnyStartsWith } from "~~/helpers/findAnyStartsWith";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

interface IOptions {
  skipDisabled?: boolean;
  skipDefault?: boolean;
  skipHtml?: boolean;
}

const applyOptions = ({
  contentOption,
  contentValue,
  handler,
  options,
}: {
  contentOption: Record<string, any>;
  contentValue: string | Record<string, string>;
  handler: (content: string | Record<string, string>) => void;
  options?: IOptions;
}): void | null => {
  const { getLocalizedValueFromDefaultLanguage } = useLocalizedValue();
  const value =
    getLocalizedValueFromDefaultLanguage.value(contentValue) || contentValue;

  if (
    options?.skipDisabled &&
    (contentOption._active === false ||
      contentOption.options?._active === false ||
      contentOption.options?.text?._active === false ||
      contentOption.label?._active === false ||
      contentOption.tooltip?._active === false ||
      contentOption.placeholder?._active === false ||
      contentOption.options?.buttonTextSettings?._active === false)
  ) {
    return null;
  }
  if (
    options?.skipDefault &&
    findAnyStartsWith(DEFAULT_CONTENT_VALUES, value)
  ) {
    return null;
  }
  if (options?.skipHtml && TEST_HTML.test(value)) {
    return null;
  }
  handler(contentValue);
};

export const useWidgetContentChanges = () => {
  function processWidgetTextContent(
    widget: IWidgetWithFields | IPageContentWidget,
    onContentHandler: (content: string | Record<string, string>) => void,
    options?: IOptions
  ) {
    const widgetContent = "fields" in widget ? widget.fields : widget.content;

    Object.values(widgetContent).forEach((field: any) => {
      if (
        [
          WIDGET_FIELD_TYPES.TEXT_ELEMENT,
          WIDGET_FIELD_TYPES.DROPDOWN_ELEMENT,
          WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
          WIDGET_FIELD_TYPES.UNKNOWN,
        ].includes(field.type)
      ) {
        if (field.value) {
          applyOptions({
            contentOption: field,
            contentValue: field.value,
            handler: onContentHandler,
            options,
          });
        }
        // other individual cases
        // transaction history fenix
        if (field.options.text && field.name === "title") {
          for (const key in field.options.text) {
            applyOptions({
              contentOption: field.options.text,
              contentValue: field.options.text[key],
              handler: onContentHandler,
              options,
            });
          }
        }
        // search field widget
        if (
          field.options.text &&
          ["field_container", "search_field"].includes(field.name)
        ) {
          applyOptions({
            contentOption: field.options.text,
            contentValue: field.options.text.value,
            handler: onContentHandler,
            options,
          });
        }
        if (
          field.options.placeholder &&
          ["field_container", "search"].includes(field.name)
        ) {
          applyOptions({
            contentOption: field.options.placeholder,
            contentValue: field.options.placeholder.value,
            handler: onContentHandler,
            options,
          });
        }
        // casino list
        if (
          field.options.description &&
          ["games_category_nogames_title", "all_games_nogames_title"].includes(
            field.name
          )
        ) {
          applyOptions({
            contentOption: field.options.description,
            contentValue: field.options.description.value,
            handler: onContentHandler,
            options,
          });
        }
        if (field.options.query && ["search_result"].includes(field.name)) {
          applyOptions({
            contentOption: field.options.query,
            contentValue: field.options.query.value,
            handler: onContentHandler,
            options,
          });
        }
      } else if (
        [
          WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
          WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
        ].includes(field.type)
      ) {
        if (Array.isArray(field.value)) {
          for (let index = 0; index < field.value.length; index++) {
            const itemsFieldValue = field.value[index]?.fields;
            if (Array.isArray(itemsFieldValue)) {
              itemsFieldValue.forEach((item: any) => {
                applyOptions({
                  contentOption: item,
                  contentValue: item.value,
                  handler: onContentHandler,
                  options,
                });
              });
            }
          }
        }
      } else if ([WIDGET_FIELD_TYPES.FORM_ELEMENT].includes(field.type)) {
        Object.values(field?.value?.fields || {}).forEach((formField: any) => {
          // Localized form field items
          if (Array.isArray(formField.options.items)) {
            formField.options.items.forEach((formFieldItem: any) => {
              applyOptions({
                contentOption: formFieldItem,
                contentValue: formFieldItem.label,
                handler: onContentHandler,
                options,
              });
            });
          }

          // Localized form field label
          if ("label" in formField.options) {
            applyOptions({
              contentOption: formField.options,
              contentValue: formField.options.label.value,
              handler: onContentHandler,
              options,
            });
          }

          // Localized form field placeholder

          if ("placeholder" in formField.options) {
            applyOptions({
              contentOption: formField.options,
              contentValue: formField.options.placeholder.value,
              handler: onContentHandler,
              options,
            });
          }

          // Localized form field tooltip
          if ("tooltip" in formField.options) {
            applyOptions({
              contentOption: formField.options,
              contentValue: formField.options.tooltip.value,
              handler: onContentHandler,
              options,
            });
          }
        });
      }
    });
  }

  function processWidgetImageContent(
    widget: IWidgetWithFields | IPageContentWidget,
    onContentHandler: (content: string | Record<string, string>) => void,
    options?: IOptions
  ) {
    const widgetContent = "fields" in widget ? widget.fields : widget.content;
    Object.values(widgetContent).forEach((field: any) => {
      if ([WIDGET_FIELD_TYPES.IMAGE_ELEMENT].includes(field.type)) {
        if (field.value) {
          applyOptions({
            contentOption: field,
            contentValue: field.value,
            handler: onContentHandler,
            options,
          });
        }
      }
    });
  }

  function processWidgetHtmlContent(
    widget: IWidgetWithFields | IPageContentWidget,
    onContentHandler: (content: string | Record<string, string>) => void,
    options?: IOptions
  ) {
    const widgetContent = "fields" in widget ? widget.fields : widget.content;
    Object.values(widgetContent).forEach((field: any) => {
      if (
        [
          WIDGET_FIELD_TYPES.TEXT_AREA_ELEMENT,
          WIDGET_FIELD_TYPES.TEXT_EDITOR_ELEMENT,
        ].includes(field.type)
      ) {
        if (field.value) {
          applyOptions({
            contentOption: field,
            contentValue: field.value,
            handler: onContentHandler,
            options,
          });
        }
      }
    });
  }

  function processWidgetDataParamsContent(
    widget: IWidgetWithFields | IPageContentWidget,
    onContentHandler: (content: string | Record<string, string>) => void,
    options?: IOptions
  ) {
    if (widget.options?.bindingParams) {
      for (const param in widget.options.bindingParams) {
        applyOptions({
          contentOption: widget.options.bindingParams[param],
          contentValue: widget.options.bindingParams[param].value,
          handler: onContentHandler,
          options,
        });
      }
    }
    if (widget.options?.breadcrumbs) {
      widget.options.breadcrumbs?.forEach((breadcrumb: Record<string, any>) => {
        for (const param in breadcrumb) {
          applyOptions({
            contentOption: breadcrumb[param],
            contentValue: breadcrumb[param].value,
            handler: onContentHandler,
            options,
          });
        }
      });
    }
  }

  function processWidgetLinksContent(
    widget: IWidgetWithFields | IPageContentWidget,
    onContentHandler: (content: string | Record<string, string>) => void,
    options?: IOptions
  ) {
    const widgetContent = "fields" in widget ? widget.fields : widget.content;

    const operationWithOption = (option: Record<string, any>) => {
      if (!option?.link) {
        return;
      }

      if (option.link?.value) {
        applyOptions({
          contentOption: option.link,
          contentValue: option.link.value,
          handler: onContentHandler,
          options,
        });
      }

      if (option.link?.authorizeValue) {
        applyOptions({
          contentOption: option.link,
          contentValue: widget.options.link.authorizeValue,
          handler: onContentHandler,
          options,
        });
      }
    };

    operationWithOption(widget.options);

    Object.values(widgetContent).forEach((field: any) => {
      if (
        [
          WIDGET_FIELD_TYPES.IMAGE_ELEMENT,
          WIDGET_FIELD_TYPES.TEXT_ELEMENT,
          WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
          WIDGET_FIELD_TYPES.UNKNOWN,
        ].includes(field.type)
      ) {
        operationWithOption(field.options);
      } else if (
        [
          WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
          WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
        ].includes(field.type)
      ) {
        for (let index = 0; index < field.value.length; index++) {
          const itemsFieldValue = field.value[index]?.fields;
          if (Array.isArray(itemsFieldValue)) {
            itemsFieldValue.forEach((item: any) => {
              operationWithOption(item.options);
            });
          }
        }
      }
    });
  }

  return {
    processWidgetTextContent,
    processWidgetHtmlContent,
    processWidgetImageContent,
    processWidgetDataParamsContent,
    processWidgetLinksContent,
  };
};
