<template>
  <BaseWidgetNew
    ignore-style-states
    :widget="widget"
    :cell="cell"
    :current-state="activeView"
    :non-state-options="['fillImageColor']"
  >
    <LoginFormWidgetSettings
      :widget="widget"
      :states="currentStates"
      :custom-form-elements="customFormElements"
      :active-view="activeView"
      :views="VIEWS"
      @update-active-view="handleUpdateActiveView"
      @update-state="handleStateUpdate"
      @reset-states="handleResetStates"
    />

    <div
      v-if="activeView === 'login_form'"
      class="basic-form-widget__content"
    >
      <WidgetField
        :widget="widget"
        :field="fields.login_form.field"
      >
        <FormElementField
          :widget="widget"
          :field="fields.login_form.field"
          :states="currentStates"
          :form-elements="customFormElements.login_form"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.login_text.active"
        :widget="widget"
        :field="fields.login_text.field"
      >
        <BasicTitleField :field="fields.login_text.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.login_button.active"
        :widget="widget"
        :field="fields.login_button.field"
      >
        <BasicButtonField
          :field="fields.login_button.field"
          :current-state="currentStates.login_button"
        />
      </WidgetField>
    </div>

    <div
      v-else-if="activeView === 'forgot_password_form'"
      class="basic-form-widget__content"
    >
      <WidgetField
        :widget="widget"
        :field="fields.forgot_password_form.field"
      >
        <FormElementField
          :widget="widget"
          :field="fields.forgot_password_form.field"
          :states="currentStates"
          :form-elements="customFormElements.forgot_password_form"
        />
      </WidgetField>
    </div>

    <div
      v-else-if="activeView === 'success'"
      class="basic-form-widget__content"
    >
      <WidgetField
        v-if="fields.result_title.active"
        :widget="widget"
        :field="fields.result_title.field"
      >
        <BasicTitleField :field="fields.result_title.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.result_back_button.active"
        :widget="widget"
        :field="fields.result_back_button.field"
      >
        <BasicButtonField
          :field="fields.result_back_button.field"
          :current-state="currentStates.result_back_button"
        />
      </WidgetField>

      <WidgetField
        v-if="fields.result_image.active"
        :widget="widget"
        :field="fields.result_image.field"
      >
        <BasicImageField :field="fields.result_image.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.result_message.active"
        :widget="widget"
        :field="fields.result_message.field"
      >
        <BasicTitleField :field="fields.result_message.field" />
      </WidgetField>

      <WidgetField
        v-if="fields.result_timer.active"
        :widget="widget"
        :field="fields.result_timer.field"
      >
        <BasicTitleField :field="fields.result_timer.field" />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/login-form-fenix/useLoginFormFenixInit";

const VIEWS: { label: string; value: string }[] = [
  {
    label: "Login",
    value: "login_form",
  },
  {
    label: "Forgot password",
    value: "forgot_password_form",
  },
  {
    label: "Result",
    value: "success",
  },
];

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const customFormElements: Record<string, string[]> = {
  login_form: [
    "login_title",
    "login_submit",
    "login_forgot_password_button",
    "login_description_1",
    "login_description_2",
  ],
  forgot_password_form: [
    "forgot_password_title",
    "forgot_password_submit",
    "forgot_password_back_button",
    "forgot_password_description_1",
    "forgot_password_description_2",
  ],
};

const screens = computed<string[]>(() => {
  return VIEWS.map(view => view.value);
});

const {
  init,
  currentStates,
  activeView,
  handleUpdateActiveView,
  handleStateUpdate,
  handleResetStates,
} = useInit({
  widget: ref(props.widget),
  screens: screens.value,
  formElements: customFormElements,
  initialView: screens.value[0],
});

init();
</script>
