import { Sizing } from "~~/models/grid.interface";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 69;

const v69WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "ComingEventsSwitcherWidget") {
    widget.options.contentWidth = {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    };
  }

  return widget;
};
export default v69WidgetMigration;
