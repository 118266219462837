<template>
  <div class="widgets-tree-tab">
    <WidgetsTreeView
      v-if="widgetsTreeData?.length"
      v-model:tree-data="widgetsTreeData"
      :expanded-state="expandedState"
      :selected-node-id="selectedNodeId"
    />

    <div v-else>
      <p class="text-center widgets-tree-tab__empty">No widgets found</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGridConfig } from "~~/store/grid";
import { getWidgetsFromLayout } from "~~/assets/utils";
import { useLanguagesStore } from "~~/store/languages";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

import WidgetsTreeView from "./WidgetsTreeView.vue";

export interface ITreeNode {
  title: string;
  key: string;
  iconName: string;
  level?: number;
  widgetData?: IWidgetWithFields;
  parentWidgetData?: IWidgetWithFields;
  visible?: boolean;
  showSuffixControls?: boolean;
  children?: ITreeNode[];
  isLastChild?: boolean;
  disableDropInside?: boolean;
}
// TODO: add another deprecated field names here
const DEPRECATED_FIELD_NAMES = ["description_2", "description_3", "container"];

const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);
const { currentWidgetsLayout } = storeToRefs(useGridConfig());

const expandedState = ref(new Map<string, boolean>());

const buildTree = (widget: IWidgetWithFields, level = 0): ITreeNode => {
  const { name, id, options, fields, cell_id, parentId } = widget;

  const treeNode: ITreeNode = {
    title: name,
    key: String(id),
    iconName: options._tag
      ? "ant-design:global-outlined"
      : "custom:widgets-tree-widget",
    widgetData: widget,
    visible: widget.options?._isVisible?.[currentLanguageCode.value] ?? false,
    showSuffixControls: true,
    level: level,
  };

  if (cell_id && parentId) {
    treeNode.parentWidgetData =
      useGridConfig().findWidget(cell_id, parentId) || undefined;
  }

  if (options._children || name === "ContainerWidget") {
    treeNode.iconName = options._tag
      ? treeNode.iconName
      : "custom:widgets-tree-container-widget";

    treeNode.children = options._children?.map(childWidget =>
      buildTree(childWidget, level + 1)
    );
  } else {
    treeNode.disableDropInside = true;

    treeNode.children = fields
      .filter(field => !DEPRECATED_FIELD_NAMES.includes(field.name))
      .map(
        (field: IWidgetField): ITreeNode => ({
          title: field.name,
          key: String(field.id),
          iconName: "ant-design:borderless-table-outlined",
          parentWidgetData: widget,
          isLastChild: true,
        })
      );
  }

  return treeNode;
};

const widgetsTreeData = computed({
  get() {
    return getWidgetsFromLayout(currentWidgetsLayout.value).map(buildTree);
  },
  set() {
    getWidgetsFromLayout(currentWidgetsLayout.value).map(buildTree);
  },
});

const selectedNodeId = computed(() =>
  String(
    useWidgetSettingsStore().selectedField?.id ||
      useWidgetSettingsStore().selectedWidget?.id ||
      ""
  )
);
</script>

<style lang="scss">
.widgets-tree-tab {
  width: $propertybar-width;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 100px;

  &__empty {
    font-style: italic;
    color: $c-grey-45;
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
