import type { Ref } from "vue";

import { type IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  ConfigTabItem,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidgetFields } from "../../useWidgetFields";
import { useFormElementConfig } from "../../form/useFormElementConfig";

export const useCasinoGamesFiltersWidgetControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const { dividedFields } = useFormElementConfig(widget, states, emit);

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: fields.value.form.field,
            options: {
              label: "Layout",
              items: [
                { label: "Horizontal", value: DisplayOrientation.HORIZONTAL },
                { label: "Vertical", value: DisplayOrientation.VERTICAL },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.form.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigFormItems",
        options: {
          fields: dividedFields.value,
          label: "Items",
          showVisibilityButton: false,
        },
      },
    ];
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields);
  });

  const widgetStylesControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  return {
    widgetDesignControls,
    widgetContentControls,
    widgetStylesControls,
  };
};
