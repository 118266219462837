<script lang="ts" setup>
import { generateParamsFromSlug } from "~~/components/dynamic-params/utils";
import { IWidgetBindingParam } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  paramName: string;
  validationPath: string[];
}>();

const {
  getLocalizedValue,
  setLocalizedValue,
  currentLanguageCode,
  transformToLocalizedValue,
} = useLocalizedValue();

const listToOptions = (list: string[]) => {
  return list.map(item => ({
    label: item,
    value: item,
  }));
};

const formData = defineModel<IWidgetBindingParam>("modelValue", {
  required: true,
});

function onCustomValueChange(value: unknown) {
  formData.value.value = setLocalizedValue.value(
    formData.value.value,
    String(value)
  );
}

const customFieldUpdate = () => {
  const { value, children } = formData.value;

  if (!value || !children) {
    formData.value.children = transformToLocalizedValue({}).localizedValue;
  }

  const params = generateParamsFromSlug({}, getLocalizedValue.value(value));

  formData.value.children![currentLanguageCode.value] = Object.keys(
    params
  ).reduce<Record<string, IWidgetBindingParam>>((acc, key) => {
    const localized = formData.value.children?.[currentLanguageCode.value];
    const value = localized?.[key]?.value ?? params[key];

    acc[key] = {
      label: key,
      source: "widgetData",
      sources: ["widgetData"],
      value,
    } as IWidgetBindingParam;

    return acc;
  }, {});
};

const onSourceChange = () => {
  if (formData.value.source === "custom") {
    formData.value.value = transformToLocalizedValue("").localizedValue;
  }

  if (formData.value.source === "widgetData") {
    formData.value.value = {
      entity_type: "",
      entity_variable: "",
    };
  }
};

const rules = computed(() => {
  return [
    formData.value.required && {
      required: true,
      message: "Field is required!",
    },
  ].filter(Boolean);
});

const validationFieldsNames = computed(() => {
  const validationPath = {
    value: [...props.validationPath, props.paramName, "value"],
    source: [...props.validationPath, props.paramName, "source"],
    children: [...props.validationPath, props.paramName, "children"],
  };

  if (formData.value.source === "custom") {
    validationPath.value.push(currentLanguageCode.value);
    validationPath.children.push(currentLanguageCode.value);
  }

  return validationPath;
});

const hasChildren = computed(() => {
  const localizedChildren = getLocalizedValue.value(formData.value);
  return Object.keys(localizedChildren || {}).length > 0;
});
</script>

<template>
  <div
    class="breadcrumbs-dynamic-param"
    :class="{ 'breadcrumbs-dynamic-param--has-children': hasChildren }"
  >
    <DynamicParamField :label="formData.label">
      <a-form-item
        v-if="formData.source !== 'custom'"
        class="margin-bottom-none"
        label="Source"
        layout="vertical"
        :name="validationFieldsNames.source"
        :rules="rules"
      >
        <a-select
          v-model:value="formData.source"
          :disabled="true"
          :options="listToOptions(formData.sources)"
          @change="onSourceChange"
        />
      </a-form-item>

      <a-form-item
        v-if="formData.source === 'custom'"
        class="margin-bottom-none"
        :name="validationFieldsNames.value"
        :rules="rules"
        layout="vertical"
      >
        <a-input
          :value="getLocalizedValue(formData.value, '')"
          placeholder="Type value"
          :on-blur="customFieldUpdate"
          @update:value="onCustomValueChange"
        />
      </a-form-item>

      <DynamicEntityInput
        v-if="formData.source === 'widgetData'"
        v-model="formData.value"
        label-type="Entity"
        label-variable="Entity variable"
        :rules="rules"
        :validation-path="validationFieldsNames.value"
      />
    </DynamicParamField>

    <template v-if="formData.children?.[currentLanguageCode]">
      <BreadcrumbsDynamicParam
        v-for="(_, name) in formData.children![currentLanguageCode]"
        :key="`${currentLanguageCode}-${name}`"
        v-model="formData.children![currentLanguageCode]![name]"
        :param-name="name"
        :validation-path="validationFieldsNames.children"
      />
    </template>
  </div>
</template>

<style lang="scss">
.breadcrumbs-dynamic-param {
  @include flex-column(unset, unset, 16px);

  .dynamic-param-field {
    background-color: transparent;
    padding: 16px 0 0;
  }

  &--has-children {
    .dynamic-param-field {
      background-color: #0000000a;
      padding: 16px;
    }
  }
}
</style>
