<template>
  <div>
    <WidgetSettingsNew
      :dropdown-items="dropdownItems"
      :tabs="tabs"
      @blur-tab="$emit('reset-states')"
      @change="$emit('reset-states')"
      @update-state="$emit('update-state', $event)"
    >
      <template #login_text>
        <ControlsList
          :widget="widget"
          :controls="screenTitleControls"
        />
      </template>

      <template #login_button-design>
        <ControlsList
          :widget="widget"
          :controls="screenButtonDesignControls"
        />
      </template>

      <template #login_button-content>
        <ControlsList
          :widget="widget"
          :controls="screenButtonContentControls"
        />
      </template>

      <template #login_button-states>
        <ControlsList
          :widget="widget"
          :controls="screenButtonStatesControls"
        />
      </template>

      <template #result_title>
        <ControlsList
          :widget="widget"
          :controls="screenTitleControls"
        />
      </template>

      <template #result_message>
        <ControlsList
          :widget="widget"
          :controls="screenTitleControls"
        />
      </template>

      <template #result_timer>
        <ControlsList
          :widget="widget"
          :controls="timerTitleControls"
        />
      </template>

      <template #result_back_button-design>
        <ControlsList
          :widget="widget"
          :controls="screenButtonDesignControls"
        />
      </template>

      <template #result_back_button-content>
        <ControlsList
          :widget="widget"
          :controls="screenButtonContentControls"
        />
      </template>

      <template #result_back_button-states>
        <ControlsList
          :widget="widget"
          :controls="screenButtonStatesControls"
        />
      </template>

      <template #result_image>
        <ControlsList
          :widget="widget"
          :controls="screenImageControls"
        />
      </template>
    </WidgetSettingsNew>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { DropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem } from "~~/helpers/configs/generate-tab-item";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useImageControls } from "~~/composables/widgets/card/useImageControls";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  dropdownItems: DropdownItem[];
  tabs: Record<string, TabItem>;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedField } = storeToRefs(widgetsStore);
const fields = useWidgetFields(props.widget);

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: string }): void;
  (e: "reset-states"): void;
}>();

const screenTitleControls = computed(() => {
  return [
    {
      componentPath: "content/ConfigTextInput",
      valuePath: "value",
      valueSource: selectedField.value,
      className: "config-group-last-element",
      options: {
        placeholder: "Enter text",
        label: "Active",
        active: true,
      },
    },
    typography({ source: selectedField.value! }),
    {
      componentPath: "ConfigLinkType",
      valuePath: "options.link",
      valueSource: selectedField.value,
      options: {
        label: "Link",
        "onShow-states-settings": () => {},
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ];
});

const timerTitleControls = computed(() => {
  return [
    {
      componentPath: "content/ConfigTextInput",
      valuePath: "value",
      valueSource: selectedField.value,
      className: "config-group-last-element",
      options: {
        placeholder: "Enter text",
        label: "Active",
        active: true,
      },
    },
    typography({ source: selectedField.value! }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ];
});

const buttonState = computed<string>(() => {
  return props.states[selectedField.value?.name!];
});

const {
  buttonStatesControls: screenButtonStatesControls,
  buttonDesignControls: screenButtonDesignControls,
  buttonContentControls: screenButtonContentControls,
} = useButtonControls({
  state: buttonState,
  stateHandler: (state: string) => {
    emit("update-state", { state: selectedField.value?.name!, value: state });
  },
});

const imageField = computed(() => {
  return fields.value.result_image.field;
});

const { imageControls: screenImageControls } = useImageControls(
  null,
  imageField,
  toRef("result_image")
);
</script>
