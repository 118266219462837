<template>
  <div
    :style="cardStyles"
    class="bonuses-widget-card"
  >
    <slot name="left">
      <div>
        <slot name="image"></slot>
      </div>
    </slot>

    <div class="bonuses-widget-card__content">
      <slot
        name="title"
        :align="cardsAlignment"
      ></slot>

      <slot
        name="status"
        :align="cardsAlignment"
      ></slot>
      <slot
        name="date"
        :align="cardsAlignment"
      ></slot>
      <slot
        name="description"
        :align="cardsAlignment"
      ></slot>
      <slot name="progress_bar"></slot>
      <slot
        name="amounts"
        :align="cardsAlignment"
      ></slot>
      <slot
        name="terms_title"
        :align="cardsAlignment"
      ></slot>
      <slot
        name="action_button"
        :align="cardsAlignment"
      ></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import {
  Alignment,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getFlexPosition } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  field: IWidgetField;
  state: State;
}>();

const cardsAlignment = computed<Alignment>(() => {
  return props.field.options.alignment;
});

const activeStateOptions = computed<IWidgetOptions>(() => {
  if (props.state === State.DEFAULT) {
    return props.field.options;
  }

  return props.field.options.states[props.state];
});

const cardStyles = computed<ElementStyle>(() => {
  const direction =
    props.field.options.layout === DisplayOrientation.HORIZONTAL
      ? "row"
      : "column";

  return {
    ...getCommonStyles({
      ...props.field.options,
      ...activeStateOptions.value,
    }),
    ...getStylesAsVars(
      {
        flexAlignment: getFlexPosition(props.field.options.alignment),
        textAlignment: props.field.options.alignment,
      },
      "card"
    ),
    flexDirection: direction,
  };
});
</script>

<style lang="scss">
.bonuses-widget-card {
  display: flex;

  & > div {
    &:first-child {
      flex-grow: 0;
    }

    &:nth-child(2) {
      flex-grow: 2;
    }
  }

  &__content {
    @include flex(flex-start, flex-start, 0);
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
