<template>
  <div
    class="config-side-menu"
    :class="{
      'config-side-menu--opened': isSideMenuConfigOpened,
      'config-side-menu--layout-view': activeStep === Step.LAYOUTS,
    }"
  >
    <div class="config-side-menu__subtitle-container">
      <slot name="title"></slot>
      <CommonIcon
        name="custom:close-icon"
        class="config-side-menu__close-button pointer"
        @click="closeSideMenu()"
      />
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from "~~/store/ui";
import { Step } from "~~/models/step.enum";

const uiStore = useUiStore();
const { isSideMenuConfigOpened, activeStep } = storeToRefs(uiStore);
const { closeSideMenu } = uiStore;
</script>

<style lang="scss">
.config-side-menu {
  position: fixed;
  top: ($header-height + $space-lg);
  left: 0;
  height: calc(100vh - $header-height);
  width: $propertybar-width;
  background-color: $c-white;
  border-right: 1px solid $c-grey-15;
  transform: translateX(-($propertybar-width));
  transition: transform $base-transition;
  z-index: -1;

  &--opened {
    transform: translateX($sidebar-size);
    z-index: 1;
  }

  &--layout-view {
    top: $header-height;
  }

  &__subtitle-container {
    @include inline-flex(center, space-between);
    margin: 0;
    color: $c-black;
    width: 100%;
    padding: $space-m $space-m 0;
    font-weight: 600;
  }
}
</style>
