import type { Ref, StyleValue } from "vue";

import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  IFillControl,
  SpacingKeyName,
  State,
  TextPosition,
  TextPositionComplex,
} from "~~/models/widgets/widget-controls.model";
import {
  DepositListScreen,
  FAIL_SCREEN_FIELDS,
  FORM_CARD_FIELDS,
  FormScreen,
  HIDE_DEPOSIT_LIST_FORM_SETTINGS_DICT,
  PROVIDERS_FIELDS,
  SUCCESS_SCREEN_FIELDS,
} from "~~/models/widgets/form.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getColorStyle,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontSizeStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { Sizing } from "~~/models/grid.interface";
import { useStates } from "~~/composables/widgets/common/useStates";
import { INITIAL_STATES } from "~~/constants/widget-details/form/dynamic-fields";

import { useFillBackground } from "../useFillBackground";

export const useDepositListSharedLogic = (
  widget: Ref<IWidgetWithFields>,
  activeView: Ref<string>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DEFAULT_STATES = {
    providers_item: State.DEFAULT,
    title: State.DEFAULT,
    provider_title: State.DEFAULT,
    providers_title: State.DEFAULT,
    provider_card: State.DEFAULT,
    form_back_button: State.DEFAULT,
    providers_item_button: State.DEFAULT,
    providers_item_title: State.DEFAULT,
    ...INITIAL_STATES,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const { handleStateUpdate, resetStates } = useStates({
    defaultStates: DEFAULT_STATES,
    states,
  });
  const depositListFilteredFields = computed<IWidgetField[]>(() => {
    return customGetFormElements(widget.value);
  });

  const customGetFormElements = (widget: IWidgetWithFields): IWidgetField[] => {
    return widget.fields.filter(field => {
      return (
        field.name !== "form" &&
        !field.options._custom &&
        !SUCCESS_SCREEN_FIELDS.includes(field.name) &&
        !FAIL_SCREEN_FIELDS.includes(field.name) &&
        !PROVIDERS_FIELDS.includes(field.name) &&
        !FORM_CARD_FIELDS.includes(field.name) &&
        field.type !== "UnknownField"
      );
    });
  };

  const shouldShowFormSettings = computed<boolean>(() => {
    const field = selectedField.value;

    if (!field) return false;

    return !HIDE_DEPOSIT_LIST_FORM_SETTINGS_DICT.includes(field.name);
  });

  const handleUpdateActiveView = (value: string): void => {
    if (value === FormScreen.FORM) {
      activeView.value = DepositListScreen.DATA_FILLED;
      return;
    }
    activeView.value = value;
  };

  const activeStateOptions = computed<any>(() => {
    return states.value.providers_item !== State.DEFAULT
      ? fields.value.providers_item.field.options.states[
          states.value.providers_item
        ]
      : fields.value.providers_item.field.options;
  });

  const { fillBackgroundStyling } = useFillBackground(ref(activeStateOptions));

  const cardsListContainerStyles = computed<ElementStyle>(() => {
    return {
      gap: `${widget.value.options.gridSettings.minCardsGap}px`,
      rowGap: `${widget.value.options.gridSettings.rowGap}px`,
      justifyContent: widget.value.options.gridSettings.alignment,
    };
  });

  const cardItemStyle = computed<ElementStyle>(() => {
    const cardsOptions = fields.value.providers_item.field.options;
    const fill = fillBackgroundStyling.value;
    const border = getBorderStyle(
      states.value.providers_item !== State.DEFAULT
        ? cardsOptions.states[states.value.providers_item]?.border
        : cardsOptions?.border
    );
    const shadow = getBoxShadowStyle(
      states.value.providers_item !== State.DEFAULT
        ? cardsOptions.states[states.value.providers_item]?.shadow
        : cardsOptions?.shadow
    );
    const cornerRadius = getCornerRadiusStyle(cardsOptions.cornerRadius);
    const padding = getSpacing(cardsOptions.padding, SpacingKeyName.PADDING);

    const stylesObj: ElementStyle = {
      ...padding,
      ...border,
      ...shadow,
      ...cornerRadius,
      ...fill,
    };

    stylesObj.width = `${widget.value.options.gridSettings.cardsSize}px`;

    if (
      !layoutPosition.value ||
      layoutPosition.value.position === TextPosition.TOP
    ) {
      return stylesObj;
    } else {
      stylesObj.alignItems = layoutPosition.value.alignment || undefined;
    }

    return stylesObj;
  });

  const paymnetMethodPageInlineStyles = computed<ElementStyle>(() => {
    const contentWidth =
      widget.value.options.states[activeView.value].contentWidth;

    if (contentWidth?.type === Sizing.FIXED) {
      return {
        width: `${contentWidth?.width}px`,
        margin: "0 auto",
      };
    }
    return {
      width: "100%",
    };
  });

  const tooltipField = computed<IWidgetField | undefined>(() => {
    return fields.value.providers_item_tooltip.field;
  });

  const tooltipStyles = computed<ElementStyle>(() => {
    if (!tooltipField.value) {
      return {};
    }

    const textColor = getColorFromHex(
      tooltipField.value.options.color
    ) as StyleValue;

    const styles = {
      ...getSpacing(tooltipField.value.options.padding, SpacingKeyName.PADDING),
      ...getCornerRadiusStyle(tooltipField.value.options.cornerRadius),
      ...getBackgroundColorStyle(tooltipField.value.options.fill),
      ...getBorderStyle(tooltipField.value.options.border),
      ...getBoxShadowStyle(tooltipField.value.options.shadow),
      ...getFontSizeStyle(tooltipField.value.options.theme),
      ...getDecorationValue(tooltipField.value.options.decoration),
      textColor,
    };

    return getStylesAsVars(styles as never);
  });

  const layoutPosition = computed<TextPositionComplex>(() => {
    return fields.value.providers_item.field.options.layoutPosition;
  });

  const tooltipIconOptions = computed<{
    icon: string;
    color: IFillControl;
  } | null>(() => {
    if (!tooltipField.value) {
      return null;
    }

    return tooltipField.value.options.textChooseIcon;
  });

  const tooltipIconComponent = computed<string | null>(() => {
    if (!tooltipIconOptions.value) {
      return null;
    }

    return "ant-design:question-circle-outlined";
  });

  const tooltipIconColorStyle = computed<ElementStyle>(() => {
    if (!tooltipIconOptions.value || !tooltipField.value) {
      return {};
    }

    return {
      ...getColorStyle(tooltipIconOptions.value.color),
    };
  });

  const cardsTitleInlineStyles = computed<ElementStyle>(() => {
    return {
      justifyContent: fields.value.providers_item_title.field.options.alignment,
      ...getSpacing(
        fields.value.providers_item_title.field.options.padding,
        SpacingKeyName.PADDING
      ),
    };
  });

  return {
    shouldShowFormSettings,
    handleUpdateActiveView,
    fields,
    cardsListContainerStyles,
    cardItemStyle,
    layoutPosition,
    cardsTitleInlineStyles,
    tooltipStyles,
    tooltipIconComponent,
    tooltipIconColorStyle,
    customGetFormElements,
    depositListFilteredFields,
    paymnetMethodPageInlineStyles,
    handleStateUpdate,
    resetStates,
    states,
  };
};
