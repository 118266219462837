import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

export const useCasinoListFenixItems = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value);

  const SETTINGS_ITEMS = [
    {
      label: "Back Button",
      value: "back_button",
      isActiveValueSource: fields.value.back_button.field.options,
    },
    {
      label: "Header",
      value: "header",
    },
  ];

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Back Button", "back_button"),
    generateDropdownItem("Games List", "games_list"),

    generateDropdownItem("Header", "header", "header"),
    generateDropdownItem("Page Title", "page_title", "header"),
    generateDropdownItem("Search Result", "search_result", "header"),
    generateDropdownItem("Sorting", "sorting", "header"),
    generateDropdownItem("Search", "search", "header"),

    generateDropdownItem(
      "Category / Subcategory",
      "games_category",
      "category"
    ),
    generateDropdownItem(
      "Category / Subcategory Title",
      "games_category_title",
      "category"
    ),
    generateDropdownItem("Games Button", "games_category_button", "category"),
    generateDropdownItem(
      "Hover Button",
      "games_category_hover_button",
      "category"
    ),
    generateDropdownItem(
      "Category / Subcategory List",
      "games_category_list",
      "category"
    ),

    generateDropdownItem("All Games", "all_games", "all"),
    generateDropdownItem("All Games Title", "all_games_title", "all"),
    generateDropdownItem("All Games List", "all_games_list", "all"),
    generateDropdownItem("Show More Button", "show_more_button", "all"),

    generateDropdownItem("Game Cards", "game_cards", "cardsElements"),
    generateDropdownItem("Game Cover", "game_cover", "cardsElements"),
    generateDropdownItem("Game Name", "game_name", "cardsElements"),

    generateDropdownItem("Hover Content", "hover_content", "hoverContent"),
    generateDropdownItem("Favourite Icon", "favorite_icon", "hoverContent"),
    generateDropdownItem("Promo Tag", "promo_tag", "hoverContent"),
    generateDropdownItem("Game Name Hover", "game_title", "hoverContent"),
    generateDropdownItem(
      "Play for money button",
      "play_money_button",
      "hoverContent"
    ),
    generateDropdownItem(
      "Play for free button",
      "play_free_button",
      "hoverContent"
    ),
    generateDropdownItem("Promo group list", "promo_group_list", "promo_group"),
    generateDropdownItem("Promo cards", "promo_cards", "promo_group"),
    generateDropdownItem("Image", "promo_image", "promo_group"),
    generateDropdownItem("Title", "promo_title", "promo_group"),
    generateDropdownItem("Description", "promo_description", "promo_group"),
    generateDropdownItem("Button group", "promo_button_group", "promo_group"),
    generateDropdownItem("Button", "promo_button_1", "promo_group"),
    generateDropdownItem("Button", "promo_button_2", "promo_group"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    back_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    page_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    search_result: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    sorting: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    search: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    games_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    games_category: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    games_category_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    games_category_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    games_category_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    games_category_hover_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    all_games: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    all_games_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    all_games_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    game_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    show_more_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    game_cover: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_tag: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_money_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_free_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_group_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_button_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
