import { type Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/casino-promoted-categories/useCasinoPromotedCategoriesItems";

import { useConfig as useLangWidgetSharedConfig } from "../languages-list/useLangsConfig";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);

  const {
    widgetDesignControls,
    widgetContentControls,

    dropdownContainerDesignConfig,
    dropdownContainerContentConfig,
    dropdownContainerStatesConfig,

    dropdownMenuControls,

    menuItemsDesignControls,
    menuItemsContentControls,
    menuItemsStatesControls,
  } = useLangWidgetSharedConfig(widget, states, emit);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    dropdownContainerDesignConfig,
    dropdownContainerContentConfig,
    dropdownContainerStatesConfig,

    dropdownMenuControls,

    menuItemsDesignControls,
    menuItemsContentControls,
    menuItemsStatesControls,
  };
};
