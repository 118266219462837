import { RADIO_GROUP_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/radio-group-styling";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { getDefaultFillValue } from "~~/constants/configs/common/widget-initial";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 36;

const v27WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;

  if (
    ![
      "RegisterFormWidget",
      "SetPasswordFormWidget",
      "ChangePasswordWidget",
      "LoginFormWidget",
      "UserProfileFormWidget",
      "PaymentProviderFormFenixWidget",
      "DepositListWidget",
      "WithdrawalListWidget",
      "UserProfileFormWidget",
      "TransactionHistoryFenixWidget",
    ].includes(widget.name)
  ) {
    return widget;
  }

  const currentOptions =
    widget.options._customFields?.radio_group_styling?.options;

  if (!currentOptions) {
    return widget;
  }

  const radioInitial = RADIO_GROUP_STYLE_INITIAL();

  const radioBorder = currentOptions.border;
  const radioIcon = currentOptions.radioIcon;
  const textColor = currentOptions.color;
  const radioCornerRadius = currentOptions.cornerRadius;
  const decoration = currentOptions.decoration;
  const radioFill = currentOptions.fill;
  const fontFamily = currentOptions.fontFamily;
  const theme = currentOptions.theme;
  const size = currentOptions.size;

  radioInitial.options.radio.fill = radioFill;
  radioInitial.options.radio.border = radioBorder;
  radioInitial.options.radio.icon = radioIcon || getDefaultFillValue("#000000");

  radioInitial.options.cornerRadius = radioCornerRadius;
  radioInitial.options.color = textColor;
  radioInitial.options.decoration = decoration;
  radioInitial.options.fontFamily = fontFamily;
  radioInitial.options.theme = theme;
  radioInitial.options.size = size;

  for (const state in currentOptions.states) {
    const currOptions = currentOptions.states[state];

    const radioBorder = currOptions.border as IWidgetOptions;
    const radioIcon = currOptions.radioIcon || getDefaultFillValue();
    const textColor = currOptions.color;
    const radioFill = currOptions.fill;

    const initialStates = radioInitial.options.states as Record<
      string,
      IWidgetOptions
    >;

    initialStates[state].radio.border = radioBorder;
    initialStates[state].radio.icon = radioIcon;
    initialStates[state].radio.fill = radioFill;
    initialStates[state].color = textColor;
  }

  widget.options._customFields.radio_group_styling.options =
    radioInitial.options;

  return widget;
};

export default v27WidgetMigration;
