<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <SimpleTextWidgetSettings
      :widget="widget"
      :title-link-state="states.title"
      @update-title-link-state="
        handleStateUpdate({ state: 'title', value: $event })
      "
      @reset-states="resetStates"
    />

    <div class="simple-text-widget">
      <WidgetField
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :state="states.title"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useSimpleTextWidgetInit } from "~~/composables/widgets/simple-text/useSimpleTextWidgetInit";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { states, fields, init, handleStateUpdate, resetStates } =
  useSimpleTextWidgetInit(toRef(props.widget));

init();
</script>

<style lang="scss">
.simple-text-widget {
  position: relative;
}
</style>
