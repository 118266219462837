import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";

const WIDGET_VERSION = 6;

const generateIconRightDefaultOptions = () => ({
  color: {
    color: "#000000",
    opacity: 100,
  },
  gap: 5,
});

const v6WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  const fieldStyling = widget.options?._customFields?.fields_styling;

  if (fieldStyling && !fieldStyling.options.iconRight) {
    fieldStyling.options.iconRight = generateIconRightDefaultOptions();

    for (const state in fieldStyling.options.states) {
      fieldStyling.options.states[state].iconRight =
        generateIconRightDefaultOptions();
    }
  }

  const tooltipsStyling = widget.options?._customFields?.tooltips_styling;

  if (tooltipsStyling && !tooltipsStyling.options?.textChooseIcon?.gap) {
    tooltipsStyling.options.textChooseIcon.gap = 5;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v6WidgetMigration;
