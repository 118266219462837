import { Sizing } from "~~/models/grid.interface";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 44;

const v44WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }
  widget.options.__VERSION = WIDGET_VERSION;

  if (
    ![
      "EventWidget",
      "BetsListWidget",
      "BetslipFenixWidget",
      "ComingSportEventsWidget",
      "DepositListWidget",
      "WithdrawalListWidget",
      "FavouritesEventsLiveWidget",
      "ChangePasswordWidget",
      "LoginFormWidget",
      "PaymentProviderFormFenixWidget",
      "RegisterFormWidget",
      "SetPasswordFormWidget",
      "UserProfileFormWidget",
      "PromotedBetsListWidget",
      "RacingSportsbookLiveWidget",
      "RacingSportsEventLiveWidget",
      "SearchFieldWidget",
      "SearchResultsWidget",
      "SportsBookLiveWidget",
      "SportsBookWidget",
      "SportsBookPrematchFenixWidget",
      "SportsBookPreMatchFenixMobileWidget",
      "TransactionHistoryWidget",
    ].includes(widget.name)
  ) {
    widget.options.contentWidth = {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    };
  }

  return widget;
};
export default v44WidgetMigration;
