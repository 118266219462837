import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useNoItemsTextConfig } from "~~/composables/widgets/common/useNoItemsTextConfig";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useSearchResultEventCardConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { eventCardsNoContentControls, stateControls } = useNoItemsTextConfig(
    toRef({
      widget: widget.value,
      states: states.value,
      stateName: "cards",
      noTextFieldName: "no_event_title",
      customLabels: {
        [State.NO_ACTIVE]: "No search results",
      },
    }),
    emit
  );

  /* 
   Event card
  */

  const eventCardDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.event_card.field,
      }),
    ];
  });

  const eventCardContentControls = computed<ControlProp[]>(() => {
    if (states.value.cards === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,

      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.displayMode.layout",
            valueSource: fields.value.event_card.field,
            className: "event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:layout-column-1",
                  value: 1,
                },
                {
                  icon: "custom:layout-column-2",
                  value: 2,
                },
                {
                  icon: "custom:layout-column-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.displayMode.cardsDistance",
            valueSource: fields.value.event_card.field,
            options: {
              label: "Distance between cards",
            },
          },
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.displayMode.eventInfoLayout",
            valueSource: fields.value.event_card.field,
            className: "event-cards-radio",
            options: {
              label: "Event info layout",
              items: [
                {
                  icon: "custom:event-info-row",
                  value: "row",
                },
                {
                  icon: "custom:event-info-column",
                  value: "column",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode.betItemsLayout",
            valueSource: fields.value.event_card.field,
            options: {
              label: "Bet items layout",
              items: [
                { value: "vertical", label: "Vertical" },
                { value: "horizontal", label: "Horizontal" },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.displayMode.betItemsDistance",
            valueSource: fields.value.event_card.field,
            options: {
              label: "Distance between bet items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Time",
              value: "event_time",
            },
            {
              label: "Team / Player",
              value: "event_title",
            },
            {
              label: "Bet items",
              value: "bet_items",
            },
            {
              label: "Event button link",
              value: "event_live_status",
            },
          ],
        },
      },
    ];
  });

  return {
    eventCardDesignControls,
    eventCardContentControls,
  };
};
