<script lang="ts" setup>
import axios from "axios";
import { onClickOutside } from "@vueuse/core";

import { debounce } from "~~/assets/utils";
import { usePermissions } from "~~/composables/permissions/usePermissions";

interface IProps {
  modelValue?: string;
  label?: string;
  allowDisable?: boolean;
  isActive?: boolean;
}

const emits = defineEmits<{
  (e: "update:modelValue", event: string): void;
  (e: "update:active", event: boolean): void;
}>();

const props = withDefaults(defineProps<IProps>(), {
  modelValue: "mdi:car-pickup",
  label: undefined,
});

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const API_URL = "https://api.iconify.design";

const target = ref(null);

const icons = ref([]);
const isLoading = ref(false);
const isOpened = ref(false);

onClickOutside(target, () => {
  isOpened.value = false;
});

const onPick = (icon: string) => {
  isOpened.value = false;

  emits("update:modelValue", icon);
};

const handleActiveUpdate = (value: boolean): void => {
  emits("update:active", value);
};

const onLoad = debounce(async (event: any) => {
  try {
    const query = (event.target as HTMLInputElement).value;

    isLoading.value = true;

    const { data } = await axios(`${API_URL}/search`, {
      params: {
        query,
        pretty: 1,
        limit: 999,
      },
    });

    icons.value = data.icons;
  } finally {
    isLoading.value = false;
  }
}, 300);
</script>

<template>
  <div v-if="hasContentPermissions">
    <ConfigToggle
      v-if="allowDisable"
      class="m-b-16"
      :model-value="isActive"
      size="small"
      :label="label ? label : ''"
      :allow-disable="allowDisable"
      is-field-item-toggle
      :show-options="false"
      @update:model-value="handleActiveUpdate($event)"
    />

    <p
      v-if="label && !allowDisable"
      class="m-b-4"
    >
      {{ label }}
    </p>

    <div
      ref="target"
      class="iconify-picker"
    >
      <div
        class="iconify-picker-field"
        @click="isOpened = !isOpened"
      >
        <div class="iconify-picker-field__icon">
          <CommonIcon :name="props.modelValue" />
        </div>

        <span class="iconify-picker-field__name">
          {{ props.modelValue }}
        </span>
      </div>

      <div
        v-show="isOpened"
        class="iconify-picker-container"
      >
        <div class="iconify-picker-container__search">
          <input
            type="text"
            class="iconify-picker-container__search-input"
            placeholder="Search for icons"
            @input="onLoad"
          />
        </div>

        <div class="iconify-picker-container__icons">
          <div
            v-for="icon in icons"
            :key="icon"
            class="iconify-picker-container__icon"
            @click="onPick(icon)"
          >
            <CommonIcon :name="icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.iconify-picker {
  position: relative;
  max-width: 300px;
  width: 100%;
}

.iconify-picker-field {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  &__icon {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
    padding: 4px 8px;
    color: #292929;
  }

  &__name {
    padding: 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.iconify-picker-container {
  position: absolute;
  top: calc(100% + 4px);
  padding: 8px;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  z-index: 9;

  &__search {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;

    input {
      border: none;
      outline: none;
      width: 100%;
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 200px;
    overflow-y: auto;
  }

  &__icon {
    width: 30px;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #eee;
    }
  }
}
</style>
