<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container config-image-position"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label || "Image position" }}
    </p>

    <div class="config-image-position__content">
      <div class="config-image-position__inputs">
        <ConfigInput
          v-number
          :model-value="modelValue?.x"
          :stretch-input="true"
          :is-number="true"
          :input-props="{
            min: minValue,
            max: maxValue,
            controls: false,
          }"
          class="config-range-slider__input"
          @update:model-value="handleUpdate({ x: $event })"
        >
          <template #prefix>
            <span> X </span>
          </template>
          <template #suffix>
            <span> % </span>
          </template>
        </ConfigInput>

        <ConfigInput
          v-number
          :model-value="modelValue?.y"
          :stretch-input="true"
          :is-number="true"
          :input-props="{
            min: minValue,
            max: maxValue,
            controls: false,
          }"
          class="config-range-slider__input"
          @update:model-value="handleUpdate({ y: $event })"
        >
          <template #prefix>
            <span> Y </span>
          </template>
          <template #suffix>
            <span> % </span>
          </template>
        </ConfigInput>
      </div>

      <div class="config-image-position__alignment">
        <ConfigAlignmentFull
          :custom-align-values="ALIGN_VALUES"
          label=""
          :model-value="modelValue?.value"
          @update:model-value="handleUpdate({ value: $event })"
        >
          <template #icon>
            <CommonIcon name="ant-design:picture-filled" />
          </template>
        </ConfigAlignmentFull>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  Alignment,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue?: Record<string, string | null | undefined>;
  label?: string;
  isBold?: boolean;
  maxValue: number;
  minValue: number;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: Record<string, string | null | undefined>
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const ALIGN_VALUES = [
  {
    title: "Top Left",
    value: `${VerticalPosition.TOP} ${Alignment.LEFT}`,
  },
  {
    title: "Top Center",
    value: `${VerticalPosition.TOP} ${Alignment.CENTER}`,
  },
  {
    title: "Top Right",
    value: `${VerticalPosition.TOP} ${Alignment.RIGHT}`,
  },
  {
    title: "Center Left",
    value: `${VerticalPosition.CENTER} ${Alignment.LEFT}`,
  },
  {
    title: "Center Center",
    value: `${VerticalPosition.CENTER} ${Alignment.CENTER}`,
  },
  {
    title: "Center Right",
    value: `${VerticalPosition.CENTER} ${Alignment.RIGHT}`,
  },
  {
    title: "Bottom Left",
    value: `${VerticalPosition.BOTTOM} ${Alignment.LEFT}`,
  },
  {
    title: "Bottom Center",
    value: `${VerticalPosition.BOTTOM} ${Alignment.CENTER}`,
  },
  {
    title: "Bottom Right",
    value: `${VerticalPosition.BOTTOM} ${Alignment.RIGHT}`,
  },
];

const handleUpdate = (value: Record<string, string>): void => {
  if (value.value) {
    emit("update:modelValue", {
      x: null,
      y: null,
      ...value,
    });

    return;
  }

  emit("update:modelValue", {
    ...(props.modelValue || {}),
    value: null,
    ...value,
  });
};
</script>

<style lang="scss">
.config-image-position {
  &__content {
    @include flex(flex-start, flex-start, 8px);
  }

  &__inputs {
    @include flex-column(flex-start, flex-start, 8px);
    align-self: stretch;

    .ant-input-number-prefix {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border: 1px solid $c-grey-15;
      margin: 0;
      border-right: none;
      padding-left: 12px;
      color: $c-grey-45;
    }

    .ant-input-number-affix-wrapper {
      div.ant-input-number {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .ant-input-number-group-addon {
      border-radius: 2px;
    }
  }

  &__alignment,
  &__inputs {
    flex: 1;
  }

  &__alignment {
    .config-alignment-full {
      padding: 0;
      border-bottom: none;
    }

    .wconfig-common-radio__radio-group-container {
      padding: 6px 8px;
      border-radius: 2px;
    }

    .ant-radio-button-wrapper {
      padding: 0;
      background: transparent;
      height: 20px;
    }
  }

  .config-input {
    flex: 1;
  }

  .ant-input-number-affix-wrapper,
  .ant-input-number-group-wrapper,
  .ant-input-number-wrapper,
  .ant-input-group,
  .ant-input-number-input,
  .ant-input-number-input-wrap {
    height: 100%;
  }
}
</style>
