<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-styles>
          <ControlsList
            :widget="widget"
            :controls="widgetStylesControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #categories>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template #providers>
          <ControlsList
            :widget="widget"
            :controls="dropdownFieldControls"
          />
        </template>

        <template
          v-for="(controlValues, controlOptionName) in formStylesControls"
          #[controlOptionName]
          :key="controlOptionName"
        >
          <ControlsList
            :widget="widget"
            :controls="controlValues"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useCasinoGamesFiltersWidgetConfig } from "~~/composables/widgets/casino/filter/useCasinoGamesFiltersWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "categoryId": {
      label: "Category ID",
      sources: ["pageRoute"],
      required: false,
    },
    "isCategoryIdSlug": {
      label: "Category ID is slug",
      description: "Possible values: Yes | No",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,
  widgetStylesControls,
  formStylesControls,
  dropdownFieldControls,
} = useCasinoGamesFiltersWidgetConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
