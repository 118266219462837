import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

const SETTINGS_ITEMS = [
  {
    label: "Title",
    value: "title",
  },
  {
    label: "Event cards",
    value: "event_cards",
  },
];

export const useItems = (widget: Ref<IWidgetWithFields>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Event cards", "event_cards"),
    generateDropdownItem(
      "Suspended status",
      "event_suspended_status",
      "event_cards"
    ),
    generateDropdownItem("Time", "event_time", "event_cards"),
    generateDropdownItem("League", "event_league", "event_cards"),
    generateDropdownItem("Event info", "event_info", "event_cards"),
    generateDropdownItem("Market name", "market_name", "event_cards"),
    generateDropdownItem("Bet items", "bet_items", "event_cards"),
    generateDropdownItem("Event button link", "event_button", "event_cards"),
    generateDropdownItem("Team / player", "event_team_player", "event_info"),
    generateDropdownItem("Score", "event_score", "event_info"),
    generateDropdownItem("Favorite icon", "favourites_button", "event_cards"),
    generateDropdownItem("Stream icon", "stream_icon", "event_cards"),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    event_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_suspended_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    event_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
