<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form-element-field">
    <div
      :style="formSettingsStyle"
      class="form-element-field__fields"
    >
      <div
        v-for="(innerField, index) in fieldsList"
        :key="innerField.id"
        class="form-element-field__field"
        :class="{
          'hidden': !innerField.options._active,
        }"
      >
        <slot
          v-if="innerField.options._active"
          :name="innerField.name"
        >
          <WidgetField
            v-if="FORM_FIELD_COMPONENTS[innerField.type]"
            :widget="widget"
            :field="innerField"
            :custom-dropdown-value="innerField.type"
            :no-overflow="true"
          >
            <component
              :is="FORM_FIELD_COMPONENTS[innerField.type]"
              :key="innerField.name"
              :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[index] = el;
              }
              "
              :field="innerField"
              :widget="widget"
              :styling-fields="getFieldStylings(innerField.type)"
              :states="states"
            />
          </WidgetField>

          <span v-else> No field data {{ innerField.type }} </span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { FORM_FIELD_COMPONENTS } from "~~/constants/widget-details/form/form-dynamic-components";
import { TriggerComponent } from "~~/models/widgets/form.model";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getBackgroundFillStyle } from "~~/assets/utils/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  formElements: string[];
  states: Record<string, string>;
}>();

const fieldsList = computed<IWidgetField[]>(() => {
  return props.widget.fields
    .filter(field => {
      if (field.options._parent === props.field.name) {
        return true;
      }

      if (props.formElements.includes(field.name)) {
        return true;
      }

      return false;
    })
    .sort((a, b) => a.options.position - b.options.position);
});

const {
  fieldsRefsList,

  getFieldStylings,
} = useFormInner(props.widget, fieldsList);

const formSettingsStyle = computed(() => {
  return {
    ...getCommonStyles(props.field.options),
    gap: getPxValueFromNumber(props.field.options.verticalSpace),
    ...getBackgroundFillStyle(props.field.options.fillImageColor),
  };
});
</script>

<style lang="scss">
.form-element-field {
  &__fields {
    @include flex-column(stretch, flex-start, 0);
  }
}
</style>
