import { useI18n } from "vue-i18n";

import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  State,
  ResizingType,
  Align,
  DisplayOrientation,
  Alignment,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultAlignValue,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import {
  TITLE_INITIAL,
  BUTTON_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { prefillCustomFields } from "~~/assets/utils/widget/form";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import {
  ELEMENTS_INITIAL,
  HOVER_CONTENT_INITIAL,
  ICON_INITIAL,
  TAG_INITIAL,
} from "~~/constants/configs/casino/initial-constants";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

export const useCasinoListInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const { t } = useI18n();
  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const DEFAULT_STATES = {
    game_title: State.DEFAULT,
    game_cards: State.DEFAULT,
    games_status: State.WITH_ACTIVE_GAMES,
    promo_tag: State.DEFAULT,
    play_money_button: State.DEFAULT,
    play_free_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(
    [],
    {
      bindingParams: {},
    },
    {
      spacing: {
        padding: getDefaultSpacing(16),
        margin: getDefaultSpacing(),
      },
    }
  );

  const createFields = (): void => {
    // Comment if use fake widget data
    if (!widgetCacheData.value) {
      return;
    }
    // end of comment

    const widgetFields: IWidgetField[] = [];

    // Uncomment and replace if use fake widget data
    // for (const key in fields.value) {
    //   const fieldDetails = fields.value[key].field;

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      widgetFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title || "",
          fieldDetails.value,
          {},
          fieldDetails.validation
        )
      );
    }
    // Uncomment and replace if use fake widget data
    // widgetsStore.updateWidgetFields(widget, widgetFields);

    widgetsStore.updateWidgetFields(widget, [...widgetFields]);
  };

  const initWidgetFieldsOptions = (): void => {
    const initialNoGamesTitleOptions = prefillEmptyOptions(
      fields.value.no_games_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialGamesState = () => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 6),
        }),
        shadow: getDefaultShadow(),
      };
    };

    const initialGamesStateHover = () => {
      return {
        ...initialGamesState(),
        overlay: getDefaultFillValue("#000000", 60),
      };
    };

    const initialGamesOptions = prefillEmptyOptions(
      fields.value.game_cards.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing"],
        values: {
          fill: getDefaultFillValue("#ffffff"),
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000", 6),
          }),
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          size: {
            width: getDefaultSizeValue(ResizingType.FILL, "224"),
            height: getDefaultSizeValue(ResizingType.FIXED, "188"),
          },
        },
        additionalData: {
          options: {
            padding: getDefaultSpacing(16),
            display: {
              layout: DisplayOrientation.HORIZONTAL,
              gap: {
                row: 24,
                col: 24,
              },
              alignment: getDefaultAlignValue([Align.MIDDLE, Align.START]),
            },
            states: {
              [State.HOVER]: initialGamesStateHover(),
              [State.ACTIVE]: initialGamesState(),
            },
          },
        },
      }).options
    );

    const initialPhotoOptions = prefillEmptyOptions(
      fields.value.photo.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size", "fill", "spacing"],
      }).options
    );

    const initialGamesElementsOptions = prefillEmptyOptions(
      fields.value.cards_elements.field.options,
      getInitialOptions({
        data: ELEMENTS_INITIAL(),
      }).options
    );

    const initialHoverContentOptions = prefillEmptyOptions(
      fields.value.hover_content.field.options,
      getInitialOptions({
        data: HOVER_CONTENT_INITIAL(),
      }).options
    );

    const initialFavIconOptions = prefillEmptyOptions(
      fields.value.favorite_icon.field.options,
      getInitialOptions({
        data: ICON_INITIAL(),
      }).options
    );

    const initialTagOptions = prefillEmptyOptions(
      fields.value.promo_tag.field.options,
      getInitialOptions({
        data: TAG_INITIAL(),
      }).options
    );

    const initialGameTitleOptions = prefillEmptyOptions(
      fields.value.game_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#ffffff"),
          theme: getDefaultTheme(19),
          alignment: Alignment.CENTER,
        },
      }).options
    );

    fields.value.play_money_button.field.value =
      useLocalizedValue().transformToLocalizedValue(
        t("widgets.CasinoGamesListWidget.play_for_money")
      ).localizedValue;

    const initialPlayMoneyButtonOptions = prefillEmptyOptions(
      fields.value.play_money_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      }).options
    );

    fields.value.play_free_button.field.value =
      useLocalizedValue().transformToLocalizedValue(
        t("widgets.CasinoGamesListWidget.play_for_free")
      ).localizedValue;

    const initialPlayFreeButtonOptions = prefillEmptyOptions(
      fields.value.play_free_button.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          fill: getDefaultFillValue("#ffffff"),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_games_title.field,
      initialNoGamesTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.game_cards.field,
      initialGamesOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.photo.field,
      initialPhotoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.cards_elements.field,
      initialGamesElementsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.hover_content.field,
      initialHoverContentOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.favorite_icon.field,
      initialFavIconOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.promo_tag.field,
      initialTagOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.game_title.field,
      initialGameTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.play_money_button.field,
      initialPlayMoneyButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.play_free_button.field,
      initialPlayFreeButtonOptions
    );
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();

      fields.value.no_games_title.field.value =
        useLocalizedValue().transformToLocalizedValue(
          "There is no active games"
        ).localizedValue;
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  return {
    states,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
