import type { LocationQueryValue } from "vue-router";
import { defineStore } from "pinia";
import { nanoid } from "nanoid";

import {
  Grid,
  ICell,
  CellId,
  IInsertPosition,
  InsertionRule,
  NestingLevel,
  ICellDimension,
  ICreateCellSettings,
  MoveDirection,
  IGridWidthParams,
  WidthParams,
  IParentData,
  TemplateQueryType,
  Sizing,
  ICellOptions,
} from "~~/models/grid.interface";
import {
  findCell,
  deleteCell,
  putCellInside,
  insertCell,
  addColumnsToCell,
  deleteColumnsFromCell,
  moveCellToRootLevel,
  addCellToGrid,
  changeCellPosition,
  deepCopyCell,
  hasChildNestingLevel,
} from "~~/assets/utils/grid/grid-tree";
import {
  IGridStore,
  ITemplateResponse,
  ISaveLayoutData,
  TemplateStatus,
  GridWithMeta,
} from "~~/models/stores/grid-store.model";
import { IDividerPosition } from "~~/models/dnd.model";
import { Breakpoint, breakpoints } from "~~/models/breakpoints.model";
import http from "~~/services/http";
import {
  generateCreateLayoutData,
  sortGridByPosition,
} from "~~/assets/utils/grid";
import { deepCopy, gatherWidgetsTags } from "~~/assets/utils";
import { generateGridWidgetsData } from "~~/assets/utils/widget";
import {
  generateWidgetContainersCssData,
  generateWidgetsCssData,
} from "~~/assets/utils/widget-css";
import { IGridWidgetsData, IPageContentResponse } from "~~/models/page.model";
import {
  IWidgetWithFields,
  PageIdType,
} from "~~/models/widgets/widget.core/widget.model";

import { usePagesStore } from "./pages";
import { useMetaStore } from "./meta";
import { useUiStore } from "./ui";

const getGridLayoutDraftKey = (templateId: TemplateQueryType): string => {
  const metaStore = useMetaStore();

  return `${metaStore.projectId}_${templateId || ""}`;
};

const defaultLayout = {
  [Breakpoint.MOBILE]: [],
  [Breakpoint.TABLET]: [],
  [Breakpoint.DESKTOP]: [],
};

const findWidgets = (cell: any, list: any[]) => {
  const { children, widgets } = cell || {};

  if (widgets?.length) {
    list.push(...widgets);
  }

  if (children?.length) {
    children.forEach((child: any) => findWidgets(child, list));
  }

  return list;
};

export const useGridConfig = defineStore("grid", {
  state: (): IGridStore => ({
    template: {
      name: "",
      id: null,
      status: null,
    },
    isTemplateEditable: true,
    /*
    NOTE: `gridLayout` is an object without widgets, non-mutable;
    `widgetsGridLayout` merges grid and widgets, mutable.
    TODO: preferably to move `widgetsGridLayout` into the pages store
    */
    gridLayout: deepCopy(defaultLayout),
    widgetsGridLayout: {},
    gridRootRowsDimensions: {},
    highlightedCells: [],
    colWithHover: null,
    selectedCell: null,
    dividerData: {} as IDividerPosition,
    hasWidthSettingsErrors: false,
    isLayoutSaved: false,
    cellsOptions: {},
  }),

  getters: {
    isMobileTemplateEmpty({ gridLayout }): boolean {
      return (gridLayout[Breakpoint.MOBILE] || []).length === 0;
    },
    templateName({ template }): string {
      return template.name;
    },
    templateId({ template }): TemplateQueryType {
      return template.id;
    },
    isDraft({ template }): boolean {
      return template.status === TemplateStatus.DRAFT;
    },
    currentLayout({ gridLayout }): ICell[] {
      const uiStore = useUiStore();

      const currentGrid = gridLayout[uiStore.activeScreen];
      return currentGrid ?? [];
    },
    currentWidgetsLayout({ widgetsGridLayout }): ICell[] {
      const uiStore = useUiStore();

      const currentGrid = widgetsGridLayout[uiStore.activeScreen];
      return currentGrid ?? [];
    },
    currentDimensions({ gridRootRowsDimensions }): ICellDimension[] {
      const uiStore = useUiStore();

      const currentDimensions = gridRootRowsDimensions[uiStore.activeScreen];
      return currentDimensions ?? [];
    },
    getCell() {
      return (cellId: CellId): ICell | null =>
        findCell(this.currentLayout, cellId);
    },
    areColumnsConfigurable(): boolean {
      if (
        this.selectedCell &&
        this.selectedCell.settings.level === NestingLevel.PARENT
      ) {
        return !hasChildNestingLevel(
          this.currentLayout,
          this.selectedCell.cellId
        );
      }
      return true;
    },

    // Sorry for this, but I can`t move this to the widgets store -_-
    pageWidgets(state) {
      return Object.values(state.widgetsGridLayout).reduce<IWidgetWithFields[]>(
        (acc, cells) => {
          cells.map(cell => findWidgets(cell, acc));

          return acc;
        },
        []
      );
    },
  },

  actions: {
    findWidget(
      cellId: CellId,
      widgetId: string | number,
      widgets?: IWidgetWithFields[]
    ): IWidgetWithFields | null {
      const cell = findCell(this.currentWidgetsLayout, cellId);

      if (!cell) {
        return null;
      }

      const currWidgets = widgets || cell.widgets;

      if (!currWidgets) {
        return null;
      }

      for (let i = 0; i < currWidgets.length; i++) {
        const currWidget = currWidgets[i];

        if (currWidget.id === widgetId) {
          return currWidget;
        }

        if (currWidget.options._children) {
          const childWidget = this.findWidget(
            cellId,
            widgetId,
            currWidget.options._children
          );

          if (childWidget) {
            return childWidget;
          }
        }
      }

      return null;
    },
    findWidgetParentList(
      cellId: CellId,
      widgetId: string | number,
      widgets?: IWidgetWithFields[]
    ): IWidgetWithFields[] | null {
      const cell = findCell(this.currentWidgetsLayout, cellId);

      if (!cell) {
        return null;
      }

      const currWidgets = widgets || cell.widgets;

      if (!currWidgets) {
        return null;
      }

      for (let i = 0; i < currWidgets.length; i++) {
        const currWidget = currWidgets[i];

        if (currWidget.id === widgetId) {
          return currWidgets;
        }

        if (currWidget.options._children) {
          const widgetsListWithFound = this.findWidgetParentList(
            cellId,
            widgetId,
            currWidget.options._children
          );

          if (widgetsListWithFound) {
            return widgetsListWithFound;
          }
        }
      }

      return null;
    },
    findWidgetIndexInCell(
      cellId: CellId,
      widgetId: string | number,
      widgets?: IWidgetWithFields[]
    ): number | null {
      const cell = findCell(this.currentWidgetsLayout, cellId);

      if (!cell) {
        return null;
      }

      const currWidgets = widgets || cell.widgets;

      if (!currWidgets) {
        return null;
      }

      for (let i = 0; i < currWidgets.length; i++) {
        const currWidget = currWidgets[i];

        if (currWidget.id === widgetId) {
          return i;
        }

        if (currWidget.options._children) {
          const childWidgetIndex = this.findWidgetIndexInCell(
            cellId,
            widgetId,
            currWidget.options._children
          );

          if (childWidgetIndex !== null && childWidgetIndex >= 0) {
            return childWidgetIndex;
          }
        }
      }

      return null;
    },
    updateCellsOptions(cellId: CellId, options: Partial<ICellOptions>): void {
      const currentCell = findCell(this.currentLayout, cellId);

      this.cellsOptions[cellId] = {
        ...(this.cellsOptions[cellId] || {}),
        ...options,
        childIds: currentCell?.children.map(child => child.cellId) || [],
        parentId: currentCell?.parentId || undefined,
      };
    },

    setCellsOptions(options: IPageContentResponse["data"]["options"]): void {
      this.cellsOptions = deepCopy({
        ...(options.cellsOptions || {}),
      });
    },

    resetCurrentLayout(): void {
      const uiStore = useUiStore();

      this.gridLayout[uiStore.activeScreen] = [];
    },

    getGridLayoutDraft(): GridWithMeta | null {
      try {
        return JSON.parse(
          localStorage.getItem(getGridLayoutDraftKey(this.templateId)) || ""
        );
      } catch {
        return null;
      }
    },

    saveGridLayoutDraft() {
      localStorage.setItem(
        getGridLayoutDraftKey(this.templateId),
        JSON.stringify({
          grid: this.gridLayout,
          name: this.templateName,
        } as GridWithMeta)
      );
    },

    clearGridLayoutDraft(templateId: TemplateQueryType) {
      localStorage.removeItem(getGridLayoutDraftKey(templateId));
    },

    fetchGridLayout(): Promise<GridWithMeta> {
      return http
        .get(`/template/${this.templateId}`)
        .then((response: ITemplateResponse) => {
          response.data.grid = sortGridByPosition(response.data.grid);

          return response.data;
        });
    },

    createLayout(): Promise<void | void[]> {
      const uiStore = useUiStore();

      const currentScreenName = uiStore.activeScreen;

      const layout = this.gridLayout[currentScreenName] as ICell[];
      const body: ISaveLayoutData = generateCreateLayoutData(
        layout,
        this.template.name
      );

      return http
        .post(`/template/${currentScreenName}`, body)
        .then((response: ITemplateResponse) => {
          this.setTemplateId(response.data.id);
          const cells = response.data.grid[currentScreenName] as ICell[];
          this.gridLayout[currentScreenName] = cells;

          /*
            Temporary solution to save all the templates
            TODO remove during autosave implement
          */
          const layoutsWithoutCurrent = Object.keys(this.gridLayout).filter(
            templateType => {
              return templateType !== currentScreenName;
            }
          );

          return this.updateLayouts(layoutsWithoutCurrent as Breakpoint[]);
        });
    },

    updateLayout(layoutType: Breakpoint): Promise<void> {
      const layout = this.gridLayout[layoutType] as ICell[];
      const body: ISaveLayoutData = generateCreateLayoutData(
        layout,
        this.template.name
      );

      return http
        .put(`/template/${this.template.id}/${layoutType}`, body)
        .then((response: ITemplateResponse) => {
          if (!response.data.grid || !Object.keys(response.data.grid).length) {
            return;
          }
          response.data.grid = sortGridByPosition(response.data.grid);

          this.gridLayout[layoutType] = response.data.grid[layoutType];
        });
    },

    updateLayouts(layouts: Breakpoint[]): Promise<void[]> {
      const result: Array<Promise<void>> = [];
      layouts.forEach(layoutType => {
        result.push(this.updateLayout(layoutType as Breakpoint));
      });

      return Promise.all(result);
    },

    updateAllLayouts(): Promise<void[]> {
      return this.updateLayouts(Object.keys(this.gridLayout) as Breakpoint[]);
    },

    setTemplateName(name: string): void {
      this.template.name = name;
    },

    setTemplateId(id: TemplateQueryType): void {
      this.template.id = id;
    },

    setTemplateStatus(status: TemplateStatus | null): void {
      this.template.status = status;
    },

    changeTemplateEditability(isTemplateEditable: boolean): void {
      this.isTemplateEditable = isTemplateEditable;
    },

    checkTemplateEditability(): void {
      const pagesStore = usePagesStore();

      pagesStore.fetchPagesByTemplate(this.templateId).then(pages => {
        this.isTemplateEditable = !pages.length;
      });
    },

    updateGrid(gridData: Grid): void {
      this.gridLayout = deepCopy({ ...defaultLayout, ...gridData });
    },

    updateGridWithMeta(gridWithMeta: GridWithMeta) {
      this.setTemplateName(gridWithMeta.name);
      this.updateGrid(gridWithMeta.grid);
    },

    updateDimensions(dimensions: ICellDimension[]): void {
      const uiStore = useUiStore();

      this.gridRootRowsDimensions[uiStore.activeScreen] = dimensions;
    },

    updateWidgetsGridLayout(updatedGrid: Grid): void {
      this.widgetsGridLayout = deepCopy(updatedGrid);
    },

    fetchWidgetsGrid(): Promise<void> {
      return this.fetchGridLayout().then(layoutData => {
        this.widgetsGridLayout = layoutData.grid;
        this.gridLayout = deepCopy(layoutData.grid);
      });
    },

    saveContentPage(data: {
      pageId: PageIdType;
      widgetsData: IGridWidgetsData | null;
      cssFileId: string;
      cellsOptions: Record<CellId, ICellOptions>;
      options: Record<string, any> | undefined;
    }): Promise<void> {
      const { pageId, widgetsData, cssFileId, cellsOptions, options } = data;

      return http.post(`/pages/${pageId}/save`, {
        ...widgetsData,
        options: {
          ...(options || {}),
          cellsOptions,
          cssFileId,
        },
      });
    },

    saveWidgetsGridCss(
      widgetsCssBody: { content: string; filename: string }[]
    ): Promise<void> {
      return http.post("/save-css", widgetsCssBody);
    },

    generateWidgetsGridCss(data: {
      gridLayout: Grid;
      page: any;
      widgetsGridData: IGridWidgetsData;
      cssFileId: string;
      cellsOptions: Record<CellId, ICellOptions>;
    }): { content: string; filename: string }[] {
      const { page, gridLayout, widgetsGridData, cssFileId, cellsOptions } =
        data;

      const widgetsGridCss = generateWidgetsCssData(
        gridLayout,
        widgetsGridData,
        cellsOptions
      );

      const widgetsContainerCss = generateWidgetContainersCssData(
        page.options.cellsOptions,
        cssFileId
      );

      const widgetsCssBody: { content: string; filename: string }[] = [];

      const templateIdScoped = widgetsGridData.templateId;

      if (widgetsGridCss?.pageElementsStyles) {
        Object.entries(widgetsGridCss.pageElementsStyles).forEach(
          ([breakpoint, pageCssString]) => {
            widgetsCssBody.push({
              content: pageCssString + widgetsContainerCss,
              filename: `${page.id}_${templateIdScoped}_${breakpoint}_${cssFileId}.css`,
            });
          }
        );
      }

      return widgetsCssBody;
    },

    saveWidgetsGrid(): Promise<void> {
      const cssFileId = nanoid();

      const pagesStore = usePagesStore();

      const selectedPageId = pagesStore.selectedPageId;
      const widgetsGridData = generateGridWidgetsData(
        this.widgetsGridLayout,
        this.templateId as string | null
      );

      const widgetsGridCss = generateWidgetsCssData(
        this.widgetsGridLayout,
        widgetsGridData,
        this.cellsOptions
      );

      const widgetsContainerCss = generateWidgetContainersCssData(
        this.cellsOptions,
        cssFileId
      );

      const widgetsCssBody: Array<{ content: string; filename: string }> = [];

      function minifyCSS(css: string): string {
        return css
          .replace(/\/\*[\s\S]*?\*\//g, "") // Remove comments
          .replace(/\s*([{}:;,])\s*/g, "$1") // Remove whitespace around {},:;,
          .replace(/\s+/g, " ") // Collapse whitespace
          .replace(/;\s*}/g, "}") // Remove semicolon before }
          .trim();
      }

      if (widgetsGridCss?.pageElementsStyles) {
        Object.entries(widgetsGridCss.pageElementsStyles).forEach(
          ([breakpoint, pageCssString]) => {
            widgetsCssBody.push({
              content: minifyCSS(`${pageCssString} ${widgetsContainerCss}`),
              filename: `${selectedPageId}_${this.templateId}_${breakpoint}_${cssFileId}.css`,
            });
          }
        );
      }

      const pageWidgetsTags = new Set(
        gatherWidgetsTags(this.widgetsGridLayout)
      );

      return this.saveContentPage({
        pageId: selectedPageId,
        widgetsData: widgetsGridData,
        cssFileId,
        cellsOptions: this.cellsOptions,
        options: {
          ...pagesStore.selectedPage!.options,
          tags: [...pageWidgetsTags],
        },
      }).then(() => {
        return this.saveWidgetsGridCss(widgetsCssBody);
      });
    },

    addHighlightedCell(cellId: CellId): void {
      this.highlightedCells.push(cellId);
    },

    removeHighlightedCell(cellId: CellId): void {
      this.highlightedCells = this.highlightedCells.filter(
        cell => cell !== cellId
      );
    },

    setHoveredColumn(cellId: CellId | null): void {
      this.colWithHover = cellId;
    },

    setSelectedCell(cell: ICell | null): void {
      this.selectedCell = cell;
    },

    /* Grid mutation */

    updateCurrentGrid(dataToUpdate: ICell[]): void {
      const uiStore = useUiStore();

      this.gridLayout[uiStore.activeScreen] = dataToUpdate;
    },

    deleteGridCell(cellId: CellId, parentId?: CellId): void {
      let currentLayout = this.currentLayout;
      currentLayout = deleteCell(currentLayout, cellId, parentId ?? null);
      this.updateCurrentGrid(currentLayout);
    },

    moveGridCellInside(cellId: CellId, targetID: CellId, index: number): void {
      let currentLayout = this.currentLayout;
      const cellToMove = findCell(currentLayout, cellId);
      if (cellToMove === null) return;
      currentLayout = putCellInside(currentLayout, cellToMove, targetID, index);
      this.updateCurrentGrid(currentLayout);
      this.selectedCell = findCell(currentLayout, cellId);
    },

    moveGridCellAt(
      cellId: CellId,
      targetID: CellId,
      insertionRule?: InsertionRule
    ): void {
      let currentLayout = this.currentLayout;
      const cellToMove = findCell(currentLayout, cellId);
      if (cellToMove) {
        currentLayout = insertCell(
          currentLayout,
          cellToMove,
          targetID,
          insertionRule
        );
      }
      this.updateCurrentGrid(currentLayout);
      this.selectedCell = cellToMove;
    },

    copyCell(cell: ICell): void {
      const cellCopy = deepCopyCell(cell);
      let currentLayout = this.currentLayout;
      currentLayout = insertCell(
        currentLayout,
        cellCopy,
        cell.cellId,
        InsertionRule.AFTER
      );
      this.updateCurrentGrid(currentLayout);
    },

    moveGridCell(cell: ICell, shift: MoveDirection): void {
      let currentLayout = this.currentLayout;
      let index = -1;
      if (cell.parentId === null) {
        index = currentLayout.indexOf(cell);
        // next element
      } else {
        const parent = findCell(currentLayout, cell.parentId);
        if (!parent) return;
        index = parent.children.indexOf(cell);
      }
      currentLayout = changeCellPosition(
        currentLayout,
        cell,
        index,
        index + shift
      );
      this.updateCurrentGrid(currentLayout);
    },

    changeColumnsNumber(columns: number): void {
      if (!this.selectedCell) return;
      const {
        cellId,
        children,
        settings: { level },
      } = this.selectedCell;
      const numColumnsToAddOrDelete = children.length - columns;
      if (numColumnsToAddOrDelete === 0) return;

      if (numColumnsToAddOrDelete < 0) {
        this.addColumns(cellId, Math.abs(numColumnsToAddOrDelete), level);
      } else {
        this.deleteColumns(cellId, numColumnsToAddOrDelete);
      }
    },

    addColumns(
      cellId: CellId,
      columns: number,
      level: NestingLevel = NestingLevel.PARENT
    ): void {
      let currentLayout = this.currentLayout;
      currentLayout = addColumnsToCell(currentLayout, cellId, columns, level);
      this.updateCurrentGrid(currentLayout);
    },

    deleteColumns(cellId: CellId, columns: number): void {
      let currentLayout = this.currentLayout;
      currentLayout = deleteColumnsFromCell(currentLayout, cellId, columns);
      this.updateCurrentGrid(currentLayout);
    },

    addCell(
      columns: number,
      level: NestingLevel,
      index: number,
      parent: IParentData = { parentId: null }
    ) {
      const params: ICreateCellSettings = {
        columns,
        level,
        indexToInsert: index,
      };

      const result = addCellToGrid(this.currentLayout, parent, params);
      this.updateCurrentGrid(result.grid);
      this.selectedCell = result.newCell;
    },

    moveCellToRoot(cellId: CellId, insertPosition: IInsertPosition) {
      const uiStore = useUiStore();

      const grid = moveCellToRootLevel(
        this.currentLayout,
        cellId,
        insertPosition.index + insertPosition.insertionRule,
        breakpoints[uiStore.activeScreen].screenSize
      );
      this.updateCurrentGrid(grid);
      this.selectedCell = grid[insertPosition.index];
    },

    setDividerData(data: IDividerPosition): void {
      this.dividerData = {
        top: data.top,
        width: data.width,
        left: data.left,
      };
    },

    /* Sizing */
    setSelectedCellWidth(widthParams: IGridWidthParams): void {
      if (!this.selectedCell) return;
      const width = Number(widthParams.width as number);
      this.selectedCell.settings.sizing = widthParams.sizing;
      this.selectedCell.settings.width = width;
      // For one-column FIXED container set fixed width for its column
      if (
        widthParams.sizing === Sizing.FIXED &&
        this.selectedCell.children.length === 1
      ) {
        const column = this.selectedCell.children[0];
        column.settings.sizing = Sizing.FIXED;
        column.settings.width = width;
      }
    },

    setSelectedCellChildren(children: WidthParams[]): void {
      if (!this.selectedCell) return;
      this.selectedCell.children.forEach((child, index) => {
        child.settings.width = Number(children[index].width);
        child.settings.sizing = children[index].sizing;
      });
    },

    setValidationErrors(value: boolean): void {
      this.hasWidthSettingsErrors = value;
    },

    changeIsLayoutSavedStatus(status: boolean): void {
      this.isLayoutSaved = status;
    },

    duplicateTemplate(): Promise<void> {
      return http
        .post(`/template/clone/${this.templateId}`)
        .then((response: ITemplateResponse) => {
          const data = response.data;
          this.setTemplateId(data.id);
          this.setTemplateName(data.name);
          this.setTemplateStatus(TemplateStatus.DRAFT);
          this.checkTemplateEditability();
        });
    },

    editTemplateName(): Promise<void | void[]> {
      if (!this.templateId) {
        return this.createLayout();
      }

      return http.put(`/template/edit-name/${this.templateId}`, {
        name: this.templateName,
      });
    },

    getWidgetById(widgetId: LocationQueryValue): IWidgetWithFields | undefined {
      return this.pageWidgets.find(widget => widget.id === widgetId);
    },

    findScreenByCellId(cellId: CellId | undefined): Breakpoint {
      const grid = this.widgetsGridLayout;

      if (cellId && Object.keys(grid).length) {
        for (const [screen, cell] of Object.entries(grid)) {
          if (findCell(cell, cellId)) {
            return screen as Breakpoint;
          }
        }
      }
      return Breakpoint.MOBILE;
    },
  },
});
