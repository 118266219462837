<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-alignment widget-control-container"
  >
    <p class="m-b-4">Text style</p>
    <div class="config-alignment__menu">
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentListStyle === ListStyle.NONE,
        }"
        @click="handleClick(ListStyle.NONE)"
      >
        <CommonIcon name="custom:text-style-none" />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentListStyle === ListStyle.LIST,
        }"
        @click="handleClick(ListStyle.LIST)"
      >
        <CommonIcon name="custom:text-style-list" />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentListStyle === ListStyle.ORDERED,
        }"
        @click="handleClick(ListStyle.ORDERED)"
      >
        <CommonIcon name="custom:text-style-ordered-list" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  ListStyle,
  SimpleStringControlValue,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  modelValue: SimpleStringControlValue;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentListStyle = computed<string>(() => {
  return props.modelValue || "";
});

const handleClick = (value: string): void => {
  if (currentListStyle.value === value) {
    return;
  }

  emit("update:modelValue", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-alignment {
  @include controls-menu(33%);
}
</style>
