<template>
  <div
    :style="containerStyles"
    class="racing-sports-event-live-widget__players-info"
  >
    <RacingSportsTable
      :widget="widget"
      :field="field"
      :states="states"
      :card-field="cardField"
      :header-field="headerField"
      :number-field="numberField"
      :name-field="nameField"
      :last-races-field="lastRacesField"
      :prev-odds-field="prevOddsField"
      :odds-field="oddsField"
    >
      <template #players_info_player_number="data">
        <WidgetField
          :widget="widget"
          :field="numberField"
          :no-overflow="true"
        >
          <img
            v-if="data.value === 1 || data.value === 2"
            :src="`/widgets/racing/race-icon-${data.value}.png`"
            alt=""
            class="racing-sports-event-live-widget__number-img"
          />

          <div v-else>
            <p
              :style="numberTextStyle"
              class="racing-sports-event-live-widget__number"
            >
              {{ data.value }}
            </p>

            <img
              :style="numberImageStyle"
              :src="`/widgets/racing/place-${data.value}.png`"
              alt=""
            />
          </div>
        </WidgetField>
      </template>

      <template #players_info_player_name="data">
        <WidgetField
          :widget="widget"
          :field="nameField"
          :no-overflow="true"
        >
          <p :style="nameStyle">{{ data.value }}</p>
        </WidgetField>
      </template>

      <template #players_info_player_last_score="data">
        <WidgetField
          :widget="widget"
          :field="lastRacesField"
          :no-overflow="true"
        >
          <p :style="lastRacesTextStyle">{{ data.value }}</p>
        </WidgetField>
      </template>

      <template #players_info_player_prev_odds="data">
        <WidgetField
          :widget="widget"
          :field="prevOddsField"
          :no-overflow="true"
        >
          <p :style="prevOddsTextStyle">{{ data.value }}</p>
        </WidgetField>
      </template>

      <template #players_info_player_current_odds="data">
        <WidgetField
          :widget="widget"
          :field="oddsField"
          :no-overflow="true"
        >
          <RacingSportsBetItem
            :bet-item-field="oddsField"
            :is-up="states[oddsField.name] === State.UP"
            :is-down="states[oddsField.name] === State.DOWN"
            :value="data.value"
            :state-options="betItemsStateOptions"
            :show-both-arrows-states="[State.ACTIVE, State.HOVER]"
            :state="states[oddsField.name]"
          />
        </WidgetField>
      </template>
    </RacingSportsTable>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  states: Record<string, State>;
  cardField: IWidgetField;
  headerField: IWidgetField;
  numberField: IWidgetField;
  nameField: IWidgetField;
  lastRacesField: IWidgetField;
  prevOddsField: IWidgetField;
  oddsField: IWidgetField;
  cardState: State;
}>();

const containerStyles = computed<ElementStyle>(() => {
  return getCommonStyles(props.field.options);
});

const numberTextStyle = computed<ElementStyle>(() => {
  if (props.cardState === State.DEFAULT) {
    return getTextCommonStyles(props.numberField.options);
  }

  return getTextCommonStyles({
    ...props.numberField.options,
    ...props.cardField.options.states[props.cardState][props.numberField.name],
  });
});

const nameStyle = computed<ElementStyle>(() => {
  if (props.cardState === State.DEFAULT) {
    return getTextCommonStyles(props.nameField.options);
  }

  return getTextCommonStyles({
    ...props.nameField.options,
    ...props.cardField.options.states[props.cardState][props.nameField.name],
  });
});

const numberImageStyle = computed<ElementStyle>(() => {
  return {
    width: getPxValueFromNumber(props.numberField.options.iconSize),
  };
});

const lastRacesTextStyle = computed<ElementStyle>(() => {
  if (props.cardState === State.DEFAULT) {
    return getTextCommonStyles(props.lastRacesField.options);
  }

  return getTextCommonStyles({
    ...props.lastRacesField.options,
    ...props.cardField.options.states[props.cardState][
      props.lastRacesField.name
    ],
  });
});

const prevOddsTextStyle = computed<ElementStyle>(() => {
  if (props.cardState === State.DEFAULT) {
    return getTextCommonStyles(props.prevOddsField.options);
  }

  return getTextCommonStyles({
    ...props.prevOddsField.options,
    ...props.cardField.options.states[props.cardState][
      props.prevOddsField.name
    ],
  });
});

const betItemsStateOptions = computed<IWidgetOptions>(() => {
  if (props.states[props.cardField.name] === State.DISABLED) {
    return props.oddsField.options.states.not_runner;
  }

  if (props.states[props.oddsField.name] === State.DEFAULT) {
    return props.oddsField.options;
  }

  return props.oddsField.options.states[props.states[props.oddsField.name]];
});
</script>

<style lang="scss">
.racing-sports-event-live-widget {
  &__number-img {
    width: 30px;
    height: 30px;
  }

  &__number {
    display: inline-block;
    margin-right: 4px;
  }

  &__players-info {
    p {
      margin: 0;
    }
  }
}
</style>
