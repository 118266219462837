import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { stateConfig } from "~~/constants/configs/common/state-config";

export const useSportsbookLiveFenixSportsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();

  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    Sports container config
  */

  const sportsContainerDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.sports_container.field,
    });
  });

  const sportsContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distanceBetweenSports",
            valueSource: fields.value.sports_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between sports",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Sports title"),
        controls: [],
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.title.field
          );
        },
      },
      {
        section: generateContentSectionName("Leagues"),
        showConfig: true,
        toggleable: true,
        valueSource: fields.value.leagues_titles.field.options,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.leagues_titles.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distanceBetweenLeagueEvents",
            valueSource: fields.value.sports_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between league events",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Sport events"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.sport_events_container.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.sport_events_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between event cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Racing events"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.racing_events_container.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.racing_events_container.field,
            className: "group-control-element",
            options: {
              label: "Distance between event cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
    ];
  });

  const currentBetItemPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const sportsContainerStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  /* 
    Sports title config
  */

  const sportsTitleDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.title.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.title.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const sportsTitleContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.title.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography({
        source: fields.value.title.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateContentSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.title.field.options.icon,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.title.field,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.title.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Sports events button"),
        toggleable: true,
        valueSource: fields.value.title_button.field.options,
        controls: [],
      },
    ];
  });

  const currentTitleStatePath = computed<string>(() => {
    if (states.value.title === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.title}`;
  });

  const sportsTitleStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.title,
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "title", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.title.field,
        basePath: currentTitleStatePath.value,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentTitleStatePath.value}.color`,
            valueSource: fields.value.title.field,
            options: {
              placeholder: "None",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentTitleStatePath.value}.icon.color`,
            valueSource: fields.value.title.field,
            options: {
              placeholder: "None",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    sportsContainerDesignControls,
    sportsContainerContentControls,
    sportsContainerStateControls,

    sportsTitleDesignControls,
    sportsTitleContentControls,
    sportsTitleStatesControls,
  };
};
