<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew :dropdown-items="DROPDOWN_ITEMS">
        <template #widgetSettings>
          <ControlsList
            :widget="widget"
            :controls="widgetControls"
          />
        </template>

        <template #iframe_container>
          <p class="p-t-16 p-l-16 p-r-16">
            The event broadcast will be displayed in this frame
          </p>
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useVirtualsConfig } from "~~/composables/widgets/virtuals/useVirtualsWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const { widgetControls, DROPDOWN_ITEMS } = useVirtualsConfig(
  toRef(() => props.widget)
);
</script>
