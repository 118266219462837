import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";

import { useFormContentConfig } from "../../form/useFormContentConfig";
import { useFormDropdownConfig } from "../../form/useFormDropdownConfig";
import { useFormFieldConfig } from "../../form/useFormFieldConfig";

import { useCasinoGamesFiltersWidgetControls } from "./useCasinoGamesFiltersWidgetControls";

export const useCasinoGamesFiltersWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Category", "categories", "form"),
    generateDropdownItem("Providers", "providers", "form"),
  ];

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Styles", "styles"),
      generateTabItem("Data", "data"),
    ],
  };

  const { widgetDesignControls, widgetContentControls, widgetStylesControls } =
    useCasinoGamesFiltersWidgetControls(widget, states, emit);

  const { dropdownFieldControls } = useFormContentConfig();

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);
  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
    successFieldControls,
  } = useFormFieldConfig(widget, states);

  const formStylesControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "tooltips_styling-design": formTooltipDesignStyles.value,
      "tooltips_styling-content": formTooltipContentStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
      "dropdowns_styling": formDropdownStyles.value,
      "dropdown_menu_styling": formDropdownMenuStyles.value,
      "dropdown_menu_items_styling-design":
        formDropdownMenuItemsDesignStyles.value,
      "dropdown_menu_items_styling-content":
        formDropdownMenuItemsContentStyles.value,
      "dropdown_menu_items_styling-states": formDropdownMenuItemsStates.value,
      "field_success_styling": successFieldControls.value,
    };
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgetStylesControls,

    formStylesControls,

    dropdownFieldControls,
  };
};
