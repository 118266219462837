import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { ControlProp } from "~~/models/settings/settings-sidebar.model";
import type { State } from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useButtonWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  buttonState: Ref<State>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = [generateDropdownItem("Button", "button")];

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Button"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.button.field
          );
        },
        controls: [],
      },
    ];
  });

  const { buttonDesignControls, buttonContentControls, buttonStatesControls } =
    useButtonControls(
      {
        state: buttonState,
      },
      emit
    );

  return {
    TABS,
    DROPDOWN_ITEMS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    buttonDesignControls,
    buttonContentControls,
    buttonStatesControls,
  };
};
