import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useWidgetFields } from "../../useWidgetFields";

export const useSearchResultItemsControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const searchResultItemsDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: fields.value.search_result_items.field,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: fields.value.search_result_items.field,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: fields.value.search_result_items.field,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: fields.value.search_result_items.field,
      options: {
        placeholder: "None",
        label: "Corner Radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: fields.value.search_result_items.field,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigColumnWidth",
      valuePath: "options.spaceBetween",
      valueSource: fields.value.search_result_items.field,
      options: {
        iconName: "ant-design:column-height-outlined",
        label: "Distance between items",
        isBold: true,
      },
    },
  ]);

  const searchResultItemsContentControls = computed(() => [
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: "options.itemsText.color",
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
        {
          componentPath: "ConfigThemeSelect",
          valuePath: "options.itemsText.theme",
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Text color",
          },
        },
        {
          componentPath: "ConfigFontFamilySelect",
          valuePath: "options.itemsText.fontFamily",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Font family",
          },
        },
        {
          componentPath: "ConfigDecoration",
          valuePath: "options.itemsText.decoration",
          valueSource: selectedField.value,
          options: {
            placeholder: "Select",
            label: "Decoration",
          },
        },
      ],
    },
  ]);

  const currentFieldContainerPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }
    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const searchResultItemsStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBorder",
          valuePath: `${currentFieldContainerPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
      ],
    },
    {
      withoutSectionLabel: true,
      controls: [
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${currentFieldContainerPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${currentFieldContainerPath.value}.itemsText.color`,
          valueSource: selectedField.value,
          options: {
            placeholder: "Enter text",
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  return {
    searchResultItemsDesignControls,
    searchResultItemsContentControls,
    searchResultItemsStatesControls,
  };
};
