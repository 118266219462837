<template>
  <div
    class="wconfig-gap-space widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <p
      v-if="label"
      class="m-b-4"
      :class="{
        'bold': isBold,
      }"
    >
      {{ label }}
    </p>

    <div class="wconfig-gap-space__list">
      <ConfigVerticalSpace v-model="gap.row" />

      <ConfigVerticalSpace
        v-model="gap.column"
        :not-rotate-icon="true"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  label?: string;
  isBold?: boolean;
}>();

const gap = defineModel<{
  row: string | number | null;
  column: string | number | null;
}>("modelValue", {
  default: () => {
    return {
      row: null,
      column: null,
    };
  },
});
</script>

<style lang="scss">
.wconfig-gap-space {
  &__list {
    gap: 8px;
    display: flex;

    .wconfig-vertical-space {
      padding: 0;
    }
  }
}
</style>
