import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { State } from "~~/models/widgets/widget-controls.model";

type Args = {
  widget: Ref<IWidgetWithFields>;
  states: Ref<Record<string, string>>;
  stateName: string;
};

export const useBonusesTermsConfig = (
  { widget, states, stateName }: Args,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);

  const termsDesignControls = computed(() => {
    return [
      typography({ source: fields.value.terms_title.field }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.terms_title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  // const termsContentControls = computed(() => {
  //   return [
  //     {
  //       componentPath: "content/ConfigTextInput",
  //       valuePath: "value",
  //       valueSource: fields.value.terms_title.field,
  //       options: {
  //         placeholder: "None",
  //         isBold: true,
  //         label: "Content",
  //       },
  //     },
  //   ];
  // });

  const currentTabsStatePath = computed<string>(() => {
    if (states.value[stateName] === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${states.value[stateName]}`;
  });

  const termsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value[stateName],
        onUpdate: value => {
          emit("update-state", { state: stateName, value });
        },
      }),
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentTabsStatePath.value}.color`,
        valueSource: fields.value.terms_title.field,
        options: {
          placeholder: "Enter text",
          label: "Text color",
          type: ColorPickerType.TEXT,
        },
      },
    ];
  });

  return {
    termsDesignControls,
    termsStatesControls,
  };
};
