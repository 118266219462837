<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <ProfileDropdownFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <section
      class="profile-dropdown-fenix-widget"
      :style="profileWrapperStyles"
    >
      <template v-if="states.status === State.UNAUTHORIZED">
        <WidgetField
          :widget="widget"
          :field="fields.login.field"
          :class="[
            'text-truncate',
            {
              'w-100':
                fields.login.field.options.buttonDisplaySettings?.resizing ===
                ResizingType.FILL,
            },
          ]"
        >
          <BasicButtonField
            :field="fields.login.field"
            :current-state="states.login"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.registration.field"
          :class="[
            'text-truncate',
            {
              'w-100':
                fields.registration.field.options.buttonDisplaySettings
                  ?.resizing === ResizingType.FILL,
            },
          ]"
        >
          <BasicButtonField
            :field="fields.registration.field"
            :current-state="states.registration"
          />
        </WidgetField>
      </template>
      <div
        v-else
        :style="{
          ...profileWrapperStyles,
          justifyContent: 'initial',
          alignItems: 'initial',
        }"
      >
        <WidgetField
          v-if="fields.deposit.active"
          :widget="widget"
          :field="fields.deposit.field"
          :class="{
            'w-100':
              fields.deposit.field.options.buttonDisplaySettings?.resizing ===
              ResizingType.FILL,
          }"
        >
          <BasicButtonField
            :field="fields.deposit.field"
            :current-state="states.deposit"
          />
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.account.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.account.field"
          >
            <WidgetField
              v-if="isAccountDropdown"
              :widget="widget"
              :field="fields.dropdown_container.field"
              :style="dropdownContainerWrapperStyles"
            >
              <DropdownTriggerField
                :widget="widget"
                :field="fields.dropdown_container.field"
                value="$ 000,00"
                :state="states.dropdown_container"
                :is-hug="true"
              />
            </WidgetField>

            <WidgetField
              v-if="isWidgetSelected || !isAccountDropdown"
              :widget="widget"
              :field="fields.dropdown_menu.field"
            >
              <DropdownMenuField
                :field="fields.dropdown_menu.field"
                :is-wrapper="isAccountDropdown"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.account_info.field"
                  :style="accountInfoStyles"
                >
                  <WidgetField
                    v-if="fields.user_id.active"
                    :widget="widget"
                    :field="fields.user_id.field"
                    :style="userIdStyles"
                  >
                    <WidgetField
                      :widget="widget"
                      :field="fields.username.field"
                    >
                      <BasicTitleField
                        :field="fields.username.field"
                        value="Username"
                      />
                    </WidgetField>

                    <WidgetField
                      :widget="widget"
                      :field="fields.account_number.field"
                    >
                      <BasicTitleField :field="fields.account_number.field">
                        <div
                          class="account-number"
                          :style="accountNumberStyles"
                        >
                          <span>Account number: 4007332617</span>
                          <CommonIcon
                            :name="
                              fields.account_number.field.options.icon.value
                            "
                          />
                        </div>
                      </BasicTitleField>
                    </WidgetField>
                  </WidgetField>
                  <WidgetField
                    v-if="fields.balance.active"
                    :widget="widget"
                    :field="fields.balance.field"
                    :is-custom-hovered="hoveredBalanceField === 'balance'"
                    :style="balanceWrapperStyles"
                    custom-dropdown-value="balance"
                    @hover="handleHoverBalanceField('balance')"
                    @leave="handleLeaveBalanceField()"
                  >
                    <template
                      v-for="balanceField in balanceFields"
                      :key="balanceField.name"
                    >
                      <WidgetField
                        v-if="balanceField.options._active"
                        class="w-100"
                        :widget="widget"
                        :field="balanceField"
                        :custom-dropdown-value="balanceField.name"
                        :is-custom-hovered="
                          hoveredBalanceField === balanceField.name
                        "
                        :class="{
                          'widget-field--selected': isBalanceItemSelected,
                        }"
                        @hover="handleHoverBalanceField(balanceField.name)"
                        @leave="handleLeaveBalanceField()"
                      >
                        <ProfileDropdownBalanceItem
                          :field="balanceField"
                          :wrapper-field="fields.balance.field"
                          :state="states.balanceItems"
                          :style="balanceItemsWrapperStyles"
                        />
                      </WidgetField>
                    </template>
                  </WidgetField>

                  <WidgetField
                    v-if="fields.account_menu.active"
                    :widget="widget"
                    :field="fields.account_menu.field"
                    :style="accountMenuStyles"
                  >
                    <WidgetField
                      v-for="itemField in itemsFields"
                      :key="itemField.id"
                      :field="itemField"
                      :widget="widget"
                      :class="[
                        'w-100',
                        { 'widget-field--selected': isItemsGroupSelected },
                      ]"
                    >
                      <ArrayItemField
                        :field="itemField"
                        :state="states.itemsGroup"
                        :wrapper-options="{
                          ...itemsGroup?.options,
                          display: fields.account_menu.field.options.display,
                          itemsIcon: {
                            gap: itemsGroup?.options.display.distance,
                          },
                        }"
                        :items-group-options="itemsGroup?.options"
                        :is-items-group-selected="isItemsGroupSelected"
                      />
                    </WidgetField>
                  </WidgetField>

                  <WidgetField
                    v-if="fields.logout.active"
                    :widget="widget"
                    :field="fields.logout.field"
                  >
                    <BasicButtonField
                      :field="fields.logout.field"
                      :current-state="states.logout"
                    />
                  </WidgetField>
                </WidgetField>
              </DropdownMenuField>
            </WidgetField>
          </BasicDesignWrapperField>
        </WidgetField>
      </div>
    </section>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorFromHex,
  getFlexPosition,
  getWidthStyles,
} from "~~/assets/utils/widget-settings";
import { getCommonStyles } from "~~/assets/utils/styles";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ICell } from "~~/models/grid.interface";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { useStates } from "~~/composables/widgets/common/useStates";
import { useInit } from "~~/composables/widgets/profile-dropdown/fenix/useProfileDropdownFenixInit";
import { useWidgetWithArrayField } from "~~/composables/widgets/useWidgetWithArrayField";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const { itemsFields, isItemsGroupSelected, itemsGroup } =
  useWidgetWithArrayField(
    props.widget,
    FieldName.DROPDOWN,
    FieldName.ACCOUNT_MENU
  );

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget, selectedDropdownItem } =
  storeToRefs(widgetSettingsStore);

const isWidgetSelected = computed<boolean>(() => {
  return props.widget.id === selectedWidget.value?.id;
});

const isBalanceItemSelected = computed<boolean>(
  () => selectedDropdownItem.value === "balanceItems"
);

const isAccountDropdown = computed<boolean>(
  () => fields.value.account.field.options.display?.mode === "dropdown"
);

const hoveredBalanceField = ref<string | null>(null);
const balanceFields = computed<IWidgetField[]>(() =>
  [fields.value.real.field, fields.value.bonus.field].sort(
    (a, b) => a.options._position - b.options._position
  )
);

const profileWrapperStyles = computed<ElementStyle>(() => {
  const profileDropdownOptions = props.widget.options;

  if (!profileDropdownOptions) {
    return {};
  }
  const isVertical =
    profileDropdownOptions.display?.layout === DisplayOrientation.VERTICAL;

  return {
    display: "flex",
    flexDirection: isVertical ? "column" : "row",
    justifyContent: profileDropdownOptions.display?.alignment,
    alignItems: isVertical
      ? getFlexPosition(profileDropdownOptions.display?.alignment)
      : "initial",
    gap: getPxValueFromNumber(profileDropdownOptions.display?.distance ?? 0),
  };
});

const dropdownContainerWrapperStyles = computed<ElementStyle>(() => {
  const dropdownContainerOptions =
    fields.value.dropdown_container.field.options;

  if (!dropdownContainerOptions) {
    return {};
  }

  return {
    display: "inline-block",
    width:
      dropdownContainerOptions.display?.resizing === ResizingType.FILL
        ? "100%"
        : "auto",
  };
});

const accountInfoStyles = computed<ElementStyle>(() => {
  const accountInfoOptions = fields.value.account_info.field.options;

  if (!accountInfoOptions) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(accountInfoOptions.display?.distance ?? 0),
    width: getWidthStyles(accountInfoOptions.display.resizing).width,
    ...getCommonStyles(accountInfoOptions),
  };
});

const userIdStyles = computed<ElementStyle>(() => {
  const userIdOptions = fields.value.user_id.field.options;

  if (!userIdOptions) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: getFlexPosition(userIdOptions.display?.alignment),
    gap: getPxValueFromNumber(userIdOptions.display?.distance ?? 0),
    ...getCommonStyles(userIdOptions),
  };
});

const accountMenuStyles = computed<ElementStyle>(() => {
  const accountMenuOptions = fields.value.account_menu.field.options;

  if (!accountMenuOptions) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: getFlexPosition(accountMenuOptions.display?.alignment),
    gap: getPxValueFromNumber(accountMenuOptions.display?.distance ?? 0),
    ...getCommonStyles(accountMenuOptions),
  };
});

const balanceWrapperStyles = computed<ElementStyle>(() => {
  const balanceOptions = fields.value.balance.field.options;

  if (!balanceOptions) {
    return {};
  }

  const isVertical =
    balanceOptions.display?.layout === DisplayOrientation.VERTICAL;

  return {
    display: "flex",
    flexDirection: isVertical ? "column" : "row",
    justifyContent: balanceOptions.display?.alignment,
    alignItems: isVertical
      ? getFlexPosition(balanceOptions.display?.alignment)
      : "initial",
    gap: getPxValueFromNumber(balanceOptions.display?.distance ?? 0),
    ...getCommonStyles(balanceOptions),
  };
});

const balanceItemsWrapperStyles = computed<ElementStyle>(() => {
  const balanceOptions = fields.value.balance.field.options;

  if (!balanceOptions) {
    return {};
  }

  const isVertical =
    balanceOptions.displayItems?.layout === DisplayOrientation.VERTICAL;

  return {
    display: "flex",
    flexDirection: isVertical ? "column" : "row",
    justifyContent: balanceOptions.displayItems?.alignment,
    alignItems: isVertical
      ? getFlexPosition(balanceOptions.displayItems?.alignment)
      : "center",
    gap: getPxValueFromNumber(balanceOptions.displayItems?.distance ?? 0),
  };
});

const accountNumberStyles = computed<ElementStyle>(() => {
  const icon = fields.value.account_number.field.options.icon;

  if (!icon) {
    return {};
  }

  return {
    display: "flex",
    alignItems: "center",
    gap: getPxValueFromNumber(
      fields.value.account_number.field.options.icon.distance
    ),
    ...getStylesAsVars(
      {
        color: getColorFromHex(icon.color) as string,
        fontSize: getPxValueFromNumber(icon.size),
      },
      "icon"
    ),
  };
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["status"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

const handleHoverBalanceField = (value: string): void => {
  hoveredBalanceField.value = value;
};

const handleLeaveBalanceField = (): void => {
  hoveredBalanceField.value = null;
};

init();
</script>

<style lang="scss">
.profile-dropdown-fenix-widget {
  position: relative;
  .account-number {
    svg {
      color: var(--icon-color);
      font-size: var(--icon-fontSize);
    }
  }
}
</style>
