import { CSSProperties } from "vue";

import {
  getBackgroundFillStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { SpacingKeyName } from "~~/models/widgets/widget-controls.model";

import { clearObject } from "../../PromoBetsFenixWidgetHelpers";

export const useDesignInitialStyles = (options: any): CSSProperties => {
  const fill = options.fill ? getBackgroundFillStyle(options.fill) : {};
  const border = options.border ? getBorderStyle(options.border) : {};
  const shadow = options.shadow ? getBoxShadowStyle(options.shadow) : {};

  const cornerRadius = options.cornerRadius
    ? getCornerRadiusStyle(options.cornerRadius)
    : {};
  const margin = options.spacing?.margin
    ? getSpacing(options.spacing?.margin, SpacingKeyName.MARGIN)
    : {};
  const padding = options.spacing?.padding
    ? getSpacing(options.spacing?.padding, SpacingKeyName.PADDING)
    : {};

  const styles = {
    ...fill,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...margin,
    ...padding,
  };

  return clearObject(styles);
};
