import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useGridConfig } from "~~/store/grid";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { createWidgetFromListElement } from "~~/assets/utils/widget";
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";

export const useWidgetsNewDnd = () => {
  const gridStore = useGridConfig();
  const widgetStore = useWidgetsStore();
  const widgetSettingsStore = useWidgetSettingsStore();

  const onEnd = async (data: {
    item: HTMLElement;
    newIndex: number;
    to: any;
  }) => {
    const { item, newIndex, to } = data;

    const fromEl = item as HTMLElement;
    const isTargetEmpty = to.dataset.empty;
    const isGridElement = Boolean(fromEl.dataset.cellId);
    const widgetListElement = JSON.parse(fromEl.dataset.widgetData || "{}");

    /* 
      if container is empty, count it as parent cell. Else take first child element
    */
    const toEl: HTMLElement | undefined = isTargetEmpty ? to : to?.children[0];

    if (!toEl) {
      console.error("Element to drop is not defined");
      return;
    }

    // Disable dnd inside fields container in WidgetsTree.vue
    if (
      toEl.dataset?.isLastChild === "true" ||
      toEl.dataset?.disableDropInside === "true"
    ) {
      widgetStore.setDragTargetEmptyWidgetCell(null);

      return;
    }

    const fromCell = findCell(
      gridStore.currentWidgetsLayout,
      fromEl.dataset.cellId as string
    );
    const toCell = findCell(
      gridStore.currentWidgetsLayout,
      toEl.dataset.cellId || null
    );

    /* 
      If widget dragged from grid, remove it from old place.
      If widget dragged from sidebar widgets list create new widget from list element
    */
    const widgetToMove = isGridElement
      ? widgetStore.removeWidget(fromCell!, fromEl.dataset.widgetId as string)
      : createWidgetFromListElement(
          widgetListElement! as IWidgetsListElement,
          toCell?.cellId
        );

    /* 
      We've dropped widget inside another widget
    */
    if (toEl.dataset.isChildren === "true" || toEl.dataset.parentWidgetId) {
      const parentWidgetId =
        toEl.dataset.parentWidgetId ?? toEl.dataset.widgetId!;
      const parentCellId = toEl.dataset.cellId!;

      const parentWidget = gridStore.findWidget(parentCellId, parentWidgetId);

      if (!parentWidget) {
        console.error(
          `Could not find widget with parent id - ${parentWidgetId} in cell - ${parentCellId}`
        );
        return;
      }

      widgetStore.addChildWidget(
        parentWidget,
        widgetToMove!,
        newIndex,
        isGridElement
      );
      widgetSettingsStore.updateSelectedWidgetData("cell_id", toCell?.cellId);
      widgetStore.setDragTargetEmptyWidgetCell(null);

      return;
    }

    await widgetStore.addWidget(
      toCell!,
      widgetToMove!,
      newIndex,
      isGridElement
    );
    widgetSettingsStore.updateSelectedWidgetData("cell_id", toCell?.cellId);
    widgetStore.setDragTargetEmptyWidgetCell(null);
  };

  /* 
    When order changed inside same container
  */
  const handleUpdate = (data: any) => {
    onEnd(data);
  };

  const onDragMove = (evt: any): boolean => {
    if (evt.related.dataset.empty === "true" && evt.related.dataset.widgetId) {
      widgetStore.setDragTargetEmptyWidgetCell(evt.related.dataset.widgetId);
    } else if (evt.related.dataset.cellId) {
      widgetStore.setDragTargetEmptyWidgetCell(evt.related.dataset.cellId);
    } else {
      widgetStore.setDragTargetEmptyWidgetCell(null);
    }

    return true;
  };

  return {
    onEnd,
    handleUpdate,
    onDragMove,
  };
};
