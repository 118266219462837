<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportEventFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sport-event-fenix-widget">
      <WidgetField
        v-if="
          states.widget !== 'prematch_event' && states.widget !== 'no_events'
        "
        :widget="widget"
        :field="fields.live_header.field"
      >
        <SportEventFenixHeader
          :widget="widget"
          :field="fields.live_header.field"
        >
          <template #left>
            <WidgetField
              v-if="fields.live_header_back_button.field.options._active"
              :widget="widget"
              :field="fields.live_header_back_button.field"
            >
              <BasicIconField
                :field="fields.live_header_back_button.field"
                :value="fields.live_header_back_button.field.options.value"
              />
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.live_header_sport_league_name.field"
            >
              <BasicTitleField
                :field="fields.live_header_sport_league_name.field"
              >
                <div
                  :style="headerTitleStyles"
                  class="sport-event-fenix-widget__header-content"
                >
                  <BasicIconField
                    :options="
                      fields.live_header_sport_league_name.field.options.icon
                    "
                    :value="
                      fields.live_header_sport_league_name.field.options.icon
                        .value
                    "
                  />

                  <span> Sport Name / League Name </span>
                </div>
              </BasicTitleField>
            </WidgetField>
          </template>

          <template #right>
            <WidgetField
              :widget="widget"
              :field="fields.live_header_view_mode_switch.field"
            >
              <SportEventHeaderSwitch
                :widget="widget"
                :field="fields.live_header_view_mode_switch.field"
                :state="states.live_header_view_mode_switch"
              /> </WidgetField
          ></template>
        </SportEventFenixHeader>
      </WidgetField>

      <SportEventBanner
        v-if="states.widget !== 'no_events'"
        :widget="widget"
        :states="states"
        :fields="fields"
      />

      <WidgetField
        v-if="states.widget === 'prematch_event'"
        :widget="widget"
        :field="fields.betting_constructor.field"
      >
        <EventBetBuilder
          :widget="props.widget"
          :field="fields.betting_constructor.field"
        >
          <template #header>
            <WidgetField
              :widget="widget"
              :field="fields.betting_constructor_header.field"
            >
              <EventBetBuilderHeader
                :field="fields.betting_constructor_header.field"
                :state="states.betting_constructor_header"
                value="Build your bet!"
              />
            </WidgetField>
          </template>

          <template #content_container>
            <WidgetField
              :widget="widget"
              :field="fields.betting_constructor_content.field"
            >
              <EventBetBuilderContent
                :widget="widget"
                :field="fields.betting_constructor_content.field"
                :iframe-field="fields.betting_constructor_iframe.field"
              >
                <template #description>
                  <WidgetField
                    :widget="widget"
                    :field="fields.betting_constructor_description.field"
                  >
                    <BasicDescriptionField
                      :fields="[fields.betting_constructor_description.field]"
                      :widget="widget"
                    />
                  </WidgetField>
                </template>
              </EventBetBuilderContent>
            </WidgetField>
          </template>
        </EventBetBuilder>
      </WidgetField>

      <WidgetField
        v-if="states.widget !== 'racing_event' && states.widget !== 'no_events'"
        :widget="widget"
        :field="fields.markets_container.field"
      >
        <SportEventFenixMarketsContainer
          :widget="widget"
          :field="fields.markets_container.field"
        >
          <WidgetField
            v-for="(card, index) in MARKET_CARDS"
            :key="index"
            :widget="widget"
            :field="fields.markets_cards.field"
          >
            <SportEventFenixMarketCard
              :widget="widget"
              :field="fields.markets_cards.field"
            >
              <template #name>
                <WidgetField
                  :widget="widget"
                  :field="fields.markets_market_name.field"
                >
                  <BasicTitleField
                    :field="fields.markets_market_name.field"
                    value="Market Name"
                  />
                </WidgetField>
              </template>

              <template #status>
                <WidgetField
                  :widget="widget"
                  :field="fields.markets_status.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.markets_status.field"
                  >
                    <BasicTitleField
                      :field="fields.markets_status.field"
                      value="Status"
                    />
                  </BasicDesignWrapperField>
                </WidgetField>
              </template>

              <template #pin>
                <WidgetField
                  :widget="widget"
                  :field="fields.markets_pin_icon.field"
                >
                  <BasicIconField
                    :field="fields.markets_pin_icon.field"
                    :value="
                      card.pinned
                        ? 'ant-design:pushpin-filled'
                        : 'ant-design:pushpin-outlined'
                    "
                  />
                </WidgetField>
              </template>

              <template #bet_items>
                <WidgetField
                  :widget="widget"
                  :field="fields.bet_items.field"
                >
                  <SportEventFenixBetItems
                    :field="fields.bet_items.field"
                    :items="card.betItems"
                    :state="states.bet_items"
                    :columns="card.cols"
                  />
                </WidgetField>
              </template>
            </SportEventFenixMarketCard>
          </WidgetField>
        </SportEventFenixMarketsContainer>
      </WidgetField>

      <WidgetField
        v-if="states.widget === 'racing_event'"
        :widget="widget"
        :field="fields.racing_event_info.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.racing_event_info.field"
        >
          <WidgetField
            :widget="widget"
            :field="fields.racing_title.field"
          >
            <BasicTitleField
              :field="fields.racing_title.field"
              value="00:00 Venue — Race distance"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.racing_description.field"
          >
            <BasicTitleField
              :field="fields.racing_description.field"
              value="Group stage: conditions for passage of participants to the next stage."
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <WidgetField
        v-if="states.widget === 'racing_event'"
        :widget="widget"
        :field="fields.racing_players_info.field"
      >
        <RacingSportsPlayersInfo
          :widget="widget"
          :field="fields.racing_players_info.field"
          :states="states"
          :card-field="fields.racing_player_cards.field"
          :header-field="fields.racing_header_card.field"
          :number-field="fields.racing_number.field"
          :name-field="fields.racing_player_name.field"
          :last-races-field="fields.racing_last_5_races.field"
          :prev-odds-field="fields.racing_prev_odds.field"
          :odds-field="fields.racing_odds.field"
          :card-state="states.racing_player_cards"
        >
        </RacingSportsPlayersInfo>
      </WidgetField>

      <WidgetField
        v-if="states.widget === 'no_events'"
        :widget="widget"
        :field="fields.no_events_title.field"
      >
        <BasicTitleField :field="fields.no_events_title.field">
        </BasicTitleField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/sport-event-fenix/useSportEventFenixInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useLanguagesStore } from "~~/store/languages";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();
const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);

const MARKET_CARDS = [
  {
    cols: 2,
    pinned: true,
    betItems: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    cols: 2,
    betItems: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    cols: 2,
    betItems: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    cols: 2,
    betItems: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
  {
    cols: 3,
    betItems: [
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
      { name: "Bet name", coef: "0.00" },
    ],
  },
];

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

init();

const headerTitleStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(
      fields.value.live_header_sport_league_name.field.options.icon.distance
    ),
  };
});

onMounted(() => {
  const widgetBindingParams = props.widget.options.bindingParams;

  if (widgetBindingParams && widgetBindingParams.pageType) {
    const parsedPageTypeParam = (
      widgetBindingParams.pageType.value as Record<string, string>
    )[currentLanguageCode.value]
      .toLowerCase()
      .trim()
      .split(" ")
      .join("_");

    switch (parsedPageTypeParam) {
      case "live":
        states.value.widget = "live_event";
        break;

      case "racing":
        states.value.widget = "racing_event";
        break;

      default:
        states.value.widget = "prematch_event";
    }
  }
});
</script>

<style lang="scss">
.sport-event-fenix-widget {
  &__header-content {
    @include flex(center, flex-start);
  }
}
</style>
