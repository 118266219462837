import { generateInitialLinkOptions } from "~~/helpers/configs/generate-initial-link-options";
import {
  Alignment,
  DisplayOrientation,
  FillType,
  State,
  TextStyle,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import {
  WIDGET_INITIAL as defaultWidgetInitial,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";

import { getDefaultSize } from "../common/widget-initial";

export const WIDGET_INITIAL = {
  options: {
    ...defaultWidgetInitial().options,
    bindingParams: {},
    baseDisplaySettings: {
      alignment: VerticalAlignment.TOP,
    },
    size: getDefaultSize(),
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    spacing: {
      margin: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      padding: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    profileDropdownDisplay: {
      alignment: Alignment.LEFT,
      distanceBetweenItems: 8,
      layout: DisplayOrientation.HORIZONTAL,
    },
    authStates: {
      [State.UNAUTHORIZED]: {
        _active: false,
        profileDropdownDisplay: {
          alignment: Alignment.RIGHT,
          distanceBetweenItems: 8,
          layout: DisplayOrientation.HORIZONTAL,
        },
      },
    },
  },
};

export const BUTTON_INITIAL = () => ({
  value: "Button",
  options: {
    cornerRadius: getDefaultCornerRadiusValue(4),
    padding: {
      top: 4,
      bottom: 4,
      left: 16,
      right: 16,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 15),
    }),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    buttonTextSettings: {
      _active: true,
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      theme: getDefaultTheme(),
      fontFamily: getDefaultFontFamily(),
      decoration: [],
    },
    iconSettings: {
      default_value: "ant-design:arrow-right-outlined",
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      position: "right",
      gap: 5,
      _active: false,
    },
    buttonDisplaySettings: {
      resizing: "hug",
      alignment: "left",
    },
    link: {
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      type: null,
      isNewTabOpen: false,
    },
    states: {
      [State.HOVER]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.DISABLED]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        buttonTextSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        iconSettings: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const DROPDOWN_CONTAINER_INITIAL = () => ({
  options: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 15),
    }),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
  },
});

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    fill: {
      color: "#ffffff",
      opacity: 100,
    },
    border: getDefaultBorderValue({
      fill: getDefaultFillValue("#000000", 15),
    }),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(6),
    padding: { top: 5, bottom: 5, right: 16, left: 16 },
    margins: {
      top: 0,
      bottom: 0,
    },
    text: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 16,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    icon: {
      value: "ant-design:arrow-right-outlined",
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      _active: false,
    },
    arrow: {
      value: "ant-design:arrow-down-outlined",
      default_value: "ant-design:arrow-down-outlined",
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      _active: true,
    },
    display: { resizing: "hug", alignment: "left" },
    states: {
      [State.HOVER]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        icon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        arrow: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "#ffffff",
          opacity: 100,
        },
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        icon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
        arrow: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const ITEMS_GROUP_INITIAL = () => ({
  options: {
    _active: true,
    fillImageColor: {
      fillType: FillType.COLOR,
      value: {
        color: "",
        opacity: 100,
      },
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: {
      top: 4,
      bottom: 4,
      right: 12,
      left: 12,
    },
    margins: {
      top: 4,
      bottom: 4,
    },
    spaceBetween: 1,
    itemsText: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    itemsIcon: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      position: "right",
    },
    states: {
      [State.HOVER]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: {
            color: "",
            opacity: 100,
          },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: {
            color: "",
            opacity: 100,
          },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const BALANCE_INITIAL = () => ({
  options: {
    _active: true,
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    margins: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },

    fontFamily: getDefaultFontFamily(),

    text: {
      _active: true,
      theme: 16,
      decoration: [],
      color: {
        color: "#000000",
        opacity: 100,
      },
    },

    primaryText: {
      theme: 16,
      decoration: [],
      color: {
        color: "#000000",
        opacity: 100,
      },
    },
    icon: {
      _active: true,
      size: 16,
      color: {
        color: "#000000",
        opacity: 100,
      },
    },
    gap: 12,
    layout: DisplayOrientation.HORIZONTAL,
    link: {
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      type: null,
      isNewTabOpen: false,
    },
    states: {
      [State.HOVER]: {
        text: {
          decoration: [],
          color: {
            color: "#000000",
            opacity: 100,
          },
        },

        primaryText: {
          decoration: [],
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
        icon: {
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        text: {
          decoration: [],
          color: {
            color: "#000000",
            opacity: 100,
          },
        },

        primaryText: {
          decoration: [],
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
        icon: {
          color: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const BONUS_INITIAL = () => ({
  options: {
    _active: true,
    text: {
      _active: true,
      theme: 16,
      decoration: [],
      color: {
        color: "#000000",
        opacity: 100,
      },
    },
    primaryText: {
      theme: 16,
      decoration: [],
      color: {
        color: "#000000",
        opacity: 100,
      },
    },
    icon: {
      _active: true,
      size: 16,
      color: {
        color: "#000000",
        opacity: 100,
      },
      default_value: "ant-design:gift-filled",
    },
    link: generateInitialLinkOptions(),
  },
});
