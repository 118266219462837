import { type App } from "vue";
import { addIcon, Icon } from "@iconify/vue";

export default {
  install: (app: App) => {
    const icons = import.meta.glob("~~/assets/icons/*/**.svg", { as: "raw" });

    for (const path in icons) {
      icons[path]().then(icon => {
        const { width = 24, height = 24 } = getSvgDimensions(icon);

        const name = path
          .replace(".svg", "")
          .split("icons")
          .pop()
          ?.split("/")
          .filter(Boolean)
          .join(":");

        const clearIcon = icon.replace(/<svg(.*?)>/, "").replace("</svg>", "");

        addIcon(name as string, {
          body: clearIcon,
          width,
          height,
        });
      });
    }

    app.component("IconifyIcon", Icon);
    // Plugin code goes here
  },
};

function getSvgDimensions(svg: string) {
  const width = (svg.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/) || [])[1];
  const height = (svg.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/) || [])[1];

  return {
    width: parseInt(width),
    height: parseInt(height),
  };
}
