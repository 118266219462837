export interface IStaticTranslations {
  staticTranslations: CategoryTranslations;
  translationLanguage: string;
}

export type CategoryTranslations = {
  [key: string]: Record<TranslationCategory, Record<string, any>>;
};

export interface ITranslationsResponse {
  data: {
    items: CategoryTranslations;
  };
}

export interface IFrontendTranslationsResponse {
  data: {
    translations: CategoryTranslations;
  };
}

export enum TranslationCategory {
  GRID = "grid",
  WIDGETS = "widgets",
  GLOBAL = "global",
}
