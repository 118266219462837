import {
  Alignment,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { ArrowType, iconMapping } from "~~/constants/icons-mapping";
import {
  ITEMS_GROUP_INITIAL,
  ITEMS_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const DEFAULT_STATES = {
    dropdown_container: State.DEFAULT,
    menu_items: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["cornerRadius"], {
    display: {
      resizing: ResizingType.FILL,
      alignment: Alignment.LEFT,
    },
  });

  const init = (): void => {
    initFields();

    const initialDropdownContainerState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000"),
        }),
        shadow: getDefaultShadow(),
        text: {
          color: getDefaultFillValue("#1677FF"),
        },
        icon: {
          textColor: getDefaultFillValue("#1677FF"),
        },
        arrow: {
          textColor: getDefaultFillValue("#1677FF"),
        },
      };
    };

    addFieldInitialOptions(
      "dropdown_container",
      {
        data: TITLE_INITIAL(),
        exclude: ["icon"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          distanceBetweenItems: 8,
          text: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          icon: {
            value: "ant-design:arrow-right-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 14,
            gap: 8,
            _active: true,
          },
          arrow: {
            _active: true,
            value: ArrowType.Down,
            default_value: iconMapping[ArrowType.Down],
            arrowSize: 14,
            gap: 8,
            textColor: getDefaultFillValue("#1F1F1F"),
          },
          states: {
            [State.HOVER]: initialDropdownContainerState(),
            [State.ACTIVE]: initialDropdownContainerState(),
            [State.FILTERED]: initialDropdownContainerState(),
          },
        },
      },
      { value: "This is default title" }
    );

    addFieldInitialOptions("dropdown_menu", {
      data: ITEMS_INITIAL(),
      values: {
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 6),
        }),
        shadow: {
          fill: getDefaultFillValue("#000000", 10),
          x: 0,
          y: 10,
          blur: 20,
          spread: 4,
        },
        margins: getDefaultSpacing({
          top: 4,
        }),
      },
    });

    addFieldInitialOptions("dropdown_container", {
      data: ITEMS_INITIAL(),
      values: {
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 6),
        }),
        shadow: {
          fill: getDefaultFillValue("#000000", 10),
          x: 0,
          y: 10,
          blur: 20,
          spread: 4,
        },
        margins: getDefaultSpacing({
          top: 4,
        }),
      },
    });

    const initialMenuItemsState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000"),
        }),
        shadow: getDefaultShadow(),
        itemsText: {
          color: getDefaultFillValue("#1677FF"),
        },
        arrow: {
          textColor: getDefaultFillValue("#1677FF"),
        },
      };
    };

    addFieldInitialOptions(
      "menu_items",
      {
        data: ITEMS_GROUP_INITIAL(),
        exclude: ["itemsIcon", "margins"],
        values: {
          fill: getDefaultFillValue(),
          cornerRadius: getDefaultCornerRadiusValue(6),
          itemsText: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          spaceBetween: 4,
          states: {
            [State.HOVER]: initialMenuItemsState(),
            [State.ACTIVE]: initialMenuItemsState(),
          },
        },
      },
      { value: "Item" }
    );

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
