import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSportsbookLiveFenixItems } from "~~/composables/widgets/sportbook-live/fenix/useSportsbookLiveFenixItems";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useAllowDisableDependentFieldContentItems } from "../../useAllowDisableDependentFieldContentItems";
import { useBetItemsConfig } from "../../sportsbook/useBetItemsConfig";

import { useSportbookLiveFenixRacingConfig } from "./useSportbookLiveFenixRacingConfig";

export const useSportsbookLiveFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS } = useSportsbookLiveFenixItems(widget);

  /* 
    Bet items
  */

  const { betItemsDesignControls, betItemsContentControls } = useBetItemsConfig(
    states,
    emit,
    true
  );

  /* 
    Ricing config
  */

  const {
    racingContainerDesignControls,
    racingContainerContentControls,
    racingEventCardsDesignControls,
    racingEventCardsContentControls,
    racingEventCardsStateControls,
    racingEventNameControls,
    racingEventStatusDesignControls,
    racingEventStatusContentControls,
    racingFavouritesButtonControls,
    racingEventButtonDesignControls,
    racingEventButtonContentControls,
    racingEventButtonStateControls,
    racingStreamIconControls,
  } = useSportbookLiveFenixRacingConfig(widget, states, emit);

  /* 
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    const sharedControls = [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.events_status,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.active_events,
              value: State.ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS.no_active,
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "events_status", value });
        },
      },
    ];

    if (states.value.events_status === State.ACTIVE_EVENTS) {
      return [
        ...sharedControls,
        {
          section: generateContentSectionName("Sports"),
          showConfig: true,
          onConfigClick() {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              fields.value.sports_container.field
            );
          },
          controls: [
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.distanceBetweenSports",
              valueSource: fields.value.sports_container.field,
              options: {
                label: "Distance between sports",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "value",
          valueSource: fields.value.no_events_title.field,
          options: {
            placeholder: "Text",
            label: "Text",
          },
        },
        typography(
          { source: fields.value.no_events_title.field },
          "Typography"
        ),
      ];
    }
  });

  /* 
   Sports container
  */

  const sportsContainerDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.sports_container.field,
    });
  });

  const sportsContainerContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distanceBetweenSports",
            valueSource: fields.value.sports_container.field,
            options: {
              label: "Distance between sport events",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "title", label: "Sports title" }],
        },
      },
      {
        section: generateContentSectionName("Leagues"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.leagues_titles.field
          );
        },
        toggleable: true,
        valueSource: fields.value.leagues_titles.field.options,
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distanceBetweenLeagueEvents",
            valueSource: fields.value.sports_container.field,
            options: {
              label: "Distance between league events",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Sport events"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.sport_events_container.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.sport_events_container.field,
            options: {
              label: "Distance between event cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Racing events"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.racing_events_container.field
          );
        },
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.racing_events_container.field,
            options: {
              label: "Distance between event cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
    ];
  });

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const sportsContainerStateControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
    if (states.value[selectedField.value!.name] === State.HOVER) {
      return [
        ...sharedControls,
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              {
                value: "title_hover_button",
                label: "Hover button",
                isActiveValueSource:
                  fields.value.title_hover_button.field.options,
              },
            ],
          },
        },
      ];
    } else {
      return [...sharedControls];
    }
  });

  /* 
   Sports title config
  */

  const titleDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: `options.padding`,
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const titleContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distanceBetweenItems",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        { source: selectedField.value, except: ["ConfigAlignment"] },
        "Text"
      ),
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "title_button",
              label: "Sports title button",
              isActiveValueSource: fields.value.title_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const titleStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.icon.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
   Sports title button
  */
  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const titleButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.title_button.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.title_button.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.title_button.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const titleButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        visible:
          selectedField.value?.options.buttonDisplaySettings.resizing ===
          ResizingType.FILL,
        options: {
          isTextContentIcons: false,
        },
      },
    ];
  });

  const titleButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /* 
   Title hover button
  */

  const titleHoverButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.title_hover_button.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.title_hover_button.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.title_hover_button.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const titleHoverButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        visible:
          selectedField.value?.options.buttonDisplaySettings.resizing ===
          ResizingType.FILL,
        options: {
          isTextContentIcons: false,
        },
      },
    ];
  });

  const titleHoverButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /* 
   Events cards
  */
  const eventCardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_cards.field,
    });
  });

  const eventCardsContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.contentLayout",
            valueSource: fields.value.event_cards.field,
            options: {
              label: "Content layout",
              items: [
                {
                  label: "First",
                  value: 1,
                },
                {
                  label: "Second",
                  value: 2,
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.contentItemsDistance",
            valueSource: fields.value.event_cards.field,
            options: {
              label: "Distance between card elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { value: "event_header", label: "Header" },
            { value: "event_info", label: "Event Info" },
            { value: "bet_items", label: "Bet Items" },
            {
              value: "event_button",
              label: "Event Button",
              isActiveValueSource: fields.value.event_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const eventCardsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        section: generateContentSectionName("Card"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.fill`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${selectedElementPath.value}.border`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigBoxShadow",
            valuePath: `${selectedElementPath.value}.shadow`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Shadow",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Event Name"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath:
              states.value[selectedField.value!.name] !== State.DEFAULT
                ? `${selectedElementPath.value}.color`
                : "options.color",
            valueSource:
              states.value[selectedField.value!.name] !== State.DEFAULT
                ? selectedField.value
                : fields.value.event_name.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /**
   * Sport Events Container
   */

  const sportEventsContainerDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.sport_events_container.field,
    });
  });

  const sportEventsContainerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigDisplayGridSettings",
            valuePath: "options.gridSettings",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [{ value: "event_cards", label: "Sport event card" }],
        },
      },
    ];
  });

  /**
   * Leagues titles
   */

  const leaguesTitlesDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.leagues_titles.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.leagues_titles.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.leagues_titles.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const leaguesTitlesContentControls = computed(() => {
    return [
      typography(
        {
          source: fields.value.leagues_titles.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
    ];
  });

  /**
   * Event League name
   */

  const eventLeagueControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.resizing",
            valueSource: fields.value.event_league.field,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
        ],
      },
      typography({
        source: fields.value.event_league.field,
        addElements: [
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.event_league.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      }),
    ];
  });

  /**
   * Favorites Button
   */

  const favouritesButtonControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.favourites_button.field,
        options: {
          hidePicker: true,
          hideSwitch: true,
          className: "p-l-16 p-t-16 p-r-16",
        },
      },
    ];
  });

  /**
   * Status event
   */

  const eventStatusDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_suspended_status.field,
      except: ["ConfigSpacingInputs", "ConfigBoxShadow"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.event_suspended_status.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.event_suspended_status.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const eventStatusContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_suspended_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Event stream icon
   */

  const streamIconControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options",
        valueSource: fields.value.stream_icon.field,
        options: {
          hideSwitch: true,
          className: "p-l-16 p-t-16 p-r-16",
        },
      },
    ];
  });

  /**
   * Event time
   */

  const eventTimeControls = computed(() => {
    return [
      typography({
        source: fields.value.event_time.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.event_time.field.options.icon,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.event_time.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_time.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /**
   * Event period
   */

  const eventPeriodControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.resizing",
            valueSource: fields.value.event_period.field,
            options: {
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
        ],
      },
      typography({ source: fields.value.event_period.field }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_period.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /**
   * Event info
   */

  const eventInfoDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_info.field,
      except: ["ConfigCornerRadius"],
    });
  });

  const eventInfoContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Event info layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.distance",
            valueSource: fields.value.event_info.field,
            options: {
              label: "Distance between event info items",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Event name", value: "event_name" },
            {
              label: "Score",
              value: "event_score",
              isActiveValueSource: fields.value.event_score.field.options,
            },
          ],
        },
      },
    ];
  });

  /**
   * Event name
   */

  const eventNameControls = computed(() => {
    return [
      typography({ source: fields.value.event_name.field }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_name.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /**
   * Event score
   */

  const eventScoreDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_score.field,
      except: ["ConfigBoxShadow", "ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigSpacingInputs",
            valuePath: "options.spacing",
            valueSource: fields.value.event_score.field,
            options: {
              isBold: true,
              placeholder: "None",
              label: false,
            },
          },
        ],
      ],
    });
  });

  const eventScoreContentControls = computed(() => {
    return [
      typography({
        source: fields.value.event_score.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  /**
   * Event button
   */

  const eventButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_button.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.event_button.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: fields.value.event_button.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const eventButtonContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            visible:
              selectedField.value?.options.buttonDisplaySettings.resizing ===
              ResizingType.FILL,
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const eventButtonStateControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /**
   * Event header
   */

  const eventHeaderDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.event_header.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigSpacingInputs",
            valuePath: "options.spacing",
            valueSource: fields.value.event_header.field,
            options: {
              isBold: true,
              placeholder: "None",
              label: false,
            },
          },
        ],
      ],
    });
  });

  const eventHeaderContentControls = computed(() => {
    // Vertical | Horizontal
    const layout = selectedField.value?.options.contentLayout;

    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.contentLayout",
            valueSource: fields.value.event_header.field,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath:
              layout === DisplayOrientation.VERTICAL
                ? "options.leaguePosition.vertical"
                : "options.leaguePosition.horizontal",
            valueSource: fields.value.event_header.field,
            options: {
              label: "League name position",
              items: [
                {
                  label:
                    layout === DisplayOrientation.VERTICAL ? "Top" : "Left",
                  value:
                    layout === DisplayOrientation.VERTICAL
                      ? VerticalPosition.TOP
                      : Alignment.LEFT,
                },
                {
                  label:
                    layout === DisplayOrientation.VERTICAL ? "Bottom" : "Right",
                  value:
                    layout === DisplayOrientation.VERTICAL
                      ? VerticalPosition.BOTTOM
                      : Alignment.RIGHT,
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.iconAlignment",
            valueSource: fields.value.event_header.field,
            className: "group-control-element--child",
            options: {
              label: "Favorite icon alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.event_header.field,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "event_league",
              label: "League Name",
              isActiveValueSource: fields.value.event_league.field.options,
            },
            {
              value: "favourites_button",
              label: "Favorite icon",
              isActiveValueSource: fields.value.favourites_button.field.options,
            },
            {
              value: "stream_icon",
              label: "Steam Icon",
              isActiveValueSource: fields.value.stream_icon.field.options,
            },
            {
              value: "event_period",
              label: "Period",
              isActiveValueSource: fields.value.event_period.field.options,
            },
            { value: "event_time", label: "Time" },
            { value: "event_suspended_status", label: "Status" },
          ],
        },
      },
    ];
  });

  /* 
    Bet items stateControls
  */

  const currentBetItemPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const liveBetStates = computed(() => {
    return [
      {
        label: STATE_KEY_LABELS.up,
        value: State.UP,
      },
      {
        label: STATE_KEY_LABELS.down,
        value: State.DOWN,
      },
    ];
  });

  const betItemsStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            ...liveBetStates.value,

            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
            {
              label: "Suspended",
              value: "suspended",
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentBetItemPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentBetItemPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentBetItemPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Bet name text style"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.name.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.name.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Coefficient text style"),
        visible:
          (states.value[selectedField.value!.name] as string) !== "suspended",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.coef.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentBetItemPath.value}.coef.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        visible: [State.UP, State.DOWN, State.HOVER, State.ACTIVE].includes(
          states.value[selectedField.value!.name]
        ),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.up.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !==
              State.DOWN,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Up color",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentBetItemPath.value}.arrow.down.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !== State.UP,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Down color",
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    sportsContainerDesignControls,
    sportsContainerContentControls,
    sportsContainerStateControls,

    titleDesignControls,
    titleContentControls,
    titleStateControls,

    titleButtonDesignControls,
    titleButtonContentControls,
    titleButtonStateControls,

    titleHoverButtonDesignControls,
    titleHoverButtonContentControls,
    titleHoverButtonStateControls,

    eventCardsDesignControls,
    eventCardsContentControls,
    eventCardsStateControls,

    leaguesTitlesDesignControls,
    leaguesTitlesContentControls,

    sportEventsContainerDesignControls,
    sportEventsContainerContentControls,

    eventHeaderDesignControls,
    eventHeaderContentControls,

    eventLeagueControls,

    favouritesButtonControls,

    eventStatusDesignControls,
    eventStatusContentControls,

    streamIconControls,

    eventTimeControls,

    eventPeriodControls,

    eventInfoDesignControls,
    eventInfoContentControls,

    eventNameControls,

    eventScoreDesignControls,
    eventScoreContentControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    eventButtonDesignControls,
    eventButtonContentControls,
    eventButtonStateControls,
    // Racing
    racingContainerDesignControls,
    racingContainerContentControls,

    racingEventCardsDesignControls,
    racingEventCardsContentControls,
    racingEventCardsStateControls,

    racingEventNameControls,

    racingEventStatusDesignControls,
    racingEventStatusContentControls,

    racingFavouritesButtonControls,

    racingEventButtonDesignControls,
    racingEventButtonContentControls,
    racingEventButtonStateControls,

    racingStreamIconControls,
  };
};
