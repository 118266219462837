import { type Ref } from "vue";

import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useWidgetFields } from "../useWidgetFields";

export const useBetslipCollapseControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const fields = useWidgetFields(widget.value);

  const collapseDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.collapse.field,
    });
  });

  const collapseContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: fields.value.collapse.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography({
        source: fields.value.collapse.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrowSettings",
            valueSource: fields.value.collapse.field,
            className: "p-t-0",
            options: {
              label: "Fill",
              isBold: true,
              allowDisable: false,
              showOptions: false,
              meta: {
                hideToggle: true,
              },
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.arrowSettings.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16 config-group-last-element",
            valueSource: fields.value.collapse.field,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "form/ConfigTabs",
            className: "config-group-last-element",
            options: {
              items: [
                {
                  label: "Tooltip",
                  value: "multibet_tooltip",
                },
              ],
            },
          },
        ],
      },
    ];
  });

  const currentStatePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const collapseStateControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentStatePath.value}.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.arrowSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    collapseDesignControls,
    collapseContentControls,
    collapseStateControls,
  };
};
