import type { ComputedRef, Ref } from "vue";

import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";

export const useSuccessScreenConfig = (
  widget: Ref<IWidgetWithFields>,
  configFormItems: Ref<ConfigTabItem[]>,
  activeView: Ref<string>,
  emit: any,
  isWidthConfigDisabled: ComputedRef<boolean>,
  valueSources: Ref<Record<string, IWidgetField>>,
  imageNameKey: Ref<string>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const allowScreenFieldsDisable = computed<boolean>(() => {
    return (
      Object.values(valueSources.value).filter(field => {
        return field.options._active;
      }).length > 1
    );
  });

  const screenContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: activeView.value,
        options: {
          items: configFormItems.value,
        },
        onUpdate(value: string) {
          emit("update-active-view", value);
        },
      },
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: `options.states.${activeView.value}.contentWidth`,
        valueSource: widget.value,
        options: {
          items: configFormItems.value,
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "content/ConfigImageWithToggle",
        valuePath: "value",
        valueSource: valueSources.value.image,
        options: {
          label: "Image",
          allowDisable: allowScreenFieldsDisable.value,
          active: valueSources.value.image.options._active,
          showOptions: valueSources.value.image.options._active,
          attribute: imageNameKey.value,
          "onUpdate:active": (value: boolean) => {
            valueSources.value.image.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              valueSources.value.image
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: valueSources.value.title,
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: allowScreenFieldsDisable.value,
          active: valueSources.value.title.options._active,
          showOptions: valueSources.value.title.options._active,
          "onUpdate:active": (value: boolean) => {
            valueSources.value.title.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              valueSources.value.title
            );
          },
        },
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: valueSources.value.description,
        className: "text-widget__content-input",
        options: {
          placeholder: "Enter text",
          label: "Description",
          allowDisable: allowScreenFieldsDisable.value,
          fields: [valueSources.value.description],
          primaryField: valueSources.value.description,
          active: valueSources.value.description.options._active,
          showOptions: valueSources.value.description.options._active,
          "onUpdate:active": (value: boolean) => {
            valueSources.value.description.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              valueSources.value.description
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: valueSources.value.button,
        options: {
          placeholder: "Enter text",
          label: "Button",
          max: 50,
          allowDisable: allowScreenFieldsDisable.value,
          active: valueSources.value.button.options._active,
          showOptions: valueSources.value.button.options._active,
          "onUpdate:active": (value: boolean) => {
            valueSources.value.button.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              valueSources.value.button
            );
          },
        },
      },
    ];
  });

  return {
    screenContentControls,
  };
};
