import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { generateLeagueBetItemCssString } from "~~/assets/utils/widget-css/widgets/sportsbook-widget-css";
import { generateCardPositionCss } from "~~/assets/utils/widget-css/utils/form-helper-functions";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateStringDefault } from "../utils/pipe-helper-functions";
import { generateStageButtonCssString } from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateGrid,
  generateTextColor,
} from "../helpers";
import { getPxValueFromNumber } from "../..";
import { getFlexPosition } from "../../widget-settings";

export const generateCardsCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const settings = field.options.display;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${settings.layout}, 1fr)`,
      columnGap: settings.distance,
      rowGap: settings.distance,
    });

    cssString += generateCssClassWithContent({
      className: field.options._containerCssClass,
      content: widgetPositionCssContent,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateCardPositionCss(field.options.display.contentPosition),
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: " > div",
      content: "flex: 1;",
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

export const generatePhotoCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: generateDefaultStyles({
        padding: field.options.padding,
      }),
    });

    const imgCustomStyles = "";

    const { resizing, containerSize } = field.options;
    const objectFit = resizing === ResizingType.FIT ? "contain" : "cover";
    const width = resizing === ResizingType.FILL ? "100%" : "unset";
    const height = getPxValueFromNumber(containerSize);

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      pseudoClassName: " img",
      content:
        imgCustomStyles +
        generateDefaultStyles({
          ...field.options,
          padding: null,
        }) +
        `object-fit: ${objectFit};` +
        `width: ${width};` +
        `height: ${height};`,
    });

    return cssString;
  };

export const generateTimeCssString =
  (startTime: IWidgetField) =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(startTime.options) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          gap: "4",
        }),
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: getFlexPosition(options.alignment),
        gap: "8",
      }),
    });

    return cssString;
  };

export const generateEventInfoCssString =
  (field: IWidgetField) =>
  (cssString: string): string => {
    const { options } = field;

    const isHorizontal =
      options.display.layout === DisplayOrientation.HORIZONTAL;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateFlex({
        direction: isHorizontal ? "row" : "column",
        flex: "flex",
        align: isHorizontal ? "center" : "stretch",
        justify: "flex-start",
        gap: options.display.distance,
      }),
    });

    return cssString;
  };

export const generateBetItemsContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const isHorizontal = options.displayMode === DisplayOrientation.HORIZONTAL;

    const betItemsContainerCss = generateGrid({
      gridAutoFlow: isHorizontal ? "column" : "row",
      rowGap: options.distance,
      columnGap: options.distance,
      templateColumns: isHorizontal
        ? "repeat(auto-fit, minmax(50px, 1fr));"
        : "",
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content: betItemsContainerCss,
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      pseudoClassName: " span",
      content: "overflow: hidden;text-overflow: ellipsis;white-space: nowrap;",
    });

    return cssString;
  };

export const generatePromotedBetsWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName("PromotedBetsWidget");
  widget.options._contentCssClass = generateClassName(
    "PromotedBetsWidget_content"
  );

  const cards = widget.content["event_card"];
  const image = widget.content["event_image"];
  const event_name = widget.content["event_name"];
  const event_date_time = widget.content["event_date_time"];
  const event_button = widget.content["event_button"];
  const bet_items = widget.content["bet_items"];
  const event_info = widget.content["event_info"];

  cards.options._cssClass = generateClassName("event_card");
  cards.options._containerCssClass = generateClassName("event_card");

  image.options._cssClass = generateClassName("image");

  event_info.options._cssClass = generateClassName("event_info");
  event_name.options._cssClass = generateClassName("event_name");

  event_date_time.options._cssClass = generateClassName("event_date_time");
  event_date_time.options._containerCssClass = generateClassName(
    "event_date_time_container"
  );
  event_date_time.options.icon._cssClass = generateClassName(
    "event_date_time_icon"
  );

  event_button.options.iconSettings._cssClass =
    generateClassName("event_button_icon");
  event_button.options._cssClass = generateClassName("event_button");

  bet_items.options._containerCssClass = generateClassName(
    "bet_items_container"
  );
  bet_items.options._cssClass = generateClassName("bet_items");

  bet_items.options._itemCssClass = generateClassName("bet_items_item");
  bet_items.options._itemSelectedCssClass = bet_items.options._itemCssClass;

  bet_items.options.name._cssClass = generateClassName("bet_items_name");
  bet_items.options.coef._cssClass = generateClassName("bet_items_coef");

  bet_items.options._itemSelectedCssClass =
    bet_items.options._cssClass + '[data-selected="true"]';

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(event_info),
    generateEventInfoCssString(event_info as IWidgetField),
    generateCardsCssString(cards),
    generatePhotoCssString(image),
    generateStringDefault(event_name),
    generateTimeCssString(event_date_time as IWidgetField),
    generateStageButtonCssString(event_button as IWidgetField),
    generateLeagueBetItemCssString(bet_items as IWidgetField, true),
    generateBetItemsContainerCssString(bet_items)
  )("");
};
