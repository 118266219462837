import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { stateConfig } from "~~/constants/configs/common/state-config";

export const useSportsbookLiveFenixSportEventsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    Sport events container config
  */

  const sportEventsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const sportEventsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Event cards layout",
              items: [
                {
                  icon: "custom:sportbook-live-fenix-col-1",
                  value: 1,
                },
                {
                  icon: "custom:sportbook-live-fenix-col-2",
                  value: 2,
                },
                {
                  icon: "custom:sportbook-live-fenix-col-3",
                  value: 3,
                },
                {
                  icon: "custom:sportbook-live-fenix-col-4",
                  value: 4,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Sport event card"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.event_cards.field
          );
        },
        controls: [],
      },
    ];
  });

  /* 
    Sport events  config
  */

  const sportEventsCardsDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const sportEventsCardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_cards.field,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: 1,
                },
                {
                  label: "Second",
                  value: 2,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: fields.value.event_cards.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Header", value: "event_header" },
            { label: "Event info", value: "event_info" },
            { label: "Bet items", value: "bet_items" },
            {
              label: "Event button",
              value: "event_button",
              isActiveValueSource: fields.value.event_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const racingEventsCardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between cards elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            { label: "Event name", value: "racing_event_name" },
            {
              label: "Stream icon",
              value: "racing_event_stream_icon",
              isActiveValueSource:
                fields.value.racing_event_stream_icon.field.options,
            },
            {
              label: "Favorite icon",
              value: "racing_event_favourites_button",
              isActiveValueSource:
                fields.value.racing_event_favourites_button.field.options,
            },
            {
              label: "Event button",
              value: "racing_event_button",
              isActiveValueSource:
                fields.value.racing_event_button.field.options,
            },
          ],
        },
      },
    ];
  });

  const currentEventStatePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const sportEventsCardsStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value[selectedField.value!.name],
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      }),
      {
        section: generateDesignSectionName("Card"),
        controls: [
          ...getInitialDesignList({
            source: selectedField.value,
            except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
            basePath: currentEventStatePath.value,
            isBold: false,
          }),
        ],
      },
      {
        section: generateDesignSectionName("Event name"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentEventStatePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              type: ColorPickerType.BACKGROUND,
            },
          },
        ],
      },
    ];
  });

  /* 
    Sport events header config
  */

  const sportEventHeadersDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: fields.value.event_header.field,
    });
  });

  const sportEventHeadersContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_header.field,
            options: {
              label: "Header layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.position",
            valueSource: fields.value.event_header.field,

            visible:
              fields.value.event_header.field.options.display.layout ===
              DisplayOrientation.VERTICAL,
            options: {
              label: "League name position",
              items: [
                {
                  label: "Top",
                  value: "top",
                },
                {
                  label: "Bottom",
                  value: "bottom",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.position",
            valueSource: fields.value.event_header.field,

            visible:
              fields.value.event_header.field.options.display.layout ===
              DisplayOrientation.HORIZONTAL,
            options: {
              label: "League name position",
              items: [
                {
                  label: "Left",
                  value: "left",
                },
                {
                  label: "Right",
                  value: "right",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.iconAlignment",
            valueSource: fields.value.event_header.field,
            options: {
              label: "Favorite icon alignment",
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.display.distance",
            valueSource: fields.value.event_header.field,
            options: {
              label: "Distance between header elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "League name",
              value: "event_league",
              isActiveValueSource: fields.value.event_league.field.options,
            },
            {
              label: "Favorite icon",
              value: "favourites_button",
              isActiveValueSource: fields.value.favourites_button.field.options,
            },
            {
              label: "Stream icon",
              value: "stream_icon",
              isActiveValueSource: fields.value.stream_icon.field.options,
            },
            {
              label: "Period",
              value: "event_period",
              isActiveValueSource: fields.value.event_period.field.options,
            },
            {
              label: "Time",
              value: "event_time",
            },
          ],
        },
      },
    ];
  });

  return {
    sportEventsDesignControls,
    sportEventsContentControls,

    sportEventsCardsDesignControls,
    sportEventsCardsContentControls,
    racingEventsCardsContentControls,
    sportEventsCardsStatesControls,

    sportEventHeadersDesignControls,
    sportEventHeadersContentControls,
  };
};
