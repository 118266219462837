<template>
  <div
    class="wconfig-number-input widget-control-container p-b-16 p-l-16 p-r-16 p-t-16"
  >
    <div class="wconfig-number-input__label-container">
      <span
        class="m-b-4"
        :class="{
          'sub-heading': isBold,
        }"
      >
        {{ label }}
      </span>
      <div
        v-if="showTooltip"
        class="tooltip m-b-4"
      >
        <a-tooltip placement="top">
          <template #title>
            {{ tooltipContent }}
          </template>

          <CommonIcon
            v-if="showTooltip"
            :size="16"
            name="ant-design:question-circle-outlined"
          />
        </a-tooltip>
      </div>
    </div>

    <ConfigInput
      v-number
      :is-number="true"
      :model-value="modelValue"
      :stretch-input="true"
      :input-props="{
        min: 0,
        max: maxValue,
        controls: false,
      }"
      class="wconfig-number-input__input"
      @update:model-value="handleUpdate"
    >
      <template
        v-if="suffixContent"
        #suffix
        >{{ suffixContent }}</template
      >
    </ConfigInput>
  </div>
</template>

<script lang="ts" setup>
import { MAX_NUMBER_INPUT_VALUE } from "~~/constants/input-validation";

withDefaults(
  defineProps<{
    modelValue: string | number | null;
    label?: string;
    isBold?: boolean;
    suffixContent?: any;
    showTooltip?: boolean;
    tooltipContent?: string;
    maxValue?: number;
  }>(),
  {
    label: "Number",
    isBold: false,
    showIconPrefix: true,
    suffixContent: "",
    showTooltip: false,
    tooltipContent: "",
    maxValue: MAX_NUMBER_INPUT_VALUE,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const handleUpdate = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.wconfig-number-input {
  &__label-container {
    @include flex(center, flex-start, 8px);

    .tooltip {
      color: $c-grey-45;
    }
  }

  &__input {
    width: 100%;

    .ant-input-number-group-wrapper {
      width: 100%;
    }
  }
}
</style>
