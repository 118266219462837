<template>
  <div
    class="widget-wrapper position-relative"
    :class="{
      'widget-wrapper--loading': widgetIdDetailsLoading === widget.id,
      'widget-wrapper--not-visible': !isVisible,
    }"
  >
    <CommonSpinner v-if="widgetIdDetailsLoading === widget.id" />

    <component
      :is="widgetComponent"
      v-else
      :cell="cell"
      :widget="widget"
    >
      <template #default>
        <DraggableList
          :model-value="widget.options._children"
          :item-key="
            (e: IWidgetWithFields) => {
              return `${e.id}-${e.position}`;
            }
          "
          :class="{
            'widget-wrapper--empty': !widget.options._children?.length,
            'widget-wrapper--target':
              widget.id === widgetsStore.dragTargetEmptyWidgetCell,
          }"
          :fallback-on-body="true"
          :data-cell-id="cell.cellId"
          :data-empty="!widget.options._children?.length"
          :data-widget-id="widget.id"
          :scroll-sensitivity="200"
          :force-fallback="true"
          group="widgets"
          data-is-children="true"
          class="widget-wrapper__children"
          @add="onEnd"
          @update="handleUpdate"
          @move="onDragMove"
        >
          <template #item="{ element: childWidget, index }">
            <WidgetCellItem
              :key="childWidget.id"
              :widget="childWidget"
              :cell="cell"
              :index="index"
              :data-widget-id="childWidget.id"
              :data-cell-id="props.cell.cellId"
              :parent-widget="widget"
              :data-is-child-element="true"
              :data-parent-widget-id="widget.id"
              is-child
            />
          </template>
        </DraggableList>

        <div
          v-if="!widget.options._children?.length"
          data-empty="true"
          data-is-children="true"
          :data-widget-id="widget.id"
          :data-cell-id="cell.cellId"
          class="widget-wrapper__drop-zone"
        >
          Drop child widget here
        </div>
      </template>
    </component>
  </div>
</template>

<script lang="ts" setup>
import { type Component, defineAsyncComponent } from "vue";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useLanguagesStore } from "~~/store/languages";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetsNewDnd } from "~~/components/composables/widgets/useWidgetsNewDnd";
import { ElementStyle } from "~~/models/common";

const widgetsStore = useWidgetsStore();

const props = defineProps<{
  cell: ICell;
  widget: IWidgetWithFields;
  containerStyle?: ElementStyle;
}>();

const { widgetIdDetailsLoading } = storeToRefs(widgetsStore);

const languagesStore = useLanguagesStore();

const { currentLanguageCode } = storeToRefs(languagesStore);

const isVisible = computed<boolean>(() => {
  return props.widget.options._isVisible?.[currentLanguageCode.value];
});

const widgetComponent = computed<Component>(() => {
  return defineAsyncComponent(async () => {
    const widgetId = props.widget.widget_id;
    let component;

    try {
      component = await import(
        `~~/components/widgets/widget-components/${widgetId}.vue`
      );
    } catch (e) {
      try {
        component = await import(
          `~~/components/widgets/widgets-entities/${widgetId}/index.ts`
        );
      } catch (e) {
        console.error(`Component for widget ID "${widgetId}" not found.`);
        throw e;
      }
    }

    return component;
  });
});

const { onEnd, onDragMove, handleUpdate } = useWidgetsNewDnd();
</script>

<style lang="scss">
.widget-wrapper {
  height: 100%;

  &--empty {
    min-height: 100px;
  }

  &--loading {
    min-height: 30px;
  }

  &--not-visible {
    display: none;
  }

  &__drop-zone {
    height: 100px;
    background-color: var(--container-containerFill);
    @include flex(center, center, 0);
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  &--target {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      border: 2px dashed $c-primary-base;
      background-color: $c-light-blue;
    }

    & + .widget-wrapper__drop-zone {
      display: none;
    }
  }

  &__children {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: var(--container-flexDirection);
    gap: var(--container-gap);
    flex-wrap: var(--container-flexWrap, "nowrap");
    align-items: var(--container-align-items);
    justify-content: var(--container-justify-content);
    overflow-x: var(--container-overflowX);
    overflow-y: var(--container-overflowY);
  }
}
</style>
