<template>
  <div
    :class="{
      'p-t-16 p-b-16 widget-control-container':
        (shouldShowStyleSettings && hasDesignPermissions) ||
        hasContentPermissions,
    }"
  >
    <div class="p-l-16 p-r-16">
      <p
        v-if="
          (shouldShowStyleSettings && hasDesignPermissions) ||
          hasContentPermissions
        "
        class="sub-heading"
      >
        Link
      </p>
      <span
        v-if="shouldShowStyleSettings && hasDesignPermissions"
        class="c-primary-base pointer"
        @click="$emit('show-states-settings')"
      >
        States settings
      </span>

      <p
        v-if="shouldShowTypeChanging && hasContentPermissions"
        class="m-b-4 m-t-16 f-base"
      >
        Type
      </p>

      <div
        v-if="shouldShowTypeChanging && hasContentPermissions"
        class="config-link-type__menu"
      >
        <button
          :class="{
            'config-link-type__button--active':
              currentLink.type === null || !currentLink,
          }"
          class="config-link-type__button pointer"
          @click="updateLinkType(null)"
        >
          <CommonIcon name="ant-design:minus-outlined" />
        </button>
        <button
          :class="{
            'config-link-type__button--active':
              currentLink.type === LinkType.EXTERNAL,
          }"
          class="config-link-type__button pointer"
          @click="updateLinkType(LinkType.EXTERNAL)"
        >
          <CommonIcon name="ant-design:global-outlined" />
        </button>
        <button
          :class="{
            'config-link-type__button--active':
              currentLink.type === LinkType.MODAL,
          }"
          class="config-link-type__button pointer"
          @click="updateLinkType(LinkType.MODAL)"
        >
          <CommonIcon name="vaadin:modal" />
        </button>

        <button
          :class="{
            'config-link-type__button--active':
              currentLink.type === LinkType.PHONE,
          }"
          class="config-link-type__button pointer"
          @click="updateLinkType(LinkType.PHONE)"
        >
          <CommonIcon name="ant-design:phone-outlined" />
        </button>
        <button
          :class="{
            'config-link-type__button--active':
              currentLink.type === LinkType.EMAIL,
          }"
          class="config-link-type__button pointer"
          @click="updateLinkType(LinkType.EMAIL)"
        >
          <CommonIcon name="ant-design:mail-outlined" />
        </button>
      </div>

      <div
        v-if="inputDetails && hasContentPermissions"
        class="m-t-16"
      >
        <label
          v-if="inputDetails.hasAuthorizeValueField"
          class="flex gap-8 items-center m-b-16"
        >
          <a-switch
            v-model:checked="isOneLinkForAll"
            size="small"
            @change="handleChange({})"
          />

          <div>General for all users</div>
        </label>

        <div
          v-if="!isOneLinkForAll && inputDetails.authorizeField"
          class="m-b-16"
        >
          <p class="f-base config-link-type__label">
            {{ inputDetails.authorizeField.label }}
          </p>

          <a-input
            :key="`${selectedField?.id}-${currentLink.type}-authorize-${currentLanguageCode}`"
            :default-value="getLocalizedValue(currentLink.authorizeValue, '')"
            :placeholder="inputDetails.authorizeField.placeholder"
            class="config-link-type__input"
            :class="{ 'config-link-type__input--error': isAuthorizeLinkError }"
            :maxlength="MAX_TEXT"
            @blur="handleAuthorizeInputUpdate"
            @press-enter="handleUrlEnterPress"
          />

          <span
            v-if="isAuthorizeLinkError"
            class="config-link-type__link-error"
          >
            Must be valid {{ currentLink.type }} value
          </span>
        </div>

        <p class="f-base config-link-type__label">
          {{ inputDetails.label }}
        </p>

        <a-input
          :key="`${selectedField?.id}-${currentLink.type}-non-authorize-${currentLanguageCode}`"
          :default-value="getLocalizedValue(currentLink.value, '')"
          :placeholder="inputDetails.placeholder"
          class="config-link-type__input"
          :class="{ 'config-link-type__input--error': isLinkError }"
          :maxlength="MAX_TEXT"
          @blur="handleInputUpdate"
          @press-enter="handleUrlEnterPress"
        />

        <span
          v-if="isLinkError"
          class="config-link-type__link-error"
        >
          Must be valid {{ currentLink.type }} value
        </span>

        <a-checkbox
          v-if="currentLink.type === LinkType.EXTERNAL"
          v-model:checked="currentLink.isNewTabOpen"
          class="m-t-16"
          @change="handleChange({})"
        >
          Open link in new tab
        </a-checkbox>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LinkType, Link } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { isValidLink } from "~~/helpers/link-validation";
import { MAX_TEXT } from "~~/constants/input-validation";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const widgetSettingsStore = useWidgetSettingsStore();
const {
  transformToLocalizedValue,
  getLocalizedValue,
  setLocalizedValue,
  currentLanguageCode,
} = useLocalizedValue();

withDefaults(
  defineProps<{
    shouldShowStyleSettings?: boolean;
    shouldShowTypeChanging?: boolean;
  }>(),
  {
    shouldShowStyleSettings: true,
    shouldShowTypeChanging: true,
  }
);

const currentLink = defineModel<Link>("modelValue", {
  default: () => ({
    type: null,
    value: transformToLocalizedValue("").localized,
    authorizeValue: "",
    isNewTabOpen: null,
  }),
});

defineEmits<{
  (e: "update:widget-option", event: IWidgetOptions): void;
  (e: "update:field-option", event: IWidgetOptions): void;
  (e: "show-states-settings"): void;
}>();

const isLinkError = ref<boolean>(false);
const isAuthorizeLinkError = ref<boolean>(false);

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const { selectedField } = storeToRefs(widgetSettingsStore);

const isOneLinkForAll = computed({
  get: () => !currentLink.value.hasAuthorizeValue,
  set: (value: boolean) => {
    currentLink.value.hasAuthorizeValue = !value;
  },
});

const inputDetails = computed<{
  label: string;
  placeholder: string;
  hasAuthorizeValueField?: boolean;
  authorizeField?: { label: string; placeholder: string };
} | null>(() => {
  switch (currentLink.value?.type) {
    case LinkType.EXTERNAL:
      return {
        label: currentLink.value.hasAuthorizeValue
          ? "Page url for unauthorized users"
          : "Page url",
        placeholder: "Paste URL here",
        hasAuthorizeValueField: true,
        authorizeField: {
          label: "Page url for authorized users",
          placeholder: "Paste URL here",
        },
      };

    case LinkType.MODAL:
      return {
        label: currentLink.value.hasAuthorizeValue
          ? "Modal url for unauthorized users"
          : "Modal url",
        placeholder: "Paste URL here",
        hasAuthorizeValueField: true,
        authorizeField: {
          label: "Modal url for authorized users",
          placeholder: "Paste URL here",
        },
      };

    case LinkType.EMAIL:
      return {
        label: "Email Address",
        placeholder: "feedback@company.com",
      };

    case LinkType.PHONE:
      return {
        label: "Phone number",
        placeholder: "+1-234-5678910",
      };

    default:
      return null;
  }
});

const updateLinkType = (type: LinkType | null): void => {
  let openInNewTab = currentLink.value?.isNewTabOpen;
  const value = type ? "" : null;
  const authorizeValue = type ? "" : null;
  const hasAuthorizeValue = false;

  if (type === LinkType.EXTERNAL) {
    openInNewTab = true;
  }

  isLinkError.value = false;
  isAuthorizeLinkError.value = false;

  handleChange({
    type,
    value: transformToLocalizedValue(value).localizedValue,
    authorizeValue: transformToLocalizedValue(authorizeValue).localizedValue,
    hasAuthorizeValue,
    isNewTabOpen: openInNewTab || null,
  });
};

const handleChange = (value: Partial<Link>) => {
  currentLink.value = {
    ...currentLink.value,
    ...value,
  };
};

const handleInputUpdate = (e: Event): void => {
  const target = e.target as HTMLInputElement;
  isLinkError.value = false;

  if (
    !isValidLink(target.value, currentLink.value?.type as LinkType) &&
    target.value
  ) {
    isLinkError.value = true;
    return;
  }

  handleChange({
    value: setLocalizedValue.value(currentLink.value.value, target.value),
  });
};

const handleAuthorizeInputUpdate = (e: Event): void => {
  const target = e.target as HTMLInputElement;
  isAuthorizeLinkError.value = false;

  if (
    !isValidLink(target.value, currentLink.value?.type as LinkType) &&
    target.value
  ) {
    isAuthorizeLinkError.value = true;
    return;
  }

  handleChange({
    authorizeValue: setLocalizedValue.value(
      currentLink.value.authorizeValue,
      target.value
    ),
  });
};

const handleUrlEnterPress = (event: Event): void => {
  const target = event.target as HTMLInputElement;
  target.blur();
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-link-type {
  @include controls-menu(33%);

  &__label {
    margin-bottom: 0;
  }

  &__input {
    height: $space-l;
    border-radius: 4px;

    &--error {
      border-color: $c-red;
    }
  }

  &__link-error {
    color: $c-red;
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }

  .ant-checkbox-wrapper {
    margin-top: $space-m;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  &__states-settings {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: $c-white;
    height: 100%;
    z-index: $z-index-max;
  }

  .common-dropdown-menu__option {
    max-width: $propertybar-width;
  }

  .ant-input-affix-wrapper {
    height: $space-l !important;
  }
}
</style>
