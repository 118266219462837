<template>
  <div
    :style="containerStyles"
    :class="`transaction-filter--${displayOrientation}`"
    class="transaction-filter"
  >
    <div
      :style="distanceStyles"
      class="transaction-filter__time"
    >
      <WidgetField
        :widget="widget"
        :field="objectFields.date_from"
        class="transaction-filter__time-item"
        no-overflow
      >
        <FormDateField
          :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[0] = el;
              }
            "
          :field="objectFields.date_from"
          :styling-fields="dateInputStyleFields"
          :states="states"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="objectFields.date_to"
        class="transaction-filter__time-item"
        no-overflow
      >
        <FormDateField
          :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[1] = el;
              }
            "
          :field="objectFields.date_to"
          :styling-fields="dateInputStyleFields"
          :states="states"
        />
      </WidgetField>
    </div>

    <WidgetField
      :widget="widget"
      :field="objectFields.transaction_types"
      class="transaction-filter__transaction"
    >
      <FormDropdownField
        :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[2] = el;
              }
            "
        :field="objectFields.transaction_types"
        :styling-fields="marketInputStyleFields"
        :states="states"
      />
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="objectFields.transaction_subtypes"
      class="transaction-filter__transaction"
    >
      <FormDropdownField
        :ref="
              (el: TriggerComponent) => {
                if (el) fieldsRefsList[3] = el;
              }
            "
        :field="objectFields.transaction_subtypes"
        :styling-fields="marketInputStyleFields"
        :states="states"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TriggerComponent } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { FormElementName } from "~~/models/widgets/form.model";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  fields: IWidgetField[];
  states: Record<string, State>;
}>();

const objectFields = computed<Record<string, IWidgetField>>(() => {
  return props.fields.reduce((result, curr) => {
    return {
      ...result,
      [curr.name]: curr,
    };
  }, {});
});

const filterFormFields = computed<IWidgetField[]>(() => {
  return [
    objectFields.value.date_from,
    objectFields.value.date_to,
    objectFields.value.transaction_types,
    objectFields.value.transaction_subtypes,
  ];
});

const { fieldsRefsList, getFieldStylings } = useFormInner(
  props.widget,
  filterFormFields
);

const distanceStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(props.field.options.distance),
  };
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(props.field.options),
    ...distanceStyles.value,
  };
});

const dateInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DATE);
});

const marketInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DROPDOWN);
});

const displayOrientation = computed<string>(() => {
  return props.field.options.displayMode;
});
</script>

<style lang="scss">
.transaction-filter {
  @include flex(flex-end, flex-start, 24px);

  &__time {
    @include flex(flex-end, flex-start, 24px);
    flex: 1;
  }

  &__transaction {
    flex: 1;
  }

  &__time-item {
    flex: 1;
  }

  &--vertical {
    @include flex-column(flex-start, flex-start, 24px);

    .transaction-filter__time {
      width: 100%;
    }

    .transaction-filter__transaction {
      width: 100%;
    }
  }
}
</style>
