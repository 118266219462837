<template>
  <div
    v-if="hasDesignPermissions"
    class="config-display-orientation p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container"
  >
    <p :class="{ 'sub-heading': isBold }">{{ label }}</p>
    <div class="config-display-orientation__options">
      <div
        v-for="option in options"
        :key="option.value"
        class="config-display-orientation__option-wrapper"
        :class="{
          'config-display-orientation__option-wrapper--active':
            modelValue === option.value,
        }"
        @click="handleUpdate(option.value)"
      >
        <CommonIcon
          :name="option.icon"
          class="config-display-orientation__icon"
        />

        {{ option.value }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

const options = [
  { value: DisplayOrientation.VERTICAL, icon: "custom:orientation-vertical" },
  {
    value: DisplayOrientation.HORIZONTAL,
    icon: "custom:orientation-horizontal",
  },
];

withDefaults(
  defineProps<{
    modelValue: DisplayOrientation;
    label?: string;
    isBold?: boolean;
  }>(),
  {
    label: "Layout",
    isBold: true,
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: DisplayOrientation): void;
}>();

const handleUpdate = (value: DisplayOrientation): void => {
  emit("update:modelValue", value);
};

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);
</script>

<style lang="scss">
.config-display-orientation {
  &__container {
    @include flex(center, space-around, $space-s);
  }

  &__options {
    margin-top: $space-m;
    display: flex;
    gap: $space-s;
  }

  &__option-wrapper {
    width: 50%;
    cursor: pointer;
    padding: 12px;
    border: 2px solid $c-grey-15;
    border-radius: 6px;
    text-align: center;
    text-transform: capitalize;

    &--active {
      border-color: $c-primary-base;
    }
  }

  &__icon {
    width: 100%;
    height: 48px;
    background: $c-light-blue;
    padding: 0 22px;
    border-radius: 4px;
    margin-bottom: $space-s;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: $c-primary-base-hover;
    }
  }
}
</style>
