import {
  getDefaultFillValue,
  getDefaultBorderValue,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultTheme,
  getDefaultFontFamily,
  getDefaultDecoration,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

const getStateValue = (): IWidgetOptions => {
  return {
    fill: getDefaultFillValue(),
    border: getDefaultBorderValue(),
    shadow: getDefaultShadow(),
    text: {
      color: getDefaultFillValue("#1677FF"),
      decoration: getDefaultDecoration(),
    },
    fileIcon: {
      textColor: getDefaultFillValue("#69B1FF"),
    },
    deleteIcon: {
      textColor: getDefaultFillValue("#69B1FF"),
    },
  };
};

export const UPLOAD_ITEMS_INITIAL = () => ({
  title: "Upload items style",
  options: {
    _active: true,
    fill: getDefaultFillValue(),
    border: getDefaultBorderValue(),
    shadow: getDefaultShadow(),
    cornerRadius: getDefaultCornerRadiusValue(),
    padding: getDefaultSpacing(),
    distance: 8,
    text: {
      color: getDefaultFillValue("#1677FF"),
      theme: getDefaultTheme(13),
      fontFamily: getDefaultFontFamily(),
      decoration: getDefaultDecoration(),
    },
    fileIcon: {
      iconSize: 16,
      textColor: getDefaultFillValue("#000000", 45),
      gap: 8,
      value: "ant-design:paper-clip-outlined",
    },
    deleteIcon: {
      iconSize: 16,
      textColor: getDefaultFillValue("#000000", 45),
      gap: 8,
      value: "ant-design:delete-outlined",
    },
    states: {
      [State.HOVER]: getStateValue(),
      [State.ACTIVE]: getStateValue(),
    },
  },
});
