<template>
  <!-- We need id here to take image of element later for dragging -->
  <div
    v-if="isLayoutAvailable"
    :id="`layout-column-${props.columns}`"
    v-draggable="{
      payload: { columns: props.columns },
      disabled: isDisabled,
      imageSelectorId: `layout-column-${props.columns}`,
      containerId: DndContainerId.GRID,
      onDragStart: handleDragStart,
    }"
    :class="{
      'disabled pointer-events-disabled': props.isDisabled,
    }"
    class="layout-option pointer"
  >
    <CommonIcon
      name="custom:dnd-marker"
      class="layout-option__icon"
    />

    <div class="layout-option__content">
      <div
        v-for="column in props.columns"
        :key="column"
        class="layout-option__inner"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGridConfig } from "~~/store/grid";
import { DndContainerId } from "~~/models/common/dropdown.model";

import { useLayoutOption } from "../composables/grid/useLayoutOptions";

const store = useGridConfig();

const props = defineProps<{
  columns: number;
  isDisabled?: boolean;
}>();

const { isLayoutAvailable } = useLayoutOption(props.columns);

const handleDragStart = (): void => store.setSelectedCell(null);
</script>

<style lang="scss">
.layout-option {
  position: relative;
  padding: 12px;
  width: 192px;
  background-color: $c-light-blue;
  border-radius: 4px;
  @include flex(center);

  &__icon {
    position: absolute;
    top: 50%;
    left: 3.5px;
    color: $c-primary-base;
    transform: translateY(-50%);
    font-size: 8px;
  }

  &__content {
    width: 184px;
    @include flex(center, center, 12px);
  }

  &__inner {
    height: 40px;
    flex-basis: 100%;
    border-radius: 4px;
    background-color: $c-layout-inner-bg-blue;
  }
}
</style>
