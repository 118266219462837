<template>
  <div
    :style="itemStyles"
    class="sportsbook-bet-item"
    :class="{
      'sportsbook-bet-item--is-live': isUp || isDown,
      'sportsbook-bet-item--is-down': isDown,
      'sportsbook-bet-item--show-both-arrows': shouldShowBothArrows,
      'sportsbook-bet-item--show-both-arrows-live': isUp || isDown,
      'sportsbook-bet-item--show-both-arrows-down': isDown,
    }"
  >
    <div class="text-truncate">
      <span :style="itemNameStyles">
        {{ itemValue.name }}
      </span>
    </div>

    <span
      :style="itemCoefStyles"
      class="text-truncate p-l-8"
    >
      {{ itemValue.coef }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getColorFromHex,
} from "~~/assets/utils/widget-settings";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  itemValue: { name: string; coef: string };
  betItemField: IWidgetField;
  stateOptions: IWidgetOptions;
  isUp?: boolean;
  isDown?: boolean;
  showBothArrowsStates?: Array<State>;
  state?: State;
}>();

const itemStyles = computed<ElementStyle>(() => {
  let styles = {
    fontFamily: props.betItemField.options.fontFamily,
    ...getCommonStyles({
      ...props.betItemField.options,
      ...props.stateOptions,
    }),
  };

  if (props.showBothArrowsStates) {
    styles = {
      ...styles,
      ...getStylesAsVars({
        upColor: getColorFromHex(props.stateOptions.arrow?.up?.color) || "",
        downColor: getColorFromHex(props.stateOptions.arrow?.down?.color) || "",
      }),
    };

    return styles;
  }

  styles = {
    ...styles,
    ...getStylesAsVars({
      ...getColorStyle({
        ...props.stateOptions.arrow?.color,
      }),
    }),
  };

  return styles;
});

const itemNameStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.stateOptions.name.color),
    ...getFontSizeStyle(props.betItemField.options.name.theme),
    ...getDecorationValue(props.stateOptions.name.decoration),
  };
});

const itemCoefStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(props.stateOptions.coef.color),
    ...getFontSizeStyle(props.betItemField.options.coef.theme),
    ...getDecorationValue(props.stateOptions.coef.decoration),
  };
});

const shouldShowBothArrows = computed<boolean>(() => {
  if (!props.showBothArrowsStates || !props.state) return false;

  return !!props.showBothArrowsStates.includes(props.state);
});
</script>

<style lang="scss">
.sportsbook-bet-item {
  @include flex(center, space-between, 0);

  & > div {
    @include flex(flex-start, flex-start, 0);
    display: block;

    /* Fix clipped issue for font style italic */
    padding-right: 3px;
  }

  &--is-live {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      top: -7px;
      width: 27px;
      height: 15px;
      background-color: var(--color);
      z-index: 2;
      transform: rotate(45deg);
    }
  }

  &--is-down {
    &::after {
      transform: rotate(-45deg);
      top: initial;
      bottom: -7px;
    }
  }

  &--show-both-arrows {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      width: 27px;
      height: 15px;
      z-index: 2;
    }

    &::after {
      background-color: var(--upColor);
      transform: rotate(45deg);
      top: -7px;
    }

    &::before {
      background-color: var(--downColor);
      transform: rotate(-45deg);
      bottom: -7px;
    }
  }

  &--show-both-arrows-live {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      top: -7px;
      width: 27px;
      height: 15px;
      background-color: var(--upColor);
      z-index: 2;
      transform: rotate(45deg);
    }
  }

  &--show-both-arrows-down {
    position: relative;
    overflow: hidden;

    &::after {
      transform: rotate(-45deg);
      top: initial;
      bottom: -7px;
      background-color: var(--downColor);
    }
  }
}
</style>
