// import { useI18n } from "vue-i18n";
import { useWidgetsStore } from "~~/store/widgets";
import {
  Alignment,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

import { useCreateFields } from "../../common/useCreateFields";
import { STATUS_MESSAGE_INITIAL } from "../../provider-form-fenix/useProviderFormFenixInitFieldsOptions";

const LIST_FILTER_FIELDS = [
  "form",
  "date_from",
  "date_to",
  "transaction_types",
  "transaction_subtypes",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "calendar_styling",
  "datepicker_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

const pageTypeDataToState: Record<string, State> = {
  transaction: State.TRANSACTION_HISTORY,
  betting: State.BETTING_HISTORY,
  pending: State.PENDING_WITHDRAWALS,
};

export const useInit = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();
  const { getLocalizedValue } = useLocalizedValue();

  const initialPageState =
    getLocalizedValue.value(widget.options.bindingParams?.pageType?.value) ||
    "transaction";

  const DEFAULT_STATES: Record<string, State> = {
    page_type: pageTypeDataToState[initialPageState],
    table_checkboxes_settings: State.SELECTED_DEFAULT,
    action_button: State.DEFAULT,
    items: State.ACTIVE_EVENTS,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.CALENDAR]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
    [FormStyleFieldName.RADIO_GROUP]: State.SELECTED_DEFAULT,
    [FormStyleFieldName.CHECKBOX]: State.SELECTED_DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget);

  /*
    Initialize widget with initial options
  */

  const widgetOptions = initWidgetOptions(["fill"], {
    fillImageColor: getDefaultFillImage(),
    states: {
      [State.TRANSACTION_HISTORY]: { _active: true },
      [State.BETTING_HISTORY]: { _active: false },
      [State.PENDING_WITHDRAWALS]: { _active: false },
    },
  });

  const init = (): void => {
    initFields();

    // TODO - make translations
    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      exclude: ["margins", "link"],
      values: {
        text: {
          [State.TRANSACTION_HISTORY]: "Transaction History",
          [State.BETTING_HISTORY]: "Betting History",
          [State.PENDING_WITHDRAWALS]: "Pending withdrawals",
        },
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({
          bottom: 16,
        }),
      },
    });

    addFieldInitialOptions("filter", {
      data: DESIGN_INITIAL(),
      values: {
        spacing: {
          margin: getDefaultSpacing({
            bottom: 16,
          }),
        },
      },
      additionalData: {
        options: {
          fillImageColor: getDefaultFillImage(),
        },
      },
    });

    addFieldInitialOptions(
      "filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(20),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({
            bottom: 12,
          }),
        },
      },
      { value: "Filter" }
    );

    addFieldInitialOptions(
      "pending_description",
      {
        data: {
          options: {
            ...TITLE_INITIAL().options,
            ...DESIGN_INITIAL().options,
          },
        },
        exclude: ["link", "margins", "spacing"],
        values: {
          color: getDefaultFillValue("#000000", 65),
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({ bottom: 12 }),
        },
      },
      { value: "Pending withdrawal cancel info" }
    );

    addFieldInitialOptions(
      "pending_error_text",
      {
        data: STATUS_MESSAGE_INITIAL(
          "#FFFFFF",
          "#FF4D4F",
          "ant-design:close-circle-filled"
        ),
        additionalData: {
          options: {
            margins: getDefaultSpacing({
              top: 12,
            }),
          },
        },
      },
      { value: "Error Message" }
    );

    addFieldInitialOptions("form", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(),
        },
      },
      additionalData: {
        options: {
          distance: 24,
          displayMode: 2,
        },
      },
    });

    addFieldInitialOptions("date_filter", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          distance: 12,
        },
      },
    });

    addFieldInitialOptions("transactions_filter", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(),
          distance: 12,
          itemsType: "dropdown",
        },
      },
    });

    addFieldInitialOptions(
      "date_filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({
            bottom: 4,
          }),
        },
      },
      { value: "By date" }
    );

    addFieldInitialOptions(
      "transactions_filter_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "margins"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({
            bottom: 4,
          }),
        },
      },
      { value: "By transactions" }
    );

    addFieldInitialOptions("table", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          mode: "table",
          displayMode: {
            distanceBetweenItems: 16,
            distanceBetweenCards: 1,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#000000", 5),
      },
    });

    addFieldInitialOptions("table_header_settings", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: TITLE_INITIAL().options,
      },
      exclude: ["padding"],
      values: {
        fill: getDefaultFillValue("#ffffff"),
        theme: getDefaultTheme(14),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions("table_row_settings", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
    });

    addFieldInitialOptions(
      "table_checkboxes_settings",
      {
        data: CHECKBOX_STYLE_INITIAL(),
      },
      {
        value: "Default",
      }
    );

    addFieldInitialOptions(
      "no_events_title",
      {
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            ...TITLE_INITIAL().options,
          },
        },
        exclude: ["link", "spacing"],
        values: {
          theme: getDefaultTheme(),
          color: getDefaultFillValue("#000000", 65),
          alignment: Alignment.LEFT,
        },
        additionalData: {
          options: {
            iconSettings: {
              _active: false,
              default_value: "ant-design:ant-design-outlined",
              textColor: getDefaultFillValue("#000000", 65),
              iconSize: 16,
            },
          },
        },
      },
      { value: "There is no active transaction" }
    );

    addFieldInitialOptions(
      "action_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          margins: getDefaultSpacing({ top: 16 }),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 16,
            right: 16,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.LEFT,
          },
          iconSettings: {
            default_value: "ant-design:close-outlined",
            textColor: getDefaultFillValue("#FFFFFF"),
            iconSize: 20,
            position: Alignment.LEFT,
            gap: 8,
            _active: true,
          },
        },
      },
      { value: "Cancel button" }
    );

    addFieldInitialOptions("table_data", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(ResizingType.FIXED, "350"),
        },
      },
    });

    addFieldInitialOptions("table_parameters", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          width: getDefaultSizeValue(ResizingType.FIXED, "405"),
        },
      },
    });

    addFieldInitialOptions("table_date", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("table_reference", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
      },
      additionalData: {
        options: {
          icon: {
            value: "ant-design:copy-outlined",
            size: 12,
            color: getDefaultFillValue("#000000"),
          },
        },
      },
    });

    addFieldInitialOptions("table_transaction_type", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("table_status", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("table_amount", {
      data: {
        options: {
          label: {
            ...TITLE_INITIAL().options,
            theme: getDefaultTheme(13),
          },
          value: {
            ...TITLE_INITIAL().options,
            theme: getDefaultTheme(13),
          },
          display: {
            alignment: Alignment.LEFT,
            distance: 8,
          },
        },
      },
    });

    addFieldInitialOptions("table_description", {
      data: TITLE_INITIAL(),
      values: {
        theme: getDefaultTheme(13),
      },
    });

    widgetsStore.updateWidgetOptions(widget, widgetOptions);
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    DEFAULT_STATES,

    init,
    filterFields,
  };
};
