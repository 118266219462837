<script setup lang="ts">
import type { IconifyIcon } from "@iconify/vue";

const props = defineProps<{
  name: IconifyIcon | string | null;
  spin?: boolean;
  size?: number;
}>();

const icon = computed(() => {
  return props.name || null; // TODO: implement default icon
});

const iconSize = computed(() => {
  if (props.size) {
    return `${props.size}px`;
  }

  return `1em`;
});
</script>

<template>
  <span
    v-if="icon"
    class="common-icon"
    :class="{
      'common-icon__spin': spin,
    }"
  >
    <IconifyIcon
      :data-name="name"
      :icon="icon"
    />
  </span>
</template>

<style lang="scss">
.common-icon {
  display: flex;
  line-height: 1;
  font-size: v-bind(iconSize);

  &__spin {
    @include animation-spin;
  }
}
</style>
