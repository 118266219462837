<template>
  <div
    :id="`widgetId_${widget.id}`"
    ref="widgetElement"
    class="base-widget position-relative"
    :class="{
      'base-widget--active': isWidgetHovered || isWidgetSelected || isSelected,
      'base-widget--selected': isWidgetSelected || isSelected,
      'base-widget--disabled': disabled,
      'base-widget--has-tag': widget.options._tag,
    }"
    @mouseenter="handleHover"
    @mouseleave="handleLeave"
  >
    <template v-if="activeSideMenuTab !== SideMenuTab.PAGES">
      <WidgetLabel
        v-if="isWidgetHovered || isWidgetSelected || isSelected"
        :label="widgetLabel"
        class="base-widget__label"
        :show-icon="isWidgetHovered || isWidgetSelected || isSelected"
      />
    </template>

    <div
      :style="widgetContentStyles"
      class="base-widget__content-container"
    >
      <slot name="root" />

      <div
        :style="widgetStyle"
        class="background-image position-relative base-widget__content"
      >
        <SpacingHighlight
          :is-target-selected="isWidgetSelected || isSelected"
          :is-target-disabled="disabled"
          :padding="widgetSpacing.padding || emptySpacingValues"
          :margin="widgetSpacing.margin || emptySpacingValues"
        />
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetOptions,
  IWidgetWithFields,
  WidgetDefaultSettingsDropdownItem,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IVerticalSpacingInput,
  ISpacingInput,
  SpacingKeyName,
  State,
  Alignment,
  WidgetDimension,
  MinMaxDimension,
} from "~~/models/widgets/widget-controls.model";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";
import { ICell, CellSizeType, Sizing } from "~~/models/grid.interface";
import {
  getBackgroundFillStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
  getDimensionValue,
} from "~~/assets/utils/widget-settings";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useUiStore } from "~~/store/ui";
import { ElementStyle } from "~~/models/common";
import { getFormWidth } from "~~/assets/utils/widget/form";
import { Dimension } from "~~/models/grid.interface";

const widgetSettingsStore = useWidgetSettingsStore();
const uiStore = useUiStore();

const {
  selectedWidget,
  selectedField,
  hoveredField,
  hoveredWidget,
  selectedDropdownItem,
} = storeToRefs(widgetSettingsStore);

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
  disabled?: boolean;
  isSelected?: boolean;
  ignoreStyleStates?: boolean;
  currentState?: State;
  nonStateOptions?: string[];
}>();

const { activeSideMenuTab } = storeToRefs(uiStore);
const widgetElement = ref<HTMLElement | null>(null);

const widgetLabel = computed<string>(() => {
  if (props.widget.options?._tag) {
    return `${props.widget.name} / ${props.widget.options?._tag}`;
  }

  return props.widget.name;
});

const isWidgetHovered = computed<boolean>(() => {
  if (widgetSettingsStore.tagWidgets.includes(props.widget.id)) {
    return true;
  }

  if (props.disabled) {
    return false;
  }

  if (!hoveredWidget.value || hoveredWidget.value.id !== props.widget.id) {
    return false;
  }

  if (hoveredField.value) {
    return false;
  }

  return true;
});

const isWidgetSelected = computed<boolean>(() => {
  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  if (selectedField.value) {
    return false;
  }

  if (
    selectedDropdownItem.value !==
    WidgetDefaultSettingsDropdownItem.WIDGET_SETTINGS
  ) {
    return false;
  }

  return true;
});

const widgetSpacing = computed<{
  margin: IVerticalSpacingInput;
  padding: ISpacingInput;
}>(() => {
  const widget = props.widget;

  if (widget.options.padding) {
    return {
      margin: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      padding: widget.options.padding,
    };
  }

  if (widget.options.margins) {
    return {
      margin: widget.options.margins,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    };
  }

  const spacing: { margin: IVerticalSpacingInput; padding: ISpacingInput } =
    widget.options.spacing;

  return spacing;
});

const activeStateOptions = computed<any>(() => {
  if (!props.currentState || props.currentState === State.DEFAULT) {
    return props.widget.options;
  }

  if (!props.nonStateOptions) {
    return props.widget.options.states[props.currentState];
  }

  let currNonStateOptions: IWidgetOptions = {};

  for (const optionName of props.nonStateOptions) {
    currNonStateOptions[optionName] = props.widget.options[optionName];
  }

  return {
    ...props.widget.options.states[props.currentState],
    ...currNonStateOptions,
  };
});

const contentWidth = computed(() => {
  return activeStateOptions.value?.contentWidth;
});

const containerAlignmentStyle = computed<ElementStyle>(() => {
  if (!contentWidth.value || contentWidth.value.type === Sizing.ADAPTIVE) {
    return {};
  }

  const styles = {
    ...getFormWidth(contentWidth.value),
  };

  if (contentWidth.value.alignment === Alignment.LEFT) {
    return {
      ...styles,
      "margin-right": "auto",
    };
  }

  if (contentWidth.value.alignment === Alignment.RIGHT) {
    return {
      ...styles,
      "margin-left": "auto",
    };
  }

  return {
    ...styles,
    "margin-left": "auto",
    "margin-right": "auto",
  };
});

const getWidthStyles = (width: WidgetDimension): ElementStyle => {
  if (width.type === CellSizeType.HUG) {
    return {
      width: "initial",
      display: "inline-block",
    };
  }

  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.value?.type === Dimension.PERCENT) {
    return {
      width: "100%",
    };
  }

  if (width.value?.type === Dimension.CALC) {
    return {
      width: `calc(${width.value.value})`,
    };
  }

  if (width.value?.type === Dimension.CUSTOM) {
    return {
      width: `${width.value.value}`,
    };
  }

  // return {
  //   width: "100%",
  // };

  return {
    width: `${width.value?.value}${width.value?.type}`,
  };
};

const getHeightStyles = (height: WidgetDimension): ElementStyle => {
  if (!height || height.type === CellSizeType.HUG) {
    return {
      height: "initial",
      display: "inline-block",
    };
  }

  if (height.type === CellSizeType.FILL) {
    return {
      alignSelf: "stretch",
    };
  }

  if (height.value?.type === Dimension.CALC) {
    return {
      height: `calc(${height.value.value})`,
    };
  }

  if (height.value?.type === Dimension.CUSTOM) {
    return {
      height: `${height.value.value}`,
    };
  }

  return {
    height: `${height.value?.value}${height.value?.type}`,
  };
};

const getMinMaxStyles = (
  minMaxOptions: MinMaxDimension,
  key: string
): ElementStyle => {
  if (!minMaxOptions || !minMaxOptions._active) {
    return {};
  }

  if (minMaxOptions.mode === "min" && minMaxOptions.min) {
    return {
      [`min${key}`]: getDimensionValue(minMaxOptions.min),
    };
  }

  if (minMaxOptions.mode === "max" && minMaxOptions.max) {
    return {
      [`max${key}`]: getDimensionValue(minMaxOptions.max),
    };
  }

  if (
    minMaxOptions.mode === "min_max" &&
    minMaxOptions.max &&
    minMaxOptions.min
  ) {
    return {
      [`min${key}`]: getDimensionValue(minMaxOptions.min),
      [`max${key}`]: getDimensionValue(minMaxOptions.max),
    };
  }

  return {};
};

const getMinMaxWidthStyles = (minMaxOptions: MinMaxDimension): ElementStyle => {
  return getMinMaxStyles(minMaxOptions, "Width");
};

const getMinMaxHeightStyles = (
  minMaxOptions: MinMaxDimension
): ElementStyle => {
  return getMinMaxStyles(minMaxOptions, "Height");
};

const sizeStyles = computed<ElementStyle>(() => {
  const size = props.widget.options.size;

  if (!size) {
    return {};
  }

  const widthStyles = getWidthStyles(size.width);
  const heightStyles = getHeightStyles(size.height);

  return {
    ...widthStyles,
    ...heightStyles,
    ...getMinMaxWidthStyles(size.width.minMax),
    ...getMinMaxHeightStyles(size.height.minMax),
  };
});

const baseWidgetStyle = computed<ElementStyle>(() => {
  const baseSettings = {
    display: "flex",
    flexDirection: "column",
    justifyContent: props.widget.options.baseDisplaySettings?.alignment,
  };

  return baseSettings;
});

const widgetHeightSizeStyles = computed<ElementStyle>(() => {
  const size = props.widget.options.size?.height as WidgetDimension;

  if (!size) {
    return {};
  }

  if (size.type !== CellSizeType.FIXED) {
    return {};
  }

  return {
    overflow: "hidden",
  };
});

const widgetStyle = computed<ElementStyle>(() => {
  return {
    ...containerAlignmentStyle.value,
    ...widgetHeightSizeStyles.value,
    ...baseWidgetStyle.value,
  };
});

const fillStyle = computed<ElementStyle>(() => {
  if (activeStateOptions.value.fillImageColor) {
    return getBackgroundFillStyle(activeStateOptions.value.fillImageColor);
  }

  return getBackgroundFillStyle(activeStateOptions.value.fill);
});

const widgetContentStyles = computed<ElementStyle>(() => {
  const border = getBorderStyle(
    props.currentState &&
      props.currentState !== State.DEFAULT &&
      !props.ignoreStyleStates
      ? props.widget.options.states[props.currentState]?.border
      : props.widget.options?.border
  );

  const shadow = getBoxShadowStyle(
    props.currentState &&
      props.currentState !== State.DEFAULT &&
      !props.ignoreStyleStates
      ? props.widget.options.states[props.currentState]?.shadow
      : props.widget.options?.shadow
  );
  const cornerRadius = getCornerRadiusStyle(props.widget.options.cornerRadius);
  const margin = getSpacing(widgetSpacing.value.margin, SpacingKeyName.MARGIN);
  const padding = getSpacing(
    widgetSpacing.value.padding,
    SpacingKeyName.PADDING
  );

  return {
    ...sizeStyles.value,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...fillStyle.value,
    ...margin,
    ...padding,
  };
});

const handleHover = () => {
  if (props.disabled) {
    return;
  }

  widgetSettingsStore.setHoveredWidget(props.widget);
};

const handleLeave = () => {
  if (props.disabled) {
    return;
  }

  widgetSettingsStore.setHoveredWidget(null);
};

const emptySpacingValues = computed(() => {
  return { top: 0, right: 0, bottom: 0, left: 0 };
});

const getWidgetHeight = (): number => {
  return widgetElement.value?.scrollHeight || 0;
};

const getWidgetWidth = (): number => {
  return widgetElement.value?.scrollWidth || 0;
};

provide("getWidgetHeight", getWidgetHeight);
provide("getWidgetWidth", getWidgetWidth);
</script>

<style lang="scss">
$base-widget: "base-widget";

.#{$base-widget} {
  // @include flex-column(flex-start, flex-start, 0);
  @include flex(flex-start, flex-start, 0);
  border: 1px solid transparent;
  height: 100%;

  /* 
    Removes bottom space on overflow: hidden 
  */
  vertical-align: top;

  &__content {
    height: 100%;
    overflow: auto;
  }

  &__label {
    display: flex;
    z-index: 2;
  }

  &--active {
    border-color: rgba($c-primary-base, 1);
  }

  &--active.#{$base-widget}--has-tag {
    border-color: rgba($c-green, 1);
  }

  &--has-tag {
    .#{$base-widget}__label {
      background-color: rgba($c-green, 1);
    }
  }

  &--selected {
    border-width: 2px;
  }

  &--disabled {
    pointer-events: none;
  }
}
</style>
