<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-states>
          <ControlsList
            :widget="widget"
            :controls="widgeStatesControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #image>
          <ControlsList
            :widget="widget"
            :controls="imageControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template
          v-if="fields.description_1.field"
          #description_1
        >
          <div>
            <ConfigPaddingInputs
              :model-value="fields.description_1.field.options.padding"
              is-bold
              @update:model-value="
                updateFieldOption('padding', $event, fields.description_1.field)
              "
            />

            <ConfigToggle
              size="small"
              :model-value="fields.description_1.field.options.withTranslations"
              :label="'With Translations'"
              class="p-l-16 p-t-16"
              :allow-disable="true"
              :show-options="false"
              is-field-item-toggle
              @update:model-value="
                updateFieldOption(
                  'withTranslations',
                  $event,
                  fields.description_1.field
                )
              "
            />
          </div>
        </template>

        <template #buttons_group-design>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupDesignControls"
          />
        </template>

        <template #buttons_group-content>
          <ControlsList
            :widget="widget"
            :controls="buttonsGroupContentControls"
          />
        </template>

        <template #button_1-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_1-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_1-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>

        <template #button_2-design>
          <ControlsList
            :widget="widget"
            :controls="buttonDesignControls"
          />
        </template>

        <template #button_2-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>

        <template #button_2-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useCardConfig } from "~~/composables/widgets/card/useCardWidgetConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  titleLinkState: State;
  widgetState: State;
  buttonOneState: State;
  buttonTwoState: State;
  widget: IWidgetWithFields;
}>();

const emit = defineEmits<{
  (e: "description-field-option-updated"): void;
  (e: "update-title-link-state", event: State): void;
  (e: "update-widget-state", event: State): void;
  (e: "update-button-state", event: State): void;
  (e: "reset-states"): void;
}>();

const store = useWidgetsStore();
const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const titleLinkState = toRef(() => props.titleLinkState);
const widgetState = toRef(() => props.widgetState);
const buttonState1 = toRef(() => props.buttonOneState);
const buttonState2 = toRef(() => props.buttonTwoState);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgeStatesControls,
  widgetContentControls,
  titleControls,
  titleLinkStatesControls,
  fields,
  imageControls,
  buttonsGroupDesignControls,
  buttonsGroupContentControls,
  buttonDesignControls,
  buttonStatesControls,
  buttonContentControls,
} = useCardConfig(
  ref(props.widget),
  titleLinkState,
  widgetState,
  buttonState1,
  buttonState2,
  emit
);

const { bindingParams } = useWidgetDynamicParams(
  {
    "useSeoTitle": {
      label: "Use SEO h1",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
    "useSeoDescription": {
      label: "Use SEO description",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const updateFieldOption = (
  optionName: string,
  value: any,
  field: IWidgetField
): void => {
  store.updateFieldOptionValue(value, optionName, field.id);
};

const resetStates = (): void => {
  emit("reset-states");
};

onBeforeUnmount(() => resetStates());
</script>
