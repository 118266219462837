export const DND_CONTAINER_ELEMENT_ID = 0;

export const DND_DIVIDER_ID = "dnd-divider";

export const RECALCULATE_TOP_POSITION_FREQUENCY = 200;

export const RECALCULATE_DIVIDER_FREQUENCY = 100;

export const PAYLOAD_DND_KEY = "payload_dnd";

export const DND_COPY_OPERATION_NAME = "copy";

export const PROMPT_OFFSET_INNER = 24;

export const PROMPT_OFFSET_OUTER = 56;
