import { IWidgetMigration } from "~~/migrations/runWidgetMigrations";
import {
  Alignment,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";

const WIDGET_VERSION = 5;

const generateProfileDropdownDisplaySettings = () => ({
  alignment: Alignment.LEFT,
  distanceBetweenItems: 8,
  layout: DisplayOrientation.HORIZONTAL,
});

const v5WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["ProfileDropdownWidget"].includes(widget.name)) {
    if (!widget?.options?.profileDropdownDisplay.distanceBetweenItems) {
      widget.options.profileDropdownDisplay =
        generateProfileDropdownDisplaySettings();
    }

    if (
      !widget?.options?.authStates[State.UNAUTHORIZED].profileDropdownDisplay
        .distanceBetweenItems
    ) {
      widget.options.authStates[State.UNAUTHORIZED].profileDropdownDisplay =
        generateProfileDropdownDisplaySettings();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v5WidgetMigration;
