import { CSSProperties } from "vue";

import { getDisplayGridStyles } from "~~/assets/utils/styles";
import { Display } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexAlign } from "~~/assets/utils/widget-settings";

import { CardsDisplayMode } from "../../PromoBetsFenixWidgetTypes";

export const useWrapperStyles = (widget: any) => {
  const displayMode = widget.options.displayMode;
  const gridSettings = widget.options.gridSettings;
  const carouselSettings = widget.options.carouselSettings;
  const carousel = widget.options.carousel;

  const gridStyles = (): CSSProperties => {
    return {
      ...getDisplayGridStyles(gridSettings, {
        gridAutoRows: "auto",
      }),
    };
  };

  const carouselStyles = (): CSSProperties => {
    const alignItems = getFlexAlign(carousel.alignment)["align-items"];
    return {
      display: Display.FLEX,
      alignItems,
      gap: getPxValueFromNumber(carouselSettings.gap),
    };
  };

  if (displayMode === CardsDisplayMode.Grid) {
    return gridStyles();
  } else if (displayMode === CardsDisplayMode.Carousel) {
    return carouselStyles();
  } else {
    return {};
  }
};
