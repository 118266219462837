import { CSSProperties } from "vue";

import {
  Align,
  Display,
  FlexDirection,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import {
  getColorFromHex,
  getDecorationValue,
  getFlexPosition,
  getFontFamilyStyle,
  getFontSizeStyle,
  getResizingWidthStyles,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetHelpers";
import { useDesignInitialStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/composables/styles/useDesignInitialStyles";

export const useButtonStyles = (buttonOptions: any = {}) => {
  const buttonTextSettings = buttonOptions.buttonTextSettings || {};
  const iconSettings = buttonOptions.iconSettings || {};
  const _active = buttonOptions._active;

  const buttonContainerStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const styles = {
      display: Display.FLEX,
      ...getResizingWidthStyles(buttonOptions.buttonDisplaySettings?.resizing),
      justifyContent: getFlexPosition(
        buttonOptions.buttonDisplaySettings?.alignment
      ),
    };
    return clearObject(styles);
  };

  const buttonStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const styles = {
      ...useDesignInitialStyles(buttonOptions),

      display: Display.FLEX,
      alignItems: Align.MIDDLE,
      flexDirection: buttonOptions.iconSettings?.position
        ? buttonOptions.iconSettings?.position === TextPosition.RIGHT
          ? FlexDirection.ROW
          : FlexDirection.ROW_REVERSE
        : undefined,
      gap: buttonOptions.iconSettings?.gap
        ? getPxValueFromNumber(buttonOptions.iconSettings?.gap)
        : undefined,
    };
    return clearObject(styles);
  };

  const buttonTextStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const styles = {
      color: buttonTextSettings.textColor
        ? getColorFromHex(buttonTextSettings.textColor)
        : undefined,

      ...(buttonTextSettings.theme
        ? getFontSizeStyle(buttonTextSettings.theme)
        : {}),
      ...(buttonTextSettings.fontFamily
        ? getFontFamilyStyle(buttonTextSettings.fontFamily)
        : {}),
      ...(buttonTextSettings.decoration
        ? getDecorationValue(buttonTextSettings.decoration)
        : {}),
    };
    return clearObject(styles);
  };

  const buttonIconStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const styles = {
      color: iconSettings.textColor
        ? getColorFromHex(iconSettings.textColor)
        : undefined,
      ...(iconSettings.iconSize ? getFontSizeStyle(iconSettings.iconSize) : {}),
    };
    return clearObject(styles);
  };
  return {
    buttonContainerStyles,
    buttonStyles,
    buttonTextStyles,
    buttonIconStyles,
  };
};
