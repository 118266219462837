<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #dropdown_container-design>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerDesignConfig"
          />
        </template>

        <template #dropdown_container-content>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerContentConfig"
          />
        </template>

        <template #dropdown_container-states>
          <ControlsList
            :widget="widget"
            :controls="dropdownContainerStatesConfig"
          />
        </template>

        <template #dropdown_menu>
          <ControlsList
            :widget="widget"
            :controls="dropdownMenuControls"
          />
        </template>

        <template #menu_items-design>
          <ControlsList
            :widget="widget"
            :controls="menuItemsDesignControls"
          />
        </template>

        <template #menu_items-content>
          <ControlsList
            :widget="widget"
            :controls="menuItemsContentControls"
          />
        </template>

        <template #menu_items-states>
          <ControlsList
            :widget="widget"
            :controls="menuItemsStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/languages-list/useLangsConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const { bindingParams } = useWidgetDynamicParams(
  {
    "pageType": {
      label: "Page type",
      description:
        "Possible values: default | casino. Empty value means default",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,

  dropdownContainerDesignConfig,
  dropdownContainerContentConfig,
  dropdownContainerStatesConfig,

  dropdownMenuControls,

  menuItemsDesignControls,
  menuItemsContentControls,
  menuItemsStatesControls,
} = useConfig(toRef(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
