import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  ColorPickerType,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { typography } from "~~/constants/configs/text-common/typography-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { stateConfig } from "~~/constants/configs/common/state-config";

import type { IFields } from "../useWidgetFields";

export const useSportEventFenixRacingConfig = (
  fields: Ref<IFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  /* 
    Event info
  */

  const racingLiveHeaderContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.distance",
            valueSource: fields.value.racing_event_info.field,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "live_header_back_button",
              label: "Back button",
              isActiveValueSource:
                fields.value.live_header_back_button.field.options._active,
            },
            {
              value: "racing_title",
              label: "Race distance and venue",
            },
            {
              value: "racing_description",
              label: "Group stage and conditions",
            },
          ],
        },
      },
    ];
  });

  /* 
      Racing title
    */

  const racingTitleControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.racing_title.field,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.racing_title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
      Racing description
    */

  const racingDescriptionControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.racing_description.field,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.racing_description.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
      Players info
    */

  const playersInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.mode",
            valueSource: selectedField.value,
            className: "group-control-element event-cards-radio",
            options: {
              label: "Cards items layout ",
              items: [
                { icon: "custom:players-info-column-1", value: "table" },
                { icon: "custom:players-info-column-2", value: "rows" },
                { icon: "custom:players-info-column-vertical", value: "wrap" },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenItems",
            valueSource: fields.value.racing_players_info.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenCards",
            valueSource: fields.value.racing_players_info.field,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header card",
              value: "racing_header_card",
              isActiveValueSource:
                fields.value.racing_header_card.field.options,
            },
            {
              label: "Player cards",
              value: "racing_player_cards",
            },
          ],
        },
      },
    ];
  });

  /* 
      Header card
    */

  const racingHeaderCardContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.racing_header_card.field,
      }),
    ];
  });

  /* 
      Player cards
    */

  const playerCardsContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "№",
              value: "racing_number",
            },
            {
              label: "Last 5 races",
              value: "racing_last_5_races",
            },
            {
              label: "Previous odds",
              value: "racing_prev_odds",
            },
            {
              label: "Odds",
              value: "racing_odds",
            },
          ],
        },
      },
    ];
  });

  const currentPlayerCardsStatePath = computed<string>(() => {
    if (states.value.racing_player_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.racing_player_cards}`;
  });

  const currentPlayerCardsOddsStatePath = computed<string>(() => {
    if (states.value.racing_player_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.not_runner`;
  });

  const getValueSource = (fieldName: string): IWidgetField => {
    if (states.value.racing_player_cards === State.DEFAULT) {
      return fields.value[fieldName].field;
    }

    return fields.value.racing_player_cards.field;
  };

  const getValuePath = (fieldName: string): string => {
    if (states.value.racing_player_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.racing_player_cards}.${fieldName}`;
  };

  const numberStateSource = computed<IWidgetField>(() => {
    return getValueSource("racing_number");
  });

  const numberValuePath = computed<string>(() => {
    return getValuePath("racing_number");
  });

  const nameStateSource = computed<IWidgetField>(() => {
    return getValueSource("racing_player_name");
  });

  const nameValuePath = computed<string>(() => {
    return getValuePath("racing_player_name");
  });

  const lastRacesStateSource = computed<IWidgetField>(() => {
    return getValueSource("racing_last_5_races");
  });

  const lastRacesValuePath = computed<string>(() => {
    return getValuePath("racing_last_5_races");
  });

  const prevOddsStateSource = computed<IWidgetField>(() => {
    return getValueSource("racing_prev_odds");
  });

  const prevOddsValuePath = computed<string>(() => {
    return getValuePath("racing_prev_odds");
  });

  const playerCardsStatesControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.racing_player_cards,
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "racing_player_cards", value });
        },
      }),
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentPlayerCardsStatePath.value}.fill`,
        valueSource: fields.value.racing_player_cards.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        section: generateDesignSectionName("№"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${numberValuePath.value}.color`,
            valueSource: numberStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Horse or greyhound"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${nameValuePath.value}.color`,
            valueSource: nameStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Last 5 races"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${lastRacesValuePath.value}.color`,
            valueSource: lastRacesStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Previous odds"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${prevOddsValuePath.value}.color`,
            valueSource: prevOddsStateSource.value,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Odds"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPlayerCardsOddsStatePath.value}.fill`,
            valueSource: fields.value.racing_odds.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
              type: ColorPickerType.BACKGROUND,
            },
          },
          {
            componentPath: "ConfigBorder",
            valuePath: `${currentPlayerCardsOddsStatePath.value}.border`,
            valueSource: fields.value.racing_odds.field,
            options: {
              placeholder: "None",
              label: "Border",
              isBold: true,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentPlayerCardsOddsStatePath.value}.color`,
            valueSource: fields.value.racing_odds.field,
            options: {
              placeholder: "None",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /* 
      Player info text
    */

  const playerNumberControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.racing_number.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.iconSize",
            valueSource: fields.value.racing_number.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const playerTextControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  const oddsDesignControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.racing_odds.field,
        except: ["ConfigSpacingInputs"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: `options.padding`,
        valueSource: fields.value.racing_odds.field,
        options: {
          placeholder: "None",
          label: "Padding",
        },
      },
    ];
  });

  const oddsContentControls = computed<ControlProp[]>(() => {
    return [
      typography(
        {
          source: fields.value.racing_odds.field,
        },
        "Text"
      ),
    ];
  });

  const currentOddsStatePath = computed<string>(() => {
    if (states.value.racing_odds === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.racing_odds}`;
  });

  const oddsStateControls = computed<ControlProp[]>(() => {
    return [
      stateConfig({
        source: states.value.racing_odds,
        statesList: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.up,
            value: State.UP,
          },
          {
            label: STATE_KEY_LABELS.down,
            value: State.DOWN,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
          {
            label: STATE_KEY_LABELS.not_runner,
            value: State.NOT_RUNNER,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "racing_odds", value });
        },
      }),
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentOddsStatePath.value}.fill`,
        valueSource: fields.value.racing_odds.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentOddsStatePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        section: generateContentSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentOddsStatePath.value}.color`,
            valueSource: fields.value.racing_odds.field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: true,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow style"),
        visible: [State.UP, State.DOWN, State.HOVER, State.ACTIVE].includes(
          states.value["racing_odds"] as State
        ),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentOddsStatePath.value}.arrow.up.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !==
              State.DOWN,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Up color",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentOddsStatePath.value}.arrow.down.color`,
            valueSource: selectedField.value,
            visible:
              (states.value[selectedField.value!.name] as string) !== State.UP,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Arrow Down color",
            },
          },
        ],
      },
    ];
  });

  return {
    racingLiveHeaderContentControls,
    racingTitleControls,
    racingDescriptionControls,
    playersInfoContentControls,
    racingHeaderCardContentControls,
    playerCardsContentControls,
    playerCardsStatesControls,
    playerNumberControls,
    playerTextControls,
    oddsDesignControls,
    oddsContentControls,
    oddsStateControls,
  };
};
