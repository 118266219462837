<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-ff widget-control-container"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label }}
    </p>
    <CommonSelect
      :model-value="currentOverflow"
      :options="overflowOptions"
      placeholder="Select overflow"
      class="m-t-4"
      @update:model-value="handleChange"
    />
  </div>
</template>

<script setup lang="ts">
import type { SelectProps } from "ant-design-vue";

import { usePermissions } from "~~/composables/permissions/usePermissions";
import { Overflow } from "~~/models/widgets/widget-controls.model";

const { label = "Overflow", ...props } = defineProps<{
  modelValue: Overflow; // Make it with Orientation
  label?: string;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", event: Overflow): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentOverflow = computed<Overflow>(() => {
  return props.modelValue;
});

const overflowOptions = ref<SelectProps["options"]>([
  { value: Overflow.INITIAL, label: "Initial" },
  { value: Overflow.AUTO, label: "Auto" },
  { value: Overflow.VISIBLE, label: "Visible" },
  { value: Overflow.HIDDEN, label: "Hidden" },
  { value: Overflow.SCROLL, label: "Scroll" },
  { value: Overflow.CLIP, label: "Clip" },
]);

const handleChange = (value: Overflow): void => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
.config-ff {
  &__select {
    width: 100%;

    .ant-select-selector {
      border-radius: 6px !important;
    }
  }
}
</style>
