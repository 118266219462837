<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container config-image-resizing"
  >
    <p
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label || "Image position" }}
    </p>

    <div class="config-image-resizing__content">
      <ConfigRadioTextElements
        class="p-l-0 p-b-0 p-r-0 border-bottom-none"
        label="Position"
        :items="[
          { value: ResizingType.FILL, label: 'Fill container' },
          { value: ResizingType.FIT, label: 'Fit container' },
        ]"
        :model-value="modelValue.type"
        @update:model-value="handleUpdate({ type: $event })"
      />

      <div
        v-if="modelValue.type === ResizingType.FIT"
        class="config-image-resizing__alignment"
      >
        <ConfigAlignmentFull
          :custom-align-values="ALIGN_VALUES"
          label=""
          :model-value="modelValue?.position"
          @update:model-value="handleUpdate({ position: $event })"
        >
          <template #icon>
            <CommonIcon name="ant-design:picture-filled" />
          </template>
        </ConfigAlignmentFull>
      </div>

      <div
        v-else-if="modelValue.type === ResizingType.FILL"
        class="config-image-resizing__fill"
      >
        <div class="config-image-resizing__inputs">
          <ConfigInput
            v-number
            :model-value="modelValue?.x"
            :stretch-input="true"
            :is-number="true"
            :input-props="{
              min: 0,
              max: 100,
              controls: false,
            }"
            class="config-range-slider__input"
            @update:model-value="handleUpdate({ x: $event })"
          >
            <template #prefix>
              <span> X </span>
            </template>
            <template #suffix>
              <span> % </span>
            </template>
          </ConfigInput>

          <ConfigInput
            v-number
            :model-value="modelValue?.y"
            :stretch-input="true"
            :is-number="true"
            :input-props="{
              min: 0,
              max: 100,
              controls: false,
            }"
            class="config-range-slider__input"
            @update:model-value="handleUpdate({ y: $event })"
          >
            <template #prefix>
              <span> Y </span>
            </template>
            <template #suffix>
              <span> % </span>
            </template>
          </ConfigInput>
        </div>

        <div class="config-image-resizing__alignment">
          <ConfigAlignmentFull
            :custom-align-values="PERCENTAGE_ALIGN_VALUES"
            label=""
            :model-value="percentageValue"
            @update:model-value="handlePercentageAlignmentUpdate"
          >
            <template #icon>
              <CommonIcon name="ant-design:picture-filled" />
            </template>
          </ConfigAlignmentFull>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  Alignment,
  ResizingType,
  VerticalPosition,
} from "~~/models/widgets/widget-controls.model";

type ModelValue = {
  type: string;
  x?: string;
  y?: string;
  position: string;
};

const props = defineProps<{
  modelValue: ModelValue;
  label?: string;
  isBold?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: Record<string, string | null | undefined>
  ): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const ALIGN_VALUES = [
  {
    title: "Top Left",
    value: `${VerticalPosition.TOP} ${Alignment.LEFT}`,
  },
  {
    title: "Top Center",
    value: `${VerticalPosition.TOP} ${Alignment.CENTER}`,
  },
  {
    title: "Top Right",
    value: `${VerticalPosition.TOP} ${Alignment.RIGHT}`,
  },
  {
    title: "Center Left",
    value: `${VerticalPosition.CENTER} ${Alignment.LEFT}`,
  },
  {
    title: "Center Center",
    value: `${VerticalPosition.CENTER} ${Alignment.CENTER}`,
  },
  {
    title: "Center Right",
    value: `${VerticalPosition.CENTER} ${Alignment.RIGHT}`,
  },
  {
    title: "Bottom Left",
    value: `${VerticalPosition.BOTTOM} ${Alignment.LEFT}`,
  },
  {
    title: "Bottom Center",
    value: `${VerticalPosition.BOTTOM} ${Alignment.CENTER}`,
  },
  {
    title: "Bottom Right",
    value: `${VerticalPosition.BOTTOM} ${Alignment.RIGHT}`,
  },
];

const PERCENTAGE_ALIGN_VALUES = [
  {
    title: "Top Left",
    value: `0 0`,
  },
  {
    title: "Top Center",
    value: `50 0`,
  },
  {
    title: "Top Right",
    value: `100 0`,
  },
  {
    title: "Center Left",
    value: `0 50`,
  },
  {
    title: "Center Center",
    value: `50 50`,
  },
  {
    title: "Center Right",
    value: `100 50`,
  },
  {
    title: "Bottom Left",
    value: `0 100`,
  },
  {
    title: "Bottom Center",
    value: `50 100`,
  },
  {
    title: "Bottom Right",
    value: `100 100`,
  },
];

const handlePercentageAlignmentUpdate = (value: string): void => {
  const [x, y] = value.split(" ");

  handleUpdate({ x, y });
};

const percentageValue = computed<string>(() => {
  return `${props.modelValue.x} ${props.modelValue.y}`;
});

const handleUpdate = (value: Record<string, string>): void => {
  if (value.value) {
    emit("update:modelValue", {
      x: null,
      y: null,
      ...value,
    });

    return;
  }

  emit("update:modelValue", {
    ...(props.modelValue || {}),
    value: null,
    ...value,
  });
};
</script>

<style lang="scss">
.config-image-resizing {
  &__content {
    @include flex-column(stretch, flex-start, 8px);
  }

  &__fill {
    @include flex(flex-start, flex-start, 8px);
  }

  &__inputs {
    @include flex-column(flex-start, flex-start, 8px);
    align-self: stretch;

    .ant-input-number-prefix {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border: 1px solid $c-grey-15;
      margin: 0;
      border-right: none;
      padding-left: 12px;
      color: $c-grey-45;
    }

    .ant-input-number-affix-wrapper {
      div.ant-input-number {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .ant-input-number-group-addon {
      border-radius: 2px;
    }
  }

  &__alignment,
  &__inputs {
    flex: 1;
  }

  &__alignment {
    .config-alignment-full {
      padding: 0;
      border-bottom: none;
    }

    .wconfig-common-radio__radio-group-container {
      padding: 6px 8px;
      border-radius: 2px;
    }

    .ant-radio-button-wrapper {
      padding: 0;
      background: transparent;
      height: 20px;
    }
  }

  .config-input {
    flex: 1;
  }

  .ant-input-number-affix-wrapper,
  .ant-input-number-group-wrapper,
  .ant-input-number-wrapper,
  .ant-input-group,
  .ant-input-number-input,
  .ant-input-number-input-wrap {
    height: 100%;
  }
}
</style>
