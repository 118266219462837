import { useWidgetsStore } from "~~/store/widgets";
import { isSimpleValue, prefillEmptyOptions } from "~~/assets/utils/widget";
import {
  Align,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultAlignValue,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { InnerFieldValue } from "~~/models/page.model";
import { FormFieldDetails } from "~~/models/widgets/form.model";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import { TITLE_INITIAL } from "~~/constants/configs/dropdown/initial-data";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

import { useWidgetCacheData } from "../../common/useWidgetCacheData";
import { useWidgetFields } from "../../useWidgetFields";

export const useCasinoGamesSearchWidgetInitData = (
  widget: IWidgetWithFields
) => {
  const widgetsStore = useWidgetsStore();
  const { widgetCacheData } = useWidgetCacheData(widget);
  const fields = useWidgetFields(widget);
  const { initWidgetOptions } = useCreateFields(widget);

  const INITIAL_STATES: Record<string, State> = {
    field_container: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...INITIAL_STATES,
  });

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(
        ...generateFormFields(formFieldDetails, widget, key, ["title"])
      );
    }
    widgetsStore.updateWidgetFields(widget, [...widget.fields, ...formFields]);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }
  };

  const initWidgetFieldsOptions = () => {
    const initialWidgetOptions = initWidgetOptions();

    const initialFormOptions = prefillEmptyOptions(
      fields.value.form.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            distance: 24,
            displayMode: DisplayOrientation.HORIZONTAL,
            alignment: getDefaultAlignValue([Align.START, Align.START]),
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialFieldContainerState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#1677FF"),
        }),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: getDefaultFillValue("#000000"),
        },
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
        placeholder: {
          color: getDefaultFillValue("#C7C7C7"),
        },
      };
    };

    const initialFieldContainerOptions = prefillEmptyOptions(
      fields.value.field_container.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["arrow"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          text: {
            _active: true,
            color: getDefaultFillValue("#494949"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: null,
            value: "Request example",
          },
          icon: {
            value: "ant-design:search-outlined",
            textColor: {
              color: "#000000",
              opacity: 100,
            },
            _active: true,
          },
          states: {
            [State.HOVER]: initialFieldContainerState(),
            [State.ACTIVE]: initialFieldContainerState(),
            [State.DISABLED]: initialFieldContainerState(),
          },
        },
        additionalData: {
          options: {
            placeholder: {
              _active: true,
              value: "Search",
              color: getDefaultFillValue("#C7C7C7"),
            },
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.field_container.field,
      initialFieldContainerOptions
    );
    widgetsStore.updateFieldOptions(
      fields.value.form.field,
      initialFormOptions
    );
    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  return {
    states,
    INITIAL_STATES,

    initFields,
    initWidgetFieldsOptions,
  };
};
