import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFlexAlignment,
  generateFontSize,
  generateMarginStyle,
  generateTextColor,
  generateWidth,
  generateGap,
} from "../helpers";
import {
  generateItemsGroupCssString,
  generateWidgetCssString,
  itemsGroupClassNameGenerator,
} from "../compiler/items-css-compiler";

// Generating styles here, since dropdown title has custom fields
export const generateTitleCssString =
  (title: IWidgetField, displayOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = title;
    const iconFontCssContent =
      generateTextColor(options.icon.textColor) +
      generateFontSize(options.icon.iconSize) +
      generateMarginStyle({ right: options.icon.gap });

    cssString += generateDefaultStylesWithStates(title, false);

    const isDisplayFill = displayOptions.resizing === ResizingType.FILL;

    const widthValue = isDisplayFill ? "100%" : "unset";
    const justifyContent = isDisplayFill ? displayOptions.alignment : "unset";
    const alignSelf = isDisplayFill
      ? "unset"
      : generateFlexAlignment(displayOptions.alignment);

    const titleContent =
      generateWidth(widthValue, null) +
      generateFlex({
        flex: "flex",
        align: "center",
        justify: justifyContent,
        alignSelf,
      });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: titleContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: options.text,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.arrow._cssClass,
      content: `${generateTextColor(
        options.arrow.textColor
      )}${generateMarginStyle({ left: 4 })}`,
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    for (const state in title.options.states) {
      const currentState = options.states[state];

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.arrow?._cssClass,
        content: currentState.arrow?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.icon?._cssClass,
        content: currentState.icon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: currentState.text,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

export const generateItemsCssString =
  (element: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const widthValue =
      widget.options.display.resizing === ResizingType.FILL ? "100%" : "unset";

    cssString += `.${element.options._cssClass} {${generateDefaultStyles(
      element.options
    )}${generateWidth(widthValue, null)}}`;
    cssString += `.${
      element.options._cssClass
    } .dropdown-element__container-override{ ${generateGap(
      element.value[0].options.spaceBetween
    )} align-items:${generateFlexAlignment(
      widget.options.display.alignment
    )};}`;

    cssString += `.${
      widget.options._cssClass
    } .dropdown-trigger-title-override{${
      widget.options.display.resizing === ResizingType.FILL
        ? `width:100%; text-align:${widget.options.display.alignment};`
        : ""
    }}`;

    return cssString;
  };

export const generateDropdownWidgetCssString = (
  widget: IWidgetOptions
): string => {
  const title = widget.content["title"];
  const dropdownMenu = widget.content["items"];

  widget.options._cssClass = generateClassName("DropdownWidget");
  widget.options._contentCssClass = generateClassName("DropdownWidget_content");
  title.options._cssClass = generateClassName("title");
  title.options.arrow._cssClass = generateClassName("titleArrow");
  title.options.icon._cssClass = generateClassName("titleIcon");
  dropdownMenu.options._cssClass = generateClassName("items");
  dropdownMenu.value[0].options.itemsIcon._cssClass =
    generateClassName("itemsIcon");
  dropdownMenu.value[0].options.itemsText._cssClass =
    generateClassName("itemsText");
  itemsGroupClassNameGenerator(dropdownMenu);

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateTitleCssString(title, widget.options.display),
    generateItemsCssString(dropdownMenu, widget),
    generateItemsGroupCssString(dropdownMenu.value[0], widget.options.display)
  )("");
};
