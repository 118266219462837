<template>
  <FormInputBase
    :field="field"
    :styling-fields="stylingFields"
    :is-error-visible="isErrorVisible"
    :is-tooltip-visible="isTooltipVisible"
    :value="value"
  >
    <div
      class="form-phone-field"
      :style="inputStyles"
    >
      <FormDropdownField
        ref="formDropdownField"
        :widget="widget"
        :field="field"
        :styling-fields="stylingFields"
        :value="value"
        :states="states"
        class="form-phone-field__dropdown"
      >
        <CommonIcon name="custom:brazil-flag" /> +55
      </FormDropdownField>

      <FormTextInputField
        :field="field"
        :styling-fields="stylingFields"
        :value="value"
        :states="states"
        class="form-phone-field__input"
      />
    </div>
  </FormInputBase>
</template>

<script setup lang="ts">
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useFormInputStyling } from "~~/composables/widgets/form/useFormInputStyling";

import FormDropdownField from "./FormDropdownField.vue";

const props = defineProps<{
  widget?: IWidgetWithFields;
  field: IWidgetField;
  stylingFields: IWidgetField[];
  value?: string;
  states: Record<string, string>;
}>();

const formDropdownField = ref<InstanceType<typeof FormDropdownField> | null>(
  null
);

const state = computed<string>(() => {
  return props.states[FormStyleFieldName.FIELD];
});

const {
  isErrorVisible,
  isTooltipVisible,
  inputStyles,
  triggerFieldExample: triggerFieldExampleLocal,
  hideFieldExample: hideFieldExampleLocal,
} = useFormInputStyling(
  ref<IWidgetField[]>(props.stylingFields),
  props.widget,
  state
);

const triggerFieldExample = (fieldStylingName: FormStyleFieldName): void => {
  if (fieldStylingName.startsWith("dropdown")) {
    formDropdownField.value?.triggerFieldExample(fieldStylingName);
    return;
  }

  triggerFieldExampleLocal(fieldStylingName);
};

const hideFieldExample = (fieldStylingName: FormStyleFieldName): void => {
  if (fieldStylingName.startsWith("dropdown")) {
    formDropdownField.value?.hideFieldExample(fieldStylingName);
    return;
  }

  hideFieldExampleLocal(fieldStylingName);
};

defineExpose({
  triggerFieldExample,
  hideFieldExample,
});
</script>

<style lang="scss">
.form-phone-field {
  display: flex;
  overflow: hidden;

  .form-text-input-field__label-container {
    display: none;
  }

  .form-text-input-field__input-container {
    flex-basis: 100%;
    max-width: none;
  }

  &__dropdown {
    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $space-s;

      padding: 0 12px 0 0 !important;
      border: none !important;
      box-shadow: none !important;

      .form-dropdown-field__icon {
        position: static !important;
        transform: none !important;
      }
    }
  }

  &__input {
    width: 100%;

    .ant-input-affix-wrapper {
      border: none !important;
      box-shadow: none !important;
      padding: 0 0 0 12px !important;
    }
  }
}
</style>
