<template>
  <div
    class="config-side-bar__wrapper"
    :class="{
      'config-side-bar__wrapper--layout-view': activeStep === Step.LAYOUTS,
    }"
  >
    <div class="config-side-bar__icons-container">
      <CommonIcon
        name="ant-design:plus-circle-filled"
        class="config-side-bar__icons-container-icon pointer"
        :class="{
          'config-side-bar__icons-container-icon--active':
            activeSideMenuTab === SideMenuTab.CONTENT,
        }"
        @click="toggleConfigTabs(SideMenuTab.CONTENT)"
      />
      <CommonIcon
        v-if="activeStep === Step.WIDGETS"
        name="ant-design:file-filled"
        class="config-side-bar__icons-container-icon pointer"
        :class="{
          'config-side-bar__icons-container-icon--active':
            activeSideMenuTab === SideMenuTab.PAGES,
        }"
        @click="toggleConfigTabs(SideMenuTab.PAGES)"
      />
      <CommonIcon
        name="ant-design:database-outlined"
        class="config-side-bar__icons-container-icon pointer"
        :class="{
          'config-side-bar__icons-container-icon--active':
            activeSideMenuTab === SideMenuTab.TAGS,
        }"
        @click="toggleConfigTabs(SideMenuTab.TAGS)"
      />
      <CommonIcon
        name="ant-design:apartment-outlined"
        class="config-side-bar__icons-container-icon pointer"
        :class="{
          'config-side-bar__icons-container-icon--active':
            activeSideMenuTab === SideMenuTab.WIDGETS_TREE,
        }"
        @click="toggleConfigTabs(SideMenuTab.WIDGETS_TREE)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from "~~/store/ui";
import { Step } from "~~/models/step.enum";
import { SideMenuTab } from "~~/models/side-menu-tab.enum";

const store = useUiStore();

const { activeSideMenuTab, activeStep } = storeToRefs(store);

// methods
const { toggleConfigTabs } = store;
</script>

<style lang="scss">
.config-side-bar {
  &__wrapper {
    position: fixed;
    top: ($header-height + $space-lg);
    left: 0;
    height: 100vh;
    width: $sidebar-size;
    background-color: $c-white;
    border-right: 1px solid $c-grey-15;
    z-index: 2;

    &--layout-view {
      top: $header-height;
    }
  }

  &__icons-container {
    @include flex-column(center, flex-start, 5px);
    color: $c-grey-45;

    &-icon {
      @include flex(center, center);
      height: 40px;
      text-align: center;
      width: 100%;
      padding-top: 15px;

      &--active#{&} {
        color: $c-primary-base;
      }
    }
  }
}
</style>
