import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import { ICustomField } from "~~/models/widgets/widget.core/widget.model";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getDefaultSize } from "~~/constants/configs/common/widget-initial";

import { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 38;

const v38WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (!["BannerSliderWidget"].includes(widget.name)) {
    return widget;
  }

  if (!widget.options.slideOptions) {
    widget.options.slideOptions = {
      size: getDefaultSize(),
    };
  }

  const slides = (widget.content.items.value || []) as ICustomField[];

  slides.forEach(element => {
    const image = element.fields!.find(field => field.name === "image")!;

    /* 
      If old version
    */
    if (!image.options.resizing) {
      const initial = {
        ...FLEXIBLE_IMAGE_INITIAL().options,
        _seo: {
          title: "",
          alt: "",
        },
        link: {
          type: null,
          value: null,
          authorizeValue: null,
          hasAuthorizeValue: false,
          isNewTabOpen: null,
        },
      };

      const currentImageOptions = image.options;

      initial._active = currentImageOptions._active as boolean;

      initial.resizing.type = currentImageOptions.containerSize.resizing;
      initial.containerSize.height.value =
        currentImageOptions.containerSize.size;
      initial.containerSize.height.type = ResizingType.FIXED;

      initial.border = currentImageOptions.border;
      initial.cornerRadius = currentImageOptions.cornerRadius;
      initial.padding = currentImageOptions.padding;

      image.options = initial;
    }
  });

  return widget;
};

export default v38WidgetMigration;
