<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgeDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #sports_container-design>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerDesignControls"
          />
        </template>

        <template #sports_container-content>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerContentControls"
          />
        </template>

        <template #sports_container-states>
          <ControlsList
            :widget="widget"
            :controls="sportsContainerStateControls"
          />
        </template>

        <template #title-design>
          <ControlsList
            :widget="widget"
            :controls="sportsTitleDesignControls"
          />
        </template>

        <template #title-content>
          <ControlsList
            :widget="widget"
            :controls="sportsTitleContentControls"
          />
        </template>

        <template #title-states>
          <ControlsList
            :widget="widget"
            :controls="sportsTitleStatesControls"
          />
        </template>

        <template #title_button-design>
          <ControlsList
            :widget="widget"
            :controls="titleButtonDesignControls"
          />
        </template>

        <template #title_button-content>
          <ControlsList
            :widget="widget"
            :controls="titleButtonContentControls"
          />
        </template>

        <template #title_button-states>
          <ControlsList
            :widget="widget"
            :controls="titleButtonStatesControls"
          />
        </template>

        <template #leagues_titles-design>
          <ControlsList
            :widget="widget"
            :controls="leaguesTitleDesignControls"
          />
        </template>

        <template #leagues_titles-content>
          <ControlsList
            :widget="widget"
            :controls="leaguesTitleContentControls"
          />
        </template>

        <template #sport_events_container-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventsDesignControls"
          />
        </template>

        <template #sport_events_container-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventsContentControls"
          />
        </template>

        <template #event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventsCardsDesignControls"
          />
        </template>

        <template #event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventsCardsContentControls"
          />
        </template>

        <template #event_cards-states>
          <ControlsList
            :widget="widget"
            :controls="sportEventsCardsStatesControls"
          />
        </template>

        <template #event_header-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventHeadersDesignControls"
          />
        </template>

        <template #event_header-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventHeadersContentControls"
          />
        </template>

        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="textWithDisplayControls"
          />
        </template>

        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_period>
          <ControlsList
            :widget="widget"
            :controls="textWithDisplayControls"
          />
        </template>

        <template #event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>

        <template #event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <template #event_name>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #event_score-design>
          <ControlsList
            :widget="widget"
            :controls="eventScoreDesignControls"
          />
        </template>

        <template #event_score-content>
          <ControlsList
            :widget="widget"
            :controls="eventScoreContentControls"
          />
        </template>

        <template #bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonDesignControls"
          />
        </template>

        <template #event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonContentControls"
          />
        </template>

        <template #event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonStatesControls"
          />
        </template>

        <!-- 
          Racing
         -->

        <template #racing_events_container-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventsDesignControls"
          />
        </template>

        <template #racing_events_container-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventsContentControls"
          />
        </template>

        <template #racing_event_cards-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventsCardsDesignControls"
          />
        </template>

        <template #racing_event_cards-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventsCardsContentControls"
          />
        </template>

        <template #racing_event_cards-states>
          <ControlsList
            :widget="widget"
            :controls="sportEventsCardsStatesControls"
          />
        </template>

        <template #racing_event_stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>

        <template #racing_event_name>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #racing_event_favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #racing_event_favourites_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonDesignControls"
          />
        </template>

        <template #racing_event_favourites_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonContentControls"
          />
        </template>

        <template #racing_event_favourites_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonStatesControls"
          />
        </template>

        <template #racing_event_status-design>
          <ControlsList
            :widget="widget"
            :controls="racingStatusDesignControls"
          />
        </template>

        <template #racing_event_status-content>
          <ControlsList
            :widget="widget"
            :controls="racingStatusContentControls"
          />
        </template>

        <template #racing_event_button-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonDesignControls"
          />
        </template>

        <template #racing_event_button-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonContentControls"
          />
        </template>

        <template #racing_event_button-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useConfig } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixMobileConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "sportId": {
      label: "Sport ID",
      sources: ["pageRoute", "custom"],
    },
    "eventPageUrl": {
      label: "Event page URL",
      sources: ["custom"],
      required: true,
    },
    "sportPageUrl": {
      label: "Sports page URL",
      sources: ["custom"],
    },
    "sportsBookPageUrl": {
      label: "Sports book page URL",
      sources: ["custom"],
      required: true,
    },
    "pageType": {
      label: "Page type",
      sources: ["custom"],
      description: "Possible values: List | Single",
      required: true,
    },
    "maxVisibleEvents": {
      label: "Max visible events",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgeDesignControls,
  widgetContentControls,

  /* 
    Sports container
  */
  sportsContainerDesignControls,
  sportsContainerContentControls,
  sportsContainerStateControls,

  /* 
    Sports title
  */
  sportsTitleDesignControls,
  sportsTitleContentControls,
  sportsTitleStatesControls,

  /* 
    Leagues title
  */
  leaguesTitleDesignControls,
  leaguesTitleContentControls,

  sportEventsDesignControls,
  sportEventsContentControls,

  /* 
    Sport event card
  */
  sportEventsCardsDesignControls,
  sportEventsCardsContentControls,
  racingEventsCardsContentControls,
  sportEventsCardsStatesControls,

  sportEventHeadersDesignControls,
  sportEventHeadersContentControls,

  textWithDisplayControls,
  favIconControls,
  streamIconControls,
  eventTimeControls,

  eventInfoDesignControls,
  eventInfoContentControls,

  eventNameControls,

  eventScoreContentControls,
  eventScoreDesignControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventButtonDesignControls,
  eventButtonContentControls,
  eventButtonStatesControls,

  racingStatusDesignControls,
  racingStatusContentControls,

  titleButtonStatesControls,
  titleButtonDesignControls,
  titleButtonContentControls,
} = useConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
