<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <DraggableList
      :model-value="items"
      :item-key="
            (e:any) => {
              return `${e.uuid}`;
            }
          "
      :group="{ name: 'widgets', pull: 'clone', put: false }"
      :sort="false"
      :scroll-sensitivity="200"
      :force-fallback="true"
      :fallback-on-body="true"
      class="widgets-list"
      @move="onDragMove"
    >
      <template #item="{ element }">
        <div
          data-is-new="true"
          :data-widget-data="JSON.stringify(element)"
          class="widgets-list__item relative"
        >
          <a-popover
            :key="element.uuid"
            :visible="hoveredItemId === element.uuid"
            placement="right"
            overlay-class-name="widgets-list__popover"
            @mouseenter="setHovereditemId(element.uuid)"
            @mouseleave="setHovereditemId()"
          >
            <template #content>
              <WidgetPreview :widget="element" />
            </template>

            <div class="widgets-list__item-content relative">
              <CommonIcon
                name="custom:dnd-marker"
                class="widgets-list__item-draggable-icon absolute"
              />

              <CommonIcon
                v-if="element.icon"
                :name="element.icon"
                class="widgets-list__item-icon"
              />

              <p
                class="f-small text-center m-t-8 widgets-list__item-label"
                v-html="element.nameHighlighted"
              ></p>
            </div>
          </a-popover>
        </div>
      </template>
    </DraggableList>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetsListElement } from "~~/models/widgets/widgets-list.model";
import WidgetPreview from "~~/components/widgets/widgets-list/WidgetPreview.vue";
import { useWidgetsNewDnd } from "~~/components/composables/widgets/useWidgetsNewDnd";

defineProps<{
  items: IWidgetsListElement[];
}>();

const { onDragMove } = useWidgetsNewDnd();

const hoveredItemId = ref("");

const setHovereditemId = (id = "") => {
  hoveredItemId.value = id;
};
</script>

<style lang="scss">
.widgets-list {
  user-select: none;
  @include flex(flex-start, flex-start, 0, true);

  &__popover {
    padding: $space-m;

    .ant-popover-inner {
      border-radius: 6px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }

  &__item {
    @include flex-column(center, center, 0);

    position: relative;
    margin: 0 15px 15px 0;
    width: 88px;
    height: 92px;
    border: 1px solid $c-grey-15;
    border-radius: 4px;
    background-color: $c-white;
    transition: border-color $base-transition;
    overflow: hidden;

    &-content {
      @include flex-column(center, center, 0);
    }

    &-draggable-icon {
      position: absolute;
      top: 6px;
      left: 7px;
      opacity: 0;
      transition: opacity $base-transition;
      color: $c-primary-base;
      font-size: 10px;
    }

    &-icon {
      transition: fill $base-transition;
      font-size: 32px;
    }

    &-label {
      @include text-ellipsis(2);

      transition: color $base-transition;
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    $this: &;
    &:hover {
      cursor: pointer;
      border-color: $c-primary-base;

      #{$this}-icon {
        fill: $c-primary-base;
      }

      #{$this}-label {
        color: $c-primary-base;
      }

      #{$this}-draggable-icon {
        opacity: 1;
      }
    }
  }
}
</style>
