<template>
  <ul
    :class="{
      'common-dropdown-menu--hasChildren': hasChildren,
    }"
    class="common-dropdown-menu"
  >
    <li
      v-for="option in options"
      :key="option.id"
      class="common-dropdown-menu__option"
      :class="{
        'common-dropdown-menu__option--expanded': expanded.includes(option.id),
        'common-dropdown-menu__option--selected':
          modelValue instanceof Object && modelValue?.id === option.value,
        'common-dropdown-menu__option--has-children':
          option.children && option.children.length,
      }"
      @click="handleOptionClick(option)"
    >
      <div class="common-dropdown-menu__label f-base">
        <span class="common-dropdown-menu__icon-container">
          <CommonIcon
            v-if="option.children && option.children.length"
            name="ant-design:caret-down-outlined"
            class="common-dropdown-menu__icon"
          />
        </span>

        <span class="overflow-ellipsis">
          {{ option.label }}
        </span>
      </div>

      <transition name="slide-y">
        <ul
          v-if="
            option.children &&
            option.children.length &&
            expanded.includes(option.id)
          "
          class="p-l-24 p-t-8"
        >
          <li
            v-for="child in option.children"
            :key="child.id"
            :class="{
              'common-dropdown-menu__option--selected':
                modelValue instanceof Object && modelValue?.id === child.value,
            }"
            class="common-dropdown-menu__option"
            @click="handleOptionClick(child)"
          >
            <p class="common-dropdown-menu__label f-base">
              {{ child.label }}
            </p>
          </li>
        </ul>
      </transition>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import {
  DropdownSearchItem,
  ICommonMenuItem,
} from "~~/models/common/dropdown.model";

const props = defineProps<{
  options: ICommonMenuItem[];
  modelValue: DropdownSearchItem | string;
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      value: DropdownSearchItem;
      label: string;
    }
  ): void;
}>();

const expanded = ref<Array<string | number>>([]);

const handleExpand = (id: string | number): void => {
  const index = expanded.value.indexOf(id);

  if (index === -1) {
    expanded.value.push(id);
    return;
  }

  expanded.value.splice(index, 1);
};

const handleOptionClick = (option: ICommonMenuItem): void => {
  if (option.children && option.children.length) {
    handleExpand(option.id);
    return;
  }

  emit("update:modelValue", {
    value: { id: String(option.value), type: option.type },
    label: option.label,
  });
};

const hasChildren = computed<boolean>(() => {
  const withChildren = props.options.filter(prop => {
    return prop.children && prop.children.length;
  });

  return withChildren.length > 0;
});
</script>

<style lang="scss">
.common-dropdown-menu {
  padding: $space-xs;
  border-radius: 8px;
  background-color: $c-white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  margin: 0;

  &--hasChildren {
    .common-dropdown-menu__icon-container {
      min-width: 22px;
    }
  }

  &__option {
    padding: 5px;
    border-radius: 4px;
    transition: background-color $base-transition;
    color: $c-black;
    cursor: pointer;
    width: 100%;
    max-width: 192px;
    overflow: hidden;

    &:hover:not(&--has-children),
    &--selected {
      background-color: $c-grey-04;
    }
  }

  &__label {
    margin: 0;

    @include flex(center, flex-start, $space-xs);
  }

  &__icon {
    font-size: 12px;
    transition: transform $base-transition;
    transform: rotate(-90deg);
  }

  &__option--expanded {
    .common-dropdown-menu__icon {
      transform: rotate(0);
    }
  }
}
</style>
