import { useLocalizedValue } from "~~/composables/useLocalizedValue";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 17;

const generateShowMoreField = () => {
  const field = {
    type: "ButtonLinkField",
    value: "Default",
    options: {},
    name: "show_more_title",
    validation: [{ string: { max: 50 } }],
  };

  const { localizedValue, localized } =
    useLocalizedValue().transformToLocalizedValue(field.value);

  field.value = localized ? localizedValue : field.value;

  return field;
};

const v17WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  if (["LiveBetsListWidget"].includes(widget.name)) {
    if (!("show_more_title" in widget.content)) {
      widget.content.show_more_title = generateShowMoreField();
    }
  }

  widget.options.__VERSION = WIDGET_VERSION;

  return widget;
};

export default v17WidgetMigration;
