interface IParams {
  transform?: {
    get?: (value: string) => any;
    set?: (value: any) => any;
  };
}

export const useQueryStore = (key: string, params: IParams = {}) => {
  const route = useRoute();
  const router = useRouter();

  const { get: getterTransform, set: setterTransform } = params.transform || {};

  return computed({
    get: () => {
      const value = route.query[key] as string;

      if (getterTransform) {
        return getterTransform(value);
      }

      return route.query[key];
    },

    set: val => {
      let value = val as string;

      if (setterTransform) {
        value = setterTransform(value);
      }

      router.replace({
        query: {
          ...route.query,
          [key]: value,
        },
      });
    },
  });
};
