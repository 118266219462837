<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #linkStates>
          <ControlsList
            :widget="widget"
            :controls="titleLinkStatesControls"
          />
        </template>

        <template
          v-if="fields.description_1.field"
          #description_1
        >
          <div>
            <ConfigPaddingInputs
              :model-value="fields.description_1.field.options.padding"
              is-bold
              @update:model-value="
                updateFieldOption('padding', $event, fields.description_1.field)
              "
            />

            <ConfigToggle
              size="small"
              :model-value="fields.description_1.field.options.withTranslations"
              :label="'With Translations'"
              class="p-l-16 p-t-16"
              :allow-disable="true"
              :show-options="false"
              is-field-item-toggle
              @update:model-value="
                updateFieldOption(
                  'withTranslations',
                  $event,
                  fields.description_1.field
                )
              "
            />
          </div>
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";

import {
  IWidgetWithFields,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useTextConfig } from "~~/composables/widgets/text/useTextWidgetConfig";
import { useWidgetsStore } from "~~/store/widgets";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  titleLinkState: State;
  widget: IWidgetWithFields;
}>();

const emit = defineEmits<{
  (e: "description-field-option-updated"): void;
  (e: "update-title-link-state", event: State): void;
  (e: "reset-states"): void;
}>();

const store = useWidgetsStore();
const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);
const titleLinkState = toRef(() => props.titleLinkState);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgetDesignControls,
  widgetContentControls,
  titleControls,
  titleLinkStatesControls,
  fields,
} = useTextConfig(ref(props.widget), titleLinkState, emit);

const updateFieldOption = (
  optionName: string,
  value: any,
  field: IWidgetField
): void => {
  store.updateFieldOptionValue(value, optionName, field.id);
};

const resetStates = () => {
  emit("reset-states");
};
</script>
