import { type Ref, computed } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useItems } from "~~/composables/widgets/search-results/useSearchResultsItems";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { Sizing } from "~~/models/grid.interface";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { useSearchResultEventCardConfig } from "~~/composables/widgets/search-results/useSearchResultEventCardConfig";
import { useSearchBetItemsConfig } from "~~/composables/widgets/search-results/useSearchResultBetItemsConfig";
import { useSearchEventButtonLinkConfig } from "~~/composables/widgets/search-results/useSearchResultEventButtonLink";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems();
  const { isWidthConfigDisabled } = useWidthConfig();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigContentWidth",
        valuePath: "options.contentWidth",
        className:
          widget.value.options.contentWidth.type === Sizing.FIXED
            ? "group-control-element"
            : "",
        valueSource: widget.value,
        options: {
          label: "",
          isDisabled: isWidthConfigDisabled.value,
          tooltipMessage: "The setting is disabled for fixed layout block",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.contentWidth.alignment",
        valueSource: widget.value,
        className: "group-control-element--child",
        visible: widget.value.options.contentWidth.type === Sizing.FIXED,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "content/ConfigTextInputWithSettings",
        valuePath: "value",
        valueSource: fields.value.title.field,
        options: {
          placeholder: "None",
          isBold: true,
          label: "Title",
          itemName: "title",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /* 
   Event card
  */

  const { eventCardDesignControls, eventCardContentControls } =
    useSearchResultEventCardConfig(widget, states, emit);

  /* 
    Bet items.
  */

  const {
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
  } = useSearchBetItemsConfig(widget, states, emit);

  /* 
    Event button link
  */

  const {
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,
  } = useSearchEventButtonLinkConfig(widget, states, emit);

  /* 
   Common title config
  */

  const titleControls = computed<ControlProp[]>(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Event time
  */

  const eventTimeControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.event_time.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.event_time.field,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.event_time.field,
            options: {
              placeholder: "Enter text",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.event_time.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /* 
    Status
  */

  const eventLiveStatusDesignControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.event_live_status.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.event_live_status.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.event_live_status.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.event_live_status.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const eventLiveStatusContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.event_live_status.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    eventCardDesignControls,
    eventCardContentControls,

    eventTimeControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStateControls,

    eventLiveStatusDesignControls,
    eventLiveStatusContentControls,
  };
};
