import type { Ref } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";

import { useBetsListFenixMobileItems } from "./useBetsListFenixMobileItems";
import { useBetsListFenixMobileWidgetControls } from "./useBetsListFenixMobileWidgetControls";
import { useBetsListFenixMobileSportsControls } from "./useBetsListFenixMobileSportsControls";
import { useBetsListFenixMobileEventControls } from "./useBetsListFenixMobileEventControls";

export const useBetsListFenixMobileConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);
  const { DROPDOWN_ITEMS, TABS } = useBetsListFenixMobileItems();

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const { widgetDesignControls, widgetContentControls, widgetDataControls } =
    useBetsListFenixMobileWidgetControls(widget, fields, states, emit);

  const {
    sportsDesignControls,
    sportsContentControls,
    sportsStatesControls,
    titleDesignControls,
    titleContentControls,
    titleStatesControls,
    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStatesControls,

    leaguesDesignControls,
    leaguesContentControls,
  } = useBetsListFenixMobileSportsControls(
    widget,
    statesValuePath,
    states,
    fields,
    emit
  );

  const {
    eventCardDesignControls,
    eventCardContentControls,
    eventCardStatesControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    startTimeDateControls,

    headerDesignControls,
    headerContentControls,

    eventLeagueControls,

    eventNameControls,

    favIconControls,
  } = useBetsListFenixMobileEventControls(
    statesValuePath,
    states,
    fields,
    emit
  );

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,

    titleDesignControls,
    titleContentControls,
    titleStatesControls,

    sportsDesignControls,
    sportsContentControls,
    sportsStatesControls,

    eventCardDesignControls,
    eventCardContentControls,
    eventCardStatesControls,

    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,

    startTimeDateControls,

    eventButtonLinkDesignControls,
    eventButtonLinkContentControls,
    eventButtonLinkStatesControls,

    headerDesignControls,
    headerContentControls,

    eventLeagueControls,

    favIconControls,

    eventNameControls,

    leaguesDesignControls,
    leaguesContentControls,
  };
};
