import { defineStore } from "pinia";

import { IAuthStore, Permissions } from "~~/models/stores/auth-store.model";
import http from "~~/services/http";

const permissionMappingsDict: Record<string, Permissions> = {
  "GRID_CONTENT": Permissions.CONTENT,
  "GRID_DESIGN": Permissions.DESIGN,
};

export const useAuthStore = defineStore("auth", {
  state: (): IAuthStore => ({
    permissions: {
      [Permissions.DESIGN]: false,
      [Permissions.CONTENT]: false,
    },
  }),
  getters: {},
  actions: {
    async fetchPermissions(): Promise<void> {
      const { data } = await http.get("/rbac/user-permissions");

      data.permissions.forEach((permission: string) => {
        const currentPerm = permissionMappingsDict[permission];

        if (currentPerm) {
          this.permissions[currentPerm] = true;
        }
      });
    },
  },
});
